/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = [
        (v1 /*: any*/),
        {
            "alias": null,
            "args": null,
            "concreteType": "BillTo",
            "kind": "LinkedField",
            "name": "billTo",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "BillFrom",
            "kind": "LinkedField",
            "name": "billFrom",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integration",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "invoiceId",
                "type": "ID!"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                (v0 /*: any*/),
                (v0 /*: any*/)
            ]
        },
        "name": "ExportInvoiceBillingOptionModal_viewer",
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "invoiceId"
                    }
                ],
                "concreteType": "InvoiceType",
                "kind": "LinkedField",
                "name": "invoice",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "projects",
                        "plural": true,
                        "selections": [
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Client",
                                "kind": "LinkedField",
                                "name": "client",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    {
                                        "alias": "clientBillTos",
                                        "args": null,
                                        "concreteType": "ClientBillToTypeConnection",
                                        "kind": "LinkedField",
                                        "name": "__Client_clientBillTos_connection",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ClientBillToTypeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ClientBillTo",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": (v3 /*: any*/),
                                                        "storageKey": null
                                                    },
                                                    (v4 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v5 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": "projectBillTos",
                                "args": null,
                                "concreteType": "ProjectBillToTypeConnection",
                                "kind": "LinkedField",
                                "name": "__Project_projectBillTos_connection",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProjectBillToTypeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProjectBillTo",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": (v3 /*: any*/),
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v5 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '07729c76c96b3ad3aeee641804895e5a';
export default node;
