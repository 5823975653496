import React from 'react';
import Warning from '../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {useIntl} from 'react-intl';
import GenericModal from './generic_modal';

const TaskDeletionWarningModal = ({task, deleteCallback, closeModal}) => {
	const {formatMessage} = useIntl();

	let message = formatMessage({id: 'delete_task_modal.description'});

	const hasTimeRegistrations = task.hasTimeRegistrations || task.timeRegistrations?.edges.length > 0;
	if (hasTimeRegistrations && task.hasChildren) {
		message = formatMessage({id: 'delete-prompt.card-children-and-ime-entries-info'});
	} else if (task.hasChildren) {
		message = formatMessage({id: 'delete-prompt.card-children-info'});
	} else if (hasTimeRegistrations) {
		message = formatMessage({id: 'delete-prompt.card-time-entries-info'});
	}
	const content = (
		<div>
			<Warning message={message} />
			<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
		</div>
	);

	const buttons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
			cy: 'task-modal-delete-task-modal-cancel',
		},
		{
			text: formatMessage({id: 'common.delete'}),
			callback: deleteCallback,
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.RED,
			cy: 'task-modal-delete-task-modal-delete',
		},
	];

	return (
		<GenericModal
			top={true}
			className="default-warning-modal"
			cy="task-modal-delete-task-modal"
			buttons={buttons}
			content={content}
			closeModal={closeModal}
		/>
	);
};

export default TaskDeletionWarningModal;
