import React, {useState} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import Styled from 'styled-components/macro';
import {RadioButton} from 'web-components';
import {CSS_CONSTANTS} from '../../css_variables';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import {BUTTON_COLOR, DependencyType, WorkflowCategories} from '../../constants';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import ReorderProjectBoardTaskMutation from '../../mutations/ReorderProjectBoardTaskMutation';
import {cloneDeep} from 'lodash';
import WarningIcon from '../../images/warning_icon';

const ContenWrapper = Styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom:8px;
`;

const Header = Styled.div`
	margin-bottom:24px;
`;

const ChoicesWrapper = Styled.div`
	margin: 24px 0;
`;

const ChoiceWrapper = Styled.div`
	margin-bottom:16px;
`;

const Option = Styled.div`
	display:flex;
	flex-direction: row;
	align-items:center;
	margin-bottom:2px;
`;

const OptionText = Styled.div`
	margin-left:8px;
`;

const OptionDescription = Styled.div`
	margin-left:30px; //22 + 8
	margin-top: 5px;
	width:180px;
`;

const TaskList = Styled.div`
	display: grid;
	grid-template-columns: ${props => (props.blocked ? `auto auto auto` : `auto auto`)};
	width:584px;
`;

const HeaderElement = Styled.div`
	color:${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 9px;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-bottom:8px;
	font-weight: 500;
	padding-left: 24px;
	padding-right: 24px;
	position:sticky;
	top:0;
	background-color:white;
`;

const SprintElement = Styled.div`
	height:40px;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-left: 24px;
	padding-right: 24px;
	padding-top:12px;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
`;

const TASKS_OPTIONS = {
	CHANGE_STATUS: true,
	KEEP_STATUS: false,
};

const TaskStatusMicroAssistModal = ({closeModal, viewer, intl}) => {
	/***
	 * This will find all the available status options - in the category DONE
	 */
	const statusSelectOptions = cloneDeep(viewer.parentTask.project.statusColumnsV2.edges)
		.sort((a, b) => a.node.order - b.node.order)
		.map(col => ({value: col.node.id, label: col.node.name, category: col.node.category}))
		.filter(option => option.category === WorkflowCategories.DONE);

	/**
	 * State handling for selectedOption (Keep or change status), and the selected status to change to.
	 */
	const [selectedOption, setSelectedOption] = useState(TASKS_OPTIONS.CHANGE_STATUS);
	const [selectedStatus, setSelectedStatus] = useState(
		statusSelectOptions.length > 0 &&
			statusSelectOptions.find(option => option.value === viewer.parentTask.statusColumnV2.id)
	);

	const switchSelected = opt => {
		setSelectedOption(opt);
	};

	/**
	 * Change the selected status to change to.
	 *
	 * @param status The selected status
	 */
	const handleSelectedStatus = status => {
		setSelectedOption(TASKS_OPTIONS.CHANGE_STATUS);
		setSelectedStatus(status);
	};

	/**
	 * This will flatten the children and their children to an array
	 * @param children The children
	 * @returns {*[]} An array with the subtasks.
	 */
	const flatten = children => {
		const subTasks = [];
		children.forEach(child => {
			if (child.node.childrenTasks?.edges.length > 0) {
				subTasks.push(...flatten(child.node.childrenTasks.edges));
			}
			subTasks.push(child);
		});
		return subTasks;
	};

	/**
	 * All subtask that are not in the state of DONE
	 */
	const openChildren = flatten(viewer.parentTask.childrenTasks.edges).filter(
		task => task.node.statusColumnV2.category !== WorkflowCategories.DONE
	);

	/**
	 * Should the micro assist modal be shown?
	 *
	 * Requires the parent task to be moved to a DONE status and
	 * subtask that isn't DONE.
	 */
	const microAssistRequired =
		openChildren.length > 0 && viewer.parentTask.statusColumnV2.category === WorkflowCategories.DONE;

	/**
	 * Filter dependencies according to condition and status
	 */
	const dependencyFilter = dependency => {
		if (
			dependency.node.type === DependencyType.CANNOT_BE_COMPLETED &&
			dependency.node.thisDependsOnTask.statusColumnV2.category !== WorkflowCategories.DONE
		) {
			return true;
		}

		if (
			dependency.node.type === DependencyType.CANNOT_START &&
			dependency.node.thisDependsOnTask.statusColumnV2.category === WorkflowCategories.TODO
		) {
			return true;
		}

		return false;
	};

	/**
	 * Child has dependency block a status change
	 */
	const findBlockingDependencies = children => {
		const blockingDependencies = [];
		children.forEach(child => {
			if (child.node.hasDependency) {
				// This child task depends on a task
				if (child.node.thisTaskDependsOn?.edges.length > 0) {
					if (child.node.thisTaskDependsOn.edges.filter(dependencyFilter).length > 0) {
						blockingDependencies.push(child.node.id);
					}
				}
			}
		});
		return blockingDependencies;
	};

	/**
	 * Task with blocking dependency
	 */
	const blockedTasks = findBlockingDependencies(openChildren);

	/**
	 * Method to change the status on the substasks.
	 */
	const changeStatus = () => {
		if (selectedOption) {
			var taskIDs = openChildren.map(task => task.node.id);

			if (!!blockedTasks.length) {
				taskIDs = taskIDs.filter(id => !blockedTasks.includes(id));
			}

			const prevStatusColumnIdMap = openChildren.reduce((map, task) => {
				map.set(task.node.id, {
					statusColumnId: task.node.statusColumnV2.id,
					projectGroupStatusColumnId: task.node.statusColumnV2.projectGroupStatusColumnId,
				});
				return map;
			}, new Map());

			const mutationObject = {
				ids: taskIDs,
				companyId: viewer.company.id,
				prevStatusColumnIdMap: prevStatusColumnIdMap,
				projectGroupStatusColumnId: selectedStatus.projectGroupStatusColumnId,
				statusColumnId: selectedStatus.value,
				statusColumnCategory: selectedStatus.category,
			};

			/* mutationObject.projectGroupStatusColumnId = selectedStatus.projectGroupStatusColumnId;
			mutationObject.statusColumnId = selectedStatus.value; */

			Util.CommitSchedulingModalUpdate(ReorderProjectBoardTaskMutation, mutationObject);
		}
	};

	/**
	 * This will render the content of the modal.
	 *
	 * If the micro assist dialog isn't needed, it will close the modal instead.
	 */
	const content = () => {
		// This will check if the modal should be shown or not. If there aren't any 'Open' children, don't show it.
		if (!microAssistRequired) {
			closeModal();
			return null;
		}

		const {formatMessage} = intl;

		return (
			<ContenWrapper>
				<Header>
					{formatMessage({id: 'project_scoping.task_status_micro_assist_title'}, {amount: openChildren.length})}
				</Header>
				<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={300} autoHide={false}>
					<TaskList blocked={!!blockedTasks.length}>
						<HeaderElement>{formatMessage({id: 'common.subtask'})}</HeaderElement>
						<HeaderElement>{formatMessage({id: 'common.status'})}</HeaderElement>
						{!!blockedTasks.length ? <HeaderElement>{formatMessage({id: 'common.blocked'})}</HeaderElement> : null}
						{openChildren.map(task => {
							const elements = [];
							elements.push(
								<SprintElement title={task.node.name} key={task.node.id + '_name'}>
									{task.node.name}
								</SprintElement>
							);
							elements.push(
								<SprintElement title={task.node.statusColumnV2.name} key={task.node.id + '_status'}>
									{task.node.statusColumnV2.name}
								</SprintElement>
							);
							if (!!blockedTasks.length) {
								elements.push(
									<SprintElement title={formatMessage({id: 'common.blocked'})} key={task.node.id + '_block'}>
										{blockedTasks.includes(task.node.id) ? (
											<WarningIcon
												title={'A dependency on the task is blocking the status change'}
												danger={true}
												customWidth={15}
												customHeight={15}
											/>
										) : (
											''
										)}
									</SprintElement>
								);
							}
							return elements;
						})}
					</TaskList>
				</CustomScrollDiv>

				<ChoicesWrapper>
					<ChoiceWrapper>
						<Option>
							<RadioButton
								checked={selectedOption === TASKS_OPTIONS.CHANGE_STATUS}
								onChange={() => switchSelected(TASKS_OPTIONS.CHANGE_STATUS)}
								onClick={() => switchSelected(TASKS_OPTIONS.CHANGE_STATUS)}
								cy={'change-status-option'}
							/>
							<OptionText>
								<FormattedHTMLMessage
									id={
										!!blockedTasks.length
											? 'project_scoping.task_status_micro_assist_change_status_with_blocked'
											: 'project_scoping.task_status_micro_assist_change_status'
									}
									values={{amount: openChildren.length}}
								/>
							</OptionText>
						</Option>
						{statusSelectOptions.length > 1 && (
							<OptionDescription>
								<DropdownV2
									hideLabel={true}
									options={statusSelectOptions}
									onChange={handleSelectedStatus}
									value={selectedStatus.value}
									multi={false}
									focusOnClick={true}
									customHeight={30}
									maxHeight={190}
								/>
							</OptionDescription>
						)}
					</ChoiceWrapper>
					<ChoiceWrapper>
						<Option>
							<RadioButton
								checked={selectedOption === TASKS_OPTIONS.KEEP_STATUS}
								onChange={() => switchSelected(TASKS_OPTIONS.KEEP_STATUS)}
								onClick={() => switchSelected(TASKS_OPTIONS.KEEP_STATUS)}
								cy={'keep-status-option'}
							/>
							<OptionText>
								<FormattedHTMLMessage id={'project_scoping.task_status_micro_assist_keep_status'} />
							</OptionText>
						</Option>
					</ChoiceWrapper>
				</ChoicesWrapper>
			</ContenWrapper>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{text: intl.formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
				{
					text: intl.formatMessage({id: 'common.confirm'}),
					cy: 'confirm-change-status',
					callback: () => changeStatus(),
				},
			]}
			content={content()}
			headerText={intl.formatMessage(
				{id: 'project_scoping.task_status_micro_assist_header'},
				{amount: openChildren.length}
			)}
			modalWidth={MODAL_WIDTH.MEDIUM}
		/>
	);
};

const TaskStatusMicroAssistModalQuery = graphql`
	query TaskStatusMicroAssistModal_Query($id: String) {
		viewer {
			component(name: "task_status_micro_assist_modal")
			actualPersonId
			id
			...TaskStatusMicroAssistModal_viewer @arguments(id: $id)
		}
	}
`;

export {TaskStatusMicroAssistModalQuery};

export default injectIntl(
	createFragmentContainer(TaskStatusMicroAssistModal, {
		viewer: graphql`
			fragment TaskStatusMicroAssistModal_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
				parentTask: task(companyTaskId: $id) {
					id
					name
					statusColumnV2 {
						id
						name
						category
					}
					project {
						statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
							edges {
								node {
									id
									name
									projectGroupStatusColumnId
									category
								}
							}
						}
					}
					childrenTasks(first: 1000000) @connection(key: "ParentTask_childrenTasks", filters: []) {
						edges {
							node {
								id
								name
								statusColumnV2 {
									id
									name
									projectGroupStatusColumnId
									category
								}
								hasDependency
								thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
									edges {
										node {
											id
											type
											thisDependsOnTask {
												statusColumnV2 {
													category
												}
											}
										}
									}
								}
								childrenTasks(first: 1000000) @connection(key: "ParentTask_childrenTasks", filters: []) {
									edges {
										node {
											id
											name
											statusColumnV2 {
												id
												name
												projectGroupStatusColumnId
												category
											}
											hasDependency
											thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
												edges {
													node {
														id
														type
														thisDependsOnTask {
															statusColumnV2 {
																category
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				company {
					id
				}
			}
		`,
	})
);
