import {graphql} from 'graphql';
import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {createFragmentContainer} from 'react-relay';
import {Dropdown} from 'web-components';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import GenericModal from './generic_modal';
import DirectApi from '../../directApi';

const importSageIntacctExpenseItemModal = ({viewer, closeModal, handleNewExpense, projectId, onSave}) => {
	const [unimportedExpenses, setUnimportedExpenses] = useState([]);
	const [fetchingExpenses, setFetchingExpenses] = useState(true);
	const [selectedPurchase, setSelectedPurchase] = useState([]);
	const [failed, setFailed] = useState(false);

	const intl = useIntl();

	useEffect(() => {
		DirectApi.Fetch('sage_intacct/unimported_purchase_orders').then(res => {
			if (res?.purchaseOrders) {
				const sortedPurchaseOrders = res.purchaseOrders.sort((a, b) => {
					if (a.DOCID.toUpperCase().trim() > b.DOCID.toUpperCase().trim()) {
						return 1;
					} else if (a.DOCID.toUpperCase().trim() < b.DOCID.toUpperCase().trim()) {
						return -1;
					}
					return 0;
				});
				setUnimportedExpenses(sortedPurchaseOrders);
				setFetchingExpenses(false);
			} else if (res?.error) {
				setFailed(true);
				setFetchingExpenses(false);
			} else {
				setUnimportedExpenses([]);
				setFetchingExpenses(false);
			}
		});
	}, []);

	const content = (
		<div className="integration-sync-clients-modal-content">
			<div className="integration-sync-clients-modal-description">
				<FormattedMessage id="integrations.sage_intacct.import_purchases.description" />
			</div>
			{fetchingExpenses ? (
				<InlineLoader />
			) : unimportedExpenses[0] !== undefined ? (
				<Dropdown
					usePortal={true}
					selectedItems={selectedPurchase}
					name={intl.formatMessage({id: 'project_budget.expenses.select_purchase'})}
					maxHeight={300}
					onSelect={value => {
						setSelectedPurchase(value);
					}}
					onRemove={() => setSelectedPurchase(null)}
				>
					{unimportedExpenses.map(expense => {
						return (
							<Dropdown.SingleText key={expense.RECORDNO} value={expense}>
								{expense.DOCID}
							</Dropdown.SingleText>
						);
					})}
				</Dropdown>
			) : failed ? (
				<b>Something has gone wrong, try again or contact support.</b>
			) : (
				<FormattedMessage id="integrations.sage_intacct.all_purchase_orders_synched" />
			)}
		</div>
	);

	const getPurchaseInForecastFormat = purchase => {
		const convertedDate = new Date(purchase.WHENCREATED.substring(0, 10));
		const person = {id: viewer.actualPersonId};

		return {
			purchaseOrderId: purchase.RECORDNO,
			name: purchase.DOCID,
			cost: purchase.TOTAL,
			price: purchase.TOTAL,
			markupPercentage: 0,
			expenseYear: convertedDate.getFullYear(),
			expenseMonth: convertedDate.getMonth() + 1,
			expenseDay: convertedDate.getDate(),
			notes: !purchase.NOTE ? purchase.NOTE : '',
			person: person,
			project: projectId ? projectId : null,
			phase: null,
			category: null,
			billable: false,
			partOfFixedPrice: false,
			planned: false,
			approved: true,
			quantity: 1,
			nonBillable: null,
			selectedTab: 'general',
			isUploading: false,
			enlargedFileSrc: null,
			files: [],
			filesPreview: [],
			loading: false,
			nameError: null,
		};
	};

	return (
		<GenericModal
			headerText={<FormattedMessage id="integrations.sage_intacct.import_purchases" />}
			closeModal={closeModal}
			className="integration-sync-clients-modal"
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'project_budget.create_expense'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => {
						closeModal();
						showModal({
							type: MODAL_TYPE.EXPENSE_ITEM,
							expenseToImport: getPurchaseInForecastFormat(selectedPurchase[0]),
							projectId: projectId,
							onSave: onSave,
							handleNewExpense: handleNewExpense,
							fromSageIntacct: true,
						});
					},
					disabled: fetchingExpenses || selectedPurchase.length === 0,
					defaultCallback: () => null,
					preventDefaultClose: true,
				},
			]}
			content={content}
		/>
	);
};
const importSageIntacctExpenseItemModalQuery = graphql`
	query importSageIntacctExpenseItemModal_Query {
		viewer {
			actualPersonId
			component(name: "import_expense_item_modal")
			...importSageIntacctExpenseItemModal_viewer
		}
	}
`;

export {importSageIntacctExpenseItemModalQuery};

export default createFragmentContainer(importSageIntacctExpenseItemModal, {
	viewer: graphql`
		fragment importSageIntacctExpenseItemModal_viewer on Viewer {
			id
			actualPersonId
		}
	`,
});
