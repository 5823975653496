import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import moment from 'moment';
import Util from '../../../../../forecast-app/shared/util/util';
import {StatsContainer, StatContainer, StatLine, StatTitle, StatValue, StatTitleBold} from './PredictedEndDateModal.styled';
import {getPredictedEndDateWarningVariant} from './PredictedEndDateModalLogic';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {getFocusRollingTooltipContent, getPerformanceRollingTooltipContent} from '../../ProjectHealthUtil';

const PredictedEndDateModalStatsSection = ({project}) => {
	const intl = useIntl();
	const {projectEndYear, projectEndMonth, projectEndDay, projectHealth} = project;
	const {
		totalTaskEstimate,
		totalBaselineEstimate,
		totalTaskEstimateDone,
		totalRemainingAllocation,
		focus,
		performanceRolling,
		optimisticPredictedEndDate,
		predictedEndDate,
		conservativePredictedEndDate,
	} = projectHealth;

	const projectEndDate = projectEndYear && Util.CreateNonUtcMomentDate(projectEndYear, projectEndMonth, projectEndDay);

	const optimisticPredictedEndDateMoment = optimisticPredictedEndDate && moment(optimisticPredictedEndDate);
	const predictedEndDateMoment = predictedEndDate && moment(predictedEndDate);
	const conservativePredictedEndDateMoment = conservativePredictedEndDate && moment(conservativePredictedEndDate);

	const optimisticPredictedEndDateVariant =
		optimisticPredictedEndDateMoment &&
		projectEndDate &&
		getPredictedEndDateWarningVariant(optimisticPredictedEndDateMoment, projectEndDate);
	const predictedEndDateVariant =
		predictedEndDateMoment && projectEndDate && getPredictedEndDateWarningVariant(predictedEndDateMoment, projectEndDate);
	const conservativePredictedEndDateVariant =
		conservativePredictedEndDateMoment &&
		projectEndDate &&
		getPredictedEndDateWarningVariant(conservativePredictedEndDateMoment, projectEndDate);

	return (
		<StatsContainer>
			<StatContainer>
				{projectEndDate ? (
					<StatLine>
						<StatTitle>Deadline</StatTitle>
						<StatValue>{projectEndDate.format(Util.GetShortYearMomentFormat('ll'))}</StatValue>
					</StatLine>
				) : null}
				{totalBaselineEstimate ? (
					<StatLine>
						<StatTitle>Baseline</StatTitle>
						<StatValue>{Util.convertMinutesToFullHour(totalBaselineEstimate, intl)}</StatValue>
					</StatLine>
				) : null}
				<StatLine>
					<StatTitle>Planned work</StatTitle>
					<StatValue>{Util.convertMinutesToFullHour(totalTaskEstimate, intl)}</StatValue>
				</StatLine>
				<StatLine>
					<StatTitle>Done work</StatTitle>
					<StatValue>{Util.convertMinutesToFullHour(totalTaskEstimateDone, intl)}</StatValue>
				</StatLine>
				<StatLine>
					<StatTitle>Remaining allocation</StatTitle>
					<StatValue>{Util.convertMinutesToFullHour(totalRemainingAllocation, intl)}</StatValue>
				</StatLine>
			</StatContainer>
			<StatContainer>
				<ForecastTooltip maxWidth={350} content={getPerformanceRollingTooltipContent(performanceRolling, intl)}>
					<StatLine marginBottom={4}>
						<StatTitle>Task Performance</StatTitle>
						<StatValue>
							{performanceRolling === 0 ? '-' : intl.formatNumber(performanceRolling * 100) + '%'}
						</StatValue>
					</StatLine>
				</ForecastTooltip>
				<ForecastTooltip maxWidth={350} content={getFocusRollingTooltipContent(focus, intl)}>
					<StatLine>
						<StatTitle>Team Focus</StatTitle>
						<StatValue>{focus === 0 ? '-' : intl.formatNumber(focus * 100) + '%'}</StatValue>
					</StatLine>
				</ForecastTooltip>
			</StatContainer>
			<StatContainer>
				<StatLine>
					<StatTitle>Optimistic pred.</StatTitle>
					<StatValue variant={optimisticPredictedEndDateVariant}>
						{optimisticPredictedEndDateMoment
							? optimisticPredictedEndDateMoment.format(Util.GetShortYearMomentFormat('ll'))
							: 'N/A'}
					</StatValue>
				</StatLine>
				<StatLine>
					<StatTitleBold>Predicted end</StatTitleBold>
					<StatValue variant={predictedEndDateVariant}>
						{predictedEndDateMoment ? predictedEndDateMoment.format(Util.GetShortYearMomentFormat('ll')) : 'N/A'}
					</StatValue>
				</StatLine>
				<StatLine>
					<StatTitle>Conservative pred.</StatTitle>
					<StatValue variant={conservativePredictedEndDateVariant}>
						{conservativePredictedEndDateMoment
							? conservativePredictedEndDateMoment.format(Util.GetShortYearMomentFormat('ll'))
							: 'N/A'}
					</StatValue>
				</StatLine>
			</StatContainer>
		</StatsContainer>
	);
};

export default createFragmentContainer(PredictedEndDateModalStatsSection, {
	project: graphql`
		fragment PredictedEndDateModalStatsSection_project on ProjectType {
			projectEndYear
			projectEndMonth
			projectEndDay
			projectHealth {
				totalTaskEstimate
				totalBaselineEstimate
				totalTaskEstimateDone
				totalRemainingAllocation
				focus
				performanceRolling
				predictedEndDate
				optimisticPredictedEndDate
				conservativePredictedEndDate
			}
		}
	`,
});
