/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type missionControlHeader_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type mlStats_viewer$ref: FragmentReference;
declare export opaque type mlStats_viewer$fragmentType: mlStats_viewer$ref;
export type mlStats_viewer = {|
  +id: string,
  +missionControlData: ?{|
    +id: string,
    +companiesWithMLModels: ?$ReadOnlyArray<?{|
      +companyId: ?number,
      +companyName: ?string,
      +lowHighModel: ?boolean,
      +lowHighModelLastUpdated: ?string,
      +lowHighModelAccuracyTest: ?number,
      +lowHighModelAccuracyTrain: ?number,
      +lowHighModelRmseTest: ?number,
      +overrunPredictorPrecisionTest: ?number,
      +overrunPredictorRecallTest: ?number,
      +roleModel: ?boolean,
      +roleModelLastUpdated: ?string,
      +roleModelAccuracyTest: ?number,
      +roleModelAccuracyTrain: ?number,
      +roleModelTop1AccuracyTest: ?number,
      +labelModel: ?boolean,
      +labelModelLastUpdated: ?string,
      +labelModelAccuracyTest: ?number,
      +labelModelAccuracyTrain: ?number,
      +labelModelTop1AccuracyTest: ?number,
      +labelModelTop3AccuracyTest: ?number,
      +assigneeModel: ?boolean,
      +assigneeModelLastUpdated: ?string,
      +assigneeModelAccuracyTest: ?number,
      +assigneeModelAccuracyTrain: ?number,
      +assigneeModelTop1AccuracyTest: ?number,
      +assigneeModelTop3AccuracyTest: ?number,
    |}>,
  |},
  +$fragmentRefs: missionControlHeader_viewer$ref,
  +$refType: mlStats_viewer$ref,
|};
export type mlStats_viewer$data = mlStats_viewer;
export type mlStats_viewer$key = {
  +$data?: mlStats_viewer$data,
  +$fragmentRefs: mlStats_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "mlStats_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlCompanyMLStatsType",
          "kind": "LinkedField",
          "name": "companiesWithMLModels",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowHighModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowHighModelLastUpdated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowHighModelAccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowHighModelAccuracyTrain",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lowHighModelRmseTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "overrunPredictorPrecisionTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "overrunPredictorRecallTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleModelLastUpdated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleModelAccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleModelAccuracyTrain",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleModelTop1AccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModelLastUpdated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModelAccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModelAccuracyTrain",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModelTop1AccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "labelModelTop3AccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModelLastUpdated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModelAccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModelAccuracyTrain",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModelTop1AccuracyTest",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assigneeModelTop3AccuracyTest",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "missionControlHeader_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '21c53fd07bbaf256e7664e1d63675ae3';

module.exports = node;
