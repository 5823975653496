/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ExportInvoiceButton_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingTable_project$ref: FragmentReference;
declare export opaque type ProjectInvoicingTable_project$fragmentType: ProjectInvoicingTable_project$ref;
export type ProjectInvoicingTable_project = {|
  +id: string,
  +name: ?string,
  +rateCard: ?{|
    +currency: ?string
  |},
  +budget: ?number,
  +budgetType: ?BUDGET_TYPE,
  +invoices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +invoicedWithoutTax: ?number,
        +invoicedWithoutTaxProjectCurrency: ?number,
        +paidWithoutTax: ?number,
        +paidWithoutTaxProjectCurrency: ?number,
        +quickbooksId: ?number,
        +sageIntacctId: ?string,
        +economicId: ?number,
        +xeroId: ?string,
        +id: string,
        +invoiceReference: ?string,
        +companyInvoiceId: ?number,
        +currency: ?string,
        +status: ?string,
        +externalStatus: ?string,
        +paymentStatus: ?string,
        +createdYear: ?number,
        +createdMonth: ?number,
        +createdDay: ?number,
        +dueYear: ?number,
        +dueMonth: ?number,
        +dueDay: ?number,
        +name: ?string,
        +client: ?{|
          +id: string,
          +name: ?string,
        |},
      |}
    |}>
  |},
  +$fragmentRefs: ExportInvoiceButton_project$ref,
  +$refType: ProjectInvoicingTable_project$ref,
|};
export type ProjectInvoicingTable_project$data = ProjectInvoicingTable_project;
export type ProjectInvoicingTable_project$key = {
  +$data?: ProjectInvoicingTable_project$data,
  +$fragmentRefs: ProjectInvoicingTable_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectInvoicingTable_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "rateCard",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceTypeConnection",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoicedWithoutTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoicedWithoutTaxProjectCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidWithoutTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidWithoutTaxProjectCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quickbooksId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sageIntacctId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "economicId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "xeroId",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiceReference",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyInvoiceId",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueDay",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "client",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportInvoiceButton_project"
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '51ea4a7e4840e6ca6984a55f99914530';

module.exports = node;
