import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import FixedRangePicker from '../../../components/new-ui/date-controls/fixed_range_picker';
import GenericModal from '../../modal/generic_modal';
import Util from '../../../forecast-app/shared/util/util';

class CopyTimeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dateToCopyTimeTo: null,
		};
	}

	render() {
		const {formatDate, formatMessage} = this.props.intl;
		const header = this.props.intl.formatMessage(
			{id: 'overview_time.copy_time_registration_from'},
			{date: formatDate(this.props.sourceDate, {weekday: 'long', day: 'numeric', month: 'short', year: 'numeric'})}
		);
		var projectTimes = [];
		var idleTimes = [];
		this.props.timeRegistrations.forEach(timeReg => {
			// If has task and time registered push to projecTimes, else if has idleTime and time registered push to idleTimes.
			if (((timeReg.task && timeReg.task.project) || timeReg.project) && timeReg.minutesRegistered)
				projectTimes.push(timeReg);
			else if (timeReg.idleTime && timeReg.minutesRegistered) idleTimes.push(timeReg);
		});

		const content = (
			<div>
				<div className={'copy-time-registrations'}>
					<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={374}>
						<div className={'table-container'}>
							<table className={'copy-time-table'} ref={el => (this.timeTable = el)}>
								<colgroup>
									<col />
									<col />
									<col />
								</colgroup>
								<thead>
									<tr>
										<th className={'copy-time-project-header'}>
											{formatMessage({id: 'common.project'}).toUpperCase()}
										</th>
										<th className={'copy-time-task-header'}>
											{formatMessage({id: 'common.task'}).toUpperCase()}
										</th>
										<th className={'copy-time-hours-header'}>
											{formatMessage({id: 'common.hours'}).toUpperCase()}
										</th>
									</tr>
								</thead>
								<tbody>
									{projectTimes.map((timeReg, index) => {
										const project =
											timeReg.task && timeReg.task.project ? timeReg.task.project : timeReg.project;
										return (
											<tr id={index} key={index} className={'alternating'}>
												<td className={'copy-time-project-cell'}>
													<div
														className={'project-indicator'}
														title={project.name}
														style={{
															backgroundColor: project.projectColor,
															color: Util.getTextColor(project.projectColor),
														}}
													>
														<p className={'project-indicator-text'}>P{project.companyProjectId}</p>
													</div>
													<div className={'project-name'}>
														{project ? project.name : timeReg.idleTime ? timeReg.idleTime.name : ''}
													</div>
												</td>
												<td className={'copy-time-task-cell'}>
													{timeReg.task ? timeReg.task.name : ''}
												</td>
												<td className={'copy-time-hours-cell'}>
													{Math.round((timeReg.minutesRegistered / 60) * 10) / 10 +
														' ' +
														formatMessage({id: 'common.hours.short'})}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className={'spacer'} />
							<table className={'copy-time-table-idle'} ref={el => (this.timeTable = el)}>
								<tbody>
									{idleTimes.map((timeReg, index) => (
										<tr id={index} key={index} className={'idle'}>
											<td className={'copy-time-project-cell'}>
												<div title={timeReg.idleTime.name} className={'project-indicator'}>
													<p className={'project-indicator-text'}>{''}</p>
												</div>
												<div className={'project-name'}>
													{timeReg.project
														? timeReg.project.name
														: timeReg.idleTime
														? timeReg.idleTime.name
														: ''}
												</div>
											</td>
											<td className={'copy-time-task-cell'}>{timeReg.task ? timeReg.task.name : ''}</td>
											<td className={'copy-time-hours-cell'}>
												{Math.round((timeReg.minutesRegistered / 60) * 10) / 10 +
													' ' +
													formatMessage({id: 'common.hours.short'})}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</CustomScrollDiv>
				</div>
				<div className={'date-picker-title'}>{this.props.intl.formatMessage({id: 'common.to'}).toUpperCase()}</div>
				<FixedRangePicker
					locked={this.props.timeRegistrations.length === 0}
					rangeType={'day'}
					customBackgroundColor={'#f6f1f6'}
					useCompactStyle={true}
					calendarOffsetX={-84}
					hideLabel={true}
					startDate={this.props.targetDate}
					locale={this.props.language}
					showEmptyDate={true}
					handleDateRangeChange={this.props.handleDateRangeChange}
					cy="new-ui-time-copy-time-reg-modal-date-picker"
				/>
			</div>
		);

		return (
			<GenericModal
				className={'copy-time-modal'}
				closeModal={this.props.closeModal}
				content={content}
				headerText={header}
				cy={this.props.cy}
				buttons={this.props.buttons}
			/>
		);
	}
}
export default injectIntl(CopyTimeModal);
