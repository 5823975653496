import React from 'react';
import {injectIntl} from 'react-intl';
import WarningModal from './warning_modal';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../constants';

const WarningModalLockedTimeRegs = ({closeModal, intl, nonTimeRegWarning}) => {
	const warningInformation = nonTimeRegWarning
		? [
				intl.formatMessage({id: 'warning.time_locked.description.first_line_non_time'}),
				intl.formatMessage({id: 'warning.time_locked.description.first_line_non_time_2'}),
		  ]
		: [intl.formatMessage({id: 'warning.time_locked.description.first_line'})];
	const buttons = [
		{
			text: intl.formatMessage({id: 'common.ok'}),
			style: BUTTON_STYLE.OUTLINE_THICK,
			color: BUTTON_COLOR.VERYLIGHTGREY,
		},
	];

	return (
		<WarningModal
			closeModal={closeModal}
			buttons={buttons}
			warningMessageId={nonTimeRegWarning ? 'warning.time_locked.header_non_time' : 'warning.time_locked.header'}
			warningInformation={warningInformation}
		/>
	);
};

export default injectIntl(WarningModalLockedTimeRegs);
