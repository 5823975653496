import React, {useState} from 'react';
import {BasicNumberInput, Switch, RadioButton} from 'web-components';
import {ESTIMATION_UNIT} from '../../../constants';
import {
	SelectionElementLineWrapper,
	SubSection,
	SprintLenghtWrapper,
	SelectionElementLabel,
	SprintInputWrapper,
	ExtraElementWrapper,
	StoryPointLable,
	HoursInputWrapper,
} from './ProjectSettings_styled';
import {
	changeProjectGroupUseSprint,
	changeProjectGroupSprintLength,
	changeProjectGroupEstimationUnit,
	changeProjectGoupHoursPrStoryPoint,
} from './ProjectSettingsMutations';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';

const ProjectGroupSettingsTasks = ({
	intl,
	projectGroupId,
	useSprint,
	sprintLength,
	estimationUnit,
	hoursPrStoryPoint,
	projectDoneOrHalted,
	isJiraProject,
	isInProjectGroup,
}) => {
	const {formatMessage} = intl;
	const [_sprintLength, setSprintLength] = useState(sprintLength || 14);
	const [_hoursPrStoryPoint, setHoursPrStoryPoint] = useState(hoursPrStoryPoint);

	const handleCchangeSprintLength = value => {
		const roundedValue = Math.round(value);
		setSprintLength(roundedValue);
		changeProjectGroupSprintLength(projectGroupId, roundedValue);
	};

	const handleChangeHoursPrStoryPoint = (hours, _, error) => {
		const minutes = hours * 60;
		setHoursPrStoryPoint(hours);
		if (!isNaN(minutes)) {
			changeProjectGoupHoursPrStoryPoint(projectGroupId, minutes);
		}
	};

	return (
		<>
			{/** Sprint planning */}
			<SubSection
				title={formatMessage({id: 'project_settings.sprint_planning'})}
				description={formatMessage({id: 'project_settings.sprint_planning_desc'})}
			>
				<SelectionElementLineWrapper>
					<Switch
						locked={projectDoneOrHalted || isJiraProject || isInProjectGroup}
						checked={useSprint}
						onChange={value => changeProjectGroupUseSprint(projectGroupId, value)}
					/>
					<SelectionElementLabel>{formatMessage({id: 'common.enable'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
				<SelectionElementLineWrapper>
					<SprintLenghtWrapper>
						{formatMessage({id: 'project_settings.on_sprint_is'})}
						<SprintInputWrapper>
							<BasicNumberInput
								value={_sprintLength}
								callback={days => handleCchangeSprintLength(days)}
								width={'50px'}
								disabled={!useSprint || projectDoneOrHalted || isJiraProject || isInProjectGroup}
								hasError={_sprintLength < 1}
							/>
						</SprintInputWrapper>
						{formatMessage({id: 'common.days'}).toLowerCase()}
					</SprintLenghtWrapper>
				</SelectionElementLineWrapper>
			</SubSection>
			{/** Estimation units */}
			<SubSection
				title={formatMessage({id: 'settings.estimation-title'})}
				description={formatMessage({id: 'settings.estimation_units_title'})}
			>
				<SelectionElementLineWrapper>
					<RadioButton
						checked={estimationUnit === ESTIMATION_UNIT.HOURS}
						onClick={() => changeProjectGroupEstimationUnit(projectGroupId, ESTIMATION_UNIT.HOURS)}
						disabled={projectDoneOrHalted || isJiraProject || isInProjectGroup}
					/>
					<SelectionElementLabel>{formatMessage({id: 'common.hours'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
				<SelectionElementLineWrapper>
					<RadioButton
						checked={estimationUnit === ESTIMATION_UNIT.POINTS}
						onClick={() => changeProjectGroupEstimationUnit(projectGroupId, ESTIMATION_UNIT.POINTS)}
						disabled={projectDoneOrHalted || isJiraProject || isInProjectGroup}
					/>
					<SelectionElementLabel>{formatMessage({id: 'common.points'})}</SelectionElementLabel>
				</SelectionElementLineWrapper>
				{estimationUnit === ESTIMATION_UNIT.POINTS && (
					<ExtraElementWrapper>
						<StoryPointLable>{formatMessage({id: 'settings.estimation-hours-pr-point'})}</StoryPointLable>
						<HoursInputWrapper>
							<HoursInput
								value={_hoursPrStoryPoint}
								mutation={handleChangeHoursPrStoryPoint}
								hasError={isNaN(_hoursPrStoryPoint * 60)}
							></HoursInput>
						</HoursInputWrapper>
					</ExtraElementWrapper>
				)}
			</SubSection>
		</>
	);
};

export default ProjectGroupSettingsTasks;
