import { Checkbox, ComboBox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, Label, SearchField, SubHeading, Text, } from '@forecast-it/design-system';
import React, { useEffect, useState, useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import DirectApi from '../../../directApi';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import ImportSageIntacctTeamMembersMutation from '../../../mutations/ImportSageIntacctTeamMembersMutation';
import { hideLoader, showLoader } from '../../global_loader';
import Util from '../../../forecast-app/shared/util/util';
import { getSelectableProfiles } from '../../../forecast-app/shared/util/PermissionsUtil';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { HorizontalBar } from './SageIntacctModal.styled';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 900 ? '140px' : '500px';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '1px solid #ccc' : 'none')};
`;
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const SageIntacctImportTeamMembersModal = ({ closeModal, company }) => {
    const { formatMessage } = useIntl();
    const [teamMembers, setTeamMembers] = useState([]);
    const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const selectAll = useMemo(() => {
        if (filteredTeamMembers.length === 0 || selectedTeamMembers.length === 0)
            return false;
        return filteredTeamMembers.every(tm => selectedTeamMembers.includes(tm.EMPLOYEEID));
    }, [filteredTeamMembers, selectedTeamMembers]);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [fetchingEmployees, setFetchingEmployees] = useState(true);
    const [failed, setFailed] = useState(false);
    const [importing, setImporting] = useState(false);
    const [assignDepartment, setAssignDepartment] = useState(false);
    const disableImportButton = importing || selectedTeamMembers.length === 0 || !selectedProfileId;
    useEffect(() => {
        var _a;
        if (((_a = company === null || company === void 0 ? void 0 : company.profiles) === null || _a === void 0 ? void 0 : _a.edges) && Array.isArray(company.profiles.edges)) {
            const sortedProfiles = getSelectableProfiles(company.profiles.edges);
            setProfiles(sortedProfiles);
        }
    }, []);
    useEffect(() => {
        DirectApi.Fetch('sage_intacct/employees').then(res => {
            if (res === null || res === void 0 ? void 0 : res.employees) {
                const tempTms = res.employees.map(t => {
                    return Object.assign(Object.assign({}, t), { NAME: `${t.PERSONALINFO__2e__FIRSTNAME} ${t.PERSONALINFO__2e__LASTNAME} ${t.PERSONALINFO__2e__EMAIL1 ? `(${t.PERSONALINFO__2e__EMAIL1})` : ''}` });
                });
                setTeamMembers(tempTms);
                setFilteredTeamMembers(tempTms);
                setFetchingEmployees(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetchingEmployees(false);
            }
            else {
                setTeamMembers([]);
                setFilteredTeamMembers([]);
                setFetchingEmployees(false);
            }
        });
    }, []);
    const selectAllClick = () => {
        if (filteredTeamMembers.length === 0)
            return;
        if (selectedTeamMembers.length === 0) {
            setSelectedTeamMembers(filteredTeamMembers.map(tm => tm.EMPLOYEEID));
        }
        else {
            const allFilteredIds = filteredTeamMembers.map(tm => tm.EMPLOYEEID);
            const isAllFilteredIdsInSelected = allFilteredIds.every(id => selectedTeamMembers.includes(id));
            if (isAllFilteredIdsInSelected) {
                const removedSelectedIds = selectedTeamMembers.filter(id => !allFilteredIds.includes(id));
                setSelectedTeamMembers(removedSelectedIds);
            }
            else {
                const addedNewSelectedIds = selectedTeamMembers.concat(allFilteredIds);
                setSelectedTeamMembers(addedNewSelectedIds);
            }
        }
    };
    const teamMemberClick = (inputId) => {
        if (selectedTeamMembers.includes(inputId)) {
            const selectedUsers = selectedTeamMembers.filter(id => id !== inputId);
            setSelectedTeamMembers(selectedUsers);
        }
        else {
            const selectedUsers = [...selectedTeamMembers];
            selectedUsers.push(inputId);
            setSelectedTeamMembers(selectedUsers);
        }
    };
    const onImportClick = () => {
        setImporting(true);
        showLoader();
        Util.CommitMutation(ImportSageIntacctTeamMembersMutation, {
            sageIntacctEmployeesIds: selectedTeamMembers,
            permissionProfile: selectedProfileId,
            assignDepartment: assignDepartment,
            companyId: company.id,
        }, () => {
            setImporting(false);
            closeModal();
            hideLoader();
            createToast({
                duration: 5000,
                message: `${selectedTeamMembers.length} Team Members imported from Sage Intacct`,
            });
        });
    };
    const onFilterChange = (input) => {
        setFilteredTeamMembers(teamMembers.filter(tm => { var _a; return (_a = tm.NAME) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(input.toLowerCase()); }));
    };
    return (React.createElement(CreativeModal, { title: 'Import Team Members from Sage Intacct', size: 'l', closeModal: closeModal, creativeButtonText: `Import Selected Team Members (${selectedTeamMembers.length})`, cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onImportClick, onCancelHandler: closeModal, disabled: disableImportButton, replicateDesignSystem: true },
        React.createElement(ComboBox, { label: "Permissions", helperText: "Please select the permissions level you wish to assign.", "data-cy": "combobox-profiles", defaultItems: profiles, value: selectedProfileId, onChange: setSelectedProfileId, width: ComboBox.WIDTH.FLEX }, (profile) => (React.createElement(ComboBox.Item, { textValue: profile.name || '' },
            React.createElement(Text, null, profile.name)))),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Label, null, "Team Members"),
            React.createElement(Text, { color: Text.color.medium }, "Please select the team members you wish to import from Sage Intacct"),
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, width: "600px" })),
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: selectAllClick, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null, fetchingEmployees ? (React.createElement(InlineLoader, null)) : (React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
            React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredTeamMembers &&
                filteredTeamMembers.map(tm => {
                    return (React.createElement(ListItem, { key: 'team-member-' + tm.EMPLOYEEID, hasBorderBottom: true },
                        React.createElement(FlexRow, { gap: 'xl' },
                            React.createElement(Checkbox, { onChange: () => teamMemberClick(tm.EMPLOYEEID), checked: selectedTeamMembers.includes(tm.EMPLOYEEID) }),
                            React.createElement(Text, { size: '2' }, tm.NAME))));
                }))))),
        React.createElement(HorizontalBar, null),
        React.createElement(Checkbox, { onChange: setAssignDepartment, checked: assignDepartment, description: 'Assign imported team members to their Sage Intacct departments' })));
};
const sageIntacctImportTeamMembersModalQuery = graphql `
	query SageIntacctImportTeamMembersModal_Query {
		viewer {
			actualPersonId
			component(name: "sage_intacct_import_users_modal")
			company {
				...SageIntacctImportTeamMembersModal_company
			}
		}
	}
`;
export { sageIntacctImportTeamMembersModalQuery };
export default createFragmentContainer(SageIntacctImportTeamMembersModal, {
    company: graphql `
		fragment SageIntacctImportTeamMembersModal_company on Company {
			id
			profiles {
				edges {
					node {
						id
						name
						canBeEdited
						permissions
						disabled
					}
				}
			}
		}
	`,
});
