import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {deletePlaceholderMutation, handleMutationSuccess} from './PlaceholderAllocationUtils';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import InputField from '../../../components/inputs/input_field';
import Styled from 'styled-components';
import WarningIcon from '../../../images/warning_icon';

const PlaceholderModalContent = Styled.div`
	.warning-description {
		padding-top: 20px;
		font-size: 13px;
	}
	.confirm-text {
		padding-top: 59px;
		font-size: 11px;
	}
	
`;

const DeletePlaceholderConfirmationModal = ({closeModal, placeholderId, placeholderAllocations, isFromContextMenu}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const [inputValue, setInputValue] = useState(null);
	const disableDelete = inputValue !== 'confirm';

	const handleInputChange = value => {
		setInputValue(value);
	};

	const content = (
		<PlaceholderModalContent>
			<ModalBody>
				<Field>
					<div className="warning-description">
						{formatMessage({id: 'placeholder.delete_placeholder_modal.description'})}
					</div>
				</Field>
				<Field>
					<div className="confirm-text">
						{formatMessage({id: 'placeholder.delete_placeholder_modal.confirm_text'})}
					</div>
					<InputField value={inputValue} onChange={handleInputChange} type="text" autoFocus={true} />
				</Field>
			</ModalBody>
		</PlaceholderModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={
				<>
					<WarningIcon danger={true} />
					<div>{formatMessage({id: 'placeholder.delete_placeholder_modal.header_text'})}</div>
				</>
			}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					callback: closeModal,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: () => {
						deletePlaceholderMutation(
							formatMessage,
							placeholderId,
							handleMutationSuccess,
							placeholderAllocations,
							isFromContextMenu
						);
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					disabled: disableDelete,
				},
			]}
		/>
	);
};

export default DeletePlaceholderConfirmationModal;
