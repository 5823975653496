import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import {Button as WCButton} from 'web-components';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../constants';
import GenericTooltip from '../../forecast-app/shared/components/tooltips/generic_tooltip';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {HelpIcon} from 'web-components/icons';

export const MODAL_WIDTH = {
	SMALL: 500,
	MEDIUM: 700,
	MEDIUM_LARGE: 750,
	LARGE: 1000,
};
class GenericModal extends Component {
	constructor(props) {
		super(props);

		this.resize = this.resize.bind(this);

		this.state = {};
	}
	componentDidMount() {
		if (this.props.useScrollbars) {
			this.resize();
			window.addEventListener('resize', this.resize);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.useScrollbars) {
			this.resize();
		}
	}

	componentWillUnmount() {
		if (this.props.useScrollbars) {
			window.removeEventListener('resize', this.resize);
		}
	}

	resize() {
		const element = this.innerContent;
		if (element === null || element === undefined) return;
		if (window.innerHeight - element.scrollHeight < 100) {
			if (this.state.height !== window.innerHeight - 100) {
				this.setState({height: window.innerHeight - 100});
			}
		} else if (this.state.height !== undefined) {
			this.setState({height: undefined});
		}
	}

	callback(callback, preventDefaultClose) {
		callback();
		if (preventDefaultClose) return;
		this.props.closeModal();
	}

	defaultCallback(preventClickAwayClose) {
		if (this.props.defaultCallback) {
			this.props.defaultCallback();
		}
		if (preventClickAwayClose) return;
		this.props.closeModal();
	}

	getModalButton(button, index) {
		const buttonComponent = (
			<Button
				id={button.id}
				data-cy={button.cy}
				isDisabled={button.disabled}
				key={`tertiary-generic-modal-button-${index}`}
				text={button.text}
				cy={button.cy}
				buttonStyle={button.style || BUTTON_STYLE.FILLED}
				colorTheme={button.color || BUTTON_COLOR.GREEN}
				onClick={
					button.callback
						? this.callback.bind(this, button.callback, button.preventDefaultClose)
						: this.defaultCallback.bind(this, false)
				}
				autoScheduleStyle={button.autoScheduleStyle}
				className={button.className}
				userpilot={button.userpilot}
				symbolClass={button.symbolClass}
				symbolSize={button.symbolSize}
			/>
		);
		if (button.tooltipText) {
			return <ForecastTooltip content={button.tooltipText}>{buttonComponent}</ForecastTooltip>;
		}
		return buttonComponent;
	}

	render() {
		const {
			buttons,
			tertiaryButtons,
			headerText,
			coverImage,
			headerInfo,
			headerTooltipWidth,
			headerButtons,
			content,
			footer,
			leftContent,
			rightContent,
			className,
			wrapperClassName,
			actionsMenuOptions,
			id,
			isBetaFeature,
			isPremiumFeature,
		} = this.props;

		const buttonContainer =
			buttons && buttons.length ? (
				<div className="button-wrapper">
					{tertiaryButtons &&
						tertiaryButtons.map((button, index) => {
							return this.getModalButton(button, index);
						})}
					<div className="button-container">
						{buttons.map((button, index) => {
							return this.getModalButton(button, index);
						})}
					</div>
				</div>
			) : null;
		return (
			<div
				style={this.props.top ? {zIndex: 1000} : null}
				className={`generic-modal ${wrapperClassName || ''}`}
				onMouseDown={this.defaultCallback.bind(this, this.props.preventClickAwayClose)}
				data-cy={this.props.cy}
				ref={this.props.modalRef}
				data-userpilot={this.props.userPilot}
			>
				<div
					id={id}
					className={`generic-modal-content ${className || ''} ${
						leftContent || rightContent ? 'has-side-content' : ''
					}`}
					onMouseDown={e => {
						e.stopPropagation();
					}}
					ref={e => (this.modalContent = e)}
					style={{width: this.props.modalWidth ? this.props.modalWidth : null}}
				>
					{leftContent ? <div className={'side-content-container'}>{leftContent}</div> : null}
					<div
						className={
							'content-container' +
							(this.props.useScrollbars ? ' useScrollbars' : '') +
							(this.props.overflowVisible ? ' overflow-visible' : ' ')
						}
					>
						<button
							className="close-button"
							onClick={this.defaultCallback.bind(this, false)}
							data-cy={`${this.props.cy}-close-button`}
						/>
						{coverImage ? <div className="cover-image">{coverImage}</div> : null}
						{headerText ? (
							<div
								className={'header-container' + (headerButtons ? ' with-header-buttons' : '')}
								style={{marginRight: this.props.useScrollbars ? 60 : undefined}}
							>
								<h1
									className={`header-text ${headerInfo ? 'info-icon-header' : ''} ${
										actionsMenuOptions ? 'action-menu-present' : ''
									}`}
								>
									{headerText}
									{headerInfo ? (
										<div style={{marginLeft: '8px', display: 'flex', alignItems: 'center'}}>
											<ForecastTooltip
												content={headerInfo}
												delay={[100, 100]}
												maxWidth={headerTooltipWidth || 350}
												onShow={() => this.props.onInfoIconShow && this.props.onInfoIconShow()}
											>
												<HelpIcon size={'large'} />
											</ForecastTooltip>
										</div>
									) : null}
									{isBetaFeature ? <GenericTooltip type="beta" key={'beta-tooltip'} showOnRight /> : null}
									{isPremiumFeature ? (
										<GenericTooltip type="premium" key={'premium-tooltip'} showOnRight />
									) : null}
								</h1>
								{actionsMenuOptions ? (
									<div className="actions-menu-container">
										<ActionsMenu cy={'expense'} whiteInner options={actionsMenuOptions} />
									</div>
								) : null}
								{headerButtons ? (
									<div className={'header-button-container'}>
										{headerButtons.map((button, index) => {
											return (
												<WCButton
													key={`generic-modal-header-button-${index}`}
													disabled={button.disabled}
													children={button.text}
													variant={button.variant || WCButton.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
													onClick={
														button.callback
															? this.callback.bind(
																	this,
																	button.callback,
																	button.preventDefaultClose
															  )
															: this.defaultCallback.bind(this, false)
													}
													cy={button.cy}
													userpilot={button.userpilot}
												/>
											);
										})}
									</div>
								) : null}
							</div>
						) : null}
						{content || footer ? (
							this.props.useScrollbars ? (
								<Scrollbars
									//This ref is used in new project modal
									ref={e => (this.scrollableContainer = e)}
									className={'custom-scrollbar-div'}
									hideTracksWhenNotNeeded={true}
									autoHide
									autoHeight={true}
									autoHeightMax={1000}
									autoHeightMin={200}
									style={{
										height: this.state.height,
										width: this.props.contentWidth ? this.props.contentWidth + 'px' : 'auto',
									}}
									renderTrackHorizontal={props => <div {...props} className="track track-horizontal" />}
									renderTrackVertical={props => <div {...props} className="track track-vertical" />}
									renderThumbHorizontal={props => <div {...props} className="thumb thumb-horizontal" />}
									renderThumbVertical={props => <div {...props} className="thumb thumb-vertical" />}
								>
									<div ref={e => (this.innerContent = e)}>
										{content}
										{buttonContainer}
										{footer}
									</div>
								</Scrollbars>
							) : (
								content
							)
						) : null}
						{this.props.useScrollbars ? null : buttonContainer}
						{this.props.useScrollbars ? null : footer}
					</div>
					{rightContent ? <div className={'side-content-container'}>{rightContent}</div> : null}
				</div>
			</div>
		);
	}
}

GenericModal.propTypes = {
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
			callback: PropTypes.func,
			style: PropTypes.oneOf([BUTTON_STYLE.FILLED, BUTTON_STYLE.OUTLINE]),
			color: PropTypes.oneOf([
				BUTTON_COLOR.GREEN,
				BUTTON_COLOR.GREY,
				BUTTON_COLOR.GREEN,
				BUTTON_COLOR.RED,
				BUTTON_COLOR.LIGHTGREY,
				BUTTON_COLOR.MEDIUMGREY,
				BUTTON_COLOR.FADEDGRAY,
				BUTTON_COLOR.WHITE,
				BUTTON_COLOR.WHITE_ACTIVE,
				BUTTON_COLOR.AI_BLUE,
				BUTTON_COLOR.GREEN_APPROVE,
			]),
			cy: PropTypes.string,
			disabled: PropTypes.bool,
			preventDefaultClose: PropTypes.bool,
			className: PropTypes.string,
			tooltipText: PropTypes.string,
		}).isRequired
	),
	headerButtons: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
			callback: PropTypes.func,
			cy: PropTypes.string,
			disabled: PropTypes.bool,
			preventDefaultClose: PropTypes.bool,
		}).isRequired
	),
	headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	content: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	defaultCallback: PropTypes.func,
	closeModal: PropTypes.func.isRequired,
	wrapperClassName: PropTypes.string,
	headerInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
	id: PropTypes.string,
	cy: PropTypes.string,
	actionsMenuOptions: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	),
	useScrollbars: PropTypes.bool,
	modalWidth: PropTypes.oneOfType([PropTypes.oneOf(Object.values(MODAL_WIDTH)), PropTypes.number]),
	leftContent: PropTypes.element,
	rightContent: PropTypes.element,
};

export default GenericModal;
