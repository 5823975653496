import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {ForecastTooltipFormulaRenderer} from '../../../../../components/ForecastTooltipFormulaRenderer';
import GenericModal from '../../../../modal/generic_modal';
import * as tracking from '../../../../../tracking';
import {FocusSubTitle, SubTitleStatValue} from './FocusModal.styled';
import {formatPersonData, getResourceBasedPersonsMap} from './FocusModalLogic';
import {FocusModalTable} from './FocusModalTable';
import {trackEvent, trackTooltip} from '../../../../../tracking/amplitude/TrackingV2';

const FocusModal = ({viewer, focus, warningInfo, warningVariant, closeModal}) => {
	const intl = useIntl();
	const {project} = viewer;
	const trackingInfo = {projectId: project.id, projectName: project.name};

	useEffect(() => {
		tracking.trackPageAction('Focus Modal shown', trackingInfo);
		trackEvent('Focus Modal', 'Shown');
	}, []);

	const persons = project.projectPersons.edges.map(edge => edge.node.person);
	const resourceBasedPersonsMap = getResourceBasedPersonsMap(viewer.company.resourceBasedPersons.edges);
	const data = persons
		.filter(person => !!resourceBasedPersonsMap.get(person.id) || person.totalMinutesRegistered > 0) // Only show persons with allocation or time registered in the period
		.map(person => formatPersonData(person, resourceBasedPersonsMap.get(person.id), intl));

	const teamMinutesRegistered = data.reduce((acc, person) => acc + person.timeRegisteredRaw, 0);
	const teamAllocation = data.reduce((acc, person) => acc + person.timeAllocatedRaw, 0);
	const teamData = {id: 'team', fullName: 'Team', totalMinutesRegistered: teamMinutesRegistered, isHeader: true};
	const teamHeader = formatPersonData(teamData, teamAllocation, intl);

	const getContent = () => {
		return (
			<>
				<FocusSubTitle>
					{warningInfo}. The project team had an average
					<SubTitleStatValue color={warningVariant.color}>{` focus of ${focus} `}</SubTitleStatValue>
					in the previous 30 days.
				</FocusSubTitle>
				<FocusModalTable data={data} header={teamHeader} />
			</>
		);
	};

	const tooltipContent = () => {
		return (
			<ForecastTooltipFormulaRenderer
				items={[
					{
						description:
							'Here you see how much time your team spent on the project over the last 30 days, compared to allocations.',
					},
					{
						description: `When a team member's time registrations match the allocation, their focus is 100%.`,
					},
					{
						title: 'Team focus is calculated as',
						details: ['Time Registered', '/', 'Allocated time'],
					},
				]}
				translatedMessage={true}
			/>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			content={getContent()}
			headerText={'Team focus'}
			headerInfo={tooltipContent()}
			onInfoIconShow={() => {
				tracking.trackInfoTooltipShown('Focus modal');
				trackTooltip('Focus Modal Info');
			}}
			modalWidth={900}
		/>
	);
};

const FocusModalQuery = graphql`
	query FocusModalModal_Query(
		$projectId: ID
		$resourceSearchQuery: TaskSearchQueryType
		$timeRegSearchQuery: TaskSearchQueryType
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			component(name: "focus_modal")
			actualPersonId
			id
			...FocusModal_viewer
				@arguments(
					projectId: $projectId
					resourceSearchQuery: $resourceSearchQuery
					timeRegSearchQuery: $timeRegSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export {FocusModalQuery};

export default createFragmentContainer(FocusModal, {
	viewer: graphql`
		fragment FocusModal_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID"}
			resourceSearchQuery: {type: "TaskSearchQueryType"}
			timeRegSearchQuery: {type: "TaskSearchQueryType"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
		) {
			company {
				resourceBasedPersons(
					first: 10000000
					searchQuery: $resourceSearchQuery
					onlyProjectData: true
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					edges {
						node {
							id
							resourceNumbers {
								plannedTotalMinutes
							}
						}
					}
				}
			}
			project(internalId: $projectId) {
				id
				name
				projectPersons {
					edges {
						node {
							id
							person {
								id
								fullName
								profilePictureId
								profilePictureDefaultId
								totalMinutesRegistered(searchQuery: $timeRegSearchQuery)
							}
						}
					}
				}
			}
		}
	`,
});
