/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type InvoiceFilterButton_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingTableSection_viewer$ref: FragmentReference;
declare export opaque type ProjectInvoicingTableSection_viewer$fragmentType: ProjectInvoicingTableSection_viewer$ref;
export type ProjectInvoicingTableSection_viewer = {|
  +$fragmentRefs: InvoiceFilterButton_viewer$ref,
  +$refType: ProjectInvoicingTableSection_viewer$ref,
|};
export type ProjectInvoicingTableSection_viewer$data = ProjectInvoicingTableSection_viewer;
export type ProjectInvoicingTableSection_viewer$key = {
  +$data?: ProjectInvoicingTableSection_viewer$data,
  +$fragmentRefs: ProjectInvoicingTableSection_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectInvoicingTableSection_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceFilterButton_viewer"
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '16c70b4d8388537ee07f5990b272d857';

module.exports = node;
