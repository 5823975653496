import React from 'react';
import Util from '../../../../../forecast-app/shared/util/util';
import {StatContainer, StatsContainer, StatTitle, StatValue} from './ForecastSurplusModal.styled';

export const ForecastSurplusModalStatSection = ({currencySymbol, fixedPrice, forecastRevenue, variant, intl}) => {
	const surplus = fixedPrice - forecastRevenue;
	return (
		<StatsContainer>
			<StatContainer>
				<StatValue>{Util.getFormattedNumberWithCurrency(currencySymbol, fixedPrice, intl)}</StatValue>
				<StatTitle>Fixed Price Budget</StatTitle>
			</StatContainer>
			<StatContainer>
				<StatValue>{Util.getFormattedNumberWithCurrency(currencySymbol, forecastRevenue, intl)}</StatValue>
				<StatTitle>Forecast total billable time and expenses at completion</StatTitle>
			</StatContainer>
			<StatContainer>
				<StatValue color={variant.color}>
					{Util.getFormattedNumberWithCurrency(currencySymbol, surplus, intl)}
				</StatValue>
				<StatTitle>Variance</StatTitle>
			</StatContainer>
		</StatsContainer>
	);
};
