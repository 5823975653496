import React from 'react';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import PerformanceModal, {PerformanceModalQuery} from './PerformanceModal';

const PerformanceModalRelayWrapper = props => {
	return (
		<ForecastQueryRenderer
			key="query-render-performance-modal"
			query={PerformanceModalQuery}
			modal={true}
			variables={{projectId: props.projectId}}
			render={relayProps => {
				return <PerformanceModal {...relayProps} {...props} />;
			}}
		/>
	);
};

export default PerformanceModalRelayWrapper;
