/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type controlPersonList_viewer$ref = any;
export type controlPersonList_QueryVariables = {|
  searchString?: ?string
|};
export type controlPersonList_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: controlPersonList_viewer$ref,
  |}
|};
export type controlPersonList_Query = {|
  variables: controlPersonList_QueryVariables,
  response: controlPersonList_QueryResponse,
|};
*/


/*
query controlPersonList_Query(
  $searchString: String
) {
  viewer {
    actualPersonId
    component(name: "control_person_list")
    ...controlPersonList_viewer_4ES8AM
    id
  }
}

fragment controlPersonList_viewer_4ES8AM on Viewer {
  id
  ...missionControlHeader_viewer
  missionControlData {
    id
    personList(searchString: $searchString) {
      id
      personId
      companyId
      companyName
      accountPlan
      firstName
      lastName
      email
      seatType
      active
      profileName
    }
  }
}

fragment missionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "control_person_list"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"control_person_list\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "searchString",
    "variableName": "searchString"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "controlPersonList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "controlPersonList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "controlPersonList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "MissionControlPersonListType",
                "kind": "LinkedField",
                "name": "personList",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountPlan",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seatType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "controlPersonList_Query",
    "operationKind": "query",
    "text": "query controlPersonList_Query(\n  $searchString: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"control_person_list\")\n    ...controlPersonList_viewer_4ES8AM\n    id\n  }\n}\n\nfragment controlPersonList_viewer_4ES8AM on Viewer {\n  id\n  ...missionControlHeader_viewer\n  missionControlData {\n    id\n    personList(searchString: $searchString) {\n      id\n      personId\n      companyId\n      companyName\n      accountPlan\n      firstName\n      lastName\n      email\n      seatType\n      active\n      profileName\n    }\n  }\n}\n\nfragment missionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8a730bcb2d939e92ac4eac53ae68db2';

module.exports = node;
