import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import moment from 'moment';
import {decompressAggregatedData} from '../../../../../components/new-ui/project/project-budget-v3/util/DataDecompressUtil';
import Util from '../../../../../forecast-app/shared/util/util';
import {ChartContainer} from './ForecastSurplusModal.styled';
import * as tracking from '../../../../../tracking';
import {ForecastSurplusModalChart} from './ForecastSurplusModalChart';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const ForecastSurplusModalChartSection = ({project, fixedPrice, currencySymbol}) => {
	const projectFirstDate =
		Util.CreateNonUtcMomentDate(project.projectFirstDateYear, project.projectFirstDateMonth, project.projectFirstDateDay) ||
		moment();
	const projectLastDate =
		Util.CreateNonUtcMomentDate(project.projectLastDateYear, project.projectLastDateMonth, project.projectLastDateDay) ||
		moment().add(1, 'days');

	const aggregatedFinancialNumbers = decompressAggregatedData(project.aggregatedFinancialNumbers, [
		'actualRevenue',
		'forecastRevenue',
	]);

	const today = moment();

	const data = {
		actualRevenueChartData: [],
		forecastRevenueChartData: [],
		fixedPriceChartData: [],
		revenueMarginLowData: [],
		revenueMarginHighData: [],
		todayIntersection: [],
	};

	const revenueMarginLowValue = fixedPrice * project.revenueMarginLow;
	const revenueMarginHighValue = fixedPrice * project.revenueMarginHigh;

	aggregatedFinancialNumbers.forEach(financialNumbers => {
		const momentDate = financialNumbers.date.clone();
		const isYesterday = momentDate.clone().add(1, 'days').isSame(today, 'days');
		const isToday = momentDate.isSame(today, 'days');
		const isFuture = momentDate.isAfter(today, 'days');

		data.actualRevenueChartData.push({
			date: momentDate,
			value: isFuture || isToday ? null : financialNumbers.actualRevenueAccumulated,
		});

		if (isYesterday) {
			data.forecastRevenueChartData.push({
				date: momentDate,
				value: financialNumbers.actualRevenueAccumulated,
				customData: {
					hideLabel: true,
				},
			});
		} else {
			data.forecastRevenueChartData.push({
				date: momentDate,
				value: isToday || isFuture ? financialNumbers.forecastRevenueAccumulated : null,
			});
		}

		data.todayIntersection.push({
			date: momentDate,
			value: isToday ? financialNumbers.forecastRevenueAccumulated : null,
			customData: {
				hideLabel: true,
			},
		});
		data.fixedPriceChartData.push({date: momentDate, value: fixedPrice});
		data.revenueMarginLowData.push({date: momentDate, value: revenueMarginLowValue});
		data.revenueMarginHighData.push({date: momentDate, value: revenueMarginHighValue});
	});

	return (
		<ChartContainer
			onMouseEnter={() => {
				tracking.trackElementHovered('Budget modal chart');
				trackEvent('Budget Modal Chart', 'Hovered');
			}}
		>
			<ForecastSurplusModalChart
				data={data}
				firstDate={projectFirstDate}
				lastDate={projectLastDate}
				currencySymbol={currencySymbol}
			/>
		</ChartContainer>
	);
};

export default createFragmentContainer(ForecastSurplusModalChartSection, {
	project: graphql`
		fragment ForecastSurplusModalChartSection_project on ProjectType {
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			revenueMarginLow
			revenueMarginHigh
			aggregatedFinancialNumbers(
				aggregates: [actualRevenue, forecastRevenue]
				aggregateLevel: DAY
				addAccumulatedNumbers: true
				convertToProjectCurrency: true
			)
		}
	`,
});
