import {useIntl} from 'react-intl';
import {Checkbox, Table} from 'web-components';
import React, {useMemo} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {personIsVirtualUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeleteSkillMutation from '../../../mutations/delete_skill_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {DeactivatedUsers} from './SkillRow.styled';
import * as tracking from '../../../tracking';
import {ActionMenuWrapper} from './TableRow.styled';
import SkillLevelRow from './SkillLevelRow';
import SkillAssignees from './SkillAssignees';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {profilePicSrc} from '../../../directApi';

const NO_LEVEL = {id: undefined};
const SkillRow = ({company, tableColumnsProps, nextLevelProps, checked, toggleSelect, skill, expanded, cy}) => {
	const {formatMessage} = useIntl();

	const {skillLevelsEnabled} = company;

	const skillLevels = useMemo(() => [...company.skillLevels, NO_LEVEL], [company.skillLevels]);

	function openSkill(e, skillLevel) {
		tracking.trackEvent('skills - skill selected');
		trackEvent('Skill', 'Opened');
		showModal({
			type: MODAL_TYPE.UPDATE_SKILL,
			skillId: skill.id,
			skillLevel: skillLevel && (skillLevel === NO_LEVEL ? 'no_level' : skillLevel.id),
		});
	}

	function getActionsMenuOptions() {
		return [
			{
				text: formatMessage({id: 'settings_skills.delete_skill'}),
				cy: 'delete',
				onClick: () => {
					showModal({
						type: MODAL_TYPE.CONFIRM_SKILL_DELETION,
						title: formatMessage({id: 'settings_skills.delete_title'}, {name: skill.name}),
						warningMessage: formatMessage({id: 'settings_skills.delete_warning'}),
						confirmText: formatMessage({id: 'settings.confirm'}),
						onDelete: () => {
							Util.CommitMutation(
								DeleteSkillMutation,
								{
									id: skill.id,
									companyId: company.id,
								},
								() => {
									createToast({
										duration: 5000,
										message: formatMessage({id: 'settings_skills.delete_toast'}, {name: skill.name}),
									});
								}
							);
						},
					});
				},
			},
		].filter(Boolean);
	}

	const persons = skill.skillPersons.map(skillPerson => {
		const person = skillPerson.person;
		return {
			...person,
			levelId: skillPerson.level?.id,
			imageSource: profilePicSrc(person.profilePictureId),
		};
	});

	return (
		<>
			<Table.Row
				hoverStyle={{visibility: 'visible'}}
				cy={cy}
				onClick={skillLevelsEnabled ? undefined : openSkill}
				canExpandRow={skillLevelsEnabled}
				{...tableColumnsProps}
			>
				<Table.Column>
					<Table.Column.Cell onClick={openSkill}>
						<Checkbox checked={checked} onClick={toggleSelect} cy={'checkbox'} />
						<span>{skill.name}</span>
					</Table.Column.Cell>
				</Table.Column>
				<Table.Column>
					<Table.Column.Cell>
						<SkillAssignees assignees={persons?.filter(person => person.active && !personIsVirtualUser(person))} />
					</Table.Column.Cell>
				</Table.Column>
				<Table.Column>
					<Table.Column.Cell>
						<SkillAssignees assignees={persons?.filter(person => person.active && personIsVirtualUser(person))} />
					</Table.Column.Cell>
				</Table.Column>
				<Table.Column>
					<DeactivatedUsers>
						<SkillAssignees assignees={persons?.filter(person => !person.active)} />
					</DeactivatedUsers>
				</Table.Column>
				<Table.Column>
					<Table.Column.Cell>
						<ActionMenuWrapper
							cy={'skill'}
							className={'row-hover'}
							whiteInner={true}
							options={getActionsMenuOptions()}
						/>
					</Table.Column.Cell>
				</Table.Column>
			</Table.Row>
			{expanded && skillLevelsEnabled && (
				<Table.Rows
					data={{
						rows: skillLevels,
					}}
					{...nextLevelProps}
				>
					{({rowData: skillLevel, tableColumnsProps}) => (
						<SkillLevelRow
							cy={`${cy}-skill-level-${skillLevel.level}`}
							tableColumnsProps={tableColumnsProps}
							skillLevel={skillLevel === NO_LEVEL ? null : skillLevel}
							persons={persons.filter(person => person.levelId === skillLevel.id)}
							onClick={e => openSkill(e, skillLevel)}
						/>
					)}
				</Table.Rows>
			)}
		</>
	);
};

export default createFragmentContainer(SkillRow, {
	company: graphql`
		fragment SkillRow_company on Company {
			id
			skillLevelsEnabled
			skillLevels {
				id
				level
				...SkillLevelRow_skillLevel
			}
		}
	`,
	skill: graphql`
		fragment SkillRow_skill on Skill {
			id
			name
			skillPersons {
				person {
					id
					fullName
					active
					permissions
					profilePictureId
				}
				level {
					id
				}
			}
		}
	`,
});
