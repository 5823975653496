/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PersonSkillModal_company$ref = any;
export type PersonSkillModalQueryVariables = {|
  personId?: ?string
|};
export type PersonSkillModalQueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +company: ?{|
      +$fragmentRefs: PersonSkillModal_company$ref
    |},
  |}
|};
export type PersonSkillModalQuery = {|
  variables: PersonSkillModalQueryVariables,
  response: PersonSkillModalQueryResponse,
|};
*/


/*
query PersonSkillModalQuery(
  $personId: ID
) {
  viewer {
    actualPersonId
    component(name: "person_skills_modal")
    company {
      ...PersonSkillModal_company_3WrWRM
      id
    }
    id
  }
}

fragment PersonSkillModal_company_3WrWRM on Company {
  skillLevelsEnabled
  ...SkillsPreviewSelector_company
  person(id: $personId) {
    id
    fullName
    skillPersons {
      skill {
        id
      }
      level {
        id
        description
        level
      }
      id
    }
  }
}

fragment SkillDropdown_company on Company {
  skillLevelsEnabled
  skillLevels {
    id
    level
    description
  }
  skills(first: 10000) {
    edges {
      node {
        id
        name
        category {
          id
          name
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  skillCategories(first: 10000) {
    edges {
      node {
        id
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment SkillsPreviewSelector_company on Company {
  skillLevelsEnabled
  ...SkillDropdown_company
  skills(first: 10000) {
    edges {
      node {
        id
        name
        category {
          id
          name
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  skillLevels {
    id
    level
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "person_skills_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"person_skills_modal\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonSkillModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "personId",
                    "variableName": "personId"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "PersonSkillModal_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonSkillModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "skillLevelsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLevel",
                "kind": "LinkedField",
                "name": "skillLevels",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "SkillTypeConnection",
                "kind": "LinkedField",
                "name": "skills",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillCategory",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "skills(first:10000)"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Company_skills",
                "kind": "LinkedHandle",
                "name": "skills"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "SkillCategoryTypeConnection",
                "kind": "LinkedField",
                "name": "skillCategories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillCategoryTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillCategory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "skillCategories(first:10000)"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Company_skillCategories",
                "kind": "LinkedHandle",
                "name": "skillCategories"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "personId"
                  }
                ],
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skill",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillLevel",
                        "kind": "LinkedField",
                        "name": "level",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PersonSkillModalQuery",
    "operationKind": "query",
    "text": "query PersonSkillModalQuery(\n  $personId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"person_skills_modal\")\n    company {\n      ...PersonSkillModal_company_3WrWRM\n      id\n    }\n    id\n  }\n}\n\nfragment PersonSkillModal_company_3WrWRM on Company {\n  skillLevelsEnabled\n  ...SkillsPreviewSelector_company\n  person(id: $personId) {\n    id\n    fullName\n    skillPersons {\n      skill {\n        id\n      }\n      level {\n        id\n        description\n        level\n      }\n      id\n    }\n  }\n}\n\nfragment SkillDropdown_company on Company {\n  skillLevelsEnabled\n  skillLevels {\n    id\n    level\n    description\n  }\n  skills(first: 10000) {\n    edges {\n      node {\n        id\n        name\n        category {\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  skillCategories(first: 10000) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SkillsPreviewSelector_company on Company {\n  skillLevelsEnabled\n  ...SkillDropdown_company\n  skills(first: 10000) {\n    edges {\n      node {\n        id\n        name\n        category {\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  skillLevels {\n    id\n    level\n    description\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cbc200ee0484730cf29936ecf6c845f';

module.exports = node;
