import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import PlaceholderInitials from '../../../../../forecast-app/shared/components/placeholder/placeholder_initials';
import {DeprecatedScrollbar as Scrollbar, DeprecatedText as Text} from '@forecast-it/design-system';
import Util from '../../../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import Nova02Image from '../../../../../images/graphics/Nova02Image';

const AllocationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: ${props => (props.noMargin ? '0px' : '16px')};
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding-left: 16px;
	width: 100%;
	height: 300px;
`;

const CenterText = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-right: 16px;
	gap: 24px;
`;

const TableHeader = styled.div`
	display: flex;
	padding: 8px 0;
	margin-right: 8px;
	justify-content: space-between;
	font-size: 9px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	border-bottom: 1px solid ${CSS_CONSTANTS.v2_border_gray};
`;

const AllocationRow = styled.div`
	height: 49px;
	display: flex;
	border-bottom: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	font-size: 13px;
	padding: 8px 0;
	justify-content: space-between;
	&:last-child {
		border: none;
	}
`;

const InitialsRoleWrapper = styled.div`
	display: flex;
`;

const RoleSubtextWrapper = styled.div`
	margin-left: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const RoleName = styled.div`
	display: flex;
	align-items: center;
	line-break: anywhere;
`;

const Subtext = styled.div`
	margin-top: 4px;
	font-size: 11px;
	color: #a1a1a1;
	font-style: italic;
`;

const HourField = styled.div`
	display: flex;
	align-items: center;
	margin-left: 8px;
	white-space: nowrap;
	font-weight: 500;
`;

const isSymbol = char => char.toUpperCase() === char.toLowerCase();

const getFirstLetter = name => {
	const firstName = name.split(' ').find(n => n !== '');
	let firstLetter = firstName[0];

	if (isSymbol(firstLetter)) {
		for (const character of firstName) {
			if (!isSymbol(character)) {
				firstLetter = character;
				break;
			}
		}
	}

	return firstLetter;
};

const AllocationContainer = ({allocationAssistanceRoles, noOutstandingPlaceholders}) => {
	const intl = useIntl();

	return (
		<AllocationWrapper noMargin={noOutstandingPlaceholders}>
			{noOutstandingPlaceholders ? (
				<CenterText>
					<Text type={'heading'} variant={'s'}>
						Placeholders exist that match the resource demand to meet the deadline!
					</Text>
					<Text type={'base'} variant={'regular'}>
						Assign people to the placeholders to update the predicted end date.
					</Text>
					<Nova02Image height={120} />
				</CenterText>
			) : (
				<>
					<TableHeader>
						<div>Role</div>
						<div>Missing Allocation</div>
					</TableHeader>
					<Scrollbar maxHeight={'272px'}>
						<div style={{paddingRight: '16px'}}>
							{allocationAssistanceRoles.map(allocationAssistanceRole => {
								const roleName = allocationAssistanceRole.role
									? allocationAssistanceRole.role.name
									: 'Unassigned';
								return (
									<AllocationRow key={allocationAssistanceRole.role?.id || 'Unassigned'}>
										<InitialsRoleWrapper>
											{allocationAssistanceRole.role ? (
												<PlaceholderInitials initials={getFirstLetter(roleName)} />
											) : null}
											<RoleSubtextWrapper>
												<RoleName>{roleName}</RoleName>
												{!allocationAssistanceRole.role ? (
													<Subtext>Placeholders will not be created for unassigned work</Subtext>
												) : null}
											</RoleSubtextWrapper>
										</InitialsRoleWrapper>
										<HourField>
											{Util.convertMinutesToFullHour(allocationAssistanceRole.missingAllocation, intl)}
										</HourField>
									</AllocationRow>
								);
							})}
						</div>
					</Scrollbar>
				</>
			)}
		</AllocationWrapper>
	);
};

export default AllocationContainer;
