import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import CheckBox from '../../components/new-ui/check_box';
import {BUTTON_COLOR} from '../../constants';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import UpdateRepeatingTaskMutation from '../../mutations/update_repeating_task_mutation';
import CreateRepeatingTaskMutation from '../../mutations/create_repeating_task_mutation';
import Util from '../../forecast-app/shared/util/util';

class taskRecurrenceModal extends Component {
	constructor(props) {
		super(props);

		const weekDays = {};

		// if updating a task, get the current weekly interval
		if (this.props.repeatingTask) {
			weekDays.monday = this.props.repeatingTask.monday || false;
			weekDays.tuesday = this.props.repeatingTask.tuesday || false;
			weekDays.wednesday = this.props.repeatingTask.wednesday || false;
			weekDays.thursday = this.props.repeatingTask.thursday || false;
			weekDays.friday = this.props.repeatingTask.friday || false;
			weekDays.saturday = this.props.repeatingTask.saturday || false;
			weekDays.sunday = this.props.repeatingTask.sunday || false;
		} else {
			weekDays.monday = false;
			weekDays.tuesday = false;
			weekDays.wednesday = false;
			weekDays.thursday = false;
			weekDays.friday = false;
			weekDays.saturday = false;
			weekDays.sunday = false;
		}

		this.state = {
			isWeeklyTabSelected: this.props.repeatingTask ? this.props.repeatingTask.repeatType === 'WEEKLY' : true,
			monthlyDay: this.props.repeatingTask ? this.props.repeatingTask.monthlyDay : undefined,
			weekDays,
		};
	}

	handleWeekDaySelect(day) {
		this.setState({weekDays: {...this.state.weekDays, [day]: !this.state.weekDays[day]}});
	}

	handleMonthDaySelect(day) {
		this.setState({monthlyDay: day ? day.value : 0});
	}

	// update the task based on the selected dates
	updateTask() {
		if (this.state.isWeeklyTabSelected) {
			const data = {
				id: this.props.repeatingTask.id,
				taskId: this.props.taskId,
				...this.state.weekDays,
				repeatType: 'WEEKLY',
			};
			Util.CommitMutation(UpdateRepeatingTaskMutation, data);
		} else {
			const data = {
				id: this.props.repeatingTask.id,
				taskId: this.props.taskId,
				monthlyDay: this.state.monthlyDay,
				repeatType: 'MONTHLY',
			};
			Util.CommitMutation(UpdateRepeatingTaskMutation, data);
		}
	}

	//  create recurrent task if there are not available recurring dates
	createRepeatingTask() {
		const onSuccess = () => {
			this.setState({openAccordion: 'recurring'});
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'task_modal.has-been-set-to-repeating'})});
		};

		if (this.state.isWeeklyTabSelected) {
			Util.CommitMutation(
				CreateRepeatingTaskMutation,
				{
					globalTaskId: this.props.taskId,
					repeatType: 'WEEKLY',
					...this.state.weekDays,
					monthlyDay: 1,
				},
				onSuccess
			);
		} else {
			Util.CommitMutation(
				CreateRepeatingTaskMutation,
				{
					globalTaskId: this.props.taskId,
					repeatType: 'MONTHLY',
					...this.state.weekDays,
					monthlyDay: this.state.monthlyDay,
				},
				onSuccess
			);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {isWeeklyTabSelected} = this.state;
		const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
		const monthlyRecurringOptions = [];
		for (var i = 1; i <= 31; i++) {
			monthlyRecurringOptions.push({value: i, label: i + ''});
		}

		const content = (
			<div className="task-recurrence-modal-content">
				<div className="tabs-container">
					<div
						className={'tab ' + (isWeeklyTabSelected ? 'selected' : '')}
						onClick={() => {
							this.setState({
								isWeeklyTabSelected: true,
							});
						}}
					>
						{formatMessage({id: 'card_modal.repeating.weekly'})}
					</div>
					<div
						className={'tab ' + (!isWeeklyTabSelected ? 'selected' : '')}
						onClick={() => {
							this.setState({
								isWeeklyTabSelected: false,
							});
						}}
					>
						{formatMessage({id: 'card_modal.repeating.monthly'})}
					</div>
				</div>

				{isWeeklyTabSelected ? (
					<div className="days-checkboxes-container">
						{weekDays.map(day => (
							<div key={day} className="day-container">
								<span className="day">{formatMessage({id: `common.${day}`}).substr(0, 3)}</span>
								<CheckBox
									onChange={this.handleWeekDaySelect.bind(this, day)}
									isChecked={this.state.weekDays[day]}
								/>
							</div>
						))}
					</div>
				) : (
					<div className="days-dropdown-container">
						<Dropdown
							onChange={this.handleMonthDaySelect.bind(this)}
							options={monthlyRecurringOptions}
							placeholder={formatMessage({id: 'task_recurrence_modal.select_month_day'})}
							value={this.state.monthlyDay}
						/>
					</div>
				)}
			</div>
		);

		return (
			<GenericModal
				content={content}
				headerText={formatMessage({id: 'task_recurrence_modal.repeat_task'})}
				className="task-recurrence-modal"
				closeModal={this.props.closeModal}
				buttons={[
					{text: formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
					{
						text: formatMessage({id: 'common.save'}),
						callback: this.props.repeatingTask ? this.updateTask.bind(this) : this.createRepeatingTask.bind(this),
					},
				]}
			/>
		);
	}
}

export default injectIntl(taskRecurrenceModal);
