import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import {Dropdown, SingleText} from 'web-components';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

export const DepartmentSelector = ({selected, departments, onChange}) => {
	const {formatMessage} = useIntl();

	const sortedDepartments = useMemo(
		() => departments.map(edge => edge.node).sort((a, b) => Util.sortAlphabetically(a.name, b.name)),
		[departments]
	);

	const onSelect = id => {
		trackEvent('Placeholder Department', 'Selected');
		onChange(id[0]);
	};
	const onRemove = () => {
		trackEvent('Placeholder Department', 'Deselected');
		onChange(null);
	};
	return (
		<Dropdown
			usePortal
			isClearable
			selectedItems={selected ? [selected] : []}
			onSelect={onSelect}
			onRemove={onRemove}
			cy={'dropdown-department'}
			dropdownAlignment={'none'}
			name={formatMessage({id: 'placeholder.select_department'})}
		>
			{sortedDepartments.map(department => (
				<SingleText key={department.id} value={department.id} searchString={department.name}>
					{department.name}
				</SingleText>
			))}
		</Dropdown>
	);
};
