import React from 'react';
import {StandardMargin} from '../../components/insights/invoicing/InvoicingPage.styled';
import {SubNavigation} from 'web-components';
import {useHistory, useLocation} from 'react-router-dom';
import Util from '../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';

const getSubPages = hasStatusAugmentation => {
	const pages = [
		{
			value: '/my-profile/account-configuration',
			label: 'Account Configuration',
		},
		{
			value: '/my-profile/security',
			label: 'Security',
		},
		{
			value: '/my-profile/notifications',
			label: 'Notification Settings',
		},
		{
			value: '/my-profile/task-activities',
			label: 'My Task Activities',
		},
	];
	if (hasStatusAugmentation)
		pages.push({
			value: '/my-profile/project-status-updates',
			label: 'Project Status Updates',
		});
	return pages;
};

const SettingsProfileSubNav = ({viewer}) => {
	const {pathname} = useLocation();
	const history = useHistory();

	const subPages = getSubPages(Util.hasStatusAugmentation(viewer.company, viewer.disableNotifications));

	const activeSubPage = subPages.find(subPage => pathname?.startsWith?.(subPage.value));

	const handleSetActivePage = value => {
		const newPage = subPages.some(option => option.value === value);
		if (activeSubPage?.value !== value && newPage) {
			history.push(value);
		}
	};

	return (
		<>
			<div style={{marginTop: '20px'}}>
				<StandardMargin data-cy={'Projects-tabs'}>
					{subPages.map(option => (
						<SubNavigation.NavigationItem
							active={activeSubPage?.value === option.value}
							onClick={() => handleSetActivePage(option.value)}
							key={option.value}
							cy={'settings-profile-tabs' + option.value.toLowerCase().replaceAll('/', '-')}
							userpilot={'settings-profile-tabs' + option.value.toLowerCase().replaceAll('/', '-')}
						>
							{option.label}
						</SubNavigation.NavigationItem>
					))}
				</StandardMargin>
			</div>
		</>
	);
};

export default createFragmentContainer(SettingsProfileSubNav, {
	viewer: graphql`
		fragment SettingsProfileSubNav_viewer on Viewer {
			id
			projectOwner
			disableNotifications
			company {
				id
				forecastDemo
				tier
			}
		}
	`,
});
