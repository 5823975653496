import React, {useState} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import Styled from 'styled-components/macro';
import {ProjectIndicator, RadioButton} from 'web-components';
import {CSS_CONSTANTS} from '../../css_variables';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import {BUTTON_COLOR} from '../../constants';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';

const ContenWrapper = Styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom:8px;
`;

const Header = Styled.div`
	margin-bottom:24px;
`;

const ChoicesWrapper = Styled.div`
	margin: 24px 0;
`;
const ChoiceWrapper = Styled.div`
	margin-bottom:16px;
`;

const Option = Styled.div`
	display:flex;
	flex-direction: row;
	align-items:center;
	margin-bottom:2px;

`;
const OptionText = Styled.div`
	margin-left:8px;
`;

const OptionDescription = Styled.div`
	margin-left:30px; //22 + 8
	margin-top: 5px;
	width:180px;
`;

const TaskList = Styled.div`
	display: grid;
	grid-template-columns: auto auto 1fr auto;
	width:584px;
`;

const HeaderElement = Styled.div`
	color:${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 9px;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-bottom:8px;
	font-weight: 500;
	padding-left:${props => (props.firstColumn ? 0 : '24px')};
	padding-right:${props => (props.lastColumn ? '24px' : 0)};
	min-width:${props => (props.middleColumn ? '350px' : 0)};

	position:sticky;
	top:0;
	background-color:white;

`;

const SprintElement = Styled.div`
	height:40px;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-left:${props => (props.firstColumn ? 0 : '24px')};
	padding-right:${props => (props.lastColumn ? '24px' : 0)};
	min-width:${props => (props.middleColumn ? '350px' : 0)};

	padding-top:12px;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
`;

export const TASKS_OPTIONS = {
	NEXT_SPRINT: 0,
	DONE: 1,
	BACKLOG: 2,
};

const CloseSprintModal = ({intl, closeModal, tasksList, doneColumns, callback}) => {
	const [selectedOption, setSelectedOption] = useState(TASKS_OPTIONS.NEXT_SPRINT);
	const [selectedColumn, setSelectedColumn] = useState(doneColumns.length > 0 && doneColumns[0]);
	const switchSelected = opt => {
		setSelectedOption(opt);
	};

	const handleSelecteColumn = column => {
		setSelectedOption(TASKS_OPTIONS.DONE);
		setSelectedColumn(column);
	};

	const content = () => {
		const {formatMessage} = intl;

		return (
			<ContenWrapper>
				<Header>{formatMessage({id: 'project_sprints.warning-modal-title'})}</Header>
				<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={300} autoHide={false}>
					<TaskList>
						<HeaderElement firstColumn={true}>{formatMessage({id: 'common.project_id_short'})}</HeaderElement>
						<HeaderElement>{formatMessage({id: 'common.id'})}</HeaderElement>
						<HeaderElement middleColumn={true}>{formatMessage({id: 'common.task_name'})}</HeaderElement>
						<HeaderElement lastColumn={true}>{formatMessage({id: 'common.status'})}</HeaderElement>
						{tasksList.map(task => {
							const project = task.project;
							return [
								<SprintElement firstColumn={true}>
									<ProjectIndicator
										color={project.projectColor}
										disableLink={true}
										id={project.companyProjectId}
										name={project.name}
									/>
								</SprintElement>,
								<SprintElement>T{task.companyTaskId}</SprintElement>,
								<SprintElement middleColumn={true} title={task.name}>
									{task.name}
								</SprintElement>,
								<SprintElement lastColumn={true} title={task.statusColumnV2.name}>
									{task.statusColumnV2.name}
								</SprintElement>,
							];
						})}
					</TaskList>
				</CustomScrollDiv>
				<ChoicesWrapper>
					<ChoiceWrapper>
						<Option>
							<RadioButton
								checked={selectedOption === TASKS_OPTIONS.NEXT_SPRINT}
								onClick={() => switchSelected(TASKS_OPTIONS.NEXT_SPRINT)}
								cy={'move-to-next-sprint'}
							></RadioButton>
							<OptionText>
								<FormattedHTMLMessage id={'project_sprints.move_unfinished_tasks_to_next_sprint'} />
							</OptionText>
						</Option>
					</ChoiceWrapper>
					<ChoiceWrapper>
						<Option>
							<RadioButton
								checked={selectedOption === TASKS_OPTIONS.DONE}
								onClick={() => switchSelected(TASKS_OPTIONS.DONE)}
								cy={'move-to-done'}
							></RadioButton>
							<OptionText>
								<FormattedHTMLMessage id={'project_sprints.set_unfinished_tasks_to_done'} />
							</OptionText>
						</Option>
						{doneColumns.length > 1 && (
							<OptionDescription>
								<DropdownV2
									hideLabel={true}
									options={doneColumns}
									onChange={handleSelecteColumn}
									value={selectedColumn.value}
									multi={false}
									focusOnClick={true}
									customHeight={30}
									maxHeight={190}
								/>
							</OptionDescription>
						)}
					</ChoiceWrapper>
					<ChoiceWrapper>
						<Option>
							<RadioButton
								checked={selectedOption === TASKS_OPTIONS.BACKLOG}
								onClick={() => switchSelected(TASKS_OPTIONS.BACKLOG)}
								cy={'move-to-backlog'}
							></RadioButton>
							<OptionText>
								<FormattedHTMLMessage id={'project_sprints.move_unfinished_tasks_to_backlog'} />
							</OptionText>
						</Option>
					</ChoiceWrapper>
				</ChoicesWrapper>
			</ContenWrapper>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{text: intl.formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
				{
					text: intl.formatMessage({id: 'common.confirm'}),
					callback: () => callback(selectedOption, selectedColumn.value),
					cy: 'confirm-close-sprint',
				},
			]}
			content={content()}
			headerText={intl.formatMessage({id: 'sprint.close-sprint'})}
			modalWidth={MODAL_WIDTH.MEDIUM}
		></GenericModal>
	);
};
export default injectIntl(CloseSprintModal);
