import Styled from 'styled-components';

export const Wrapper = Styled.div`
	display: flex;
	flex-direction: column;
	cursor: default;
`;

export const Item = Styled.div`
	
	display: flex;
	flex-direction: row;
	margin-bottom: 17px;
	align-items: center;

`;

export const SectionHeader = Styled.h3`
	font-weight: 600;
	font-size: 13px;
	color: #53535;
	margin: 0 0 17px;
	padding: 0;
`;

export const ItemControl = Styled.span`	
	display: inline-block;
	margin-right: 10px;
`;

export const ItemText = Styled.p`
	margin: 0;
	padding: 0;
	display: inline-block;
	font-size: 13px;
	color: #535353;
`;
