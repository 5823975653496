import util from '../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT, projectStatusColors} from '../../constants';

export const getSortableProjectId = projectNode => {
	let sortableId = projectNode.customProjectId
		? projectNode.customProjectId
		: projectNode.companyProjectId !== undefined
		? projectNode.companyProjectId.toString()
		: projectNode.companyProjectGroupId?.toString();

	// pad the numeric component of the id with zeros, keep the initial string if it exists
	if (sortableId?.match(/^\D*\d+$/)) {
		const numberComponent = sortableId.match(/^\D*(\d+)$/)[1];
		const stringComponent = sortableId.replace(numberComponent, '');
		sortableId = stringComponent + String(numberComponent).padStart(8, '0');
	}
	return sortableId;
};

export const sortProjects = (projects, sortBy, calculatedDataMap) => {
	if (sortBy.column) {
		const sortConnectedProjects = (a, b, sortFunction) => {
			if (a.node.projects) {
				a.node.projects.edges.sort(sortFunction);
			}
			if (b.node.projects) {
				b.node.projects.edges.sort(sortFunction);
			}
		};

		const sortByNumericColumn = (a, b) => {
			sortConnectedProjects(a, b, sortByNumericColumn);

			if (a.node[sortBy.column] < b.node[sortBy.column]) return sortBy.ascending ? -1 : 1;
			if (a.node[sortBy.column] === b.node[sortBy.column]) return 0;
			if (a.node[sortBy.column] > b.node[sortBy.column]) return sortBy.ascending ? 1 : -1;
		};

		const sortByCustomColumn = (a, b, columnName) => {
			sortConnectedProjects(a, b, sortByCustomColumn);

			if (a.node[columnName] < b.node[columnName]) return sortBy.ascending ? -1 : 1;
			if (a.node[columnName] === b.node[columnName]) return 0;
			if (a.node[columnName] > b.node[columnName]) return sortBy.ascending ? 1 : -1;
		};

		const sortByIdColumn = (a, b) => {
			sortConnectedProjects(a, b, sortByIdColumn);
			let aId = getSortableProjectId(a.node);
			let bId = getSortableProjectId(b.node);

			if (aId < bId) return sortBy.ascending ? -1 : 1;
			if (aId === bId) return 0;
			if (aId > bId) return sortBy.ascending ? 1 : -1;
		};

		const sortByClientColumn = (a, b) => {
			sortConnectedProjects(a, b, sortByClientColumn);
			const aName = a.node.client ? a.node.client.name : '';
			const bName = b.node.client ? b.node.client.name : '';
			if (aName < bName) return sortBy.ascending ? -1 : 1;
			if (aName > bName) return sortBy.ascending ? 1 : -1;
			return 0;
		};

		const sortByPriorityLevel = (a, b) => {
			sortConnectedProjects(a, b, sortByPriorityLevel);
			const aWeight = a.node.priorityLevel ? a.node.priorityLevel.weight : -1;
			const bWeight = b.node.priorityLevel ? b.node.priorityLevel.weight : -1;
			if (aWeight < bWeight) return sortBy.ascending ? -1 : 1;
			if (aWeight > bWeight) return sortBy.ascending ? 1 : -1;
			return 0;
		};

		const sortByNameColumn = (a, b) => {
			sortConnectedProjects(a, b, sortByNameColumn);
			const aName = a.node.name ? a.node.name.toUpperCase() : '';
			const bName = b.node.name ? b.node.name.toUpperCase() : '';
			if (aName < bName) return sortBy.ascending ? -1 : 1;
			if (aName > bName) return sortBy.ascending ? 1 : -1;
			return 0;
		};

		const sortByStartDate = (a, b) => {
			sortConnectedProjects(a, b, sortByStartDate);
			// connected projects should be compared by earliest start date
			let date_a, date_b;
			if (a.node.projects) {
				date_a = a.node.startDate;
			} else {
				date_a = util.CreateNonUtcMomentDate(a.node.projectStartYear, a.node.projectStartMonth, a.node.projectStartDay); //new Date(a.node.projectStartYear, a.node.projectStartMonth, a.node.projectStartDay);
			}
			if (b.node.projects) {
				date_b = b.node.startDate;
			} else {
				date_b = util.CreateNonUtcMomentDate(b.node.projectStartYear, b.node.projectStartMonth, b.node.projectStartDay); // new Date(b.node.projectStartYear, b.node.projectStartMonth, b.node.projectStartDay);
			}
			if (date_a < date_b) return sortBy.ascending ? -1 : 1;
			if (date_a === date_b) return 0;
			if (date_a > date_b) return sortBy.ascending ? 1 : -1;
		};

		const sortByEndDate = (a, b) => {
			sortConnectedProjects(a, b, sortByEndDate);
			// connected projects should be compared by latest end date
			let date_a, date_b;
			if (a.node.projects) {
				date_a = a.node.endDate;
			} else {
				date_a = util.CreateNonUtcMomentDate(a.node.projectEndYear, a.node.projectEndMonth, a.node.projectEndDay); // new Date(a.node.projectEndYear, a.node.projectEndMonth, a.node.projectEndDay);
			}
			if (b.node.projects) {
				date_b = b.node.endDate;
			} else {
				date_b = util.CreateNonUtcMomentDate(b.node.projectEndYear, b.node.projectEndMonth, b.node.projectEndDay);
			}

			if (date_a < date_b) return sortBy.ascending ? -1 : 1;
			if (date_a === date_b) return 0;
			if (date_a > date_b) return sortBy.ascending ? 1 : -1;
		};

		const sortByCalculatedNumericColumn = (a, b, colName) => {
			const columnName = colName || sortBy.column;
			// sort projects in connected project
			if (a.node.projects) {
				a.node.projects.edges.sort((a, b) => sortByCalculatedNumericColumn(a, b, colName));
			}
			if (b.node.projects) {
				b.node.projects.edges.sort((a, b) => sortByCalculatedNumericColumn(a, b, colName));
			}

			const defaultValues = {completion: 0, tasksCount: 0, doneTasksCount: 0, projectPersonsCount: 0, reported: 0};

			const aNode = a.node.projects ? a.node : calculatedDataMap.get(a.node.id) || defaultValues;
			const bNode = b.node.projects ? b.node : calculatedDataMap.get(b.node.id) || defaultValues;

			if (aNode[columnName] < bNode[columnName]) return sortBy.ascending ? -1 : 1;
			if (aNode[columnName] === bNode[columnName]) return 0;
			if (aNode[columnName] > bNode[columnName]) return sortBy.ascending ? 1 : -1;
		};

		const sortByFinancial = (a, b) => {
			sortConnectedProjects(a, b, sortByFinancial);
			const is_a_project_group = a.node.projects !== undefined;
			const is_b_project_group = b.node.projects !== undefined;
			let val_a = 0;
			let val_b = 0;

			const defaultValues = {
				forecastMoney: 0,
				reportedMoney: 0,
				projectedMoney: 0,
				forecastBillablePrice: 0,
				forecastNonBillablePrice: 0,
				reportedBillablePrice: 0,
				reportedNonBillablePrice: 0,
				projectedProfit: 0,
				projectedCost: 0,
				forecastProfit: 0,
				forecastCost: 0,
				remainingBillablePrice: 0,
				remainingNonBillablePrice: 0,
				remainingMoney: 0,
				currentCost: 0,
				remainingCost: 0,
				invoiced: 0,
				invoicedPaid: 0,
				recognitionOpenRevenue: 0,
				recognitionForecastRevenue: 0,
				recognitionLockedRevenue: 0,
			};

			const aNode = is_a_project_group ? a.node : a.node.financialNumbers || defaultValues;
			const bNode = is_b_project_group ? b.node : b.node.financialNumbers || defaultValues;
			if (sortBy.column === 'budget') {
				val_a = a.node.budget ? a.node.budget : 0;
				val_b = b.node.budget ? b.node.budget : 0;
			} else if (sortBy.column === 'forecast-money') {
				val_a = aNode.plannedRevenue;
				val_b = bNode.plannedRevenue;
			} else if (sortBy.column === 'reported-money') {
				val_a = aNode.actualRevenue;
				val_b = bNode.actualRevenue;
			} else if (sortBy.column === 'remaining-money') {
				val_a = aNode.remainingRevenue;
				val_b = bNode.remainingRevenue;
			} else if (sortBy.column === 'projected-money') {
				val_a = aNode.forecastRevenue;
				val_b = bNode.forecastRevenue;
			} else if (sortBy.column === 'recognition-locked-revenue') {
				val_a = aNode.recognitionLockedRevenue;
				val_b = bNode.recognitionLockedRevenue;
			} else if (sortBy.column === 'recognition-remaining-revenue') {
				val_a = aNode.recognitionOpenRevenue;
				val_b = bNode.recognitionOpenRevenue;
			} else if (sortBy.column === 'recognition-forecast-revenue') {
				val_a = aNode.recognitionForecastRevenue;
				val_b = bNode.recognitionForecastRevenue;
			} else if (sortBy.column === 'projected-profit') {
				val_a = aNode.forecastProfit;
				val_b = bNode.forecastProfit;
			} else if (sortBy.column === 'projected-cost') {
				val_a = aNode.forecastCost;
				val_b = bNode.forecastCost;
			} else if (sortBy.column === 'forecast-profit') {
				val_a = aNode.plannedProfit;
				val_b = bNode.plannedProfit;
			} else if (sortBy.column === 'cost') {
				val_a = aNode.plannedCost;
				val_b = bNode.plannedCost;
			} else if (sortBy.column === 'reported-cost') {
				val_a = aNode.actualCost;
				val_b = bNode.actualCost;
			} else if (sortBy.column === 'remaining-cost') {
				val_a = aNode.remainingCost;
				val_b = bNode.remainingCost;
			}
			if (val_a < val_b) return sortBy.ascending ? -1 : 1;
			if (val_a === val_b) return 0;
			if (val_a > val_b) return sortBy.ascending ? 1 : -1;
		};

		const sortByRateCard = (a, b) => {
			sortConnectedProjects(a, b, sortByRateCard);
			const rateCard_a = a.node.rateCard ? a.node.rateCard.name : '';
			const rateCard_b = b.node.rateCard ? b.node.rateCard.name : '';
			if (rateCard_a < rateCard_b) return sortBy.ascending ? -1 : 1;
			if (rateCard_a === rateCard_b) return 0;
			if (rateCard_a > rateCard_b) return sortBy.ascending ? 1 : -1;
		};

		const sortByStatusColor = (a, b) => {
			sortConnectedProjects(a, b, sortByStatusColor);
			const aIndex = a.node.currentProjectStatus ? projectStatusColors.indexOf(a.node.currentProjectStatus.color) : -1;
			const bIndex = b.node.currentProjectStatus ? projectStatusColors.indexOf(b.node.currentProjectStatus.color) : -1;
			if (aIndex < bIndex) return sortBy.ascending ? 1 : -1;
			if (aIndex > bIndex) return sortBy.ascending ? -1 : 1;
		};

		const sortByContactPerson = (a, b) => {
			sortConnectedProjects(a, b, sortByContactPerson);
			let aValue = '';
			let bValue = '';
			if (a.node.projectPersons) {
				a.node.projectPersons.edges
					.filter(pp => pp.node.isContactPerson)
					.forEach(
						projectPerson =>
							(aValue +=
								projectPerson.node.person.firstName.toLowerCase() +
								projectPerson.node.person.lastName.toLowerCase())
					);
			}
			if (b.node.projectPersons) {
				b.node.projectPersons.edges
					.filter(pp => pp.node.isContactPerson)
					.forEach(
						projectPerson =>
							(bValue +=
								projectPerson.node.person.firstName.toLowerCase() +
								projectPerson.node.person.lastName.toLowerCase())
					);
			}
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
			if (aValue === bValue) return 0;
			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
		};

		const sortByLabels = (a, b) => {
			sortConnectedProjects(a, b, sortByLabels);
			let aValue = '';
			let bValue = '';
			if (a.node.projectLabels) {
				a.node.projectLabels.edges.forEach(
					projectlabel => (aValue += projectlabel.node.label.name ? projectlabel.node.label.name.toLowerCase() : '')
				);
			}
			if (b.node.projectLabels) {
				b.node.projectLabels.edges.forEach(
					projectlabel => (bValue += projectlabel.node.label.name ? projectlabel.node.label.name.toLowerCase() : '')
				);
			}
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
			if (aValue === bValue) return 0;
			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
		};

		const sortByXeroPaid = (a, b) => {
			sortConnectedProjects(a, b, sortByXeroPaid);
			// sum for connected projects
			let aValue = a.node.projects
				? a.node.xeroPaid
				: a.node.xeroInvoices
				? a.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0)
				: null;
			let bValue = b.node.projects
				? b.node.xeroPaid
				: b.node.xeroInvoices
				? b.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0)
				: null;
			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
			if (aValue === bValue) return 0;
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
		};
		const sortByXeroAmountTotal = (a, b) => {
			sortConnectedProjects(a, b, sortByXeroAmountTotal);
			// sum for connected projects
			let aValue = a.node.projects
				? a.node.xeroAmountTotal
				: a.node.xeroInvoices
				? a.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0)
				: null;
			let bValue = b.node.projects
				? b.node.xeroAmountTotal
				: b.node.xeroInvoices
				? b.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0)
				: null;
			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
			if (aValue === bValue) return 0;
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
		};
		const sortByXeroDue = (a, b) => {
			sortConnectedProjects(a, b, sortByXeroDue);
			// sum for connected projects
			let aValue = a.node.projects
				? a.node.amountTotal - a.node.amountPaid
				: a.node.xeroInvoices
				? a.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0) -
				  a.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0)
				: null;
			let bValue = b.node.projects
				? b.node.amountTotal - b.node.amountPaid
				: b.node.xeroInvoices
				? b.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0) -
				  b.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0)
				: null;
			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
			if (aValue === bValue) return 0;
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
		};

		const sortByVarianceToForecast = (a, b) => {
			sortConnectedProjects(a, b, sortByVarianceToForecast);
			const isAHourEstimated =
				a.node.isHourEstimated !== undefined ? a.node.isHourEstimated : a.node.estimationUnit === ESTIMATION_UNIT.HOURS;
			const isBHourEstimated =
				b.node.isHourEstimated !== undefined ? b.node.isHourEstimated : b.node.estimationUnit === ESTIMATION_UNIT.HOURS;

			const defaultValues = {forecast: 0, reported: 0, remaining: 0, minutesPerEstimationPoint: 1};

			const aNode = a.node.projects ? a.node : calculatedDataMap.get(a.node.id) || defaultValues;
			const bNode = b.node.projects ? b.node : calculatedDataMap.get(b.node.id) || defaultValues;

			let aValue = util.calculateVarianceToForecast(aNode, isAHourEstimated),
				bValue = util.calculateVarianceToForecast(bNode, isBHourEstimated);
			if (isAHourEstimated) {
				aValue /= 60.0;
			}
			if (isBHourEstimated) {
				bValue /= 60.0;
			}
			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
			if (aValue === bValue) return 0;
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
		};

		const sortByInvoicePaid = (a, b) => {
			sortConnectedProjects(a, b, sortByInvoicePaid);

			const aPaid = a.node.projects ? a.node.invoicePaid : a.node.financialNumbers.paid;
			const bPaid = b.node.projects ? b.node.invoicePaid : b.node.financialNumbers.paid;

			if (aPaid < bPaid) return sortBy.ascending ? -1 : 1;
			if (aPaid === bPaid) return 0;
			if (aPaid > bPaid) return sortBy.ascending ? 1 : -1;
		};
		const sortByInvoiceAmountTotal = (a, b) => {
			sortConnectedProjects(a, b, sortByInvoiceAmountTotal);

			const aTotal = a.node.projects ? a.node.invoiceAmountTotal : a.node.financialNumbers.invoiced;
			const bTotal = b.node.projects ? b.node.invoiceAmountTotal : b.node.financialNumbers.invoiced;

			if (aTotal < bTotal) return sortBy.ascending ? -1 : 1;
			if (aTotal === bTotal) return 0;
			if (aTotal > bTotal) return sortBy.ascending ? 1 : -1;
		};
		const sortByInvoiceDue = (a, b) => {
			sortConnectedProjects(a, b, sortByInvoiceDue);

			const aPaid = a.node.projects ? a.node.invoicePaid : a.node.financialNumbers.paid;
			const bPaid = b.node.projects ? b.node.invoicePaid : b.node.financialNumbers.paid;

			const aTotal = a.node.projects ? a.node.invoiceAmountTotal : a.node.financialNumbers.invoiced;
			const bTotal = b.node.projects ? b.node.invoiceAmountTotal : b.node.financialNumbers.invoiced;

			const aDue = aTotal - aPaid;
			const bDue = bTotal - bPaid;

			if (aDue < bDue) return sortBy.ascending ? -1 : 1;
			if (aDue === bDue) return 0;
			if (aDue > bDue) return sortBy.ascending ? 1 : -1;
		};

		const sortByHoursColumn = (a, b) => {
			sortConnectedProjects(a, b, sortByHoursColumn);

			const defaultValues = {forecast: 0, forecastTotal: 0, remaining: 0};

			const aNode = a.node.projects ? a.node : calculatedDataMap.get(a.node.id) || defaultValues;
			const bNode = b.node.projects ? b.node : calculatedDataMap.get(b.node.id) || defaultValues;

			let aValue = aNode[sortBy.column],
				bValue = bNode[sortBy.column];
			const isAHourEstimated =
				a.node.isHourEstimated !== undefined ? a.node.isHourEstimated : a.node.estimationUnit === ESTIMATION_UNIT.HOURS;
			const isBHourEstimated =
				b.node.isHourEstimated !== undefined ? b.node.isHourEstimated : b.node.estimationUnit === ESTIMATION_UNIT.HOURS;
			if (isAHourEstimated) {
				aValue /= 60.0;
			}
			if (isBHourEstimated) {
				bValue /= 60.0;
			}

			if (aValue < bValue) return sortBy.ascending ? -1 : 1;
			if (aValue === bValue) return 0;
			if (aValue > bValue) return sortBy.ascending ? 1 : -1;
		};

		if (sortBy.column === 'id') {
			projects = projects.sort(sortByIdColumn);
		} else if (sortBy.column === 'client') {
			projects = projects.sort(sortByClientColumn);
		} else if (sortBy.column === 'priorityLevel') {
			projects = projects.sort(sortByPriorityLevel);
		} else if (sortBy.column === 'name') {
			projects = projects.sort(sortByNameColumn);
		} else if (sortBy.column === 'startDate') {
			projects = projects.sort(sortByStartDate);
		} else if (sortBy.column === 'endDate') {
			projects = projects.sort(sortByEndDate);
		} else if (sortBy.column === 'variance_to_forecast') {
			projects = projects.sort(sortByVarianceToForecast);
		} else if (sortBy.column === 'rateCard') {
			projects = projects.sort(sortByRateCard);
		} else if (sortBy.column === 'statusColor' || sortBy.column === 'statusColorV2') {
			projects = projects.sort(sortByStatusColor);
		} else if (
			[
				'budget',
				'forecast-money',
				'reported-money',
				'remaining-money',
				'projected-money',
				'projected-cost',
				'projected-profit',
				'forecast-profit',
				'cost',
				'remaining-cost',
				'reported-cost',
				'recognition-locked-revenue',
				'recognition-remaining-revenue',
				'recognition-forecast-revenue',
			].includes(sortBy.column)
		) {
			projects = projects.sort(sortByFinancial);
		} else if (sortBy.column === 'contact') {
			projects = projects.sort(sortByContactPerson);
		} else if (sortBy.column === 'labels') {
			projects = projects.sort(sortByLabels);
		} else if (sortBy.column === 'xero-paid') {
			projects = projects.sort(sortByXeroPaid);
		} else if (sortBy.column === 'xero-amount-total') {
			projects = projects.sort(sortByXeroAmountTotal);
		} else if (sortBy.column === 'xero-due') {
			projects = projects.sort(sortByXeroDue);
		} else if (sortBy.column === 'invoice-paid') {
			projects = projects.sort(sortByInvoicePaid);
		} else if (sortBy.column === 'invoice-amount-total') {
			projects = projects.sort(sortByInvoiceAmountTotal);
		} else if (sortBy.column === 'invoice-due') {
			projects = projects.sort(sortByInvoiceDue);
		} else if (['forecastTotal', 'forecast', 'remaining'].includes(sortBy.column)) {
			//need separate sort by function for columns using hours/points in order to convert points and hours so they can be compared correctly
			projects = projects.sort(sortByHoursColumn);
		} else if (['completion', 'tasksCount', 'doneTasksCount', 'projectPersonsCount', 'reported'].includes(sortBy.column)) {
			projects = projects.sort(sortByCalculatedNumericColumn);
		} else if (sortBy.column === 'win-chance') {
			projects = projects.sort((a, b) => sortByCustomColumn(a, b, 'baselineWinChance'));
		} else {
			projects = projects.sort(sortByNumericColumn);
		}
	}
	return projects;
};
