import Styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';

export const TableHeader = Styled.th`
    position: sticky;
    top: 0;
    text-align: left;
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${CSS_CONSTANTS.v2_text_light_gray};
    background-color: white;
    z-index: 999;
    padding-bottom: 4px;
    font-weight: 500;
`;

export const ProjectRow = Styled.tr`
    border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
    border-width: 1px 0;
    height: 40px;
`;

export const ProjectCol = Styled.td`
    font-size: 13px;
    color: ${CSS_CONSTANTS.v2_text_gray};
    &:last-child {
        padding-right: 12px;
        width: 202px;
    }
`;

export const ProjectIndicatorWrapper = Styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    span {
        margin-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const IdleTimeWrapper = Styled.div`
    display: flex;
  	gap: 12px;
  	span:first-child {
  		font-style: italic;
  	}
`;
