import styled from 'styled-components';

export const FocusSubTitle = styled.span`
	font-size: 13px;
`;

export const ColorStatValue = styled.span`
	color: ${props => props.color};
`;

export const SubTitleStatValue = styled(ColorStatValue)`
	font-size: 13px;
	font-weight: 500;
`;
