import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {INSIGHTS} from '../ProjectHealthLogic';
import MonocularAstronaut from '../../../../images/v2/avatars/MonocularAstronaut.png';
import * as tracking from '../../../../tracking';
import Step from './Step';
import {CrossIcon} from 'web-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

const OnboardingContainer = styled.div`
	display: flex;
	margin: 0 24px;
	padding: 24px 16px 24px 36px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	background-color: #fff;
	justify-content: space-between;

	&.onboarding-enter {
		opacity: 0.01;
	}

	&.onboarding-enter.onboarding-enter-active {
		opacity: 1;
		transition: opacity 0.5s ease-in;
	}

	&.onboarding-leave {
		opacity: 1;
	}

	&.onboarding-leave.onboarding-leave-active {
		opacity: 0.01;
		transition: opacity 300ms ease-in;
	}
`;

const ImageStepsWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ImageContainer = styled.div`
	margin-right: 64px;
	height: 95px;
	width: 117px;
	background: url(${MonocularAstronaut});
	background-position: center;
	background-repeat: no-repeat;
`;

const StepContainer = styled.div`
	display: flex;
`;

const IconContainer = styled.div`
	align-self: flex-start;
	margin-top: -8px;
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom: 8px;
`;

const Text = styled.div`
	font-size: 11px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const ProgressTextWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
`;

const ProgressBarBorder = styled.div`
	width: 178px;
	height: 8px;
	background-color: #e7e7f3;
	border-radius: 4px;
	margin-right: 16px;
`;

const ProgressBar = styled.div`
	width: ${props => props.completion};
	height: 8px;
	background-color: #49b153;
	border-radius: 4px;
`;

const getCompletion = (projectSetUp, isOpportunity, focusLocked, performanceLocked) => {
	const doneSteps = [projectSetUp, !isOpportunity, !focusLocked, !performanceLocked];
	const doneStepsFiltered = doneSteps.filter(step => !!step);
	const completionPercentage = (doneStepsFiltered.length / doneSteps.length) * 100;
	return completionPercentage + '%';
};

const getOnboardingDismissals = () => {
	const onboardingDismissals = localStorage.getItem('nova-insights-onboarding-dismissed');
	return onboardingDismissals ? JSON.parse(onboardingDismissals) : [];
};

const setOnboardingDismissals = (projectId, existingDismissals) => {
	tracking.trackEvent('Nova Insights onboarding dismissed', {projectId: projectId, existingDismissals: existingDismissals});
	trackEvent('Nova Insights Onboarding', 'Dismissed', {existingDismissals: existingDismissals});
	const newDismissals = JSON.stringify([...existingDismissals, projectId]);
	localStorage.setItem('nova-insights-onboarding-dismissed', newDismissals);
};

const NovaInsightsOnboarding = ({projectId, projectOpportunity, lockedInsightsData}) => {
	const onboardingDismissals = getOnboardingDismissals();
	const isOnboardingDismissed = onboardingDismissals.length > 2 || onboardingDismissals.includes(projectId);

	const [onboardingDismissed, setOnboardingDismissed] = useState(isOnboardingDismissed);

	const dismissOnboarding = (projectId, existingDismissals) => {
		setOnboardingDismissed(true);
		setOnboardingDismissals(projectId, existingDismissals);
	};

	const projectSetUp = true;
	const focusLocked = lockedInsightsData.some(insight => insight.id === INSIGHTS.FOCUS);
	const performanceLocked = lockedInsightsData.some(insight => insight.id === INSIGHTS.PERFORMANCE);

	const hideOnboarding = onboardingDismissed || (!projectOpportunity && !focusLocked && !performanceLocked);

	const completion = getCompletion(projectSetUp, projectOpportunity, focusLocked, performanceLocked);

	useEffect(() => {
		if (!hideOnboarding) {
			tracking.trackEvent('Nova Insights onboarding shown', {
				completion: completion,
				projectRunning: !projectOpportunity,
				personRegisteredTime: !focusLocked,
				threeTasksDone: !performanceLocked,
			});
			trackEvent('Nova Insights Onboarding', 'Shown', {
				completion: completion,
				projectRunning: !projectOpportunity,
				personRegisteredTime: !focusLocked,
				threeTasksDone: !performanceLocked,
			});
		}
	}, []);

	return (
		<ReactCSSTransitionGroup
			transitionName="onboarding"
			transitionAppear={true}
			transitionAppearTimeout={500}
			transitionEnterTimeout={500}
			transitionLeaveTimeout={300}
		>
			{hideOnboarding ? null : (
				<OnboardingContainer>
					<ImageStepsWrapper>
						<ImageContainer />
						<div>
							<Title>Your locked insights are quick clicks away!</Title>
							<ProgressTextWrapper>
								<ProgressBarBorder>
									<ProgressBar completion={completion} />
								</ProgressBarBorder>
								<Text>{completion} complete</Text>
							</ProgressTextWrapper>
							<StepContainer>
								<Step isDone={projectSetUp} title={'Project set up'} />
								<Step isDone={!projectOpportunity} title={'Project set to running'} />
								<Step isDone={!focusLocked} title={'A person registered time'} />
								<Step isDone={!performanceLocked} title={'3 tasks are done'} />
							</StepContainer>
						</div>
					</ImageStepsWrapper>
					<IconContainer>
						<CrossIcon
							size={CrossIcon.SIZE.STANDARD}
							onClick={() => dismissOnboarding(projectId, onboardingDismissals)}
						/>
					</IconContainer>
				</OnboardingContainer>
			)}
		</ReactCSSTransitionGroup>
	);
};

export default NovaInsightsOnboarding;
