import React from 'react';
import DirectApi from '../directApi';
import {SelectDropdown} from 'web-components';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../css_variables';

const DatacenterSwitch = styled.div`
	display: flex;
`;

const DatacenterDrpdownWrapper = styled.div`
	color: ${CSS_CONSTANTS.color_dark_gray};
`;

export const DatacenterDropdown = ({datacenter, setDatacenter}) => {
	// if (process.env.CIRCLE_BRANCH === 'production' && DirectApi.enableDatacenterSwitch()) {
	// 			fetch(DirectApi.graphqlServerEndpoint('ip_location_info'))
	// 				.then(result => result.json())
	// 				.then(res => {
	// 					if (res.continent_code && res.continent_code !== 'EU') {
	// 						this.setState({datacenter: 'US'});
	// 					}
	// 				});
	// 		}

	const setDatacenterClicked = val => {
		DirectApi.setDatacenterBasedOnString(val);

		setDatacenter(val);
	};

	return (
		<DatacenterSwitch>
			{DirectApi.enableDatacenterSwitch() && (
				<>
					{/*<div className="datacenter-help-icon" onClick={() => console.log('CLICKED')}>
						<Tooltip content="This is a test">
							<HelpIcon size="small" />
						</Tooltip>
					</div>*/}
					<DatacenterDrpdownWrapper>
						<SelectDropdown selectedValue={datacenter} onClick={val => setDatacenterClicked(val)}>
							<SelectDropdown.Option value={'EU'}>EU Datacenter</SelectDropdown.Option>
							<SelectDropdown.Option value={'US'}>US Datacenter</SelectDropdown.Option>
						</SelectDropdown>
					</DatacenterDrpdownWrapper>
				</>
			)}
		</DatacenterSwitch>
	);
};
