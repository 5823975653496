import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal from '../../../../modal/generic_modal';
import * as tracking from '../../../../../tracking';
import {PredictionInfo, ChartStatsContainer} from '../predicted_end_date_modal/PredictedEndDateModal.styled';
import {ForecastTooltipFormulaRenderer} from '../../../../../components/ForecastTooltipFormulaRenderer';
import {ForecastSurplusModalStatSection} from './ForecastSurplusModalStatSection';
import {useIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import ForecastSurplusModalChartSection from './ForecastSurplusModalChartSection';
import Util from '../../../../../forecast-app/shared/util/util';
import {hasPermission} from '../../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../Permissions';
import {useHistory} from 'react-router-dom';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {projectUrl} from '../../../../../directApi';

const ForecastSurplusModal = ({
	viewer,
	fixedPrice,
	forecastRevenue,
	forecastSurplusInfo,
	forecastSurplusVariant,
	closeModal,
}) => {
	const intl = useIntl();
	const history = useHistory();
	const {project} = viewer;

	const trackingInfo = {projectId: project.id, projectName: project.name};

	useEffect(() => {
		tracking.trackPageAction('Budget Modal shown', trackingInfo);
		trackEvent('Budget Modal', 'Shown');
	}, []);

	const currency = project.rateCard.currency;
	const currencySymbol = Util.GetCurrencySymbol(currency);

	const navigateSettings = () => {
		tracking.trackElementClicked('Edit Tolerance', trackingInfo);
		trackEvent('Edit Tolerance', 'Clicked');
		history.push(`${projectUrl(project.companyProjectId, project.customProjectId)}/settings#financials+budget-tolerance`);
	};

	const navigateBudget = () => {
		tracking.trackElementClicked('See Budget Page', trackingInfo);
		trackEvent('See Budget Page', 'Clicked');
		window.open(`${projectUrl(project.companyProjectId, project.customProjectId)}/budget`);
	};

	const getContent = () => {
		return (
			<>
				<PredictionInfo>{forecastSurplusInfo}</PredictionInfo>
				<ChartStatsContainer>
					<ForecastSurplusModalStatSection
						currencySymbol={currencySymbol}
						fixedPrice={fixedPrice}
						forecastRevenue={forecastRevenue}
						variant={forecastSurplusVariant}
						intl={intl}
					></ForecastSurplusModalStatSection>
					<ForecastSurplusModalChartSection
						project={project}
						fixedPrice={fixedPrice}
						currencySymbol={currencySymbol}
						intl={intl}
					></ForecastSurplusModalChartSection>
				</ChartStatsContainer>
			</>
		);
	};

	const tooltipContent = () => {
		return (
			<ForecastTooltipFormulaRenderer
				items={[
					{
						description:
							'With the Budget insight, you can see the predicted variance between estimates and actuals at the time of project completion.',
					},
					{
						title: 'The Budget insight is calculated as',
						details: ['Fixed Price', '-', 'Forecasted Revenue'],
					},
				]}
				translatedMessage={true}
			/>
		);
	};

	const getButtons = () => {
		const buttons = [
			{
				text: 'See Budget',
				callback: () => navigateBudget(),
				preventDefaultClose: true,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.WHITE,
			},
		];

		return buttons;
	};

	const getTertiaryButtons = () => {
		if (hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE)) {
			return [
				{
					text: 'Edit Tolerance',
					callback: () => navigateSettings(),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			];
		}

		return [];
	};

	return (
		<GenericModal
			closeModal={closeModal}
			content={getContent()}
			headerText={'Budget'}
			headerInfo={tooltipContent()}
			onInfoIconShow={() => tracking.trackInfoTooltipShown('Budget modal')}
			modalWidth={900}
			buttons={getButtons()}
			tertiaryButtons={getTertiaryButtons()}
		/>
	);
};

const ForecastSurplusModalQuery = graphql`
	query ForecastSurplusModal_Query($projectId: ID) {
		viewer {
			component(name: "forecast_surplus_modal")
			actualPersonId
			id
			...ForecastSurplusModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {ForecastSurplusModalQuery};

export default createFragmentContainer(ForecastSurplusModal, {
	viewer: graphql`
		fragment ForecastSurplusModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			project(internalId: $projectId) {
				id
				name
				companyProjectId
				revenueMarginLow
				revenueMarginHigh
				rateCard {
					currency
				}
				...ForecastSurplusModalChartSection_project
			}
		}
	`,
});
