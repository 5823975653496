/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectHeader_projectGroup$ref = any;
type SecondaryNavigation_projectGroup$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectGroupSettingsPage_viewer$ref: FragmentReference;
declare export opaque type ProjectGroupSettingsPage_viewer$fragmentType: ProjectGroupSettingsPage_viewer$ref;
export type ProjectGroupSettingsPage_viewer = {|
  +id: string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +projectGroup: ?{|
    +id: string,
    +name: ?string,
    +color: ?string,
    +companyProjectGroupId: ?number,
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +estimationUnit: ?string,
          +sprintTimeBox: ?boolean,
          +sprintLengthInDays: ?number,
          +minutesPerEstimationPoint: ?number,
          +useManualAllocations: ?boolean,
          +projectStartDay: ?number,
          +projectStartMonth: ?number,
          +projectStartYear: ?number,
          +projectEndDay: ?number,
          +projectEndMonth: ?number,
          +projectEndYear: ?number,
          +teamsId: ?string,
          +projectColor: ?string,
          +companyProjectId: ?number,
          +name: ?string,
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +isContactPerson: ?boolean,
                +relationType: ?string,
                +role: ?{|
                  +id: string,
                  +name: ?string,
                |},
                +person: ?{|
                  +id: string,
                  +firstName: ?string,
                  +lastName: ?string,
                  +fullName: ?string,
                  +initials: ?string,
                  +profiles: ?{|
                    +edges: ?$ReadOnlyArray<?{|
                      +node: ?{|
                        +id: string,
                        +name: ?string,
                      |}
                    |}>
                  |},
                  +permissions: ?$ReadOnlyArray<?string>,
                  +email: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +active: ?boolean,
                  +isViewer: ?boolean,
                  +harvestUser: ?boolean,
                  +role: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                  +personLabels: ?{|
                    +edges: ?$ReadOnlyArray<?{|
                      +node: ?{|
                        +id: string,
                        +label: ?{|
                          +id: string,
                          +name: ?string,
                        |},
                      |}
                    |}>
                  |},
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +$fragmentRefs: ProjectHeader_projectGroup$ref & SecondaryNavigation_projectGroup$ref,
  |},
  +company: ?{|
    +isUsingProjectAllocation: ?boolean,
    +harvestEnabled: ?boolean,
    +createdAt: ?string,
    +profiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string,
                  +active: ?boolean,
                  +firstName: ?string,
                  +lastName: ?string,
                  +fullName: ?string,
                  +initials: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +email: ?string,
                  +monday: ?number,
                  +tuesday: ?number,
                  +wednesday: ?number,
                  +thursday: ?number,
                  +friday: ?number,
                  +saturday: ?number,
                  +sunday: ?number,
                  +harvestUser: ?boolean,
                  +role: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +fullName: ?string,
          +initials: ?string,
          +active: ?boolean,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +email: ?string,
          +monday: ?number,
          +tuesday: ?number,
          +wednesday: ?number,
          +thursday: ?number,
          +friday: ?number,
          +saturday: ?number,
          +sunday: ?number,
          +harvestUser: ?boolean,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
  |},
  +$refType: ProjectGroupSettingsPage_viewer$ref,
|};
export type ProjectGroupSettingsPage_viewer$data = ProjectGroupSettingsPage_viewer;
export type ProjectGroupSettingsPage_viewer$key = {
  +$data?: ProjectGroupSettingsPage_viewer$data,
  +$fragmentRefs: ProjectGroupSettingsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProfileTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "harvestUser",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroup",
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "allPersons"
        ]
      }
    ]
  },
  "name": "ProjectGroupSettingsPage_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "groupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectGroupId",
          "storageKey": null
        },
        {
          "alias": "projects",
          "args": null,
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__ProjectGroup_projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimationUnit",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sprintTimeBox",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sprintLengthInDays",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minutesPerEstimationPoint",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "useManualAllocations",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "teamsId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": "projectPersons",
                      "args": null,
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Project_projectPersons_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isContactPerson",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "relationType",
                                  "storageKey": null
                                },
                                (v4/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/),
                                    (v7/*: any*/),
                                    (v8/*: any*/),
                                    {
                                      "alias": null,
                                      "args": (v9/*: any*/),
                                      "concreteType": "ProfileTypeConnection",
                                      "kind": "LinkedField",
                                      "name": "profiles",
                                      "plural": false,
                                      "selections": (v10/*: any*/),
                                      "storageKey": "profiles(first:10000)"
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "permissions",
                                      "storageKey": null
                                    },
                                    (v11/*: any*/),
                                    (v12/*: any*/),
                                    (v13/*: any*/),
                                    (v14/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "isViewer",
                                      "storageKey": null
                                    },
                                    (v15/*: any*/),
                                    (v4/*: any*/),
                                    {
                                      "alias": null,
                                      "args": (v16/*: any*/),
                                      "concreteType": "PersonLabelTypeConnection",
                                      "kind": "LinkedField",
                                      "name": "personLabels",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "PersonLabelTypeEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "PersonLabel",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v0/*: any*/),
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "Label",
                                                  "kind": "LinkedField",
                                                  "name": "label",
                                                  "plural": false,
                                                  "selections": (v3/*: any*/),
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": "personLabels(first:1000)"
                                    }
                                  ],
                                  "storageKey": null
                                },
                                (v17/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v18/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v19/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v17/*: any*/)
                  ],
                  "storageKey": null
                },
                (v18/*: any*/)
              ],
              "storageKey": null
            },
            (v19/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_projectGroup"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_projectGroup"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100000
            }
          ],
          "concreteType": "ProfileTypeConnection",
          "kind": "LinkedField",
          "name": "profiles",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": "profiles(first:100000)"
        },
        {
          "alias": null,
          "args": (v9/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:10000)"
        },
        {
          "alias": null,
          "args": (v9/*: any*/),
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:10000)"
        },
        {
          "alias": null,
          "args": (v16/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": (v16/*: any*/),
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "teamPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v14/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/),
                                    (v7/*: any*/),
                                    (v8/*: any*/),
                                    (v12/*: any*/),
                                    (v13/*: any*/),
                                    (v11/*: any*/),
                                    (v20/*: any*/),
                                    (v21/*: any*/),
                                    (v22/*: any*/),
                                    (v23/*: any*/),
                                    (v24/*: any*/),
                                    (v25/*: any*/),
                                    (v26/*: any*/),
                                    (v15/*: any*/),
                                    (v4/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "teamPersons(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000)"
        },
        {
          "alias": "allPersons",
          "args": null,
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_allPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v14/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v11/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    (v22/*: any*/),
                    (v23/*: any*/),
                    (v24/*: any*/),
                    (v25/*: any*/),
                    (v26/*: any*/),
                    (v15/*: any*/),
                    (v4/*: any*/),
                    (v17/*: any*/)
                  ],
                  "storageKey": null
                },
                (v18/*: any*/)
              ],
              "storageKey": null
            },
            (v19/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ad972094941dce7e22a841a53289f4e';

module.exports = node;
