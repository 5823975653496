import React from 'react';
import {injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import Warning from '../../components/warning';
import GenericModal from './generic_modal';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../constants';

const NoAvailableSeatsModal = ({intl: {formatMessage}, seats, seatType, closeModal, warningMessageId}) => {
	const handleLinkClick = () => {
		if (closeModal) {
			closeModal();
		}
	};

	const content = (
		<div className="default-warning-modal">
			<Warning messageId={warningMessageId} />
			<div className="warning-part-2">
				{formatMessage(
					{id: 'team_modal.seats_warning_action_prevented'},
					{type: seatType || '', max: seats ? `(${seats})` : ''}
				)}
				<Link onClick={() => handleLinkClick()} to="/settings/subscription">
					{formatMessage({id: 'team_modal.seats_warning_subscription_page'})}
				</Link>
			</div>
		</div>
	);

	const modalButtons = [
		{
			text: formatMessage({id: 'common.ok'}),
			style: BUTTON_STYLE.OUTLINE_THICK,
			color: BUTTON_COLOR.LIGHTGREY,
		},
	];

	return <GenericModal buttons={modalButtons} closeModal={closeModal} content={content} />;
};

export default injectIntl(NoAvailableSeatsModal);
