import {graphql} from 'graphql';
import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {createFragmentContainer} from 'react-relay';
import {Dropdown} from 'web-components';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import GenericModal from './generic_modal';
import DirectApi from '../../directApi';

const importExpenseItemModal = ({viewer, closeModal, handleNewExpense, projectId, onSave}) => {
	const [unimportedExpenses, setUnimportedExpenses] = useState(null);
	const [fetchingExpenses, setFetchingExpenses] = useState(true);
	const [selectedPurchase, setSelectedPurchase] = useState([]);

	const intl = useIntl();

	useEffect(() => {
		DirectApi.Fetch('xero/unimportedPurchases').then(expenses => {
			const sortedExpenses = expenses.sort((a, b) => {
				if (a.PurchaseOrderNumber.toUpperCase().trim() > b.PurchaseOrderNumber.toUpperCase().trim()) {
					return 1;
				} else if (a.PurchaseOrderNumber.toUpperCase().trim() < b.PurchaseOrderNumber.toUpperCase().trim()) {
					return -1;
				}
				return 0;
			});

			setUnimportedExpenses(sortedExpenses);
			setFetchingExpenses(false);
		});
	}, []);

	const content = (
		<div className="integration-sync-clients-modal-content">
			<div className="integration-sync-clients-modal-description">
				<FormattedMessage id="integrations.xero.import_purchases.description" />
			</div>
			{fetchingExpenses ? (
				<InlineLoader />
			) : (
				<Dropdown
					usePortal={true}
					selectedItems={selectedPurchase}
					name={intl.formatMessage({id: 'project_budget.expenses.select_purchase'})}
					maxHeight={300}
					onSelect={value => {
						setSelectedPurchase(value);
					}}
					onRemove={() => setSelectedPurchase(null)}
				>
					{unimportedExpenses.map(expense => {
						return (
							<Dropdown.SingleText key={expense.PurchaseOrderID} value={expense}>
								{expense.PurchaseOrderNumber}
							</Dropdown.SingleText>
						);
					})}
				</Dropdown>
			)}
		</div>
	);

	const formatPurchaseNotes = purchase => {
		let notes = '';

		if (purchase.DeliveryAddress) {
			notes += `\n${purchase.DeliveryAddress}\n`;
		}
		if (purchase.AttentionTo) {
			notes += `\n${purchase.AttentionTo}\n`;
		}
		if (purchase.Telephone) {
			notes += `\n${purchase.Telephone} \n`;
		}
		if (purchase.DeliveryInstructions) {
			notes += `\n${purchase.DeliveryInstructions}\n`;
		}

		return notes;
	};

	const getPurchaseInForecastFormat = purchase => {
		const convertedDate = new Date(purchase.DateString.substring(0, 10));
		const initialProject = projectId ? viewer.projects.edges.find(project => project.node.id === projectId) : null;
		const viewerInProject = initialProject
			? initialProject.node.projectPersons.edges.find(pp => pp.node.person.id === viewer.actualPersonId)
			: null;
		const person = viewerInProject ? viewerInProject.node.person : null;

		return {
			purchaseOrderId: purchase.PurchaseOrderID,
			name: purchase.PurchaseOrderNumber,
			cost: purchase.Total,
			price: null,
			markupPercentage: 0,
			expenseYear: convertedDate.getFullYear(),
			expenseMonth: convertedDate.getMonth() + 1,
			expenseDay: convertedDate.getDate(),
			notes: formatPurchaseNotes(purchase),
			person: person,
			project: projectId ? projectId : null,
			phase: null,
			category: null,
			billable: false,
			partOfFixedPrice: false,
			planned: false,
			approved: purchase.Status === 'AUTHORISED' || purchase.Status === 'BILLED',
			quantity: 1,
			nonBillable: null,
			selectedTab: 'general',
			isUploading: false,
			enlargedFileSrc: null,
			files: [],
			filesPreview: [],
			loading: false,
			nameError: null,
		};
	};

	return (
		<GenericModal
			headerText={<FormattedMessage id="integrations.xero.import_purchases" />}
			closeModal={closeModal}
			className="integration-sync-clients-modal"
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'project_budget.create_expense'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => {
						closeModal();
						showModal({
							type: MODAL_TYPE.EXPENSE_ITEM,
							expenseToImport: getPurchaseInForecastFormat(selectedPurchase[0]),
							projectId: projectId,
							onSave: onSave,
							handleNewExpense: handleNewExpense,
							fromXero: true,
						});
					},
					disabled: fetchingExpenses || selectedPurchase.length === 0,
					defaultCallback: () => null,
					preventDefaultClose: true,
				},
			]}
			content={content}
		/>
	);
};
const importExpenseItemModalQuery = graphql`
	query importExpenseItemModal_Query {
		viewer {
			actualPersonId
			component(name: "import_expense_item_modal")
			...importExpenseItemModal_viewer
		}
	}
`;

export {importExpenseItemModalQuery};

export default createFragmentContainer(importExpenseItemModal, {
	viewer: graphql`
		fragment importExpenseItemModal_viewer on Viewer {
			id
			actualPersonId
			projects(excludeDoneOrHalted: true, first: 100000) {
				edges {
					node {
						id
						projectPersons(first: 1000) {
							edges {
								node {
									id
									person {
										id
										fullName
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
