import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import styled from 'styled-components';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import MissionControlHeader from './mission_control_header';
import DatePicker from '../../components/inputs/date_picker';
import UpdateCompanyInternalDataMutation from '../../mutations/mission_control/update_company_internal_data_mutation';
import Util from '../../forecast-app/shared/util/util';
import {injectIntl} from 'react-intl';
import {hasFeatureFlag, setAvailableFeatureFlags} from '../../forecast-app/shared/util/FeatureUtil';
import {TIERS} from '../../constants';
import {Button} from '@forecast-it/design-system';

const CompanyList = styled.div`
	max-height: 100vh;
	overflow: hidden;
`;

class companyStats extends Component {
	constructor(props) {
		super(props);

		setAvailableFeatureFlags(props.viewer.availableFeatureFlags);

		this.state = {
			sortColumn: 'ID',
			sortAsc: true,
			pickerOpenFor: null,
			minChangeOpenFor: null,
			minVirtualChangeOpenFor: null,
			includeExpiredTrials: false,
			includeCurrentTrials: false,
			tierChangeFor: null,
			projectFilter: 'both',
		};
	}

	sort(val) {
		if (this.state.sortColumn === val) {
			this.setState({sortAsc: !this.state.sortAsc});
		} else {
			this.setState({sortAsc: true, sortColumn: val});
		}
	}

	openPicker(companyId) {
		this.setState({pickerOpenFor: companyId});
	}

	exportBetaOptInCSV(companyStatsData) {
		const data = [];
		companyStatsData
			.filter(stat => stat.betaOptIn)
			.forEach(stat => {
				const d = {
					company_id: stat.companyId,
					company_name: stat.companyName,
					tier: Util.getTiersMessage(stat.tier),
					active_persons: stat.numActivePersons,
					projects: stat.numOfProjects,
					tasks: stat.numOfCards,
					time_regs: stat.numTimeRegs,
				};
				data.push(d);
			});
		const columnNames = ['company_id', 'company_name', 'tier', 'active_persons', 'projects', 'tasks', 'time_regs'];
		let exportData = columnNames + '\r\n';
		exportData += Util.JSONToCSV(data);
		const fileName = 'beta_opt_in_companies.csv';
		Util.exportToCSV(exportData, fileName);
	}

	changeTrialDate(companyId, id, datacenter, value) {
		this.setState({pickerOpenFor: null});
		const date = Util.GetYearMonthDateFromMomentDate(value);
		Util.CommitMutation(UpdateCompanyInternalDataMutation, {
			companyId: companyId,
			trialEndYear: date.year,
			trialEndMonth: date.month,
			trialEndDay: date.day,
			datacenter: datacenter,
		});
	}

	shouldChangeToFreeForever(companyId) {
		if (this.state.tierChangeFor === null) {
			this.setState({tierChangeFor: companyId});
		} else {
			this.setState({tierChangeFor: null});
		}
	}

	changeToFreeForever(companyId, datacenter) {
		this.setState({tierChangeFor: null});
		Util.CommitMutation(UpdateCompanyInternalDataMutation, {
			companyId: companyId,
			tier: 'FREE_FOREVER',
			datacenter: datacenter,
		});
	}

	toggleBlock(companyId, id, datacenter) {
		const blocked = this.props.viewer.missionControlData.companyStats.find(
			company => company.companyId === companyId
		).accessBlocked;
		Util.CommitMutation(UpdateCompanyInternalDataMutation, {
			companyId: companyId,
			accessBlocked: !blocked,
			datacenter: datacenter,
		});
	}

	changeShow() {
		this.setState({includeExpiredTrials: !this.state.includeExpiredTrials});
	}

	changeShowCurrent() {
		this.setState({includeCurrentTrials: !this.state.includeCurrentTrials});
	}

	onFilterClick(value) {
		this.setState({projectFilter: value});
	}

	render() {
		const {actualPersonId} = this.props.viewer;
		const isSuperAdmin =
			process.env.CIRCLE_BRANCH !== 'production' ||
			actualPersonId === 'UGVyc29uOjE=' ||
			actualPersonId === 'UGVyc29uOjQ=';

		if (!this.props.viewer.missionControlData) {
			this.props.history.push('/not-found');
			return null;
		}

		if (!this.props.viewer.missionControlData.companyStats) {
			return null;
		}
		let data = cloneDeep(this.props.viewer.missionControlData.companyStats).filter(company => {
			let trialFilter = true;
			let sageFilter = true;
			if (company.tier === 'TRIAL') {
				trialFilter = false;
				const trialEndDate = moment(company.trialEndDate, 'DD/MM/YYYY hh:mm');
				const now = moment();
				if (
					(this.state.includeCurrentTrials && trialEndDate.isAfter(now)) ||
					(this.state.includeExpiredTrials && trialEndDate.isBefore(now))
				) {
					trialFilter = true;
				}
			}
			if (
				(this.state.projectFilter === 'no-sage' && company.isSageCompany) ||
				(this.state.projectFilter === 'only-sage' && !company.isSageCompany)
			) {
				sageFilter = false;
			}
			return trialFilter && sageFilter;
		});
		const direction = this.state.sortAsc ? 1 : -1;

		if (this.state.sortColumn === 'ID') {
			data.sort((a, b) => {
				return a.companyId > b.companyId ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'NAME') {
			data.sort((a, b) => {
				return (a.companyName || '').toUpperCase() > (b.companyName || '').toUpperCase() ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'TIER') {
			data.sort((a, b) => {
				return (a.tier || '').toUpperCase() > (b.tier || '').toUpperCase() ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'END_DATE') {
			data.sort((a, b) => {
				const a_date = moment(a.trialEndDate, 'DD-MM-YYYY HH:mm');
				const b_date = moment(b.trialEndDate, 'DD-MM-YYYY HH:mm');
				return a_date.isBefore(b_date) ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'PROJECTS') {
			data.sort((a, b) => {
				return a.numOfProjects > b.numOfProjects ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'CARDS') {
			data.sort((a, b) => {
				return a.numOfCards > b.numOfCards ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'PERSONS') {
			data.sort((a, b) => {
				return a.numActivePersons > b.numActivePersons ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'TIME') {
			data.sort((a, b) => {
				return a.numTimeRegs > b.numTimeRegs ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'BETA_OPT_IN') {
			data.sort((a, b) => {
				return a.betaOptIn < b.betaOptIn ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'SEATS') {
			data.sort((a, b) => {
				return a.userSeats > b.userSeats ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'UNUSED_SEATS') {
			data.sort((a, b) => {
				const aseats = a.userSeats;
				const aseatsUsed = a.numActivePersons;
				const aunusedSeats = aseats - aseatsUsed;

				const bseats = b.userSeats;
				const bseatsUsed = b.numActivePersons;
				const bunusedSeats = bseats - bseatsUsed;

				return aunusedSeats > bunusedSeats ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'VIRTUAL_SEATS') {
			data.sort((a, b) => {
				return a.virtualSeats > b.virtualSeats ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'VIRTUAL_USERS') {
			data.sort((a, b) => {
				return a.numVirtualUsers > b.numVirtualUsers ? direction : direction * -1;
			});
		} else if (this.state.sortColumn === 'UNUSED_VIRTUAL_SEATS') {
			data.sort((a, b) => {
				const aval = a.virtualSeats - a.numVirtualUsers;
				const bval = b.virtualSeats - b.numVirtualUsers;

				return aval > bval ? direction : direction * -1;
			});
		}

		const style = {position: 'sticky', top: 0, background: 'white'};

		return (
			<CompanyList>
				<div className="mission-control-list">
					<MissionControlHeader viewer={this.props.viewer} />
					<h1 style={{padding: '12px 0 0 0'}}>Company Stats</h1>
					<div style={{display: 'flex', justifyContent: 'space-between', padding: '12px 0'}}>
						<div style={{display: 'flex', gap: '8px'}}>
							<Button emphasis={'medium'} onClick={this.changeShow.bind(this)}>
								{this.state.includeExpiredTrials ? 'Hide expired trials' : 'Show expired trials'}
							</Button>
							<Button emphasis={'medium'} onClick={this.changeShowCurrent.bind(this)}>
								{this.state.includeCurrentTrials ? 'Hide current trials' : 'Show current trials'}
							</Button>
						</div>
						<Button emphasis={'medium'} onClick={() => this.exportBetaOptInCSV(data)}>
							Export Beta Opt-in companies
						</Button>
					</div>
					<div style={{display: 'flex', gap: '4px'}}>
						<input
							type="radio"
							id="both"
							name="company-filter"
							value="both"
							checked={this.state.projectFilter === 'both'}
							onClick={this.onFilterClick.bind(this, 'both')}
						/>
						<label for="both" style={{marginRight: '8px'}}>
							Both
						</label>
						<input
							type="radio"
							id="no-sage"
							name="company-filter"
							value="no-sage"
							checked={this.state.projectFilter === 'no-sage'}
							onClick={this.onFilterClick.bind(this, 'no-sage')}
						/>
						<label for="no-sage" style={{marginRight: '8px'}}>
							No Sage Companies
						</label>
						<input
							type="radio"
							id="only-sage"
							name="company-filter"
							value="only-sage"
							checked={this.state.projectFilter === 'only-sage'}
							onClick={this.onFilterClick.bind(this, 'only-sage')}
						/>
						<label for="only-sage" style={{marginRight: '8px'}}>
							Only Sage Companies
						</label>
					</div>
					<div style={{maxHeight: '90vh', overflowY: 'scroll'}}>
						<table>
							<tbody>
								<tr>
									<th style={style} onClick={this.sort.bind(this, 'ID')}>
										Id
									</th>
									<th style={style} onClick={this.sort.bind(this, 'NAME')}>
										Name
									</th>
									<th style={style} onClick={this.sort.bind(this, 'TIER')}>
										Tier
									</th>
									<th style={style} onClick={this.sort.bind(this, 'ALLOCATION_MODE')}>
										Resourcing Mode
									</th>
									{hasFeatureFlag('get_missioncontrol_data_from_us') && <th style={style}>Datacenter</th>}
									<th style={style} onClick={this.sort.bind(this, 'END_DATE')}>
										Trial end
									</th>
									<th style={style} onClick={this.sort.bind(this, 'BETA_OPT_IN')}>
										Beta Opt-in
									</th>
									<th style={style} onClick={this.sort.bind(this, 'SEATS')}>
										Seats
									</th>
									<th style={style} onClick={this.sort.bind(this, 'PERSONS')}>
										Active Persons
									</th>
									<th style={style} onClick={this.sort.bind(this, 'UNUSED_SEATS')}>
										Unused Seats
									</th>
									<th style={style} onClick={this.sort.bind(this, 'VIRTUAL_SEATS')}>
										Virtual Seats
									</th>
									<th style={style} onClick={this.sort.bind(this, 'VIRTUAL_USERS')}>
										Virtual Users
									</th>
									<th style={style} onClick={this.sort.bind(this, 'UNUSED_VIRTUAL_SEATS')}>
										Unused Virtual Users
									</th>
									<th style={style} onClick={this.sort.bind(this, 'COLLABORATOR_SEATS')}>
										Collaborator Seats
									</th>
									<th style={style} onClick={this.sort.bind(this, 'COLLABORATOR_USERS')}>
										Collaborator Users
									</th>
									<th style={style} onClick={this.sort.bind(this, 'UNUSED_COLLABORATOR_SEATS')}>
										Unused Collaborator Users
									</th>
									<th style={style} onClick={this.sort.bind(this, 'PROJECTS')}>
										Projects
									</th>
									<th style={style} onClick={this.sort.bind(this, 'CARDS')}>
										Tasks
									</th>
									<th style={style} onClick={this.sort.bind(this, 'TIME')}>
										Time regs
									</th>
									<th style={style} onClick={this.sort.bind(this, 'BLOCKED')}>
										Blocked
									</th>
								</tr>
								{data.map(s => {
									let tierChange = null;
									if (this.state.tierChangeFor === s.companyId) {
										tierChange = (
											<div>
												<button
													onClick={this.changeToFreeForever.bind(this, s.companyId, s.datacenter)}
												>
													Are you sure?
												</button>
											</div>
										);
									}
									let tierChangeButton = null;
									if (s.tier === 'TRIAL' && isSuperAdmin) {
										tierChangeButton = (
											<button onClick={this.shouldChangeToFreeForever.bind(this, s.companyId)}>
												Free Forever
											</button>
										);
									}

									let picker = null;
									if (this.state.pickerOpenFor === s.companyId) {
										picker = (
											<DatePicker
												label="Trial end date"
												value={moment.utc(s.trialEndDate, 'DD-MM-YYYY HH:mm')}
												onConfirm={this.changeTrialDate.bind(this, s.companyId, s.id, s.datacenter)}
											/>
										);
									} else if (s.tier === 'TRIAL') {
										picker = <button onClick={this.openPicker.bind(this, s.companyId)}>Change</button>;
									}

									const toggleBlockButton = (
										<button onClick={this.toggleBlock.bind(this, s.companyId, s.id, s.datacenter)}>
											{s.accessBlocked ? 'Unblock' : 'Block'}
										</button>
									);

									const seats = s.userSeats;
									const seatsUsed = s.numActivePersons;
									const unusedSeats = seats - seatsUsed;

									const paying = s.tier !== 'TRIAL' && s.tier !== 'FREE_FOREVER';

									const allocationMode = s.isUsingMixedAllocation
										? 'Combined Mode'
										: s.isUsingProjectAllocation
										? 'Project allocation'
										: s.isUsingSchedulingPlanMode
										? 'Task estimates'
										: 'Task actuals & remaining';

									return (
										<tr key={s.companyId}>
											<td>{s.companyId}</td>
											<td>{s.companyName}</td>
											<td>
												{Util.getTiersMessage(s.tier)}
												{tierChangeButton}
												{tierChange}
											</td>
											<td>{allocationMode}</td>
											{hasFeatureFlag('get_missioncontrol_data_from_us') && <td>{s.datacenter}</td>}
											<td>
												{s.trialEndDate} {picker}
											</td>
											<td>{s.betaOptIn ? 'Yes' : 'No'}</td>
											<td>{seats}</td>
											<td>{seatsUsed}</td>
											<td>{paying ? unusedSeats : '-'}</td>
											<td>{s.virtualSeats}</td>
											<td>{s.numVirtualUsers}</td>
											<td>
												{[TIERS.LITE, TIERS.PRO, TIERS.ENTERPRISE, TIERS.CORE].indexOf(s.tier) > -1
													? s.virtualSeats - s.numVirtualUsers
													: '-'}
											</td>
											<td>{s.collaboratorSeats}</td>
											<td>{s.numCollaboratorUsers}</td>
											<td>
												{[TIERS.LITE, TIERS.PRO, TIERS.ENTERPRISE, TIERS.CORE].indexOf(s.tier) > -1
													? s.collaboratorSeats - s.numCollaboratorUsers
													: '-'}
											</td>
											<td>{s.numOfProjects}</td>
											<td>{s.numOfCards}</td>
											<td>{s.numTimeRegs}</td>
											<td>
												{s.accessBlocked ? 'Yes' : 'No'} {toggleBlockButton}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</CompanyList>
		);
	}
}

const companyStatsQuery = graphql`
	query companyStats_Query {
		viewer {
			actualPersonId
			component(name: "control_company_stats")
			...companyStats_viewer
		}
	}
`;

export {companyStatsQuery};

export default createFragmentContainer(injectIntl(withRouter(companyStats)), {
	viewer: graphql`
		fragment companyStats_viewer on Viewer {
			id
			...missionControlHeader_viewer
			availableFeatureFlags {
				key
			}
			actualPersonId
			missionControlData {
				id
				companyStats {
					id
					companyId
					companyName
					tier
					trialEndDate
					numOfProjects
					numOfCards
					numActivePersons
					numTimeRegs
					minimumSeats
					minimumVirtualSeats
					accessBlocked
					userSeats
					virtualSeats
					collaboratorSeats
					numOfClientUsers
					numVirtualUsers
					numCollaboratorUsers
					datacenter
					betaOptIn
					isUsingProjectAllocation
					isUsingSchedulingPlanMode
					isUsingMixedAllocation
					isSageCompany
				}
			}
		}
	`,
});
