import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import ImportSageIntacctCustomersMutation from '../../../mutations/ImportSageIntacctCustomersMutation';
import { hideLoader, showLoader } from '../../global_loader';
import DirectApi from '../../../directApi';
import { Checkbox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, SubHeading, Text, } from '@forecast-it/design-system';
import styled from 'styled-components/macro';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { HorizontalBar } from './SageIntacctModal.styled';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 800 ? '200px' : '500px';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '1px solid #ccc' : 'none')};
`;
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const SageIntacctImportCustomersModal = ({ closeModal, companyId }) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const [sageIntacctCustomers, setSageIntacctCustomers] = useState([]);
    const [fetchingCustomers, setFetchingCustomers] = useState(true);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [importing, setImporting] = useState(false);
    const [failed, setFailed] = useState(false);
    const disableSyncButton = importing || selectedCustomers.length === 0;
    useEffect(() => {
        DirectApi.Fetch('sage_intacct/unimported_customers').then(res => {
            if (res === null || res === void 0 ? void 0 : res.customers) {
                setSageIntacctCustomers(res.customers.sort((a, b) => (a.NAME.toUpperCase().trim() > b.NAME.toUpperCase().trim() ? 1 : -1)));
                setFetchingCustomers(false);
            }
            else if (res === null || res === void 0 ? void 0 : res.error) {
                setFailed(true);
                setFetchingCustomers(false);
            }
            else {
                setSageIntacctCustomers([]);
                setFetchingCustomers(false);
            }
        });
    }, []);
    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedCustomers([]);
            setSelectAll(false);
        }
        else {
            setSelectedCustomers(sageIntacctCustomers.map(customer => customer.CUSTOMERID));
            setSelectAll(true);
        }
    };
    const toggleCustomer = (sageIntacctCustomerId) => {
        setSelectAll('indeterminate');
        if (selectedCustomers.includes(sageIntacctCustomerId)) {
            const tempCustomers = selectedCustomers.filter(id => id !== sageIntacctCustomerId);
            setSelectedCustomers(tempCustomers);
            if (tempCustomers.length === 0) {
                setSelectAll(false);
            }
        }
        else {
            const newSelectedCustomers = [...selectedCustomers, sageIntacctCustomerId];
            if (newSelectedCustomers.length === sageIntacctCustomers.length) {
                setSelectAll(true);
            }
            setSelectedCustomers(newSelectedCustomers);
        }
    };
    const syncContacts = () => {
        setImporting(true);
        showLoader();
        Util.CommitMutation(ImportSageIntacctCustomersMutation, {
            sageIntacctCustomerIds: selectedCustomers,
            companyId: companyId,
        }, () => {
            setImporting(false);
            closeModal();
            hideLoader();
            createToast({
                duration: 5000,
                message: `${selectedCustomers.length} Clients imported from Sage Intacct`,
            });
        });
    };
    return (React.createElement(CreativeModal, { title: React.createElement(FormattedMessage, { id: "integrations.sage_intacct.sync_clients" }), size: 'l', closeModal: closeModal, creativeButtonText: `Import Clients (${selectedCustomers.length})`, cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: syncContacts, onCancelHandler: closeModal, disabled: disableSyncButton, replicateDesignSystem: true },
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Text, { color: Text.color.medium }, "Select the Sage Intacct customers that you would like to synchronize to Forecast as clients.")),
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: toggleSelectAll, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null, fetchingCustomers ? (React.createElement(InlineLoader, null)) : (React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
            React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, sageIntacctCustomers &&
                sageIntacctCustomers.map((cust) => {
                    return (React.createElement(ListItem, { key: 'department-' + cust.CUSTOMERID, hasBorderBottom: true },
                        React.createElement(FlexRow, { gap: 'xl' },
                            React.createElement(Checkbox, { onChange: () => toggleCustomer(cust.CUSTOMERID), checked: selectedCustomers.includes(cust.CUSTOMERID) }),
                            React.createElement(Text, { size: '2' }, cust.NAME))));
                }))))),
        React.createElement(HorizontalBar, null)));
};
export default SageIntacctImportCustomersModal;
