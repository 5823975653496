import React, { useState } from 'react';
import { Label, FlexRow, Modal, Text, FlexColumn, RadioGroupHandler, Frame } from '@forecast-it/design-system';
import { useIntl, FormattedMessage } from 'react-intl';
import { PERIOD_BUDGET_TYPE } from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import DeleteRetainerPeriodMutation from '../../../mutations/delete_retainer_period_mutation';
import DeleteRetainerPeriodBulkMutation from '../../../mutations/delete_retainer_period_bulk_mutation';
import { dispatch, EVENT_ID } from '../../../containers/event_manager';
const uniquePeriodFromNames = (rollovers) => [...new Set(rollovers.map(rollover => { var _a; return (_a = rollover.periodFrom) === null || _a === void 0 ? void 0 : _a.name; }))];
const DeleteRetainerPeriodModal = ({ rollovers, periodIds, projectId, periodBudgetType, currency, closeModal, }) => {
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [revertRollovers, setRevertRollovers] = useState('false');
    const intl = useIntl();
    const { formatNumber } = intl;
    const hasRollovers = rollovers.length > 0;
    const subtractedRollovers = rollovers.filter(rollover => rollover.amount && rollover.amount < 0);
    const addedRollovers = rollovers.filter(rollover => rollover.amount && rollover.amount >= 0);
    const hasSubtractedRollovers = subtractedRollovers.length > 0;
    const hasAddedRollovers = addedRollovers.length > 0;
    const isFixedPrice = periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE;
    const isBulk = periodIds.length > 1;
    const saveChanges = () => {
        if (hasRollovers && !deletionConfirmed) {
            setDeletionConfirmed(true);
        }
        else {
            const onSuccess = () => {
                dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
                closeModal();
            };
            const keepRollovers = revertRollovers === 'false';
            if (isBulk) {
                Util.CommitMutation(DeleteRetainerPeriodBulkMutation, {
                    ids: periodIds,
                    projectId,
                    keepRollovers,
                }, onSuccess);
            }
            else {
                Util.CommitMutation(DeleteRetainerPeriodMutation, {
                    id: periodIds[0],
                    projectId,
                    keepRollovers,
                }, onSuccess);
            }
        }
    };
    const formatRolloverAmount = (rollover) => {
        var _a, _b;
        return isFixedPrice
            ? Util.getFormattedCurrencyValue(Util.GetCurrencySymbol(currency), formatNumber(Math.abs((_a = rollover.amount) !== null && _a !== void 0 ? _a : 0), {
                format: 'always_two_decimal',
            }))
            : Util.convertMinutesToFullHour(Math.abs((_b = rollover.amount) !== null && _b !== void 0 ? _b : 0) * 60, intl);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: isBulk ? 'retainer.delete_periods' : 'retainer.delete_period' })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'l' }, deletionConfirmed ? (React.createElement(React.Fragment, null,
                hasAddedRollovers && (React.createElement(FlexColumn, { gap: 's' },
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: isBulk
                                ? isFixedPrice
                                    ? 'retainer.delete_period.add_info_bulk_price'
                                    : 'retainer.delete_period.add_info_bulk_hours'
                                : isFixedPrice
                                    ? 'retainer.delete_period.add_info_price'
                                    : 'retainer.delete_period.add_info_hours' })),
                    React.createElement(Frame, null,
                        React.createElement(FlexColumn, { gap: 'xxs' }, isBulk
                            ? uniquePeriodFromNames(addedRollovers).map(name => React.createElement(Text, null, name))
                            : addedRollovers.map(rollover => rollover.periodTo ? (React.createElement(Text, null, `${rollover.periodTo.name} (${formatRolloverAmount(rollover)})`)) : null))))),
                hasSubtractedRollovers && (React.createElement(FlexColumn, { gap: 's' },
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: isBulk
                                ? isFixedPrice
                                    ? 'retainer.delete_period.subtract_info_bulk_price'
                                    : 'retainer.delete_period.subtract_info_bulk_hours'
                                : isFixedPrice
                                    ? 'retainer.delete_period.subtract_info_price'
                                    : 'retainer.delete_period.subtract_info_hours' })),
                    React.createElement(Frame, null,
                        React.createElement(FlexColumn, { gap: 'xxs' }, isBulk
                            ? uniquePeriodFromNames(subtractedRollovers).map(name => React.createElement(Text, null, name))
                            : subtractedRollovers.map(rollover => rollover.periodTo ? (React.createElement(Text, null, `${rollover.periodTo.name} (${formatRolloverAmount(rollover)})`)) : null))))),
                React.createElement(FlexColumn, { gap: 'xxs' },
                    React.createElement(Label, null,
                        React.createElement(FormattedMessage, { id: 'retainer.delete_period.confirm_remove_' +
                                (hasAddedRollovers ? 'add_' : '') +
                                (hasAddedRollovers && hasSubtractedRollovers ? 'and_' : '') +
                                (hasSubtractedRollovers ? 'substract_' : '') +
                                (isBulk && hasAddedRollovers && !hasSubtractedRollovers ? 'bulk_' : '') +
                                (isFixedPrice ? 'price' : 'hours') }))),
                React.createElement(RadioGroupHandler, { onValueChange: setRevertRollovers, value: revertRollovers },
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: 'true' }),
                        React.createElement(Text, null,
                            React.createElement(FormattedMessage, { id: 'retainer.delete_period.remove_' +
                                    (hasAddedRollovers ? 'add_' : '') +
                                    (hasAddedRollovers && hasSubtractedRollovers ? 'and_' : '') +
                                    (hasSubtractedRollovers ? 'substract_' : '') +
                                    (isFixedPrice ? 'price' : 'hours') }))),
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: 'false' }),
                        React.createElement(Text, null,
                            React.createElement(FormattedMessage, { id: 'retainer.delete_period.keep_' +
                                    (hasAddedRollovers ? 'add_' : '') +
                                    (hasAddedRollovers && hasSubtractedRollovers ? 'and_' : '') +
                                    (hasSubtractedRollovers ? 'substract_' : '') +
                                    (isFixedPrice ? 'price' : 'hours') })))))) : (React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: isBulk ? 'retainer.delete_confirmation_bulk' : 'retainer.delete_confirmation' }))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges, dataCy: 'modal-btn-confirm-delete', disabled: deletionConfirmed && revertRollovers === null },
                React.createElement(FormattedMessage, { id: "common.confirm" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: "common.cancel" })))));
};
export default DeleteRetainerPeriodModal;
