import {FormattedMessage, injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import React, {useState} from 'react';
import Styled from 'styled-components';
import moment from 'moment';
import UpdateFixedPriceLockMutation from '../../mutations/update_fixed_price_lock_mutation';
import Util from '../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import DeleteFixedPriceLockMutation from '../../mutations/delete_fixed_price_lock_mutation';
import {calculateOptions, getLockedPeriodByDate} from '../../components/new-ui/project/project-budget-v3/util/PeriodLocks';
import {Dropdown} from 'web-components';

const ContentWrapper = Styled.div`
	display: flex;
	width: 400px;
	align-items: baseline;
	height: 100px;
	font-size: 13px;
`;

const BodyWrapper = Styled.div`
	display: flex;
	flex-direction: column;
`;

const Body = Styled.div`
    padding-bottom: 10px
	flex: 1;
`;

const DropdownWrapper = Styled.div`
	width: 150px;
	margin-right: 16px;
`;

const DropdownLabel = Styled.div`
	margin-right: 16px;
`;

const getLatestLock = lockedPeriods => {
	let latestPeriod = null;
	// Next lock is either the period after the latest locked period on the project
	// or the period containing the project start date if no locks exist yet.
	if (lockedPeriods.length > 0) {
		lockedPeriods.forEach(lock => {
			if (lock.locked && (!latestPeriod || latestPeriod.startDate < lock.startDate)) {
				latestPeriod = lock;
			}
		});
	}
	return latestPeriod;
};

const fixedPriceOpenLockModal = ({closeModal, project, month, intl: {formatMessage}, callback}) => {
	const hasManualRecognition = hasFeatureFlag('manual_revenue_recognition');
	const lockedPeriods = project.fixedPriceLocks.edges.map(lockedPeriod => lockedPeriod.node);
	const latestLock = getLatestLock(lockedPeriods);
	const latesLockStartDate = moment(latestLock.startDate).startOf('month');
	const options = calculateOptions(project, null, false);
	const preSelectedMonth = options.find(option => moment(option.value).format('YYYY-MM') === month);

	const [selectedOption, setSelectedOption] = useState(
		hasManualRecognition ? (preSelectedMonth ? preSelectedMonth.value : options[0].value) : null
	);
	const [saving, setSaving] = useState(false);

	const updateFixedPriceLock = lockId => {
		Util.CommitMutation(UpdateFixedPriceLockMutation, {id: lockId, locked: false});
	};
	const deleteFixedPriceLock = lockId => {
		Util.CommitMutation(DeleteFixedPriceLockMutation, {fixedPriceLockId: lockId});
	};

	const onSave = latestLock => {
		setSaving(true);
		if (hasManualRecognition) {
			if (selectedOption) {
				const lockedPeriod = getLockedPeriodByDate(moment(selectedOption), lockedPeriods);
				updateFixedPriceLock(lockedPeriod.id);
			}
		} else {
			deleteFixedPriceLock(latestLock.id);
		}
		callback();
	};

	const onPeriodSelect = value => {
		setSelectedOption(value);
	};

	const content = (
		<ContentWrapper>
			{hasManualRecognition ? (
				<DropdownLabel>{formatMessage({id: 'fixed_price_lock.unlock_message'})}</DropdownLabel>
			) : (
				<BodyWrapper>
					<Body>
						<FormattedMessage
							id="fixed_price_lock.unlock_message_1"
							values={{period: <b>{latesLockStartDate.format('MMM YYYY')}</b>}}
						/>
					</Body>
					<Body>
						<FormattedMessage
							id="fixed_price_lock.unlock_message_2"
							values={{period: <b>{latesLockStartDate.format('MMM YYYY')}</b>}}
						/>
					</Body>
				</BodyWrapper>
			)}
			{hasManualRecognition && (
				<DropdownWrapper>
					<Dropdown
						usePortal={true}
						disabled={saving}
						name={''}
						selectedItems={[selectedOption]}
						onSelect={value => onPeriodSelect(value[0])}
					>
						{options.map(option => (
							<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
								{option.label}
							</Dropdown.SingleText>
						))}
					</Dropdown>
				</DropdownWrapper>
			)}
			?
		</ContentWrapper>
	);

	return (
		<GenericModal
			className="fixed-price-lock-modal-budget"
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => onSave(latestLock),
				},
			]}
			headerText={formatMessage({id: 'fixed_price_lock.unlock_modal_button'})}
			content={content}
		/>
	);
};

export default injectIntl(fixedPriceOpenLockModal);
