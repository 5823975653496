/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type maintenance_viewer$ref = any;
export type maintenance_QueryVariables = {||};
export type maintenance_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: maintenance_viewer$ref,
  |}
|};
export type maintenance_Query = {|
  variables: maintenance_QueryVariables,
  response: maintenance_QueryResponse,
|};
*/


/*
query maintenance_Query {
  viewer {
    component(name: "maintenance")
    ...maintenance_viewer
    id
  }
}

fragment maintenance_viewer on Viewer {
  component(name: "maintenance")
  downForMaintenance
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "maintenance"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"maintenance\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "maintenance_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "maintenance_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "maintenance_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downForMaintenance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "maintenance_Query",
    "operationKind": "query",
    "text": "query maintenance_Query {\n  viewer {\n    component(name: \"maintenance\")\n    ...maintenance_viewer\n    id\n  }\n}\n\nfragment maintenance_viewer on Viewer {\n  component(name: \"maintenance\")\n  downForMaintenance\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7cada36d13ea0860fe5d9b27f09eef8b';

module.exports = node;
