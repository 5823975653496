import React, {Component} from 'react';
import {FormattedDate, FormattedHTMLMessage, injectIntl} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DateRangePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../constants';
import SuppressTimeRegOutsideProjectPeriodWarningMutation from '../../mutations/suppress_time_reg_outside_project_period_warning_mutation';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';

class TimeRegsOutsideProjectPeriodModal extends Component {
	constructor(props) {
		super(props);
		const {project} = this.props;

		this.state = {
			projectStartDate: Util.CreateNonUtcMomentDate(
				project.projectStartYear,
				project.projectStartMonth,
				project.projectStartDay
			),
			projectEndDate: Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay),
		};

		this.handleOnOk = this.handleOnOk.bind(this);
		this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
	}

	handleOnOk() {
		const {projectStartDate, projectEndDate} = this.state;
		const startDate = Util.GetYearMonthDateFromMomentDate(projectStartDate);
		const endDate = Util.GetYearMonthDateFromMomentDate(projectEndDate);
		const {onSuccess, project} = this.props;

		Util.CommitMutation(UpdateProjectMutation, {
			project,
			projectStartYear: startDate.year,
			projectStartMonth: startDate.month,
			projectStartDay: startDate.day,
			projectEndYear: endDate.year,
			projectEndMonth: endDate.month,
			projectEndDay: endDate.day,
		});
		Util.CommitMutation(
			SuppressTimeRegOutsideProjectPeriodWarningMutation,
			{
				projectId: project.id,
			},
			onSuccess
		);
	}

	handleDateRangeChange(projectStartDate, projectEndDate) {
		this.setState({
			projectStartDate,
			projectEndDate,
		});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {timeRegistrations} = this.props;
		const {projectStartDate, projectEndDate} = this.state;

		const earliestTimeRegDate = timeRegistrations.edges
			.map(timeRegistration =>
				Util.CreateNonUtcMomentDate(timeRegistration.node.year, timeRegistration.node.month, timeRegistration.node.day)
			)
			.sort((a, b) => {
				return b - a;
			})
			.pop();

		const content = (
			<div className="auto-scheduling-modal time-reg-on-project-modal">
				<div className="warning-description-large">
					<FormattedHTMLMessage id="project_budget.time_entries_outside_project_period_description_1" />
				</div>
				<div className="warning-description-large">
					<DateRangePicker
						startDate={projectStartDate}
						endDate={projectEndDate}
						handleDateRangeChange={this.handleDateRangeChange}
						label={formatMessage({id: 'common.date'})}
						isNewDateRange={true}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						highlighted={true}
						focusStart={earliestTimeRegDate.isBefore(projectStartDate)}
						focusEnd={!earliestTimeRegDate.isBefore(projectStartDate)}
					/>
				</div>
				<div className="warning-description-large time_reg_description">
					<FormattedHTMLMessage id="project_budget.time_entries_outside_project_period_description_2" />
				</div>
				<div className="table-heading-row">
					<div className="scrollable-table">
						<div className="headers">
							<div className="header column-task">{formatMessage({id: 'common.task'})}</div>
							<div className="header column-date">{formatMessage({id: 'common.date'})}</div>
							<div className="header column-entry">{formatMessage({id: 'common.time_entry'})}</div>
							<div className="header column-last" />
						</div>

						<div className="rows">
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
								{timeRegistrations.edges.map(timeRegistration => (
									<div key={timeRegistration.node.id} className="row">
										<div className="column-task">{timeRegistration.node.task.name}</div>
										<div className="column-date">
											<FormattedDate
												value={Util.CreateNonUtcMomentDate(
													timeRegistration.node.year,
													timeRegistration.node.month,
													timeRegistration.node.day
												)}
												year="numeric"
												month="short"
												day="numeric"
											/>
										</div>
										<div className="column-entry">
											{Util.convertMinutesToFullHour(
												timeRegistration.node.minutesRegistered,
												this.props.intl,
												true
											)}
										</div>
										<div className="column-last">
											<div className="chip-container">
												<div className="chip-div" />
												<div className="chip" />
											</div>
										</div>
									</div>
								))}
							</CustomScrollDiv>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.save'}),
						callback: this.handleOnOk,
					},
				]}
				content={content}
				headerText={formatMessage({id: 'project_budget.time_entries_outside_project_period'})}
			/>
		);
	}
}

TimeRegsOutsideProjectPeriodModal.propTypes = {};

export default injectIntl(TimeRegsOutsideProjectPeriodModal);
