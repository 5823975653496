import React from 'react';
import {LockIcon} from 'web-components';
import {WarningIcon} from 'web-components/icons';
import {TitleWrapper, WarningIconWrapper} from '../project_health_styles/ProjectHealthTableSection.styled';

export const TitleStatusColumn = ({title, warningVariant, disabled}) => {
	return (
		<>
			<WarningIconWrapper>
				{warningVariant ? (
					<WarningIcon variant={warningVariant} />
				) : (
					<LockIcon color={'#C4C4CC'} size={LockIcon.SIZE.STANDARD} />
				)}
			</WarningIconWrapper>
			<TitleWrapper disabled={disabled}>{title}</TitleWrapper>
		</>
	);
};
