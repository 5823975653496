/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PredictedEndDateModalStatsSection_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllocationAssistanceModal_project$ref: FragmentReference;
declare export opaque type AllocationAssistanceModal_project$fragmentType: AllocationAssistanceModal_project$ref;
export type AllocationAssistanceModal_project = {|
  +id: string,
  +name: ?string,
  +$fragmentRefs: PredictedEndDateModalStatsSection_project$ref,
  +$refType: AllocationAssistanceModal_project$ref,
|};
export type AllocationAssistanceModal_project$data = AllocationAssistanceModal_project;
export type AllocationAssistanceModal_project$key = {
  +$data?: AllocationAssistanceModal_project$data,
  +$fragmentRefs: AllocationAssistanceModal_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllocationAssistanceModal_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PredictedEndDateModalStatsSection_project"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'ccd989af222d51ebce06206eb2d684a1';

module.exports = node;
