import React, {Component} from 'react';
import {EVENT_ID, subscribe, unsubscribe, dispatch} from './event_manager';
import InlineLoader from '../forecast-app/shared/components/inline-loader/inline_loader';
import styled from 'styled-components';

export const showLoader = message => dispatch(EVENT_ID.SHOW_LOADER, message);
export const hideLoader = message => dispatch(EVENT_ID.HIDE_LOADER, message);

const GlobalLoaderWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
`;

export class GlobalLoader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowingLoader: false,
		};

		this.showLoader = this.showLoader.bind(this);
		this.hideLoader = this.hideLoader.bind(this);
	}

	componentDidMount() {
		subscribe(EVENT_ID.SHOW_LOADER, this.showLoader);
		subscribe(EVENT_ID.HIDE_LOADER, this.hideLoader);
	}

	componentWillUnmount() {
		unsubscribe(EVENT_ID.SHOW_LOADER, this.showLoader);
		unsubscribe(EVENT_ID.HIDE_LOADER, this.hideLoader);
	}

	showLoader() {
		this.setState({isShowingLoader: true});
	}

	hideLoader() {
		this.setState({isShowingLoader: false});
	}

	render() {
		return this.state.isShowingLoader ? (
			<GlobalLoaderWrapper>
				<InlineLoader />
			</GlobalLoaderWrapper>
		) : null;
	}
}
