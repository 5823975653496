import React, {Component} from 'react';
import * as tracking from '../tracking';
import BgPic from '../images/sections/not-found/bg.png';
import {FormattedMessage} from 'react-intl';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';
import Util from '../forecast-app/shared/util/util';
import styled from 'styled-components';
import {SEARCH_SHORTCUT} from '../forecast-app/navigation/header/global-search/GlobalSearchButton';

const SearchLink = styled.span`
	text-decoration: underline;
	color: blue;
	cursor: pointer;
`;

class NotFound extends Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('Not Found');
	}

	componentDidMount() {
		document.title = '404 Not found - Forecast';
		tracking.trackPage('not-found');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSearchLinkClick() {
		// Little hack to trigger the search modal.
		// Since it can't be invoked through e.g. generic modal conductor, we instead trigger it by synthetically invoking the shortcut.
		window.dispatchEvent(new KeyboardEvent('keydown', {key: SEARCH_SHORTCUT}));
	}

	render() {
		const queryParams = Util.getQueryParams(window.location.search);
		const projectId = queryParams?.projectId?.[0];
		return (
			<div className="not-found">
				<div className="text-container">
					<h1>
						{projectId ? (
							<FormattedMessage id={'not_found.project_header'} values={{projectId}} />
						) : (
							<FormattedMessage id="not_found.header" />
						)}
					</h1>
					<br />
					<br />
					{projectId ? (
						<p>
							<FormattedMessage id={'not_found.things_to_try'} />
							<ul>
								<li>
									<FormattedMessage
										id={'not_found.search_project'}
										values={{
											span: msg => (
												<SearchLink onClick={() => this.handleSearchLinkClick()}>{msg}</SearchLink>
											),
										}}
									/>
								</li>
								<li>
									<FormattedMessage id={'not_found.contact_owner'} />
								</li>
								<li>
									<FormattedMessage id={'not_found.contact_support'} />
								</li>
							</ul>
						</p>
					) : (
						<>
							<p>
								<FormattedMessage id="not_found.paragraph_1" />
							</p>
							<p>
								<FormattedMessage id="not_found.paragraph_2" />
							</p>
						</>
					)}
				</div>
				<div className="image-container">
					<img src={BgPic} alt="" />
				</div>
			</div>
		);
	}
}

export default NotFound;
