/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type profileSecurity_viewer$ref = any;
export type profileSecurity_QueryVariables = {||};
export type profileSecurity_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: profileSecurity_viewer$ref,
  |}
|};
export type profileSecurity_Query = {|
  variables: profileSecurity_QueryVariables,
  response: profileSecurity_QueryResponse,
|};
*/


/*
query profileSecurity_Query {
  viewer {
    actualPersonId
    component(name: "settings_security")
    ...profileSecurity_viewer
    id
  }
}

fragment SettingsProfileSubNav_viewer on Viewer {
  id
  projectOwner
  disableNotifications
  company {
    id
    forecastDemo
    tier
  }
}

fragment profileSecurity_viewer on Viewer {
  id
  ...SettingsProfileSubNav_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "settings_security"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"settings_security\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "profileSecurity_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "profileSecurity_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "profileSecurity_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectOwner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disableNotifications",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forecastDemo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "profileSecurity_Query",
    "operationKind": "query",
    "text": "query profileSecurity_Query {\n  viewer {\n    actualPersonId\n    component(name: \"settings_security\")\n    ...profileSecurity_viewer\n    id\n  }\n}\n\nfragment SettingsProfileSubNav_viewer on Viewer {\n  id\n  projectOwner\n  disableNotifications\n  company {\n    id\n    forecastDemo\n    tier\n  }\n}\n\nfragment profileSecurity_viewer on Viewer {\n  id\n  ...SettingsProfileSubNav_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '38021a12d392d14ec1c0fedd457f56fb';

module.exports = node;
