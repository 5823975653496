import React from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {ModalText, ModalWrapper} from './modal_styled';

const HTMLTextButtonsModal = ({
	closeModal,
	intl: {formatMessage},
	callbackPositive,
	headerTextId,
	buttonTextId,
	buttonDanger,
	modalTextIds,
	cy,
}) => {
	const content = (
		<ModalWrapper>
			{modalTextIds.map(id => {
				return <ModalText>{<FormattedHTMLMessage id={id} />}</ModalText>;
			})}
		</ModalWrapper>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: cy + '-button-negative',
				},
				{
					text: formatMessage({id: buttonTextId}),
					style: BUTTON_STYLE.FILLED,
					color: buttonDanger ? BUTTON_COLOR.RED : BUTTON_COLOR.GREEN,
					callback: callbackPositive.bind(this),
					cy: cy + '-button-positive',
				},
			]}
			headerText={formatMessage({id: headerTextId})}
			content={content}
		/>
	);
};

export default injectIntl(HTMLTextButtonsModal);
