/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectHeader_project$ref = any;
type ProjectHeader_psProject$ref = any;
type SecondaryNavigation_project$ref = any;
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectFiles_viewer$ref: FragmentReference;
declare export opaque type projectFiles_viewer$fragmentType: projectFiles_viewer$ref;
export type projectFiles_viewer = {|
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +gdriveEnabled: ?boolean
  |},
  +project: ?{|
    +id: string,
    +name: ?string,
    +isInProjectGroup: ?boolean,
    +projectGroupId: ?string,
    +projectColor: ?string,
    +companyProjectId: ?number,
    +status: ?PROJECT_STATUS,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
    +googleDriveFiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: ?string,
          +name: ?string,
          +link: ?string,
          +thumb: ?string,
          +createdAt: ?string,
          +person: ?{|
            +id: string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
            +firstName: ?string,
            +lastName: ?string,
            +role: ?{|
              +name: ?string
            |},
          |},
          +folder: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +files: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +mimeType: ?string,
          +key: ?string,
          +size: ?number,
          +yearCreated: ?number,
          +monthCreated: ?number,
          +dayCreated: ?number,
          +hourCreated: ?number,
          +minuteCreated: ?number,
          +secondCreated: ?number,
          +person: ?{|
            +id: string,
            +profilePictureId: ?string,
            +firstName: ?string,
            +lastName: ?string,
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +folder: ?{|
            +id: string,
            +name: ?string,
          |},
          +task: ?{|
            +id: string,
            +name: ?string,
            +companyTaskId: ?number,
          |},
        |}
      |}>
    |},
    +folders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +yearCreated: ?number,
          +monthCreated: ?number,
          +dayCreated: ?number,
          +hourCreated: ?number,
          +minuteCreated: ?number,
          +secondCreated: ?number,
          +person: ?{|
            +id: string,
            +profilePictureId: ?string,
            +firstName: ?string,
            +lastName: ?string,
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +project: ?{|
            +id: string
          |},
          +folder: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +companyTaskId: ?number,
          +name: ?string,
          +highPriority: ?boolean,
          +statusColumn: ?{|
            +id: string,
            +name: ?string,
          |},
          +googleDriveFiles: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: ?string,
                +name: ?string,
                +link: ?string,
                +thumb: ?string,
                +createdAt: ?string,
                +person: ?{|
                  +id: string,
                  +profilePictureId: ?string,
                  +firstName: ?string,
                  +lastName: ?string,
                  +role: ?{|
                    +name: ?string
                  |},
                |},
                +folder: ?{|
                  +id: string,
                  +name: ?string,
                |},
              |}
            |}>
          |},
          +files: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +mimeType: ?string,
                +key: ?string,
                +size: ?number,
                +yearCreated: ?number,
                +monthCreated: ?number,
                +dayCreated: ?number,
                +hourCreated: ?number,
                +minuteCreated: ?number,
                +secondCreated: ?number,
                +person: ?{|
                  +id: string,
                  +profilePictureId: ?string,
                  +firstName: ?string,
                  +lastName: ?string,
                  +role: ?{|
                    +id: string,
                    +name: ?string,
                  |},
                |},
                +folder: ?{|
                  +id: string,
                  +name: ?string,
                |},
                +task: ?{|
                  +id: string,
                  +name: ?string,
                  +companyTaskId: ?number,
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +$fragmentRefs: ProjectHeader_project$ref & SecondaryNavigation_project$ref,
  |},
  +psProject: ?{|
    +$fragmentRefs: ProjectHeader_psProject$ref
  |},
  +$refType: projectFiles_viewer$ref,
|};
export type projectFiles_viewer$data = projectFiles_viewer;
export type projectFiles_viewer$key = {
  +$data?: projectFiles_viewer$data,
  +$fragmentRefs: projectFiles_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumb",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Folder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearCreated",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monthCreated",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayCreated",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hourCreated",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minuteCreated",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondCreated",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FileTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mimeType",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ],
    "storageKey": null
  },
  (v15/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "googleDriveFiles"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "files"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "folders"
        ]
      },
      (v0/*: any*/),
      (v0/*: any*/)
    ]
  },
  "name": "projectFiles_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gdriveEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInProjectGroup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": "googleDriveFiles",
          "args": null,
          "concreteType": "GoogleDriveFileTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_googleDriveFiles_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GoogleDriveFileTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GoogleDriveFile",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureDefaultId",
                          "storageKey": null
                        },
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v12/*: any*/),
                    (v13/*: any*/)
                  ],
                  "storageKey": null
                },
                (v14/*: any*/)
              ],
              "storageKey": null
            },
            (v15/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "files",
          "args": null,
          "concreteType": "FileTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_files_connection",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": null
        },
        {
          "alias": "folders",
          "args": null,
          "concreteType": "FolderTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_folders_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FolderTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Folder",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v16/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    (v22/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v12/*: any*/),
                    (v13/*: any*/)
                  ],
                  "storageKey": null
                },
                (v14/*: any*/)
              ],
              "storageKey": null
            },
            (v15/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10000
            }
          ],
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "tasks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v23/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "highPriority",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumn",
                      "kind": "LinkedField",
                      "name": "statusColumn",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "googleDriveFiles",
                      "args": null,
                      "concreteType": "GoogleDriveFileTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Task_googleDriveFiles_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GoogleDriveFileTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "GoogleDriveFile",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                (v3/*: any*/),
                                (v5/*: any*/),
                                (v6/*: any*/),
                                (v7/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/),
                                    (v8/*: any*/),
                                    (v9/*: any*/),
                                    (v10/*: any*/),
                                    (v11/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v12/*: any*/),
                                (v13/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v14/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v15/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": "files",
                      "args": null,
                      "concreteType": "FileTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Task_files_connection",
                      "plural": false,
                      "selections": (v24/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "tasks(first:10000)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '60a8fb4be6d9157118336e6eb7a1af36';

module.exports = node;
