/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActivateTimeApprovalModal_viewer$ref = any;
export type ActivateTimeApprovalModal_QueryVariables = {||};
export type ActivateTimeApprovalModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ActivateTimeApprovalModal_viewer$ref,
  |}
|};
export type ActivateTimeApprovalModal_Query = {|
  variables: ActivateTimeApprovalModal_QueryVariables,
  response: ActivateTimeApprovalModal_QueryResponse,
|};
*/


/*
query ActivateTimeApprovalModal_Query {
  viewer {
    actualPersonId
    component(name: "activate-time-approval")
    ...ActivateTimeApprovalModal_viewer
    id
  }
}

fragment ActivateTimeApprovalModal_viewer on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    allProjects(first: 1000000, searchQuery: {filters: [{field: NO_CONTACT}]}) {
      edges {
        node {
          id
          name
          companyProjectId
          projectColor
          status
          projectPersons(first: 100000, excludeClientUsers: true) {
            edges {
              ...ProjectPersonDropdown_persons
              node {
                id
                isContactPerson
                person {
                  id
                  permissions
                  active
                  fullName
                  profilePictureId
                }
              }
            }
          }
          ...DeprecatedProjectIndicatorJS_project
        }
      }
    }
  }
}

fragment DeprecatedProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}

fragment ProjectPersonDropdown_persons on ProjectPersonTypeEdge {
  node {
    id
    role {
      name
      id
    }
    person {
      id
      firstName
      lastName
      profilePictureId
      role {
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "activate-time-approval"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"activate-time-approval\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivateTimeApprovalModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActivateTimeApprovalModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ActivateTimeApprovalModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000000
                  },
                  {
                    "kind": "Literal",
                    "name": "searchQuery",
                    "value": {
                      "filters": [
                        {
                          "field": "NO_CONTACT"
                        }
                      ]
                    }
                  }
                ],
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "allProjects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyProjectId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectColor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "excludeClientUsers",
                                "value": true
                              },
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 100000
                              }
                            ],
                            "concreteType": "ProjectPersonTypeConnection",
                            "kind": "LinkedField",
                            "name": "projectPersons",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectPersonTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectPerson",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Person",
                                        "kind": "LinkedField",
                                        "name": "person",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstName",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastName",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "profilePictureId",
                                            "storageKey": null
                                          },
                                          (v4/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "permissions",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "active",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fullName",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isContactPerson",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "projectPersons(excludeClientUsers:true,first:100000)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customProjectId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allProjects(first:1000000,searchQuery:{\"filters\":[{\"field\":\"NO_CONTACT\"}]})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ActivateTimeApprovalModal_Query",
    "operationKind": "query",
    "text": "query ActivateTimeApprovalModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"activate-time-approval\")\n    ...ActivateTimeApprovalModal_viewer\n    id\n  }\n}\n\nfragment ActivateTimeApprovalModal_viewer on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    allProjects(first: 1000000, searchQuery: {filters: [{field: NO_CONTACT}]}) {\n      edges {\n        node {\n          id\n          name\n          companyProjectId\n          projectColor\n          status\n          projectPersons(first: 100000, excludeClientUsers: true) {\n            edges {\n              ...ProjectPersonDropdown_persons\n              node {\n                id\n                isContactPerson\n                person {\n                  id\n                  permissions\n                  active\n                  fullName\n                  profilePictureId\n                }\n              }\n            }\n          }\n          ...DeprecatedProjectIndicatorJS_project\n        }\n      }\n    }\n  }\n}\n\nfragment DeprecatedProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n\nfragment ProjectPersonDropdown_persons on ProjectPersonTypeEdge {\n  node {\n    id\n    role {\n      name\n      id\n    }\n    person {\n      id\n      firstName\n      lastName\n      profilePictureId\n      role {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89720e73a7ec8ad3bf4da64ed22c1915';

module.exports = node;
