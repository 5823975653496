/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query RestoreData_Query(
  $companyId: Int
  $page: Int
  $daysAgo: Int
) {
  viewer {
    actualPersonId
    component(name: "restore_data")
    ...RestoreData_viewer_e84Br
    id
  }
}

fragment MissionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}

fragment RestoreData_viewer_e84Br on Viewer {
  id
  ...MissionControlHeader_viewer
  missionControlData {
    id
    paginatedDeletedTaskList(companyId: $companyId, page: $page, daysAgo: $daysAgo) {
      id
      pageSize
      pageNumber
      totalObjectCount
      pageContents {
        id
        name
        projectName
        deletedAt
        deletedBy
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyId",
            "type": "Int"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "page",
            "type": "Int"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "daysAgo",
            "type": "Int"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "restore_data"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"restore_data\")"
    }, v3 = [
        {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
        },
        {
            "kind": "Variable",
            "name": "daysAgo",
            "variableName": "daysAgo"
        },
        {
            "kind": "Variable",
            "name": "page",
            "variableName": "page"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RestoreData_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": (v3 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "RestoreData_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RestoreData_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MissionControlDataType",
                            "kind": "LinkedField",
                            "name": "missionControlData",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "MissionControlPaginatedDeletedTasksType",
                                    "kind": "LinkedField",
                                    "name": "paginatedDeletedTaskList",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "pageSize",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "pageNumber",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "totalObjectCount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "MissionControlDeletedTaskType",
                                            "kind": "LinkedField",
                                            "name": "pageContents",
                                            "plural": true,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "projectName",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "deletedAt",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "deletedBy",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "RestoreData_Query",
            "operationKind": "query",
            "text": "query RestoreData_Query(\n  $companyId: Int\n  $page: Int\n  $daysAgo: Int\n) {\n  viewer {\n    actualPersonId\n    component(name: \"restore_data\")\n    ...RestoreData_viewer_e84Br\n    id\n  }\n}\n\nfragment MissionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n\nfragment RestoreData_viewer_e84Br on Viewer {\n  id\n  ...MissionControlHeader_viewer\n  missionControlData {\n    id\n    paginatedDeletedTaskList(companyId: $companyId, page: $page, daysAgo: $daysAgo) {\n      id\n      pageSize\n      pageNumber\n      totalObjectCount\n      pageContents {\n        id\n        name\n        projectName\n        deletedAt\n        deletedBy\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '45798c74b985e8a1962f66d0153271a1';
export default node;
