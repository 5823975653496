/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExportNovaInsightsStatsCSV_viewer$ref: FragmentReference;
declare export opaque type ExportNovaInsightsStatsCSV_viewer$fragmentType: ExportNovaInsightsStatsCSV_viewer$ref;
export type ExportNovaInsightsStatsCSV_viewer = {|
  +missionControlData: ?{|
    +companiesWithNovaInsights: ?$ReadOnlyArray<?{|
      +companyId: ?number,
      +companyName: ?string,
      +runningProjectsCount: ?number,
      +projectsInConnectedProjectCount: ?number,
      +timeRegProjectsCount: ?number,
      +doneTaskProjectsCount: ?number,
      +allocationProjectsCount: ?number,
      +estimatedProjectsCount: ?number,
      +predictedEndWithoutTimeRegAvailability: ?number,
      +predictedEndWithoutDoneTaskAvailability: ?number,
      +predictedEndWithoutAllocationAvailability: ?number,
      +predictedEndWithoutTimeRegDoneTaskAvailability: ?number,
      +predictedEndWithoutTimeRegDoneTaskAllocationAvailability: ?number,
      +predictedEndAvailability: ?number,
      +focusAvailability: ?number,
      +performanceAvailability: ?number,
      +novaInsightCompatibilityScore: ?number,
    |}>
  |},
  +$refType: ExportNovaInsightsStatsCSV_viewer$ref,
|};
export type ExportNovaInsightsStatsCSV_viewer$data = ExportNovaInsightsStatsCSV_viewer;
export type ExportNovaInsightsStatsCSV_viewer$key = {
  +$data?: ExportNovaInsightsStatsCSV_viewer$data,
  +$fragmentRefs: ExportNovaInsightsStatsCSV_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeProjectGroups",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExportNovaInsightsStatsCSV_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "includeProjectGroups",
              "variableName": "includeProjectGroups"
            }
          ],
          "concreteType": "MissionControlCompanyNIStatsType",
          "kind": "LinkedField",
          "name": "companiesWithNovaInsights",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "runningProjectsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectsInConnectedProjectCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeRegProjectsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "doneTaskProjectsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allocationProjectsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedProjectsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedEndWithoutTimeRegAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedEndWithoutDoneTaskAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedEndWithoutAllocationAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedEndWithoutTimeRegDoneTaskAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedEndWithoutTimeRegDoneTaskAllocationAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "predictedEndAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "focusAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "performanceAvailability",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "novaInsightCompatibilityScore",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '6fd3d0227734b89059f2022b788b4a26';

module.exports = node;
