import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {Checkbox, Dropdown, SingleText, BasicTextInput} from 'web-components';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CreateUnit4ResourcesMutation from '../../mutations/CreateUnit4ResourcesMutation';
import DirectApi from '../../directApi';
import {getSelectableProfiles, personHasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';

let getUsersTimeout;
const Unit4SyncUsersModal = ({closeModal, company}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const [unit4Users, setUnit4Users] = useState([]);
	const [fetchingUsers, setFetchingUsers] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [profiles, setProfiles] = useState([]);
	const [selectedProfileId, setSelectedProfileId] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [importing, setImporting] = useState(false);

	useEffect(() => {
		if (company?.profiles?.edges && Array.isArray(company.profiles.edges)) {
			// Filter out admin profile
			const sortedProfiles = getSelectableProfiles(company.profiles.edges).filter(
				profile => !personHasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS, profile)
			);
			setProfiles(sortedProfiles);
		}
	}, []);

	const getUsers = () => {
		setFetchingUsers(true);
		DirectApi.Fetch(`unit4_users?filterString=${searchText}`).then(users => {
			users = users
				.filter(u => !u.forecastId)
				.sort((a, b) => {
					const userA = a.firstName.toUpperCase().trim() + ' ' + a.surname.toUpperCase().trim();
					const userB = b.firstName.toUpperCase().trim() + ' ' + b.surname.toUpperCase().trim();
					if (userA > userB) {
						return 1;
					} else if (userA < userB) {
						return -1;
					}
					return 0;
				});
			setUnit4Users(users);
			setFetchingUsers(false);
		});
	};
	useEffect(() => {
		if (getUsersTimeout) {
			clearTimeout(getUsersTimeout);
		}
		if (searchText?.length && searchText?.length > 3) {
			getUsersTimeout = setTimeout(() => {
				setSelectAll(false);
				setSelectedUsers([]);
				getUsers();
			}, 1000);
		}
	}, [searchText]);

	const toggleSelectAll = () => {
		if (selectAll) {
			setSelectedUsers([]);
			setSelectAll(false);
		} else {
			setSelectedUsers(unit4Users.map(unit4User => unit4User.personId));
			setSelectAll(true);
		}
	};

	const toggleUser = unit4PersonId => {
		if (selectedUsers.includes(unit4PersonId)) {
			const tempSelectedUsers = selectedUsers.filter(personId => personId !== unit4PersonId);
			setSelectedUsers(tempSelectedUsers);
			setSelectAll(false);
		} else {
			setSelectedUsers([...selectedUsers, unit4PersonId]);
		}
	};

	const getSelectedProfileId = () => {
		return selectedProfileId[0];
	};

	const syncUsers = () => {
		setImporting(true);
		const onSuccess = () => {
			closeModal();
			showModal({
				type: MODAL_TYPE.INTEGRATIONS_INFO,
				title: 'Import Unit4 Resources',
				infoMessage: 'The resources have been created in Forecast as Deactivated team members.',
			});
		};

		Util.CommitMutation(
			CreateUnit4ResourcesMutation,
			{
				unit4PersonIds: selectedUsers,
				profileId: getSelectedProfileId(),
			},
			onSuccess
		);
	};

	const getElements = () => {
		return profiles.map(option => {
			return (
				<SingleText key={option.id} value={option.id}>
					{option.name}
				</SingleText>
			);
		});
	};

	const content = (
		<div className="unit4-sync-users-modal-content">
			<div className="unit4-sync-users-modal-description">
				<FormattedMessage id="modal.unit4.sync_users.description" />
			</div>
			<div className="unit4-profiles">
				<Dropdown
					maxHeight={400}
					selectedItems={selectedProfileId}
					onSelect={setSelectedProfileId}
					name={formatMessage({id: 'settings_profile.choose_profile'})}
				>
					{getElements()}
				</Dropdown>
			</div>

			<div className="unit4-search">
				<BasicTextInput
					value={searchText}
					onChange={setSearchText}
					placeholder="Start typing to search for Unit4 resources (minimum 4 characters)"
				/>
			</div>
			{fetchingUsers ? (
				<InlineLoader />
			) : (
				<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
					<div className="unit4-users">
						{unit4Users?.length ? (
							<>
								<div className="unit4-user" key={'0'}>
									<Checkbox checked={selectAll} onClick={toggleSelectAll} />
									<FormattedMessage id="common.select_all" />
								</div>
								{unit4Users.map(unit4User => (
									<div className="unit4-user" key={unit4User.personId}>
										<Checkbox
											checked={selectedUsers.includes(unit4User.personId)}
											onClick={toggleUser.bind(this, unit4User.personId)}
										/>
										{unit4User.personId + ' ' + unit4User.firstName + ' ' + unit4User.surname}
									</div>
								))}
							</>
						) : null}
					</div>
				</CustomScrollDiv>
			)}
		</div>
	);

	return (
		<GenericModal
			headerText={<FormattedMessage id="common.sync_users" />}
			closeModal={closeModal}
			className="unit4-sync-users-modal"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.create_x_users'}, {numOfUsers: selectedUsers.length}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: syncUsers,
					disabled: selectedUsers.length === 0 || !selectedProfileId?.length || importing || fetchingUsers,
					preventDefaultClose: true,
				},
			]}
			content={content}
		/>
	);
};

const unit4SyncUsersModalQuery = graphql`
	query unit4SyncUsersModal_Query {
		viewer {
			actualPersonId
			component(name: "unit4import_users_modal")
			company {
				...unit4SyncUsersModal_company
			}
		}
	}
`;

export {unit4SyncUsersModalQuery};

export default createFragmentContainer(Unit4SyncUsersModal, {
	company: graphql`
		fragment unit4SyncUsersModal_company on Company {
			id
			profiles {
				edges {
					node {
						id
						name
						canBeEdited
						permissions
						disabled
					}
				}
			}
		}
	`,
});
