import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import Util from '../../forecast-app/shared/util/util';
import RenameSavedReportMutation from '../../mutations/rename_saved_report_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {categoryColors} from '../../constants';
import {getSavedReportURLByReportType} from '../../components/insights/saved_report_util';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';

const savedReportCard = ({report, deleteSavedReport, actualPersonId, intl}) => {
	const [actionsMenuExpanded, setActionsMenuExpanded] = useState(false);
	const nameInput = useRef(null);
	const [viewOnly, setReadOnly] = useState(true);

	const isReportShared = () => {
		return report.shares ? report.shares.edges && report.shares.edges.length > 0 : false;
	};

	const calculateWidth = (text, font) => {
		const element = document.createElement('canvas');
		const context = element.getContext('2d');
		context.font = font;
		return context.measureText(text).width;
	};

	const getTextAreaNumberOfRows = () => {
		const reportName = report.name;
		const width = calculateWidth(reportName, '400 16px neue-haas-grotesk-text');
		if (width > 143) {
			return 2;
		} else return 1;
	};

	const toggleActionsMenu = e => {
		e.stopPropagation();
		setActionsMenuExpanded(!actionsMenuExpanded);
	};

	const onSuccess = () => {
		createToast({
			duration: 5000,
			message: intl.formatMessage({id: 'save_report.report_updated'}),
		});
	};

	/**
	 * Support in Report Service - to only rename report.
	 *
	 * @param name The new name
	 */
	const renameReportMutation = name => {
		Util.CommitMutation(
			RenameSavedReportMutation,
			{
				id: report.id,
				reportService: report.reportService,
				name: name,
			},
			onSuccess
		);
	};

	const handleRenameClick = e => {
		e.stopPropagation();
		showModal({
			type: MODAL_TYPE.RENAME_REPORT_MODAL,
			reportName: report.name,
			renameReport: renameReportMutation,
		});
	};

	const getLink = () => {
		return getSavedReportURLByReportType(report.type, report.id);
	};

	const onEnterPress = e => {
		//if enter is pressed
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			if (report.name !== e.target.value) {
				nameInput.current.blur();
			}
		}
	};

	const closeActionsMenu = () => {
		if (actionsMenuExpanded) setActionsMenuExpanded(false);
	};

	const closeTextArea = e => {
		if (!viewOnly) {
			setReadOnly(true);
			if (report.name !== e.target.value) {
				renameReportMutation(e.target.value);
			}
		}
	};

	const handleTextAreaClick = e => {
		if (!viewOnly) e.preventDefault();
	};

	return (
		<div className="saved-report-card">
			<svg height="250px" width="300px">
				<path
					d="M0 220 L0 5 C0 5 0 0 5 0 L295 0 C295 0 300 0 300 5 L300 150 L218 103 Z"
					style={{fill: categoryColors.Portfolio.dark}}
				/>
				<polygon points="218,0 240,0 300,40 300,56 218,103" style={{fill: categoryColors.Portfolio.darkest}} />
			</svg>
			{(report.person && report.person.active && actualPersonId === report.person.id) ||
			hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) ? (
				<div
					className="actions"
					onBlur={closeActionsMenu.bind(this)}
					onClick={toggleActionsMenu.bind(this)}
					tabIndex="0"
				>
					<div className={'actions-drop-down ' + (actionsMenuExpanded ? 'expanded' : 'collapsed')}>
						<ul>
							<li onClick={deleteSavedReport}>
								<FormattedMessage id="common.delete" />
							</li>

							<li onClick={handleRenameClick.bind(this)}>
								<FormattedMessage id="common.rename" />
							</li>
						</ul>
					</div>
				</div>
			) : null}
			<Link to={getLink} style={{textDecoration: 'none'}}>
				<div className="card-data">
					<div className="header">
						<textarea
							rows={getTextAreaNumberOfRows()}
							onKeyDown={onEnterPress.bind(this)}
							onClick={handleTextAreaClick.bind(this)}
							onBlur={closeTextArea.bind(this)}
							className="title"
							ref={nameInput}
							readOnly={viewOnly}
							value={report.name}
						/>
					</div>
					<div className="category-name">{intl.formatMessage({id: 'insights.category.portfolio'})}</div>
				</div>
			</Link>
			{isReportShared() && (
				<div style={{position: 'absolute', bottom: '10px', right: '10px', fontSize: 'small'}}>
					{intl.formatMessage({id: 'insights.report_is_shared'})}
				</div>
			)}
		</div>
	);
};

export default savedReportCard;
