import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import CreateSavedReportMutation from '../../../mutations/reporting/create_saved_report_mutation';
import {HIDDEN_FEATURES, REPORT_TYPE} from '../../../constants';
import Moment from 'moment';
import {
	getEyeApplied,
	getGroupingOne,
	getPeriodDate,
	getPeriodType,
} from '../../modal/create-new-report/create-new-report-util';
import {getSavedReportURLByReportType} from '../../../components/insights/saved_report_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

class NewInsightStep1 extends Component {
	componentDidMount() {
		document.title = 'Create new insight - Forecast';
	}

	createCategory(name, handleCreateInsight) {
		return (
			<div className={'card ' + name} onClick={handleCreateInsight}>
				<div className="text-container" data-cy={'card ' + name}>
					<h3 className="title">
						<FormattedMessage id={'insights.category.' + name} />
					</h3>
					<div className="description">
						<FormattedMessage id={'new_insight.step1-description-' + name} />
					</div>
				</div>
			</div>
		);
	}

	getSavedReportLink(report) {
		return getSavedReportURLByReportType(report.type, report.id);
	}

	next(category) {
		if (
			category !== REPORT_TYPE.TIME_REG &&
			category !== REPORT_TYPE.UTILIZATION_REPORT &&
			category !== REPORT_TYPE.PROJECT_PORTFOLIO_REPORT &&
			category !== REPORT_TYPE.TASK_REPORT
		) {
			this.props.setCategory(category);
			this.props.history.push('/reports/new/step2');
		} else {
			const onSuccess = response => {
				this.props.history.push(this.getSavedReportLink(response.createSavedReport.savedReport.node));
			};

			const startDate = Moment().startOf('month').format('YYYY-MM-DD');
			const endDate = Moment().endOf('month').format('YYYY-MM-DD');

			Util.CommitMutation(
				CreateSavedReportMutation,
				{
					name: category + ', Untitled',
					type: category,
					eyeApplied: JSON.stringify(
						getEyeApplied(
							category,
							this.props.isUsingProjectAllocation,
							this.props.isUsingSchedulingPlanMode,
							this.props.isUsingMixedMode,
							this.props.hasPriorityLevels
						)
					),
					startDate,
					endDate,
					filterApplied: JSON.stringify(null),
					filters: [],
					groupingOne: getGroupingOne(category),
					periodType: getPeriodType(category),
					periodDate: getPeriodDate(category),
					reportService:
						category === REPORT_TYPE.TASK_REPORT ||
						category === REPORT_TYPE.TIME_REG ||
						(hasFeatureFlag('reports_redesign') &&
							(category === REPORT_TYPE.PROJECT_PORTFOLIO_REPORT || category === REPORT_TYPE.UTILIZATION_REPORT)),
				},
				onSuccess
			);
		}
	}

	render() {
		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);
		// Check if they are using the no time registration flag
		const noTimeReg = Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS); // replace with company setting
		return (
			<div className="new-insight">
				<div className="new-insight-step1">
					<div className="new-insight-header">
						<button className="back-button" onClick={this.props.history.goBack} />
						<h1>
							<FormattedMessage id={'new_report'} />
						</h1>
						<button className="cancel-button" onClick={() => this.props.history.push('/insights')} />
					</div>
					<h2 className="new-insight-description">Standard Reports</h2>
					<div className="content">
						{hasFinancialAccess &&
							this.createCategory(
								'projectPortfolioReport',
								this.next.bind(this, REPORT_TYPE.PROJECT_PORTFOLIO_REPORT)
							)}
						{this.createCategory('utilizationReport', this.next.bind(this, REPORT_TYPE.UTILIZATION_REPORT))}
						{!noTimeReg && this.createCategory('timeRegistered', this.next.bind(this, REPORT_TYPE.TIME_REG))}
						{this.createCategory('taskReport', this.next.bind(this, REPORT_TYPE.TASK_REPORT))}
					</div>
					{!hasFeatureFlag('cant_use_old_component_reports') && (
						<>
							<h2 className="new-insight-description">Component Reports </h2>
							<div className={'content'}>
								{this.createCategory('projects', this.next.bind(this, 'Projects'))}
								{this.createCategory('portfolio', this.next.bind(this, 'Portfolio'))}
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}

NewInsightStep1.propTypes = {};

export default injectIntl(withRouter(NewInsightStep1));
