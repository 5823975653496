import React from 'react';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import ForecastSurplusModal, {ForecastSurplusModalQuery} from './ForecastSurplusModal';

const ForecastSurplusModalRelayWrapper = props => {
	return (
		<ForecastQueryRenderer
			key="query-render-forecast-surplus-modal"
			query={ForecastSurplusModalQuery}
			modal={true}
			variables={{projectId: props.projectId}}
			render={relayProps => {
				return <ForecastSurplusModal {...relayProps} {...props} />;
			}}
		/>
	);
};

export default ForecastSurplusModalRelayWrapper;
