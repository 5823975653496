/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PredictedEndDateModal_viewer$ref: FragmentReference;
declare export opaque type PredictedEndDateModal_viewer$fragmentType: PredictedEndDateModal_viewer$ref;
export type PredictedEndDateModal_viewer = {|
  +project: ?{|
    +projectStats: ?$ReadOnlyArray<?{|
      +id: string,
      +date: ?string,
      +totalEstimateMinutes: ?number,
      +totalBaselineEstimateMinutes: ?number,
      +totalDoneEstimateMinutes: ?number,
      +performance: ?number,
      +optimisticPerformance: ?number,
      +conservativePerformance: ?number,
    |}>
  |},
  +$refType: PredictedEndDateModal_viewer$ref,
|};
export type PredictedEndDateModal_viewer$data = PredictedEndDateModal_viewer;
export type PredictedEndDateModal_viewer$key = {
  +$data?: PredictedEndDateModal_viewer$data,
  +$fragmentRefs: PredictedEndDateModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PredictedEndDateModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectStats",
          "kind": "LinkedField",
          "name": "projectStats",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalEstimateMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalBaselineEstimateMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDoneEstimateMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "performance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "optimisticPerformance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "conservativePerformance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '382350d635e5a90620dd682754b84db3';

module.exports = node;
