import React from 'react';
import ProjectBreakdownTable, { projectBreakdownTableQuery } from './ProjectBreakdownTable';
import { useTrackPage } from '../../../../tracking/amplitude/hooks/useTrackPage';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
const ProjectBreakdownPage = ({ projectId, budgetType }) => {
    useTrackPage('Project Financials Breakdown', null, { budgetType: budgetType });
    window.history.replaceState(null, '', `${location.pathname}#breakdown`);
    return (React.createElement(React.Fragment, null,
        React.createElement(ForecastQueryRenderer, { query: projectBreakdownTableQuery, variables: {
                projectId: `${projectId}`,
            }, authorizeAccessRoute: 'project-budget', render: relayProps => {
                return React.createElement(ProjectBreakdownTable, Object.assign({ key: 'ProjectBreakdownTable' }, relayProps));
            } })));
};
export default ProjectBreakdownPage;
