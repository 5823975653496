import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import MovePhaseOnTimelineMutation from '../../mutations/move_phase_on_timeline_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import {BUTTON_COLOR} from '../../constants';
import GenericModal from './generic_modal';
import ProjectDropdownRelay from '../../forecast-app/shared/components/dropdowns/project-dropdown-b/Project_dropdown';

class phaseLocationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedProjectId: this.props.currentProjectId,
		};
	}

	confirm() {
		const onSuccess = result => {
			if (result.movePhaseOnTimeline.errors && result.movePhaseOnTimeline.errors.length === 1) {
				Util.checkForSageErrorAndShowModal(result.updateTask.errors, false);
			} else {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'project_scoping.move_phase_toast'})});
			}
		};

		Util.CommitMutation(
			MovePhaseOnTimelineMutation,
			{
				id: this.props.phaseId,
				projectId: this.state.selectedProjectId,
				previousProjectId: this.props.currentProjectId,
				isJiraPhase: this.props.isJiraPhase,
			},
			onSuccess
		);
	}

	handleProjectChange(selected) {
		this.setState({selectedProjectId: selected.value});
	}

	handleProjectSelected(selected) {
		this.setState({selectedProjectId: selected[0]});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const hasOpportunityAccess = Util.hasOpportunityAccess(this.props.viewer.company.modules);

		const projectOptions = this.props.isJiraPhase
			? this.props.viewer.projects.edges.filter(proj => proj.node.jiraCloudEpicIds)
			: this.props.viewer.projects.edges;

		const content = (
			<div className="move-phase-modal" onMouseDown={this.props.closeModal}>
				<div
					className="inner-container"
					onMouseDown={e => {
						e.stopPropagation();
					}}
				>
					<div className="body-container project-dropdown-wrapper">
						<ProjectDropdownRelay
							showHalted={false}
							showDone={false}
							projects={projectOptions}
							optionsName={formatMessage({id: 'common.projects'})}
							selectedItems={this.state.selectedProjectId ? [this.state.selectedProjectId] : []}
							onRemove={this.handleProjectSelected.bind(this)}
							onSelect={this.handleProjectSelected.bind(this)}
							groupByStatus
							showRunning
							showPlanning
							showOpportunity={hasOpportunityAccess}
							width={372}
							maxHeight={255}
							name={formatMessage({id: 'common.all_projects'})}
							userpilot="timesheets-select-project"
							cy="time-entry-project-dropdown"
						/>
					</div>
				</div>
			</div>
		);

		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						color: BUTTON_COLOR.WHITE,
						callback: this.props.closeModal.bind(this),
					},
					{
						text: formatMessage({id: 'common.move'}),
						callback: this.confirm.bind(this),
					},
				]}
				content={content}
				overflowVisible={true}
				headerText={formatMessage({id: 'task_location_modal.title'})}
			/>
		);
	}
}
phaseLocationModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

const phaseLocationModalQuery = graphql`
	query phaseLocationModal_Query {
		viewer {
			actualPersonId
			component(name: "phase_location_modal")
			...phaseLocationModal_viewer
		}
	}
`;

export {phaseLocationModalQuery};

export default injectIntl(
	createFragmentContainer(phaseLocationModal, {
		viewer: graphql`
			fragment phaseLocationModal_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					modules {
						moduleType
					}
				}
				projects(first: 100000, excludeDoneOrHalted: true) {
					edges {
						...ProjectDropdown_projects
						node {
							id
							projectColor
							projectGroupId
							name
							companyProjectId
							status
							isJiraProject
							jiraCloudEpicIds
							harvestProject {
								id
							}
							statusColumns {
								id
								projectGroupStatusColumnId
							}
						}
					}
				}
			}
		`,
	})
);
