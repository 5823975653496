import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import * as tracking from '../tracking';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import JoinCompanyMutation from '../mutations/join_company_mutation';
import Util from '../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE} from '../constants';
import {getRedirectLink} from '../forecast-app/shared/util/UrlUtil';
import nova from '../images/nova.svg';
import {ImagesWrapper} from '../forecast-app/shared/components/empty-states/empty_state_styled';
import Button from '../forecast-app/shared/components/buttons/button/button';
import DirectApi from '../directApi';
import {DatacenterDropdown} from './DatacenterDropdown';
import {trackEvent, trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class signup extends Component {
	constructor(props) {
		super(props);

		let email = '';
		const queryParams = Util.getQueryParams(window.location.search);
		if (queryParams.email) {
			email = queryParams.email[0];
		}

		this.state = {
			errorMsg: null,
			verifying: false,
			autojoinDomain: '',
			email: email,
			joinEmailSent: false,
			joinFailed: false,
			datacenter: DirectApi.getDatacenter(),
		};
		this.props.setLocaleFromBrowser();
		this.createNew = this.createNew.bind(this);

		this.superPropertyChecksum = trackPage('Signup');
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.downForMaintenance) {
			this.props.history.push({
				pathname: '/maintenance',
			});
			return;
		}

		if (this.props.viewer.actualPersonId !== null) {
			this.props.history.push({
				pathname: '/',
			});
		}
	}

	componentDidMount() {
		// Segment
		document.title = this.props.intl.formatMessage({id: 'login.site_title'});
		tracking.trackPage('signup');

		if (process.env.CIRCLE_BRANCH === 'production' && DirectApi.enableDatacenterSwitch()) {
			fetch(DirectApi.graphqlServerEndpoint('ip_location_info'))
				.then(result => result.json())
				.then(res => {
					if (res.continent_code && res.continent_code !== 'EU') {
						this.setState({datacenter: 'US'});
					}
				});
		}

		this.refs.email.focus();

		if (this.state.email.length > 0) {
			this.refs.email.value = this.state.email;
			//this.handleSubmit();
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	joinCompany() {
		this.setState({verifying: true});
		const onSuccess = response => {
			if (response.joinCompany.person) {
				this.setState({joinEmailSent: true});
				this.setState({verifying: false});
				tracking.trackEvent('started join process');
				trackEvent('Join Process', 'Started');
			} else if (response.joinCompany.errors && response.joinCompany.errors.includes('NO_AVAILABLE_SEATS')) {
				this.setState({verifying: false, joinFailed: true});
			}
		};

		Util.CommitMutation(
			JoinCompanyMutation,
			{
				email: this.state.email === '' ? null : this.state.email,
				join: true,
			},
			onSuccess
		);
	}

	createNew() {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const init = {headers: headers};
		const hubspotCookie = Util.getCookieFromDocument('hubspotutk');

		// Get xero uuid
		const queryParams = Util.getQueryParams(window.location.search);
		const xeroUUID = queryParams.xero_uuid ? queryParams.xero_uuid[0] : null;

		//Get redirect link
		const redirectLink = getRedirectLink(window.location.search);

		fetch(
			DirectApi.graphqlServerEndpoint(
				'signup?source=signup_loginpage&hubspotutk=' + hubspotCookie + '&email=' + this.state.email
			),
			init
		)
			.then(result => result.json())
			.then(json => {
				this.setState({errorMsg: null});
				switch (json.resultCode) {
					case '0':
						window.location.href = `/first/${json.key}${xeroUUID ? `?xero_uuid=${xeroUUID}` : ''}${
							redirectLink !== '/' ? '?retURL=' + redirectLink : ''
						}`;
						break;
					case '1':
					case '4':
						this.setState({errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-invalid'})});
						break;

					case '2':
						this.setState({errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-in-use'})});
						break;
					default:
						break;
				}
				this.setState({verifying: false});
			});
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}
		const email = this.refs.email.value.trim();
		var valid = /(.+)@(.+)\.(.+)/.test(email);

		if (valid) {
			this.setState({verifying: true});
			this.setState({email: email});
			const headers = new Headers();
			headers.append('Content-Type', 'application/json');
			const init = {headers: headers};

			// Get xero uuid
			const queryParams = Util.getQueryParams(window.location.search);
			//Get redirect link
			const redirectLink = getRedirectLink(window.location.search);
			const xeroUUID = queryParams.xero_uuid ? queryParams.xero_uuid[0] : null;

			fetch(DirectApi.graphqlServerEndpoint('autojoin_check?email=' + email), init)
				.then(result => result.json())
				.then(json => {
					switch (json.resultCode) {
						case '0':
							window.location.href = `/first/${json.key}${xeroUUID ? `?xero_uuid=${xeroUUID}` : ''}${
								redirectLink !== '/' ? '?retURL=' + redirectLink : ''
							}`;
							break;
						case '2':
							this.setState({
								errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-in-use'}),
								verifying: false,
							});
							break;
						case '6':
							if (xeroUUID) {
								this.createNew();
							} else {
								// Ask user to join existing or continue
								this.setState({verifying: false, autojoinDomain: json.domain});
							}
							break;
						case '7':
							// Continue normal signup procedure
							this.createNew();
							break;
						case '8':
							// Not a business email
							this.setState({
								errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-not-business'}),
								verifying: false,
							});
							break;
						default:
							break;
					}
				});
		} else {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-invalid'}), verifying: false});
		}
	}

	setDatacenter(dc) {
		this.setState({datacenter: dc});
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<>
				<div className="animation-container signup-animation-container">
					<ImagesWrapper size="small" position="absolute" right="15%" top="10vh">
						<img style={{height: 180}} src={nova} alt="Nova" />
					</ImagesWrapper>
				</div>

				{this.state.verifying ? <UploadingOverlay /> : null}

				<div className="content">
					{this.state.autojoinDomain ? (
						<>
							{this.state.joinEmailSent ? (
								<>
									<h1>{formatMessage({id: 'signup.autojoin-accepted'})}</h1>
								</>
							) : this.state.joinFailed ? (
								<>
									<h1>{'Your auto-join failed.'}</h1>
									<h4>{'The company you tried to join does not have any available user seats.'}</h4>
									<h4>
										{
											'Please contact an admin of the company and ask them to add more user seats to their account.'
										}
									</h4>
								</>
							) : (
								<>
									<h1>{formatMessage({id: 'signup.autojoin-exists'})}</h1>
									<h4 className="auto-join-question">
										{formatMessage({id: 'signup.autojoin-question'})}{' '}
										<span className="domain-name">{this.state.autojoinDomain}</span>?
									</h4>
									<div className="buttons join-company-actions">
										<Button
											text={formatMessage({id: 'onboarding.no_thanks'})}
											buttonStyle={BUTTON_STYLE.FILLED}
											colorTheme={BUTTON_COLOR.LIGHT}
											onClick={this.createNew.bind(this)}
											uppercase={false}
											roundedBorders={true}
											buttonTitle={this.props.actionTitle}
										/>
										<Button
											cy="accept-join-domain-button"
											text={formatMessage({id: 'common.yes'})}
											buttonStyle={BUTTON_STYLE.FILLED}
											colorTheme={BUTTON_COLOR.GREEN_APPROVE}
											onClick={this.joinCompany.bind(this)}
											uppercase={false}
											roundedBorders={true}
											symbolClass="check-circle"
											symbolSize={20}
											placeSymbolLast={false}
											buttonTitle={this.props.actionTitle}
										/>
									</div>
								</>
							)}
						</>
					) : (
						<>
							<form>
								<h1>{formatMessage({id: 'signup.title'})}</h1>
								<label htmlFor="email">{formatMessage({id: 'common.email'})}</label>
								<input
									data-cy="signup-input-field"
									className="e-mail"
									type="email"
									name="username"
									ref="email"
									placeholder={formatMessage({id: 'login.enter-email-address'})}
								/>
								{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
								<DatacenterDropdown
									datacenter={this.state.datacenter}
									setDatacenter={this.setDatacenter.bind(this)}
								/>
								<div className="buttons single-button">
									<Button
										cy="signup-button"
										text={formatMessage({id: 'signup.signup-button'})}
										buttonStyle={BUTTON_STYLE.FILLED}
										colorTheme={BUTTON_COLOR.PINK}
										onClick={this.handleSubmit.bind(this)}
										uppercase={false}
										roundedBorders={true}
										symbolClass="arrow-right"
										symbolSize={18}
										placeSymbolLast
									/>
								</div>
							</form>
						</>
					)}

					<div className="signup-already-have-account">
						<span>{formatMessage({id: 'signup.already-have-account'})} </span>
						<span>
							<Link to="/login">
								<FormattedMessage id="login.sign-in" />
							</Link>
						</span>
					</div>
				</div>
			</>
		);
	}
}

const signupQuery = graphql`
	query signup_Query {
		viewer {
			component(name: "signup")
			...signup_viewer
		}
	}
`;

export {signupQuery};

export default createFragmentContainer(injectIntl(withRouter(signup)), {
	viewer: graphql`
		fragment signup_viewer on Viewer {
			actualPersonId
			downForMaintenance
		}
	`,
});
