import React, {useState, useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import DirectApi from '../../../directApi';

import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import GenericModal from '../generic_modal';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CheckBox from '../../../components/new-ui/check_box';
import EditJiraEpicsMutation from '../../../mutations/EditJiraEpicsMutation';
import Util from '../../../forecast-app/shared/util/util';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';

const JiraEditEpicsModalStyle = styled.div`
	.description {
		margin-bottom: 8px;
	}
	.list {
		display: flex;
		flex-direction: column;
		max-width: 500px;
		input[type='search'] {
			margin-bottom: 8px;
		}
		.list-item {
			width: 500px;
			max-width: 500px;
			display: flex;
			.epic-checkbox {
				margin-right: 8px;
				flex: 0 0 auto;
			}
			.epic-name {
				flex: 1 1 0px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
`;
const jiraEditEpicsModal = ({closeModal, epicIds, projectId, retrieveLinkedEpics}) => {
	const [selectedEpics, setSelectedEpics] = useState(epicIds);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const intl = useIntl();

	const [availableJiraEpics, setAvailableJiraEpics] = useState([]);

	useEffect(() => {
		DirectApi.Fetch(`jira/available_epics/${projectId}`).then(epics =>
			setAvailableJiraEpics(
				epics.map(epic => ({
					id: epic.id,
					key: epic.key,
					summary: epic.fields.summary,
					label: `${epic.key} ${epic.fields.summary}`,
				}))
			)
		);
	}, []);

	const toggleEpic = epicId => {
		if (selectedEpics.includes(epicId)) {
			setSelectedEpics(selectedEpics.filter(id => id !== epicId));
		} else {
			setSelectedEpics(selectedEpics.concat(epicId));
		}
	};

	const showErrorModal = () => {
		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: (
				<Warning message="Something went wrong. If this problem persist please contact your customer success manager." />
			),
			buttons: [
				{
					text: intl.formatMessage({id: 'common.ok'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	};
	const editEpics = () => {
		setLoading(true);
		Util.CommitMutation(
			EditJiraEpicsMutation,
			{jiraEpicIds: selectedEpics, forecastProjectId: projectId},
			response => {
				const errors = response.editJiraEpics.errors;
				if (errors.length === 0) {
					retrieveLinkedEpics(selectedEpics);
					setSelectedEpics(selectedEpics);

					// onSuccess
					createToast({
						duration: 5000,
						message: intl.formatMessage({id: 'integrations.jira.linked_epics_changed'}),
					});
				}
				setLoading(false);
				closeModal();
				if (errors.length > 0) {
					showErrorModal();
				}
			},
			true,
			null,
			() => {
				setLoading(false);
				closeModal();
				showErrorModal();
			}
		);
	};

	const content = (
		<JiraEditEpicsModalStyle>
			{loading ? (
				<>
					<div className="description">
						Loading... Please wait while your project is updating.
						<br />
						This may take some time depending on the number of issues being synchronized.
					</div>
					<InlineLoader />
				</>
			) : (
				<>
					<div className="description">
						{intl.formatMessage({id: 'integrations.jira.add_epics_modal.description'})}
					</div>
					<div className="list">
						<input
							type="search"
							value={searchText}
							onChange={e => {
								setSearchText(e.target.value || '');
							}}
							placeholder={intl.formatMessage({id: 'common.search'})}
						/>
						{availableJiraEpics.length === 0 ? (
							intl.formatMessage({id: 'common.loading'})
						) : (
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={'70vh'}>
								{availableJiraEpics
									.filter(epic => epic.label.toLowerCase().includes(searchText.toLowerCase()))
									.map(epic => (
										<div className="list-item" key={epic.id}>
											<CheckBox
												customClasses="epic-checkbox"
												small={true}
												isFocusable={true}
												isChecked={selectedEpics.includes(epic.id)}
												onClick={toggleEpic.bind(this, epic.id)}
											/>
											<div className="epic-name">{epic.label}</div>
										</div>
									))}
							</CustomScrollDiv>
						)}
					</div>
				</>
			)}
		</JiraEditEpicsModalStyle>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					disabled: loading,
				},
				{
					text: intl.formatMessage({id: 'integrations.jira.set_linked_epics'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => editEpics(),
					preventDefaultClose: true,
					disabled:
						loading || (epicIds.length === selectedEpics.length && epicIds.every(id => selectedEpics.includes(id))),
				},
			]}
			headerText={intl.formatMessage({id: 'integrations.jira.edit_epics'})}
			content={content}
		/>
	);
};

const jiraEditEpicsModalQuery = graphql`
	query JiraEditEpicsModal_Query {
		viewer {
			actualPersonId
			component(name: "jira_edit_epics_modal")
			...JiraEditEpicsModal_viewer
		}
	}
`;

export {jiraEditEpicsModalQuery};

export default withRouter(
	createFragmentContainer(jiraEditEpicsModal, {
		viewer: graphql`
			fragment JiraEditEpicsModal_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				project {
					id
					jiraCloudEpicIds
				}
				company {
					id
					modules {
						moduleType
					}
				}
			}
		`,
	})
);
