/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type missionControlHeader_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type controlPersonList_viewer$ref: FragmentReference;
declare export opaque type controlPersonList_viewer$fragmentType: controlPersonList_viewer$ref;
export type controlPersonList_viewer = {|
  +id: string,
  +missionControlData: ?{|
    +id: string,
    +personList: ?$ReadOnlyArray<?{|
      +id: string,
      +personId: ?number,
      +companyId: ?number,
      +companyName: ?string,
      +accountPlan: ?string,
      +firstName: ?string,
      +lastName: ?string,
      +email: ?string,
      +seatType: ?string,
      +active: ?boolean,
      +profileName: ?string,
    |}>,
  |},
  +$fragmentRefs: missionControlHeader_viewer$ref,
  +$refType: controlPersonList_viewer$ref,
|};
export type controlPersonList_viewer$data = controlPersonList_viewer;
export type controlPersonList_viewer$key = {
  +$data?: controlPersonList_viewer$data,
  +$fragmentRefs: controlPersonList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchString",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "controlPersonList_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "searchString",
              "variableName": "searchString"
            }
          ],
          "concreteType": "MissionControlPersonListType",
          "kind": "LinkedField",
          "name": "personList",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "personId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountPlan",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profileName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "missionControlHeader_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7b3920d0948febd3f1d51eaf3a4f52b';

module.exports = node;
