import SettingsCalendar from './SettingsCalendar';
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';

const SettingsCalendarWrapper = ({viewer, personId, type}) => {
	return <SettingsCalendar viewer={viewer} personId={personId} type={type} />;
};

export default createFragmentContainer(SettingsCalendarWrapper, {
	viewer: graphql`
		fragment SettingsCalendarWrapper_viewer on Viewer {
			id
			calendarIntegrations {
				id
				googleCalendar {
					id
					calendarTokenError
					calendarViewPrivateEvents
					calendarUser {
						name
						email
						picture
					}
					enabledCalendars
					calendars {
						edges {
							node {
								id
								name
								type
								description
								backgroundColor
								foregroundColor
							}
						}
					}
				}
				outlookCalendar {
					id
					calendarTokenError
					calendarViewPrivateEvents
					calendarUser {
						name
						email
						picture
					}
					enabledCalendars
					calendars {
						edges {
							node {
								id
								name
								type
								description
								backgroundColor
								foregroundColor
							}
						}
					}
				}
			}
		}
	`,
});
