import React, { useEffect, useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexColumn, FlexRow, Label, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
import { MODAL_TYPE, showModal } from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ConditionalDropdown from '../../../../../components/dropdowns/conditional-dropdown/ConditionalDropdown';
import { getNotNullishNodes } from '../../../../../forecast-app/shared/util/NotNullPredicate';
import CreateProjectBillToMutation from '../../../../../mutations/invoicing/CreateProjectBillToMutation';
import { CommitMutation } from '../../../../../mutations/ts/CommitMutation';
import InvoicingIntegrationIcon from '../../../../../forecast-app/admin-tab/finance-page/ts/InvocingIntegrationIcon';
const showExportIntegrationModal = (invoiceId, projectBillTo) => {
    var _a;
    showModal({
        type: MODAL_TYPE.EXPORT_INVOICE,
        invoiceId,
        billToId: (_a = projectBillTo === null || projectBillTo === void 0 ? void 0 : projectBillTo.billTo) === null || _a === void 0 ? void 0 : _a.id,
    });
};
const BillFromItem = ({ billFrom }) => (React.createElement(FlexRow, null,
    React.createElement(InvoicingIntegrationIcon, { integration: billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration }),
    React.createElement(Text, null, billFrom === null || billFrom === void 0 ? void 0 : billFrom.name)));
const BillToItem = ({ billTo }) => {
    var _a;
    return (React.createElement(FlexRow, null,
        React.createElement(Text, null, (_a = billTo === null || billTo === void 0 ? void 0 : billTo.billTo) === null || _a === void 0 ? void 0 : _a.name)));
};
const getEligibleProjectBillTos = (projects) => {
    var _a;
    const eligibleProjectBillTos = new Map();
    let firstProject = true;
    if (!projects) {
        return [];
    }
    for (const project of projects) {
        const projectBillTos = getNotNullishNodes((_a = project === null || project === void 0 ? void 0 : project.projectBillTos) === null || _a === void 0 ? void 0 : _a.edges);
        if (firstProject) {
            for (const projectBillTo of projectBillTos) {
                if (projectBillTo.billTo) {
                    eligibleProjectBillTos.set(projectBillTo.billTo.id, projectBillTo);
                }
            }
        }
        else {
            // Any project bill to that doesn't use an already eligible bill to, should be removed.
            const projectBillToIds = new Set(projectBillTos.map(projectBillTo => { var _a; return (_a = projectBillTo.billTo) === null || _a === void 0 ? void 0 : _a.id; }));
            for (const eligibleBillToId of eligibleProjectBillTos.keys()) {
                if (!projectBillToIds.has(eligibleBillToId)) {
                    eligibleProjectBillTos.delete(eligibleBillToId);
                }
            }
        }
        firstProject = false;
    }
    return Array.from(eligibleProjectBillTos.values());
};
const getBillFromOptions = (billTos) => {
    const existingBillFroIds = new Set();
    const billFromOptions = [];
    for (const billTo of billTos) {
        const billFrom = billTo.billFrom;
        if (billFrom) {
            if (!existingBillFroIds.has(billFrom.id)) {
                billFromOptions.push(billFrom);
                existingBillFroIds.add(billFrom.id);
            }
        }
    }
    return billFromOptions;
};
const ExportInvoiceBillingOptionModal = ({ viewer, invoiceId, closeModal }) => {
    const invoice = viewer.invoice;
    const projects = invoice === null || invoice === void 0 ? void 0 : invoice.projects;
    const singleProject = (projects === null || projects === void 0 ? void 0 : projects.length) === 1;
    const project = singleProject ? projects === null || projects === void 0 ? void 0 : projects[0] : undefined;
    const client = project ? project.client : undefined;
    const projectBillTos = useMemo(() => getEligibleProjectBillTos(projects), [viewer]);
    const clientBillTos = useMemo(() => { var _a; return getNotNullishNodes((_a = client === null || client === void 0 ? void 0 : client.clientBillTos) === null || _a === void 0 ? void 0 : _a.edges); }, [viewer]);
    const billTos = (projectBillTos.length ? projectBillTos : clientBillTos) || [];
    const addProjectBillTo = projectBillTos.length === 0;
    const onlyOneProjectBillingOption = clientBillTos.length === 1;
    const openExportModal = (projectBillTo) => {
        closeModal().then(() => showExportIntegrationModal(invoiceId, projectBillTo));
    };
    useEffect(() => {
        const numOfProjectBillTos = projectBillTos.length;
        if (numOfProjectBillTos === 1) {
            closeModal().then(() => openExportModal(projectBillTos[0]));
        }
        else if (numOfProjectBillTos === 0) {
            if (singleProject) {
                const hasClientBillTos = clientBillTos.length > 0;
                if (!hasClientBillTos) {
                    closeModal().then(() => showModal({
                        type: MODAL_TYPE.MISSING_CLIENT_BILL_TO_WARNING,
                        clientId: client === null || client === void 0 ? void 0 : client.id,
                    }));
                }
            }
            else {
                closeModal().then(() => showModal({
                    type: MODAL_TYPE.PROJECTS_BILLING_DETAILS_WARNING,
                }));
            }
        }
    }, [closeModal, projectBillTos, clientBillTos]);
    const billFromOptions = useMemo(() => getBillFromOptions(billTos), [projectBillTos, clientBillTos]);
    const [selectedBillFrom, setSelectedBillFrom] = useState();
    const billToOptions = useMemo(() => (selectedBillFrom ? billTos.filter(billTo => { var _a; return ((_a = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _a === void 0 ? void 0 : _a.id) === (selectedBillFrom === null || selectedBillFrom === void 0 ? void 0 : selectedBillFrom.id); }) : []), [selectedBillFrom]);
    const [selectedBillTo, setSelectedBillTo] = useState();
    const doExport = () => {
        var _a;
        if (addProjectBillTo) {
            CommitMutation(CreateProjectBillToMutation, {
                billToId: (_a = selectedBillTo === null || selectedBillTo === void 0 ? void 0 : selectedBillTo.billTo) === null || _a === void 0 ? void 0 : _a.id,
                clientBillToId: selectedBillTo === null || selectedBillTo === void 0 ? void 0 : selectedBillTo.id,
                clientId: client === null || client === void 0 ? void 0 : client.id,
                projectId: project === null || project === void 0 ? void 0 : project.id,
                billFromId: selectedBillFrom === null || selectedBillFrom === void 0 ? void 0 : selectedBillFrom.id,
            }, addedProjectBillTo => {
                var _a, _b;
                openExportModal((_b = (_a = addedProjectBillTo.createProjectBillTo) === null || _a === void 0 ? void 0 : _a.projectBillTo) === null || _b === void 0 ? void 0 : _b.node);
            });
        }
        else {
            openExportModal(selectedBillTo);
        }
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: addProjectBillTo ? 'invoicing.add_billing_details_and_export_invoice' : 'invoicing.export_invoice' })),
        React.createElement(Modal.Content, null,
            React.createElement(FormattedMessage, { id: singleProject
                    ? addProjectBillTo
                        ? onlyOneProjectBillingOption
                            ? 'invoicing.add_project_billing_details_one_pair'
                            : 'invoicing.add_project_billing_details_multiple_pair'
                        : 'invoicing.choose_project_billing_option'
                    : 'invoicing.choose_projects_billing_option' }),
            React.createElement(FlexColumn, { gap: "xs" },
                React.createElement(Label, null,
                    React.createElement(FormattedMessage, { id: "common.bill_from" })),
                React.createElement(ConditionalDropdown, { options: billFromOptions, renderOption: billFrom => React.createElement(BillFromItem, { billFrom: billFrom }), onSelect: setSelectedBillFrom },
                    React.createElement(BillFromItem, { billFrom: selectedBillFrom }))),
            React.createElement(FlexColumn, { gap: "xs" },
                React.createElement(Label, null,
                    React.createElement(FormattedMessage, { id: "common.bill_to" })),
                React.createElement(ConditionalDropdown, { options: billToOptions, renderOption: item => React.createElement(BillToItem, { billTo: item }), onSelect: setSelectedBillTo },
                    React.createElement(BillToItem, { billTo: selectedBillTo })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: doExport, disabled: !selectedBillFrom || !selectedBillTo },
                React.createElement(FormattedMessage, { id: 'common.continue' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export const ExportInvoiceBillingOptionModalQuery = graphql `
	query ExportInvoiceBillingOptionModalQuery($invoiceId: ID!) {
		viewer {
			actualPersonId
			component(name: "ExportInvoiceBillingOptionModal")
			...ExportInvoiceBillingOptionModal_viewer @arguments(invoiceId: $invoiceId)
		}
	}
`;
export default createFragmentContainer(ExportInvoiceBillingOptionModal, {
    viewer: graphql `
		fragment ExportInvoiceBillingOptionModal_viewer on Viewer @argumentDefinitions(invoiceId: {type: "ID!"}) {
			id
			invoice(id: $invoiceId) {
				projects {
					id
					client {
						id
						clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
							edges {
								node {
									id
									billTo {
										id
										name
									}
									billFrom {
										id
										name
										integration
									}
								}
							}
						}
					}
					projectBillTos(first: 10000) @connection(key: "Project_projectBillTos", filters: []) {
						edges {
							node {
								id
								billTo {
									id
									name
								}
								billFrom {
									id
									name
									integration
								}
							}
						}
					}
				}
			}
		}
	`,
});
