import React from 'react';
import { hasFeatureFlag } from '../../forecast-app/shared/util/FeatureUtil';
import OverviewProjectsV2, { overviewProjectsQueryV2 } from './overview_projects_v2';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';
import { Query, isQueryInRelayStore } from '../../forecast-app/shared/util/cache/RelayQueryCache';
import { isClientUser } from '../../forecast-app/shared/util/PermissionsUtil';
export const shouldExcludeDoneOrHalted = () => {
    // Work-around to avoid race-condition. See RelayQuerycache.ts for extended documentation.
    if (!isQueryInRelayStore(Query.OverviewProjects)) {
        // If either Done or Halted is the expanded category, don't use lazy pattern.
        const expansionMapString = localStorage.getItem('projects-page-section-expanded-map');
        if (expansionMapString) {
            const expansionMap = new Map(JSON.parse(expansionMapString));
            if (expansionMap.get('DONE') || expansionMap.get('HALTED')) {
                return false;
            }
        }
        return true;
    }
    return false;
};
export const OverviewProjectsQueryWrapper = ({ buyNowTime }) => {
    const excludeDoneOrHalted = shouldExcludeDoneOrHalted();
    const excludeGroupProjectsFromProjectConnection = !isClientUser();
    return (React.createElement(ForecastQueryRenderer, { key: "query-render-overviewProjects", query: overviewProjectsQueryV2, fetchPolicy: "store-or-network", variables: {
            loadSingleCurrency: hasFeatureFlag('projects_overview_load_single_currency') && !hasFeatureFlag('financial_categories_update'),
            loadMultiCurrency: !hasFeatureFlag('projects_overview_load_single_currency') && !hasFeatureFlag('financial_categories_update'),
            excludeDoneOrHalted,
            excludeGroupProjectsFromProjectConnection,
        }, render: (relayProps, retry) => {
            return (React.createElement(OverviewProjectsV2, Object.assign({ retry: retry }, relayProps, { buyNowTime: buyNowTime, excludeDoneOrHalted: excludeDoneOrHalted })));
        } }));
};
