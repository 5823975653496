import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import FinancialLockIcon from '../../components/new-ui/FinancialLockIcon';
import Styled from 'styled-components';

class ComponentCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showInfo: false,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.selected !== nextProps.selected || this.state.showInfo !== nextState.showInfo;
	}

	toggleSelect() {
		if (!(this.props.comingSoon || this.props.noFinance)) {
			this.props.toggleSelect(this.props.componentName);
		}
	}

	toggleInfo(e) {
		e.stopPropagation();
		this.setState({showInfo: !this.state.showInfo});
	}

	hideInfo() {
		this.setState({showInfo: false});
	}

	render() {
		return (
			<div
				title={this.props.noFinance ? this.props.intl.formatMessage({id: 'common.requires_financial_module'}) : null}
				className={
					'card' +
					(this.props.selected ? ' selected' : '') +
					(this.props.noFinance ? ' no-finance' : '') +
					(this.props.comingSoon ? ' disabled' : '')
				}
				onClick={this.toggleSelect.bind(this)}
				data-cy="card"
			>
				{this.props.AIPowered ? (
					<div className="ai-frame">
						<div className="ai-powered">
							<span className="ai-label">AI</span>
						</div>
					</div>
				) : null}
				<div className="top-wrapper">
					{this.props.infoText ? (
						<button className="info" onClick={this.toggleInfo.bind(this)} onBlur={this.hideInfo.bind(this)}>
							{this.state.showInfo ? <div className="text">{this.props.infoText}</div> : null}
						</button>
					) : null}
				</div>
				<div
					className={
						(this.props.comingSoon ? 'picture component-' : 'picture component-color-') + this.props.componentName
					}
				>
					{this.props.comingSoon ? (
						<div className="coming-soon">
							<FormattedMessage id="common.coming_soon" />
						</div>
					) : null}
				</div>
				{this.props.noFinance && (
					<div style={{textAlign: 'center', zIndex: '1000'}}>
						<FinancialLockIcon width={21} height={15} />
					</div>
				)}
				<div className="title" data-cy={this.props.componentName}>
					<FormattedMessage id={'insights.component.title.' + this.props.componentName} />
				</div>
				{this.props.noFinance && <DisabledOverlay />}
			</div>
		);
	}
}

ComponentCard.propTypes = {
	selected: PropTypes.bool,
	componentName: PropTypes.string.isRequired,
	toggleSelect: PropTypes.func,
	comingSoon: PropTypes.bool,
};

export default injectIntl(ComponentCard);

const DisabledOverlay = Styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	opacity: .70;
`;
