/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type loginAzureActiveDirectory_viewer$ref: FragmentReference;
declare export opaque type loginAzureActiveDirectory_viewer$fragmentType: loginAzureActiveDirectory_viewer$ref;
export type loginAzureActiveDirectory_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +company: ?{|
    +AADTenant: ?string
  |},
  +$refType: loginAzureActiveDirectory_viewer$ref,
|};
export type loginAzureActiveDirectory_viewer$data = loginAzureActiveDirectory_viewer;
export type loginAzureActiveDirectory_viewer$key = {
  +$data?: loginAzureActiveDirectory_viewer$data,
  +$fragmentRefs: loginAzureActiveDirectory_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "loginAzureActiveDirectory_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "AADTenant",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '488bb1f42fe72d32fe7783ebf8bac525';

module.exports = node;
