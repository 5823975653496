import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import uuid from 'uuid';
import ComponentCard from './component_card';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, injectIntl} from 'react-intl';
import CreateInsightMutation from '../../mutations/create_insight_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';

class AddComponents extends Component {
	constructor(props) {
		super(props);

		this.showUpgradePath = this.showUpgradePath.bind(this);
		// If we are trying to load step 2 of the new insight flow without a category, jump to step 1
		if (props.newInsight && props.category === null) this.props.history.push('/reports/new/step1');

		this.state = {
			selectedComponents: [],
			insightName: this.props.name || '',
		};

		// New components should be added with their unique component name in the array below.
		this.projectComponents = [
			'burnUp',
			'overallVelocity',
			'cardsDone',
			'plannedVsSpent',
			'sprintVelocity',
			'sprintsCompleted',
			'sprintList',
			'cummulativeFlow',
			'freeTextField',
			'sprintBurndownV2',
			'expenseReportProject',
		];

		this.portfolioComponents = [
			'clientList',
			'freeTextField',
			'expenseReportPortfolio',
			'timeRegisteredPerPersonPortfolio',
		];

		this.peopleComponents = ['freeTextField'];

		this.businessComponents = ['freeTextField'];

		this.comingSoonComponentsProject = [];
		this.comingSoonComponentsBusiness = [];
		this.comingSoonComponentsPeople = [];
		this.comingSoonComponentsPortfolio = [];

		switch (this.props.category) {
			case 'Projects':
				this.components = this.projectComponents;
				this.comingSoonComponents = this.comingSoonComponentsProject;
				break;
			case 'People':
				this.components = this.peopleComponents;
				this.comingSoonComponents = this.comingSoonComponentsPeople;
				break;
			case 'Business':
				this.components = this.businessComponents;
				this.comingSoonComponents = this.comingSoonComponentsBusiness;
				break;
			case 'Portfolio':
				this.components = this.portfolioComponents;
				this.comingSoonComponents = this.comingSoonComponentsPortfolio;
				break;
			default:
				this.components = [];
				this.comingSoonComponents = [];
				break;
		}
		this.components.sort();
		this.comingSoonComponents.sort();

		// This object holds the default columns for the list components. These will be the selected columns when a new list component is added.
		this.listColumns = {
			cardList: [
				'name',
				'sprint',
				'progress',
				'cardStatus',
				'forecast',
				'reported',
				'remaining',
				'projected',
				'assigned',
				'bug',
				'blocked',
			],
			sprintList: ['name', 'endDate', 'progress', 'cardsTodo', 'cardsInProgress', 'cardsDone', 'forecast', 'remaining'],
			peopleListPortfolio: [
				'name',
				'defaultRole',
				'skills',
				'permission',
				'totalProjects',
				'totalCards',
				'remaining',
				'reported',
				'spend',
			],
			peopleListProjects: ['name', 'projectRole', 'skills', 'todoCards', 'totalCards', 'remaining', 'reported', 'spend'],
			clientList: [
				'name',
				'totalProjects',
				'spend',
				'budget',
				'registered',
				'forecast',
				'remaining',
				'progress',
				'avgRatePrHourRegistered',
			],
			utilizationList: [
				'name',
				'role',
				'availableHours',
				'scheduledHours',
				'projectTimeHours',
				'nonProjectTimeHours',
				'registered',
				'overtimeHours',
			],
			reportedTimeBusiness: ['person', 'projectName', 'cardName', 'role', 'reported', 'price', 'cost'],
			reportedTimePeople: ['person', 'projectName', 'cardName', 'role', 'reported', 'price', 'cost'],
			reportedTimePortfolio: ['person', 'projectName', 'cardName', 'role', 'reported', 'price', 'cost'],
			reportedTimeProject: ['person', 'projectName', 'cardName', 'role', 'reported', 'price', 'cost'],
			cardListPortfolio: [
				'name',
				'projectName',
				'sprint',
				'progress',
				'cardStatus',
				'forecast',
				'reported',
				'remaining',
				'projected',
				'assigned',
				'bug',
				'blocked',
			],
			expenseReportProject: [
				'name',
				'cost',
				'price',
				'markup',
				'quantity',
				'person',
				'date',
				'billable',
				'updatedAt',
				'updatedBy',
			],
			expenseReportPortfolio: [
				'name',
				'projectName',
				'cost',
				'price',
				'markup',
				'quantity',
				'person',
				'date',
				'billable',
				'updatedAt',
				'updatedBy',
			],
		};
		if (!this.props.isUsingSchedulingPlanMode) {
			this.listColumns.utilizationList.push('actualBillableHours', 'actualBillablePercentage');
		}
	}

	showUpgradePath(component) {
		if ((component === 'expenseReportProject' || component === 'expenseReportPortfolio') && this.props.noFinance) {
			return true;
		}
		return false;
	}

	toggleSelect(component) {
		const selectedComponents = this.state.selectedComponents;
		if (selectedComponents.includes(component)) {
			const index = selectedComponents.indexOf(component);
			selectedComponents.splice(index, 1);
		} else {
			selectedComponents.push(component);
		}
		this.setState({selectedComponents});
	}

	addSelected() {
		const componentObjects = [];
		for (let i = 0; i < this.state.selectedComponents.length; i++) {
			componentObjects.push(this.getComponentObject(this.state.selectedComponents[i]));
		}
		this.props.addComponents(componentObjects);
		this.back();
	}

	back() {
		if (this.props.newInsight) this.props.history.goBack();
		else this.props.history.push(this.props.history.location.pathname.replace('/components', ''));
	}

	next(category) {
		//If only 1 component, skip placement
		if (this.state.selectedComponents.length === 1) {
			const component = this.getComponentObject(this.state.selectedComponents[0]);
			const onSuccess = result => {
				let message = '';
				switch (this.props.category) {
					case 'Projects':
						message = this.props.intl.formatMessage({id: 'new_report.report-created-project'});
						break;
					case 'People':
						message = this.props.intl.formatMessage({id: 'new_report.report-created-person'});
						break;
					case 'Business':
						message = this.props.intl.formatMessage({id: 'new_report.report-created-client'});
						break;
					default:
						message = this.props.intl.formatMessage({id: 'new_report.report-created'});
						break;
				}
				this.props.history.push('/reports/' + result.createInsight.insight.node.id);
				createToast({duration: 5000, message: message});
			};
			Util.CommitMutation(
				CreateInsightMutation,
				{
					companyId: this.props.companyId,
					name: this.state.insightName,
					category: this.props.category,
					components: [
						{
							id: component.id,
							componentName: component.componentName,
							x: component.x,
							y: component.y,
							w: component.w,
							h: component.h,
							config: component.config,
						},
					],
				},
				onSuccess
			);
		} else {
			// Add new components
			const componentObjects = [];
			for (let i = 0; i < this.state.selectedComponents.length; i++) {
				componentObjects.push(this.getComponentObject(this.state.selectedComponents[i]));
			}

			let y = 0;
			let x = 0;
			let heightAdded = 1;
			for (let i = 0; i < componentObjects.length; i++) {
				if (x + componentObjects[i].w > 4) {
					// increment row by 1 or 2 based on if a tall component was added to the previous row.
					y += heightAdded;
					x = 0;
				}

				componentObjects[i].x = x;
				componentObjects[i].y = y;
				if (componentObjects[i].h > heightAdded) heightAdded = componentObjects[i].h;
				x += componentObjects[i].w;
			}

			this.props.setComponents(componentObjects);
			this.props.setName(this.state.insightName);
			this.props.history.push('/reports/new/step3');
		}
	}

	cancel() {
		this.props.history.go(-2);
	}

	getComponentObject(component) {
		let width = 1;
		let height = 1;
		let config = {};
		// If a component has a different height or width than 1, add it as a case in the switch below.
		switch (component) {
			case 'burnUp':
			case 'sprintVelocity':
			case 'cummulativeFlow':
			case 'sprintBurndownV2':
				height = 2;
				width = 2;
				break;
			case 'overallVelocity':
				height = 2;
				break;
			// Lists. Don't forget to add the default columns to the 'listColumns' object in the constructor.
			case 'cardList':
			case 'cardListPortfolio':
			case 'sprintList':
			case 'reportedTimePeople':
			case 'reportedTimePortfolio':
			case 'reportedTimeProject':
			case 'reportedTimeBusiness':
			case 'peopleListPortfolio':
			case 'peopleListProjects':
			case 'clientList':
			case 'utilizationList':
			case 'utilizationBarChart':
			case 'expenseReportProject':
			case 'expenseReportPortfolio':
			case 'timeRegisteredPerPersonPortfolio':
				height = 2;
				width = 4;
				config.columns = this.listColumns[component];
				break;
			case 'utilizationMonthly':
				height = 2;
				width = 4;
				config = {
					tooLow: 74,
					low: 90,
					optimal: 105,
					high: 110,
					tooLowColor: '#FF7C75',
					lowColor: '#A56DFD',
					optimalColor: '#99FF8C',
					highColor: '#FFD761',
					tooHighColor: '#E93A65',
				};
				break;
			case 'projectTimeline':
				height = 2;
				width = 4;
				break;
			default:
				break;
		}
		return {
			componentName: component,
			i: uuid.v4(),
			w: width,
			h: height,
			x: 0,
			y: 0,
			config: JSON.stringify(config),
		};
	}

	handleNameInputChange(e) {
		this.setState({insightName: e.target.value});
	}

	render() {
		return (
			<div className="new-insight">
				<CustomScrollDiv>
					<div className="container">
						<div className="new-insight-step2">
							{this.props.newInsight ? (
								<div className="new-insight-header">
									<button className="back-button" onClick={this.back.bind(this)} />
									<h1>
										<FormattedMessage id={'new_report'} />
									</h1>
									<button className="cancel-button" onClick={this.cancel.bind(this)} />
								</div>
							) : (
								<div className="new-insight-header">
									<button className="back-button" onClick={this.back.bind(this)} />
									<h1 className="title">
										<FormattedMessage id="insights.add_components" />
									</h1>
									<div className="header-right" />
								</div>
							)}
							{this.props.newInsight ? (
								<h4 className="new-insight-step">
									<FormattedMessage id="new_insight.step2" />
								</h4>
							) : null}
							{this.props.newInsight ? (
								<h2 className="new-insight-description">
									<FormattedMessage id="new_insight.step2.description" />
								</h2>
							) : null}
							<div className="components">
								{this.components.map(component => {
									return (
										<ComponentCard
											noFinance={this.showUpgradePath(component)}
											key={component}
											componentName={component}
											selected={this.state.selectedComponents.includes(component)}
											toggleSelect={this.toggleSelect.bind(this)}
											infoText={this.props.intl.formatMessage({
												id: `insights.component.info.${component}`,
											})}
											AIPowered={false}
										/>
									);
								})}
								{this.comingSoonComponents.map(component => {
									return <ComponentCard key={component} componentName={component} comingSoon={true} />;
								})}
							</div>
						</div>
					</div>
				</CustomScrollDiv>
				<div className="footer">
					{this.props.newInsight ? (
						<div className="new-insight-name" data-cy="new-insight-name-input">
							<input
								placeholder={this.props.intl.formatMessage({id: 'new_report.name.placeholder'})}
								ref={input => (this.insight_name_input = input)}
								onChange={this.handleNameInputChange.bind(this)}
								value={this.state.insightName}
							/>
						</div>
					) : null}
					{this.props.newInsight ? (
						<button
							disabled={
								this.state.selectedComponents.length === 0 ||
								(this.insight_name_input && this.state.insightName === '')
							}
							className="next"
							onClick={this.next.bind(this)}
							data-cy="button-next"
						>
							<FormattedMessage id="common.next" />
						</button>
					) : (
						<button className="add-button" onClick={this.addSelected.bind(this)}>
							<FormattedMessage
								id="insights.add_x_components"
								values={{numOfComponents: this.state.selectedComponents.length}}
							/>
						</button>
					)}
				</div>
			</div>
		);
	}
}

AddComponents.propTypes = {};

export default injectIntl(withRouter(AddComponents));
