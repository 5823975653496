export const convertStatsToMapPerDay = statsBreakdown => {
	const statsMap = new Map();
	if (statsBreakdown) {
		statsBreakdown.forEach(statPerDay => {
			statsMap.set(statPerDay.date, {
				performance: statPerDay.performance,
			});
		});
	}
	return statsMap;
};
