import moment from 'moment';
import {
	addPredictedEndDateData,
	addPredictedEndDateNoAllocationData,
	addPredictedIntersection,
	addTodayIntersection,
	addTotalDoneScopeData,
	addTotalScopeData,
} from './PredictedEndDateModalChartDataFormat';
import {getDateArray, convertToMapPerDay, accumulateMapPerDay} from './PredictedEndDateModalLogic';

const getLastChartDate = (today, firstChartDate, projectEndDate, conservativePredictedEndDate) => {
	const lastProjectDate =
		!projectEndDate && !conservativePredictedEndDate
			? today
			: !conservativePredictedEndDate
			? projectEndDate
			: !projectEndDate
			? conservativePredictedEndDate
			: projectEndDate.isAfter(conservativePredictedEndDate)
			? projectEndDate
			: conservativePredictedEndDate;
	const projectStartEndDateDiff = lastProjectDate.diff(firstChartDate, 'days');

	// limit size of chart to 10 years
	if (projectStartEndDateDiff > 3650) {
		return firstChartDate.clone().add(3650, 'days');
	}

	// add buffer
	return lastProjectDate.clone().add(Math.ceil(projectStartEndDateDiff / 10) + 2, 'days');
};

export const getChartData = (projectHealth, statsPerDayMap, firstChartDate, projectEndDate, projectDone) => {
	const {
		totalTaskEstimate,
		totalBaselineEstimate,
		totalTaskEstimateDone,
		predictedEndDate,
		optimisticPredictedEndDate,
		conservativePredictedEndDate,
		predictedAverageScopePerDay,
		optimisticAverageScopePerDay,
		conservativeAverageScopePerDay,
	} = projectHealth;

	const estimateTarget = Math.max(totalTaskEstimate, totalBaselineEstimate);

	const predictedScopePerDay = projectDone ? [] : projectHealth.predictedScopePerDay;
	const optimisticPredictedScopePerDay = projectDone ? [] : projectHealth.optimisticPredictedScopePerDay;
	const conservativePredictedScopePerDay = projectDone ? [] : projectHealth.conservativePredictedScopePerDay;

	const today = moment();
	const predictedEndDateMoment = predictedEndDate && moment(predictedEndDate);
	const optimisticPredictedEndDateMoment = optimisticPredictedEndDate && moment(optimisticPredictedEndDate);
	const conservativePredictedEndDateMoment = conservativePredictedEndDate && moment(conservativePredictedEndDate);

	const lastAllocationDate =
		predictedScopePerDay.length > 0 ? moment(predictedScopePerDay[predictedScopePerDay.length - 1].date) : today;

	const lastChartDate = getLastChartDate(today, firstChartDate, projectEndDate, conservativePredictedEndDateMoment);
	const dateArray = getDateArray(firstChartDate, lastChartDate);

	const data = {
		totalScope: [],
		totalBaselineScope: [],
		doneScope: [],
		predictedEndDateData: [],
		optimisticPredictedEndDateData: [],
		conservativePredictedEndDateData: [],
		predictedEndDateNoAllocationData: [],
		optimisticPredictedEndDateNoAllocationData: [],
		conservativePredictedEndDateNoAllocationData: [],
		optimisticPredictedEndDateIntersection: [],
		predictedEndDateIntersection: [],
		conservativePredictedEndDateIntersection: [],
		todayIntersection: [],
		hideNoAllocationLabel: !conservativeAverageScopePerDay,
		hideBaselineData: totalBaselineEstimate === 0,
		baselineAsTarget: totalBaselineEstimate > totalTaskEstimate,
	};

	const predictedScopePerDayMap = accumulateMapPerDay(
		convertToMapPerDay(predictedScopePerDay),
		dateArray,
		totalTaskEstimateDone
	);
	const optimisticPredictedScopePerDayMap = accumulateMapPerDay(
		convertToMapPerDay(optimisticPredictedScopePerDay),
		dateArray,
		totalTaskEstimateDone
	);
	const conservativePredictedScopePerDayMap = accumulateMapPerDay(
		convertToMapPerDay(conservativePredictedScopePerDay),
		dateArray,
		totalTaskEstimateDone
	);

	// fallback in case some days have not been cached
	let previousTotalScope = 0;
	let previousTotalBaselineScope = 0;
	let previousTotalDoneScope = 0;
	let previousPredictedNoAllocationScope = predictedScopePerDayMap.get(lastAllocationDate.format('YYYY-MM-DD'));
	let previousOptimisticPredictedNoAllocationScope = optimisticPredictedScopePerDayMap.get(
		lastAllocationDate.format('YYYY-MM-DD')
	);
	let previousConservativePredictedNoAllocationScope = conservativePredictedScopePerDayMap.get(
		lastAllocationDate.format('YYYY-MM-DD')
	);

	dateArray.forEach(date => {
		const momentDate = moment(date);
		const isToday = momentDate.isSame(today, 'day');
		const isFuture = momentDate.isAfter(today, 'day');
		const isPast = momentDate.isBefore(today, 'day');
		const dateInfo = {date, momentDate, isToday, isPast, isFuture};
		const statDay = statsPerDayMap.get(date);

		// historical data
		const dayScope = addTotalScopeData(
			data.totalScope,
			totalTaskEstimate,
			statDay?.totalEstimateMinutes,
			previousTotalScope,
			dateInfo
		);
		if (dayScope) {
			previousTotalScope = dayScope;
		}

		const dayBaselineScope = addTotalScopeData(
			data.totalBaselineScope,
			totalBaselineEstimate,
			statDay?.totalBaselineEstimateMinutes,
			previousTotalBaselineScope,
			dateInfo
		);
		if (dayBaselineScope) {
			previousTotalBaselineScope = dayBaselineScope;
		}

		const dayDoneScope = addTotalDoneScopeData(
			data,
			totalTaskEstimateDone,
			statsPerDayMap,
			previousTotalDoneScope,
			dateInfo
		);
		if (dayDoneScope) {
			previousTotalDoneScope = dayDoneScope;
		}

		addTodayIntersection(data, totalTaskEstimateDone, dateInfo);

		// predicted data
		addPredictedEndDateData(
			data.predictedEndDateData,
			estimateTarget,
			totalTaskEstimateDone,
			predictedScopePerDayMap,
			lastAllocationDate,
			conservativePredictedEndDateMoment,
			dateInfo
		);

		const noAllocationDayScope = addPredictedEndDateNoAllocationData(
			data.predictedEndDateNoAllocationData,
			estimateTarget,
			previousPredictedNoAllocationScope,
			predictedAverageScopePerDay,
			lastAllocationDate,
			conservativePredictedEndDateMoment,
			dateInfo
		);
		if (noAllocationDayScope) {
			previousPredictedNoAllocationScope = noAllocationDayScope;
		}

		addPredictedIntersection(data.predictedEndDateIntersection, predictedEndDateMoment, momentDate, estimateTarget);

		// optimistic data
		addPredictedEndDateData(
			data.optimisticPredictedEndDateData,
			estimateTarget,
			totalTaskEstimateDone,
			optimisticPredictedScopePerDayMap,
			lastAllocationDate,
			conservativePredictedEndDateMoment,
			dateInfo
		);

		const optimisticNoAllocationDayScope = addPredictedEndDateNoAllocationData(
			data.optimisticPredictedEndDateNoAllocationData,
			estimateTarget,
			previousOptimisticPredictedNoAllocationScope,
			optimisticAverageScopePerDay,
			lastAllocationDate,
			conservativePredictedEndDateMoment,
			dateInfo
		);
		if (optimisticNoAllocationDayScope) {
			previousOptimisticPredictedNoAllocationScope = optimisticNoAllocationDayScope;
		}

		addPredictedIntersection(
			data.optimisticPredictedEndDateIntersection,
			optimisticPredictedEndDateMoment,
			momentDate,
			estimateTarget
		);

		// conservative data
		addPredictedEndDateData(
			data.conservativePredictedEndDateData,
			estimateTarget,
			totalTaskEstimateDone,
			conservativePredictedScopePerDayMap,
			lastAllocationDate,
			conservativePredictedEndDateMoment,
			dateInfo
		);

		const conservativeNoAllocationDayScope = addPredictedEndDateNoAllocationData(
			data.conservativePredictedEndDateNoAllocationData,
			estimateTarget,
			previousConservativePredictedNoAllocationScope,
			conservativeAverageScopePerDay,
			lastAllocationDate,
			conservativePredictedEndDateMoment,
			dateInfo
		);
		if (conservativeNoAllocationDayScope) {
			previousConservativePredictedNoAllocationScope = conservativeNoAllocationDayScope;
		}

		addPredictedIntersection(
			data.conservativePredictedEndDateIntersection,
			conservativePredictedEndDateMoment,
			momentDate,
			estimateTarget
		);
	});

	return data;
};

export const getChartDataSets = data => {
	const dataSets = [];

	dataSets.push({
		name: 'Conservative Prediction (not allocated)',
		color: '#d0f1f7',
		backgroundColor: 'rgba(208, 241, 247, 0.2)',
		dashed: true,
		filled: 11,
		data: data.conservativePredictedEndDateNoAllocationData,
		hideLegend: true,
	});

	dataSets.push({
		name: 'Prediction (not allocated)',
		color: '#d0f1f7',
		backgroundColor: 'rgba(208, 241, 247, 0.2)',
		dashed: true,
		filled: 10,
		data: data.predictedEndDateNoAllocationData,
		hideLegend: data.hideNoAllocationLabel,
		hideLabel: true,
	});

	dataSets.push({
		name: 'Optimistic Prediction (not allocated)',
		color: '#d0f1f7',
		backgroundColor: 'rgba(208, 241, 247, 0.2)',
		dashed: true,
		filled: 10,
		data: data.optimisticPredictedEndDateNoAllocationData,
		hideLegend: true,
	});

	dataSets.push({
		name: 'Conservative prediction',
		color: '#02D5FF',
		backgroundColor: 'rgba(2, 213, 255, 0.1)',
		borderDashOffset: 4,
		dashed: true,
		filled: 8,
		data: data.conservativePredictedEndDateData,
		hideLegend: true,
	});

	dataSets.push({
		name: 'Prediction',
		color: '#02D5FF',
		dashed: true,
		backgroundColor: 'rgba(2, 213, 255, 0.1)',
		borderDashOffset: 4,
		filled: 7,
		data: data.predictedEndDateData,
		hideLabel: true,
	});

	dataSets.push({
		name: 'Optimistic prediction',
		color: '#02D5FF',
		backgroundColor: 'rgba(2, 213, 255, 0.1)',
		borderDashOffset: 4,
		dashed: true,
		filled: 7,
		data: data.optimisticPredictedEndDateData,
		hideLegend: true,
	});

	dataSets.push({
		name: 'Done work',
		color: '#279FFF',
		backgroundColor: '#279FFF',
		data: data.doneScope,
	});

	dataSets.push({
		name: 'Baseline',
		color: `rgba(5, 226, 152, ${data.baselineAsTarget ? 1 : 0.4})`,
		data: data.hideBaselineData ? [] : data.totalBaselineScope,
		hideLegend: data.hideBaselineData,
		hideLabel: !data.baselineAsTarget,
	});

	dataSets.push({
		name: 'Planned work',
		color: `rgba(188, 151, 245, ${data.baselineAsTarget ? 0.4 : 1})`,
		data: data.totalScope,
		hideLabel: data.baselineAsTarget,
	});

	dataSets.push({
		name: 'Today intersection',
		pointRadius: 4,
		pointHoverRadius: 4,
		pointHoverBorderWidth: 2,
		color: '#E6E6E6',
		backgroundColor: '#FFF',
		borderColor: '#E6E6E6',
		data: data.todayIntersection,
		hideLegend: true,
		hideLabel: true,
	});

	dataSets.push({
		name: 'Conservative predicted end date intersection',
		pointRadius: 4,
		pointHoverRadius: 4,
		pointHoverBorderWidth: 2,
		color: '#02D5FF',
		backgroundColor: '#FFF',
		borderColor: '#02D5FF',
		data: data.conservativePredictedEndDateIntersection,
		hideLegend: true,
		hideLabel: true,
	});

	dataSets.push({
		name: 'Predicted end date intersection',
		pointRadius: 4,
		pointHoverRadius: 4,
		pointHoverBorderWidth: 2,
		color: '#02D5FF',
		backgroundColor: '#FFF',
		borderColor: '#02D5FF',
		data: data.predictedEndDateIntersection,
		hideLegend: true,
		hideLabel: true,
	});

	dataSets.push({
		name: 'Optimistic predicted end date intersection',
		pointRadius: 4,
		pointHoverRadius: 4,
		pointHoverBorderWidth: 2,
		color: '#02D5FF',
		backgroundColor: '#FFF',
		borderColor: '#02D5FF',
		data: data.optimisticPredictedEndDateIntersection,
		hideLegend: true,
		hideLabel: true,
	});

	return dataSets;
};
