import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import React from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import Styled from 'styled-components';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const PlaceholderModalContent = Styled.div`
	.warning-description {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 20px;
		padding-bottom: 64px;
		font-size: 13px;
	}
`;

const Header = Styled.div`
	display: inline-block
	font-size: 16px;
	font-weight: 600;
	.header-text {
		margin-left: 12px;
	}
`;

const PlaceholderStaffingUnsavedChanges = ({closeModal, onSave, onExit}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const content = (
		<PlaceholderModalContent>
			<ModalBody>
				<Field>
					<div className="warning-description">
						{formatMessage({id: 'placeholder.unsaved_changes_modal.description'})}
					</div>
				</Field>
			</ModalBody>
		</PlaceholderModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.MEDIUM}
			headerText={
				<Header>
					<div className={'header-text'}>{formatMessage({id: 'placeholder.unsaved_changes_modal.header'})}</div>
				</Header>
			}
			tertiaryButtons={[
				{
					text: formatMessage({id: 'common.do_not_save'}),
					callback: onExit,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			]}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					callback: () => {
						trackEvent('Staffing Mode Unsaved Changes Modal', 'Cancelled');
						closeModal();
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.save_changes'}),
					callback: () => {
						closeModal();
						onSave();
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN_APPROVE,
				},
			]}
		/>
	);
};

export default PlaceholderStaffingUnsavedChanges;
