import React from 'react';
import {useIntl, FormattedHTMLMessage} from 'react-intl';
import {DeprecatedText as Text, DeprecatedDestructiveModal as DestructiveModal} from '@forecast-it/design-system';

const RateCardRoleDeactivateWarningModal = ({deactivateRole, closeModal, projectsUsingRole}) => {
	const intl = useIntl();
	const projectsString = Array.from(projectsUsingRole).join(', ');

	return (
		<DestructiveModal
			destructiveButtonText={intl.formatMessage({id: 'common.deactivate'})}
			onDeleteHandler={() => {
				deactivateRole();
				closeModal();
			}}
			closeModal={closeModal}
			size={'m'}
			title={intl.formatMessage({id: 'rate_card_modal.role_in_use_warning_title'})}
		>
			<Text>
				<FormattedHTMLMessage id="rate_card_modal.role_in_use_warning_text" values={{projects: projectsString}} />
			</Text>
		</DestructiveModal>
	);
};

export default RateCardRoleDeactivateWarningModal;
