/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type loginSalesforce_viewer$ref = any;
export type loginSalesforce_QueryVariables = {||};
export type loginSalesforce_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: loginSalesforce_viewer$ref,
  |}
|};
export type loginSalesforce_Query = {|
  variables: loginSalesforce_QueryVariables,
  response: loginSalesforce_QueryResponse,
|};
*/


/*
query loginSalesforce_Query {
  viewer {
    component(name: "login_salesforce")
    ...loginSalesforce_viewer
    id
  }
}

fragment loginSalesforce_viewer on Viewer {
  id
  actualPersonId
  firstName
  lastName
  company {
    id
    salesforceEnabled
    modules {
      moduleType
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "login_salesforce"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"login_salesforce\")"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "loginSalesforce_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "loginSalesforce_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "loginSalesforce_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "salesforceEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "loginSalesforce_Query",
    "operationKind": "query",
    "text": "query loginSalesforce_Query {\n  viewer {\n    component(name: \"login_salesforce\")\n    ...loginSalesforce_viewer\n    id\n  }\n}\n\nfragment loginSalesforce_viewer on Viewer {\n  id\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    salesforceEnabled\n    modules {\n      moduleType\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8094f159a5266e4aa469a00452575fe4';

module.exports = node;
