/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type sharedInsight_viewer$ref = any;
export type sharedInsight_QueryVariables = {|
  shareKey?: ?string
|};
export type sharedInsight_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: sharedInsight_viewer$ref,
  |}
|};
export type sharedInsight_Query = {|
  variables: sharedInsight_QueryVariables,
  response: sharedInsight_QueryResponse,
|};
*/


/*
query sharedInsight_Query(
  $shareKey: String
) {
  viewer {
    component(name: "shared_insight")
    ...sharedInsight_viewer_D5pEI
    id
  }
}

fragment sharedInsight_viewer_D5pEI on Viewer {
  id
  downForMaintenance
  sharedInsight(shareKey: $shareKey) {
    id
    name
    companyName
    category
    labels {
      id
      name
      category {
        allowOnTasks
        allowOnProjects
        allowOnPeople
        id
      }
    }
    skills {
      id
      name
    }
    clients {
      id
      name
    }
    projects {
      id
      name
      client {
        id
      }
    }
    sprints {
      id
      name
      startYear
      startMonth
      startDay
      endYear
      endMonth
      endDay
      projectGroupSprintId
      isProjectGroupSprint
    }
    persons {
      id
      firstName
      lastName
    }
    departments {
      id
      name
    }
    roles {
      id
      name
    }
    teams {
      id
      name
    }
    components {
      id
      componentName
      x
      y
      w
      h
      config
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "shared_insight"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"shared_insight\")"
},
v2 = [
  {
    "kind": "Variable",
    "name": "shareKey",
    "variableName": "shareKey"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sharedInsight_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "sharedInsight_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sharedInsight_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downForMaintenance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Insight",
            "kind": "LinkedField",
            "name": "sharedInsight",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "labels",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LabelCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allowOnTasks",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allowOnProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allowOnPeople",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "skills",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "projects",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Client",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Sprint",
                "kind": "LinkedField",
                "name": "sprints",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endYear",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectGroupSprintId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isProjectGroupSprint",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "persons",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DepartmentType",
                "kind": "LinkedField",
                "name": "departments",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "roles",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InsightComponent",
                "kind": "LinkedField",
                "name": "components",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "componentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "x",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "y",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "w",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "h",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "config",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sharedInsight_Query",
    "operationKind": "query",
    "text": "query sharedInsight_Query(\n  $shareKey: String\n) {\n  viewer {\n    component(name: \"shared_insight\")\n    ...sharedInsight_viewer_D5pEI\n    id\n  }\n}\n\nfragment sharedInsight_viewer_D5pEI on Viewer {\n  id\n  downForMaintenance\n  sharedInsight(shareKey: $shareKey) {\n    id\n    name\n    companyName\n    category\n    labels {\n      id\n      name\n      category {\n        allowOnTasks\n        allowOnProjects\n        allowOnPeople\n        id\n      }\n    }\n    skills {\n      id\n      name\n    }\n    clients {\n      id\n      name\n    }\n    projects {\n      id\n      name\n      client {\n        id\n      }\n    }\n    sprints {\n      id\n      name\n      startYear\n      startMonth\n      startDay\n      endYear\n      endMonth\n      endDay\n      projectGroupSprintId\n      isProjectGroupSprint\n    }\n    persons {\n      id\n      firstName\n      lastName\n    }\n    departments {\n      id\n      name\n    }\n    roles {\n      id\n      name\n    }\n    teams {\n      id\n      name\n    }\n    components {\n      id\n      componentName\n      x\n      y\n      w\n      h\n      config\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2e04c1072fa729ed0e1609844f8316d1';

module.exports = node;
