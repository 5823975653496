import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import GenericModal from './generic_modal';

const NotPermittedProjectBudgetChangeModal = ({closeModal, programName, isInFixedPriceProgram, messageTranslationTag}) => {
	const {formatMessage} = useIntl();

	const content = (
		<div style={{width: '600px'}}>
			{programName ? (
				<FormattedHTMLMessage
					id={
						isInFixedPriceProgram
							? 'program.project_program_rights_warning_part1'
							: 'program.project_program_rights_warning_part1.capped'
					}
					values={{programName: programName}}
				/>
			) : (
				<FormattedHTMLMessage
					id={
						isInFixedPriceProgram
							? 'program.project_program_rights_warning_part1_no_program_name'
							: 'program.project_program_rights_warning_part1_no_program_name.capped'
					}
				/>
			)}
			<FormattedHTMLMessage id={messageTranslationTag} />
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.close'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
				},
			]}
			headerText={formatMessage({id: 'common.change_not_permitted'})}
			content={content}
		/>
	);
};

export default NotPermittedProjectBudgetChangeModal;
