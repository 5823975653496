import {createFragmentContainer, graphql} from 'react-relay';
import * as tracking from '../../tracking';
import {FormattedMessage, injectIntl} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import React, {Component} from 'react';
import ClipboardButton from 'react-clipboard.js';
import CreateInsightShareMutation from '../../mutations/create_insight_share_mutation';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DeleteInsightMutation from '../../mutations/delete_insight_mutation';
import DeleteInsightShareMutation from '../../mutations/delete_insight_share_mutation';
import GridLayout from '../../components/insights/grid_layout';
import HeaderTitleDropdown from '../../components/header_title_dropdown';
import InputField from '../../components/inputs/input_field';
import {withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import SendInsightShareEmailMutation from '../../mutations/send_insight_share_email_mutation';
import Util from '../../forecast-app/shared/util/util';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {cloneDeep} from 'lodash';
import {hasSomePermission, hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import DirectApi from '../../directApi';
import CompanySetupUtil from '../../forecast-app/shared/util/CompanySetupUtil';
import {getProjectIndicatorString} from '../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
import {getTaskUrl, pathIncludesTask} from '../../forecast-app/shared/util/UrlUtil';

const getProjectName = ({customProjectId, companyProjectId, projectName}) => {
	return `${getProjectIndicatorString(companyProjectId, customProjectId)} ${projectName}`;
};

class insightsViewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedProject: null,
			selectedPerson: null,
			selectedClient: null,
			selectExpanded: false,
			dropdownSearchValue: '',
			actionsMenuExpanded: false,
			shareMenuExpanded: false,
			dateSpan: null,
			fullScreen: false,
			shareEmails: '',
			shouldRender: true,
		};

		this.superPropertyChecksum = trackPage('Individual Insight', {
			insightComponents:
				this.props.viewer.company.insight && this.props.viewer.company.insight.components
					? this.props.viewer.company.insight.components.map(c => c.componentName)
					: undefined,
		});
	}

	UNSAFE_componentWillMount() {
		if (!hasPermission(PERMISSION_TYPE.INSIGHTS_READ)) {
			const path = this.props.history.location.pathname;
			if (pathIncludesTask(path)) {
				this.setState({shouldRender: false});
				this.props.history.push(getTaskUrl(path));
			} else {
				//validate access
				this.props.history.replace('/not-authorized');
			}
		}

		if (this.props.viewer.company && !this.props.viewer.company.insight) this.props.history.replace('/not-found');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage(
			'insights-insight',
			this.props.viewer.company.insight && this.props.viewer.company.insight.components
				? this.props.viewer.company.insight.components.map(c => c.componentName)
				: undefined
		);

		document.title = 'Insight - Forecast';
		if (this.state.editMode) {
			this.insightName.select();
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	editInsight() {
		this.props.history.push(this.props.location.pathname + '/edit');
	}

	deleteInsight() {
		const callbackPositive = params => {
			Util.CommitMutation(DeleteInsightMutation, {
				id: this.props.viewer.company.insight.id,
				companyId: this.props.viewer.company.id,
			});
			this.props.history.push('/insights');
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	handleSelectChange(selected) {
		if (selected) {
			if (this.props.viewer.company.insight.category === 'Projects') {
				Util.localStorageSetItem('insightProjectId', selected.id);
				this.setState({selectedProject: selected});
			} else if (this.props.viewer.company.insight.category === 'People') {
				Util.localStorageSetItem('insightPersonId', selected.id);
				this.setState({selectedPerson: selected});
			} else if (this.props.viewer.company.insight.category === 'Business') {
				Util.localStorageSetItem('insightClientId', selected.id);
				this.setState({selectedClient: selected});
			}
		}
	}

	handleInputBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget !== ReactDOM.findDOMNode(this.selectProjectDropdown)) {
			this.setState({selectProjectExpanded: false, dropdownSearchValue: ''});
		}
		e.stopPropagation();
	}

	changeDropdownSearchValue(e) {
		this.setState({dropdownSearchValue: e.target.value});
	}

	toggleActionsMenu(e) {
		e.stopPropagation();
		this.setState({
			actionsMenuExpanded: !this.state.actionsMenuExpanded,
		});
	}

	toggleShareMenu(e) {
		e.stopPropagation();
		this.setState({
			shareMenuExpanded: !this.state.shareMenuExpanded,
		});
		if (!this.state.shareMenuExpanded && this.emailsInput) {
			this.emailsInput.setFocusAfterRender(true);
		}
	}

	closeActionsMenu() {
		if (this.state.actionsMenuExpanded)
			this.setState({
				actionsMenuExpanded: false,
			});
	}

	closeShareMenu() {
		if (this.state.shareMenuExpanded)
			this.setState({
				shareMenuExpanded: false,
			});
	}

	toggleFullscreen() {
		if (this.state.fullScreen) {
			// We are about to exit fullscreen
			clearInterval(this.fullscreenTimeout);
		} else if (this.grid) {
			// We are about to enter fullscreen
			this.fullscreenTimeout = setInterval(() => {
				if (this.grid) {
					this.refresh(this.grid);
				} else {
					clearInterval(this.fullscreenTimeout);
				}
			}, 60000);
		}
		this.setState({fullScreen: !this.state.fullScreen});
	}

	handleShareBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			newTarget &&
			((newTarget.className &&
				(newTarget.className.indexOf('share-window') !== -1 || newTarget.className.indexOf('input') !== -1)) ||
				newTarget.nodeName === 'TEXTAREA')
		) {
			return;
		}
		this.setState({shareMenuExpanded: false});
	}

	createShareInfo(selected) {
		const category = this.props.viewer.company.insight.category;
		Util.CommitMutation(CreateInsightShareMutation, {
			insightId: this.props.viewer.company.insight.id,
			projectId: selected && category === 'Projects' && !selected.isProjectGroup ? selected.id : null,
			projectGroupId: selected && category === 'Projects' && selected.isProjectGroup ? selected.id : null,
			personId: selected && category === 'People' ? selected.id : null,
			clientId: selected && category === 'Business' ? selected.id : null,
		});
	}

	deleteShareInfo(selected) {
		this.closeShareMenu();
		const shareInfo = this.findShareInfo(selected);
		if (shareInfo) {
			Util.CommitMutation(DeleteInsightShareMutation, {
				id: shareInfo.node.id,
				insightId: this.props.viewer.company.insight.id,
			});
		}
	}

	onEmailsChange(value) {
		this.setState({shareEmails: value});
	}

	share(selected) {
		const shareInfo = this.findShareInfo(selected);
		const onSuccess = result => {
			this.setState({shareEmails: ''});
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'share_insight.emails_sent'})});
		};

		Util.CommitMutation(
			SendInsightShareEmailMutation,
			{
				insightShareId: shareInfo.node.id,
				emails: this.state.shareEmails,
			},
			onSuccess
		);
	}

	refresh(grid) {
		grid.refresh();
	}

	findShareInfo(selected) {
		if (this.props.viewer.company.insight.shares === undefined) return false;
		return this.props.viewer.company.insight.shares.edges
			.filter(e => e.node != null)
			.find(shareEdge => {
				const share = shareEdge.node;
				const category = this.props.viewer.company.insight.category;
				if (category === 'Portfolio') {
					return share.project === null && share.person === null && share.client === null;
				} else if (selected) {
					if (category === 'Projects') {
						if (selected.isProjectGroup) {
							return share.projectGroup && share.projectGroup.id === selected.id;
						}
						return share.project && share.project.id === selected.id;
					}
					if (category === 'People') {
						return share.person && share.person.id === selected.id;
					}
					if (category === 'Business') {
						return share.client && share.client.id === selected.id;
					}
				}
				return false;
			});
	}

	focusLink() {
		if (this.link) {
			this.link.focus();
		}
	}

	focusPassword() {
		if (this.password) {
			this.password.focus();
		}
	}

	setScrollElement(isFullScreen, element) {
		let scrollElement, scrollElementFullScreen;
		if (isFullScreen) {
			scrollElementFullScreen = element;
			scrollElement = null;
		} else {
			scrollElement = element;
			scrollElementFullScreen = null;
		}
		this.setState({scrollElement, scrollElementFullScreen});
	}

	render() {
		if (!this.props.viewer.company.insight || !this.state.shouldRender) return <div />;

		let components = cloneDeep(this.props.viewer.company.insight.components);
		// Modifying some props to implement the feature flag use_studio_dashboard
		components = components ? components.filter(e => e.componentName !== 'studioDashboard') : [];

		const {formatMessage} = this.props.intl;
		const category = this.props.viewer.company.insight.category;
		let elements = [];
		let selectedElementFromLocalStorage = null;
		let selected = null;
		const localStorageId =
			category === 'Projects'
				? localStorage.getItem('insightProjectId')
				: category === 'Business'
				? localStorage.getItem('insightClientId')
				: localStorage.getItem('insightPersonId');

		if (category === 'Projects') {
			this.props.viewer.projectGroups.edges.forEach(group => {
				if (group.node.projects && group.node.projects.edges.length > 0) {
					elements.push({
						node: {
							id: group.node.id,
							name: 'X-' + group.node.companyProjectGroupId + ' ' + group.node.name,
							isProjectGroup: true,
						},
					});
					group.node.projects.edges
						.filter(project => project.node.fullAccessToProject)
						.forEach(project =>
							elements.push({
								node: {
									id: project.node.id,
									name: getProjectName({
										customProjectId: project.node.customProjectId,
										companyProjectId: project.node.companyProjectId,
										projectName: project.node.name,
									}),
									isInProjectGroup: true,
								},
							})
						);
				}
			});
			this.props.viewer.projects.edges
				.filter(project => project.node.fullAccessToProject && !project.node.isInProjectGroup)
				.forEach(project =>
					elements.push({
						node: {
							id: project.node.id,
							name: getProjectName({
								customProjectId: project.node.customProjectId,
								companyProjectId: project.node.companyProjectId,
								projectName: project.node.name,
							}),
						},
					})
				);
		} else if (category === 'People') {
			elements = this.props.viewer.company.allPersons.edges.map(person => {
				return {node: {id: person.node.id, name: person.node.firstName + ' ' + person.node.lastName}};
			});
		} else if (category === 'Business') {
			elements = this.props.viewer.company.clients.edges.map(client => {
				return {node: {id: client.node.id, name: client.node.name}};
			});
		}
		if (localStorageId) {
			for (let i = 0; i < elements.length; i++) {
				if (elements[i].node.id === localStorageId) {
					selectedElementFromLocalStorage = elements[i].node;
					break;
				}
			}
		}
		if (category === 'Projects') {
			selected = this.state.selectedProject ? this.state.selectedProject : selectedElementFromLocalStorage;
		} else if (category === 'People') {
			selected = this.state.selectedPerson ? this.state.selectedPerson : selectedElementFromLocalStorage;
		} else if (category === 'Business') {
			selected = this.state.selectedClient ? this.state.selectedClient : selectedElementFromLocalStorage;
		}

		const projectOptions =
			category === 'Business' && selected
				? cloneDeep(this.props.viewer.company.insight.projects).filter(
						project => project.client && project.client.id === selected.id
				  )
				: cloneDeep(this.props.viewer.company.insight.projects)
				? cloneDeep(this.props.viewer.company.insight.projects)
				: [];

		const projectOptionIds = projectOptions.map(option => {
			return option.id;
		});
		for (const idleTimeEdge of this.props.viewer.company.idleTimes.edges) {
			if (category !== 'Business' && category !== 'Projects' && !projectOptionIds.includes(idleTimeEdge.node.id)) {
				projectOptions.push(idleTimeEdge.node);
			}
		}

		const noDataLanguageId =
			category === 'Business'
				? 'insights.no_client_selected'
				: category === 'Projects'
				? 'insights.no_project_selected'
				: 'insights.no_person_selected';

		const shareInfo = this.findShareInfo(selected);
		// key needed in order to mount new GridLayout on project, client, person change and recalculate windowScroller placement correctly
		const gridKey = category + '_' + (selected ? (selected.id ? selected.id : selected) : 'none');

		const canViewFinancial =
			CompanySetupUtil.hasFinance() &&
			hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]);
		// Full screen
		if (this.state.fullScreen) {
			return (
				<div className="full-screen-mode">
					<div className="page-header">
						<a className="logo" />
						<h4 className="page-title">{formatMessage({id: 'insights.full_screen_forecast_title'})}</h4>
						<button
							title="Exit fullscreen"
							className="exit-full-screen"
							onClick={this.toggleFullscreen.bind(this)}
						/>
					</div>
					<CustomScrollDiv setScrollbarsRef={this.setScrollElement.bind(this, true)}>
						<div className="insights-viewer">
							{this.props.children}
							<div className="main-container">
								{selected || category === 'Portfolio' ? (
									<div className="insight-container" data-cy="insight-container">
										{components && this.state.scrollElementFullScreen ? (
											<GridLayout
												ref={grid => {
													this.grid = grid;
												}}
												components={components}
												editMode={false}
												key={gridKey}
												labelsOptions={this.props.viewer.company.insight.labels}
												skillsOptions={this.props.viewer.company.insight.skills}
												contactPersonOptions={this.props.viewer.projects.edges.reduce((acc, curr) => {
													return curr.node.projectPersons
														? [
																...acc,
																...curr.node.projectPersons.edges
																	.filter(
																		ppedge =>
																			!acc.some(el => el.id === ppedge.node.person.id)
																	)
																	.map(ppedge => ppedge.node.person),
														  ]
														: acc;
												}, [])}
												rateCardOptions={this.props.viewer.company.rateCards.edges.map(
													redge => redge.node
												)}
												clientsOptions={this.props.viewer.company.insight.clients}
												projectOptions={projectOptions}
												teamOptions={this.props.viewer.company.insight.teams}
												projectId={
													category === 'Projects' && selected && !selected.isProjectGroup
														? selected.id
														: null
												}
												projectGroupId={
													category === 'Projects' && selected && selected.isProjectGroup
														? selected.id
														: null
												}
												insightId={this.props.insightId}
												personId={category === 'People' && selected ? selected.id : null}
												dateSpan={this.state.dateSpan ? this.state.dateSpan : null}
												clientId={category === 'Business' && selected ? selected.id : null}
												showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
												canViewFinancial={canViewFinancial}
												projectGroupOptions={this.props.viewer.company.insight.projectGroups}
												currency={this.props.viewer.company.currency}
												personOptions={this.props.viewer.company.insight.persons}
												roleOptions={this.props.viewer.company.insight.roles}
												projectStageOptions={['PLANNING', 'RUNNING', 'HALTED', 'DONE']}
												scrollElementFullScreen={this.state.scrollElementFullScreen}
												xeroEnabled={this.props.viewer.company.xeroEnabled}
												availableFeatureFlags={this.props.viewer.availableFeatureFlags}
												modules={this.props.viewer.company.modules}
											/>
										) : null}
									</div>
								) : (
									<div className="no-data">{formatMessage({id: noDataLanguageId})}</div>
								)}
							</div>
						</div>
					</CustomScrollDiv>
				</div>
			);
		}

		const defaultTitle =
			category === 'Business'
				? this.props.intl.formatMessage({id: 'insights.select-client'})
				: category === 'Projects'
				? this.props.intl.formatMessage({id: 'cards.select-project'})
				: this.props.intl.formatMessage({id: 'cards.select-person'});

		return (
			<CustomScrollDiv setScrollbarsRef={this.setScrollElement.bind(this, false)}>
				<div className="insights-viewer">
					{this.props.children}

					<div className="main-container">
						<div className="top-container">
							{category !== 'Portfolio' ? (
								<HeaderTitleDropdown
									ref={dropdown => {
										this.selectProjectDropdown = dropdown;
									}}
									selectedElement={selected}
									elements={elements}
									expanded={this.state.selectProjectExpanded}
									handleSelectElement={this.handleSelectChange.bind(this)}
									handleInputBlur={this.handleInputBlur.bind(this)}
									changeDropdownSearchValue={this.changeDropdownSearchValue.bind(this)}
									handleClick={() =>
										this.setState({selectProjectExpanded: !this.state.selectProjectExpanded})
									}
									handleCollapse={() =>
										this.setState({selectProjectExpanded: false, dropdownSearchValue: ''})
									}
									searchValue={this.state.dropdownSearchValue}
									inputPlaceholder={defaultTitle}
									emptyTitle={this.props.intl.formatMessage({id: 'common.untitled'})}
									defaultTitle={defaultTitle}
									titlePrefix={this.props.intl.formatMessage({id: 'insights.view_data_for'})}
									isInsightSelector={true}
									cy={defaultTitle}
								/>
							) : (
								<h2 className="title-portfolio">
									<FormattedMessage id="insights.view_data_for_portfolio" />
								</h2>
							)}
							<div className="controls">
								{selected || category === 'Portfolio' ? (
									<div
										className={'share share-window ' + (this.state.shareMenuExpanded ? 'expanded' : '')}
										tabIndex="0"
										onBlur={this.handleShareBlur.bind(this)}
									>
										<button
											ref={button => {
												this.shareButton = button;
											}}
											data-cy="share-button"
											className={
												'share-button image-button ' + (this.state.shareMenuExpanded ? 'expanded' : '')
											}
											onClick={this.toggleShareMenu.bind(this)}
										/>
										<div
											className={
												'share-drop-down ' + (this.state.shareMenuExpanded ? 'expanded' : 'collapsed')
											}
										>
											{shareInfo ? (
												<div>
													<h3>
														{formatMessage(
															{id: 'share_reports.title'},
															{value: this.props.viewer.company.insight.name}
														)}
													</h3>
													<div className="flex-row">
														<InputField
															label={formatMessage({id: 'common.link'})}
															value={
																'https://app.forecast.it/sharedinsight/' +
																shareInfo.node.uuid +
																'?dc=' +
																DirectApi.getDatacenter()
															}
															disabled={true}
															type="text"
															placeholder=""
															className="share-window"
															ref={link => {
																this.link = link;
															}}
															cy="sharable-link"
														/>
														<ClipboardButton
															className="copy-button image-button share-window"
															title="Copy"
															data-clipboard-text={
																'https://app.forecast.it/sharedinsight/' +
																shareInfo.node.uuid +
																'?dc=' +
																DirectApi.getDatacenter()
															}
															onSuccess={this.focusLink.bind(this)}
														/>
													</div>
													<div className="flex-row">
														<InputField
															label={formatMessage({id: 'user-settings.security.password'})}
															value={shareInfo.node.password}
															disabled={true}
															type="text"
															placeholder=""
															className="share-window"
															ref={password => {
																this.password = password;
															}}
															cy="sharable-password"
														/>
														<ClipboardButton
															className="copy-button image-button share-window"
															title={formatMessage({id: 'common.copy'})}
															data-clipboard-text={shareInfo.node.password}
															onSuccess={this.focusPassword.bind(this)}
														/>
													</div>
													<div className="flex-row">
														<InputField
															ref={emailsInput => {
																this.emailsInput = emailsInput;
															}}
															label={formatMessage({id: 'share_insight.emails.label'})}
															value={this.state.shareEmails}
															onChange={this.onEmailsChange.bind(this)}
															disabled={false}
															type="text"
															placeholder={formatMessage({
																id: 'share_insight.emails.placeholder',
															})}
															className="share-window"
														/>
														<div className="flex-space" />
													</div>
													<div className="buttons">
														<button
															className="share-window unshare-btn"
															onClick={this.deleteShareInfo.bind(this, selected)}
														>
															{formatMessage({id: 'share_insight.button.unshare'})}
														</button>
														<button
															data-cy="share-window-button"
															className="share-window share-btn"
															onClick={this.share.bind(this, selected)}
														>
															{formatMessage({id: 'common.send'})}
														</button>
													</div>
												</div>
											) : (
												<div className="create-share-container">
													<h3>{formatMessage({id: 'share_insight.description'})}</h3>
													<button
														data-cy="create-share"
														className="create-share share-window"
														title="Share insight"
														onClick={this.createShareInfo.bind(this, selected)}
													>
														{formatMessage({id: 'share_insight.button.share'})}
													</button>
												</div>
											)}
										</div>
									</div>
								) : null}

								<button
									title="Fullscreen"
									className="full-screen image-button"
									onClick={this.toggleFullscreen.bind(this)}
								/>
								{hasPermission(PERMISSION_TYPE.INSIGHTS_UPDATE) ? (
									<div
										title="Actions"
										className="actions image-button"
										onBlur={this.closeActionsMenu.bind(this)}
										onClick={this.toggleActionsMenu.bind(this)}
										tabIndex="0"
									>
										<div
											className={
												'actions-drop-down ' +
												(this.state.actionsMenuExpanded ? 'expanded' : 'collapsed')
											}
										>
											<ul>
												{
													// <li>
													//     <FormattedMessage id='common.duplicate'/>
													// </li>
												}
												<li onClick={this.deleteInsight.bind(this)}>
													<FormattedMessage id="common.delete" />
												</li>
												<li onClick={this.editInsight.bind(this)}>
													<FormattedMessage id="common.edit" />
												</li>
												{
													// TODO: add no-print css to all parts of the page that we do not want printed.
													// <li onClick={() => {window.print();}}>
													//     <FormattedMessage id='common.print'/>
													// </li>
												}
											</ul>
										</div>
									</div>
								) : null}
							</div>
						</div>

						{selected || category === 'Portfolio' ? (
							<div className="insight-container" data-cy="insight-container">
								{components && this.state.scrollElement ? (
									<GridLayout
										ref={grid => {
											this.grid = grid;
										}}
										key={gridKey}
										labelsOptions={this.props.viewer.company.insight.labels}
										skillsOptions={this.props.viewer.company.insight.skills}
										clientsOptions={this.props.viewer.company.insight.clients}
										projectOptions={projectOptions}
										projectGroupOptions={this.props.viewer.company.insight.projectGroups}
										personOptions={this.props.viewer.company.insight.persons}
										departmentOptions={this.props.viewer.company.insight.departments}
										roleOptions={this.props.viewer.company.insight.roles}
										teamOptions={this.props.viewer.company.insight.teams}
										contactPersonOptions={this.props.viewer.projects.edges.reduce((acc, curr) => {
											return curr.node.projectPersons
												? [
														...acc,
														...curr.node.projectPersons.edges
															.filter(ppedge => !acc.some(el => el.id === ppedge.node.person.id))
															.map(ppedge => ppedge.node.person),
												  ]
												: acc;
										}, [])}
										rateCardOptions={this.props.viewer.company.rateCards.edges.map(redge => redge.node)}
										components={components}
										editMode={false}
										projectId={
											category === 'Projects' && selected && !selected.isProjectGroup ? selected.id : null
										}
										projectGroupId={
											category === 'Projects' && selected && selected.isProjectGroup ? selected.id : null
										}
										insightId={this.props.insightId}
										personId={category === 'People' && selected ? selected.id : null}
										dateSpan={this.state.dateSpan ? this.state.dateSpan : null}
										clientId={category === 'Business' && selected ? selected.id : null}
										showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
										canViewFinancial={canViewFinancial}
										currency={this.props.viewer.company.currency}
										scrollElement={this.state.scrollElement}
										xeroEnabled={this.props.viewer.company.xeroEnabled}
										availableFeatureFlags={this.props.viewer.availableFeatureFlags}
										modules={this.props.viewer.company.modules}
									/>
								) : null}
							</div>
						) : (
							<div className="no-data">{formatMessage({id: noDataLanguageId})}</div>
						)}
					</div>
				</div>
			</CustomScrollDiv>
		);
	}
}

const insightsViewerQuery = graphql`
	query insightsViewer_Query($insightId: ID) {
		viewer {
			actualPersonId
			component(name: "insights_viewer")
			company {
				insight(id: $insightId) {
					id
				}
			}
			...insightsViewer_viewer @arguments(insightId: $insightId)
		}
	}
`;

export {insightsViewerQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(insightsViewer, {
			viewer: graphql`
				fragment insightsViewer_viewer on Viewer @argumentDefinitions(insightId: {type: "ID"}) {
					id
					language
					availableFeatureFlags {
						id
						name
						key
					}
					company {
						id
						currency
						xeroEnabled
						modules {
							moduleType
						}
						insight(id: $insightId) {
							id
							name
							category
							labels {
								id
								name
								category {
									allowOnPeople
									allowOnProjects
									allowOnTasks
								}
							}
							skills {
								id
								name
							}
							clients {
								id
								name
							}
							roles {
								id
								name
							}
							persons {
								id
								firstName
								lastName
								active
							}
							departments {
								id
								name
							}
							projects {
								id
								name
								sprintTimeBox
								client {
									id
								}
								sprints(first: 1000) {
									edges {
										node {
											id
											name
											startYear
											startMonth
											startDay
											endYear
											endMonth
											endDay
										}
									}
								}
							}
							projectGroups {
								id
								name
								companyProjectGroupId
								projects(first: 100000) {
									edges {
										node {
											id
											sprints(first: 1000) {
												edges {
													node {
														id
														name
														isProjectGroupSprint
														projectGroupSprintId
														startYear
														startMonth
														startDay
														endYear
														endMonth
														endDay
													}
												}
											}
										}
									}
								}
							}
							teams {
								id
								name
							}
							components {
								id
								componentName
								x
								y
								w
								h
								config
							}
							shares(first: 100000) @connection(key: "Insight_shares") {
								edges {
									node {
										id
										uuid
										password
										project {
											id
											sprints(first: 1000) {
												edges {
													node {
														id
														name
													}
												}
											}
										}
										person {
											id
										}
										client {
											id
										}
										projectGroup {
											id
										}
									}
								}
							}
						}
						allPersons(first: 100000, onlyActive: true, excludeClientUsers: true) {
							edges {
								node {
									id
									firstName
									lastName
								}
							}
						}
						clients(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						idleTimes(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						rateCards(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
					projects(first: 100000) {
						edges {
							node {
								id
								name
								status
								companyProjectId
								customProjectId
								isInProjectGroup
								fullAccessToProject
								projectPersons(contactsOnly: true, first: 10000) {
									edges {
										node {
											id
											person {
												id
												fullName
											}
										}
									}
								}
							}
						}
					}
					projectGroups(first: 1000) @connection(key: "Viewer_projectGroups", filters: []) {
						edges {
							node {
								id
								name
								companyProjectGroupId
								projects(first: 100000) {
									edges {
										node {
											id
											name
											status
											fullAccessToProject
											companyProjectId
											customProjectId
										}
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
