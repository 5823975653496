import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect } from 'react';
import GoogleCalendarOauthButton from './oauth-button/GoogleCalendarOauthButton';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import UpdateGoogleCalendarTokenMutation from '../../../mutations/update_google_calendar_token_mutation';
import { LOCALSTORAGE_TIMESHEETS_SELECTED_TAB } from '../../../forecast-app/my-work-tab/my-timesheets-page/top-section/TimesheetsCalendarUtil';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import { TRACKING_OBJECTS } from '../../../tracking/amplitude/constants/TrackingObjects';
const GoogleCalendarOAuth = ({ calendarTokenError, personId }) => {
    const authFailure = (error) => {
        const errorMessage = error.message || error.error_description;
        if (errorMessage) {
            trackEvent(TRACKING_OBJECTS.LINK_GOOGLE_CALENDAR_ACCOUNT, 'Failed', { reason: 'Authentication Error' });
            showModal({
                type: MODAL_TYPE.GOOGLE_CALENDAR_OAUTH_ERROR,
                errorMessage,
            });
        }
    };
    const onNonOAuthError = (nonOAuthError) => {
        switch (nonOAuthError.type) {
            case 'popup_closed':
            case 'popup_failed_to_open':
                break;
            default:
                trackEvent(TRACKING_OBJECTS.LINK_GOOGLE_CALENDAR_ACCOUNT, 'Failed', { reason: 'Unknown Error' });
                showModal({
                    type: MODAL_TYPE.GOOGLE_CALENDAR_OAUTH_ERROR,
                    errorMessage: 'An unknown error happened',
                });
        }
    };
    const authSuccess = (response) => {
        trackEvent(TRACKING_OBJECTS.LINK_GOOGLE_CALENDAR_ACCOUNT, 'Succeeded');
        localStorage.removeItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB);
        Util.CommitMutation(UpdateGoogleCalendarTokenMutation, {
            personId: personId,
            authorizationCode: response.code,
        });
    };
    useEffect(() => {
        trackEvent(TRACKING_OBJECTS.LINK_GOOGLE_CALENDAR_ACCOUNT, 'Shown');
    }, []);
    return (React.createElement("div", { "data-cy": 'google-calendar-oauth-button' },
        React.createElement(GoogleOAuthProvider, { clientId: "354239394989-v52u48ags9bvvchjrh0ipf6ii425h54k.apps.googleusercontent.com" },
            React.createElement(GoogleCalendarOauthButton, { title: 'Google Calendar Auth', onSuccess: authSuccess, onError: authFailure, onNonOAuthError: onNonOAuthError, calendarTokenError: calendarTokenError }))));
};
export default GoogleCalendarOAuth;
