/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ExportInvoiceButton_company$ref = any;
type InvoiceFilterButton_company$ref = any;
type ProjectInvoicingTable_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingTableSection_company$ref: FragmentReference;
declare export opaque type ProjectInvoicingTableSection_company$fragmentType: ProjectInvoicingTableSection_company$ref;
export type ProjectInvoicingTableSection_company = {|
  +newXeroEnabled: ?boolean,
  +quickbooksEnabled: ?boolean,
  +economicEnabled: ?boolean,
  +sageIntacctConnectionEnabled: ?boolean,
  +$fragmentRefs: ProjectInvoicingTable_company$ref & ExportInvoiceButton_company$ref & InvoiceFilterButton_company$ref,
  +$refType: ProjectInvoicingTableSection_company$ref,
|};
export type ProjectInvoicingTableSection_company$data = ProjectInvoicingTableSection_company;
export type ProjectInvoicingTableSection_company$key = {
  +$data?: ProjectInvoicingTableSection_company$data,
  +$fragmentRefs: ProjectInvoicingTableSection_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectInvoicingTableSection_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newXeroEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quickbooksEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "economicEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sageIntacctConnectionEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectInvoicingTable_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportInvoiceButton_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceFilterButton_company"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '0870e7f14fe6062ac836427bea236dbd';

module.exports = node;
