import React from 'react';
import {useIntl} from 'react-intl';
import {Chart} from 'web-components';
import {getTimeUnit} from '../../../../../components/new-ui/project/project-budget-v3/util/ChartsUtil';
import Util from '../../../../../forecast-app/shared/util/util';

export const ForecastSurplusModalChart = ({data, firstDate, lastDate, currencySymbol}) => {
	const intl = useIntl();

	const modifyChartOptions = options => {
		options.scales.x.time.unit = getTimeUnit(firstDate, lastDate);
		options.scales.y.ticks.callback = label => Util.getFormattedNumberWithCurrency(currencySymbol, label, intl);
		options.plugins.legend.onClick = e => e.native.stopPropagation();
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			if (item.hideLabel || (dataItem.customData && dataItem.customData.hideLabel)) {
				return '';
			} else {
				const dataSetLabel = item.label ? `${item.label}: ` : '';
				return `${dataSetLabel}${Util.getFormattedNumberWithCurrency(currencySymbol, dataItem.y, intl)}`;
			}
		};
		return options;
	};

	const dataSets = [];

	dataSets.push({
		name: 'Forecast billable time and expenses to complete',
		color: '#279FFF',
		data: data.forecastRevenueChartData,
		dashed: true,
		borderDashOffset: 4,
	});

	dataSets.push({
		name: 'Actual billable time and expenses',
		color: '#279FFF',
		data: data.actualRevenueChartData,
	});

	dataSets.push({
		name: 'revenueMarginTopData',
		color: 'rgba(251, 49, 40, 0.15)',
		backgroundColor: 'rgba(251, 49, 40, 0.15)',
		filled: 3,
		data: data.fixedPriceChartData,
		hideLegend: true,
		hideLabel: true,
	});

	dataSets.push({
		name: 'revenueMarginHighData',
		color: 'rgba(255, 200, 51, 0.15)',
		backgroundColor: 'rgba(255, 200, 51, 0.15)',
		filled: 2,
		data: data.revenueMarginHighData,
		hideLegend: true,
		hideLabel: true,
	});

	dataSets.push({
		name: 'revenueMarginLowData',
		color: 'rgba(73, 177, 83, 0.15)',
		backgroundColor: 'rgba(73, 177, 83, 0.15)',
		filled: true,
		data: data.revenueMarginLowData,
		hideLegend: true,
		hideLabel: true,
	});

	dataSets.push({
		name: 'Fixed Price Budget',
		color: '#bc97f5',
		backgroundColor: '#bc97f5',
		data: data.fixedPriceChartData,
	});

	dataSets.push({
		name: 'Today intersection',
		pointRadius: 4,
		pointHoverRadius: 4,
		pointHoverBorderWidth: 2,
		color: '#E6E6E6',
		backgroundColor: '#FFF',
		borderColor: '#E6E6E6',
		data: data.todayIntersection,
		hideLegend: true,
	});

	return <Chart dataSets={dataSets} modifyOptions={modifyChartOptions} />;
};
