/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConvertOldSubtasksModal_viewer$ref: FragmentReference;
declare export opaque type ConvertOldSubtasksModal_viewer$fragmentType: ConvertOldSubtasksModal_viewer$ref;
export type ConvertOldSubtasksModal_viewer = {|
  +id: string,
  +project: ?{|
    +remainingAutoCalculated: ?boolean,
    +minutesPerEstimationPoint: ?number,
    +estimationUnit: ?string,
    +remaining: ?number,
    +forecastTotal: ?number,
    +tasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +timeLeft: ?number,
          +estimateForecast: ?number,
          +done: ?boolean,
          +totalMinutesRegistered: ?number,
          +subTasks: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +estimate: ?number,
                +done: ?boolean,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +$refType: ConvertOldSubtasksModal_viewer$ref,
|};
export type ConvertOldSubtasksModal_viewer$data = ConvertOldSubtasksModal_viewer;
export type ConvertOldSubtasksModal_viewer$key = {
  +$data?: ConvertOldSubtasksModal_viewer$data,
  +$fragmentRefs: ConvertOldSubtasksModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "done",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConvertOldSubtasksModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "id"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remainingAutoCalculated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutesPerEstimationPoint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remaining",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "tasks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timeLeft",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecast",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalMinutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SubTaskTypeConnection",
                      "kind": "LinkedField",
                      "name": "subTasks",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SubTaskTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SubTask",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "estimate",
                                  "storageKey": null
                                },
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2c6474b09978d410f711ce31f18370b';

module.exports = node;
