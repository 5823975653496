import React, {Component} from 'react';
import * as tracking from '../tracking';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import LogoutMutation from '../mutations/logout_mutation';
import Util from '../forecast-app/shared/util/util';
import {TIERS} from '../constants';

class trialEnd extends Component {
	UNSAFE_componentWillMount() {
		if (this.props.viewer.company.tier !== TIERS.TRIAL) {
			this.props.history.push({
				pathname: '/',
			});
		}
	}

	componentDidMount() {
		// Segment
		document.title = 'Trial ended - Forecast';
		tracking.trackPage('trial-end');
	}

	handleLogout() {
		const onSuccess = () => {
			tracking.logout();
			window.location.href = window.location.origin;
		};

		Util.CommitMutation(LogoutMutation, null, onSuccess);
	}

	render() {
		return (
			<div
				style={{
					position: 'absolute',
					left: '0px',
					top: '0px',
					height: '100%',
					width: '100%',
					display: 'flex',
					zIndex: '200',
				}}
			>
				<div className="fullscreen fullscreen-centered-outer trial-end-v2">
					<div className="fullscreen-centered-inner">
						<div className="trial-end-wrapper">
							<div className="trial-end-card large no-permission">
								<div className="company-details-v2" data-cy={'company-details'}>
									<h3 className="no-top-margin">Your trial has ended</h3>
								</div>
								<table></table>
								<div className="logout-button" onClick={this.handleLogout.bind(this)}>
									<FormattedMessage id="header.log-out" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const trialEndQuery = graphql`
	query trialEnd_Query {
		viewer {
			actualPersonId
			component(name: "trial_end")
			...trialEnd_viewer
		}
	}
`;

export {trialEndQuery};

export default createFragmentContainer(injectIntl(withRouter(trialEnd)), {
	viewer: graphql`
		fragment trialEnd_viewer on Viewer {
			id
			actualPersonId
			company {
				id
				tier
			}
		}
	`,
});
