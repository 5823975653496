import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import LogOutMutation from '../mutations/logout_mutation';
import Util from '../forecast-app/shared/util/util';

class XeroSignupContainer extends Component {
	render() {
		const xeroUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.XERO_INTEGRATION_APP_CLIENT_ID}&redirect_uri=${process.env.API_URL}/xero/oauth/signup&scope=openid email offline_access accounting.transactions accounting.contacts accounting.settings`;

		const onSuccess = () => (window.location.href = xeroUrl);

		// log user out
		if (this.props.viewer.actualPersonId) Util.CommitMutation(LogOutMutation, {}, onSuccess);
		else {
			window.location.href = xeroUrl;
		}

		return <div></div>;
	}
}

const XeroSignupContainerQuery = graphql`
	query XeroSignupContainer_Query {
		viewer {
			id
			component(name: "signup_xero")
			...XeroSignupContainer_viewer
		}
	}
`;

export {XeroSignupContainerQuery};

export default createFragmentContainer(XeroSignupContainer, {
	viewer: graphql`
		fragment XeroSignupContainer_viewer on Viewer {
			id
			actualPersonId
		}
	`,
});
