import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from '../generic_modal';
import {APPROVAL_TYPE, BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../../constants';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import UpdateApprovalStatusMutation from '../../../mutations/UpdateApprovalStatusMutation';
import {ModalText, ModalWrapper} from '../modal_styled';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const UnsubmitTimeApprovalModal = ({
	selectedPersonId,
	selectedPersonName,
	currentViewingDate,
	lockedDate,
	companyLockedDate,
	closeModal,
	intl: {formatMessage},
}) => {
	const startDateLimit = companyLockedDate && companyLockedDate.clone().add(1, 'd');
	const currentDateNoLock =
		companyLockedDate && companyLockedDate.isSameOrAfter(currentViewingDate, 'day') ? startDateLimit : currentViewingDate;
	const endDateLimit = lockedDate;

	const [selectedDate, setSelectedDate] = useState(currentDateNoLock);

	const handleConfirmButton = () => {
		trackEvent('Unsubmit Timesheet Modal Unsubmit Button', 'Clicked');
		const onSuccess = res => {
			const personRes = res.updateApprovalStatus.persons[0];
			if (personRes) {
				const newPersonLockDate = Util.CreateNonUtcMomentDate(
					personRes.submitLockedDateYear,
					personRes.submitLockedDateMonth,
					personRes.submitLockedDateDay
				);
				createToast({
					duration: 2500,
					message: `Timesheets unlocked from ${newPersonLockDate.clone().add(1, 'days').format('LL')}`,
				});
			}
			closeModal();
		};

		// Mutation
		Util.CommitMutation(
			UpdateApprovalStatusMutation,
			{
				personIds: [selectedPersonId],
				approvalType: APPROVAL_TYPE.UNSUBMIT,
				startYear: selectedDate.year(),
				startMonth: selectedDate.month() + 1,
				startDay: selectedDate.date(),
				includeTimeOff: hasFeatureFlag('pto_timesheet_allocation_linking'),
			},
			onSuccess
		);
	};

	const cancelButton = () => {
		trackEvent('Unsubmit Timesheet Modal Cancel Button', 'Clicked');
	};

	const content = (
		<ModalWrapper medium>
			<ModalText margin={'24px'}>{formatMessage({id: 'time_approval.unsubmit_timesheets_info'})}</ModalText>

			<ModalText margin={'8px'}>{formatMessage({id: 'time_approval.unlock_from'})}</ModalText>
			<DatePicker
				startDate={currentDateNoLock}
				handleDateRangeChange={setSelectedDate}
				datePickerStyle={DATE_PICKER_STYLE.SLIM}
				isSingleDatePicker={true}
				startDateLimite={startDateLimit}
				endDateLimite={endDateLimit}
			/>
		</ModalWrapper>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: cancelButton.bind(this),
				},
				{
					text: formatMessage({id: 'time_approval.unsubmit_timesheets'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: handleConfirmButton.bind(this),
					disabled: !selectedDate,
					cy: 'unsubmit-time-save-button',
				},
			]}
			headerText={
				selectedPersonName
					? formatMessage({id: 'time_approval.unsubmit_names_timesheets'}, {name: selectedPersonName})
					: formatMessage({id: 'time_approval.unsubmit_timesheets'})
			}
			content={content}
		/>
	);
};

UnsubmitTimeApprovalModal.propTypes = {
	currentViewingDate: PropTypes.object,
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(UnsubmitTimeApprovalModal);
