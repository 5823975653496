import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import UpdateSkillMutation from '../../../mutations/update_skill_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {ModalHeaderInput} from 'web-components';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../forecast-app/shared/util/util';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import PeoplePreviewSelector from '../../../forecast-app/shared/components/dropdowns/preview-selector/PersonsPreviewSelector';
import SkillBadge from '../../../forecast-app/shared/components/skills/skill_badge';

const UpdateSkillModal = ({skillId, company, closeModal, skillLevel}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const skillLevelsEnabled = !!company.skillLevelsEnabled;
	const NO_CATEGORY = formatMessage({id: 'settings_skills.no_category'});
	const skills = company.skills.edges.map(skill => skill.node);
	const existingNames = new Set(skills.filter(skill => skill.id !== skillId).map(skill => skill.name));
	const skill = skills.find(skill => skill.id === skillId);
	if (!skill) {
		return null;
	}

	const [name, setName] = useState(skill.name);
	const [selectedCategory, setSelectedCategory] = useState(skill.category?.id);
	const [selectedPersons, setSelectedPersons] = useState(
		skill.skillPersons.map(sp => ({
			personId: sp.person.id,
			skillId: skill.id,
			skillLevelId: sp.level?.id,
		}))
	);

	const [errorMessage, setErrorMessage] = useState();
	const [nameValid, setNameValid] = useState(true);

	const persons = company.allPersons.edges.map(edge => edge.node);
	const categories = company.skillCategories.edges.map(skill => skill.node);
	const categoryOptions = categories.map(skill => ({value: skill.id, label: skill.name}));
	categoryOptions.push({value: null, label: NO_CATEGORY});

	const create = () => {
		Util.CommitMutation(
			UpdateSkillMutation,
			{
				name,
				id: skillId,
				categoryId: selectedCategory,
				skillPersons: selectedPersons,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.changes_saved'}),
				});
			}
		);
	};

	const onNameChange = newName => {
		setName(newName);
		const duplicateName = existingNames.has(newName) || newName === formatMessage({id: 'settings_skills.no_category'});
		if (duplicateName) {
			setNameValid(false);
			setErrorMessage(formatMessage({id: 'settings_skills.skill_already_exists'}));
		} else {
			setNameValid(!!newName);
			setErrorMessage(null);
		}
	};

	const onSelectCategory = option => {
		setSelectedCategory(option.value);
	};

	const renderSkillBadge = person => {
		const {skillLevels} = company;

		const skillLevelId = selectedPersons.find(sp => sp.personId === person.id)?.skillLevelId;
		const level = skillLevels.find(skillLevel => skillLevel.id === skillLevelId)?.level;

		return skillLevelsEnabled && <SkillBadge skill={skill} unknownLevel={!level} skillLevel={level || '?'} />;
	};

	const onSkillLevelChange = (personId, skillLevelId) => {
		setSelectedPersons(
			selectedPersons.map(sp => (sp.personId === personId ? {personId, skillId: skill.id, skillLevelId} : sp))
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.MEDIUM}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: create,
					disabled: !nameValid || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<div>
					<ModalHeaderInput
						cy="skill-name"
						placeholder={formatMessage({id: 'common.name_placeholder'})}
						value={name}
						onChange={onNameChange}
						hasError={!nameValid}
						errorMessage={errorMessage}
					/>
					<ModalBody>
						<Field>
							<DropdownV2
								options={categoryOptions}
								onChange={onSelectCategory}
								value={selectedCategory}
								label={formatMessage({id: 'settings_skills.category'})}
								placeholder={NO_CATEGORY}
								inputName={'category-input'}
								inputCy={'category'}
								listDataCy={'category'}
							/>
						</Field>
						<Field>
							<PeoplePreviewSelector
								persons={persons}
								selectedPersons={selectedPersons}
								onChange={setSelectedPersons}
								selectionHeaderLabel={formatMessage({id: 'common.people'})}
								renderTag={renderSkillBadge}
								skillLevelsEnabled={skillLevelsEnabled}
								skillLevels={company.skillLevels}
								onSkillLevelChange={onSkillLevelChange}
								skill={skill}
								selectedSkillLevel={skillLevel}
								required
							/>
						</Field>
					</ModalBody>
				</div>
			}
		/>
	);
};

const updateSkillModalQuery = graphql`
	query UpdateSkillModal_Query {
		viewer {
			actualPersonId
			component(name: "create_skill_category_modal")
			company {
				...UpdateSkillModal_company
			}
		}
	}
`;

export {updateSkillModalQuery};

export default createFragmentContainer(UpdateSkillModal, {
	company: graphql`
		fragment UpdateSkillModal_company on Company {
			id
			skillLevelsEnabled
			skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
			skills(first: 10000) @connection(key: "Company_skills") {
				edges {
					node {
						id
						name
						category {
							id
							name
						}
						skillPersons {
							person {
								id
							}
							level {
								id
								level
							}
						}
					}
				}
			}
			allPersons(first: 10000, excludeClientUsers: true) {
				edges {
					node {
						...PersonsPreviewSelector_persons
					}
				}
			}
			skillLevels {
				id
				level
				description
			}
		}
	`,
});
