/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "companyId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "analysisId",
                "type": "Int"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "SageSyncAnalysisTable_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlDataType",
                "kind": "LinkedField",
                "name": "missionControlData",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "analysisId",
                                "variableName": "analysisId"
                            },
                            {
                                "kind": "Variable",
                                "name": "companyId",
                                "variableName": "companyId"
                            }
                        ],
                        "concreteType": "MissionControlSageSyncAnalysisType",
                        "kind": "LinkedField",
                        "name": "companySyncAnalysis",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "syncDate",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "MissionControlSageSyncAnalysisEntryType",
                                "kind": "LinkedField",
                                "name": "entries",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "companyAnalysisId",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "forecastCompanyId",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "sageEntityRecordNumber",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "forecastEntityId",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "entityType",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "relevancy",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '40474f0ff04dca99afa69bdb9002e3bd';
export default node;
