import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter, Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {hasPermission} from '../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../Permissions';

class accessBlocked extends Component {
	componentDidMount() {
		if (!this.props.viewer.company.accessBlocked) {
			this.props.history.push({
				pathname: '/',
			});
		}
	}

	getSupportEmail() {
		return 'support@forecast.app';
	}

	getContent() {
		const supportEmail = this.getSupportEmail();
		const adminOrFullAccess = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
		const supportHref = (
			<a className="mailto" href={`mailto:${supportEmail}`}>
				{adminOrFullAccess ? <FormattedMessage id="access_blocked.admin_support_info_2" /> : supportEmail}
			</a>
		);
		return (
			<div>
				{adminOrFullAccess ? (
					<h2>
						<FormattedMessage id="access_blocked.title" />
					</h2>
				) : null}
				<div>
					<FormattedMessage id="access_blocked.info_title" />, <br /> <br />
					{adminOrFullAccess ? (
						<div>
							<FormattedMessage id="access_blocked.info_admin_1" />
							<Link to="/settings/subscription">
								<FormattedMessage id="access_blocked.info_admin_2" />
							</Link>
						</div>
					) : (
						<div>
							<FormattedMessage id="access_blocked.info_team_member" />
							{supportHref}
						</div>
					)}
				</div>
				<br />
				{adminOrFullAccess ? (
					<div>
						<FormattedMessage id="access_blocked.admin_support_info_1" />
						{supportHref}
						<FormattedMessage id="access_blocked.admin_support_info_3" />
					</div>
				) : null}
				<br />
				<br />
				<div>- The Forecast Team</div>
			</div>
		);
	}

	render() {
		return (
			<div className="fullscreen fullscreen-centered-outer access-blocked">
				<div className="fullscreen-centered-inner">
					<div className="fullscreen-centered-content">
						<div>{this.getContent()}</div>
					</div>
				</div>
			</div>
		);
	}
}

const accessBlockedQuery = graphql`
	query accessBlocked_Query {
		viewer {
			actualPersonId
			component(name: "access_blocked")
			...accessBlocked_viewer
		}
	}
`;

export {accessBlockedQuery};

export default createFragmentContainer(withRouter(accessBlocked), {
	viewer: graphql`
		fragment accessBlocked_viewer on Viewer {
			id
			company {
				id
				accessBlocked
			}
		}
	`,
});
