import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {personIsVirtualUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {DeactivatedUsers} from './SkillRow.styled';
import Styled from 'styled-components/macro';
import SkillAssignees from './SkillAssignees';

const NoLevelStyle = Styled.span`
      color: #8E8F9F;
`;

const SkillLevelRow = ({tableColumnsProps, skillLevel, cy, persons, onClick}) => {
	const {formatMessage} = useIntl();

	return (
		<Table.Row hoverStyle={{visibility: 'visible'}} cy={cy} {...tableColumnsProps} onClick={onClick} isLastRow>
			<Table.Column>
				<Table.Column.Cell>
					{!skillLevel ? (
						<NoLevelStyle>? - {formatMessage({id: 'settings_skills.levels.no_level'})}</NoLevelStyle>
					) : (
						<span>
							{skillLevel.level} - {skillLevel.description}
						</span>
					)}
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					<SkillAssignees assignees={persons?.filter(person => person.active && !personIsVirtualUser(person))} />
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<Table.Column.Cell>
					<SkillAssignees assignees={persons?.filter(person => person.active && personIsVirtualUser(person))} />
				</Table.Column.Cell>
			</Table.Column>
			<Table.Column>
				<DeactivatedUsers>
					<SkillAssignees assignees={persons?.filter(person => !person.active)} />
				</DeactivatedUsers>
			</Table.Column>
			<Table.Column />
		</Table.Row>
	);
};

export default createFragmentContainer(SkillLevelRow, {
	skillLevel: graphql`
		fragment SkillLevelRow_skillLevel on SkillLevel {
			id
			level
			description
		}
	`,
});
