/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SettingsCalendarWrapper_viewer$ref: FragmentReference;
declare export opaque type SettingsCalendarWrapper_viewer$fragmentType: SettingsCalendarWrapper_viewer$ref;
export type SettingsCalendarWrapper_viewer = {|
  +id: string,
  +calendarIntegrations: ?{|
    +id: string,
    +googleCalendar: ?{|
      +id: string,
      +calendarTokenError: ?string,
      +calendarViewPrivateEvents: ?boolean,
      +calendarUser: ?{|
        +name: ?string,
        +email: ?string,
        +picture: ?string,
      |},
      +enabledCalendars: ?$ReadOnlyArray<?string>,
      +calendars: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +type: ?string,
            +description: ?string,
            +backgroundColor: ?string,
            +foregroundColor: ?string,
          |}
        |}>
      |},
    |},
    +outlookCalendar: ?{|
      +id: string,
      +calendarTokenError: ?string,
      +calendarViewPrivateEvents: ?boolean,
      +calendarUser: ?{|
        +name: ?string,
        +email: ?string,
        +picture: ?string,
      |},
      +enabledCalendars: ?$ReadOnlyArray<?string>,
      +calendars: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +type: ?string,
            +description: ?string,
            +backgroundColor: ?string,
            +foregroundColor: ?string,
          |}
        |}>
      |},
    |},
  |},
  +$refType: SettingsCalendarWrapper_viewer$ref,
|};
export type SettingsCalendarWrapper_viewer$data = SettingsCalendarWrapper_viewer;
export type SettingsCalendarWrapper_viewer$key = {
  +$data?: SettingsCalendarWrapper_viewer$data,
  +$fragmentRefs: SettingsCalendarWrapper_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "calendarTokenError",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "calendarViewPrivateEvents",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CalendarUser",
    "kind": "LinkedField",
    "name": "calendarUser",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "picture",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabledCalendars",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CalendarTypeConnection",
    "kind": "LinkedField",
    "name": "calendars",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CalendarTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Calendar",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "foregroundColor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsCalendarWrapper_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CalendarIntegrations",
      "kind": "LinkedField",
      "name": "calendarIntegrations",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CalendarIntegration",
          "kind": "LinkedField",
          "name": "googleCalendar",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CalendarIntegration",
          "kind": "LinkedField",
          "name": "outlookCalendar",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5bc0a9f219c4d70bcd95a0ef8b1843b';

module.exports = node;
