import React, {Component} from 'react';

class NotFoundModal extends Component {
	closeModal() {
		this.props.closeModal();
	}

	render() {
		return (
			<div className="not-found-modal">
				<div className="content">
					<div className="header">
						<h2>{this.props.title}</h2>
						<button className="close-button" onClick={this.closeModal.bind(this)} />
					</div>
					<span className="info-msg">{this.props.message}</span>
				</div>
			</div>
		);
	}
}

export default NotFoundModal;
