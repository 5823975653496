import React, { useMemo, useState } from 'react';
import { BetaTag, Checkbox, FlexRow, Link, SubHeading, Text } from '@forecast-it/design-system';
import Util from '../../../forecast-app/shared/util/util';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import RevokeGoogleCalendarTokenMutation from '../../../mutations/revoke_google_calendar_token_mutation';
import Styled from 'styled-components';
import GoogleCalendarOAuth from './GoogleCalendarOAuth';
import { LOCALSTORAGE_TIMESHEETS_SELECTED_TAB } from '../../../forecast-app/my-work-tab/my-timesheets-page/top-section/TimesheetsCalendarUtil';
import UpdateGoogleCalendarMutation from '../../../mutations/ts/integrations/calendars/UpdateGoogleCalendarMutation';
import UpdateOutlookCalendarMutation from '../../../mutations/ts/integrations/calendars/UpdateOutlookCalendarMutation';
import DeleteOutlookCalendarTokensMutation from '../../../mutations/ts/integrations/calendars/DeleteOutlookCalendarTokensMutation';
import OutlookCalendarOAuth from './OutlookCalendarOAuth';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { useIntl } from 'react-intl';
const CalendarSection = Styled.div `
	padding-top: 8px;
`;
const SubTitle = Styled.div `
	padding-right: 8px;
`;
const SubTitleSection = Styled.div `
	padding-top: 8px;
	display: flex;
	align-items: center;
`;
const Description = Styled.div `
	padding-top: 8px;
	padding-bottom: 16px;
	width: 35rem;
`;
const CheckboxContainer = Styled.div `
	display: flex;
	flex-direction: row;
	padding-top: 16px;
`;
const PrivacySettingsTitle = Styled.div `
	width 150px;
`;
const SettingsCheckbox = Styled.div `
	display: flex;
	flex-direction: column;
	> div {
		padding-bottom: 8px;
	}
`;
const ProfileContainer = Styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	> div {
		padding-right: 8px;
	}
`;
const SettingsCalendar = ({ viewer, personId, type }) => {
    var _a, _b, _c, _d, _e;
    const { formatMessage } = useIntl();
    const calendarSettings = useMemo(() => {
        return type === 'google' ? viewer.calendarIntegrations.googleCalendar : viewer.calendarIntegrations.outlookCalendar;
    }, [viewer.calendarIntegrations]);
    const [calendars, setCalendars] = useState((_a = calendarSettings.calendars) === null || _a === void 0 ? void 0 : _a.edges.map(edge => edge.node));
    const [enabledCalendars, setEnabledCalendars] = useState(calendarSettings.enabledCalendars);
    if ((enabledCalendars === null || enabledCalendars === void 0 ? void 0 : enabledCalendars.length) !== ((_b = calendarSettings.enabledCalendars) === null || _b === void 0 ? void 0 : _b.length)) {
        setEnabledCalendars(calendarSettings.enabledCalendars);
    }
    if ((calendars === null || calendars === void 0 ? void 0 : calendars.length) !== ((_d = (_c = calendarSettings.calendars) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.length)) {
        setCalendars((_e = calendarSettings.calendars) === null || _e === void 0 ? void 0 : _e.edges.map(edge => edge.node));
    }
    const isCalendarEnabled = (id) => {
        return enabledCalendars === null || enabledCalendars === void 0 ? void 0 : enabledCalendars.includes(id);
    };
    const updateEnabledCalendars = (calendarIds) => {
        trackEvent(`${type.charAt(0).toUpperCase() + type.slice(1)} Calendar Enabled Calendars`, 'Toggled');
        if (type === 'google') {
            Util.CommitMutation(UpdateGoogleCalendarMutation, {
                id: calendarSettings.id,
                enabledCalendarIds: calendarIds,
            });
        }
        else {
            Util.CommitMutation(UpdateOutlookCalendarMutation, {
                id: calendarSettings.id,
                enabledCalendarIds: calendarIds,
            });
        }
    };
    const toggleCalendar = (id) => {
        let checkedCalendars;
        if (isCalendarEnabled(id)) {
            checkedCalendars = enabledCalendars.filter(calendarId => calendarId !== id);
        }
        else {
            checkedCalendars = [id, ...enabledCalendars];
        }
        setEnabledCalendars(checkedCalendars);
        updateEnabledCalendars(checkedCalendars);
    };
    const toggleViewPrivate = () => {
        if (type === 'google') {
            trackEvent('Google Calendar View Private', 'Toggled', { viewPrivate: !calendarSettings.calendarViewPrivateEvents });
            Util.CommitMutation(UpdateGoogleCalendarMutation, {
                id: calendarSettings.id,
                viewPrivateEvents: !calendarSettings.calendarViewPrivateEvents,
            });
        }
        else {
            trackEvent('Outlook Calendar View Private', 'Toggled', { viewPrivate: !calendarSettings.calendarViewPrivateEvents });
            Util.CommitMutation(UpdateOutlookCalendarMutation, {
                id: calendarSettings.id,
                viewPrivateEvents: !calendarSettings.calendarViewPrivateEvents,
            });
        }
    };
    const revokeTokens = () => {
        const callbackPositive = () => {
            if (type === 'google') {
                trackEvent('Google Calendar Unlink Account', 'Clicked');
                localStorage.removeItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB);
                Util.CommitMutation(RevokeGoogleCalendarTokenMutation, {
                    personId: personId,
                }, () => {
                    setCalendars([]);
                    setEnabledCalendars([]);
                });
            }
            else if (type === 'outlook') {
                trackEvent('Outlook Calendar Unlink Account', 'Clicked');
                localStorage.removeItem(LOCALSTORAGE_TIMESHEETS_SELECTED_TAB);
                Util.CommitMutation(DeleteOutlookCalendarTokensMutation, {
                    id: calendarSettings.id,
                }, () => {
                    setCalendars([]);
                    setEnabledCalendars([]);
                });
            }
        };
        showModal({
            type: MODAL_TYPE.CONFIRMATION,
            title: formatMessage({ id: 'calendar_integration.confirm_unlink_account' }),
            description: formatMessage({ id: 'calendar_integration.confirm_unlink_account_description' }),
            callbackPositive,
        }, true);
    };
    return (React.createElement(CalendarSection, null,
        React.createElement(SubTitleSection, null,
            React.createElement(SubTitle, null,
                React.createElement(SubHeading, { size: '1' }, `View your ${type.charAt(0).toUpperCase() + type.slice(1)} Calendar events inside Forecast`)),
            React.createElement(BetaTag, null)),
        React.createElement(Description, null,
            React.createElement(Text, { size: '2' },
                "The integration fetches your ",
                type.charAt(0).toUpperCase() + type.slice(1),
                " calendar events into Forecast, making it easy to reference what you worked on when you need to log time. That means no more jumping back and forth between your ",
                type.charAt(0).toUpperCase() + type.slice(1),
                " calendar and Forecast.")),
        React.createElement(FlexRow, null,
            calendarSettings.calendarUser ? (React.createElement(ProfileContainer, null,
                React.createElement("div", null,
                    "Linked ",
                    type.charAt(0).toUpperCase() + type.slice(1),
                    " Account:"),
                calendarSettings.calendarUser.picture ? (React.createElement("div", null,
                    React.createElement("img", { crossOrigin: "anonymous", src: calendarSettings.calendarUser.picture, width: "32", height: "32", alt: `${type} profile` }))) : null,
                React.createElement("div", null, calendarSettings.calendarUser.name),
                React.createElement(Link, { onClick: revokeTokens }, "Unlink Account"))) : null,
            !calendarSettings.calendarUser || calendarSettings.calendarTokenError ? (type === 'google' ? (React.createElement(GoogleCalendarOAuth, { calendarTokenError: calendarSettings.calendarTokenError, personId: personId })) : (React.createElement(OutlookCalendarOAuth, { calendarTokenError: calendarSettings.calendarTokenError, page: "my-profile" }))) : null),
        (calendars === null || calendars === void 0 ? void 0 : calendars.length) ? (React.createElement(React.Fragment, null,
            React.createElement(CheckboxContainer, null,
                React.createElement(PrivacySettingsTitle, null, "Shown Calendars:"),
                React.createElement(SettingsCheckbox, null, calendars.map(calendar => {
                    return (React.createElement(Checkbox, { checked: isCalendarEnabled(calendar.id), onChange: () => toggleCalendar(calendar.id), labelText: calendar.name, notBoldLabel: true, bgColor: calendar.backgroundColor, fgColor: calendar.foregroundColor }));
                }))),
            React.createElement(CheckboxContainer, null,
                React.createElement(PrivacySettingsTitle, null, "Privacy settings:"),
                React.createElement(SettingsCheckbox, null,
                    React.createElement(Checkbox, { checked: calendarSettings.calendarViewPrivateEvents, onChange: toggleViewPrivate, labelText: `View ${type.charAt(0).toUpperCase() + type.slice(1)} Calendar events that are marked as private`, notBoldLabel: true }))))) : null));
};
export default SettingsCalendar;
