import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import InputFieldV2 from '../../../components/new-ui/input_field';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import CreateSkillMutation from '../../../mutations/create_skill_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import PeoplePreviewSelector from '../../../forecast-app/shared/components/dropdowns/preview-selector/PersonsPreviewSelector';

const CreateSkillModal = ({company, closeModal}) => {
	const {formatMessage} = useIntl();
	const skillLevelsEnabled = !!company.skillLevelsEnabled;
	const NO_CATEGORY = formatMessage({id: 'settings_skills.no_category'});
	const [name, setName] = useState('');
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedPersons, setSelectedPersons] = useState([]);
	const [errorMessage, setErrorMessage] = useState();
	const skills = company.skills.edges.map(skill => skill.node);
	const existingSkillNames = new Set(skills.map(skills => skills.name));
	const persons = company.allPersons.edges.map(edge => edge.node);
	const categories = company.skillCategories.edges.map(skillCategory => skillCategory.node);
	const categoryOptions = categories.map(skill => ({value: skill.id, label: skill.name}));
	categoryOptions.push({value: null, label: NO_CATEGORY});

	const createSkill = () => {
		Util.CommitMutation(
			CreateSkillMutation,
			{
				name,
				categoryId: selectedCategory,
				skillPersons: selectedPersons,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.skill_created_toast'}),
				});
			}
		);
	};

	const onNameChange = newName => {
		setName(newName);
		const duplicateName = existingSkillNames.has(newName);
		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings_skills.skill_already_exists'}));
		} else {
			setErrorMessage(null);
		}
	};

	const onSelectCategory = option => {
		setSelectedCategory(option.value);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'common.create_skill'})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.create'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: createSkill,
					disabled: !name || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<InputFieldV2
							value={name}
							cy={'skill-name'}
							id="skillName"
							label={formatMessage({id: 'common.name'})}
							placeholder={formatMessage({id: 'common.name_placeholder'})}
							type="text"
							onChange={onNameChange}
							errorMessage={errorMessage}
							invalidInput={!!errorMessage}
							required={true}
							maxLength={191}
						/>
					</Field>
					<Field>
						<DropdownV2
							options={categoryOptions}
							onChange={onSelectCategory}
							value={selectedCategory}
							label={`${formatMessage({id: 'settings_skills.category'})} ${formatMessage({
								id: 'common.field_optional_label',
							})}`}
							placeholder={NO_CATEGORY}
							inputName={'category-input'}
							inputCy={'category'}
							listDataCy={'category'}
						/>
					</Field>
					{!skillLevelsEnabled && (
						<Field>
							<PeoplePreviewSelector
								persons={persons}
								selectedPersons={selectedPersons}
								onChange={setSelectedPersons}
								hideSelectionHeaderActions
							/>
						</Field>
					)}
				</ModalBody>
			}
		/>
	);
};

const createSkillModalQuery = graphql`
	query CreateSkillModal_Query {
		viewer {
			actualPersonId
			component(name: "create_skill_modal")
			company {
				...CreateSkillModal_company
			}
		}
	}
`;

export {createSkillModalQuery};

export default createFragmentContainer(CreateSkillModal, {
	company: graphql`
		fragment CreateSkillModal_company on Company {
			id
			skillLevelsEnabled
			skills(first: 10000) @connection(key: "Company_skills") {
				edges {
					node {
						id
						name
					}
				}
			}
			skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
			allPersons(first: 10000, excludeClientUsers: true) {
				edges {
					node {
						...PersonsPreviewSelector_persons
					}
				}
			}
		}
	`,
});
