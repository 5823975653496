/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type companyStats_viewer$ref = any;
export type companyStats_QueryVariables = {||};
export type companyStats_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: companyStats_viewer$ref,
  |}
|};
export type companyStats_Query = {|
  variables: companyStats_QueryVariables,
  response: companyStats_QueryResponse,
|};
*/


/*
query companyStats_Query {
  viewer {
    actualPersonId
    component(name: "control_company_stats")
    ...companyStats_viewer
    id
  }
}

fragment companyStats_viewer on Viewer {
  id
  ...missionControlHeader_viewer
  availableFeatureFlags {
    key
    id
  }
  actualPersonId
  missionControlData {
    id
    companyStats {
      id
      companyId
      companyName
      tier
      trialEndDate
      numOfProjects
      numOfCards
      numActivePersons
      numTimeRegs
      minimumSeats
      minimumVirtualSeats
      accessBlocked
      userSeats
      virtualSeats
      collaboratorSeats
      numOfClientUsers
      numVirtualUsers
      numCollaboratorUsers
      datacenter
      betaOptIn
      isUsingProjectAllocation
      isUsingSchedulingPlanMode
      isUsingMixedAllocation
      isSageCompany
    }
  }
}

fragment missionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "control_company_stats"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"control_company_stats\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "companyStats_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "companyStats_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "companyStats_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlCompanyStatsType",
                "kind": "LinkedField",
                "name": "companyStats",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numOfProjects",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numOfCards",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numActivePersons",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numTimeRegs",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumVirtualSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessBlocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "virtualSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collaboratorSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numOfClientUsers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numVirtualUsers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numCollaboratorUsers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datacenter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "betaOptIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUsingProjectAllocation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUsingSchedulingPlanMode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUsingMixedAllocation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSageCompany",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "companyStats_Query",
    "operationKind": "query",
    "text": "query companyStats_Query {\n  viewer {\n    actualPersonId\n    component(name: \"control_company_stats\")\n    ...companyStats_viewer\n    id\n  }\n}\n\nfragment companyStats_viewer on Viewer {\n  id\n  ...missionControlHeader_viewer\n  availableFeatureFlags {\n    key\n    id\n  }\n  actualPersonId\n  missionControlData {\n    id\n    companyStats {\n      id\n      companyId\n      companyName\n      tier\n      trialEndDate\n      numOfProjects\n      numOfCards\n      numActivePersons\n      numTimeRegs\n      minimumSeats\n      minimumVirtualSeats\n      accessBlocked\n      userSeats\n      virtualSeats\n      collaboratorSeats\n      numOfClientUsers\n      numVirtualUsers\n      numCollaboratorUsers\n      datacenter\n      betaOptIn\n      isUsingProjectAllocation\n      isUsingSchedulingPlanMode\n      isUsingMixedAllocation\n      isSageCompany\n    }\n  }\n}\n\nfragment missionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24c5d87c1aed4c64b29d75842c8f78f8';

module.exports = node;
