import React, {useState} from 'react';
import {BasicTextInput, ColorPicker} from 'web-components';
import DateRangePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {DATE_PICKER_STYLE} from '../../../constants';
import {
	InputLineWrapper,
	SubSection,
	InputLineLabel,
	InputLineInput,
	DatesWrapper,
	InformationWrapper,
} from './ProjectSettings_styled';
import {changeProjectGroupName, changeProjectGroupColor} from './ProjectSettingsMutations';
import Util from '../../../forecast-app/shared/util/util';

const ProjectGroupSettingsGeneral = ({
	intl,
	projectGroupId,
	projecGrouptName,
	projectGroupColor,
	childProjects,
	isOldCompany,
}) => {
	const [_projectGroupName, setProjectGroupName] = useState(projecGrouptName);

	const {formatMessage} = intl;

	const handleChangeName = name => {
		if (!/\S/.test(name)) {
			setProjectGroupName(projecGrouptName);
			return;
		}
		changeProjectGroupName(projectGroupId, name);
	};

	const getStartDate = () => {
		let startDate = null;

		childProjects.forEach(project => {
			let start = null;
			if (project.node.projectStartYear) {
				start = Util.CreateNonUtcMomentDate(
					project.node.projectStartYear,
					project.node.projectStartMonth,
					project.node.projectStartDay
				);
			}
			if ((!startDate && start) || (start && startDate && start.isBefore(startDate))) {
				startDate = start;
			}
		});

		return startDate;
	};

	const getEndDate = () => {
		let endDate = null;

		childProjects.forEach(project => {
			let end = null;
			if (project.node.projectEndYear) {
				end = Util.CreateNonUtcMomentDate(
					project.node.projectEndYear,
					project.node.projectEndMonth,
					project.node.projectEndDay
				);
			}

			if ((!endDate && end) || (end && endDate && end.isAfter(endDate))) {
				endDate = end;
			}
		});

		return endDate;
	};

	return (
		<SubSection title={formatMessage({id: 'project_settings.project_details'})}>
			<InputLineWrapper>
				<InputLineLabel>{formatMessage({id: 'common.project-name'})}</InputLineLabel>
				<InputLineInput>
					<BasicTextInput
						value={_projectGroupName}
						onChange={setProjectGroupName}
						callback={handleChangeName} /* disabled={projectDoneOrHalted} */
					/>
				</InputLineInput>
			</InputLineWrapper>
			<InputLineWrapper>
				<InputLineLabel>{formatMessage({id: 'project_overview.project_dates'})}</InputLineLabel>
				<InputLineInput>
					<DatesWrapper>
						<DateRangePicker
							isSingleDatePicker={true}
							startDate={getStartDate()}
							isNewDateRange={true}
							datePickerStyle={DATE_PICKER_STYLE.SLIM}
							disabled={true}
						/>
						<DateRangePicker
							isSingleDatePicker={true}
							startDate={getEndDate()}
							isNewDateRange={true}
							datePickerStyle={DATE_PICKER_STYLE.SLIM}
							disabled={true}
						/>
					</DatesWrapper>
					<InformationWrapper>{formatMessage({id: 'project_settings.project_group_dates_info'})}</InformationWrapper>
				</InputLineInput>
			</InputLineWrapper>

			<InputLineWrapper>
				<InputLineLabel>{formatMessage({id: 'project_settings.project-color'})}</InputLineLabel>
				<InputLineInput>
					<ColorPicker
						chooseColor={color => changeProjectGroupColor(projectGroupId, color)}
						selectedColor={projectGroupColor}
						showHexPicker={true}
					></ColorPicker>
				</InputLineInput>
			</InputLineWrapper>
		</SubSection>
	);
};

export default ProjectGroupSettingsGeneral;
