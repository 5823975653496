/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PredictedEndDateModal_viewer$ref = any;
export type PredictedEndDateModal_QueryVariables = {|
  projectId?: ?string
|};
export type PredictedEndDateModal_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: PredictedEndDateModal_viewer$ref,
  |}
|};
export type PredictedEndDateModal_Query = {|
  variables: PredictedEndDateModal_QueryVariables,
  response: PredictedEndDateModal_QueryResponse,
|};
*/


/*
query PredictedEndDateModal_Query(
  $projectId: ID
) {
  viewer {
    component(name: "predicted_end_date_modal")
    actualPersonId
    id
    ...PredictedEndDateModal_viewer_2CgS8h
  }
}

fragment PredictedEndDateModal_viewer_2CgS8h on Viewer {
  project(internalId: $projectId) {
    projectStats {
      id
      date
      totalEstimateMinutes
      totalBaselineEstimateMinutes
      totalDoneEstimateMinutes
      performance
      optimisticPerformance
      conservativePerformance
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "predicted_end_date_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"predicted_end_date_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PredictedEndDateModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PredictedEndDateModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PredictedEndDateModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStats",
                "kind": "LinkedField",
                "name": "projectStats",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalEstimateMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalBaselineEstimateMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalDoneEstimateMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optimisticPerformance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "conservativePerformance",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PredictedEndDateModal_Query",
    "operationKind": "query",
    "text": "query PredictedEndDateModal_Query(\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"predicted_end_date_modal\")\n    actualPersonId\n    id\n    ...PredictedEndDateModal_viewer_2CgS8h\n  }\n}\n\nfragment PredictedEndDateModal_viewer_2CgS8h on Viewer {\n  project(internalId: $projectId) {\n    projectStats {\n      id\n      date\n      totalEstimateMinutes\n      totalBaselineEstimateMinutes\n      totalDoneEstimateMinutes\n      performance\n      optimisticPerformance\n      conservativePerformance\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49f82dab06e2ccb425b7d652c816c129';

module.exports = node;
