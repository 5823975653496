import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LogRocket from 'logrocket';
import { FlexContainer, Heading, TextField, Text } from '@forecast-it/design-system';
import Logo, { LOGO_SIZE } from '../../forecast-app/shared/components/logo/Logo';
import Util from '../../forecast-app/shared/util/util';
import SetFirstTimePasswordMutation from '../../mutations/set_first_time_password_mutation';
import VerifyFirstTimeKeyMutation from '../../mutations/verify_first_time_key_mutation';
import { Content, Page, PageContent, WideButton } from './Sage.styled';
function verifyFirstTimeKey(key, setFullName, setJobTitle, setIsFirstPerson, setIsKeyValid, setEmail, onSuccess = () => void 0) {
    Util.CommitMutation(VerifyFirstTimeKeyMutation, {
        key: key,
    }, e => {
        if (e.verifyFirstTimeKey.isKeyValid) {
            try {
                if (process.env.CIRCLE_BRANCH === 'production') {
                    LogRocket.init('forecast/forecast-nod93');
                }
            }
            catch (e) {
                // Ignore
            }
            setIsFirstPerson(e.verifyFirstTimeKey.isFirstPersonInCompany);
            setEmail(e.verifyFirstTimeKey.person.email);
            // Set values if we have them already
            if (e.verifyFirstTimeKey.person.firstName !== '') {
                let firstName = '';
                if (e.verifyFirstTimeKey.person.firstName &&
                    e.verifyFirstTimeKey.person.firstName !== e.verifyFirstTimeKey.person.email) {
                    firstName = e.verifyFirstTimeKey.person.firstName;
                }
                const lastName = e.verifyFirstTimeKey.person.lastName ? e.verifyFirstTimeKey.person.lastName : '';
                const title = e.verifyFirstTimeKey.person.jobTitle ? e.verifyFirstTimeKey.person.jobTitle : '';
                setFullName(firstName + ((lastName === null || lastName === void 0 ? void 0 : lastName.trim()) ? ' ' + lastName : ''));
                setJobTitle(title);
            }
            onSuccess();
        }
        else {
            setIsKeyValid(false);
        }
    });
}
const SageSignupFlow = () => {
    var _a;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [fullNameValid, setFullNameValid] = useState(true);
    const [jobTitle, setJobTitle] = useState('');
    const [jobTitleValid, setJobTitleValid] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [isFirstPerson, setIsFirstPerson] = useState(false);
    const [isKeyValid, setIsKeyValid] = useState(true);
    const routeMatch = useRouteMatch();
    const history = useHistory();
    const key = (_a = routeMatch === null || routeMatch === void 0 ? void 0 : routeMatch.params) === null || _a === void 0 ? void 0 : _a.key;
    useEffect(() => {
        if (key) {
            verifyFirstTimeKey(key, setFullName, setJobTitle, setIsFirstPerson, setIsKeyValid, setEmail);
        }
        else {
            setIsKeyValid(false);
        }
    }, []);
    function logIn() {
        const isPasswordValid = Util.validatePassword(password).valid;
        setFullNameValid(fullName !== '');
        setJobTitleValid(jobTitle !== '');
        setPasswordValid(isPasswordValid);
        if (fullName !== '' && jobTitle !== '' && isPasswordValid) {
            const firstName = fullName.substring(0, fullName.indexOf(' '));
            const lastName = fullName.substring(fullName.indexOf(' ') + 1);
            const language = navigator.language;
            const onSuccess = () => {
                setLoading(false);
                if (isFirstPerson) {
                    // Redirect first person to sage intact integration page
                    history.push('/admin/integrations/sage-intacct');
                }
                else {
                    history.push('/');
                }
            };
            setLoading(true);
            Util.CommitMutation(SetFirstTimePasswordMutation, {
                email,
                title: jobTitle,
                password,
                firstName,
                lastName,
                language: language || 'en-EU',
                key,
                termsVersionAccepted: 'April2018',
                autoJoinAccepted: false,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }, onSuccess, true, null, () => {
                setLoading(false);
            });
        }
    }
    return (React.createElement(Page, null,
        React.createElement(Content, { alignItems: "center", justifyContent: "center" },
            React.createElement(Logo, { size: LOGO_SIZE.MEDIUM }),
            React.createElement(FlexContainer, null,
                React.createElement(PageContent, { gap: "xl" }, isKeyValid ? (React.createElement(React.Fragment, null,
                    React.createElement(Heading, { size: "3" }, formatMessage({ id: 'sage.setup_account' })),
                    React.createElement(TextField, { label: formatMessage({ id: 'common.full_name' }), autoComplete: "name", errorText: !fullNameValid ? 'Please enter your Full Name.' : '', value: fullName, onValueChange: newValue => {
                            setFullName(newValue);
                            setFullNameValid(newValue !== '');
                        } }),
                    React.createElement(TextField, { label: formatMessage({ id: 'first_login.job_title' }), autoComplete: "organization-title", errorText: !jobTitleValid ? formatMessage({ id: 'first_login.enter_job_title' }) : '', value: jobTitle, onValueChange: newValue => {
                            setJobTitle(newValue);
                            setJobTitleValid(newValue !== '');
                        } }),
                    React.createElement(TextField, { isPasswordField: true, label: formatMessage({ id: 'common.password' }), autoComplete: "new-password", maxLength: 64, errorText: !passwordValid
                            ? 'Your Password must contain at least 8 characters, 1 digit, 1 letter, and no 3 identical characters in a row.'
                            : '', value: password, onValueChange: newValue => {
                            setPassword(newValue);
                            setPasswordValid(Util.validatePassword(newValue).valid);
                        } }),
                    React.createElement(WideButton, { onClick: logIn, disabled: loading }, formatMessage({ id: 'first_login.setup_account' })))) : (React.createElement(React.Fragment, null,
                    React.createElement(Heading, { size: "3" }, "Link no longer valid"),
                    isFirstPerson ? (React.createElement(Text, null, "Please contact your account manager")) : (React.createElement(Text, null, "Please contact your administrator")))))))));
};
export const SageSignupFlowQuery = graphql `
	query SageSignupFlow_Query {
		viewer {
			actualPersonId
			component(name: "sage_signup_flow")
			...SageSignupFlow_viewer
		}
	}
`;
export default createFragmentContainer(SageSignupFlow, {
    viewer: graphql `
		fragment SageSignupFlow_viewer on Viewer {
			id
			csrfToken
			actualPersonId
			email
			company {
				id
			}
		}
	`,
});
