import React from 'react';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import ProjectStageDropdown, {ProjectStageDropdownQuery} from './ProjectStageDropdown';

const ProjectStageDropdownRelayWrapper = props => {
	return (
		<ForecastQueryRenderer
			key="query-render-project-stage-dropdown"
			query={ProjectStageDropdownQuery}
			showLoader={false}
			variables={{projectId: props.projectId}}
			render={relayProps => {
				return <ProjectStageDropdown {...relayProps} {...props} />;
			}}
		/>
	);
};

export default ProjectStageDropdownRelayWrapper;
