import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal from '../../../../modal/generic_modal';
import * as tracking from '../../../../../tracking';
import {ForecastTooltipFormulaRenderer} from '../../../../../components/ForecastTooltipFormulaRenderer';
import {ChartStatsContainer, PredictionInfo} from '../predicted_end_date_modal/PredictedEndDateModal.styled';
import PerformanceModalChartSection from './PerformanceModalChartSection';
import {PerformanceModalStatsSection} from './PerformanceModalStatsSection';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import {trackEvent, trackTooltip} from '../../../../../tracking/amplitude/TrackingV2';

const PerformanceModal = ({viewer, performance, warningInfo, closeModal}) => {
	const {project} = viewer;
	const trackingInfo = {projectId: project.id, projectName: project.name};

	const navigateScoping = () => {
		tracking.trackElementClicked('Review Estimates', trackingInfo);
		trackEvent('Review Estimates', 'Clicked');
		window.open(`/project/P-${project.companyProjectId}/scoping?show-overrunning=true`);
	};

	useEffect(() => {
		tracking.trackPageAction('Performance Modal shown', trackingInfo);
		trackEvent('Performance Modal', 'Shown');
	}, []);

	const getContent = () => {
		return (
			<>
				<PredictionInfo>{warningInfo}</PredictionInfo>
				<ChartStatsContainer>
					<PerformanceModalStatsSection
						performanceRolling={performance}
						performanceTotal={project.projectHealth.performanceTotal}
					/>
					<PerformanceModalChartSection performance={performance} projectStats={project.projectStats} />
				</ChartStatsContainer>
			</>
		);
	};

	const tooltipContent = () => {
		return (
			<ForecastTooltipFormulaRenderer
				items={[
					{
						description: 'Here you see how your team is completing tasks, compared to your plan.',
					},
					{
						description:
							'When a task is estimated at 1 hour, and it takes exactly 1 hour to complete, task performance is 100%.',
					},
					{
						title: 'Task Performance is calculated as',
						details: ['Task Estimate', '/', 'Time Registered'],
					},
				]}
				translatedMessage={true}
			/>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			content={getContent()}
			headerText={'Task Performance'}
			headerInfo={tooltipContent()}
			onInfoIconShow={() => {
				tracking.trackInfoTooltipShown('Performance modal');
				trackTooltip('Performance Modal Info');
			}}
			modalWidth={900}
			buttons={[
				{
					text: 'Review Estimates',
					callback: () => navigateScoping(),
					preventDefaultClose: true,
					style: BUTTON_STYLE.OUTLINE,
					color: BUTTON_COLOR.WHITE,
				},
			]}
		/>
	);
};

const PerformanceModalQuery = graphql`
	query PerformanceModal_Query($projectId: ID) {
		viewer {
			component(name: "performance_modal")
			actualPersonId
			id
			...PerformanceModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {PerformanceModalQuery};

export default createFragmentContainer(PerformanceModal, {
	viewer: graphql`
		fragment PerformanceModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			project(internalId: $projectId) {
				id
				name
				companyProjectId
				estimationUnit
				projectHealth {
					id
					performanceTotal
				}
				projectStats {
					id
					...PerformanceModalChartSection_projectStats
				}
			}
		}
	`,
});
