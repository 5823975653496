import React, {Component} from 'react';
//import InputField from '../../components/new-ui/input_field';
import {injectIntl} from 'react-intl';
import GenericModal from '../../containers/modal/generic_modal';
import {BUTTON_COLOR} from '../../constants';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import UpdateTaskMutation from '../../mutations/update_task_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT} from '../../constants';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInputWrapper} from '../../forecast-app/shared/components/inputs/AffixedInputWrapper';

class TaskItem extends Component {
	constructor(props) {
		super(props);
		const {isEstimatedInHours, task} = props;
		const {estimateForecast} = task;
		this.state = {
			estimateForecast: isEstimatedInHours ? estimateForecast / 60 : estimateForecast,
		};
	}

	onEstimateBlur(input, value) {
		let estimateForecast;

		if (input === 'estimate') {
			this.setState({estimateForecast: value});
			estimateForecast = value;
		}

		const {task, isEstimatedInHours, onTaskEstimated} = this.props;

		const estimateRounded = (Math.round(parseFloat(estimateForecast * 100)) / 100) * (isEstimatedInHours ? 60 : 1);
		estimateForecast = estimateRounded;

		if (estimateForecast === this.props.task.estimateForecast) return;

		const onSuccess = response => {
			if (response.updateTask) {
				this.setState({
					estimateForecast: response.updateTask.tasks[0].estimateForecast / (isEstimatedInHours ? 60 : 1),
				});
				onTaskEstimated();
			}
		};
		Util.CommitMutation(UpdateTaskMutation, {ids: [task.id], forecast: estimateForecast}, onSuccess);
	}

	render() {
		const {task, isEstimatedInHours, intl} = this.props;
		const {formatMessage} = intl;
		const {estimateForecast} = this.state;

		return (
			<div className="task-item-container">
				<div className="task-info-container">
					<div className="task-name">{task.name}</div>
					{isEstimatedInHours ? (
						<HoursInput
							customClassName={'estimate-input'}
							value={estimateForecast}
							mutation={value => this.onEstimateBlur('estimate', value)}
						></HoursInput>
					) : (
						<AffixedInputWrapper
							customClassName={'estimate-input'}
							value={estimateForecast}
							callback={value => this.onEstimateBlur('estimate', value)}
							affix={formatMessage({id: 'common.points.short'})}
						/>
					)}
				</div>
				<div className="border-container" />
				<div className="corner-cover" />
			</div>
		);
	}
}

class FixUnestimatedTasksModal extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {unestimatedTasks, project, onSuccess} = this.props;
		const isEstimatedInHours = project.estimationUnit === ESTIMATION_UNIT.HOURS;
		const content = (
			<div className="auto-scheduling-modal">
				<div className="warning-description">
					In order to accurately calculate the budget, all tasks must have estimates.
				</div>
				<div className="table-heading-row"> </div>
				<div className="task-items-container">
					<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
						{unestimatedTasks.map(task => (
							<TaskItem
								key={task.id}
								isEstimatedInHours={isEstimatedInHours}
								task={task}
								intl={this.props.intl}
								onTaskEstimated={onSuccess}
							/>
						))}
					</CustomScrollDiv>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[{text: formatMessage({id: 'common.filter-close'}), color: BUTTON_COLOR.WHITE}]}
				content={content}
				headerText={'Estimate tasks'}
			/>
		);
	}
}

export default injectIntl(FixUnestimatedTasksModal);
