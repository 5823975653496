import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../css_variables';

export const ModalWrapper = styled.div`
	width: ${props => (props.small ? '384px' : props.medium ? '484px' : props.large ? '684px' : '584px')};
`;

export const ModalText = styled.div`
	font-weight: ${props => (props.bold ? '500' : '400')};
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom: ${props => props.margin || '16px'};
`;

export const ModalListItem = styled.li`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;
