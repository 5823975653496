/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type signup_viewer$ref = any;
export type signup_QueryVariables = {||};
export type signup_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: signup_viewer$ref,
  |}
|};
export type signup_Query = {|
  variables: signup_QueryVariables,
  response: signup_QueryResponse,
|};
*/


/*
query signup_Query {
  viewer {
    component(name: "signup")
    ...signup_viewer
    id
  }
}

fragment signup_viewer on Viewer {
  actualPersonId
  downForMaintenance
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "signup"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"signup\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "signup_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "signup_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "signup_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downForMaintenance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "signup_Query",
    "operationKind": "query",
    "text": "query signup_Query {\n  viewer {\n    component(name: \"signup\")\n    ...signup_viewer\n    id\n  }\n}\n\nfragment signup_viewer on Viewer {\n  actualPersonId\n  downForMaintenance\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d7f885e1fc813b1b38c96353d514932';

module.exports = node;
