import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import UpdateProjectGroupProjectsMutation from '../../mutations/update_project_group_projects_mutation';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import ActionMenu from '../../components/action_menu';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Warning from '../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../constants';
import HeaderBar from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import UploadingOverlay from '../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import ProjectHeader from '../../forecast-app/project-tab/projects/shared/ProjectHeader';
import ProgramUtil from '../../forecast-app/shared/util/ProgramUtil';
import DeprecatedProjectIndicatorJS from '../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';
import {projectUrl} from '../../directApi';
import {getProjectIndicatorString} from '../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
import {getTaskUrl, pathIncludesTask} from '../../forecast-app/shared/util/UrlUtil';

const getProjectLabel = (project, formatMessage) => {
	const projectId = getProjectIndicatorString(project.companyProjectId, project.customProjectId);
	const projectName = project.name ? project.name : formatMessage({id: 'common.untitled'});
	return `${projectId} ${projectName}`;
};

class projectGroupConnectedProjects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};

		this.superPropertyChecksum = trackPage('Project Group Projects');
	}

	UNSAFE_componentWillMount() {
		const path = this.props.history.location.pathname;
		if (!Util.AuthorizeViewerAccess('connected-projects')) {
			if (pathIncludesTask(path)) {
				this.props.history.push(
					'/connected/X-' + this.props.viewer.projectGroup.companyProjectGroupId + '/workflow' + getTaskUrl(path)
				);
			} else {
				// if user doesnt have access rights to view this page redirect to no access page
				this.props.history.push('/not-authorized');
				Util.localStorageSetItem('project-group-section-last-viewed', 'workflow');
			}
		}
	}

	componentDidMount() {
		const name =
			this.props.viewer.projectGroup.name !== null && this.props.viewer.projectGroup.name !== ''
				? this.props.viewer.projectGroup.name
				: 'X-' + this.props.viewer.projectGroup.companyProjectGroupId;
		document.title = 'Projects - ' + name + ' - Forecast';
		// Segment
		tracking.trackPage('project-group-projects');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	addProjectToGroup(projectId) {
		const onSuccess = () => {
			createToast({message: 'Project has been added to the project group', duration: 3000});
			this.setState({loading: false});
		};
		const project = this.props.viewer.projects.edges.find(project => project.node.id === projectId);
		if (project) {
			this.setState({loading: true});
			Util.CommitMutation(
				UpdateProjectGroupProjectsMutation,
				{project: project.node, projectGroupId: this.props.viewer.projectGroup.id},
				onSuccess
			);
		}
	}

	removeProjectFromGroup(project) {
		const onSuccess = result => {
			createToast({message: 'Project has been removed from the project group', duration: 3000});
			if (!result.updateProject.previousProjectGroup) {
				this.props.history.push('/projects');
			}
		};

		Util.CommitMutation(
			UpdateProjectGroupProjectsMutation,
			{project: project.node, projectGroupId: null, previousProjectGroupId: this.props.viewer.projectGroup.id},
			onSuccess
		);
	}

	handleAddProject(selected) {
		const {formatMessage} = this.props.intl;

		const psProject = this.props.viewer.psProjects.edges.find(
			project => project.node.serviceCompanyId === Util.getIdFromBase64String(selected.value)
		);

		if (psProject) {
			if (psProject.node.program) {
				createToast({
					duration: 5000,
					message: Util.upperCaseFirst(
						formatMessage(
							{id: 'program.project.import.cant_add_program_project'},
							{program: ProgramUtil.programText(formatMessage)}
						)
					),
				});
				return;
			}
		}

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="warning-modal.edit-warning-title" useInfoIcon={true} />
					<div className="warning-part-2">
						<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_1'})}</div>
						<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_2'})}</div>
						<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_3'})}</div>
						<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_4'})}</div>
					</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'cancel-button',
				},
				{
					text: formatMessage({id: 'common.add'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: this.addProjectToGroup.bind(this, selected.value),
					cy: 'add-button',
				},
			],
		});
	}

	handleRemoveProject(project) {
		const {formatMessage} = this.props.intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="warning-modal.edit-warning-title" />
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.remove'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: this.removeProjectFromGroup.bind(this, project),
				},
			],
		});
	}

	handleProjectClick(project) {
		let lastViewedPage = localStorage.getItem('project-section-last-viewed') || 'workflow';
		this.props.history.push(projectUrl(project.node.companyProjectId, project.node.customProjectId) + '/' + lastViewedPage);
	}

	getHeaderTitle() {
		const content = [];

		return (
			<TopHeaderBarWrapper sidePadding={66}>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.connected_projects'})} content={content} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader() {
		const rightContent = [
			{
				dataCy: 'add-project-dropdown',
				type: ELEMENT_TYPE.LEFT_DROPDOWN,
				dropdownOptions: this.props.viewer.projects.edges
					.filter(
						project =>
							!project.node.isInProjectGroup && !project.node.isJiraProject && project.node.fullAccessToProject
					)
					.map(project => {
						return {
							dataCy: 'project-option',
							value: project.node.id,
							label: getProjectLabel(project.node, this.props.intl.formatMessage),
						};
					}),
				value: null,
				callback: this.handleAddProject.bind(this),
				placeholder: this.props.intl.formatMessage({id: 'project_group_connected_projects.add_project'}),
			},
		];

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={[]}
				rightContent={rightContent}
			/>
		);
	}

	render() {
		return (
			<div className="section-content project-group-connected-projects">
				{this.state.loading && <UploadingOverlay />}
				{this.props.children}
				<ProjectHeader
					title="Connected projects"
					projectGroup={this.props.viewer.projectGroup}
					project={null}
					psProject={null}
					buttons={this.getHeader()}
				/>
				<div className={`section-body`}>
					<CustomScrollDiv>
						<div className="table-wrapper">
							<table>
								<thead>
									<tr>
										<th className="id">ID</th>
										<th>
											<FormattedMessage id="common.project" />
										</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{this.props.viewer.projectGroup.projects.edges
										.filter(project => project.node.isInProjectGroup)
										.map((project, index) => (
											<tr key={index}>
												<td data-cy="project-id">
													<DeprecatedProjectIndicatorJS project={project.node} clearSubPath />
												</td>
												<td onClick={this.handleProjectClick.bind(this, project)}>
													{project.node.name}
												</td>
												<td className="id">
													<ActionMenu
														options={[
															{
																label: this.props.intl.formatMessage({
																	id: 'connected_projects.remove',
																}),
																onClick: this.handleRemoveProject.bind(this, project),
															},
														]}
													/>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</CustomScrollDiv>
				</div>
			</div>
		);
	}
}

const projectGroupConnectedProjectsQuery = graphql`
	query projectGroupConnectedProjects_Query($groupId: String) {
		viewer {
			actualPersonId
			component(name: "project_group_projects")
			projectGroup(id: $groupId) {
				projects(first: 1000000) {
					edges {
						node {
							id
						}
					}
				}
			}
			...projectGroupConnectedProjects_viewer @arguments(groupId: $groupId)
		}
	}
`;

export {projectGroupConnectedProjectsQuery};

export default withRouter(
	injectIntl(
		createFragmentContainer(projectGroupConnectedProjects, {
			viewer: graphql`
				fragment projectGroupConnectedProjects_viewer on Viewer @argumentDefinitions(groupId: {type: "String"}) {
					id
					availableFeatureFlags {
						key
					}
					projectGroup(id: $groupId) {
						id
						name
						companyProjectGroupId
						...ProjectHeader_projectGroup
						...SecondaryNavigation_projectGroup
						projects(first: 1000000) @connection(key: "ProjectGroup_projects", filters: []) {
							edges {
								node {
									id
									companyProjectId
									...DeprecatedProjectIndicatorJS_project
									name
									isInProjectGroup
								}
							}
						}
					}
					projects(first: 10000) @connection(key: "Viewer_projects", filters: []) {
						edges {
							node {
								id
								companyProjectId
								customProjectId # Old project dropdown
								name
								isInProjectGroup
								isJiraProject
								fullAccessToProject
							}
						}
					}
					psProjects(first: 10000) {
						edges {
							node {
								id
								serviceCompanyId
								program
							}
						}
					}
				}
			`,
		})
	)
);
