/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SettingsProfileSubNav_viewer$ref: FragmentReference;
declare export opaque type SettingsProfileSubNav_viewer$fragmentType: SettingsProfileSubNav_viewer$ref;
export type SettingsProfileSubNav_viewer = {|
  +id: string,
  +projectOwner: ?boolean,
  +disableNotifications: ?boolean,
  +company: ?{|
    +id: string,
    +forecastDemo: ?boolean,
    +tier: ?TierType,
  |},
  +$refType: SettingsProfileSubNav_viewer$ref,
|};
export type SettingsProfileSubNav_viewer$data = SettingsProfileSubNav_viewer;
export type SettingsProfileSubNav_viewer$key = {
  +$data?: SettingsProfileSubNav_viewer$data,
  +$fragmentRefs: SettingsProfileSubNav_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsProfileSubNav_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableNotifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastDemo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8c5a706e6644304f251dfa33004b1cd';

module.exports = node;
