import React from 'react';
import styled from 'styled-components';
import StepCheck from '../../../../images/v2/StepCheck.svg';
import StepNoCheck from '../../../../images/v2/StepNocheck.svg';

const StepWrapper = styled.div`
	display: flex;
	font-size: 11px;
	color: ${props => (props.isDone ? '#49B153' : '#535353')};
	align-items: center;
	margin-right: 16px;
`;

const StepIcon = styled.div`
	margin-right: 4px;
	height: 16px;
	min-width: 16px;
	width: 16px;
	background: ${props => (props.isDone ? `url(${StepCheck})` : `url(${StepNoCheck})`)};
`;

const Step = ({title, isDone}) => {
	return (
		<StepWrapper isDone={isDone}>
			<StepIcon isDone={isDone} />
			{title}
		</StepWrapper>
	);
};

export default Step;
