/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlaceholderAllocationModal_viewer$ref = any;
export type PlaceholderAllocationModal_QueryVariables = {|
  projectId?: ?string,
  projectGroupId?: ?string,
  personId?: ?string,
  placeholderId?: ?string,
  placeholderAllocationId?: ?string,
  loadProject: boolean,
  loadProjectGroup: boolean,
  loadPlaceholder: boolean,
  loadAllocation: boolean,
  loadPerson: boolean,
|};
export type PlaceholderAllocationModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: PlaceholderAllocationModal_viewer$ref,
  |}
|};
export type PlaceholderAllocationModal_Query = {|
  variables: PlaceholderAllocationModal_QueryVariables,
  response: PlaceholderAllocationModal_QueryResponse,
|};
*/


/*
query PlaceholderAllocationModal_Query(
  $projectId: ID
  $projectGroupId: ID
  $personId: ID
  $placeholderId: ID
  $placeholderAllocationId: ID
  $loadProject: Boolean!
  $loadProjectGroup: Boolean!
  $loadPlaceholder: Boolean!
  $loadAllocation: Boolean!
  $loadPerson: Boolean!
) {
  viewer {
    actualPersonId
    component(name: "placeholder_allocation_modal")
    ...PlaceholderAllocationModal_viewer_3HhsnB
    id
  }
}

fragment PlaceholderAllocationModal_viewer_3HhsnB on Viewer {
  id
  email
  company {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    person(id: $personId) @include(if: $loadPerson) {
      id
      fullName
      role {
        id
        name
      }
      profilePictureId
    }
    placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
      id
      name
      role {
        id
        name
      }
    }
    placeholderAllocation(placeholderAllocationId: $placeholderAllocationId) @include(if: $loadAllocation) {
      id
      startDate
      endDate
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      description
    }
    id
  }
  project(internalId: $projectId) @include(if: $loadProject) {
    id
    name
    projectStartYear
    projectStartMonth
    projectStartDay
    projectEndYear
    projectEndMonth
    projectEndDay
  }
  projectGroup(globalId: $projectGroupId) @include(if: $loadProjectGroup) {
    id
    projects(first: 1000000) {
      edges {
        node {
          id
          projectStartYear
          projectStartMonth
          projectStartDay
          projectEndYear
          projectEndMonth
          projectEndDay
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectGroupId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeholderId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeholderAllocationId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadProject",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadProjectGroup",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadPlaceholder",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadAllocation",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadPerson",
    "type": "Boolean!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "placeholder_allocation_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"placeholder_allocation_modal\")"
},
v3 = {
  "kind": "Variable",
  "name": "placeholderAllocationId",
  "variableName": "placeholderAllocationId"
},
v4 = {
  "kind": "Variable",
  "name": "placeholderId",
  "variableName": "placeholderId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaceholderAllocationModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadAllocation",
                "variableName": "loadAllocation"
              },
              {
                "kind": "Variable",
                "name": "loadPerson",
                "variableName": "loadPerson"
              },
              {
                "kind": "Variable",
                "name": "loadPlaceholder",
                "variableName": "loadPlaceholder"
              },
              {
                "kind": "Variable",
                "name": "loadProject",
                "variableName": "loadProject"
              },
              {
                "kind": "Variable",
                "name": "loadProjectGroup",
                "variableName": "loadProjectGroup"
              },
              {
                "kind": "Variable",
                "name": "personId",
                "variableName": "personId"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "projectGroupId",
                "variableName": "projectGroupId"
              },
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PlaceholderAllocationModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlaceholderAllocationModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v5/*: any*/),
              {
                "condition": "loadPerson",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "personId"
                      }
                    ],
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "loadPlaceholder",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v4/*: any*/)
                    ],
                    "concreteType": "Placeholder",
                    "kind": "LinkedField",
                    "name": "placeholder",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "loadAllocation",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v3/*: any*/)
                    ],
                    "concreteType": "PlaceholderAllocation",
                    "kind": "LinkedField",
                    "name": "placeholderAllocation",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "condition": "loadProject",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "internalId",
                    "variableName": "projectId"
                  }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "loadProjectGroup",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "globalId",
                    "variableName": "projectGroupId"
                  }
                ],
                "concreteType": "ProjectGroupType",
                "kind": "LinkedField",
                "name": "projectGroup",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1000000
                      }
                    ],
                    "concreteType": "ProjectTypeConnection",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "projects(first:1000000)"
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PlaceholderAllocationModal_Query",
    "operationKind": "query",
    "text": "query PlaceholderAllocationModal_Query(\n  $projectId: ID\n  $projectGroupId: ID\n  $personId: ID\n  $placeholderId: ID\n  $placeholderAllocationId: ID\n  $loadProject: Boolean!\n  $loadProjectGroup: Boolean!\n  $loadPlaceholder: Boolean!\n  $loadAllocation: Boolean!\n  $loadPerson: Boolean!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"placeholder_allocation_modal\")\n    ...PlaceholderAllocationModal_viewer_3HhsnB\n    id\n  }\n}\n\nfragment PlaceholderAllocationModal_viewer_3HhsnB on Viewer {\n  id\n  email\n  company {\n    monday\n    tuesday\n    wednesday\n    thursday\n    friday\n    saturday\n    sunday\n    person(id: $personId) @include(if: $loadPerson) {\n      id\n      fullName\n      role {\n        id\n        name\n      }\n      profilePictureId\n    }\n    placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {\n      id\n      name\n      role {\n        id\n        name\n      }\n    }\n    placeholderAllocation(placeholderAllocationId: $placeholderAllocationId) @include(if: $loadAllocation) {\n      id\n      startDate\n      endDate\n      monday\n      tuesday\n      wednesday\n      thursday\n      friday\n      saturday\n      sunday\n      description\n    }\n    id\n  }\n  project(internalId: $projectId) @include(if: $loadProject) {\n    id\n    name\n    projectStartYear\n    projectStartMonth\n    projectStartDay\n    projectEndYear\n    projectEndMonth\n    projectEndDay\n  }\n  projectGroup(globalId: $projectGroupId) @include(if: $loadProjectGroup) {\n    id\n    projects(first: 1000000) {\n      edges {\n        node {\n          id\n          projectStartYear\n          projectStartMonth\n          projectStartDay\n          projectEndYear\n          projectEndMonth\n          projectEndDay\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2acf5bb2a1e524de9a71889f210f26da';

module.exports = node;
