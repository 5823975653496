import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import React from 'react';
import {useIntl} from 'react-intl';
import Styled from 'styled-components/macro';
import Person from '../../../forecast-app/shared/components/person/person';
import PersonAvatar from '../../../images/v2/avatars/Person.svg';
import {CSS_CONSTANTS} from '../../../css_variables';
import SkillBadge from '../../../forecast-app/shared/components/skills/skill_badge';

const Container = Styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	
	width: 520px;
	height: 198.18px;
`;

const ImageContent = Styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 24px;
	gap: 7px;
	width: 217px;
	height: 198.18px;
	background: #F7F7FE;
	border-radius: 12px;
	align-items: center;
	
	.forecast-person {
		align-items: center;
		width: 217px;
		.person-info {
			align-items: center;
			width: 217px;
		}
		.name {
			font-size: 13px;
			font
		}
		.role {
			font-size: 12px;
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			font-weight: normal;
		}
	}
`;

const Avatar = Styled.img`
	width: 79px;
	height: 90.18px;
`;

const TextContent = Styled.div`
	width: 256px;
	height: 120px;

	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	white-space: pre-wrap;
`;

const EnableSkillLevelsInfoModal = ({closeModal}) => {
	const {formatMessage} = useIntl();

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.MEDIUM}
			headerText={<div>{formatMessage({id: 'settings_skills.enable_levels_info_modal.title'})}</div>}
			buttons={[
				{
					text: formatMessage({id: 'common.got_it'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.AI_BLUE,
					cy: 'button-got-it',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<Container>
							<TextContent>{formatMessage({id: 'settings_skills.enable_levels_info_modal.content'})}</TextContent>
							<ImageContent>
								<Avatar src={PersonAvatar} />
								<Person name="James Dean" role="Developer" showName={true} showRole={true} hideImage={true} />
								<SkillBadge skill={{name: 'Backend'}} unknownLevel={true} />
							</ImageContent>
						</Container>
					</Field>
				</ModalBody>
			}
		/>
	);
};

export default EnableSkillLevelsInfoModal;
