import React, {Component} from 'react';
import PropTypes from 'prop-types';

class GenericEmptyState extends Component {
	render() {
		return (
			<div className={'generic-empty-state ' + (this.props.className || '')}>
				{this.props.iconClass ? <div className={'empty-state-icon ' + this.props.iconClass} /> : null}
				{this.props.headerText ? <h1 className="empty-state-header">{this.props.headerText}</h1> : null}
				{this.props.text ? <div className="empty-state-text">{this.props.text}</div> : null}
				{this.props.extraContent ? <div className="empty-state-extra">{this.props.extraContent}</div> : null}
				{this.props.buttonText && this.props.buttonAction ? (
					<button className="empty-state-button" onClick={this.props.buttonAction}>
						{this.props.buttonText}
					</button>
				) : null}
			</div>
		);
	}
}

GenericEmptyState.propTypes = {
	iconClass: PropTypes.string,
	className: PropTypes.string,
	headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	buttonText: PropTypes.string,
	buttonAction: PropTypes.func,
	extraContent: PropTypes.element,
};

export default GenericEmptyState;
