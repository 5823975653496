import React, {Component} from 'react';
import InputField from '../../components/inputs/input_field';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import CreateRateCardMutation from '../../mutations/create_rate_card_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

class addRateCardModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			canSendRequest: true,
		};
	}

	onCreateRateCardSuccess(result) {
		this.setState({
			name: '',
		});
		if (this.props.project) {
			const onUpdateProjectSuccess = () => {
				this.props.closeModal();
			};
			Util.CommitMutation(
				UpdateProjectMutation,
				{
					project: this.props.project,
					rateCardId: result.createRateCard.rateCard.node.id,
				},
				onUpdateProjectSuccess
			);
		} else {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'add_rate_card_modal.toast_text'}),
			});
			this.props.closeModal();
		}
	}

	handleInputChange(property, value) {
		this.setState({[property]: value});
	}

	createRateCard() {
		this.setState({canSendRequest: false});
		Util.CommitMutation(
			CreateRateCardMutation,
			{
				companyId: this.props.viewer.company.id,
				name: this.state.name,
			},
			this.onCreateRateCardSuccess.bind(this)
		);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const content = (
			<div className="body-container">
				<InputField
					cy={'rate-card-name-label'}
					label={formatMessage({id: 'common.name'})}
					value={this.state.name}
					onChange={this.handleInputChange.bind(this, 'name')}
					autoFocus={true}
					type="text"
					placeholder=""
					onEnter={this.createRateCard.bind(this)}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.cancelText || formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'add_rate_card_modal.button_text'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.createRateCard.bind(this),
						disabled: !this.state.name || !this.state.canSendRequest,
						cy: 'add-rate-card-modal-button',
					},
				]}
				headerText={formatMessage({id: 'add_rate_card_modal.title'})}
				content={content}
			/>
		);
	}
}

const addRateCardModalQuery = graphql`
	query addRateCardModal_Query {
		viewer {
			actualPersonId
			component(name: "add_rate_card_modal")
			...addRateCardModal_viewer
		}
	}
`;

export {addRateCardModalQuery};

export default injectIntl(
	createFragmentContainer(addRateCardModal, {
		viewer: graphql`
			fragment addRateCardModal_viewer on Viewer {
				company {
					id
					rateCards(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			}
		`,
	})
);
