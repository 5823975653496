import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {ModalListItem, ModalText, ModalWrapper} from '../modal_styled';
import Warning from '../../../components/warning';
import InputField from '../../../components/inputs/input_field';

const IntegrationIncompatibleModal = ({
	closeModal,
	handleConfirmButton,
	featureName,
	fullBlock,
	incompatibleIntegrations,
	intl: {formatMessage},
}) => {
	const hasIncompatibleIntegrations = incompatibleIntegrations.length > 0;

	const [inputValue, setInputValue] = useState('');

	const confirmText = 'confirm';

	const handleConfirm = () => {
		closeModal();
		handleConfirmButton();
	};

	const content = (
		<ModalWrapper>
			<Warning />
			<ModalText
				bold
			>{`You have the following integration(s) enabled which are not fully compatible with ${featureName}`}</ModalText>
			{hasIncompatibleIntegrations ? (
				<ul>
					{incompatibleIntegrations.map(val => (
						<ModalListItem>{val}</ModalListItem>
					))}
				</ul>
			) : null}

			<ModalText>{`${featureName} locks time registrations, which means that the listed integration(s) will not be able to synchronize changes correctly.`}</ModalText>

			{fullBlock ? (
				<ModalText bold>{`Therefore, you are not currently able to use ${featureName} in your company.`}</ModalText>
			) : (
				<>
					<ModalText>{`We strongly advise not to do the following actions outside of Forecast if you wish to use ${featureName} with an incompatible integration:`}</ModalText>
					<ul>
						<ModalListItem>{'Create / Update / Delete Time Registrations'}</ModalListItem>
						<ModalListItem>{'Delete Tasks'}</ModalListItem>
						<ModalListItem>{'Delete Projects'}</ModalListItem>
					</ul>
					<ModalText bold margin={'8px'}>
						{'If you are sure you wish to proceed, please type ‘confirm’ into the field below:'}
					</ModalText>
					<InputField
						value={inputValue}
						onChange={setInputValue.bind(this)}
						placeholder={formatMessage(
							{id: 'settings_team.delete_person_modal.type_confirm_text'},
							{confirmText: confirmText}
						)}
						type="text"
						autoFocus={true}
						hideLabel={true}
						className="confirmation-input"
					/>
				</>
			)}
		</ModalWrapper>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={
				fullBlock
					? [
							{
								text: 'Close',
								style: BUTTON_STYLE.OUTLINE,
								color: BUTTON_COLOR.VERYLIGHTGREY,
							},
					  ]
					: [
							{
								text: 'Confirm',
								style: BUTTON_STYLE.OUTLINE,
								color: BUTTON_COLOR.RED,
								callback: handleConfirm.bind(this),
								preventDefaultClose: true,
								disabled: confirmText.toLowerCase() !== inputValue.toLowerCase().trim(),
							},
					  ]
			}
			headerText={`${featureName} Integration Incompatibility`}
			content={content}
		/>
	);
};

IntegrationIncompatibleModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(IntegrationIncompatibleModal);
