/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PersonsPreviewSelector_persons$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UpdateSkillModal_company$ref: FragmentReference;
declare export opaque type UpdateSkillModal_company$fragmentType: UpdateSkillModal_company$ref;
export type UpdateSkillModal_company = {|
  +id: string,
  +skillLevelsEnabled: ?boolean,
  +skillCategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +skills: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +category: ?{|
          +id: string,
          +name: ?string,
        |},
        +skillPersons: ?$ReadOnlyArray<?{|
          +person: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string,
            +level: ?number,
          |},
        |}>,
      |}
    |}>
  |},
  +allPersons: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: PersonsPreviewSelector_persons$ref
      |}
    |}>
  |},
  +skillLevels: ?$ReadOnlyArray<?{|
    +id: string,
    +level: ?number,
    +description: ?string,
  |}>,
  +$refType: UpdateSkillModal_company$ref,
|};
export type UpdateSkillModal_company$data = UpdateSkillModal_company;
export type UpdateSkillModal_company$key = {
  +$data?: UpdateSkillModal_company$data,
  +$fragmentRefs: UpdateSkillModal_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "skillCategories"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "skills"
        ]
      }
    ]
  },
  "name": "UpdateSkillModal_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillLevelsEnabled",
      "storageKey": null
    },
    {
      "alias": "skillCategories",
      "args": null,
      "concreteType": "SkillCategoryTypeConnection",
      "kind": "LinkedField",
      "name": "__Company_skillCategories_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillCategoryTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SkillCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "skills",
      "args": null,
      "concreteType": "SkillTypeConnection",
      "kind": "LinkedField",
      "name": "__Company_skills_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Skill",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillCategory",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillPerson",
                  "kind": "LinkedField",
                  "name": "skillPersons",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillLevel",
                      "kind": "LinkedField",
                      "name": "level",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeClientUsers",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "PersonTypeConnection",
      "kind": "LinkedField",
      "name": "allPersons",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PersonsPreviewSelector_persons"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillLevel",
      "kind": "LinkedField",
      "name": "skillLevels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = '26831f51c605480bfc0fe24610841daa';

module.exports = node;
