import React from 'react';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import GenericModal from '../modal/generic_modal';

export const CompanyFeatureFlagsModal = ({features, closeModal}) => {
	const getFeatureFlag = feature => {
		const users = feature.persons.join(', ');
		const optedOutUsers = feature.personsOptedOut.join(', ');
		return (
			<tr key={feature.id} className="feature-flag-group">
				<td>{feature.name}</td>
				<td>{users}</td>
				<td>{optedOutUsers !== '' ? optedOutUsers : 'None'}</td>
			</tr>
		);
	};

	const content = (
		<div className="company-feature-flag-modal">
			<table className="feature-flag-group-table">
				<thead>
					<tr>
						<th className="feature-flag-group-table-width">Feature Flag</th>
						<th className="feature-flag-group-table-width">Users</th>
						<th className="feature-flag-group-table-width">Users opted out</th>
					</tr>
				</thead>
				<tbody>{features.map(feature => getFeatureFlag(feature))}</tbody>
			</table>
		</div>
	);

	const buttons = [
		{
			text: 'close',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
		},
	];
	return (
		<GenericModal
			closeModal={() => closeModal()}
			buttons={buttons}
			content={content}
			headerText={'Company feature flags'}
		/>
	);
};
