import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import {CSS_CONSTANTS} from '../../../css_variables';

const EnabledByTierModuleStyle = styled.div`
	font-weight: bold;
	font-size: 13px;
	margin-right: 10px;
	margin-top: 2px;
	margin-bottom: 2px;
	display: inline-block;
	padding: 2px 8px;
	border: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
	border-radius: 4px;
	background-color: ${CSS_CONSTANTS.v2_branding_purple};
	color: white;
`;

const ManualModuleStyle = styled.div`
	font-weight: bold;
	font-size: 13px;
	cursor: pointer;
	margin-right: 10px;
	margin-top: 2px;
	margin-bottom: 2px;
	display: inline-flex;
	align-items: center;
	padding: 2px 8px;
	border: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
	border-radius: 4px;
	background-color: ${CSS_CONSTANTS.v2_disabled_purple};
	color: black;
`;

const TimeRestrictionStyle = styled.span`
	margin-left: 5px;
	font-weight: normal;
	font-size: 11px;
`;

export const Module = ({module, onModuleClicked}) => {
	const moduleName =
		module.moduleType === 'TIME_LOCKING'
			? 'TIME LOCKING / APPROVAL'
			: module.moduleType === 'SISENSE'
			? 'ADVANCED ANALYTICS'
			: module.moduleType;

	if (module.enabledByTier) {
		return <EnabledByTierModuleStyle title="Module is enabled by the customer Tier">{moduleName}</EnabledByTierModuleStyle>;
	} else {
		const getTimeRestrictedString = () => {
			const startDate = Util.CreateNonUtcMomentDate(module.startYear, module.startMonth, module.startDay);
			const endDate = Util.CreateNonUtcMomentDate(module.endYear, module.endMonth, module.endDay);

			if (startDate !== null || endDate !== null) {
				if (startDate !== null && startDate.isAfter(moment())) {
					return <TimeRestrictionStyle>(Not active yet)</TimeRestrictionStyle>;
				}
				if (endDate !== null && endDate.isBefore(moment())) {
					return <TimeRestrictionStyle>(Expired)</TimeRestrictionStyle>;
				}
				if (endDate !== null && moment().isBefore(endDate)) {
					return <TimeRestrictionStyle>(Trial ends {endDate.format('YYYY-MM-DD')})</TimeRestrictionStyle>;
				}
			}

			return null;
		};

		const timeRestricted = getTimeRestrictedString();

		return (
			<ManualModuleStyle onClick={onModuleClicked} title="Manually added module">
				{moduleName}
				{timeRestricted}
			</ManualModuleStyle>
		);
	}
};
