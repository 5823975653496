import React, {useState, useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import GenericModal from '../generic_modal';
import InvoiceEntriesList from '../create_invoice_modal_v2/steps/invoice_entries_list';
import {InvoiceDropdownContainer, InvoiceDataLineTwo, ContentWrapper} from '../../../styles/v2/invoice_modals_v2_styled';
import CreateEconomicInvoiceMutation from '../../../mutations/create_economic_invoice_mutation';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {showModal, MODAL_TYPE} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import {hideLoader, showLoader} from '../../../containers/global_loader';
import DirectApi from '../../../directApi';
import {
	UneditableMessageBox,
	MessageContainer,
	PadLockContainer,
} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';

const economicExportModal = ({closeModal, viewer, economicCustomers}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {invoice, projects} = viewer;
	const {entries} = invoice;
	const mappedProjects = projects.edges.map(p => ({...p.node}));
	const clientProjects = mappedProjects.filter(project =>
		project.client && invoice.client ? project.client.id === invoice.client.id : false
	);
	const initialInvoiceLines = entries.edges.map(e => ({
		...e.node,
		projectId: e.node.project ? e.node.project.id : null,
		quantity: e.node.quantityText,
	}));

	const [invoiceLines, setInvoiceLines] = useState(initialInvoiceLines);
	const [economicProducts, setEconomicProducts] = useState([]);
	const [selectedClient, setSelectedClient] = useState(
		invoice.client && invoice.client.economicCustomerId ? invoice.client.id : null
	);
	const [selectedEconomicProduct, setSelectedEconomicProduct] = useState(null);
	const [exporting, setExporting] = useState(false);

	const selectEconomicProduct = e => {
		setSelectedEconomicProduct(e.value);
		invoiceLines.forEach(line => {
			line.economicProduct = e.value;
		});
	};

	const content = (
		<ContentWrapper minHeight="600px">
			<UneditableMessageBox style={{marginBottom: '30px'}}>
				<PadLockContainer>
					<LockIcon size={LockIcon.SIZE.LARGE} color={CSS_CONSTANTS.v2_text_gray} />
				</PadLockContainer>
				<MessageContainer>{formatMessage({id: 'invoicing.cannot_edit_warning_exporting'})}</MessageContainer>
			</UneditableMessageBox>
			<InvoiceDataLineTwo>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => setSelectedClient(option.value)}
						options={economicCustomers}
						value={selectedClient}
						label={formatMessage({id: 'common.customer'})}
						placeholder={formatMessage({id: 'common.customer'})}
					/>
				</InvoiceDropdownContainer>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectEconomicProduct(option)}
						options={economicProducts}
						value={selectedEconomicProduct}
						label={formatMessage({id: 'integrations.economic.product'})}
						placeholder={formatMessage({id: 'integrations.economic.product'})}
					/>
				</InvoiceDropdownContainer>
			</InvoiceDataLineTwo>
			<InvoiceEntriesList
				invoiceExporting={true}
				lines={invoiceLines}
				projects={mappedProjects}
				clientProjects={clientProjects}
				currency={invoice.currency}
				setInvoiceLines={setInvoiceLines}
				invoice={invoice}
				integrationData={{
					economic: {
						enabled: true,
						products: economicProducts,
					},
				}}
			/>
		</ContentWrapper>
	);

	const onInvoiceExport = () => {
		setExporting(true);
		showLoader();
		// Export invoice to E-conomic
		Util.CommitMutation(
			CreateEconomicInvoiceMutation,
			{
				invoiceId: invoice.id,
				forecastClientId: selectedClient,
				entryMap: invoiceLines.map(line => ({entryId: line.id, productId: line.economicProduct})),
			}, // Toast
			result => {
				setExporting(false);
				closeModal();
				hideLoader();
				if (result?.createEconomicInvoice?.success) {
					createToast({duration: 5000, message: intl.formatMessage({id: 'integrations.economic.invoice_created'})});
				} else {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'invoicing.not_exported',
						warningInformation: [
							result?.createEconomicInvoice?.errorMessage || 'The invoice could not be exported.',
						],
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			}
		);
	};

	const getEconomicProducts = () => {
		try {
			DirectApi.Fetch(`economic/products`).then(json => {
				setEconomicProducts(
					json.list.map(item => ({
						value: item.id,
						label: item.name,
						description: item.description,
						unitPrice: item.unitPrice,
						taxable: item.taxable,
					}))
				);
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const modalButtons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			callback: closeModal.bind(this),
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: formatMessage({id: 'common.export'}),
			preventDefaultClose: true,
			callback: onInvoiceExport,
			disabled: exporting || selectedClient === null || invoiceLines.find(line => !line.economicProduct),
		},
	];

	useEffect(() => {
		getEconomicProducts();
	}, []);

	return (
		<GenericModal
			buttons={modalButtons}
			headerText={formatMessage({id: 'invoicing.export_to'}, {systemName: 'E-conomic'})}
			closeModal={closeModal}
			content={content}
		/>
	);
};

const economicExportModalQuery = graphql`
	query economicExportModal_Query($invoiceId: ID) {
		viewer {
			actualPersonId
			component(name: "economic_export_modal")
			...economicExportModal_viewer @arguments(invoiceId: $invoiceId)
		}
	}
`;

export {economicExportModalQuery};

export default createFragmentContainer(economicExportModal, {
	viewer: graphql`
		fragment economicExportModal_viewer on Viewer @argumentDefinitions(invoiceId: {type: "ID"}) {
			id
			backendId
			invoice(id: $invoiceId) {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				economicId
				client {
					id
					economicCustomerId
				}
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quantityLockedReason
							project {
								id
								companyProjectId
								name
								projectColor
								isInProjectGroup
								budgetType
								client {
									name
								}
								rateCard {
									currency
								}
							}
						}
					}
				}
			}
			projects {
				edges {
					node {
						id
						name
						projectColor
						isInProjectGroup
						companyProjectId
						projectGroupId
						budgetType
						status
						fullAccessToProject
					}
				}
			}
		}
	`,
});
