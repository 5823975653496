/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
type ProjectHeader_projectGroup$ref = any;
type SecondaryNavigation_projectGroup$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectGroupConnectedProjects_viewer$ref: FragmentReference;
declare export opaque type projectGroupConnectedProjects_viewer$fragmentType: projectGroupConnectedProjects_viewer$ref;
export type projectGroupConnectedProjects_viewer = {|
  +id: string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +projectGroup: ?{|
    +id: string,
    +name: ?string,
    +companyProjectGroupId: ?number,
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +companyProjectId: ?number,
          +name: ?string,
          +isInProjectGroup: ?boolean,
          +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
        |}
      |}>
    |},
    +$fragmentRefs: ProjectHeader_projectGroup$ref & SecondaryNavigation_projectGroup$ref,
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +name: ?string,
        +isInProjectGroup: ?boolean,
        +isJiraProject: ?boolean,
        +fullAccessToProject: ?boolean,
      |}
    |}>
  |},
  +psProjects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +serviceCompanyId: ?number,
        +program: ?string,
      |}
    |}>
  |},
  +$refType: projectGroupConnectedProjects_viewer$ref,
|};
export type projectGroupConnectedProjects_viewer$data = projectGroupConnectedProjects_viewer;
export type projectGroupConnectedProjects_viewer$key = {
  +$data?: projectGroupConnectedProjects_viewer$data,
  +$fragmentRefs: projectGroupConnectedProjects_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProjectGroup",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroup",
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projects"
        ]
      }
    ]
  },
  "name": "projectGroupConnectedProjects_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "groupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectGroupId",
          "storageKey": null
        },
        {
          "alias": "projects",
          "args": null,
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__ProjectGroup_projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DeprecatedProjectIndicatorJS_project"
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_projectGroup"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_projectGroup"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customProjectId",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isJiraProject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullAccessToProject",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "ProjectServiceProjectTypeConnection",
      "kind": "LinkedField",
      "name": "psProjects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectServiceProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceCompanyId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "program",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "psProjects(first:10000)"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '63cee7b640f9ad6ef59a9ee50e7947d9';

module.exports = node;
