import React from 'react';
import styled from 'styled-components';
import {WarningIcon} from 'web-components/icons';
import ChevronRight from '../../../../images/chevrons/ChevronRight';

const StatusIndicatorWrapper = styled.div`
	display: inline-flex;
	height: 24px;
	align-items: center;
	padding: 6px 10px;
	border: 1px solid #fff;
	border-color: ${props => props.color};
	border-radius: 20px;
	font-size: 10px;
	color: ${props => props.color};
	white-space: nowrap;
	margin-right: ${props => props.withMargin && '8px'};
	background-color: #fff;
	outline: none;
	cursor: ${props => props.onClick && 'pointer'};
	box-shadow: ${props => props.active && '0px 0px 0px 1px white, 0px 0px 0px 3px #8843EF'};
	&:focus {
		box-shadow: ${props => !props.active && props.onKeyDown && '0px 0px 0px 1px white, 0px 0px 0px 3px #008BFA'};
	}
`;

const Dot = styled.div`
	width: 8px;
	height: 8px;
	background-color: ${props => props.color};
	border-radius: 50%;
	margin-right: 6px;
`;

const ChevronWrapper = styled.div`
	display: flex;
	margin-left: 8px;
`;

export const convertRagStatusToWarningVariant = statusColor => {
	switch (statusColor) {
		case 'RED':
			return WarningIcon.VARIANT.DANGER;
		case 'AMBER':
			return WarningIcon.VARIANT.WARNING;
		default:
			return WarningIcon.VARIANT.NO_WARNING;
	}
};

export const convertStatusColorToWarningVariant = statusColor => {
	switch (statusColor) {
		case '#ff3300':
			return WarningIcon.VARIANT.DANGER;
		case '#ffc107':
			return WarningIcon.VARIANT.WARNING;
		default:
			return WarningIcon.VARIANT.NO_WARNING;
	}
};

export const getTextFromVariant = variant => {
	switch (variant) {
		case WarningIcon.VARIANT.DANGER:
			return 'Off track';
		case WarningIcon.VARIANT.WARNING:
			return 'At risk';
		default:
			return 'On track';
	}
};

/**
 * @callback onSelectCallback
 */

/**
 * @typedef {object} StatusIndicatorProps
 * @property {object} [warning]
 * @property {string} [projectStatusColor]
 * @property {boolean} [active]
 * @property {boolean} [fromRagStatus]
 * @property {boolean} [showChevron]
 * @property {boolean} [withMargin=true]
 * @property {onSelectCallback} [onSelect]
 */

/**
 * @param {StatusIndicatorProps} props
 * @returns {React.ReactElement}
 */
export const StatusIndicator = ({
	warning,
	projectStatusColor,
	active,
	fromRagStatus,
	showChevron,
	withMargin = true,
	onSelect,
}) => {
	const onIndicatorKeyDown = keyboardEvent => {
		if (keyboardEvent.key === 'Enter' || keyboardEvent.key === ' ') {
			keyboardEvent.stopPropagation();
			keyboardEvent.preventDefault();
			if (onSelect) {
				onSelect(keyboardEvent);
			}
		}
	};

	const onIndicatorClick = keyboardEvent => {
		if (onSelect) {
			onSelect(keyboardEvent);
		}
	};

	const warningVariant =
		warning ||
		(fromRagStatus
			? convertRagStatusToWarningVariant(projectStatusColor)
			: convertStatusColorToWarningVariant(projectStatusColor));

	return (
		<StatusIndicatorWrapper
			color={warningVariant.color}
			active={active}
			onClick={onIndicatorClick}
			onKeyDown={onIndicatorKeyDown}
			withMargin={withMargin}
			tabIndex={onSelect ? 0 : undefined}
		>
			<Dot color={warningVariant.color}></Dot>
			{getTextFromVariant(warningVariant)}
			{showChevron ? (
				<ChevronWrapper>
					<ChevronRight color={warningVariant.color} />
				</ChevronWrapper>
			) : null}
		</StatusIndicatorWrapper>
	);
};
