import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

class FileLocationModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.taskId,
		};
	}

	setSelected(selected) {
		this.setState({selected: selected.value});
	}

	handleMove(card, file) {
		this.props.handleFileMoveToCard(card, file);
		this.props.closeModal();
	}

	render() {
		const {formatMessage} = this.props.intl;

		const content = (
			<div className="content">
				<Dropdown
					options={this.props.cards.map(c => ({
						value: c.node.id,
						label: `T${c.node.companyTaskId} - ${c.node.name}`,
					}))}
					onChange={this.setSelected.bind(this)}
					label={formatMessage({id: 'common.card'})}
					value={this.state.selected}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.move'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.handleMove.bind(this, this.state.selected, this.props.file),
					},
				]}
				headerText={formatMessage({id: 'overview_time.select-card'})}
				content={content}
			/>
		);
	}
}

FileLocationModal.propTypes = {
	cards: PropTypes.arrayOf(PropTypes.object).isRequired,
	file: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	handleFileMoveToCard: PropTypes.func.isRequired,
};

export default injectIntl(FileLocationModal);
