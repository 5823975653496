import { Text, Modal } from '@forecast-it/design-system';
import React from 'react';
const IntegrationsInfoModal = ({ closeModal, title, infoMessage }) => {
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, title),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null, infoMessage)),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { key: 'modal_integrations_info', onPress: closeModal }, "Ok"))));
};
export default IntegrationsInfoModal;
