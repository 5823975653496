import React from 'react';
import * as tracking from '../../tracking';
import {showModal, MODAL_TYPE, closeModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {registerSuperProperty, SUPER_PROPERTY, unregisterSuperProperty} from '../../tracking/amplitude/TrackingV2';

class OpenTaskModal extends React.Component {
	UNSAFE_componentWillMount() {
		if (this.props.params && this.props.params.taskId && !isNaN(this.props.params.taskId)) {
			tracking.registerSuperProperty('_TaskModalOpen', true);
			this.superPropertyChecksum = registerSuperProperty(SUPER_PROPERTY.AMPLITUDE_TASK_MODAL_INFO, {
				taskModalOpened: true,
			});
			showModal(
				{
					type: MODAL_TYPE.TASK_MODAL,
					taskId: this.props.params.taskId,
					connected: this.props.params.pageNo?.includes('connected'),
				},
				true
			);
		} else {
			window.location.href = '/not-found';
		}
	}

	componentDidUpdate(prevProps) {
		// Open new task modal if receiving a new taskId
		if (this.props.params && this.props.params.taskId && this.props.params.taskId !== prevProps.params.taskId) {
			if (!isNaN(this.props.params.taskId)) {
				tracking.registerSuperProperty('_TaskModalOpen', true);
				this.superPropertyChecksum = registerSuperProperty(SUPER_PROPERTY.AMPLITUDE_TASK_MODAL_INFO, {
					taskModalOpened: true,
				});
				showModal({type: MODAL_TYPE.TASK_MODAL, taskId: this.props.params.taskId}, true);
			} else {
				window.location.href = '/not-found';
			}
		}
	}

	componentWillUnmount() {
		tracking.registerSuperProperty('_TaskModalOpen', false);
		unregisterSuperProperty(SUPER_PROPERTY.AMPLITUDE_TASK_MODAL_INFO, this.superPropertyChecksum);
		closeModal();
	}

	render() {
		return null;
	}
}

export default OpenTaskModal;
