import styled from 'styled-components/macro';
export const HorizontalBar = styled.hr `
	border: none;
	height: 20px;
	width: 100%;
	height: 18px;
	margin-top: 0;
	border-top: 1px solid #d3d3df;
	box-shadow: 0px -8px 10px -8px rgba(35, 35, 52, 0.12);
	margin: -17px -24px -18px;
	padding: 0 24px;
`;
