/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ExportInvoiceButton_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingTable_company$ref: FragmentReference;
declare export opaque type ProjectInvoicingTable_company$fragmentType: ProjectInvoicingTable_company$ref;
export type ProjectInvoicingTable_company = {|
  +currency: ?string,
  +newXeroEnabled: ?boolean,
  +quickbooksEnabled: ?boolean,
  +economicEnabled: ?boolean,
  +sageIntacctConnectionEnabled: ?boolean,
  +integrations: ?{|
    +userCanUnlinkInvoice: ?boolean
  |},
  +$fragmentRefs: ExportInvoiceButton_company$ref,
  +$refType: ProjectInvoicingTable_company$ref,
|};
export type ProjectInvoicingTable_company$data = ProjectInvoicingTable_company;
export type ProjectInvoicingTable_company$key = {
  +$data?: ProjectInvoicingTable_company$data,
  +$fragmentRefs: ProjectInvoicingTable_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectInvoicingTable_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newXeroEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quickbooksEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "economicEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sageIntacctConnectionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyIntegrationsType",
      "kind": "LinkedField",
      "name": "integrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userCanUnlinkInvoice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportInvoiceButton_company"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'a8b87c1f351b6adc0272d66fc511ffc4';

module.exports = node;
