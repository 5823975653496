import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import LoginMutation from '../mutations/login_mutation';
import {Link, withRouter} from 'react-router-dom';
import GoogleLoginMutation from '../mutations/google_login_mutation';
import CreateOktaVerificationKeyMutation from '../mutations/create_okta_verification_key_mutation';
import CreateOneloginOauthKeyMutation from '../mutations/create_onelogin_oauth_key_mutation';
import CreateAADOauthKeyMutation from '../mutations/create_AAD_oauth_key_mutation';
import * as tracking from '../tracking';
import {FormattedMessage, injectIntl} from 'react-intl';
import Util from '../forecast-app/shared/util/util';
import oktaLogo from '../images/integrations/okta-logo.png';
import microsoftEntraLogo from '../images/integrations/microsoft-entra-id-logo.svg';
import newGoogleLogo from '../images/sections/login/newGoogleLogo.png';
import newOneloginLogo from '../images/sections/login/oneLoginLogo.png';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import GoogleLoginOld from 'react-google-login';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {BUTTON_COLOR, BUTTON_STYLE, LOGIN_TYPE, MODULE_TYPES} from '../constants';
import {isInValidEmailFormat} from '../forecast-app/shared/util/EmailUtil';
import {XeroIcon} from 'web-components';
import {getPathNameAndSearch, isSageSpecificUrl} from '../forecast-app/shared/util/UrlUtil';
import {PERMISSION_TYPE} from '../Permissions';
import CreateSisenseJwtMutation from '../mutations/CreateSisenseJwtMutation';
import CreateZendeskJwtMutation from '../mutations/CreateZendeskJwtMutation';
import CheckSisenseUsertypeMutation from '../mutations/CheckSisenseUsertypeMutation';
import CreateSisenseUserMutation from '../mutations/CreateSisenseUserMutation';
import {personHasPermission} from '../forecast-app/shared/util/PermissionsUtil';
import Button from '../forecast-app/shared/components/buttons/button/button';
import {hasModule, setEnabledModules} from '../forecast-app/shared/util/ModuleUtil';
import DirectApi from '../directApi';
import {DatacenterDropdown} from './DatacenterDropdown';
import {showModal, MODAL_TYPE} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import {CreateUserErrorMessage} from '../components/advanced-analytics/AdvancedAnalyticsPage';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';
import GoogleLoginButton from './google-login-button';
import {setAvailableFeatureFlags} from '../forecast-app/shared/util/FeatureUtil';
import {SageLogin} from './sage-signup-flow/SageLogin';

class login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			verifying: false,
			loginMethod: LOGIN_TYPE.MAIL,
			sisenseLoginStarted: false,
			zendeskLoginStarted: false,
			datacenter: DirectApi.getDatacenter(),
			email: '',
			password: '',
		};
		this.props.setLocaleFromBrowser();

		this.superPropertyChecksum = trackPage('Login');
		this.newGoogleGSI = localStorage.getItem('new_google_gsi') !== 'false';
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.downForMaintenance) {
			this.props.history.push({
				pathname: '/maintenance',
			});
			return;
		}

		if (this.props.viewer.actualPersonId !== null) {
			this.updateModules();
			// Is this a Sisense SSO login?
			if (this.props.sisenseLogin && hasModule(MODULE_TYPES.SISENSE)) {
				this.checkSisenseUser();
			} else if (this.props.zendeskLogin) {
				this.sendToZendesk();
			} else {
				const pathname = '/';
				this.props.history.push({
					pathname: pathname,
				});
			}
		}
	}

	componentDidMount() {
		// Segment
		document.title = this.props.intl.formatMessage({id: 'login.site_title'});
		tracking.trackPage('login');
		this.refs.email?.focus();
	}

	componentDidUpdate() {
		// Did the user login?
		if (this.props.viewer.actualPersonId !== null) {
			this.updateModules();
			setAvailableFeatureFlags(this.props.viewer.availableFeatureFlags);
			// Is this a Sisense SSO login?
			if (this.props.sisenseLogin && hasModule(MODULE_TYPES.SISENSE)) {
				this.checkSisenseUser();
			} else if (this.props.zendeskLogin) {
				this.sendToZendesk();
			} else {
				const {pathname, search} = getPathNameAndSearch();
				// Check necessary to prevent infinite update
				if (!this.props.location.state || this.props.location.state.nextPathname !== pathname) {
					// Redirect user to correct path
					this.props.history.push({
						pathname: pathname,
						search: search,
						state: {nextPathname: this.props.location.pathname},
					});
				}
			}
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	updateModules() {
		setEnabledModules(this.props.viewer.company.modules);
	}

	checkSisenseUser() {
		if (!this.state.sisenseLoginStarted) {
			this.setState({sisenseLoginStarted: true});
			if (this.props.viewer.sisenseUserId !== null) {
				this.sendToSisense();
			} else {
				// Attempt to create a sisense user.
				const input = {
					email: this.props.viewer.email,
					firstName: this.props.viewer.firstName,
					lastName: this.props.viewer.lastName,
					type: personHasPermission(PERMISSION_TYPE.SISENSE_CREATE, this.props.viewer) ? 'designer' : 'viewer',
				};
				Util.CommitMutation(
					CreateSisenseUserMutation,
					input,
					response => {
						if (response?.createSisenseUser?.errors?.length) {
							showModal({
								type: MODAL_TYPE.WARNING,
								warningMessageId: 'common.error_colon',
								warningInformation: [CreateUserErrorMessage(response.createSisenseUser.errors)],
								useInfoIcon: true,
								buttons: [
									{
										text: this.props.intl.formatMessage({id: 'common.ok'}),
										style: BUTTON_STYLE.FILLED,
										color: BUTTON_COLOR.WHITE,
									},
								],
							});
							const pathname = '/';
							this.props.history.push({
								pathname: pathname,
							});
						} else {
							this.sendToSisense();
						}
					},
					false,
					null
				);
			}
		}
	}

	sendToSisense() {
		// First we check if the user's permission in Sisense matched the expected permission level
		const expectedPermission = personHasPermission(PERMISSION_TYPE.SISENSE_CREATE, this.props.viewer)
			? 'designer'
			: 'viewer';
		Util.CommitMutation(CheckSisenseUsertypeMutation, {expectedPermission, csrfToken: this.props.viewer.csrfToken}, () => {
			// Generate JWT and send to Sisense.
			const onJwtSuccess = response => {
				const encodedToken = response.createSisenseJwt.jwt;
				let redirectUrl = `https://${
					process.env.CIRCLE_BRANCH === 'production' ? 'forecast' : 'forecast-dev'
				}.sisense.com/jwt?jwt=${encodedToken}`;
				const urlParams = new URLSearchParams(this.props.location.search);
				if (urlParams.has('return_to')) {
					redirectUrl += `&return_to=${encodeURIComponent(urlParams.get('return_to'))}${window.location.hash || ''}`;
				}
				// Redirect back to Sisense
				window.location.href = redirectUrl;
			};
			Util.CommitMutation(CreateSisenseJwtMutation, {csrfToken: this.props.viewer.csrfToken}, onJwtSuccess);
		});
	}

	sendToZendesk() {
		const onJwtSuccess = response => {
			// Redirect back to Zendesk
			console.log('response', response);
			const encodedToken = response.createZendeskJwt.jwt;
			const redirectUrl = `https://forecastapp.zendesk.com/access/jwt?jwt=${encodedToken}`;
			console.log('redirectUrl', redirectUrl);
			window.location.href = redirectUrl;
		};
		Util.CommitMutation(CreateZendeskJwtMutation, {csrfToken: this.props.viewer.csrfToken}, onJwtSuccess);
	}

	updateLoginMethod(method) {
		this.setState({
			errorMsg: '',
			loginMethod: method,
		});
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		const email = this.refs.email?.value?.trim() || this.state.email;
		const password =
			this.state.loginMethod === LOGIN_TYPE.MAIL ? this.refs.password?.value?.trim() || this.state.password : '';

		// Validate password for sage login component to show both error messages simultaneously
		if (!password?.length) {
			this.setState({
				passwordError: 'Please enter a password.',
			});
		} else {
			this.setState({
				passwordError: '',
			});
		}

		// Validate email
		if (!isInValidEmailFormat(email)) {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-invalid'})});
			return;
		}

		if (this.state.loginMethod === LOGIN_TYPE.MAIL && (!email.length || !password.length)) {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.all-fields-must-be-filled'})});
			return;
		}

		const onMailLoginSuccess = ({login}) => {
			if (login.errors && login.errors.length > 0) {
				if (login.errors[0] === 'NO_LOGIN') {
					this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.virtual_user'}), verifying: false});
				} else if (login.errors[0] === 'EMAIL_LOCKED') {
					this.setState({
						errorMsg: this.props.intl.formatMessage({id: 'login.multiple_failed_attempts'}),
						verifying: false,
					});
				} else if (login.errors[0] === 'RATE_LIMIT') {
					this.setState({
						errorMsg: this.props.intl.formatMessage({id: 'login.rate_limit'}),
						verifying: false,
					});
				}
			} else if (!this.props.viewer.actualPersonId) {
				this.setState({
					errorMsg: this.props.intl.formatMessage({id: 'login.wrong_information'}),
					verifying: false,
				});
			}
		};

		const onOktaLoginSuccess = ({createOktaVerificationKey}) => {
			if (createOktaVerificationKey.key === null) {
				this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
				return;
			}
			const redirectUri = process.env.OKTA_REDIRECT_URI;
			window.location.href = `https://${createOktaVerificationKey.oktaUrl}.okta.com/oauth2/v1/authorize?client_id=${createOktaVerificationKey.oktaClientId}&response_type=code&scope=openid email profile&redirect_uri=${redirectUri}&state=${createOktaVerificationKey.key}`;
		};

		const onOneLoginSuccess = ({createOneloginOauthKey}) => {
			if (createOneloginOauthKey.key === null) {
				this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
				return;
			}

			window.location.href = `https://${createOneloginOauthKey.oneloginUrl}.onelogin.com/oidc/2/auth?client_id=${createOneloginOauthKey.oneloginClientId}&redirect_uri=${process.env.ONELOGIN_REDIRECT_URI}&response_type=code&scope=openid&state=${createOneloginOauthKey.key}`;
		};

		const onAzureADSuccess = ({createAADOauthKey}) => {
			if (createAADOauthKey.key === null) {
				this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
				return;
			}

			window.location.href = `https://login.microsoftonline.com/${createAADOauthKey.AADTenant}.onmicrosoft.com/oauth2/v2.0/authorize?client_id=${createAADOauthKey.AADClientId}&response_type=code&redirect_uri=${process.env.AAD_REDIRECT_URI}&response_mode=form_post&scope=openid email&state=${createAADOauthKey.key}`;
		};

		this.setState({verifying: true});

		switch (this.state.loginMethod) {
			case LOGIN_TYPE.MAIL:
				Util.CommitMutation(
					LoginMutation,
					{
						email: email,
						password: password,
						rememberMe: true,
					},
					onMailLoginSuccess
				);
				break;
			case LOGIN_TYPE.OKTA:
				Util.CommitMutation(
					CreateOktaVerificationKeyMutation,
					{
						email: email,
					},
					onOktaLoginSuccess
				);
				break;
			case LOGIN_TYPE.ONELOGIN:
				Util.CommitMutation(
					CreateOneloginOauthKeyMutation,
					{
						email: email,
					},
					onOneLoginSuccess
				);
				break;
			case LOGIN_TYPE.AZURE:
				Util.CommitMutation(
					CreateAADOauthKeyMutation,
					{
						email: email,
					},
					onAzureADSuccess
				);
				break;
			default:
				return null;
		}
	}

	googleOauth(e) {
		const onSuccess = ({googleLogin}) => {
			if (googleLogin.errors && googleLogin.errors.length > 0) {
				const err = googleLogin.errors[0];

				switch (err) {
					case 'INVALID_TOKEN':
						this.setState({
							errorMsg: this.props.intl.formatMessage({id: 'login.google.failure'}),
							verifying: false,
						});
						break;
					case 'INVALID_EMAIL':
						this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.no_such_user'}), verifying: false});
						break;
					case 'INACTIVE_USER':
						this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.inactive_user'}), verifying: false});
						break;
					case 'NO_LOGIN':
						this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.virtual_user'}), verifying: false});
						break;
					case 'SSO_MANDATORY':
						this.setState({
							errorMsg: this.props.intl.formatMessage({id: 'login.sso_mandatory'}),
							verifying: false,
						});
						break;
					default:
						// Can only happen if backend returns unknown error message, due to it not being added in both places.
						break;
				}
			}
		};

		this.setState({verifying: true});

		let loginInput = {};
		if (this.newGoogleGSI) {
			loginInput.accessToken = e.access_token;
		} else {
			loginInput.token = e.getAuthResponse().id_token;
		}

		Util.CommitMutation(GoogleLoginMutation, loginInput, onSuccess);
	}

	xeroOauth() {
		window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.XERO_INTEGRATION_APP_CLIENT_ID}&redirect_uri=${process.env.XERO_SSO_REDIRECT_URI}&scope=openid email`;
	}

	googleOauthFailureHandler() {
		if (this.state.loginMethod === LOGIN_TYPE.GOOGLE) {
			this.setState({
				verifying: false,
				errorMsg: this.props.intl.formatMessage({id: 'login.google.failure'}),
				loginMethod: LOGIN_TYPE.MAIL,
			});
		}
	}

	setDatacenter(dc) {
		this.setState({datacenter: dc});
	}

	onEmailChange(email) {
		this.setState({
			email: email,
		});
		if (!isInValidEmailFormat(email)) {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'signup.email-is-invalid'})});
		} else {
			this.setState({errorMsg: ''});
		}
	}

	onPasswordChange(password) {
		this.setState({
			password: password,
		});
		if (!password?.length) {
			this.setState({
				passwordError: 'Please enter a password.',
			});
		} else {
			this.setState({
				passwordError: '',
			});
		}
	}
	render() {
		if (this.props.sisenseLogin && this.props.viewer.actualPersonId) {
			return null;
		}
		if (this.props.zendeskLogin && this.props.viewer.actualPersonId) {
			return null;
		}
		const {formatMessage} = this.props.intl;

		if (isSageSpecificUrl()) {
			return (
				<SageLogin
					email={this.state.email}
					onEmailChange={this.onEmailChange.bind(this)}
					password={this.state.password}
					passwordError={this.state.passwordError}
					onPasswordChange={this.onPasswordChange.bind(this)}
					errorMessage={this.state.errorMsg}
					onLogin={this.handleSubmit.bind(this)}
				/>
			);
		} else {
			return (
				<div className="content">
					{this.state.verifying ? <UploadingOverlay /> : null}

					<form name="login-form">
						<h1
							style={{
								marginBottom: '24px',
							}}
						>
							{formatMessage({id: 'login.title'})}
						</h1>

						<div>
							<label htmlFor="email">{formatMessage({id: 'common.email'})}</label>
							<input
								className="e-mail"
								type="email"
								name="username"
								ref="email"
								placeholder={formatMessage({id: 'login.enter-email-address'})}
							/>
							{this.state.loginMethod === LOGIN_TYPE.MAIL ? (
								<>
									<label htmlFor="password">{formatMessage({id: 'common.password'})}</label>
									<input
										className="password"
										type="password"
										name="password"
										ref="password"
										defaultValue=""
										placeholder={formatMessage({id: 'login.enter-password'})}
									/>
								</>
							) : (
								''
							)}
							{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
						</div>

						<div className="buttons">
							<DatacenterDropdown
								datacenter={this.state.datacenter}
								setDatacenter={this.setDatacenter.bind(this)}
							/>
							<Link to="/forgot-password">
								<FormattedMessage id="login.forgot-password" />
							</Link>
						</div>
						<div className="buttons signin-button">
							<Button
								id="login-button"
								text={formatMessage({id: 'login.sign-in'})}
								cy="login-button"
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={BUTTON_COLOR.MODERN_BLUE}
								onClick={this.handleSubmit.bind(this)}
								uppercase={false}
								roundedBorders={true}
								symbolClass="arrow-right"
								symbolSize={18}
								placeSymbolLast
							/>
						</div>
					</form>

					<div className="signup-options-container">
						<span className="signup-options-title">{formatMessage({id: 'login.or-sign-in-using'})}</span>

						<div className="signup-options">
							<button
								className={
									'signup-option ' +
									(this.state.loginMethod === LOGIN_TYPE.MAIL ? 'signup-option-active' : '')
								}
								onClick={this.updateLoginMethod.bind(this, LOGIN_TYPE.MAIL)}
							>
								{formatMessage({id: 'common.email'})}
							</button>

							{this.newGoogleGSI ? (
								<GoogleOAuthProvider clientId="722419082843-j59h7vind9sucus9rv4ug9npk97rqffl.apps.googleusercontent.com">
									<GoogleLoginButton
										className={
											'signup-option ' +
											(this.state.loginMethod === LOGIN_TYPE.GOOGLE ? 'signup-option-active' : '')
										}
										title={'Login with Google'}
										onSuccess={response => this.googleOauth(response)}
										onFailure={() => this.googleOauthFailureHandler()}
									>
										<img
											src={newGoogleLogo}
											className="new-google-button"
											alt="google logo"
											title={'Login with Google'}
										/>
									</GoogleLoginButton>
								</GoogleOAuthProvider>
							) : (
								<GoogleLoginOld
									clientId={'722419082843-j59h7vind9sucus9rv4ug9npk97rqffl.apps.googleusercontent.com'}
									onSuccess={e => this.googleOauth(e)}
									onFailure={() => this.googleOauthFailureHandler()}
									responseType={'id_token'}
									prompt={'select_account'}
									scope={'email profile'}
									className={
										'signup-option ' +
										(this.state.loginMethod === LOGIN_TYPE.GOOGLE ? 'signup-option-active' : '')
									}
									onClick={this.updateLoginMethod.bind(this, LOGIN_TYPE.GOOGLE)}
								>
									<img
										src={newGoogleLogo}
										className="new-google-button"
										alt="google logo"
										title={'Login with Google'}
									/>
								</GoogleLoginOld>
							)}

							<button
								className={
									'signup-option ' +
									(this.state.loginMethod === LOGIN_TYPE.ONELOGIN ? 'signup-option-active' : '')
								}
								onClick={this.updateLoginMethod.bind(this, LOGIN_TYPE.ONELOGIN)}
								title={'Login with OneLogin'}
							>
								<img id="oneLogin" src={newOneloginLogo} className="new-onelogin-logo" alt="onelogin logo" />
							</button>
							<button
								className={
									'signup-option ' +
									(this.state.loginMethod === LOGIN_TYPE.OKTA ? 'signup-option-active' : '')
								}
								onClick={this.updateLoginMethod.bind(this, LOGIN_TYPE.OKTA)}
								title={'Login with Okta'}
							>
								<img src={oktaLogo} className="okta-logo" alt="okta logo" />
							</button>
							<button
								className={
									'signup-option ' +
									(this.state.loginMethod === LOGIN_TYPE.AZURE ? 'signup-option-active' : '')
								}
								onClick={this.updateLoginMethod.bind(this, LOGIN_TYPE.AZURE)}
								title={'Login with Microsoft Entra ID'}
							>
								<img
									src={microsoftEntraLogo}
									className="microsoft-entra-id-logo"
									alt="microsoft entra id logo"
								/>
							</button>
							<button className={'signup-option'} onClick={() => this.xeroOauth()} title={'Login with Xero'}>
								<div className="xero-label">
									<XeroIcon />
								</div>
							</button>
						</div>
					</div>
				</div>
			);
		}
	}
}

const loginQuery = graphql`
	query login_Query {
		viewer {
			actualPersonId
			component(name: "login")
			...login_viewer
		}
	}
`;

export {loginQuery};

export default createFragmentContainer(injectIntl(withRouter(login)), {
	viewer: graphql`
		fragment login_viewer on Viewer {
			id
			email
			downForMaintenance
			actualPersonId
			firstName
			lastName
			csrfToken
			permissions
			sisenseUserId
			availableFeatureFlags {
				key
				name
				color
			}
			company {
				modules {
					moduleType
				}
			}
		}
	`,
});
