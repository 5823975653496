import {createFragmentContainer, graphql} from 'react-relay';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import React, {useReducer, useState} from 'react';
import {Dropdown, HexagonText, InputLabel} from 'web-components';
import {BUTTON_COLOR} from '../../../constants';
import Styled from 'styled-components';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {
	applyDistributionArray,
	filterPersons,
	getAllocationTotal,
	getAverageWorkingMinutes,
	getDistributionArray,
	getPercentage,
	getTotal,
	handleAllocationTotalChange,
	handleMutationSuccess,
	sumWeeklyMinutes,
	transferPlaceholderMutation,
} from './PlaceholderAllocationUtils';
import Placeholder from '../../../forecast-app/shared/components/placeholder/placeholder';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import Input from '../../../components/new-ui/input_field';
import Util from '../../../forecast-app/shared/util/util';
import {generateStaffingId} from '../../../components/canvas-scheduling/placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {profilePicSrc} from '../../../directApi';

export const PlaceholderModalContent = Styled.div`
	height: 300px;
`;

const PlaceholderSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	border-bottom: 1px solid #e7e7f3;
	padding-bottom: 27px;
	.placeholder-info {
		padding-right: 8px;
		flex: 3;
		align-self: flex-start;
	}
	.hours-input {
		padding-right: 8px;
		flex: 1;
	}
	.input-container-v2 {
		padding-right: 8px;
		flex: 1;
	}
`;

const PersonSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: start;
	padding-bottom: 27px;
	.dropdown-container {
		display: flex;
		flex-direction: column;
		padding-right: 8px;
		flex: 3;
	}
	.hours-input {
		padding-right: 8px;
		flex: 1;
	}
	.input-container-v2 {
		padding-right: 8px;
		flex: 1;
	}
`;

const TransferToPersonModal = ({closeModal, viewer, staffingModeActive, placeholderInput, placeholderAllocationInput}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {company} = viewer;
	let {placeholder, placeholderAllocation} = company;

	if (staffingModeActive) {
		placeholder = placeholderInput;
		placeholderAllocation = placeholderAllocationInput;
	}

	const startDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.startDate);
	const endDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.endDate);
	const [forceUpdate, forceUpdateHourInput] = useReducer(x => x + 1, 0);

	const skills = placeholder.skills.edges.map(skill => skill.node);
	const placeholderSkillIds = skills ? skills.map(skill => skill.id) : [];
	let persons = company.allPersons.edges;
	const matchedPersons = filterPersons(persons, placeholder.role?.id, placeholderSkillIds);
	const matchedPersonIds = matchedPersons.map(matchedPerson => matchedPerson.node.id);
	persons = persons.filter(person => !matchedPersonIds.includes(person.node.id));

	const companyWeeklyMinutes = sumWeeklyMinutes(company);

	const companyDistributionArray = getDistributionArray(company);
	const originalPlaceholderDistributionArray = getDistributionArray(placeholderAllocation);

	const personAllocation = applyDistributionArray(
		{
			id: staffingModeActive ? generateStaffingId() : undefined,
			placeholderId: staffingModeActive ? placeholder.id : undefined,
			projectId: placeholder.project?.id || placeholder.projectId,
			projectGroupId: placeholder.projectGroupId,
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			endYear: endDate.year(),
			endMonth: endDate.month() + 1,
			endDay: endDate.date(),
			description: placeholderAllocation.description,
		},
		originalPlaceholderDistributionArray
	);

	const hoursPerDay = Util.getHourEstimate(getAverageWorkingMinutes(companyDistributionArray, companyWeeklyMinutes) / 60);

	const originalTotal = getAllocationTotal(placeholderAllocation);

	const [placeholderDistributionArray, setPlaceholderDistributionArray] = useState([0, 0, 0, 0, 0, 0, 0]);
	const [placeholderDistributionPercentage, setPlaceholderDistributionPercentage] = useState(
		getPercentage(placeholderDistributionArray, companyWeeklyMinutes)
	);

	const [personDistributionArray, setPersonDistributionArray] = useState(getDistributionArray(personAllocation));
	const [personDistributionPercentage, setPersonDistributionPercentage] = useState(
		getPercentage(personDistributionArray, companyWeeklyMinutes)
	);

	const [placeholderTotal, setPlaceholderTotal] = useState(getTotal(placeholderDistributionArray, startDate, endDate));
	const [personTotal, setPersonTotal] = useState(getAllocationTotal(personAllocation));
	const [selectedPersonIds, setSelectedPersonIds] = useState([]);
	const [selectedPersonName, setSelectedPersonName] = useState('');

	const onPersonChange = personId => {
		setSelectedPersonIds(personId);

		const unfilteredPersons = company.allPersons.edges;
		const person = unfilteredPersons.find(person => person.node.id === personId[0]);
		if (person?.node) {
			setSelectedPersonName(person.node.fullName);
		}
	};

	const onPersonTotalChange = value => {
		value *= 60;
		if (value !== personTotal) {
			const personResult = handleAllocationTotalChange(
				value,
				originalPlaceholderDistributionArray,
				companyWeeklyMinutes,
				startDate,
				endDate
			);
			setPersonTotal(personResult.newTotal);
			setPersonDistributionPercentage(personResult.newDistributionPercentage);
			setPersonDistributionArray(personResult.newDistributionArray);

			const placeholderResult = handleAllocationTotalChange(
				originalTotal - personResult.newTotal,
				originalPlaceholderDistributionArray,
				companyWeeklyMinutes,
				startDate,
				endDate
			);
			setPlaceholderTotal(placeholderResult.newTotal);
			setPlaceholderDistributionPercentage(placeholderResult.newDistributionPercentage);
			setPlaceholderDistributionArray(placeholderResult.newDistributionArray);
			forceUpdateHourInput();
		}
	};

	const onPersonPercentChange = percentage => {
		const newDistributionArray = companyDistributionArray.map(minutes => {
			return Math.floor((minutes * percentage) / 100);
		});
		const total = getTotal(newDistributionArray, startDate, endDate);

		setPersonTotal(total);
		setPersonDistributionArray(newDistributionArray);
		setPersonDistributionPercentage(percentage);

		const placeholderResult = handleAllocationTotalChange(
			originalTotal - total,
			originalPlaceholderDistributionArray,
			companyWeeklyMinutes,
			startDate,
			endDate
		);
		setPlaceholderTotal(placeholderResult.newTotal);
		setPlaceholderDistributionPercentage(placeholderResult.newDistributionPercentage);
		setPlaceholderDistributionArray(placeholderResult.newDistributionArray);
	};

	const constructTransferResponse = tempPlaceholderAllocation => {
		const response = {
			transferPlaceholder: {},
		};

		if (personTotal) {
			response.transferPlaceholder['personAllocation'] = {...personAllocation};
		}
		if (placeholderTotal) {
			response.transferPlaceholder['placeholderAllocation'] = {...tempPlaceholderAllocation};
		} else {
			response.transferPlaceholder['deletedPlaceholderAllocationId'] = tempPlaceholderAllocation.id;
		}

		return response;
	};

	const transferToPerson = () => {
		const tempPlaceholderAllocation = {...placeholderAllocation};
		applyDistributionArray(personAllocation, personDistributionArray);
		personAllocation.personId = selectedPersonIds[0];
		applyDistributionArray(tempPlaceholderAllocation, placeholderDistributionArray);
		tempPlaceholderAllocation.placeholderId = placeholder.id;

		if (staffingModeActive) {
			handleMutationSuccess(constructTransferResponse(tempPlaceholderAllocation));
		} else {
			transferPlaceholderMutation(
				formatMessage,
				tempPlaceholderAllocation,
				personAllocation,
				selectedPersonName,
				handleMutationSuccess
			);
		}
	};

	const content = (
		<PlaceholderModalContent>
			<ModalBody>
				<Field>
					<PlaceholderSection>
						<Placeholder className={'placeholder-info'} placeholder={placeholder} cy={'placeholder-info'} />
						<HoursInput
							id="placeholder-allocation-modal-input"
							customClassName={'hours-input'}
							placeholder="0"
							label={formatMessage({id: 'scheduling.allocation_remaining'})}
							value={placeholderTotal / 60 || ''}
							forceUpdate={forceUpdate}
							modalView={true}
							disabled={true}
						/>
						<Input
							id="placeholder-allocation-modal-percentage"
							placeholder="0"
							type="number"
							label={`% ${formatMessage({id: 'scheduling.allocation_modal.hours_pr_day'}, {hours: hoursPerDay})}`}
							suffix={'%'}
							value={
								placeholderDistributionPercentage
									? Math.round(placeholderDistributionPercentage * 100) / 100
									: ''
							}
							alignTextRight
							locked={true}
						/>
					</PlaceholderSection>
				</Field>
				<Field>
					<PersonSection>
						<div className={'dropdown-container'}>
							<InputLabel
								text={formatMessage({id: 'common.select_person_placeholder'})}
								child={
									<Dropdown
										selectedItems={selectedPersonIds}
										name={formatMessage({id: 'common.select_person_placeholder'})}
										maxHeight={200}
										size={'large'}
										onSelect={onPersonChange}
										isNested
									>
										<Dropdown.Group
											name={formatMessage({id: 'placeholder.modal.matched'})}
											key={`matched-key`}
										>
											{matchedPersons.map(person => {
												const {id, firstName, lastName, profilePictureId, role} = person.node;
												return (
													<HexagonText
														key={id}
														value={id}
														searchString={`${firstName} ${lastName}`}
														text={`${firstName} ${lastName}`}
														subText={role ? role.name : ' '}
														imgUrl={profilePicSrc(profilePictureId)}
													/>
												);
											})}
										</Dropdown.Group>
										<Dropdown.Group name={formatMessage({id: 'common.people'})} key={`people-key`}>
											{persons.map(person => {
												const {id, firstName, lastName, profilePictureId, role} = person.node;
												return (
													<HexagonText
														key={id}
														value={id}
														searchString={`${firstName} ${lastName}`}
														text={`${firstName} ${lastName}`}
														subText={role ? role.name : ' '}
														imgUrl={profilePicSrc(profilePictureId)}
													/>
												);
											})}
										</Dropdown.Group>
									</Dropdown>
								}
							/>
						</div>
						<HoursInput
							id="placeholder-allocation-modal-input"
							customClassName={'hours-input'}
							placeholder="0"
							label={formatMessage({id: 'scheduling.allocation_remaining'})}
							value={personTotal / 60 || ''}
							forceUpdate={forceUpdate}
							minuteLimit={originalTotal}
							mutation={onPersonTotalChange}
							mutationValidChange
							modalView={true}
						/>
						<Input
							id="placeholder-allocation-modal-percentage"
							placeholder="0"
							type="number"
							label={`% ${formatMessage({id: 'scheduling.allocation_modal.hours_pr_day'}, {hours: hoursPerDay})}`}
							suffix={'%'}
							alignTextRight
							value={personDistributionPercentage ? Math.round(personDistributionPercentage * 100) / 100 : ''}
							onChange={onPersonPercentChange}
						/>
					</PersonSection>
				</Field>
			</ModalBody>
		</PlaceholderModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.MEDIUM}
			headerText={formatMessage(
				{id: 'placeholder.transfer_to_person.header'},
				{allocation: Util.convertMinutesToFullHour(originalTotal, intl)}
			)}
			buttons={[
				{text: formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
				{
					text: formatMessage({id: 'placeholder.transfer_to_person.transfer'}),
					disabled: !selectedPersonIds.length || personTotal > originalTotal,
					callback: transferToPerson,
					color: BUTTON_COLOR.GREEN,
				},
			]}
		/>
	);
};

const transferToPersonModalQuery = graphql`
	query TransferToPersonModal_Query(
		$placeholderId: ID
		$placeholderAllocationId: ID
		$loadPlaceholder: Boolean!
		$loadAllocation: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "transfer_to_person_modal")
			...TransferToPersonModal_viewer
				@arguments(
					placeholderId: $placeholderId
					placeholderAllocationId: $placeholderAllocationId
					loadPlaceholder: $loadPlaceholder
					loadAllocation: $loadAllocation
				)
		}
	}
`;

export {transferToPersonModalQuery};

export default createFragmentContainer(TransferToPersonModal, {
	viewer: graphql`
		fragment TransferToPersonModal_viewer on Viewer
		@argumentDefinitions(
			placeholderId: {type: "ID"}
			placeholderAllocationId: {type: "ID"}
			loadPlaceholder: {type: "Boolean!"}
			loadAllocation: {type: "Boolean!"}
		) {
			id
			email
			company {
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				placeholderAllocation(placeholderAllocationId: $placeholderAllocationId) @include(if: $loadAllocation) {
					id
					startDate
					endDate
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					description
				}
				placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
					id
					name
					project {
						id
					}
					projectGroupId
					role {
						id
						name
					}
					skills {
						edges {
							node {
								id
							}
						}
					}
				}
				allPersons(first: 1000000, onlyActive: true, excludeClientUsers: true) {
					edges {
						node {
							id
							active
							firstName
							lastName
							fullName
							profilePictureId
							role {
								id
								name
							}
							skills {
								edges {
									node {
										id
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
