/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type addProjectGroupModal_viewer$ref = any;
export type addProjectGroupModal_QueryVariables = {||};
export type addProjectGroupModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: addProjectGroupModal_viewer$ref,
  |}
|};
export type addProjectGroupModal_Query = {|
  variables: addProjectGroupModal_QueryVariables,
  response: addProjectGroupModal_QueryResponse,
|};
*/


/*
query addProjectGroupModal_Query {
  viewer {
    actualPersonId
    component(name: "add_project_group")
    ...addProjectGroupModal_viewer
    id
  }
}

fragment addProjectGroupModal_viewer on Viewer {
  id
  projects(first: 10000, excludeDoneOrHalted: true, excludeGroupProjects: true) {
    edges {
      node {
        id
        name
        isJiraProject
        isInProjectGroup
        fullAccessToProject
      }
    }
  }
  psProjects(first: 10000) {
    edges {
      node {
        id
        serviceCompanyId
        program
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "add_project_group"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"add_project_group\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "addProjectGroupModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "addProjectGroupModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "addProjectGroupModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "excludeDoneOrHalted",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "excludeGroupProjects",
                "value": true
              },
              (v3/*: any*/)
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isJiraProject",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isInProjectGroup",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullAccessToProject",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(excludeDoneOrHalted:true,excludeGroupProjects:true,first:10000)"
          },
          {
            "alias": null,
            "args": [
              (v3/*: any*/)
            ],
            "concreteType": "ProjectServiceProjectTypeConnection",
            "kind": "LinkedField",
            "name": "psProjects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serviceCompanyId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "program",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "psProjects(first:10000)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addProjectGroupModal_Query",
    "operationKind": "query",
    "text": "query addProjectGroupModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"add_project_group\")\n    ...addProjectGroupModal_viewer\n    id\n  }\n}\n\nfragment addProjectGroupModal_viewer on Viewer {\n  id\n  projects(first: 10000, excludeDoneOrHalted: true, excludeGroupProjects: true) {\n    edges {\n      node {\n        id\n        name\n        isJiraProject\n        isInProjectGroup\n        fullAccessToProject\n      }\n    }\n  }\n  psProjects(first: 10000) {\n    edges {\n      node {\n        id\n        serviceCompanyId\n        program\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c996dc84fc66101ac9909ebaee96b8e';

module.exports = node;
