import React, {useEffect, useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import UpdateError from '../../mutations/mission_control/update_error_mutation';
import TextAreaFoldout from '../../forecast-app/shared/components/inputs/textarea_foldout';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {Scrollbars} from 'react-custom-scrollbars';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../forecast-app/shared/util/util';
import {BasicTextInput, Button} from 'web-components';
import Styled from 'styled-components';
import EmptyState, {EMPTY_STATE} from '../../forecast-app/shared/components/empty-states/empty_state';
import OverlayLoader from '../../components/loaders/overlay_loader';

const Toolbar = Styled.div`
	display: flex; 
	gap: 5px;
	margin: 20px 30px;
`;

const bugDuty = ({relay, history, viewer}) => {
	const allTabs = {
		FAB_FEW: 'Work In Progress',
		LAST_CALL: 'Hold your resources',
		MIND_THE_GAAP: 'Mind The GAAP',
		GEEK_SQUAD: 'Integrations',
		AI_TEAM: 'Ai Team',
		CRISTINA: 'Cristina is Cool',
		MOBILE: 'Mobile',
		UNKNOWN: 'Unknown',
		CLOSED: 'Closed',
		ARCHITECTURE: 'Architecture',
	};

	const [filter, setFilter] = useState();
	const [filterApplied, setFilterApplied] = useState();
	const [bulkNote, setBulkNote] = useState();
	const [selectedTab, setSelectedTab] = useState();
	const [fadeTask, setFadeTask] = useState();
	const [refetching, setRefetching] = useState();

	function getTeam() {
		let team = null;
		if (selectedTab === allTabs.GEEK_SQUAD) {
			team = 'GEEK_SQUAD';
		} else if (selectedTab === allTabs.FAB_FEW) {
			team = 'FAB_FEW';
		} else if (selectedTab === allTabs.LAST_CALL) {
			team = 'LAST_CALL';
		} else if (selectedTab === allTabs.MIND_THE_GAAP) {
			team = 'MIND_THE_GAAP';
		} else if (selectedTab === allTabs.AI_TEAM) {
			team = 'AI_TEAM';
		} else if (selectedTab === allTabs.ARCHITECTURE) {
			team = 'ARCHITECTURE';
		} else if (selectedTab === allTabs.CRISTINA) {
			team = 'CRISTINA';
		} else if (selectedTab === allTabs.MOBILE) {
			team = 'MOBILE';
		} else if (selectedTab === allTabs.CLOSED) {
			team = 'CLOSED';
		}
		return team;
	}

	let team = getTeam();

	const errors = viewer.missionControlData.errorList.filter(e => {
		if (team === 'CLOSED') {
			return e.fixed === true;
		} else {
			return e.team === team && e.fixed === false;
		}
	});

	const getSearchString = hash => {
		let searchString = '';
		if (hash === '#geek_squad') {
			setSelectedTab(allTabs.GEEK_SQUAD);
			searchString = 'GEEK_SQUAD';
		} else if (hash === '#the_fabulous_few') {
			setSelectedTab(allTabs.FAB_FEW);
			searchString = 'FAB_FEW';
		} else if (hash === '#last_call_legacy') {
			setSelectedTab(allTabs.LAST_CALL);
			searchString = 'LAST_CALL';
		} else if (hash === '#mind_the_gaap') {
			setSelectedTab(allTabs.MIND_THE_GAAP);
			searchString = 'MIND_THE_GAAP';
		} else if (hash === '#ai_team') {
			setSelectedTab(allTabs.AI_TEAM);
			searchString = 'AI_TEAM';
		} else if (hash === '#architecture') {
			setSelectedTab(allTabs.ARCHITECTURE);
			searchString = 'ARCHITECTURE';
		} else if (hash === '#cristina_is_cool') {
			setSelectedTab(allTabs.CRISTINA);
			searchString = 'CRISTINA';
		} else if (hash === '#mobile') {
			setSelectedTab(allTabs.MOBILE);
			searchString = 'MOBILE';
		} else if (hash === '#unknown' || hash === '') {
			setSelectedTab(allTabs.UNKNOWN);
			searchString = 'UNKNOWN';
		} else if (hash === '#closed') {
			setSelectedTab(allTabs.CLOSED);
			searchString = 'CLOSED';
		}
		return searchString;
	};

	const doRefetch = (searchString, filter) => {
		setRefetching(true);
		relay.refetch({searchString, filter: filter}, null, () => {
			setRefetching(false);
		});
	};

	const setLocation = hash => {
		doRefetch(getSearchString(hash), filter);
	};

	const onApplyFilter = () => {
		doRefetch(getSearchString(window.location.hash), filter);
		setFilterApplied(!!filter);
	};

	useEffect(() => {
		const hash = window.location.hash;
		setLocation(hash);
	}, []);

	const onBulkClose = () => {
		if (!bulkNote) {
			createToast({duration: 10000, message: 'You have to enter a note before using bulk close.'});
		} else {
			for (const error of errors) {
				Util.CommitMutation(UpdateError, {
					id: error.actualId,
					fixed: true,
					notes: bulkNote,
				});
			}
			setFilter('');
			setFilterApplied(false);
			setBulkNote('');
			doRefetch(getSearchString(window.location.hash), '');
		}
	};

	const handleFixed = e => {
		if (!e.notes) {
			createToast({duration: 10000, message: 'You have to enter a note before closing.'});
		} else {
			Util.CommitMutation(UpdateError, {
				id: e.actualId,
				fixed: !e.fixed,
			});
		}
	};

	const handleExclude = e => {
		if (e.notes === null || e.notes === undefined || e.notes === '') {
			createToast({duration: 10000, message: 'You have to enter a note before closing.'});
		} else {
			Util.CommitMutation(UpdateError, {
				id: e.actualId,
				excludeFromStats: !e.excludeFromStats,
			});
		}
	};

	const handleNotes = (newNote, error) => {
		let skip = false;

		if (!error.notes && newNote === '') {
			skip = true;
		}

		if (error.notes === newNote) {
			skip = true;
		}

		if (!skip) {
			Util.CommitMutation(UpdateError, {
				id: error.actualId,
				notes: newNote,
			});
		}
	};

	const handleTeam = (event, error) => {
		if (event.value !== error.team) {
			setFadeTask(error.id);
			Util.CommitMutation(UpdateError, {
				id: error.actualId,
				team: event.value,
			});
			setTimeout(() => {
				setFadeTask(null);
			}, 1000);
		}
	};

	const handleErrorTypeChange = (event, error) => {
		if (event.value !== error.type) {
			Util.CommitMutation(UpdateError, {
				id: error.actualId,
				knownType: event.label,
			});
		}
	};

	const changeTab = selectedTab => {
		let newHash = '';
		if (selectedTab === allTabs.GEEK_SQUAD) {
			newHash = '#geek_squad';
		} else if (selectedTab === allTabs.FAB_FEW) {
			newHash = '#the_fabulous_few';
		} else if (selectedTab === allTabs.LAST_CALL) {
			newHash = '#last_call_legacy';
		} else if (selectedTab === allTabs.MIND_THE_GAAP) {
			newHash = '#mind_the_gaap';
		} else if (selectedTab === allTabs.AI_TEAM) {
			newHash = '#ai_team';
		} else if (selectedTab === allTabs.ARCHITECTURE) {
			newHash = '#architecture';
		} else if (selectedTab === allTabs.CRISTINA) {
			newHash = '#cristina_is_cool';
		} else if (selectedTab === allTabs.MOBILE) {
			newHash = '#mobile';
		} else if (selectedTab === allTabs.UNKNOWN) {
			newHash = '#unknown';
		} else if (selectedTab === allTabs.CLOSED) {
			newHash = '#closed';
		}
		const newUrl = 'bug-duty' + newHash;
		history.replace(newUrl);
		setLocation(newHash);
	};

	const getHeader = () => {
		const visibleTabs = [
			allTabs.UNKNOWN,
			allTabs.FAB_FEW,
			allTabs.LAST_CALL,
			allTabs.MIND_THE_GAAP,
			allTabs.GEEK_SQUAD,
			allTabs.AI_TEAM,
			allTabs.ARCHITECTURE,
			allTabs.CLOSED,
		];

		return (
			<div className="bugduty-wrapper">
				<div className="tabs">
					{visibleTabs.map(tab => (
						<div
							key={tab}
							className={'tab' + (selectedTab === tab ? ' active' : '')}
							onClick={() => changeTab(tab)}
							data-cy={tab.split('_')[0]}
						>
							{tab.replace(/_/g, ' ')}
						</div>
					))}
				</div>
			</div>
		);
	};

	const getBugsList = () => {
		const allTeams = [
			{value: 'FAB_FEW', label: 'Work In Progress'},
			{value: 'LAST_CALL', label: 'Hold Your Resources'},
			{value: 'MIND_THE_GAAP', label: 'Mind The GAAP'},
			{value: 'GEEK_SQUAD', label: 'Integrations'},
			{value: 'AI_TEAM', label: 'AI Team'},
			{value: 'ARCHITECTURE', label: 'Architecture'},
		];
		const errorTypes = viewer.missionControlData.knownErrorTypes.map(et => {
			return {value: et.name, label: et.name};
		});

		return (
			<>
				<Toolbar>
					<BasicTextInput
						type="text"
						value={filter}
						label={'Filter:'}
						width={'300px'}
						placeholder={'Filter on where and notes'}
						onChange={setFilter}
						callbackOnEnter={onApplyFilter}
					/>
					<Button onClick={onApplyFilter} size={Button.SIZE.STANDARD} variant={Button.VARIANT.GREEN_FILLED}>
						Apply
					</Button>
					{filterApplied && (
						<>
							<BasicTextInput
								type="text"
								value={bulkNote}
								label={'Bulk Close:'}
								width={'300px'}
								placeholder={'Note to apply to all errors when closing'}
								onChange={setBulkNote}
							/>
							<Button onClick={onBulkClose} size={Button.SIZE.STANDARD} variant={Button.VARIANT.GREEN_FILLED}>
								Bulk Close
							</Button>
						</>
					)}
				</Toolbar>
				<Scrollbars>
					{errors && errors.length !== 0 ? (
						<table className={'mission-control-bug-duty-table'} /*ref={el => (this.table = el)}*/>
							<thead>
								<tr>
									<th className={'extra-width-60'}>Close</th>
									<th className={'extra-width-60'}>Id</th>
									<th className={'extra-width-100'}>Updated By</th>
									<th className={'extra-width-150'}>Time</th>
									<th>Who</th>
									<th className={'extra-width-150'}>Where</th>
									<th className={'extra-padding'}>Notes</th>
									<th className={'extra-width-250'}>CorrelationId</th>
									<th>Sentry</th>
									<th>LogRocket</th>
									<th className={'extra-padding'}>Team</th>
									<th className={'extra-padding'}>Known Error</th>
									<th className={'extra-width-60'}>Exclude</th>
								</tr>
							</thead>
							<tbody>
								{errors.map(e => {
									return (
										<tr
											key={e.id}
											className={fadeTask && fadeTask === e.id ? 'hidden' : `visible ${e.bugDuty}`}
										>
											<td className={'elem-checkbox'}>
												<input checked={e.fixed} type="checkbox" onChange={handleFixed.bind(this, e)} />
											</td>
											<td>
												<span className={'elem'}>{e.actualId}</span>
											</td>
											<td>
												<span className={'elem'}>{e.lastUpdatedByName}</span>
											</td>
											<td>
												<span className={'elem'}>{e.timestamp}</span>
											</td>
											<td>
												<span className={'elem'}>
													{e.companyName + ' #' + e.companyId} <br />{' '}
													{e.personName + ' #' + e.personId}{' '}
												</span>
											</td>
											<td>
												<span className={'elem'}>{e.url}</span>
											</td>
											<td className={'notes-cell'}>
												<TextAreaFoldout
													placeholder={'Notes'}
													value={e.notes}
													onBlur={event => handleNotes(event, e)}
													saveOnEnter={true}
													selectOnClick={true}
													maxChars={300}
												/>
											</td>
											<td>
												{e.correlationId && e.correlationId !== '' ? (
													<>
														<div>{e.correlationId}</div>
														<a
															className={'elem'}
															target="_blank"
															rel="noopener noreferrer"
															href={`https://app.datadoghq.eu/logs?cols=service%2C%40level&event&from_ts=${
																new Date().getTime() - 604800000
															}&index=&live=true&messageDisplay=inline&query=%40correlationId%3A${
																e.correlationId
															}&stream_sort=desc&to_ts=${new Date().getTime()}`}
														>
															Logs
														</a>
														<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
														<a
															className={'elem'}
															target="_blank"
															rel="noopener noreferrer"
															// https://app.datadoghq.eu/apm/traces?query=env%3Aproduction%20service%3Agraphql%20%40correlation_id%3Add58f0ae-4e41-4f94-9ecd-fe50783868a3&cols=service%2C%40duration%2Cresource_name%2C%40http.status_code%2Cenv%2C%40email%2C%40company_id%2C%40company_name%2C%40company_tier%2C%40_duration.by_service%2C%40db_calls%2C%40http_calls%2Ctime&historicalData=true&saved-view-id=33615&spanType=all&streamTraces=true&start=1646130308149&end=1646133908149&paused=false
															href={`https://app.datadoghq.eu/apm/traces?query=env%3Aproduction%20service%3Agraphql%20%40correlation_id%3A${
																e.correlationId
															}&cols=service%2C%40duration%2Cresource_name%2C%40http.status_code%2Cenv%2C%40email%2C%40company_id%2C%40company_name%2C%40company_tier%2C%40_duration.by_service&start=${
																new Date().getTime() - 604800000
															}end=${new Date().getTime()}&paused=true&historicalData=true`}
														>
															APM Trace
														</a>
													</>
												) : (
													'-'
												)}
											</td>
											<td>
												<a
													className={'elem'}
													target="_blank"
													rel="noopener noreferrer"
													href={
														'https://sentry.io/organizations/forecast/issues/?project=131628&query=is%3Aunresolved+user.id%3A' +
														e.personId
													}
												>
													Sentry
												</a>
												{e.sentryEventId && e.sentryEventId !== '' && (
													<>
														{' - '}
														<a
															className={'elem'}
															target="_blank"
															rel="noopener noreferrer"
															href={`https://sentry.io/organizations/forecast/issues/?query=${e.sentryEventId}`}
														>
															{'Direct Link'}
														</a>
													</>
												)}
											</td>
											<td>
												<a
													className={'elem'}
													target="_blank"
													rel="noopener noreferrer"
													href={
														'https://app.logrocket.com/forecast/forecast-nod93?filters=%5B%7B%22type%22%3A%22userID%22%2C%22operator%22%3A%7B%22name%22%3A%22is%22%2C%22type%22%3A%22IS%22%2C%22hasStrings%22%3Atrue%2C%22autocompleteEnabled%22%3Atrue%7D%2C%22strings%22%3A%5B%22' +
														e.personId +
														'%22%5D%7D%5D'
													}
												>
													Logrocket
												</a>
											</td>
											<td className="team-dropdown">
												<Dropdown
													focusOnClick={true}
													optionClickEvent={true}
													onChange={event => handleTeam(event, e)}
													options={allTeams}
													value={e.team}
													hideLabel={true}
													disabled={false}
												/>
											</td>
											<td className="team-dropdown">
												<Dropdown
													focusOnClick={true}
													optionClickEvent={true}
													onChange={event => handleErrorTypeChange(event, e)}
													options={errorTypes}
													value={e.knownType}
													hideLabel={true}
													disabled={false}
												/>
											</td>
											<td className={'elem-checkbox'}>
												<input
													checked={e.excludeFromStats}
													type="checkbox"
													onChange={handleExclude.bind(this, e)}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : refetching ? (
						<OverlayLoader></OverlayLoader>
					) : (
						<div className={'state-center'}>
							<EmptyState
								pageName={filterApplied ? EMPTY_STATE.NO_RESULT_FOUND : EMPTY_STATE.NO_ERRORS}
							></EmptyState>
						</div>
					)}
				</Scrollbars>
			</>
		);
	};

	if (!viewer.missionControlData) {
		history.push('/not-found');
		return null;
	}

	if (!viewer.missionControlData.errorList) {
		return null;
	}

	return (
		<div className="mission-control-bug-duty">
			<div className={`header-container`}>{getHeader()}</div>
			<div className={`mission-control-bug-duty-container`}>{getBugsList()}</div>
			<br />
		</div>
	);
};

const bugDutyQuery = graphql`
	query bugDuty_Query($searchString: String, $filter: String) {
		viewer {
			actualPersonId
			component(name: "control_bug_duty")
			...bugDuty_viewer @arguments(searchString: $searchString, filter: $filter)
		}
	}
`;

export {bugDutyQuery};

export default withRouter(
	createRefetchContainer(
		bugDuty,
		{
			viewer: graphql`
				fragment bugDuty_viewer on Viewer
				@argumentDefinitions(searchString: {type: "String"}, filter: {type: "String"}) {
					id
					missionControlData {
						id
						errorList(searchString: $searchString, filter: $filter) {
							id
							actualId
							timestamp
							personId
							personName
							companyId
							companyName
							correlationId
							sentryEventId
							fixed
							notes
							excludeFromStats
							lastUpdatedByName
							bugDuty
							team
							url
							knownType
						}
						knownErrorTypes {
							id
							name
							description
						}
					}
				}
			`,
		},
		graphql`
			query bugDutyRefetchQuery($searchString: String, $filter: String) {
				viewer {
					...bugDuty_viewer @arguments(searchString: $searchString, filter: $filter)
				}
			}
		`
	)
);
