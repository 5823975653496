import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import SendTasksToJiraMutationModern from '../../mutations/send_tasks_to_jira_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../components/warning';
import InputFieldV2 from '../../components/new-ui/input_field';
import DirectApi from '../../directApi';
import {Checkbox, InputLabel} from 'web-components';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import {getLocalStorageSilent, setLocalStorageSilent} from '../../forecast-app/shared/util/LocalStorageUtil';

class CreateJiraIssueModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchingIssueTypes: false,
			selectedIssueType: null,
			selectedSubtaskIssueType: null,
			selectedEpic: null,
			selectedCustomFieldValues: null,
			issueTypes: [],
			epics: [],
			syncing: false,
			syncHierarchy:
				(props.useTaskHierarchy && getLocalStorageSilent('jira-create-issue-modal.sync-hierarchy') === 'true') || false,
			firstLevelAsEpic: getLocalStorageSilent('jira-create-issue-modal.first-level-as-epic') === 'true' || false,
			epicIssueTypeId: null,
		};
	}

	componentDidMount() {
		this.fetchIssueTypes();
		this.fetchEpics();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedEpic?.projectId !== this.state.selectedEpic?.projectId) {
			this.fetchIssueTypes();
		}
	}

	fetchIssueTypes() {
		let jiraProjectId;

		if (this.state.selectedEpic?.projectId) {
			jiraProjectId = this.state.selectedEpic.projectId;
		} else if (this.props.viewer.project.jiraCloudProject?.id) {
			jiraProjectId = Util.getIdFromBase64String(this.props.viewer.project.jiraCloudProject.id);
		} else if (this.props.viewer.project.jiraServerProject?.id) {
			jiraProjectId = Util.getIdFromBase64String(this.props.viewer.project.jiraServerProject.id);
		}

		if (jiraProjectId) {
			this.setState({fetchingIssueTypes: true});

			DirectApi.Fetch(`jira_issue_types/${this.props.jiraType}/${jiraProjectId}`).then(json => {
				let issueTypes = json || [];
				const epicIssueType = issueTypes.find(
					issueType => issueType.untranslatedName === 'Epic' || issueType.untranslatedName === 'Epos'
				);
				let epicIssueTypeId;
				if (epicIssueType) {
					epicIssueTypeId = epicIssueType.id;
				}
				// Filter out epic type
				issueTypes = issueTypes.filter(
					issueType => issueType.untranslatedName !== 'Epic' && issueType.untranslatedName !== 'Epos'
				);
				const normalIssueTypes = issueTypes.filter(issueType => !issueType.subtask);
				const subtaskIssueTypes = issueTypes.filter(issueType => issueType.subtask);
				const selectedIssueType = normalIssueTypes && normalIssueTypes.length > 0 ? normalIssueTypes[0].id : undefined;
				const selectedSubtaskIssueType =
					subtaskIssueTypes && subtaskIssueTypes.length > 0 ? subtaskIssueTypes[0].id : undefined;

				this.setState({
					fetchingIssueTypes: false,
					issueTypes,
					selectedIssueType,
					selectedSubtaskIssueType,
					epicIssueTypeId,
				});
			});
		}
	}

	fetchEpics() {
		if (!this.props.viewer.project.jiraCloudEpicIds) return;
		DirectApi.Fetch(`jira/issues?issueIds=${this.props.viewer.project.jiraCloudEpicIds}`).then(json =>
			this.setState({
				epics: json.map(epic => ({
					label: `${epic.key} ${epic.fields.summary}`,
					value: epic.id,
					projectId: epic?.fields?.project?.id,
				})),
			})
		);
	}

	handleIssueTypeChange(value) {
		this.setState({selectedIssueType: value ? value.value : undefined, selectedCustomFieldValues: null});
	}

	handleSubtaskIssueTypeChange(value) {
		this.setState({selectedSubtaskIssueType: value ? value.value : undefined});
	}

	handleCustomFieldArrayChange(key, selected) {
		if (!selected) {
			return;
		}

		let newValue =
			this.state.selectedCustomFieldValues && this.state.selectedCustomFieldValues[key]
				? this.state.selectedCustomFieldValues[key]
				: [];
		let selectedValues =
			this.state.selectedCustomFieldValues && this.state.selectedCustomFieldValues[key]
				? this.state.selectedCustomFieldValues[key].map(el => el.value)
				: [];
		if (selectedValues.includes(selected.value)) {
			newValue = newValue.filter(el => el.value !== selected.value);
		} else {
			newValue.push(selected);
		}
		if (newValue.length === 0) {
			newValue = null;
		}
		this.setState({selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: newValue})});
	}

	handleCustomFieldChange(key, value) {
		this.setState({
			selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: value.value}),
		});
	}

	handleCustomFieldRadioButtonChange(key, id, value) {
		this.setState({selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: id})});
	}

	handleCustomFieldInputChange(key, value) {
		this.setState({selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: value})});
	}

	handleEpicChange(value) {
		this.setState({selectedEpic: value});
	}

	toggleSyncHierarchy() {
		const newVal = !this.state.syncHierarchy;

		this.setState({syncHierarchy: newVal});
		setLocalStorageSilent('jira-create-issue-modal.sync-hierarchy', newVal);
	}

	toggleFirstLevelAsEpic() {
		const newVal = !this.state.firstLevelAsEpic;
		this.setState({firstLevelAsEpic: newVal});
		setLocalStorageSilent('jira-create-issue-modal.first-level-as-epic', newVal);
	}

	showErrorModal(errorMessageText) {
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId="common.invalid_action_modal_title" />
					<div className="warning-part-2">
						{this.props.intl.formatMessage({id: 'modal.jira.issue_creation_failed.warning'})}
					</div>
					<div className="warning-part-2">{errorMessageText}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: 'OK',
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	}

	createIssue() {
		const onSuccess = response => {
			this.props.closeModal();
			if (response.sendTasksToJira && response.sendTasksToJira.errors && response.sendTasksToJira.errors.length > 0) {
				const sprintClosed = response.sendTasksToJira.errors.includes('SPRINT_CLOSED');
				const errorMessage = response.sendTasksToJira.errors.find(e => e.startsWith('Error message'));
				const errorMessageText =
					errorMessage ||
					this.props.intl.formatMessage({
						id: sprintClosed
							? 'modal.jira.issue_creation_failed.sprint_closed'
							: 'modal.jira.issue_creation_failed.default',
					});
				this.showErrorModal(errorMessageText);
			}
			if (this.props.callback) {
				this.props.callback();
			}
		};
		const customFields = this.state.selectedCustomFieldValues
			? Object.keys(this.state.selectedCustomFieldValues).map(key => ({
					key: key,
					value: !Array.isArray(this.state.selectedCustomFieldValues[key])
						? this.state.selectedCustomFieldValues[key]
						: Array.prototype.map.call(this.state.selectedCustomFieldValues[key], s => s.value).toString(),
			  }))
			: undefined;

		Util.CommitMutation(
			SendTasksToJiraMutationModern,
			{
				ids: this.props.taskIds,
				jiraIssueTypeId: this.state.selectedIssueType,
				jiraSubtaskIssueTypeId:
					this.props.viewer.project.jiraSubtaskType === 'subtasks' || this.state.syncHierarchy
						? this.state.selectedSubtaskIssueType
						: undefined,
				jiraType: this.props.jiraType,
				jiraEpicId: this.state.selectedEpic?.value,
				customFields: customFields,
				syncHierarchy: this.state.syncHierarchy,
				firstLevelAsEpic: this.shouldShowFirstLevelAsEpicCheckbox() && this.state.firstLevelAsEpic,
				epicIssueTypeId: this.state.epicIssueTypeId,
			},
			onSuccess,
			false,
			null,
			response => {
				this.props.closeModal();

				if (response.toString().includes('Failed to fetch') || response.toString().includes('Load failed')) {
					showModal({
						type: MODAL_TYPE.GENERIC,
						headerText: 'Issue creation is taking longer than expected',
						content:
							'This may be due to the amount of issues being sent. The process will continue to run in the background.',
						buttons: [
							{
								text: this.props.intl.formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					this.showErrorModal(`An error occurred: ${response.toString()}`);
				}
			}
		);
		this.setState({syncing: true});
	}

	shouldShowFirstLevelAsEpicCheckbox() {
		return (
			!this.props.viewer.project.jiraCloudEpicIds &&
			!this.props.viewer.project.jiraCloudProjectSettings.mapJiraEpicToPhase &&
			(this.state.syncHierarchy || !this.props.hasParent)
		);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const issueTypeOptions = this.state.fetchingIssueTypes
			? [{label: formatMessage({id: 'common.loading'}), value: null, disabled: true}]
			: this.state.issueTypes
					.filter(issueType => !issueType.subtask)
					.map(issueType => ({label: issueType.name, value: issueType.id, description: issueType.description}));

		const subTaskIssueTypeOptions = this.state.fetchingIssueTypes
			? [{label: formatMessage({id: 'common.loading'}), value: null, disabled: true}]
			: this.state.issueTypes
					.filter(issueType => issueType.subtask)
					.map(issueType => ({label: issueType.name, value: issueType.id, description: issueType.description}));

		const issueTypeWithFields =
			this.state.selectedIssueType || this.state.firstLevelAsEpic
				? this.state.issueTypes.filter(x => x.id === this.state.selectedIssueType)[0]
				: null;

		const fieldTypes = issueTypeWithFields
			? Object.keys(issueTypeWithFields.fields).map(function (key, index) {
					return issueTypeWithFields.fields[key];
			  })
			: null;

		const missingRequiredCustomField = this.state.selectedCustomFieldValues
			? fieldTypes.some(
					x =>
						!(x.key in this.state.selectedCustomFieldValues
							? this.state.selectedCustomFieldValues[x.key] !== '' && this.state.selectedCustomFieldValues[x.key]
							: false)
			  )
			: fieldTypes && fieldTypes.length > 0;

		const firstTypeDropdownLabel = this.state.syncHierarchy
			? this.state.firstLevelAsEpic
				? formatMessage({id: 'modal.jira.issue_type_for_second'})
				: formatMessage({id: 'modal.jira.issue_type_for_first'})
			: formatMessage({id: 'modal.jira.jira_issue_type'});

		const shouldShowDropdowns = !this.state.firstLevelAsEpic || this.props.hasParent;
		let firstTypeDropdown = shouldShowDropdowns ? (
			<Dropdown
				value={this.state.selectedIssueType}
				label={firstTypeDropdownLabel}
				options={issueTypeOptions}
				onChange={this.handleIssueTypeChange.bind(this)}
				disabled={this.props.viewer.project.jiraCloudEpicIds && this.state.selectedEpic == null}
				customClasses="optional-dropdown"
			/>
		) : null;

		let secondTypeDropdown =
			shouldShowDropdowns &&
			(this.props.viewer.project.jiraSubtaskType === 'subtasks' || (this.state.syncHierarchy && this.props.hasParent)) ? (
				<Dropdown
					value={this.state.selectedSubtaskIssueType}
					label={
						this.state.firstLevelAsEpic
							? formatMessage({id: 'modal.jira.issue_type_for_third'})
							: formatMessage({id: 'modal.jira.issue_type_for_second_third'})
					}
					options={subTaskIssueTypeOptions}
					onChange={this.handleSubtaskIssueTypeChange.bind(this)}
					customClasses="optional-dropdown"
					disabled={this.props.viewer.project.jiraCloudEpicIds && this.state.selectedEpic == null}
				/>
			) : (
				''
			);

		const content = (
			<div className="create-jira-issue-modal">
				{this.state.syncing ? (
					<FormattedMessage id="common.loading_please_wait" />
				) : (
					<>
						{this.props.viewer.project.jiraCloudEpicIds ? (
							<Dropdown
								value={this.state.selectedEpic?.value}
								label={formatMessage({id: 'integrations.jira.epic'})}
								options={this.state.epics}
								onChange={this.handleEpicChange.bind(this)}
							/>
						) : null}

						<div>
							{this.props.hasParent ? (
								<div class="jira-checkbox-container">
									<TooltipContainer
										tooltipInfinteDuration={true}
										infoText={formatMessage({
											id: 'modal.jira.sync_hierarchy_tooltip',
										})}
										canBeMiddle={true}
									>
										<InputLabel
											text={formatMessage({id: 'modal.jira.sync_hierarchy'})}
											child={
												<Checkbox
													onChange={() => this.toggleSyncHierarchy()}
													checked={this.state.syncHierarchy}
												/>
											}
										/>
									</TooltipContainer>
								</div>
							) : null}
							{this.shouldShowFirstLevelAsEpicCheckbox() ? (
								<div class="jira-checkbox-container">
									<TooltipContainer
										tooltipInfinteDuration={true}
										infoText={formatMessage({
											id: 'modal.jira.sync_first_level_tooltip',
										})}
										canBeMiddle={true}
									>
										<InputLabel
											text={formatMessage({id: 'modal.jira.sync_first_level'})}
											child={
												<Checkbox
													onChange={() => this.toggleFirstLevelAsEpic()}
													checked={this.state.firstLevelAsEpic}
												/>
											}
										/>
									</TooltipContainer>
								</div>
							) : null}

							{firstTypeDropdown}
							{secondTypeDropdown}

							{fieldTypes?.length ? (
								<div className={'field'}>
									<label className="custom-field-header-title">Required custom fields</label>
								</div>
							) : null}
							{issueTypeWithFields
								? fieldTypes.map(x => {
										switch (x.schema.type) {
											case 'number':
											case 'string':
												return (
													<div className={'field'}>
														{
															<InputFieldV2
																value={
																	this.state.selectedCustomFieldValues
																		? this.state.selectedCustomFieldValues[x.key]
																		: ''
																}
																id={x.key}
																label={x.name}
																type={x.schema.type === 'number' ? 'number' : 'text'}
																onChange={this.handleCustomFieldInputChange.bind(this, x.key)}
																required={true}
																maxLength={191}
															/>
														}
													</div>
												);
											case 'option':
												return (
													<div className={'field'}>
														{
															<Dropdown
																value={
																	this.state.selectedCustomFieldValues
																		? this.state.selectedCustomFieldValues[x.key]
																		: null
																}
																label={x.name}
																options={
																	x.allowedValues
																		? x.allowedValues.map(option => ({
																				label: option.name || option.value,
																				value: option.id,
																		  }))
																		: []
																}
																onChange={this.handleCustomFieldChange.bind(this, x.key)}
																multiSelect={false}
																required={true}
															/>
														}
													</div>
												);
											case 'array':
												return (
													<div className={'field'}>
														{
															<Dropdown
																value={
																	this.state.selectedCustomFieldValues &&
																	this.state.selectedCustomFieldValues[x.key]
																		? this.state.selectedCustomFieldValues[x.key].map(
																				el => el.value
																		  )
																		: null
																}
																label={x.name}
																options={
																	x.allowedValues
																		? x.allowedValues.map(option => ({
																				label: option.name || option.value,
																				value: option.id,
																		  }))
																		: []
																}
																onChange={this.handleCustomFieldArrayChange.bind(this, x.key)}
																multiSelect={true}
																required={true}
															/>
														}
													</div>
												);
											default:
												return (
													<div className={'field'}>
														<label className="custom-field-input-title">{x.name}</label>
														<div
															className={
																'input-value-container' +
																(this.props.useBorderStyle ? ' bordered' : '') +
																(this.props.addSearchIconPlaceholder
																	? ' search-icon-placeholder'
																	: '') +
																(this.props.currency
																	? ' currency-placeholder ' + this.props.currency
																	: '') +
																(this.props.invalidInput ? ' invalid' : '') +
																(this.state.isFocused ? ' focused' : '') +
																(this.props.alignTextRight ? ' align-right' : '') +
																(this.props.locked ? ' locked' : '')
															}
														>
															<textarea
																rows={2}
																class="input-value"
																cols={49}
																value={
																	'This required custom field type is not supported by Forecast, to continue make the field type optional in Jira.'
																}
																disabled={true}
																style={{resize: 'none'}}
															/>
														</div>
													</div>
												);
										}
								  })
								: null}
						</div>
					</>
				)}
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						disabled: this.state.syncing,
					},
					{
						text: formatMessage({id: 'common.create'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.createIssue.bind(this),
						preventDefaultClose: true,
						disabled:
							this.state.syncing ||
							!this.state.selectedIssueType ||
							(this.props.viewer.project.jiraSubtaskType === 'subtasks' &&
								!this.state.selectedSubtaskIssueType) ||
							missingRequiredCustomField,
					},
				]}
				headerText={formatMessage({id: 'modal.jira.create_x_in_jira'}, {tasks: this.props.taskIds.length})}
				content={content}
				modalWidth={MODAL_WIDTH.SMALL}
			/>
		);
	}
}
CreateJiraIssueModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	taskIds: PropTypes.array.isRequired,
	jiraType: PropTypes.string.isRequired,
	callback: PropTypes.func,
};

const createJiraIssueModalQuery = graphql`
	query createJiraIssueModal_Query($projectId: ID) {
		viewer {
			actualPersonId
			component(name: "create_jira_issue_modal")
			...createJiraIssueModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {createJiraIssueModalQuery};

export default injectIntl(
	createFragmentContainer(CreateJiraIssueModal, {
		viewer: graphql`
			fragment createJiraIssueModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
				id
				project(internalId: $projectId) {
					id
					jiraSubtaskType
					jiraCloudProject {
						id
					}
					jiraCloudProjectSettings {
						id
						mapJiraEpicToPhase
					}
					jiraServerProject {
						id
					}
					jiraCloudEpicIds
				}
			}
		`,
	})
);
