/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type bugDuty_viewer$ref: FragmentReference;
declare export opaque type bugDuty_viewer$fragmentType: bugDuty_viewer$ref;
export type bugDuty_viewer = {|
  +id: string,
  +missionControlData: ?{|
    +id: string,
    +errorList: ?$ReadOnlyArray<?{|
      +id: string,
      +actualId: ?number,
      +timestamp: ?string,
      +personId: ?number,
      +personName: ?string,
      +companyId: ?number,
      +companyName: ?string,
      +correlationId: ?string,
      +sentryEventId: ?string,
      +fixed: ?boolean,
      +notes: ?string,
      +excludeFromStats: ?boolean,
      +lastUpdatedByName: ?string,
      +bugDuty: ?string,
      +team: ?string,
      +url: ?string,
      +knownType: ?string,
    |}>,
    +knownErrorTypes: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +description: ?string,
    |}>,
  |},
  +$refType: bugDuty_viewer$ref,
|};
export type bugDuty_viewer$data = bugDuty_viewer;
export type bugDuty_viewer$key = {
  +$data?: bugDuty_viewer$data,
  +$fragmentRefs: bugDuty_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "bugDuty_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filter",
              "variableName": "filter"
            },
            {
              "kind": "Variable",
              "name": "searchString",
              "variableName": "searchString"
            }
          ],
          "concreteType": "MissionControlErrorListType",
          "kind": "LinkedField",
          "name": "errorList",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "personId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "personName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "correlationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sentryEventId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fixed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "notes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "excludeFromStats",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastUpdatedByName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bugDuty",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "team",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "knownType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlKnownErrorType",
          "kind": "LinkedField",
          "name": "knownErrorTypes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a980b40759d3f4f1dff4d36b3f1bce8d';

module.exports = node;
