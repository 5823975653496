import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Checkbox from '../../components/new-ui/check_box';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import SetADOProjectSettingsMutation from '../../mutations/SetADOProjectSettingsMutation';
import DirectApi from '../../directApi';

class LinkADOProjectModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			adoProject: '',
			adoAccount: '',
			adoArea: '',
			adoProjects: [],
			adoAreas: [],
			syncBugs: true,
			useEpics: false,
			twoWaySync: this.props.companyId !== 4013,
			useNewSubtasks: this.props.companyId !== 4013,
			linking: false,
			projectsLoading: true,
			areasLoading: false,
		};

		this.getProjects();
	}

	selectProject(val) {
		const adoProject = val.value;
		const adoAccount = this.state.adoProjects.find(p => p.id === val.value).account;

		this.setState({
			adoProject,
			adoAccount,
			adoArea: '',
		});

		this.getAreas(adoProject, adoAccount);
	}

	selectArea(val) {
		this.setState({
			adoArea: val.value,
		});
	}

	getAreas(adoProject, adoAccount) {
		this.setState({
			areasLoading: true,
		});

		DirectApi.Fetch(`vsts_areas_by_account?vsts_project_id=${adoProject}&account=${adoAccount}`).then(json =>
			this.setState({
				adoAreas: json,
				adoArea: json[0].value,
				areasLoading: false,
			})
		);
	}

	getProjects() {
		DirectApi.Fetch('vsts_projects').then(json =>
			this.setState({
				adoProjects: json,
				projectsLoading: false,
			})
		);
	}

	link() {
		this.setState({linking: true});

		Util.CommitMutation(
			SetADOProjectSettingsMutation,
			{
				projectId: this.props.project.id,
				vstsProject: this.state.adoProject,
				vstsArea: this.state.adoArea || null,
				vstsAccount: this.state.adoAccount,
				vstsSyncBugs: this.state.syncBugs,
				vstsUseEpics: this.state.useEpics,
				vstsTwoWaySync: this.state.twoWaySync,
				vstsUseNewSubtasks: this.state.useNewSubtasks,
			},
			() => {
				this.setState({linking: false});
				this.props.closeModal();
			}
		);
	}

	toggleTwoWaySync() {
		this.setState({twoWaySync: !this.state.twoWaySync});
	}

	toggleUseNewSubtasks() {
		this.setState({useNewSubtasks: !this.state.useNewSubtasks});
	}

	toggleSyncBugs() {
		this.setState({syncBugs: !this.state.syncBugs});
	}

	toggleSyncUseEpics() {
		this.setState({useEpics: !this.state.useEpics});
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className="layer" onMouseDown={this.props.closeModal.bind(this)}>
				<div className="link-ado-project-modal">
					{this.state.linking ? (
						<InlineLoader />
					) : (
						<div
							className="inner"
							onMouseDown={e => {
								e.stopPropagation();
							}}
						>
							<div className="container">
								<div className="header">
									<h3>
										<FormattedMessage id="settings.link_ado_project" />
									</h3>
									<button className="close-button" onClick={this.props.closeModal.bind(this)} />
								</div>
								<div className="body">
									<div className="ado-input-container">
										<DropdownV2
											label={formatMessage({id: 'settings.ado_project_input'})}
											placeholder={
												this.state.projectsLoading
													? formatMessage({id: 'common.loading'})
													: formatMessage({id: 'settings.ado_project_placeholder'})
											}
											disabled={this.state.projectsLoading}
											options={this.state.adoProjects.map(p => ({
												value: p.id.toString(),
												label: p.name,
											}))}
											onChange={v => this.selectProject(v)}
											value={this.state.adoProject}
										/>
									</div>

									<div className="ado-input-container">
										<DropdownV2
											label={formatMessage({id: 'settings.ado_area_input'})}
											placeholder={
												this.state.areasLoading
													? formatMessage({id: 'common.loading'})
													: formatMessage({id: 'settings.ado_area_placeholder'})
											}
											options={this.state.adoAreas}
											onChange={this.selectArea.bind(this)}
											value={this.state.adoArea}
											disabled={this.state.adoProject === '' || this.state.areasLoading}
										/>
									</div>

									<div className="ado-input-container">
										<Checkbox
											isChecked={this.state.syncBugs}
											onChange={() => this.toggleSyncBugs()}
											id="sync-ado-bugs-checkbox"
											customClasses="ado-checkbox"
										/>
										<label for="sync-ado-bugs-checkbox" className="input-header">
											{formatMessage({id: 'settings.ado_sync_bugs'})}
										</label>
									</div>

									<div className="ado-input-container">
										<Checkbox
											isChecked={this.state.useEpics}
											onChange={() => this.toggleSyncUseEpics()}
											id="sync-ado-use-epics-checkbox"
											customClasses="ado-checkbox"
										/>
										<label for="sync-ado-epics-checkbox" className="input-header">
											{formatMessage({id: 'settings.ado_use_epics'})}
										</label>
									</div>

									<div className="ado-input-container">
										<Checkbox
											isChecked={this.state.twoWaySync}
											onChange={() => this.toggleTwoWaySync()}
											id="two-way-sync-checkbox"
											customClasses="ado-checkbox"
										/>
										<label for="two-way-sync-checkbox" className="input-header">
											{formatMessage({id: 'settings.two_way_sync'})}
										</label>
									</div>

									<div className="ado-input-container">
										<Checkbox
											isChecked={this.state.useNewSubtasks}
											onChange={() => this.toggleUseNewSubtasks()}
											id="use-new-subtasks-checkbox"
											customClasses="ado-checkbox"
										/>
										<label for="use-new-subtasks-checkbox" className="input-header">
											{formatMessage({id: 'settings.use_new_subtasks'})}
										</label>
									</div>
								</div>
								<div className="bottom">
									<Button
										className="ado-button"
										colorTheme={BUTTON_COLOR.WHITE}
										buttonStyle={BUTTON_STYLE.FILLED}
										text={formatMessage({id: 'common.cancel'})}
										onClick={this.props.closeModal.bind(this)}
									/>
									<Button
										className="ado-button"
										colorTheme={BUTTON_COLOR.GREEN}
										buttonStyle={BUTTON_STYLE.FILLED}
										text={formatMessage({id: 'common.link'})}
										isDisabled={!this.state.adoProject || !this.state.adoAccount || !this.state.adoArea}
										onClick={() => this.link()}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default injectIntl(withRouter(LinkADOProjectModal));
