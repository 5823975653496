import React from 'react';
import {ProjectHealthTableSectionWrapper, BottomSpacer} from './project_health_styles/ProjectHealthTableSection.styled';
import {CustomScrollDiv} from 'web-components';
import {ProjectHealthTable} from './project_health_table/ProjectHealthTable';
import NovaInsightsOnboarding from './nova_insights_onboarding/NovaInsightsOnboarding';

export const ProjectHealthTableSection = ({
	projectId,
	availableInsightsData,
	lockedInsightsData,
	projectHalted,
	projectOpportunity,
}) => {
	return (
		<ProjectHealthTableSectionWrapper>
			<CustomScrollDiv className="custom-scrollbar-div">
				{availableInsightsData?.length > 0 ? (
					<>
						<ProjectHealthTable data={availableInsightsData} projectHalted={projectHalted} />
						<BottomSpacer />
					</>
				) : null}
				{lockedInsightsData?.length > 0 ? (
					<>
						<ProjectHealthTable data={lockedInsightsData} projectHalted={projectHalted} isLockedTable />
						<BottomSpacer />
					</>
				) : null}
				<NovaInsightsOnboarding
					projectId={projectId}
					projectOpportunity={projectOpportunity}
					lockedInsightsData={lockedInsightsData}
				/>
			</CustomScrollDiv>
		</ProjectHealthTableSectionWrapper>
	);
};
