import React, {useState, useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl, FormattedMessage} from 'react-intl';
import GenericModal from '../generic_modal';
import InvoiceEntriesList from '../create_invoice_modal_v2/steps/invoice_entries_list';
import {
	InvoiceDropdownContainer,
	InvoiceDataLineTwo,
	ContentWrapper,
	InvoiceDataLine,
	SwitchWrapper,
	SwitchTitle,
} from '../../../styles/v2/invoice_modals_v2_styled';
import CreateXeroInvoiceMutation from '../../../mutations/CreateXeroInvoiceMutation';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {showModal, MODAL_TYPE} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import {hideLoader, showLoader} from '../../global_loader';
import {setLocalStorageSilent, getLocalStorageSilent} from '../../../forecast-app/shared/util/LocalStorageUtil';
import Switch from '../../../forecast-app/shared/components/switch/switch';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import DirectApi from '../../../directApi';
import {
	UneditableMessageBox,
	MessageContainer,
	PadLockContainer,
} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';

const xeroExportModal = ({closeModal, viewer, xeroClients}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {invoice, projects} = viewer;
	const entries = invoice ? invoice.entries : [];
	const mappedProjects = projects.edges.map(p => ({...p.node}));
	const clientProjects = mappedProjects.filter(project =>
		project.client && invoice.client ? project.client.id === invoice.client.id : false
	);
	const initialInvoiceLines = entries.edges.map(e => ({
		...e.node,
		projectId: e.node.project ? e.node.project.id : null,
		quantity: e.node.quantityText,
	}));

	const [invoiceLines, setInvoiceLines] = useState(initialInvoiceLines);
	const [xeroAccounts, setXeroAccounts] = useState([]);
	const [xeroContacts, setXeroContacts] = useState([]);
	const [selectedClient, setSelectedClient] = useState(
		xeroClients.find(client => invoice.client && client.value === invoice.client.id)
	);
	const [selectedClientTaxRate, setSelectedClientTaxRate] = useState(null);
	const [selectedXeroAccount, setSelectedXeroAccount] = useState('-1');
	const [exporting, setExporting] = useState(false);
	const selectXeroAccount = account => {
		setSelectedXeroAccount(account.value);
		invoiceLines.forEach(line => {
			line.xeroAccount = account.value;
			line.tax = selectedClientTaxRate != null ? selectedClientTaxRate : account.taxRate;
			line.accountCode = account.code;
		});
	};

	let xeroProjectInDescription = getLocalStorageSilent('forecast_xeroProjectInDescription') === 'true';

	const [projectInDescription, setProjectInDescription] = useState(xeroProjectInDescription);

	const toggleProjectInDescription = () => {
		const val = !projectInDescription;
		setLocalStorageSilent('forecast_xeroProjectInDescription', val);
		setProjectInDescription(val);
	};

	const getXeroAccounts = async tenantId => {
		setXeroAccounts([{value: '-1', label: intl.formatMessage({id: 'common.loading'})}]);
		setSelectedXeroAccount('-1');

		try {
			return await DirectApi.Fetch_Raw(`xero/accounts/${tenantId}`).then(result => {
				if (result.status === 403) {
					return false;
				}

				result.json().then(json =>
					setXeroAccounts(
						json.map(account => ({
							value: account.AccountID,
							label: account.Name,
							taxRate: account.DefaultTaxRate,
							type: account.Type,
							code: account.Code,
						}))
					)
				);

				return true;
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const getXeroContacts = async () => {
		try {
			return await DirectApi.Fetch_Raw(`xero/contacts`).then(result => {
				if (result.status === 403) {
					return false;
				}

				return result.json().then(json => {
					setXeroContacts(json);
					if (selectedClient) {
						const xeroContact = json?.find(xeroContact => xeroContact.ContactID === selectedClient.xeroContactId);
						setSelectedClientTaxRate(xeroContact?.DefaultTaxRate);
						invoiceLines.forEach(line => {
							line.tax = xeroContact.DefaultTaxRate;
						});
					}
					return json;
				});
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const selectClient = client => {
		setSelectedClient(client);
		const xeroContact = xeroContacts?.find(xeroContact => xeroContact.ContactID === client.xeroContactId);

		setSelectedClientTaxRate(xeroContact?.DefaultTaxRate);
		if (xeroContact?.DefaultTaxRate != null) {
			invoiceLines.forEach(line => {
				line.tax = xeroContact.DefaultTaxRate;
			});
		}

		if (xeroContact) {
			getXeroAccounts(xeroContact.TenantId);
		}
	};

	const getXeroTaxRate = xeroAccountId => {
		if (selectedClientTaxRate != null) {
			return selectedClientTaxRate;
		}

		const xeroAccount = xeroAccounts.find(acc => acc.value === xeroAccountId);
		return xeroAccount?.taxRate || 0;
	};

	let xeroClientsSpliced = xeroClients;

	if (xeroClients && xeroClients.length !== 0 && xeroContacts && xeroContacts.length !== 0) {
		xeroClientsSpliced = xeroClients.map(client => {
			// find contact
			const contact = xeroContacts.find(c => c.ContactID === client.xeroContactId);

			return {...client, label: `${client.label} (${contact?.Organization})`};
		});
	}

	const content = (
		<ContentWrapper minHeight="600px">
			<UneditableMessageBox style={{marginBottom: '30px'}}>
				<PadLockContainer>
					<LockIcon size={LockIcon.SIZE.LARGE} color={CSS_CONSTANTS.v2_text_gray} />
				</PadLockContainer>
				<MessageContainer>{formatMessage({id: 'invoicing.cannot_edit_warning_exporting'})}</MessageContainer>
			</UneditableMessageBox>
			<InvoiceDataLineTwo>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectClient(option)}
						options={xeroClientsSpliced}
						value={selectedClient ? selectedClient.value : null}
						label={formatMessage({id: 'common.customer'})}
						placeholder={formatMessage({id: 'common.customer'})}
					/>
				</InvoiceDropdownContainer>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectXeroAccount(option)}
						options={xeroAccounts}
						value={selectedXeroAccount}
						label={formatMessage({id: 'integrations.xero.xero_account'})}
						placeholder={formatMessage({id: 'integrations.xero.xero_account'})}
						disabled={xeroAccounts.length === 0}
					/>
				</InvoiceDropdownContainer>
			</InvoiceDataLineTwo>
			<InvoiceDataLine>
				<SwitchWrapper>
					<SwitchTitle>
						<FormattedMessage id="invoicing.xero.project_in_description" />
					</SwitchTitle>
					<Switch
						onChange={() => toggleProjectInDescription()}
						checked={projectInDescription}
						sliderWidth={30}
						sliderHeight={18}
					/>
				</SwitchWrapper>
			</InvoiceDataLine>
			<InvoiceEntriesList
				invoiceExporting={true}
				lines={invoiceLines.map(line => {
					const convertedLine = {...line};
					convertedLine.tax = getXeroTaxRate(convertedLine.xeroAccount);
					if (projectInDescription && convertedLine.project)
						convertedLine.description = `(P${convertedLine.project.companyProjectId}-${convertedLine.project.name}) ${convertedLine.description}`;
					return convertedLine;
				})}
				projects={mappedProjects}
				clientProjects={clientProjects}
				currency={invoice.currency}
				setInvoiceLines={setInvoiceLines}
				invoice={invoice}
				integrationData={{
					xero: {
						enabled: true,
						accounts: xeroAccounts,
					},
				}}
			/>
		</ContentWrapper>
	);

	const onInvoiceExport = () => {
		// Get tenantId
		const xeroContact = xeroContacts?.find(xeroContact => xeroContact.ContactID === selectedClient.xeroContactId);

		setExporting(true);
		showLoader();
		// Export invoice to Xero
		Util.CommitMutation(
			CreateXeroInvoiceMutation,
			{
				invoiceId: invoice.id,
				forecastClientId: selectedClient.value,
				accountMap: invoiceLines.map(line => ({
					entryId: line.id,
					accountId: line.xeroAccount,
					accountCode: line.accountCode,
				})),
				tenantId: xeroContact.TenantId,
				projectInDescription: projectInDescription,
			}, // Toast
			result => {
				setExporting(false);
				closeModal();
				hideLoader();
				if (result.createXeroInvoice.success) {
					createToast({duration: 5000, message: intl.formatMessage({id: 'integrations.xero.invoice_created'})});
				} else {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'invoicing.not_exported',
						warningInformation: [result.createXeroInvoice.errorMessage || 'The invoice could not be exported.'],
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			}
		);
	};

	const modalButtons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			callback: closeModal.bind(this),
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: formatMessage({id: 'common.export'}),
			preventDefaultClose: true,
			callback: onInvoiceExport,
			disabled: exporting || selectedClient === null || !selectedClient || invoiceLines.find(line => !line.xeroAccount),
		},
	];

	useEffect(() => {
		getXeroContacts().then(contRes => {
			if (!contRes) {
				closeModal();
				showModal({
					type: MODAL_TYPE.XERO_INACCESSIBLE,
					isAdminUser: hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS),
				});
			}

			if (selectedClient) {
				const xeroContact = contRes?.find(xeroContact => xeroContact.ContactID === selectedClient.xeroContactId);
				getXeroAccounts(xeroContact.TenantId);
			}
		});
	}, []);

	return (
		<GenericModal
			buttons={modalButtons}
			headerText={formatMessage({id: 'invoicing.export_to'}, {systemName: 'Xero'})}
			closeModal={closeModal}
			content={content}
		/>
	);
};

const xeroExportModalQuery = graphql`
	query xeroExportModal_Query($invoiceId: ID) {
		viewer {
			actualPersonId
			component(name: "xero_export_modal")
			...xeroExportModal_viewer @arguments(invoiceId: $invoiceId)
		}
	}
`;

export {xeroExportModalQuery};

export default createFragmentContainer(xeroExportModal, {
	viewer: graphql`
		fragment xeroExportModal_viewer on Viewer @argumentDefinitions(invoiceId: {type: "ID"}) {
			id
			backendId
			invoice(id: $invoiceId) {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				economicId
				client {
					id
				}
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quantityLockedReason
							project {
								id
								companyProjectId
								name
								projectColor
								isInProjectGroup
								budgetType
								client {
									name
								}
								rateCard {
									currency
								}
							}
						}
					}
				}
			}
			projects {
				edges {
					node {
						id
						name
						projectColor
						isInProjectGroup
						companyProjectId
						projectGroupId
						budgetType
						status
						fullAccessToProject
					}
				}
			}
		}
	`,
});
