import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import moment from 'moment';

import GenericModal from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {ContentWrapper} from '../../../styles/v2/invoice_modals_v2_styled';
import ProjectSelectionOrInvoiceSettings from './steps/project_selection_or_invoice_settings';
import InvoiceSettingsAndLineGeneration from './steps/invoice_settings_and_line_generation';
import InvoiceEntriesList, {invoiceEntriesListQuery} from './steps/invoice_entries_list';
import {getDefaultConfig} from '../../../components/insights/invoicing/line-generation/invoicing_generate_lines_config';
import Util from '../../../forecast-app/shared/util/util';
import CreateInvoiceMutation from '../../../mutations/create_invoice_mutation';
import CreateBulkInvoicesMutation from '../../../mutations/CreateBulkInvoicesMutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {generateLines} from '../../../components/insights/invoicing/line-generation/invoicing_generate_lines';
import {keyToFilterField} from '../../../forecast-app/shared/components/filters/filter_util';

export const invoiceLineConverter = ({
	id,
	description,
	quantity,
	unitPrice,
	discount,
	tax,
	projectId,
	timeRegIds,
	expenseItemIds,
	periodIds,
	quantityLockedReason,
}) => ({
	id,
	name: '',
	projectId,
	quantity: +quantity,
	quantityText: quantity,
	unitPrice: +unitPrice,
	discount: +discount,
	tax: +tax,
	description,
	timeRegIds,
	expenseItemIds,
	periodIds,
	quantityLockedReason,
});

const CreateInvoiceModalV2 = ({
	closeModal,
	isEditing,
	company,
	defaultSelectedProjectIds = [],
	lockedClientId = null,
	company: {clients, exchangeRates},
	projects,
	startDate: defaultStartDate,
	endDate: defaultEndDate,
	clientId,
	projectId,
	refetch,
	onInvoiceCreation,
	modalRefetch,
	isProjectInvoicing,
	project,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const defaultConfig = getDefaultConfig();
	if (!defaultStartDate) {
		defaultStartDate = moment().subtract(1, 'month').startOf('month');
	}
	if (!defaultStartDate) {
		defaultStartDate = moment().subtract(1, 'month').endOf('month');
	}

	const [selectedClientId, setSelectedClientId] = useState(lockedClientId || clientId || -1);
	const [isCreatingBlankInvoice, setIsCreatingBlankInvoice] = useState(false);
	const [config, setConfig] = useState(defaultConfig);
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [invoiceLines, setInvoiceLines] = useState([]);
	const [invoice, setInvoice] = useState({
		name: '',
		date: moment(),
		dueDate: moment().add(30, 'days'),
		ProjectsIds: [],
		reference: null,
		tax: null,
		discount: null,
		currency: isProjectInvoicing && project && project.rateCard ? project.rateCard.currency : company.currency,
		notes: '',
	});
	const [hasError, setHasError] = useState(false);
	const [creatingBulkInvoice, setCreatingBulkInvoice] = useState(false);
	const [isBulkLoading, setIsBulkLoading] = useState(false);
	const [selectedProjectsIds, setSelectedProjectsIds] = useState(projectId ? [projectId] : defaultSelectedProjectIds);
	const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);

	useEffect(() => {
		const selectedProjects = projects.filter(p => selectedProjectsIds.includes(p.id));
		// Get unique client Ids from selected projects
		const selectedProjectClientIds = selectedProjects
			.map(p => p.client?.id)
			.filter((clientId, index, self) => clientId && self.indexOf(clientId) === index);
		setConfig({
			timeRegGrouping: config.timeRegGrouping,
			expenseGrouping: config.expenseGrouping,
			includeExpenses: config.includeExpenses,
			selectedProjectsIds,
			selectedProjectClientIds,
			startDate,
			endDate,
		});

		// If all projects use the same currency, use project currency instead of company currency
		let defaultCurrency = company.currency;
		for (let i = 0; i < selectedProjectsIds.length; i++) {
			const project = projects.find(project => project.id === selectedProjectsIds[i]);
			const projectCurrency = project && project.rateCard ? project.rateCard.currency : company.currency;
			if (
				(projectCurrency !== defaultCurrency && defaultCurrency !== company.currency) ||
				projectCurrency === company.currency
			) {
				defaultCurrency = company.currency;
				break;
			} else {
				defaultCurrency = projectCurrency;
			}
		}
		if (defaultCurrency !== company.currency) {
			let newInvoice = invoice;
			newInvoice.currency = defaultCurrency;
			setInvoice(newInvoice);
		}
	}, [selectedProjectsIds, startDate, endDate]);

	const [step, setStep] = useState('project-selection-or-invoice-settings');
	const currenciesDropdownOptions = [
		{value: company.currency, label: company.currency},
		...exchangeRates.edges.map(er => ({value: er.node.currency, label: er.node.currency})),
	];

	const onStepChange = (step, next) => {
		switch (step) {
			case 'project-selection-or-invoice-settings':
				if (isCreatingBlankInvoice) {
					setStep('invoice-entries');
				} else {
					setStep('invoice-settings-and-line-generation');
				}
				break;
			case 'invoice-settings-and-line-generation':
				if (next) {
					setStep('invoice-entries');
				} else {
					setStep('project-selection-or-invoice-settings');
				}
				break;
			case 'create-bulk-invoices':
				if (next) {
					setCreatingBulkInvoice(true);
				} else {
					setStep('project-selection-or-invoice-settings');
				}
				break;
			case 'invoice-entries':
				if (isCreatingBlankInvoice) {
					setStep('project-selection-or-invoice-settings');
				} else {
					setStep('invoice-settings-and-line-generation');
				}
				break;
		}
	};

	const filterProjectByClient = clientId => {
		if (clientId) {
			// clientId -1 means all clients
			return projects.filter(project => project.client && (project.client.id === clientId || clientId === -1));
		}
		return [];
	};

	const onInvoiceCreate = () => {
		const onSuccess = res => {
			createToast({duration: 5000, message: formatMessage({id: 'invoice.invoice_created'})});
			onInvoiceCreation('INVOICES', invoice.date);
			closeModal();
			// update state
			if (refetch) refetch();
		};
		// Use selected client id from drop down, or if all selected projects have same client then use that.
		const clientId =
			selectedClientId !== -1
				? selectedClientId
				: config.selectedProjectClientIds.length === 1
				? config.selectedProjectClientIds[0]
				: null;

		const newInvoiceData = {
			clientId: clientId,
			projectId: null,
			invoiceType: null,
			name: invoice.name,
			currency: invoice.currency,
			invoiceReference: invoice.reference,
			status: 'DRAFT',
			dueDay: invoice.dueDate.date(),
			dueMonth: invoice.dueDate.month() + 1,
			dueYear: invoice.dueDate.year(),
			createdDay: invoice.date.date(),
			createdMonth: invoice.date.month() + 1,
			createdYear: invoice.date.year(),
			notes: invoice.notes,
			entries: invoiceLines.map(invoiceLineConverter),
		};

		setIsCreatingInvoice(true);
		Util.CommitMutation(CreateInvoiceMutation, newInvoiceData, onSuccess);
	};

	const onBulkInvoiceCreate = (fetchedUninvoicedTimeRegistrations, projectPersonsMap) => {
		setCreatingBulkInvoice(false);
		setIsBulkLoading(true);
		const onSuccess = res => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'invoicing.x_invoices_created'}, {x: selectedProjectsIds.length}),
			});
			closeModal();
			setIsBulkLoading(false);
			onInvoiceCreation('INVOICES', invoice.date);
			// update state
			if (refetch) refetch();
		};
		const bulkInvoiceLines = generateLines(
			config,
			intl,
			invoice,
			fetchedUninvoicedTimeRegistrations,
			projects,
			projectPersonsMap,
			company
		);
		const projectInvoices = bulkInvoiceLines.reduce((acc, cur) => {
			const project = projects.find(project => project.id === cur.projectId);
			if (acc[cur.projectId]) {
				acc[cur.projectId].entries.push(invoiceLineConverter(cur));
			} else {
				acc[cur.projectId] = {
					projectId: cur.projectId,
					projectName: project?.name,
					clientId: project?.client?.id,
					entries: [invoiceLineConverter(cur)],
				};
			}
			return acc;
		}, {});

		const bulkInvoicesData = {
			bulkName: invoice.name,
			currency: invoice.currency,
			invoiceReference: invoice.reference,
			dueDay: invoice.dueDate.date(),
			dueMonth: invoice.dueDate.month() + 1,
			dueYear: invoice.dueDate.year(),
			createdDay: invoice.date.date(),
			createdMonth: invoice.date.month() + 1,
			createdYear: invoice.date.year(),
			notes: invoice.notes,
			invoices: Object.values(projectInvoices),
		};
		Util.CommitMutation(CreateBulkInvoicesMutation, bulkInvoicesData, onSuccess);
	};

	const projectSearchQuery = {
		filters: [{field: keyToFilterField('project'), value: selectedProjectsIds}],
	};

	const content = (
		<ContentWrapper data-cy={'create-invoice-modal'}>
			{step === 'project-selection-or-invoice-settings' ? (
				<ProjectSelectionOrInvoiceSettings
					clients={clients}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					company={company}
					selectedProjectsIds={selectedProjectsIds}
					selectedClientId={selectedClientId}
					isClientChangeable={!lockedClientId}
					isCreatingBlankInvoice={isCreatingBlankInvoice}
					setSelectedProjectsIds={setSelectedProjectsIds}
					setSelectedClientId={setSelectedClientId}
					setIsCreatingBlankInvoice={setIsCreatingBlankInvoice}
					invoice={invoice}
					setInvoice={setInvoice}
					currenciesDropdownOptions={currenciesDropdownOptions}
					isEditing={isEditing}
					clientProjects={filterProjectByClient(selectedClientId)}
					setHasError={setHasError}
					projectId={projectId}
					modalRefetch={modalRefetch}
					isProjectInvoicing={isProjectInvoicing}
					project={project}
				/>
			) : null}
			{step === 'invoice-settings-and-line-generation' || step === 'create-bulk-invoices' ? (
				<>
					<InvoiceSettingsAndLineGeneration
						setInvoice={setInvoice}
						invoice={invoice}
						selectedProjectsIds={selectedProjectsIds}
						currenciesDropdownOptions={currenciesDropdownOptions}
						projects={projects}
						company={company}
						isEditing={isEditing}
						config={config}
						setConfig={setConfig}
						setHasError={setHasError}
						isBulk={step === 'create-bulk-invoices'}
						isProjectInvoicing={isProjectInvoicing}
					/>
					{creatingBulkInvoice ? (
						<ForecastQueryRenderer
							key="query-render-invoiceEntriesList"
							query={invoiceEntriesListQuery}
							modal={true}
							variables={{
								searchQuery: projectSearchQuery,
								startYear: startDate.year(),
								startMonth: startDate.month() + 1,
								startDay: startDate.date(),
								endYear: endDate.year(),
								endMonth: endDate.month() + 1,
								endDay: endDate.date(),
							}}
							render={relayProps => {
								let fetchedUnInvoicedTimeRegistrations = [];
								const projectPersonsMap = relayProps.viewer.company.allProjects.edges.reduce(function (
									map,
									obj
								) {
									map[obj.node.id] = obj.node.projectPersons;
									return map;
								},
								{});
								relayProps.viewer.company.allProjects.edges.forEach(
									project =>
										(fetchedUnInvoicedTimeRegistrations = fetchedUnInvoicedTimeRegistrations.concat(
											project.node.unInvoicedTimeRegistrations
										))
								);
								onBulkInvoiceCreate(fetchedUnInvoicedTimeRegistrations, projectPersonsMap);
								return null;
							}}
						/>
					) : null}
				</>
			) : null}
			{step === 'invoice-entries' ? (
				<ForecastQueryRenderer
					key="query-render-invoiceEntriesList"
					query={invoiceEntriesListQuery}
					modal={true}
					variables={{
						searchQuery: projectSearchQuery,
						startYear: startDate.year(),
						startMonth: startDate.month() + 1,
						startDay: startDate.date(),
						endYear: endDate.year(),
						endMonth: endDate.month() + 1,
						endDay: endDate.date(),
					}}
					render={relayProps => (
						<InvoiceEntriesList
							{...relayProps}
							canEditInvoice
							lines={invoiceLines}
							setInvoiceLines={setInvoiceLines}
							projects={projects}
							clientProjects={filterProjectByClient(selectedClientId)}
							currency={invoice.currency}
							tax={invoice.tax}
							discount={invoice.discount}
							setHasError={setHasError}
							config={config}
							invoice={invoice}
							company={company}
							isProjectInvoicing={isProjectInvoicing}
							project={project}
							isCreatingBlankInvoice={isCreatingBlankInvoice}
						/>
					)}
				/>
			) : null}
		</ContentWrapper>
	);

	const modalButtons = [];
	if (step === 'project-selection-or-invoice-settings') {
		if (selectedProjectsIds.length > 1 && !isCreatingBlankInvoice) {
			modalButtons.push({
				text: formatMessage({id: 'invoicing.create_x_invoices'}, {x: selectedProjectsIds.length}),
				preventDefaultClose: true,
				callback: () => {
					setStep('create-bulk-invoices');
				},
				disabled: hasError,
				style: BUTTON_STYLE.OUTLINE,
			});
		}
		modalButtons.push({
			text: formatMessage({id: 'invoicing.create_invoice'}),
			preventDefaultClose: true,
			callback: onStepChange.bind(this, step, true),
			disabled: hasError || config.selectedProjectClientIds.length > 1 || !selectedClientId,
			userpilot: 'create-invoice-next-button',
			tooltipText:
				config.selectedProjectClientIds.length > 1
					? formatMessage({id: 'invoicing.tooltip.disabled_multi_client'})
					: null,
			cy: 'next-button',
		});
	} else if (step === 'invoice-settings-and-line-generation') {
		modalButtons.push(
			{
				text: formatMessage({id: 'common.back'}),
				preventDefaultClose: true,
				callback: onStepChange.bind(this, step, false),
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'common.next'}),
				preventDefaultClose: true,
				callback: onStepChange.bind(this, step, true),
				disabled: hasError,
				userpilot: 'create-invoice-next-button',
				cy: 'next-button',
			}
		);
	} else if (step === 'invoice-entries' && !creatingBulkInvoice) {
		modalButtons.push(
			{
				text: formatMessage({id: 'common.back'}),
				preventDefaultClose: true,
				callback: onStepChange.bind(this, step, false),
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'new_project_modal.save_button'}),
				preventDefaultClose: true,
				callback: onInvoiceCreate,
				disabled: hasError || isCreatingInvoice,
				userpilot: 'create-invoice-save-button',
				cy: 'save-button',
			}
		);
	} else if (step === 'create-bulk-invoices') {
		modalButtons.push(
			{
				text: formatMessage({id: 'common.back'}),
				preventDefaultClose: true,
				callback: onStepChange.bind(this, step, false),
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'invoicing.create_x_invoices'}, {x: selectedProjectsIds.length}),
				preventDefaultClose: true,
				callback: onStepChange.bind(this, step, true),
				disabled: hasError || isBulkLoading,
				cy: 'bulk-save-button',
			}
		);
	}
	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			buttons={modalButtons}
			headerText={formatMessage({id: 'invoicing.create_invoice'})}
			wrapperClassName="create-invoice-modal-wrapper"
			cy={'create-invoice'}
		/>
	);
};

export default CreateInvoiceModalV2;
