import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Moment from 'moment';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, INITIALS_SIZE, MODULE_TYPES, PROJECT_STATUS} from '../../constants';
import RadioButton from '../../components/new-ui/radio_button';
import DateRangePicker from '../../components/new-ui/date-controls/date_range_picker';
import InputField from '../../components/new-ui/input_field';
import Util from '../../forecast-app/shared/util/util';
import RichTextField from '../../components/new-ui/rich_text_field';
import {getAllocationTotal} from '../../components/scheduling/project_allocation_logic';
import CreateMultipleAllocationMutation from '../../mutations/create_multiple_allocations_mutation';
import {createAllocations} from '../../components/scheduling/scheduling_mutations';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import CheckboxRow from '../../components/canvas-scheduling/components/CheckboxRow';
import {isDateDisabled} from '../../forecast-app/shared/util/DateUtil';
import AllocationTypeSelector from '../../components/canvas-scheduling/components/AllocationTypeSelector';
import styled from 'styled-components';
import ExpandIcon from '../../images/v2/components/dropdown/expand_icon';
import {canApproveAllocation} from '../../forecast-app/shared/util/PermissionsUtil';
import {profilePicSrc} from '../../directApi';

class allocateTeamMembersModalV2 extends Component {
	constructor(props) {
		super(props);
		let persons = [],
			personFullDurationAllocationMap = new Map(),
			projectStartDate = null,
			projectEndDate = null,
			startDateMap = new Map(),
			endDateMap = new Map(),
			distributionArrayMap = new Map(),
			isDistributionExpandedMap = new Map(),
			allocationTotalMap = new Map(),
			personsWeeklyTotalMap = new Map(),
			personsPercentageMap = new Map(),
			startDate,
			makeAllocationsSoftFromStart,
			endDate;
		if (props.viewer.projectGroup) {
			const projectGroup = props.viewer.projectGroup;
			projectGroup.projects.edges.forEach(project => {
				const connectedProjectsStartDate = project.node.projectStartDay
					? Util.CreateNonUtcMomentDate(
							project.node.projectStartYear,
							project.node.projectStartMonth,
							project.node.projectStartDay
					  )
					: null;
				const connectedProjectsEndDate = project.node.projectEndDay
					? Util.CreateNonUtcMomentDate(
							project.node.projectEndYear,
							project.node.projectEndMonth,
							project.node.projectEndDay
					  )
					: null;
				if (projectStartDate === null || projectStartDate.isAfter(connectedProjectsStartDate)) {
					projectStartDate = connectedProjectsStartDate;
				}
				if (projectEndDate === null || projectEndDate.isBefore(connectedProjectsEndDate)) {
					projectEndDate = connectedProjectsEndDate;
				}
			});

			if (projectGroup.projectGroupPersons) {
				persons = projectGroup.projectGroupPersons.edges
					.filter(pp => props.selectedTeamMembersIds.includes(pp.node.person.id))
					.map(pp => pp.node.person);
			}
			makeAllocationsSoftFromStart =
				hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) && !canApproveAllocation();
		} else if (props.viewer.project) {
			const project = props.viewer.project;
			projectStartDate = project.projectStartDay
				? Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay)
				: null;
			projectEndDate = project.projectEndDay
				? Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay)
				: null;
			persons = project.projectPersons.edges
				.filter(pp => props.selectedTeamMembersIds.includes(pp.node.person.id) && pp.node.person.active)
				.map(pp => pp.node.person);
			makeAllocationsSoftFromStart =
				hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? project?.status === PROJECT_STATUS.OPPORTUNITY || !canApproveAllocation()
					: false;
		}
		startDate = projectStartDate ? projectStartDate.clone() : Moment();
		endDate = projectStartDate && projectEndDate ? projectEndDate : startDate.clone().add(1, 'month');
		const allocationsSoftMap = new Map();
		persons.forEach(p => {
			personFullDurationAllocationMap.set(p.id, projectStartDate && projectEndDate);
			startDateMap.set(p.id, startDate);
			endDateMap.set(p.id, endDate);
			const mon = p.monday ? p.monday : props.viewer.company.monday,
				tue = p.tuesday ? p.tuesday : props.viewer.company.tuesday,
				wed = p.wednesday ? p.wednesday : props.viewer.company.wednesday,
				thu = p.thursday ? p.thursday : props.viewer.company.thursday,
				fri = p.friday ? p.friday : props.viewer.company.friday,
				sat = p.saturday ? p.saturday : props.viewer.company.saturday,
				sun = p.sunday ? p.sunday : props.viewer.company.sunday;

			const distributionArray =
				props.viewer.language === 'ENGLISH_US'
					? [sun, mon, tue, wed, thu, fri, sat]
					: [mon, tue, wed, thu, fri, sat, sun];
			distributionArrayMap.set(p.id, distributionArray);
			isDistributionExpandedMap.set(p.id, false);
			const allocObject = {
				monday: mon,
				tuesday: tue,
				wednesday: wed,
				thursday: thu,
				friday: fri,
				saturday: sat,
				sunday: sun,
			};
			allocationTotalMap.set(p.id, getAllocationTotal(allocObject, null, startDate, endDate));
			const weeklyPersonsTotal = mon + tue + wed + thu + fri + sat + sun;
			personsWeeklyTotalMap.set(p.id, weeklyPersonsTotal);
			const weeklyAllocationMinutes = distributionArray.reduce((total, value) => total + value, 0);
			personsPercentageMap.set(p.id, (weeklyAllocationMinutes / weeklyPersonsTotal) * 100);
			allocationsSoftMap.set(p.id, makeAllocationsSoftFromStart);
		});
		this.state = {
			persons,
			personFullDurationAllocationMap,
			projectStartDate,
			projectEndDate,
			notesMap: new Map(),
			startDateMap,
			endDateMap,
			distributionArrayMap,
			isDistributionExpandedMap,
			allocationTotalMap,
			personsWeeklyTotalMap,
			personsPercentageMap,
			makeAllocationsSoftFromStart: makeAllocationsSoftFromStart,
			allocationsSoftMap: allocationsSoftMap,
		};
	}

	changeFullDurationSelected(personId, type) {
		const personFullDurationAllocationMap = this.state.personFullDurationAllocationMap,
			startDateMap = this.state.startDateMap,
			endDateMap = this.state.endDateMap;
		personFullDurationAllocationMap.set(personId, type === 'full-duration');
		if (type === 'full-duration') {
			startDateMap.set(personId, this.state.projectStartDate);
			endDateMap.set(personId, this.state.projectEndDate);
		}
		this.setState({personFullDurationAllocationMap, startDateMap, endDateMap});
	}

	handleDatesChange(personId, startDate, endDate) {
		const startDateMap = this.state.startDateMap;
		const endDateMap = this.state.endDateMap;
		startDateMap.set(personId, startDate);
		endDateMap.set(personId, endDate);
		this.setState({startDateMap, endDateMap});
		this.adjustValues(personId);
	}

	handleNotesChange(personId, content) {
		const notesMap = this.state.notesMap;
		notesMap.set(personId, content);
		this.setState({notesMap});
	}

	handleAllocationSoftChange(personId, boolean_value) {
		const allocationsSoftMap = this.state.allocationsSoftMap;
		allocationsSoftMap.set(personId, boolean_value);
		this.setState({allocationsSoftMap});
	}

	toggleDistributionExpansion(personId) {
		const isDistributionExpandedMap = this.state.isDistributionExpandedMap;
		isDistributionExpandedMap.set(personId, !this.state.isDistributionExpandedMap.get(personId));
		this.setState({isDistributionExpandedMap});
	}

	handleDistributionChange(index, personId, adjustedDistributionArray, value) {
		const {startDateMap, endDateMap, distributionArrayMap, personsPercentageMap, allocationTotalMap} = this.state;
		const distributionArray = adjustedDistributionArray || distributionArrayMap.get(personId);

		if (index !== null && index !== undefined) {
			distributionArray[index] = value * 60;
		}
		let percentage = this.getPercentage(distributionArray, personId);
		if (adjustedDistributionArray) {
			percentage = Math.round(percentage * 100) / 100;
		}
		const total = this.getTotal(distributionArray, startDateMap.get(personId), endDateMap.get(personId));
		personsPercentageMap.set(personId, percentage);
		allocationTotalMap.set(personId, total);
		distributionArrayMap.set(personId, distributionArray);
		this.setState({personsPercentageMap, allocationTotalMap, distributionArrayMap});
	}

	getPersonById(personId) {
		let projectPerson = null;
		if (this.props.viewer.project) {
			projectPerson = this.props.viewer.project.projectPersons.edges.find(pp => pp.node.person.id === personId);
		} else if (this.props.viewer.projectGroup) {
			projectPerson = this.props.viewer.projectGroup.projectGroupPersons.edges.find(pp => pp.node.person.id === personId);
		}
		if (projectPerson) {
			return projectPerson.node.person;
		}
		return null;
	}

	handlePercentageChange(personId, value) {
		const {allocationTotalMap, personsPercentageMap, distributionArrayMap, startDateMap, endDateMap} = this.state;
		const percentage = value;
		const person = this.getPersonById(personId);
		const personWorkingMinutes =
			this.props.viewer.language === 'ENGLISH_US'
				? [
						person.sunday,
						person.monday,
						person.tuesday,
						person.wednesday,
						person.thursday,
						person.friday,
						person.saturday,
				  ]
				: [
						person.monday,
						person.tuesday,
						person.wednesday,
						person.thursday,
						person.friday,
						person.saturday,
						person.sunday,
				  ];

		const distributionArray = personWorkingMinutes.map(minutes => {
			return (minutes * percentage) / 100;
		});
		const total = this.getTotal(distributionArray, startDateMap.get(personId), endDateMap.get(personId));
		allocationTotalMap.set(personId, total);
		personsPercentageMap.set(personId, percentage);
		distributionArrayMap.set(personId, distributionArray);
		this.setState({allocationTotalMap, personsPercentageMap, distributionArrayMap});
	}

	getPercentage(distributionArray, personId) {
		const person = this.getPersonById(personId);
		const weeklyAllocationMinutes = distributionArray.reduce((total, value) => total + value, 0);
		const weeklyPersonMinutes =
			person.monday +
			person.tuesday +
			person.wednesday +
			person.thursday +
			person.friday +
			person.saturday +
			person.sunday;
		return (weeklyAllocationMinutes / weeklyPersonMinutes) * 100;
	}

	getTotal(distributionArray, startDate, endDate) {
		const allocObject =
			this.props.viewer.language === 'ENGLISH_US'
				? {
						monday: distributionArray[1],
						tuesday: distributionArray[2],
						wednesday: distributionArray[3],
						thursday: distributionArray[4],
						friday: distributionArray[5],
						saturday: distributionArray[6],
						sunday: distributionArray[0],
				  }
				: {
						monday: distributionArray[0],
						tuesday: distributionArray[1],
						wednesday: distributionArray[2],
						thursday: distributionArray[3],
						friday: distributionArray[4],
						saturday: distributionArray[5],
						sunday: distributionArray[6],
				  };
		return getAllocationTotal(allocObject, null, startDate, endDate);
	}

	adjustValues(personId) {
		let distributionArray = this.state.distributionArrayMap.get(personId);
		distributionArray = distributionArray.map(minutes => {
			return Util.getMinuteEstimate(minutes / 60);
		});
		this.handleDistributionChange(null, personId, distributionArray);
	}

	handleChangeAndAdjustValues(personId, value) {
		const {startDateMap, endDateMap, personsPercentageMap, allocationTotalMap, distributionArrayMap} = this.state;
		const total = value * 60;
		const person = this.getPersonById(personId);
		const personWorkingMinutes =
			this.props.viewer.language === 'ENGLISH_US'
				? [
						person.sunday,
						person.monday,
						person.tuesday,
						person.wednesday,
						person.thursday,
						person.friday,
						person.saturday,
				  ]
				: [
						person.monday,
						person.tuesday,
						person.wednesday,
						person.thursday,
						person.friday,
						person.saturday,
						person.sunday,
				  ];
		const allocationWeekdayCountArray = [1, 2, 3, 4, 5, 6, 7].map(e =>
			Util.weekdaysBetween(startDateMap.get(personId), endDateMap.get(personId), e)
		);
		const allocationWorkingDayCount = allocationWeekdayCountArray.reduce((total, weekdayCount, index) => {
			return total + (personWorkingMinutes[index] ? weekdayCount : 0);
		}, 0);
		const minutesPerDay = total / allocationWorkingDayCount;
		let distributionArray = [];
		for (let i = 0; i < 7; i++) {
			distributionArray.push(personWorkingMinutes[i] ? minutesPerDay : 0);
		}
		const percentage = this.getPercentage(distributionArray, personId);
		distributionArrayMap.set(personId, distributionArray);
		allocationTotalMap.set(personId, total);
		personsPercentageMap.set(personId, percentage);
		this.setState({distributionArrayMap, allocationTotalMap, personsPercentageMap});

		distributionArray = distributionArray.map(minutes => {
			return Util.getMinuteEstimate(minutes / 60);
		});
		this.handleDistributionChange(null, personId, distributionArray);
	}

	allocateTeamMembers() {
		//create allocations
		const allocations = [];
		this.props.selectedTeamMembersIds.forEach(personId => {
			const endDate = Util.GetYearMonthDateFromMomentDate(this.state.endDateMap.get(personId));
			const startDate = Util.GetYearMonthDateFromMomentDate(this.state.startDateMap.get(personId));
			const distributionArray = this.state.distributionArrayMap.get(personId);
			let mon, tue, wed, thu, fri, sat, sun;
			if (this.props.viewer.language === 'ENGLISH_US') {
				mon = distributionArray[1];
				tue = distributionArray[2];
				wed = distributionArray[3];
				thu = distributionArray[4];
				fri = distributionArray[5];
				sat = distributionArray[6];
				sun = distributionArray[0];
			} else {
				mon = distributionArray[0];
				tue = distributionArray[1];
				wed = distributionArray[2];
				thu = distributionArray[3];
				fri = distributionArray[4];
				sat = distributionArray[5];
				sun = distributionArray[6];
			}
			const obj = {
				personId: personId,
				startYear: startDate.year,
				startMonth: startDate.month,
				startDay: startDate.day,
				endYear: endDate.year,
				endMonth: endDate.month,
				endDay: endDate.day,
				monday: mon,
				tuesday: tue,
				wednesday: wed,
				thursday: thu,
				friday: fri,
				saturday: sat,
				sunday: sun,
				description: this.state.notesMap.get(personId),
				isSoft: this.state.allocationsSoftMap.get(personId),
			};
			if (this.props.viewer.project) {
				obj.projectId = this.props.viewer.project.id;
			} else if (this.props.viewer.projectGroup) {
				obj.projectGroupId = this.props.viewer.projectGroup.id;
			}
			allocations.push(obj);
		});
		const mutationObject = {
			allocations: allocations,
		};
		if (this.props.viewer.project) {
			mutationObject.projectId = this.props.viewer.project.id;
		} else if (this.props.viewer.projectGroup) {
			mutationObject.projectGroupId = this.props.viewer.projectGroup.id;
		}
		if (Util.isScheduling()) {
			const onSuccess = response => {
				Util.dispatchScheduleEvent(response);
			};
			mutationObject.csrfToken = Util.getCsrfValue();
			mutationObject.socketClientId = Util.getClientId();
			createAllocations(mutationObject, onSuccess);
		} else {
			Util.CommitMutation(CreateMultipleAllocationMutation, mutationObject);
		}
	}

	getDaysHTML(personId) {
		const {formatDate} = this.props.intl;
		const loopDate = Moment().startOf('week');
		return (
			<WeekDaysWrapper>
				{this.state.distributionArrayMap.get(personId).map((dayWorkingHours, index) => (
					<div key={'dist-key-' + index} className={'input-container-v2'}>
						<label className={'input-title'}>
							{formatDate(loopDate.add(index === 0 ? 0 : 1, 'day'), {
								weekday: 'short',
							})}
						</label>
						<HoursInput
							value={dayWorkingHours / 60}
							mutation={this.handleDistributionChange.bind(this, loopDate.weekday(), personId, null)}
						></HoursInput>
					</div>
				))}
			</WeekDaysWrapper>
		);
	}

	getPersonAverageWorkingMinutes(person) {
		const personWorkingMinutes = [
			person.monday,
			person.tuesday,
			person.wednesday,
			person.thursday,
			person.friday,
			person.saturday,
			person.sunday,
		];
		const totalWorkingDays = personWorkingMinutes.reduce((total, minutes) => total + (minutes ? 1 : 0), 0);
		return (
			(person.monday +
				person.tuesday +
				person.wednesday +
				person.thursday +
				person.friday +
				person.saturday +
				person.sunday) /
			totalWorkingDays
		);
	}

	getContent() {
		const {formatMessage, formatDate} = this.props.intl;

		return (
			<AllocateTeamModalV2>
				<div className="allocation-info">{formatMessage({id: 'new_project_modal.allocate_description'})}</div>
				{this.state.persons.map((person, index) => (
					<PersonWrapper key={index} className="person-wrapper">
						<div className="person-info">
							{person.profilePictureId ? (
								<img
									crossOrigin="use-credentials"
									alt="profile_pic"
									src={profilePicSrc(person.profilePictureId)}
									width="36"
									height="36"
								/>
							) : (
								<PersonInitials
									initials={Util.getInitials(`${person.firstName} ${person.lastName}`)}
									size={INITIALS_SIZE.LARGE}
								/>
							)}
							<div className="name-email">
								<span className="name">{person.firstName + ' ' + person.lastName}</span>
								<span className="email">{person.email}</span>
							</div>
						</div>
						<div className="radio-button-outer-wrapper">
							<RadioButton
								onSelected={this.changeFullDurationSelected.bind(this, person.id, 'full-duration')}
								value={'full-duration'}
								stateValue={
									this.state.personFullDurationAllocationMap.get(person.id)
										? 'full-duration'
										: 'custom-duration'
								}
								locked={!this.state.projectStartDate || !this.state.projectEndDate}
							/>
							<span
								className={
									'radio-button-info' +
									(!this.state.projectStartDate || !this.state.projectEndDate ? ' locked' : '')
								}
							>
								{formatMessage({id: 'project_allocation_modal.whole_project_group_option'}) +
									(this.state.projectStartDate && this.state.projectEndDate
										? ' (' +
										  formatDate(this.state.projectStartDate) +
										  ' - ' +
										  formatDate(this.state.projectEndDate) +
										  ')'
										: '')}
							</span>
						</div>
						<div className="radio-button-outer-wrapper">
							<RadioButton
								onSelected={this.changeFullDurationSelected.bind(this, person.id, 'custom-duration')}
								value={'custom-duration'}
								stateValue={
									this.state.personFullDurationAllocationMap.get(person.id)
										? 'full-duration'
										: 'custom-duration'
								}
							/>
							<span className="radio-button-info">
								{formatMessage({id: 'project_allocation_modal.custom_date_option'})}
							</span>
						</div>
						{this.state.personFullDurationAllocationMap.get(person.id) ? null : (
							<>
								<Container>
									<DateWrapper>
										<label className={'input-title'}>{'DATE'}</label>
										<DateRangePicker
											cy={'date-picker-button'}
											handleDateRangeChange={this.handleDatesChange.bind(this, person.id)}
											startDate={this.state.startDateMap.get(person.id)}
											endDate={this.state.endDateMap.get(person.id)}
											useFixedPosition={true}
											disabledDates={isDateDisabled}
										/>
									</DateWrapper>

									<AllocationTotalWrapper>
										<label className={'input-title'}>
											{formatMessage({id: 'scheduling.allocation_total'})}
										</label>
										<HoursInput
											modalView
											value={this.state.allocationTotalMap.get(person.id) / 60}
											mutation={this.handleChangeAndAdjustValues.bind(this, person.id)}
											placeholder={formatMessage({id: 'hours_input.placeholder'})}
										></HoursInput>
									</AllocationTotalWrapper>
									<HourPerDayWrapper>
										<InputField
											value={this.state.personsPercentageMap.get(person.id)}
											type="number"
											onChange={this.handlePercentageChange.bind(this, person.id)}
											placeholder={''}
											label={`% ${formatMessage(
												{id: 'scheduling.allocation_modal.hours_pr_day'},
												{
													hours: Util.getHourEstimate(
														this.getPersonAverageWorkingMinutes(person) / 60
													),
												}
											)}`}
											suffix="%"
											onBlur={this.adjustValues.bind(this, person.id)}
										/>
									</HourPerDayWrapper>
								</Container>
								<ExpandContainer onClick={this.toggleDistributionExpansion.bind(this, person.id)}>
									{formatMessage({id: 'common.distribution'})}
									<ExpandIcon expanded={this.state.isDistributionExpandedMap.get(person.id)} />
								</ExpandContainer>
							</>
						)}
						{this.state.isDistributionExpandedMap.get(person.id) ? this.getDaysHTML(person.id) : null}
						{hasFeatureFlag('capacity_planning_beta_2_improvements') &&
						hasFeatureFlag('placeholders') &&
						hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) ? (
							<AllocationTypeSelectorWrapper>
								<AllocationTypeSelector
									onChange={() => {
										this.handleAllocationSoftChange(
											person.id,
											!this.state.allocationsSoftMap.get(person.id)
										);
									}}
									isSoft={this.state.allocationsSoftMap.get(person.id)}
									disabled={!canApproveAllocation()}
								/>
							</AllocationTypeSelectorWrapper>
						) : null}
						<RichTextField
							handleTextChange={this.handleNotesChange.bind(this, person.id)}
							text={this.state.notesMap.get(person.id)}
							textFieldLabel={formatMessage({id: 'common.notes'})}
						/>
						{!hasFeatureFlag('capacity_planning_beta_2_improvements') &&
						hasFeatureFlag('placeholders') &&
						hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) ? (
							<CheckboxRowAllocateModal>
								<CheckboxRow
									checked={this.state.allocationsSoftMap.get(person.id)}
									cy={'allocate-modal-soft'}
									highlightChecked
									onChange={() => {
										this.handleAllocationSoftChange(
											person.id,
											!this.state.allocationsSoftMap.get(person.id)
										);
									}}
									disabled={!canApproveAllocation()}
									label={formatMessage({id: 'allocation.soft_allocation'})}
								/>
							</CheckboxRowAllocateModal>
						) : null}
					</PersonWrapper>
				))}
			</AllocateTeamModalV2>
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const buttons = [
			{
				text: formatMessage({id: 'project_allocation_modal.skip'}),
				callback: this.props.closeModal.bind(this),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				cy: 'skip-button-modal',
			},
			{
				text: formatMessage({id: 'scheduling.allocate'}),
				cy: 'allocate-button-modal',
				callback: this.allocateTeamMembers.bind(this),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
			},
		];
		return (
			<GenericModal
				useScrollbars={true}
				closeModal={this.props.closeModal}
				headerText={formatMessage({id: 'scheduling.allocate'})}
				buttons={buttons}
				content={this.getContent()}
			/>
		);
	}
}

const allocateTeamMembersModalV2Query = graphql`
	query allocateTeamMembersModalV2_Query($projectId: ID, $groupId: ID, $fetchProject: Boolean!) {
		viewer {
			actualPersonId
			component(name: "allocate_team_member")
			...allocateTeamMembersModalV2_viewer
				@arguments(projectId: $projectId, groupId: $groupId, fetchProject: $fetchProject)
		}
	}
`;

const AllocateTeamModalV2 = styled.div`
	padding-right: 58px;
	width: 638px;

	.allocation-info,
	.radio-button-info {
		font-size: 14px;
		line-height: 1.43;
		color: $v2-text-gray;
	}
`;

const ExpandContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 16px;
	flex-shrink: 0;
	font-size: 12px;
	font-weight: 600;
	cursor: pointer;
	height: 38px;
	align-self: flex-start;

	.expand-icon {
		margin-left: 5px;
	}
`;

const AllocationTypeSelectorWrapper = styled.div`
	margin-top: 15px;
`;

const PersonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 25px;
	padding-bottom: 25px;
	border-bottom: solid 1px $app-border-color;

	.person-info {
		display: flex;
		align-items: center;
		flex-direction: row;
		margin-bottom: 19px;

		.name-email {
			display: flex;
			flex-direction: column;
			margin-left: 18px;

			.name {
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.5px;
				color: $v2-text-gray;
			}

			.email {
				font-size: 13px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: 0.5px;
				color: $v2-text-light-gray;
			}
		}
	}

	.radio-button-outer-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		.radio-button-info {
			margin-left: 16px;
		}
	}

	.rich-text-field-container {
		margin-top: 24px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	label {
		font-size: 9px;
		font-weight: 500;
		text-transform: uppercase;
		color: #a1a1a1;
	}
`;

const DateWrapper = styled.div`
	margin-right: 10px;

	label {
		font-size: 9px;
		font-weight: 500;
		text-transform: uppercase;
		color: #a1a1a1;
	}
`;

const AllocationTotalWrapper = styled.div`
	margin-right: 10px;

	input {
		height: 40px;
	}
`;

const HourPerDayWrapper = styled.div`
	display: flex;

	.input-container-v2 {
		.input-title {
			margin-bottom: 0;
		}

		.input-value-container {
			height: 40px;
		}

		.input-value-unit {
			line-height: 30px;
		}
	}
`;

const CheckboxRowAllocateModal = styled.div`
	margin-top: 20px;
`;

const WeekDaysWrapper = styled.div`
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
	justify-content: space-between;

	.input-container-v2 {
		width: 78px;
	}
`;

export {allocateTeamMembersModalV2Query};

export default injectIntl(
	createFragmentContainer(allocateTeamMembersModalV2, {
		viewer: graphql`
			fragment allocateTeamMembersModalV2_viewer on Viewer
			@argumentDefinitions(projectId: {type: "ID"}, groupId: {type: "ID"}, fetchProject: {type: "Boolean!"}) {
				id
				language
				availableFeatureFlags {
					key
				}
				project(internalId: $projectId) @include(if: $fetchProject) {
					id
					companyProjectId
					name
					projectStartDay
					projectStartMonth
					projectStartYear
					projectEndDay
					projectEndMonth
					projectEndYear
					status
					projectPersons(first: 1000, includeInactive: true) @connection(key: "Project_projectPersons", filters: []) {
						edges {
							node {
								id
								person {
									id
									email
									firstName
									lastName
									profilePictureDefaultId
									profilePictureId
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
									active
								}
							}
						}
					}
				}
				projectGroup(globalId: $groupId) @skip(if: $fetchProject) {
					id
					projectGroupPersons(first: 1000) @connection(key: "ProjectGroup_projectGroupPersons") {
						edges {
							node {
								id
								person {
									id
									email
									firstName
									lastName
									profilePictureDefaultId
									profilePictureId
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
								}
							}
						}
					}
					projects(first: 100000) {
						edges {
							node {
								id
								projectStartDay
								projectStartMonth
								projectStartYear
								projectEndDay
								projectEndMonth
								projectEndYear
							}
						}
					}
				}
				company {
					id
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
				}
			}
		`,
	})
);
