import React from 'react';
import {graphql} from 'react-relay';
import {personIsClientUser} from '../../forecast-app/shared/util/PermissionsUtil';
import BulkTaskUpdateModal from './bulk_task_update_modal';

const BulkTaskUpdateModalWrapper = props => {
	const role_options = props.viewer.company.roles.edges.map(role => ({value: role.node.id, label: role.node.name}));
	const people_options = props.viewer.project
		? props.viewer.project.projectPersons.edges.map(pp => ({
				value: pp.node.person.id,
				label: pp.node.person.fullName,
				profilePictureId: pp.node.person.profilePictureId,
				profilePictureDefaultId: pp.node.person.profilePictureDefaultId,
		  }))
		: props.viewer.projectGroup.projects.edges[0].node.projectPersons.edges
				.filter(pp => !personIsClientUser(pp.node.person))
				.map(pp => ({
					value: pp.node.person.id,
					label: pp.node.person.fullName,
					profilePictureId: pp.node.person.profilePictureId,
					profilePictureDefaultId: pp.node.person.profilePictureDefaultId,
				}));
	const status_options = props.viewer.project
		? props.viewer.project.statusColumns
			? props.viewer.project.statusColumns.map(col => ({value: col.id, label: col.name}))
			: props.viewer.project.statusColumnsV2.edges.map(col => ({
					value: col.node.id,
					label: col.node.name,
					logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
			  }))
		: props.viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges.map(col => ({
				value: col.node.id,
				label: col.node.name,
				logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
		  }));

	const estimationUnit = props.viewer.project
		? props.viewer.project.estimationUnit
		: props.viewer.projectGroup.projects.edges[0].node.estimationUnit;

	return (
		<BulkTaskUpdateModal
			closeModal={props.closeModal}
			useTaskFollowers={props.useTaskFollowers}
			role_options={role_options}
			people_options={people_options}
			status_options={status_options}
			labels={props.viewer.company.labels.edges}
			tasks={props.tasks}
			estimationUnit={estimationUnit}
			projectGroupId={props.viewer.projectGroup ? props.viewer.projectGroup.id : null}
			saveCallback={props.saveCallback}
			isJiraProject={props.isJiraProject}
			isClientUser={props.isClientUser}
		/>
	);
};
const BulkTaskUpdateModalWrapperQuery = graphql`
	query BulkTaskUpdateModalWrapper_Query($projectId: ID, $projectGroupId: ID) {
		viewer {
			actualPersonId
			component(name: "bulk_task_update_modal_wrapper")
			company {
				id
				# FILTER_TYPE.LABEL, FILTER_TYPE.TASK_PROJECT_LABEL
				labels(first: 1000000, labelType: TASK) {
					edges {
						node {
							id
							name
							color
							category {
								id
								name
							}
						}
					}
				}
				# FILTER_TYPE.ROLE
				roles(first: 1000000) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
			projectGroup(globalId: $projectGroupId) {
				id
				companyProjectGroupId
				name
				projectGroupPersons(first: 1000000) {
					edges {
						node {
							id
							person {
								id
								permissions
								initials
								firstName
								lastName
								fullName
								profilePictureId
								profilePictureDefaultId
							}
						}
					}
				}
				...ProjectWorkflowAddTask_projectGroup
				projects(first: 1000000) @connection(key: "ProjectGroup_projects", filters: []) {
					edges {
						node {
							estimationUnit
							id
							name
							status
							projectColor
							companyProjectId
							taskLevels
							sprintTimeBox
							useTaskOwner
							useTaskFollowers
							projectPersons(first: 100000) {
								edges {
									node {
										id
										person {
											id
											permissions
											initials
											firstName
											lastName
											fullName
											profilePictureId
											profilePictureDefaultId
										}
									}
								}
							}
							statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
								edges {
									node {
										id
										name
										order
										category
										projectGroupStatusColumnId
										encourageTimeRegistration
										jiraStatusId
										adoState
										userActions {
											canDelete
											canRename
											canCreateTask
										}
									}
								}
							}
						}
					}
				}
			}
			project(internalId: $projectId) {
				id
				taskLevels
				status
				companyProjectId
				sprintTimeBox
				useTaskFollowers
				useTaskOwner
				projectColor
				name
				estimationUnit
				isJiraProject
				jiraCloudProject {
					id
				}
				jiraCloudEpicIds
				vstsProject
				vstsAccount
				vstsArea
				isInProjectGroup
				projectPersons(first: 1000000) {
					edges {
						node {
							person {
								id
								initials
								firstName
								lastName
								fullName
								profilePictureId
								profilePictureDefaultId
							}
						}
					}
				}
				statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
					edges {
						node {
							id
							name
							order
							category
							encourageTimeRegistration
							jiraStatusId
							adoState
							userActions {
								canDelete
								canRename
								canCreateTask
							}
						}
					}
				}
			}
		}
	}
`;

export {BulkTaskUpdateModalWrapperQuery};

export default BulkTaskUpdateModalWrapper;
