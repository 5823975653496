import {TopHeaderBar} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../constants';

export const getProjectHealthTopHeaderContent = () => {
	const content = [];
	const onboardingFlows = [
		{
			id: 'nova-insights-introduction',
			title: 'Nova Insights - Intro',
			description: null,
			contentId: '1645723588hAox7942',
		},
	];

	const onboardingComponent = {
		id: 'onboarding-component',
		type: TopHeaderBar.TYPE.ONBOARDING,
		title: 'Learn to use Nova Insights',
		options: onboardingFlows,
		helpCenterLink: 'https://support.forecast.app/hc/en-us/articles/5776859840529-Nova-Insights-Predicted-Project-End',
	};
	content.push(onboardingComponent);

	return content;
};

export const getProjectHealthHeader = (intl, projectDisabled) => {
	const leftContent = [];
	const rightContent = [];

	const button = {
		type: ELEMENT_TYPE.BUTTON,
		text: intl.formatMessage({id: 'settings.project-status'}),
		style: BUTTON_STYLE.OUTLINE,
		color: BUTTON_COLOR.PURPLE,
		dataCy: '-button',
		disabled: projectDisabled,
	};
	rightContent.push(button);
	return buildHeaderBar(leftContent, rightContent);
};
