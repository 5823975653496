import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import LoginMutation from '../mutations/login_mutation';
import * as tracking from '../tracking';
import {injectIntl} from 'react-intl';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import CreateSalesforceVerificationKeyMutation from '../mutations/create_salesforce_verification_key_mutation';
import Util from '../forecast-app/shared/util/util';
import {MODULE_TYPES} from '../constants';
import {Checkbox} from 'web-components';
import {hasModule, setEnabledModules} from '../forecast-app/shared/util/ModuleUtil';
import DirectApi from '../directApi';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class loginSalesforce extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			showSignUp: false,
			showInvalidEmail: false,
			showEmailInUse: false,
			frontendError: false,
			showOverlay: false,
			useSandbox: false,
		};
		this.props.setLocaleFromBrowser();

		this.superPropertyChecksum = trackPage('Login - Salesforce');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('login-salesforce');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		const email = this.signInEmail.value.trim();
		const password = this.signInPassword.value.trim();

		if (!password.length) {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.password-empty'})});
			return;
		}

		if (!email.length) {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.enter-email-address'})});
			return;
		}

		const onSuccess = ({login}) => {
			if (login.viewer.actualPersonId) {
			} else {
				if (login.errors && login.errors.length > 0 && login.errors[0] === 'NO_LOGIN') {
					this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.no-permission'})});
				} else {
					this.setState({
						errorMsg: this.props.intl.formatMessage({id: 'login.invalid-user-pass'}),
					});
				}
			}
		};

		Util.CommitMutation(
			LoginMutation,
			{
				email: email,
				password: password,
				rememberMe: true,
			},
			onSuccess
		);
	}

	handleSignupSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		this.setState({
			frontendError: false,
			errorMsg: null,
		});

		const email = this.refs.economicPersonEmailSignup.value.trim();

		const valid = /(.+)@(.+)\.(.+)/.test(email);
		if (valid) {
			let xhr = new XMLHttpRequest();

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					this.setState({showOverlay: false});
					const response = JSON.parse(xhr.responseText);
					let resultCode = response.resultCode;
					if (resultCode === '0') {
						const key = response.key;
						this.props.history.push(`/first/${key}/${email}?salesforce=true`);
					} else if (resultCode === '1') {
						this.setState({
							showInvalidEmail: true,
							showEmailInUse: false,
						});
					} else if (resultCode === '2') {
						this.setState({
							showInvalidEmail: false,
							showEmailInUse: true,
						});
					} else if (resultCode === '4') {
						this.setState({
							showInvalidEmail: true,
							showEmailInUse: false,
						});
					}
				}
			};

			this.setState({showOverlay: true});
			let url = DirectApi.graphqlServerEndpoint('signup/?source=salesforce&email=' + email);
			xhr.open('GET', url, true);
			xhr.setRequestHeader('Content-Type', 'text/plain');
			xhr.send(null);
		} else {
			this.setState({
				showInvalidEmail: true,
				showEmailInUse: false,
			});
		}
	}

	continue() {
		this.props.history.push('/');
	}

	connect_to_salesforce() {
		const onSuccess = ({createSalesforceVerificationKey}) => {
			this.setState({showOverlay: false});
			const redirectUri = process.env.SALESFORCE_REDIRECT_URI;
			const clientId = process.env.SALESFORCE_CLIENT_ID;
			window.location.href = `https://${
				this.state.useSandbox ? 'test' : 'login'
			}.salesforce.com/services/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${
				createSalesforceVerificationKey.key
			}`;
		};

		this.setState({showOverlay: true});
		Util.CommitMutation(CreateSalesforceVerificationKeyMutation, {useSandbox: this.state.useSandbox}, onSuccess);
	}

	sign_up_clicked() {
		this.setState({showSignUp: !this.state.showSignUp});
	}

	renderContent() {
		const {formatMessage} = this.props.intl;
		if (this.props.viewer.actualPersonId) {
			setEnabledModules(this.props.viewer.company.modules);
			if (!hasModule(MODULE_TYPES.SALESFORCE)) {
				return (
					<div>
						<h1>{formatMessage({id: 'signup.salesforce_not_installable'})}</h1>
					</div>
				);
			}
			if (this.props.viewer.company.salesforceEnabled) {
				return (
					<div>
						<h1>{formatMessage({id: 'signup.salesforce_already_linked'})}</h1>
						<button className="buttons-continue" onClick={this.continue.bind(this)}>
							{formatMessage({id: 'common.continue'})}
						</button>
					</div>
				);
			}

			return (
				<div className="connect-economic-outer">
					<h1>Hi {this.props.viewer.firstName ? this.props.viewer.firstName + ',' : null}</h1>
					<h2>{formatMessage({id: 'signup.connect_salesforce'})}</h2>
					<div className="sandbox-container">
						<Checkbox
							checked={this.state.useSandbox}
							onClick={() => this.setState({useSandbox: !this.state.useSandbox})}
						/>
						<span>Use Sandbox Environment</span>
					</div>

					<div className="button-container">
						<button className="buttons-ok" onClick={this.connect_to_salesforce.bind(this)}>
							{formatMessage({id: 'common.ok'})}
						</button>
					</div>
				</div>
			);
		}

		if (this.state.showSignUp) {
			return (
				<form onSubmit={this.handleSignupSubmit.bind(this)}>
					<h1>{formatMessage({id: 'signup.signup_account'})}</h1>
					<div className="fieldset">
						<input
							type="email"
							key="economic_person_email_signup"
							ref="economicPersonEmailSignup"
							placeholder={formatMessage({id: 'login.enter-email-address'})}
							defaultValue={this.state.economicEmail}
						/>
						{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
						{this.state.showInvalidEmail ? (
							<div className="error">{formatMessage({id: 'signup.email-is-invalid'})}</div>
						) : null}
						{this.state.showEmailInUse ? (
							<div className="error">{formatMessage({id: 'signup.email-is-in-use'})}</div>
						) : null}
					</div>

					<div className="button-signin">
						<input
							name="login"
							type="submit"
							value={formatMessage({id: 'login.salesforce_signup_and_connect'})}
							className={'btn btn-pull-right'}
						/>
					</div>
					<span onClick={this.sign_up_clicked.bind(this)} className="sign-up-link">
						{formatMessage({id: 'signup.economic-go_back_have_account'})}
					</span>
				</form>
			);
		}

		return (
			<form onSubmit={this.handleSubmit.bind(this)}>
				<h1>{formatMessage({id: 'login.title'})}</h1>
				<div className="fieldset">
					<input
						type="email"
						key="economic_person_email_signin"
						ref={input => (this.signInEmail = input)}
						placeholder={formatMessage({id: 'login.enter-email-address'})}
					/>
					<input
						type="password"
						name="password"
						ref={input => (this.signInPassword = input)}
						defaultValue=""
						placeholder={formatMessage({id: 'login.enter-password'})}
					/>
					{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
				</div>

				<div className="button-signin">
					<input
						name="login"
						type="submit"
						value={formatMessage({id: 'login.log-in'})}
						className={'btn btn-pull-right'}
					/>
				</div>
				<span onClick={this.sign_up_clicked.bind(this)} className="sign-up-link">
					{formatMessage({id: 'login.dont-have-acc'})}
				</span>
			</form>
		);
	}

	render() {
		return (
			<div className="fullscreen fullscreen-centered-outer login-economic login-bg">
				{this.state.showOverlay ? <UploadingOverlay /> : null}
				<div className="fullscreen-centered-inner">
					<div className="logos">
						<div className="logo_forecast" />
						<div className="logo_and" />
						<div className="logo-salesforce" />
					</div>

					<div className="fullscreen-centered-content form-vertical">{this.renderContent()}</div>
				</div>
			</div>
		);
	}
}

const loginSalesforceQuery = graphql`
	query loginSalesforce_Query {
		viewer {
			component(name: "login_salesforce")
			...loginSalesforce_viewer
		}
	}
`;

export {loginSalesforceQuery};

export default createFragmentContainer(injectIntl(withRouter(loginSalesforce)), {
	viewer: graphql`
		fragment loginSalesforce_viewer on Viewer {
			id
			actualPersonId
			firstName
			lastName
			company {
				id
				salesforceEnabled
				modules {
					moduleType
				}
			}
		}
	`,
});
