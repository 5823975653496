import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InputField from '../../components/new-ui/input_field';
import Checkbox from '../../components/new-ui/check_box';
import SelectInput from '../../components/inputs/select_input';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CreateDepartmentMutation from '../../mutations/create_department_mutation';
import CreateTeamMutation from '../../mutations/create_team_mutation';
import UpdateTeamMutation from '../../mutations/update_team_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';
import PersonSelector from '../../components/person_selector';
import GenericModal from './generic_modal';
import Person from '../../forecast-app/shared/components/person/person';
import Search from '../../components/new-ui//search';
import {profilePicSrc} from '../../directApi';

class AddTeamModal extends Component {
	constructor(props) {
		super(props);
		const selectedPersons = this.props.initialTeam
			? this.props.teams
					.find(team => team.node.id === this.props.initialTeam)
					.node.teamPersons.edges.map(tp => tp.node.person.id)
			: this.props.team
			? this.props.team.teamPersons.edges.map(tp => tp.node.person.id)
			: [];
		this.state = {
			name: this.props.team ? this.props.team.name : '',
			selectedPersons: selectedPersons,
			selectedTeam: this.props.initialTeam,
			searchValue: '',
		};
	}

	handleNameChange(value) {
		this.setState({name: value});
	}

	createTeam() {
		if (this.props.addDepartment) {
			Util.CommitMutation(
				CreateDepartmentMutation,
				{
					name: this.state.name,
					companyId: this.props.companyId,
					persons: this.state.selectedPersons,
				},
				() => {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'settings_team.department_added'}),
					});
					this.props.closeModal();
				},
				true
			);
			return;
		}

		if (this.props.initialTeam) {
			this.props.closeModal();
			this.props.callbackPositive(this.state.selectedPersons);
		} else if (this.props.team) {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_team.team_updated'})});
				this.props.closeModal();
			};
			Util.CommitMutation(
				UpdateTeamMutation,
				{
					id: this.props.team.id,
					name: this.state.name,
					persons: this.state.selectedPersons,
				},
				onSuccess,
				true
			);
		} else {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_team.team_added'})});
				this.props.closeModal();
			};
			Util.CommitMutation(
				CreateTeamMutation,
				{
					name: this.state.name,
					companyId: this.props.companyId,
					persons: this.state.selectedPersons,
				},
				onSuccess,
				true
			);
		}
	}

	selectAll(e) {
		let selectedPersons = [];
		if (this.state.selectedPersons.length === 0 && this.props.initialTeam) {
			const team = this.props.teams.find(t => t.node.id === this.state.selectedTeam);
			selectedPersons = team.node.teamPersons.edges.map(tp => tp.node.person.id);
		} else if (this.state.selectedPersons.length === 0) {
			selectedPersons = this.props.persons;
			if (this.state.searchValue !== '') {
				selectedPersons = selectedPersons.filter(person => {
					return (person.node.fullName + person.node.email)
						.toUpperCase()
						.includes(this.state.searchValue.toUpperCase());
				});
			}
			selectedPersons = selectedPersons.map(p => p.node.id);
		}
		this.setState({selectedPersons});
	}

	selectPerson(person, e) {
		//Need to prevent checkbox change and onclick from triggering at the same time and nullifying each other
		if (this.preventSelectEvent) return;
		this.preventSelectEvent = true;
		let selectedPersons = this.state.selectedPersons;
		if (selectedPersons.includes(person.node.id)) {
			selectedPersons = selectedPersons.filter(id => id !== person.node.id);
		} else {
			selectedPersons.push(person.node.id);
		}
		this.setState({selectedPersons}, () => {
			this.preventSelectEvent = false;
		});
	}

	handleTeamSelectChange(selected) {
		const team = this.props.teams.find(team => team.node.id === selected.value);
		const selectedPersons = team.node.teamPersons.edges.map(tp => tp.node.person.id);
		this.setState({selectedTeam: selected.value, selectedPersons: selectedPersons});
	}

	onSearchChange(searchValue) {
		this.setState({searchValue});
	}

	addPersonToTeam(selected) {
		const selectedPersons = this.state.selectedPersons;
		selectedPersons.push(selected);
		this.setState({selectedPersons});
	}

	render() {
		const {formatMessage} = this.props.intl;
		let persons = [];
		if (this.props.initialTeam) {
			const team = this.props.teams.find(t => t.node.id === this.state.selectedTeam);
			team.node.teamPersons.edges.forEach(tp => persons.push({node: tp.node.person}));
		} else if (this.props.team) {
			this.props.team.teamPersons.edges.forEach(tp => persons.push({node: tp.node.person}));
		} else {
			persons = this.props.persons;
		}
		if (this.state.searchValue !== '') {
			persons = persons.filter(person => {
				return (person.node.fullName + person.node.email).toUpperCase().includes(this.state.searchValue.toUpperCase());
			});
		}

		persons.sort((a, b) => {
			if (a.node.firstName < b.node.firstName) return -1;
			if (a.node.firstName > b.node.firstName) return 1;
			return 0;
		});
		const personSelectorPeople = [];
		if (this.props.allPersons) {
			this.props.allPersons.forEach(person => {
				if (this.state.selectedPersons.includes(person.id)) {
					persons.push({node: person});
				} else {
					personSelectorPeople.push(person);
				}
			});
		}

		const content = (
			<div className="content">
				<CustomScrollDiv>
					<div className={'body'}>
						{!this.props.initialTeam ? (
							<InputField
								id="add-team-modal-input"
								autoFocus={true}
								label={
									this.props.addDepartment
										? formatMessage({id: 'add_team_modal.department_name_label'})
										: formatMessage({id: 'add_team_modal.team_name_label'})
								}
								value={this.state.name}
								placeholder={
									this.props.addDepartment
										? formatMessage({id: 'add_team_modal.department_name_placeholder'})
										: formatMessage({id: 'add_team_modal.name_placeholder'})
								}
								onChange={this.handleNameChange.bind(this)}
								type="text"
								required={true}
							/>
						) : (
							<SelectInput
								multi={false}
								options={this.props.teams.map(t => ({value: t.node.id, label: t.node.name}))}
								value={this.state.selectedTeam}
								onChange={this.handleTeamSelectChange.bind(this)}
							/>
						)}
						{this.props.team ? (
							<PersonSelector
								isAdmin={true}
								people={personSelectorPeople}
								handleSelectItem={this.addPersonToTeam.bind(this)}
								showOnTop={false}
								helpText={formatMessage({id: 'team_modal.add_people'})}
							/>
						) : null}

						<div className="select-all">
							<Search
								alwaysExpanded={true}
								placeholder={formatMessage({id: 'settings_teams.search_people'})}
								value={this.state.searchValue}
								onChange={this.onSearchChange.bind(this)}
							/>
							<div className="checkbox-wrapper" onClick={this.selectAll.bind(this)}>
								<div className="text">{formatMessage({id: 'common.all'})}</div>
								<Checkbox
									onChange={this.selectAll.bind(this)}
									isChecked={this.state.selectedPersons.length !== 0}
									hasEntitiesChecked={
										this.state.selectedPersons.length !== 0 &&
										this.state.selectedPersons.length !== persons.length
									}
								/>
							</div>
						</div>
						{persons.map((person, index) => (
							<div key={index} className={'person-row'} onClick={this.selectPerson.bind(this, person)}>
								<div className="person-content-container">
									<Person
										key={person.node.id}
										name={person.node.fullName}
										role={person.node.role ? person.node.role.name : ''}
										showName={true}
										showRole={true}
										imageSrc={profilePicSrc(person.node.profilePictureId)}
										imageSize="large"
									/>
									<Checkbox
										onChange={this.selectPerson.bind(this, person)}
										isChecked={this.state.selectedPersons.includes(person.node.id)}
									/>
								</div>

								{this.state.selectedPersons.includes(person.node.id) ? (
									<div className="background-highlight" />
								) : null}
							</div>
						))}
					</div>
				</CustomScrollDiv>
			</div>
		);
		const headerText = this.props.addDepartment
			? formatMessage({id: 'add_team_modal.create_department'})
			: this.props.initialTeam
			? formatMessage({id: 'add_team_modal.select_team_title'})
			: this.props.team
			? formatMessage({id: 'settings_team.edit_team'})
			: formatMessage({id: 'settings_team.add-team'});
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				className={'add-team-modal'}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({
							id: this.props.initialTeam ? 'common.select' : this.props.team ? 'common.save' : 'common.create',
						}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.createTeam.bind(this),
						disabled:
							(!this.props.initialTeam && !this.state.name.trim()) ||
							(this.state.selectedPersons.length === 0 && !this.props.addDepartment),
					},
				]}
				headerText={headerText}
				content={content}
			/>
		);
	}
}

AddTeamModal.propTypes = {
	addDepartment: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
	allPersons: PropTypes.shape({
		edges: PropTypes.shape({
			node: PropTypes.shape({
				id: PropTypes.string.isRequired,
				fullName: PropTypes.string.isRequired,
				email: PropTypes.string.isRequired,
				profilePictureId: PropTypes.string,
				profilePictureDefaultId: PropTypes.number.isRequired,
			}),
		}),
	}),
};

export default injectIntl(AddTeamModal);
