import React, { useEffect } from 'react';
import OutlookCalendarOauthButton from './oauth-button/OutlookCalendarOauthButton';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import { TRACKING_OBJECTS } from '../../../tracking/amplitude/constants/TrackingObjects';
const OutlookCalendarOAuth = ({ calendarTokenError, page }) => {
    useEffect(() => {
        trackEvent(TRACKING_OBJECTS.LINK_OUTLOOK_CALENDAR_ACCOUNT, 'Shown');
    }, []);
    return (React.createElement("div", { "data-cy": 'outlook-calendar-oauth-button' },
        React.createElement(OutlookCalendarOauthButton, { calendarTokenError: calendarTokenError, page: page })));
};
export default OutlookCalendarOAuth;
