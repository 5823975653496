var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '@forecast-it/design-system';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { TRACKING_OBJECTS } from '../../../../tracking/amplitude/constants/TrackingObjects';
const GoogleCalendarOauthButton = ({ title, onSuccess, onError, onNonOAuthError, calendarTokenError, }) => {
    // read-only scope for Google calendar api
    const calendarScopeRO = 'https://www.googleapis.com/auth/calendar.readonly';
    // read-only scope for Google calendar list api
    const calendarListScopeRO = 'https://www.googleapis.com/auth/calendar.calendarlist.readonly';
    const validateTokenResponse = tokenResponse => {
        var _a, _b;
        // Verify that user accepted both requested scopes
        return !!(((_a = tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.scope) === null || _a === void 0 ? void 0 : _a.includes(calendarScopeRO)) && ((_b = tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.scope) === null || _b === void 0 ? void 0 : _b.includes(calendarListScopeRO)));
    };
    const handleSuccess = (tokenResponse) => __awaiter(void 0, void 0, void 0, function* () {
        if (validateTokenResponse(tokenResponse)) {
            return onSuccess(tokenResponse);
        }
        else {
            return onError({ error_description: 'We did not receive the necessary permissions to access your calendar.' });
        }
    });
    const googleLogin = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: onError,
        onNonOAuthError: onNonOAuthError,
        scope: `${calendarScopeRO} ${calendarListScopeRO}`,
        flow: 'auth-code',
    });
    const handleLinkClick = () => {
        trackEvent(TRACKING_OBJECTS.LINK_GOOGLE_CALENDAR_ACCOUNT, 'Clicked');
        googleLogin();
    };
    return (React.createElement(Button, { title: title, emphasis: 'medium', onClick: () => handleLinkClick() }, calendarTokenError ? 'Re-authenticate' : 'Link Google Account'));
};
export default GoogleCalendarOauthButton;
