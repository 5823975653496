import React, {useState} from 'react';
import {DropdownLabel, TextArea} from '../../styles/components/timesheets/add_time_entry_component_styled';
import {idFromGlobalId} from '../../forecast-app/shared/util/GlobalIdUtil';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import GenericModal from '../modal/generic_modal';
import Util from '../../forecast-app/shared/util/util';
import UpdateFeatureFlag from '../../mutations/mission_control/feature_flag_mutation';
import {useMemo} from 'react';

const getName = person => {
	return person.firstName + (person.lastName ? ' ' + person.lastName : '') + (person.email ? ' (' + person.email + ')' : '');
};

const saveBulkUserAccess = (personIds, currentFeatureKey) => {
	if (personIds) {
		Util.CommitMutation(UpdateFeatureFlag, {
			persons: personIds,
			featureFlag: currentFeatureKey,
		});
	}
};

export const BulkUsersModal = ({data, currentFeatureKey, allPersonsByCompany, hideBulkUsers, closeModal}) => {
	const [bulkUserIdInput, setBulkUserIdInput] = useState('');

	const filteredUsers = useMemo(() => {
		const companyFeatureFlags = Object.values(data[currentFeatureKey]);
		const allUsersInCompanyIds = companyFeatureFlags.filter(company => company.allUsers).map(company => company.id);
		const specificUserIds = companyFeatureFlags
			.flatMap(companyFeatureFlag => companyFeatureFlag.specificUsers)
			.map(user => user.id);
		const filteredCompanyKeys = Object.entries(allPersonsByCompany).filter(([key]) => !allUsersInCompanyIds.includes(key));
		return filteredCompanyKeys.flatMap(([_, value]) => value).filter(user => !specificUserIds.includes(user.id));
	}, [data, allPersonsByCompany]);

	const enteredUserIds = bulkUserIdInput.split(',').reduce((acc, curr) => {
		const idParsed = parseInt(curr.trim());
		if (!isNaN(idParsed)) {
			acc.push(idParsed);
		}
		return acc;
	}, []);

	const validEnteredUsers = filteredUsers.filter(c => enteredUserIds.some(userId => idFromGlobalId(c.id) === userId));
	const validIds = validEnteredUsers.map(user => user.id);

	const content = (
		<div className="feature-flag-modal">
			<DropdownLabel>{'User IDs'}</DropdownLabel>
			<TextArea
				maxLength={9999}
				value={bulkUserIdInput}
				rows={5}
				onChange={e => setBulkUserIdInput(e.target.value)}
				placeholder={
					'Enter comma-separated list of user ids (e.g. 1,2,3,4,5). Only users from paying and active trial companies will be included.'
				}
			/>
			<div style={{marginTop: '16px'}}>
				<DropdownLabel>{`Users that will be added`}</DropdownLabel>
				{validIds.length > 0 ? (
					<div>{`${validIds.length} valid IDs found from ${enteredUserIds.length} IDs inputted`}</div>
				) : null}
				<ul style={{maxHeight: '300px', overflowY: 'auto'}}>
					{validEnteredUsers.map(user => (
						<li key={user.id}>{getName(user)}</li>
					))}
				</ul>
			</div>
		</div>
	);

	const buttons = [
		{
			text: 'Cancel',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
			callback: () => hideBulkUsers(),
			preventDefaultClose: true,
		},
		{
			text: 'Save',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.GREEN,
			callback: () => saveBulkUserAccess(validIds, currentFeatureKey),
			disabled: !bulkUserIdInput,
		},
	];
	return (
		<GenericModal
			closeModal={() => closeModal()}
			buttons={buttons}
			content={content}
			headerText={'Bulk add feature flags'}
		/>
	);
};
