/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query ExportInvoiceBillingOptionModalQuery(
  $invoiceId: ID!
) {
  viewer {
    actualPersonId
    component(name: "ExportInvoiceBillingOptionModal")
    ...ExportInvoiceBillingOptionModal_viewer_MIuQ2
    id
  }
}

fragment ExportInvoiceBillingOptionModal_viewer_MIuQ2 on Viewer {
  id
  invoice(id: $invoiceId) {
    projects {
      id
      client {
        id
        clientBillTos(first: 10000) {
          edges {
            node {
              id
              billTo {
                id
                name
              }
              billFrom {
                id
                name
                integration
              }
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      projectBillTos(first: 10000) {
        edges {
          node {
            id
            billTo {
              id
              name
            }
            billFrom {
              id
              name
              integration
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "invoiceId",
            "type": "ID!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "ExportInvoiceBillingOptionModal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"ExportInvoiceBillingOptionModal\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = [
        (v3 /*: any*/),
        {
            "alias": null,
            "args": null,
            "concreteType": "BillTo",
            "kind": "LinkedField",
            "name": "billTo",
            "plural": false,
            "selections": [
                (v3 /*: any*/),
                (v5 /*: any*/)
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "BillFrom",
            "kind": "LinkedField",
            "name": "billFrom",
            "plural": false,
            "selections": [
                (v3 /*: any*/),
                (v5 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integration",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
        }
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ExportInvoiceBillingOptionModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "invoiceId",
                                    "variableName": "invoiceId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "ExportInvoiceBillingOptionModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ExportInvoiceBillingOptionModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "invoiceId"
                                }
                            ],
                            "concreteType": "InvoiceType",
                            "kind": "LinkedField",
                            "name": "invoice",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectType",
                                    "kind": "LinkedField",
                                    "name": "projects",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Client",
                                            "kind": "LinkedField",
                                            "name": "client",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": (v4 /*: any*/),
                                                    "concreteType": "ClientBillToTypeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "clientBillTos",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ClientBillToTypeEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ClientBillTo",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": (v6 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": "clientBillTos(first:10000)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v4 /*: any*/),
                                                    "filters": [],
                                                    "handle": "connection",
                                                    "key": "Client_clientBillTos",
                                                    "kind": "LinkedHandle",
                                                    "name": "clientBillTos"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": (v4 /*: any*/),
                                            "concreteType": "ProjectBillToTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "projectBillTos",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ProjectBillToTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ProjectBillTo",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": (v6 /*: any*/),
                                                            "storageKey": null
                                                        },
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": "projectBillTos(first:10000)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v4 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "Project_projectBillTos",
                                            "kind": "LinkedHandle",
                                            "name": "projectBillTos"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "ExportInvoiceBillingOptionModalQuery",
            "operationKind": "query",
            "text": "query ExportInvoiceBillingOptionModalQuery(\n  $invoiceId: ID!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"ExportInvoiceBillingOptionModal\")\n    ...ExportInvoiceBillingOptionModal_viewer_MIuQ2\n    id\n  }\n}\n\nfragment ExportInvoiceBillingOptionModal_viewer_MIuQ2 on Viewer {\n  id\n  invoice(id: $invoiceId) {\n    projects {\n      id\n      client {\n        id\n        clientBillTos(first: 10000) {\n          edges {\n            node {\n              id\n              billTo {\n                id\n                name\n              }\n              billFrom {\n                id\n                name\n                integration\n              }\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n      }\n      projectBillTos(first: 10000) {\n        edges {\n          node {\n            id\n            billTo {\n              id\n              name\n            }\n            billFrom {\n              id\n              name\n              integration\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '7eec19573a3b575e899dba6dab4f0cd5';
export default node;
