import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Table, Checkbox} from 'web-components';
import {InvoiceTable, InvoiceTableHeader} from '../../../../styles/v2/invoice_modals_v2_styled';
import Util from '../../../../forecast-app/shared/util/util';
import {BUDGET_TYPE} from '../../../../constants';
import DeprecatedProjectIndicatorJS from '../../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const InvoiceProjectsTable = ({
	projects,
	budgetType,
	budgetTypeName,
	selectedProjectsIds,
	onProjectSelect,
	onSelectAll,
	isProjectInvoicing,
}) => {
	const [expanded, setExpanded] = useState(true);

	const intl = useIntl();

	const tableData = {rows: projects};

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	return projects?.length ? (
		<InvoiceTable>
			<InvoiceTableHeader>
				<div className={'expand-section ' + (expanded ? '' : 'collapsed')} onClick={toggleExpanded}>
					<div className={'headline'}>{budgetTypeName}</div>
					<div className="controls">
						<div className={'expand-control' + (expanded ? ' expand' : ' collapse')} />
					</div>
				</div>
			</InvoiceTableHeader>
			{expanded ? (
				<Table>
					<Table.Header>
						<Table.HeaderColumn align="left" visible={!isProjectInvoicing} width="EXTRA_SMALL">
							<Checkbox
								checked={projects.some(
									project => selectedProjectsIds && selectedProjectsIds.includes(project.id)
								)}
								hasEntitiesChecked={
									!projects.every(
										project => selectedProjectsIds && selectedProjectsIds.includes(project.id)
									) &&
									projects.some(project => selectedProjectsIds && selectedProjectsIds.includes(project.id))
								}
								onClick={() => onSelectAll(projects.map(p => p.id))}
							/>
						</Table.HeaderColumn>
						<Table.HeaderColumn align="left" visible={true} width="EXTRA_SMALL">
							<Table.HeaderColumn.Title>
								{intl.formatMessage({id: 'common.project_id_short'})}
							</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn align="left" visible={true} width="MEDIUM" flex={1} usePadding>
							<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.project'})}</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn align="left" visible={true} width="MEDIUM" usePadding>
							<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.client'})}</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn align="right" visible={true} width="MEDIUM" usePadding>
							<Table.HeaderColumn.Title>
								{budgetType === BUDGET_TYPE.TIME_AND_MATERIALS
									? intl.formatMessage({id: 'common.uninvoiced_time_registrations'})
									: budgetType === BUDGET_TYPE.FIXED_PRICE_V2
									? intl.formatMessage({id: 'common.uninvoiced_fixed_price'})
									: budgetType === BUDGET_TYPE.RETAINER
									? intl.formatMessage({id: 'common.uninvoiced_locked_periods'})
									: ''}
							</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
						<Table.HeaderColumn align="right" visible={true} width="MEDIUM" usePadding>
							<Table.HeaderColumn.Title>
								{intl.formatMessage({id: 'common.uninvoiced_expenses'})}
							</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					</Table.Header>
					<Table.Rows data={tableData} canExpand={false}>
						{({rowData, tableColumnsProps}) => {
							return (
								<Table.Row {...tableColumnsProps}>
									<Table.Column>
										<Checkbox
											checked={selectedProjectsIds.includes(rowData.id)}
											onClick={() => onProjectSelect(rowData.id)}
										/>
									</Table.Column>
									<Table.Column>
										<DeprecatedProjectIndicatorJS project={rowData.relayProject} showProjectName={false} />
									</Table.Column>
									<Table.Column>{rowData.name}</Table.Column>
									<Table.Column>{rowData.client?.name}</Table.Column>
									<Table.Column>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(rowData.currency),
											rowData.uninvoicedPrice + rowData.uninvoicedFixedPrice,
											intl
										)}
									</Table.Column>
									<Table.Column>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(rowData.currency),
											rowData.uninvoicedExpenses,
											intl
										)}
									</Table.Column>
								</Table.Row>
							);
						}}
					</Table.Rows>
				</Table>
			) : null}
		</InvoiceTable>
	) : null;
};

export default InvoiceProjectsTable;
