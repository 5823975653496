import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import oneloginLogo from '../images/integrations/onelogin-textlogo.svg';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Util from '../forecast-app/shared/util/util';
import CreateOneloginOauthKeyMutation from '../mutations/create_onelogin_oauth_key_mutation';
import qs from 'query-string';

class loginOnelogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			verifying: false,
		};
		this.props.setLocaleFromBrowser();
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.actualPersonId !== null) {
			this.props.history.push({
				pathname: '/',
			});
			return;
		}

		if (qs.parse(this.props.location.search).iss) {
			this.setState({verifying: true});
			const onSuccess = ({createOneloginOauthKey}) => {
				if (createOneloginOauthKey.key === null) {
					this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
					return;
				}

				window.location.href = `https://${createOneloginOauthKey.oneloginUrl}.onelogin.com/oidc/2/auth?client_id=${createOneloginOauthKey.oneloginClientId}&redirect_uri=${process.env.ONELOGIN_REDIRECT_URI}&response_type=code&scope=openid&state=${createOneloginOauthKey.key}`;
			};

			Util.CommitMutation(
				CreateOneloginOauthKeyMutation,
				{
					issuer: qs.parse(this.props.location.search).iss,
				},
				onSuccess
			);
		}
	}

	loginOnelogin(event) {
		const onSuccess = ({createOneloginOauthKey}) => {
			if (createOneloginOauthKey.key === null) {
				this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
				return;
			}

			window.location.href = `https://${createOneloginOauthKey.oneloginUrl}.onelogin.com/oidc/2/auth?client_id=${createOneloginOauthKey.oneloginClientId}&redirect_uri=${process.env.ONELOGIN_REDIRECT_URI}&response_type=code&scope=openid&state=${createOneloginOauthKey.key}`;
		};

		if (event) {
			event.preventDefault();
		}

		const email = this.refs.email.value.trim();

		if (!email.length) {
			this.setState({errorMsg: 'All fields must be filled'});
			return;
		}

		this.setState({verifying: true});

		Util.CommitMutation(
			CreateOneloginOauthKeyMutation,
			{
				email: email,
			},
			onSuccess
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="fullscreen fullscreen-centered-outer login login-bg">
				{this.state.verifying ? <UploadingOverlay /> : null}
				<div className="logo" />
				<div className="fullscreen-centered-inner">
					<div className="fullscreen-centered-content form-vertical">
						<form>
							<h1>{formatMessage({id: 'login.title'})}</h1>
							<div className="fieldset">
								<input
									type="email"
									name="username"
									ref="email"
									placeholder={formatMessage({id: 'login.enter-email-address'})}
								/>
								{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
							</div>
							<div className="external-login">
								<button className="onelogin-login-button" onClick={this.loginOnelogin.bind(this)}>
									{formatMessage({id: 'login.login_with'})}{' '}
									<img src={oneloginLogo} height="17" alt="Onelogin logo" />
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const loginOneloginQuery = graphql`
	query loginOnelogin_Query {
		viewer {
			component(name: "login_onelogin")
			...loginOnelogin_viewer
		}
	}
`;

export {loginOneloginQuery};

export default createFragmentContainer(injectIntl(withRouter(loginOnelogin)), {
	viewer: graphql`
		fragment loginOnelogin_viewer on Viewer {
			id
			actualPersonId
		}
	`,
});
