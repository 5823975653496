import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

class XeroInaccessibleModal extends Component {
	redirectToAppPage() {
		this.props.history.replace('/admin/integrations/xero');
	}

	render() {
		const {formatMessage} = this.props.intl;

		let content;
		if (this.props.isAdminUser) {
			content = <FormattedMessage id="integrations.xero.inaccessible_reconnect" />;
		} else {
			content = <FormattedMessage id="integrations.xero.inaccessible_contact_admin" />;
		}

		const buttons = [
			{
				text: formatMessage({id: this.props.isAdminUser ? 'common.cancel' : 'common.ok'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
		];

		if (this.props.isAdminUser) {
			buttons.push({
				text: formatMessage({id: 'common.reconnect'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				callback: this.redirectToAppPage.bind(this),
			});
		}

		return (
			<GenericModal
				headerText={<FormattedMessage id="integrations.xero.reconnect_xero" />}
				closeModal={this.props.closeModal}
				className="integration-sync-clients-modal"
				buttons={buttons}
				content={content}
			/>
		);
	}
}

export default injectIntl(withRouter(XeroInaccessibleModal));
