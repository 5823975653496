import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {Wrapper, Item, ItemControl, ItemText, SectionHeader} from './style';

import GenericModal from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {RadioButton} from 'web-components';
import Switch from '../../../forecast-app/shared/components/switch/switch';
export default ({approveCallback, closeModal}) => {
	const [winChanceFullState, setWinChanceFullState] = useState(true);
	const [showScopingState, setShowScopingState] = useState(true);
	const [showBudgetState, setShowBudgetState] = useState(true);
	const [showPortfolioState, setShowPortfolioState] = useState(true);

	const handleNotSetWinChance = () => setWinChanceFullState(false);
	const handleSetWinChance = () => setWinChanceFullState(true);
	const handleShowScoping = () => setShowScopingState(!showScopingState);
	const handleShowBudget = () => setShowBudgetState(!showBudgetState);
	const handleShowPortfolio = () => setShowPortfolioState(!showPortfolioState);
	const intl = useIntl();
	const content = (
		<Wrapper>
			<SectionHeader>{intl.formatMessage({id: 'baseline.now_visible_heading'})}</SectionHeader>
			<Item>
				<ItemControl>
					<Switch onChange={handleShowScoping} checked={showScopingState} sliderWidth={30} sliderHeight={18} />
				</ItemControl>
				<ItemText>{intl.formatMessage({id: 'project_section.scope'})}</ItemText>
			</Item>
			<Item>
				<ItemControl>
					<Switch onChange={handleShowBudget} checked={showBudgetState} sliderWidth={30} sliderHeight={18} />
				</ItemControl>
				<ItemText>{intl.formatMessage({id: 'common.budget'})}</ItemText>
			</Item>
			<Item>
				<ItemControl>
					<Switch onChange={handleShowPortfolio} checked={showPortfolioState} sliderWidth={30} sliderHeight={18} />
				</ItemControl>
				<ItemText>{intl.formatMessage({id: 'common.all_reports'})}</ItemText>
			</Item>
			<SectionHeader>{intl.formatMessage({id: 'baseline.update_win_chance'})}</SectionHeader>
			<Item>
				<ItemControl>
					<RadioButton checked={winChanceFullState} onClick={handleSetWinChance} />
				</ItemControl>
				<ItemText>{intl.formatMessage({id: 'baseline.yes_set_100'})}</ItemText>
			</Item>
			<Item>
				<ItemControl>
					<RadioButton checked={!winChanceFullState} onClick={handleNotSetWinChance} />
				</ItemControl>
				<ItemText>{intl.formatMessage({id: 'baseline.no_not_yet'})}</ItemText>
			</Item>
		</Wrapper>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'project_budget.approve_expense_button'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => approveCallback(showScopingState, showBudgetState, showPortfolioState, winChanceFullState),
					cy: 'add-client-modal-button',
				},
			]}
			headerText={intl.formatMessage({id: 'baseline.approve_and_lock_baseline'})}
			content={content}
		/>
	);
};
