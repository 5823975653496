/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlaceholderAllocationModal_viewer$ref: FragmentReference;
declare export opaque type PlaceholderAllocationModal_viewer$fragmentType: PlaceholderAllocationModal_viewer$ref;
export type PlaceholderAllocationModal_viewer = {|
  +id: string,
  +email: ?string,
  +company: ?{|
    +monday: ?number,
    +tuesday: ?number,
    +wednesday: ?number,
    +thursday: ?number,
    +friday: ?number,
    +saturday: ?number,
    +sunday: ?number,
    +person?: ?{|
      +id: string,
      +fullName: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +profilePictureId: ?string,
    |},
    +placeholder?: ?{|
      +id: string,
      +name: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
    +placeholderAllocation?: ?{|
      +id: string,
      +startDate: ?string,
      +endDate: ?string,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +description: ?string,
    |},
  |},
  +project?: ?{|
    +id: string,
    +name: ?string,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
  |},
  +projectGroup?: ?{|
    +id: string,
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +projectStartYear: ?number,
          +projectStartMonth: ?number,
          +projectStartDay: ?number,
          +projectEndYear: ?number,
          +projectEndMonth: ?number,
          +projectEndDay: ?number,
        |}
      |}>
    |},
  |},
  +$refType: PlaceholderAllocationModal_viewer$ref,
|};
export type PlaceholderAllocationModal_viewer$data = PlaceholderAllocationModal_viewer;
export type PlaceholderAllocationModal_viewer$key = {
  +$data?: PlaceholderAllocationModal_viewer$data,
  +$fragmentRefs: PlaceholderAllocationModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectGroupId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "placeholderId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "placeholderAllocationId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadProject",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadProjectGroup",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadPlaceholder",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadAllocation",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadPerson",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceholderAllocationModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "condition": "loadPerson",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "personId"
                }
              ],
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        },
        {
          "condition": "loadPlaceholder",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "placeholderId",
                  "variableName": "placeholderId"
                }
              ],
              "concreteType": "Placeholder",
              "kind": "LinkedField",
              "name": "placeholder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/)
              ],
              "storageKey": null
            }
          ]
        },
        {
          "condition": "loadAllocation",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "placeholderAllocationId",
                  "variableName": "placeholderAllocationId"
                }
              ],
              "concreteType": "PlaceholderAllocation",
              "kind": "LinkedField",
              "name": "placeholderAllocation",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endDate",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "condition": "loadProject",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "internalId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v8/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "loadProjectGroup",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "globalId",
              "variableName": "projectGroupId"
            }
          ],
          "concreteType": "ProjectGroupType",
          "kind": "LinkedField",
          "name": "projectGroup",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 1000000
                }
              ],
              "concreteType": "ProjectTypeConnection",
              "kind": "LinkedField",
              "name": "projects",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "projects(first:1000000)"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0decf156ec9f6d566058ea07350b536';

module.exports = node;
