/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PredictedEndDateModalChartSection_project$ref: FragmentReference;
declare export opaque type PredictedEndDateModalChartSection_project$fragmentType: PredictedEndDateModalChartSection_project$ref;
export type PredictedEndDateModalChartSection_project = {|
  +projectStartYear: ?number,
  +projectStartMonth: ?number,
  +projectStartDay: ?number,
  +projectEndYear: ?number,
  +projectEndMonth: ?number,
  +projectEndDay: ?number,
  +projectHealth: ?{|
    +totalTaskEstimate: ?number,
    +totalBaselineEstimate: ?number,
    +totalTaskEstimateDone: ?number,
    +predictedEndDate: ?string,
    +optimisticPredictedEndDate: ?string,
    +conservativePredictedEndDate: ?string,
    +predictedScopePerDay: ?$ReadOnlyArray<?{|
      +date: ?string,
      +value: ?number,
    |}>,
    +optimisticPredictedScopePerDay: ?$ReadOnlyArray<?{|
      +date: ?string,
      +value: ?number,
    |}>,
    +conservativePredictedScopePerDay: ?$ReadOnlyArray<?{|
      +date: ?string,
      +value: ?number,
    |}>,
    +predictedAverageScopePerDay: ?number,
    +optimisticAverageScopePerDay: ?number,
    +conservativeAverageScopePerDay: ?number,
  |},
  +$refType: PredictedEndDateModalChartSection_project$ref,
|};
export type PredictedEndDateModalChartSection_project$data = PredictedEndDateModalChartSection_project;
export type PredictedEndDateModalChartSection_project$key = {
  +$data?: PredictedEndDateModalChartSection_project$data,
  +$fragmentRefs: PredictedEndDateModalChartSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PredictedEndDateModalChartSection_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStartYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStartMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStartDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectHealth",
      "kind": "LinkedField",
      "name": "projectHealth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTaskEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalBaselineEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTaskEstimateDone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "predictedEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optimisticPredictedEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "conservativePredictedEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DateValueType",
          "kind": "LinkedField",
          "name": "predictedScopePerDay",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DateValueType",
          "kind": "LinkedField",
          "name": "optimisticPredictedScopePerDay",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DateValueType",
          "kind": "LinkedField",
          "name": "conservativePredictedScopePerDay",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "predictedAverageScopePerDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optimisticAverageScopePerDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "conservativeAverageScopePerDay",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '585699852fc1f7a3d8681e9a0bcce76b';

module.exports = node;
