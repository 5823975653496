import React, {useState} from 'react';
import {CustomScrollDiv, HexagonText, Table} from 'web-components';
import {ColumnWrapper} from '../../project_health_styles/ProjectHealthTableSection.styled';
import {ColorStatValue} from './FocusModal.styled';
import {sortTable} from './FocusModalLogic';

export const FocusModalTable = ({data, header}) => {
	const [sort, setSort] = useState({id: null, direction: null});
	const sortedData = sortTable(data, sort);
	const sortedDataWithHeader = [header, ...sortedData];

	return (
		<Table onSort={sort => setSort(sort)}>
			<Table.Header>
				<Table.HeaderColumn sortId={'name'} flex={1} align="left" visible={true} width="LARGE">
					<Table.HeaderColumn.Title>{'Name'}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn sortId={'allocated-time'} align="right" visible={true} width="MEDIUM">
					<Table.HeaderColumn.Title>{'Allocated time'}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn sortId={'actual-time'} align="right" visible={true} width="MEDIUM">
					<Table.HeaderColumn.Title>{'Actual time'}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn sortId={'focus'} align="right" visible={true} width="MEDIUM">
					<Table.HeaderColumn.Title>{'Focus'}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			</Table.Header>
			<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={260}>
				<Table.Rows
					data={{
						rows: sortedDataWithHeader,
					}}
				>
					{({rowData, tableColumnsProps}) => (
						<Table.Row
							hoverStyle={{
								color: 'blue',
							}}
							{...tableColumnsProps}
							isHeader={rowData.isHeader}
						>
							<Table.Column>
								<HexagonText key={rowData.id} text={rowData.name} imgUrl={rowData.profilePicSrc} />
							</Table.Column>
							<Table.Column>
								<ColumnWrapper>{rowData.timeAllocated}</ColumnWrapper>
							</Table.Column>
							<Table.Column>
								<ColumnWrapper>{rowData.timeRegistered}</ColumnWrapper>
							</Table.Column>
							<Table.Column>
								<ColumnWrapper>
									<ColorStatValue color={rowData.warningVariant?.color}>{rowData.focus}</ColorStatValue>
								</ColumnWrapper>
							</Table.Column>
						</Table.Row>
					)}
				</Table.Rows>
			</CustomScrollDiv>
		</Table>
	);
};
