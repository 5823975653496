import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {createFragmentContainer, graphql} from 'react-relay';
import {Route, withRouter} from 'react-router-dom';
import GridLayout from '../../components/insights/grid_layout';
import UpdateInsightMutation from '../../mutations/update_insight_mutation';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as tracking from '../../tracking';
import Util from '../../forecast-app/shared/util/util';
import AddComponents from './add_components';
import {hasSomePermission, hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import CompanySetupUtil from '../../forecast-app/shared/util/CompanySetupUtil';

class insightsBuilderV2 extends Component {
	constructor(props) {
		super(props);
		// If the user does not have permission to edit insights, send them home.
		if (!hasPermission(PERMISSION_TYPE.INSIGHTS_UPDATE)) this.props.history.push('/');

		this.state = {
			components:
				props.viewer.company && props.viewer.company.insight
					? cloneDeep(props.viewer.company.insight.components)
					: null,
		};

		this.superPropertyChecksum = trackPage('Insights Builder');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('insights-insight');

		if (this.insightName) this.insightName.select();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			this.props.viewer.company &&
			nextProps.viewer.company &&
			this.props.viewer.company.insight.id !== nextProps.viewer.company.insight.id
		) {
			this.setState({
				components: nextProps.viewer.company.insight ? cloneDeep(nextProps.viewer.company.insight.components) : null,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !!nextProps.viewer.company;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.viewer.company &&
			this.props.viewer.company &&
			prevProps.viewer.company.insight.id !== this.props.viewer.company.insight.id
		) {
			this.insightName.value = this.props.viewer.company.insight.name;
			this.insightName.select();
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	saveInsight() {
		const components = [];

		for (let i = 0; i < this.state.components.length; i++) {
			const component = this.state.components[i];
			components.push({
				id: component.id,
				componentName: component.componentName,
				x: component.x,
				y: component.y,
				w: component.w,
				h: component.h,
				config: component.config,
			});
		}
		const onSuccess = () => {
			this.props.history.push(this.props.location.pathname.replace('/edit', ''));
		};

		Util.CommitMutation(
			UpdateInsightMutation,
			{
				id: this.props.viewer.company.insight.id,
				name: this.insightName.value,
				components: components,
			},
			onSuccess
		);
	}

	setComponents(components) {
		this.setState({components});
	}
	addComponents(components) {
		// Find the first empty row.
		let firstEmptyRow = 0;
		for (let i = 0; i < this.state.components.length; i++) {
			const y = this.state.components[i].y + this.state.components[i].h;
			if (y > firstEmptyRow) firstEmptyRow = y;
		}

		// Add new components
		let y = firstEmptyRow;
		let x = 0;
		let heightAdded = 1;
		for (let i = 0; i < components.length; i++) {
			if (x + components[i].w > 4) {
				// increment row by 1 or 2 based on if a tall component was added to the previous row.
				y += heightAdded;
				x = 0;
			}

			components[i].x = x;
			components[i].y = y;
			if (components[i].h > heightAdded) heightAdded = components[i].h;
			x += components[i].w;
		}

		const currentComponents = this.state.components.slice();
		for (let i = 0; i < components.length; i++) {
			currentComponents.push(components[i]);
		}
		this.setComponents(currentComponents);
	}

	showAddComponentsModal() {
		this.props.history.push(this.props.location.pathname + '/components');
	}

	onLayoutChange(layout) {
		// Update state components with values from the new layout
		const components = this.state.components;
		for (let i = 0; i < components.length; i++) {
			for (let j = 0; j < layout.length; j++) {
				if (components[i].id === layout[j].i || components[i].i === layout[j].i) {
					components[i].x = layout[j].x;
					components[i].y = layout[j].y;
					components[i].w = layout[j].w;
					components[i].h = layout[j].h;
					break;
				}
			}
		}
		// Save state
		this.setState({components});
	}

	updateComponentConfiguration(id, configuration) {
		// Find the component in state with the given ID and update it's configuration.
		const components = this.state.components;
		for (let i = 0; i < components.length; i++) {
			if (components[i].id === id || components[i].i === id) {
				components[i].config = JSON.stringify(configuration);
				this.setState({components});
				break;
			}
		}
	}

	removeComponent(id) {
		// Filter the removed component out of state components
		const components = this.state.components.filter(component => {
			return component.id !== id && component.i !== id;
		});
		// Save state
		this.setState({components});
	}

	setScrollElement(element) {
		this.setState({scrollElement: element});
	}

	render() {
		if (!this.props.viewer.company) {
			return <div />;
		}
		const insight = this.props.viewer.company.insight;
		const projects = [];
		const canViewFinancial =
			CompanySetupUtil.hasFinance(this.props.viewer.company.modules) &&
			hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]);
		this.props.viewer.projects.edges.map(project => {
			return projects.push({node: {id: project.node.id, name: project.node.name}});
		});
		// TODO: What should we show if user doesn't have edit permission?
		return (
			<div className={'insights-builder'}>
				<Route path="/insights/:insightId/edit/components" component={AddComponents}>
					<AddComponents
						addComponents={this.addComponents.bind(this)}
						category={this.props.viewer.company.insight.category}
						noFinance={!CompanySetupUtil.hasFinance(this.props.viewer.company.modules)}
					/>
				</Route>
				<CustomScrollDiv setScrollbarsRef={this.setScrollElement.bind(this)}>
					<div className="main-container">
						<div className="top-container">
							<input
								id="name"
								className="name-input"
								type="text"
								maxlength={190}
								defaultValue={insight !== null ? insight.name : ''}
								ref={input => (this.insightName = input)}
								title="Name"
							/>
							<div>
								<div className="controls">
									<button onClick={this.showAddComponentsModal.bind(this)}>
										<FormattedMessage id="insights.button.add_components" />
									</button>
									<button onClick={this.saveInsight.bind(this)}>
										<FormattedMessage id="common.save" />
									</button>
								</div>
							</div>
						</div>
						{this.state.components.length > 0 ? (
							<div className="insight-container">
								{this.state.scrollElement ? (
									<GridLayout
										components={this.state.components}
										editMode={true}
										projectId={this.state.selectedProject ? this.state.selectedProject.id : null}
										insightId={this.props.insightId}
										onLayoutChange={this.onLayoutChange.bind(this)}
										removeComponent={this.removeComponent.bind(this)}
										updateComponentConfiguration={this.updateComponentConfiguration.bind(this)}
										canViewFinancial={canViewFinancial}
										currency={this.props.viewer.company.currency}
										labelsOptions={this.props.viewer.company.insight.labels}
										skillsOptions={this.props.viewer.company.insight.skills}
										clientsOptions={this.props.viewer.company.insight.clients}
										statusColumnsOptions={this.props.viewer.company.insight.statusColumns}
										scrollElement={this.state.scrollElement}
										xeroEnabled={this.props.viewer.company.xeroEnabled}
										availableFeatureFlags={this.props.viewer.availableFeatureFlags}
										modules={this.props.viewer.company.modules}
									/>
								) : null}
							</div>
						) : (
							<div className="no-components">
								<FormattedMessage id={'reports.no_components'} />
							</div>
						)}
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const insightsBuilderV2Query = graphql`
	query insightsBuilderV2_Query($insightId: ID) {
		viewer {
			actualPersonId
			component(name: "insightsBuilderV2")
			company {
				insight(id: $insightId) {
					id
				}
			}
			...insightsBuilderV2_viewer @arguments(insightId: $insightId)
		}
	}
`;

export {insightsBuilderV2Query};

export default injectIntl(
	withRouter(
		createFragmentContainer(insightsBuilderV2, {
			viewer: graphql`
				fragment insightsBuilderV2_viewer on Viewer @argumentDefinitions(insightId: {type: "ID"}) {
					availableFeatureFlags {
						id
						name
						key
					}
					company {
						id
						currency
						xeroEnabled
						modules {
							moduleType
						}
						tier
						modules {
							moduleType
						}
						insight(id: $insightId) {
							id
							name
							category
							components {
								id
								componentName
								x
								y
								w
								h
								config
							}
							labels {
								id
								name
							}
							skills {
								id
								name
							}
							statusColumns {
								id
								category
								name
							}
							clients {
								id
								name
							}
						}
					}
					projects(first: 100000) {
						edges {
							node {
								id
								name
								status
								companyProjectId
							}
						}
					}
				}
			`,
		})
	)
);
