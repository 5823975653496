import Styled from 'styled-components/macro';
import corner from './corner.svg';
import cornerSelected from './corner_selected.svg';

export const TaskItemsContainer = Styled.div`
	width: ${props => (props.large ? '918px' : '702px')};
	margin-right: 16px;
`;

export const LoaderContainer = Styled.div`
	width: 100%;
	height:237.19px;
`;
export const Line = Styled.div`
	overflow: hidden;
	position: relative;
	height: auto;
	width: 100%;
	background:white;

`;
export const TaskContainer = Styled.div`
	width: ${props => (props.large ? '934' : '718px')};
	
`;
export const TableRow = Styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: stretch;
	align-items: center;
	width: 100%;
	min-height: 40px;
	overflow:hidden;
	z-index: 900;

`;

export const CornerChip = Styled.div`
	position: absolute;
	top: -25px;
	right: -10px;
	width: 28px;
	height: 28px;
	background-color: white;
	border: 1px solid #e6e6e6;
	transform: rotate(30deg);
	z-index: 10;
`;

export const TableRowHeader = Styled(TableRow)`
	font-size: 9px;
	line-height: normal;
	letter-spacing: 1px;
	color: #a1a1a1;
	width: ${props => (props.large ? '918px' : '702px')};
	margin-right: 16px;
	padding-right: 8px;
	text-transform: uppercase;
`;

export const TaskItemTableRow = Styled(TableRow)`
/width: 702px;
	font-size: 13px;
	position: relative;
	color: #535353;

	z-index: 1;
	border: 1px solid #e6e6e6;
	border-top: ${props => !props.first && 0};
`;

const TableRowItem = Styled.div`
 	
	padding-top: 0;
	padding-bottom: 0;
	width:100% ;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 16px;
	min-height: 40px;
	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

`;

export const StaticTableRowItem = Styled(TableRowItem)`
	min-width: ${props => (props.width ? props.width + 'px' : 'auto')};
	max-width: ${props => (props.width ? props.width + 'px' : 'auto')};
	width: ${props => (props.width ? props.width + 'px' : 'auto')};
	justify-content: flex-start;
	display: flex;
	align-items: center;	


	.clear-input {
		bottom:0px !important;
	}
`;

export const WrapStaticRowItem = Styled(StaticTableRowItem)`
	flex-wrap: wrap;
	padding: 4px;
`;

export const CornerTableRowItem = Styled(TableRowItem)`
	min-width: ${props => (props.width ? props.width + 'px' : 'auto')};
	max-width: ${props => (props.width ? props.width + 'px' : 'auto')};
	width: ${props => (props.width ? props.width + 'px' : 'auto')};
	height: 100%;
	background: url(${props => (props.rowSelected ? cornerSelected : corner)});
	background-size:cover;
	margin:0;
`;

export const GrowableTableRowItem = Styled(TableRowItem)`
	flex-grow: 1;
	height: 100%;
	display: flex;
	align-items: center;
`;

// export const TaskTable = Styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// `;
export const Container = Styled.div`
	display: flex;
	flex-direction: column;
`;

export const Row = Styled.div`
	margin-bottom: ${props => (props.last ? '0px' : '24px')};
`;

export const Text = Styled.p`
	margin: 0;
	padding: 0;
	font-size: 13px;
	color: #535353;
	max-width: 702px;
`;

export const LabelContainer = Styled.div`
	margin: 2px 2px;
`;

export const DropdownContainer = Styled.div`
	padding-right: 10px;
`;
