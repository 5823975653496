/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DEPENDENCY_TYPE = "CANNOT_BE_COMPLETED" | "CANNOT_START" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskStatusMicroAssistModal_viewer$ref: FragmentReference;
declare export opaque type TaskStatusMicroAssistModal_viewer$fragmentType: TaskStatusMicroAssistModal_viewer$ref;
export type TaskStatusMicroAssistModal_viewer = {|
  +parentTask: ?{|
    +id: string,
    +name: ?string,
    +statusColumnV2: ?{|
      +id: string,
      +name: ?string,
      +category: ?STATUS_CATEGORY,
    |},
    +project: ?{|
      +statusColumnsV2: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +projectGroupStatusColumnId: ?number,
            +category: ?STATUS_CATEGORY,
          |}
        |}>
      |}
    |},
    +childrenTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +projectGroupStatusColumnId: ?number,
            +category: ?STATUS_CATEGORY,
          |},
          +hasDependency: ?boolean,
          +thisTaskDependsOn: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +type: ?DEPENDENCY_TYPE,
                +thisDependsOnTask: ?{|
                  +statusColumnV2: ?{|
                    +category: ?STATUS_CATEGORY
                  |}
                |},
              |}
            |}>
          |},
          +childrenTasks: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +statusColumnV2: ?{|
                  +id: string,
                  +name: ?string,
                  +projectGroupStatusColumnId: ?number,
                  +category: ?STATUS_CATEGORY,
                |},
                +hasDependency: ?boolean,
                +thisTaskDependsOn: ?{|
                  +edges: ?$ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +type: ?DEPENDENCY_TYPE,
                      +thisDependsOnTask: ?{|
                        +statusColumnV2: ?{|
                          +category: ?STATUS_CATEGORY
                        |}
                      |},
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +company: ?{|
    +id: string
  |},
  +$refType: TaskStatusMicroAssistModal_viewer$ref,
|};
export type TaskStatusMicroAssistModal_viewer$data = TaskStatusMicroAssistModal_viewer;
export type TaskStatusMicroAssistModal_viewer$key = {
  +$data?: TaskStatusMicroAssistModal_viewer$data,
  +$fragmentRefs: TaskStatusMicroAssistModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupStatusColumnId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusColumnV2",
  "kind": "LinkedField",
  "name": "statusColumnV2",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v4/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDependency",
  "storageKey": null
},
v10 = {
  "alias": "thisTaskDependsOn",
  "args": null,
  "concreteType": "DependencyTypeConnection",
  "kind": "LinkedField",
  "name": "__Task_thisTaskDependsOn_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DependencyTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DependencyType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "thisDependsOnTask",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "statusColumnV2",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "parentTask",
          "project",
          "statusColumnsV2"
        ]
      },
      (v0/*: any*/),
      (v0/*: any*/),
      (v0/*: any*/),
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "parentTask",
          "childrenTasks"
        ]
      }
    ]
  },
  "name": "TaskStatusMicroAssistModal_viewer",
  "selections": [
    {
      "alias": "parentTask",
      "args": [
        {
          "kind": "Variable",
          "name": "companyTaskId",
          "variableName": "id"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusColumnV2",
          "kind": "LinkedField",
          "name": "statusColumnV2",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "alias": "statusColumnsV2",
              "args": null,
              "concreteType": "StatusColumnV2TypeConnection",
              "kind": "LinkedField",
              "name": "__Project_statusColumnsV2_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2TypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v4/*: any*/),
                        (v3/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "childrenTasks",
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__ParentTask_childrenTasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": "childrenTasks",
                      "args": null,
                      "concreteType": "TaskTypeConnection",
                      "kind": "LinkedField",
                      "name": "__ParentTask_childrenTasks_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TaskTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Task",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v8/*: any*/),
                                (v9/*: any*/),
                                (v10/*: any*/),
                                (v5/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e6d36adb7d25c5bb017bb0ebe3d42b5';

module.exports = node;
