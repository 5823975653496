import React from 'react';
import {AvatarOutline, ArrowRightOutline, BinOutline} from '../../../images/svg-collection';
import {BUTTON_VARIANT} from '../../../constants';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import DeleteMultipleSkillsMutation from '../../../mutations/delete_multiple_skills_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const skillsBulkAssignToUsers = selectedSkills => {
	tracking.trackEvent('skills - opened bulk option (assign skills to people)');
	trackEvent('Bulk Assign Skill Modal', 'Opened');
	showModal({
		type: MODAL_TYPE.BULK_ASSIGN_SKILLS_TO_PERSONS,
		skillIds: selectedSkills,
	});
};

const skillsBulkMoveCategory = selectedSkills => {
	tracking.trackEvent('skills - opened bulk option (move skills to category)');
	trackEvent('Bulk Move Skill Category Modal', 'Opened');
	showModal({
		type: MODAL_TYPE.BULK_MOVE_SKILLS_TO_CATEGORY,
		skillIds: selectedSkills,
	});
};

const skillsBulkDelete = (selectedSkills, company, formatMessage, unselectSkills) => {
	tracking.trackEvent('skills - opened bulk option (delete)');
	trackEvent('Bulk Delete Skill Modal', 'Opened');
	const skillCount = selectedSkills.size;
	showModal({
		type: MODAL_TYPE.CONFIRM_SKILL_DELETION,
		title: formatMessage({id: 'settings_skills.bulk_delete_skills_title'}, {skillCount}),
		warningMessage: formatMessage({id: 'settings_skills.bulk_delete_skills_warning'}),
		confirmText: formatMessage({id: 'settings.confirm'}),
		onDelete: () => {
			Util.CommitMutation(
				DeleteMultipleSkillsMutation,
				{
					ids: selectedSkills,
					companyId: company.id,
				},
				() => {
					unselectSkills();
					createToast({
						duration: 5000,
						message: formatMessage({id: 'settings_skills.skills_deleted'}, {skillCount}),
					});
				}
			);
		},
	});
};

export const getSkillsBulkOptions = (selectedSkills, company, formatMessage, unselectSkills) => {
	return [
		company.skillLevelsEnabled
			? undefined
			: {
					id: 'bulk-assign-to-users',
					label: formatMessage({id: 'bulk_edit.skills.assign_to_people'}),
					icon: color => <AvatarOutline color={color} width={18} height={18} />,
					callback: () => skillsBulkAssignToUsers(selectedSkills),
					userpilot: 'bulk-assign-to-users-button',
					cy: 'bulk-assign-to-users-button',
					variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
			  },
		{
			id: 'bulk-move-category',
			label: formatMessage({id: 'bulk_edit.skills.move_to_category'}),
			icon: color => <ArrowRightOutline color={color} width={16} height={16} />,
			callback: () => skillsBulkMoveCategory(selectedSkills),
			userpilot: 'bulk-move-button',
			cy: 'bulk-move-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
		},
		{
			id: 'delete',
			icon: color => <BinOutline color={color} width={16} height={16} />,
			label: formatMessage({id: 'common.delete'}),
			callback: () => skillsBulkDelete(selectedSkills, company, formatMessage, unselectSkills),
			userpilot: 'bulk-delete-button',
			cy: 'bulk-delete-button',
			variant: BUTTON_VARIANT.VERY_LIGHT_GRAY_OUTLINE,
		},
	].filter(Boolean);
};
