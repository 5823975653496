import React from 'react';
import { Alert, Modal, Text } from '@forecast-it/design-system';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
const ProjectsBillingDetailsWarningModal = ({ closeModal }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "invoicing.billing_details_required" })),
        React.createElement(Modal.Content, null,
            React.createElement(Alert, { text: formatMessage({ id: 'invoicing.missing_billing_details' }), type: Alert.TYPE.ERROR }),
            React.createElement(Text, null,
                React.createElement(FormattedHTMLMessage, { id: 'invoicing.projects_no_common_invoicing_pathway' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default ProjectsBillingDetailsWarningModal;
