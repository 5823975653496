/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PerformanceModalChartSection_projectStats$ref: FragmentReference;
declare export opaque type PerformanceModalChartSection_projectStats$fragmentType: PerformanceModalChartSection_projectStats$ref;
export type PerformanceModalChartSection_projectStats = $ReadOnlyArray<{|
  +id: string,
  +date: ?string,
  +performance: ?number,
  +optimisticPerformance: ?number,
  +conservativePerformance: ?number,
  +$refType: PerformanceModalChartSection_projectStats$ref,
|}>;
export type PerformanceModalChartSection_projectStats$data = PerformanceModalChartSection_projectStats;
export type PerformanceModalChartSection_projectStats$key = $ReadOnlyArray<{
  +$data?: PerformanceModalChartSection_projectStats$data,
  +$fragmentRefs: PerformanceModalChartSection_projectStats$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PerformanceModalChartSection_projectStats",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "performance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimisticPerformance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "conservativePerformance",
      "storageKey": null
    }
  ],
  "type": "ProjectStats"
};
// prettier-ignore
(node/*: any*/).hash = 'fd4afecea4c4fc08e771a4b0e2e82d9c';

module.exports = node;
