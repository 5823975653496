import React, {useState, useRef, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {BUTTON_STYLE} from '../../components/new-ui/ButtonV2';
import {BUTTON_COLOR} from '../../constants';
import GenericModal from './generic_modal';
import Styled from 'styled-components';
import {Dropdown, SingleText} from 'web-components';
import {PercentageInputWrapper} from '../../components/inputs/percentage_input_wrapper';
import {CurrencyInputWrapper} from '../../components/inputs/currency_input_wrapper';
import {adjustBaseline} from '../../components/initial-plan/InitialPlanLogic';
import {ValueCell} from '../../components/initial-plan/ValueCell';
export const AdjustBaselineModal = ({
	baselineTarget,
	baselineTargetPrice,
	baselineAdjustPrice,
	baselineAdjustPercentage,
	closeModal,
	onSave,
	projectId,
	currency,
	currencySymbol,
}) => {
	const [adjustedPrice, setAdjustedPrice] = useState(baselineAdjustPrice ? Math.abs(baselineAdjustPrice) : null);
	const [adjustedPercentage, setAdjustedPercentage] = useState(
		baselineAdjustPercentage ? Math.abs(baselineAdjustPercentage) : null
	);

	const [percentageError, setPercentageError] = useState(false);
	const [priceError, setPriceError] = useState(false);
	const inputRef = useRef();
	const getAdjustmentType = () => {
		let value = baselineAdjustPrice || baselineAdjustPercentage;

		if (value === null) {
			return null;
		} else if (value > 0) {
			return 'contingency';
		} else return 'discount';
	};

	const [adjustmentType, setAdjustmentType] = useState(getAdjustmentType());

	const getAdjustmentFormat = () => {
		if (baselineAdjustPercentage) {
			return 'percentage';
		} else return 'price';
	};

	const [selectedFormat, setSelectedFormat] = useState(getAdjustmentFormat());
	const intl = useIntl();

	const handleClearAdjustment = () => {
		adjustBaseline(null, null, projectId);
	};

	const isSaveDisabled = () => {
		if ((!adjustedPrice && !adjustedPercentage) || percentageError || priceError) {
			return true;
		}

		return false;
	};

	const handleSave = () => {
		let price = adjustedPrice;
		let percentage = adjustedPercentage;
		if (adjustmentType === 'discount' && price) {
			price *= -1;
		}
		if (adjustmentType === 'discount' && percentage) {
			percentage *= -1;
		}
		onSave(price, percentage, projectId, () => closeModal());
	};
	const buttons = [];
	if (baselineAdjustPrice || baselineAdjustPercentage) {
		buttons.push({
			text: intl.formatMessage({id: 'common.clear_adjustment'}),
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
			callback: handleClearAdjustment,
		});
	}

	buttons.push({
		text: intl.formatMessage({id: 'common.save'}),
		style: BUTTON_STYLE.FILLED,
		color: BUTTON_COLOR.GREEN,
		disabled: isSaveDisabled(),
		callback: handleSave,
	});

	const getSelectedAdjustmentType = () => {
		if (!adjustmentType) {
			return [];
		} else {
			return [adjustmentType];
		}
	};

	const handleSelectType = a => {
		setAdjustmentType(a[0]);
	};

	const getSelectedFormat = () => {
		return [selectedFormat];
	};

	const handleSelectFormat = a => {
		if (a[0] === 'percentage') {
			setAdjustedPercentage(0);
			setAdjustedPrice(null);
			setPriceError(false);
		} else {
			setAdjustedPercentage(null);
			setPercentageError(false);
			setAdjustedPrice(0);
		}
		setSelectedFormat(a[0]);
	};

	useEffect(() => {
		inputRef.current?.focus();
	}, [selectedFormat]);

	const handleSetPercentage = percentage => {
		if (percentage <= 0) {
			setPercentageError(true);
			return;
		}
		setPercentageError(false);
		if (percentage !== adjustedPercentage) {
			setAdjustedPercentage(percentage);
		}
	};

	const handleSetPrice = value => {
		if (value <= 0) {
			setPriceError(true);
			return;
		}
		setPriceError(false);
		if (value !== adjustedPrice) {
			setAdjustedPrice(value);
		}
	};

	const getAdjustText = () => {
		let adjustString;
		if (adjustmentType === null) {
			adjustString = `${intl.formatMessage({id: 'project_section.xero_modal_table_discount'})} / ${intl.formatMessage({
				id: 'common.contingency',
			})}`;
		} else if (adjustmentType === 'discount') {
			adjustString = intl.formatMessage({id: 'project_section.xero_modal_table_discount'});
		} else if (adjustmentType === 'contingency') {
			adjustString = intl.formatMessage({id: 'common.contingency'});
		}

		if (selectedFormat === 'percentage') {
			adjustString += ` (${adjustedPercentage * 100}%)`;
		}

		return adjustString;
	};

	const getAdjustValue = () => {
		if (selectedFormat === 'percentage') {
			if (adjustmentType === 'discount') {
				return adjustedPercentage * -1 * baselineTargetPrice;
			} else {
				return adjustedPercentage * baselineTargetPrice;
			}
		} else {
			if (adjustmentType === 'discount') {
				return adjustedPrice * -1;
			} else {
				return adjustedPrice || 0;
			}
		}
	};

	const getNewBaseline = () => {
		return baselineTargetPrice + getAdjustValue();
	};
	const content = (
		<Container>
			<Row marginBottom={8}>
				<Label>{intl.formatMessage({id: 'baseline.adjustment_type'})}</Label>
			</Row>
			<Row marginBottom={24}>
				<Dropdown
					selectedItems={getSelectedAdjustmentType()}
					dropdownAlignment="none"
					onSelect={handleSelectType}
					name={intl.formatMessage({id: 'common.select_an_option'})}
					size="large"
				>
					<SingleText
						key={'discount'}
						value={'discount'}
						searchString={intl.formatMessage({id: 'project_section.xero_modal_table_discount'})}
					>
						{intl.formatMessage({id: 'project_section.xero_modal_table_discount'})}
					</SingleText>
					<SingleText
						key={'contingency'}
						value={'contingency'}
						searchString={intl.formatMessage({id: 'common.contingency'})}
					>
						{intl.formatMessage({id: 'common.contingency'})}
					</SingleText>
				</Dropdown>
			</Row>
			<Row marginBottom={24}>
				<Element width={184}>
					<Row marginBottom={8}>
						<Label>{intl.formatMessage({id: 'common.format'})}</Label>
					</Row>
					<Row>
						<Dropdown
							disabled={!adjustmentType}
							selectedItems={!adjustmentType ? [] : getSelectedFormat()}
							dropdownAlignment="none"
							onSelect={handleSelectFormat}
							name={intl.formatMessage({id: 'common.select_an_option'})}
							size="large"
						>
							<SingleText key={'price'} value={'price'} searchString={intl.formatMessage({id: 'common.price'})}>
								{intl.formatMessage({id: 'common.price'})}
							</SingleText>
							<SingleText
								key={'percentage'}
								value={'percentage'}
								searchString={intl.formatMessage({id: 'scheduling.allocation_modal.percent'})}
							>
								{intl.formatMessage({id: 'scheduling.allocation_modal.percent'})}
							</SingleText>
						</Dropdown>
					</Row>
				</Element>
				<Element width={184}>
					<Row marginBottom={8}>
						<Label>{intl.formatMessage({id: 'baseline.budget_size'})}</Label>
					</Row>
					<Row>
						{selectedFormat === 'percentage' ? (
							<PercentageInputWrapper
								width="184px"
								modalView
								innerRef={inputRef}
								disabled={!adjustmentType}
								hasError={percentageError}
								value={adjustedPercentage * 100}
								callback={handleSetPercentage}
							/>
						) : (
							<CurrencyInputWrapper
								innerRef={inputRef}
								intl={intl}
								modalView
								value={adjustedPrice}
								callback={handleSetPrice}
								currency={currency}
								hasError={priceError}
								width={'184px'}
								disabled={!adjustmentType}
							/>
						)}
					</Row>
				</Element>
			</Row>
			<Row>
				<CalculationContainer>
					<Row marginBottom={8}>
						<CalcStyleText>{intl.formatMessage({id: 'baseline.original_baseline'})}</CalcStyleText>
						<CalcStyleText>
							<ValueCell
								noJustify
								type={ValueCell.VALUE_TYPE.PRICE}
								value={baselineTargetPrice}
								currencySymbol={currencySymbol}
							></ValueCell>
						</CalcStyleText>
					</Row>
					<Row marginBottom={24}>
						<CalcStyleText disabled={adjustmentType === null}>{getAdjustText()}</CalcStyleText>
						<CalcStyleText>
							{adjustmentType !== null && (
								<ValueCell
									noJustify
									type={ValueCell.VALUE_TYPE.PRICE}
									value={getAdjustValue()}
									currencySymbol={currencySymbol}
								></ValueCell>
							)}
						</CalcStyleText>
					</Row>
					<Row>
						<NewBaselineStyleText disabled={adjustmentType === null}>
							{intl.formatMessage({id: 'baseline.new_baseline'})}
						</NewBaselineStyleText>
						<NewBaselineStyleText>
							{adjustmentType !== null && (
								<ValueCell
									noJustify
									type={ValueCell.VALUE_TYPE.PRICE}
									value={getNewBaseline()}
									currencySymbol={currencySymbol}
								></ValueCell>
							)}
						</NewBaselineStyleText>
					</Row>
				</CalculationContainer>
			</Row>
		</Container>
	);

	return (
		<GenericModal
			headerText={intl.formatMessage({id: 'baseline.adjust_baseline_total'})}
			content={content}
			closeModal={closeModal}
			buttons={buttons}
		/>
	);
};

const Container = Styled.div`
    display: flex;
    flex-direction: column;
    width: 384px;
`;

const Label = Styled.label`
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #a5a5ae;
`;

const Row = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${props => props.marginBottom && `${props.marginBottom}px`};
`;

const Element = Styled.div`
    display: flex;
    width: ${props => props.width && `${props.width}px`};
    flex-direction: column;
`;

const CalculationContainer = Styled.div`
    width: 100%;
    padding: 24px;
    background: #F7F7FE;
    border-radius: 4px;
`;
const CalcStyleText = Styled.div`
    font-size: 13px;
    color: ${props => (props.disabled ? '#c4c4cc' : '#4C4C58')};
`;

const NewBaselineStyleText = Styled.div`
    font-size: 14px;
    font-weight:500;
    color: ${props => (props.disabled ? '#c4c4cc' : '#4C4C58')};
`;
