import React, {useEffect, useMemo, useState} from 'react';
import {injectIntl} from 'react-intl';
import Styled from 'styled-components/macro';
import {RadioButton} from 'web-components';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {CSS_CONSTANTS} from '../../css_variables';
import Util from '../../forecast-app/shared/util/util';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import {BUTTON_COLOR} from '../../constants';

const ContenWrapper = Styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom:8px;
`;

const Header = Styled.div`
	margin-bottom:24px;
`;

const ChoicesWrapper = Styled.div`
	margin-bottom: 24px;
`;
const ChoiceWrapper = Styled.div`
	margin-bottom:24px;
`;

const Option = Styled.div`
	display:flex;
	flex-direction: row;
	align-items:center;
	margin-bottom:2px;

`;
const OptionText = Styled.div`
	font-weight:400;
	margin-left:8px;
`;
const OptionDescription = Styled.div`
	color:#a1a1a1;
	margin-left:30px; //22 + 8

`;
const HoursInputWrapper = Styled.div`
	position:relative;
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:8px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	input{
		width:90px;
		margin-right:8px;
	}
`;

const InputWarning = Styled.div`
	position:absolute;
	bottom: -19px;
	font-size: 13px;
	line-height: 15px;
	color: #E4253B;
`;

export const CalculatedPerformanceWarning = Styled.div`
	font-style: italic;
	position:absolute;
	bottom: -3px;
	font-size: 13px;
	line-height: 15px;
`;

const ValuesWrapper = Styled.div`
	position:relative;
	padding:24px 0 16px 0;
	border-top: solid 1px ${CSS_CONSTANTS.app_border_color};
`;

const ValueWrapper = Styled.div`
	display:flex;
	flex-direction: row;
	height:36px; 
	padding:0 16px;
	color: ${props => props.purple && CSS_CONSTANTS.v2_branding_purple};
	background-color: ${props => props.purple && CSS_CONSTANTS.background_purple};
	div{
		align-self:center;
	}

`;

const Title = Styled.div`
	font-weight:bold;
	flex:1;
`;

const SprintList = Styled.div`
	margin-top:24px;
	display: grid;
	grid-template-columns: 1fr auto auto auto ;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

const HeaderElement = Styled.div`
	color:${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 9px;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-bottom:8px;
	font-weight: 500;
	padding-left:${props => (props.sprintColumn ? 0 : '24px')};
	text-align:${props => (props.sprintColumn ? 'left' : 'right')};
`;

const SprintElement = Styled.div`
	height:40px;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	text-align:right;
	padding-left:${props => (props.sprintColumn ? 0 : '24px')};
	text-align:${props => (props.sprintColumn ? 'left' : 'right')};
	padding-top:12px;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
`;

const TotalPerformance = Styled.div`
	color: ${CSS_CONSTANTS.v2_text_gray};
	text-align: right;
	font-weight:500;
	margin-top:12px;
`;

/* const HelpCenterLink = Styled.div`
	display:flex;
	padding-top: 10px;
	font-weight: 500;
	font-size: 11px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	a {
		color: ${CSS_CONSTANTS.link_text_color};
	}
`; */

export const OPTIONS = {
	CALCULATED: 0,
	MANUAL: 1,
	DO_NOT_UPDATE: 2,
};

const UpdateSprintPerformanceModal = ({intl, closeModal, currentPerformance, currentSprint, fourLastDoneSprints, callback}) => {
	const [selectedOption, setSelectedOption] = useState(OPTIONS.CALCULATED);
	const [newPerformance, setNewPerformance] = useState(0);
	const [inputValue, setInputValue] = useState(currentPerformance / 60);

	const currentSprintValues = useMemo(() => {
		const currentSprintValues = {
			doneTasksEstimation: 0,
			doneTasksTimeRegistered: 0,
			performance: 0,
		};
		currentSprint.node.tasks.forEach(task => {
			if (task.statusColumnV2.category === 'DONE') {
				currentSprintValues.doneTasksEstimation += task.estimateForecast;
				currentSprintValues.doneTasksTimeRegistered += task.timeRegistrations.edges.reduce(
					(acc, timeReg) => acc + timeReg.node.minutesRegistered,
					0
				);
			}
		});
		currentSprintValues.doneTasksEstimation *= 60;
		currentSprintValues.performance =
			currentSprintValues.doneTasksEstimation > 0
				? currentSprintValues.doneTasksTimeRegistered / currentSprintValues.doneTasksEstimation
				: 0;

		return currentSprintValues;
	}, []);

	const fiveLastSprintsValues = useMemo(() => {
		const fourLastSprintsValues = {
			doneTasksEstimation: 0,
			doneTasksTimeRegistered: 0,
		};
		fourLastDoneSprints.forEach(sprint => {
			sprint.node.tasks.forEach(task => {
				if (task.statusColumnV2.category === 'DONE') {
					fourLastSprintsValues.doneTasksEstimation += task.estimateForecast;
					fourLastSprintsValues.doneTasksTimeRegistered += task.timeRegistrations.edges.reduce(
						(acc, timeReg) => acc + timeReg.node.minutesRegistered,
						0
					);
				}
			});
		});
		fourLastSprintsValues.doneTasksEstimation *= 60;

		const fiveLastSprintsValues = {
			doneTasksEstimation: currentSprintValues.doneTasksEstimation + fourLastSprintsValues.doneTasksEstimation,
			doneTasksTimeRegistered:
				currentSprintValues.doneTasksTimeRegistered + fourLastSprintsValues.doneTasksTimeRegistered,
			performance: 0,
		};
		const perf =
			fiveLastSprintsValues.doneTasksEstimation > 0
				? fiveLastSprintsValues.doneTasksTimeRegistered / fiveLastSprintsValues.doneTasksEstimation
				: 0;
		fiveLastSprintsValues.performance = perf;
		return fiveLastSprintsValues;
	}, []);

	useEffect(() => {
		setNewPerformance(fiveLastSprintsValues.performance);
	}, []);

	useEffect(() => {
		switch (selectedOption) {
			case OPTIONS.MANUAL:
				setNewPerformance(inputValue, fiveLastSprintsValues.performance);
				break;
			case OPTIONS.CALCULATED:
				setNewPerformance(fiveLastSprintsValues.performance);
				break;
			default:
				//OPTIONS.DO_NOT_UPDATE:
				setNewPerformance(currentPerformance / 60);
				break;
		}
	}, [selectedOption, inputValue]);

	const switchSelected = opt => {
		setSelectedOption(opt);
	};

	const sprintList = [currentSprint, ...fourLastDoneSprints];

	const handleInputValue = value => {
		if (!isNaN(value)) {
			setInputValue(value);
		} else {
			setInputValue(null);
		}
	};

	const content = () => {
		const {formatMessage} = intl;
		const performanceHourFormat = Util.convertMinutesToFullHour(fiveLastSprintsValues.performance * 60, intl);
		const currentPerformanceHourFormat = Util.convertMinutesToFullHour(currentPerformance, intl);
		const newPerformanceHourFormat = Util.convertMinutesToFullHour(Math.max(newPerformance * 60, 60), intl);
		return (
			<ContenWrapper>
				<Header>{formatMessage({id: 'story_point_performance.modal_question'})}</Header>
				<ChoicesWrapper>
					<ChoiceWrapper>
						<Option>
							<RadioButton
								checked={selectedOption === OPTIONS.CALCULATED}
								onClick={() => switchSelected(OPTIONS.CALCULATED)}
							></RadioButton>
							<OptionText>{formatMessage({id: 'story_point_performance.modal_choice_1_header'})}</OptionText>
						</Option>
						<OptionDescription>
							{formatMessage({id: 'story_point_performance.modal_choice_1_description'})}
							<SprintList>
								<HeaderElement sprintColumn={true}>{formatMessage({id: 'sprints.sprint'})}</HeaderElement>
								<HeaderElement>{formatMessage({id: 'common.estimates'})}</HeaderElement>
								<HeaderElement>{formatMessage({id: 'common.reported'})}</HeaderElement>
								<HeaderElement>{formatMessage({id: 'common.performance'})}</HeaderElement>

								{sprintList.map(sprint => {
									return [
										<SprintElement sprintColumn={true}>{sprint.node.name}</SprintElement>,
										<SprintElement>{sprint.node.forecast}p</SprintElement>,
										<SprintElement>
											{Util.convertMinutesToFullHour(sprint.node.timeEntries, intl)}
										</SprintElement>,
										<SprintElement>
											{Util.convertMinutesToFullHour(sprint.node.performance, intl)}
										</SprintElement>,
									];
								})}
							</SprintList>
							<TotalPerformance>
								{formatMessage({id: 'common.total_per_story_point'}, {value: performanceHourFormat})}
							</TotalPerformance>
						</OptionDescription>
					</ChoiceWrapper>
					<ChoiceWrapper>
						<ChoiceWrapper>
							<Option>
								<RadioButton
									checked={selectedOption === OPTIONS.MANUAL}
									onClick={() => switchSelected(OPTIONS.MANUAL)}
									cy={'set-manual-value'}
								></RadioButton>
								<OptionText>{formatMessage({id: 'story_point_performance.modal_choice_2_header'})}</OptionText>
							</Option>
							<OptionDescription>
								<HoursInputWrapper>
									<HoursInput
										value={inputValue}
										mutation={handleInputValue}
										placeholder={formatMessage({id: 'hours_input.placeholder'})}
										disabled={selectedOption !== OPTIONS.MANUAL}
										hasError={inputValue < 1}
									/>
									<div>{formatMessage({id: 'settings.per_stroy_point'})}</div>
									{selectedOption === OPTIONS.MANUAL && inputValue < 1 && (
										<InputWarning>
											{formatMessage(
												{id: 'hours_input.warning'},
												{min: Util.convertMinutesToFullHour(60, intl)}
											)}
										</InputWarning>
									)}
								</HoursInputWrapper>
							</OptionDescription>
						</ChoiceWrapper>
					</ChoiceWrapper>
					<ChoiceWrapper>
						<ChoiceWrapper>
							<Option>
								<RadioButton
									checked={selectedOption === OPTIONS.DO_NOT_UPDATE}
									onClick={() => switchSelected(OPTIONS.DO_NOT_UPDATE)}
									cy={'do-not-update'}
								></RadioButton>
								<OptionText>{formatMessage({id: 'common.do_not_update'})}</OptionText>
							</Option>
						</ChoiceWrapper>
					</ChoiceWrapper>
				</ChoicesWrapper>
				<ValuesWrapper>
					<ValueWrapper>
						<Title>{formatMessage({id: 'story_point_performance.current_perfomance'})}</Title>
						<div>
							<b>{currentPerformanceHourFormat}</b> {formatMessage({id: 'settings.per_stroy_point'})}
						</div>
					</ValueWrapper>
					<ValueWrapper purple={true}>
						<Title>{formatMessage({id: 'story_point_performance.new_performance'})}</Title>
						<div>
							<b>
								{newPerformanceHourFormat +
									(selectedOption === OPTIONS.CALCULATED && fiveLastSprintsValues.performance < 1 ? '*' : '')}
							</b>{' '}
							{formatMessage({id: 'settings.per_stroy_point'})}
						</div>
					</ValueWrapper>
					{selectedOption === OPTIONS.CALCULATED && fiveLastSprintsValues.performance < 1 && (
						<CalculatedPerformanceWarning>
							*{formatMessage({id: 'story_point_performance.minimum_warning'})}
						</CalculatedPerformanceWarning>
					)}
				</ValuesWrapper>

				{/* <HelpCenterLink>
					<FormattedHTMLMessage
						id="story_point_performance.help_center_link"
						values={{
							link: 'https://support.forecast.app/',
						}}
					></FormattedHTMLMessage>
				</HelpCenterLink> */}
			</ContenWrapper>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{text: intl.formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
				{
					text: intl.formatMessage({id: 'common.confirm'}),
					callback: () =>
						callback(
							currentSprint.node.id,
							currentSprintValues.performance,
							Math.max(newPerformance, 1),
							selectedOption
						),
					disabled: inputValue == null,
					cy: 'confirm-update-perfomance',
				},
			]}
			content={content()}
			headerText={intl.formatMessage({id: 'story_point_performance.modal_header'})}
			modalWidth={MODAL_WIDTH.MEDIUM}
		></GenericModal>
	);
};
export default injectIntl(UpdateSprintPerformanceModal);
