/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type featureFlags_viewer$ref = any;
export type featureFlags_QueryVariables = {||};
export type featureFlags_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: featureFlags_viewer$ref,
  |}
|};
export type featureFlags_Query = {|
  variables: featureFlags_QueryVariables,
  response: featureFlags_QueryResponse,
|};
*/


/*
query featureFlags_Query {
  viewer {
    actualPersonId
    component(name: "control_feature_flags")
    ...featureFlags_viewer
    id
  }
}

fragment featureFlags_viewer on Viewer {
  component(name: "control_feature_flags")
  id
  firstName
  lastName
  company {
    id
  }
  availableFeatureFlags {
    key
    id
  }
  ...missionControlHeader_viewer
  missionControlData {
    id
    companies {
      id
      companyId
      name
      tier
      forecastDemo
      contactEmail
      accessBlocked
    }
    availableFeatures {
      id
      name
      key
      color
      team
      description
      rolledOut
      rolledOutDate
      cleanedUp
      expectedRollOutDate
      type
      companies {
        id
        companyId
        name
        timestamp
      }
      persons {
        id
        personId
        companyId
        companyName
        name
        email
        timestamp
      }
      personsOptedOut {
        id
        companyId
        companyName
        name
        email
      }
    }
    otherPersons(includeForecastPersons: true) {
      id
      firstName
      lastName
      email
      companyName
      companyId
    }
  }
}

fragment missionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "control_feature_flags"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"control_feature_flags\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "featureFlags_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "featureFlags_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "featureFlags_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlCompanyType",
                "kind": "LinkedField",
                "name": "companies",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastDemo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessBlocked",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailableFeaturesType",
                "kind": "LinkedField",
                "name": "availableFeatures",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "team",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolledOut",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolledOutDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cleanedUp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expectedRollOutDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyFeatureType",
                    "kind": "LinkedField",
                    "name": "companies",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v5/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonFeatureType",
                    "kind": "LinkedField",
                    "name": "persons",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "personId",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonFeatureType",
                    "kind": "LinkedField",
                    "name": "personsOptedOut",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeForecastPersons",
                    "value": true
                  }
                ],
                "concreteType": "MissionControlImpersonatePersonType",
                "kind": "LinkedField",
                "name": "otherPersons",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": "otherPersons(includeForecastPersons:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "featureFlags_Query",
    "operationKind": "query",
    "text": "query featureFlags_Query {\n  viewer {\n    actualPersonId\n    component(name: \"control_feature_flags\")\n    ...featureFlags_viewer\n    id\n  }\n}\n\nfragment featureFlags_viewer on Viewer {\n  component(name: \"control_feature_flags\")\n  id\n  firstName\n  lastName\n  company {\n    id\n  }\n  availableFeatureFlags {\n    key\n    id\n  }\n  ...missionControlHeader_viewer\n  missionControlData {\n    id\n    companies {\n      id\n      companyId\n      name\n      tier\n      forecastDemo\n      contactEmail\n      accessBlocked\n    }\n    availableFeatures {\n      id\n      name\n      key\n      color\n      team\n      description\n      rolledOut\n      rolledOutDate\n      cleanedUp\n      expectedRollOutDate\n      type\n      companies {\n        id\n        companyId\n        name\n        timestamp\n      }\n      persons {\n        id\n        personId\n        companyId\n        companyName\n        name\n        email\n        timestamp\n      }\n      personsOptedOut {\n        id\n        companyId\n        companyName\n        name\n        email\n      }\n    }\n    otherPersons(includeForecastPersons: true) {\n      id\n      firstName\n      lastName\n      email\n      companyName\n      companyId\n    }\n  }\n}\n\nfragment missionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0c95a640c0d8dae11588dae418a5376';

module.exports = node;
