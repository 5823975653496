import React from 'react';
import {useGoogleLogin} from '@react-oauth/google';
const GoogleLoginButton = ({children, onSuccess, onFailure, ...rest}) => {
	const googleLogin = useGoogleLogin({
		onSuccess: async tokenResponse => onSuccess(tokenResponse),
		onError: onFailure,
		onNonOAuthError: onFailure,
		scope: 'email profile',
	});

	return (
		<button {...rest} onClick={() => googleLogin()}>
			{children}
		</button>
	);
};

export default GoogleLoginButton;
