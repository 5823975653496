import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../../constants';
import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, HexagonText, InputLabel} from 'web-components';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import Styled from 'styled-components';
import {assignPlaceholderAllocationsToPersonMutation, filterPersons, handleMutationSuccess} from './PlaceholderAllocationUtils';
import AllocationCheckboxList from '../../../components/canvas-scheduling/components/AllocationCheckboxList';
import {canApproveAllocation} from '../../../forecast-app/shared/util/PermissionsUtil';
import Util from '../../../forecast-app/shared/util/util';
import CheckboxRow from '../../../components/canvas-scheduling/components/CheckboxRow';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import AllocationTypeSelector from '../../../components/canvas-scheduling/components/AllocationTypeSelector';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import FindAvailableTeamMember from '../../../components/canvas-scheduling/components/FindAvailableTeamMember';
import KeepEmptyPlaceholder from '../../../components/canvas-scheduling/components/KeepEmptyPlaceholder';
import {KEEP_PLACEHOLDER_STORAGE_KEY} from '../../../components/canvas-scheduling/constants';
import {profilePicSrc} from '../../../directApi';

export const PlaceholderModalContent = Styled.div``;

export const PlaceholderModalFieldHeader = Styled.h4`
	margin-bottom: 3px;
	font-weight: 600;
	font-size: 13px;
`;

export const PlaceholderModalFieldDescription = Styled.span`
	font-weight: 400;
	font-size: 12px;
`;

const AssignToPersonModal = ({
	closeModal,
	viewer,
	staffingModeActive,
	initialPersonId,
	activateStaffingMode,
	placeholderInput,
	placeholderAllocationIds,
	suggestedPersonIdsInput,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {company} = viewer;
	let {placeholder} = company;

	let suggestedPersonIds;
	if (staffingModeActive) {
		placeholder = placeholderInput;
		suggestedPersonIds = suggestedPersonIdsInput;
	} else {
		suggestedPersonIds = placeholder.suggestedPersonIds;
	}

	let placeholderAllocations = placeholder.placeholderAllocations.edges.map(edge => edge.node);

	const skills = placeholder.skills.edges.map(skill => skill.node);
	const placeholderSkillIds = skills ? skills.map(skill => skill.id) : [];

	const sortBySuitability = (a, b) => {
		if (!suggestedPersonIds.includes(a.node.id)) return 1;
		if (!suggestedPersonIds.includes(b.node.id)) return -1;
		return suggestedPersonIds.indexOf(a.node.id) - suggestedPersonIds.indexOf(b.node.id);
	};

	const closeModalWithTracking = () => {
		trackEvent('Assign To Modal', 'Closed');
		closeModal();
	};
	const allPersons = company.allPersons.edges;
	let persons = [...allPersons];
	persons.sort(sortBySuitability);

	const matchedPersons = filterPersons(persons, placeholder.role?.id, placeholderSkillIds);
	const matchedPersonIds = matchedPersons.map(matchedPerson => matchedPerson.node.id);
	persons = persons.filter(person => !matchedPersonIds.includes(person.node.id));

	const [selectedPersonId, setSelectedPersonId] = useState(initialPersonId);
	const [selectedAllocationIds, setSelectedAllocationIds] = useState(
		placeholderAllocationIds ? placeholderAllocationIds : []
	);

	const [isSoft, setIsSoft] = useState(hasModule(MODULE_TYPES.SOFT_ALLOCATIONS));

	const keepPlaceholderLocalStorage = Util.localStorageGetItem(KEEP_PLACEHOLDER_STORAGE_KEY);
	const keepPlaceholderDefault = keepPlaceholderLocalStorage ? JSON.parse(keepPlaceholderLocalStorage) : false;
	const [keepPlaceholderIfEmpty, setKeepPlaceholderIfEmpty] = useState(keepPlaceholderDefault);

	const onPersonChange = personIds => {
		trackEvent('Assign To Modal Person', 'Selected');
		setSelectedPersonId(personIds[0]);
	};

	const onPersonRemove = () => {
		setSelectedPersonId(null);
		trackEvent('Assign To Modal Person', 'Deselected');
	};

	const constructStaffingResponse = () => {
		const placeholderEmpty = placeholderAllocations.length === selectedAllocationIds.length;

		return {
			assignPlaceholderAllocationsToPerson: {
				placeholderId: placeholder.id,
				deletedPlaceholderAllocationIds: selectedAllocationIds,
				placeholderEmpty,
				placeholderWasDeleted: placeholderEmpty && !keepPlaceholderIfEmpty,
				allocations: placeholderAllocations
					.filter(placeholderAllocation => selectedAllocationIds.includes(placeholderAllocation.id))
					.map(placeholderAllocation => {
						const startDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.startDate);
						const endDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.endDate);
						return {
							node: {
								...placeholderAllocation,
								id: placeholderAllocation.id,
								projectId: placeholder.projectId,
								projectGroupId: placeholder.projectGroupId,
								startYear: startDate.year(),
								startMonth: startDate.month() + 1,
								startDay: startDate.date(),
								endYear: endDate.year(),
								endMonth: endDate.month() + 1,
								endDay: endDate.date(),
								isSoft,
								personId: selectedPersonId,
							},
						};
					}),
			},
		};
	};

	const assignToPerson = () => {
		if (staffingModeActive) {
			handleMutationSuccess(constructStaffingResponse());
		} else {
			trackEvent('Assign To Modal Assign Button', 'Clicked');
			const personName = allPersons.find(edge => edge.node.id === selectedPersonId)?.node.fullName;
			assignPlaceholderAllocationsToPersonMutation(
				formatMessage,
				{
					placeholderId: placeholder.id,
					placeholderAllocationIds: selectedAllocationIds,
					personId: selectedPersonId,
					deletePlaceholderIfEmpty: !keepPlaceholderIfEmpty,
					isSoft,
				},
				personName,
				handleMutationSuccess
			);
		}

		if (hasFeatureFlag('capacity_planning_beta_2_improvements') && keepPlaceholderDefault !== keepPlaceholderIfEmpty) {
			Util.localStorageSetItem(KEEP_PLACEHOLDER_STORAGE_KEY, keepPlaceholderIfEmpty);
		}
	};

	const getActionButtons = () => {
		const buttons = [];

		if (staffingModeActive) {
			buttons.push({
				text: formatMessage({id: 'common.cancel'}),
				color: BUTTON_COLOR.WHITE,
				callback: closeModalWithTracking,
			});
		}

		const isSoftSet = typeof isSoft === 'boolean';
		buttons.push({
			text: formatMessage({id: 'placeholder.assign_to_person.assign'}),
			disabled: !selectedPersonId || selectedAllocationIds < 1 || !isSoftSet,
			callback: assignToPerson,
			color: BUTTON_COLOR.GREEN,
		});

		return buttons;
	};

	const getTertiaryActionButtons = () => {
		const buttons = [];

		if (!hasFeatureFlag('capacity_planning_beta_2_improvements') && !staffingModeActive) {
			buttons.push({
				text: formatMessage({id: 'placeholder.assign_to_person.open_staffing'}),
				color: BUTTON_COLOR.WHITE,
				style: BUTTON_STYLE.OUTLINE,
				callback: () => {
					trackEvent('Assign To Modals Advanced Staffing Mode Button', 'Clicked');
					activateStaffingMode();
				},
				disabled: !!selectedPersonId,
			});
		}

		return buttons;
	};

	const content = (
		<PlaceholderModalContent>
			<ModalBody>
				{hasFeatureFlag('capacity_planning_beta_2_improvements') && (
					<Field>
						<PlaceholderModalFieldHeader>
							{formatMessage({id: 'placeholder.assign_to_person.assignee_header.title'})}
						</PlaceholderModalFieldHeader>
						<PlaceholderModalFieldDescription>
							{formatMessage({id: 'placeholder.assign_to_person.assignee_header.description'})}
						</PlaceholderModalFieldDescription>
					</Field>
				)}
				<Field>
					<CustomScrollDiv
						autoHeight
						autoHeightMin={0}
						autoHeightMax={hasFeatureFlag('capacity_planning_beta_2_improvements') ? 179 : 300}
						style={{flexDirection: 'column'}}
					>
						<AllocationCheckboxList
							allocations={placeholderAllocations}
							onChange={setSelectedAllocationIds}
							selected={selectedAllocationIds}
						/>
					</CustomScrollDiv>
				</Field>
				<Field>
					<div className={'dropdown-container'}>
						<InputLabel
							text={formatMessage({id: 'placeholder.assign_to_person.assignee'})}
							child={
								<Dropdown
									selectedItems={[selectedPersonId]}
									name={formatMessage({id: 'common.select_person_placeholder'})}
									maxHeight={200}
									size={'large'}
									onSelect={onPersonChange}
									isNested
									usePortal
									isClearable
									onRemove={onPersonRemove}
								>
									<Dropdown.Group name={formatMessage({id: 'placeholder.modal.matched'})} key={`matched-key`}>
										{matchedPersons.map(person => {
											const {id, firstName, lastName, profilePictureId, role} = person.node;
											return (
												<HexagonText
													key={id}
													value={id}
													searchString={`${firstName} ${lastName}`}
													text={`${firstName} ${lastName}`}
													subText={role ? role.name : ' '}
													imgUrl={profilePicSrc(profilePictureId)}
												/>
											);
										})}
									</Dropdown.Group>
									<Dropdown.Group name={formatMessage({id: 'common.people'})} key={`people-key`}>
										{persons.map(person => {
											const {id, firstName, lastName, profilePictureId, role} = person.node;
											return (
												<HexagonText
													key={id}
													value={id}
													searchString={`${firstName} ${lastName}`}
													text={`${firstName} ${lastName}`}
													subText={role ? role.name : ' '}
													imgUrl={profilePicSrc(profilePictureId)}
												/>
											);
										})}
									</Dropdown.Group>
								</Dropdown>
							}
						/>
					</div>
				</Field>
				{hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) && (
					<Field>
						{hasFeatureFlag('capacity_planning_beta_2_improvements') ? (
							<AllocationTypeSelector
								onChange={isSoftSelected => {
									if (isSoftSelected !== isSoft) {
										setIsSoft(isSoftSelected);
									}
								}}
								isSoft={isSoft}
								disabled={!canApproveAllocation()}
							/>
						) : (
							<CheckboxRow
								checked={isSoft}
								cy={'allocate-modal-soft'}
								highlightChecked
								onChange={() => {
									setIsSoft(!isSoft);
								}}
								disabled={!canApproveAllocation()}
								label={formatMessage({id: 'allocation.soft_allocation'})}
							/>
						)}
					</Field>
				)}

				{!staffingModeActive && hasFeatureFlag('capacity_planning_beta_2_improvements') && (
					<Field>
						<KeepEmptyPlaceholder
							isChecked={keepPlaceholderIfEmpty}
							changeIsChecked={() => setKeepPlaceholderIfEmpty(!keepPlaceholderIfEmpty)}
						/>
					</Field>
				)}

				{hasFeatureFlag('capacity_planning_beta_2_improvements') && !staffingModeActive && (
					<Field>
						<FindAvailableTeamMember
							activateStaffingMode={() => {
								trackEvent('Find Team Member', 'Clicked');
								activateStaffingMode();
								closeModal();
							}}
						/>
					</Field>
				)}
			</ModalBody>
		</PlaceholderModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModalWithTracking}
			content={content}
			modalWidth={hasFeatureFlag('capacity_planning_beta_2_improvements') ? MODAL_WIDTH.MEDIUM : MODAL_WIDTH.SMALL}
			headerText={formatMessage({
				id: hasFeatureFlag('capacity_planning_beta_2_improvements')
					? 'placeholder.assign_to_person.assign_placeholder_allocations'
					: 'placeholder.assign_to_person.header',
			})}
			buttons={getActionButtons()}
			tertiaryButtons={getTertiaryActionButtons()}
		/>
	);
};

const assignToPersonModalQuery = graphql`
	query AssignToPersonModal_Query($placeholderId: ID, $loadPlaceholder: Boolean!) {
		viewer {
			actualPersonId
			component(name: "assign_to_person_modal")
			...AssignToPersonModal_viewer @arguments(placeholderId: $placeholderId, loadPlaceholder: $loadPlaceholder)
		}
	}
`;

export {assignToPersonModalQuery};

export default createFragmentContainer(AssignToPersonModal, {
	viewer: graphql`
		fragment AssignToPersonModal_viewer on Viewer
		@argumentDefinitions(placeholderId: {type: "ID"}, loadPlaceholder: {type: "Boolean!"}) {
			id
			email
			company {
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
					id
					name
					project {
						id
						status
					}
					projectGroupId
					role {
						id
						name
					}
					skills {
						edges {
							node {
								id
							}
						}
					}
					placeholderAllocations {
						edges {
							node {
								id
								startDate
								endDate
								monday
								tuesday
								wednesday
								thursday
								friday
								saturday
								sunday
								description
							}
						}
					}
					suggestedPersonIds
				}
				allPersons(first: 1000000, onlyActive: true, excludeClientUsers: true) {
					edges {
						node {
							id
							active
							firstName
							lastName
							fullName
							profilePictureId
							role {
								id
								name
							}
							skills {
								edges {
									node {
										id
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
