/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
type SettingsProfileSubNav_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type myTaskActivities_viewer$ref: FragmentReference;
declare export opaque type myTaskActivities_viewer$fragmentType: myTaskActivities_viewer$ref;
export type myTaskActivities_viewer = {|
  +component: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +id: string,
  +actualPersonId: ?string,
  +taskActivities: ?$ReadOnlyArray<?{|
    +id: string,
    +date: ?string,
    +eventType: ?string,
    +params: ?string,
    +task: ?{|
      +id: string,
      +companyTaskId: ?number,
      +name: ?string,
      +fullAccessToProject: ?boolean,
      +project: ?{|
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +name: ?string,
        +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
      |},
    |},
  |}>,
  +$fragmentRefs: SettingsProfileSubNav_viewer$ref,
  +$refType: myTaskActivities_viewer$ref,
|};
export type myTaskActivities_viewer$data = myTaskActivities_viewer;
export type myTaskActivities_viewer$key = {
  +$data?: myTaskActivities_viewer$data,
  +$fragmentRefs: myTaskActivities_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "myTaskActivities_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "my_task_activities"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"my_task_activities\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskActivity",
      "kind": "LinkedField",
      "name": "taskActivities",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "params",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "task",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyTaskId",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullAccessToProject",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customProjectId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DeprecatedProjectIndicatorJS_project"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsProfileSubNav_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8fa1c06c856e80a9ed2b435f2cabe8a';

module.exports = node;
