import React from 'react';
import {Table} from 'web-components';
import {
	TableContainer,
	ColumnWrapper,
	ButtonColumnWrapper,
	DetailsWrapper,
} from '../project_health_styles/ProjectHealthTableSection.styled';
import {ActionsColumnAssist, ActionsColumnExplain} from './ActionsColumn';
import {getBaseLevelTableHeader} from './ProjectHealthTableHeader';
import {TitleStatusColumn} from './TitleStatusColumn';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';

export const ProjectHealthTable = ({data, projectHalted, isLockedTable}) => {
	const filteredData = !CompanySetupUtil.hasFinance() ? data.filter(row => row.title !== 'Budget') : data;
	const tableHasAssistCallback = filteredData.some(row => !!row.assistCallback);
	return (
		<TableContainer>
			<Table>
				{getBaseLevelTableHeader(isLockedTable, tableHasAssistCallback)}
				<Table.Rows
					data={{
						rows: filteredData,
					}}
				>
					{({rowData, tableColumnsProps}) => {
						const rowDisabled = projectHalted || rowData.disabled;
						return (
							<Table.Row
								hoverStyle={{
									color: 'blue',
								}}
								onClick={() => rowData.actionCallback && rowData.actionCallback()}
								{...tableColumnsProps}
							>
								<Table.Column>
									<TitleStatusColumn
										title={rowData.title}
										warningVariant={rowData.warningVariant}
										disabled={rowDisabled}
									/>
								</Table.Column>
								<Table.Column>
									<ColumnWrapper title={rowData.info} disabled={rowDisabled}>
										<DetailsWrapper>{rowData.info}</DetailsWrapper>
									</ColumnWrapper>
								</Table.Column>
								<Table.Column>
									<ButtonColumnWrapper>
										{rowData.assistCallback ? (
											<ActionsColumnAssist
												onClick={rowData.assistCallback}
												assistText={rowData.assistText}
											/>
										) : null}
										{rowData.actionCallback ? (
											<ActionsColumnExplain actionText={rowData.actionText}></ActionsColumnExplain>
										) : null}
									</ButtonColumnWrapper>
								</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
			</Table>
		</TableContainer>
	);
};
