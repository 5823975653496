import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import ImportBambooHRUsersMutation from '../../mutations/ImportBambooHRUsersMutation';
import {Checkbox, Dropdown, SingleText} from 'web-components';
import {hideLoader, showLoader} from '../global_loader';
import DirectApi from '../../directApi';
import {createFragmentContainer, graphql} from 'react-relay';
import {Field, ModalBody} from '../../forecast-app/shared/components/modals/Modal.styled';
import InputField from '../../components/inputs/input_field';
import {getAssignableProfiles} from '../../forecast-app/shared/util/ImportUserModalUtil';

class BambooHRImportUsersModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bambooUsers: [],
			fetchingUsers: true,
			selectedUsers: [],
			searchInput: '',
			selectAll: false,
			importing: false,
			selectedProfileId: [],
			profiles: props.company.profiles,
		};

		this.getUsers();
	}

	getUsers() {
		DirectApi.Fetch('bamboohr/employees').then(contacts => {
			this.setState({
				bambooUsers: contacts
					.map(c => ({
						id: c.id,
						name: `${c.firstName ?? ''} ${c.lastName ?? ''} (${c.workEmail})`,
					}))
					.sort((a, b) => (a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : -1)),
				fetchingUsers: false,
			});
		});
	}

	toggleSelectAll() {
		if (this.state.selectAll) {
			this.setState({
				selectedUsers: [],
				selectAll: false,
			});
		} else {
			this.setState({
				selectedUsers: this.state.bambooUsers.map(bambooUser => bambooUser.id),
				selectAll: true,
			});
		}
	}

	toggleContact(bambooHRUserId) {
		if (this.state.selectedUsers.includes(bambooHRUserId)) {
			const selectedUsers = this.state.selectedUsers.filter(id => id !== bambooHRUserId);
			this.setState({
				selectedUsers,
				selectAll: false,
			});
		} else {
			const selectedUsers = this.state.selectedUsers;
			selectedUsers.push(bambooHRUserId);
			this.setState({
				selectedUsers,
			});
		}
	}

	importUsers() {
		this.setState({importing: true});
		showLoader();
		Util.CommitMutation(
			ImportBambooHRUsersMutation,
			{
				bambooHRUserIds: this.state.selectedUsers,
				profileId: this.getSelectedProfileId(),
			},
			() => {
				this.setState({importing: false});
				this.props.closeModal();
				hideLoader();
			}
		);
	}

	onProfileSelect(input) {
		this.setState({selectedProfileId: input});
	}

	getSelectedProfileId() {
		return this.state.selectedProfileId[0];
	}

	getElements() {
		const profilesArray = this.state.profiles.edges.map(option => {
			return {id: option.node.id, name: option.node.name, disable: option.node.disable};
		});
		return getAssignableProfiles(profilesArray).map(option => {
			return (
				<SingleText key={option.id} value={option.id}>
					{option.name}
				</SingleText>
			);
		});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<ModalBody>
				<div className="integration-sync-clients-modal-content">
					<Field>
						<div className="integration-sync-clients-modal-description">
							<FormattedMessage id="integrations.bamboohr.import_users.description" />
						</div>
					</Field>
					<Field>
						<Dropdown
							maxHeight={400}
							selectedItems={this.state.selectedProfileId}
							onSelect={this.onProfileSelect.bind(this)}
							name={formatMessage({id: 'settings_profile.choose_profile'})}
						>
							{this.getElements()}
						</Dropdown>
					</Field>
					{this.state.fetchingUsers ? (
						<InlineLoader />
					) : (
						<>
							<Field>
								<InputField
									onChange={value => {
										this.setState({searchInput: value});
									}}
									placeholder={formatMessage({id: 'common.type_to_search'})}
								/>
							</Field>
							<Field>
								<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
									<div className="integration-clients">
										{this.state.bambooUsers && this.state.bambooUsers.length > 0 ? (
											<>
												<Field>
													<div className="integration-client" key={'0'}>
														<Checkbox
															checked={this.state.selectAll}
															onClick={this.toggleSelectAll.bind(this)}
														/>
														<FormattedMessage id="common.select_all" />
													</div>
												</Field>
												{this.state.bambooUsers
													.filter(
														x =>
															!this.state.searchInput ||
															x.name.toLowerCase().includes(this.state.searchInput.toLowerCase())
													)
													.map(bambooCustomer => (
														<Field>
															<div className="integration-client" key={bambooCustomer.id}>
																<Checkbox
																	checked={this.state.selectedUsers.includes(
																		bambooCustomer.id
																	)}
																	onClick={this.toggleContact.bind(this, bambooCustomer.id)}
																/>
																{bambooCustomer.name}
															</div>
														</Field>
													))}
											</>
										) : (
											<FormattedMessage id="integrations.bamboohr.all_users_linked" />
										)}
									</div>
								</CustomScrollDiv>
							</Field>
						</>
					)}
				</div>
			</ModalBody>
		);

		return (
			<GenericModal
				headerText={<FormattedMessage id="integrations.bamboohr.import_users" />}
				closeModal={this.props.closeModal}
				className="integration-sync-clients-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.create_x_users'}, {numOfUsers: this.state.selectedUsers.length}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.importUsers.bind(this),
						disabled:
							this.state.importing ||
							this.state.selectedUsers.length === 0 ||
							this.state.selectedProfileId.length === 0,
						defaultCallback: () => null,
						preventDefaultClose: true,
					},
				]}
				modalWidth={MODAL_WIDTH.MEDIUM}
				content={content}
			/>
		);
	}
}

const bambooHRImportUsersModalQuery = graphql`
	query BambooHRImportUsersModal_Query {
		viewer {
			actualPersonId
			component(name: "bamboohr_import_users_modal")
			company {
				...BambooHRImportUsersModal_company
			}
		}
	}
`;

export {bambooHRImportUsersModalQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(BambooHRImportUsersModal, {
			company: graphql`
				fragment BambooHRImportUsersModal_company on Company {
					profiles {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
		})
	)
);
