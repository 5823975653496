import {FILTER_SECTION, FILTER_TYPE} from '../../../../constants';
import {FILTER_SECTIONS} from '../../../../forecast-app/shared/components/filters/dropdown_section';
import FilterV4 from '../../../../forecast-app/shared/components/filters/FilterWrapper';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {graphql} from 'graphql';

const LOCALSTORAGE_FILTERS_KEY = 'project-invoices-filter';
const getInvoiceFilters = (newXeroEnabled, quickbooksEnabled, economicEnabled, sageIntacctConnectionEnabled) => {
	const invoiceFilters = [FILTER_TYPE.INVOICE_STATUS, FILTER_TYPE.DUE_DATE];
	if (quickbooksEnabled) {
		invoiceFilters.push(FILTER_TYPE.QBO_STATUS);
	}
	if (newXeroEnabled) {
		invoiceFilters.push(FILTER_TYPE.XERO_STATUS);
	}
	if (economicEnabled) {
		invoiceFilters.push(FILTER_TYPE.ECONOMIC_STATUS);
	}
	if (sageIntacctConnectionEnabled) {
		invoiceFilters.push(FILTER_TYPE.SAGE_INTACCT_STATUS);
	}
	return invoiceFilters;
};

const InvoiceFiltersInLocalstorage = localStorage.getItem(LOCALSTORAGE_FILTERS_KEY)
	? JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY))
	: '';

const InvoiceFilterButton = ({viewer, company, project, setFilterFunctions}) => {
	const onFilterChange = (_, filterFunctions) => {
		setFilterFunctions(filterFunctions);
	};

	return (
		<FilterV4
			key={`filter-v4-component-1`}
			invoiceFilters={getInvoiceFilters(
				company.newXeroEnabled,
				company.quickbooksEnabled,
				company.economicEnabled,
				company.sageIntacctConnectionEnabled
			)}
			viewer={viewer}
			projectId={project.id}
			onFiltersChange={onFilterChange}
			appliedFiltersName={LOCALSTORAGE_FILTERS_KEY}
			filterSection={FILTER_SECTION.INVOICING_INVOICES}
			defaultSection={FILTER_SECTIONS.INVOICES}
			cy={'project_invoicing_filter'}
			enableFilterMode={true}
			useDesignSystemButton={true}
		/>
	);
};

const InvoiceFilterButtonContainer = createFragmentContainer(InvoiceFilterButton, {
	viewer: graphql`
		fragment InvoiceFilterButton_viewer on Viewer {
			filters(first: 1000000, filterSection: INVOICING_INVOICES) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
					}
				}
			}
		}
	`,
	company: graphql`
		fragment InvoiceFilterButton_company on Company {
			newXeroEnabled
			quickbooksEnabled
			economicEnabled
			sageIntacctConnectionEnabled
		}
	`,
	project: graphql`
		fragment InvoiceFilterButton_project on ProjectType {
			id
		}
	`,
});

InvoiceFilterButtonContainer.InvoiceFiltersInLocalstorage = InvoiceFiltersInLocalstorage;
export default InvoiceFilterButtonContainer;
