import React, { useEffect, useMemo, useRef, useState } from 'react';
import Styled from 'styled-components';
import { CollapsibleCard, FlexColumn, FlexRow, Heading, IconTooltip, SegmentedControl, SubHeading, Text, } from '@forecast-it/design-system';
import { Checkbox } from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import useIsMount from '../../../../forecast-app/shared/hooks/useIsMount';
import { useRemappingFormatMessage } from '../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { TooltipFormulaRenderer } from '../../../../components/TooltipFormulaRenderer';
const ValueCalculationsWrapper = Styled.div `
    ul {
    	margin: 0;
    }
`;
export var ValueSource;
(function (ValueSource) {
    ValueSource["TASK"] = "TASK";
    ValueSource["ALLOCATION"] = "ALLOCATION";
})(ValueSource || (ValueSource = {}));
export var Variant;
(function (Variant) {
    Variant["PLANNED"] = "planned";
    Variant["FORECAST"] = "forecast";
    Variant["ACTUAL"] = "actual";
})(Variant || (Variant = {}));
const allTheSame = (items) => {
    if (items.every(e => e === ValueSource.ALLOCATION)) {
        return ValueSource.ALLOCATION;
    }
    else if (items.every(e => e === ValueSource.TASK)) {
        return ValueSource.TASK;
    }
    return '';
};
const CheckboxWrapper = Styled.div `
    &[aria-disabled='false'] {
    	cursor: pointer;
    }
  
    display: flex;
    align-items: center;
	gap: 24px;
	padding: 8px;
`;
function toValueSource(value) {
    return value ? ValueSource[value] : ValueSource.TASK;
}
function getHours(settings, variant) {
    let value;
    if (variant === Variant.PLANNED) {
        value = settings === null || settings === void 0 ? void 0 : settings.plannedHours;
    }
    else if (variant === Variant.FORECAST) {
        value = settings === null || settings === void 0 ? void 0 : settings.forecastHours;
    }
    else if (variant === Variant.ACTUAL) {
        value = settings === null || settings === void 0 ? void 0 : settings.actualHours;
    }
    return toValueSource(value);
}
function getRevenue(settings, variant) {
    let value;
    if (variant === Variant.PLANNED) {
        value = settings === null || settings === void 0 ? void 0 : settings.plannedRevenue;
    }
    else if (variant === Variant.FORECAST) {
        value = settings === null || settings === void 0 ? void 0 : settings.forecastRevenue;
    }
    else if (variant === Variant.ACTUAL) {
        value = settings === null || settings === void 0 ? void 0 : settings.actualRevenue;
    }
    return toValueSource(value);
}
function getCost(settings, variant) {
    let value;
    if (variant === Variant.PLANNED) {
        value = settings === null || settings === void 0 ? void 0 : settings.plannedCost;
    }
    else if (variant === Variant.FORECAST) {
        value = settings === null || settings === void 0 ? void 0 : settings.forecastCost;
    }
    else if (variant === Variant.ACTUAL) {
        value = settings === null || settings === void 0 ? void 0 : settings.actualCost;
    }
    return toValueSource(value);
}
const ValueCalculationsComponent = ({ project, variant }) => {
    //const {formatMessage} = useIntl();
    const formatMessage = useRemappingFormatMessage();
    const settings = project.financialSourceSettings;
    const isMount = useIsMount();
    const [hours, setHours] = useState(getHours(settings, variant));
    const [revenue, setRevenue] = useState(getRevenue(settings, variant));
    const [cost, setCost] = useState(getCost(settings, variant));
    const allSame = useMemo(() => allTheSame([hours, revenue, cost]), [hours, revenue, cost]);
    const [separately, setSeparately] = useState(!allSame);
    const prevHours = useRef(hours);
    const prevRevenue = useRef(revenue);
    const prevCost = useRef(cost);
    const changeAll = value => {
        setHours(value);
        setRevenue(value);
        setCost(value);
    };
    const translateTrackingValue = (value) => variant === Variant.ACTUAL && value === ValueSource.TASK ? 'TIME_REG' : value;
    const trackValueCalculationsSettings = (quantity, oldValue, newValue) => trackEvent('Value Calculations Settings', 'Changed', {
        pageName: 'Project Settings - Financials',
        category: variant,
        quantity,
        oldValue: translateTrackingValue(oldValue),
        newValue: translateTrackingValue(newValue),
    });
    useEffect(() => {
        if (!isMount) {
            let fields;
            if (variant === Variant.PLANNED) {
                fields = {
                    plannedHours: hours,
                    plannedRevenue: revenue,
                    plannedCost: cost,
                };
            }
            else if (variant === Variant.FORECAST) {
                fields = {
                    forecastHours: hours,
                    forecastRevenue: revenue,
                    forecastCost: cost,
                };
            }
            else if (variant === Variant.ACTUAL) {
                fields = {
                    actualHours: hours,
                    actualRevenue: revenue,
                    actualCost: cost,
                };
            }
            Util.CommitMutation(UpdateProjectMutation, {
                project: { id: project.id },
                financialSourceSettings: fields,
            });
            if (prevHours.current !== hours) {
                trackValueCalculationsSettings('Hours', prevHours.current, hours);
            }
            if (prevRevenue.current !== revenue) {
                trackValueCalculationsSettings('Revenue', prevRevenue.current, revenue);
            }
            if (prevCost.current !== cost) {
                trackValueCalculationsSettings('Cost', prevCost.current, cost);
            }
            prevHours.current = hours;
            prevRevenue.current = revenue;
            prevCost.current = cost;
        }
    }, [hours, revenue, cost]);
    const onCheckboxToggle = () => (!separately || allSame) && setSeparately(!separately);
    const taskCalculationsLabelId = variant === Variant.ACTUAL
        ? 'project_settings.financials.value_calculations.time_registrations'
        : 'project_settings.financials.value_calculations.task_estimates';
    const allocationCalculationsLabelId = 'project_settings.financials.value_calculations.allocations';
    return (React.createElement(ValueCalculationsWrapper, null,
        React.createElement(CollapsibleCard, { open: separately },
            React.createElement(CollapsibleCard.Header, null,
                React.createElement(FlexColumn, { gap: 's' },
                    React.createElement(Heading, { size: 4 }, formatMessage({ id: 'project_settings.financials.value_calculations.' + variant + '_values' })),
                    React.createElement(Text, { color: 'medium' }, formatMessage({ id: 'project_settings.financials.value_calculations.' + variant + '_values_description' }, {
                        ul: (...msg) => React.createElement("ul", null, msg),
                        li: (...msg) => React.createElement("li", null, msg),
                    })),
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(SubHeading, null, formatMessage({ id: 'project_settings.financials.value_calculations.' + variant + '_label' })),
                        React.createElement(IconTooltip, null,
                            React.createElement(FlexColumn, { gap: 's' },
                                React.createElement(SubHeading, null, formatMessage({
                                    id: 'project_settings.financials.value_calculations.' + variant + '_tooltip_header',
                                })),
                                React.createElement(Text, null, formatMessage({
                                    id: 'project_settings.financials.value_calculations.' + variant + '_tooltip',
                                }))))),
                    React.createElement(SegmentedControl, { value: allSame, disabled: separately, onValueChange: value => value && changeAll(value) },
                        React.createElement(SegmentedControl.Item, { value: ValueSource.TASK }, formatMessage({ id: taskCalculationsLabelId })),
                        React.createElement(SegmentedControl.Item, { value: ValueSource.ALLOCATION }, formatMessage({ id: allocationCalculationsLabelId }))),
                    React.createElement(CollapsibleCard.Trigger, null,
                        React.createElement(CheckboxWrapper, { onClick: onCheckboxToggle, "aria-disabled": !allSame },
                            React.createElement(Checkbox, { checked: separately, disabled: !allSame }),
                            React.createElement(Text, { color: allSame ? 'link' : 'medium' }, formatMessage({
                                id: 'project_settings.financials.value_calculations.' + variant + '_separately',
                            })))))),
            React.createElement(CollapsibleCard.Content, null,
                React.createElement(FlexColumn, { gap: 'xxl' },
                    React.createElement("div", null,
                        React.createElement(FlexRow, { gap: 's' },
                            React.createElement(SubHeading, null, formatMessage({
                                id: 'project_settings.financials.value_calculations.' + variant + '_hours',
                            })),
                            React.createElement(IconTooltip, null,
                                React.createElement(FlexColumn, { gap: 's' },
                                    React.createElement(SubHeading, null, formatMessage({
                                        id: 'project_settings.financials.value_calculations.' + variant + '_hours',
                                    })),
                                    React.createElement(Text, null, formatMessage({
                                        id: 'project_settings.financials.value_calculations.' +
                                            variant +
                                            '_' +
                                            hours.toLowerCase() +
                                            '_hours_tooltip',
                                    }))))),
                        React.createElement(SegmentedControl, { value: hours, onValueChange: value => value && setHours(value) },
                            React.createElement(SegmentedControl.Item, { value: ValueSource.TASK }, formatMessage({ id: taskCalculationsLabelId })),
                            React.createElement(SegmentedControl.Item, { value: ValueSource.ALLOCATION }, formatMessage({ id: allocationCalculationsLabelId })))),
                    React.createElement("div", null,
                        React.createElement(FlexRow, { gap: 's' },
                            React.createElement(SubHeading, null, formatMessage({
                                id: 'project_settings.financials.value_calculations.' + variant + '_revenue',
                            })),
                            React.createElement(IconTooltip, null,
                                React.createElement(TooltipFormulaRenderer, { items: [
                                        {
                                            title: formatMessage({
                                                id: 'project_settings.financials.value_calculations.' +
                                                    variant +
                                                    '_revenue',
                                            }),
                                            description: formatMessage({
                                                id: 'project_settings.financials.value_calculations.' +
                                                    variant +
                                                    '_revenue_tooltip_description',
                                            }),
                                            details: [
                                                formatMessage({
                                                    id: 'project_settings.financials.value_calculations.' +
                                                        variant +
                                                        '_hours',
                                                }),
                                                'x',
                                                formatMessage({ id: 'common.rate' }),
                                            ],
                                        },
                                    ] }))),
                        React.createElement(SegmentedControl, { value: revenue, onValueChange: value => value && setRevenue(value) },
                            React.createElement(SegmentedControl.Item, { value: ValueSource.TASK }, formatMessage({ id: taskCalculationsLabelId })),
                            React.createElement(SegmentedControl.Item, { value: ValueSource.ALLOCATION }, formatMessage({ id: allocationCalculationsLabelId })))),
                    React.createElement("div", null,
                        React.createElement(FlexRow, { gap: 's' },
                            React.createElement(SubHeading, null, formatMessage({
                                id: 'project_settings.financials.value_calculations.' + variant + '_cost',
                            })),
                            React.createElement(IconTooltip, { width: 'xl', whiteSpace: 'normal' },
                                React.createElement(TooltipFormulaRenderer, { items: [
                                        {
                                            title: formatMessage({
                                                id: 'project_settings.financials.value_calculations.' + variant + '_cost',
                                            }),
                                            details: [
                                                formatMessage({
                                                    id: 'project_settings.financials.value_calculations.' +
                                                        variant +
                                                        '_hours',
                                                }),
                                                'x',
                                                formatMessage({ id: 'finance_tooltip_components.internal_hourly_cost' }),
                                            ],
                                        },
                                    ] }))),
                        React.createElement(SegmentedControl, { value: cost, onValueChange: value => value && setCost(value) },
                            React.createElement(SegmentedControl.Item, { value: ValueSource.TASK }, formatMessage({ id: taskCalculationsLabelId })),
                            React.createElement(SegmentedControl.Item, { value: ValueSource.ALLOCATION }, formatMessage({ id: allocationCalculationsLabelId })))))))));
};
export default ValueCalculationsComponent;
