import React, {useState} from 'react';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from '../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {useIntl} from 'react-intl';
import CreateProjectPersonMutation from '../../mutations/create_project_person_mutation';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import AllocationProjectDropdown from '../../components/canvas-scheduling/components/allocation_project_dropdown';

const AddPersonToProjectModal = ({actualPersonId, personId, projects, projectGroups, projectPersons, company, closeModal}) => {
	const {formatMessage} = useIntl();
	const [selectedProjectId, setSelectedProjectId] = useState();

	const originalAddedProjectIds = projectPersons.filter(pp => pp.personId === personId).map(pp => pp.projectId);
	const selectableProjects = projects?.filter(project => !originalAddedProjectIds.includes(project.id));

	const addPersonToProject = () => {
		const selectedProject =
			projects.find(project => project.id === selectedProjectId) || projectGroups.find(pg => pg.id === selectedProjectId);
		const isConnectedProject = !!selectedProject.companyProjectGroupId;
		Util.CommitMutation(
			CreateProjectPersonMutation,
			{
				projectId: !isConnectedProject ? selectedProjectId : null,
				projectGroupId: isConnectedProject ? selectedProjectId : null,
				personId,
			},
			response => {
				Util.dispatchScheduleEvent(response);
				if (company.isUsingProjectAllocation) {
					showModal(
						{
							type: MODAL_TYPE.ALLOCATE_TEAM_MEMBERSV2,
							projectId: !isConnectedProject ? selectedProjectId : null,
							projectGroupId: isConnectedProject ? selectedProjectId : null,
							selectedTeamMembersIds: [personId],
						},
						true
					);
				}
			}
		);
	};

	const content = (
		<div className="content project-dropdown-wrapper">
			<AllocationProjectDropdown
				label={formatMessage({id: 'common.project'})}
				projects={selectableProjects}
				projectGroups={projectGroups}
				projectPersons={projectPersons}
				selectedValue={selectedProjectId}
				onChange={setSelectedProjectId}
				actualPersonId={actualPersonId}
				includeConnectedProjects
				sortAlphabetically
				cy={'project-list'}
			/>
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.add'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: addPersonToProject,
					cy: 'person-modal-location-submit',
					disabled: !selectedProjectId,
				},
			]}
			cy={'task-location-modal'}
			content={content}
			overflowVisible={true}
			headerText={formatMessage({id: 'scheduling.add_to_project'})}
		/>
	);
};

export default AddPersonToProjectModal;
