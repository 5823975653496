import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Moment from 'moment';
import Input from '../../components/new-ui/input_field';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import ImportHolidayCalendarEntriesMutation from '../../mutations/import_holiday_calendar_entries_mutation';
import Util from '../../forecast-app/shared/util/util';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import CountryData from '../../holiday_api_countries.json';
import {getHolidayCalendar} from './import_holiday_calendar_entries/import_holiday_calendar_util';

class ImportHolidayCalendarEntriesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			year: Moment().year(),
			selectedCountry: 'US',
			canImport: true,
			validYear: true,
		};

		const includeSubdivisions = ['GB'];

		this.countryOptions = CountryData.countries
			.filter(el => {
				// Remove country that includes subdivisions. Because in the current case United Kingdom is
				// also included as a subdivision
				return !includeSubdivisions.includes(el.code);
			})
			.map(el => {
				return {value: el.code, label: el.name};
			});

		const subdivisions = CountryData.countries
			.filter(el => {
				return includeSubdivisions.includes(el.code);
			})
			.reduce((accumulator, currentValue) => accumulator.concat(currentValue.subdivisions), [])
			.map(el => {
				return {value: el.code, label: el.name};
			});

		this.countryOptions = this.countryOptions.concat(subdivisions);

		this.countryOptions.sort((a, b) => {
			if (a.label > b.label) return 1;
			if (a.label < b.label) return -1;
			return 0;
		});
	}

	onYearChange(year) {
		const currentYear = Moment().year();
		const validYear = year >= currentYear - 100 && year <= currentYear + 100;
		this.setState({
			year,
			validYear,
		});
	}

	onCountryChange(option) {
		this.setState({selectedCountry: option.value});
	}

	importHolidayCalendarEntries() {
		this.setState({canImport: false});
		getHolidayCalendar(this.state.year, this.state.selectedCountry, holidayCalendarEntries => {
			Util.CommitMutation(
				ImportHolidayCalendarEntriesMutation,
				{
					id: this.props.holidayCalendarId,
					holidayCalendarEntries,
				},
				() => {
					this.props.closeModal();
				},
				true
			);
		});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="import-holiday-calendar-entries-modal-content">
				<Input
					id="import-holidays-modal-year-input"
					label={formatMessage({id: 'settings_holiday_calendars.year'})}
					value={this.state.year}
					onChange={this.onYearChange.bind(this)}
					type="number"
					invalidInput={!this.state.validYear}
					placeholder=""
				/>
				<Dropdown
					options={this.countryOptions}
					onChange={this.onCountryChange.bind(this)}
					value={this.state.selectedCountry}
					label={formatMessage({id: 'company_details.country_label'})}
				/>
			</div>
		);

		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.cancelText || formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'settings_holiday_calendars.import'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.importHolidayCalendarEntries.bind(this),
						disabled: !this.state.canImport || !this.state.validYear,
						cy: 'import-public-holidays-button-modal',
					},
				]}
				headerText={formatMessage({id: 'settings_holiday_calendars.import'})}
				content={content}
			/>
		);
	}
}
ImportHolidayCalendarEntriesModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	holidayCalendarId: PropTypes.string.isRequired,
};
export default injectIntl(ImportHolidayCalendarEntriesModal);
