import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const ProjectStatusWrapper = styled.div`
	margin-bottom: 12px;
`;

export const TopRow = styled.div`
	display: flex;
	align-items: center;
	font-size: 11px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const RichTextWrapper = styled.div`
	font-size: 13px;
	font-weight: 400;
	color: ${CSS_CONSTANTS.v2_text_gray};
	max-height: ${props => props.maxHeight};
	overflow: hidden;
	.editor {
		padding: 8px 0 0 0;
		margin: 0;
		.public-DraftEditor-content {
			min-height: 0px;
		}
	}
	.DraftEditor-editorContainer,
	.DraftEditor-root {
		position: unset;
	}
`;
