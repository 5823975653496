/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type missionControlHeader_viewer$ref = any;
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type featureFlags_viewer$ref: FragmentReference;
declare export opaque type featureFlags_viewer$fragmentType: featureFlags_viewer$ref;
export type featureFlags_viewer = {|
  +component: ?string,
  +id: string,
  +firstName: ?string,
  +lastName: ?string,
  +company: ?{|
    +id: string
  |},
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +missionControlData: ?{|
    +id: string,
    +companies: ?$ReadOnlyArray<?{|
      +id: string,
      +companyId: ?number,
      +name: ?string,
      +tier: ?TierType,
      +forecastDemo: ?boolean,
      +contactEmail: ?string,
      +accessBlocked: ?boolean,
    |}>,
    +availableFeatures: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +key: ?string,
      +color: ?string,
      +team: ?string,
      +description: ?string,
      +rolledOut: ?boolean,
      +rolledOutDate: ?string,
      +cleanedUp: ?boolean,
      +expectedRollOutDate: ?string,
      +type: ?string,
      +companies: ?$ReadOnlyArray<?{|
        +id: string,
        +companyId: string,
        +name: ?string,
        +timestamp: ?string,
      |}>,
      +persons: ?$ReadOnlyArray<?{|
        +id: string,
        +personId: string,
        +companyId: string,
        +companyName: ?string,
        +name: ?string,
        +email: ?string,
        +timestamp: ?string,
      |}>,
      +personsOptedOut: ?$ReadOnlyArray<?{|
        +id: string,
        +companyId: string,
        +companyName: ?string,
        +name: ?string,
        +email: ?string,
      |}>,
    |}>,
    +otherPersons: ?$ReadOnlyArray<?{|
      +id: string,
      +firstName: ?string,
      +lastName: ?string,
      +email: ?string,
      +companyName: ?string,
      +companyId: string,
    |}>,
  |},
  +$fragmentRefs: missionControlHeader_viewer$ref,
  +$refType: featureFlags_viewer$ref,
|};
export type featureFlags_viewer$data = featureFlags_viewer;
export type featureFlags_viewer$key = {
  +$data?: featureFlags_viewer$data,
  +$fragmentRefs: featureFlags_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "featureFlags_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "control_feature_flags"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"control_feature_flags\")"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlCompanyType",
          "kind": "LinkedField",
          "name": "companies",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tier",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastDemo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessBlocked",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailableFeaturesType",
          "kind": "LinkedField",
          "name": "availableFeatures",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "team",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rolledOut",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rolledOutDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cleanedUp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expectedRollOutDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyFeatureType",
              "kind": "LinkedField",
              "name": "companies",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonFeatureType",
              "kind": "LinkedField",
              "name": "persons",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personId",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v7/*: any*/),
                (v5/*: any*/),
                (v8/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonFeatureType",
              "kind": "LinkedField",
              "name": "personsOptedOut",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v7/*: any*/),
                (v5/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "includeForecastPersons",
              "value": true
            }
          ],
          "concreteType": "MissionControlImpersonatePersonType",
          "kind": "LinkedField",
          "name": "otherPersons",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v8/*: any*/),
            (v7/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": "otherPersons(includeForecastPersons:true)"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "missionControlHeader_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c1ff45ba54d3d5df10c237e8f143554';

module.exports = node;
