/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectStatus_projectStatus$ref: FragmentReference;
declare export opaque type ProjectStatus_projectStatus$fragmentType: ProjectStatus_projectStatus$ref;
export type ProjectStatus_projectStatus = {|
  +id: string,
  +person: ?{|
    +id: string,
    +fullName: ?string,
    +profilePictureId: ?string,
    +profilePictureDefaultId: ?number,
  |},
  +description: ?string,
  +color: ?string,
  +day: ?number,
  +month: ?number,
  +year: ?number,
  +$refType: ProjectStatus_projectStatus$ref,
|};
export type ProjectStatus_projectStatus$data = ProjectStatus_projectStatus;
export type ProjectStatus_projectStatus$key = {
  +$data?: ProjectStatus_projectStatus$data,
  +$fragmentRefs: ProjectStatus_projectStatus$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectStatus_projectStatus",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePictureId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePictureDefaultId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "day",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    }
  ],
  "type": "ProjectStatus"
};
})();
// prettier-ignore
(node/*: any*/).hash = '55435694180e87cc07ccdaf24bb4826f';

module.exports = node;
