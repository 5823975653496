import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import UpdatePasswordMutation from '../../mutations/update_password_mutation';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as tracking from '../../tracking';
import InputField from '../../components/inputs/input_field';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import SettingsProfileSubNav from '../settings/SettingsProfileSubNav';

class SettingsSecurity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCurrentPasswordValidationError: false,
			showConfirmPasswordValidationError: false,
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			passwordValidationError: '',
			showPasswordHelp: false,
		};
		this.onRelaySuccess = this.onRelaySuccess.bind(this);

		this.superPropertyChecksum = trackPage('Security');
	}

	componentDidMount() {
		document.title = 'Security - My profile - Forecast';

		// Segment
		tracking.trackPage('settings-security');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	onRelaySuccess(result) {
		if (result.updateViewer.errors) {
			// Show error
			this.setState({
				showCurrentPasswordValidationError: true,
				showConfirmPasswordValidationError: false,
			});
		} else {
			// Hide error
			this.setState({
				showCurrentPasswordValidationError: false,
				showConfirmPasswordValidationError: false,
				currentPassword: '',
				newPassword: '',
				confirmPassword: '',
			});
			createToast({
				message: this.props.intl.formatMessage({id: 'confirm_reset_password_modal.description'}),
				duration: 3000,
			});
		}
	}

	updatePassword() {
		const currentPassword = this.state.currentPassword;
		const newPassword = this.state.newPassword;
		const confirmPassword = this.state.confirmPassword;

		if (newPassword !== '' && newPassword === confirmPassword) {
			const passwordValidation = Util.validatePasswordChange(currentPassword, newPassword);
			if (passwordValidation.valid) {
				this.setState({
					showCurrentPasswordValidationError: false,
					showConfirmPasswordValidationError: false,
				});
				Util.CommitMutation(
					UpdatePasswordMutation,
					{
						oldPassword: currentPassword,
						newPassword: newPassword,
					},
					this.onRelaySuccess
				);
			} else {
				this.setState({
					showCurrentPasswordValidationError: false,
					showConfirmPasswordValidationError: true,
					passwordValidationError: passwordValidation.error,
				});
			}
		} else {
			this.setState({
				showCurrentPasswordValidationError: false,
				showConfirmPasswordValidationError: true,
				passwordValidationError: 'PASSWORDS_DONT_MATCH',
			});
		}
	}

	handleKeyPress(target) {
		// If Enter was pressed, update the password
		if (target && target.charCode === 13) {
			this.updatePassword();
		}
	}

	handleCurrentPasswordChange(value) {
		this.setState({currentPassword: value});
	}

	handleNewPasswordChange(value) {
		this.setState({newPassword: value});
	}

	handleConfirmPasswordChange(value) {
		this.setState({confirmPassword: value});
	}

	onPasswordFocus() {
		this.setState({showPasswordHelp: true});
	}

	onPasswordBlur() {
		if (this.isPasswordValid()) this.setState({showPasswordHelp: false});
	}

	handlePasswordInputRef(ref) {
		this.passwordInputRef = ref;
	}

	isPasswordValid() {
		if (process.env.CIRCLE_BRANCH !== 'production' && process.env.CIRCLE_BRANCH !== 'master') {
			return true;
		}
		return (
			Util.validateMin8Chars(this.state.newPassword) &&
			Util.validate1Digit(this.state.newPassword) &&
			Util.validate1Letter(this.state.newPassword) &&
			Util.validate3Identical(this.state.newPassword)
		);
	}

	getTopHeader() {
		const {formatMessage} = this.props.intl;
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={formatMessage({id: 'header.security'})} />
			</TopHeaderBarWrapper>
		);
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className="section-content settings-app settings-security" data-cy={'security-page'}>
				{this.props.children}
				{this.getTopHeader()}
				<SettingsProfileSubNav viewer={this.props.viewer} />
				<div className="section-body">
					<div className="inner">
						<fieldset className="general">
							<h3>
								<FormattedMessage id="user-settings.security.password" />
							</h3>
							<p className="description">
								<FormattedMessage id="user-settings.security.description" />
							</p>
							<div className="titles-material">
								<InputField
									label={formatMessage({id: 'user-settings.security.field.title.current-password'})}
									type="password"
									placeholder={formatMessage({
										id: 'user-settings.security.field.placeholder.current-password',
									})}
									id="current_password"
									value={this.state.currentPassword}
									onChange={this.handleCurrentPasswordChange.bind(this)}
									errorMessage={formatMessage({
										id: 'user-settings.security.password_validation_error.CURRENT_PASSWORD_INCORRECT',
									})}
									inputValid={!this.state.showCurrentPasswordValidationError}
								/>
								<InputField
									handleRef={this.handlePasswordInputRef.bind(this)}
									onFocus={this.onPasswordFocus.bind(this)}
									onBlur={this.onPasswordBlur.bind(this)}
									label={formatMessage({id: 'user-settings.security.field.title.new-password'})}
									type="password"
									placeholder={formatMessage({id: 'user-settings.security.field.placeholder.new-password'})}
									id="new_password"
									value={this.state.newPassword}
									onChange={this.handleNewPasswordChange.bind(this)}
								/>
								<div
									ref={div => (this.passwordBubble = div)}
									className={
										'bubble left' +
										(this.state.showPasswordHelp ? ' show' : '') +
										(this.isPasswordValid() ? ' valid' : '')
									}
									style={{top: '30px', left: '240px'}}
								>
									{Util.getPasswordHelpText(this.state.newPassword, this.state.confirmPassword)}
								</div>
								<InputField
									onFocus={this.onPasswordFocus.bind(this)}
									onBlur={this.onPasswordBlur.bind(this)}
									label={formatMessage({id: 'user-settings.security.field.title.confirm-password'})}
									type="password"
									placeholder={formatMessage({
										id: 'user-settings.security.field.placeholder.confirm-password',
									})}
									id="confirm_password"
									onKeyPress={this.handleKeyPress.bind(this)}
									value={this.state.confirmPassword}
									onChange={this.handleConfirmPasswordChange.bind(this)}
									errorMessage={
										this.state.passwordValidationError
											? formatMessage({
													id:
														'user-settings.security.password_validation_error.' +
														this.state.passwordValidationError,
											  })
											: undefined
									}
									inputValid={!this.state.showConfirmPasswordValidationError}
								/>
								<div className="save">
									<button
										className="submit-button"
										onClick={this.updatePassword.bind(this)}
										disabled={!this.isPasswordValid()}
									>
										<FormattedMessage id="user-settings.security.button.update-password" />
									</button>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		);
	}
}

const profileSecurityQuery = graphql`
	query profileSecurity_Query {
		viewer {
			actualPersonId
			component(name: "settings_security")
			...profileSecurity_viewer
		}
	}
`;

export {profileSecurityQuery};

export default createFragmentContainer(injectIntl(SettingsSecurity), {
	viewer: graphql`
		fragment profileSecurity_viewer on Viewer {
			id
			...SettingsProfileSubNav_viewer
		}
	`,
});
