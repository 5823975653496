import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import MomentTimezone from 'moment-timezone';
import LogRocket from 'logrocket';

import * as tracking from '../tracking';
import {timezones, TIERS} from '../constants';
import Util from '../forecast-app/shared/util/util';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import DirectApi from '../directApi';
import {
	DeprecatedButton as Button,
	FLEX_ALIGN_ITEMS,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedInput as Input,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import JoinCompanyMutation from '../mutations/join_company_mutation';
import SetFirstTimePasswordMutation from '../mutations/set_first_time_password_mutation';
import VerifyFirstTimeKeyMutation from '../mutations/verify_first_time_key_mutation';
import countryPhoneCodes from '../countryPhoneCodes';
import DropdownV2 from '../forecast-app/shared/components/dropdowns/dropdown';
import SegmentedControl from '../forecast-app/shared/components/segmented-control/SegmentedControl';
import RadioButton from '../forecast-app/shared/components/radio-button/RadioButton';
import RadioDropdown from '../forecast-app/shared/components/radio-button/RadioDropdown';
import CreatePersonMutationModern from '../mutations/create_person_mutation.modern';
import {trackEvent, trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';
import {emailBlockedList} from '../emailBlockedList';

export const TERMS_VERSION = 'July2023';

const EMAIL_ERROR = {
	NONE: 'none',
	INVALID: 'invalid',
	USED: 'used',
};

const CountryCodeContainer = styled.div`
	flex: 0 0 90px;
	max-width: 90px;
`;

const Checkbox = styled.input`
	appearance: none;
	width: 24px;
	height: 24px;
	border: 1px solid ${props => (props.error ? '#FEB6B3' : '#e7e7f3')};
	border-radius: 4px;
	cursor: pointer;
	&:checked {
		background: #605dec;
		border: 1px solid #605dec;
		position: relative;
	}
	&:checked:before {
		content: '';
		position: absolute;
		left: 6px;
		top: 4px;
		--t: 5px;
		--r: 5px;
		--w: 35px;
		--h: 20px;
		--c: white;
		height: var(--h);
		width: var(--w);
		border-bottom-left-radius: var(--r);
		background: radial-gradient(farthest-side at top right, transparent 97%, var(--c) 100%) bottom calc(var(--t) - 0.5px)
				left calc(var(--t) - 0.5px) / calc(var(--t) * 0.8) calc(var(--t) * 0.8),
			radial-gradient(farthest-side, var(--c) 99%, transparent 100%) top left / var(--t) var(--t),
			radial-gradient(farthest-side, var(--c) 99%, transparent 100%) bottom right/var(--t) var(--t),
			linear-gradient(var(--c), var(--c)) left bottom/var(--t) calc(100% - var(--t) / 2),
			linear-gradient(var(--c), var(--c)) left bottom/calc(100% - var(--t) / 2) var(--t);
		background-repeat: no-repeat;
		transform: rotate(-45deg) scale(0.4);
		transform-origin: left;
	}
	&:focus {
		outline: 4px solid #e5f4ff;
		border: 1px solid #4db0ff;
	}
`;

const PasswordInput = styled.input`
	height: 40px;
	border: 1px solid ${props => (props.error ? '#FEB6B3' : '#e7e7f3')};
	border-radius: 4px;
	background: ${props => (props.error ? '#FFEBEB' : '#FFFFFF')} !important;
	font: large Verdana, sans-serif;
	letter-spacing: 1px;
	padding: 0px 12px;
	&:focus {
		outline: 4px solid #e5f4ff;
		border: 1px solid #4db0ff;
	}
`;

const StandardWidthContainer = styled.div`
	width: 440px;
	max-width: 440px;
	button {
		padding: 0 16px;
	}
	.ghxert {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		width: 0;
		z-index: -1;
	}
`;

const CheckboxText = styled.div`
	font-family: Inter, sans-serif;
	font-size: 11px;
	line-height: 12px;
`;

const CheckboxContainer = styled.div`
	height: 24px;
	flex: 0 0 auto;
`;

// TODO: Change when new text component is exported from design-system
const NewHeader = styled.div`
	font-family: 'Inter';
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #535353;
`;

// TODO: Change when new text component is exported from design-system
const NewText = styled.div`
	font-family: 'Inter';
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #535353;
`;

class signupFlow extends Component {
	constructor(props) {
		super(props);
		const adminId = btoa('Profile:-3');
		const {search} = this.props.location;
		const searchParams = new URLSearchParams(search);

		this.state = {
			isFirstPersonInCompany: false,
			step: 'start',
			// step: 'invite_team_members',
			emailError: EMAIL_ERROR.NONE,
			email: searchParams.get('email') || '',
			verifyingEmail: false,
			firstLoginKey: this.props.match?.params?.key || '',
			name: '',
			nameMissing: false,
			phone: '',
			phoneMissing: false,
			countryCode: '',
			countryCodeMissing: false,
			password: '',
			passwordInvalid: false,
			acceptTerms: false,
			termsMissing: false,
			acceptAutoJoin: true,
			isAutojoinAvailable: false,
			accountName: '',
			accountNameMissing: false,
			companySize: '',
			companySizeMissing: false,
			industry: '',
			industryMissing: false,
			otherIndustry: 'Other',
			team: '',
			teamMissing: false,
			role: '',
			roleMissing: false,
			profiles: [{id: adminId, name: 'Admin'}],
			inviteData: {
				1: {
					email: '',
					error: '',
					profile: adminId,
					loading: false,
					success: false,
				},
				2: {
					email: '',
					error: '',
					profile: adminId,
					loading: false,
					success: false,
				},
				3: {
					email: '',
					error: '',
					profile: adminId,
					loading: false,
					success: false,
				},
			},
			loading: false,
			userJoining: false,
			// old
			// loggedIn: this.props.viewer.actualPersonId,
			// showContent: false,
			// title: '',
			// website: null,
			// forecastPickOption: [],
			// toolUsed: '',
			// companyRoleOption: '',
			// companySizeValid: true,
			// companySizeOptions: ['1-9', '10-19', '20-49', '50-99', '100-199', '200-499', '500+'],
			// companyRoleOptions: '',
			// companyRoleValid: true,
			// businessType: '',
			// businessTypeValid: true,
			// tourOption: null,
			// loading: false,
			// showPasswordHelp: false,
			// acceptTermsValid: true,
			// ipGeolocation: '',
			// userJoining: false,
		};

		// this.getDomainFromEmail = this.getDomainFromEmail.bind(this);

		if (this.props.viewer.actualPersonId) {
			if (this.props.viewer.company.tier === TIERS.TRIAL) {
				this.props.history.push('/trial-guide');
			} else {
				this.props.history.push('/');
			}
		}
		this.props.setLocaleFromBrowser();

		this.accountNameAutoFocused = false;

		this.accountNameRef = input => {
			if (input && !this.accountNameAutoFocused) {
				input.focus();
				this.accountNameAutoFocused = true; // only auto focus on first call
			}
		};
		this.ghxertRef = React.createRef();

		this.superPropertyChecksum = trackPage('Signup Flow');
	}

	componentDidMount() {
		const key = this.props.match?.params?.key;
		if (key) {
			this.verifyFirstTimeKey(key);
		} else if (this.state.email) {
			trackEvent('Signup Flow', 'Started');
			this.submitEmail();
		} else {
			trackEvent('Signup Flow', 'Started');
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSubmit() {
		this.setState({loading: true});
		const fullName = this.state.name.trim();
		let firstName;
		let lastName;
		if (fullName.indexOf(' ') <= 0) {
			firstName = fullName;
			lastName = '';
		} else {
			firstName = fullName.substr(0, fullName.indexOf(' ')).trim();
			lastName = fullName.substr(fullName.indexOf(' ') + 1).trim();
		}
		const email = this.state.email.trim() || this.props.viewer.email,
			password = this.state.password.trim(),
			phone = this.state.phone,
			countryPhoneCode = this.state.countryCode; //.replace(/[A-Za-z]/, '');

		const onSuccess = response => {
			if (response.setFirstTimePassword.success) {
				const profiles = this.state.profiles;
				profiles.push(...response.setFirstTimePassword.profiles);
				Util.setCsrfValue(response.setFirstTimePassword.csrfToken);
				Util.localStorageSetItem('displayMode-projects-overview', 'list');
				if (this.state.isFirstPersonInCompany) {
					tracking.trackEvent('Signup Flow Completed');
					trackEvent('First Person Signup', 'Completed');
					this.setState({loading: false, step: 'invite_team_members', profiles: profiles});
				} else {
					tracking.trackEvent(this.state.userJoining ? 'Auto-join Flow Completed' : 'Invited User Flow Completed');
					if (this.state.userJoining) {
						trackEvent('Auto-join User Signup', 'Completed');
					} else {
						trackEvent('Invited User Signup', 'Completed');
					}
					this.setState({loading: false});
					if (response.setFirstTimePassword.companyTier === TIERS.TRIAL) {
						this.props.history.push('/trial-guide');
					} else {
						this.props.history.push('/projects');
					}
				}
			}
		};

		let timezone = timezones.filter(timezone => {
			return timezone.value === MomentTimezone.tz.guess();
		});
		timezone = timezone.length ? timezone[0].value : undefined;

		const language = navigator.language || navigator.userLanguage;
		const noDanishDefaultLanguage = language.startsWith('da') ? 'en-EU' : language;

		Util.CommitMutation(
			SetFirstTimePasswordMutation,
			{
				email: email,
				companyName: this.state.accountName,
				title: this.state.role,
				password: password,
				firstName: firstName,
				lastName: lastName,
				phoneNumber: countryPhoneCode + phone,
				website: this.getDomainFromEmail(email),
				timezone: timezone,
				key: this.state.firstLoginKey,
				language: noDanishDefaultLanguage,
				companySize: this.state.companySize,
				businessType: this.state.industry === 'Other' ? this.state.otherIndustry : this.state.industry,
				teamType: this.state.team,
				roleType: this.state.role,
				termsVersionAccepted: TERMS_VERSION,
				autoJoinAccepted: this.state.isAutojoinAvailable ? this.state.acceptAutoJoin : false,
			},
			onSuccess,
			true,
			null,
			() => {
				this.setState({loading: false});
			}
		);
	}

	getDomainFromEmail(email) {
		return email != null ? email.split('@')[1] : '';
	}

	verifyFirstTimeKey(key, onSuccess = () => {}) {
		Util.CommitMutation(
			VerifyFirstTimeKeyMutation,
			{
				key: key,
			},
			e => {
				if (e.verifyFirstTimeKey.isKeyValid) {
					const email = e.verifyFirstTimeKey.person.email;
					const domain = this.getDomainFromEmail(email).split('.')[0];
					const accountName = domain.replace(/^./, domain[0].toUpperCase());
					const stateData = {
						email,
						accountName,
						showContent: true,
						isFirstPersonInCompany: e.verifyFirstTimeKey.isFirstPersonInCompany,
						isAutojoinAvailable: e.verifyFirstTimeKey.isAutojoinAvailable,
						userJoining: e.verifyFirstTimeKey.person.joined,
						firstLoginKey: key,
					};

					try {
						if (process.env.CIRCLE_BRANCH === 'production') {
							LogRocket.init('forecast/forecast-nod93');
						}
					} catch (e) {}

					if (e.verifyFirstTimeKey.person.firstName !== '') {
						let firstName = '';

						if (
							e.verifyFirstTimeKey.person.firstName &&
							e.verifyFirstTimeKey.person.firstName !== e.verifyFirstTimeKey.person.email
						) {
							firstName = e.verifyFirstTimeKey.person.firstName;
						}

						let lastName = e.verifyFirstTimeKey.person.lastName ? e.verifyFirstTimeKey.person.lastName : '',
							JobTitle = e.verifyFirstTimeKey.person.jobTitle ? e.verifyFirstTimeKey.person.jobTitle : '',
							phoneNumber = e.verifyFirstTimeKey.person.phone ? e.verifyFirstTimeKey.person.phone : '';

						stateData.name = firstName + (lastName?.trim() ? ' ' + lastName : '');
						stateData.title = JobTitle;
						stateData.phone = phoneNumber;
					}
					if (stateData.isFirstPersonInCompany) {
						//tracking.trackEvent('Signup Flow Started');
						stateData.step = 'setup_company_account';
					} else {
						// TODO: Initiate invited / auto-join flow
						tracking.trackEvent(
							stateData.userJoining ? 'Autojoin Signup Flow Started' : 'Invite Signup Flow Started'
						);
						stateData.step = 'join_account';
					}
					this.setState(stateData);
					onSuccess();
				} else {
					this.setState({step: 'invalid_key'});
				}
			}
		);
	}

	joinCompany() {
		this.setState({verifyingEmail: true});
		const onSuccess = response => {
			if (response.joinCompany.person) {
				tracking.trackEvent('Signup Flow Autojoin successful');
				trackEvent('Signup Flow Auto-join', 'Succeeded');
				this.setState({verifyingEmail: false, step: 'autojoin_success'});
			} else if (response.joinCompany.errors && response.joinCompany.errors.includes('NO_AVAILABLE_SEATS')) {
				tracking.trackEvent('Signup Flow Autojoin failed');
				trackEvent('Signup Flow Auto-join', 'Failed');
				this.setState({verifyingEmail: false, step: 'autojoin_failed'});
			}
		};

		Util.CommitMutation(
			JoinCompanyMutation,
			{
				email: this.state.email === '' ? null : this.state.email,
				join: true,
			},
			onSuccess
		);
	}

	createNew() {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const init = {headers: headers};

		fetch(DirectApi.graphqlServerEndpoint('signup?email=' + this.state.email), init)
			.then(result => result.json())
			.then(json => {
				this.setState({invalidEmail: false, emailInUse: false});
				switch (json.resultCode) {
					case '0':
						// Success
						this.verifyFirstTimeKey(json.key);
						break;
					case '1':
					case '4':
						// Email missing
						this.setState({invalidEmail: true});
						break;
					case '2':
						// Email already exists
						this.setState({emailInUse: true});
						break;
					default:
						break;
				}
				this.setState({verifyingEmail: false});
			});
	}

	submitEmail() {
		const email = this.state.email.trim();
		var valid = /(.+)@(.+)\.(.+)/.test(email);

		var emailAccepted = true;

		if (valid) {
			let emailProvider = email.split('@')[1];
			if (emailBlockedList.includes(emailProvider)) {
				emailAccepted = false;
			}
		}

		if (valid && emailAccepted) {
			const domain = this.getDomainFromEmail(email).split('.')[0];
			const accountName = domain.replace(/^./, domain[0].toUpperCase());
			this.setState({email, verifyingEmail: true, accountName});
			const headers = new Headers();
			headers.append('Content-Type', 'application/json');
			const init = {headers: headers};
			fetch(DirectApi.graphqlServerEndpoint('autojoin_check?email=' + email), init)
				.then(result => result.json())
				.then(json => {
					switch (json.resultCode) {
						case '0':
							// Success - Incomplete signup found. Continue with first login key
							this.verifyFirstTimeKey(json.key, () => {
								tracking.trackEvent('Signup Flow Email Submitted', {email: email});
								trackEvent('Signup Flow Email', 'Submitted', {email: email});
								this.setState({verifyingEmail: false});
							});
							break;
						case '2':
							// Email in use
							this.setState({
								emailError: EMAIL_ERROR.USED,
								verifyingEmail: false,
							});
							break;
						case '6':
							// Auto join
							tracking.trackEvent('Signup Flow Email Submitted', {email: email});
							trackEvent('Signup Flow Email', 'Submitted', {email: email});
							this.setState({step: 'autojoin', autojoinDomain: json.domain, verifyingEmail: false});
							break;
						case '7':
							// Success - Continue normal signup procedure
							tracking.trackEvent('Signup Flow Email Submitted', {email: email});
							trackEvent('Signup Flow Email', 'Submitted', {email: email});
							this.createNew();
							break;
						case '8':
							// Not a business email
							this.setState({
								emailError: EMAIL_ERROR.INVALID,
								verifyingEmail: false,
							});
							break;
						default:
							break;
					}
				})
				.catch(e => {
					this.setState({emailError: EMAIL_ERROR.INVALID, verifyingEmail: false});
				});
		} else {
			this.setState({emailError: EMAIL_ERROR.INVALID});
		}
	}

	getEmailErrorMessage() {
		switch (this.state.emailError) {
			case EMAIL_ERROR.USED:
				return 'An account with this email already exists, please enter a new email.';
			case EMAIL_ERROR.INVALID:
				return 'Please enter your work email.';
			default:
				return '';
		}
	}

	getContent() {
		switch (this.state.step) {
			case 'start':
				return this.getEmailStepContent();
			case 'autojoin':
				return this.getAutoJoinStepContent();
			case 'autojoin_success':
				return this.getAutoJoinSuccessStepContent();
			case 'autojoin_failed':
				return this.getAutoJoinFailedStepContent();
			case 'setup_company_account':
				return this.getNewCompanyStepContent();
			case 'company_details':
				return this.getCompanyDetailsStepContent();
			case 'team_details':
				return this.getTeamDetailsStepContent();
			case 'invite_team_members':
				return this.getInviteStepContent();
			case 'join_account':
				return this.getJoinAccountContent();
			case 'invalid_key':
				return this.getInvalidKeyContent();
			default:
				return null;
		}
	}

	getEmailStepContent() {
		const onEmailChange = event => {
			this.setState({email: event.target.value, emailError: EMAIL_ERROR.NONE});
		};

		const emailErrorMessage = this.getEmailErrorMessage();

		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="s">
							<Text type="heading" variant="xl">
								Sign up for free
							</Text>
							<Text type="base">Free 7 day trial. No credit card needed.</Text>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Work email
							</Text>
							<FlexColumn gap="xs">
								<Input
									id="email"
									key="email"
									autoFocus
									size="l"
									error={this.state.emailError !== EMAIL_ERROR.NONE}
									value={this.state.email}
									onChange={onEmailChange}
									onKeyPress={e => {
										if (e.key === 'Enter') {
											this.submitEmail();
										}
									}}
									data-cy="signup-flow-email"
								/>
								{emailErrorMessage ? (
									<Text type="small" color="destructive">
										{emailErrorMessage}
									</Text>
								) : null}
							</FlexColumn>
						</FlexColumn>
					</FlexColumn>
					<FlexItem>
						<Button
							size="l"
							onClick={this.submitEmail.bind(this)}
							disabled={this.state.verifyingEmail}
							data-cy="signup-flow-submit-email-btn"
						>
							Continue
						</Button>
					</FlexItem>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}

	getAutoJoinStepContent() {
		const dontConnect = () => {
			tracking.trackEvent('Signup Flow Autojoin rejected');
			trackEvent('Signup Flow Auto-join', 'Rejected');
			this.setState({verifyingEmail: true});
			this.createNew();
		};
		return (
			<FlexColumn gap="xl">
				<FlexColumn gap="s">
					<Text type="heading" variant="xl">
						We already have an account with this name
					</Text>
					<Text type="base">Would you like to be connected with this team?</Text>
				</FlexColumn>
				<FlexRow gap="l">
					<FlexItem>
						<Button
							size="l"
							onClick={this.joinCompany.bind(this)}
							disabled={this.state.verifyingEmail}
							data-cy="signup-flow-connect-btn"
						>
							Connect me
						</Button>
					</FlexItem>
					<FlexItem>
						<Button
							size="l"
							type="ghost"
							onClick={dontConnect}
							disabled={this.state.verifyingEmail}
							data-cy="signup-flow-dont-connect-btn"
						>
							Do not connect
						</Button>
					</FlexItem>
				</FlexRow>
			</FlexColumn>
		);
	}

	getAutoJoinSuccessStepContent() {
		return (
			<FlexColumn gap="s">
				<Text type="heading" variant="xl">
					Account Created
				</Text>
				<Text type="base">Check your email to verify your email address and join Forecast.</Text>
			</FlexColumn>
		);
	}

	getAutoJoinFailedStepContent() {
		return (
			<FlexColumn gap="xl">
				<FlexColumn gap="s">
					<Text type="heading" variant="xl">
						Unable to Join Forecast
					</Text>
					<Text type="base">
						The plan for your company has no Forecast seats available. Please contact your Forecast administrator.
					</Text>
				</FlexColumn>
				<FlexItem>
					<Button
						size="l"
						onClick={() => {
							this.props.history.push('https://www.forecast.app');
						}}
					>
						Log out
					</Button>
				</FlexItem>
			</FlexColumn>
		);
	}

	getNewCompanyStepContent() {
		const onNameChange = event => {
			this.setState({name: event.target.value, nameMissing: false});
		};
		const onPhoneChange = event => {
			this.setState({phone: event.target.value, phoneMissing: false});
		};
		const onCountryCodeChange = option => {
			this.setState({countryCode: option.value, countryCodeMissing: false});
		};
		const onPasswordChange = event => {
			this.setState({password: event.target.value, passwordInvalid: false});
		};
		const onTermsClick = () => {
			this.setState({acceptTerms: !this.state.acceptTerms, termsMissing: false});
		};
		const onAutoJoinClick = () => {
			this.setState({acceptAutoJoin: !this.state.acceptAutoJoin});
		};
		const validateAccountInput = () => {
			const nameMissing = this.state.name.length === 0;
			const phoneMissing = this.state.phone.length === 0;
			const countryCodeMissing = this.state.countryCode.length === 0;
			const passwordInvalid = !Util.validatePassword(this.state.password).valid;
			const termsMissing = !this.state.acceptTerms;
			if (nameMissing || phoneMissing || countryCodeMissing || passwordInvalid || termsMissing) {
				this.setState({nameMissing, phoneMissing, countryCodeMissing, passwordInvalid, termsMissing});
			} else {
				tracking.trackEvent('Signup Flow Account Setup', {
					name: this.state.name,
					phone: this.state.phone,
					countryCode: this.state.countryCode,
					acceptAutoJoin: this.state.acceptAutoJoin,
					suspectedBot: this.ghxertRef?.current?.value?.length > 0,
				});
				trackEvent('Signup Flow Account Setup Step', 'Completed', {
					name: this.state.name,
					phone: this.state.phone,
					countryCode: this.state.countryCode,
					acceptAutoJoin: this.state.acceptAutoJoin,
					suspectedBot: this.ghxertRef?.current?.value?.length > 0,
				});
				this.setState({step: 'company_details'});
			}
		};
		const countryCodeOptions = countryPhoneCodes.countryPhoneCodes
			.map((code, index) => ({
				label: code.name + ' (' + code.dial_code + ')',
				value: '(' + code.dial_code + ')',
				valueLabel: code.dial_code,
				// cy: 'code' + index,
			}))
			.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			});
		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="s">
							<Text type="heading" variant="xl">
								Set up your account
							</Text>
							<Text type="base">Please enter your details.</Text>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Full name
							</Text>
							<FlexColumn gap="xs">
								<Input
									autoFocus
									id="nameghxert"
									name="nameghxert"
									data-cy="signup-flow-name"
									size="l"
									error={this.state.nameMissing}
									value={this.state.name}
									onChange={onNameChange}
								/>
								{this.state.nameMissing ? (
									<Text type="small" color="destructive">
										Please enter your full name.
									</Text>
								) : null}
							</FlexColumn>
							{/* H o n e y p o t fields */}
							<form autoComplete="do-not-autofill" className="ghxert" id="ghxertform">
								<label className="ghxert" htmlFor="name"></label>
								<input
									className="ghxert"
									autoComplete="do-not-autofill"
									type="text"
									id="name"
									name="name"
									placeholder="Your name here"
									tabIndex="-1"
									ref={this.ghxertRef}
								></input>
							</form>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Phone
							</Text>
							<FlexRow alignItems="flex-start" gap={'s'}>
								<CountryCodeContainer>
									{/* TODO: Replace with searchable dropdown once it gets added to design system */}
									<DropdownV2
										value={this.state.countryCode}
										onChange={onCountryCodeChange}
										options={countryCodeOptions}
										hideLabel={true}
										inputCy={'signup-flow-country-code-dropdown'}
										invalidInput={this.state.countryCodeMissing}
										placeholder={this.state.countryCode !== '' ? this.state.countryCode.value : ''}
										// stayOpenOnSelect={false}
										restrictWidth={false}
										customClasses={'no-border shadow'}
									/>
								</CountryCodeContainer>
								<FlexColumn gap="xs">
									<Input
										id="phoneghxert"
										name="phoneghxert"
										data-cy="signup-flow-phone"
										size="l"
										error={this.state.phoneMissing}
										value={this.state.phone}
										onChange={onPhoneChange}
									/>
									{this.state.phoneMissing ? (
										<Text type="small" color="destructive">
											Please enter your phone number.
										</Text>
									) : null}
								</FlexColumn>
							</FlexRow>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Password
							</Text>
							<FlexColumn gap="xs">
								<PasswordInput
									type="password"
									id="passwordghxert"
									name="passwordghxert"
									data-cy="signup-flow-password"
									autoComplete="new-password"
									error={this.state.passwordInvalid}
									value={this.state.password}
									onChange={onPasswordChange}
								/>
								{this.state.passwordInvalid ? (
									<Text type="small" color="destructive">
										Password must contain atleast 8 characters, 1 digit, 1 letter, and no 3 identical
										characters in a row.
									</Text>
								) : null}
							</FlexColumn>
						</FlexColumn>
					</FlexColumn>
					{this.state.isAutojoinAvailable ? (
						<FlexRow gap="l" alignItems="center">
							<CheckboxContainer>
								<Checkbox type="checkbox" checked={this.state.acceptAutoJoin} onChange={onAutoJoinClick} />
							</CheckboxContainer>
							<FlexItem>
								<CheckboxText>
									Allow everyone with a <b>{`@${this.state.email.split('@')[1]}`}</b> email address users to
									automatically join your Forecast account.
								</CheckboxText>
							</FlexItem>
						</FlexRow>
					) : null}
					<FlexRow gap="l" alignItems="center">
						<CheckboxContainer>
							<Checkbox
								type="checkbox"
								data-cy="signup-flow-terms-chkbox"
								checked={this.state.acceptTerms}
								onChange={onTermsClick}
								error={this.state.termsMissing}
							/>
						</CheckboxContainer>
						<FlexItem>
							<CheckboxText>
								To comply with data protection laws, we ask that you accept our{' '}
								<a href="https://www.forecast.app/terms-of-service">terms and conditions</a> before using
								Forecast.
							</CheckboxText>
						</FlexItem>
					</FlexRow>
					<FlexItem>
						<Button size="l" onClick={validateAccountInput} data-cy="signup-flow-step1-btn">
							Set up account
						</Button>
					</FlexItem>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}

	getCompanyDetailsStepContent() {
		const onAccountNameChange = event => {
			this.setState({accountName: event.target.value, accountNameMissing: false});
		};

		const onCompanySizeChange = value => {
			this.setState({companySize: value, companySizeMissing: false});
		};

		const onIndustryChange = value => {
			this.setState({industry: value, industryMissing: false});
		};

		const validateAccountInput = () => {
			const accountNameMissing = this.state.accountName === '';
			const companySizeMissing = this.state.companySize === '';
			const industryMissing = this.state.industry === '';

			if (accountNameMissing || companySizeMissing || industryMissing) {
				this.setState({accountNameMissing, companySizeMissing, industryMissing});
			} else {
				// Send to next step
				tracking.trackEvent('Signup Flow Company Details', {
					accountName: this.state.accountName,
					companySize: this.state.companySize,
					industry: this.state.industry,
				});
				trackEvent('Signup Flow Company Details Step', 'Completed', {
					accountName: this.state.accountName,
					companySize: this.state.companySize,
					industry: this.state.industry,
				});
				this.setState({step: 'team_details'});
			}
		};

		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="s">
							<Text type="heading" variant="xl">
								Tell us about your company
							</Text>
							<Text type="base">Help us set up your account.</Text>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Account name
							</Text>
							<FlexColumn gap="xs">
								<Input
									id="account"
									key="account"
									autoComplete="do-not-autofill"
									autoFocus
									data-cy="signup-flow-account-name"
									size="l"
									error={this.state.accountNameMissing}
									value={this.state.accountName}
									onChange={onAccountNameChange}
									ref={this.accountNameRef}
								/>
								{this.state.accountNameMissing ? (
									<Text type="small" color="destructive">
										Please enter an account name.
									</Text>
								) : null}
							</FlexColumn>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Company Size
							</Text>
							<FlexItem>
								<SegmentedControl
									name="company_size"
									onClick={onCompanySizeChange}
									cyPrefix="signup-flow-"
									options={[
										{value: '1-9'},
										{value: '10-19'},
										{value: '20-49'},
										{value: '50-99'},
										{value: '100-199'},
										{value: '200-499'},
										{value: '500+'},
									]}
									error={this.state.companySizeMissing}
								/>
							</FlexItem>
						</FlexColumn>
						{this.state.companySize !== '' ? (
							<FlexColumn gap="s">
								<Text type="base" variant="bold">
									Industry
								</Text>
								<FlexColumn gap="m">
									<FlexRow wrap="true" gap="m">
										{[
											'Advertising / Marketing / Media',
											'Business Consulting',
											'Construction / Real Estate',
											'Telecommunications',
											'Technology / IT / Software / System Integrator',
										].map((value, index) => (
											<RadioButton
												key={index}
												name="industry-select"
												value={value}
												dataCy={`signup-flow-industry-${value}`}
												onClick={onIndustryChange}
												error={this.state.industryMissing}
											/>
										))}
									</FlexRow>
									<FlexItem alignSelf={FLEX_ALIGN_ITEMS.FLEX_START}>
										<RadioDropdown
											name="industry-select"
											value="Other"
											onClick={onIndustryChange}
											options={[
												{value: 'Education'},
												{value: 'Engineering / Architecture'},
												{value: 'Entertainment / Travel / Hospitality'},
												{value: 'Financial & Legal Services'},
												{value: 'Government'},
												{value: 'Health Care / Biotechnology'},
												{value: 'Manufacturing'},
												{value: 'Not For Profit'},
												{value: 'Oil / Gas / Energy'},
												{value: 'Retail'},
												{value: 'Other'},
											]}
											onOptionSelect={option => {
												this.setState({otherIndustry: option.value});
											}}
											error={this.state.industryMissing}
										/>
									</FlexItem>
								</FlexColumn>
							</FlexColumn>
						) : null}
					</FlexColumn>
					<FlexItem>
						<Button size="l" onClick={validateAccountInput} data-cy="signup-flow-step2-btn">
							Add company details
						</Button>
					</FlexItem>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}

	getTeamDetailsStepContent() {
		const onTeamChange = value => {
			this.setState({team: value, teamMissing: false});
		};

		const onRoleChange = value => {
			this.setState({role: value, roleMissing: false});
		};

		const validateTeamInput = () => {
			const teamMissing = this.state.team === '';
			const roleMissing = !teamMissing && this.state.role === '';
			if (teamMissing || roleMissing) {
				this.setState({teamMissing, roleMissing});
			} else {
				// Log in
				tracking.trackEvent('Signup Flow Team Details', {
					team: this.state.team,
					role: this.state.role,
				});
				trackEvent('Signup Flow Team Details Step', 'Completed');
				this.handleSubmit();
			}
		};
		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="s">
							<Text type="heading" variant="xl">
								Tell us about your team
							</Text>
							<Text type="base">Let’s get to know you better.</Text>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Your team
							</Text>
							<FlexRow wrap="true" gap="m">
								{[
									'Project Management',
									'Operations',
									'Marketing',
									'Technology / IT',
									'Finance',
									'Studio / Producer',
									'Professional Services',
									'Client Services',
									'Product',
									'Design / Research',
								].map((value, index) => (
									<RadioButton
										autoFocus={index === 0}
										key={`team-radio-${index}`}
										name="team-select"
										value={value}
										dataCy={`signup-flow-team-${value}`}
										onClick={onTeamChange}
										error={this.state.teamMissing}
									/>
								))}
							</FlexRow>
						</FlexColumn>
						{this.state.team !== '' ? (
							<FlexColumn gap="s">
								<Text type="base" variant="bold">
									Your role
								</Text>
								<FlexRow wrap="true" gap="m">
									{[
										'Team member',
										'Team lead / Manager',
										'Freelancer',
										'Business owner',
										'Director / VP / C-Level',
									].map((value, index) => (
										<RadioButton
											key={`role-radio-${index}`}
											name="role-select"
											value={value}
											dataCy={`signup-flow-role-${value}`}
											onClick={onRoleChange}
											error={this.state.roleMissing}
										/>
									))}
								</FlexRow>
							</FlexColumn>
						) : null}
					</FlexColumn>
					<FlexItem>
						<Button
							size="l"
							onClick={validateTeamInput}
							disabled={this.state.loading}
							data-cy="signup-flow-step3-btn"
						>
							Add team details
						</Button>
					</FlexItem>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}

	getInviteRow(index) {
		const getEmailErrorMessage = error => {
			switch (error) {
				case 'ACCOUNT_ALREADY_EXIST':
					return 'This email is already in use.';
				case 'INVALID':
					return 'This email is invalid.';
				case 'EMPTY':
					return 'Please enter an email.';
				default:
					return 'An error occurred.';
			}
		};
		const profileOptions = this.state.profiles.map(profile => ({
			label: profile.name,
			value: profile.id,
		}));
		return (
			<FlexRow gap="m" alignItems="flex-start">
				<FlexColumn gap="xs">
					<Input
						size="l"
						error={this.state.inviteData[index].error !== ''}
						value={this.state.inviteData[index].email}
						onChange={e => {
							let inviteData = this.state.inviteData;
							inviteData[index].email = e.target.value;
							inviteData[index].error = '';
							this.setState({inviteData});
						}}
						placeholder="Add email"
						disabled={this.state.inviteData[index].loading || this.state.inviteData[index].success}
					/>
					{this.state.inviteData[index].error ? (
						<Text type="small" color="destructive">
							{getEmailErrorMessage(this.state.inviteData[index].error)}
						</Text>
					) : null}
				</FlexColumn>

				<DropdownV2
					value={this.state.inviteData[index].profile}
					onChange={option => {
						let inviteData = this.state.inviteData;
						inviteData[index].profile = option.value;
						this.setState({inviteData});
					}}
					options={profileOptions}
					hideLabel={true}
					customWidth={120}
					customClasses={'no-border shadow'}
					disabled={this.state.inviteData[index].loading || this.state.inviteData[index].success}
				/>
			</FlexRow>
		);
	}

	inviteUser(index) {
		let inviteData = this.state.inviteData;
		if (inviteData[index].email === '') {
			return;
		}
		if (!inviteData[index].loading && !inviteData[index].success) {
			inviteData[index].loading = true;
			this.setState({inviteData});
			Util.CommitMutation(
				CreatePersonMutationModern,
				{
					companyId: this.props.viewer.company.id,
					email: inviteData[index].email,
					firstName: inviteData[index].email.split('@')[0],
					lastName: '',
					profileIds: [inviteData[index].profile],
					adminPassword: this.state.password.trim(),
					invited: true,
				},
				e => {
					inviteData = this.state.inviteData;
					if (e.createPerson.errors?.length) {
						inviteData[index].error = e.createPerson.errors[0];
					} else {
						tracking.trackEvent('Signup Flow User Invited', {email: inviteData[index].email});
						trackEvent('Signup Flow User', 'Invited', {email: inviteData[index].email});
						inviteData[index].success = true;
					}
					inviteData[index].loading = false;

					// If all 3 invites are either successful or error free, forward to trial guide page
					if (
						(inviteData['1'].success || !inviteData['1'].error) &&
						(inviteData['2'].success || !inviteData['2'].error) &&
						(inviteData['3'].success || !inviteData['3'].error)
					) {
						this.props.history.push('/trial-guide');
					} else {
						this.setState({inviteData});
					}
				}
			);
		}
	}

	getInviteStepContent() {
		const sendInvites = () => {
			let inviteData = this.state.inviteData;
			// If all 3 inputs are empty or successful show an error message in the first empty input.
			if (
				(inviteData['1'].email === '' || inviteData['1'].success) &&
				(inviteData['2'].email === '' || inviteData['2'].success) &&
				(inviteData['3'].email === '' || inviteData['3'].success)
			) {
				if (inviteData['1'].email === '') {
					inviteData['1'].error = 'EMPTY';
				} else if (inviteData['2'].email === '') {
					inviteData['2'].error = 'EMPTY';
				} else if (inviteData['3'].email === '') {
					inviteData['3'].error = 'EMPTY';
				} else {
					// If all three invites are successful and we are somehow still here, forward to trial guide.
					this.props.history.push('/trial-guide');
					return;
				}
				this.setState({inviteData});
				return;
			}
			// Validate against regEx
			const regEx = /(.+)@(.+)\.(.+)/;
			const valid1 = inviteData['1'].email === '' || regEx.test(inviteData['1'].email);
			const valid2 = inviteData['2'].email === '' || regEx.test(inviteData['2'].email);
			const valid3 = inviteData['3'].email === '' || regEx.test(inviteData['3'].email);
			if (!valid1 || !valid2 || !valid3) {
				inviteData['1'].error = valid1 ? '' : 'INVALID';
				inviteData['2'].error = valid2 ? '' : 'INVALID';
				inviteData['3'].error = valid3 ? '' : 'INVALID';
				this.setState({inviteData});
				return;
			}

			// Invite users
			this.inviteUser('1');
			this.inviteUser('2');
			this.inviteUser('3');
		};
		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="s">
							<Text type="heading" variant="xl">
								Invite your colleagues
							</Text>
							<Text type="base">Invite more people to Forecast.</Text>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Invite colleagues
							</Text>
							{this.getInviteRow('1')}
							{this.getInviteRow('2')}
							{this.getInviteRow('3')}
						</FlexColumn>
					</FlexColumn>
					<FlexRow gap="l">
						<FlexItem>
							<Button size="l" onClick={sendInvites}>
								Invite colleagues
							</Button>
						</FlexItem>
						<FlexItem>
							<Button
								size="l"
								onClick={() => {
									tracking.trackEvent('Signup Flow Invite Step Skipped');
									trackEvent('Signup Flow Invite Step', 'Skipped');
									this.props.history.push('/trial-guide');
								}}
								type="ghost"
								data-cy="signup-flow-step4-skip-btn"
							>
								Skip
							</Button>
						</FlexItem>
					</FlexRow>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}

	getJoinAccountContent() {
		const onNameChange = event => {
			this.setState({name: event.target.value, nameMissing: false});
		};
		const onJobTitleChange = event => {
			this.setState({role: event.target.value, roleMissing: false});
		};
		const onPasswordChange = event => {
			this.setState({password: event.target.value, passwordInvalid: false});
		};
		const onTermsClick = () => {
			this.setState({acceptTerms: !this.state.acceptTerms, termsMissing: false});
		};
		const validateAccountInput = () => {
			const nameMissing = this.state.name.length === 0;
			const jobTitleMissing = this.state.role.length === 0;
			const passwordInvalid = !Util.validatePassword(this.state.password).valid;
			const termsMissing = !this.state.acceptTerms;
			if (nameMissing || jobTitleMissing || passwordInvalid || termsMissing) {
				this.setState({nameMissing, jobTitleMissing, passwordInvalid, termsMissing});
			} else {
				const eventObject = this.state.userJoining ? 'Autojoined Account Setup' : 'Invited Account Setup';
				tracking.trackEvent(eventObject, {
					name: this.state.name,
					jobTitle: this.state.role,
				});

				trackEvent(eventObject, 'Completed', {
					name: this.state.name,
					jobTitle: this.state.role,
				});

				this.handleSubmit();
			}
		};
		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="s">
							<Text type="heading" variant="xl">
								Set up your account
							</Text>
							<Text type="base">Please enter your details.</Text>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Full name
							</Text>
							<FlexColumn gap="xs">
								<Input
									autoFocus
									id="name"
									name="name"
									data-cy="signup-flow-name"
									size="l"
									error={this.state.nameMissing}
									value={this.state.name}
									onChange={onNameChange}
								/>
								{this.state.nameMissing ? (
									<Text type="small" color="destructive">
										Please enter your full name.
									</Text>
								) : null}
							</FlexColumn>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Job title
							</Text>
							<FlexColumn gap="xs">
								<Input
									id="job-title"
									name="job-title"
									data-cy="signup-flow-job-title"
									size="l"
									error={this.state.jobTitleMissing}
									value={this.state.jobTitle}
									onChange={onJobTitleChange}
								/>
								{this.state.jobTitleMissing ? (
									<Text type="small" color="destructive">
										Please enter your job title.
									</Text>
								) : null}
							</FlexColumn>
						</FlexColumn>
						<FlexColumn gap="s">
							<Text type="base" variant="bold">
								Password
							</Text>
							<FlexColumn gap="xs">
								<PasswordInput
									type="password"
									id="password"
									name="password"
									data-cy="signup-flow-password"
									autoComplete="new-password"
									error={this.state.passwordInvalid}
									value={this.state.password}
									onChange={onPasswordChange}
								/>
								{this.state.passwordInvalid ? (
									<Text type="small" color="destructive">
										Password must contain atleast 8 characters, 1 digit, 1 letter, and no 3 identical
										characters in a row.
									</Text>
								) : null}
							</FlexColumn>
						</FlexColumn>
					</FlexColumn>
					<FlexRow gap="l" alignItems="center">
						<CheckboxContainer>
							<Checkbox
								type="checkbox"
								data-cy="signup-flow-terms-chkbox"
								checked={this.state.acceptTerms}
								onChange={onTermsClick}
								error={this.state.termsMissing}
							/>
						</CheckboxContainer>
						<FlexItem>
							<CheckboxText>
								To comply with data protection laws, we ask that you accept our{' '}
								<a href="https://www.forecast.app/terms-of-service">terms and conditions</a> before using
								Forecast.
							</CheckboxText>
						</FlexItem>
					</FlexRow>
					<FlexItem>
						<Button size="l" onClick={validateAccountInput} data-cy="signup-flow-step1-btn">
							Set up account
						</Button>
					</FlexItem>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}
	getInvalidKeyContent() {
		return (
			<StandardWidthContainer>
				<FlexColumn gap="xl">
					<FlexColumn gap="l">
						<FlexColumn gap="xxl">
							<NewHeader>The Link Is No Longer Active</NewHeader>
							<NewText>Contact your admin to receive an active link</NewText>
						</FlexColumn>
					</FlexColumn>
				</FlexColumn>
			</StandardWidthContainer>
		);
	}

	render() {
		return (
			<div className="signup-flow-outer-wrapper">
				{this.state.loading ? <UploadingOverlay useGrayOverlay={true} /> : null}
				<div className="background-image"></div>
				<div className="inner-wrapper">
					<div className="signup-container">{this.getContent()}</div>
				</div>
			</div>
		);
	}
}

const signupFlowQuery = graphql`
	query signupFlow_Query {
		viewer {
			component(name: "signup_flow")
			...signupFlow_viewer
		}
	}
`;

export {signupFlowQuery};

export default createFragmentContainer(withRouter(signupFlow), {
	viewer: graphql`
		fragment signupFlow_viewer on Viewer {
			id
			csrfToken
			actualPersonId
			email
			scimProvisioned
			company {
				id
				tier
			}
		}
	`,
});
