import React, {Component} from 'react';

import PropTypes from 'prop-types';

class FlexModal extends Component {
	callback(callback) {
		callback(this.props.params);
		this.props.closeModal();
	}

	defaultCallback() {
		if (this.props.defaultCallback) {
			this.props.defaultCallback(this.props.params);
		}
		this.props.closeModal();
	}

	render() {
		return (
			<div className="layer top-level" onMouseDown={this.defaultCallback.bind(this)}>
				<div className="flex-modal">
					<div
						className={'flex-container' + (this.props.fullHeight ? ' full-height' : '')}
						onMouseDown={e => {
							e.stopPropagation();
						}}
						style={{maxWidth: this.props.maxWidth ? this.props.maxWidth + 'px' : ''}}
					>
						<div className="content">
							<div className={'header' + (this.props.headerBorder ? ' headerBorder' : '')}>
								{this.props.headerText && this.props.headerText.length ? (
									<h2>{this.props.headerText}</h2>
								) : null}
								<button className="close-button" onClick={this.props.closeModal} />
							</div>
							{this.props.descriptionText ? <span>{this.props.descriptionText}</span> : null}
							{this.props.descriptionPoints
								? this.props.descriptionPoints.map((point, index) => <span key={index}>{point}</span>)
								: null}
							<div className="content-body">
								{this.props.content
									? React.cloneElement(this.props.content, {closeModal: this.props.closeModal})
									: null}
							</div>
						</div>
						{this.props.options ? (
							<div className="controls">
								{this.props.options.map((option, index) => (
									<button
										data-cy={option.cy}
										key={'flexModalButton' + index}
										className={
											option.type
												? option.type === 'primary'
													? 'button-primary'
													: option.type === 'delete'
													? 'button-delete'
													: 'button-secondary'
												: 'button-secondary'
										}
										onClick={
											option.callback
												? this.callback.bind(this, option.callback)
												: this.defaultCallback.bind(this)
										}
									>
										{option.label}
									</button>
								))}
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}
FlexModal.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string.isRequired, callback: PropTypes.func}).isRequired),
	headerText: PropTypes.string,
	descriptionText: PropTypes.string,
	params: PropTypes.object,
	defaultCallback: PropTypes.func,
	content: PropTypes.element,
	closeModal: PropTypes.func.isRequired,
	fullHeight: PropTypes.bool,
};
export default FlexModal;
