import React, { useEffect, useState } from 'react';
import { createRefetchContainer } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';
import styled from 'styled-components';
import RestoreTaskMutation from '../../../mutations/mission_control/RestoreTaskMutation.js';
import Util from '../../../forecast-app/shared/util/util';
import MissionControlHeader from './MissionControlHeader';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { BUTTON_COLOR } from '../../../constants';
import { hideLoader, showLoader } from '../../global_loader';
import environment from '../../../RelayEnvironment';
const Controls = styled.div `
	display: flex;
	gap: 8px;
	margin: 8px;
	input {
		width: 75px;
	}
`;
const deletedTaskLevelInfoQuery = graphql `
	query RestoreData_deletedTaskLevelInfo_Query($companyId: Int, $taskEventCount: ID!) {
		viewer {
			missionControlData {
				deletedTaskLevelInfo(companyId: $companyId, taskEventCount: $taskEventCount) {
					parentCompanyTaskId
					grandParentCompanyTaskId
				}
			}
		}
	}
`;
function fetchDeletedTasks(relay, companyId, page, daysAgo, setLoading) {
    setLoading(true);
    relay.refetch({ companyId, page, daysAgo }, null, () => setLoading(false));
}
function RestoreData({ viewer, relay }) {
    var _a, _b;
    const [page, setPage] = useState(1);
    const [companyId, setCompanyId] = useState('');
    const [daysAgo, setDaysAgo] = useState('');
    const [restoring, setRestoring] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (companyId && daysAgo) {
            fetchDeletedTasks(relay, Number(companyId), page, Number(daysAgo), setLoading);
        }
    }, [page]);
    function getWarningInfo(data, taskName) {
        var _a, _b;
        const deletedTaskLevelInfo = (_b = (_a = data === null || data === void 0 ? void 0 : data.viewer) === null || _a === void 0 ? void 0 : _a.missionControlData) === null || _b === void 0 ? void 0 : _b.deletedTaskLevelInfo;
        if (!deletedTaskLevelInfo) {
            return { text: `Are you sure you want to restore "${taskName}"?`, taskHierarchy: false };
        }
        else {
            if (deletedTaskLevelInfo.grandParentCompanyTaskId) {
                return {
                    text: `This task has a parent task: "T${deletedTaskLevelInfo.parentCompanyTaskId}" and a grandparent task: "T${deletedTaskLevelInfo.grandParentCompanyTaskId}". Restoring this task: "${taskName}" without restoring the parent task and grandparent task first, will cause the task to be restored without its task hierarchy. Are you sure you want to restore "${taskName}"?`,
                    taskHierarchy: true,
                };
            }
            if (deletedTaskLevelInfo.parentCompanyTaskId) {
                return {
                    text: `This task has a parent task: "T${deletedTaskLevelInfo.parentCompanyTaskId}". Restoring this task: "${taskName}" without restoring the parent task first, will cause the task to be restored without its task hierarchy. Are you sure you want to restore "${taskName}"?`,
                    taskHierarchy: true,
                };
            }
            return {
                text: `Are you sure you want to restore "${taskName}"?`,
                taskHierarchy: false,
            };
        }
    }
    function restoreTask(taskEventCount, taskName) {
        if (!taskEventCount) {
            return;
        }
        fetchQuery(environment.getInstanceMC(), deletedTaskLevelInfoQuery, {
            companyId: Number(companyId),
            taskEventCount,
        }).then((response) => {
            const { text, taskHierarchy } = getWarningInfo(response, taskName);
            const callback = () => {
                if (restoring) {
                    return;
                }
                showLoader();
                setRestoring(true);
                Util.CommitMutation(RestoreTaskMutation, {
                    taskEventCount,
                    ignoreParent: taskHierarchy,
                }, () => {
                    hideLoader();
                    setRestoring(false);
                    fetchDeletedTasks(relay, Number(companyId), page, Number(daysAgo), setLoading);
                });
            };
            showModal({
                type: MODAL_TYPE.WARNING,
                message: 'Are you sure?',
                irreversible: true,
                warningInformation: [text],
                buttons: [
                    {
                        text: 'Cancel',
                        color: BUTTON_COLOR.WHITE,
                    },
                    {
                        text: taskHierarchy ? 'Restore without hierarchy' : 'Restore',
                        callback,
                    },
                ],
            });
        });
    }
    const data = (_a = viewer.missionControlData) === null || _a === void 0 ? void 0 : _a.paginatedDeletedTaskList;
    return (React.createElement("div", { className: "mission-control-list" },
        React.createElement(MissionControlHeader, { viewer: viewer }),
        React.createElement("h1", null, "Restore Data"),
        React.createElement(Controls, null,
            React.createElement("div", null, "Company Id:"),
            React.createElement("input", { value: companyId, onChange: e => {
                    setCompanyId(e.target.value.replace(/\D/g, ''));
                } }),
            React.createElement("div", null, "Last"),
            React.createElement("input", { value: daysAgo, onChange: e => {
                    setDaysAgo(e.target.value.replace(/\D/g, ''));
                } }),
            React.createElement("div", null, "days"),
            React.createElement("button", { onClick: () => {
                    if (page !== 1) {
                        setPage(1);
                    }
                    else {
                        fetchDeletedTasks(relay, Number(companyId), page, Number(daysAgo), setLoading);
                    }
                }, disabled: !companyId || !daysAgo || loading }, "Fetch deleted tasks")),
        React.createElement(Controls, null, (data === null || data === void 0 ? void 0 : data.pageNumber) && data.pageSize && data.totalObjectCount ? (React.createElement(React.Fragment, null,
            React.createElement("div", null, `Showing ${(data.pageNumber - 1) * data.pageSize + 1} to ${Math.min(data.pageNumber * data.pageSize, data.totalObjectCount)} out of ${data.totalObjectCount} deleted tasks.`),
            React.createElement("div", null,
                React.createElement("button", { disabled: page < 2 || loading, onClick: () => {
                        setPage(page - 1);
                    } }, "Prev"),
                React.createElement("button", { disabled: data.pageNumber * data.pageSize >= data.totalObjectCount || loading, onClick: () => {
                        setPage(page + 1);
                    } }, "Next")))) : null),
        React.createElement("div", { style: { maxHeight: '85vh', overflowY: 'scroll' } },
            React.createElement("table", null,
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Task Name"),
                        React.createElement("th", null, "Project Name"),
                        React.createElement("th", null, "Deleted at (UTC)"),
                        React.createElement("th", null, "Deleted by"),
                        React.createElement("th", null, "Restore")), (_b = data === null || data === void 0 ? void 0 : data.pageContents) === null || _b === void 0 ? void 0 :
                    _b.map(task => {
                        return (React.createElement("tr", { key: task === null || task === void 0 ? void 0 : task.id },
                            React.createElement("td", null, task === null || task === void 0 ? void 0 : task.name),
                            React.createElement("td", null, task === null || task === void 0 ? void 0 : task.projectName),
                            React.createElement("td", null, task === null || task === void 0 ? void 0 : task.deletedAt),
                            React.createElement("td", null, task === null || task === void 0 ? void 0 : task.deletedBy),
                            React.createElement("td", null,
                                React.createElement("button", { onClick: () => {
                                        restoreTask(task === null || task === void 0 ? void 0 : task.id, (task === null || task === void 0 ? void 0 : task.name) || '');
                                    }, disabled: restoring }, "Restore Task"))));
                    }))))));
}
export const RestoreDataQuery = graphql `
	query RestoreData_Query($companyId: Int, $page: Int, $daysAgo: Int) {
		viewer {
			actualPersonId
			component(name: "restore_data")
			...RestoreData_viewer @arguments(companyId: $companyId, page: $page, daysAgo: $daysAgo)
		}
	}
`;
export default createRefetchContainer(RestoreData, {
    viewer: graphql `
			fragment RestoreData_viewer on Viewer
			@argumentDefinitions(companyId: {type: "Int"}, page: {type: "Int"}, daysAgo: {type: "Int"}) {
				id
				...MissionControlHeader_viewer
				missionControlData {
					id
					paginatedDeletedTaskList(companyId: $companyId, page: $page, daysAgo: $daysAgo) {
						id
						pageSize
						pageNumber
						totalObjectCount
						pageContents {
							id
							name
							projectName
							deletedAt
							deletedBy
						}
					}
				}
			}
		`,
}, graphql `
		query RestoreDataRefetchQuery($companyId: Int, $page: Int, $daysAgo: Int) {
			viewer {
				...RestoreData_viewer @arguments(companyId: $companyId, page: $page, daysAgo: $daysAgo)
			}
		}
	`);
