import React, {useState} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DateRangePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../constants';
import SuppressExpensesOutsideProjectPeriodWarningMutation from '../../mutations/suppress_expenses_outside_project_period_warning_mutation';
import UpdateProjectMutation from '../../mutations/update_project_budget_page_mutation';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';
import UpdateExpenseItemMutation from '../../mutations/update_expense_item_mutation';

const expensesOutsideProjectPeriodWarningModal = ({expenseItems, project, onSuccess, intl: {formatMessage}, closeModal}) => {
	const [projectStartDate, setProjectStartDate] = useState(
		Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay)
	);
	const [projectEndDate, setProjectEndDate] = useState(
		Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay)
	);
	const [expenseDates, setExpenseDates] = useState({});

	const onSave = () => {
		const startDate = Util.GetYearMonthDateFromMomentDate(projectStartDate);
		const endDate = Util.GetYearMonthDateFromMomentDate(projectEndDate);

		Util.CommitMutation(UpdateProjectMutation, {
			project,
			projectStartYear: startDate.year,
			projectStartMonth: startDate.month,
			projectStartDay: startDate.day,
			projectEndYear: endDate.year,
			projectEndMonth: endDate.month,
			projectEndDay: endDate.day,
		});
		Object.keys(expenseDates).forEach(expenseItemId => {
			const expenseDate = Util.GetYearMonthDateFromMomentDate(expenseDates[expenseItemId]);
			Util.CommitMutation(UpdateExpenseItemMutation, {
				id: expenseItemId,
				expenseYear: expenseDate.year,
				expenseMonth: expenseDate.month,
				expenseDay: expenseDate.day,
			});
		});
		Util.CommitMutation(
			SuppressExpensesOutsideProjectPeriodWarningMutation,
			{
				projectId: project.id,
			},
			onSuccess
		);
	};

	const onProjectDateRangeChange = (projectStartDate, projectEndDate) => {
		setProjectStartDate(projectStartDate);
		setProjectEndDate(projectEndDate);
	};

	const onExpenseDateChange = (expenseId, expenseDate) => {
		setExpenseDates({
			...expenseDates,
			[expenseId]: expenseDate,
		});
	};

	const content = (
		<div className="auto-scheduling-modal expenses-outside-project-period-modal">
			<div className="warning-description-large">
				<FormattedHTMLMessage id="project_budget.expenses_outside_project_period_description_1" />
			</div>
			<div className="warning-description-large">
				<DateRangePicker
					startDate={projectStartDate}
					endDate={projectEndDate}
					handleDateRangeChange={onProjectDateRangeChange}
					label={formatMessage({id: 'common.date'})}
					isNewDateRange={true}
					datePickerStyle={DATE_PICKER_STYLE.STANDARD}
					highlighted={true}
				/>
			</div>
			<div className="warning-description-large expenses_description">
				<FormattedHTMLMessage id="project_budget.expenses_outside_project_period_description_2" />
			</div>
			<div className="table-heading-row">
				<div className="scrollable-table">
					<div className="headers">
						<div className="header column-name">{formatMessage({id: 'common.name'})}</div>
						<div className="header column-date">{formatMessage({id: 'common.date'})}</div>
					</div>

					<div className="rows">
						<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
							{expenseItems.edges.map((expenseItem, index) => {
								const expenseDate = expenseItem[expenseItem.node.id]
									? expenseDates[expenseItem.node.id]
									: Util.CreateNonUtcMomentDate(
											expenseItem.node.expenseYear,
											expenseItem.node.expenseMonth,
											expenseItem.node.expenseDay
									  );

								return (
									<div key={expenseItem.node.id} className="row">
										<div className="column-name">{expenseItem.node.name}</div>
										<div className="column-date">
											<DateRangePicker
												startDate={expenseDate}
												handleDateRangeChange={expenseDate =>
													onExpenseDateChange(expenseItem.node.id, expenseDate)
												}
												isSingleDatePicker={true}
												label={false}
												datePickerStyle={DATE_PICKER_STYLE.STANDARD}
												highlighted={true}
												focusStart={index === 0}
											/>
										</div>
									</div>
								);
							})}
						</CustomScrollDiv>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.save'}),
					callback: onSave,
				},
			]}
			content={content}
			headerText={formatMessage({id: 'project_budget.expenses_outside_project_period'})}
		/>
	);
};

expensesOutsideProjectPeriodWarningModal.propTypes = {
	expenseItems: PropTypes.array.isRequired,
	project: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
};

export default injectIntl(expensesOutsideProjectPeriodWarningModal);
