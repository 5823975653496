import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LabelsCell extends Component {
	render() {
		const labels = this.props.labelList.filter(label => label !== null);
		return (
			<div className="labels-wrapper">
				{labels.map(label => (
					<div
						key={label.id}
						style={{backgroundColor: label.label.color}}
						className="skill-label"
						title={label.label.name}
					/>
				))}
				{this.props.labelsCount > labels.length ? `+${this.props.labelsCount - labels.length}` : null}
			</div>
		);
	}
}

LabelsCell.propTypes = {
	labelList: PropTypes.array,
};

export default LabelsCell;
