import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import InputField from '../../components/new-ui/input_field';
import CreateProjectGroupMutation from '../../mutations/create_project_group_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import UploadingOverlay from '../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Warning from '../../components/warning';
import ProgramUtil from '../../forecast-app/shared/util/ProgramUtil';

class addProjectGroupModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			selectedProjectsMap: new Map(),
			selectItemsNumber: 2,
			step: 'form',
			loading: false,
		};
	}

	handleNameChange(value) {
		this.setState({name: value});
	}

	handleProjectSelect(index, selected) {
		const {formatMessage} = this.props.intl;
		const psProject = this.props.viewer.psProjects.edges.find(
			project => project.node.serviceCompanyId === Util.getIdFromBase64String(selected.value)
		);

		if (psProject) {
			if (psProject.node.program) {
				createToast({
					duration: 5000,
					message: Util.upperCaseFirst(
						formatMessage(
							{id: 'program.project.import.cant_add_program_project'},
							{program: ProgramUtil.programText(formatMessage)}
						)
					),
				});
				return;
			}
		}

		const selectedProjectsMap = this.state.selectedProjectsMap;
		if (selected === null) {
			selectedProjectsMap.delete(index);
		} else {
			selectedProjectsMap.set(index, selected ? selected.value : null);
		}

		this.setState({selectedProjects: selectedProjectsMap});
	}

	closeModal() {
		this.props.closeModal();
	}

	addGroup() {
		const onSuccess = result => {
			this.setState({loading: false});
			const newURL = '/connected/X-' + result.createProjectGroup.projectGroup.node.companyProjectGroupId;
			this.props.history.push(newURL);
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'add_project_group_modal.project_group_added'}),
			});
			this.closeModal();
		};
		const projects = [...this.state.selectedProjectsMap.values()];
		Util.CommitMutation(CreateProjectGroupMutation, {projects: projects, name: this.state.name}, onSuccess);
		this.setState({loading: true});
	}

	setStep(step) {
		this.setState({step: step});
	}

	excludeSelectedFilter(project) {
		return this.state.selectedProjectsMap;
	}

	getProjectSelects() {
		const project_options = this.props.viewer.projects.edges
			.filter(project => {
				return project.node.fullAccessToProject && !project.node.isInProjectGroup && !project.node.isJiraProject;
			})
			.map(project => {
				return {
					value: project.node.id,
					label: project.node.name ? project.node.name : this.props.intl.formatMessage({id: 'common.untitled'}),
				};
			});
		const elements = [];
		for (let i = 0; i < this.state.selectItemsNumber; i++) {
			elements.push(
				<div key={i} className="project-select-container">
					<Dropdown
						dataCy="select-project-dropdown"
						listDataCy="project-option"
						placeholder={this.props.intl.formatMessage({id: 'task_location_modal.select_project'})}
						options={project_options.filter(project => {
							let isSelectedInOther = false;
							this.state.selectedProjectsMap.forEach((value, key) => {
								if (!isSelectedInOther) {
									isSelectedInOther = value !== null && key !== i && value === project.value;
								}
							});
							return !isSelectedInOther;
						})}
						onChange={this.handleProjectSelect.bind(this, i)}
						value={this.state.selectedProjectsMap.has(i) ? this.state.selectedProjectsMap.get(i) : null}
						clearable={true}
						label={this.props.intl.formatMessage({id: 'common.project'}) + ' ' + (i + 1)}
					/>
				</div>
			);
		}
		return elements;
	}

	addSelectItem() {
		this.setState({selectItemsNumber: this.state.selectItemsNumber + 1});
	}

	render() {
		const {formatMessage} = this.props.intl;

		const content =
			this.state.step === 'form' ? (
				<div className="body">
					{this.state.loading ? <UploadingOverlay /> : null}
					<InputField
						cy={'create-connected-project-name'}
						id="create-project-group-name"
						type="text"
						value={this.state.name}
						onChange={this.handleNameChange.bind(this)}
						placeholder={formatMessage({id: 'add_project_group_modal.group_title_placeholder'})}
						label={formatMessage({id: 'add_project_group_modal.group_title'})}
						autoFocus={true}
					/>
					{this.getProjectSelects()}
					{this.props.viewer.projects.edges.length > this.state.selectItemsNumber &&
					this.state.selectItemsNumber !== 5 ? (
						<button className="more-projects-button" onClick={this.addSelectItem.bind(this)}>
							+ {formatMessage({id: 'add_project_group_modal.add_select_button'})}
						</button>
					) : null}
				</div>
			) : (
				<div className="body">
					{this.state.loading ? <UploadingOverlay /> : null}
					<div className="default-warning-modal">
						<Warning messageId="warning-modal.edit-warning-title" useInfoIcon={true} />
						<div className="warning-part-2">
							<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_1'})}</div>
							<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_2'})}</div>
							<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_3'})}</div>
							<div>{formatMessage({id: 'connected_projects.add_project_to_group_info_4'})}</div>
						</div>
					</div>
				</div>
			);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				className="add-project-group-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						cy: 'modal-add-button',
						text: formatMessage({id: 'common.add'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.state.step === 'form' ? this.setStep.bind(this, 'confirm') : this.addGroup.bind(this),
						disabled:
							this.state.step === 'form' &&
							(this.state.selectedProjectsMap.size === 0 || !/\S/.test(this.state.name)),
						preventDefaultClose: true,
					},
				]}
				headerText={this.state.step === 'form' ? formatMessage({id: 'add_project_group_modal.title'}) : undefined}
				content={content}
			/>
		);
	}
}

const addProjectGroupModalQuery = graphql`
	query addProjectGroupModal_Query {
		viewer {
			actualPersonId
			component(name: "add_project_group")
			...addProjectGroupModal_viewer
		}
	}
`;

export {addProjectGroupModalQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(addProjectGroupModal, {
			viewer: graphql`
				fragment addProjectGroupModal_viewer on Viewer {
					id
					projects(first: 10000, excludeDoneOrHalted: true, excludeGroupProjects: true) {
						edges {
							node {
								id
								name
								isJiraProject
								isInProjectGroup
								fullAccessToProject
							}
						}
					}
					psProjects(first: 10000) {
						edges {
							node {
								id
								serviceCompanyId
								program
							}
						}
					}
				}
			`,
		})
	)
);
