import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useHistory} from 'react-router-dom';
import Moment from 'moment';
import GenericModal from '../../../../modal/generic_modal';
import {ChartStatsContainer, PredictionInfo} from '../predicted_end_date_modal/PredictedEndDateModal.styled';
import PredictedEndDateModalStatsSection from '../predicted_end_date_modal/PredictedEndDateModalStatsSection';
import {ForecastTooltipFormulaRenderer} from '../../../../../components/ForecastTooltipFormulaRenderer';
import {trackEvent, trackTooltip} from '../../../../../tracking/amplitude/TrackingV2';
import AllocationContainer from './AllocationContainer';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_FORMAT_DAY, MODULE_TYPES} from '../../../../../constants';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';
import Util from '../../../../../forecast-app/shared/util/util';
import CreatePlaceholderMutation from '../../../../../mutations/create_placeholder_mutation';
import CreatePlaceholderAllocationMutation from '../../../../../mutations/create_placeholder_allocation_mutation';
import {
	getDistributionArray,
	handleAllocationTotalChange,
	sumWeeklyMinutes,
} from '../../../../modal/placeholder/PlaceholderAllocationUtils';
import {hasModule} from '../../../../../forecast-app/shared/util/ModuleUtil';

const createPlaceholderAllocation = (placeholderRes, missingAllocation, companyAllocationInfo, startDate, endDate) => {
	const placeholderId = placeholderRes.createPlaceholder.placeholder.node.id;

	const allocationResult = handleAllocationTotalChange(
		missingAllocation,
		companyAllocationInfo.distributionArray,
		companyAllocationInfo.companyWeeklyMinutes,
		startDate,
		endDate
	);

	const newDistributionArray = allocationResult.newDistributionArray;

	const input = {
		startDate: startDate.format(DATE_FORMAT_DAY),
		endDate: endDate.format(DATE_FORMAT_DAY),
		placeholderId: placeholderId,
		monday: newDistributionArray[0],
		tuesday: newDistributionArray[1],
		wednesday: newDistributionArray[2],
		thursday: newDistributionArray[3],
		friday: newDistributionArray[4],
		saturday: newDistributionArray[5],
		sunday: newDistributionArray[6],
		description: 'Automatically created by Nova Allocation Assistance',
	};
	return new Promise((resolve, reject) => {
		Util.CommitMutation(CreatePlaceholderAllocationMutation, input, () => resolve());
	});
};

const createPlaceholder = (projectId, role, missingAllocation, companyAllocationInfo, startDate, endDate) => {
	const createRequest = {
		projectId: projectId,
		name: role.name,
		roleId: role.id,
		teamIds: [],
		skillPlaceholders: [],
	};
	return new Promise((resolve, reject) => {
		Util.CommitMutation(CreatePlaceholderMutation, createRequest, res => resolve(res));
	}).then(result => {
		return createPlaceholderAllocation(result, missingAllocation, companyAllocationInfo, startDate, endDate);
	});
};

const navigateToPlaceholders = history => {
	history.push('/scheduling#demand');
};

const createAllocationAssistancePlaceholders = (
	projectId,
	allocationAssistanceRoles,
	projectEndDate,
	companyAllocationInfo,
	history
) => {
	const startNextWeek = Moment().add(1, 'weeks').startOf('isoWeek');

	Promise.all(
		allocationAssistanceRoles
			.filter(allocationAssistanceRole => !!allocationAssistanceRole.role)
			.map(allocationAssistanceRole => {
				const {role, missingAllocation} = allocationAssistanceRole;
				return createPlaceholder(
					projectId,
					role,
					missingAllocation,
					companyAllocationInfo,
					startNextWeek,
					projectEndDate
				);
			})
	).then(() => {
		trackEvent('Allocation Assitance Create Placeholders', 'Clicked');
		navigateToPlaceholders(history);
	});
};

const navigateSchedulePlaceholders = history => {
	trackEvent('Allocation Assitance Assign People', 'Clicked');
	navigateToPlaceholders(history);
};

const navigateSchedule = () => {
	trackEvent('Allocation Assitance Allocate People', 'Clicked');
	window.open(`/scheduling#people`);
};

const AllocationAssistanceModal = ({viewer, project, projectEndDate, endDateInfo, closeModal}) => {
	const history = useHistory();

	const allocationAssistance = viewer.project.projectHealth.allocationAssistance;
	const allocationAssistanceRolesLackingAllocation = allocationAssistance
		.filter(allocationAssistanceRole => allocationAssistanceRole.missingAllocation > 0)
		.sort((a, b) => Util.sortAlphabetically(a.role?.name, b.role?.name));

	const companyWeeklyMinutes = sumWeeklyMinutes(viewer.company);
	const distributionArray = getDistributionArray(viewer.company);
	const companyAllocationInfo = {companyWeeklyMinutes, distributionArray};

	useEffect(() => {
		trackEvent('Allocation Assistance Modal', 'Shown');
	}, []);

	const hasPlaceholders = hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.PLACEHOLDERS);
	const noOutstandingPlaceholders =
		hasPlaceholders &&
		allocationAssistanceRolesLackingAllocation.filter(allocationAssistanceRole => !!allocationAssistanceRole.role)
			.length === 0;

	const getContent = () => {
		return (
			<>
				<PredictionInfo>{`${endDateInfo}.`}</PredictionInfo>
				{noOutstandingPlaceholders ? null : (
					<PredictionInfo>
						Allocating the following resources before the project deadline will get you back on track to finish on
						time according to the prediction.
					</PredictionInfo>
				)}
				<ChartStatsContainer>
					{noOutstandingPlaceholders ? null : <PredictedEndDateModalStatsSection project={project} />}
					<AllocationContainer
						allocationAssistanceRoles={allocationAssistanceRolesLackingAllocation}
						noOutstandingPlaceholders={noOutstandingPlaceholders}
					/>
				</ChartStatsContainer>
			</>
		);
	};

	const tooltipContent = () => {
		return (
			<ForecastTooltipFormulaRenderer
				items={[
					{
						description: `Allocation Assistance highlights what resources the Predicted Project End insight considers to be bottlenecking the project from finishing on time.`,
					},
					{
						title: 'Allocation Assistance is calculated for each role as',
						details: ['Remaining Task Estimate', 'x', '1 / Team Focus', 'x'],
						smallMargin: true,
					},
					{details: ['1 / Task Performance', '-', 'Remaining Role Allocation before Deadline']},
					{
						description: `Team Focus and Task Performance both default to 1 if the insight is not available. For unassigned work, all surplus allocation from roles is substituted for Remaining Role Allocation.`,
					},
				]}
				translatedMessage={true}
			/>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			content={getContent()}
			headerText={'Allocation Assistance'}
			headerInfo={tooltipContent()}
			headerTooltipWidth={420}
			onInfoIconShow={() => {
				trackTooltip('Allocation Assistance Info');
			}}
			modalWidth={900}
			isBetaFeature
			buttons={[
				{
					text: noOutstandingPlaceholders
						? 'Assign People'
						: hasPlaceholders
						? 'Create Placeholders'
						: 'Allocate People',
					callback: () =>
						noOutstandingPlaceholders
							? navigateSchedulePlaceholders(history)
							: hasPlaceholders
							? createAllocationAssistancePlaceholders(
									project.id,
									allocationAssistanceRolesLackingAllocation,
									projectEndDate,
									companyAllocationInfo,
									history
							  )
							: navigateSchedule(),
					preventDefaultClose: !hasPlaceholders,
					style: BUTTON_STYLE.OUTLINE,
					color: BUTTON_COLOR.WHITE,
				},
			]}
		/>
	);
};

const AllocationAssistanceModalQuery = graphql`
	query AllocationAssistanceModal_Query($projectId: ID, $performance: Float, $focus: Float) {
		viewer {
			component(name: "allocation_assistance_modal")
			actualPersonId
			id
			...AllocationAssistanceModal_viewer @arguments(projectId: $projectId, focus: $focus, performance: $performance)
		}
	}
`;

export {AllocationAssistanceModalQuery};

export default createFragmentContainer(AllocationAssistanceModal, {
	viewer: graphql`
		fragment AllocationAssistanceModal_viewer on Viewer
		@argumentDefinitions(projectId: {type: "ID"}, focus: {type: "Float"}, performance: {type: "Float"}) {
			company {
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
			}
			project(internalId: $projectId) {
				id
				projectHealth {
					allocationAssistance(focus: $focus, performance: $performance) {
						role {
							id
							name
						}
						missingAllocation
					}
				}
			}
		}
	`,
	project: graphql`
		fragment AllocationAssistanceModal_project on ProjectType {
			id
			name
			...PredictedEndDateModalStatsSection_project
		}
	`,
});
