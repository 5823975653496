import {WarningIcon} from 'web-components/icons';

export const INSIGHTS = {
	PREDICTED_PROJECT_END: 'PREDICTED_PROJECT_END',
	FOCUS: 'FOCUS',
	PERFORMANCE: 'PERFORMANCE',
	BUDGET: 'BUDGET',
};

export const getWarningVariantFromRAGStatus = ragStatus => {
	switch (ragStatus) {
		case 'RED':
			return WarningIcon.VARIANT.DANGER;
		case 'AMBER':
			return WarningIcon.VARIANT.WARNING;
		case 'GREEN':
			return WarningIcon.VARIANT.NO_WARNING;
		default:
			return null;
	}
};

export const getFocusVariant = focus => {
	if (focus < 0.7) {
		return WarningIcon.VARIANT.DANGER;
	}
	if (focus < 0.9) {
		return WarningIcon.VARIANT.WARNING;
	}
	if (focus > 1.3) {
		return WarningIcon.VARIANT.WARNING;
	}

	return WarningIcon.VARIANT.NO_WARNING;
};

export const getPerformanceVariant = performance => {
	if (performance < 0.7) {
		return WarningIcon.VARIANT.DANGER;
	}
	if (performance < 0.9) {
		return WarningIcon.VARIANT.WARNING;
	}
	if (performance > 1.3) {
		return WarningIcon.VARIANT.WARNING;
	}

	return WarningIcon.VARIANT.NO_WARNING;
};

export const getSuggestedStatusVariantFromRag = suggestedStatusRag => {
	if (suggestedStatusRag === 'RED') {
		return WarningIcon.VARIANT.DANGER;
	} else if (suggestedStatusRag === 'AMBER') {
		return WarningIcon.VARIANT.WARNING;
	} else if (suggestedStatusRag === 'GREEN') {
		return WarningIcon.VARIANT.NO_WARNING;
	}
};
