/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StatusIndicatorWithTooltip_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type overviewProjectsV2_viewer$ref: FragmentReference;
declare export opaque type overviewProjectsV2_viewer$fragmentType: overviewProjectsV2_viewer$ref;
export type overviewProjectsV2_viewer = {|
  +id: string,
  +backendId: ?number,
  +firstName: ?string,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +client: ?{|
    +id: string
  |},
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
        +updatedAt: ?string,
      |}
    |}>
  |},
  +company: ?{|
    +currency: ?string,
    +xeroEnabled: ?boolean,
    +priorityLevels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string
        |}
      |}>
    |},
  |},
  +projectGroups: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectGroupId: ?number,
        +name: ?string,
        +color: ?string,
        +projects: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: ?PROJECT_STATUS,
              +name: ?string,
              +budgetType: ?BUDGET_TYPE,
              +isInProjectGroup: ?boolean,
              +readOnlyAccess: ?boolean,
              +description: ?string,
              +projectStartDay: ?number,
              +projectStartMonth: ?number,
              +projectStartYear: ?number,
              +projectEndDay: ?number,
              +projectEndMonth: ?number,
              +projectEndYear: ?number,
              +companyProjectId: ?number,
              +customProjectId: ?string,
              +client: ?{|
                +id: string,
                +name: ?string,
                +logoId: ?string,
                +logoDefaultId: ?number,
              |},
              +rateCard: ?{|
                +id: string,
                +name: ?string,
                +currency: ?string,
                +parentRateCardId: ?string,
              |},
              +projectLabels: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +label: ?{|
                      +id: string,
                      +name: ?string,
                      +color: ?string,
                    |},
                    +id: string,
                  |}
                |}>
              |},
              +projectColor: ?string,
              +currentProjectStatus: ?{|
                +id: string,
                +color: ?string,
                +person: ?{|
                  +firstName: ?string,
                  +lastName: ?string,
                  +id: string,
                |},
              |},
              +priorityLevel: ?{|
                +id: string,
                +name: ?string,
                +weight: ?number,
              |},
              +projectPersons: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +isContactPerson: ?boolean,
                    +person: ?{|
                      +id: string,
                      +firstName: ?string,
                      +lastName: ?string,
                      +profilePictureId: ?string,
                      +profilePictureDefaultId: ?number,
                      +initials: ?string,
                    |},
                  |}
                |}>
              |},
              +statusColor: ?string,
              +budget: ?number,
              +budgetBaseCurrency: ?number,
              +estimationUnit: ?string,
              +minutesPerEstimationPoint: ?number,
              +baselineWinChance: ?number,
              +sprintTimeBox: ?boolean,
              +progress: ?number,
              +manualProgressOnProjectEnabled: ?boolean,
              +manualProgressOnPhasesEnabled: ?boolean,
              +manualProgressOnTasksEnabled: ?boolean,
              +xeroInvoices: ?$ReadOnlyArray<?{|
                +id: string,
                +amountTotal: ?number,
                +amountPaid: ?number,
              |}>,
              +financialNumbers?: ?{|
                +billablePlannedTimeAndExpenses: ?number,
                +actualRevenue: ?number,
                +remainingRevenue: ?number,
                +forecastRevenue: ?number,
                +recognitionOpenRevenue: ?number,
                +recognitionForecastRevenue: ?number,
                +recognitionLockedRevenue: ?number,
                +plannedCost: ?number,
                +actualCost: ?number,
                +forecastCost: ?number,
                +remainingCost: ?number,
                +plannedProfit: ?number,
                +forecastProfit: ?number,
                +invoiced: ?number,
                +paid: ?number,
              |},
              +financialNumbersProjectCurrency?: ?{|
                +billablePlannedTimeAndExpenses: ?number,
                +actualRevenue: ?number,
                +remainingRevenue: ?number,
                +forecastRevenue: ?number,
                +recognitionOpenRevenue: ?number,
                +recognitionForecastRevenue: ?number,
                +recognitionLockedRevenue: ?number,
                +plannedCost: ?number,
                +actualCost: ?number,
                +forecastCost: ?number,
                +remainingCost: ?number,
                +plannedProfit: ?number,
                +forecastProfit: ?number,
                +invoiced: ?number,
                +paid: ?number,
              |},
              +$fragmentRefs: StatusIndicatorWithTooltip_project$ref,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +budgetType: ?BUDGET_TYPE,
        +status: ?PROJECT_STATUS,
        +description: ?string,
        +statusColor: ?string,
        +companyProjectId: ?number,
        +readOnlyAccess: ?boolean,
        +budget: ?number,
        +budgetBaseCurrency: ?number,
        +projectColor: ?string,
        +estimationUnit: ?string,
        +minutesPerEstimationPoint: ?number,
        +projectStartDay: ?number,
        +projectStartMonth: ?number,
        +projectStartYear: ?number,
        +projectEndDay: ?number,
        +projectEndMonth: ?number,
        +projectEndYear: ?number,
        +baselineWinChance: ?number,
        +sprintTimeBox: ?boolean,
        +currentProjectStatus: ?{|
          +id: string,
          +color: ?string,
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
          |},
        |},
        +client: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |},
        +rateCard: ?{|
          +id: string,
          +name: ?string,
          +currency: ?string,
          +parentRateCardId: ?string,
        |},
        +progress: ?number,
        +manualProgressOnProjectEnabled: ?boolean,
        +manualProgressOnPhasesEnabled: ?boolean,
        +manualProgressOnTasksEnabled: ?boolean,
        +priorityLevel: ?{|
          +id: string,
          +name: ?string,
          +weight: ?number,
        |},
        +projectLabels: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +label: ?{|
                +id: string,
                +name: ?string,
                +color: ?string,
              |},
            |}
          |}>
        |},
        +projectPersons: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +isContactPerson: ?boolean,
              +person: ?{|
                +id: string,
                +firstName: ?string,
                +lastName: ?string,
                +profilePictureId: ?string,
                +profilePictureDefaultId: ?number,
                +initials: ?string,
              |},
            |}
          |}>
        |},
        +xeroInvoices: ?$ReadOnlyArray<?{|
          +id: string,
          +amountTotal: ?number,
          +amountPaid: ?number,
        |}>,
        +financialNumbers?: ?{|
          +billablePlannedTimeAndExpenses: ?number,
          +actualRevenue: ?number,
          +remainingRevenue: ?number,
          +forecastRevenue: ?number,
          +recognitionOpenRevenue: ?number,
          +recognitionForecastRevenue: ?number,
          +recognitionLockedRevenue: ?number,
          +plannedCost: ?number,
          +actualCost: ?number,
          +forecastCost: ?number,
          +remainingCost: ?number,
          +plannedProfit: ?number,
          +forecastProfit: ?number,
          +invoiced: ?number,
          +paid: ?number,
        |},
        +financialNumbersProjectCurrency?: ?{|
          +billablePlannedTimeAndExpenses: ?number,
          +actualRevenue: ?number,
          +remainingRevenue: ?number,
          +forecastRevenue: ?number,
          +recognitionOpenRevenue: ?number,
          +recognitionForecastRevenue: ?number,
          +recognitionLockedRevenue: ?number,
          +plannedCost: ?number,
          +actualCost: ?number,
          +forecastCost: ?number,
          +remainingCost: ?number,
          +plannedProfit: ?number,
          +forecastProfit: ?number,
          +invoiced: ?number,
          +paid: ?number,
        |},
        +isInProjectGroup: ?boolean,
        +customProjectId: ?string,
        +$fragmentRefs: StatusIndicatorWithTooltip_project$ref,
      |}
    |}>
  |},
  +$refType: overviewProjectsV2_viewer$ref,
|};
export type overviewProjectsV2_viewer$data = overviewProjectsV2_viewer;
export type overviewProjectsV2_viewer$key = {
  +$data?: overviewProjectsV2_viewer$data,
  +$fragmentRefs: overviewProjectsV2_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProjectGroup",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readOnlyAccess",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoDefaultId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "RateCard",
  "kind": "LinkedField",
  "name": "rateCard",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentRateCardId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "label",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "PriorityLevel",
  "kind": "LinkedField",
  "name": "priorityLevel",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "contactsOnly",
      "value": true
    },
    (v9/*: any*/)
  ],
  "concreteType": "ProjectPersonTypeConnection",
  "kind": "LinkedField",
  "name": "projectPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectPerson",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isContactPerson",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v28/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "initials",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusColor",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budget",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetBaseCurrency",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesPerEstimationPoint",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineWinChance",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sprintTimeBox",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnProjectEnabled",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnPhasesEnabled",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnTasksEnabled",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "concreteType": "XeroInvoiceType",
  "kind": "LinkedField",
  "name": "xeroInvoices",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountPaid",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v43 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "StatusIndicatorWithTooltip_project"
},
v44 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "billablePlannedTimeAndExpenses",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actualRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionOpenRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionForecastRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionLockedRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "plannedCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actualCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "plannedProfit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastProfit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "invoiced",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paid",
    "storageKey": null
  }
],
v45 = {
  "alias": null,
  "args": null,
  "concreteType": "FinancialNumbers",
  "kind": "LinkedField",
  "name": "financialNumbers",
  "plural": false,
  "selections": (v44/*: any*/),
  "storageKey": null
},
v46 = {
  "condition": "loadMultiCurrency",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v45/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbersProjectCurrency",
      "plural": false,
      "selections": (v44/*: any*/),
      "storageKey": null
    }
  ]
},
v47 = {
  "condition": "loadSingleCurrency",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v45/*: any*/)
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadSingleCurrency",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadMultiCurrency",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeDoneOrHalted",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeGroupProjectsFromProjectConnection",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroups"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projects"
        ]
      }
    ]
  },
  "name": "overviewProjectsV2_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xeroEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriorityLevelTypeConnection",
          "kind": "LinkedField",
          "name": "priorityLevels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriorityLevelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriorityLevel",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "projectGroups",
      "args": null,
      "concreteType": "ProjectGroupTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_projectGroups_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectGroupTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectGroupType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectGroupId",
                  "storageKey": null
                },
                (v3/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": [
                    (v9/*: any*/)
                  ],
                  "concreteType": "ProjectTypeConnection",
                  "kind": "LinkedField",
                  "name": "projects",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v10/*: any*/),
                            (v3/*: any*/),
                            (v11/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v16/*: any*/),
                            (v17/*: any*/),
                            (v18/*: any*/),
                            (v19/*: any*/),
                            (v20/*: any*/),
                            (v21/*: any*/),
                            (v22/*: any*/),
                            (v23/*: any*/),
                            (v24/*: any*/),
                            {
                              "alias": null,
                              "args": (v25/*: any*/),
                              "concreteType": "ProjectLabelTypeConnection",
                              "kind": "LinkedField",
                              "name": "projectLabels",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProjectLabelTypeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ProjectLabel",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v26/*: any*/),
                                        (v0/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "projectLabels(first:10000)"
                            },
                            (v27/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectStatus",
                              "kind": "LinkedField",
                              "name": "currentProjectStatus",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v8/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v28/*: any*/),
                                    (v0/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v29/*: any*/),
                            (v30/*: any*/),
                            (v31/*: any*/),
                            (v32/*: any*/),
                            (v33/*: any*/),
                            (v34/*: any*/),
                            (v35/*: any*/),
                            (v36/*: any*/),
                            (v37/*: any*/),
                            (v38/*: any*/),
                            (v39/*: any*/),
                            (v40/*: any*/),
                            (v41/*: any*/),
                            (v42/*: any*/),
                            (v43/*: any*/),
                            (v46/*: any*/),
                            (v47/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "projects(first:1000000)"
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v11/*: any*/),
                (v10/*: any*/),
                (v14/*: any*/),
                (v31/*: any*/),
                (v21/*: any*/),
                (v13/*: any*/),
                (v32/*: any*/),
                (v33/*: any*/),
                (v27/*: any*/),
                (v34/*: any*/),
                (v35/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v36/*: any*/),
                (v37/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectStatus",
                  "kind": "LinkedField",
                  "name": "currentProjectStatus",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v28/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v23/*: any*/),
                (v24/*: any*/),
                (v38/*: any*/),
                (v39/*: any*/),
                (v40/*: any*/),
                (v41/*: any*/),
                (v29/*: any*/),
                {
                  "alias": null,
                  "args": (v25/*: any*/),
                  "concreteType": "ProjectLabelTypeConnection",
                  "kind": "LinkedField",
                  "name": "projectLabels",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectLabelTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectLabel",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v26/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "projectLabels(first:10000)"
                },
                (v30/*: any*/),
                (v42/*: any*/),
                (v12/*: any*/),
                (v22/*: any*/),
                (v4/*: any*/),
                (v43/*: any*/),
                (v46/*: any*/),
                (v47/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '98f979b0830335663bb9fe2ab817dbf7';

module.exports = node;
