/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ForecastSurplusModal_viewer$ref = any;
export type ForecastSurplusModal_QueryVariables = {|
  projectId?: ?string
|};
export type ForecastSurplusModal_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: ForecastSurplusModal_viewer$ref,
  |}
|};
export type ForecastSurplusModal_Query = {|
  variables: ForecastSurplusModal_QueryVariables,
  response: ForecastSurplusModal_QueryResponse,
|};
*/


/*
query ForecastSurplusModal_Query(
  $projectId: ID
) {
  viewer {
    component(name: "forecast_surplus_modal")
    actualPersonId
    id
    ...ForecastSurplusModal_viewer_2CgS8h
  }
}

fragment ForecastSurplusModalChartSection_project on ProjectType {
  projectFirstDateYear
  projectFirstDateMonth
  projectFirstDateDay
  projectLastDateYear
  projectLastDateMonth
  projectLastDateDay
  revenueMarginLow
  revenueMarginHigh
  aggregatedFinancialNumbers(aggregates: [actualRevenue, forecastRevenue], aggregateLevel: DAY, addAccumulatedNumbers: true, convertToProjectCurrency: true)
}

fragment ForecastSurplusModal_viewer_2CgS8h on Viewer {
  project(internalId: $projectId) {
    id
    name
    companyProjectId
    revenueMarginLow
    revenueMarginHigh
    rateCard {
      currency
      id
    }
    ...ForecastSurplusModalChartSection_project
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "forecast_surplus_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"forecast_surplus_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForecastSurplusModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ForecastSurplusModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForecastSurplusModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueMarginLow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueMarginHigh",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCard",
                "kind": "LinkedField",
                "name": "rateCard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "addAccumulatedNumbers",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "aggregateLevel",
                    "value": "DAY"
                  },
                  {
                    "kind": "Literal",
                    "name": "aggregates",
                    "value": [
                      "actualRevenue",
                      "forecastRevenue"
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "convertToProjectCurrency",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "aggregatedFinancialNumbers",
                "storageKey": "aggregatedFinancialNumbers(addAccumulatedNumbers:true,aggregateLevel:\"DAY\",aggregates:[\"actualRevenue\",\"forecastRevenue\"],convertToProjectCurrency:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ForecastSurplusModal_Query",
    "operationKind": "query",
    "text": "query ForecastSurplusModal_Query(\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"forecast_surplus_modal\")\n    actualPersonId\n    id\n    ...ForecastSurplusModal_viewer_2CgS8h\n  }\n}\n\nfragment ForecastSurplusModalChartSection_project on ProjectType {\n  projectFirstDateYear\n  projectFirstDateMonth\n  projectFirstDateDay\n  projectLastDateYear\n  projectLastDateMonth\n  projectLastDateDay\n  revenueMarginLow\n  revenueMarginHigh\n  aggregatedFinancialNumbers(aggregates: [actualRevenue, forecastRevenue], aggregateLevel: DAY, addAccumulatedNumbers: true, convertToProjectCurrency: true)\n}\n\nfragment ForecastSurplusModal_viewer_2CgS8h on Viewer {\n  project(internalId: $projectId) {\n    id\n    name\n    companyProjectId\n    revenueMarginLow\n    revenueMarginHigh\n    rateCard {\n      currency\n      id\n    }\n    ...ForecastSurplusModalChartSection_project\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2e2eaf0f47f474ed1782a0a99dd6e1d';

module.exports = node;
