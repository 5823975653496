let CSS_CONSTANTS = {
	brand_color: '#392664',
	// brand_color_dark: 'darken($brand-color, 12.5%)',
	// brand_color_light: 'lighten($brand-color, 15%)',
	brand_color_disabled: '#eee',
	brand_color_accent: '#e500d2',
	brand_brown_light: '#eee',
	brand500: '#6E0FEB',

	error_background_color: '#eabcbc',
	error_border_color: '#cb5c5c',
	error_text_color: '#a82d2d',
	alert_color: '#cc0000',

	negative_value: '#d0021b',

	light_border_color: '#eee',
	light_hover_color: '#fefefe',
	menu_hover_color: '#fefefe',

	color_white: '#fff',
	color_dark_blue: '#1d3557',
	color_green: '#5ccb66',
	color_red: '#e63946',
	color_segment_sleepers: '#f3a030',
	color_segment_risk: '#f36530',
	color_segment_disaster: '#e63946',
	color_light_black: '#625c58',
	color_black: '#000',
	color_light_gray: '#fafafa',
	color_light_grey: '#f2f2f2',
	color_light_blue: '#4ed6f8',
	color_medium_gray: '#f3f3f3',
	color_dark_gray: '#7d7d7d',
	color_silver: 'silver',

	color_branding_purple: '#2b1951',
	color_purple_hover_active: '#6f49c5',

	color_martin_primary_salmon: '#ff7c75',
	color_martin_primary_purple: '#392664',
	color_martin_primary_blue: '#44b4ff',
	color_martin_primary_pink: '#efa7fd',

	color_martin_secondary_green: '#99ff8c',
	color_martin_secondary_purple: '#742bd6',
	color_martin_secondary_beige: '#f5ede4',
	color_martin_secondary_yellow: '#ffd761',

	color_martin_tertiary_purple: '#742bd6',
	color_martin_tertiary_brown: '#886c68',
	color_martin_tertiary_orange: '#faa434',
	color_martin_tertiary_swamp_green: '#006970',
	color_martin_tertiary_warm_purple: '#ac42a2',
	color_martin_tertiary_pink: '#e93a66',
	color_martin_tertiary_blue: '#217bf6',
	color_martin_tertiary_dark_purple: '#00001f',
	color_martin_tertiary_green: '#62d87b',
	color_martin_tertiary_light_purple: '#a56dfd',

	color_martin_timeline_border: '#ebebee',
	color_martin_timeline_background: '#f7f7f7',
	color_martin_dependency_gray: '#d8d8d8',

	delete_button_red: '#ff4e5a',
	favoured_task_yellow: '#fefdf3',
	v2_tooltip_grey: '#ebebeb',
	v2_receipt_green: '#00cc66',
	v2_input_disabled_grey: '#f3f3f3',
	v2_list_hover_purple: 'rgba(110, 15, 234, 0.1)',
	v2_list_background_purple: 'rgba(110, 15, 234, 0.05)',
	v2_list_background_purple_opaque: 'rgb(247, 243, 253)',
	v2_backdrop_gray: '#cecece',
	v2_retainer_conflict_red: '#ec4b4b',
	v2_column_header_grey: '#dfdfdf',
	v2_section_main_background: '#ffffff',
	v2_dark_green: '#00300c',
	v2_branding_purple: '#6e0fea',
	v2_dark_purple: '#560eb3',
	v2_text_light_gray: '#a1a1a1',
	v2_text_gray: '#535353',
	v2_header_text_dark_gray: '#2e2541',
	v2_background_dark_gray: '#4a5968',
	v2_light_gray: '#f6f6f6',
	v2_border_gray: '#e9e9e9',
	v2_border_invalid: '#e4253b',
	v2_create_button_green: '#05e298',
	v2_create_button_green_active: '#0ab47b',
	v2_create_button_green_alpha: '#05e298b3',
	v2_create_menu_green_border: '#00e891',
	v2_dependency_green: '#07e064',
	v2_negative_dropdown_grey: '#3f4b57',
	v2_date_picker_button_gray: '#f6f6f6',
	v2_date_picker_button_border_color: '#a1a1a1',
	v2_date_picker_day_in_range_gray: '#a4bac4',
	v2_date_picker_day_in_range_gray_8: '#a4bac4b3',
	v2_dark_grey: '#3f4b57',
	v2_grey: '#dbdbdb',
	v2_fixed_element_grey: '#f5f5f8',
	v2_row_highlight_color: '#dbdbdb',
	v2_row_highlight_border_color: '#f3f3f3',
	v2_red: '#e23f3f',
	v2_workflow_task_done_state_color: '#19e042',
	v2_workflow_task_selected_color: '#f0e7fc',
	v2_workflow_halted_task_selected_color: '#c1c1c1',
	v2_workflow_task_warning_red: '#e21e5b',
	v2_workflow_task_warning_yellow: '#ffd200',
	v2_workflow_column_category_option_grey: '#a4b9c4',
	v2_completed_green: '#19e042',
	v2_files_selected_row: '#f8f8f8',
	v2_files_upload_hover_message_color: '#efefef',
	v2_table_border_color: '#eaeaea',
	v2_dropdown_hover_grey: '#5b6671',
	v2_button_light_grey: '#dcdcdc',
	v2_button_very_light_grey: '#e6e6e6',
	v2_button_very_light_grey_hovered: '#a1a1a1',
	v2_button_medium_grey: '#f5f5f5',
	v2_add_button_blue: '#0081ff',
	v2_input_field_filled_gray: '#a7a7a7',
	v2_nested_dropdown_input_color: '#545454',
	v2_create_menu_text_color: '#133429',
	v2_projects_dropdown_project_group_indicator: '#5a18b6',
	v2_projects_dropdown_blocked: '#e01212',
	v2_pricing_list_grey: '#f5f6f8',
	v2_urban_black: '#2d095c',
	non_project_time_allocation: '#efefef',
	v2_pricing_border: '#c3c3c3',
	v2_disabled_purple: '#dccaf5',
	link_text_color: '#4a90e2',
	v2_person_dropdown_role_background_color: '#4a90e2',
	v2_global_search_criteria_background_color: '#e1e1e1',
	v2_role_shorthand_color: '#470c93',

	base_font_size: '16px',
	app_header_title_size: '2.42rem',
	base_font_regular_weight: '400',
	base_font_medium_weight: '500',
	base_font_semibold_weight: '600',
	base_font_bold_weight: '700',

	heading_font_family: "'urbane', sans-serif",
	text_font_family: "'neue-haas-grotesk-text', sans-serif",
	v2_site_header_font_family: "'neue-haas-grotesk-text', sans-serif",
	text_font_family_v2: "'neue-haas-grotesk-text', sans-serif",
	text_base_color: 'rgba(0, 0, 0, 0.87)',

	input_background: '#fbfaf8',
	input_border_color: '#e4e2db',
	input_border_color_focus: '#c8c6c1',
	input_color: '#333',
	input_placeholder_color: '#c9bdb5',
	// input_placeholder_color_focus: 'lighten($input-placeholder-color, 10%)',
	input_placeholder_color_disabled: '#c0c0c0',

	app_border_color: '#e6e6e6',
	app_hard_border_color: '#b3b3b3',
	app_branding_table_border_color: '#b3b3b3',
	app_loader_url: "'images/loaders/loader-16x16-white.svg'",
	nav_section_header: '#2d2d2d',

	app_header_height: '37px',
	section_header_height: '55px',
	app_header_logo_url: "'images/logo.svg'",
	app_header_logo_white_url: "'images/logo-white.svg'",
	app_header_logo_gray_url: "'images/logo-gray.svg'",
	app_header_logo_v2_url: "'images/logo-v2.svg'",
	app_header_logo_width: '66px',

	btn_second_pressed: '#757575',
	btn_second_active: '#e0e0e0',
	btn_white_pressed: '#c7c7c7',
	btn_white_active: '#f7f7f7',

	drop_color: '#f3f3f3',

	// chart_axis_label_color: 'darken($input-placeholder-color, 10%)',
	chart_tooltip_background_color: 'rgba(0, 0, 0, 0.8)',
	chart_tooltip_label_text_color: '#fff',
	chart_tooltip_data_outline_color: '#fff',
	chart_tooltip_data_text_color: '#ddd',
	chart_data_color_blue: '#51a7d9',
	chart_axis_label_font_size: '0.9rem',
	chart_line_dot_size: '12px',

	material_label_color: '#858585',
	material_locked_color: '#979797',

	bulk_update_selected_color: '#f1e9fa',

	onboarding_container_background_color: '#9b6bf7',

	insight_component_portfolio_report_very_positive_value: '#00ff00',
	insight_component_portfolio_report_positive_value: '#19a042',
	insight_component_portfolio_report_medium_value: '#ffe599',
	insight_component_portfolio_report_negative_value: '#e7abab',
	insight_component_portfolio_report_very_negative_value: '#ff0000',
	insight_component_portfolio_report_no_value: '#ffffff',

	schedule_v2_green: '#90E498',
	schedule_v2_red: '#FFEBEB',
	schedule_text_grey: '#727483',
	schedule_text_dark_grey: '#393946',
	schedule_border_color_primary: '#E7E7F3',
	schedule_actual_blue: '#c0eaff',
	schedule_heatmap_actual_blue: '#edf9ff',

	// scheduling legends
	schedule_legend_soft_background: '#C0EABE',
	schedule_legend_soft_stripe: '#ACE3A9',
	schedule_legend_soft_over_background: '#FBC6C4',
	schedule_legend_soft_over_stripe: '#F9B3B0',

	background_purple: '#F8F5FE',
	background_ghost_white: '#F7F7FE',
	color_brand_50: '#F0E7FE',
	color_green_300: '#59C063',
	color_red_500: '#EB0A00',
};

CSS_CONSTANTS = {
	...CSS_CONSTANTS,
	color_segment_loyal: CSS_CONSTANTS.brand_color,
	section_main_background: CSS_CONSTANTS.color_light_grey,
	chart_grid_line_color: CSS_CONSTANTS.input_border_color,
	chart_axis_line_color: CSS_CONSTANTS.input_placeholder_color,
	chart_axis_tick_color: CSS_CONSTANTS.input_placeholder_color,
	chart_data_color_green: CSS_CONSTANTS.brand_color,
	chart_tracer_color: CSS_CONSTANTS.input_border_color,
	material_focused_color: CSS_CONSTANTS.color_purple_hover_active,
};

module.exports = {
	CSS_CONSTANTS,
};
