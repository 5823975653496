import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import UpdateSkillCategoryMutation from '../../../mutations/update_skill_category_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {ModalHeaderInput} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import SkillsPreviewSelector from '../../../forecast-app/shared/components/dropdowns/preview-selector/SkillsPreviewSelector';

const UpdateSkillCategoryModal = ({skillCategoryId, company, closeModal}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const skillCategories = company.skillCategories.edges.map(skillCategory => skillCategory.node);
	const skills = company.skills.edges.map(skill => skill.node);
	const existingCategoryNames = new Set(
		skillCategories.filter(skillCategory => skillCategory.id !== skillCategoryId).map(skillCategory => skillCategory.name)
	);
	const skillCategory = skillCategories.find(skillCategory => skillCategory.id === skillCategoryId);
	if (!skillCategory) {
		return null;
	}

	const [name, setName] = useState(skillCategory.name);
	const [selectedSkills, setSelectedSkills] = useState(
		skills.filter(skill => skill.category?.id === skillCategory.id).map(skill => skill.id)
	);
	const originalSelection = useMemo(() => new Set(selectedSkills), []);
	const [errorMessage, setErrorMessage] = useState();
	const [nameValid, setNameValid] = useState(true);

	const createCategory = () => {
		Util.CommitMutation(
			UpdateSkillCategoryMutation,
			{
				name,
				id: skillCategoryId,
				companyId: company.id,
				assignedSkills: selectedSkills,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.changes_saved'}),
				});
			}
		);
	};

	const onNameChange = newName => {
		setName(newName);
		const duplicateName =
			existingCategoryNames.has(newName) || newName === formatMessage({id: 'settings_skills.no_category'});
		if (duplicateName) {
			setNameValid(false);
			setErrorMessage(formatMessage({id: 'settings_skills.category_already_exists'}));
		} else {
			setNameValid(!!newName);
			setErrorMessage(null);
		}
	};

	const getWarningSkillSelection = skill => {
		const categoryMoved = !!skill.category && !originalSelection.has(skill.id);
		if (categoryMoved) {
			return formatMessage({id: 'settings_skills.skill_will_be_moved'}, {name: skill.category.name});
		}
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: createCategory,
					disabled: !nameValid || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<div>
					<ModalHeaderInput
						cy="skill-category-name"
						placeholder={formatMessage({id: 'common.name_placeholder'})}
						value={name}
						onChange={onNameChange}
						hasError={!nameValid}
						errorMessage={errorMessage}
					/>
					<ModalBody>
						<Field>
							<SkillsPreviewSelector
								company={company}
								selectedSkillPersons={selectedSkills.map(skillId => ({skillId}))}
								onChange={selections => setSelectedSkills(selections.map(selection => selection.skillId))}
								warningCallback={getWarningSkillSelection}
								hideSelectionHeaderActions
							/>
						</Field>
					</ModalBody>
				</div>
			}
		/>
	);
};

const updateSkillCategoryModalQuery = graphql`
	query UpdateSkillCategoryModal_Query {
		viewer {
			actualPersonId
			component(name: "create_skill_category_modal")
			company {
				...UpdateSkillCategoryModal_company
			}
		}
	}
`;

export {updateSkillCategoryModalQuery};

export default createFragmentContainer(UpdateSkillCategoryModal, {
	company: graphql`
		fragment UpdateSkillCategoryModal_company on Company {
			id
			...SkillsPreviewSelector_company
			skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
			skills(first: 10000) @connection(key: "Company_skills") {
				edges {
					node {
						id
						category {
							id
						}
					}
				}
			}
		}
	`,
});
