import React, {Component} from 'react';
import InputField from '../../components/new-ui/input_field';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {getDefaultMediumClientLogo} from '../../components/default_avatars';
import CreateClientMutation from '../../mutations/create_client_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import GetSuggestedClientLogoMutation from '../../mutations/get_suggested_client_logo_mutation';
import CheckBox from '../../components/inputs/checkbox';
import {showModal, MODAL_TYPE} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../components/warning';
import UploadingOverlay from '../../forecast-app/shared/components/uploading-overlay/uploading_overlay';

class addClientModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			canSendRequest: true,
			suggestedLogoApproved: false,
			localClientLogo: null,
			localClientLogoSrc: null,
		};
	}

	onCreateClientSuccess(result) {
		this.setState({
			name: '',
			logoSrc: null,
		});
		if (this.props.project) {
			const onUpdateProjectSuccess = () => {
				this.props.closeModal();
			};
			Util.CommitMutation(
				UpdateProjectMutation,
				{project: this.props.project, clientId: result.createClient.client.node.id},
				onUpdateProjectSuccess
			);
		} else {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'settings_clients.client-added'}),
			});
			this.props.closeModal();
		}
	}

	handleInputChange(property, value) {
		this.setState({[property]: value});
	}

	handleLocalFileSelect() {
		if (this.file_input && this.file_input.files && this.file_input.files.length) {
			const {formatMessage} = this.props.intl;
			const file = this.file_input.files[0];
			Util.confirmFilePreviewType(file, res => {
				if (res) {
					var reader = new FileReader();
					reader.onload = e => {
						const localClientLogoSrc = e.target.result;
						this.setState({localClientLogoSrc});
					};
					reader.readAsDataURL(file);
					this.setState({localClientLogo: file, suggestedLogoApproved: false});
				} else {
					showModal({
						type: MODAL_TYPE.GENERIC,
						content: (
							<div className="default-warning-modal">
								<Warning messageId="warning_modal.profile_picture_wrong_format_title" />
								<div className="warning-part-2">
									{formatMessage({id: 'warning_modal.profile_picture_wrong_format_info'})}
								</div>
							</div>
						),
						buttons: [
							{
								text: formatMessage({id: 'common.cancel'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			});
		}
	}

	createClient() {
		if (this.state.canSendRequest) {
			this.setState({canSendRequest: false});
			if (this.state.localClientLogo) {
				Util.CommitMutation(
					CreateClientMutation,
					{
						companyId: this.props.viewer.company.id,
						name: this.state.name,
					},
					null,
					true,
					{
						id: this.props.viewer.company.id,
						file: this.state.localClientLogo,
					}
				);
			} else {
				Util.CommitMutation(
					CreateClientMutation,
					{
						companyId: this.props.viewer.company.id,
						name: this.state.name,
						logoSrc: this.state.logoSrc && this.state.suggestedLogoApproved ? this.state.logoSrc : undefined,
					},
					this.onCreateClientSuccess.bind(this)
				);
			}
		}
	}

	toggleApproveSuggestedLogo() {
		this.setState({suggestedLogoApproved: !this.state.suggestedLogoApproved});
	}

	doneTyping() {
		if (this.state.name && !this.state.localClientLogoSrc) {
			const onSuggestedLogoSuccess = res => {
				if (res?.getSuggestedClientLogo?.suggestedLogoSrc) {
					fetch(res.getSuggestedClientLogo.suggestedLogoSrc)
						.then(imageResponse => {
							if (imageResponse.status === 200) {
								this.setState({
									logoSrc: res.getSuggestedClientLogo.suggestedLogoSrc,
									suggestedLogoApproved: true,
									fetchingLogoSuggestion: false,
								});
							} else {
								this.setState({logoSrc: null, suggestedLogoApproved: false, fetchingLogoSuggestion: false});
							}
						})
						.catch(() => {
							this.setState({logoSrc: null, suggestedLogoApproved: false, fetchingLogoSuggestion: false});
						});
				} else {
					this.setState({logoSrc: null, suggestedLogoApproved: false, fetchingLogoSuggestion: false});
				}
			};
			this.setState({fetchingLogoSuggestion: true});
			Util.CommitMutation(GetSuggestedClientLogoMutation, {name: this.state.name}, onSuggestedLogoSuccess);
		} else {
			this.setState({logoSrc: null, suggestedLogoApproved: false});
		}
	}

	startCountdown() {
		this.clearCountdown();
		this.typingTimer = setTimeout(this.doneTyping.bind(this), 500);
	}

	clearCountdown() {
		if (this.typingTimer !== null) {
			clearTimeout(this.typingTimer);
		}
	}

	removeLocalClientLogo() {
		this.setState({
			localClientLogoSrc: null,
			localClientLogo: null,
			suggestedLogoApproved: this.state.logoSrc !== null && this.state.logoSrc !== undefined,
		});
	}

	getImgSrc() {
		if (this.state.suggestedLogoApproved) {
			return this.state.logoSrc;
		} else if (this.state.localClientLogo && this.state.localClientLogoSrc) {
			return this.state.localClientLogoSrc;
		} else {
			return getDefaultMediumClientLogo(0);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="body-container add-client-modal">
				<InputField
					label={formatMessage({id: 'common.name'})}
					value={this.state.name}
					onChange={this.handleInputChange.bind(this, 'name')}
					autoFocus={true}
					type="text"
					placeholder=""
					cy="client-name-label"
					onEnter={this.createClient.bind(this)}
					onKeyDown={this.clearCountdown.bind(this)}
					onKeyUp={this.startCountdown.bind(this)}
				/>
				{
					// this.state.logoSrc ? (

					<div className="suggested-client-logo-wrapper">
						{this.state.fetchingLogoSuggestion ? <UploadingOverlay /> : null}
						<div className="logo-controls-wrapper">
							<img alt="suggested-logo" className={'suggested-logo'} src={this.getImgSrc()} />
							<div className="suggested-logo-controls">
								{this.state.localClientLogoSrc || !this.state.logoSrc ? null : (
									<div className="approve-suggested-logo-controls">
										<CheckBox
											id="suggested-logo-approve-input"
											onChange={this.toggleApproveSuggestedLogo.bind(this)}
											checked={this.state.suggestedLogoApproved}
										/>
										<label className="title-label" htmlFor="suggested-logo-approve-input">
											{formatMessage({id: 'add_client_modal.suggested_logo_checkbox'})}
										</label>
									</div>
								)}
								<div className="controls-upload-local-file">
									<div className="file-upload-input-label-wrapper">
										<input
											className="client-logo-file-input"
											type="file"
											ref={input => (this.file_input = input)}
											onChange={this.handleLocalFileSelect.bind(this)}
										/>
										<label className="control update">
											{formatMessage({id: 'settings_details.update-logo'})}
										</label>
									</div>
									{this.state.localClientLogoSrc ? (
										<div className="control remove" onClick={this.removeLocalClientLogo.bind(this)}>
											{formatMessage({id: 'settings_details.remove-logo'})}
										</div>
									) : null}
								</div>
							</div>
						</div>
						{this.state.logoSrc ? (
							<a className="clearbit-link" href="https://clearbit.com" target="_blank" rel="noopener noreferrer">
								Logos provided by Clearbit
							</a>
						) : null}
					</div>
				}
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.cancelText || formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'settings_clients.add-client'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.createClient.bind(this),
						disabled: !this.state.name,
						cy: 'add-client-modal-button',
					},
				]}
				headerText={formatMessage({id: 'settings_clients.add-client'})}
				content={content}
			/>
		);
	}
}

const addClientModalQuery = graphql`
	query addClientModal_Query {
		viewer {
			actualPersonId
			component(name: "add_client_modal")
			...addClientModal_viewer
		}
	}
`;

export {addClientModalQuery};

export default injectIntl(
	createFragmentContainer(addClientModal, {
		viewer: graphql`
			fragment addClientModal_viewer on Viewer {
				company {
					id
					clients(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			}
		`,
	})
);
