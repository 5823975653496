/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SkillCategoryRow_skillCategory$ref: FragmentReference;
declare export opaque type SkillCategoryRow_skillCategory$fragmentType: SkillCategoryRow_skillCategory$ref;
export type SkillCategoryRow_skillCategory = {|
  +id: string,
  +name: ?string,
  +$refType: SkillCategoryRow_skillCategory$ref,
|};
export type SkillCategoryRow_skillCategory$data = SkillCategoryRow_skillCategory;
export type SkillCategoryRow_skillCategory$key = {
  +$data?: SkillCategoryRow_skillCategory$data,
  +$fragmentRefs: SkillCategoryRow_skillCategory$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillCategoryRow_skillCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "SkillCategory"
};
// prettier-ignore
(node/*: any*/).hash = '87ed52423512a020c8ee085652356932';

module.exports = node;
