import React from 'react';
import {useIntl} from 'react-intl';
import {Button, PlusIcon, CustomScrollDiv} from 'web-components';
import {
	VersionHeader,
	VersionHeaderText,
	VersionListHeader,
	VersionListItem,
	VersionDate,
	VersionActiveToday,
	VersionListItemContent,
} from './RateCard.styled';
import moment from 'moment';
import ActionsMenu from '../../../forecast-app/shared/components/action-menu/actions_menu';

const RateCardVersionList = ({
	rateCardVersions,
	activeVersionIndex,
	handleRateCardIndexChange,
	createRateCardVersion,
	deleteRateCardVersion,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	let versionActiveToday = null;
	rateCardVersions.forEach((version, index) => {
		const versionStartDate = moment(version.startDate);
		if (
			(versionActiveToday === null || versionStartDate.isAfter(versionActiveToday.startDate)) &&
			!versionStartDate.isAfter(moment())
		) {
			versionActiveToday = {
				startDate: versionStartDate,
				index: index,
			};
		}
	});

	const getActionsMenuOptions = (rateCardVersion, index) => [
		{
			text: formatMessage({id: 'common.duplicate'}),
			onClick: () => createRateCardVersion(index, false),
		},
		{
			text: formatMessage({id: 'common.delete'}),
			onClick: () => deleteRateCardVersion(index),
			locked: !rateCardVersion.startDate,
			disabledTitle: formatMessage({id: 'rate_card_modal.delete_initial_version'}),
		},
	];

	return (
		<>
			<VersionHeader>
				<VersionHeaderText>{formatMessage({id: 'common.versions'})}</VersionHeaderText>
				<Button
					onClick={() => createRateCardVersion(activeVersionIndex, false)}
					size={Button.SIZE.MEDIUM_SMALL}
					variant={Button.VARIANT.GREEN_FILLED}
					fontSize={Button.FONT_SIZE.LARGE}
					icon={color => <PlusIcon size={PlusIcon.SIZE.MEDIUM} color={color} />}
					iconPosition={Button.ICON_POSITION.FIRST}
				>
					{formatMessage({id: 'common.new'})}
				</Button>
			</VersionHeader>
			<VersionListHeader>{formatMessage({id: 'rate_card_modal.effective_date'})}</VersionListHeader>
			<CustomScrollDiv autoHeight autoHeightMin={1} autoHeightMax={'calc(65vh - 150px)'}>
				{rateCardVersions.map((rateCardVersion, index) => (
					<VersionListItem
						className={activeVersionIndex === index ? 'highlighted' : ''}
						onClick={() => handleRateCardIndexChange(index)}
					>
						<VersionListItemContent>
							<VersionDate>
								{rateCardVersion.startDate
									? moment(rateCardVersion.startDate).format('Do MMM YYYY')
									: formatMessage({id: 'rate_card_modal.initial_version'})}
							</VersionDate>
							{versionActiveToday.index === index ? (
								<VersionActiveToday>{formatMessage({id: 'rate_card_modal.active'})}</VersionActiveToday>
							) : null}
						</VersionListItemContent>
						<ActionsMenu whiteInner options={getActionsMenuOptions(rateCardVersion, index)} />
					</VersionListItem>
				))}
			</CustomScrollDiv>
		</>
	);
};

export default RateCardVersionList;
