import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl, FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {withRouter} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import {ELEMENT_TYPE} from '../../constants';
import * as tracking from '../../tracking';
import Util from '../../forecast-app/shared/util/util';
import {buildHeaderBar} from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import SettingsProfileSubNav from '../settings/SettingsProfileSubNav';
import {projectUrl} from '../../directApi';
import DeprecatedProjectIndicatorJS from '../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

class myTaskActivities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchFilterValue: '',
		};

		this.superPropertyChecksum = trackPage('My Task Activities');
	}

	componentDidMount() {
		document.title = 'My Task Activities - My profile - Forecast';

		// Segment
		tracking.trackPage('settings-my-task-activities');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	getTaskActivityMessage(activity) {
		const params = activity.params ? JSON.parse(activity.params) : {};
		let messageParams = params;
		const taskLevelSuffix = this.props.taskLevels === 1 ? '.TODO' : '.SUBTASK';
		return Util.getTaskActivityMessage(activity.eventType, messageParams, params, taskLevelSuffix, this.props.intl);
	}

	handleSearchChange(value) {
		this.setState({searchFilterValue: value});
	}

	getTopHeader() {
		const {formatMessage} = this.props.intl;
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={formatMessage({id: 'my_profile.task_activities'})} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader() {
		const rightContent = [];
		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: this.state.searchFilterValue,
			onChange: this.handleSearchChange.bind(this),
		});

		return buildHeaderBar([], rightContent);
	}

	getActivitySearchCrit(activity) {
		let searchCrit = '';
		if (activity.task) {
			searchCrit += 'T' + activity.task.companyTaskId + activity.task.name;
			if (activity.task.project) {
				searchCrit +=
					'P' +
					activity.task.project.companyProjectId +
					activity.task.project.customProjectId +
					activity.task.project.name;
			}
		}
		if (activity.date) {
			searchCrit += Moment(activity.date).format('DD MMM YYYY');
		}
		searchCrit += activity.eventType;
		const params = activity.params ? JSON.parse(activity.params) : {};
		if (params.personName) {
			searchCrit += params.personName;
		}
		return searchCrit.toLowerCase();
	}

	showTaskModal(companyTaskId) {
		Util.showTaskModal(companyTaskId, this.props.history);
	}

	navigateToProject(companyProjectId, customProjectId) {
		this.props.history.push(projectUrl(companyProjectId, customProjectId));
	}

	render() {
		const tableStyle = {tableLayout: 'fixed', width: '100%'};
		let taskActivities = this.props.viewer.taskActivities;
		if (this.state.searchFilterValue !== '') {
			taskActivities = taskActivities.filter(activity =>
				this.getActivitySearchCrit(activity).includes(this.state.searchFilterValue.toLowerCase())
			);
		}
		return (
			<div className="section-content settings-app my_task-activities V2" data-cy={'task-activity-page'}>
				{this.props.children}
				{this.getTopHeader()}
				<SettingsProfileSubNav viewer={this.props.viewer} />
				{this.getHeader()}
				<Scrollbars>
					<div className="section-body V2">
						<fieldset className="general">
							<table style={tableStyle}>
								<thead>
									<tr>
										<th>
											<FormattedMessage id="common.project" />
										</th>
										<th>
											<FormattedMessage id="common.task" />
										</th>
										<th>
											<FormattedMessage id="common.activity" />
										</th>
										<th>
											<FormattedMessage id="common.date" />
										</th>
									</tr>
								</thead>
								<tbody>
									{taskActivities.map((activity, index) => (
										<tr key={index}>
											<td
												className={
													activity.task &&
													activity.task.fullAccessToProject &&
													activity.task.project &&
													activity.task.project.companyProjectId !== null &&
													activity.task.project.companyProjectId !== undefined
														? 'clickable-column'
														: ''
												}
												onClick={
													activity.task &&
													activity.task.fullAccessToProject &&
													activity.task.project &&
													activity.task.project.companyProjectId !== null &&
													activity.task.project.companyProjectId !== undefined
														? this.navigateToProject.bind(
																this,
																activity.task.project.companyProjectId,
																activity.task.project.customProjectId
														  )
														: null
												}
											>
												{activity.task && activity.task.fullAccessToProject && activity.task.project ? (
													<DeprecatedProjectIndicatorJS
														project={activity.task.project}
														showProjectName={true}
														disableLink={true}
													/>
												) : (
													<FormattedMessage id="common.not_available" />
												)}
											</td>
											<td
												className={
													activity.task &&
													activity.task.companyTaskId !== null &&
													activity.task.companyTaskId !== undefined &&
													activity.task.fullAccessToProject
														? 'clickable-column'
														: ''
												}
												onClick={
													activity.task &&
													activity.task.companyTaskId !== null &&
													activity.task.companyTaskId !== undefined &&
													activity.task.fullAccessToProject
														? this.showTaskModal.bind(this, activity.task.companyTaskId.toString())
														: null
												}
											>
												{activity.task && activity.task.fullAccessToProject ? (
													`T${activity.task.companyTaskId} ${activity.task.name}`
												) : (
													<FormattedMessage id="common.not_available" />
												)}
											</td>
											<td>{this.getTaskActivityMessage(activity)}</td>
											<td>{activity.date ? Moment(activity.date).format('DD MMM YYYY') : ''}</td>
										</tr>
									))}
								</tbody>
							</table>
						</fieldset>
					</div>
				</Scrollbars>
			</div>
		);
	}
}

const myTaskActivitiesQuery = graphql`
	query myTaskActivities_Query {
		viewer {
			actualPersonId
			component(name: "my_task_activities")
			...myTaskActivities_viewer
		}
	}
`;

export {myTaskActivitiesQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(myTaskActivities, {
			viewer: graphql`
				fragment myTaskActivities_viewer on Viewer {
					component(name: "my_task_activities")
					availableFeatureFlags {
						key
					}
					id
					actualPersonId
					taskActivities {
						id
						date
						eventType
						params
						task {
							id
							companyTaskId
							name
							fullAccessToProject
							project {
								companyProjectId
								customProjectId
								...DeprecatedProjectIndicatorJS_project
								name
							}
						}
					}
					...SettingsProfileSubNav_viewer
				}
			`,
		})
	)
);
