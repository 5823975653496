import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router-dom';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../forecast-app/shared/util/util';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import {showModal, MODAL_TYPE} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../directApi';

class linkUnit4ProjectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUnit4Project: null,
			synching: false,
			unSynchedUnit4Projects: [],
			fetchingProjects: false,
		};
	}

	fetchUnit4Projects(filterString) {
		if (!this.state.fetchingProjects) {
			this.setState({
				fetchingProjects: true,
			});
			if (this.props.viewer.company.unit4Enabled) {
				DirectApi.Fetch(`unit4/unsynched_projects?filterString=${filterString}`)
					.then(json => {
						this.setState({
							unSynchedUnit4Projects: Array.isArray(json) ? json : [],
							fetchingProjects: false,
						});
					})
					.catch(e => {
						this.setState({
							fetchingProjects: false,
						});
					});
			}
		}
	}

	selectProject(selected) {
		if (selected.value !== this.state.selectedUnit4Project) {
			this.setState({selectedUnit4Project: selected.value});
		}
	}

	syncProject() {
		const {formatMessage} = this.props.intl;

		DirectApi.Fetch(`unit4_sync_project/${this.props.projectId}`).then(json => {
			// Display success / failure
			showModal({
				type: MODAL_TYPE.GENERIC,
				headerText: json.success ? (
					<FormattedMessage id="settings.unit4.sync_project.success.header" />
				) : (
					<FormattedMessage id="settings.unit4.sync_project.failure.header" />
				),
				content: json.success ? (
					<FormattedMessage id="settings.unit4.sync_project.success.description" />
				) : (
					<FormattedMessage id="settings.unit4.sync_project.failure.description" />
				),
				buttons: [
					{
						text: formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
					},
				],
			});
		});
	}

	linkProject() {
		const {formatMessage} = this.props.intl;
		this.setState({synching: true});
		const onSuccess = () => {
			this.setState({synching: false});
			this.props.closeModal();
			showModal({
				type: MODAL_TYPE.GENERIC,
				headerText: <FormattedMessage id="settings.unit4.sync_project.modal.header" />,
				content: <FormattedMessage id="settings.unit4.sync_project.modal.description" />,
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						callback: this.props.closeModal,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.synchronize'}),
						callback: this.syncProject.bind(this),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
					},
				],
			});
		};
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project: {id: this.props.projectId},
				unit4Project: this.state.selectedUnit4Project,
			},
			onSuccess
		);
	}

	getContent() {
		const unsynched_unit4_project_options = this.state.unSynchedUnit4Projects?.map(project => ({
			value: project.id,
			label: project.id + ': ' + project.name,
		}));
		return (
			<DropdownV2
				options={unsynched_unit4_project_options ?? []}
				customHeight={30}
				onChange={this.selectProject.bind(this)}
				value={this.state.selectedUnit4Project}
				label={'Unit4 Project'}
				placeholder={'Unit4 Project. Type to search'}
				integrationDropdown={true}
				integrationLoading={this.state.fetchingProjects}
				onSearchValueChange={this.fetchUnit4Projects.bind(this)}
			/>
		);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const buttons = [
			{
				text: formatMessage({id: 'common.cancel'}),
				callback: this.props.closeModal,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				disabled: this.state.synching,
			},
			{
				text: formatMessage({id: 'settings.link_project'}),
				callback: this.linkProject.bind(this),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				disabled: !this.state.selectedUnit4Project || this.state.synching,
				preventDefaultClose: true,
			},
		];
		return (
			<GenericModal
				useScrollbars={false}
				closeModal={this.props.closeModal}
				headerText={formatMessage({id: 'settings.link_project'})}
				buttons={buttons}
				content={this.getContent()}
			/>
		);
	}
}

const linkUnit4ProjectModalQuery = graphql`
	query linkUnit4ProjectModal_Query {
		viewer {
			actualPersonId
			component(name: "link_unit4_project_modal")
			...linkUnit4ProjectModal_viewer
		}
	}
`;

export {linkUnit4ProjectModalQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(linkUnit4ProjectModal, {
			viewer: graphql`
				fragment linkUnit4ProjectModal_viewer on Viewer {
					id
					company {
						id
						unit4Enabled
					}
				}
			`,
		})
	)
);
