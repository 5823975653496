/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
type PersonDropdown_persons$ref = any;
type ProjectDropdown_projects$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type standaloneCreateInvoiceModalV2_viewer$ref: FragmentReference;
declare export opaque type standaloneCreateInvoiceModalV2_viewer$fragmentType: standaloneCreateInvoiceModalV2_viewer$ref;
export type standaloneCreateInvoiceModalV2_viewer = {|
  +id: string,
  +backendId: ?number,
  +firstName: ?string,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +currency: ?string,
    +invoiceLastSequence: ?number,
    +invoiceQuantityDecimals: ?number,
    +exchangeRates: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +currency: ?string,
          +rate: ?number,
        |}
      |}>
    |},
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
        +$fragmentRefs: PersonDropdown_persons$ref,
      |}>
    |},
  |},
  +project?: ?{|
    +id: string,
    +name: ?string,
    +projectColor: ?string,
    +isInProjectGroup: ?boolean,
    +companyProjectId: ?number,
    +projectGroupId: ?string,
    +currencyExchangeRate: ?number,
    +budgetType: ?BUDGET_TYPE,
    +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
    +status: ?PROJECT_STATUS,
    +budget: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +fullAccessToProject: ?boolean,
    +rateCard: ?{|
      +currency: ?string
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
    +invoiceTotals: ?$ReadOnlyArray<?{|
      +invoiceId: string,
      +createdDay: ?number,
      +createdMonth: ?number,
      +createdYear: ?number,
      +invoicedWithoutTax: ?number,
      +invoicedWithoutTaxBaseCurrency: ?number,
      +invoicedWithoutTaxProjectCurrency: ?number,
    |}>,
    +unInvoicedTotal: {|
      +unInvoicedMinutes: ?number,
      +unInvoicedPrice: ?number,
    |},
    +expenseItems: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +approved: ?boolean,
          +expenseYear: ?number,
          +expenseMonth: ?number,
          +expenseDay: ?number,
          +billable: ?boolean,
          +partOfFixedPrice: ?boolean,
          +price: ?number,
          +cost: ?number,
          +quantity: ?number,
          +planned: ?boolean,
          +createdAt: ?string,
          +invoiceId: ?number,
          +invoiced: ?boolean,
          +person: ?{|
            +id: string,
            +fullName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
          |},
          +phase: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +retainerPeriods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +periodBudgetType: ?PERIOD_BUDGET_TYPE,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +endYear: ?number,
          +endMonth: ?number,
          +endDay: ?number,
          +periodPriceAmount: ?number,
          +periodHoursAmount: ?number,
          +periodLocked: ?boolean,
          +invoiced: ?boolean,
          +sharedPeriodDifferencePriceAmount: ?number,
        |}
      |}>
    |},
    +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
  |},
  +projects?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +projectColor: ?string,
        +isInProjectGroup: ?boolean,
        +companyProjectId: ?number,
        +projectGroupId: ?string,
        +currencyExchangeRate: ?number,
        +budgetType: ?BUDGET_TYPE,
        +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
        +status: ?PROJECT_STATUS,
        +budget: ?number,
        +projectEndYear: ?number,
        +projectEndMonth: ?number,
        +projectEndDay: ?number,
        +fullAccessToProject: ?boolean,
        +invoiceTotals: ?$ReadOnlyArray<?{|
          +invoiceId: string,
          +createdDay: ?number,
          +createdMonth: ?number,
          +createdYear: ?number,
          +invoicedWithoutTax: ?number,
          +invoicedWithoutTaxBaseCurrency: ?number,
          +invoicedWithoutTaxProjectCurrency: ?number,
        |}>,
        +unInvoicedTotal: {|
          +unInvoicedMinutes: ?number,
          +unInvoicedPrice: ?number,
        |},
        +currentProjectStatus: ?{|
          +id: string,
          +color: ?string,
        |},
        +client: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |},
        +rateCard: ?{|
          +id: string,
          +name: ?string,
          +currency: ?string,
          +parentRateCardId: ?string,
          +defaultRate: ?number,
          +rates: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +rate: ?number,
                +startDate: ?string,
                +defaultRate: ?boolean,
                +role: ?{|
                  +id: string,
                  +name: ?string,
                |},
              |}
            |}>
          |},
        |},
        +expenseItems: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +approved: ?boolean,
              +expenseYear: ?number,
              +expenseMonth: ?number,
              +expenseDay: ?number,
              +billable: ?boolean,
              +partOfFixedPrice: ?boolean,
              +price: ?number,
              +cost: ?number,
              +quantity: ?number,
              +planned: ?boolean,
              +createdAt: ?string,
              +invoiceId: ?number,
              +invoiced: ?boolean,
              +person: ?{|
                +id: string,
                +fullName: ?string,
                +profilePictureId: ?string,
                +profilePictureDefaultId: ?number,
              |},
              +phase: ?{|
                +id: string,
                +name: ?string,
              |},
            |}
          |}>
        |},
        +retainerPeriods: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +periodBudgetType: ?PERIOD_BUDGET_TYPE,
              +startYear: ?number,
              +startMonth: ?number,
              +startDay: ?number,
              +endYear: ?number,
              +endMonth: ?number,
              +endDay: ?number,
              +periodPriceAmount: ?number,
              +periodHoursAmount: ?number,
              +periodLocked: ?boolean,
              +invoiced: ?boolean,
              +sharedPeriodDifferencePriceAmount: ?number,
            |}
          |}>
        |},
        +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
      |},
      +$fragmentRefs: ProjectDropdown_projects$ref,
    |}>
  |},
  +$refType: standaloneCreateInvoiceModalV2_viewer$ref,
|};
export type standaloneCreateInvoiceModalV2_viewer$data = standaloneCreateInvoiceModalV2_viewer;
export type standaloneCreateInvoiceModalV2_viewer$key = {
  +$data?: standaloneCreateInvoiceModalV2_viewer$data,
  +$fragmentRefs: standaloneCreateInvoiceModalV2_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProjectGroup",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currencyExchangeRate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultPeriodBudgetType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budget",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectInvoiceTotalType",
  "kind": "LinkedField",
  "name": "invoiceTotals",
  "plural": true,
  "selections": [
    (v22/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoicedWithoutTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoicedWithoutTaxBaseCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoicedWithoutTaxProjectCurrency",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "endDay",
      "variableName": "endDay"
    },
    {
      "kind": "Variable",
      "name": "endMonth",
      "variableName": "endMonth"
    },
    {
      "kind": "Variable",
      "name": "endYear",
      "variableName": "endYear"
    },
    {
      "kind": "Variable",
      "name": "startDay",
      "variableName": "startDay"
    },
    {
      "kind": "Variable",
      "name": "startMonth",
      "variableName": "startMonth"
    },
    {
      "kind": "Variable",
      "name": "startYear",
      "variableName": "startYear"
    }
  ],
  "concreteType": "UnInvoicedProjectTotal",
  "kind": "LinkedField",
  "name": "unInvoicedTotal",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unInvoicedMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unInvoicedPrice",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpenseItemTypeConnection",
  "kind": "LinkedField",
  "name": "expenseItems",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExpenseItemTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseItem",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expenseYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expenseMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expenseDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "partOfFixedPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planned",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            (v22/*: any*/),
            (v25/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseType",
              "kind": "LinkedField",
              "name": "phase",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    }
  ],
  "concreteType": "RetainerPeriodTypeConnection",
  "kind": "LinkedField",
  "name": "retainerPeriods",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RetainerPeriodTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RetainerPeriodType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodBudgetType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodPriceAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodHoursAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodLocked",
              "storageKey": null
            },
            (v25/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sharedPeriodDifferencePriceAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "retainerPeriods(first:100000)"
},
v28 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeprecatedProjectIndicatorJS_project"
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultRate",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectInvoicing",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "standaloneCreateInvoiceModalV2_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceLastSequence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceQuantityDecimals",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExchangeRateTypeConnection",
          "kind": "LinkedField",
          "name": "exchangeRates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExchangeRateTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExchangeRate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeClientUsers",
              "value": false
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            },
            {
              "kind": "Literal",
              "name": "onlyActive",
              "value": false
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PersonDropdown_persons"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:false,first:1000000,onlyActive:false)"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isProjectInvoicing",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "internalId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "RateCard",
              "kind": "LinkedField",
              "name": "rateCard",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            (v23/*: any*/),
            (v24/*: any*/),
            (v26/*: any*/),
            (v27/*: any*/),
            (v28/*: any*/)
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isProjectInvoicing",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeDoneOrHalted",
              "value": true
            }
          ],
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "projects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    (v23/*: any*/),
                    (v24/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectStatus",
                      "kind": "LinkedField",
                      "name": "currentProjectStatus",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "color",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Client",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoDefaultId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RateCard",
                      "kind": "LinkedField",
                      "name": "rateCard",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "parentRateCardId",
                          "storageKey": null
                        },
                        (v29/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "first",
                              "value": 10000
                            }
                          ],
                          "concreteType": "RateTypeConnection",
                          "kind": "LinkedField",
                          "name": "rates",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "RateTypeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Rate",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v3/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "startDate",
                                      "storageKey": null
                                    },
                                    (v29/*: any*/),
                                    (v8/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "rates(first:10000)"
                        }
                      ],
                      "storageKey": null
                    },
                    (v26/*: any*/),
                    (v27/*: any*/),
                    (v28/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectDropdown_projects"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projects(excludeDoneOrHalted:true)"
        }
      ]
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd5c1d81aa7ab15e32e8ae2de78421bd';

module.exports = node;
