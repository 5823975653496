/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type maintenance_viewer$ref: FragmentReference;
declare export opaque type maintenance_viewer$fragmentType: maintenance_viewer$ref;
export type maintenance_viewer = {|
  +component: ?string,
  +downForMaintenance: ?boolean,
  +$refType: maintenance_viewer$ref,
|};
export type maintenance_viewer$data = maintenance_viewer;
export type maintenance_viewer$key = {
  +$data?: maintenance_viewer$data,
  +$fragmentRefs: maintenance_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "maintenance_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "maintenance"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"maintenance\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downForMaintenance",
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'e1930cc4ebc9273d3744932aa182fff4';

module.exports = node;
