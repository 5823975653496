/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SkillDropdown_company$ref = any;
type TeamDropdown_teams$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlaceholderModal_viewer$ref: FragmentReference;
declare export opaque type PlaceholderModal_viewer$fragmentType: PlaceholderModal_viewer$ref;
export type PlaceholderModal_viewer = {|
  +id: string,
  +email: ?string,
  +company: ?{|
    +skillLevelsEnabled: ?boolean,
    +placeholder?: ?{|
      +id: string,
      +name: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +skills: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
      +skillPlaceholders: ?$ReadOnlyArray<?{|
        +skill: ?{|
          +id: string
        |},
        +level: ?{|
          +id: string
        |},
      |}>,
      +placeholderAllocations: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
      +departmentId: ?string,
      +teamPlaceholders: ?$ReadOnlyArray<?{|
        +teamId: ?string
      |}>,
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |},
        +$fragmentRefs: TeamDropdown_teams$ref,
      |}>
    |},
    +departments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +$fragmentRefs: SkillDropdown_company$ref,
  |},
  +$refType: PlaceholderModal_viewer$ref,
|};
export type PlaceholderModal_viewer$data = PlaceholderModal_viewer;
export type PlaceholderModal_viewer$key = {
  +$data?: PlaceholderModal_viewer$data,
  +$fragmentRefs: PlaceholderModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "placeholderId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadPlaceholder",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "placeholder",
          "skills"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "placeholder",
          "placeholderAllocations"
        ]
      }
    ]
  },
  "name": "PlaceholderModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skillLevelsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TeamDropdown_teams"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DepartmentTypeConnection",
          "kind": "LinkedField",
          "name": "departments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DepartmentTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DepartmentType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillDropdown_company"
        },
        {
          "condition": "loadPlaceholder",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "placeholderId",
                  "variableName": "placeholderId"
                }
              ],
              "concreteType": "Placeholder",
              "kind": "LinkedField",
              "name": "placeholder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "role",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": "skills",
                  "args": null,
                  "concreteType": "SkillTypeConnection",
                  "kind": "LinkedField",
                  "name": "__Placeholder_skills_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Skill",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillPlaceholder",
                  "kind": "LinkedField",
                  "name": "skillPlaceholders",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "skill",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SkillLevel",
                      "kind": "LinkedField",
                      "name": "level",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": "placeholderAllocations",
                  "args": null,
                  "concreteType": "PlaceholderAllocationTypeConnection",
                  "kind": "LinkedField",
                  "name": "__Placeholder_placeholderAllocations_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PlaceholderAllocationTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PlaceholderAllocation",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "departmentId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamPlaceholder",
                  "kind": "LinkedField",
                  "name": "teamPlaceholders",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "teamId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '3632ef020f48132038ac4026eb22a1f7';

module.exports = node;
