import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import moment from 'moment';
import {getTimeUnit} from '../../../../../components/new-ui/project/project-budget-v3/util/ChartsUtil';
import Util from '../../../../../forecast-app/shared/util/util';
import {Chart} from 'web-components';
import {ChartContainer} from './PredictedEndDateModal.styled';
import {getChartData, getChartDataSets} from './PredictedEndDateModalChartData';
import * as tracking from '../../../../../tracking';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

import Annotation from 'chartjs-plugin-annotation';

Chart.register(Annotation);

const PredictedEndDateModalChartSection = ({project, statsPerDayMap, projectDone}) => {
	const intl = useIntl();

	const projectHealth = project.projectHealth;

	const projectStartDate =
		project.projectStartYear &&
		Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay);
	const projectEndDate =
		project.projectEndYear &&
		Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);

	const statDates = Array.from(statsPerDayMap.keys()).map(day => moment(day));
	const firstStatDate = moment.min(statDates);
	const firstChartDate = projectStartDate && projectStartDate.isAfter(firstStatDate) ? projectStartDate : firstStatDate;

	const modifyChartOptions = options => {
		options.scales.x.time.unit = getTimeUnit(firstChartDate, projectEndDate);
		options.scales.y.ticks.callback = label => Util.convertMinutesToFullHour(label, intl);
		options.plugins.legend.onClick = e => e.native.stopPropagation();
		options.plugins.tooltip.position = 'nearest';
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			if (item.hideLabel || (dataItem.customData && dataItem.customData.hideLabel)) {
				return '';
			} else {
				const dataSetLabel = item.label ? `${item.label}: ` : '';
				return `${dataSetLabel}${Util.convertMinutesToFullHour(dataItem.y, intl)}`;
			}
		};
		if (projectEndDate && projectEndDate.isAfter(firstChartDate)) {
			options.plugins.annotation = {
				annotations: {
					line1: {
						type: 'line',
						scaleID: 'x',
						value: projectEndDate,
						borderColor: '#e6e6e6',
						borderWidth: 1,
						label: {
							backgroundColor: 'rgba(224,224,224,0.8)',
							content: 'Deadline',
							display: true,
							font: {
								color: '#a1a1a1',
								size: 11,
								style: 'normal',
							},
							yAdjust: 60,
						},
					},
				},
			};
		}
		return options;
	};

	const data = getChartData(projectHealth, statsPerDayMap, firstChartDate, projectEndDate, projectDone);

	const dataSets = getChartDataSets(data);

	return (
		<ChartContainer
			onMouseEnter={() => {
				tracking.trackElementHovered('Predicted end date modal chart');
				trackEvent('Predicted End Date Modal Chart', 'Hovered');
			}}
		>
			<Chart dataSets={dataSets} modifyOptions={modifyChartOptions} />
		</ChartContainer>
	);
};

export default createFragmentContainer(PredictedEndDateModalChartSection, {
	project: graphql`
		fragment PredictedEndDateModalChartSection_project on ProjectType {
			projectStartYear
			projectStartMonth
			projectStartDay
			projectEndYear
			projectEndMonth
			projectEndDay
			projectHealth {
				totalTaskEstimate
				totalBaselineEstimate
				totalTaskEstimateDone
				predictedEndDate
				optimisticPredictedEndDate
				conservativePredictedEndDate
				predictedScopePerDay {
					date
					value
				}
				optimisticPredictedScopePerDay {
					date
					value
				}
				conservativePredictedScopePerDay {
					date
					value
				}
				predictedAverageScopePerDay
				optimisticAverageScopePerDay
				conservativeAverageScopePerDay
			}
		}
	`,
});
