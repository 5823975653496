import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import WarningIcon from '../../../images/warning_icon';
import Styled from 'styled-components';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';

const ModalContent = Styled.div`
	.operation-description {
		padding-top: 26px;
		font-size: 13px;
	}
	.bottom-section {
		padding-bottom: 121px;
	}
`;

const DeleteSkillLevelModal = ({levelToDelete, skillLevels, levelIsUsed, closeModal, saveAndCloseModal}) => {
	const {formatMessage} = useIntl();

	const options = skillLevels.map(skillLevel => {
		return {
			label: `Level ${skillLevel.level} "${skillLevel.description}"`,
			value: skillLevel.level,
			skillLevel: skillLevel,
		};
	});

	const [selected, setSelected] = useState(null);

	const onSelectLevel = option => {
		setSelected(option);
	};

	const confirmAndDelete = () => {
		const result = {skillLevel: selected?.skillLevel};
		saveAndCloseModal(result);
	};

	const content = (
		<ModalContent>
			<ModalBody>
				{levelIsUsed && (
					<>
						<Field>
							<div className="operation-description">
								{formatMessage(
									{id: 'settings_skills.delete_level_modal.description'},
									{level: levelToDelete.level}
								)}
							</div>
						</Field>
						<Field>
							<DropdownV2
								options={options}
								onChange={onSelectLevel}
								value={selected && selected.value}
								placeholder={formatMessage({id: 'settings_skills.levels.no_level'})}
								inputName={'level-input'}
								inputCy={'level'}
								listDataCy={'level'}
								customClasses={'dropdown-section'}
							/>
						</Field>
					</>
				)}
				<Field>
					<div className="bottom-section" />
				</Field>
			</ModalBody>
		</ModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={
				<>
					<WarningIcon danger={true} />
					<div>{formatMessage({id: 'settings_skills.delete_level_modal.delete_level'})}</div>
				</>
			}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'settings_skills.delete_level_modal.delete_confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: confirmAndDelete,
					disabled: !selected && levelIsUsed,
					cy: 'button-delete',
				},
			]}
			content={content}
		/>
	);
};

export default DeleteSkillLevelModal;
