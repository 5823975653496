/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type bambooDuplicates_viewer$ref = any;
export type bambooDuplicatesRefetchQueryVariables = {||};
export type bambooDuplicatesRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: bambooDuplicates_viewer$ref
  |}
|};
export type bambooDuplicatesRefetchQuery = {|
  variables: bambooDuplicatesRefetchQueryVariables,
  response: bambooDuplicatesRefetchQueryResponse,
|};
*/


/*
query bambooDuplicatesRefetchQuery {
  viewer {
    ...bambooDuplicates_viewer
    id
  }
}

fragment bambooDuplicates_viewer on Viewer {
  id
  missionControlData {
    id
    bambooDuplicates {
      id
      company
      duplicateCount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bambooDuplicatesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "bambooDuplicates_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bambooDuplicatesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlBambooDuplicatesType",
                "kind": "LinkedField",
                "name": "bambooDuplicates",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "company",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duplicateCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bambooDuplicatesRefetchQuery",
    "operationKind": "query",
    "text": "query bambooDuplicatesRefetchQuery {\n  viewer {\n    ...bambooDuplicates_viewer\n    id\n  }\n}\n\nfragment bambooDuplicates_viewer on Viewer {\n  id\n  missionControlData {\n    id\n    bambooDuplicates {\n      id\n      company\n      duplicateCount\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4eb4a80b53857259f635d674e43f0068';

module.exports = node;
