import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import GenericModal from '../../containers/modal/generic_modal';
import {BUTTON_COLOR} from '../../constants';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import UpdateTaskMutation from '../../mutations/update_task_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import AssignedDropdown from '../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import 'whatwg-fetch'; //IE 11 fetch polyfill

class TaskItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignedRole: null,
		};
	}

	assignRole(roleId) {
		const {task, onTaskAssigned} = this.props;
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}

		const onSuccess = () => {
			const role = this.props.viewer.company.roles.edges.find(edge => edge.node.id === roleId);
			this.setState({assignedRole: role ? role.node : null});
			onTaskAssigned();
		};
		Util.CommitMutation(UpdateTaskMutation, {ids: [task.id], roleId}, onSuccess);
	}

	render() {
		const {task, viewer} = this.props;
		const {assignedRole} = this.state;
		return (
			<div className="task-item-container">
				<div className="task-info-container">
					<div className="task-name">{task.name}</div>
					<AssignedDropdown
						assignableRoles={viewer.company.roles.edges}
						assignedRole={assignedRole}
						assignRole={this.assignRole.bind(this)}
						isMultiSelect={true}
						showSuggestions={false}
						viewer={viewer}
						closeOnChoice={true}
					/>
				</div>
				<div className="border-container" />
				<div className="corner-cover" />
			</div>
		);
	}
}

class FixUnassignedTasksModal extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		const {unassignedTasks, viewer, onSuccess} = this.props;

		const content = (
			<div className="auto-scheduling-modal fix-unassigned-tasks-modal-container">
				<div className="warning-description">
					In order to accurately calculate the budget, all tasks must have assigned roles.
				</div>
				<div className="table-heading-row"> </div>
				<div className="task-items-container">
					<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
						{unassignedTasks.map(task => (
							<TaskItem
								key={task.id}
								task={task}
								formatMessage={formatMessage}
								viewer={viewer}
								onTaskAssigned={onSuccess}
							/>
						))}
					</CustomScrollDiv>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[{text: formatMessage({id: 'common.filter-close'}), color: BUTTON_COLOR.WHITE}]}
				content={content}
				headerText={'Assign tasks'}
			/>
		);
	}
}

export default injectIntl(FixUnassignedTasksModal);
