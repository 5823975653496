/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type bugDuty_viewer$ref = any;
export type bugDutyRefetchQueryVariables = {|
  searchString?: ?string,
  filter?: ?string,
|};
export type bugDutyRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: bugDuty_viewer$ref
  |}
|};
export type bugDutyRefetchQuery = {|
  variables: bugDutyRefetchQueryVariables,
  response: bugDutyRefetchQueryResponse,
|};
*/


/*
query bugDutyRefetchQuery(
  $searchString: String
  $filter: String
) {
  viewer {
    ...bugDuty_viewer_4qtwxc
    id
  }
}

fragment bugDuty_viewer_4qtwxc on Viewer {
  id
  missionControlData {
    id
    errorList(searchString: $searchString, filter: $filter) {
      id
      actualId
      timestamp
      personId
      personName
      companyId
      companyName
      correlationId
      sentryEventId
      fixed
      notes
      excludeFromStats
      lastUpdatedByName
      bugDuty
      team
      url
      knownType
    }
    knownErrorTypes {
      id
      name
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "String"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "searchString",
    "variableName": "searchString"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bugDutyRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "bugDuty_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bugDutyRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "MissionControlErrorListType",
                "kind": "LinkedField",
                "name": "errorList",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actualId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timestamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "correlationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sentryEventId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fixed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "excludeFromStats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastUpdatedByName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bugDuty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "team",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "knownType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlKnownErrorType",
                "kind": "LinkedField",
                "name": "knownErrorTypes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bugDutyRefetchQuery",
    "operationKind": "query",
    "text": "query bugDutyRefetchQuery(\n  $searchString: String\n  $filter: String\n) {\n  viewer {\n    ...bugDuty_viewer_4qtwxc\n    id\n  }\n}\n\nfragment bugDuty_viewer_4qtwxc on Viewer {\n  id\n  missionControlData {\n    id\n    errorList(searchString: $searchString, filter: $filter) {\n      id\n      actualId\n      timestamp\n      personId\n      personName\n      companyId\n      companyName\n      correlationId\n      sentryEventId\n      fixed\n      notes\n      excludeFromStats\n      lastUpdatedByName\n      bugDuty\n      team\n      url\n      knownType\n    }\n    knownErrorTypes {\n      id\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4aa55599d0459bb72f7b8c647f0fb9df';

module.exports = node;
