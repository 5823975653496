export const addTotalScopeData = (dataSet, totalEstimate, dayStat, previousTotalScope, dateInfo) => {
	const {momentDate, isToday, isPast, isFuture} = dateInfo;

	let dayScope = null;

	if (isToday) {
		dataSet.push({
			date: momentDate,
			value: totalEstimate,
		});
	} else if (isFuture) {
		dataSet.push({
			date: momentDate,
			value: totalEstimate,
		});
	} else if (isPast) {
		dayScope = dayStat;
		dataSet.push({
			date: momentDate,
			value: dayScope || previousTotalScope,
		});
	}

	return dayScope;
};

export const addTotalDoneScopeData = (data, totalTaskEstimateDone, statsPerDayMap, previousTotalDoneScope, dateInfo) => {
	const {date, momentDate, isToday, isPast, isFuture} = dateInfo;

	let dayDoneScope = null;

	if (isToday) {
		data.doneScope.push({
			date: momentDate,
			value: totalTaskEstimateDone,
		});
	} else if (isFuture) {
		data.doneScope.push({
			date: momentDate,
			value: null,
		});
	} else if (isPast) {
		dayDoneScope = statsPerDayMap.get(date)?.totalDoneEstimateMinutes;
		data.doneScope.push({
			date: momentDate,
			value: dayDoneScope || previousTotalDoneScope,
		});
	}

	return dayDoneScope;
};

export const addPredictedEndDateData = (
	dataSet,
	totalTaskEstimate,
	totalTaskEstimateDone,
	predictedScopePerDayMap,
	lastAllocationDate,
	lastPredictedEndDateMoment,
	dateInfo
) => {
	const {date, momentDate, isToday, isPast, isFuture} = dateInfo;

	if (isToday) {
		dataSet.push({
			date: momentDate,
			value: totalTaskEstimateDone,
		});
	} else if (isFuture) {
		if (
			momentDate.isSameOrBefore(lastAllocationDate, 'days') &&
			momentDate.isSameOrBefore(lastPredictedEndDateMoment, 'days')
		) {
			dataSet.push({
				date: momentDate,
				value: Math.min(predictedScopePerDayMap.get(date) || 0, totalTaskEstimate),
			});
		}
	} else if (isPast) {
		dataSet.push({
			date: momentDate,
			value: null,
		});
	}
};

export const addPredictedEndDateNoAllocationData = (
	dataSet,
	totalTaskEstimate,
	lastAllocationEstimateDone,
	averageDonePerDay,
	lastAllocationDate,
	lastPredictedEndDateMoment,
	dateInfo
) => {
	const {momentDate} = dateInfo;
	let totalDoneScope = lastAllocationEstimateDone;

	if (momentDate.isSameOrAfter(lastAllocationDate, 'days') && momentDate.isSameOrBefore(lastPredictedEndDateMoment, 'days')) {
		if (momentDate.isAfter(lastAllocationDate, 'days')) {
			totalDoneScope += averageDonePerDay;
			dataSet.push({
				date: momentDate,
				value: Math.min(totalDoneScope || 0, totalTaskEstimate),
			});
		} else {
			dataSet.push({
				date: momentDate,
				value: Math.min(totalDoneScope || 0, totalTaskEstimate),
				customData: {
					hideLabel: true,
				},
			});
		}

		return totalDoneScope;
	} else {
		dataSet.push({
			date: momentDate,
			value: null,
		});
	}

	return null;
};

export const addTodayIntersection = (data, totalTaskEstimateDone, dateInfo) => {
	const {momentDate, isToday, isPast, isFuture} = dateInfo;

	if (isToday) {
		data.todayIntersection.push({
			date: momentDate,
			value: totalTaskEstimateDone,
			customData: {
				hideLabel: true,
			},
		});
	} else if (isFuture) {
		data.todayIntersection.push({
			date: momentDate,
			value: null,
		});
	} else if (isPast) {
		data.todayIntersection.push({
			date: momentDate,
			value: null,
		});
	}
};

export const addPredictedIntersection = (dataSet, predictedEndDateMoment, momentDate, totalTaskEstimate) => {
	if (predictedEndDateMoment && momentDate.isSame(predictedEndDateMoment, 'day')) {
		dataSet.push({
			date: momentDate,
			value: totalTaskEstimate,
			customData: {
				hideLabel: true,
			},
		});
	} else {
		dataSet.push({
			date: momentDate,
			value: null,
		});
	}
};
