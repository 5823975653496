import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import moment from 'moment';
import {getTimeUnit} from '../../../../../components/new-ui/project/project-budget-v3/util/ChartsUtil';
import {Chart} from 'web-components';
import {ChartContainer} from '../predicted_end_date_modal/PredictedEndDateModal.styled';
import {convertStatsToMapPerDay} from './PerformanceLogic';
import {getChartData, getChartDataSets} from './PerformanceModalChartData';
import * as tracking from '../../../../../tracking';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const PerformanceModalChartSection = ({performance, projectStats}) => {
	const statsPerDayMap = convertStatsToMapPerDay(projectStats);

	const today = moment();
	const thirtyDaysAgo = today.clone().subtract(30, 'day');
	const statDates = Array.from(statsPerDayMap.keys()).map(day => moment(day));
	const firstStatDate = moment.min(statDates);

	const firstChartDate = firstStatDate.isAfter(thirtyDaysAgo) ? firstStatDate : thirtyDaysAgo;

	const modifyChartOptions = options => {
		options.scales.x.time.unit = getTimeUnit(firstChartDate, today);
		options.scales.y.ticks.callback = label => `${Math.round(label * 100)}%`;
		options.plugins.legend.onClick = e => e.native.stopPropagation();
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			const dataSetLabel = item.label ? `${item.label}: ` : '';
			if (dataItem.customData && dataItem.customData.hideLabel) {
				return '';
			}
			return `${dataSetLabel}${Math.round(dataItem.y * 100)}%`;
		};
		return options;
	};

	const data = getChartData(performance, statsPerDayMap, firstChartDate, today);

	const dataSets = getChartDataSets(data);

	return (
		<ChartContainer
			onMouseEnter={() => {
				tracking.trackElementHovered('Performance modal chart');
				trackEvent('Performance Modal Chart', 'Hovered');
			}}
		>
			<Chart dataSets={dataSets} modifyOptions={modifyChartOptions} />
		</ChartContainer>
	);
};

export default createFragmentContainer(PerformanceModalChartSection, {
	projectStats: graphql`
		fragment PerformanceModalChartSection_projectStats on ProjectStats @relay(plural: true) {
			id
			date
			performance
			optimisticPerformance
			conservativePerformance
		}
	`,
});
