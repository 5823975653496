import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const ProjectHealthTableSectionWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	background-color: #f5f5f8;
	border-top: 1px solid ${CSS_CONSTANTS.app_border_color};
`;

export const BottomSpacer = styled.div`
	height: 34px;
`;

export const TableContainer = styled.div`
	padding: 0 32px;
`;

export const ColumnWrapper = styled.div`
	display: flex;
	font-weight: 400;
	padding: 0 16px;
	overflow: hidden;
	white-space: normal;
	color: ${props => props.disabled && CSS_CONSTANTS.v2_text_light_gray};
`;

export const DetailsWrapper = styled.div`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	max-height: 48px;
`;

export const ButtonColumnWrapper = styled(ColumnWrapper)`
	padding: 0 8px;
`;

export const TitleWrapper = styled.div`
	font-weight: 400;
	color: ${props => props.disabled && CSS_CONSTANTS.v2_text_light_gray};
`;

export const WarningIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 20px;
	margin-right: 10px;
	font-weight: 400;
`;
