import {DragHandleIcon} from 'web-components';
import React from 'react';
import Styled from 'styled-components/macro';
import InputFieldV2 from '../../../../components/new-ui/input_field';
import trashIcon from '../../../../images/v2/trash.svg';
import {Draggable} from 'react-beautiful-dnd';

const Item = Styled.div`
  background-color: 'none';
  left: 52px;
  display:flex;
  height: 40px;
  width: 350px;
  gap: 0px;
`;

const Title = Styled.div`
  width: 420px;
  height: 40px;
  overflow: hidden;
`;

const TrashIcon = Styled.div`
	width: 20px;
	height: 20px;
	background-image: url(${trashIcon});
	margin: 10px;
`;

const DragHandle = Styled.div`
	padding: 14px;
	width: 34px;
	align-self: center;
`;

const TrashButton = Styled.button`
	margin-left: 10
	padding: 0px;
	border: 0px;
`;

const OrderedItem = React.forwardRef((props, ref) => {
	const {item, index, onDescriptionChanged, onRemoveItem, disabled} = props;
	return (
		<Draggable isDragDisabled={disabled} draggableId={item.level + ''} index={index} ref={ref}>
			{provided => (
				<Item {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
					<DragHandle>
						<DragHandleIcon size={DragHandleIcon.SIZE.STANDARD} color="#4a4a4a" />
					</DragHandle>
					<Title>
						<InputFieldV2
							locked={disabled}
							value={item.description}
							hideLabel={true}
							type="text"
							onChange={value => onDescriptionChanged(index, value)}
						/>
					</Title>
					<TrashButton type="button" disabled={disabled} onClick={() => onRemoveItem(index)}>
						<TrashIcon />
					</TrashButton>
				</Item>
			)}
		</Draggable>
	);
});

export default OrderedItem;
