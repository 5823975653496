import React, {Component} from 'react';
import {injectIntl, FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import CheckBox from '../../components/new-ui/check_box';
import Person from '../../forecast-app/shared/components/person/person';
import UpdateRetainerPeriodMutation from './../../mutations/update_retainer_period_mutation';
import UpdateTimeRegistrationRetainerConflict from './../../mutations/update_time_registration_retainer_conflict_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {Scrollbars} from 'react-custom-scrollbars';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import {profilePicSrc} from '../../directApi';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import RadioButton from '../../components/new-ui/radio_button';
import {dispatch, EVENT_ID} from '../event_manager';

const RolloverTargets = {
	NEXT: 'next',
	SPLIT: 'split',
};

class RetainerTimeEntryConflictModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedTimeEntries: [...props.conflictedTimeEntries],
			rolloverTarget: RolloverTargets.NEXT,
		};
	}

	handleTimeEntryConflicts(splitRollover) {
		const {formatMessage} = this.props.intl;
		const period = this.props.period;
		const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');

		const onRetainerUpdateSuccess = () => {
			if (hasFinancialServiceFlag) {
				dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
			}
			createToast({
				duration: 5000,
				message: formatMessage({id: 'retainer.conflicts_for_period_resolved'}, {period: period.name}),
			});
		};

		// Periods in hours
		if (this.props.inHours) {
			const totalRollover = this.state.checkedTimeEntries.reduce(
				(acc, timeEntry) => acc + timeEntry.node.minutesRegistered / 60,
				0
			);
			const ignoredHours =
				totalRollover -
				this.props.conflictedTimeEntries.reduce((acc, timeEntry) => acc + timeEntry.node.minutesRegistered / 60, 0);
			const handledConflictIds = this.props.conflictedTimeEntries.map(timeEntry => timeEntry.node.id);
			const onSuccess = () => {
				const ignoredRolloverHours = period.ignoredRolloverHours
					? period.ignoredRolloverHours + ignoredHours
					: ignoredHours;
				Util.CommitMutation(
					UpdateRetainerPeriodMutation,
					{
						id: period.id,
						// Negated since conflict handling will always be subtraction, but timeEntries are positive. Value is needed for splitting
						periodDifferenceHoursAmount: -totalRollover,
						ignoredRolloverHours,
						splitRollover,
					},
					onRetainerUpdateSuccess
				);
			};
			Util.CommitMutation(
				UpdateTimeRegistrationRetainerConflict,
				{ids: handledConflictIds, retainerConflictHandled: true, overrideInvoiced: true},
				onSuccess
			);
		} else {
			const totalRollover = this.state.checkedTimeEntries.reduce((acc, timeEntry) => acc + timeEntry.node.price, 0);
			const ignoredPrice =
				totalRollover - this.props.conflictedTimeEntries.reduce((acc, timeEntry) => acc + timeEntry.node.price, 0);
			const handledConflictIds = this.props.conflictedTimeEntries.map(timeEntry => timeEntry.node.id);
			const onSuccess = () => {
				const ignoredRolloverPrice = period.ignoredRolloverPrice
					? period.ignoredRolloverPrice + ignoredPrice
					: ignoredPrice;
				Util.CommitMutation(
					UpdateRetainerPeriodMutation,
					{
						id: period.id,
						// Negated since conflict handling will always be subtraction, but timeEntries are positive. Value is needed for splitting
						periodDifferencePriceAmount: -totalRollover,
						ignoredRolloverPrice,
						splitRollover,
					},
					onRetainerUpdateSuccess
				);
			};
			Util.CommitMutation(
				UpdateTimeRegistrationRetainerConflict,
				{ids: handledConflictIds, retainerConflictHandled: true, overrideInvoiced: true},
				onSuccess
			);
		}
	}

	handleTimeRegCheckChange(timeReg) {
		const checkedEntries = this.state.checkedTimeEntries;
		if (checkedEntries.find(entry => entry.node.id === timeReg.node.id)) {
			checkedEntries.splice(
				checkedEntries.findIndex(entry => entry.node.id === timeReg.node.id),
				1
			);
		} else {
			checkedEntries.push(timeReg);
		}
		this.setState({checkedTimeEntries: checkedEntries});
	}

	changeRolloverTarget(rolloverTarget) {
		this.setState({rolloverTarget});
	}

	render() {
		const inHours = this.props.inHours;
		const {formatMessage, formatNumber} = this.props.intl;
		const thisPeriod = this.props.period;
		const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
		const periodSplit = hasRolloverFlag
			? this.state.rolloverTarget === RolloverTargets.SPLIT
			: thisPeriod.periodDifferenceSplit;

		const periods = this.props.viewer.project.retainerPeriods.edges;
		const periodEndDate = Util.CreateMomentDate(thisPeriod.endYear, thisPeriod.endMonth, thisPeriod.endDay);
		const unlockedFuturePeriods = periods.filter(filterPeriod => {
			const filterPeriodStartDate = Util.CreateMomentDate(
				filterPeriod.node.startYear,
				filterPeriod.node.startMonth,
				filterPeriod.node.startDay
			);
			return !filterPeriod.node.periodLocked && filterPeriodStartDate.isAfter(periodEndDate);
		});
		const unlockedFuturePeriodsAmount = unlockedFuturePeriods.length;

		const getProfilePicSrc = person => {
			return profilePicSrc(person.profilePictureId);
		};
		const getRegistrationObjectForTimeReg = timeReg => {
			return timeReg.node.task ? timeReg.node.task.name : formatMessage({id: 'common.project_time'});
		};
		const totalToSubtract = inHours
			? this.state.checkedTimeEntries.reduce((acc, timeReg) => acc + timeReg.node.minutesRegistered / 60, 0)
			: this.state.checkedTimeEntries.reduce((acc, timeReg) => acc + timeReg.node.price, 0);
		const totalWithSplit =
			periodSplit && unlockedFuturePeriodsAmount > 0 ? totalToSubtract / unlockedFuturePeriodsAmount : totalToSubtract;

		// targetPeriod is the period which the rolled over/subtracted value will be sent to.
		const targetPeriod = unlockedFuturePeriods.length > 0 ? unlockedFuturePeriods[0] : null;
		const from = targetPeriod
			? inHours
				? targetPeriod.node.periodHoursAmount +
				  targetPeriod.node.periodDifferenceHoursAmount +
				  targetPeriod.node.sharedPeriodDifferenceHoursAmount
				: targetPeriod.node.periodPriceAmount +
				  targetPeriod.node.periodDifferencePriceAmount +
				  targetPeriod.node.sharedPeriodDifferencePriceAmount
			: null;
		const to = targetPeriod ? from - totalWithSplit : null;
		const targetPeriodName = targetPeriod ? targetPeriod.node.name : null;
		const asFormattedCurrency = val =>
			Util.getFormattedCurrencyValue(
				Util.GetCurrencySymbol(this.props.currency),
				formatNumber(val, {format: 'always_two_decimal'})
			);

		const formattedTotalWithSplit = inHours
			? Util.convertMinutesToFullHour(totalWithSplit * 60, this.props.intl, true)
			: asFormattedCurrency(totalWithSplit);
		const formattedAdjustedTarget = inHours
			? Util.convertMinutesToFullHour(to * 60, this.props.intl, true)
			: asFormattedCurrency(to);
		const formattedTotalRollover = inHours
			? Util.convertMinutesToFullHour(totalToSubtract * 60, this.props.intl, true)
			: asFormattedCurrency(totalToSubtract);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						cy: 'cancel-button',
						text: formatMessage({
							id: 'common.cancel',
						}),
						color: BUTTON_COLOR.WHITE,
						callback: this.props.closeModal,
						style: BUTTON_STYLE.FILLED,
					},
					{
						cy: 'handle-conflicts-button',
						text: formatMessage({
							id: 'retainer.ok_handle_conflicts',
						}),
						color: BUTTON_COLOR.GREEN,
						callback: this.handleTimeEntryConflicts.bind(this, periodSplit),
						style: BUTTON_STYLE.FILLED,
					},
				]}
				content={
					<div className={'auto-scheduling-modal retainer-time-entry-conflict-modal'}>
						<div className={'header-container'}>
							<h1 className="header-text">
								{inHours
									? formatMessage({id: 'retainer.handle_conflicted_time'})
									: formatMessage({id: 'retainer.handle_conflicted_time'})}
							</h1>
						</div>
						{hasRolloverFlag ? (
							<div className={'warning-description-large'}>
								<FormattedMessage
									id={
										inHours
											? 'retainer.time_entry_conflicts_description_hours'
											: 'retainer.time_entry_conflicts_description_price'
									}
								/>
								<div className={'text-spacer'} />
							</div>
						) : (
							<div className={'warning-description-large'}>
								<FormattedHTMLMessage id="retainer.time_entry_conflicts_description" />
								<div className={'text-spacer'} />
								<TooltipContainer
									tooltipInfinteDuration={true}
									infoText={formatMessage({
										id: periodSplit
											? 'retainer.time_entry_conflicts_subtraction_tooltip_split'
											: 'retainer.time_entry_conflicts_subtraction_tooltip',
									})}
								>
									<FormattedHTMLMessage
										id={
											periodSplit
												? 'retainer.time_entry_conflicts_description2_split'
												: 'retainer.time_entry_conflicts_description2'
										}
									/>
								</TooltipContainer>
								{this.props.useTimeApproval ? (
									<>
										<div className={'text-spacer'} />
										<FormattedHTMLMessage id="retainer.time_entry_conflicts_approval" />
									</>
								) : null}
							</div>
						)}
						<div className={'time-entries'}>
							<div className={'time-entry header-row'}>
								<div className={'time-entry-details'}>
									<div className={'registration-object'}>{formatMessage({id: 'common.task'})}</div>
									<div className={'person'}>{formatMessage({id: 'common.person'})}</div>
									<div className={'date'}>{formatMessage({id: 'common.date'})}</div>
									<div className={'hours-registered'}>
										{inHours
											? formatMessage({id: 'common.time_entry'})
											: formatMessage({id: 'common.price'})}
									</div>
									<div className={'checkbox-wrapper'}>{formatMessage({id: 'retainer.subtract'})}</div>
								</div>
							</div>
							<Scrollbars hideTracksWhenNotNeeded={true} autoHeight={true} autoHeightMin={1} autoHeightMax={300}>
								{this.props.conflictedTimeEntries.map(timeEntry => (
									<div key={timeEntry.node.id} className={'time-entry'}>
										<div className={'time-entry-details rows'}>
											<div
												className={'time-entry-detail registration-object'}
												title={getRegistrationObjectForTimeReg(timeEntry)}
											>
												{getRegistrationObjectForTimeReg(timeEntry)}
											</div>
											<div className={'time-entry-detail person'}>
												<Person
													name={
														timeEntry.node.person.firstName + ' ' + timeEntry.node.person.lastName
													}
													showName={true}
													showRole={false}
													imageSrc={getProfilePicSrc(timeEntry.node.person)}
													imageSize="medium"
												/>
											</div>
											<div className={'time-entry-detail date'}>
												{Util.CreateMomentDate(
													timeEntry.node.year,
													timeEntry.node.month,
													timeEntry.node.day
												).format('DD MMM YYYY')}
											</div>
											{inHours ? (
												<div className={'time-entry-detail hours-registered'}>
													{Util.convertMinutesToFullHour(
														timeEntry.node.minutesRegistered,
														this.props.intl,
														true
													)}
												</div>
											) : (
												<div className={'time-entry-detail hours-registered'}>
													{Util.getFormattedCurrencyValue(
														Util.GetCurrencySymbol(this.props.currency),
														formatNumber(timeEntry.node.price, {format: 'always_two_decimal'})
													)}
												</div>
											)}
											<div className={'time-entry-detail checkbox-wrapper'}>
												<CheckBox
													isChecked={
														!!this.state.checkedTimeEntries.find(
															entry => entry.node.id === timeEntry.node.id
														)
													}
													onChange={this.handleTimeRegCheckChange.bind(this, timeEntry)}
												/>
											</div>
										</div>
									</div>
								))}
							</Scrollbars>
						</div>
						<div className={hasRolloverFlag ? 'summary-wrapper' : 'total-time-to-subtract-wrapper'}>
							{targetPeriod == null ? (
								<span className={'total-time-to-subtract'}></span>
							) : hasRolloverFlag ? (
								<>
									<FormattedMessage
										id={
											inHours
												? 'retainer.time_entry_conflicts_description_question_hours'
												: 'retainer.time_entry_conflicts_description_question_price'
										}
									/>
									<div className={'radio-row'}>
										<RadioButton
											cy={'conflict-rollover-next'}
											value={RolloverTargets.NEXT}
											stateValue={this.state.rolloverTarget}
											onSelected={this.changeRolloverTarget.bind(this, RolloverTargets.NEXT)}
										/>
										<FormattedMessage id="retainer.time_entry_conflicts_substract_next" />
									</div>
									<div className={'radio-row'}>
										<RadioButton
											cy={'conflict-rollover-split'}
											value={RolloverTargets.SPLIT}
											stateValue={this.state.rolloverTarget}
											onSelected={this.changeRolloverTarget.bind(this, RolloverTargets.SPLIT)}
										/>
										<FormattedMessage id="retainer.time_entry_conflicts_substract_split" />
									</div>

									<FormattedHTMLMessage
										id={
											this.state.rolloverTarget === RolloverTargets.NEXT
												? inHours
													? 'retainer.time_entry_conflicts_substract_next_summary_hours'
													: 'retainer.time_entry_conflicts_substract_next_summary_price'
												: inHours
												? 'retainer.time_entry_conflicts_substract_split_summary_hours'
												: 'retainer.time_entry_conflicts_substract_split_summary_price'
										}
										values={{
											periodName: targetPeriodName,
											totalRollover: formattedTotalRollover,
											adjustedTarget: formattedAdjustedTarget,
											splitRollover: formattedTotalWithSplit,
										}}
									/>

									{this.props.useTimeApproval ? (
										<p>
											<FormattedMessage id="retainer.time_entry_conflicts_time_approval_note" />
										</p>
									) : null}
								</>
							) : totalWithSplit > 0 ? (
								<div className={'total-time-texts'}>
									<span className={'total-time-to-subtract'}>
										{formatMessage(
											{
												id: inHours
													? periodSplit
														? 'retainer.total_time_to_subtract_split'
														: 'retainer.total_time_to_subtract'
													: periodSplit
													? 'retainer.total_price_to_subtract_split'
													: 'retainer.total_price_to_subtract',
											},
											{
												total: formattedTotalWithSplit,
											}
										)}
									</span>
									{periodSplit ? null : (
										<span className={'new-target-text'}>
											{formatMessage(
												{
													id: inHours
														? 'retainer.subtract_new_target_hours'
														: 'retainer.subtract_new_target_price',
												},
												{
													periodName: targetPeriodName,
													value: formattedAdjustedTarget,
												}
											)}
										</span>
									)}
								</div>
							) : (
								<span className={'total-time-to-subtract'}>
									{formatMessage(
										{id: 'retainer.subtract_from_period_nothing'},
										{periodName: targetPeriodName}
									)}
								</span>
							)}
						</div>
					</div>
				}
			/>
		);
	}
}
RetainerTimeEntryConflictModal.propTypes = {};
export default injectIntl(RetainerTimeEntryConflictModal);
