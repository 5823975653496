import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal from './generic_modal';
import InputField from '../../components/inputs/input_field';
import Warning from '../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

export const TypeConfirmModal = ({confirmText, onConfirm, closeModal, title, warningMessage, hideWarningTitle, labelText}) => {
	const {formatMessage} = useIntl();
	const [inputText, setInputText] = useState('');

	const handleInputChange = e => {
		setInputText(e);
	};

	const content = (
		<div className="default-warning-modal">
			{!hideWarningTitle ? <Warning messageId="warning-modal.edit-warning-title" /> : null}
			<div className="warning-part-2">{warningMessage}</div>

			<InputField
				cy="type-to-delete-input"
				value={inputText}
				onChange={handleInputChange}
				label={labelText ? labelText : formatMessage({id: 'settings.confirm_text'}, {confirmText})}
				placeholder={confirmText}
				type="text"
				autoFocus={true}
			/>
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			headerText={title}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: onConfirm,
					cy: 'type-to-confirm',
					disabled: confirmText !== inputText.trim(),
				},
			]}
			content={content}
		/>
	);
};
