import React from 'react';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {useIntl} from 'react-intl';
import GenericModal from './generic_modal';

const PersonDeactivateWarningModal = ({person, deactivateCallback, closeModal}) => {
	const {formatMessage} = useIntl();

	let header = person
		? formatMessage({id: 'deactivate_person_modal.single_header'}, {name: person.fullName})
		: formatMessage({id: 'deactivate_person_modal.multiple_header'});

	let message = person
		? formatMessage({id: 'deactivate_person_modal.single_message'}, {name: person.fullName})
		: formatMessage({id: 'deactivate_person_modal.multiple_message'});

	const content = (
		<div>
			<div className="warning-message-component">
				<div className={'message'}>{header}</div>
			</div>
			<div className="warning-part-2">{message}</div>
		</div>
	);

	const buttons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
			cy: 'task-modal-deactivate-person-modal-cancel',
		},
		{
			text: person
				? formatMessage({id: 'deactivate_person_modal.single_action'})
				: formatMessage({id: 'deactivate_person_modal.multiple_action'}),
			callback: deactivateCallback,
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			cy: 'task-modal-deactivate-person-modal-delete',
		},
	];

	return (
		<GenericModal
			top={true}
			className="default-warning-modal"
			cy="task-modal-delete-task-modal"
			buttons={buttons}
			content={content}
			closeModal={closeModal}
		/>
	);
};

export default PersonDeactivateWarningModal;
