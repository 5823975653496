/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type missionControlHeader_viewer$ref: FragmentReference;
declare export opaque type missionControlHeader_viewer$fragmentType: missionControlHeader_viewer$ref;
export type missionControlHeader_viewer = {|
  +email: ?string,
  +actualPersonId: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +company: ?{|
    +id: string,
    +name: ?string,
  |},
  +$refType: missionControlHeader_viewer$ref,
|};
export type missionControlHeader_viewer$data = missionControlHeader_viewer;
export type missionControlHeader_viewer$key = {
  +$data?: missionControlHeader_viewer$data,
  +$fragmentRefs: missionControlHeader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "missionControlHeader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '5a8babfac87e7540c29cbed1ade689fd';

module.exports = node;
