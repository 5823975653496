import React, {useRef} from 'react';
import {Route} from 'react-router-dom';
import {versionManager} from '../version_manager';

/**
 * Only reload if the route is an exact match. This is because that a route is
 * also triggered if it matches as part of a sub route. And since all pages can
 * have /T-:taskId appended to open the task modal, we need to be explicit in
 * what routes we trigger on.
 */
const isExactMatch = props => (props.computedMatch && props.computedMatch.isExact) || (props.match && props.match.isExact);

/**
 * When task modal is closed it triggers a route change on the underlying page,
 * but we are not interested in forcing a reload at that point. Therefore we
 * track the state of the route change, by detecting if the location has
 * "taskModalClosed" in it's state. This is being set in the task modals
 * "closeModal" function.
 */
const isNotNavigatingFromClosedTaskModal = props =>
	!(props.location && props.location.state && props.location.state.taskModalClosed);

/**
 * State changes in the app can cause the page to reload at inappropriate times,
 * so to minimize that, ensure that the route is always different when reloading.
 * We do that by tracking the route last being changed and comparing it with
 * the one being requested.
 */
const isDifferentRoute = (props, previousRouteRef) => {
	const differentRoute = props.path !== previousRouteRef.current;
	previousRouteRef.current = props.path;
	return differentRoute;
};

/**
 * Route wrapper that reloads the page, if version changes requires it.
 */
export const VersionReloadRoute = props => {
	const previousRouteRef = useRef(props.path);

	if (
		isExactMatch(props) &&
		isNotNavigatingFromClosedTaskModal(props) &&
		isDifferentRoute(props, previousRouteRef) &&
		versionManager.requiresReload()
	) {
		window.location.reload();
		return null;
	} else {
		return <Route {...props} />;
	}
};
