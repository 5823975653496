import Moment from 'moment';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {BasicNumberInput, BasicTextInput, Checkbox, CurrencyInput, InputLabel, PercentageInput} from 'web-components';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import ProjectDropdownRelay from '../../../forecast-app/shared/components/dropdowns/project-dropdown-b/Project_dropdown';
import FileDropZone from '../../../components/file_drop_zone';
import FilePreview from '../../../components/file_preview';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import RichTextField from '../../../components/new-ui/rich_text_field';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {
	BUDGET_TYPE,
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_PICKER_STYLE,
	HIDDEN_FEATURES,
	PERIOD_BUDGET_TYPE,
	PROJECT_STATUS,
} from '../../../constants';
import CreateExpenseItemMutation from '../../../mutations/create_expense_item_mutation';
import DeleteExpenseItemMutation from '../../../mutations/delete_expense_item_mutation';
import DuplicateExpenseItemMutation from '../../../mutations/duplicate_expense_item_mutation';
import UpdateExpenseItemMutation from '../../../mutations/update_expense_item_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import GenericModal from '../generic_modal';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {BillableSection} from './billable_section';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {ExpenseWarnings} from './ExpenseWarnings';

class expenseItemModal extends Component {
	constructor(props) {
		super(props);
		const viewer = this.props.viewer;
		const expenseItem = viewer.expenseItem
			? viewer.expenseItem
			: this.props.expenseToImport
			? this.props.expenseToImport
			: null;
		const initialProject = this.props.projectId
			? viewer.projects.edges.find(project => project.node.id === this.props.projectId)
			: null;

		let initialApprovedState = expenseItem
			? expenseItem.approved
			: hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			  ]);

		const initialBillableState = expenseItem ? expenseItem.billable : false;

		const initialQuantity = expenseItem ? expenseItem.quantity : 1;

		const initialPrice =
			initialProject && !initialProject.node.billable
				? null
				: expenseItem && expenseItem.price
				? expenseItem.price
				: null;

		const initialExpenseAmount =
			initialApprovedState && initialBillableState && !expenseItem.partOfFixedPrice
				? Math.round(initialPrice * initialQuantity * 100) / 100
				: 0;

		this.state = {
			name: expenseItem ? expenseItem.name : '',
			cost: expenseItem ? expenseItem.cost : 0,
			price: initialPrice,
			initialExpenseAmount: initialExpenseAmount,
			markupPercentage:
				expenseItem && expenseItem.cost ? ((expenseItem.price - expenseItem.cost) / expenseItem.cost) * 100 : null,
			date: expenseItem
				? Util.CreateNonUtcMomentDate(expenseItem.expenseYear, expenseItem.expenseMonth, expenseItem.expenseDay)
				: Moment(),
			notes: expenseItem ? expenseItem.notes : '',
			person: expenseItem ? (expenseItem.person ? expenseItem.person.id : null) : this.props.viewer.actualPersonId,
			project: this.props.projectId ? this.props.projectId : null,
			phase: expenseItem && expenseItem.phase ? expenseItem.phase.id : null,
			category: expenseItem && expenseItem.category && expenseItem.category.id,
			billable: initialBillableState,
			initialOnTopOfFixedPrice: expenseItem && initialBillableState && !expenseItem.partOfFixedPrice,
			partOfFixedPrice: expenseItem && hasFeatureFlag('Expense_Modal_Update_23Q1') ? expenseItem.partOfFixedPrice : false,
			planned: expenseItem ? expenseItem.planned : true,
			initialApprovedState: expenseItem ? expenseItem.approved : false,
			approved: initialApprovedState,
			quantity: initialQuantity,
			nonBillable: initialProject && !initialProject.node.billable,
			selectedTab: this.props.selectedTab || 'general',
			isUploading: false,
			enlargedFileSrc: null,
			files: [],
			filesPreview: [],
			loading: false,
			initialProject,
			nameError: null,
			hasLimitedAccessBecauseOfProgramRestrictions: null,
			budgetOverflowError: null,
			showLockedRevenueError: null,
			locked: this.props.locked,
			readOnly: this.props.readOnly,
			disableSave: false,
			projectBudgetType: initialProject && initialProject.node.budgetType,
			projectPeriodBudgetType: initialProject && initialProject.node.defaultPeriodBudgetType,
		};

		trackEvent('Expense Item Modal', 'Opened');
	}

	closeModal() {
		if (this.state.enlargedFileSrc !== null) {
			this.setState({enlargedFileSrc: null});
		} else {
			this.props.closeModal();
		}
	}

	getBillableOptionDescription(billable, partOfFixedPrice) {
		if (billable && partOfFixedPrice === true) {
			return 'Billable As Part Of Fixed Price';
		}

		if (billable && partOfFixedPrice === false) {
			return 'Billable On Top Of Fixed Price';
		}

		return 'Non Billable';
	}

	getMutationObject() {
		const dateObject = Util.GetYearMonthDateFromMomentDate(this.state.date);
		let companyProjectId = null;
		if (this.state.project) {
			const project = this.props.viewer.projects.edges.find(project => project.node.id === this.state.project);
			companyProjectId = parseInt(project.node.companyProjectId, 0);

			if (hasFeatureFlag('Expense_Modal_Update_23Q1')) {
				if (!ProjectUtil.isFixedPriceProject(project.node)) {
					this.setState({partOfFixedPrice: null});
				} else {
					trackEvent('Expense Item', this.props.viewer.expenseItem ? 'Updated' : 'Created', {
						billableOption: this.getBillableOptionDescription(this.state.billable, this.state.partOfFixedPrice),
					});
				}
			}
		}
		const mutationObject = {
			actualPersonId: this.props.viewer.actualPersonId,
			projectId: this.state.project,
			companyProjectId: companyProjectId,
			viewerId: this.props.viewer.id,
		};
		if (this.props.viewer.expenseItem) {
			const currentExpenseItem = this.props.viewer.expenseItem;
			mutationObject.id = this.props.viewer.expenseItem.id;
			if (this.state.name !== currentExpenseItem.name) {
				mutationObject.name = this.state.name;
			}
			if (this.state.category !== currentExpenseItem.category) {
				mutationObject.expenseCategoryId = this.state.category;
			}
			if (this.state.phase !== currentExpenseItem.phase) {
				mutationObject.phaseId = this.state.phase;
			}
			if (this.state.cost !== currentExpenseItem.cost) {
				mutationObject.cost = this.state.cost || 0;
			}
			if (this.state.price !== currentExpenseItem.price && this.state.billable) {
				mutationObject.price = this.state.price || 0;
			}
			if (this.state.billable !== currentExpenseItem.billable) {
				mutationObject.billable = this.state.billable;
			}
			if (this.state.partOfFixedPrice !== currentExpenseItem.partOfFixedPrice) {
				mutationObject.partOfFixedPrice = this.state.partOfFixedPrice;
			}
			if (this.state.planned !== currentExpenseItem.planned) {
				mutationObject.planned = this.state.planned;
			}
			if (this.state.approved !== currentExpenseItem.approved) {
				mutationObject.approved = this.state.approved;
			}
			if (this.state.quantity !== currentExpenseItem.quantity) {
				mutationObject.quantity = this.state.quantity || 1;
			}
			if (
				dateObject &&
				(dateObject.year !== currentExpenseItem.expenseYear ||
					dateObject.month !== currentExpenseItem.expenseMonth ||
					dateObject.day !== currentExpenseItem.expenseDay)
			) {
				mutationObject.expenseYear = dateObject.year;
				mutationObject.expenseMonth = dateObject.month;
				mutationObject.expenseDay = dateObject.day;
			}
			if (this.state.notes !== currentExpenseItem.notes) {
				mutationObject.notes = this.state.notes;
			}
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				if (
					(this.state.person && !currentExpenseItem.person) ||
					(!this.state.person && currentExpenseItem.person) ||
					(this.state.person && currentExpenseItem.person && this.state.person !== currentExpenseItem.person.id)
				) {
					mutationObject.personId = this.state.person;
				}
			} else if (this.state.person !== this.props.viewer.actualPersonId) {
				mutationObject.personId = this.props.viewer.actualPersonId;
			}
		} else {
			mutationObject.name = this.state.name;
			mutationObject.cost = this.state.cost ? this.state.cost : 0;
			const hasFinancialAccess = hasSomePermission([
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			]);
			mutationObject.price = !hasFinancialAccess
				? this.state.billable
					? mutationObject.cost
					: null
				: this.state.billable
				? !this.state.price || this.state.price === ''
					? 0
					: this.state.price
				: null;
			mutationObject.billable = this.state.billable;
			mutationObject.partOfFixedPrice = this.state.partOfFixedPrice;
			mutationObject.approved = this.state.approved;
			mutationObject.quantity =
				this.state.quantity === '' || this.state.quantity < 0 || this.state.quantity === null ? 1 : this.state.quantity;
			mutationObject.expenseYear = dateObject ? dateObject.year : null;
			mutationObject.expenseMonth = dateObject ? dateObject.month : null;
			mutationObject.expenseDay = dateObject ? dateObject.day : null;
			mutationObject.notes = this.state.notes;
			mutationObject.planned = this.state.planned;
			mutationObject.personId = hasSomePermission([
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			])
				? this.state.person
				: this.props.viewer.actualPersonId;
			mutationObject.expenseCategoryId = this.state.category;
			mutationObject.phaseId = this.state.phase;
			mutationObject.xeroPurchaseOrderId =
				this.props.expenseToImport && this.props.fromXero === true ? this.props.expenseToImport.purchaseOrderId : null;
			mutationObject.sageIntacctPurchaseOrderId =
				this.props.expenseToImport && this.props.fromSageIntacct === true
					? this.props.expenseToImport.purchaseOrderId
					: null;
		}
		if (
			mutationObject.personId !== undefined &&
			mutationObject.personId !== null &&
			mutationObject.personId === this.props.viewer.actualPersonId
		) {
			mutationObject.viewerId = this.props.viewer.id;
		}

		return mutationObject;
	}

	onSave() {
		if (typeof this.props.onSave === 'function') {
			this.props.onSave();
		}
	}

	callbackPositive() {
		this.setState({loading: true});

		const mutationObject = this.getMutationObject();

		const onSuccess = result => {
			if (!this.props.viewer.expenseItem) {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'expense_item_modal.expense_item_created'}),
				});
				trackEvent('Edit Expense Modal Add Button', 'Clicked', {});
			} else {
				trackEvent('Edit Expense Modal Save Button', 'Clicked', {});
			}
			if (this.props.handleNewExpense) {
				const expense = result.updateExpenseItem
					? result.updateExpenseItem.expenseItem
					: result.createExpenseItem.expenseItem.node;
				this.props.handleNewExpense(expense);
			}
			this.onSave();
			if (mutationObject.expenseYear && this.props.onDateChange && typeof this.props.onDateChange === 'function') {
				this.props.onDateChange();
			}
			if (this.state.files.length !== 0) {
				this.createFile(this.state.files, result.createExpenseItem.expenseItem.node.id);
			} else {
				this.closeModal();
			}
		};

		if (this.props.viewer.expenseItem && !this.props.expenseToImport) {
			Util.CommitMutation(UpdateExpenseItemMutation, mutationObject, onSuccess);
		} else {
			Util.CommitMutation(CreateExpenseItemMutation, mutationObject, onSuccess);
		}
	}

	handleNameChange(value) {
		this.setState({name: value});
		if (value.length > 191) {
			this.setState({nameError: this.props.intl.formatMessage({id: 'settings_finance.name_limit_191'})});
		} else {
			this.setState({nameError: null});
		}
	}

	handleBudgetOverflowError(value) {
		this.setState({budgetOverflowError: value});
	}

	handleShowLockedRevenueError(value) {
		this.setState({showLockedRevenueError: value});
	}

	setLocked(value) {
		this.setState({locked: value || this.props.locked});
	}

	setReadOnly(value) {
		this.setState({readOnly: value || this.props.readOnly});
	}

	setDisableSave(value) {
		this.setState({disableSave: value});
	}

	handleProjectChange(selected) {
		const project = this.props.viewer.projects.edges.find(project => project.node.id === selected[0]).node;
		const newState = {
			project: project.id,
			phase: null,
			projectBudgetType: project.budgetType,
			projectPeriodBudgetType: project.defaultPeriodBudgetType,
			person: this.props.viewer.actualPersonId,
		};

		if (ProjectUtil.projectTracksRevenue(project)) {
			newState.nonBillable = false;
		} else {
			newState.billable = false;
			newState.nonBillable = true;
			newState.price = null;
			newState.markupPercentage = null;
		}

		if (project.budgetType !== BUDGET_TYPE.RETAINER || project.defaultPeriodBudgetType !== PERIOD_BUDGET_TYPE.FIXED_PRICE) {
			newState.partOfFixedPrice = null;
		}

		if (
			this.state.billable &&
			newState.billable !== false &&
			((project.budgetType === BUDGET_TYPE.RETAINER &&
				project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) ||
				project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2)
		) {
			newState.planned = true;
			newState.partOfFixedPrice = true;
		}

		this.setState(newState);
	}

	handlePhaseChange(selected) {
		if (selected === null) {
			this.setState({phase: selected});
		} else {
			this.setState({phase: selected.value});
		}
	}

	handleCategoryChange(selected) {
		if (selected === null) {
			this.setState({category: selected});
		} else {
			this.setState({category: selected.value});
		}
	}

	handleDateRangeChange(date) {
		this.setState({date});
	}

	editCost(value) {
		if (this.state.markupPercentage) {
			const markupPrice = (this.state.markupPercentage * value) / 100;
			this.setState({
				cost: value,
				price: value !== 0 ? Math.round((value + markupPrice) * 100) / 100 : this.state.price,
				markupPercentage: value !== 0 ? this.state.markupPercentage : null,
			});
		} else {
			const newPrice = this.state.price || value;
			this.setState({
				cost: value,
				markupPercentage: value !== 0 ? ((newPrice - value) / value) * 100 : null,
				price: newPrice,
			});
		}
	}

	editPrice(value) {
		this.setState({price: value}, () => {
			if (this.state.price) {
				this.setState({markupPercentage: this.state.cost ? (this.state.price / this.state.cost - 1) * 100 : null});
			}
		});
	}

	editMarkupPercentage(value) {
		this.setState({markupPercentage: value * 100}, () => {
			if (this.state.cost) {
				const markupPrice = (this.state.markupPercentage * this.state.cost) / 100;
				this.setState({price: Math.round((this.state.cost + markupPrice) * 100) / 100});
			}
		});
	}

	editQuantity(value) {
		this.setState({quantity: value});
	}

	handlePersonChange(selected) {
		if (selected === null) {
			this.setState({person: selected});
		} else {
			this.setState({person: selected.value});
		}
	}

	handlePlannedRevenueChange(selected) {
		const isSelected = selected.target.checked;
		this.setState({planned: isSelected});
	}

	handleNotesChange(value) {
		this.setState({notes: value});
	}

	handleBillableChange(selected) {
		const isSelected = selected.target.checked;
		if (!isSelected) {
			this.setState({billable: false, price: null, markupPercentage: null});
		} else {
			this.setState({billable: true, markupPercentage: 0});
		}
		if (
			isSelected &&
			((this.state.projectBudgetType === BUDGET_TYPE.RETAINER &&
				this.state.projectPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) ||
				this.state.projectBudgetType === BUDGET_TYPE.FIXED_PRICE_V2)
		) {
			this.setState({planned: true});
		}
	}

	setBillable(isBillable) {
		this.setState({billable: isBillable});
	}

	setInitialApproved() {
		// Needed for the logic of checking the revenue setting (ALWAYS_DISABLED). If the expense has already been approved, this code
		// ensures that the expense can be disapproved and approved. Expenses that have not been apporved before locking revenue,
		// cannnot be approved.
		this.setState({
			approved: !!!this.props.viewer.expenseItem
				? false
				: !this.state.initialOnTopOfFixedPrice
				? false
				: this.state.initialApprovedState,
		});
	}

	handleApprovedChange(selected) {
		const isSelected = selected.target.checked;
		this.setState({approved: isSelected});
	}

	handlePartOfFixedPriceChange(selected) {
		const isSelected = selected.target.checked;
		this.setState({partOfFixedPrice: !isSelected});
	}

	setPartOfFixedPrice(isPartOfFixedPrice) {
		this.setState({partOfFixedPrice: isPartOfFixedPrice});
	}

	handleDelete() {
		const onSuccess = () => {
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'expense_item.has_been_deleted'})});
			this.closeModal();
		};
		const expenseProject = this.props.viewer.projects.edges.find(p => p.node.id === this.props.projectId);
		let companyProjectId = null;
		if (expenseProject) {
			companyProjectId = expenseProject.node.companyProjectId;
		}
		Util.CommitMutation(
			DeleteExpenseItemMutation,
			{
				id: this.props.viewer.expenseItem.id,
				projectId: this.props.projectId,
				companyProjectId: companyProjectId,
			},
			onSuccess
		);
	}

	handleDuplicate() {
		const onSuccess = () => {
			if (this.props.handleNewExpense) {
				this.props.handleNewExpense({approved: false});
			}
			this.onSave();
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'expense_item.has_been_duplicated'})});
			this.closeModal();
		};
		Util.CommitMutation(
			DuplicateExpenseItemMutation,
			{
				expenseItemId: this.props.viewer.expenseItem.id,
				companyId: this.props.viewer.company.id,
				projectId: this.props.projectId,
			},
			onSuccess
		);
	}

	handleSelectTab(selected) {
		this.setState({selectedTab: selected});
	}

	uploadFiles(files) {
		if (this.props.viewer.expenseItem) {
			this.createFile(files, null);
		} else {
			if (files.constructor === Array) {
				files.forEach(file => {
					this.addFile(file);
				});
			} else {
				this.addFile(files);
			}
		}
	}

	addFile(file) {
		const stateFiles = this.state.files;
		const filesPreview = this.state.filesPreview;
		stateFiles.push(file);
		const reader = new FileReader();
		reader.onload = e => {
			const filePreview = {
				node: {
					id: null,
					name: file.name,
					mimeType: file.type,
					src: e.target.result,
				},
			};
			filesPreview.push(filePreview);
			this.setState({files: stateFiles, filesPreview: filesPreview});
		};
		reader.readAsDataURL(file);
	}

	createFile(files, newExpenseItemId) {
		if (!files) return;

		this.setState({isUploading: true});
		const onSuccess = () => {
			this.setState({isUploading: false});
			if (newExpenseItemId !== null) {
				this.closeModal();
			}
		};

		const upload = [];

		if (Array.isArray(files)) {
			files.forEach(f =>
				upload.push({
					expenseItemId: newExpenseItemId ? newExpenseItemId : this.props.viewer.expenseItem.id,
					mimeType: f.type,
					name: f.name,
					file: f,
				})
			);
		} else {
			upload.push({
				expenseItemId: newExpenseItemId ? newExpenseItemId : this.props.viewer.expenseItem.id,
				mimeType: files.type,
				name: files.name,
				file: files,
			});
		}

		Util.uploadFiles(upload, onSuccess);
	}

	removeLocalFile(index) {
		const localFiles = this.state.filesPreview;
		localFiles.splice(index, 1);
		this.setState({filesPreview: localFiles});
	}

	renameLocalFile(index, name) {
		const localFiles = this.state.filesPreview;
		const file = localFiles[index];

		if (file) {
			file.name = name;
		}

		this.setState({filesPreview: localFiles});
	}

	clearDate() {
		this.setState({date: null});
	}
	render() {
		const {formatMessage} = this.props.intl;
		const selectedProject = this.state.project
			? this.props.viewer.projects.edges.find(project => project.node.id === this.state.project)
			: null;
		const phase_options = selectedProject
			? selectedProject.node.phases.edges.map(phase => ({
					value: phase.node.id,
					label: phase.node.name || formatMessage({id: 'common.untitled'}),
			  }))
			: [];
		const category_options = this.props.viewer.company.expenseCategories.edges
			.filter(
				category =>
					!category.node.disabled ||
					(this.props.viewer.expenseItem && this.props.viewer.expenseItem.category.id === category.node.id)
			)
			.map(category => ({
				value: category.node.id,
				label: category.node.name || formatMessage({id: 'common.untitled'}),
				disabled: category.node.disabled,
			}))
			.sort((a, b) => Util.sortAlphabetically(a.label, b.label));
		const isSelectedProjectHaltedOrDone = selectedProject
			? selectedProject.node.status === PROJECT_STATUS.HALTED || selectedProject.node.status === PROJECT_STATUS.DONE
			: null;

		const isSelectedProjectFixedPrice =
			(this.state.projectBudgetType === BUDGET_TYPE.RETAINER &&
				this.state.projectPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) ||
			this.state.projectBudgetType === BUDGET_TYPE.FIXED_PRICE_V2;

		const isSelectedProjectBillable = this.state.projectBudgetType !== BUDGET_TYPE.NON_BILLABLE;

		const currentUser = this.props.viewer.company.allPersons.edges.find(
			person => person.node.id === this.props.viewer.actualPersonId
		);
		const person_options = selectedProject
			? selectedProject.node.projectPersons.edges.map(pp => ({value: pp.node.person.id, label: pp.node.person.fullName}))
			: [];
		const person_options_values = person_options.map(option => option.value);
		if (!person_options_values.includes(currentUser.node.id)) {
			person_options.unshift({value: currentUser.node.id, label: currentUser.node.fullName});
		}

		if (this.props.viewer.expenseItem && this.props.viewer.expenseItem.person) {
			//if there is person assigned to expense item make sure it is included in person_options
			if (!person_options_values.includes(this.props.viewer.expenseItem.person.id)) {
				person_options.unshift({
					value: this.props.viewer.expenseItem.person.id,
					label: this.props.viewer.expenseItem.person.fullName,
				});
			}
		}
		const currency =
			selectedProject &&
			selectedProject.node.rateCard &&
			selectedProject.node.rateCard.currency !== this.props.viewer.company.currency
				? selectedProject.node.rateCard.currency
				: this.props.viewer.company.currency;

		const currencySymbol = Util.GetCurrencySymbol(currency);

		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);

		const disabledDates = [];
		if (!this.state.locked) {
			selectedProject?.node.fixedPriceLocks.edges
				.filter(period => period.locked)
				.forEach(period => {
					const periodStartDate = Moment(period.node.startDate);
					const periodEndDate = Moment(period.node.endDate);
					const days = Util.getMomentDaysBetweenDates(periodStartDate, periodEndDate);
					disabledDates.push(...days);
					if (this.state.date && this.state.date.isBetween(periodStartDate, periodEndDate)) {
						this.setState({date: null});
					}
				});
		}

		const content = (
			<div className="expense-item-modal-content">
				{this.state.loading ? <UploadingOverlay /> : null}
				<div className="tabs">
					<button
						className={'tab ' + (this.state.selectedTab === 'general' ? 'selected' : '')}
						onClick={this.handleSelectTab.bind(this, 'general')}
						title="General"
					>
						{formatMessage({id: 'common.about'})}
					</button>
					{!this.props.viewer.company.hideFiles ? (
						<button
							className={'tab ' + (this.state.selectedTab === 'files' ? 'selected' : '')}
							onClick={this.handleSelectTab.bind(this, 'files')}
							title="Files"
						>
							{formatMessage({id: 'common.files'}) +
								(this.props.viewer.expenseItem && this.props.viewer.expenseItem.files
									? ' (' + this.props.viewer.expenseItem.files.edges.length + ')'
									: '')}
						</button>
					) : null}
				</div>
				{this.state.selectedTab === 'general' ? (
					<div style={{display: 'flex', height: '50vh'}}>
						<CustomScrollDiv>
							<div className="body">
								<ExpenseWarnings
									actualPersonId={this.props.viewer.actualPersonId}
									companyProjectId={selectedProject?.node?.companyProjectId}
									approved={this.state.approved}
									billable={this.state.billable}
									isCreate={!!!this.props.viewer.expenseItem}
									expenseTotalAmount={this.state.price * this.state.quantity}
									initialExpenseAmount={this.state.initialExpenseAmount}
									budgetOverflowError={this.state.budgetOverflowError} // budgetOverflowError should not be required once this is refactored to be a functional components
									showLockedRevenueError={this.state.showLockedRevenueError}
									partOfFixedPrice={this.state.partOfFixedPrice}
									closeModal={this.closeModal.bind(this)}
								/>
								<div className="generic-input-wrapper">
									<div className={'expense-modal-row'}>
										<div className="input-control-v2 grow">
											<InputLabel
												text={formatMessage({id: 'expense_modal.expense_name'})}
												required
												extension={this.state.nameError}
												activeError={!!this.state.nameError}
												child={
													<BasicTextInput
														cy={'expense-name-input'}
														id={'expenseName'}
														type="text"
														disabled={
															isSelectedProjectHaltedOrDone ||
															this.state.locked ||
															this.state.readOnly
														}
														placeholder="Enter name"
														value={this.state.name}
														onChange={this.handleNameChange.bind(this)}
														shouldFocus={true}
														modalView={true}
													/>
												}
											/>
										</div>

										<div className="input-control-v2 date-picker">
											<InputLabel
												text={formatMessage({id: 'common.date'})}
												required
												activeError={false}
												child={
													<DatePicker
														buttonCy="expense-date-picker"
														startDate={this.state.date}
														handleDateRangeChange={this.handleDateRangeChange.bind(this)}
														datePickerStyle={DATE_PICKER_STYLE.STANDARD}
														isSingleDatePicker={true}
														disabled={
															(this.props.viewer.expenseItem && isSelectedProjectHaltedOrDone) ||
															this.state.locked ||
															this.state.readOnly
														}
														disabledDates={disabledDates}
													/>
												}
											/>
										</div>
									</div>

									<div className={'expense-modal-row'}>
										<div className="input-control-v2 selection-dropdown">
											<InputLabel
												text={formatMessage({id: 'insights.category'})}
												required
												activeError={false}
												child={
													<Dropdown
														multi={false}
														listDataCy={'expense-category-option'}
														inputCy={'expense-category-dropdown'}
														value={this.state.category}
														options={category_options}
														onChange={this.handleCategoryChange.bind(this)}
														required={true}
														disabled={
															(this.props.viewer.expenseItem && isSelectedProjectHaltedOrDone) ||
															this.state.locked ||
															this.state.readOnly
														}
														maxHeight={200}
														hideLabel
													/>
												}
											/>
										</div>

										<div className="input-control-v2 selection-dropdown">
											<InputLabel
												text={formatMessage({id: 'common.project'})}
												required
												activeError={false}
												child={
													<ProjectDropdownRelay
														hideEmptyGroups
														projects={this.props.viewer.projects.edges}
														optionsName={formatMessage({id: 'common.projects'})}
														selectedItems={this.state.project ? [this.state.project] : []}
														isMultiSelect={false}
														onSelect={this.handleProjectChange.bind(this)}
														maxHeight={170}
														disabled={
															(this.props.viewer.expenseItem && isSelectedProjectHaltedOrDone) ||
															this.state.locked ||
															this.state.readOnly
														}
														isClearable={false}
														size={'large'}
														groupByStatus
														showDone={false}
														showHalted={false}
													/>
												}
											/>
										</div>
									</div>

									<div className={'expense-modal-row'}>
										<div className="input-control-v2 selection-dropdown">
											<InputLabel
												text={formatMessage({id: 'common.person'})}
												activeError={false}
												child={
													<Dropdown
														listDataCy={'expense-person-option'}
														inputCy={'expense-person-dropdown'}
														disableRenderCalculations={true}
														multi={false}
														options={person_options}
														value={this.state.person}
														placeholder={formatMessage({id: 'common.select_person_placeholder'})}
														onChange={this.handlePersonChange.bind(this)}
														clearable={true}
														clearText={formatMessage({id: 'common.clear'})}
														disabled={
															!hasFinancialAccess ||
															isSelectedProjectHaltedOrDone ||
															this.state.locked ||
															this.state.readOnly
														}
														maxHeight={200}
														hideLabel
													/>
												}
											/>
										</div>
										<div className="input-control-v2 selection-dropdown">
											<InputLabel
												text={formatMessage({id: 'common.phase'})}
												activeError={false}
												child={
													<Dropdown
														placeholder={formatMessage({id: 'expense_modal.select_phase'})}
														multi={false}
														value={this.state.phase}
														options={phase_options}
														onChange={this.handlePhaseChange.bind(this)}
														clearable={true}
														clearText={formatMessage({id: 'common.clear'})}
														disabled={
															(this.props.viewer.expenseItem && isSelectedProjectHaltedOrDone) ||
															!selectedProject ||
															this.state.locked ||
															this.state.readOnly
														}
														maxHeight={200}
														hideLabel
													/>
												}
											/>
										</div>
									</div>
									<div className={'expense-modal-row'}>
										<div className="input-control-v2 grow">
											<InputLabel
												text={formatMessage({id: 'common.quantity'})}
												required
												extension={
													this.state.quantity <= 0
														? formatMessage({id: 'common.must_be_greater_than_zero'})
														: null
												}
												activeError={this.state.quantity <= 0}
												child={
													<BasicNumberInput
														cy={'quantity-input-modal'}
														disabled={
															isSelectedProjectHaltedOrDone ||
															this.state.locked ||
															this.state.readOnly
														}
														value={this.state.quantity}
														callback={this.editQuantity.bind(this)}
														modalView={true}
														alignLeft={true}
														converterValue={undefined}
													/>
												}
											/>
										</div>
										<div className="input-control-v2 grow">
											<InputLabel
												text={formatMessage({id: 'expense_item_modal.unit_cost'})}
												child={
													<CurrencyInput
														cy={'unit-cost-input-modal'}
														disabled={
															isSelectedProjectHaltedOrDone ||
															this.state.locked ||
															this.state.readOnly
														}
														currencySymbole={currencySymbol}
														value={Math.round(this.state.cost * 100) / 100}
														callback={this.editCost.bind(this)}
														modalView={true}
														alignLeft={true}
														intl={this.props.intl}
													/>
												}
											/>
										</div>
										<div className="input-control-v2 total grow">
											<InputLabel
												text={formatMessage({id: 'expense_item_modal.total_cost'})}
												alignRight
												child={
													<div className="total-amount" data-cy={'total-cost-input-modal'}>
														{Util.getFormattedCurrencyValue(
															currencySymbol,
															Math.round(this.state.cost * this.state.quantity * 100) / 100,
															false
														)}
													</div>
												}
											/>
										</div>
									</div>

									{hasFinancialAccess ? (
										hasFeatureFlag('Expense_Modal_Update_22Q3') ? (
											<BillableSection
												isCreate={!!!this.props.viewer.expenseItem}
												billable={this.state.billable}
												setBillable={this.setBillable.bind(this)}
												setInitialApproved={this.setInitialApproved.bind(this)}
												initialOnTopOfFixedPrice={this.state.initialOnTopOfFixedPrice}
												partOfFixedPrice={this.state.partOfFixedPrice}
												setPartOfFixedPrice={this.setPartOfFixedPrice.bind(this)}
												planned={this.state.planned}
												updatePlanned={this.handlePlannedRevenueChange.bind(this)}
												approved={this.state.approved}
												updateApproved={this.handleApprovedChange.bind(this)}
												markupPercentage={this.state.markupPercentage}
												updateMarkupPercentage={this.editMarkupPercentage.bind(this)}
												price={this.state.price}
												initialExpenseAmount={this.state.initialExpenseAmount}
												updatePrice={this.editPrice.bind(this)}
												quantity={this.state.quantity}
												updateQuantity={this.editQuantity.bind(this)}
												budgetOverflowError={this.state.budgetOverflowError}
												showLockedRevenueError={this.state.showLockedRevenueError}
												handleBudgetOverflowError={this.handleBudgetOverflowError.bind(this)}
												handleShowLockedRevenueError={this.handleShowLockedRevenueError.bind(this)}
												setLocked={this.setLocked.bind(this)}
												locked={this.state.locked}
												readOnly={this.state.readOnly}
												setDisableSave={this.setDisableSave.bind(this)}
												disableSave={this.state.disableSave}
												isSelectedProjectBillable={isSelectedProjectBillable}
												isSelectedProjectHaltedOrDone={isSelectedProjectHaltedOrDone}
												isSelectedProjectFixedPrice={isSelectedProjectFixedPrice}
												currency={currency}
												currencySymbol={currencySymbol}
												actualPersonId={this.props.viewer.actualPersonId}
												companyProjectId={selectedProject?.node?.companyProjectId}
												initialApprovedState={this.state.initialApprovedState}
											/>
										) : (
											<>
												<div className={'billable-section'}>
													<div className={'checkboxes-section'}>
														<div className={'checkbox-row'}>
															<Checkbox
																disabled={
																	this.state.nonBillable ||
																	isSelectedProjectHaltedOrDone ||
																	this.state.locked ||
																	Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE) ||
																	this.state.readOnly
																}
																checked={this.state.billable}
																onChange={this.handleBillableChange.bind(this)}
																cy={'expense-billable-checkbox'}
															/>
															<span
																className={
																	'checkbox-text ' +
																	(this.state.nonBillable ||
																	isSelectedProjectHaltedOrDone ||
																	this.state.locked ||
																	this.state.readOnly
																		? 'disabled'
																		: '')
																}
															>
																{formatMessage({id: 'expenses.billable'})}
															</span>
														</div>
														{this.state.billable &&
														this.state.projectBudgetType === BUDGET_TYPE.RETAINER &&
														this.state.projectPeriodBudgetType ===
															PERIOD_BUDGET_TYPE.FIXED_PRICE ? (
															<div className={'checkbox-row'}>
																<Checkbox
																	checked={this.state.partOfFixedPrice === false}
																	onChange={this.handlePartOfFixedPriceChange.bind(this)}
																/>
																<span>{formatMessage({id: 'expenses.add_fixed_price'})}</span>
															</div>
														) : null}
													</div>
													{this.state.billable ? (
														<div className={'expense-modal-row'}>
															<div className="input-control-v2 grow">
																<InputLabel
																	text={formatMessage({id: 'common.markup'})}
																	child={
																		<PercentageInput
																			cy={'markup-input-modal'}
																			disabled={
																				isSelectedProjectHaltedOrDone ||
																				this.state.locked ||
																				this.state.readOnly
																			}
																			placeholder={'-'}
																			value={
																				this.state.markupPercentage !== null
																					? Math.round(
																							this.state.markupPercentage * 100
																					  ) / 100
																					: null
																			}
																			callback={this.editMarkupPercentage.bind(this)}
																			modalView={true}
																			alignLeft={true}
																			showDecimals={true}
																		/>
																	}
																/>
															</div>
															<div className="input-control-v2 grow">
																<InputLabel
																	text={formatMessage({id: 'expense_item_modal.unit_price'})}
																	required
																	extension={
																		this.state.price !== null && this.state.price <= 0
																			? formatMessage({
																					id: 'common.must_be_greater_than_zero',
																			  })
																			: null
																	}
																	activeError={
																		this.state.price !== null && this.state.price <= 0
																	}
																	child={
																		<CurrencyInput
																			cy={'unit-price-input-modal'}
																			disabled={
																				isSelectedProjectHaltedOrDone ||
																				this.state.locked ||
																				this.state.readOnly
																			}
																			currencySymbole={currencySymbol}
																			placeholder="-"
																			value={
																				this.state.price !== null
																					? Math.round(this.state.price * 100) / 100
																					: null
																			}
																			callback={this.editPrice.bind(this)}
																			modalView={true}
																			alignLeft={true}
																			intl={this.props.intl}
																		/>
																	}
																/>
															</div>
															<div className="input-control-v2 grow total">
																<InputLabel
																	text={formatMessage({id: 'expense_item_modal.total_price'})}
																	alignRight
																	child={
																		<div
																			className="total-amount"
																			data-cy={'total-price-input-modal'}
																		>
																			{Util.getFormattedCurrencyValue(
																				currencySymbol,
																				Math.round(
																					this.state.price * this.state.quantity * 100
																				) / 100,
																				false
																			)}
																		</div>
																	}
																/>
															</div>
														</div>
													) : null}
												</div>
												<div className={'checkboxes-section'}>
													<div className={'checkbox-row'}>
														<Checkbox
															checked={this.state.planned}
															disabled={
																this.state.billable &&
																((this.state.projectBudgetType === BUDGET_TYPE.RETAINER &&
																	this.state.projectPeriodBudgetType ===
																		PERIOD_BUDGET_TYPE.FIXED_PRICE) ||
																	this.state.projectBudgetType === BUDGET_TYPE.FIXED_PRICE_V2)
															}
															onChange={this.handlePlannedRevenueChange.bind(this)}
															cy={'expense-planned-checkbox'}
														/>
														<span
															className={
																'checkbox-text ' +
																(this.state.billable &&
																this.state.projectBudgetType === BUDGET_TYPE.RETAINER &&
																this.state.projectPeriodBudgetType ===
																	PERIOD_BUDGET_TYPE.FIXED_PRICE
																	? 'disabled'
																	: '')
															}
														>
															{this.state.projectBudgetType === BUDGET_TYPE.FIXED_PRICE_V2
																? formatMessage({id: 'expense_modal.fixed_price'})
																: formatMessage({id: 'expense_modal.plan_budget'})}
														</span>
													</div>
													<div className={'checkbox-row'}>
														<Checkbox
															cy={'expense-modal-approved'}
															checked={this.state.approved}
															disabled={this.state.budgetOverflowError}
															onChange={this.handleApprovedChange.bind(this)}
														/>
														<span>{formatMessage({id: 'expenses.approved'})}</span>
													</div>
												</div>
											</>
										)
									) : (
										<>
											{hasFinancialAccess && this.state.billable ? (
												<div className={'expense-modal-row'}>
													<div className="input-control-v2 grow">
														<InputLabel
															text={formatMessage({id: 'common.markup'})}
															child={
																<PercentageInput
																	cy={'markup-input-modal'}
																	disabled={
																		isSelectedProjectHaltedOrDone ||
																		this.state.locked ||
																		this.state.readOnly
																	}
																	placeholder={'-'}
																	value={
																		this.state.markupPercentage !== null
																			? Math.round(this.state.markupPercentage * 100) /
																			  100
																			: null
																	}
																	callback={this.editMarkupPercentage.bind(this)}
																	modalView={true}
																	alignLeft={true}
																	showDecimals={true}
																/>
															}
														/>
													</div>
													<div className="input-control-v2 grow">
														<InputLabel
															text={formatMessage({id: 'expense_item_modal.unit_price'})}
															required
															extension={
																this.state.price !== null && this.state.price <= 0
																	? formatMessage({id: 'common.must_be_greater_than_zero'})
																	: null
															}
															activeError={this.state.price !== null && this.state.price <= 0}
															child={
																<CurrencyInput
																	cy={'unit-price-input-modal'}
																	disabled={
																		isSelectedProjectHaltedOrDone ||
																		this.state.locked ||
																		this.state.readOnly
																	}
																	currencySymbole={currencySymbol}
																	placeholder="-"
																	value={
																		this.state.price !== null
																			? Math.round(this.state.price * 100) / 100
																			: null
																	}
																	callback={this.editPrice.bind(this)}
																	modalView={true}
																	alignLeft={true}
																	intl={this.props.intl}
																/>
															}
														/>
													</div>
													<div className="input-control-v2 grow total">
														<InputLabel
															text={formatMessage({id: 'expense_item_modal.total_price'})}
															alignRight
															child={
																<div
																	className="total-amount"
																	data-cy={'total-price-input-modal'}
																>
																	{Util.getFormattedCurrencyValue(
																		currencySymbol,
																		Math.round(
																			(this.state.price * this.state.quantity * 100) / 100
																		),
																		false
																	)}
																</div>
															}
														/>
													</div>
												</div>
											) : null}
										</>
									)}

									<div className="expense-modal-row notes">
										<div className="input-control-v2 grow">
											<InputLabel
												text={formatMessage({id: 'common.notes'})}
												child={
													<RichTextField
														textCy={'notes-container-modal'}
														text={this.state.notes}
														placeholder={'Write notes here...'}
														alwaysShowControls={false}
														handleTextChange={this.handleNotesChange.bind(this)}
														characterLimit={this.props.viewer.company.characterLimit}
														readOnly={
															isSelectedProjectHaltedOrDone ||
															this.state.locked ||
															this.state.readOnly
														}
														ignoreComponentWillReceiveProps={true}
														showUploadFileIcon={false}
														gdriveEnabled={false}
													/>
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</CustomScrollDiv>
					</div>
				) : null}
				{this.state.selectedTab === 'files' ? (
					<div style={{display: 'flex', height: '50vh'}}>
						<CustomScrollDiv>
							<div className="body files-wrapper">
								{isSelectedProjectHaltedOrDone || this.state.locked || this.state.readOnly ? null : (
									<FileDropZone
										taskId={null}
										isExpenseItemDropZone={true}
										isUploading={this.state.isUploading}
										uploadFiles={this.uploadFiles.bind(this)}
										folderExceptionMsg={formatMessage({id: 'file_upload.folder-message'})}
										commonOkMsg={formatMessage({id: 'common.ok'})}
									/>
								)}
								<div className="files-preview-container">
									{this.state.filesPreview.map((file, index) => (
										<FilePreview
											key={index}
											file={file}
											taskId={null}
											isCover={false}
											setAsTaskCover={null}
											removeLocalFile={this.removeLocalFile.bind(this)}
											renameLocalFile={this.renameLocalFile.bind(this)}
											index={index}
											locked={isSelectedProjectHaltedOrDone || this.state.locked || this.state.readOnly}
											readOnly={this.state.readOnly}
										/>
									))}
									{this.props.viewer.expenseItem &&
										this.props.viewer.expenseItem.files &&
										this.props.viewer.expenseItem.files.edges.map((file, index) => (
											<FilePreview
												expenseItemId={this.props.viewer.expenseItem.id}
												key={index}
												file={file}
												files={this.props.viewer.expenseItem.files.edges}
												taskId={null}
												isCover={false}
												setAsTaskCover={null}
												locked={
													isSelectedProjectHaltedOrDone || this.state.locked || this.state.readOnly
												}
												readOnly={this.state.readOnly}
											/>
										))}
								</div>
							</div>
						</CustomScrollDiv>
					</div>
				) : null}
			</div>
		);

		return (
			<GenericModal
				wrapperClassName={'expense-item-modal'}
				closeModal={this.closeModal.bind(this)}
				buttons={
					this.state.locked || this.state.readOnly
						? null
						: [
								{
									text:
										this.props.viewer.expenseItem && isSelectedProjectHaltedOrDone
											? formatMessage({id: 'common.ok'})
											: formatMessage({id: 'common.cancel'}),
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.WHITE,
									cy: 'cancel-button',
								},
								{
									text: formatMessage({id: this.props.viewer.expenseItem ? 'common.save' : 'common.add'}),
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.GREEN,
									callback: this.callbackPositive.bind(this),
									disabled:
										this.state.disableSave ||
										!this.state.project ||
										!this.state.date ||
										!this.state.name ||
										!this.state.category ||
										this.state.nameError ||
										this.state.budgetOverflowError ||
										(this.state.showLockedRevenueError && this.state.billable) ||
										!(this.state.quantity > 0) ||
										(this.state.billable && !(this.state.price > 0)),
									cy: 'save-expense-button',
								},
						  ]
				}
				content={content}
				headerText={formatMessage({
					id: this.props.viewer.expenseItem
						? isSelectedProjectHaltedOrDone || this.state.locked || this.state.readOnly
							? 'header.expense-item'
							: 'expense_item_modal.edit_title'
						: 'expense_item_modal.add_title',
				})}
				actionsMenuOptions={
					this.props.viewer.expenseItem && !this.state.readOnly
						? [
								{
									cy: 'action-option-duplicate',
									text: formatMessage({id: 'common.duplicate'}),
									onClick: this.handleDuplicate.bind(this),
									locked: this.props.viewer.company.expenseCategories.edges.some(
										category => category.node.id === this.state.category && category.node.disabled
									),
								},
								...(!(isSelectedProjectHaltedOrDone || this.state.locked)
									? [
											{
												cy: 'action-option-delete',
												text: formatMessage({id: 'common.delete'}),
												onClick: this.handleDelete.bind(this),
											},
									  ]
									: []),
						  ]
						: undefined
				}
			/>
		);
	}
}

const expenseItemModalQuery = graphql`
	query expenseItemModal_Query($expenseItemId: ID) {
		viewer {
			actualPersonId
			component(name: "expense_item_modal")
			...expenseItemModal_viewer @arguments(expenseItemId: $expenseItemId)
		}
	}
`;

export {expenseItemModalQuery};

export default injectIntl(
	createFragmentContainer(expenseItemModal, {
		viewer: graphql`
			fragment expenseItemModal_viewer on Viewer @argumentDefinitions(expenseItemId: {type: "ID"}) {
				id
				actualPersonId
				language
				availableFeatureFlags {
					key
				}
				company {
					id
					currency
					characterLimit
					hideFiles
					expenseCategories(first: 10000) {
						edges {
							node {
								id
								name
								disabled
							}
						}
					}
					defaultExpenseCategory {
						id
					}
					allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
						edges {
							node {
								id
								fullName
							}
						}
					}
				}
				expenseItem(expenseItemId: $expenseItemId) {
					id
					name
					billable
					partOfFixedPrice
					cost
					price
					quantity
					approved
					expenseYear
					expenseMonth
					expenseDay
					notes
					planned
					category {
						id
					}
					person {
						id
						fullName
						profilePictureId
						profilePictureDefaultId
					}
					phase {
						id
					}
					files(first: 1000) @connection(key: "ExpenseItem_files") {
						edges {
							node {
								id
								name
								mimeType
								key
								yearCreated
								monthCreated
								dayCreated
								hourCreated
								minuteCreated
								secondCreated
								person {
									id
									profilePictureId
									profilePictureDefaultId
									firstName
									lastName
									role {
										id
										name
									}
								}
							}
						}
					}
				}
				projects(excludeDoneOrHalted: true, excludeReadOnly: true, first: 100000) {
					edges {
						...ProjectDropdown_projects
						node {
							id
							companyProjectId
							name
							billable
							status
							budgetType
							defaultPeriodBudgetType
							projectColor
							projectPersons(first: 1000) {
								edges {
									node {
										id
										person {
											id
											fullName
										}
									}
								}
							}
							rateCard {
								id
								currency
							}
							phases(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
							fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
								edges {
									node {
										id
										startDate
										endDate
										locked
									}
								}
							}
						}
					}
				}
			}
		`,
	})
);
