/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AcceptTerms_viewer$ref = any;
export type AcceptTerms_QueryVariables = {||};
export type AcceptTerms_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: AcceptTerms_viewer$ref,
  |}
|};
export type AcceptTerms_Query = {|
  variables: AcceptTerms_QueryVariables,
  response: AcceptTerms_QueryResponse,
|};
*/


/*
query AcceptTerms_Query {
  viewer {
    actualPersonId
    component(name: "accept_terms")
    ...AcceptTerms_viewer
    id
  }
}

fragment AcceptTerms_viewer on Viewer {
  id
  actualPersonId
  hasAcceptedTerms
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "accept_terms"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"accept_terms\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptTerms_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AcceptTerms_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AcceptTerms_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAcceptedTerms",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AcceptTerms_Query",
    "operationKind": "query",
    "text": "query AcceptTerms_Query {\n  viewer {\n    actualPersonId\n    component(name: \"accept_terms\")\n    ...AcceptTerms_viewer\n    id\n  }\n}\n\nfragment AcceptTerms_viewer on Viewer {\n  id\n  actualPersonId\n  hasAcceptedTerms\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f31ecd43760cf011b537f7427fe7791';

module.exports = node;
