import { Checkbox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, Label, SubHeading, Text, } from '@forecast-it/design-system';
import React, { useEffect, useState } from 'react';
import Util from '../../../forecast-app/shared/util/util';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import DirectApi from '../../../directApi';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import ImportSageIntacctDepartmentsMutation from '../../../mutations/ImportSageIntacctDepartmentsMutation';
import { hideLoader, showLoader } from '../../global_loader';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { HorizontalBar } from './SageIntacctModal.styled';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 800 ? '200px' : '500px';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '1px solid #ccc' : 'none')};
`;
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const SageIntacctImportDepartmentsModal = ({ closeModal, companyId }) => {
    const { formatMessage } = useIntl();
    const [departments, setDepartments] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [fetchingDepartments, setFetchingDepartments] = useState(true);
    const [failed, setFailed] = useState(false);
    const [importing, setImporting] = useState(false);
    const disableImportButton = importing || selectedDepartments.length === 0;
    useEffect(() => {
        DirectApi.Fetch('sage_intacct/departments').then(res => {
            if (res === null || res === void 0 ? void 0 : res.departments) {
                setDepartments(res === null || res === void 0 ? void 0 : res.departments);
                setFetchingDepartments(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetchingDepartments(false);
            }
            else {
                setDepartments([]);
                setFetchingDepartments(false);
            }
        });
    }, []);
    const selectAllClick = () => {
        if (selectAll) {
            setSelectedDepartments([]);
            setSelectAll(false);
        }
        else {
            const selectedDeps = departments.map(d => d.DEPARTMENTID);
            setSelectedDepartments(selectedDeps);
            setSelectAll(true);
        }
    };
    const departmentClick = (inputId) => {
        setSelectAll('indeterminate');
        if (selectedDepartments.includes(inputId)) {
            const selectedDeps = selectedDepartments.filter(id => id !== inputId);
            if (selectedDeps.length === 0) {
                setSelectAll(false);
            }
            setSelectedDepartments(selectedDeps);
        }
        else {
            const selectedDeps = [...selectedDepartments];
            selectedDeps.push(inputId);
            if (selectedDeps.length === departments.length) {
                setSelectAll(true);
            }
            setSelectedDepartments(selectedDeps);
        }
    };
    const onImportClick = () => {
        setImporting(true);
        showLoader();
        Util.CommitMutation(ImportSageIntacctDepartmentsMutation, {
            sageIntacctDepartmentIds: selectedDepartments,
            companyId: companyId,
        }, () => {
            setImporting(false);
            closeModal();
            hideLoader();
            createToast({
                duration: 5000,
                message: `${selectedDepartments.length} Departments imported from Sage Intacct`,
            });
        });
    };
    return (React.createElement(CreativeModal, { title: 'Import Departments from Sage Intacct', size: 'l', closeModal: closeModal, creativeButtonText: `Import Selected Departments (${selectedDepartments.length})`, cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onImportClick, onCancelHandler: closeModal, disabled: disableImportButton, replicateDesignSystem: true },
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Label, null, "Departments"),
            React.createElement(Text, { color: Text.color.medium }, "Please select the departments you wish to import from Sage Intacct")),
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: selectAllClick, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null, fetchingDepartments ? (React.createElement(InlineLoader, null)) : (React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
            React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, departments &&
                departments.map((dep) => {
                    return (React.createElement(ListItem, { key: 'department-' + dep.DEPARTMENTID, hasBorderBottom: true },
                        React.createElement(FlexRow, { gap: 'xl' },
                            React.createElement(Checkbox, { onChange: () => departmentClick(dep.DEPARTMENTID), checked: selectedDepartments.includes(dep.DEPARTMENTID) }),
                            React.createElement(Text, { size: '2' }, dep.NAME))));
                }))))),
        React.createElement(HorizontalBar, null)));
};
export default SageIntacctImportDepartmentsModal;
