import Util from '../../../../forecast-app/shared/util/util';
import UpdateInvoiceMutation from '../../../../mutations/update_invoice_mutation';
import * as tracking from '../../../../tracking';
import {trackCSVExport} from '../../../../tracking/amplitude/TrackingV2';
import {useDataExport} from '../../../../forecast-app/shared/hooks/useDataExport';
import InvoiceFormatter from '../../../../forecast-app/shared/util/export-formatters/InvoiceFormatter';

export const changeStatus = invoiceId => selectedValue => {
	if (selectedValue && selectedValue.length === 1) {
		Util.CommitMutation(UpdateInvoiceMutation, {
			id: invoiceId,
			status: selectedValue[0],
		});
	}
};

export const handleCsvDownloadPress = (projectId, invoicesToExport, tableColumns, intl) => {
	tracking.trackEvent('download invoices');
	trackCSVExport('Invoices');

	const visibleColumns = Object.keys(tableColumns)
		.filter(columnName => tableColumns[columnName] && columnName !== 'hasContextMenu')
		.map(name => {
			return {
				name: name,
				checked: true,
			};
		});
	visibleColumns.push({name: 'currency', checked: true});

	const exportFunction = useDataExport(
		new InvoiceFormatter(intl, [], [], true),
		null,
		data => data,
		_ => `invoices_project${projectId}`
	);
	exportFunction(visibleColumns, invoicesToExport, null, null, 'CSV');
};

export const preProcessInvoiceData = (invoice, project) => {
	const {
		xeroId,
		economicId,
		quickbooksId,
		sageIntacctId,
		id,
		status,
		externalStatus,
		paymentStatus,
		createdYear,
		createdMonth,
		createdDay,
		dueYear,
		dueMonth,
		dueDay,
		name,
		currency,
		companyInvoiceId,
		invoicedWithoutTax,
		paidWithoutTax,
		client,
		invoiceReference,
	} = invoice;

	return {
		name,
		id,
		currency,
		companyInvoiceId,
		invoicedWithoutTax,
		paidWithoutTax,
		client,
		invoiceDate: Util.CreateMomentDate(createdYear, createdMonth, createdDay),
		dueDate: Util.CreateMomentDate(dueYear, dueMonth, dueDay),
		projects: [project],
		status,
		externalStatus,
		paymentStatus,
		isExported: xeroId || economicId || quickbooksId || sageIntacctId,
		xeroId,
		economicId,
		quickbooksId,
		sageIntacctId,
		invoiceReference,
	};
};
