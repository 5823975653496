import { graphql } from 'react-relay';
const exportInvoiceModalQuery = graphql `
	query ExportInvoiceModal_Query($invoiceId: ID, $billToId: ID) {
		viewer {
			actualPersonId
			component(name: "export_invoice_modal")
			...ExportInvoiceModal_Viewer @arguments(invoiceId: $invoiceId, billToId: $billToId)
		}
	}
`;
export { exportInvoiceModalQuery };
export const exportInvoiceModalFragment = {
    viewer: graphql `
		fragment ExportInvoiceModal_Viewer on Viewer @argumentDefinitions(invoiceId: {type: "ID"}, billToId: {type: "ID"}) {
			id
			backendId
			billTo(id: $billToId) {
				id
				name
				externalId
				billFrom {
					id
					name
					integration
					externalId
				}
			}
			invoice(id: $invoiceId) {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				economicId
				client {
					id
					name
				}
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quantityLockedReason
							project {
								id
								customProjectId
								companyProjectId
								name
								projectColor
								isInProjectGroup
								budgetType
								client {
									name
								}
								rateCard {
									currency
								}
							}
						}
					}
				}
			}
		}
	`,
};
