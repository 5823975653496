/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PerformanceModal_viewer$ref = any;
export type PerformanceModal_QueryVariables = {|
  projectId?: ?string
|};
export type PerformanceModal_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: PerformanceModal_viewer$ref,
  |}
|};
export type PerformanceModal_Query = {|
  variables: PerformanceModal_QueryVariables,
  response: PerformanceModal_QueryResponse,
|};
*/


/*
query PerformanceModal_Query(
  $projectId: ID
) {
  viewer {
    component(name: "performance_modal")
    actualPersonId
    id
    ...PerformanceModal_viewer_2CgS8h
  }
}

fragment PerformanceModalChartSection_projectStats on ProjectStats {
  id
  date
  performance
  optimisticPerformance
  conservativePerformance
}

fragment PerformanceModal_viewer_2CgS8h on Viewer {
  project(internalId: $projectId) {
    id
    name
    companyProjectId
    estimationUnit
    projectHealth {
      id
      performanceTotal
    }
    projectStats {
      id
      ...PerformanceModalChartSection_projectStats
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "performance_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"performance_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PerformanceModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformanceModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimationUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectHealth",
                "kind": "LinkedField",
                "name": "projectHealth",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performanceTotal",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectStats",
                "kind": "LinkedField",
                "name": "projectStats",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optimisticPerformance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "conservativePerformance",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PerformanceModal_Query",
    "operationKind": "query",
    "text": "query PerformanceModal_Query(\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"performance_modal\")\n    actualPersonId\n    id\n    ...PerformanceModal_viewer_2CgS8h\n  }\n}\n\nfragment PerformanceModalChartSection_projectStats on ProjectStats {\n  id\n  date\n  performance\n  optimisticPerformance\n  conservativePerformance\n}\n\nfragment PerformanceModal_viewer_2CgS8h on Viewer {\n  project(internalId: $projectId) {\n    id\n    name\n    companyProjectId\n    estimationUnit\n    projectHealth {\n      id\n      performanceTotal\n    }\n    projectStats {\n      id\n      ...PerformanceModalChartSection_projectStats\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c0121aea6a589eb3160251a76a4d407';

module.exports = node;
