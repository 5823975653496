/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConvertOldSubtasksModal_viewer$ref = any;
export type ConvertOldSubtasksModalQueryVariables = {|
  projectId?: ?string
|};
export type ConvertOldSubtasksModalQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ConvertOldSubtasksModal_viewer$ref,
  |}
|};
export type ConvertOldSubtasksModalQuery = {|
  variables: ConvertOldSubtasksModalQueryVariables,
  response: ConvertOldSubtasksModalQueryResponse,
|};
*/


/*
query ConvertOldSubtasksModalQuery(
  $projectId: ID
) {
  viewer {
    id
    actualPersonId
    component(name: "convert_old_subtasks_modal")
    ...ConvertOldSubtasksModal_viewer_3VHfkf
  }
}

fragment ConvertOldSubtasksModal_viewer_3VHfkf on Viewer {
  id
  project(internalId: $projectId) {
    remainingAutoCalculated
    minutesPerEstimationPoint
    estimationUnit
    remaining
    forecastTotal
    tasks {
      edges {
        node {
          id
          timeLeft
          estimateForecast
          done
          totalMinutesRegistered
          subTasks {
            edges {
              node {
                id
                estimate
                done
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "convert_old_subtasks_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"convert_old_subtasks_modal\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "done",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvertOldSubtasksModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ConvertOldSubtasksModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConvertOldSubtasksModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remainingAutoCalculated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minutesPerEstimationPoint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimationUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remaining",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forecastTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timeLeft",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "estimateForecast",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalMinutesRegistered",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SubTaskTypeConnection",
                            "kind": "LinkedField",
                            "name": "subTasks",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SubTaskTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SubTask",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "estimate",
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ConvertOldSubtasksModalQuery",
    "operationKind": "query",
    "text": "query ConvertOldSubtasksModalQuery(\n  $projectId: ID\n) {\n  viewer {\n    id\n    actualPersonId\n    component(name: \"convert_old_subtasks_modal\")\n    ...ConvertOldSubtasksModal_viewer_3VHfkf\n  }\n}\n\nfragment ConvertOldSubtasksModal_viewer_3VHfkf on Viewer {\n  id\n  project(internalId: $projectId) {\n    remainingAutoCalculated\n    minutesPerEstimationPoint\n    estimationUnit\n    remaining\n    forecastTotal\n    tasks {\n      edges {\n        node {\n          id\n          timeLeft\n          estimateForecast\n          done\n          totalMinutesRegistered\n          subTasks {\n            edges {\n              node {\n                id\n                estimate\n                done\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '20a77c11d5251c97ee5796feb602ee1a';

module.exports = node;
