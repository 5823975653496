/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type taskList_viewer$ref = any;
export type taskList_QueryVariables = {|
  fetchAssignee: boolean,
  fetchHighLow: boolean,
  fetchRoles: boolean,
  fetchLabels: boolean,
  taskIds: $ReadOnlyArray<?string>,
|};
export type taskList_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: taskList_viewer$ref,
  |}
|};
export type taskList_Query = {|
  variables: taskList_QueryVariables,
  response: taskList_QueryResponse,
|};
*/


/*
query taskList_Query(
  $fetchAssignee: Boolean!
  $fetchHighLow: Boolean!
  $fetchRoles: Boolean!
  $fetchLabels: Boolean!
  $taskIds: [ID]!
) {
  viewer {
    actualPersonId
    component(name: "task_list")
    ...taskList_viewer_1FRc8M
    id
  }
}

fragment taskList_viewer_1FRc8M on Viewer {
  availableFeatureFlags {
    key
    id
  }
  tasks(taskIds: $taskIds, first: 10000) {
    edges {
      node {
        id
        highLowSuggestion @include(if: $fetchHighLow) {
          high
          low
          estimate
        }
        roleSuggestion @include(if: $fetchRoles)
        assigneeSuggestion @include(if: $fetchAssignee)
        labelSuggestion @include(if: $fetchLabels)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchAssignee",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchHighLow",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchRoles",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchLabels",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskIds",
    "type": "[ID]!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "task_list"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"task_list\")"
},
v3 = {
  "kind": "Variable",
  "name": "taskIds",
  "variableName": "taskIds"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "fetchAssignee",
                "variableName": "fetchAssignee"
              },
              {
                "kind": "Variable",
                "name": "fetchHighLow",
                "variableName": "fetchHighLow"
              },
              {
                "kind": "Variable",
                "name": "fetchLabels",
                "variableName": "fetchLabels"
              },
              {
                "kind": "Variable",
                "name": "fetchRoles",
                "variableName": "fetchRoles"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "taskList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000
              },
              (v3/*: any*/)
            ],
            "concreteType": "TaskTypeConnection",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "condition": "fetchHighLow",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HighLowSuggestions",
                            "kind": "LinkedField",
                            "name": "highLowSuggestion",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "high",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "low",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "estimate",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "condition": "fetchRoles",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roleSuggestion",
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "condition": "fetchAssignee",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assigneeSuggestion",
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "condition": "fetchLabels",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "labelSuggestion",
                            "storageKey": null
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "taskList_Query",
    "operationKind": "query",
    "text": "query taskList_Query(\n  $fetchAssignee: Boolean!\n  $fetchHighLow: Boolean!\n  $fetchRoles: Boolean!\n  $fetchLabels: Boolean!\n  $taskIds: [ID]!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"task_list\")\n    ...taskList_viewer_1FRc8M\n    id\n  }\n}\n\nfragment taskList_viewer_1FRc8M on Viewer {\n  availableFeatureFlags {\n    key\n    id\n  }\n  tasks(taskIds: $taskIds, first: 10000) {\n    edges {\n      node {\n        id\n        highLowSuggestion @include(if: $fetchHighLow) {\n          high\n          low\n          estimate\n        }\n        roleSuggestion @include(if: $fetchRoles)\n        assigneeSuggestion @include(if: $fetchAssignee)\n        labelSuggestion @include(if: $fetchLabels)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7095a4f270e143cf655e04e725feedb6';

module.exports = node;
