/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type importSageIntacctExpenseItemModal_viewer$ref = any;
export type importSageIntacctExpenseItemModal_QueryVariables = {||};
export type importSageIntacctExpenseItemModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: importSageIntacctExpenseItemModal_viewer$ref,
  |}
|};
export type importSageIntacctExpenseItemModal_Query = {|
  variables: importSageIntacctExpenseItemModal_QueryVariables,
  response: importSageIntacctExpenseItemModal_QueryResponse,
|};
*/


/*
query importSageIntacctExpenseItemModal_Query {
  viewer {
    actualPersonId
    component(name: "import_expense_item_modal")
    ...importSageIntacctExpenseItemModal_viewer
    id
  }
}

fragment importSageIntacctExpenseItemModal_viewer on Viewer {
  id
  actualPersonId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "import_expense_item_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"import_expense_item_modal\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "importSageIntacctExpenseItemModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "importSageIntacctExpenseItemModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "importSageIntacctExpenseItemModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "importSageIntacctExpenseItemModal_Query",
    "operationKind": "query",
    "text": "query importSageIntacctExpenseItemModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"import_expense_item_modal\")\n    ...importSageIntacctExpenseItemModal_viewer\n    id\n  }\n}\n\nfragment importSageIntacctExpenseItemModal_viewer on Viewer {\n  id\n  actualPersonId\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aceb7540ee5500ab1ea9613da5ce3def';

module.exports = node;
