import React, {useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import styled from 'styled-components';
import {sortBy} from 'lodash';
import MissionControlHeader from '../mission_control_header';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import Util from '../../../forecast-app/shared/util/util';
import updateCompanyInternalDataMutation from '../../../mutations/mission_control/update_company_internal_data_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';

const TrialsListStyle = styled.div`
	max-height: 100vh;
	overflow: hidden;
`;

const SignUpButtonStyle = styled.button`
	white-space: nowrap;
`;

const TableHeader = styled.thead`
	th {
		background-color: white;
		position: sticky;
		top: 0;
	}
`;

const TableBody = styled.tbody``;

const SortableTableHeaderCellStyle = styled.th`
	text-decoration: underline;
	width: 1%;
	white-space: nowrap;
`;

const SortableTableHeaderCell = ({label, property, sortValue, onClick}) => {
	const sortDirection = sortValue.property.join('') === property.join('') ? (sortValue.desc ? '▼' : '▲') : null;

	return (
		<SortableTableHeaderCellStyle onClick={() => onClick(property)}>
			{label}
			{sortDirection}
		</SortableTableHeaderCellStyle>
	);
};

const TrialsList = props => {
	const {
		viewer: {
			missionControlData: {trialsList},
		},
	} = props;

	const [searchValue, setSearchValue] = useState('');
	const [sortValue, setSortValue] = useState({property: ['companyId'], desc: false});

	const sortByProperty = property =>
		setSortValue({property, desc: property.join('') !== sortValue.property.join('') ? false : !sortValue.desc});

	const filteredTrialsList = sortBy(
		trialsList.filter(trial => {
			return trial.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
		}),
		sortValue.property
	);

	if (sortValue.desc) {
		filteredTrialsList.reverse();
	}

	const syncWithSF = companyId => {
		Util.CommitMutation(
			updateCompanyInternalDataMutation,
			{
				companyId,
				syncWithSF: true,
			},
			data => {
				if (data.UpdateCompanyInternalData.MissionControlCompanyStats.tier !== 'TRIAL') {
					console.log('is not trial anymore. Should refresh the page');
					createToast({
						duration: 5000,
						message: 'Account is converted to a paid account and moved to the company stats page',
					});
					props.relay.refetch();
				} else {
					createToast({
						duration: 5000,
						message: '⚠️⚠️⚠️Account could not be found in SF. Please check! ⚠️⚠️⚠️',
					});
				}
			}
		);
	};

	return (
		<TrialsListStyle>
			<div className="mission-control-list">
				<MissionControlHeader viewer={props.viewer} />
				<h1>Trials</h1>
				<div>
					<input
						type="search"
						placeholder="Search"
						value={searchValue}
						onChange={event => setSearchValue(event.target.value)}
					/>
				</div>
				<div style={{maxHeight: '90vh', overflowY: 'scroll'}}>
					<table>
						<TableHeader>
							<tr>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="ID"
									property={['companyId']}
								/>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="Company"
									property={['companyName']}
								/>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="Trial End"
									property={['trialEndYear', 'trialEndMonth', 'trialEndDay']}
								/>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="Contact Name"
									property={['contactName']}
								/>
								<SortableTableHeaderCell
									sortValue={sortValue}
									onClick={sortByProperty}
									label="Email"
									property={['contactEmail']}
								/>
								{hasFeatureFlag('get_missioncontrol_data_from_us') && (
									<SortableTableHeaderCell
										sortValue={sortValue}
										onClick={sortByProperty}
										label="Datacenter"
										property={['datacenter']}
									/>
								)}
								<th>&nbsp;</th>
							</tr>
						</TableHeader>
						<TableBody>
							{filteredTrialsList.map(trial => {
								return (
									<tr key={trial.companyId}>
										<td style={{verticalAlign: 'middle', width: '1%'}}>{trial.companyId}</td>
										<td style={{verticalAlign: 'middle', width: '1%', whiteSpace: 'nowrap'}}>
											{trial.companyName}
										</td>
										<td style={{verticalAlign: 'middle', width: '1%', whiteSpace: 'nowrap'}}>{`${
											trial.trialEndYear
										}-${trial.trialEndMonth}-${trial.trialEndDay} ${
											trial.trialExpired ? ' (Expired)' : ''
										}`}</td>
										<td style={{verticalAlign: 'middle', width: '1%', whiteSpace: 'nowrap'}}>
											{trial.contactName}
										</td>
										<td style={{verticalAlign: 'middle', width: '1%', whiteSpace: 'nowrap'}}>
											{trial.contactEmail}
										</td>
										{hasFeatureFlag('get_missioncontrol_data_from_us') && (
											<td style={{verticalAlign: 'middle', width: '1%', whiteSpace: 'nowrap'}}>
												{trial.datacenter}
											</td>
										)}
										<td style={{verticalAlign: 'middle'}}>
											<SignUpButtonStyle onClick={() => syncWithSF(trial.companyId)}>
												Sync with SF
											</SignUpButtonStyle>
										</td>
									</tr>
								);
							})}
						</TableBody>
					</table>
				</div>
			</div>
		</TrialsListStyle>
	);
};

const trialsListQuery = graphql`
	query trialsList_Query {
		viewer {
			actualPersonId
			component(name: "control_trials_list")
			...trialsList_viewer
		}
	}
`;

export {trialsListQuery};

export default createRefetchContainer(
	TrialsList,
	{
		viewer: graphql`
			fragment trialsList_viewer on Viewer {
				id
				...missionControlHeader_viewer
				missionControlData {
					trialsList {
						companyId
						companyName
						jiraEnabled
						minimumSeats
						minimumVirtualSeats
						users
						virtualUsers
						trialEndDay
						trialEndMonth
						trialEndYear
						contactName
						contactEmail
						trialExpired
						datacenter
						persons {
							id
							active
							role {
								id
								name
							}
							firstName
							lastName
							fullName
							profilePictureId
							email
							permissions
						}
					}
				}
			}
		`,
	},
	graphql`
		query trialsList_viewerRefetchQuery {
			viewer {
				component(name: "control_trials_list")
				...trialsList_viewer
			}
		}
	`
);
