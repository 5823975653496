import React, {useEffect} from 'react';
import styled from 'styled-components';
import {graphql, createFragmentContainer} from 'react-relay';
import {withRouter, useHistory} from 'react-router-dom';
import {MODAL_TYPE, showModal} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import {hasPermission, hasSomePermission} from '../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../Permissions';
import {TIERS} from '../constants';
import Tracking from '../tracking';
import Logo from '../images/forecast-f.svg';
import {useTrackPage} from '../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../tracking/amplitude/TrackingV2';
import {startCase} from 'lodash';
import CompanySetupUtil from '../forecast-app/shared/util/CompanySetupUtil';
import {projectUrl} from '../directApi';

const TrialGuideContainer = styled.div`
	display: grid;
	grid-column-gap: 32px;
	@media (min-width: 1800px) {
		align-self: center;
		grid-template-columns: repeat(3, 416px);
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'welcome empty1 empty2'
			'section1 section2 logo';
	}
	@media (max-width: 1799px) {
		padding: 0 80px;
		grid-template-columns: repeat(3, 368px);
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'welcome empty1 empty2'
			'section1 section2 logo';
	}
	@media (max-width: 1327px) {
		padding: 0 80px;
		grid-template-columns: repeat(2, 422px);
		grid-template-rows: auto auto 1fr;
		grid-template-areas:
			'welcome empty1'
			'section1 section2'
			'empty2 logo';
	}
	@media (max-width: 1023px) {
		grid-column-gap: 0px;
		padding: 0 80px;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto auto auto 1fr;
		grid-template-areas:
			'welcome'
			'section1'
			'section2';
	}
	height: 100%;
	overflow: auto;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const WelcomeSection = styled(Column)`
	grid-area: welcome;
	padding-bottom: 16px;
`;

const TitleSection = styled(Column)`
	white-space: nowrap;
	overflow: visible;
	font-weight: 700;
	font-size: 32px;
	line-height: 48px;
	color: #393946;
	padding-top: 36px;
	padding-bottom: 16px;
`;

const GuideSection1 = styled(Column)`
	grid-area: section1;
`;
const GuideSection2 = styled(Column)`
	grid-area: section2;
`;
const EmptySection1 = styled(Column)`
	grid-area: empty1;
`;
const EmptySection2 = styled(Column)`
	grid-area: empty2;
`;

const LogoSection = styled.div`
	grid-area: logo;
	background-image: url(${Logo});
	background-repeat: no-repeat;
	background-position: right 0px bottom -90px;
	overflow: hidden;
	@media (max-width: 1327px) {
		display: none;
	}
`;

const SectionOuterContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding-bottom: 24px;
`;

const SectionInnerContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const SectionTitle = styled.div`
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	color: #393946;
	margin-bottom: 8px;
`;
const SectionText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #393946;
	margin-bottom: 8px;
	a {
		font-weight: 700;
		color: #661bb1;
		cursor: pointer;
		text-decoration: none;
		position: relative;
		&::before {
			position: absolute;
			border-style: solid;
			border-width: 0.2em 0.2em 0 0;
			content: '';
			display: inline-block;
			height: 0.7em;
			right: -1em;
			top: 0.25em;
			vertical-align: top;
			width: 0.7em;
			transform: rotate(45deg);
		}
	}
`;

const TrialGuideV2 = ({viewer}) => {
	const history = useHistory();

	const handleCreateProject = () => {
		showModal({
			type: MODAL_TYPE.NEW_PROJECT_V2,
		});
	};

	const link = (text, href, condition = true, onClick, target = '_self') => {
		return condition ? (
			<a
				href={href || undefined}
				onMouseUp={() => trackEvent(`Trial Guide V2 ${startCase(text)}`, 'Clicked')} // Mouse up instead of click to capture middle click
				onClick={() => {
					Tracking.trackEvent(`Trial guide_v2_${text.toLowerCase()} clicked`);
					onClick && onClick();
				}}
				target={target}
			>
				{text}
			</a>
		) : (
			text
		);
	};

	const getProjectUrl = (project, postfix = '') => {
		return `${projectUrl(project.companyProjectId, project.customProjectId)}/${postfix}`;
	};

	// Ids. default to NULL
	const projects = viewer.projects.edges?.filter(p => p.node.fullAccessToProject);
	const mainProject = projects?.[0]?.node;
	const waterfallProject = projects.find(p => !p.node.sprintTimeBox && p.node.budgetType !== 'RETAINER')?.node;
	const novaFixedPriceProject = projects.find(p => !p.node.isInProjectGroup && p.node.budgetType === 'FIXED_PRICE_V2')?.node;
	const novaProject =
		novaFixedPriceProject || projects.find(p => !p.node.isInProjectGroup && p.node.budgetType !== 'RETAINER')?.node;
	const fixedPriceProject = projects.find(p => p.node.budgetType === 'FIXED_PRICE_V2')?.node;

	const hasFinance = CompanySetupUtil.hasFinance();
	const canCreateProject = hasPermission(PERMISSION_TYPE.PROJECTS_CREATE);
	const canSeeFinancials = hasSomePermission([
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
	]);

	useTrackPage('Trial Guide V2');

	useEffect(() => {
		Tracking.trackEvent('Trial guide v2 page viewed');
		// If no feature flag or trial ended, redirect
		if (viewer.company.tier !== TIERS.TRIAL || hasPermission(PERMISSION_TYPE.IS_CLIENT_USER)) {
			history.push('/');
			return;
		}
	}, []);

	return (
		<TrialGuideContainer data-cy={'trial-guide-page'}>
			<WelcomeSection>
				<TitleSection>Explore Forecast</TitleSection>
				<SectionText>
					To help you get started we’ve set up a selection of projects and resources to show how Forecast works.
				</SectionText>
			</WelcomeSection>
			<GuideSection1>
				<SectionOuterContainer>
					<SectionInnerContainer>
						<SectionTitle>Projects</SectionTitle>
						<SectionText>
							Plan, run and track projects using agile, waterfall or your own way of working.
						</SectionText>
						<SectionText>
							{waterfallProject ?? mainProject
								? link('View an example project', getProjectUrl(waterfallProject ?? mainProject, 'workflow'))
								: canCreateProject
								? link('Create a new project', '', canCreateProject, handleCreateProject)
								: ''}
						</SectionText>
					</SectionInnerContainer>
				</SectionOuterContainer>
				<SectionOuterContainer>
					<SectionInnerContainer>
						<SectionTitle>Resourcing</SectionTitle>
						<SectionText>
							Assign people to work based on roles, skills and availability. Forecast your organizations’s needs
							and ensure maximum resource utilization.
						</SectionText>
						<SectionText>{link('View resource overview', `/scheduling#people`)}</SectionText>
					</SectionInnerContainer>
				</SectionOuterContainer>
				<SectionOuterContainer>
					<SectionInnerContainer>
						<SectionTitle>Contact us</SectionTitle>
						<SectionText>
							If you'd like to request a personalized demo or want to talk to us about intelligent work
							management.
						</SectionText>
						<SectionText>{link('Get in touch', 'mailto:sales@forecast.app')}</SectionText>
					</SectionInnerContainer>
				</SectionOuterContainer>
			</GuideSection1>
			<GuideSection2>
				<SectionOuterContainer>
					<SectionInnerContainer>
						<SectionTitle>Finances</SectionTitle>
						<SectionText>
							Generate a project budget to track cost, revenue, and profit and compare them to all other projects
							in your organization.
						</SectionText>
						<SectionText>
							{hasFinance && canSeeFinancials
								? fixedPriceProject ?? mainProject
									? link(
											'View an in-progress budget',
											getProjectUrl(fixedPriceProject ?? mainProject, 'financials#details')
									  )
									: canCreateProject
									? link('Create a new project', '', canCreateProject, handleCreateProject)
									: ''
								: ''}
						</SectionText>
					</SectionInnerContainer>
				</SectionOuterContainer>
				<SectionOuterContainer>
					<SectionInnerContainer>
						<SectionTitle>Risk and Status management</SectionTitle>
						<SectionText>
							Proactively manage risk in projects and correct issues before it’s too late with our AI-powered
							insights.
						</SectionText>
						<SectionText>
							{novaProject ?? mainProject
								? link(
										'View Risk and Status management',
										getProjectUrl(novaProject ?? mainProject, 'nova-insights')
								  )
								: canCreateProject
								? link('Create a new project', '', canCreateProject, handleCreateProject)
								: ''}
						</SectionText>
					</SectionInnerContainer>
				</SectionOuterContainer>
			</GuideSection2>
			<LogoSection></LogoSection>
			<EmptySection1></EmptySection1>
			<EmptySection2></EmptySection2>
		</TrialGuideContainer>
	);
};

const TrialGuideV2Query = graphql`
	query TrialGuideV2_Query {
		viewer {
			actualPersonId
			component(name: "trial_guide_v2")
			...TrialGuideV2_viewer
		}
	}
`;

export {TrialGuideV2Query};

export default withRouter(
	createFragmentContainer(TrialGuideV2, {
		viewer: graphql`
			fragment TrialGuideV2_viewer on Viewer {
				id
				company {
					id
					createdAt
					tier
				}
				projects(first: 1000000) {
					edges {
						node {
							id
							companyProjectId
							budgetType
							sprintTimeBox
							isInProjectGroup
							fullAccessToProject
						}
					}
				}
			}
		`,
	})
);
