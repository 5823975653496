/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ValueCalculations_project$ref: FragmentReference;
declare export opaque type ValueCalculations_project$fragmentType: ValueCalculations_project$ref;
export type ValueCalculations_project = {|
  +id: string,
  +financialSourceSettings: ?{|
    +plannedHours: ?string,
    +plannedRevenue: ?string,
    +plannedCost: ?string,
    +forecastHours: ?string,
    +forecastRevenue: ?string,
    +forecastCost: ?string,
    +actualHours: ?string,
    +actualRevenue: ?string,
    +actualCost: ?string,
  |},
  +$refType: ValueCalculations_project$ref,
|};
export type ValueCalculations_project$data = ValueCalculations_project;
export type ValueCalculations_project$key = {
  +$data?: ValueCalculations_project$data,
  +$fragmentRefs: ValueCalculations_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ValueCalculations_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialSourceSettings",
      "kind": "LinkedField",
      "name": "financialSourceSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualCost",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'b1547a39d3ac8f974c981c7986608d72';

module.exports = node;
