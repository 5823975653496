/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PredictedEndDateModalStatsSection_project$ref: FragmentReference;
declare export opaque type PredictedEndDateModalStatsSection_project$fragmentType: PredictedEndDateModalStatsSection_project$ref;
export type PredictedEndDateModalStatsSection_project = {|
  +projectEndYear: ?number,
  +projectEndMonth: ?number,
  +projectEndDay: ?number,
  +projectHealth: ?{|
    +totalTaskEstimate: ?number,
    +totalBaselineEstimate: ?number,
    +totalTaskEstimateDone: ?number,
    +totalRemainingAllocation: ?number,
    +focus: ?number,
    +performanceRolling: ?number,
    +predictedEndDate: ?string,
    +optimisticPredictedEndDate: ?string,
    +conservativePredictedEndDate: ?string,
  |},
  +$refType: PredictedEndDateModalStatsSection_project$ref,
|};
export type PredictedEndDateModalStatsSection_project$data = PredictedEndDateModalStatsSection_project;
export type PredictedEndDateModalStatsSection_project$key = {
  +$data?: PredictedEndDateModalStatsSection_project$data,
  +$fragmentRefs: PredictedEndDateModalStatsSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PredictedEndDateModalStatsSection_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectHealth",
      "kind": "LinkedField",
      "name": "projectHealth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTaskEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalBaselineEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTaskEstimateDone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalRemainingAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "focus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performanceRolling",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "predictedEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optimisticPredictedEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "conservativePredictedEndDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '453ef17d6fa88e4b92f6de0d4bcb2e68';

module.exports = node;
