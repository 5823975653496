var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DirectApi from '../../../directApi';
import Util from '../../../forecast-app/shared/util/util';
import CreateXeroInvoiceMutation from '../../../mutations/CreateXeroInvoiceMutation';
import { showModal, MODAL_TYPE } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { BUTTON_STYLE, BUTTON_COLOR } from '../../../constants';
const getXeroAccounts = (projectBillTo) => __awaiter(void 0, void 0, void 0, function* () {
    const { billFrom } = projectBillTo || {};
    const { externalId } = billFrom || {};
    return DirectApi.Fetch_Raw(`xero/accounts/${externalId}`).then((result) => __awaiter(void 0, void 0, void 0, function* () {
        if (result.status !== 403) {
            const json = yield result.json();
            return json.map(account => ({
                value: account.AccountID,
                label: account.Name,
                taxRate: account.DefaultTaxRate,
                type: account.Type,
                code: account.Code,
            }));
        }
        return [];
    }));
});
export const fetchXeroAccounts = (projectBillTo, setAccounts) => __awaiter(void 0, void 0, void 0, function* () {
    const accounts = yield getXeroAccounts(projectBillTo);
    setAccounts(accounts);
});
const onExportXeroInvoiceSuccess = (result, intl, onSuccess) => {
    const { formatMessage } = intl;
    const { createXeroInvoice } = result;
    onSuccess();
    if (createXeroInvoice.success) {
        createToast({ duration: 5000, message: formatMessage({ id: 'integrations.xero.invoice_created' }) });
    }
    else {
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'invoicing.not_exported',
            warningInformation: [createXeroInvoice.errorMessage || 'The invoice could not be exported.'],
            buttons: [
                {
                    text: formatMessage({ id: 'common.ok' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
            ],
        });
    }
};
export const onExportXeroInvoice = ({ intl, onSuccess, invoice, invoiceRows, billTo, projectInDescription, onError, }) => {
    const { billFrom } = billTo;
    const accountMap = invoiceRows.map(line => ({
        entryId: line.id,
        accountId: line.xeroAccount,
        accountCode: line.accountCode,
    }));
    const input = {
        invoiceId: invoice.id,
        billToId: billTo.id,
        xeroClientId: billTo === null || billTo === void 0 ? void 0 : billTo.externalId,
        accountMap,
        tenantId: billFrom === null || billFrom === void 0 ? void 0 : billFrom.externalId,
        projectInDescription,
    };
    Util.CommitMutation(CreateXeroInvoiceMutation, input, result => onExportXeroInvoiceSuccess(result, intl, onSuccess), true, null, onError);
};
