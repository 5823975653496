import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_STYLE, BUTTON_COLOR, DATE_PICKER_STYLE} from '../../constants';
import UploadingOverlay from '../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import InputField from '../../components/inputs/input_field';
import Checkbox from '../../components/new-ui/check_box';
import Moment from 'moment';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';
import CreateDemoMutation from '../../mutations/create_demo_mutation';

const SaveDemoEnvironmentModal = ({closeModal, intl, companyId}) => {
	const [demoName, setDemoName] = useState('');
	const [demoViewDate, setDemoViewDate] = useState(Moment());
	const [shareDemo, setShareDemo] = useState(false);
	const [loading, setLoading] = useState(false);
	const [anonymize, setAnonymize] = useState(false);

	const callbackPositive = () => {
		setLoading(true);
		const onSuccess = result => {
			setLoading(false);
			createToast({duration: 5000, message: intl.formatMessage({id: 'settings_details.demo_saved'})}); //This is only for internal use, hence translations not needed
		};

		Util.CommitMutation(
			CreateDemoMutation,
			{
				companyId: companyId,
				name: demoName,
				sharedInCompany: shareDemo,
				viewYear: Moment(demoViewDate).year(),
				viewMonth: Moment(demoViewDate).month() + 1,
				viewDay: Moment(demoViewDate).date(),
				anonymize: anonymize,
			},
			onSuccess
		);
	};

	const handleNameChange = name => {
		setDemoName(name);
	};

	const handleShareChange = () => {
		setShareDemo(!shareDemo);
	};

	const handleDateViewChange = date => {
		setDemoViewDate(date);
	};

	const handleAnonymizeChange = e => {
		setAnonymize(e.target.checked);
	};

	const content = () => {
		return (
			<div className="save-demo-environment-modal">
				{loading ? <UploadingOverlay /> : null}
				<div>
					<InputField
						label={intl.formatMessage({id: 'settings_details.demo_name'})}
						type="text"
						placeholder={intl.formatMessage({id: 'settings_details.name_demo'})}
						id="demo_name"
						value={demoName}
						onChange={handleNameChange}
					/>
				</div>
				<div className="wrapper">
					<label className="label">{intl.formatMessage({id: 'settings_details.demo_view'})}</label>
					<DatePicker
						startDate={demoViewDate}
						handleDateRangeChange={handleDateViewChange}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						isSingleDatePicker={true}
						disabled={false}
					/>
				</div>
				<div className="wrapper">
					<label className="label">{intl.formatMessage({id: 'settings_details.share_demo'})}</label>
					<Checkbox isChecked={shareDemo} onChange={handleShareChange}></Checkbox>
				</div>
				<div className="wrapper">
					<label className="label">{intl.formatMessage({id: 'settings_details.anonymize_demo'})}</label>
					<Checkbox isChecked={anonymize} onChange={handleAnonymizeChange}></Checkbox>
				</div>
			</div>
		);
	};

	return (
		<GenericModal
			cy="save-demo-environment"
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: callbackPositive,
				},
			]}
			closeModal={closeModal}
			headerText={intl.formatMessage({id: 'settings_details.save_demo_header'})}
			content={content()}
		/>
	);
};

export default injectIntl(SaveDemoEnvironmentModal);
