/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type signupFlow_viewer$ref: FragmentReference;
declare export opaque type signupFlow_viewer$fragmentType: signupFlow_viewer$ref;
export type signupFlow_viewer = {|
  +id: string,
  +csrfToken: ?string,
  +actualPersonId: ?string,
  +email: ?string,
  +scimProvisioned: ?boolean,
  +company: ?{|
    +id: string,
    +tier: ?TierType,
  |},
  +$refType: signupFlow_viewer$ref,
|};
export type signupFlow_viewer$data = signupFlow_viewer;
export type signupFlow_viewer$key = {
  +$data?: signupFlow_viewer$data,
  +$fragmentRefs: signupFlow_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "signupFlow_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "csrfToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scimProvisioned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b63f9fb2ebec6508982ff5c3da3745fa';

module.exports = node;
