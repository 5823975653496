import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import SelectInput from '../../components/inputs/select_input';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

class SelectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.initialValue ? this.props.initialValue : null,
		};
	}

	handleSelectChange(selected) {
		this.setState({selected: selected});
	}

	onConfirm() {
		this.props.defaultCallback(this.state.selected);
		this.props.closeModal();
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="content">
				<SelectInput
					options={this.props.options}
					onChange={this.handleSelectChange.bind(this)}
					label={this.props.label || ''}
					multi={this.props.multi || false}
					value={this.props.multi ? this.state.selected : this.state.selected ? this.state.selected.value : null}
					personSelect={this.props.personSelect}
					showValuesInline={this.props.personSelect}
					cy={'select-modal-dropdown'}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.update'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.onConfirm.bind(this),
						disabled:
							!this.state.selected || (this.props.multi && this.state.selected && !this.state.selected.length),
					},
				]}
				headerText={this.props.title}
				content={content}
			/>
		);
	}
}
SelectModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	label: PropTypes.string,
	multi: PropTypes.bool,
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	defaultCallback: PropTypes.func.isRequired,
};

export default injectIntl(SelectModal);
