import React, {useState} from 'react';
import {showLoader, hideLoader} from '../global_loader';
import DirectApi from '../../directApi';
import moment from 'moment';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import GenericModal from '../modal/generic_modal';

const SisenseAdminPage = () => {
	const [companyResult, setCompanyResult] = useState({});
	const [fieldStats, setFieldStats] = useState([]);
	const [forecastCompanyInfo, setForecastCompanyInfo] = useState([]);
	const [showModal, setShowModal] = useState({});
	const [showWidgetsModal, setShowWidgetsModal] = useState({});
	const [deletingCompanyId, setDeletingCompanyId] = useState(null);

	const CheckUsers = () => {
		showLoader();
		setFieldStats([]);
		setCompanyResult({});

		DirectApi.Fetch_Raw('sisense/check_users').then(result => {
			result.json().then(resp => {
				setCompanyResult(resp);
				hideLoader();
			});
		});
	};

	const FieldStats = () => {
		showLoader();
		setFieldStats([]);
		setCompanyResult({});

		DirectApi.Fetch_Raw('sisense/field_stats').then(result => {
			result.json().then(resp => {
				setForecastCompanyInfo(resp.forecastCompanyInfo);
				setFieldStats(resp.fieldStats);
				hideLoader();
			});
		});
	};

	const deleteCompany = id => {
		setDeletingCompanyId(id);
	};

	const deleteCompanyForReal = id => {
		console.log('deleting company', id);
		showLoader();
		DirectApi.Fetch_Raw(`sisense/delete_company/${id}`).then(result => {
			hideLoader();
			CheckUsers();
		});
		setDeletingCompanyId(null);
	};

	const style = {position: 'sticky', top: 0, background: 'white'};

	const stats = [];
	if (fieldStats.length > 0) {
		fieldStats.forEach(fieldStat => {
			let statsTable = stats.find(stat => stat.table === fieldStat.table);
			if (!statsTable) {
				statsTable = {table: fieldStat.table, fields: []};
				stats.push(statsTable);
			}
			let statsField = statsTable.fields.find(field => field.field === fieldStat.column);
			if (!statsField) {
				statsField = {field: fieldStat.column, dashboards: []};
				statsTable.fields.push(statsField);
			}

			let statsFieldDashboard = statsField.dashboards.find(dashboard => dashboard.id === fieldStat.dashboardId);
			if (!statsFieldDashboard) {
				statsFieldDashboard = {
					id: fieldStat.dashboardId,
					title: fieldStat.dashboardTitle,
					widgets: [],
					owner: fieldStat.owner,
					forecastCompanyInfo: fieldStat.group
						? forecastCompanyInfo.find(info => info.companyId + '' === fieldStat.group.split('_')[1])
						: null,
					group: fieldStat.group,
					isDashboardFilter: false,
				};
				statsField.dashboards.push(statsFieldDashboard);
			}

			if (fieldStat.isFilter && fieldStat.widgetId == null) {
				//dashboard filter
				statsFieldDashboard.isDashboardFilter = true;
			} else {
				// Used in dashboard widget
				if (statsFieldDashboard.widgets.find(widget => widget.id === fieldStat.widgetId) == null) {
					statsFieldDashboard.widgets.push({
						id: fieldStat.widgetId,
						isFilter: fieldStat.isFilter,
						name: fieldStat.widgetTitle,
					});
				}
			}
		});
	}

	console.log(stats);

	return (
		<div className="mission-control-list">
			<br />
			<br />
			<button onClick={() => CheckUsers()}>Check AvA Companies</button>
			<button onClick={() => FieldStats()}>Field stats</button>
			<br />
			<br />
			<div>Results:</div>
			<div style={{maxHeight: '90vh', overflowY: 'scroll'}}>
				{companyResult.forecastCompanyInfo != null && (
					<table>
						<tbody>
							<tr>
								<th style={style}>Company Id</th>
								<th style={style}>Company Name</th>
								<th style={style}>Company Tier</th>
								<th style={style}>Company Trial End Date</th>
								<th style={style}>Last Activity</th>
								<th style={style}>AvA Module Enabled</th>
								<th style={style}>AvA Module End Date</th>
								<th style={style}>AvA Users</th>
								<th style={style}>AvA Dashboards</th>
								<th style={style}>Actions</th>
							</tr>
							{companyResult.forecastCompanyInfo.map(item => {
								const trialEndDate = moment(item.trialEndDate, 'DD/MM/YYYY hh:mm');
								const now = moment();

								const trialEnded = trialEndDate.isBefore(now);
								let sisenseModuleEnded = false;

								if (item.sisenseModuleEndDate) {
									const sisenseModuleEndDate = moment(item.sisenseModuleEndDate, 'DD/MM/YYYY hh:mm');
									sisenseModuleEnded = sisenseModuleEndDate.isBefore(now);
								}

								const sisenseGroup = companyResult.sisenseGroups.find(
									group => group.name === 'company_' + item.companyId
								);

								return (
									<tr key={item.companyId}>
										<td>{item.companyId}</td>
										<td>{item.companyName}</td>
										<td>{item.companyTier}</td>
										<td>
											{item.companyTier === 'TRIAL'
												? item.trialEndDate + (trialEnded ? ' (trial ended)' : '')
												: ''}
										</td>
										<td>
											{sisenseGroup
												? sisenseGroup.users
														.reduce(
															(acc, user) =>
																acc.isAfter(moment(user.lastActivity))
																	? acc
																	: moment(user.lastActivity),
															moment('01/01/1980', 'DD/MM/YYYY')
														)
														.format('DD/MM/YYYY hh:mm')
												: '-'}
										</td>
										<td>{item.sisenseModuleEnabled + ''}</td>
										<td>
											{item.sisenseModuleEndDate
												? item.sisenseModuleEndDate + (sisenseModuleEnded ? ' (trial ended)' : '')
												: ''}
										</td>
										<td>{sisenseGroup ? sisenseGroup.users.length : 0}</td>
										<td>
											{sisenseGroup
												? sisenseGroup.users.reduce((acc, user) => acc + user.dashboardsList?.length, 0)
												: 0}
										</td>
										<td>
											{deletingCompanyId === item.companyId ? (
												<button onClick={() => deleteCompanyForReal(item.companyId)}>
													Are you sure?
												</button>
											) : (
												<button onClick={() => deleteCompany(item.companyId)}>Delete</button>
											)}
										</td>
									</tr>
								);
							})}
							<tr>
								<td>
									<b>Total ({companyResult.forecastCompanyInfo.length})</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<b>
										{companyResult.sisenseGroups
											.filter(group => group.name.includes('company_'))
											.reduce((acc, group) => acc + group.users.length, 0)}
									</b>
								</td>
								<td>
									<b>
										{companyResult.sisenseGroups
											.filter(group => group.name.includes('company_'))
											.reduce(
												(acc, group) =>
													acc +
													group.users.reduce((acc, user) => acc + user.dashboardsList?.length, 0),
												0
											)}
									</b>
								</td>
							</tr>
						</tbody>
					</table>
				)}
				{stats.length > 0 && (
					<table>
						<tbody>
							<tr>
								<th style={style}>Table</th>
								<th style={style}>Field</th>
								<th style={style}>Dashboards</th>
								<th style={style}>Widgets</th>
							</tr>
							{stats.map(statsTable => {
								return statsTable.fields.map((statsField, i) => {
									return (
										<tr key={i}>
											<td>{statsTable.table}</td>
											<td>
												<div
													onClick={() =>
														setShowModal({
															table: statsTable.table,
															field: statsField.field,
															statsField,
														})
													}
												>
													{statsField.field}
												</div>
											</td>
											<td>{statsField.dashboards.length}</td>
											<td>
												{statsField.dashboards.reduce(
													(acc, dashboard) => acc + dashboard.widgets.length,
													0
												)}
											</td>
										</tr>
									);
								});
							})}
						</tbody>
					</table>
				)}
			</div>
			{showModal.table && (
				<GenericModal
					buttons={[
						{
							text: 'OK',
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
						},
					]}
					headerText={`${showModal.table}.${showModal.field}. Used in ${
						showModal.statsField.dashboards.length
					} dashboards and ${showModal.statsField.dashboards.reduce(
						(acc, dashboard) => acc + dashboard.widgets.length,
						0
					)} widgets.`}
					content={
						<div>
							<table>
								<tbody>
									<tr>
										<th>Dashboard Title</th>
										<th>Dashboard Id</th>
										<th>Owner</th>
										<th>Company</th>
										<th>Widgets</th>
									</tr>
									{showModal.statsField.dashboards.map((dashboard, i) => {
										return (
											<tr key={i}>
												<td>
													<a
														target="_blank"
														href={`https://forecast${
															process.env.CIRCLE_BRANCH !== 'production' ? '-dev' : ''
														}.sisense.com/app/main#/dashboards/${dashboard.id}/adminAccess`}
													>
														{dashboard.title}
													</a>
												</td>
												<td>{dashboard.id}</td>
												<td>{dashboard.owner}</td>
												<td>
													{dashboard.forecastCompanyInfo
														? dashboard.forecastCompanyInfo.companyName
														: ''}
												</td>
												<td>
													<div
														style={{cursor: 'pointer'}}
														onClick={() => setShowWidgetsModal({dashboard})}
													>
														{dashboard.widgets.length}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					}
					closeModal={() => setShowModal(false)}
				/>
			)}
			{showWidgetsModal.dashboard && (
				<GenericModal
					buttons={[
						{
							text: 'OK',
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
						},
					]}
					headerText={showWidgetsModal.dashboard.title}
					content={
						<div>
							<table>
								<tbody>
									<tr>
										<th>Widget Name</th>
										<th>Widget Id</th>
									</tr>
									{showWidgetsModal.dashboard.widgets.map((widget, i) => {
										return (
											<tr key={i}>
												<td>{widget.name}</td>
												<td>
													<a
														target="_blank"
														href={`https://forecast${
															process.env.CIRCLE_BRANCH !== 'production' ? '-dev' : ''
														}.sisense.com/app/main/dashboards/${
															showWidgetsModal.dashboard.id
														}/widgets/${widget.id}/adminAccess`}
													>
														{widget.id}
													</a>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					}
					closeModal={() => setShowWidgetsModal({})}
				/>
			)}
		</div>
	);
};

export default SisenseAdminPage;
