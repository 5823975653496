import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import ImportQuickbooksCustomersMutation from '../../mutations/ImportQuickbooksCustomersMutation';
import {Checkbox} from 'web-components';
import {hideLoader, showLoader} from '../global_loader';
import DirectApi from '../../directApi';

class QuickbooksImportCustomersModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			qboCustomers: [],
			fetchingCustomers: true,
			selectedCustomers: [],
			searchInput: '',
		};

		this.getContacts();
	}

	getContacts() {
		DirectApi.Fetch('quickbooks/unimported_customers').then(contacts => {
			contacts = contacts.sort((a, b) => {
				if (a.name.toUpperCase().trim() > b.name.toUpperCase().trim()) {
					return 1;
				} else if (a.name.toUpperCase().trim() < b.name.toUpperCase().trim()) {
					return -1;
				}
				return 0;
			});
			this.setState({
				qboCustomers: contacts,
				fetchingCustomers: false,
			});
		});
	}

	toggleSelectAll() {
		if (this.state.selectAll) {
			this.setState({
				selectedCustomers: [],
				selectAll: false,
			});
		} else {
			this.setState({
				selectedCustomers: this.state.qboCustomers.map(qboContact => qboContact.id),
				selectAll: true,
			});
		}
	}

	toggleContact(qboCustomerId) {
		if (this.state.selectedCustomers.includes(qboCustomerId)) {
			const selectedCustomers = this.state.selectedCustomers.filter(id => id !== qboCustomerId);
			this.setState({
				selectedCustomers,
				selectAll: false,
			});
		} else {
			const selectedCustomers = this.state.selectedCustomers;
			selectedCustomers.push(qboCustomerId);
			this.setState({
				selectedCustomers,
			});
		}
	}

	syncContacts() {
		this.setState({importing: true});
		showLoader();
		Util.CommitMutation(
			ImportQuickbooksCustomersMutation,
			{
				qboCustomerIds: this.state.selectedCustomers,
			},
			() => {
				this.setState({importing: false});
				this.props.closeModal();
				hideLoader();
				window.location.reload();
			}
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="integration-sync-clients-modal-content">
				<div className="integration-sync-clients-modal-description">
					<FormattedMessage id="integrations.quickbooks.sync_clients.description" />
				</div>
				{this.state.fetchingCustomers ? (
					<InlineLoader />
				) : (
					<>
						<input
							autoFocus={true}
							onChange={event => {
								this.setState({searchInput: event.target.value});
							}}
						/>

						<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
							<div className="integration-clients">
								{this.state.qboCustomers && this.state.qboCustomers.length > 0 ? (
									<>
										<div className="integration-client" key={'0'}>
											<Checkbox
												checked={this.state.selectAll}
												onClick={this.toggleSelectAll.bind(this)}
											/>
											<FormattedMessage id="common.select_all" />
										</div>
										{this.state.qboCustomers
											.filter(x => x.name.toLowerCase().includes(this.state.searchInput.toLowerCase()))
											.map(qboCustomer => (
												<div className="integration-client" key={qboCustomer.id}>
													<Checkbox
														checked={this.state.selectedCustomers.includes(qboCustomer.id)}
														onClick={this.toggleContact.bind(this, qboCustomer.id)}
													/>
													{qboCustomer.name}
												</div>
											))}
									</>
								) : (
									<FormattedMessage id="integrations.quickbooks.all_contacts_synched" />
								)}
							</div>
						</CustomScrollDiv>
					</>
				)}
			</div>
		);

		return (
			<GenericModal
				headerText={<FormattedMessage id="integrations.quickbooks.sync_clients" />}
				closeModal={this.props.closeModal}
				className="integration-sync-clients-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage(
							{id: 'common.create_x_clients'},
							{numOfClients: this.state.selectedCustomers.length}
						),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.syncContacts.bind(this),
						disabled: this.state.importing || this.state.selectedCustomers.length === 0,
						defaultCallback: () => null,
						preventDefaultClose: true,
					},
				]}
				content={content}
			/>
		);
	}
}

export default injectIntl(withRouter(QuickbooksImportCustomersModal));
