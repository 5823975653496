import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { ComboBox, DeprecatedCreativeModal as CreativeModal, Text, TextField } from '@forecast-it/design-system';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../../directApi';
import { createFragmentContainer, graphql } from 'react-relay';
import CreateSageIntacctProjectMutation from '../../../mutations/CreateSageIntacctProjectMutation';
import { hideLoader, showLoader } from '../../global_loader';
import styled from 'styled-components/macro';
import Util from '../../../forecast-app/shared/util/util';
import { HorizontalBar as DefaultHorizontalBar } from './SageIntacctModal.styled';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const HorizontalBar = styled(DefaultHorizontalBar) `
	margin: 8px -24px -18px;
`;
const SageIntacctCreateNewProjectModal = ({ closeModal, forecastProjectId, forecastProjectName, company, }) => {
    // Hardcoded project categories. Sage Intacct does not have an endpoint to retrieve these.
    const projectCategories = [
        { id: 'Contract' },
        { id: 'Capitalized' },
        { id: 'Internal non-billable' },
        { id: 'Internal billable' },
    ];
    const { formatMessage } = useIntl();
    const [locations, setLocations] = useState([]);
    const [clients, setClients] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [failed, setFailed] = useState(false);
    const [projectName, setProjectName] = useState(forecastProjectName);
    const [selectedProjectCategory, setSelectedProjectCategory] = useState(null);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [creating, setCreating] = useState(false);
    const disableCreateButton = creating || fetching || !projectName || !selectedProjectCategory;
    useEffect(() => {
        var _a;
        if (((_a = company === null || company === void 0 ? void 0 : company.clients) === null || _a === void 0 ? void 0 : _a.edges) && Array.isArray(company.clients.edges)) {
            const companyClients = company.clients.edges.map(edge => edge.node).filter(client => client.sageIntacctCustomerId);
            setClients(companyClients);
        }
    }, []);
    useEffect(() => {
        DirectApi.Fetch('sageIntacct/locations').then(res => {
            if (res === null || res === void 0 ? void 0 : res.locations) {
                setLocations(res.locations);
                setFetching(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetching(false);
            }
            else {
                setLocations([]);
                setFetching(false);
            }
        });
    }, []);
    const onCreateClick = () => {
        setCreating(true);
        showLoader();
        Util.CommitMutation(CreateSageIntacctProjectMutation, {
            name: projectName,
            projectCategory: selectedProjectCategory,
            clientId: selectedClientId,
            locationId: selectedLocationId,
            forecastProjectId: forecastProjectId,
        }, () => {
            setCreating(false);
            hideLoader();
            closeModal();
            showModal({
                type: MODAL_TYPE.SAGE_INTACCT_EXPORT_TASKS,
                forecastProjectId: forecastProjectId,
                isPartOfProjectSyncFlow: true,
            });
        });
    };
    return (React.createElement(CreativeModal, { title: 'Create New Project in Sage Intacct', size: 'l', closeModal: closeModal, creativeButtonText: 'Create New Project in Sage Intacct', cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onCreateClick, onCancelHandler: closeModal, disabled: disableCreateButton, replicateDesignSystem: true },
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        React.createElement(TextField, { label: "Project name", onValueChange: setProjectName, value: projectName, placeholder: "Project name", size: "large", width: "flex" }),
        React.createElement(ComboBox, { label: "Project category", "data-cy": "combobox-project-category", defaultItems: projectCategories, value: selectedProjectCategory, onChange: setSelectedProjectCategory, width: ComboBox.WIDTH.FLEX }, (projectCategory) => (React.createElement(ComboBox.Item, { textValue: projectCategory.id },
            React.createElement(Text, null, projectCategory.id)))),
        React.createElement(ComboBox, { label: "Client", "data-cy": "combobox-client", defaultItems: clients, value: selectedClientId, onChange: setSelectedClientId, width: ComboBox.WIDTH.FLEX }, (client) => (React.createElement(ComboBox.Item, { key: client.sageIntacctCustomerId, textValue: client.name },
            React.createElement(Text, null, client.name)))),
        React.createElement(ComboBox, { label: "Location", "data-cy": "combobox-location", defaultItems: locations, value: selectedLocationId, onChange: setSelectedLocationId, width: ComboBox.WIDTH.FLEX, disabled: fetching }, (location) => (React.createElement(ComboBox.Item, { key: location.LOCATIONID, textValue: location.NAME },
            React.createElement(Text, null, location.NAME)))),
        React.createElement(HorizontalBar, null)));
};
const sageIntacctCreateNewProjectModalQuery = graphql `
	query SageIntacctCreateNewProjectModal_Query {
		viewer {
			actualPersonId
			component(name: "sage_intacct_new_project_modal")
			company {
				...SageIntacctCreateNewProjectModal_company
			}
		}
	}
`;
export { sageIntacctCreateNewProjectModalQuery };
export default createFragmentContainer(SageIntacctCreateNewProjectModal, {
    company: graphql `
		fragment SageIntacctCreateNewProjectModal_company on Company {
			clients {
				edges {
					node {
						id
						name
						sageIntacctCustomerId
					}
				}
			}
		}
	`,
});
