/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type taskLocationModal_viewer$ref = any;
export type taskLocationModal_QueryVariables = {||};
export type taskLocationModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: taskLocationModal_viewer$ref,
  |}
|};
export type taskLocationModal_Query = {|
  variables: taskLocationModal_QueryVariables,
  response: taskLocationModal_QueryResponse,
|};
*/


/*
query taskLocationModal_Query {
  viewer {
    actualPersonId
    component(name: "task_location_modal")
    ...taskLocationModal_viewer
    id
  }
}

fragment DeprecatedProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}

fragment ProjectDropdown_projects on ProjectTypeEdge {
  node {
    id
    name
    projectColor
    companyProjectId
    customProjectId
    status
    ...DeprecatedProjectIndicatorJS_project
    manualProgressOnProjectEnabled
    manualProgressOnPhasesEnabled
    manualProgressOnTasksEnabled
  }
}

fragment taskLocationModal_viewer on Viewer {
  id
  company {
    id
    modules {
      moduleType
      id
    }
  }
  availableFeatureFlags {
    key
    id
  }
  projects(first: 100000, excludeDoneOrHalted: true, excludeReadOnly: true, excludeRestricted: true) {
    edges {
      ...ProjectDropdown_projects
      node {
        id
        projectColor
        projectGroupId
        name
        companyProjectId
        status
        isJiraProject
        harvestProject {
          id
        }
        statusColumnsV2(first: 1000000) {
          edges {
            node {
              id
              category
              projectGroupStatusColumnId
              order
            }
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "task_location_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"task_location_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "excludeDoneOrHalted",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "excludeReadOnly",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "excludeRestricted",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "taskLocationModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "taskLocationModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "taskLocationModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualProgressOnProjectEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualProgressOnPhasesEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualProgressOnTasksEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectGroupId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isJiraProject",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HarvestProjectType",
                        "kind": "LinkedField",
                        "name": "harvestProject",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1000000
                          }
                        ],
                        "concreteType": "StatusColumnV2TypeConnection",
                        "kind": "LinkedField",
                        "name": "statusColumnsV2",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StatusColumnV2TypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StatusColumnV2",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "category",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectGroupStatusColumnId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "order",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "statusColumnsV2(first:1000000)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(excludeDoneOrHalted:true,excludeReadOnly:true,excludeRestricted:true,first:100000)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Viewer_projects",
            "kind": "LinkedHandle",
            "name": "projects"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "taskLocationModal_Query",
    "operationKind": "query",
    "text": "query taskLocationModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"task_location_modal\")\n    ...taskLocationModal_viewer\n    id\n  }\n}\n\nfragment DeprecatedProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n\nfragment ProjectDropdown_projects on ProjectTypeEdge {\n  node {\n    id\n    name\n    projectColor\n    companyProjectId\n    customProjectId\n    status\n    ...DeprecatedProjectIndicatorJS_project\n    manualProgressOnProjectEnabled\n    manualProgressOnPhasesEnabled\n    manualProgressOnTasksEnabled\n  }\n}\n\nfragment taskLocationModal_viewer on Viewer {\n  id\n  company {\n    id\n    modules {\n      moduleType\n      id\n    }\n  }\n  availableFeatureFlags {\n    key\n    id\n  }\n  projects(first: 100000, excludeDoneOrHalted: true, excludeReadOnly: true, excludeRestricted: true) {\n    edges {\n      ...ProjectDropdown_projects\n      node {\n        id\n        projectColor\n        projectGroupId\n        name\n        companyProjectId\n        status\n        isJiraProject\n        harvestProject {\n          id\n        }\n        statusColumnsV2(first: 1000000) {\n          edges {\n            node {\n              id\n              category\n              projectGroupStatusColumnId\n              order\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9dfe9aaf2ab78c46faccdb23f1e8cf51';

module.exports = node;
