import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal from './generic_modal';
import InputField from '../../components/inputs/input_field';
import Warning from '../../components/warning';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../constants';
export const TypeToDeleteModal = ({confirmText, onDelete, closeModal, title, warningMessage}) => {
	const {formatMessage} = useIntl();
	const [inputText, setInputText] = useState('');

	const handleInputChange = e => {
		setInputText(e);
	};

	const content = (
		<div className="default-warning-modal">
			<Warning message={title} messageId={title ? null : 'common.delete-confirmation-title'} />
			<div className="warning-part-2">
				{warningMessage ? warningMessage : formatMessage({id: 'common.delete-project-title'})}
			</div>
			<div className="warning-part-2">{formatMessage({id: 'settings.delete_to_confirm'}, {confirmText})}</div>
			<InputField
				cy="type-to-delete-input"
				value={inputText}
				onChange={handleInputChange}
				label={formatMessage({id: 'settings.type_confirm_text'}, {confirmText})}
				placeholder={confirmText}
				type="text"
				autoFocus={true}
			/>
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: onDelete,
					cy: 'type-to-delete-confirm',
					disabled: confirmText !== inputText.trim(),
				},
			]}
			content={content}
		/>
	);
};
