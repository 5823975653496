import {useState} from 'react';
import {useIntl} from 'react-intl';

export const useInvoiceContextMenu = (onEditInvoice, onDeleteInvoice, userCanUnlinkInvoice, onUnlinkInvoice) => {
	const intl = useIntl();
	const [contextMenuData, setContextMenuData] = useState(null);
	const openContextMenu = (e, invoiceId, companyInvoiceId) => {
		e.preventDefault();
		const newContextMenuData = {
			invoiceId,
			companyInvoiceId,
		};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			newContextMenuData.posX = btnBounds.right - btn.clientWidth - 75;
			newContextMenuData.posY = btnBounds.y + 25;
		} else {
			newContextMenuData.posX = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				newContextMenuData.posY = e.pageY - 250;
			} else {
				newContextMenuData.posY = e.pageY;
			}
		}
		setContextMenuData(newContextMenuData);
	};

	const closeContextMenu = () => {
		setContextMenuData(null);
	};

	const getContextMenuOptions = (invoiceId, invoices) => {
		const invoice = invoices.find(invoice => invoice.id === invoiceId);
		let options = [
			{
				text: intl.formatMessage({id: 'common.edit'}),
				onClick: () => onEditInvoice(invoice.companyInvoiceId),
			},
		];

		options.push({
			text: intl.formatMessage({id: 'common.delete'}),
			onClick: () => onDeleteInvoice(invoiceId),
			disabled: invoice.status !== 'DRAFT' || invoice.isExported,
			cy: 'delete-invoice',
		});

		if (invoice.xeroId || invoice.economicId || invoice.quickbooksId || invoice.sageIntacctId) {
			options.push({
				text: intl.formatMessage({id: 'invoice.unlink_invoice'}),
				disabled: !userCanUnlinkInvoice,
				disabledDescription: 'Unlinking of invoices is not allowed. Contact an administrator',
				onClick: () => onUnlinkInvoice(invoiceId),
			});
		}

		return options;
	};

	return {openContextMenu, closeContextMenu, getContextMenuOptions, contextMenuData};
};
