import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import UpdateProjectMutation from '../../mutations/update_project_budget_page_mutation';
import Util from '../../forecast-app/shared/util/util';

import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, TASK_COST_CALCULATION_TYPE} from '../../constants';
import RadioButton from '../../components/new-ui/radio_button';
import Input from '../../components/new-ui/input_field';

const costRateModal = ({
	closeModal,
	project,
	taskCostCalculationType,
	unassignedTaskHourlyCost,
	currencySymbol,
	intl: {formatMessage},
}) => {
	const [selectedOption, setSelectedOption] = useState(taskCostCalculationType);
	const [fixedCost, setFixedCost] = useState(unassignedTaskHourlyCost);

	const selectOption = selected => setSelectedOption(selected);

	const onSave = () => {
		Util.CommitMutation(UpdateProjectMutation, {
			project,
			unassignedTaskHourlyCost: +fixedCost || 0,
			taskCostCalculationType: selectedOption,
		});
	};

	const content = (
		<div className="content">
			<RadioButton
				cy={'change-cost-rate-company-average-option'}
				stateValue={selectedOption}
				onSelected={selectOption}
				value={TASK_COST_CALCULATION_TYPE.COMPANY_AVERAGE_UNASSIGNED_TASKS}
				text={formatMessage({id: 'settings.set_hourly_cost_rate_option_company'})}
			/>
			<RadioButton
				cy={'change-cost-rate-team-average-option'}
				stateValue={selectedOption}
				onSelected={selectOption}
				value={TASK_COST_CALCULATION_TYPE.PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS}
				text={formatMessage({id: 'settings.set_hourly_cost_rate_option_team'})}
			/>
			<div className="fixed-cost">
				<RadioButton
					cy={'change-cost-rate-fixed-option'}
					stateValue={selectedOption}
					onSelected={selectOption}
					value={TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS}
					text={formatMessage({id: 'settings.set_hourly_cost_rate_option_fixed_unassigned'})}
				/>
				<Input
					cy={'change-cost-rate-fixed-input'}
					id="fixed-cost-rate-input"
					type="number"
					value={fixedCost}
					onChange={value => setFixedCost(value)}
					placeholder="0"
					hideLabel
					suffix={currencySymbol}
				/>
			</div>
		</div>
	);

	return (
		<GenericModal
			className="change-cost-rate-modal-budget"
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'change-cost-rate-cancel',
				},
				{
					text: formatMessage({id: 'common.ok'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					cy: 'change-cost-rate-ok',
					callback: onSave,
				},
			]}
			headerText={formatMessage({id: 'settings.set_hourly_cost_rate_calculation_of_task'})}
			headerInfo={formatMessage({id: 'settings.set_hourly_cost_rate_info'})}
			content={content}
		/>
	);
};

PropTypes.costRateModal = {
	taskCostCalculationType: PropTypes.string,
	unassignedTaskHourlyCost: PropTypes.number,
	currencySymbol: PropTypes.string,
};

export default injectIntl(costRateModal);
