/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvoiceFilterButton_company$ref: FragmentReference;
declare export opaque type InvoiceFilterButton_company$fragmentType: InvoiceFilterButton_company$ref;
export type InvoiceFilterButton_company = {|
  +newXeroEnabled: ?boolean,
  +quickbooksEnabled: ?boolean,
  +economicEnabled: ?boolean,
  +sageIntacctConnectionEnabled: ?boolean,
  +$refType: InvoiceFilterButton_company$ref,
|};
export type InvoiceFilterButton_company$data = InvoiceFilterButton_company;
export type InvoiceFilterButton_company$key = {
  +$data?: InvoiceFilterButton_company$data,
  +$fragmentRefs: InvoiceFilterButton_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceFilterButton_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newXeroEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quickbooksEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "economicEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sageIntacctConnectionEnabled",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'aa2fa81b03e161b1e94ebdb8e3bad6bf';

module.exports = node;
