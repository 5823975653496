import {createFragmentContainer, graphql} from 'react-relay';

const BudgetWarningsLoader = ({relay, viewer, children, ...props}) => {
	const formatData = () => {
		return {
			viewer: viewer,
			project: viewer.project,
		};
	};
	return children({data: formatData(), ...props});
};

export const budgetWarningsLoaderQuery = graphql`
	query BudgetWarningsLoader_Query($projectId: ID!) {
		viewer {
			actualPersonId
			component(name: "project_budget_warnings_loader")
			...BudgetWarningsLoader_viewer @arguments(projectId: $projectId)
		}
	}
`;

export default createFragmentContainer(BudgetWarningsLoader, {
	viewer: graphql`
		fragment BudgetWarningsLoader_viewer on Viewer @argumentDefinitions(projectId: {type: "ID!"}) {
			id
			project(internalId: $projectId) {
				id
				companyProjectId
				customProjectId
				estimationUnit
				projectStartYear
				projectStartMonth
				projectStartDay
				projectEndYear
				projectEndMonth
				projectEndDay
				warnings {
					id
					year
					month
					day
					tasks(first: 10000) {
						edges {
							node {
								id
								name
								startDay
								startMonth
								startYear
								deadlineDay
								deadlineMonth
								deadlineYear
								estimateForecast
							}
						}
					}
					personIds
					expenseItems(first: 10000) {
						edges {
							node {
								id
								name
								expenseYear
								expenseMonth
								expenseDay
							}
						}
					}
					timeRegistrations(first: 10000) {
						edges {
							node {
								id
								year
								month
								day
								minutesRegistered
								person {
									id
									fullName
									profilePictureId
									profilePictureDefaultId
								}
								task {
									name
								}
							}
						}
					}
				}
			}
			company {
				id
				roles(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
