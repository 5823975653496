/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BudgetPage_company$ref = any;
type BudgetPage_project$ref = any;
type ProjectHeader_project$ref = any;
type ProjectHeader_psProject$ref = any;
type RatesAndInternalCostLitePage_company$ref = any;
type RatesAndInternalCostLitePage_project$ref = any;
type RevenueRecognitionPage_company$ref = any;
type RevenueRecognitionPage_project$ref = any;
type SecondaryNavigation_project$ref = any;
type expensesPage_company$ref = any;
type expensesPage_project$ref = any;
type ratesAndInternalCostPage_company$ref = any;
type ratesAndInternalCostPage_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectBudgetV3_viewer$ref: FragmentReference;
declare export opaque type projectBudgetV3_viewer$fragmentType: projectBudgetV3_viewer$ref;
export type projectBudgetV3_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +email: ?string,
  +project: ?{|
    +id: string,
    +name: ?string,
    +budgetType: ?BUDGET_TYPE,
    +useBaseline: ?boolean,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +hasInvoices: ?boolean,
    +sageProject: ?{|
      +id: string,
      +sageProjectId: ?string,
    |},
    +expenseItems: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +category: ?{|
            +id: string,
            +name: ?string,
          |},
          +phase: ?{|
            +id: string,
            +name: ?string,
          |},
          +name: ?string,
          +approved: ?boolean,
          +expenseYear: ?number,
          +expenseMonth: ?number,
          +expenseDay: ?number,
          +billable: ?boolean,
          +price: ?number,
          +cost: ?number,
          +quantity: ?number,
          +planned: ?boolean,
          +createdAt: ?string,
          +invoiced: ?boolean,
          +person: ?{|
            +id: string,
            +fullName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
          |},
        |}
      |}>
    |},
    +financialSourceSettings: ?{|
      +plannedCost: ?string,
      +plannedRevenue: ?string,
      +actualCost: ?string,
      +actualRevenue: ?string,
      +forecastCost: ?string,
      +forecastRevenue: ?string,
    |},
    +$fragmentRefs: BudgetPage_project$ref & expensesPage_project$ref & ratesAndInternalCostPage_project$ref & RatesAndInternalCostLitePage_project$ref & RevenueRecognitionPage_project$ref & ProjectHeader_project$ref & SecondaryNavigation_project$ref,
  |},
  +psProject: ?{|
    +$fragmentRefs: ProjectHeader_psProject$ref
  |},
  +company: ?{|
    +id: string,
    +tier: ?TierType,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +sageIntacctConnectionEnabled: ?boolean,
    +$fragmentRefs: BudgetPage_company$ref & expensesPage_company$ref & ratesAndInternalCostPage_company$ref & RatesAndInternalCostLitePage_company$ref & RevenueRecognitionPage_company$ref,
  |},
  +$refType: projectBudgetV3_viewer$ref,
|};
export type projectBudgetV3_viewer$data = projectBudgetV3_viewer;
export type projectBudgetV3_viewer$key = {
  +$data?: projectBudgetV3_viewer$data,
  +$fragmentRefs: projectBudgetV3_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonthLastDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "expenseItems"
        ]
      }
    ]
  },
  "name": "projectBudgetV3_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useBaseline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasInvoices",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SageProjectType",
          "kind": "LinkedField",
          "name": "sageProject",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sageProjectId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "expenseItems",
          "args": null,
          "concreteType": "ExpenseItemTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_expenseItems_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseItemTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExpenseItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ExpenseCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PhaseType",
                      "kind": "LinkedField",
                      "name": "phase",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approved",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expenseYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expenseMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expenseDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billable",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cost",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planned",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fullName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureDefaultId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialSourceSettings",
          "kind": "LinkedField",
          "name": "financialSourceSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenue",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "currentMonth",
              "variableName": "currentMonth"
            },
            {
              "kind": "Variable",
              "name": "currentMonthLastDay",
              "variableName": "currentMonthLastDay"
            },
            {
              "kind": "Variable",
              "name": "currentYear",
              "variableName": "currentYear"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "kind": "FragmentSpread",
          "name": "BudgetPage_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "expensesPage_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ratesAndInternalCostPage_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RatesAndInternalCostLitePage_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RevenueRecognitionPage_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctConnectionEnabled",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BudgetPage_company"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "expensesPage_company"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ratesAndInternalCostPage_company"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RatesAndInternalCostLitePage_company"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RevenueRecognitionPage_company"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0f9b9f08ef18334391e24b110b5f27d';

module.exports = node;
