/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type companiesToBeDeleted_viewer$ref: FragmentReference;
declare export opaque type companiesToBeDeleted_viewer$fragmentType: companiesToBeDeleted_viewer$ref;
export type companiesToBeDeleted_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +missionControlData: ?{|
    +id: string,
    +companiesToBeDeleted: ?$ReadOnlyArray<?{|
      +id: string,
      +companyId: ?number,
      +createdAt: ?string,
      +updatedAt: ?string,
      +name: ?string,
      +trialEndDate: ?string,
      +lastSessionDate: ?string,
      +projectCount: ?number,
      +userCount: ?number,
      +flaggedForDeletionDate: ?string,
      +domains: ?string,
      +toBeDeleted: ?boolean,
      +sisenseUserCount: ?number,
    |}>,
  |},
  +$refType: companiesToBeDeleted_viewer$ref,
|};
export type companiesToBeDeleted_viewer$data = companiesToBeDeleted_viewer;
export type companiesToBeDeleted_viewer$key = {
  +$data?: companiesToBeDeleted_viewer$data,
  +$fragmentRefs: companiesToBeDeleted_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "companiesToBeDeleted_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlCompanyToBeDeletedType",
          "kind": "LinkedField",
          "name": "companiesToBeDeleted",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updatedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialEndDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastSessionDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flaggedForDeletionDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "domains",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "toBeDeleted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sisenseUserCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e12e81a8816dd77733c1c4620c498b6';

module.exports = node;
