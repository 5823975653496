import {createFragmentContainer, graphql} from 'react-relay';
import {useEffect} from 'react';
import NovaInsightsCompatibilityFormatter from '../../forecast-app/shared/util/export-formatters/NovaInsightsCompatibilityFormatter';
import {useDataExport} from '../../forecast-app/shared/hooks/useDataExport';
import {closeModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {useIntl} from 'react-intl';

const exportToCSV = (viewer, intl) => {
	const columns = [
		{name: 'company_id', checked: true},
		{name: 'company_name', checked: true},
		{name: 'running_projects', checked: true},
		{name: 'projects_in_connected_project', checked: true},
		{name: 'performance_compatibility', checked: true},
		{name: 'focus_compatibility', checked: true},
		{name: 'predicted_end_compatibility', checked: true},
		{name: 'nova_insights_compatibility', checked: true},
		{name: 'projects_with_time_regs', checked: true},
		{name: 'projects_with_done_tasks', checked: true},
		{name: 'projects_with_allocations', checked: true},
		{name: 'projects_with_estimates', checked: true},
		{name: 'predicted_end_compatibility_no_time_regs', checked: true},
		{name: 'predicted_end_compatibility_no_done_tasks', checked: true},
		{name: 'predicted_end_compatibility_no_allocations', checked: true},
		{name: 'predicted_end_compatibility_no_time_regs_no_done_tasks', checked: true},
		{name: 'predicted_end_compatibility_no_time_regs_no_done_tasks_no_allocations', checked: true},
	];

	const exportData = useDataExport(
		new NovaInsightsCompatibilityFormatter(intl, [], [], false),
		null,
		data => data,
		_ => `Nova Insights compatibility stats`
	);

	const data = viewer.missionControlData.companiesWithNovaInsights;

	exportData(columns, data, {}, null, 'CSV');
};

const ExportNovaInsightsStatsCSV = ({viewer}) => {
	const intl = useIntl();

	useEffect(() => {
		if (viewer) {
			exportToCSV(viewer, intl);
			closeModal();
		}
	}, [viewer]);

	return null;
};

const ExportNovaInsightsStatsCSVQuery = graphql`
	query ExportNovaInsightsStatsCSV_Query($includeProjectGroups: Boolean) {
		viewer {
			actualPersonId
			component(name: "export_nova_insights_stats_csv")
			...ExportNovaInsightsStatsCSV_viewer @arguments(includeProjectGroups: $includeProjectGroups)
		}
	}
`;

export {ExportNovaInsightsStatsCSVQuery};

export default createFragmentContainer(ExportNovaInsightsStatsCSV, {
	viewer: graphql`
		fragment ExportNovaInsightsStatsCSV_viewer on Viewer @argumentDefinitions(includeProjectGroups: {type: "Boolean"}) {
			missionControlData {
				companiesWithNovaInsights(includeProjectGroups: $includeProjectGroups) {
					companyId
					companyName
					runningProjectsCount
					projectsInConnectedProjectCount
					timeRegProjectsCount
					doneTaskProjectsCount
					allocationProjectsCount
					estimatedProjectsCount
					predictedEndWithoutTimeRegAvailability
					predictedEndWithoutDoneTaskAvailability
					predictedEndWithoutAllocationAvailability
					predictedEndWithoutTimeRegDoneTaskAvailability
					predictedEndWithoutTimeRegDoneTaskAllocationAvailability
					predictedEndAvailability
					focusAvailability
					performanceAvailability
					novaInsightCompatibilityScore
				}
			}
		}
	`,
});
