/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectsLoader_financialsCompanyCurrency$ref: FragmentReference;
declare export opaque type ProjectsLoader_financialsCompanyCurrency$fragmentType: ProjectsLoader_financialsCompanyCurrency$ref;
export type ProjectsLoader_financialsCompanyCurrency = {|
  +financialNumbers: ?{|
    +billablePlannedTimeAndExpenses: ?number,
    +actualRevenue: ?number,
    +remainingRevenue: ?number,
    +forecastRevenue: ?number,
    +recognitionOpenRevenue: ?number,
    +recognitionForecastRevenue: ?number,
    +recognitionLockedRevenue: ?number,
    +plannedCost: ?number,
    +actualCost: ?number,
    +forecastCost: ?number,
    +remainingCost: ?number,
    +plannedProfit: ?number,
    +forecastProfit: ?number,
    +invoiced: ?number,
    +paid: ?number,
  |},
  +$refType: ProjectsLoader_financialsCompanyCurrency$ref,
|};
export type ProjectsLoader_financialsCompanyCurrency$data = ProjectsLoader_financialsCompanyCurrency;
export type ProjectsLoader_financialsCompanyCurrency$key = {
  +$data?: ProjectsLoader_financialsCompanyCurrency$data,
  +$fragmentRefs: ProjectsLoader_financialsCompanyCurrency$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsLoader_financialsCompanyCurrency",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billablePlannedTimeAndExpenses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remainingRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionOpenRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionForecastRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionLockedRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remainingCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedProfit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastProfit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiced",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paid",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '3a3a12ed48d895d0d4894611c1bca787';

module.exports = node;
