import {createFragmentContainer, graphql} from 'react-relay';
import React from 'react';

import {useIntl} from 'react-intl';
import {
	getBillableExpenseTotals,
	getFixedPriceExpenseTotals,
	NUMBER_TYPE,
} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import FinancialNumber from '../../../../forecast-app/shared/components/financials/FinancialNumber';
import {isYearMonthDayBeforeToday} from '../../../../forecast-app/shared/util/DateUtil';
import {SummaryCard, Tooltip} from '@forecast-it/design-system';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {BUDGET_TYPE} from '../../../../constants';

const getInvoiceCountText = (length, altTextTag, formatMessage) => {
	return length
		? length + ' ' + formatMessage({id: length !== 1 ? 'common.invoices' : 'common.invoice'})
		: formatMessage({id: altTextTag});
};

const tooltipRevenueFromExpenses = formatMessage => {
	return {
		title: formatMessage({id: 'project_budget.revenue_from_expenses'}),
		details: formatMessage({id: 'project_budget.revenue_from_expenses_tooltip'}),
	};
};

const ProjectInvoicingTotals = ({project, company}) => {
	const intl = useIntl();
	const formatMessage = intl.formatMessage;
	const currency = project.rateCard?.currency || company.currency;
	const isFixedPrice = ProjectUtil.isFixedPriceProject(project);

	const invoices = project.invoices?.edges ? project.invoices.edges.map(e => e.node) : [];
	const invoiceTotal = invoices.reduce((acc, invoice) => acc + invoice.invoicedWithoutTaxProjectCurrency, 0);

	const sendOrExportedInvoices = invoices.filter(invoice => invoice.status === 'SENT' || invoice.status === 'EXPORTED');
	const sendOrExportedInvoiceTotal = sendOrExportedInvoices.reduce(
		(acc, invoice) => acc + invoice.invoicedWithoutTaxProjectCurrency,
		0
	);

	const invoicesWithPayments = invoices.filter(invoice => invoice.paidWithoutTaxProjectCurrency > 0);
	const invoicePaymentTotal = invoicesWithPayments.reduce((acc, invoice) => acc + invoice.paidWithoutTaxProjectCurrency, 0);

	const overdueInvoices = invoices.filter(invoice =>
		isYearMonthDayBeforeToday(invoice.dueYear, invoice.dueMonth, invoice.dueDay)
	);
	const overdueInvoiceTotal = overdueInvoices.reduce((acc, invoice) => acc + invoice.unpaidWithoutTaxProjectCurrency, 0);

	const revenueFromExpenses = isFixedPrice
		? getFixedPriceExpenseTotals(project.expenseItems?.edges)
		: getBillableExpenseTotals(project.expenseItems?.edges);

	const billableTime = isFixedPrice
		? project.financialNumbers.totalActualRevenueRecognition
		: project.timeFinancialNumbers.billableActualTimeAndExpenses;

	return (
		<SummaryCard.Container wrap={'true'}>
			{isFixedPrice ? (
				<SummaryCard
					title={formatMessage({id: 'common.total_revenue'})}
					subtitle={''}
					flexBasis={'300px'}
					flexGrow={'2'}
				>
					<FinancialNumber
						cy={'projectTotalRevenue'}
						value={project.financialNumbers.totalRevenueRecognition}
						numberType={NUMBER_TYPE.MONEY}
						variant={'l'}
						currency={currency}
						zeroValuesHidden={true}
					/>

					<FinancialNumber
						labelTranslationId={
							project.budgetType === BUDGET_TYPE.RETAINER
								? 'retainer.total_project_target'
								: 'project_budget.project_fixed_price'
						}
						cy={'projectFixedPrice'}
						value={project.budget}
						numberType={NUMBER_TYPE.MONEY}
						type={'label'}
						variant={'m'}
						currency={currency}
					/>

					<FinancialNumber
						labelTranslationId={'project_budget.revenue_from_expenses'}
						cy={'projectExpenseRevenue'}
						value={revenueFromExpenses}
						numberType={NUMBER_TYPE.MONEY}
						type={'label'}
						variant={'m'}
						tooltipItem={tooltipRevenueFromExpenses(formatMessage)}
						tooltipMaxWidth={'300px'}
						currency={currency}
					/>
				</SummaryCard>
			) : (
				project.budgetType !== BUDGET_TYPE.NON_BILLABLE && (
					<SummaryCard
						title={formatMessage({id: 'common.confirmed_revenue'})}
						titleTooltip={
							<Tooltip.Item
								title={formatMessage({id: 'common.confirmed_revenue'})}
								details={formatMessage({id: 'project_budget.confirmed_revenue.tooltip'})}
							/>
						}
						subtitle={''}
						flexBasis={'300px'}
						flexGrow={'2'}
					>
						<FinancialNumber
							cy={'projectTotalRevenue'}
							value={billableTime + revenueFromExpenses}
							numberType={NUMBER_TYPE.MONEY}
							variant={'l'}
							currency={currency}
							zeroValuesHidden={true}
						/>

						<FinancialNumber
							labelTranslationId={'project_budget.actual_billable_time_short'}
							cy={'actualBillableTime'}
							value={billableTime}
							numberType={NUMBER_TYPE.MONEY}
							type={'label'}
							variant={'m'}
							currency={currency}
						/>

						<FinancialNumber
							labelTranslationId={'expenses.billable_expenses'}
							cy={'projectExpenseRevenue'}
							value={revenueFromExpenses}
							numberType={NUMBER_TYPE.MONEY}
							type={'label'}
							variant={'m'}
							tooltipItem={{
								title: formatMessage({id: 'expenses.billable_expenses'}),
								details: formatMessage({id: 'project_budget.billable_expenses.tooltip'}),
							}}
							tooltipMaxWidth={'300px'}
							currency={currency}
						/>
					</SummaryCard>
				)
			)}

			<SummaryCard
				title={formatMessage({id: 'invoicing.invoices_created'})}
				subtitle={getInvoiceCountText(invoices.length, 'invoicing.no_invoices_created', formatMessage)}
			>
				<FinancialNumber
					cy={'invoiceTotal'}
					value={invoiceTotal}
					numberType={NUMBER_TYPE.MONEY}
					variant={'l'}
					currency={currency}
					zeroValuesHidden={true}
				/>
			</SummaryCard>

			<SummaryCard
				title={formatMessage({id: 'invoicing.invoices_send_exported'})}
				subtitle={getInvoiceCountText(
					sendOrExportedInvoices.length,
					'invoicing.no_invoices_sent_or_exported',
					formatMessage
				)}
			>
				<FinancialNumber
					cy={'sendOrExportedInvoiceTotal'}
					value={sendOrExportedInvoiceTotal}
					numberType={NUMBER_TYPE.MONEY}
					variant={'l'}
					currency={currency}
					zeroValuesHidden={true}
				/>
			</SummaryCard>
			<SummaryCard
				title={formatMessage({id: 'invoicing.invoice_payments'})}
				subtitle={getInvoiceCountText(invoicesWithPayments.length, 'invoicing.no_invoice_payments', formatMessage)}
			>
				<FinancialNumber
					cy={'invoicePaymentTotal'}
					value={invoicePaymentTotal}
					numberType={NUMBER_TYPE.MONEY}
					variant={'l'}
					currency={currency}
					zeroValuesHidden={true}
				/>
			</SummaryCard>
			<SummaryCard
				title={formatMessage({id: 'invoicing.overdue_invoices'})}
				subtitle={getInvoiceCountText(overdueInvoices.length, 'invoicing.no_overdue_invoices', formatMessage)}
			>
				<FinancialNumber
					cy={'overdueInvoiceTotal'}
					value={overdueInvoiceTotal}
					numberType={NUMBER_TYPE.MONEY}
					variant={'l'}
					currency={currency}
					zeroValuesHidden={true}
				/>
			</SummaryCard>
		</SummaryCard.Container>
	);
};

export default createFragmentContainer(ProjectInvoicingTotals, {
	project: graphql`
		fragment ProjectInvoicingTotals_project on ProjectType {
			id
			name
			rateCard {
				currency
			}
			budget
			budgetType
			defaultPeriodBudgetType
			invoices {
				edges {
					node {
						status
						invoicedWithoutTaxProjectCurrency
						paidWithoutTaxProjectCurrency
						unpaidWithoutTaxProjectCurrency
						dueDay
						dueMonth
						dueYear
					}
				}
			}
			timeFinancialNumbers: financialNumbers(
				searchQuery: {filters: [{field: ROLE, operator: NOT_IN, value: "Um9sZTotMQ=="}]}
				convertToProjectCurrency: true
			) {
				billableActualTimeAndExpenses
			}
			financialNumbers(convertToProjectCurrency: true) {
				totalRevenueRecognition
				totalActualRevenueRecognition
				expenseRevenuePercentage
			}
			expenseItems(first: 10000) @connection(key: "Project_expenseItems") {
				edges {
					node {
						approved
						billable
						partOfFixedPrice
						expenseYear
						expenseMonth
						expenseDay
						price
						cost
						quantity
					}
				}
			}
		}
	`,
});
