import React from 'react';
import {useIntl} from 'react-intl';

import InvoiceProjectsTable from './invoice_projects_table';
import {ProjectTablesContainer, ProjectTablesWrapper} from '../../../../styles/v2/invoice_modals_v2_styled';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {BUDGET_TYPE} from '../../../../constants';

const InvoiceProjectTables = ({onProjectSelect, onSelectAll, selectedProjectsIds, projectData, isProjectInvoicing}) => {
	const intl = useIntl();

	return (
		<ProjectTablesContainer data-userpilot={'projects-tables'}>
			<CustomScrollDiv>
				<ProjectTablesWrapper>
					<InvoiceProjectsTable
						projects={projectData?.time_material?.projects}
						budgetTypeName={intl.formatMessage({id: 'common.time_material'})}
						onProjectSelect={onProjectSelect}
						onSelectAll={onSelectAll}
						selectedProjectsIds={selectedProjectsIds}
						budgetType={BUDGET_TYPE.TIME_AND_MATERIALS}
						isProjectInvoicing={isProjectInvoicing}
					/>
					<InvoiceProjectsTable
						projects={projectData?.fixed_price?.projects}
						budgetTypeName={intl.formatMessage({id: 'common.fixed_price'})}
						onProjectSelect={onProjectSelect}
						onSelectAll={onSelectAll}
						selectedProjectsIds={selectedProjectsIds}
						budgetType={BUDGET_TYPE.FIXED_PRICE_V2}
						isProjectInvoicing={isProjectInvoicing}
					/>
					<InvoiceProjectsTable
						projects={projectData?.retainer?.projects}
						budgetTypeName={intl.formatMessage({id: 'common.retainer'})}
						onProjectSelect={onProjectSelect}
						onSelectAll={onSelectAll}
						selectedProjectsIds={selectedProjectsIds}
						budgetType={BUDGET_TYPE.RETAINER}
						isProjectInvoicing={isProjectInvoicing}
					/>
				</ProjectTablesWrapper>
			</CustomScrollDiv>
		</ProjectTablesContainer>
	);
};

export default InvoiceProjectTables;
