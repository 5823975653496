import React from 'react';
import {InvoiceLinesTableContainer} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {InvoiceRowHeader} from 'web-components';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InvoiceRow from '../../../components/invoice-row/Invoice_row';
import Util from '../../../forecast-app/shared/util/util';
import {RoundingEntities} from '../../../forecast-app/shared/util/RoundingUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const InvoiceTimeRegsList = ({intl, timeRegs, clientProjects, currency}) => {
	const columns = {
		simpleDate: true,
		project: true,
		name: true,
		notes: true,
		quantity: true,
		unitPrice: true,
		totalPrice: true,
	};

	return (
		<>
			<InvoiceLinesTableContainer data-userpilot={'time-reg-table'}>
				<InvoiceRowHeader intl={intl} columns={columns} />
				<CustomScrollDiv>
					{timeRegs.map((timeReg, index) => {
						const project = clientProjects.find(project => {
							if (timeReg.project) return project.id === timeReg.project.id;
							return project.id === timeReg.task.project.id;
						});
						const timeRegHours = timeReg.billableMinutesRegistered / 60;
						const quantity = Math.round(timeRegHours * 10000) / 10000;
						const unitPrice = timeReg.rate || 0.0;
						return (
							<InvoiceRow
								key={index}
								columns={columns}
								simpleDate={Util.CreateMomentDate(timeReg.year, timeReg.month, timeReg.day).format(
									'D MMM YYYY'
								)}
								project={project}
								name={timeReg.person.fullName}
								notes={timeReg.notes || ''}
								quantity={quantity}
								unitPrice={
									hasFeatureFlag('rate_rounding')
										? Util.getFormattedNumberWithCurrencyFromEntity(
												Util.GetCurrencySymbol(currency),
												RoundingEntities.RATE.name,
												unitPrice
										  )
										: Util.getFormattedNumberWithCurrency(Util.GetCurrencySymbol(currency), unitPrice, intl)
								}
							/>
						);
					})}
				</CustomScrollDiv>
			</InvoiceLinesTableContainer>
		</>
	);
};

export default InvoiceTimeRegsList;
