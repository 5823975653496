import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const PredictionInfo = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	margin-bottom: 16px;
`;

export const ChartStatsContainer = styled.div`
	display: flex;
`;

export const ChartContainer = styled.div`
	min-width: 566px;
	width: 566px;
	height: 300px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 16px;
`;

export const StatsContainer = styled.div`
	margin-right: 24px;
`;

export const StatContainer = styled.div`
	width: 194px;
	padding: 16px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	margin-bottom: 16px;
`;

export const StatLine = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
	&:last-child {
		margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}px` : 0)};
	}
`;

const StatText = styled.span`
	font-size: 11px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const StatTitle = styled(StatText)`
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const StatTitleBold = styled(StatTitle)`
	font-weight: 500;
`;

export const StatValue = styled(StatText)`
	font-weight: 500;
	text-align: right;
	color: ${props =>
		props.variant && (props.variant === 'DANGER' ? '#F84746' : props.variant === 'WARNING' ? '#FFC92D' : '#00DA80')};
`;
