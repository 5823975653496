/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TransferToPersonModal_viewer$ref: FragmentReference;
declare export opaque type TransferToPersonModal_viewer$fragmentType: TransferToPersonModal_viewer$ref;
export type TransferToPersonModal_viewer = {|
  +id: string,
  +email: ?string,
  +company: ?{|
    +monday: ?number,
    +tuesday: ?number,
    +wednesday: ?number,
    +thursday: ?number,
    +friday: ?number,
    +saturday: ?number,
    +sunday: ?number,
    +placeholderAllocation?: ?{|
      +id: string,
      +startDate: ?string,
      +endDate: ?string,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +description: ?string,
    |},
    +placeholder?: ?{|
      +id: string,
      +name: ?string,
      +project: ?{|
        +id: string
      |},
      +projectGroupId: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +skills: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +active: ?boolean,
          +firstName: ?string,
          +lastName: ?string,
          +fullName: ?string,
          +profilePictureId: ?string,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +skills: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +$refType: TransferToPersonModal_viewer$ref,
|};
export type TransferToPersonModal_viewer$data = TransferToPersonModal_viewer;
export type TransferToPersonModal_viewer$key = {
  +$data?: TransferToPersonModal_viewer$data,
  +$fragmentRefs: TransferToPersonModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  (v0/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillTypeConnection",
  "kind": "LinkedField",
  "name": "skills",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "placeholderId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "placeholderAllocationId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadPlaceholder",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loadAllocation",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransferToPersonModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeClientUsers",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            },
            {
              "kind": "Literal",
              "name": "onlyActive",
              "value": true
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "active",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:true,first:1000000,onlyActive:true)"
        },
        {
          "condition": "loadAllocation",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "placeholderAllocationId",
                  "variableName": "placeholderAllocationId"
                }
              ],
              "concreteType": "PlaceholderAllocation",
              "kind": "LinkedField",
              "name": "placeholderAllocation",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endDate",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        },
        {
          "condition": "loadPlaceholder",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "placeholderId",
                  "variableName": "placeholderId"
                }
              ],
              "concreteType": "Placeholder",
              "kind": "LinkedField",
              "name": "placeholder",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectGroupId",
                  "storageKey": null
                },
                (v9/*: any*/),
                (v11/*: any*/)
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ce46d1a25e17babd45c4bc401142067';

module.exports = node;
