/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllocationAssistanceModal_viewer$ref = any;
export type AllocationAssistanceModal_QueryVariables = {|
  projectId?: ?string,
  performance?: ?number,
  focus?: ?number,
|};
export type AllocationAssistanceModal_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: AllocationAssistanceModal_viewer$ref,
  |}
|};
export type AllocationAssistanceModal_Query = {|
  variables: AllocationAssistanceModal_QueryVariables,
  response: AllocationAssistanceModal_QueryResponse,
|};
*/


/*
query AllocationAssistanceModal_Query(
  $projectId: ID
  $performance: Float
  $focus: Float
) {
  viewer {
    component(name: "allocation_assistance_modal")
    actualPersonId
    id
    ...AllocationAssistanceModal_viewer_v5Fb7
  }
}

fragment AllocationAssistanceModal_viewer_v5Fb7 on Viewer {
  company {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    id
  }
  project(internalId: $projectId) {
    id
    projectHealth {
      allocationAssistance(focus: $focus, performance: $performance) {
        role {
          id
          name
        }
        missingAllocation
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "performance",
    "type": "Float"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "focus",
    "type": "Float"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "allocation_assistance_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"allocation_assistance_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Variable",
  "name": "focus",
  "variableName": "focus"
},
v5 = {
  "kind": "Variable",
  "name": "performance",
  "variableName": "performance"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AllocationAssistanceModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "AllocationAssistanceModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AllocationAssistanceModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tuesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wednesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thursday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "friday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saturday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sunday",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectHealth",
                "kind": "LinkedField",
                "name": "projectHealth",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "concreteType": "MissingRoleAllocationType",
                    "kind": "LinkedField",
                    "name": "allocationAssistance",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "missingAllocation",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AllocationAssistanceModal_Query",
    "operationKind": "query",
    "text": "query AllocationAssistanceModal_Query(\n  $projectId: ID\n  $performance: Float\n  $focus: Float\n) {\n  viewer {\n    component(name: \"allocation_assistance_modal\")\n    actualPersonId\n    id\n    ...AllocationAssistanceModal_viewer_v5Fb7\n  }\n}\n\nfragment AllocationAssistanceModal_viewer_v5Fb7 on Viewer {\n  company {\n    monday\n    tuesday\n    wednesday\n    thursday\n    friday\n    saturday\n    sunday\n    id\n  }\n  project(internalId: $projectId) {\n    id\n    projectHealth {\n      allocationAssistance(focus: $focus, performance: $performance) {\n        role {\n          id\n          name\n        }\n        missingAllocation\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a494524acfae43eca62506078774402a';

module.exports = node;
