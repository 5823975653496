import {Checkbox} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {FormattedMessage} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import {hideLoader, showLoader} from '../global_loader';
import Util from '../../forecast-app/shared/util/util';
import ImportSalesforceClientsMutation from '../../mutations/ImportSalesforceClientsMutation';
import DirectApi from '../../directApi';

const salesforceImportClientsModal = ({intl, closeModal, companyId, retry}) => {
	//state variables
	const [selectedCustomers, setSelectedCustomers] = useState([]);
	const [salesforceCustomers, setSalesforceCustomers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [importing, setImporting] = useState(false);
	const [fetchingCustomers, setFetchingCustomers] = useState(true);
	const [searchInput, setSearchInput] = useState('');
	useEffect(() => {
		DirectApi.Fetch('salesforce/unimported_accounts').then(accounts => {
			setSalesforceCustomers(
				accounts.list.sort((a, b) => (a.Name.toUpperCase().trim() > b.Name.toUpperCase().trim() ? 1 : -1))
			);
			setFetchingCustomers(false);
		});
	}, []);

	const toggleSelectAll = () => {
		if (selectAll) {
			setSelectedCustomers([]);
			setSelectAll(false);
		} else {
			setSelectedCustomers(salesforceCustomers.map(customer => customer.Id));
			setSelectAll(true);
		}
	};

	const toggleContact = salesforceCustomerId => {
		if (selectedCustomers.some(selectedCustomerId => selectedCustomerId === salesforceCustomerId)) {
			const newSelectedCustomers = selectedCustomers.filter(
				selectedCustomerId => selectedCustomerId !== salesforceCustomerId
			);
			setSelectedCustomers(newSelectedCustomers);
			setSelectAll(false);
		} else {
			const newSelectedCustomers = [...selectedCustomers, salesforceCustomerId];
			setSelectedCustomers(newSelectedCustomers);
		}
	};

	const syncContacts = () => {
		setImporting(true);
		showLoader();
		Util.CommitMutation(
			ImportSalesforceClientsMutation,
			{
				companyId,
				clientIds: selectedCustomers,
			},
			() => {
				setImporting(false);
				if (retry) retry();
				closeModal();
				hideLoader();
			}
		);
	};

	const {formatMessage} = intl;

	const content = (
		<div className="integration-sync-clients-modal-content">
			<div className="integration-sync-clients-modal-description">
				<FormattedMessage id="integrations.salesforce.sync_clients.description" />
			</div>
			{fetchingCustomers ? (
				<InlineLoader />
			) : (
				<>
					<input
						autoFocus={true}
						onChange={event => {
							setSearchInput(event.target.value);
						}}
					/>

					<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
						<div className="integration-clients">
							{salesforceCustomers && salesforceCustomers.length > 0 ? (
								<>
									<div className="integration-client" key={'0'}>
										<Checkbox checked={selectAll} onClick={toggleSelectAll} />
										<FormattedMessage id="common.select_all" />
									</div>
									{salesforceCustomers
										.filter(x => x.Name.toLowerCase().includes(searchInput.toLowerCase()))
										.map(salesforceCustomer => {
											return (
												<div className="integration-client" key={salesforceCustomer.Id}>
													<Checkbox
														checked={selectedCustomers.includes(salesforceCustomer.Id)}
														onClick={() => {
															toggleContact(salesforceCustomer.Id);
														}}
													/>
													{salesforceCustomer.Name}
												</div>
											);
										})}
								</>
							) : (
								<FormattedMessage id="integrations.salesforce.all_accounts_synched" />
							)}
						</div>
					</CustomScrollDiv>
				</>
			)}
		</div>
	);

	return (
		<GenericModal
			headerText={<FormattedMessage id="integrations.salesforce.sync_clients" />}
			closeModal={closeModal}
			className="integration-sync-clients-modal"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.create_x_clients'}, {numOfClients: selectedCustomers.length}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: syncContacts.bind(this),
					disabled: importing || selectedCustomers.length === 0,
					defaultCallback: () => null,
					preventDefaultClose: true,
				},
			]}
			content={content}
		/>
	);
};

export default salesforceImportClientsModal;
