/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ExportNovaInsightsStatsCSV_viewer$ref = any;
export type ExportNovaInsightsStatsCSV_QueryVariables = {|
  includeProjectGroups?: ?boolean
|};
export type ExportNovaInsightsStatsCSV_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ExportNovaInsightsStatsCSV_viewer$ref,
  |}
|};
export type ExportNovaInsightsStatsCSV_Query = {|
  variables: ExportNovaInsightsStatsCSV_QueryVariables,
  response: ExportNovaInsightsStatsCSV_QueryResponse,
|};
*/


/*
query ExportNovaInsightsStatsCSV_Query(
  $includeProjectGroups: Boolean
) {
  viewer {
    actualPersonId
    component(name: "export_nova_insights_stats_csv")
    ...ExportNovaInsightsStatsCSV_viewer_3QAw7H
    id
  }
}

fragment ExportNovaInsightsStatsCSV_viewer_3QAw7H on Viewer {
  missionControlData {
    companiesWithNovaInsights(includeProjectGroups: $includeProjectGroups) {
      companyId
      companyName
      runningProjectsCount
      projectsInConnectedProjectCount
      timeRegProjectsCount
      doneTaskProjectsCount
      allocationProjectsCount
      estimatedProjectsCount
      predictedEndWithoutTimeRegAvailability
      predictedEndWithoutDoneTaskAvailability
      predictedEndWithoutAllocationAvailability
      predictedEndWithoutTimeRegDoneTaskAvailability
      predictedEndWithoutTimeRegDoneTaskAllocationAvailability
      predictedEndAvailability
      focusAvailability
      performanceAvailability
      novaInsightCompatibilityScore
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeProjectGroups",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "export_nova_insights_stats_csv"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"export_nova_insights_stats_csv\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "includeProjectGroups",
    "variableName": "includeProjectGroups"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportNovaInsightsStatsCSV_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ExportNovaInsightsStatsCSV_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportNovaInsightsStatsCSV_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "MissionControlCompanyNIStatsType",
                "kind": "LinkedField",
                "name": "companiesWithNovaInsights",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "runningProjectsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectsInConnectedProjectCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeRegProjectsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneTaskProjectsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allocationProjectsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "estimatedProjectsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndWithoutTimeRegAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndWithoutDoneTaskAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndWithoutAllocationAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndWithoutTimeRegDoneTaskAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndWithoutTimeRegDoneTaskAllocationAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "predictedEndAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "focusAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performanceAvailability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "novaInsightCompatibilityScore",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ExportNovaInsightsStatsCSV_Query",
    "operationKind": "query",
    "text": "query ExportNovaInsightsStatsCSV_Query(\n  $includeProjectGroups: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"export_nova_insights_stats_csv\")\n    ...ExportNovaInsightsStatsCSV_viewer_3QAw7H\n    id\n  }\n}\n\nfragment ExportNovaInsightsStatsCSV_viewer_3QAw7H on Viewer {\n  missionControlData {\n    companiesWithNovaInsights(includeProjectGroups: $includeProjectGroups) {\n      companyId\n      companyName\n      runningProjectsCount\n      projectsInConnectedProjectCount\n      timeRegProjectsCount\n      doneTaskProjectsCount\n      allocationProjectsCount\n      estimatedProjectsCount\n      predictedEndWithoutTimeRegAvailability\n      predictedEndWithoutDoneTaskAvailability\n      predictedEndWithoutAllocationAvailability\n      predictedEndWithoutTimeRegDoneTaskAvailability\n      predictedEndWithoutTimeRegDoneTaskAllocationAvailability\n      predictedEndAvailability\n      focusAvailability\n      performanceAvailability\n      novaInsightCompatibilityScore\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9b12b3d519e3dc9f180e7931c2206e5';

module.exports = node;
