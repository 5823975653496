import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import ProfileProjectStatusUpdates from './ProfileProjectStatusUpdates';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import SettingsProfileSubNav from '../settings/SettingsProfileSubNav';
import Util from '../../forecast-app/shared/util/util';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';

const ProfileProjectStatusUpdatesPage = ({viewer}) => {
	const history = useHistory();
	const shouldShowProjectStatusUpdates = Util.hasStatusAugmentation(viewer.company, viewer.disableNotifications);

	useEffect(() => {
		// Check access
		if (!shouldShowProjectStatusUpdates) {
			history.push('/');
		}
		document.title = 'Project Status Updates';
	}, []);

	return shouldShowProjectStatusUpdates ? (
		<>
			<TopHeaderBarWrapper>
				<TopHeaderBar title="Project Status Updates" />
			</TopHeaderBarWrapper>
			<SettingsProfileSubNav viewer={viewer} />
			<CustomScrollDiv>
				<ProfileProjectStatusUpdates viewer={viewer} />
			</CustomScrollDiv>
		</>
	) : null;
};

export const ProfileProjectStatusUpdatesPageQuery = graphql`
	query ProfileProjectStatusUpdatesPage_Query {
		viewer {
			actualPersonId
			component(name: "profile-project-updates-page")
			...ProfileProjectStatusUpdatesPage_viewer
		}
	}
`;

export default createFragmentContainer(ProfileProjectStatusUpdatesPage, {
	viewer: graphql`
		fragment ProfileProjectStatusUpdatesPage_viewer on Viewer {
			projectOwner
			disableNotifications
			company {
				forecastDemo
				tier
			}
			...ProfileProjectStatusUpdates_viewer
			...SettingsProfileSubNav_viewer
		}
	`,
});
