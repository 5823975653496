/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AssignSkillsToPersonsModal_company$ref = any;
export type AssignSkillsToPersonsModal_QueryVariables = {||};
export type AssignSkillsToPersonsModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +company: ?{|
      +$fragmentRefs: AssignSkillsToPersonsModal_company$ref
    |},
  |}
|};
export type AssignSkillsToPersonsModal_Query = {|
  variables: AssignSkillsToPersonsModal_QueryVariables,
  response: AssignSkillsToPersonsModal_QueryResponse,
|};
*/


/*
query AssignSkillsToPersonsModal_Query {
  viewer {
    actualPersonId
    component(name: "assign_skills_to_persons_modal")
    company {
      ...AssignSkillsToPersonsModal_company
      id
    }
    id
  }
}

fragment AssignSkillsToPersonsModal_company on Company {
  id
  allPersons(first: 10000, excludeClientUsers: true) {
    edges {
      node {
        ...PersonsPreviewSelector_persons
        id
      }
    }
  }
}

fragment PersonsPreviewSelector_persons on Person {
  id
  fullName
  profilePictureId
  active
  permissions
  role {
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "assign_skills_to_persons_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"assign_skills_to_persons_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignSkillsToPersonsModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssignSkillsToPersonsModal_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AssignSkillsToPersonsModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeClientUsers",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                  }
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permissions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AssignSkillsToPersonsModal_Query",
    "operationKind": "query",
    "text": "query AssignSkillsToPersonsModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"assign_skills_to_persons_modal\")\n    company {\n      ...AssignSkillsToPersonsModal_company\n      id\n    }\n    id\n  }\n}\n\nfragment AssignSkillsToPersonsModal_company on Company {\n  id\n  allPersons(first: 10000, excludeClientUsers: true) {\n    edges {\n      node {\n        ...PersonsPreviewSelector_persons\n        id\n      }\n    }\n  }\n}\n\nfragment PersonsPreviewSelector_persons on Person {\n  id\n  fullName\n  profilePictureId\n  active\n  permissions\n  role {\n    name\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98e4fc86bb20f6b881dabacee66a1049';

module.exports = node;
