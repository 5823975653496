import moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import DeletePlaceholderMutation from '../../../mutations/delete_placeholder_mutation';
import DuplicatePlaceholderMutation from '../../../mutations/duplicate_placeholder_mutation';
import ReplacePlaceholderMutation from '../../../mutations/replace_placeholder_mutation';
import TransferPlaceholderMutation from '../../../mutations/transfer_placeholder_mutation';
import BulkPlaceholderAllocationsMutation from '../../../mutations/bulk_placeholder_allocations_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import AssignPlaceholderAllocationsToPersonMutation from '../../../mutations/AssignPlaceholderAllocationsToPersonMutation';
import {handleDeletePlaceholder} from '../../../components/canvas-scheduling/actions/handle_delete_placeholder';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const getAllocationTotal = (allocation, startMoment = null, endMoment = null) => {
	const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
	const startDate =
		startMoment ||
		Util.CreateNonUtcMomentDate(allocation.startYear, allocation.startMonth, allocation.startDay) ||
		Util.CreateNonUtcMomentDateFromString(allocation.startDate);
	const endDate =
		endMoment ||
		Util.CreateNonUtcMomentDate(allocation.endYear, allocation.endMonth, allocation.endDay) ||
		Util.CreateNonUtcMomentDateFromString(allocation.endDate);
	return [1, 2, 3, 4, 5, 6, 7]
		.map(dayIndex => Util.weekdaysBetween(startDate, endDate, dayIndex) * allocation[dayNames[dayIndex - 1]])
		.reduce((total, minutes) => (total += minutes), 0);
};

export const getAverageWorkingMinutes = (distributionArray, companyWeeklyMinutes) => {
	const totalWorkingDays = distributionArray.reduce((total, minutes) => total + (minutes ? 1 : 0), 0);
	return companyWeeklyMinutes / totalWorkingDays;
};

export const getPercentage = (distributionArray, companyWeeklyMinutes) => {
	const weeklyAllocationMinutes = distributionArray.reduce((total, value) => total + value, 0);
	return (weeklyAllocationMinutes / companyWeeklyMinutes) * 100;
};

export const applyDistributionArray = (workingHoursObject, distributionArray) => {
	workingHoursObject.monday = distributionArray[0];
	workingHoursObject.tuesday = distributionArray[1];
	workingHoursObject.wednesday = distributionArray[2];
	workingHoursObject.thursday = distributionArray[3];
	workingHoursObject.friday = distributionArray[4];
	workingHoursObject.saturday = distributionArray[5];
	workingHoursObject.sunday = distributionArray[6];
	return workingHoursObject;
};

export const getTotal = (distributionArray, startDate, endDate) => {
	return getAllocationTotal(applyDistributionArray({}, distributionArray), startDate, endDate);
};

export const handleMutationSuccess = result => {
	if (Util.isScheduling()) {
		Util.dispatchScheduleEvent(result);
	}
};

export const duplicatePlaceholderMutation = (formatMessage, placeholderId) => {
	Util.CommitMutation(
		DuplicatePlaceholderMutation,
		{
			id: placeholderId,
		},
		response => {
			handleMutationSuccess(response);
			createToast({
				duration: 5000,
				message: formatMessage({id: 'scheduling.placeholder_duplicated'}),
			});
		}
	);
};

export const deletePlaceholderMutation = (
	formatMessage,
	placeholderId,
	onSuccess,
	placeholderAllocations,
	isFromContextMenu
) => {
	Util.CommitMutation(
		DeletePlaceholderMutation,
		{
			id: placeholderId,
			trackingOptions: {
				isFromContextMenu,
			},
		},
		response => {
			if (onSuccess) {
				onSuccess(response);

				if (placeholderAllocations?.length > 0) {
					tracking.trackEvent('Placeholder With Allocations Was Deleted');
					trackEvent('Placeholder With Allocations', 'Deleted');
				}
			}

			createToast({
				duration: 5000,
				message: formatMessage({id: 'scheduling.placeholder_deleted'}),
			});
		}
	);
};

export const replacePlaceholderMutation = (
	formatMessage,
	placeholderId,
	personId,
	personName,
	deletePlaceholder,
	onSuccess
) => {
	Util.CommitMutation(
		ReplacePlaceholderMutation,
		{
			placeholderId,
			personId,
			deletePlaceholder,
		},
		response => {
			if (onSuccess) {
				onSuccess(response);
			}

			createToast({
				duration: 5000,
				message: formatMessage({id: 'scheduling.placeholder_replaced'}, {personName: personName}),
			});
		}
	);
};

export const transferPlaceholderMutation = (
	formatMessage,
	placeholderAllocation,
	personAllocation,
	selectedPersonName,
	onSuccess
) => {
	Util.CommitMutation(
		TransferPlaceholderMutation,
		{
			placeholderAllocation,
			personAllocation,
		},
		response => {
			if (onSuccess) {
				onSuccess(response);
			}
			createToast({
				duration: 5000,
				message: formatMessage({id: 'scheduling.placeholder_transferred'}, {personName: selectedPersonName}),
			});
		}
	);
};
export const assignPlaceholderAllocationsToPersonMutation = (formatMessage, input, personName, onSuccess) => {
	Util.CommitMutation(AssignPlaceholderAllocationsToPersonMutation, input, response => {
		if (onSuccess) {
			onSuccess(response);
		}

		const allocationCount = response.assignPlaceholderAllocationsToPerson?.allocations?.length || 0;
		const placeholderEmpty = response.assignPlaceholderAllocationsToPerson?.placeholderEmpty;

		if (placeholderEmpty && !hasFeatureFlag('capacity_planning_beta_2_improvements')) {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'scheduling.placeholders.staffing.staffed_toast'}),
				actionText: formatMessage({id: 'placeholder.delete_placeholder'}),
				actionCallback: () => {
					trackEvent('Toast Delete Placeholder', 'Clicked');
					handleDeletePlaceholder(input.placeholderId, null, formatMessage);
				},
			});
		} else {
			createToast({
				duration: 5000,
				message: formatMessage(
					{id: 'scheduling.placeholder_allocations_assigned_to_person'},
					{personName, allocationCount}
				),
			});
		}
	});
};

export const dividePlaceholderAllocationMutation = (formatMessage, placeholderAllocations, onSuccess) => {
	tracking.trackEvent('Divide Placeholder Allocation');
	trackEvent('Placeholder Allocation', 'Divided');
	Util.CommitMutation(
		BulkPlaceholderAllocationsMutation,
		{
			placeholderAllocations,
		},
		response => {
			if (onSuccess) {
				onSuccess(response);
			}
			createToast({
				duration: 5000,
				message: formatMessage(
					{id: 'scheduling.placeholder_allocation_divided'},
					{allocationCount: placeholderAllocations?.length || 0}
				),
			});
		}
	);
};

function projectDatesToMoment(project) {
	let projectStartDate = Util.CreateNonUtcMomentDate(
		project.projectStartYear,
		project.projectStartMonth,
		project.projectStartDay
	);
	let projectEndDate = Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);
	return {projectStartDate, projectEndDate};
}

export const getProjectDates = project => {
	if (project) {
		let {projectStartDate, projectEndDate} = projectDatesToMoment(project);
		projectStartDate = projectStartDate ? projectStartDate : moment();
		projectEndDate = projectEndDate ? projectEndDate : moment();
		return {projectStartDate, projectEndDate};
	} else {
		return null;
	}
};

export const getProjectGroupDates = projectGroup => {
	if (projectGroup) {
		const projects = projectGroup.projects.edges.map(project => project.node);
		let projectStartDate = null;
		let projectEndDate = null;

		for (let project of projects) {
			const {projectStartDate: currentProjectStartDate, projectEndDate: currentProjectEndDate} =
				projectDatesToMoment(project);

			if (!projectStartDate || (currentProjectStartDate && currentProjectStartDate.isBefore(projectStartDate))) {
				projectStartDate = currentProjectStartDate;
			}

			if (!projectEndDate || (currentProjectEndDate && currentProjectEndDate.isAfter(projectEndDate))) {
				projectEndDate = currentProjectEndDate;
			}
		}
		projectStartDate = projectStartDate ? projectStartDate : moment();
		projectEndDate = projectEndDate ? projectEndDate : moment();
		return {projectStartDate, projectEndDate};
	} else {
		return null;
	}
};

export const filterPersons = (persons, placeholderRoleId, placeholderSkillIds) => {
	return persons.filter(person => {
		const personSkillIds = person.node.skills?.edges.map(edge => edge.node.id);
		return (
			person.node.role?.id === placeholderRoleId &&
			placeholderSkillIds.every(placeholderSkillId => personSkillIds.includes(placeholderSkillId))
		);
	});
};

export const handleAllocationTotalChange = (value, distributionArray, companyWeeklyMinutes, startDate, endDate) => {
	const distributionArrayClone = {...distributionArray};

	const allocationWeekdayCountArray = [1, 2, 3, 4, 5, 6, 7].map(e => Util.weekdaysBetween(startDate, endDate, e));
	const allocationWorkingDayCount = allocationWeekdayCountArray.reduce((newTotal, weekdayCount, index) => {
		return newTotal + (distributionArrayClone[index] ? weekdayCount : 0);
	}, 0);
	const minutesPerDay = Math.round(value / allocationWorkingDayCount);
	const newDistributionArray = [];
	for (let i = 0; i < 7; i++) {
		newDistributionArray.push(distributionArrayClone[i] ? minutesPerDay : 0);
	}
	const newDistributionPercentage = getPercentage(newDistributionArray, companyWeeklyMinutes);
	const newTotal = getTotal(newDistributionArray, startDate, endDate);

	return {newTotal, newDistributionPercentage, newDistributionArray};
};

export const getDistributionArray = workingHoursObject => [
	workingHoursObject.monday,
	workingHoursObject.tuesday,
	workingHoursObject.wednesday,
	workingHoursObject.thursday,
	workingHoursObject.friday,
	workingHoursObject.saturday,
	workingHoursObject.sunday,
];

export const sumWeeklyMinutes = workingHoursObject =>
	workingHoursObject.monday +
	workingHoursObject.tuesday +
	workingHoursObject.wednesday +
	workingHoursObject.thursday +
	workingHoursObject.friday +
	workingHoursObject.saturday +
	workingHoursObject.sunday;
