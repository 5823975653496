import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {useIntl} from 'react-intl';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import Util from '../../../forecast-app/shared/util/util';
import DateRangePicker from '../../../components/new-ui/date-controls/date_range_picker';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import Input from '../../../components/new-ui/input_field';
import Placeholder from '../../../forecast-app/shared/components/placeholder/placeholder';
import {
	applyDistributionArray,
	dividePlaceholderAllocationMutation,
	getAllocationTotal,
	getAverageWorkingMinutes,
	getDistributionArray,
	getPercentage,
	getTotal,
	handleAllocationTotalChange,
	handleMutationSuccess,
	sumWeeklyMinutes,
} from './PlaceholderAllocationUtils';
import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../css_variables';
import styled from 'styled-components';
import removeItemIcon from '../../../images/components/close-modal.svg';
import BulkPlaceholderAllocationsMutation from '../../../mutations/bulk_placeholder_allocations_mutation';
import {generateStaffingId} from '../../../components/canvas-scheduling/placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';

const Divider = Styled.div`
    margin: 23px 0;
    border-top: 1px solid #E7E7F3;
`;

const AddAllocation = Styled.div`
    display: flex;
    justify-content: flex-end;
    button {
        border: none;
        background: none;
        font-size: 13px;
        color: ${CSS_CONSTANTS.brand500};
   }
 `;

const AllocationRow = styled.div`
	display: flex;
	flex-direction: row;
	> * {
		&:not(:last-child) {
			margin-right: 8px;
		}
		width: 120px;
		&:first-child {
			flex: auto;
		}
	}
`;

const RemoveAllocation = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
        border: none;
        background: none;
        width: 12px;
        height: 12px;
        background-size: 12px;
        background-image: url(${removeItemIcon});
        background-repeat: no-repeat;
    }
`;

const EMPTY_ALLOCATION = {minutes: 0, percentage: 0, distribution: [0, 0, 0, 0, 0, 0, 0]};

const PlaceholderDivideAllocationModal = ({
	closeModal,
	viewer,
	staffingModeActive,
	placeholderInput,
	placeholderAllocationInput,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {company} = viewer;
	let {placeholder, placeholderAllocation} = company;

	if (staffingModeActive) {
		placeholder = placeholderInput;
		placeholderAllocation = placeholderAllocationInput;
	}

	const distributionArray = getDistributionArray(placeholderAllocation);
	const companyDistributionArray = getDistributionArray(company);
	const companyWeeklyMinutes = sumWeeklyMinutes(company);

	const [biggestAllocationIndex, setBiggestAllocationIndex] = useState(0);
	const originalMinutes = useMemo(() => getAllocationTotal(placeholderAllocation), []);
	const [placeholderTotal, setPlaceholderTotal] = useState(originalMinutes);
	const [placeholderPercentage, setPlaceholderPercentage] = useState(getPercentage(distributionArray, companyWeeklyMinutes));
	const [placeholderDistributionArray, setPlaceholderDistributionArray] = useState(distributionArray);
	const [addedAllocations, setAddedAllocations] = useState([EMPTY_ALLOCATION]);

	const startDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.startDate);
	const endDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.endDate);

	const percentageValid = placeholderPercentage >= 0;
	const totalValid = placeholderTotal >= 0;

	const calculateAllocationChange = total =>
		handleAllocationTotalChange(total, distributionArray, companyWeeklyMinutes, startDate, endDate);

	const [forceUpdate, forceUpdateHourInput] = useReducer(x => x + 1, 0);

	useEffect(() => {
		const addedMinutes = addedAllocations.map(a => a.minutes).reduce((a, b) => a + b, 0);
		const placeholderResult = calculateAllocationChange(originalMinutes - addedMinutes);
		setPlaceholderTotal(placeholderResult.newTotal);
		setPlaceholderPercentage(placeholderResult.newDistributionPercentage);
		setPlaceholderDistributionArray(placeholderResult.newDistributionArray);

		const maxMinutes = Math.max(...addedAllocations.map(allocation => allocation.minutes));
		setBiggestAllocationIndex(addedAllocations.findIndex(allocation => allocation.minutes === maxMinutes));
		forceUpdateHourInput();
	}, [addedAllocations]);

	const dividePlaceholderAllocation = () => {
		const baseAllocation = {
			startDate: placeholderAllocation.startDate,
			endDate: placeholderAllocation.endDate,
			description: placeholderAllocation.description,
			placeholderId: placeholder.id,
		};
		const placeholderAllocations = [
			applyDistributionArray({id: placeholderAllocation.id, ...baseAllocation}, placeholderDistributionArray),
		];
		for (const addedAllocation of addedAllocations) {
			placeholderAllocations.push(applyDistributionArray({...baseAllocation}, addedAllocation.distribution));
		}
		if (staffingModeActive) {
			for (const placeholderAllocation of placeholderAllocations) {
				if (!placeholderAllocation.id) {
					placeholderAllocation.id = generateStaffingId();
				}
			}

			const mutationResponse = BulkPlaceholderAllocationsMutation.getOptimisticResponse(placeholderAllocations);
			mutationResponse.personId = placeholderAllocation.personId;
			for (let pa of mutationResponse.bulkPlaceholderAllocations.placeholderAllocations) {
				pa.personId = placeholderAllocation.personId;
				pa.projectId = placeholderAllocation.projectId;
				pa.projectGroupId = placeholderAllocation.projectGroupId;
				pa.startYear = startDate.year();
				pa.startMonth = startDate.month() + 1;
				pa.startDay = startDate.date();
				pa.endYear = endDate.year();
				pa.endMonth = endDate.month() + 1;
				pa.endDay = endDate.date();
			}
			handleMutationSuccess(mutationResponse);
		} else {
			dividePlaceholderAllocationMutation(formatMessage, placeholderAllocations, handleMutationSuccess);
		}
	};

	const addAllocation = () => {
		setAddedAllocations([...addedAllocations, EMPTY_ALLOCATION]);
	};

	const removeAllocation = index => {
		const temp = [...addedAllocations];
		temp.splice(index, 1);
		setAddedAllocations(temp);
	};

	const changeTime = (allocation, minutes) => {
		const result = calculateAllocationChange(minutes);
		return {
			...allocation,
			minutes: result.newTotal,
			percentage: result.newDistributionPercentage,
			distribution: result.newDistributionArray,
		};
	};

	const changePercentage = (allocation, percentage) => {
		const distribution = companyDistributionArray.map(minutes => Math.floor((minutes * percentage) / 100));
		const minutes = getTotal(distribution, startDate, endDate);
		return {...allocation, percentage, minutes, distribution};
	};

	const updateAllocation = (changedIndex, changeValue) => {
		setAddedAllocations(
			addedAllocations.map((addedAllocation, index) =>
				changedIndex === index ? changeValue(addedAllocation) : addedAllocation
			)
		);
	};

	const onChangeTime = changedIndex => hours => {
		updateAllocation(changedIndex, allocation => changeTime(allocation, hours * 60));
	};

	const onChangePercentage = changedIndex => percentage => {
		updateAllocation(changedIndex, allocation => changePercentage(allocation, percentage));
	};

	return (
		<GenericModal
			closeModal={closeModal}
			content={
				<ModalBody>
					<Field>
						<Placeholder placeholder={placeholder} cy={'placeholder-info'} />
					</Field>
					<Divider />
					<Field>
						<AllocationRow>
							<DateRangePicker
								startDate={startDate}
								endDate={endDate}
								label={formatMessage({id: 'common.date'})}
								locked={true}
								clearable
							/>
							<HoursInput
								id="placeholder-allocation-modal-input"
								customClassName={'hours-input'}
								placeholder="0"
								label={formatMessage({id: 'scheduling.allocation_total'})}
								value={placeholderTotal / 60 || ''}
								modalView={true}
								disabled={true}
							/>
							<Input
								id="placeholder-allocation-modal-percentage"
								placeholder="0"
								type="number"
								label={`% ${formatMessage(
									{id: 'scheduling.allocation_modal.hours_pr_day'},
									{
										hours: Util.getHourEstimate(
											getAverageWorkingMinutes(distributionArray, companyWeeklyMinutes) / 60
										),
									}
								)}`}
								suffix={'%'}
								value={Math.round(placeholderPercentage * 100) / 100}
								locked={true}
								alignTextRight={true}
							/>
						</AllocationRow>
					</Field>
					{addedAllocations.map((addedAllocation, index) => (
						<Field key={'added-allocation-' + index}>
							<AllocationRow>
								<RemoveAllocation>
									{addedAllocations.length > 1 && <button onClick={() => removeAllocation(index)} />}
								</RemoveAllocation>
								<HoursInput
									customClassName={'hours-input'}
									placeholder="0"
									value={addedAllocation.minutes / 60}
									forceUpdate={forceUpdate}
									modalView={true}
									mutation={onChangeTime(index)}
									minuteLimit={originalMinutes}
									mutationValidChange
									invalidInput={!totalValid && index === biggestAllocationIndex}
								/>
								<Input
									customClassName={'percentage-input'}
									placeholder="0"
									type="number"
									suffix={'%'}
									value={Math.round(addedAllocation.percentage * 100) / 100}
									hideLabel={true}
									alignTextRight={true}
									onChange={onChangePercentage(index)}
									invalidInput={!percentageValid && index === biggestAllocationIndex}
								/>
							</AllocationRow>
						</Field>
					))}
					<Field>
						<AddAllocation>
							<button onClick={addAllocation}>
								{formatMessage({id: 'scheduling.divide_allocation.divide_further'})}
							</button>
						</AddAllocation>
					</Field>
				</ModalBody>
			}
			modalWidth={MODAL_WIDTH.MEDIUM}
			headerText={formatMessage(
				{id: 'scheduling.divide_allocation.header'},
				{hours: Util.convertMinutesToFullHour(originalMinutes, intl)}
			)}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					callback: closeModal,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.save'}),
					callback: dividePlaceholderAllocation,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					disabled: !totalValid || !percentageValid,
				},
			]}
		/>
	);
};

const placeholderDivideAllocationModalQuery = graphql`
	query PlaceholderDivideAllocationModal_Query(
		$placeholderId: ID
		$placeholderAllocationId: ID
		$loadPlaceholder: Boolean!
		$loadAllocation: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "placeholder_divide_allocation_modal")
			...PlaceholderDivideAllocationModal_viewer
				@arguments(
					placeholderId: $placeholderId
					placeholderAllocationId: $placeholderAllocationId
					loadPlaceholder: $loadPlaceholder
					loadAllocation: $loadAllocation
				)
		}
	}
`;

export {placeholderDivideAllocationModalQuery};

export default createFragmentContainer(PlaceholderDivideAllocationModal, {
	viewer: graphql`
		fragment PlaceholderDivideAllocationModal_viewer on Viewer
		@argumentDefinitions(
			placeholderId: {type: "ID"}
			placeholderAllocationId: {type: "ID"}
			loadAllocation: {type: "Boolean!"}
			loadPlaceholder: {type: "Boolean!"}
		) {
			id
			email
			company {
				monday
				tuesday
				wednesday
				wednesday
				thursday
				friday
				saturday
				sunday
				placeholderAllocation(placeholderAllocationId: $placeholderAllocationId) @include(if: $loadAllocation) {
					id
					startDate
					endDate
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					description
				}
				placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
					id
					name
					role {
						id
						name
					}
				}
			}
		}
	`,
});
