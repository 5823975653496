import { Checkbox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, SearchField, Text, ComboBox, } from '@forecast-it/design-system';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components/macro';
import { showLoader, hideLoader } from '../../global_loader';
import DirectApi from '../../../directApi';
import { CommitMutation } from '../../../mutations/ts/CommitMutation';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
import UpdateViewerMutation from '../../../mutations/update_viewer_mutation.modern';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: 1px solid #ccc;
`;
const ListWrapper = styled.div `
	height: 500px;
`;
const AdoLinkUserModal = ({ closeModal, isViewer, personId }) => {
    const { formatMessage } = useIntl();
    const [adoUsers, setAdoUsers] = useState([]);
    const [filteredAdoUsers, setFilteredAdoUsers] = useState([]);
    const [selectedAdoUser, setSelectedAdoUser] = useState(null);
    const [linking, setLinking] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const disableLinkButton = linking || selectedAdoUser === null;
    useEffect(() => {
        showLoader();
        // Fetch ADO projects
        DirectApi.Fetch(`vsts_projects`).then((adoProjects) => {
            hideLoader();
            setProjects(adoProjects);
        });
    }, []);
    useEffect(() => {
        if (selectedProjectId) {
            showLoader();
            const adoProject = projects.find(p => p.id === selectedProjectId);
            // Fetch Ado Users
            DirectApi.Fetch(`vsts_team_members?vsts_project_id=${selectedProjectId}&account=${adoProject === null || adoProject === void 0 ? void 0 : adoProject.account}`).then(response => {
                hideLoader();
                if (response.success) {
                    setAdoUsers(response.userList);
                    setFilteredAdoUsers(response.userList);
                }
                else {
                    // TODO: Show error message
                    console.log(response.error);
                }
            });
        }
    }, [selectedProjectId]);
    const userSelect = (inputId) => {
        if (selectedAdoUser === inputId) {
            setSelectedAdoUser(null);
        }
        else {
            setSelectedAdoUser(inputId);
        }
    };
    const onFilterChange = (input) => {
        setFilteredAdoUsers(adoUsers.filter(user => `${user.identity.displayName} ${user.identity.uniqueName}`.toLowerCase().includes(input.toLowerCase())));
    };
    const onExportClick = () => {
        setLinking(true);
        showLoader();
        const onSuccess = () => {
            createToast({ duration: 2000, message: 'User linked to Azure DevOps' });
            setLinking(false);
            hideLoader();
            closeModal();
        };
        if (isViewer) {
            CommitMutation(UpdateViewerMutation, {
                adoUserId: selectedAdoUser,
            }, onSuccess);
        }
        else {
            CommitMutation(UpdatePersonMutation, {
                id: personId,
                adoUserId: selectedAdoUser,
            }, onSuccess);
        }
    };
    return (React.createElement(CreativeModal, { title: 'Link user to Azure DevOps', size: 'l', closeModal: closeModal, creativeButtonText: `Link to Azure DevOps`, cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onExportClick, onCancelHandler: closeModal, disabled: disableLinkButton, replicateDesignSystem: true },
        React.createElement(ComboBox, { label: "Azure Devops project", defaultItems: projects, value: selectedProjectId, onChange: setSelectedProjectId, width: ComboBox.WIDTH.FLEX }, (project) => (React.createElement(ComboBox.Item, { key: project.id, textValue: project.name },
            React.createElement(Text, null, project.name)))),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Text, { color: Text.color.medium }, `Search for Azure DevOps team member`),
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, width: "600px" })),
        React.createElement(ListWrapper, null,
            React.createElement(Scrollbar, { maxHeight: '500px' },
                React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredAdoUsers &&
                    filteredAdoUsers.map((user) => {
                        return (React.createElement(ListItem, { key: 'adoUser-' + user.identity.id },
                            React.createElement(FlexRow, { gap: 'xl' },
                                React.createElement(Checkbox, { onChange: () => userSelect(user.identity.id), checked: selectedAdoUser === user.identity.id }),
                                React.createElement(Text, { size: '2' }, `${user.identity.displayName} (${user.identity.uniqueName})`))));
                    }))))));
};
const adoLinkUserModalQuery = graphql `
	query AdoLinkUserModal_Query {
		viewer {
			actualPersonId
			component(name: "ado_import_users_modal")
			company {
				...AdoLinkUserModal_company
			}
		}
	}
`;
export { adoLinkUserModalQuery };
export default createFragmentContainer(AdoLinkUserModal, {
    company: graphql `
		fragment AdoLinkUserModal_company on Company {
			profiles {
				edges {
					node {
						id
						name
						disabled
						permissions
					}
				}
			}
		}
	`,
});
