/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PredictedEndDateModalChartSection_project$ref = any;
type PredictedEndDateModalStatsSection_project$ref = any;
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PredictedEndDateModal_project$ref: FragmentReference;
declare export opaque type PredictedEndDateModal_project$fragmentType: PredictedEndDateModal_project$ref;
export type PredictedEndDateModal_project = {|
  +id: string,
  +name: ?string,
  +status: ?PROJECT_STATUS,
  +projectHealth: ?{|
    +totalTaskEstimate: ?number,
    +predictedAverageScopePerDay: ?number,
  |},
  +$fragmentRefs: PredictedEndDateModalChartSection_project$ref & PredictedEndDateModalStatsSection_project$ref,
  +$refType: PredictedEndDateModal_project$ref,
|};
export type PredictedEndDateModal_project$data = PredictedEndDateModal_project;
export type PredictedEndDateModal_project$key = {
  +$data?: PredictedEndDateModal_project$data,
  +$fragmentRefs: PredictedEndDateModal_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PredictedEndDateModal_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectHealth",
      "kind": "LinkedField",
      "name": "projectHealth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTaskEstimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "predictedAverageScopePerDay",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PredictedEndDateModalChartSection_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PredictedEndDateModalStatsSection_project"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '8153c292be66165f12413a3228c95d2e';

module.exports = node;
