/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query RestoreData_deletedTaskLevelInfo_Query(
  $companyId: Int
  $taskEventCount: ID!
) {
  viewer {
    missionControlData {
      deletedTaskLevelInfo(companyId: $companyId, taskEventCount: $taskEventCount) {
        parentCompanyTaskId
        grandParentCompanyTaskId
        id
      }
      id
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyId",
            "type": "Int"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "taskEventCount",
            "type": "ID!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
        },
        {
            "kind": "Variable",
            "name": "taskEventCount",
            "variableName": "taskEventCount"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentCompanyTaskId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "grandParentCompanyTaskId",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RestoreData_deletedTaskLevelInfo_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MissionControlDataType",
                            "kind": "LinkedField",
                            "name": "missionControlData",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v1 /*: any*/),
                                    "concreteType": "MissionControlDeletedTaskLevelInfoType",
                                    "kind": "LinkedField",
                                    "name": "deletedTaskLevelInfo",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RestoreData_deletedTaskLevelInfo_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MissionControlDataType",
                            "kind": "LinkedField",
                            "name": "missionControlData",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v1 /*: any*/),
                                    "concreteType": "MissionControlDeletedTaskLevelInfoType",
                                    "kind": "LinkedField",
                                    "name": "deletedTaskLevelInfo",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "RestoreData_deletedTaskLevelInfo_Query",
            "operationKind": "query",
            "text": "query RestoreData_deletedTaskLevelInfo_Query(\n  $companyId: Int\n  $taskEventCount: ID!\n) {\n  viewer {\n    missionControlData {\n      deletedTaskLevelInfo(companyId: $companyId, taskEventCount: $taskEventCount) {\n        parentCompanyTaskId\n        grandParentCompanyTaskId\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '6b6dab3af7ce03ac77f389eb68b8d7a5';
export default node;
