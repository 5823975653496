/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "companyId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "page",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "daysAgo",
                "type": "Int"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "RestoreData_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlDataType",
                "kind": "LinkedField",
                "name": "missionControlData",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "companyId",
                                "variableName": "companyId"
                            },
                            {
                                "kind": "Variable",
                                "name": "daysAgo",
                                "variableName": "daysAgo"
                            },
                            {
                                "kind": "Variable",
                                "name": "page",
                                "variableName": "page"
                            }
                        ],
                        "concreteType": "MissionControlPaginatedDeletedTasksType",
                        "kind": "LinkedField",
                        "name": "paginatedDeletedTaskList",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pageSize",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pageNumber",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalObjectCount",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "MissionControlDeletedTaskType",
                                "kind": "LinkedField",
                                "name": "pageContents",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "projectName",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "deletedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "deletedBy",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MissionControlHeader_viewer"
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '06c612c5dd10505773023b1c4f9dbf56';
export default node;
