/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ForecastSurplusModalChartSection_project$ref: FragmentReference;
declare export opaque type ForecastSurplusModalChartSection_project$fragmentType: ForecastSurplusModalChartSection_project$ref;
export type ForecastSurplusModalChartSection_project = {|
  +projectFirstDateYear: ?number,
  +projectFirstDateMonth: ?number,
  +projectFirstDateDay: ?number,
  +projectLastDateYear: ?number,
  +projectLastDateMonth: ?number,
  +projectLastDateDay: ?number,
  +revenueMarginLow: ?number,
  +revenueMarginHigh: ?number,
  +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
  +$refType: ForecastSurplusModalChartSection_project$ref,
|};
export type ForecastSurplusModalChartSection_project$data = ForecastSurplusModalChartSection_project;
export type ForecastSurplusModalChartSection_project$key = {
  +$data?: ForecastSurplusModalChartSection_project$data,
  +$fragmentRefs: ForecastSurplusModalChartSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ForecastSurplusModalChartSection_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueMarginLow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueMarginHigh",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "addAccumulatedNumbers",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "aggregateLevel",
          "value": "DAY"
        },
        {
          "kind": "Literal",
          "name": "aggregates",
          "value": [
            "actualRevenue",
            "forecastRevenue"
          ]
        },
        {
          "kind": "Literal",
          "name": "convertToProjectCurrency",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "aggregatedFinancialNumbers",
      "storageKey": "aggregatedFinancialNumbers(addAccumulatedNumbers:true,aggregateLevel:\"DAY\",aggregates:[\"actualRevenue\",\"forecastRevenue\"],convertToProjectCurrency:true)"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'de61213dbe10a2887dd6436cd2319e3d';

module.exports = node;
