/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type createJiraMilestoneModal_viewer$ref = any;
export type createJiraMilestoneModal_QueryVariables = {|
  projectId?: ?string
|};
export type createJiraMilestoneModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: createJiraMilestoneModal_viewer$ref,
  |}
|};
export type createJiraMilestoneModal_Query = {|
  variables: createJiraMilestoneModal_QueryVariables,
  response: createJiraMilestoneModal_QueryResponse,
|};
*/


/*
query createJiraMilestoneModal_Query(
  $projectId: ID
) {
  viewer {
    actualPersonId
    component(name: "create_jira_milestone_modal")
    ...createJiraMilestoneModal_viewer_2CgS8h
    id
  }
}

fragment createJiraMilestoneModal_viewer_2CgS8h on Viewer {
  id
  project(internalId: $projectId) {
    id
    jiraCloudProject {
      id
    }
    jiraServerProject {
      id
    }
    jiraCloudEpicIds
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "create_jira_milestone_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"create_jira_milestone_modal\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createJiraMilestoneModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "createJiraMilestoneModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createJiraMilestoneModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraCloudProjectType",
                "kind": "LinkedField",
                "name": "jiraCloudProject",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JiraServerProjectType",
                "kind": "LinkedField",
                "name": "jiraServerProject",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jiraCloudEpicIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createJiraMilestoneModal_Query",
    "operationKind": "query",
    "text": "query createJiraMilestoneModal_Query(\n  $projectId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"create_jira_milestone_modal\")\n    ...createJiraMilestoneModal_viewer_2CgS8h\n    id\n  }\n}\n\nfragment createJiraMilestoneModal_viewer_2CgS8h on Viewer {\n  id\n  project(internalId: $projectId) {\n    id\n    jiraCloudProject {\n      id\n    }\n    jiraServerProject {\n      id\n    }\n    jiraCloudEpicIds\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c51ae1488aa619729f163d8e903e3ed';

module.exports = node;
