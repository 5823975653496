import React from 'react';
import { useIntl } from 'react-intl';
import { FlexContainer, Heading, TextField, Link as LinkComponent, FlexRow, FlexColumn } from '@forecast-it/design-system';
import { Content, HorizontalLine, Page, PageContent, WideButton } from './Sage.styled';
import { Link as RouterLink } from 'react-router-dom';
import Logo, { LOGO_SIZE } from '../../forecast-app/shared/components/logo/Logo';
export const SageLogin = ({ errorMessage, email, onEmailChange, passwordError, password, onPasswordChange, onLogin, }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(Page, null,
        React.createElement(Content, { alignItems: "center", justifyContent: "center" },
            React.createElement(Logo, { size: LOGO_SIZE.MEDIUM }),
            React.createElement(FlexContainer, null,
                React.createElement(PageContent, { gap: "xl" },
                    React.createElement(FlexColumn, { gap: "l" },
                        React.createElement(Heading, { size: "3" }, formatMessage({ id: 'sage.login.title' })),
                        React.createElement(TextField, { label: formatMessage({ id: 'common.email' }), autoComplete: "email", errorText: errorMessage, value: email, onValueChange: onEmailChange }),
                        React.createElement(TextField, { isPasswordField: true, label: formatMessage({ id: 'common.password' }), autoComplete: "password", maxLength: 64, value: password, errorText: passwordError, onValueChange: onPasswordChange })),
                    React.createElement(RouterLink, { to: "/forgot-password", component: LinkComponent }, formatMessage({ id: 'login.forgot-your-password' })),
                    React.createElement(WideButton, { onClick: onLogin }, formatMessage({ id: 'login.log-in' })),
                    React.createElement(HorizontalLine, null),
                    React.createElement(FlexRow, { alignItems: "center", justifyContent: "center" },
                        React.createElement(LinkComponent, { href: 'https://app.forecast.it/' }, formatMessage({ id: 'sage.looking_for_forecast' }))))))));
};
