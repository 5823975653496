import {createFragmentContainer, graphql} from 'react-relay';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import React, {useState} from 'react';
import {Checkbox, Dropdown, HexagonText, InputLabel} from 'web-components';
import {BUTTON_COLOR} from '../../../constants';
import Styled from 'styled-components';
import {useIntl} from 'react-intl';
import {filterPersons, handleMutationSuccess, replacePlaceholderMutation} from './PlaceholderAllocationUtils';
import Util from '../../../forecast-app/shared/util/util';
import {generateStaffingId} from '../../../components/canvas-scheduling/placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {profilePicSrc} from '../../../directApi';

export const PlaceholderModalContent = Styled.div`
	height: 300px;

	& .checkbox-wrapper {
		display: flex;
    	flex-direction: row;
    	align-items: center;
		.delete-placeholder-text {
			font-size: 13px;
			font-weight: 400;
			padding-left: 8px;
		}
	}
`;

const ReplaceWithPersonModal = ({
	closeModal,
	viewer,
	staffingModeActive,
	placeholderInput,
	placeholderAllocationsInput,
	skillIdsInput,
	suggestedPersonIdsInput,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {company} = viewer;
	let {placeholder} = company;
	let placeholderAllocations;
	let placeholderSkillIds;
	let suggestedPersonIds;

	if (staffingModeActive) {
		placeholder = placeholderInput;
		placeholderAllocations = placeholderAllocationsInput;
		placeholderSkillIds = skillIdsInput;
		suggestedPersonIds = suggestedPersonIdsInput;
	} else {
		const skills = placeholder.skills.edges.map(skill => skill.node);
		placeholderSkillIds = skills ? skills.map(skill => skill.id) : [];
		suggestedPersonIds = placeholder.suggestedPersonIds;
	}

	const sortBySuitability = (a, b) => {
		if (!suggestedPersonIds.includes(a.node.id)) return 1;
		if (!suggestedPersonIds.includes(b.node.id)) return -1;
		return suggestedPersonIds.indexOf(a.node.id) - suggestedPersonIds.indexOf(b.node.id);
	};

	let persons = [...company.allPersons.edges];
	persons.sort(sortBySuitability);
	const matchedPersons = filterPersons(persons, placeholder.role?.id || placeholder.roleId, placeholderSkillIds);

	const matchedPersonIds = matchedPersons.map(matchedPerson => matchedPerson.node.id);
	persons = persons.filter(person => !matchedPersonIds.includes(person.node.id));

	const [selectedPersonIds, setSelectedPersonIds] = useState([]);
	const [deletePlaceholder, setDeletePlaceholder] = useState(!staffingModeActive);
	const [selectedPersonName, setSelectedPersonName] = useState('');

	const onPersonChange = personId => {
		setSelectedPersonIds(personId);

		const unfilteredPersons = company.allPersons.edges;
		const person = unfilteredPersons.find(person => person.node.id === personId[0]);
		if (person?.node) {
			setSelectedPersonName(person.node.fullName);
		}
	};

	const onDeletePersonChange = () => {
		setDeletePlaceholder(!deletePlaceholder);
	};

	const replaceWithPerson = () => {
		const selectedPersonId = selectedPersonIds[0];
		if (staffingModeActive) {
			const mutationResponse = {
				replacePlaceholder: {
					placeholderId: placeholder.id,
					deletedPlaceholderAllocationIds: placeholderAllocations.map(
						placeholderAllocations => placeholderAllocations.id
					),
					allocations: placeholderAllocations.map(placeholderAllocation => {
						const startDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.startDate);
						const endDate = Util.CreateNonUtcMomentDateFromString(placeholderAllocation.endDate);
						return {
							node: {
								...placeholderAllocation,
								id: generateStaffingId(),
								projectId: placeholder.projectId,
								projectGroupId: placeholder.projectGroupId,
								startYear: startDate.year(),
								startMonth: startDate.month() + 1,
								startDay: startDate.date(),
								endYear: endDate.year(),
								endMonth: endDate.month() + 1,
								endDay: endDate.date(),
								personId: selectedPersonId,
							},
						};
					}),
				},
			};
			handleMutationSuccess(mutationResponse);
		} else {
			replacePlaceholderMutation(
				formatMessage,
				placeholder.id,
				selectedPersonId,
				selectedPersonName,
				deletePlaceholder,
				handleMutationSuccess
			);
		}
	};

	const content = (
		<PlaceholderModalContent>
			<ModalBody>
				<Field>
					<InputLabel
						text={formatMessage({id: 'common.select_person_placeholder'})}
						child={
							<Dropdown
								selectedItems={selectedPersonIds}
								name={formatMessage({id: 'common.select_person_placeholder'})}
								maxHeight={300}
								size={'large'}
								onSelect={onPersonChange}
								isNested
							>
								<Dropdown.Group name={formatMessage({id: 'placeholder.modal.matched'})} key={`matched-key`}>
									{matchedPersons.map(person => {
										const {id, firstName, lastName, profilePictureId, role} = person.node;
										return (
											<HexagonText
												key={id}
												value={id}
												searchString={`${firstName} ${lastName}`}
												text={`${firstName} ${lastName}`}
												subText={role ? role.name : ' '}
												imgUrl={profilePicSrc(profilePictureId)}
											/>
										);
									})}
								</Dropdown.Group>
								<Dropdown.Group name={formatMessage({id: 'common.people'})} key={`people-key`}>
									{persons.map(person => {
										const {id, firstName, lastName, profilePictureId, role} = person.node;
										return (
											<HexagonText
												key={id}
												value={id}
												searchString={`${firstName} ${lastName}`}
												text={`${firstName} ${lastName}`}
												subText={role ? role.name : ' '}
												imgUrl={profilePicSrc(profilePictureId)}
											/>
										);
									})}
								</Dropdown.Group>
							</Dropdown>
						}
					/>
				</Field>
				{!!selectedPersonIds.length && !staffingModeActive && (
					<Field>
						<div className={'checkbox-wrapper'}>
							<Checkbox checked={deletePlaceholder} onChange={onDeletePersonChange} />
							<div className="delete-placeholder-text">
								{formatMessage({id: 'placeholder.delete_placeholder'})}
							</div>
						</div>
					</Field>
				)}
			</ModalBody>
		</PlaceholderModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'placeholder.replace_with_person.action'})}
			buttons={[
				{text: formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
				{
					text: 'Replace',
					disabled: !selectedPersonIds.length,
					callback: replaceWithPerson,
					color: BUTTON_COLOR.GREEN,
				},
			]}
		/>
	);
};

const replaceWithPersonModalQuery = graphql`
	query ReplaceWithPersonModal_Query($placeholderId: ID, $loadPlaceholder: Boolean!) {
		viewer {
			actualPersonId
			component(name: "replace_with_person_modal")
			...ReplaceWithPersonModal_viewer @arguments(placeholderId: $placeholderId, loadPlaceholder: $loadPlaceholder)
		}
	}
`;

export {replaceWithPersonModalQuery};

export default createFragmentContainer(ReplaceWithPersonModal, {
	viewer: graphql`
		fragment ReplaceWithPersonModal_viewer on Viewer
		@argumentDefinitions(placeholderId: {type: "ID"}, loadPlaceholder: {type: "Boolean!"}) {
			id
			email
			company {
				placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
					id
					name
					role {
						id
						name
					}
					skills {
						edges {
							node {
								id
								name
							}
						}
					}
					placeholderAllocations {
						edges {
							node {
								id
							}
						}
					}
					suggestedPersonIds
				}
				allPersons(first: 1000000, onlyActive: true, excludeClientUsers: true) {
					edges {
						node {
							id
							active
							firstName
							lastName
							fullName
							profilePictureId
							role {
								id
								name
							}
							skills {
								edges {
									node {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
