import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import ProfileNotificationSettings from './profile_notifications_settings';
import ProfileProjectStatusUpdates from './ProfileProjectStatusUpdates';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import SettingsProfileSubNav from '../settings/SettingsProfileSubNav';

const TABS = {
	NOTIFICATION_SETTINGS: {
		key: 'NOTIFICATION_SETTINGS',
		translationId: 'header.notifications',
	},
	PROJECT_STATUS_UPDATES: {
		key: 'PROJECT_STATUS_UPDATES',
		translationId: 'settings.project_status_updates',
	},
};

const getTopHeader = intl => {
	const {formatMessage} = intl;
	return (
		<TopHeaderBarWrapper>
			<TopHeaderBar title={formatMessage({id: 'header.notifications'})} />
		</TopHeaderBarWrapper>
	);
};

const getFilteredTabs = () => {
	return Object.fromEntries(Object.entries(TABS).filter(([key, _]) => key !== TABS.PROJECT_STATUS_UPDATES.key));
};

const ProfileNotificationsPage = ({viewer, retry}) => {
	const [selectedTab, setSelectedTab] = useState(TABS.NOTIFICATION_SETTINGS.key);

	const intl = useIntl();
	const history = useHistory();
	getFilteredTabs();
	useEffect(() => {
		if (history.location.hash) {
			const tab = history.location.hash.replace('#', '').toUpperCase().split('+')[0];
			setSelectedTab(tab);
		}
	}, []);

	const getSelectedSection = selectedTab => {
		switch (selectedTab) {
			case TABS.NOTIFICATION_SETTINGS.key:
				return <ProfileNotificationSettings viewer={viewer} retry={retry} />;
			case TABS.PROJECT_STATUS_UPDATES.key:
				return <ProfileProjectStatusUpdates viewer={viewer} />;
			default:
				setSelectedTab(TABS.NOTIFICATION_SETTINGS.key);
		}
	};

	return (
		<>
			{getTopHeader(intl)}
			<SettingsProfileSubNav viewer={viewer} />
			<CustomScrollDiv>{getSelectedSection(selectedTab, viewer, retry)}</CustomScrollDiv>
		</>
	);
};

export const ProfileNotificationsPageQuery = graphql`
	query ProfileNotificationsPage_Query {
		viewer {
			actualPersonId
			component(name: "profile-notifications-page")
			...ProfileNotificationsPage_viewer
		}
	}
`;

export default createFragmentContainer(ProfileNotificationsPage, {
	viewer: graphql`
		fragment ProfileNotificationsPage_viewer on Viewer {
			projectOwner
			disableNotifications
			company {
				forecastDemo
				tier
			}
			...profileNotificationsSettings_viewer
			...ProfileProjectStatusUpdates_viewer
			...SettingsProfileSubNav_viewer
		}
	`,
});
