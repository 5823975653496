import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useIntl} from 'react-intl';

export const LoginWrapper = ({children}) => {
	const intl = useIntl();
	const match = useRouteMatch();

	const getDescription = () => {
		if (match.path === '/login') {
			return (
				<>
					<h2>{intl.formatMessage({id: 'running_reminder_modal.greeting'})},</h2>
					<h2>{intl.formatMessage({id: 'login.welcome-greeting'})}</h2>
				</>
			);
		} else if (match.path === '/forgot-password') {
			return (
				<>
					<h2>{intl.formatMessage({id: 'login.forgot-your-password'})}</h2>
					<h2>{intl.formatMessage({id: 'login.got-you-covered'})}</h2>
				</>
			);
		} else if (match.path === '/signup') {
			return (
				<>
					<h2>{intl.formatMessage({id: 'login.free-trial-description'})}</h2>
				</>
			);
		} else if (match.path.includes('/reset/')) {
			return (
				<>
					<h2>{intl.formatMessage({id: 'login.create-new-password'})}</h2>
				</>
			);
		}

		return null;
	};

	return (
		<div className="login-wrapper">
			<div className="description-panel">
				<div className="description">
					<a href="https://www.forecast.app/">
						<div className="logo" />
					</a>
					{getDescription()}
				</div>
			</div>

			<div className="content-container">{children}</div>
		</div>
	);
};
