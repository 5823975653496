import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import {showModal, MODAL_TYPE} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import MigrateXeroClientsMutation from '../../mutations/MigrateXeroClientsMutation';
import {Checkbox} from 'web-components';
import {hideLoader, showLoader} from '../global_loader';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import DirectApi from '../../directApi';

class XeroSyncClientsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			xeroContacts: [],
			fetchingContacts: true,
			selectedContacts: [],
		};

		this.getContacts();
	}

	getContactsHelper(page, currentTenantIdIndex) {
		// Recursive function
		return DirectApi.Fetch_Raw(`xero/unimportedContacts?page=${page}&currentTenantIdIndex=${currentTenantIdIndex}`).then(
			result => {
				if (result.status === 403) {
					this.props.closeModal();
					showModal({
						type: MODAL_TYPE.XERO_INACCESSIBLE,
						isAdminUser: hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS),
					});
					return;
				}

				return result.json().then(result => {
					if (result.moreCustomersToFetch) {
						this.setState({
							xeroContacts: this.state.xeroContacts.concat(result.unimportedContacts),
						});
						this.getContactsHelper(result.page, result.currentTenantIdIndex);
					} else {
						this.setState({
							xeroContacts: this.state.xeroContacts.concat(result.unimportedContacts).sort((a, b) => {
								if (a.name.toUpperCase().trim() > b.name.toUpperCase().trim()) {
									return 1;
								} else if (a.name.toUpperCase().trim() < b.name.toUpperCase().trim()) {
									return -1;
								}
								return 0;
							}),
							fetchingContacts: false,
						});
						return;
					}
				});
			}
		);
	}

	getContacts() {
		// We initially pass 0s as the Xero logic undestands it as the start
		this.getContactsHelper(0, 0).then(() => {
			return;
		});
	}

	toggleSelectAll() {
		if (this.state.selectAll) {
			this.setState({
				selectedContacts: [],
				selectAll: false,
			});
		} else {
			this.setState({
				selectedContacts: this.state.xeroContacts.map(xeroContact => xeroContact.id),
				selectAll: true,
			});
		}
	}

	toggleContact(xeroContactTd) {
		if (this.state.selectedContacts.includes(xeroContactTd)) {
			const selectedContacts = this.state.selectedContacts.filter(id => id !== xeroContactTd);
			this.setState({
				selectedContacts,
				selectAll: false,
			});
		} else {
			const selectedContacts = this.state.selectedContacts;
			selectedContacts.push(xeroContactTd);
			this.setState({
				selectedContacts,
			});
		}
	}

	syncContacts() {
		this.setState({importing: true});
		showLoader();
		Util.CommitMutation(
			MigrateXeroClientsMutation,
			{
				xeroClientIds: this.state.selectedContacts,
			},
			() => {
				this.setState({importing: false});
				this.props.closeModal();
				hideLoader();
				window.location.reload();
			}
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="integration-sync-clients-modal-content">
				<div className="integration-sync-clients-modal-description">
					<FormattedMessage id="integrations.xero.sync_clients.description" />
				</div>
				{this.state.fetchingContacts ? (
					<InlineLoader />
				) : (
					<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
						<div className="integration-clients">
							{this.state.xeroContacts && this.state.xeroContacts.length > 0 ? (
								<>
									<div className="integration-client" key={'0'}>
										<Checkbox checked={this.state.selectAll} onClick={this.toggleSelectAll.bind(this)} />
										<FormattedMessage id="common.select_all" />
									</div>
									{this.state.xeroContacts.map(xeroContact => (
										<div className="integration-client" key={xeroContact.id}>
											<Checkbox
												checked={this.state.selectedContacts.includes(xeroContact.id)}
												onClick={this.toggleContact.bind(this, xeroContact.id)}
											/>
											{`${xeroContact.name} (${xeroContact.org})`}
										</div>
									))}
								</>
							) : (
								<FormattedMessage id="integrations.xero.all_contacts_synched" />
							)}
						</div>
					</CustomScrollDiv>
				)}
			</div>
		);

		return (
			<GenericModal
				headerText={<FormattedMessage id="integrations.xero.sync_clients" />}
				closeModal={this.props.closeModal}
				className="integration-sync-clients-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage(
							{id: 'common.create_x_clients'},
							{numOfClients: this.state.selectedContacts.length}
						),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.syncContacts.bind(this),
						disabled: this.state.importing || this.state.selectedContacts.length === 0,
						defaultCallback: () => null,
						preventDefaultClose: true,
					},
				]}
				content={content}
			/>
		);
	}
}

export default injectIntl(withRouter(XeroSyncClientsModal));
