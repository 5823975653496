import React, {useRef, useState} from 'react';

import {useIntl} from 'react-intl';
import {Button, SearchField, TableBar} from '@forecast-it/design-system';
import {FlexRow, Section} from '../../../../components/insights/invoicing/InvoicingPage.styled';
import Util from '../../../../forecast-app/shared/util/util';
import {createFragmentContainer} from 'react-relay';
import {graphql} from 'graphql';
import ProjectInvoicingTable from './ProjectInvoicingTable';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import moment from 'moment';
import DeleteInvoiceMutation from '../../../../mutations/delete_invoice_mutation';
import {createToast} from '../../../../forecast-app/shared/components/toasts/toast';
import {synchronizeInvoices, unlinkInvoice} from './InvoiceExportUtil';
import {getFilterFunctions} from '../../../../forecast-app/shared/components/filters/filter_logic';
import InvoiceFilterButton from './InvoiceFilterButton';
import {getInitialOptions, handleChangedOptions} from '../../../../components/insights/invoicing/invoicing_eye_options';
import TheEye from '../../../../forecast-app/shared/components/headers/header-bar/TheEye';
import {useHistory} from 'react-router-dom';
import Styled from 'styled-components/macro';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';
import {isSagePSAWithFinancialsAccess} from '../../../../forecast-app/shared/util/sage/SageRestrictionUtil';

// TODO: replace with a proper scroll component
export const TableWrapper = Styled.div`
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
`;
const ProjectInvoicingTableSection = ({viewer, company, project, refetch}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();

	const hasIntegrations =
		company.newXeroEnabled || company.quickbooksEnabled || company.economicEnabled || company.sageIntacctConnectionEnabled;

	const isSagePSA = hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);

	const showSyncAndExport = hasIntegrations && (!isSagePSA || isSagePSAWithFinancialsAccess());

	const [filterFunctions, setFilterFunctions] = useState(
		getFilterFunctions(InvoiceFilterButton.InvoiceFiltersInLocalstorage)
	);

	const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions('project', hasIntegrations));
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const handleEyeOptionsSelect = (_, __, ___, newOptions) => {
		setTheEyeOptions(handleChangedOptions(newOptions, 'project'));
	};

	const editInvoice = companyInvoiceId => {
		history.push(`${location.pathname}/I-${companyInvoiceId}`);
	};

	const openCreateNewInvoiceModal = (projectId, refetch) => {
		trackEvent('Create New Invoice Modal', 'Shown', {location: 'Project Page'});
		showModal({
			type: MODAL_TYPE.CREATE_INVOICE_V2_STANDALONE,
			refetch,
			onInvoiceCreation: () => {},
			startDate: moment().subtract(2, 'month'),
			endDate: moment(),
			projectId: projectId,
			isProjectInvoicing: true,
		});
	};

	const deleteInvoice = (invoiceId, refetch, formatMessage) => {
		const onSuccess = () => {
			// update state
			refetch();
			createToast({duration: 5000, message: formatMessage({id: 'invoice.invoice_deleted'})});
		};

		Util.CommitMutation(DeleteInvoiceMutation, {id: invoiceId}, onSuccess);
	};

	const exportCsvRef = useRef();

	return (
		<>
			<TableBar title={'Invoices'} cy={'project-invoices-table-bar'}>
				<Button id={TableBar.ELEMENTS.DOWNLOAD} onClick={() => exportCsvRef?.current?.exportCsv()} />
				<SearchField id={TableBar.ELEMENTS.SEARCH} value={searchFilterValue} onChange={setSearchFilterValue} />
				<TheEye
					id={TableBar.ELEMENTS.EYE_OPTIONS}
					openRight={'true'}
					dataCy={'project_invoicing_eye'}
					options={theEyeOptions}
					onSelect={handleEyeOptionsSelect}
					useDesignSystemButton={true}
				/>
				<InvoiceFilterButton
					id={TableBar.ELEMENTS.FILTER}
					viewer={viewer}
					company={company}
					project={project}
					setFilterFunctions={setFilterFunctions}
				/>
				{showSyncAndExport ? (
					<Button
						id={'synchronize-invoices'}
						emphasis={'medium'}
						onClick={() =>
							synchronizeInvoices(
								company.newXeroEnabled,
								company.quickbooksEnabled,
								company.economicEnabled,
								company.sageIntacctConnectionEnabled
							)
						}
					>
						Synchronize
					</Button>
				) : (
					<></>
				)}

				{CompanySetupUtil.hasCreateInvoice() ? (
					<Button
						id={'create-new-invoice'}
						icon={'plus'}
						onClick={() => openCreateNewInvoiceModal(project.id, refetch)}
					>
						{formatMessage({id: 'invoicing.create_invoice'})}
					</Button>
				) : (
					<></>
				)}
			</TableBar>

			<TableWrapper>
				<Section marginBottom={55} data-userpilot={'invoices-table'}>
					<FlexRow growable>
						<ProjectInvoicingTable
							project={project}
							company={company}
							filterFunctions={filterFunctions}
							searchFilterValue={searchFilterValue}
							theEyeOptions={theEyeOptions}
							onEditInvoice={companyInvoiceId => editInvoice(companyInvoiceId)}
							onDeleteInvoice={invoiceId => deleteInvoice(invoiceId, refetch, formatMessage)}
							onUnlinkInvoice={invoiceId => unlinkInvoice(invoiceId, refetch, formatMessage)}
							exportCsvRef={exportCsvRef}
							showExport={showSyncAndExport}
						/>
					</FlexRow>
				</Section>
			</TableWrapper>
		</>
	);
};

export default createFragmentContainer(ProjectInvoicingTableSection, {
	viewer: graphql`
		fragment ProjectInvoicingTableSection_viewer on Viewer {
			...InvoiceFilterButton_viewer
		}
	`,
	company: graphql`
		fragment ProjectInvoicingTableSection_company on Company {
			newXeroEnabled
			quickbooksEnabled
			economicEnabled
			sageIntacctConnectionEnabled
			...ProjectInvoicingTable_company
			...ExportInvoiceButton_company
			...InvoiceFilterButton_company
		}
	`,
	project: graphql`
		fragment ProjectInvoicingTableSection_project on ProjectType {
			id
			companyProjectId
			...ProjectInvoicingTable_project
			...InvoiceFilterButton_project
			invoices {
				edges {
					node {
						id
						companyInvoiceId
					}
				}
			}
		}
	`,
});
