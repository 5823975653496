import React, {Component} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import MissionControlHeader from './mission_control_header';
import DeletePersonEmail from '../../mutations/mission_control/delete_person_email_mutation';
import Util from '../../forecast-app/shared/util/util';

class controlPersonList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			deleteClickedForId: null,
		};
	}

	search() {
		this.props.relay.refetch({searchString: this.searchInput.value.toLowerCase()});
	}

	deleteEmail(id) {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => this.setState({deleteClickedForId: null}), 2000);
		this.setState({deleteClickedForId: id});
	}

	finalDeleteEmail(id, personId) {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		Util.CommitMutation(DeletePersonEmail, {
			personId: personId,
		});
		this.setState({deleteClickedForId: null});
	}

	render() {
		if (!this.props.viewer.missionControlData) {
			this.props.history.push('/not-found');
			return null;
		}

		if (!this.props.viewer.missionControlData.personList) {
			return null;
		}
		let data = cloneDeep(this.props.viewer.missionControlData.personList);

		data.sort((a, b) => {
			if (a.companyId === b.companyId) {
				return a.personId > b.personId ? 1 : -1;
			}
			return a.companyId > b.companyId ? 1 : -1;
		});

		return (
			<div className="mission-control-list">
				<MissionControlHeader viewer={this.props.viewer} />
				<h1>Person list</h1>
				Search: <input ref={item => (this.searchInput = item)} type="text" />
				<button onClick={this.search.bind(this)}>Search</button>
				<table>
					<tbody>
						<tr>
							<th>Company id</th>
							<th>Company name</th>
							<th>Company Plan</th>
							<th>User id</th>
							<th>User name</th>
							<th>Email</th>
							<th>Profiles</th>
							<th>Seat Type</th>
							<th>Active</th>
							<th>Delete email</th>
						</tr>
						{data.map(s => {
							return (
								<tr key={s.personId}>
									<td>{s.companyId}</td>
									<td>{s.companyName}</td>
									<td>{s.accountPlan}</td>
									<td>{s.personId}</td>
									<td>{(s.firstName || '') + ' ' + (s.lastName || '')}</td>
									<td>{s.email}</td>
									<td>{s.profileName}</td>
									<td>{s.seatType}</td>
									<td>{s.active ? 'YES' : 'NO'}</td>
									<td>
										{s.id === this.state.deleteClickedForId ? (
											<button onClick={this.finalDeleteEmail.bind(this, s.id, s.personId)}>
												Are you sure??
											</button>
										) : s.email && !s.email.endsWith('_DELETED') ? (
											<button onClick={this.deleteEmail.bind(this, s.id)}>Delete email</button>
										) : null}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

const controlPersonListQuery = graphql`
	query controlPersonList_Query($searchString: String) {
		viewer {
			actualPersonId
			component(name: "control_person_list")
			...controlPersonList_viewer @arguments(searchString: $searchString)
		}
	}
`;

export {controlPersonListQuery};

export default createRefetchContainer(
	withRouter(controlPersonList),
	{
		viewer: graphql`
			fragment controlPersonList_viewer on Viewer @argumentDefinitions(searchString: {type: "String"}) {
				id
				...missionControlHeader_viewer
				missionControlData {
					id
					personList(searchString: $searchString) {
						id
						personId
						companyId
						companyName
						accountPlan
						firstName
						lastName
						email
						seatType
						active
						profileName
					}
				}
			}
		`,
	},
	graphql`
		query controlPersonListRefetchQuery($searchString: String) {
			viewer {
				...controlPersonList_viewer @arguments(searchString: $searchString)
			}
		}
	`
);
