import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import {EMPTY_STATE} from '../../constants';
import EmptyState from '../../forecast-app/shared/components/empty-states/empty_state';
import MissionControlHeader from './mission_control_header';
import {Button} from 'web-components';
import styled from 'styled-components';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {injectIntl} from 'react-intl';
import ExportNovaInsightsStatsCSV, {ExportNovaInsightsStatsCSVQuery} from './ExportNovaInsightsStatsCSV';

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0 24px;
`;

class mlStats extends Component {
	constructor(props) {
		super(props);

		this.state = {
			companiesWithMLModels: this.props.viewer.missionControlData.companiesWithMLModels,
			column: 'companyId',
			asc: true,
		};
	}

	UNSAFE_componentWillMount() {}

	UNSAFE_componentWillReceiveProps(nextProps) {}

	sort(column) {
		const f = column === 'companyId' || column === 'companyName' ? -1 : 1;
		const s = column === 'companyId' || column === 'companyName' ? 1 : -1;

		let list = this.state.companiesWithMLModels.sort((a, b) => {
			if (column === this.state.column && this.state.asc) {
				return a[column] > b[column] ? f : s;
			} else if (column === this.state.column && !this.state.asc) {
				return a[column] > b[column] ? s : f;
			} else {
				return a[column] > b[column] ? s : f;
			}
		});

		this.setState({companiesWithMLModels: list, column, asc: column === this.state.column ? !this.state.asc : true});
	}

	getSortMarker(column) {
		if (column === this.state.column && this.state.asc) {
			return ' ↓';
		} else if (column === this.state.column && !this.state.asc) {
			return ' ↑';
		} else {
			return '  ';
		}
	}

	handleCSVClick(includeProjectGroups) {
		showModal({
			type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
			componentName: 'export_task_report_csv',
			query: ExportNovaInsightsStatsCSVQuery,
			variables: {includeProjectGroups: includeProjectGroups},
			createCsvDownload: <ExportNovaInsightsStatsCSV />,
		});
	}

	getMLStatsTable() {
		return (
			<Scrollbars>
				{this.props.viewer.missionControlData.companiesWithMLModels &&
				this.props.viewer.missionControlData.companiesWithMLModels.length !== 0 ? (
					<table className={'mission-control-bug-duty-table'} ref={el => (this.table = el)}>
						<thead>
							<tr>
								<th
									style={{minWidth: '100px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'companyId')}
								>
									{'Company Id' + this.getSortMarker('companyId')}
								</th>
								<th
									style={{minWidth: '150px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'companyName')}
								>
									{'Company Name' + this.getSortMarker('companyName')}
								</th>

								<th
									style={{minWidth: '84px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'lowHighModel')}
								>
									{'Low / High' + this.getSortMarker('lowHighModel')}
								</th>
								<th
									style={{minWidth: '84px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'lowHighModelAccuracyTest')}
								>
									Low / High <br /> Acc. Test {' ' + this.getSortMarker('lowHighModelAccuracyTest')}
								</th>
								{window.location.hash === '#include-train' ? (
									<th
										style={{minWidth: '151px'}}
										className="hand sticky"
										onClick={this.sort.bind(this, 'lowHighModelAccuracyTrain')}
									>
										{'Low / High <br/> Acc. Train' + this.getSortMarker('lowHighModelAccuracyTrain')}
									</th>
								) : null}
								<th
									style={{minWidth: '111px'}}
									align="center"
									className="hand sticky"
									onClick={this.sort.bind(this, 'lowHighModelRmseTest')}
								>
									Low / High <br /> RMSE Test {' ' + this.getSortMarker('lowHighModelRmseTest')}
								</th>

								<th
									style={{minWidth: '111px'}}
									align="center"
									className="hand sticky"
									onClick={this.sort.bind(this, 'overrunPredictorPrecisionTest')}
								>
									Task Overrun <br /> Precision Test{' '}
									{' ' + this.getSortMarker('overrunPredictorPrecisionTest')}
								</th>
								<th
									style={{minWidth: '111px'}}
									align="center"
									className="hand sticky"
									onClick={this.sort.bind(this, 'overrunPredictorRecallTest')}
								>
									Task Overrun <br /> Recall Test {' ' + this.getSortMarker('overrunPredictorRecallTest')}
								</th>

								<th
									style={{minWidth: '84px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'roleModel')}
								>
									{'Role' + this.getSortMarker('roleModel')}
								</th>
								<th
									style={{minWidth: '111px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'roleModelTop1AccuracyTest')}
								>
									{'Role Acc1 Test' + this.getSortMarker('roleModelTop1AccuracyTest')}
								</th>
								{window.location.hash === '#include-train' ? (
									<th
										style={{minWidth: '116px'}}
										className="hand sticky"
										onClick={this.sort.bind(this, 'roleModelAccuracyTrain')}
									>
										{'Role Acc1 Train' + this.getSortMarker('roleModelAccuracyTrain')}
									</th>
								) : null}

								<th
									style={{minWidth: '84px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'labelModel')}
								>
									{'Label' + this.getSortMarker('labelModel')}
								</th>
								<th
									style={{minWidth: '117px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'labelModelTop1AccuracyTest')}
								>
									{'Label Acc1 Test' + this.getSortMarker('labelModelTop1AccuracyTest')}
								</th>
								{window.location.hash === '#include-train' ? (
									<th
										style={{minWidth: '122px'}}
										className="hand sticky"
										onClick={this.sort.bind(this, 'labelModelAccuracyTrain')}
									>
										{'Label Acc1 Train' + this.getSortMarker('labelModelAccuracyTrain')}
									</th>
								) : null}
								<th
									style={{minWidth: '117px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'labelModelTop3AccuracyTest')}
								>
									{'Label Acc3 Test' + this.getSortMarker('labelModelTop3AccuracyTest')}
								</th>

								<th
									style={{minWidth: '84px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'assigneeModel')}
								>
									{'Person' + this.getSortMarker('assigneeModel')}
								</th>
								<th
									style={{minWidth: '138px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'assigneeModelTop1AccuracyTest')}
								>
									{'Assignee Acc1 Test' + this.getSortMarker('assigneeModelTop1AccuracyTest')}
								</th>
								{window.location.hash === '#include-train' ? (
									<th
										style={{minWidth: '145px'}}
										className="hand sticky"
										onClick={this.sort.bind(this, 'assigneeModelAccuracyTrain')}
									>
										{'Assignee Acc1 Train' + this.getSortMarker('assigneeModelAccuracyTrain')}
									</th>
								) : null}
								<th
									style={{minWidth: '138px'}}
									className="hand sticky"
									onClick={this.sort.bind(this, 'assigneeModelTop3AccuracyTest')}
								>
									{'Assignee Acc3 Test' + this.getSortMarker('assigneeModelTop3AccuracyTest')}
								</th>
							</tr>
						</thead>
						<tbody>
							{this.state.companiesWithMLModels.map(s => {
								return (
									<tr key={s.companyId}>
										<td>
											<span className={'elem'}>{s.companyId}</span>
										</td>
										<td>
											<span className={'elem'}>{s.companyName}</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.lowHighModel ? (
													<div className="ml-checkmark-yes">
														<span className="tooltiptext">
															{'Last trained: ' + s.lowHighModelLastUpdated}
														</span>
													</div>
												) : (
													<div className="ml-checkmark-no"></div>
												)}{' '}
											</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.lowHighModelAccuracyTest ? (
													<div>{s.lowHighModelAccuracyTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
										{window.location.hash === '#include-train' ? (
											<td>
												<span className={'elem'}>
													{' '}
													{s.lowHighModelAccuracyTrain ? (
														<div>{s.lowHighModelAccuracyTrain + '%'}</div>
													) : null}{' '}
												</span>
											</td>
										) : null}
										<td>
											<span className={'elem'}>
												{' '}
												{s.lowHighModelRmseTest ? (
													<div>{s.lowHighModelRmseTest + ' mins'}</div>
												) : null}{' '}
											</span>
										</td>

										<td>
											<span className={'elem'}>
												{' '}
												{s.overrunPredictorPrecisionTest ? (
													<div>{s.overrunPredictorPrecisionTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.overrunPredictorRecallTest ? (
													<div>{s.overrunPredictorRecallTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>

										<td>
											<span className={'elem'}>
												{' '}
												{s.roleModel ? (
													<div className="ml-checkmark-yes">
														<span className="tooltiptext">
															{'Last trained: ' + s.roleModelLastUpdated}
														</span>
													</div>
												) : (
													<div className="ml-checkmark-no"></div>
												)}{' '}
											</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.roleModelAccuracyTest ? (
													<div>{s.roleModelAccuracyTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
										{window.location.hash === '#include-train' ? (
											<td>
												<span className={'elem'}>
													{' '}
													{s.roleModelAccuracyTrain ? (
														<div>{s.roleModelAccuracyTrain + '%'}</div>
													) : null}{' '}
												</span>
											</td>
										) : null}
										<td>
											<span className={'elem'}>
												{' '}
												{s.labelModel ? (
													<div className="ml-checkmark-yes">
														<span className="tooltiptext">
															{'Last trained: ' + s.labelModelLastUpdated}
														</span>
													</div>
												) : (
													<div className="ml-checkmark-no"></div>
												)}{' '}
											</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.labelModelTop1AccuracyTest ? (
													<div>{s.labelModelTop1AccuracyTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
										{window.location.hash === '#include-train' ? (
											<td>
												<span className={'elem'}>
													{' '}
													{s.labelModelAccuracyTrain ? (
														<div>{s.labelModelAccuracyTrain + '%'}</div>
													) : null}{' '}
												</span>
											</td>
										) : null}
										<td>
											<span className={'elem'}>
												{' '}
												{s.labelModelTop3AccuracyTest ? (
													<div>{s.labelModelTop3AccuracyTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.assigneeModel ? (
													<div className="ml-checkmark-yes">
														<span className="tooltiptext">
															{'Last trained: ' + s.assigneeModelLastUpdated}
														</span>
													</div>
												) : (
													<div className="ml-checkmark-no"></div>
												)}{' '}
											</span>
										</td>
										<td>
											<span className={'elem'}>
												{' '}
												{s.assigneeModelTop1AccuracyTest ? (
													<div>{s.assigneeModelTop1AccuracyTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
										{window.location.hash === '#include-train' ? (
											<td>
												<span className={'elem'}>
													{' '}
													{s.assigneeModelAccuracyTrain ? (
														<div>{s.assigneeModelAccuracyTrain + '%'}</div>
													) : null}{' '}
												</span>
											</td>
										) : null}
										<td>
											<span className={'elem'}>
												{' '}
												{s.assigneeModelTop3AccuracyTest ? (
													<div>{s.assigneeModelTop3AccuracyTest + '%'}</div>
												) : null}{' '}
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				) : (
					<div className={'state-center'}>
						<EmptyState pageName={EMPTY_STATE.NO_ERRORS}></EmptyState>
					</div>
				)}
			</Scrollbars>
		);
	}

	render() {
		if (!this.props.viewer.missionControlData) {
			this.props.history.push('/not-found');
			return null;
		}

		if (!this.props.viewer.missionControlData.companiesWithMLModels) {
			return null;
		}

		return (
			<div className="mission-control-bug-duty ml-stats">
				<MissionControlHeader viewer={this.props.viewer} />
				<ButtonWrapper>
					<div style={{marginRight: '8px'}}>
						<Button variant={Button.VARIANT.PURPLE_OUTLINE} onClick={this.handleCSVClick.bind(this, true)}>
							Export Nova Insights stats (W/ Project group projects)
						</Button>
					</div>
					<Button variant={Button.VARIANT.PURPLE_OUTLINE} onClick={this.handleCSVClick.bind(this, false)}>
						Export Nova Insights stats
					</Button>
				</ButtonWrapper>
				<div className={`mission-control-bug-duty-container`}>{this.getMLStatsTable()}</div>
				<br />
			</div>
		);
	}
}

const mlStatsQuery = graphql`
	query mlStats_Query {
		viewer {
			actualPersonId
			component(name: "control_ml_models")
			...mlStats_viewer
		}
	}
`;

export {mlStatsQuery};

export default injectIntl(
	createFragmentContainer(withRouter(mlStats), {
		viewer: graphql`
			fragment mlStats_viewer on Viewer {
				id
				...missionControlHeader_viewer
				missionControlData {
					id
					companiesWithMLModels {
						companyId
						companyName
						lowHighModel
						lowHighModelLastUpdated
						lowHighModelAccuracyTest
						lowHighModelAccuracyTrain
						lowHighModelRmseTest
						overrunPredictorPrecisionTest
						overrunPredictorRecallTest
						roleModel
						roleModelLastUpdated
						roleModelAccuracyTest
						roleModelAccuracyTrain
						roleModelTop1AccuracyTest
						labelModel
						labelModelLastUpdated
						labelModelAccuracyTest
						labelModelAccuracyTrain
						labelModelTop1AccuracyTest
						labelModelTop3AccuracyTest
						assigneeModel
						assigneeModelLastUpdated
						assigneeModelAccuracyTest
						assigneeModelAccuracyTrain
						assigneeModelTop1AccuracyTest
						assigneeModelTop3AccuracyTest
					}
				}
			}
		`,
	})
);
