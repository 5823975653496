import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import GenericModal from '../generic_modal';
import Util from '../../../forecast-app/shared/util/util';
import ChangeJiraStartDateFieldMutation from '../../../mutations/ChangeJiraStartDateFieldMutation';
import {Dropdown} from 'web-components';

const JiraStartDateModalStyle = styled.div`
	.description {
		margin-bottom: 8px;
	}
	.dropdown {
		margin-bottom: 180px;
	}
`;
const JiraStartDateModal = ({closeModal, viewer}) => {
	const [selectedField, setSelectedField] = useState(
		viewer?.company?.integrations?.jiraCloud?.startDateField?.id
			? [viewer.company.integrations.jiraCloud.startDateField.id]
			: []
	);
	const intl = useIntl();
	const changeStartDateField = () => {
		Util.CommitMutation(ChangeJiraStartDateFieldMutation, {fieldId: selectedField[0] || null});
	};
	const onRemove = (removedValue, newValues) => {
		setSelectedField(newValues);
	};

	const content = (
		<JiraStartDateModalStyle>
			<div className="description">
				Select the Jira date field that contains your issue start dates in Jira.
				<br />
				Changing this field will update the start dates of your Forecast tasks to the current values in Jira.
				<br />
				These changes may take up to 24 hours to take effect.
			</div>
			<div className="dropdown">
				<Dropdown
					selectedItems={selectedField}
					name="Date field"
					maxHeight={300}
					onSelect={setSelectedField}
					onRemove={onRemove.bind(this)}
					isClearable
				>
					{viewer?.company?.integrations?.jiraCloud?.dateFields?.map(field => (
						<Dropdown.SingleText key={field.id} value={field.id} searchString={`${field.name} ${field.id}`}>
							{`${field.name} (${field.id})`}
						</Dropdown.SingleText>
					))}
				</Dropdown>
			</div>
		</JiraStartDateModalStyle>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.change'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: changeStartDateField.bind(this),
				},
			]}
			headerText={intl.formatMessage({id: 'integrations.jira.change_start_date_field'})}
			content={content}
		/>
	);
};

const jiraStartDateModalQuery = graphql`
	query JiraStartDateModal_Query {
		viewer {
			actualPersonId
			component(name: "jira_start_date_modal")
			...JiraStartDateModal_viewer
		}
	}
`;

export {jiraStartDateModalQuery};

export default withRouter(
	createFragmentContainer(JiraStartDateModal, {
		viewer: graphql`
			fragment JiraStartDateModal_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					modules {
						moduleType
					}
					integrations {
						jiraCloud {
							dateFields {
								id
								name
							}
							startDateField {
								id
							}
						}
					}
				}
			}
		`,
	})
);
