/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AssignToPersonModal_viewer$ref = any;
export type AssignToPersonModal_QueryVariables = {|
  placeholderId?: ?string,
  loadPlaceholder: boolean,
|};
export type AssignToPersonModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: AssignToPersonModal_viewer$ref,
  |}
|};
export type AssignToPersonModal_Query = {|
  variables: AssignToPersonModal_QueryVariables,
  response: AssignToPersonModal_QueryResponse,
|};
*/


/*
query AssignToPersonModal_Query(
  $placeholderId: ID
  $loadPlaceholder: Boolean!
) {
  viewer {
    actualPersonId
    component(name: "assign_to_person_modal")
    ...AssignToPersonModal_viewer_4hTwra
    id
  }
}

fragment AssignToPersonModal_viewer_4hTwra on Viewer {
  id
  email
  company {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
      id
      name
      project {
        id
        status
      }
      projectGroupId
      role {
        id
        name
      }
      skills {
        edges {
          node {
            id
          }
        }
      }
      placeholderAllocations {
        edges {
          node {
            id
            startDate
            endDate
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
            description
          }
        }
      }
      suggestedPersonIds
    }
    allPersons(first: 1000000, onlyActive: true, excludeClientUsers: true) {
      edges {
        node {
          id
          active
          firstName
          lastName
          fullName
          profilePictureId
          role {
            id
            name
          }
          skills {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeholderId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadPlaceholder",
    "type": "Boolean!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "assign_to_person_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"assign_to_person_modal\")"
},
v3 = {
  "kind": "Variable",
  "name": "placeholderId",
  "variableName": "placeholderId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillTypeConnection",
  "kind": "LinkedField",
  "name": "skills",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignToPersonModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadPlaceholder",
                "variableName": "loadPlaceholder"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AssignToPersonModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignToPersonModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeClientUsers",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000000
                  },
                  {
                    "kind": "Literal",
                    "name": "onlyActive",
                    "value": true
                  }
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:1000000,onlyActive:true)"
              },
              (v4/*: any*/),
              {
                "condition": "loadPlaceholder",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v3/*: any*/)
                    ],
                    "concreteType": "Placeholder",
                    "kind": "LinkedField",
                    "name": "placeholder",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectGroupId",
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlaceholderAllocationTypeConnection",
                        "kind": "LinkedField",
                        "name": "placeholderAllocations",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlaceholderAllocationTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PlaceholderAllocation",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endDate",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "suggestedPersonIds",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AssignToPersonModal_Query",
    "operationKind": "query",
    "text": "query AssignToPersonModal_Query(\n  $placeholderId: ID\n  $loadPlaceholder: Boolean!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"assign_to_person_modal\")\n    ...AssignToPersonModal_viewer_4hTwra\n    id\n  }\n}\n\nfragment AssignToPersonModal_viewer_4hTwra on Viewer {\n  id\n  email\n  company {\n    monday\n    tuesday\n    wednesday\n    thursday\n    friday\n    saturday\n    sunday\n    placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {\n      id\n      name\n      project {\n        id\n        status\n      }\n      projectGroupId\n      role {\n        id\n        name\n      }\n      skills {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      placeholderAllocations {\n        edges {\n          node {\n            id\n            startDate\n            endDate\n            monday\n            tuesday\n            wednesday\n            thursday\n            friday\n            saturday\n            sunday\n            description\n          }\n        }\n      }\n      suggestedPersonIds\n    }\n    allPersons(first: 1000000, onlyActive: true, excludeClientUsers: true) {\n      edges {\n        node {\n          id\n          active\n          firstName\n          lastName\n          fullName\n          profilePictureId\n          role {\n            id\n            name\n          }\n          skills {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '854455d5375c02f84778c1ea2e2458a6';

module.exports = node;
