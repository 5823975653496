import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import React from 'react';
import OrderedItem from './OrderedItem';
import {Background} from './Background';
import Styled from 'styled-components/macro';

const ListContainer = Styled.form`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 0px;
	width: 420px;
`;

const DraggableContainer = Styled.div`
	width: fit-content;
	height: fit-content;
    z-index: 2;
`;

const DroppableContainer = Styled.div`
 	gap: 12px;
  	display: flex;
  	flex-direction: column;
`;

const OrderedList = ({items, onOrderChanged, onDescriptionChanged, onRemoveItem, disabled}) => {
	return (
		<ListContainer disabled={disabled} style={{height: items.length * 52}}>
			<Background disabled={disabled} rows={items.length} />
			<DraggableContainer>
				<DragDropContext onDragEnd={data => onOrderChanged(data)}>
					<Droppable droppableId="skills">
						{provided => (
							<DroppableContainer ref={provided.innerRef}>
								{items.map((item, index) => (
									<OrderedItem
										disabled={disabled}
										key={item.level + ''}
										item={item}
										index={index}
										onDescriptionChanged={onDescriptionChanged}
										onRemoveItem={onRemoveItem}
									/>
								))}
								{provided.placeholder}
							</DroppableContainer>
						)}
					</Droppable>
				</DragDropContext>
			</DraggableContainer>
		</ListContainer>
	);
};

export default OrderedList;
