import React, {Component} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import SharedInsightLoginMutation from '../mutations/shared_insight_login_mutation';
import * as tracking from '../tracking';
import CustomScrollDiv from '../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import GridLayout from '../components/insights/grid_layout';
import Util from '../forecast-app/shared/util/util';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class sharedInsight extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dateSpan: null,
			errorMsg: null,
		};
		this.props.setLocaleFromBrowser();

		this.superPropertyChecksum = trackPage('Shared Insight');
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.downForMaintenance) {
			this.props.history.push({
				pathname: '/maintenance',
			});
		}
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('shared-insight');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		const password = this.refs.password.value.trim();

		if (!password.length) {
			this.setState({errorMsg: 'Please enter the password'});
			return;
		}

		const onSuccess = ({sharedInsightLogin}) => {
			if (sharedInsightLogin && sharedInsightLogin.errors && sharedInsightLogin.errors.length > 0) {
				if (sharedInsightLogin.errors[0] === 'NOT_SHARED') {
					this.setState({
						errorMsg: 'Sorry, this insight is no longer being shared.',
					});
				} else if (sharedInsightLogin.errors[0] === 'RATE_LIMIT') {
					this.setState({
						errorMsg: 'Too many login attempts.',
					});
				} else if (sharedInsightLogin.errors[0] === 'WRONG_PASSWORD') {
					this.setState({
						errorMsg: 'Sorry, it seems like the password is incorrect.',
					});
				}
			}
			if (sharedInsightLogin && sharedInsightLogin.errors === null) {
				this.props.relay.refetch();
			}
		};

		Util.CommitMutation(
			SharedInsightLoginMutation,
			{
				uuid: this.props.match.params.shareKey,
				password: password,
			},
			onSuccess,
			true
		);
	}

	setScrollElement(element) {
		this.setState({scrollElement: element});
	}

	render() {
		const {formatMessage} = this.props.intl;
		if (this.props.viewer.sharedInsight === null) {
			return (
				<div className="fullscreen fullscreen-centered-outer login login-bg">
					<div className="logo" />
					<div className="fullscreen-centered-inner">
						<div className="fullscreen-centered-content form-vertical">
							<form onSubmit={this.handleSubmit.bind(this)}>
								<h1>{formatMessage({id: 'login.report.title'})}</h1>
								<div data-cy="password-label" className="fieldset">
									<input
										type="password"
										ref="password"
										defaultValue=""
										placeholder={formatMessage({id: 'login.enter-password'})}
									/>
									{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
								</div>

								<div data-cy="login-button" className="buttons">
									<input
										type="submit"
										value={formatMessage({id: 'login.log-in'})}
										className={'btn btn-pull-right'}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			);
		} else {
			const hubspotLink =
				'https://experience.forecast.it/insights' +
				(this.props.viewer.sharedInsight.companyName ? '?company=' + this.props.viewer.sharedInsight.companyName : '');
			return (
				<div className="full-screen-mode">
					<div className="page-header">
						<h1 className="page-title">{this.props.viewer.sharedInsight.name}</h1>
						<h4 className="powered-by">
							<a href={hubspotLink}>Powered by Forecast</a>
						</h4>
						<a href={hubspotLink} className="logo" />
					</div>
					<CustomScrollDiv setScrollbarsRef={this.setScrollElement.bind(this)}>
						<div className="insights-viewer">
							<div className="main-container shared-insight">
								<div className="insight-container">
									{this.props.viewer.sharedInsight.components && this.state.scrollElement ? (
										<GridLayout
											components={this.props.viewer.sharedInsight.components}
											labelsOptions={this.props.viewer.sharedInsight.labels}
											skillsOptions={this.props.viewer.sharedInsight.skills}
											clientsOptions={this.props.viewer.sharedInsight.clients}
											contactPersonOptions={[]}
											rateCardOptions={[]}
											projectOptions={this.props.viewer.sharedInsight.projects}
											sprintOptions={this.props.viewer.sharedInsight.sprints}
											personOptions={this.props.viewer.sharedInsight.persons}
											departmentOptions={this.props.viewer.sharedInsight.departments}
											roleOptions={this.props.viewer.sharedInsight.roles}
											teamOptions={this.props.viewer.sharedInsight.teams}
											editMode={false}
											projectId={null}
											insightId={this.props.viewer.sharedInsight.id}
											personId={null}
											dateSpan={this.state.dateSpan ? this.state.dateSpan : null}
											clientId={null}
											shareKey={this.props.match.params.shareKey}
											forceFetch={false}
											scrollElement={this.state.scrollElement}
										/>
									) : null}
								</div>
							</div>
						</div>
					</CustomScrollDiv>
				</div>
			);
		}
	}
}

const sharedInsightQuery = graphql`
	query sharedInsight_Query($shareKey: String) {
		viewer {
			component(name: "shared_insight")
			...sharedInsight_viewer @arguments(shareKey: $shareKey)
		}
	}
`;

export {sharedInsightQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			sharedInsight,
			{
				viewer: graphql`
					fragment sharedInsight_viewer on Viewer @argumentDefinitions(shareKey: {type: "String"}) {
						id
						downForMaintenance
						sharedInsight(shareKey: $shareKey) {
							id
							name
							companyName
							category
							labels {
								id
								name
								category {
									allowOnTasks
									allowOnProjects
									allowOnPeople
								}
							}
							skills {
								id
								name
							}
							clients {
								id
								name
							}
							projects {
								id
								name
								client {
									id
								}
							}
							sprints {
								id
								name
								startYear
								startMonth
								startDay
								endYear
								endMonth
								endDay
								projectGroupSprintId
								isProjectGroupSprint
							}
							persons {
								id
								firstName
								lastName
							}
							departments {
								id
								name
							}
							roles {
								id
								name
							}
							teams {
								id
								name
							}
							components {
								id
								componentName
								x
								y
								w
								h
								config
							}
						}
					}
				`,
			},
			graphql`
				query sharedInsightRefetchQuery($shareKey: String) {
					viewer {
						...sharedInsight_viewer @arguments(shareKey: $shareKey)
					}
				}
			`
		)
	)
);
