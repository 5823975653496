/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
type ProjectDropdown_projects$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type QUANTITY_LOCKED_REASON = "FIXED_PRICE" | "FIXED_PRICE_PERIOD" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type standaloneEditInvoiceModal_viewer$ref: FragmentReference;
declare export opaque type standaloneEditInvoiceModal_viewer$fragmentType: standaloneEditInvoiceModal_viewer$ref;
export type standaloneEditInvoiceModal_viewer = {|
  +id: string,
  +backendId: ?number,
  +company: ?{|
    +invoiceQuantityDecimals: ?number
  |},
  +invoice: ?{|
    +id: string,
    +invoiceReference: ?string,
    +companyInvoiceId: ?number,
    +name: ?string,
    +currency: ?string,
    +invoiceType: ?string,
    +status: ?string,
    +dueDay: ?number,
    +dueMonth: ?number,
    +dueYear: ?number,
    +createdDay: ?number,
    +createdMonth: ?number,
    +createdYear: ?number,
    +notes: ?string,
    +quickbooksId: ?number,
    +xeroId: ?string,
    +economicId: ?number,
    +entries: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +quantity: ?number,
          +quantityText: ?string,
          +unitPrice: ?number,
          +discount: ?number,
          +tax: ?number,
          +description: ?string,
          +quantityLockedReason: ?QUANTITY_LOCKED_REASON,
          +project: ?{|
            +id: string,
            +companyProjectId: ?number,
            +name: ?string,
            +projectColor: ?string,
            +isInProjectGroup: ?boolean,
            +budgetType: ?BUDGET_TYPE,
            +client: ?{|
              +name: ?string
            |},
            +rateCard: ?{|
              +currency: ?string
            |},
          |},
        |}
      |}>
    |},
    +payments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +notes: ?string,
          +amount: ?number,
          +day: ?number,
          +month: ?number,
          +year: ?number,
          +createdAt: ?string,
          +createdBy: ?{|
            +fullName: ?string
          |},
        |}
      |}>
    |},
    +timeRegistrations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +year: ?number,
          +month: ?number,
          +day: ?number,
          +project: ?{|
            +id: string,
            +companyProjectId: ?number,
            +projectColor: ?string,
          |},
          +task: ?{|
            +project: ?{|
              +id: string,
              +companyProjectId: ?number,
              +projectColor: ?string,
            |}
          |},
          +person: ?{|
            +fullName: ?string
          |},
          +notes: ?string,
          +billableMinutesRegistered: ?number,
          +minutesRegistered: ?number,
          +price: ?number,
          +cost: ?number,
          +rate: ?number,
        |}
      |}>
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +projectColor: ?string,
        +isInProjectGroup: ?boolean,
        +companyProjectId: ?number,
        +projectGroupId: ?string,
        +budgetType: ?BUDGET_TYPE,
        +status: ?PROJECT_STATUS,
        +fullAccessToProject: ?boolean,
        +client: ?{|
          +id: string
        |},
        +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
      |},
      +$fragmentRefs: ProjectDropdown_projects$ref,
    |}>
  |},
  +language: ?LANGUAGE,
  +$refType: standaloneEditInvoiceModal_viewer$ref,
|};
export type standaloneEditInvoiceModal_viewer$data = standaloneEditInvoiceModal_viewer;
export type standaloneEditInvoiceModal_viewer$key = {
  +$data?: standaloneEditInvoiceModal_viewer$data,
  +$fragmentRefs: standaloneEditInvoiceModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000000
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProjectGroup",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "invoiceId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "companyInvoiceId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "standaloneEditInvoiceModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceQuantityDecimals",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyInvoiceId",
          "variableName": "companyInvoiceId"
        },
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "invoiceId"
        }
      ],
      "concreteType": "InvoiceType",
      "kind": "LinkedField",
      "name": "invoice",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyInvoiceId",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceType",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdYear",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quickbooksId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xeroId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "economicId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "InvoiceEntryTypeConnection",
          "kind": "LinkedField",
          "name": "entries",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceEntryTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InvoiceEntryType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantityText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "discount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tax",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantityLockedReason",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v6/*: any*/),
                        (v1/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RateCard",
                          "kind": "LinkedField",
                          "name": "rateCard",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "entries(first:100000000)"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "InvoicePaymentTypeConnection",
          "kind": "LinkedField",
          "name": "payments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoicePaymentTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InvoicePaymentType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "createdBy",
                      "plural": false,
                      "selections": (v13/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "payments(first:100000000)"
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "TimeRegTypeConnection",
          "kind": "LinkedField",
          "name": "timeRegistrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeRegType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v12/*: any*/),
                    (v11/*: any*/),
                    (v10/*: any*/),
                    (v14/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Task",
                      "kind": "LinkedField",
                      "name": "task",
                      "plural": false,
                      "selections": [
                        (v14/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": (v13/*: any*/),
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableMinutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cost",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "timeRegistrations(first:100000000)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectGroupId",
                  "storageKey": null
                },
                (v9/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullAccessToProject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "client",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DeprecatedProjectIndicatorJS_project"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectDropdown_projects"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '37300989c89ccddf6fb9c3c46f38f8b8';

module.exports = node;
