/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type impersonate_viewer$ref = any;
export type impersonate_QueryVariables = {||};
export type impersonate_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: impersonate_viewer$ref,
  |}
|};
export type impersonate_Query = {|
  variables: impersonate_QueryVariables,
  response: impersonate_QueryResponse,
|};
*/


/*
query impersonate_Query {
  viewer {
    actualPersonId
    component(name: "control_impersonate")
    ...impersonate_viewer
    id
  }
}

fragment impersonate_viewer on Viewer {
  id
  ...missionControlHeader_viewer
  actualPersonId
  missionControlData {
    id
    forecastPersons {
      id
      firstName
      lastName
      impersonatedPersonId
      email
    }
    otherPersons(includeForecastPersons: true) {
      id
      firstName
      lastName
      email
      companyName
      profileName
      datacenter
    }
  }
}

fragment missionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "control_impersonate"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"control_impersonate\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "impersonate_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "impersonate_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "impersonate_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlImpersonatePersonType",
                "kind": "LinkedField",
                "name": "forecastPersons",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "impersonatedPersonId",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeForecastPersons",
                    "value": true
                  }
                ],
                "concreteType": "MissionControlImpersonatePersonType",
                "kind": "LinkedField",
                "name": "otherPersons",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datacenter",
                    "storageKey": null
                  }
                ],
                "storageKey": "otherPersons(includeForecastPersons:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "impersonate_Query",
    "operationKind": "query",
    "text": "query impersonate_Query {\n  viewer {\n    actualPersonId\n    component(name: \"control_impersonate\")\n    ...impersonate_viewer\n    id\n  }\n}\n\nfragment impersonate_viewer on Viewer {\n  id\n  ...missionControlHeader_viewer\n  actualPersonId\n  missionControlData {\n    id\n    forecastPersons {\n      id\n      firstName\n      lastName\n      impersonatedPersonId\n      email\n    }\n    otherPersons(includeForecastPersons: true) {\n      id\n      firstName\n      lastName\n      email\n      companyName\n      profileName\n      datacenter\n    }\n  }\n}\n\nfragment missionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bbff76bf55e5a8bec9d5421425dc17fd';

module.exports = node;
