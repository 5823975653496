import React, {Component} from 'react';
import CreateXeroOauthTokenSwapMutation from '../mutations/create_xero_oauth_token_key_swap';
import {withRouter} from 'react-router-dom';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import qs from 'query-string';
import Util from '../forecast-app/shared/util/util';

class XeroToken extends Component {
	componentDidMount() {
		// oauth_verifier
		const state = qs.parse(this.props.location.search).state;

		const onSuccess = () => {
			if (state === 'app_catalog') {
				this.props.history.push('/admin/integrations/xero');
			} else {
				this.props.history.push('/project/P-' + state + '/xero');
			}
		};

		const obj = {
			verifier: qs.parse(this.props.location.search).oauth_verifier,
			tmpInvoiceId: qs.parse(this.props.location.search).tmpInvoiceId,
		};
		if (state === 'app_catalog') {
			obj.install = true;
		}
		Util.CommitMutation(CreateXeroOauthTokenSwapMutation, obj, onSuccess);
	}

	render() {
		return <UploadingOverlay />;
	}
}

export default withRouter(XeroToken);
