import styled from 'styled-components';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, MODULE_TYPES} from '../../../constants';
import React, {useState} from 'react';
import Util from '../../../forecast-app/shared/util/util';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import EnableModuleMutation from '../../../mutations/mission_control/enable_module_mutation';
import DisableModuleMutation from '../../../mutations/mission_control/disable_module_mutation';
import UpdateCompanyInternalDataMutation from '../../../mutations/mission_control/update_company_internal_data_mutation';
import GenericModal from '../../modal/generic_modal';

const ModuleModalStyle = styled.div`
	min-width: 640px;
`;

export const ModuleModal = ({module, companiesWithModules, closeModal}) => {
	const editMode = !!module.id;

	// Find existing modules for the company
	const companyWithModules = companiesWithModules.find(companyWithModules => companyWithModules.id === module.companyId);
	const existingModuleTypes = companyWithModules.modules.edges.map(edge => edge.node).map(module => module.moduleType);

	const headerText = editMode
		? `Edit module for ${companyWithModules.companyName}`
		: `Add module to ${companyWithModules.companyName}`;

	const moduleTypeOptions = Object.keys(MODULE_TYPES)
		.filter(moduleType => existingModuleTypes.indexOf(moduleType) === -1 || moduleType === module.moduleType)
		.map(moduleType => ({
			label:
				MODULE_TYPES[moduleType] === 'TIME_LOCKING'
					? 'TIME LOCKING / APPROVAL'
					: MODULE_TYPES[moduleType] === 'SISENSE'
					? 'ADVANCED ANALYTICS'
					: MODULE_TYPES[moduleType],
			value: moduleType,
		}));

	const [moduleType, setModuleType] = useState(module.moduleType);
	const [startDate, setStartDate] = useState(
		Util.CreateNonUtcMomentDate(module.startYear, module.startMonth, module.startDay)
	);
	const [endDate, setEndDate] = useState(Util.CreateNonUtcMomentDate(module.endYear, module.endMonth, module.endDay));
	const [avaViewerSeats, setAvaViewerSeats] = useState(companyWithModules.advancedAnalyticsViewerSeats);
	const [avaDesignerSeats, setAvaDesignerSeats] = useState(companyWithModules.advancedAnalyticsDesignerSeats);

	const content = (
		<ModuleModalStyle>
			<Dropdown
				options={[
					{
						label: companyWithModules.companyName,
						value: companyWithModules.id,
					},
				]}
				onChange={() => {}}
				value={companyWithModules.id}
				multi={false}
				disabled={true}
				label={'Customer'}
				clearable={false}
			/>
			<br />
			<br />
			<Dropdown
				options={moduleTypeOptions}
				onChange={option => setModuleType(option.value)}
				value={moduleType}
				multi={false}
				disabled={editMode}
				label={'Select module'}
				clearable={false}
			/>
			{moduleType === MODULE_TYPES.SISENSE ? (
				<>
					<br />
					Viewer Seats:{' '}
					<input
						onChange={e => setAvaViewerSeats(e.target.value)}
						value={avaViewerSeats}
						type="number"
						min="0"
						style={{width: 'auto'}}
					></input>
					<br />
					Designer Seats:{' '}
					<input
						onChange={e => setAvaDesignerSeats(e.target.value)}
						value={avaDesignerSeats}
						type="number"
						min="0"
						style={{width: 'auto'}}
					></input>
				</>
			) : null}
			<br />
			<br />
			<DatePicker
				label={'Trial start/end date'}
				id={'module-date-picker'}
				isNewDateRange={true}
				startDate={startDate}
				endDate={endDate}
				handleDateRangeChange={(newStartDate, newEndDate) => {
					setStartDate(newStartDate);
					setEndDate(newEndDate);
				}}
				clearBothDates={() => {
					setStartDate(null);
					setEndDate(null);
				}}
				clearable={true}
				datePickerStyle={DATE_PICKER_STYLE.STANDARD}
				colorInherited={true}
				compactShowYear={true}
			/>
		</ModuleModalStyle>
	);

	const buttons = [
		{
			text: 'Cancel',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: 'Save',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.GREEN,
			disabled: !moduleType,
			callback: () => {
				const startDateValues = Util.GetYearMonthDateFromMomentDate(startDate);
				const endDateValues = Util.GetYearMonthDateFromMomentDate(endDate);

				Util.CommitMutation(
					EnableModuleMutation,
					{
						id: module.id,
						datacenter: module.datacenter,
						moduleType: moduleType,
						companyId: module.companyId,
						startYear: startDateValues.year,
						startMonth: startDateValues.month,
						startDay: startDateValues.day,
						endYear: endDateValues.year,
						endMonth: endDateValues.month,
						endDay: endDateValues.day,
					},
					() => {
						if (
							avaViewerSeats !== companyWithModules.advancedAnalyticsViewerSeats ||
							avaDesignerSeats !== companyWithModules.advancedAnalyticsDesignerSeats
						) {
							Util.CommitMutation(
								UpdateCompanyInternalDataMutation,
								{
									companyId: companyWithModules.companyId,
									sisenseViewerSeats: parseInt(avaViewerSeats),
									sisenseDesignerSeats: parseInt(avaDesignerSeats),
								},
								() => {
									// Refresh screen to fetch updated seat numbers
									location.reload();
								}
							);
						}
					}
				);
			},
		},
	];

	if (editMode) {
		buttons.push({
			text: 'Remove',
			style: BUTTON_STYLE.FILLED,
			color: BUTTON_COLOR.RED,
			callback: () => {
				Util.CommitMutation(DisableModuleMutation, module);
			},
		});
	}

	return <GenericModal closeModal={closeModal} buttons={buttons} content={content} headerText={headerText} />;
};
