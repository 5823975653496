import React from 'react';
import {useIntl} from 'react-intl';
import {CurrencyInput, Table, Checkbox} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {Icon, NumberField, Text} from '@forecast-it/design-system';
import {Button} from 'web-components';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {RoundingEntities} from '../../../forecast-app/shared/util/RoundingUtil';

const RateCardRoleTable = ({
	rateCardVersion,
	currency,
	handleRoleDefaultChange,
	handleRoleRateChange,
	handleRoleDisabledChange,
	disabledSection,
	disabledRoles,
}) => {
	const intl = useIntl();
	const hasDisableRoles = hasFeatureFlag('disable_rate_card_roles');
	const hasRateRounding = hasFeatureFlag('rate_rounding');

	return (
		<Table paddedCells>
			<Table.Header>
				<Table.HeaderColumn flex={1} visible usePadding width={Table.TABLE_COLUMN_WIDTH.LARGE}>
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.name'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn
					usePadding
					visible
					width={hasRateRounding ? Table.TABLE_COLUMN_WIDTH.SMALL : Table.TABLE_COLUMN_WIDTH.MEDIUM_SMALL}
				>
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'rate_card_modal.use_default_rate'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn
					usePadding
					visible
					width={hasRateRounding ? Table.TABLE_COLUMN_WIDTH.MEDIUM : Table.TABLE_COLUMN_WIDTH.MEDIUM_SMALL}
					align={Table.TABLE_COLUMN_ALIGNMENT.RIGHT}
				>
					<Table.HeaderColumn.Title usePadding>{intl.formatMessage({id: 'common.rate'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding visible={hasDisableRoles} width={Table.TABLE_COLUMN_WIDTH.MEDIUM_LARGE}>
					<Table.HeaderColumn.Title>
						{intl.formatMessage({
							id: disabledSection
								? 'rate_card_modal.activate_role_header'
								: 'rate_card_modal.deactivate_role_header',
						})}
						<ForecastTooltip content={intl.formatMessage({id: 'rate_card_modal.deactivate_role_tooltip'})}>
							<Icon icon="help" size="s" />
						</ForecastTooltip>
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			</Table.Header>
			<Table.Rows
				data={{
					rows: rateCardVersion.roleRates.filter(roleRate =>
						disabledSection ? disabledRoles.includes(roleRate.roleId) : !disabledRoles.includes(roleRate.roleId)
					),
				}}
			>
				{({rowData, tableColumnsProps}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							<Table.Column>
								<Table.Column>
									<Text ellipsis={true} tooltipOnEllipsis={true} size={'3'}>
										{rowData.name}
									</Text>
								</Table.Column>
							</Table.Column>
							<Table.Column>
								<Checkbox
									checked={rowData.isUsingDefaultRate || disabledSection}
									onChange={e => handleRoleDefaultChange(rowData.roleId, e.target.checked)}
									disabled={disabledSection}
								/>
							</Table.Column>
							<Table.Column>
								{hasRateRounding ? (
									<div style={{maxWidth: '100%'}}>
										<NumberField
											disabled={rowData.isUsingDefaultRate || disabledSection}
											value={
												rowData.rate !== null && !disabledSection
													? rowData.rate
													: rateCardVersion.defaultRate
											}
											maximumFractionDigits={RoundingEntities.RATE.decimals}
											onChange={rate => handleRoleRateChange(rowData.roleId, rate)}
											format={NumberField.FORMAT.CURRENCY}
											currencyCode={currency}
											width={NumberField.WIDTH.FLEX}
											size={NumberField.SIZE.SMALL}
										/>
									</div>
								) : (
									<CurrencyInput
										disabled={rowData.isUsingDefaultRate || disabledSection}
										value={
											rowData.rate !== null && !disabledSection
												? rowData.rate
												: rateCardVersion.defaultRate
										}
										currencySymbole={Util.GetCurrencySymbol(currency)}
										callback={rate => handleRoleRateChange(rowData.roleId, rate)}
										filterNonNumerics={true}
									/>
								)}
							</Table.Column>
							<Table.Column>
								<Button
									variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
									onClick={() => handleRoleDisabledChange(rowData.roleId, !disabledSection)}
								>
									{disabledSection
										? intl.formatMessage({id: 'common.activate'})
										: intl.formatMessage({id: 'common.deactivate'})}
								</Button>
							</Table.Column>
						</Table.Row>
					);
				}}
			</Table.Rows>
		</Table>
	);
};

export default RateCardRoleTable;
