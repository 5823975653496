import React, {useState} from 'react';
import EnableAndConvertSubtasksForProjectMutation from '../../../../mutations/EnableAndConvertSubtasksForProjectMutation';
import GenericModal, {MODAL_WIDTH} from '../../../modal/generic_modal';
import Util from '../../../../forecast-app/shared/util/util';

import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';

import {RadioButton} from 'web-components';
import {FormattedMessage, useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';

import {
	CheckBoxDescription,
	RadioWithDescriptionWrapper,
	SelectionElementLabel,
	SelectionElementLineWrapper,
	ConversionTableWrapper,
} from '../ProjectSettings_styled';

const CONVERSION_METHOD = {
	CONVERT_TO_TODOS: 'CONVERT_TO_TODOS',
	CONVERT_TO_TODOS_KEEP_REMAINING: 'CONVERT_TO_TODOS_KEEP_REMAINING',
	CONVERT_TO_NEW_SUBTASKS: 'CONVERT_TO_NEW_SUBTASKS',
};

const ConvertOldSubtasksModal = ({projectId, closeModal, viewer}) => {
	const intl = useIntl();
	const [conversionMethod, setConversionMethod] = useState(null);

	const formatTime = value => {
		const estimatedInHours = viewer.project.estimationUnit === 'HOURS';

		if (estimatedInHours) {
			return Util.convertMinutesToFullHour(value, intl, false);
		} else {
			return Util.roundPoints(value, intl);
		}
	};

	const handleSave = () => {
		const onSuccess = () => {
			closeModal();
		};
		Util.CommitMutation(
			EnableAndConvertSubtasksForProjectMutation,
			{
				projectId,
				conversionMethod,
			},
			onSuccess
		);
	};

	const calculateConsequences = () => {
		let cons = {
			estimateMinutes: 0,
			remainingMinutes: 0,
		};

		const getRemainingChangesForSubtaskRemove = t => {
			// Get total "active" estimate on subtasks
			let subtaskActiveEstimate = 0;

			t.node.subTasks.edges.forEach(st => {
				if (!st.node.done) {
					subtaskActiveEstimate += st.node.estimate;
				}
			});

			// Get what the estimate will be after
			const postConversionEstimate =
				t.node.estimateForecast < t.node.totalMinutesRegistered
					? 0
					: t.node.estimateForecast - t.node.totalMinutesRegistered;

			return postConversionEstimate - subtaskActiveEstimate;
		};

		if (conversionMethod === CONVERSION_METHOD.CONVERT_TO_TODOS) {
			if (viewer.project.remainingAutoCalculated) {
				viewer.project.tasks.edges.forEach(t => {
					if (t.node.subTasks.edges.length > 0 && !t.node.done)
						cons.remainingMinutes += getRemainingChangesForSubtaskRemove(t);
				});
			} else {
				// No changes
			}
		} else if (conversionMethod === CONVERSION_METHOD.CONVERT_TO_TODOS_KEEP_REMAINING) {
			// Keeps remaining, so no changes
		} else if (conversionMethod === CONVERSION_METHOD.CONVERT_TO_NEW_SUBTASKS) {
			if (viewer.project.remainingAutoCalculated) {
				// Total estimate on subtasks
				viewer.project.tasks.edges.forEach(t => {
					if (t.node.subTasks.edges.length > 0) {
						t.node.subTasks.edges.forEach(st => {
							cons.estimateMinutes += st.node.estimate;

							// If subtask is not done, remaining will increase by estimate too
							if (!st.node.done) {
								cons.remainingMinutes += st.node.estimate;
							}
						});

						cons.remainingMinutes += getRemainingChangesForSubtaskRemove(t);
					}
				});
			} else {
				viewer.project.tasks.edges.forEach(t => {
					let subtaskEstimateSum = 0,
						notDoneEstimate = 0;
					if (t.node.subTasks.edges.length > 0) {
						t.node.subTasks.edges.forEach(st => {
							subtaskEstimateSum += st.node.estimate;
							if (!st.node.done) notDoneEstimate += st.node.estimate;
						});
					}

					// Estimate increases by sum of subtask estimates
					cons.estimateMinutes += subtaskEstimateSum;

					// Remaining is set to sum of not done subtask estimates
					if (!t.node.done) {
						cons.remainingMinutes += notDoneEstimate - t.node.estimateForecast;
					}
				});
			}
		}

		return cons;
	};

	const getContent = () => {
		const consequences = calculateConsequences();

		return (
			<>
				<FormattedMessage
					id="subtask_conversion_modal.description"
					values={{
						a: msg => (
							<a
								href="https://support.forecast.app/hc/en-us/articles/5696437741969"
								target="_blank"
								rel="noopener noreferrer"
							>
								{msg}
							</a>
						),
					}}
				/>
				<RadioWithDescriptionWrapper>
					<SelectionElementLineWrapper>
						<RadioButton
							checked={conversionMethod === CONVERSION_METHOD.CONVERT_TO_NEW_SUBTASKS}
							onClick={() => setConversionMethod(CONVERSION_METHOD.CONVERT_TO_NEW_SUBTASKS)}
						/>
						<SelectionElementLabel>
							{intl.formatMessage({id: 'subtask_conversion_modal.convert_to_subtasks'})}
						</SelectionElementLabel>
					</SelectionElementLineWrapper>
					<CheckBoxDescription>
						{intl.formatMessage({id: 'subtask_conversion_modal.convert_to_subtasks_option'})}
					</CheckBoxDescription>
				</RadioWithDescriptionWrapper>
				<RadioWithDescriptionWrapper>
					<SelectionElementLineWrapper>
						<RadioButton
							checked={conversionMethod === CONVERSION_METHOD.CONVERT_TO_TODOS_KEEP_REMAINING}
							onClick={() => setConversionMethod(CONVERSION_METHOD.CONVERT_TO_TODOS_KEEP_REMAINING)}
						/>
						<SelectionElementLabel>
							{intl.formatMessage({id: 'subtask_conversion_modal.convert_to_todos_keep_remaining'})}
						</SelectionElementLabel>
					</SelectionElementLineWrapper>
					<CheckBoxDescription>
						{intl.formatMessage({id: 'subtask_conversion_modal.convert_to_todos_keep_remaining_option'})}
					</CheckBoxDescription>
				</RadioWithDescriptionWrapper>
				<RadioWithDescriptionWrapper>
					<SelectionElementLineWrapper>
						<RadioButton
							checked={conversionMethod === CONVERSION_METHOD.CONVERT_TO_TODOS}
							onClick={() => setConversionMethod(CONVERSION_METHOD.CONVERT_TO_TODOS)}
						/>
						<SelectionElementLabel>
							{intl.formatMessage({id: 'subtask_conversion_modal.convert_to_todos'})}
						</SelectionElementLabel>
					</SelectionElementLineWrapper>
					<CheckBoxDescription>
						{intl.formatMessage({id: 'subtask_conversion_modal.convert_to_todos_option'})}
					</CheckBoxDescription>
				</RadioWithDescriptionWrapper>
				<br />
				<FormattedMessage
					id="subtask_conversion_modal.consequences"
					values={{
						br: <br />,
					}}
				/>
				<br />
				<ConversionTableWrapper>
					<table>
						<tr>
							<td></td>
							<td>{intl.formatMessage({id: 'common.before'})}</td>
							<td>{intl.formatMessage({id: 'common.after'})}</td>
							<td>{intl.formatMessage({id: 'common.difference'})}</td>
						</tr>
						<tr>
							<td>{intl.formatMessage({id: 'common.estimate'})}</td>
							<td>{formatTime(viewer.project.forecastTotal)}</td>
							<td>{formatTime(viewer.project.forecastTotal + consequences.estimateMinutes)}</td>
							<td>
								{consequences.estimateMinutes >= 0 ? '+' : ''}
								{formatTime(consequences.estimateMinutes)}
							</td>
						</tr>
						<tr>
							<td>{intl.formatMessage({id: 'common.remaining'})}</td>
							<td>{formatTime(viewer.project.remaining)}</td>
							<td>{formatTime(viewer.project.remaining + consequences.remainingMinutes)}</td>
							<td>
								{consequences.remainingMinutes >= 0 ? '+' : ''}
								{formatTime(consequences.remainingMinutes)}
							</td>
						</tr>
					</table>
				</ConversionTableWrapper>
			</>
		);
	};

	return (
		<GenericModal
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					callback: () => closeModal(),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.accept'}),
					callback: () => handleSave(),
					disabled: conversionMethod == null,
				},
			]}
			closeModal={closeModal}
			content={getContent()}
			headerText={intl.formatMessage({id: 'subtask_conversion_modal.title'})}
			modalWidth={MODAL_WIDTH.LARGE}
		/>
	);
};

const convertOldSubtasksModalQuery = graphql`
	query ConvertOldSubtasksModalQuery($projectId: ID) {
		viewer {
			id
			actualPersonId
			component(name: "convert_old_subtasks_modal")
			...ConvertOldSubtasksModal_viewer @arguments(id: $projectId)
		}
	}
`;

export {convertOldSubtasksModalQuery};

export default createFragmentContainer(ConvertOldSubtasksModal, {
	viewer: graphql`
		fragment ConvertOldSubtasksModal_viewer on Viewer @argumentDefinitions(id: {type: "ID!"}) {
			id
			project(internalId: $id) {
				remainingAutoCalculated
				minutesPerEstimationPoint
				estimationUnit
				remaining
				forecastTotal
				tasks {
					edges {
						node {
							id
							timeLeft
							estimateForecast
							done
							totalMinutesRegistered
							subTasks {
								edges {
									node {
										id
										estimate
										done
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
