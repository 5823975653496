/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SkillRow_skill$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SkillCategoryRow_skills$ref: FragmentReference;
declare export opaque type SkillCategoryRow_skills$fragmentType: SkillCategoryRow_skills$ref;
export type SkillCategoryRow_skills = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: SkillRow_skill$ref,
  +$refType: SkillCategoryRow_skills$ref,
|}>;
export type SkillCategoryRow_skills$data = SkillCategoryRow_skills;
export type SkillCategoryRow_skills$key = $ReadOnlyArray<{
  +$data?: SkillCategoryRow_skills$data,
  +$fragmentRefs: SkillCategoryRow_skills$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SkillCategoryRow_skills",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillRow_skill"
    }
  ],
  "type": "Skill"
};
// prettier-ignore
(node/*: any*/).hash = 'db345436be00c622fa3776298ced3ab4';

module.exports = node;
