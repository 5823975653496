/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ForecastSurplusModalChartSection_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ForecastSurplusModal_viewer$ref: FragmentReference;
declare export opaque type ForecastSurplusModal_viewer$fragmentType: ForecastSurplusModal_viewer$ref;
export type ForecastSurplusModal_viewer = {|
  +project: ?{|
    +id: string,
    +name: ?string,
    +companyProjectId: ?number,
    +revenueMarginLow: ?number,
    +revenueMarginHigh: ?number,
    +rateCard: ?{|
      +currency: ?string
    |},
    +$fragmentRefs: ForecastSurplusModalChartSection_project$ref,
  |},
  +$refType: ForecastSurplusModal_viewer$ref,
|};
export type ForecastSurplusModal_viewer$data = ForecastSurplusModal_viewer;
export type ForecastSurplusModal_viewer$key = {
  +$data?: ForecastSurplusModal_viewer$data,
  +$fragmentRefs: ForecastSurplusModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ForecastSurplusModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueMarginLow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueMarginHigh",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ForecastSurplusModalChartSection_project"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'abd7c165ebd72a968530d7e7a34dc4ad';

module.exports = node;
