import Styled from 'styled-components';

export const LimitWidth = Styled.div`
	{
	  max-width: 600px;
	}
`;

export const ButtonContainer = Styled.div`
	{
	  float: right;
	}
`;
