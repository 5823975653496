import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import ExportInvoiceQuickbooksMutation from '../../mutations/export_invoice_quickbooks_mutation';
import SelectInput from '../../components/inputs/select_input';
import Switch from '../../forecast-app/shared/components/switch/switch';
import Util from '../../forecast-app/shared/util/util';
import {BUTTON_COLOR} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import GenericModal from './generic_modal';

class InvoiceModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customerId: this.props.customers[0] ? this.props.customers[0].value : '-1',
			taxCodeId: '-1',
			letQBODecideRef: false,
			exportEntryNames: false,
		};
	}

	exportInvoice() {
		this.props.setExporting(true);
		let message = `${this.props.invoice.node.name} ${this.props.intl.formatMessage({
			id: 'invoicing.has_been_exported_to_quickbooks_online',
		})}`;

		Util.CommitMutation(
			ExportInvoiceQuickbooksMutation,
			{
				invoiceId: this.props.invoice.node.id,
				customerId: this.state.customerId,
				taxCodeId: this.state.taxCodeId,
				blankReference: this.state.letQBODecideRef,
				exportNames: this.state.exportEntryNames,
			}, // Toast
			res => {
				this.props.setExporting(false);
				if (res.exportInvoiceQuickbooks.invoice.quickbooksId) createToast({duration: 5000, message: message});
				else
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'invoicing.quickbooks_export_failed'}),
					});
			}
		);
	}

	toggleExportOption(name) {
		this.setState({
			[name]: !this.state[name],
		});
	}

	setCustomer(val) {
		this.setState({
			customerId: val,
		});
	}

	setTaxCode(val) {
		this.setState({
			taxCodeId: val,
		});
	}

	closeModalWrapper() {
		this.props.closeModal();
		this.props.setExporting(false);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const failure =
			!!this.props.customers.Error ||
			!!this.props.taxCodes.Error ||
			this.props.customers.length === 0 ||
			!this.props.docNumberAvailable;

		let content;
		if (!this.props.docNumberAvailable) content = <FormattedMessage id={'invoicing.quickbooks_doc_number_taken'} />;
		else if (this.props.customers.Error || this.props.taxCodes.Error) {
			content = (
				<div>
					<FormattedMessage id={'invoicing.quickbooks_error'} />
					<br />
					<span>{this.props.customers.Error}</span>
					<br />
					<span>{this.props.taxCodes.Error}</span>
				</div>
			);
		} else if (this.props.customers.length === 0) {
			content = <FormattedMessage id={'invoicing.no_customers_found'} />;
		} else {
			content = (
				<>
					<div key="invoicingPaymentNote" className="invoicing-payments-note">
						{formatMessage({id: 'invoicing.payments_will_be_deleted'})}
					</div>
					<SelectInput
						key="invoicingQuickbooksCustomerDropdown"
						value={this.state.customerId}
						options={this.props.customers}
						label={formatMessage({id: 'common.customer'})}
						onChange={v => this.setCustomer(v.value)}
					/>
					<SelectInput
						key="invoicingQuickbooksTaxCodeDropdown"
						value={this.state.taxCodeId}
						options={[
							{label: formatMessage({id: 'invoicing.no_tax'}), value: '-1'},
							...this.props.taxCodes.filter(c => !['TAX', 'NON', 'CustomSalesTax'].includes(c.value)),
						]}
						label={formatMessage({id: 'common.tax_code'})}
						onChange={v => this.setTaxCode(v.value)}
					/>
					<div className="switch-container-export-option">
						<label htmlFor={'invoice-export-ref'}>
							{formatMessage({id: 'invoicing.quickbooks.control_reference'})}
						</label>
						<Switch
							id={'invoice-export-ref'}
							onChange={() => this.toggleExportOption('letQBODecideRef')}
							checked={this.state.letQBODecideRef}
						/>
					</div>
					<div className="switch-container-export-option">
						<label htmlFor={'invoice-export-names'}>
							{formatMessage({id: 'invoicing.export_entry_names_as_description'})}
						</label>
						<Switch
							id={'invoice-export-names'}
							onChange={() => this.toggleExportOption('exportEntryNames')}
							checked={this.state.exportEntryNames}
						/>
					</div>
				</>
			);
		}
		return (
			<GenericModal
				className="export-quickbooks-modal"
				closeModal={() => this.closeModalWrapper()}
				buttons={[
					{key: 'exportQBModalCancelButton', text: formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
					{
						key: 'exportQBModalExportButten',
						text: formatMessage({id: 'common.export'}),
						callback: () => this.exportInvoice(),
						disabled: failure,
					},
				]}
				content={content}
				headerText={formatMessage({id: 'invoicing.export_quickbooks_online'})}
			/>
		);
	}
}

export default injectIntl(InvoiceModal);
