/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type loginOnelogin_viewer$ref: FragmentReference;
declare export opaque type loginOnelogin_viewer$fragmentType: loginOnelogin_viewer$ref;
export type loginOnelogin_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +$refType: loginOnelogin_viewer$ref,
|};
export type loginOnelogin_viewer$data = loginOnelogin_viewer;
export type loginOnelogin_viewer$key = {
  +$data?: loginOnelogin_viewer$data,
  +$fragmentRefs: loginOnelogin_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "loginOnelogin_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '3e90163c8d17543cca9f0f069e8f6e58';

module.exports = node;
