import {canAmountFitInBudget} from '../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {CAP_SETTING} from '../../../constants';

const ERROR = {
	NON_OWNER_APPROVED_EXPENSE_CANNOT_EDIT: 'NON_OWNER_APPROVED_EXPENSE_CANNOT_EDIT',
	NON_OWNER_UNAPPROVED_EXPENSE_CANNOT_APPROVE: 'NON_OWNER_UNAPPROVED_EXPENSE_CANNOT_APPROVE',
	EXPENSE_OVERFLOW: 'EXPENSE_OVERFLOW',
	LOCKED_PROGRAM_CAP: 'LOCKED_PROGRAM_CAP',
};

export class ExpenseProgramLogic {
	constructor(
		isInFixedPriceProgram,
		isInCappedRevenueProgram,
		canManageProgram,
		programRemainingBudget,
		isExpenseApproved,
		isExpenseBillable,
		isCreate,
		expenseTotalAmount,
		revenueSetting,
		partOfFixedPrice,
		initialOnTopOfFixedPrice
	) {
		this._isInFixedPriceProgram = isInFixedPriceProgram;
		this._isInCappedRevenueProgram = isInCappedRevenueProgram;
		this._canManageProgram = canManageProgram;
		this._programRemainingBudget = programRemainingBudget;
		this._isExpenseApproved = isExpenseApproved;
		this._isExpenseBillable = isExpenseBillable;
		this._isCreate = isCreate;
		this._expenseTotalAmount = expenseTotalAmount;
		this._revenueSetting = revenueSetting;
		this._partOfFixedPrice = partOfFixedPrice;
		this._initialOnTopOfFixedPrice = initialOnTopOfFixedPrice;
		this.init();
	}

	applyFixedPriceProgramRules() {
		if (this._canManageProgram) {
			this._needsToValidateAgainstProgramBudget = true;
			this._showProgramBudgetNumbers = true;
		}

		if (!this._canManageProgram && this._isExpenseApproved) {
			this._error = ERROR.NON_OWNER_APPROVED_EXPENSE_CANNOT_EDIT;
			this._canChangeBillable = false;
			this._canApprove = false;
			this._canEdit = this._isCreate;
		}

		if (!this._canManageProgram && !this._isExpenseApproved) {
			this._error = ERROR.NON_OWNER_UNAPPROVED_EXPENSE_CANNOT_APPROVE;
			this._canEdit = true;
			this._canChangeBillable = true;
			this._canApprove = false;
		}
	}

	applyCappedProgramRules() {
		this._needsToValidateAgainstProgramBudget = true;
		this._showProgramBudgetNumbers = this._canManageProgram;
		this._canChangeBillable = true;
		this._canApprove = true;
		this._canEdit = true;
	}

	init() {
		this._error = undefined;
		this._canEdit = true;
		this._canChangeBillable = true;
		this._canApprove = true;
		this._needsToValidateAgainstProgramBudget = false;
		this._showProgramBudgetNumbers = false;

		if (this._isInFixedPriceProgram) {
			this.applyFixedPriceProgramRules();
		} else if (this._isInCappedRevenueProgram) {
			this.applyCappedProgramRules();
		}

		if (this.needsToValidateAgainstProgramBudget() && this._isExpenseBillable) {
			this.checkForProgramBudgetOverflow();
		}
	}

	getErrorCode() {
		return this._error;
	}

	canEdit() {
		return this._canEdit;
	}

	canApprove() {
		return this.canEdit() && this._canApprove;
	}

	canSetBillable() {
		return this.canEdit() && this._canChangeBillable;
	}

	cannotAddBillableExpenses() {
		return this._revenueSetting === CAP_SETTING.ALWAYS_DISABLED;
	}

	needsToValidateAgainstProgramBudget() {
		if (this._revenueSetting === CAP_SETTING.ALWAYS_PERMITTED) {
			return false;
		}
		return this.canEdit() && this._isExpenseBillable && this._needsToValidateAgainstProgramBudget;
	}

	showProgramBudgetInfo() {
		return this._showProgramBudgetNumbers;
	}

	checkForProgramBudgetOverflow() {
		if (!this.needsToValidateAgainstProgramBudget()) {
			return false;
		}

		// If we want to check if the new amount can fit in the program budget when reducing it,
		// remove this
		if (this.cannotAddBillableExpenses() && !this.checkIfUnitPriceIsIncreased() && this._initialOnTopOfFixedPrice) {
			return false;
		}

		// Validation against program cap is not required when expense is part of fixed price
		if (this._partOfFixedPrice) {
			return false;
		}

		const hasOverflow = !this.canAmountFitInProgramBudget(this._expenseTotalAmount);
		if (hasOverflow) {
			this._error = ERROR.EXPENSE_OVERFLOW;
		}
		return hasOverflow;
	}

	checkIfUnitPriceIsIncreased() {
		if (!this.needsToValidateAgainstProgramBudget() || !this._initialOnTopOfFixedPrice) {
			return false;
		}

		const unitPriceHasNotDecreased = this._expenseTotalAmount > 0;
		if (unitPriceHasNotDecreased && this._isExpenseApproved) {
			this._error = ERROR.LOCKED_PROGRAM_CAP;
		}
		return unitPriceHasNotDecreased;
	}

	canAmountFitInProgramBudget(amount) {
		if (!this._isExpenseBillable || !amount) {
			return true;
		}
		return canAmountFitInBudget(amount, this._programRemainingBudget);
	}
}

ExpenseProgramLogic.ERROR = ERROR;
