import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {createFragmentContainer, graphql} from 'react-relay';

class MissionControlHeader extends Component {
	constructor(props) {
		super(props);

		Sentry.configureScope(scope => {
			scope.setUser({
				email: props.viewer.email,
				id: atob(props.viewer.actualPersonId).replace('Person:', ''),
				company_id: atob(props.viewer.company.id).replace('Company:', ''),
				name: props.viewer.firstName + ' ' + props.viewer.lastName,
				company_name: props.viewer.company.name,
			});
		});
	}
	goto(url) {
		this.props.history.push(url);
	}

	getButton(url, text, index) {
		const currentUrl = window.location.pathname;
		return (
			<button key={index} className={'tab' + (url === currentUrl ? ' selected' : '')} onClick={this.goto.bind(this, url)}>
				{text}
			</button>
		);
	}

	render() {
		const urls = [
			'',
			'/company-stats',
			'/trials',
			'/companies-with-modules',
			'/person-list',
			'/features',
			'/ml-stats',
			'/restore-data',
			'/sage-signup',
		];
		const texts = [
			'Impersonate',
			'Company stats',
			'Trials',
			'Modules',
			'Person list',
			'Feature Flags',
			'ML Stats',
			'Restore Data',
			'Sage Signup',
		];
		const prefix = window.location.pathname.includes('missioncontrol') ? '/missioncontrol' : '/control';
		return (
			<div className="mission-control-header">
				{urls.map((url, index) => this.getButton(prefix + url, texts[index], index))}
			</div>
		);
	}
}

export default createFragmentContainer(withRouter(MissionControlHeader), {
	viewer: graphql`
		fragment missionControlHeader_viewer on Viewer {
			email
			actualPersonId
			firstName
			lastName
			company {
				id
				name
			}
		}
	`,
});
