import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {cloneDeep} from 'lodash';
import UpdateViewerMutation from '../../mutations/update_viewer_mutation.modern';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import UpdateProjectPersonMutation from '../../mutations/update_project_person_mutation';
import Moment from 'moment';
import Util from '../../forecast-app/shared/util/util';
import {updateProjectOnSuccess} from '../project/project_settings/ProjectSettingsMutations';

class setToRunningReminderModal extends Component {
	constructor(props) {
		super(props);

		const allProjects = cloneDeep(this.props.viewer.projects.edges).map(projectEdge => {
			return projectEdge.node;
		});
		const planningProjects = allProjects.filter(project => {
			return project.status === 'PLANNING';
		});
		const planningProjectsSetToStartToday = planningProjects.filter(project => {
			if (!project.projectStartYear) return false;
			const projectStartDate = Moment.utc({
				y: project.projectStartYear,
				M: project.projectStartMonth - 1,
				d: project.projectStartDay,
			});
			return Moment().isSame(projectStartDate, 'day');
		});
		const projectsToDisplay = planningProjectsSetToStartToday.filter(project => {
			const projectPersons = project.projectPersons.edges.map(projectPersonEdge => {
				return projectPersonEdge.node;
			});
			return (
				projectPersons.filter(projectPerson => {
					if (!projectPerson.hasDismissedSetToRunningNotification && projectPerson.person.isViewer) {
						project.viewerProjectPersonId = projectPerson.id;
						return true;
					}
					return false;
				}).length === 1
			);
		});
		//If there is nothing to display, close the modal (since this is in constructor, nothing will be shown to the viewer)
		if (!projectsToDisplay.length) {
			this.dismissModal();
		}

		this.state = {
			projectsToDisplay,
		};
	}

	dismissModal() {
		Util.CommitMutation(UpdateViewerMutation, {
			viewer: this.props.viewer,
			setToRunningNotificationLastDismissalDate: Moment().format('YYYY-MM-DD HH:mm:ss'),
		});
		this.props.closeModal();
	}

	handleManualClose(projectPersonId) {
		Util.CommitMutation(UpdateProjectPersonMutation, {
			id: projectPersonId,
			hasDismissedSetToRunningNotification: true,
		});
		if (this.state.projectsToDisplay.length > 1) {
			const {projectsToDisplay} = this.state;
			projectsToDisplay.shift();
			this.setState({projectsToDisplay});
		} else {
			this.dismissModal();
		}
	}

	handleSetToRunningClick(project) {
		Util.CommitMutation(UpdateProjectMutation, {project: project, status: 'RUNNING'}, updateProjectOnSuccess);
		if (this.state.projectsToDisplay.length > 1) {
			const {projectsToDisplay} = this.state;
			projectsToDisplay.shift();
			this.setState({projectsToDisplay});
		} else {
			this.dismissModal();
		}
	}

	render() {
		const projectToDisplay = this.state.projectsToDisplay[0];
		if (!projectToDisplay) return null; //Don't go any further if there is no project to display, the modal is closed from within the constructor so nothing will be shown anyway
		const {formatMessage, formatDate} = this.props.intl;
		const dateFormatOptions = {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		};
		return (
			<div
				className="set-to-running-reminder-modal"
				onMouseDown={this.handleManualClose.bind(this, projectToDisplay.viewerProjectPersonId)}
			>
				<div
					className="content-container"
					onMouseDown={e => {
						e.stopPropagation();
					}}
				>
					<button
						className="close-button"
						onClick={this.handleManualClose.bind(this, projectToDisplay.viewerProjectPersonId)}
					/>
					<div className="inner-container">
						<div>
							{formatMessage({id: 'running_reminder_modal.greeting'})}{' '}
							<span className="person-name">{this.props.viewer.firstName}</span>,
						</div>
						<div className="description">{formatMessage({id: 'running_reminder_modal.description'})}</div>
						<div className="project-information">
							<div className="project-name">{projectToDisplay.name}</div>
							<div>
								{projectToDisplay.projectStartDay == null ? (
									<span className={'no-date'}>{formatMessage({id: 'project_overview.no_start_date'})}</span>
								) : (
									formatDate(
										Moment({
											y: projectToDisplay.projectStartYear,
											M: projectToDisplay.projectStartMonth - 1,
											d: projectToDisplay.projectStartDay,
										}),
										dateFormatOptions
									)
								)}
								{' - '}
								{projectToDisplay.projectEndDay == null ? (
									<span className={'no-date'}>{formatMessage({id: 'project_overview.no_end_date'})}</span>
								) : (
									formatDate(
										Moment({
											y: projectToDisplay.projectEndYear,
											M: projectToDisplay.projectEndMonth - 1,
											d: projectToDisplay.projectEndDay,
										}),
										dateFormatOptions
									)
								)}
							</div>
						</div>
						<div>
							<button
								className="set-to-running-button"
								onClick={this.handleSetToRunningClick.bind(this, projectToDisplay)}
							>
								{formatMessage({id: 'running_reminder_modal.button_text'})}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const setToRunningReminderModalQuery = graphql`
	query setToRunningReminderModal_Query {
		viewer {
			actualPersonId
			component(name: "set_to_running")
			...setToRunningReminderModal_viewer
		}
	}
`;

export {setToRunningReminderModalQuery};

export default injectIntl(
	createFragmentContainer(setToRunningReminderModal, {
		viewer: graphql`
			fragment setToRunningReminderModal_viewer on Viewer {
				id
				firstName
				projects(first: 100000, excludeDoneOrHalted: true) {
					edges {
						node {
							id
							isInProjectGroup
							companyProjectId
							name
							status
							projectStartDay
							projectStartMonth
							projectStartYear
							projectEndDay
							projectEndMonth
							projectEndYear
							projectPersons(first: 10000) {
								edges {
									node {
										id
										hasDismissedSetToRunningNotification
										person {
											isViewer
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	})
);
