import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedDate, FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../constants';
import * as tracking from '../../tracking';
import HeaderBar from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import {cloneDeep} from 'lodash';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../css_variables';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasSomePermission, isClientUser} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {isDateInLockedPeriod} from '../../components/new-ui/project/project-budget-v3/util/PeriodLocks';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import DeprecatedProjectIndicator from '../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';
import CompanySetupUtil from '../../forecast-app/shared/util/CompanySetupUtil';

class myExpenses extends Component {
	constructor(props) {
		super(props);
		const approvedFilterValue = (JSON.parse(localStorage.getItem('my-expenses-filter-value-approved')) || []).filter(
			val => val !== null
		);
		const billableFilterValue = (JSON.parse(localStorage.getItem('my-expenses-filter-value-billable')) || []).filter(
			val => val !== null
		);
		const ascending = localStorage.getItem('my-expenses-sort-ascending') || 'true';
		const sortBy = {
			column: localStorage.getItem('my-expenses-sort-column') || null,
			ascending: ascending === 'true',
		};
		const expenses = cloneDeep(props.viewer.expenseItems.edges);
		this.sortExpenses(expenses, sortBy);
		const expensesOrder = expenses.map(expense => expense.node.id);
		this.state = {
			approvedFilterValue,
			billableFilterValue,
			sortBy,
			expensesOrder,
		};

		this.superPropertyChecksum = trackPage('My Expenses');
	}

	UNSAFE_componentWillMount() {
		if (isClientUser()) {
			this.props.history.push('/not-found');
		}
	}

	componentDidMount() {
		document.title = 'My Expenses - My profile - Forecast';

		// Segment
		tracking.trackPage('settings-my-expenses');

		if (window.location.hash === '#newexpense') {
			// Open new expense model on mount
			this.handleAddExpense();
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (
			nextState.sortBy.column !== this.state.sortBy.column ||
			nextState.sortBy.ascending !== this.state.sortBy.ascending
		) {
			const expenses = cloneDeep(nextProps.viewer.expenseItems.edges);
			this.sortExpenses(expenses, nextState.sortBy);
			const expensesOrder = expenses.map(expense => expense.node.id);
			this.setState({expensesOrder});
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	sortExpenses(expenses, sortBy) {
		const sortByName = (a, b) => {
			a = a.node;
			b = b.node;
			if (a.name.toLowerCase() < b.name.toLowerCase()) return sortBy.ascending ? -1 : 1;
			if (a.name.toLowerCase() > b.name.toLowerCase()) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByProject = (a, b) => {
			a = a.node;
			b = b.node;
			if (a.project.name.toLowerCase() < b.project.name.toLowerCase()) return sortBy.ascending ? -1 : 1;
			if (a.project.name.toLowerCase() > b.project.name.toLowerCase()) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByPhase = (a, b) => {
			a = a.node;
			b = b.node;
			if ((a.phase ? a.phase.name.toLowerCase() : '') < (b.phase ? b.phase.name.toLowerCase() : ''))
				return sortBy.ascending ? -1 : 1;
			if ((a.phase ? a.phase.name.toLowerCase() : '') > (b.phase ? b.phase.name.toLowerCase() : ''))
				return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByCategory = (a, b) => {
			a = a.node;
			b = b.node;
			if ((a.category ? a.category.name.toLowerCase() : '') < (b.category ? b.category.name.toLowerCase() : ''))
				return sortBy.ascending ? -1 : 1;
			if ((a.category ? a.category.name.toLowerCase() : '') > (b.category ? b.category.name.toLowerCase() : ''))
				return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByQuantity = (a, b) => {
			a = a.node;
			b = b.node;
			if (a.quantity < b.quantity) return sortBy.ascending ? -1 : 1;
			if (a.quantity > b.quantity) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByUnitCost = (a, b) => {
			a = a.node;
			b = b.node;
			if (a.cost < b.cost) return sortBy.ascending ? -1 : 1;
			if (a.cost > b.cost) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByTotalCost = (a, b) => {
			const aTotalCost = a.node.cost * a.node.quantity;
			const bTotalCost = b.node.cost * b.node.quantity;
			if (aTotalCost < bTotalCost) return sortBy.ascending ? -1 : 1;
			if (aTotalCost > bTotalCost) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByMarkup = (a, b) => {
			const aMarkup = Math.round(((a.node.price * 100.0) / a.node.cost - 100) * 100.0) / 100.0;
			const bMarkup = Math.round(((b.node.price * 100.0) / b.node.cost - 100) * 100.0) / 100.0;
			if (aMarkup < bMarkup) return sortBy.ascending ? -1 : 1;
			if (aMarkup > bMarkup) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByUnitPrice = (a, b) => {
			a = a.node;
			b = b.node;
			if (a.price < b.price) return sortBy.ascending ? -1 : 1;
			if (a.price > b.price) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByTotalPrice = (a, b) => {
			const aTotalPrice = a.node.price * a.node.quantity;
			const bTotalPrice = b.node.price * b.node.quantity;
			if (aTotalPrice < bTotalPrice) return sortBy.ascending ? -1 : 1;
			if (aTotalPrice > bTotalPrice) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByDate = (a, b) => {
			const aDate = Util.CreateNonUtcMomentDate(a.node.expenseYear, a.node.expenseMonth, a.node.expenseDay);
			const bDate = Util.CreateNonUtcMomentDate(b.node.expenseYear, b.node.expenseMonth, b.node.expenseDay);
			if (aDate < bDate) return sortBy.ascending ? -1 : 1;
			if (aDate === bDate) return 0;
			if (aDate > bDate) return sortBy.ascending ? 1 : -1;
		};
		const sortByBillable = (a, b) => {
			if (!a.node.billable && b.node.billable) return sortBy.ascending ? -1 : 1;
			if (a.node.billable && !b.node.billable) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortByApproved = (a, b) => {
			if (!a.node.approved && b.node.approved) return sortBy.ascending ? -1 : 1;
			if (a.node.approved && !b.node.approved) return sortBy.ascending ? 1 : -1;
			else return 0;
		};
		const sortMapping = new Map([
			['name', sortByName],
			['project', sortByProject],
			['phase', sortByPhase],
			['category', sortByCategory],
			['quantity', sortByQuantity],
			['unit_cost', sortByUnitCost],
			['total_cost', sortByTotalCost],
			['markup', sortByMarkup],
			['unit_price', sortByUnitPrice],
			['total_price', sortByTotalPrice],
			['date', sortByDate],
			['billable', sortByBillable],
			['approved', sortByApproved],
		]);
		expenses.sort(sortMapping.get(sortBy.column));
	}

	openExpenseItemModal(expense, locked) {
		showModal({
			type: MODAL_TYPE.EXPENSE_ITEM,
			expenseItemId: expense.node.id,
			projectId: expense.node.project.id,
			onSave: this.props.retry,
			locked: locked,
		});
	}
	getFormattedFinancialValue(value, rateCardCurrency) {
		const currencySymbol =
			rateCardCurrency && rateCardCurrency !== this.props.viewer.company.currency
				? Util.GetCurrencySymbol(rateCardCurrency)
				: Util.GetCurrencySymbol(this.props.viewer.company.currency);
		const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);
		return isDollarOrEuro ? `${currencySymbol}${value}` : `${value} ${currencySymbol}`;
	}
	handleAddExpense() {
		showModal({
			type: MODAL_TYPE.EXPENSE_ITEM,
			projectId: null,
		});
	}

	getHeaderTitle() {
		return (
			<TopHeaderBarWrapper className="top-header-bar">
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'my_profile.my_expenses'})} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader(filters) {
		const rightContent = [];
		rightContent.push({
			type: ELEMENT_TYPE.FILTER,
			filters,
		});
		if (CompanySetupUtil.hasCreateExpense()) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: this.props.intl.formatMessage({id: 'new_expense_item.title'}),
				callback: this.handleAddExpense.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
				disabled: false,
			});
		}

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={[]}
				rightContent={rightContent}
			/>
		);
	}
	handleFilterChange(filterType, value) {
		this.setState({[filterType + 'FilterValue']: value});
		Util.localStorageSetItem(
			'my-expenses-filter-value-' + filterType,
			JSON.stringify(value.map(v => ({...v, label: null})))
		);
	}
	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		Util.localStorageSetItem('my-expenses-sort-column', column);
		Util.localStorageSetItem('my-expenses-sort-ascending', ascending);
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	render() {
		const {formatNumber, formatMessage} = this.props.intl;
		const hasFinancialAccess = hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]);
		const filters = [
			{
				options: [
					{value: true, label: formatMessage({id: 'common.approved'})},
					{value: false, label: formatMessage({id: 'common.not-approved'})},
				],
				onChange: this.handleFilterChange.bind(this, 'approved'),
				value: this.state.approvedFilterValue,
				label: formatMessage({id: 'common.approved'}),
			},
			{
				options: [
					{value: true, label: formatMessage({id: 'common.billable'})},
					{value: false, label: formatMessage({id: 'common.non-billable'})},
				],
				onChange: this.handleFilterChange.bind(this, 'billable'),
				value: this.state.billableFilterValue,
				label: formatMessage({id: 'common.billable'}),
			},
		];
		const approvedFilterValues = this.state.approvedFilterValue.map(el => el.value);
		const billableFilterValues = this.state.billableFilterValue.map(el => el.value);
		let expenses = [];
		this.state.expensesOrder.forEach(expenseId => {
			const expense = this.props.viewer.expenseItems.edges.find(expenseNode => expenseNode.node.id === expenseId);
			if (expense) {
				expenses.push(expense);
			}
		});
		expenses = expenses.filter(expenseItem => {
			return (
				(approvedFilterValues.length === 0 || approvedFilterValues.includes(expenseItem.node.approved)) &&
				(billableFilterValues.length === 0 || billableFilterValues.includes(expenseItem.node.billable))
			);
		});
		this.props.viewer.expenseItems.edges.forEach(item => {
			if (!this.state.expensesOrder.includes(item.node.id)) {
				expenses.unshift(item);
			}
		});

		return (
			<div className="section-content settings-app V2" data-cy={'expenses-page'}>
				{this.props.children}
				{this.getHeaderTitle()}
				{this.getHeader(filters)}
				<CustomScrollDiv style={{marginBottom: '90px'}}>
					<div className="section-body V2">
						<div className="inner">
							<fieldset className="personal-info">
								<h3>
									<FormattedMessage id="project_budget.expenses" />
								</h3>
								<p>
									<FormattedMessage id="my_profile.my_expenses_info" />
								</p>
								{expenses.length ? (
									<table>
										<thead>
											<tr>
												<th
													className={`th-name sortable ${
														this.state.sortBy.column === 'name'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'name')}
												>
													{formatMessage({id: 'common.name'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'date'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'date')}
												>
													{formatMessage({id: 'common.date'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'project'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'project')}
												>
													{formatMessage({id: 'common.project'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'phase'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'phase')}
												>
													{formatMessage({id: 'common.phase'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'category'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'category')}
												>
													{formatMessage({id: 'insights.category'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'quantity'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'quantity')}
												>
													{formatMessage({id: 'common.quantity'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'unit_cost'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'unit_cost')}
												>
													{formatMessage({id: 'expense_item_modal.unit_cost'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												<th
													className={`sortable ${
														this.state.sortBy.column === 'total_cost'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'total_cost')}
												>
													{formatMessage({id: 'expense_item_modal.total_cost'})}
													<span>&nbsp;&nbsp;</span>
												</th>
												{hasFinancialAccess ? (
													<th
														className={`sortable ${
															this.state.sortBy.column === 'markup'
																? this.state.sortBy.ascending
																	? 'ascending'
																	: 'descending'
																: ''
														}`}
														onClick={this.setSortBy.bind(this, 'markup')}
													>
														{formatMessage({id: 'common.markup'})}
														<span>&nbsp;&nbsp;</span>
													</th>
												) : null}
												{hasFinancialAccess ? (
													<th
														className={`sortable ${
															this.state.sortBy.column === 'unit_price'
																? this.state.sortBy.ascending
																	? 'ascending'
																	: 'descending'
																: ''
														}`}
														onClick={this.setSortBy.bind(this, 'unit_price')}
													>
														{formatMessage({id: 'expense_item_modal.unit_price'})}
														<span>&nbsp;&nbsp;</span>
													</th>
												) : null}
												{hasFinancialAccess ? (
													<th
														className={`sortable ${
															this.state.sortBy.column === 'total_price'
																? this.state.sortBy.ascending
																	? 'ascending'
																	: 'descending'
																: ''
														}`}
														onClick={this.setSortBy.bind(this, 'total_price')}
													>
														{formatMessage({id: 'expense_item_modal.total_price'})}
														<span>&nbsp;&nbsp;</span>
													</th>
												) : null}
												{hasFinancialAccess ? (
													<th
														className={`cell-center-aligned sortable ${
															this.state.sortBy.column === 'billable'
																? this.state.sortBy.ascending
																	? 'ascending'
																	: 'descending'
																: ''
														}`}
														onClick={this.setSortBy.bind(this, 'billable')}
													>
														{formatMessage({id: 'common.billable'})}
														<span>&nbsp;&nbsp;</span>
													</th>
												) : null}
												<th
													className={`cell-center-aligned sortable ${
														this.state.sortBy.column === 'approved'
															? this.state.sortBy.ascending
																? 'ascending'
																: 'descending'
															: ''
													}`}
													onClick={this.setSortBy.bind(this, 'approved')}
												>
													{formatMessage({id: 'common.approved'})}
													<span>&nbsp;&nbsp;</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{expenses.map((expense, index) => {
												const lockedPeriods = expense.node.project.fixedPriceLocks.edges
													.map(period => period.node)
													.filter(period => period.locked);
												const expenseDate = Util.CreateMomentDate(
													expense.node.expenseYear,
													expense.node.expenseMonth,
													expense.node.expenseDay
												);
												const isExpenseInLockedPeriod = isDateInLockedPeriod(
													expenseDate,
													lockedPeriods
												);
												return (
													<tr
														className={'my-profile-expenses-table-row'}
														key={index}
														onClick={
															expense.node.project.fullAccessToProject
																? this.openExpenseItemModal.bind(
																		this,
																		expense,
																		(expense.node.approved && !hasFinancialAccess) ||
																			expense.node.invoiced ||
																			isExpenseInLockedPeriod
																  )
																: null
														}
													>
														<td className="lock-container">
															{!expense.node.project.fullAccessToProject ? (
																<TooltipContainer
																	className="locked-icon"
																	customMaxWidth={150}
																	infoText={this.props.intl.formatMessage({
																		id: 'expense_item.tooltip.cannot.edit',
																	})}
																	tooltipInfinteDuration={true}
																>
																	<div>{expense.node.name}</div>
																</TooltipContainer>
															) : (
																<div>{expense.node.name}</div>
															)}
															{(expense.node.approved && !hasFinancialAccess) ||
															expense.node.invoiced ||
															isExpenseInLockedPeriod ? (
																<TooltipContainer
																	className="locked-icon"
																	customMaxWidth={150}
																	infoText={this.props.intl.formatMessage({
																		id: expense.node.invoiced
																			? 'expenses.invoiced'
																			: isExpenseInLockedPeriod
																			? 'expenses.locked_period'
																			: 'expenses.approved',
																	})}
																	tooltipInfinteDuration={true}
																>
																	<LockIcon
																		size={LockIcon.SIZE.STANDARD}
																		color={CSS_CONSTANTS.v2_text_gray}
																	/>
																</TooltipContainer>
															) : null}
														</td>
														<td>
															{expense.node.expenseYear ? (
																<FormattedDate
																	value={Util.CreateNonUtcMomentDate(
																		expense.node.expenseYear,
																		expense.node.expenseMonth,
																		expense.node.expenseDay
																	)}
																/>
															) : null}
														</td>
														<td className="project-column">
															<DeprecatedProjectIndicator
																clearSubPath={true}
																project={expense.node.project}
															/>
														</td>
														<td>{expense.node.phase ? expense.node.phase.name : ''}</td>
														<td>{expense.node.category ? expense.node.category.name : ''}</td>
														<td>
															<FormattedNumber value={expense.node.quantity} />
														</td>
														<td>
															{this.getFormattedFinancialValue(
																formatNumber(expense.node.cost),
																expense.node.project && expense.node.project.rateCard
																	? expense.node.project.rateCard.currency
																	: null
															)}
														</td>
														<td>
															{this.getFormattedFinancialValue(
																formatNumber(
																	Math.round(
																		expense.node.cost * expense.node.quantity * 100.0
																	) / 100.0
																),
																expense.node.project && expense.node.project.rateCard
																	? expense.node.project.rateCard.currency
																	: null
															)}
														</td>
														{hasFinancialAccess ? (
															<td>
																{expense.node.price && expense.node.cost !== 0
																	? formatNumber(
																			Math.round(
																				((expense.node.price * 100.0) /
																					expense.node.cost -
																					100) *
																					100.0
																			) / 100.0
																	  ) + '%'
																	: null}
															</td>
														) : null}
														{hasFinancialAccess ? (
															<td>
																{expense.node.price
																	? this.getFormattedFinancialValue(
																			formatNumber(expense.node.price),
																			expense.node.project &&
																				expense.node.project.rateCard
																				? expense.node.project.rateCard.currency
																				: null
																	  )
																	: null}
															</td>
														) : null}
														{hasFinancialAccess ? (
															<td>
																{expense.node.price
																	? this.getFormattedFinancialValue(
																			formatNumber(
																				Math.round(
																					expense.node.price *
																						expense.node.quantity *
																						100.0
																				) / 100.0
																			),
																			expense.node.project &&
																				expense.node.project.rateCard
																				? expense.node.project.rateCard.currency
																				: null
																	  )
																	: null}
															</td>
														) : null}
														{hasFinancialAccess ? (
															<td className="cell-center-aligned">
																<input
																	type="checkbox"
																	checked={expense.node.billable}
																	disabled={true}
																/>
															</td>
														) : null}
														<td className="cell-center-aligned">
															<input
																type="checkbox"
																checked={expense.node.approved}
																disabled={true}
															/>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								) : (
									<div className="expenses-empty-state-wrapper">
										<FormattedMessage id="my_expenses_empty_state-info" />
									</div>
								)}
							</fieldset>
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

const myExpensesQuery = graphql`
	query myExpenses_Query {
		viewer {
			actualPersonId
			component(name: "my_expenses")
			...myExpenses_viewer
		}
	}
`;

export {myExpensesQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(myExpenses, {
			viewer: graphql`
				fragment myExpenses_viewer on Viewer {
					id
					firstName
					lastName
					availableFeatureFlags {
						key
					}
					company {
						currency
					}
					expenseItems(first: 100000) @connection(key: "Viewer_expenseItems") {
						edges {
							node {
								id
								name
								cost
								price
								quantity
								approved
								billable
								invoiced
								expenseYear
								expenseMonth
								expenseDay
								project {
									id
									name
									projectColor
									companyProjectId
									rateCard {
										id
										currency
									}
									fullAccessToProject
									fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
										edges {
											node {
												id
												startDate
												endDate
												locked
											}
										}
									}
									...DeprecatedProjectIndicatorJS_project
								}
								category {
									id
									name
								}
								phase {
									id
									name
								}
							}
						}
					}
				}
			`,
		})
	)
);
