/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type phaseLocationModal_viewer$ref = any;
export type phaseLocationModal_QueryVariables = {||};
export type phaseLocationModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: phaseLocationModal_viewer$ref,
  |}
|};
export type phaseLocationModal_Query = {|
  variables: phaseLocationModal_QueryVariables,
  response: phaseLocationModal_QueryResponse,
|};
*/


/*
query phaseLocationModal_Query {
  viewer {
    actualPersonId
    component(name: "phase_location_modal")
    ...phaseLocationModal_viewer
    id
  }
}

fragment DeprecatedProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}

fragment ProjectDropdown_projects on ProjectTypeEdge {
  node {
    id
    name
    projectColor
    companyProjectId
    customProjectId
    status
    ...DeprecatedProjectIndicatorJS_project
    manualProgressOnProjectEnabled
    manualProgressOnPhasesEnabled
    manualProgressOnTasksEnabled
  }
}

fragment phaseLocationModal_viewer on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    modules {
      moduleType
      id
    }
  }
  projects(first: 100000, excludeDoneOrHalted: true) {
    edges {
      ...ProjectDropdown_projects
      node {
        id
        projectColor
        projectGroupId
        name
        companyProjectId
        status
        isJiraProject
        jiraCloudEpicIds
        harvestProject {
          id
        }
        statusColumns {
          id
          projectGroupStatusColumnId
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "phase_location_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"phase_location_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "phaseLocationModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "phaseLocationModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "phaseLocationModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "excludeDoneOrHalted",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 100000
              }
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualProgressOnProjectEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualProgressOnPhasesEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manualProgressOnTasksEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectGroupId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isJiraProject",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jiraCloudEpicIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HarvestProjectType",
                        "kind": "LinkedField",
                        "name": "harvestProject",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusColumn",
                        "kind": "LinkedField",
                        "name": "statusColumns",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectGroupStatusColumnId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(excludeDoneOrHalted:true,first:100000)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "phaseLocationModal_Query",
    "operationKind": "query",
    "text": "query phaseLocationModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"phase_location_modal\")\n    ...phaseLocationModal_viewer\n    id\n  }\n}\n\nfragment DeprecatedProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n\nfragment ProjectDropdown_projects on ProjectTypeEdge {\n  node {\n    id\n    name\n    projectColor\n    companyProjectId\n    customProjectId\n    status\n    ...DeprecatedProjectIndicatorJS_project\n    manualProgressOnProjectEnabled\n    manualProgressOnPhasesEnabled\n    manualProgressOnTasksEnabled\n  }\n}\n\nfragment phaseLocationModal_viewer on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    modules {\n      moduleType\n      id\n    }\n  }\n  projects(first: 100000, excludeDoneOrHalted: true) {\n    edges {\n      ...ProjectDropdown_projects\n      node {\n        id\n        projectColor\n        projectGroupId\n        name\n        companyProjectId\n        status\n        isJiraProject\n        jiraCloudEpicIds\n        harvestProject {\n          id\n        }\n        statusColumns {\n          id\n          projectGroupStatusColumnId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a0c544131678950d774b38ae3ebe954';

module.exports = node;
