import React, {useState} from 'react';
import {CaretIcon} from 'web-components';
import moment from 'moment';
import styled from 'styled-components';

import {BUDGET_TYPE, MODULE_TYPES, PROJECT_STATUS, WorkflowCategories} from '../../../constants';
import {InputLineInput, InputLineLabel, InputLineWrapper, ProjectStatusWrapper, SubSection} from './ProjectSettings_styled';
import {changeStage, changeTasksColumn, changeWinChance} from './ProjectSettingsMutations';
import {PercentageInputWrapper} from '../../../components/inputs/percentage_input_wrapper';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {TASKS_OPTIONS} from '../../modal/CloseProjectModal';
import {StatusTextClickableTile} from '../project_health/project_health_styles/ProjectHealthSidebar.styled';
import ProjectStatus from '../project_health/project_health_status/ProjectStatus';
import {CSS_CONSTANTS} from '../../../css_variables';
import ProjectPriorityDropdown from './ProjectPriorityDropdown';
import {FormattedHTMLMessage} from 'react-intl';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {ButtonDropdownField, FlexRow, DropdownList, Text} from '@forecast-it/design-system';
import {DesignSystemDropdownOldLookAdapter} from '../../../forecast-app/shared/components/dropdowns/DesignSystemDropdownOldLookAdapter';

const StatusHistoryTile = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 34px;
`;

const TitleCaretWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`;

const StatusHistoryTitle = styled.div`
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 8px;
`;

const showUpdateStatusModal = (projectId, projectDoneOrHalted) => {
	if (!projectDoneOrHalted) {
		showModal({
			type: MODAL_TYPE.PROJECT_STATUS_V2,
			projectId: projectId,
		});
	}
};

const ProjectSettingsStatus = ({
	intl,
	projectId,
	project,
	company,
	companyProjectId,
	projectStage,
	stages,
	hasOpportunityAccess,
	baselineWinChance,
	projectTasks,
	projectColumns,
	currentProjectStatus,
	projectStatuses,
	currentProjectStatusColor,
	currentProjectStatusDate,
	currentProjectStatusPersonImage,
	currentProjectStatusPersonFullName,
	currentProjectStatusDescription,
	viewerId,
	projectDoneOrHalted,
	budgetType,
}) => {
	const [statusHistoryExpanded, setStatusHistoryExpanded] = useState(
		localStorage.getItem('settings_status_section_expanded') !== null
			? JSON.parse(localStorage.getItem('settings_status_section_expanded'))
			: true
	);

	const {formatMessage} = intl;

	const handleUnfinishedWork = (option, statusColumnId, notDoneTasks) => {
		switch (option) {
			case TASKS_OPTIONS.DONE:
				const notDoneIds = notDoneTasks.map(task => task.node.id);
				changeTasksColumn(notDoneIds, statusColumnId, 'DONE');
				changeStage(projectId, PROJECT_STATUS.DONE);
				break;
			case TASKS_OPTIONS.STAY:
				changeStage(projectId, PROJECT_STATUS.DONE);
				break;
			default:
				break;
		}
	};

	const handleChangeStage = stage => {
		if (stage) {
			if (stage === PROJECT_STATUS.DONE) {
				const notDoneTasks = projectTasks.filter(task => task.node.statusColumnV2.category !== PROJECT_STATUS.DONE);
				if (notDoneTasks.length > 0) {
					const doneColumns = projectColumns
						.filter(col => col.node.category === WorkflowCategories.DONE)
						.sort((a, b) => a.node.order - b.node.order)
						.map(col => ({
							value: col.node.id,
							label: col.node.name,
							logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
						}));
					showModal({
						type: MODAL_TYPE.CLOSE_PROJECT,
						tasksList: notDoneTasks,
						doneColumns: doneColumns,
						callback: (option, statucColumnId) => handleUnfinishedWork(option, statucColumnId, notDoneTasks),
					});
				} else {
					changeStage(projectId, stage);
				}
			} else {
				changeStage(projectId, stage);
			}
		}
	};
	const toggleStatusHistoryExpanded = () => {
		const newStatusState = !statusHistoryExpanded;
		localStorage.setItem('settings_status_section_expanded', newStatusState);
		setStatusHistoryExpanded(newStatusState);
	};

	const oldProjectStatuses = projectStatuses.edges.filter(
		projectStatus => projectStatus.node.id !== currentProjectStatus?.id
	);

	return (
		<>
			<SubSection title={formatMessage({id: 'settings.project-stage'})}>
				<InputLineWrapper>
					<InputLineLabel>{formatMessage({id: 'settings.project-stage'})}</InputLineLabel>
					<InputLineInput>
						<DesignSystemDropdownOldLookAdapter>
							<DropdownList>
								<DropdownList.Trigger data-cy={'settings-project-stage'}>
									<ButtonDropdownField>
										{!!projectStage ? (
											<Text>{stages.find(stage => stage.value === projectStage)?.label}</Text>
										) : (
											<Text color={Text.Color.auxiliary}>
												{formatMessage({id: 'settings.project-stage'})}
											</Text>
										)}
									</ButtonDropdownField>
								</DropdownList.Trigger>
								<DropdownList.Content align={'start'}>
									{stages.map(stage => (
										<DropdownList.Item
											key={stage.value}
											data-cy={stage.cy}
											onClick={() => handleChangeStage(stage.value)}
										>
											<FlexRow>
												<Text>{stage.label}</Text>
											</FlexRow>
										</DropdownList.Item>
									))}
								</DropdownList.Content>
							</DropdownList>
						</DesignSystemDropdownOldLookAdapter>
					</InputLineInput>
				</InputLineWrapper>
				{hasOpportunityAccess && budgetType !== BUDGET_TYPE.NON_BILLABLE && (
					<>
						<InputLineWrapper>
							<InputLineLabel>{formatMessage({id: 'project_settings.win_chance'})}</InputLineLabel>
							<InputLineInput>
								<PercentageInputWrapper
									width={'70px'}
									disabled={projectStage !== PROJECT_STATUS.OPPORTUNITY}
									value={baselineWinChance * 100}
									callback={win => changeWinChance(projectId, win)}
									zeroToHundred={true}
								/>
							</InputLineInput>
						</InputLineWrapper>
						{projectStage === PROJECT_STATUS.OPPORTUNITY ? (
							<InputLineWrapper>
								<FormattedHTMLMessage
									id={
										hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)
											? 'project_settings.win_chance_description_calc_win'
											: 'project_settings.win_chance_description'
									}
								/>
							</InputLineWrapper>
						) : null}
					</>
				)}
			</SubSection>
			{
				<SubSection title={formatMessage({id: 'project_settings.project_priority'})}>
					<InputLineWrapper>
						<InputLineLabel>{formatMessage({id: 'project_settings.priority_level'})}</InputLineLabel>
						<InputLineInput>
							<ProjectPriorityDropdown project={project} company={company} />
						</InputLineInput>
					</InputLineWrapper>
				</SubSection>
			}
			<SubSection title={formatMessage({id: 'settings.project-status'})}>
				<ProjectStatusWrapper>
					<>
						<StatusTextClickableTile
							onClick={() => showUpdateStatusModal(projectId, projectDoneOrHalted)}
							disabled={projectDoneOrHalted}
						>
							{projectDoneOrHalted
								? `You can only update the status of the project when it is active`
								: 'What are your thoughts on the status of the project?'}
						</StatusTextClickableTile>
						{currentProjectStatus && <ProjectStatus projectStatus={currentProjectStatus} />}
						{oldProjectStatuses && oldProjectStatuses.length > 0 ? (
							<StatusHistoryTile>
								<TitleCaretWrapper onClick={() => toggleStatusHistoryExpanded()}>
									<StatusHistoryTitle>Status History</StatusHistoryTitle>
									<CaretIcon
										direction={statusHistoryExpanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									/>
								</TitleCaretWrapper>
								{statusHistoryExpanded &&
									oldProjectStatuses
										.sort((a, b) => {
											const aCreatedDate = moment.utc({
												y: a.node.year,
												M: a.node.month - 1,
												d: a.node.day,
												h: a.node.hour,
												m: a.node.minute,
												s: a.node.second,
											});
											const bCreatedDate = moment.utc({
												y: b.node.year,
												M: b.node.month - 1,
												d: b.node.day,
												h: b.node.hour,
												m: b.node.minute,
												s: b.node.second,
											});
											return aCreatedDate.isBefore(bCreatedDate) ? 1 : -1;
										})
										.map(projectStatus => (
											<ProjectStatus key={projectStatus.node.id} projectStatus={projectStatus.node} />
										))}
							</StatusHistoryTile>
						) : null}
					</>
				</ProjectStatusWrapper>
			</SubSection>
		</>
	);
};

export default ProjectSettingsStatus;
