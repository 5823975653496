import React from 'react';
import {ForecastTooltipFormulaRenderer} from '../../../components/ForecastTooltipFormulaRenderer';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {getSuggestedStatusVariantFromRag, getWarningVariantFromRAGStatus} from './ProjectHealthLogic';
import {PROJECT_STATUS} from '../../../constants';

const getPerformanceModifiedEstimate = (estimate, performance, intl) => {
	const modifiedEstimate = performance > 0 ? estimate / performance : 0;
	const roundedModifiedEstimate = Math.round((modifiedEstimate + 7) / 15) * 15;
	return Util.convertMinutesToFullHour(roundedModifiedEstimate, intl);
};

const getFocusModifiedEstimate = (estimate, focus, intl) => {
	const modifiedEstimate = focus > 0 ? estimate * focus : 0;
	const roundedModifiedEstimate = Math.round((modifiedEstimate + 7) / 15) * 15;
	return Util.convertMinutesToFullHour(roundedModifiedEstimate, intl);
};

export const showBudgetModal = (projectId, budget, forecastRevenue, budgetInsightInfo, budgetInsightRAGStatus) => {
	showModal({
		type: MODAL_TYPE.FORECAST_SURPLUS,
		projectId: projectId,
		fixedPrice: budget,
		forecastRevenue: forecastRevenue,
		forecastSurplusInfo: budgetInsightInfo,
		forecastSurplusVariant: getWarningVariantFromRAGStatus(budgetInsightRAGStatus),
	});
};

export const showPredictedEndDateModal = project => {
	const projectDone = project.status === PROJECT_STATUS.DONE;
	showModal({
		type: MODAL_TYPE.PREDICTED_END_DATE,
		project: project,
		endDateInfo: projectDone ? 'Project is Done' : project.projectHealth.predictedEndDateInfo,
	});
};

export const showUpdateStatusModal = (
	projectEditingDisabled,
	projectId,
	suggestedStatusRag,
	activeInsightsInfoText,
	triggeredByStatusDigest
) => {
	if (!projectEditingDisabled) {
		const suggestedStatus = getSuggestedStatusVariantFromRag(suggestedStatusRag);
		showModal({
			type: MODAL_TYPE.PROJECT_STATUS_V2,
			projectId: projectId,
			suggestedStatus: suggestedStatus,
			activeInsightsInfoText: suggestedStatus ? activeInsightsInfoText : undefined,
			triggeredByStatusDigest: triggeredByStatusDigest,
		});
	}
};

export const getPerformanceRollingTooltipContent = (performanceRolling, intl) => {
	const performanceRollingFormatted = intl.formatNumber(performanceRolling * 100) + '%';
	return (
		<ForecastTooltipFormulaRenderer
			items={
				performanceRolling === 0
					? [
							{
								description: `The prediction assumes that project actuals will match project estimates since project actuals are not available yet.`,
							},
							{
								description:
									'To improve the quality of the prediction we recommend indicating progress, for instance by setting tasks to done upon completion.',
							},
					  ]
					: [
							{
								description: `Based on the last 30 days, task performance is ${performanceRollingFormatted}.`,
							},
							{
								description: `As an example, a task estimated at 10h was taking around ${getPerformanceModifiedEstimate(
									600,
									performanceRolling,
									intl
								)} to complete.`,
							},
					  ]
			}
			translatedMessage={true}
		/>
	);
};

export const getPerformanceTotalTooltipContent = (performanceTotal, intl) => {
	const performanceTotalFormatted = intl.formatNumber(performanceTotal * 100) + '%';
	return (
		<ForecastTooltipFormulaRenderer
			items={[
				{
					description: `The average task performance on this project is ${performanceTotalFormatted}.`,
				},
				{
					description: `As an example, a task estimated at 10h was taking around ${getPerformanceModifiedEstimate(
						600,
						performanceTotal,
						intl
					)} to complete.`,
				},
			]}
			translatedMessage={true}
		/>
	);
};

export const getFocusRollingTooltipContent = (focusRolling, intl) => {
	const focusRollingFormatted = intl.formatNumber(focusRolling * 100) + '%';
	return (
		<ForecastTooltipFormulaRenderer
			items={
				focusRolling === 0
					? [
							{
								description: `The prediction assumes that project actuals will match project allocations since project actuals are not available yet.`,
							},
							{
								description:
									'To improve the quality of the prediction we recommend indicating progress, for instance by encouraging your team to register time on the project.',
							},
					  ]
					: [
							{
								description: `Based on the last 30 days, your team focus is ${focusRollingFormatted}.`,
							},
							{
								description: `As an example, if the team was allocated 100h to the project, they registered around ${getFocusModifiedEstimate(
									6000,
									focusRolling,
									intl
								)}.`,
							},
					  ]
			}
			translatedMessage={true}
		/>
	);
};
