import React from 'react';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import PredictedEndDateModal, {PredictedEndDateModalQuery} from './PredictedEndDateModal';

const PredictedEndDateModalRelayWrapper = props => {
	return (
		<ForecastQueryRenderer
			key="query-render-predicted-end-date-modal"
			query={PredictedEndDateModalQuery}
			modal={true}
			variables={{projectId: props.project.id}}
			render={relayProps => {
				return <PredictedEndDateModal {...relayProps} {...props} />;
			}}
		/>
	);
};

export default PredictedEndDateModalRelayWrapper;
