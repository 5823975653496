import {cloneDeep} from 'lodash';
import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {CaretIcon, CustomScrollDiv} from 'web-components';
import moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import * as tracking from '../../../tracking';
import ProjectStatus from './project_health_status/ProjectStatus';
import {
	ProjectHealthSidebarTitle,
	TitleCaretWrapper,
	ProjectHealthSidebarTile,
	ProjectHealthSidebarWrapper,
	StatusTextClickableTile,
	ProjectHealthSidebarWrapperInner,
} from './project_health_styles/ProjectHealthSidebar.styled';
import ProjectStageDropdownRelayWrapper from './project_stage_dropdown/ProjectStageDropdownRelayWrapper';
import {showUpdateStatusModal} from './ProjectHealthUtil';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const ProjectHealthStatusSection = ({project, activeInsightsInfoText}) => {
	const [statusHistoryExpanded, setStatusHistoryExpanded] = useState(
		localStorage.getItem('nova_status_section_expanded') !== null
			? JSON.parse(localStorage.getItem('nova_status_section_expanded'))
			: true
	);

	const {currentProjectStatus, projectStatuses} = project;
	const oldProjectStatuses = projectStatuses.edges.filter(projectStatus => projectStatus.node.id !== currentProjectStatus.id);

	const projectEditingDisabled = Util.projectEditingDisabled(project);

	const toggleStatusHistoryExpanded = () => {
		const newStatusState = !statusHistoryExpanded;
		tracking.trackPageAction('Status history toggled', {expanded: newStatusState});
		trackEvent('Status History', 'Toggled', {expanded: newStatusState});
		localStorage.setItem('nova_status_section_expanded', newStatusState);
		setStatusHistoryExpanded(newStatusState);
	};

	return (
		<ProjectHealthSidebarWrapper>
			<CustomScrollDiv autoHeight autoHeightMin={1} autoHeightMax={'calc(100vh - 133px)'}>
				<ProjectHealthSidebarWrapperInner>
					<ProjectHealthSidebarTile>
						<ProjectHealthSidebarTitle>Project Stage</ProjectHealthSidebarTitle>
						<ProjectStageDropdownRelayWrapper projectId={project.id} />
					</ProjectHealthSidebarTile>

					<ProjectHealthSidebarTile>
						<ProjectHealthSidebarTitle>Status</ProjectHealthSidebarTitle>
						<StatusTextClickableTile
							onClick={() =>
								showUpdateStatusModal(
									projectEditingDisabled,
									project.id,
									project.projectHealth.suggestedStatusRag,
									activeInsightsInfoText
								)
							}
							disabled={projectEditingDisabled}
						>
							{projectEditingDisabled
								? `You can only update the status of the project when it is active`
								: 'What are your thoughts on the status of the project?'}
						</StatusTextClickableTile>
						{currentProjectStatus && <ProjectStatus projectStatus={currentProjectStatus} />}
					</ProjectHealthSidebarTile>

					{oldProjectStatuses && oldProjectStatuses.length > 0 ? (
						<ProjectHealthSidebarTile>
							<TitleCaretWrapper onClick={() => toggleStatusHistoryExpanded()}>
								<ProjectHealthSidebarTitle>Status History</ProjectHealthSidebarTitle>
								<CaretIcon
									direction={statusHistoryExpanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
								/>
							</TitleCaretWrapper>
							{statusHistoryExpanded &&
								cloneDeep(oldProjectStatuses)
									.sort((a, b) => {
										const aCreatedDate = moment.utc({
											y: a.node.year,
											M: a.node.month - 1,
											d: a.node.day,
											h: a.node.hour,
											m: a.node.minute,
											s: a.node.second,
										});
										const bCreatedDate = moment.utc({
											y: b.node.year,
											M: b.node.month - 1,
											d: b.node.day,
											h: b.node.hour,
											m: b.node.minute,
											s: b.node.second,
										});
										return aCreatedDate.isBefore(bCreatedDate) ? 1 : -1;
									})
									.map(projectStatus => (
										<ProjectStatus key={projectStatus.node.id} projectStatus={projectStatus.node} />
									))}
						</ProjectHealthSidebarTile>
					) : null}
				</ProjectHealthSidebarWrapperInner>
			</CustomScrollDiv>
		</ProjectHealthSidebarWrapper>
	);
};

export default createFragmentContainer(ProjectHealthStatusSection, {
	project: graphql`
		fragment ProjectHealthStatusSection_project on ProjectType {
			id
			status
			projectHealth {
				suggestedStatusRag
			}
			currentProjectStatus {
				id
				...ProjectStatus_projectStatus
			}
			projectStatuses(first: 10000) @connection(key: "Project_projectStatuses") {
				edges {
					node {
						id
						year
						month
						day
						hour
						minute
						second
						...ProjectStatus_projectStatus
					}
				}
			}
		}
	`,
});
