import React, {useState, useMemo, useEffect} from 'react';
import {useIntl, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import RateCardVersionList from './RateCardVersionList';
import RateCardSettings from './RateCardSettings';
import RateCardRoleTable from './RateCardRoleTable';
import {RateCardModalContainer, VersionSection, RateCardSection, RateCardModalHeadline} from './RateCard.styled';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {RateCardUtil} from '../../../forecast-app/shared/util/RateCardUtil';
import {CustomScrollDiv} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import CreateRateCardMutation from '../../../mutations/create_rate_card_mutation';
import UpdateRateCardMutation from '../../../mutations/update_rate_card_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {FlexRow, FlexColumn, DeprecatedText as Text, Icon} from '@forecast-it/design-system';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const RateCardModal = ({closeModal, viewer, rateCard}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const rateCardUtil = useMemo(() => new RateCardUtil(rateCard, viewer.company.roles), []);
	if (!rateCardUtil.rateCardVersions.length) {
		rateCardUtil.createRateCardVersion(null, true);
	}

	const projectsByRoleId = new Map();
	if (rateCard) {
		viewer.projects.edges.forEach(project =>
			project.node.projectPersons.edges.forEach(projectPerson => {
				if (projectPerson.node.role) {
					const roleId = projectPerson.node.role.id;
					const roleProjects = projectsByRoleId.get(roleId) || new Set();
					roleProjects.add(project.node.name);
					projectsByRoleId.set(roleId, roleProjects);
				}
			})
		);
	}
	const currentDisabledRoles = rateCard && rateCard.disabledRoles?.length ? rateCard.disabledRoles.map(role => role.id) : [];

	const [rateCardVersions, setRateCardVersions] = useState(rateCardUtil.rateCardVersions);
	const [currency, setCurrency] = useState(rateCard ? rateCard.currency : viewer.company.currency);
	const [name, setName] = useState(rateCard ? rateCard.name : '');
	const [activeVersionIndex, setActiveVersionIndex] = useState(0);
	const [nameError, setNameError] = useState(null);
	const [hasError, setHasError] = useState(null);
	const [rateCardChanged, setRateCardChanged] = useState(!rateCard);
	const [disabledRoles, setDisabledRoles] = useState(currentDisabledRoles);
	const [disabledRatesExpanded, setDisabledRatesExpanded] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		if (nameError) {
			setHasError(true);
		} else {
			setHasError(false);
		}
	}, [nameError]);

	const handleRateCardVersionsChange = rateCardVersions => {
		setRateCardVersions(rateCardVersions);
		setRateCardChanged(true);
	};

	const handleRateCardIndexChange = index => {
		setActiveVersionIndex(index);
	};
	const handleCurrencyChange = currency => {
		setCurrency(currency);
		setRateCardChanged(true);
	};
	const handleNameChange = name => {
		setName(name);
		if (name.length > 191) {
			setNameError(formatMessage({id: 'rate_card_modal.name_max_characters'}));
		} else {
			setNameError(null);
		}
		setRateCardChanged(true);
	};
	const handleDateChange = startDate => {
		rateCardUtil.rateCardVersions[activeVersionIndex].startDate = startDate.format('YYYY-MM-DD');
		handleRateCardVersionsChange([...rateCardUtil.rateCardVersions]);
	};
	const handleDefaultRateChange = rate => {
		if (isNaN(rate)) {
			rate = 0;
		}
		rateCardUtil.rateCardVersions[activeVersionIndex].defaultRate = rate;
		handleRateCardVersionsChange([...rateCardUtil.rateCardVersions]);
	};
	const handleRoleDefaultChange = (roleId, checked) => {
		const roleRate = rateCardUtil.rateCardVersions[activeVersionIndex].roleRates.find(
			roleRate => roleRate.roleId === roleId
		);
		roleRate.isUsingDefaultRate = checked;
		roleRate.rate = checked ? null : rateCardUtil.rateCardVersions[activeVersionIndex].defaultRate;
		handleRateCardVersionsChange([...rateCardUtil.rateCardVersions]);
	};
	const handleRoleRateChange = (roleId, rate) => {
		const roleRate = rateCardUtil.rateCardVersions[activeVersionIndex].roleRates.find(
			roleRate => roleRate.roleId === roleId
		);
		roleRate.rate = rate;
		handleRateCardVersionsChange([...rateCardUtil.rateCardVersions]);
	};
	const handleRoleDisabledChange = (roleId, disabled) => {
		if (disabled) {
			if (projectsByRoleId.has(roleId)) {
				showModal({
					type: MODAL_TYPE.RATE_CARD_ROLE_DEACTIVATE_WARNING,
					deactivateRole: () => setDisabledRoles([...disabledRoles, roleId]),
					projectsUsingRole: projectsByRoleId.get(roleId),
				});
			} else {
				setDisabledRoles([...disabledRoles, roleId]);
			}
		} else {
			setDisabledRoles(disabledRoles.filter(disabledRoleId => disabledRoleId !== roleId));
		}
	};
	const handleDisabledRatesExpandedChange = () => {
		setDisabledRatesExpanded(!disabledRatesExpanded);
	};

	const createRateCardVersion = rateCardVersionIndex => {
		const addedVersionIndex = rateCardUtil.createRateCardVersion(rateCardVersionIndex, false);
		handleRateCardVersionsChange([...rateCardUtil.rateCardVersions]);
		setActiveVersionIndex(addedVersionIndex);
	};
	const deleteRateCardVersion = rateCardVersionIndex => {
		rateCardUtil.deleteRateCardVersion(rateCardVersionIndex);
		handleRateCardVersionsChange([...rateCardUtil.rateCardVersions]);
		if (rateCardVersionIndex < activeVersionIndex) {
			setActiveVersionIndex(activeVersionIndex - 1);
		}
	};

	const saveRateCard = () => {
		if (!name.length) {
			setNameError(formatMessage({id: 'common.required_field'}));
		} else {
			setIsSaving(true);
			const rates = rateCardUtil.getRateCardRates(disabledRoles);
			const onSuccess = () => {
				setIsSaving(false);
				closeModal();
				createToast({
					duration: 5000,
					message: rateCard
						? formatMessage({id: 'common.changes_saved'})
						: formatMessage({id: 'rate_card_modal.create_toast'}),
				});
			};
			const activatedRoles = currentDisabledRoles.filter(
				currentDisabledRole => !disabledRoles.some(disabledRole => disabledRole === currentDisabledRole)
			);
			const deactivatedRoles = disabledRoles.filter(
				disabledRole => !currentDisabledRoles.some(currentDisabledRole => currentDisabledRole === disabledRole)
			);
			activatedRoles.forEach(role => {
				const roleProjects = projectsByRoleId.get(role);
				trackEvent('Rate Card', 'Rate Card Role Deactivated Changed', {
					deactivated: false,
					isUsedInProject: roleProjects && roleProjects.size > 0,
				});
			});
			deactivatedRoles.forEach(role => {
				const roleProjects = projectsByRoleId.get(role);
				trackEvent('Rate Card', 'Role Deactivated Changed', {
					deactivated: true,
					isUsedInProject: roleProjects && roleProjects.size > 0,
				});
			});
			if (!rateCard) {
				Util.CommitMutation(
					CreateRateCardMutation,
					{
						companyId: viewer.company.id,
						name,
						currency,
						rates,
						disabledRoles: disabledRoles.map(role => Util.getIdFromBase64String(role)),
					},
					onSuccess
				);
			} else {
				Util.CommitMutation(
					UpdateRateCardMutation,
					{
						companyId: viewer.company.id,
						id: rateCard.id,
						name,
						currency,
						rates,
						disabledRoles: disabledRoles.map(role => Util.getIdFromBase64String(role)),
					},
					onSuccess
				);
			}
		}
	};

	const content = (
		<RateCardModalContainer>
			<RateCardSection>
				<RateCardSettings
					rateCardVersions={rateCardVersions}
					activeRateCardVersion={rateCardVersions[activeVersionIndex]}
					isRateCardUsed={rateCard?.isRateCardUsed}
					currency={currency}
					name={name}
					nameError={nameError}
					handleCurrencyChange={handleCurrencyChange}
					handleNameChange={handleNameChange}
					handleDateChange={handleDateChange}
					handleDefaultRateChange={handleDefaultRateChange}
					exchangeRates={viewer.company.exchangeRates}
					companyCurrency={viewer.company.currency}
				/>
				<CustomScrollDiv autoHeight autoHeightMin={1} autoHeightMax={'calc(65vh - 240px)'}>
					<FlexColumn gap="xl">
						<RateCardRoleTable
							rateCardVersion={rateCardVersions[activeVersionIndex]}
							currency={currency}
							handleRoleDefaultChange={handleRoleDefaultChange}
							handleRoleRateChange={handleRoleRateChange}
							handleRoleDisabledChange={handleRoleDisabledChange}
							disabledSection={false}
							disabledRoles={disabledRoles}
						/>
						{hasFeatureFlag('disable_rate_card_roles') && (
							<FlexColumn gap={'s'}>
								<FlexRow onClick={() => handleDisabledRatesExpandedChange()} gap={'s'}>
									<Text type="heading" variant="m">
										{formatMessage({id: 'rate_card_modal.deactivated_roles'})}
									</Text>
									<Icon icon={disabledRatesExpanded ? 'chevronDown' : 'chevronUp'} size={'l'} />
								</FlexRow>
								{disabledRatesExpanded && (
									<RateCardRoleTable
										rateCardVersion={rateCardVersions[activeVersionIndex]}
										currency={currency}
										handleRoleDefaultChange={handleRoleDefaultChange}
										handleRoleRateChange={handleRoleRateChange}
										handleRoleDisabledChange={handleRoleDisabledChange}
										disabledSection={true}
										disabledRoles={disabledRoles}
									/>
								)}
							</FlexColumn>
						)}
					</FlexColumn>
				</CustomScrollDiv>
			</RateCardSection>
		</RateCardModalContainer>
	);

	const leftContent = (
		<VersionSection>
			<RateCardModalHeadline>
				{rateCard ? formatMessage({id: 'common.rate-card'}) : formatMessage({id: 'rate_card_modal.new_rate_card'})}
			</RateCardModalHeadline>
			<RateCardVersionList
				rateCardVersions={rateCardVersions}
				activeVersionIndex={activeVersionIndex}
				handleRateCardIndexChange={handleRateCardIndexChange}
				createRateCardVersion={createRateCardVersion}
				deleteRateCardVersion={deleteRateCardVersion}
			/>
		</VersionSection>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			leftContent={leftContent}
			modalWidth={MODAL_WIDTH.LARGE}
			buttons={[
				{
					text: rateCardChanged ? formatMessage({id: 'common.cancel'}) : formatMessage({id: 'common.close'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'cancel-button',
				},
				{
					text: rateCard
						? formatMessage({id: 'common.save_changes'})
						: formatMessage({id: 'rate_card_modal.create_rate_card'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					cy: 'save-rate-card-button',
					disabled: hasError || isSaving,
					callback: () => saveRateCard(rateCard),
					preventDefaultClose: true,
				},
			]}
		/>
	);
};

const rateCardModalQuery = graphql`
	query RateCardModalQuery($rateCardId: ID) {
		viewer {
			actualPersonId
			component(name: "rate_card_modal")
			...RateCardModal_viewer @arguments(rateCardId: $rateCardId)
		}
	}
`;

export {rateCardModalQuery};

export default injectIntl(
	createFragmentContainer(RateCardModal, {
		viewer: graphql`
			fragment RateCardModal_viewer on Viewer @argumentDefinitions(rateCardId: {type: "ID"}) {
				id
				language
				availableFeatureFlags {
					key
				}
				company {
					id
					currency
					roles(first: 1000000) {
						edges {
							node {
								id
								name
							}
						}
					}
					exchangeRates(first: 10000) {
						edges {
							node {
								id
								currency
							}
						}
					}
				}
				projects(first: 100000, rateCardId: $rateCardId) {
					edges {
						node {
							id
							name
							projectPersons(first: 1000) {
								edges {
									node {
										id
										role {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	})
);
