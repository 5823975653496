/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SageSignupControlPageRefetchQuery {
  viewer {
    ...SageSignupControlPage_viewer
    id
  }
}

fragment MissionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}

fragment SageSignupControlPage_viewer on Viewer {
  id
  ...MissionControlHeader_viewer
  company {
    id
    unlinkedSageCustomers {
      id
      name
    }
  }
  missionControlData {
    sagePSACompanies {
      id
      companyId
      name
      activated
      workatoCustomerId
      createdAt
    }
    companiesWithSageEnabled {
      id
      companyId
      name
      activated
      workatoCustomerId
      createdAt
    }
    id
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyId",
            "storageKey": null
        },
        (v1 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activated",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workatoCustomerId",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SageSignupControlPageRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SageSignupControlPage_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SageSignupControlPageRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "actualPersonId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UnlinkedSageCustomer",
                                    "kind": "LinkedField",
                                    "name": "unlinkedSageCustomers",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MissionControlDataType",
                            "kind": "LinkedField",
                            "name": "missionControlData",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MissionControlSagePSACompanyType",
                                    "kind": "LinkedField",
                                    "name": "sagePSACompanies",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MissionControlSagePSACompanyType",
                                    "kind": "LinkedField",
                                    "name": "companiesWithSageEnabled",
                                    "plural": true,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SageSignupControlPageRefetchQuery",
            "operationKind": "query",
            "text": "query SageSignupControlPageRefetchQuery {\n  viewer {\n    ...SageSignupControlPage_viewer\n    id\n  }\n}\n\nfragment MissionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n\nfragment SageSignupControlPage_viewer on Viewer {\n  id\n  ...MissionControlHeader_viewer\n  company {\n    id\n    unlinkedSageCustomers {\n      id\n      name\n    }\n  }\n  missionControlData {\n    sagePSACompanies {\n      id\n      companyId\n      name\n      activated\n      workatoCustomerId\n      createdAt\n    }\n    companiesWithSageEnabled {\n      id\n      companyId\n      name\n      activated\n      workatoCustomerId\n      createdAt\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '1a10c844a1473181b0c36f7ce74c62c4';
export default node;
