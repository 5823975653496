import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import InputFieldV2 from '../../components/new-ui/input_field';
import DirectApi from '../../directApi';

const Subtitle = styled.div`
	font-size: 11px;
	max-width: 400px;
	margin-bottom: 12px;
`;

class CreateJiraMilestoneModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchingProjects: false,
			dataLoaded: false,
			selectedCustomFieldValues: null,
			epicMetaDate: [],
			loading: false,
			requiredJiraCustomFields: [],
			jiraProjectId: null,
			jiraProjects: [],
		};
	}

	componentDidMount() {
		if (this.props.viewer.project.jiraCloudProject?.id) {
			this.setState({jiraProjectId: {value: this.props.viewer.project.jiraCloudProject?.id}});
		} else {
			this.fetchJiraProjects();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.jiraProjectId?.value !== this.state.jiraProjectId?.value) {
			this.fetchCustomFiels();
		}
	}

	onConfirm() {
		this.props.defaultCallback(this.state.selectedCustomFieldValues, this.state.jiraProjectId);
		this.props.closeModal();
	}

	fetchJiraProjects() {
		this.setState({fetchingProjects: true});

		DirectApi.Fetch(`jira_project_for_milestone/cloud`).then(json => {
			this.setState({jiraProjects: json, fetchingProjects: false});
		});
	}

	fetchCustomFiels() {
		this.setState({loading: true});
		if (this.state.jiraProjectId) {
			DirectApi.Fetch(`jira_issue_types/epic/cloud/${this.state.jiraProjectId.value}`).then(json => {
				let epicMetaDate = json || [];

				this.setState({requiredJiraCustomFields: epicMetaDate, dataLoaded: true, loading: false});
			});
		}
	}

	handleCustomFieldArrayChange(key, selected) {
		if (!selected) {
			return;
		}

		let newValue =
			this.state.selectedCustomFieldValues && this.state.selectedCustomFieldValues[key]
				? this.state.selectedCustomFieldValues[key]
				: [];
		let selectedValues =
			this.state.selectedCustomFieldValues && this.state.selectedCustomFieldValues[key]
				? this.state.selectedCustomFieldValues[key].map(el => el.value)
				: [];
		if (selectedValues.includes(selected.value)) {
			newValue = newValue.filter(el => el.value !== selected.value);
		} else {
			newValue.push(selected);
		}
		if (newValue.length === 0) {
			newValue = null;
		}
		this.setState({selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: newValue})});
	}

	handleCustomFieldChange(key, value) {
		this.setState({
			selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: value.value}),
		});
	}

	handleCustomFieldRadioButtonChange(key, id, value) {
		this.setState({selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: id})});
	}

	handleCustomFieldInputChange(key, value) {
		this.setState({selectedCustomFieldValues: Object.assign({}, this.state.selectedCustomFieldValues, {[key]: value})});
	}

	handleProjectChange(value) {
		this.setState({jiraProjectId: value});
	}

	render() {
		const {formatMessage} = this.props.intl;

		const projectOptions = this.state.fetchingProjects
			? [{label: formatMessage({id: 'common.loading'}), value: null, disabled: true}]
			: this.state.jiraProjects.map(project => ({label: `${project.name}`, value: project.value}));

		const issueTypeWithFields = this.state.requiredJiraCustomFields ? this.state.requiredJiraCustomFields[0] : null;
		const fieldTypes = issueTypeWithFields
			? Object.keys(issueTypeWithFields.fields).map(function (key, index) {
					return issueTypeWithFields.fields[key];
			  })
			: null;
		const missingRequiredCustomField = this.state.selectedCustomFieldValues
			? fieldTypes.some(
					x =>
						!(x.key in this.state.selectedCustomFieldValues
							? this.state.selectedCustomFieldValues[x.key] !== '' && this.state.selectedCustomFieldValues[x.key]
							: false)
			  )
			: fieldTypes && fieldTypes.length > 0;

		const content = (
			<div className="create-jira-issue-modal">
				{<Subtitle>{formatMessage({id: 'project_scoping.jira.phase.move.description'})}</Subtitle>}

				<>
					<div>
						{this.props.viewer.project.jiraCloudEpicIds ? (
							<Dropdown
								value={this.state.jiraProjectId?.value}
								label="Jira project"
								options={projectOptions}
								onChange={this.handleProjectChange.bind(this)}
							/>
						) : null}
					</div>
					<div>
						{this.state.loading ? <FormattedMessage id="common.loading_please_wait" /> : ''}
						{fieldTypes && fieldTypes.length ? (
							<div className={'field'}>
								<label className="custom-field-header-title">Required custom fields</label>
							</div>
						) : (
							''
						)}
						{issueTypeWithFields
							? fieldTypes.map(x => {
									switch (x.schema.type) {
										case 'number':
										case 'string':
											return (
												<div className={'field'}>
													{
														<InputFieldV2
															value={
																this.state.selectedCustomFieldValues
																	? this.state.selectedCustomFieldValues[x.key]
																	: ''
															}
															id={x.key}
															label={x.name}
															type={x.schema.type === 'number' ? 'number' : 'text'}
															onChange={this.handleCustomFieldInputChange.bind(this, x.key)}
															required={true}
															maxLength={191}
														/>
													}
												</div>
											);
										case 'option':
											return (
												<div className={'field'}>
													{
														<Dropdown
															value={
																this.state.selectedCustomFieldValues
																	? this.state.selectedCustomFieldValues[x.key]
																	: null
															}
															label={x.name}
															options={
																x.allowedValues
																	? x.allowedValues.map(option => ({
																			label: option.value,
																			value: option.id,
																	  }))
																	: []
															}
															onChange={this.handleCustomFieldChange.bind(this, x.key)}
															multiSelect={false}
															required={true}
														/>
													}
												</div>
											);
										case 'array':
											return (
												<div className={'field'}>
													{
														<Dropdown
															value={
																this.state.selectedCustomFieldValues &&
																this.state.selectedCustomFieldValues[x.key]
																	? this.state.selectedCustomFieldValues[x.key].map(
																			el => el.value
																	  )
																	: null
															}
															label={x.name}
															options={
																x.allowedValues
																	? x.allowedValues.map(option => ({
																			label: option.value,
																			value: option.id,
																	  }))
																	: []
															}
															onChange={this.handleCustomFieldArrayChange.bind(this, x.key)}
															multiSelect={true}
															required={true}
														/>
													}
												</div>
											);
										default:
											return (
												<div className={'field'}>
													<label className="custom-field-input-title">{x.name}</label>
													<div
														className={
															'input-value-container' +
															(this.props.useBorderStyle ? ' bordered' : '') +
															(this.props.addSearchIconPlaceholder
																? ' search-icon-placeholder'
																: '') +
															(this.props.currency
																? ' currency-placeholder ' + this.props.currency
																: '') +
															(this.props.invalidInput ? ' invalid' : '') +
															(this.state.isFocused ? ' focused' : '') +
															(this.props.alignTextRight ? ' align-right' : '') +
															(this.props.locked ? ' locked' : '')
														}
													>
														<textarea
															rows={2}
															class="input-value"
															cols={49}
															value={
																'This required custom field type is not supported by Forecast, to continue make the field type optional in Jira.'
															}
															disabled={true}
															style={{resize: 'none'}}
														/>
													</div>
												</div>
											);
									}
							  })
							: null}
					</div>
				</>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.create'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.onConfirm.bind(this),
						preventDefaultClose: true,
						disabled: !this.state.dataLoaded || missingRequiredCustomField,
					},
				]}
				headerText={formatMessage({id: 'common.move_to_jira'})}
				content={content}
			/>
		);
	}
}
CreateJiraMilestoneModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	milestoneId: PropTypes.string,
	defaultCallback: PropTypes.func.isRequired,
};

const createJiraMilestoneModalQuery = graphql`
	query createJiraMilestoneModal_Query($projectId: ID) {
		viewer {
			actualPersonId
			component(name: "create_jira_milestone_modal")
			...createJiraMilestoneModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {createJiraMilestoneModalQuery};

export default injectIntl(
	createFragmentContainer(CreateJiraMilestoneModal, {
		viewer: graphql`
			fragment createJiraMilestoneModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
				id
				project(internalId: $projectId) {
					id
					jiraCloudProject {
						id
					}
					jiraServerProject {
						id
					}
					jiraCloudEpicIds
				}
			}
		`,
	})
);
