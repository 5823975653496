import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const StatsContainer = styled.div`
	margin-right: 24px;
`;

export const StatContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 119px;
	padding: 16px;
	margin-bottom: 16px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 8px;
`;

export const StatValue = styled.div`
	font-size: 22px;
	font-weight: 600;
	color: ${props => props.color};
`;

export const StatTitle = styled.div`
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 11px;
`;
