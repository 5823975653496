import { Checkbox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, SearchField, SubHeading, Text, } from '@forecast-it/design-system';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { showLoader, hideLoader } from '../../global_loader';
import Util from '../../../forecast-app/shared/util/util';
import CreateSageIntacctTimesheetsMutation from '../../../mutations/CreateSageIntacctTimesheetsMutation';
import { createFragmentContainer, graphql } from 'react-relay';
import { createDateFromYMD, dateObjectToIsoDate } from '../../../forecast-app/shared/util/DateUtil';
import { HorizontalBar } from './SageIntacctModal.styled';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 850 ? '200px' : '500px';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '1px solid #ccc' : 'none')};
`;
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const SageIntacctExportTimesheetsModal = ({ closeModal, startDateMoment, endDateMoment, company, }) => {
    const { formatMessage } = useIntl();
    const [timesheets, setTimesheets] = useState([]);
    const [filteredTimesheets, setFilteredTimesheets] = useState([]);
    const [selectedTimesheets, setSelectedTimesheets] = useState([]);
    const selectAll = useMemo(() => {
        if (filteredTimesheets.length === 0 || selectedTimesheets.length === 0)
            return false;
        return filteredTimesheets.every(ts => selectedTimesheets.includes(ts.SageEmployeeId));
    }, [filteredTimesheets, selectedTimesheets]);
    const [exporting, setExporting] = useState(false);
    const disableImportButton = exporting || selectedTimesheets.length === 0;
    useEffect(() => {
        var _a, _b;
        // Map time regs as Sage timesheets
        const timesheets = ((_b = (_a = company === null || company === void 0 ? void 0 : company.timeRegistrations) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.reduce((acc, cur) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const timeReg = cur === null || cur === void 0 ? void 0 : cur.node;
            // if no person has no sage id or time reg already synched to Sage, skip
            if (!((_a = timeReg === null || timeReg === void 0 ? void 0 : timeReg.person) === null || _a === void 0 ? void 0 : _a.sageIntacctId) || timeReg.sageIntacctTimesheetEntryRecordNumber) {
                return acc;
            }
            const projectSageId = ((_c = (_b = timeReg.project) === null || _b === void 0 ? void 0 : _b.sageProject) === null || _c === void 0 ? void 0 : _c.sageProjectId) || ((_f = (_e = (_d = timeReg.task) === null || _d === void 0 ? void 0 : _d.project) === null || _e === void 0 ? void 0 : _e.sageProject) === null || _f === void 0 ? void 0 : _f.sageProjectId);
            // If project is not a sage project, or if time reg is on task with no sage id, skip.
            if (!projectSageId || (timeReg.task && !timeReg.task.sageIntacctId)) {
                return acc;
            }
            const entry = {
                TimeRegId: timeReg.id,
                Date: dateObjectToIsoDate(createDateFromYMD({ year: timeReg.year || 0, month: timeReg.month || 0, day: timeReg.day || 0 })),
                Hours: (timeReg.minutesRegistered || 0) / 60.0,
                Billable: timeReg.billable,
                Notes: timeReg.notes,
                SageProjectId: projectSageId,
                SageTaskId: (_g = timeReg.task) === null || _g === void 0 ? void 0 : _g.sageIntacctId,
                SageLocationId: timeReg.person.sageIntacctLocationId,
                SageDepartmentId: (_h = timeReg.person.department) === null || _h === void 0 ? void 0 : _h.sageIntacctId,
            };
            if (timeReg) {
                const timesheet = acc.find(obj => { var _a; return obj.SageEmployeeId === ((_a = timeReg === null || timeReg === void 0 ? void 0 : timeReg.person) === null || _a === void 0 ? void 0 : _a.sageIntacctId); });
                if (timesheet) {
                    timesheet.TimeRegs.push(entry);
                }
                else {
                    const timesheet = {
                        SageEmployeeId: ((_j = timeReg.person) === null || _j === void 0 ? void 0 : _j.sageIntacctId) || '',
                        Name: timeReg.person.fullName,
                        StartDate: startDateMoment.format('YYYY-MM-DD'),
                        TimeRegs: [entry],
                    };
                    acc.push(timesheet);
                }
            }
            return acc;
        }, [])) || [];
        setTimesheets(timesheets);
        setFilteredTimesheets(timesheets);
    }, []);
    const selectAllClick = () => {
        if (filteredTimesheets.length === 0)
            return;
        if (selectedTimesheets.length === 0) {
            setSelectedTimesheets(filteredTimesheets.map(ts => ts.SageEmployeeId));
        }
        else {
            const allFilteredIds = filteredTimesheets.map(ts => ts.SageEmployeeId);
            const isAllFilteredIdsInSelected = allFilteredIds.every(id => selectedTimesheets.includes(id));
            if (isAllFilteredIdsInSelected) {
                const removedSelectedIds = selectedTimesheets.filter(id => !allFilteredIds.includes(id));
                setSelectedTimesheets(removedSelectedIds);
            }
            else {
                const addedNewSelectedIds = selectedTimesheets.concat(allFilteredIds);
                setSelectedTimesheets(addedNewSelectedIds);
            }
        }
    };
    const timesheetClick = (inputId) => {
        if (selectedTimesheets.includes(inputId)) {
            const selectedDeps = selectedTimesheets.filter(id => id !== inputId);
            setSelectedTimesheets(selectedDeps);
        }
        else {
            const selectedDeps = [...selectedTimesheets];
            selectedDeps.push(inputId);
            setSelectedTimesheets(selectedDeps);
        }
    };
    const onFilterChange = (input) => {
        setFilteredTimesheets(timesheets.filter(tm => { var _a; return (_a = tm.Name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(input.toLowerCase()); }));
    };
    const onExportClick = () => {
        setExporting(true);
        showLoader();
        Util.CommitMutation(CreateSageIntacctTimesheetsMutation, {
            People: timesheets.filter(ts => selectedTimesheets.includes(ts.SageEmployeeId)),
        }, () => {
            setExporting(false);
            closeModal();
            hideLoader();
        });
    };
    return (React.createElement(CreativeModal, { title: 'Export Timesheets to Sage Intacct', size: 'l', closeModal: closeModal, creativeButtonText: `Export Selected Timesheets (${selectedTimesheets.length})`, cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onExportClick, onCancelHandler: closeModal, disabled: disableImportButton, replicateDesignSystem: true },
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Text, { color: Text.color.medium }, `For the period ${startDateMoment.format('DD MMM YYYY')} to ${endDateMoment.format('DD MMM YYYY')}`),
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, width: "600px" })),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: selectAllClick, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null,
            React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
                React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredTimesheets &&
                    filteredTimesheets.map((dep) => {
                        return (React.createElement(ListItem, { key: 'timesheet-' + dep.SageEmployeeId, hasBorderBottom: true },
                            React.createElement(FlexRow, { gap: 'xl' },
                                React.createElement(Checkbox, { onChange: () => timesheetClick(dep.SageEmployeeId), checked: selectedTimesheets.includes(dep.SageEmployeeId) }),
                                React.createElement(Text, { size: '2' }, dep.Name))));
                    })))),
        React.createElement(HorizontalBar, null)));
};
const sageIntacctExportTimesheetsModalQuery = graphql `
	query SageIntacctExportTimesheetsModal_Query($startDateString: String, $endDateString: String) {
		viewer {
			actualPersonId
			component(name: "sage_intacct_export_timesheets_modal")
			company {
				...SageIntacctExportTimesheetsModal_company
					@arguments(startDateString: $startDateString, endDateString: $endDateString)
			}
		}
	}
`;
export { sageIntacctExportTimesheetsModalQuery };
export default createFragmentContainer(SageIntacctExportTimesheetsModal, {
    company: graphql `
		fragment SageIntacctExportTimesheetsModal_company on Company
		@argumentDefinitions(startDateString: {type: "String"}, endDateString: {type: "String"}) {
			timeRegistrations(first: 10000000, startDate: $startDateString, endDate: $endDateString) {
				edges {
					node {
						id
						year
						month
						day
						minutesRegistered
						billable
						person {
							id
							fullName
							sageIntacctId
							sageIntacctLocationId
							department {
								id
								sageIntacctId
							}
						}
						notes
						project {
							id
							name
							sageProject {
								sageProjectId
							}
						}
						task {
							id
							name
							sageIntacctId
							project {
								id
								name
								sageProject {
									sageProjectId
								}
							}
						}
						sageIntacctTimesheetEntryRecordNumber
					}
				}
			}
		}
	`,
});
