import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import qs from 'query-string';
import {withRouter} from 'react-router-dom';
import * as tracking from '../tracking';
import {injectIntl} from 'react-intl';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import LoginMutation from '../mutations/login_mutation';
import CreateOauthVerificationKeyMutation from '../mutations/create_oauth_verification_key_mutation';
import LogoutMutation from '../mutations/logout_mutation';
import Util from '../forecast-app/shared/util/util';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class loginOauth extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
		};

		this.superPropertyChecksum = trackPage('Login - OAuth');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('login-oauth');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		const email = this.refs.economicPersonEmailSignin.value.trim();
		const password = this.refs.password.value.trim();

		if (!password.length) {
			this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.password-empty'})});
			return;
		}

		const onSuccess = ({login}) => {
			if (login.viewer.actualPersonId) {
			} else {
				if (login.errors && login.errors.length > 0 && login.errors[0] === 'NO_LOGIN') {
					this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.no-permission'})});
				} else {
					this.setState({
						errorMsg: this.props.intl.formatMessage({id: 'login.invalid-user-pass'}),
					});
				}
			}
		};

		Util.CommitMutation(
			LoginMutation,
			{
				email: email,
				password: password,
				rememberMe: true,
			},
			onSuccess
		);
	}

	authorize() {
		const onSuccess = ({createOauthVerificationKey}) => {
			window.location.href = `${qs.parse(this.props.location.search).redirect_uri}?state=${
				qs.parse(this.props.location.search).state
			}&code=${createOauthVerificationKey.code}`;
		};

		this.setState({showOverlay: true});
		Util.CommitMutation(
			CreateOauthVerificationKeyMutation,
			{
				clientId: qs.parse(this.props.location.search).client_id,
				redirectUri: qs.parse(this.props.location.search).redirect_uri,
			},
			onSuccess
		);
	}

	logout() {
		const onSuccess = ({login}) => {
			tracking.logout();
		};

		Util.CommitMutation(LogoutMutation, null, onSuccess);
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div className="fullscreen fullscreen-centered-outer login-economic login-bg">
				{this.state.showOverlay ? <UploadingOverlay /> : null}
				<div className="fullscreen-centered-inner">
					<div className="logos">
						<div className="logo_forecast" />
					</div>
					<div className="fullscreen-centered-content form-vertical">
						{this.props.viewer.actualPersonId ? (
							<div className="authorize">
								<h1>
									{formatMessage(
										{id: 'oauth.header'},
										{person: this.props.viewer.firstName || this.props.viewer.lastName}
									)}
								</h1>
								<div className="authorize-buttons">
									<button className="authorize-button" onClick={this.authorize.bind(this)}>
										{formatMessage({id: 'oauth.authorize'})}
									</button>
									<button onClick={this.logout.bind(this)} className="cancel-button">
										{formatMessage(
											{id: 'oauth.not_person'},
											{person: this.props.viewer.firstName || this.props.viewer.lastName}
										)}
									</button>
								</div>
							</div>
						) : (
							<form onSubmit={this.handleSubmit.bind(this)}>
								<h1>{formatMessage({id: 'login.title'})}</h1>
								<div className="fieldset">
									<input
										type="email"
										key="economic_person_email_signin"
										ref="economicPersonEmailSignin"
										placeholder={formatMessage({id: 'signup.economic-person_email'})}
									/>
									<input
										type="password"
										name="password"
										ref="password"
										defaultValue=""
										placeholder={formatMessage({id: 'login.enter-password'})}
									/>
									{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
								</div>

								<div className="button-signin">
									<input
										name="login"
										type="submit"
										value={formatMessage({id: 'login.log-in'})}
										className={'btn btn-pull-right'}
									/>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const loginOauthQuery = graphql`
	query loginOauth_Query {
		viewer {
			component(name: "login_oauth")
			...loginOauth_viewer
		}
	}
`;

export {loginOauthQuery};

export default createFragmentContainer(injectIntl(withRouter(loginOauth)), {
	viewer: graphql`
		fragment loginOauth_viewer on Viewer {
			id
			actualPersonId
			firstName
			lastName
		}
	`,
});
