import {LARGE, SkillsSelections} from '../../../forecast-app/shared/components/dropdowns/preview-selector/SkillsSelections';
import React from 'react';
import Util from '../../../forecast-app/shared/util/util';
import UpdateSkillPersonMutation from '../../../mutations/update_skill_person_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {useIntl} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {Button, BUTTON_COLOR, BUTTON_SIZE, BUTTON_STYLE} from '../../../forecast-app/shared/components/buttons/buttonV2/button';
import {ButtonContainer, LimitWidth} from './ProfileSkills.styled';
import {createFragmentContainer, graphql} from 'react-relay';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const ProfileSkills = ({person: personOrViewer, company}) => {
	const {formatMessage} = useIntl();
	const selectedSkillPersons = personOrViewer.skillPersons?.map(sp => ({skillId: sp.skill.id, skillLevelId: sp.level?.id}));
	const skills = company.skills?.edges.map(edge => edge.node);
	const showPersonSkillsModal = () => {
		tracking.trackEvent('skills - person skills modal opened');
		trackEvent('Person Skills Modal', 'Opened');
		showModal({
			type: MODAL_TYPE.PERSON_SKILLS,
			personId: personOrViewer.actualPersonId || personOrViewer.id,
		});
	};

	function updateSkillPersons(newIdList) {
		Util.CommitMutation(
			UpdateSkillPersonMutation,
			{
				personId: personOrViewer.actualPersonId || personOrViewer.id,
				skillPersons: newIdList,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.changes_saved'}),
				});
			}
		);
	}

	const onRemoveSkill = removedSkill => {
		updateSkillPersons(selectedSkillPersons.filter(sp => removedSkill.id !== sp.skillId));
	};

	const onSkillLevelChange = (skillId, skillLevelId) => {
		updateSkillPersons(selectedSkillPersons.map(sp => (sp.skillId === skillId ? {skillId, skillLevelId} : sp)));
	};

	return (
		<LimitWidth>
			<SkillsSelections
				size={LARGE}
				header={`${formatMessage({id: 'common.skills'})} (${selectedSkillPersons.length})`}
				skills={skills}
				selectedSkillPersons={selectedSkillPersons}
				onRemove={onRemoveSkill}
				warningCallback={() => {}}
				hideSelectionHeaderActions={false}
				skillLevelsEnabled={company.skillLevelsEnabled}
				skillLevels={company.skillLevels}
				onSkillLevelChange={onSkillLevelChange}
				showGiveFeedback
			/>

			<ButtonContainer>
				<Button
					onClick={showPersonSkillsModal}
					cy={'addMoreSkills'}
					buttonColor={BUTTON_COLOR.VERYLIGHTGREY}
					buttonStyle={BUTTON_STYLE.OUTLINE}
					size={BUTTON_SIZE.STANDARD}
					content={formatMessage({id: 'settings_skills.edit_skills'})}
				/>
			</ButtonContainer>
		</LimitWidth>
	);
};

export default createFragmentContainer(ProfileSkills, {
	company: graphql`
		fragment ProfileSkills_company on Company {
			skillLevelsEnabled
			skillLevels {
				id
				level
				description
			}
			skills {
				edges {
					node {
						id
						name
						category {
							name
						}
					}
				}
			}
		}
	`,
});
