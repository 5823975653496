import React from 'react';
import { useIntl } from 'react-intl';
import { FlexContainer, Heading, TextField, Link as LinkComponent, FlexRow, FlexColumn, Text } from '@forecast-it/design-system';
import Logo, { LOGO_SIZE } from '../../forecast-app/shared/components/logo/Logo';
import { Content, Page, PageContent, WideButton } from './Sage.styled';
import { Link as RouterLink } from 'react-router-dom';
export const SageForgotPassword = ({ errorMessage, email, onEmailChange, onReset, isSent, }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(Page, null,
        React.createElement(Content, { alignItems: "center", justifyContent: "center" },
            React.createElement(Logo, { size: LOGO_SIZE.MEDIUM }),
            React.createElement(FlexContainer, null,
                React.createElement(PageContent, { gap: "xl" },
                    isSent ? (React.createElement(React.Fragment, null,
                        React.createElement(FlexColumn, { gap: "l" },
                            React.createElement(Heading, { size: "3" }, formatMessage({ id: 'login.forgot_password.check_your_email' })),
                            React.createElement(Text, null, formatMessage({
                                id: 'login.forgot_password.instructions',
                            }, { b: b => React.createElement("b", null, b), email: email, br: React.createElement("br", null) }))))) : (React.createElement(React.Fragment, null,
                        React.createElement(FlexColumn, { gap: "l" },
                            React.createElement(Heading, { size: "3" }, formatMessage({ id: 'login.forgot_password.reset_your_password' })),
                            React.createElement(TextField, { label: formatMessage({ id: 'common.email' }), autoComplete: "email", errorText: errorMessage, value: email, onValueChange: onEmailChange })),
                        React.createElement(WideButton, { onClick: onReset }, formatMessage({ id: 'login.reset-password' })))),
                    React.createElement(FlexRow, { alignItems: "center", justifyContent: "center" },
                        React.createElement(RouterLink, { to: "/", component: LinkComponent }, formatMessage({ id: 'login.forgot_password.return_to_sign_in' }))))))));
};
