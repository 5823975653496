/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectsLoader_viewer$ref = any;
export type ProjectsLoader_QueryVariables = {||};
export type ProjectsLoader_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ProjectsLoader_viewer$ref,
  |}
|};
export type ProjectsLoader_Query = {|
  variables: ProjectsLoader_QueryVariables,
  response: ProjectsLoader_QueryResponse,
|};
*/


/*
query ProjectsLoader_Query {
  viewer {
    actualPersonId
    component(name: "projects_loader")
    ...ProjectsLoader_viewer
    id
  }
}

fragment ProjectsLoader_viewer on Viewer {
  availableFeatureFlags {
    key
    id
  }
  client {
    id
  }
  company {
    xeroEnabled
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "projects_loader"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"projects_loader\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsLoader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectsLoader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectsLoader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "xeroEnabled",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectsLoader_Query",
    "operationKind": "query",
    "text": "query ProjectsLoader_Query {\n  viewer {\n    actualPersonId\n    component(name: \"projects_loader\")\n    ...ProjectsLoader_viewer\n    id\n  }\n}\n\nfragment ProjectsLoader_viewer on Viewer {\n  availableFeatureFlags {\n    key\n    id\n  }\n  client {\n    id\n  }\n  company {\n    xeroEnabled\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '56afe4518a81ec70ea7f703895f49a72';

module.exports = node;
