import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import StandaloneEditInvoiceModal, {standaloneEditInvoiceModalQuery} from './standalone_edit_invoice_modal';
import {withRouter} from 'react-router-dom';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';

class StandaloneEditInvoiceModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companyInvoiceId: this.props.companyInvoiceId,
		};
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.companyInvoiceId !== this.state.companyInvoiceId;
	}

	closeModal() {
		let currentPath = this.props.location.pathname,
			parentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
		return this.props.history.push(parentPath);
	}

	onCloseErrorModal() {
		this.closeModal();
		this.props.retry();
	}

	render() {
		let parsedId = Number.parseInt(this.props.companyInvoiceId);

		if (!parsedId) this.closeModal();
		parsedId += '';

		return (
			<ForecastQueryRenderer
				key="query-render-StandaloneEditInvoiceModal"
				query={standaloneEditInvoiceModalQuery}
				modal={true}
				onCloseErrorModal={this.onCloseErrorModal.bind(this)}
				variables={{
					companyInvoiceId: parsedId,
				}}
				render={relayProps => (
					<StandaloneEditInvoiceModal
						refetch={this.props.retry}
						{...relayProps}
						closeModal={this.closeModal.bind(this)}
					/>
				)}
			/>
		);
	}
}

StandaloneEditInvoiceModalWrapper.propTypes = {
	companyInvoiceId: PropTypes.string.isRequired,
};
export default injectIntl(withRouter(StandaloneEditInvoiceModalWrapper));
