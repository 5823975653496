/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProfileProjectStatusUpdates_viewer$ref = any;
type SettingsProfileSubNav_viewer$ref = any;
type profileNotificationsSettings_viewer$ref = any;
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProfileNotificationsPage_viewer$ref: FragmentReference;
declare export opaque type ProfileNotificationsPage_viewer$fragmentType: ProfileNotificationsPage_viewer$ref;
export type ProfileNotificationsPage_viewer = {|
  +projectOwner: ?boolean,
  +disableNotifications: ?boolean,
  +company: ?{|
    +forecastDemo: ?boolean,
    +tier: ?TierType,
  |},
  +$fragmentRefs: profileNotificationsSettings_viewer$ref & ProfileProjectStatusUpdates_viewer$ref & SettingsProfileSubNav_viewer$ref,
  +$refType: ProfileNotificationsPage_viewer$ref,
|};
export type ProfileNotificationsPage_viewer$data = ProfileNotificationsPage_viewer;
export type ProfileNotificationsPage_viewer$key = {
  +$data?: ProfileNotificationsPage_viewer$data,
  +$fragmentRefs: ProfileNotificationsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileNotificationsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableNotifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastDemo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "profileNotificationsSettings_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileProjectStatusUpdates_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsProfileSubNav_viewer"
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '75c2ba418602156e5cf047e975b1e0b8';

module.exports = node;
