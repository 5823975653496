/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type mlStats_viewer$ref = any;
export type mlStats_QueryVariables = {||};
export type mlStats_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: mlStats_viewer$ref,
  |}
|};
export type mlStats_Query = {|
  variables: mlStats_QueryVariables,
  response: mlStats_QueryResponse,
|};
*/


/*
query mlStats_Query {
  viewer {
    actualPersonId
    component(name: "control_ml_models")
    ...mlStats_viewer
    id
  }
}

fragment missionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}

fragment mlStats_viewer on Viewer {
  id
  ...missionControlHeader_viewer
  missionControlData {
    id
    companiesWithMLModels {
      companyId
      companyName
      lowHighModel
      lowHighModelLastUpdated
      lowHighModelAccuracyTest
      lowHighModelAccuracyTrain
      lowHighModelRmseTest
      overrunPredictorPrecisionTest
      overrunPredictorRecallTest
      roleModel
      roleModelLastUpdated
      roleModelAccuracyTest
      roleModelAccuracyTrain
      roleModelTop1AccuracyTest
      labelModel
      labelModelLastUpdated
      labelModelAccuracyTest
      labelModelAccuracyTrain
      labelModelTop1AccuracyTest
      labelModelTop3AccuracyTest
      assigneeModel
      assigneeModelLastUpdated
      assigneeModelAccuracyTest
      assigneeModelAccuracyTrain
      assigneeModelTop1AccuracyTest
      assigneeModelTop3AccuracyTest
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "control_ml_models"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"control_ml_models\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "mlStats_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "mlStats_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "mlStats_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlCompanyMLStatsType",
                "kind": "LinkedField",
                "name": "companiesWithMLModels",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowHighModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowHighModelLastUpdated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowHighModelAccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowHighModelAccuracyTrain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lowHighModelRmseTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overrunPredictorPrecisionTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overrunPredictorRecallTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModelLastUpdated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModelAccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModelAccuracyTrain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleModelTop1AccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModelLastUpdated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModelAccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModelAccuracyTrain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModelTop1AccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelModelTop3AccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModelLastUpdated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModelAccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModelAccuracyTrain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModelTop1AccuracyTest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assigneeModelTop3AccuracyTest",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "mlStats_Query",
    "operationKind": "query",
    "text": "query mlStats_Query {\n  viewer {\n    actualPersonId\n    component(name: \"control_ml_models\")\n    ...mlStats_viewer\n    id\n  }\n}\n\nfragment missionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n\nfragment mlStats_viewer on Viewer {\n  id\n  ...missionControlHeader_viewer\n  missionControlData {\n    id\n    companiesWithMLModels {\n      companyId\n      companyName\n      lowHighModel\n      lowHighModelLastUpdated\n      lowHighModelAccuracyTest\n      lowHighModelAccuracyTrain\n      lowHighModelRmseTest\n      overrunPredictorPrecisionTest\n      overrunPredictorRecallTest\n      roleModel\n      roleModelLastUpdated\n      roleModelAccuracyTest\n      roleModelAccuracyTrain\n      roleModelTop1AccuracyTest\n      labelModel\n      labelModelLastUpdated\n      labelModelAccuracyTest\n      labelModelAccuracyTrain\n      labelModelTop1AccuracyTest\n      labelModelTop3AccuracyTest\n      assigneeModel\n      assigneeModelLastUpdated\n      assigneeModelAccuracyTest\n      assigneeModelAccuracyTrain\n      assigneeModelTop1AccuracyTest\n      assigneeModelTop3AccuracyTest\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '592d6601455bdf57a7f0d705ad6136a4';

module.exports = node;
