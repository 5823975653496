/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectStatus_projectStatus$ref = any;
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectHealthStatusSection_project$ref: FragmentReference;
declare export opaque type ProjectHealthStatusSection_project$fragmentType: ProjectHealthStatusSection_project$ref;
export type ProjectHealthStatusSection_project = {|
  +id: string,
  +status: ?PROJECT_STATUS,
  +projectHealth: ?{|
    +suggestedStatusRag: ?string
  |},
  +currentProjectStatus: ?{|
    +id: string,
    +$fragmentRefs: ProjectStatus_projectStatus$ref,
  |},
  +projectStatuses: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +year: ?number,
        +month: ?number,
        +day: ?number,
        +hour: ?number,
        +minute: ?number,
        +second: ?number,
        +$fragmentRefs: ProjectStatus_projectStatus$ref,
      |}
    |}>
  |},
  +$refType: ProjectHealthStatusSection_project$ref,
|};
export type ProjectHealthStatusSection_project$data = ProjectHealthStatusSection_project;
export type ProjectHealthStatusSection_project$key = {
  +$data?: ProjectHealthStatusSection_project$data,
  +$fragmentRefs: ProjectHealthStatusSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectStatus_projectStatus"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectStatuses"
        ]
      }
    ]
  },
  "name": "ProjectHealthStatusSection_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectHealth",
      "kind": "LinkedField",
      "name": "projectHealth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "suggestedStatusRag",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectStatus",
      "kind": "LinkedField",
      "name": "currentProjectStatus",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "projectStatuses",
      "args": null,
      "concreteType": "ProjectStatusTypeConnection",
      "kind": "LinkedField",
      "name": "__Project_projectStatuses_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectStatusTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectStatus",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "year",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "day",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hour",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minute",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "second",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '57f6e9b77d1b27f331129201ba3c6257';

module.exports = node;
