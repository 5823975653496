var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DirectApi from '../../../directApi';
import { QuickbooksSettingId, } from './ExportInvoiceModal.type';
import Util from '../../../forecast-app/shared/util/util';
import CreateQuickbooksInvoiceMutation from '../../../mutations/CreateQuickbooksInvoiceMutation';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { BUTTON_COLOR, BUTTON_STYLE } from '../../../constants';
export const getInitialQuickbookSettings = (intl) => {
    const { formatMessage } = intl;
    return [
        {
            id: QuickbooksSettingId.CONTROL_INVOICE_REFERENCE,
            name: formatMessage({ id: 'invoicing.quickbooks.control_reference' }),
            checked: false,
            disabled: false,
        },
        {
            id: QuickbooksSettingId.USE_PRODUCT_SERVICE_DESCRIPTIONS,
            name: formatMessage({ id: 'invoicing.quickbooks.use_product_service_descriptions' }),
            checked: false,
            disabled: false,
        },
        {
            id: QuickbooksSettingId.INCLUDE_PROJECT_NAME_IN_DESCRIPTION,
            name: formatMessage({ id: 'invoicing.quickbooks.include_project_name_in_description_when_exporting' }),
            checked: false,
            disabled: false,
        },
        {
            id: QuickbooksSettingId.USE_QUICKBOOKS_TERMS,
            name: formatMessage({ id: 'invoicing.quickbooks.use_terms' }),
            checked: false,
            disabled: false,
        },
        {
            id: QuickbooksSettingId.USE_CUSTOMER_DEFAULT_TAX,
            name: formatMessage({ id: 'invoicing.quickbooks.use_default_tax' }),
            checked: false,
            disabled: false,
        },
    ];
};
const getQuickbooksTaxCodes = () => __awaiter(void 0, void 0, void 0, function* () {
    return DirectApi.Fetch(`quickbooks/tax_codes`).then(json => {
        return json.map(taxCode => ({
            value: taxCode.id,
            label: taxCode.name,
            taxRate: taxCode.tax,
        }));
    });
});
const getProductsServices = () => __awaiter(void 0, void 0, void 0, function* () {
    return DirectApi.Fetch(`quickbooks/items_and_services`).then(json => {
        return json.map(item => ({
            value: item.id,
            label: item.name,
            description: item.description,
            unitPrice: item.unitPrice,
            taxable: item.taxable,
        }));
    });
});
export const fetchQuickbooksData = (setQuickbooksTaxCodes, setSelectedQuickbooksTaxCode, setProductsServices, onSuccess) => {
    const taxCodesPromise = getQuickbooksTaxCodes();
    const productsServicesPromise = getProductsServices();
    Promise.all([taxCodesPromise, productsServicesPromise]).then(result => {
        const [taxCodes, productsServices] = result;
        setQuickbooksTaxCodes(taxCodes);
        setSelectedQuickbooksTaxCode(taxCodes.find(taxCode => taxCode.value === '-1') || null);
        setProductsServices(productsServices);
        onSuccess();
    });
};
const onExportInvoiceSuccess = (result, intl, onSuccess) => {
    const { formatMessage } = intl;
    onSuccess();
    const { createQuickbooksInvoice } = result || {};
    if (createQuickbooksInvoice) {
        const { success, errorMessage } = createQuickbooksInvoice;
        if (success) {
            createToast({ duration: 5000, message: formatMessage({ id: 'integrations.quickbooks.invoice_created' }) });
        }
        else {
            showModal({
                type: MODAL_TYPE.WARNING,
                warningMessageId: 'invoicing.not_exported',
                warningInformation: [errorMessage || 'The invoice could not be exported.'],
                buttons: [
                    {
                        text: formatMessage({ id: 'common.ok' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.WHITE,
                    },
                ],
            });
        }
    }
};
const getQuickbookSettingsInput = (settings) => {
    const settingsInput = {
        letQBOSetDescription: false,
        letQBOSetInvoiceRef: false,
        useQBOTerms: false,
        useQBODefaultTax: false,
        includeProjectNameInDesc: false,
    };
    settings.forEach(setting => {
        switch (setting.id) {
            case QuickbooksSettingId.USE_PRODUCT_SERVICE_DESCRIPTIONS:
                settingsInput.letQBOSetDescription = setting.checked;
                break;
            case QuickbooksSettingId.CONTROL_INVOICE_REFERENCE:
                settingsInput.letQBOSetInvoiceRef = setting.checked;
                break;
            case QuickbooksSettingId.USE_QUICKBOOKS_TERMS:
                settingsInput.useQBOTerms = setting.checked;
                break;
            case QuickbooksSettingId.USE_CUSTOMER_DEFAULT_TAX:
                settingsInput.useQBODefaultTax = setting.checked;
                break;
            case QuickbooksSettingId.INCLUDE_PROJECT_NAME_IN_DESCRIPTION:
                settingsInput.includeProjectNameInDesc = setting.checked;
                break;
            default:
                break;
        }
    });
    return settingsInput;
};
export const exportInvoiceToQuickbooksOnline = ({ intl, onSuccess, invoice, invoiceRows, billTo, quickbookSettings, taxCode, onError, }) => {
    const entryMap = invoiceRows.map(row => ({ entryId: row.id, itemId: row.quickbooksItem }));
    const input = Object.assign({ invoiceId: invoice.id, billToId: billTo.id, quickbooksClientId: billTo === null || billTo === void 0 ? void 0 : billTo.externalId, taxCodeId: taxCode === null || taxCode === void 0 ? void 0 : taxCode.value, entryMap }, getQuickbookSettingsInput(quickbookSettings));
    Util.CommitMutation(CreateQuickbooksInvoiceMutation, input, result => onExportInvoiceSuccess(result, intl, onSuccess), true, null, onError);
};
