import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import * as PropTypes from 'prop-types';

import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import GenericModal from '../generic_modal';

export const CsvDownloadModal = ({closeModal, query, componentName, variables, createCsvDownload, children, ...props}) => {
	const intl = useIntl();

	const content = (
		<div className={'body-container'}>
			<div style={{marginBottom: '12px'}}>{intl.formatMessage({id: 'common.wait_gathering'})}</div>
			<ForecastQueryRenderer
				key={`query-render-csv-download-${componentName}`}
				query={query}
				modal={true}
				showLoader={false}
				variables={variables}
				render={relayProps => {
					return React.cloneElement(createCsvDownload, {closeModal, ...relayProps, ...props}, children);
				}}
			/>
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			headerText={<FormattedHTMLMessage id="common.processing_loading" />}
			content={content}
			preventClickAwayClose={true}
			modalWidth={572}
		/>
	);
};

CsvDownloadModal.propTypes = {
	/**
	 * The GraphQL Query to execute, that will download all data required to do the CSV download
	 */
	query: PropTypes.any.isRequired,
	/**
	 * The component name, used in ForecastQueryRenderer
	 */
	componentName: PropTypes.string.isRequired,
	/**
	 * Optional variables to be passed to the query.
	 */
	variables: PropTypes.object,
	/**
	 * Required callback function, which will be called with one argument, which is the result viewer of the GraphQL query
	 * It is expected to actually trigger the download of the CSV file and not return anything.
	 *
	 * The viewer is passed to the function wrapped in an object, so the function
	 * could be a functional React component.
	 */
	createCsvDownload: PropTypes.func.isRequired,
};
