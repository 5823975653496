import React from 'react';
import { CommitMutation } from '../mutations/ts/CommitMutation';
import { hasFeatureFlag } from '../forecast-app/shared/util/FeatureUtil';
import OptIntoFeatureFlagMutation from '../mutations/ts/OptIntoFeatureFlagMutation';
import { scheduleUserPilotTriggerPageLoad } from '../forecast-app/shared/util/UserPilotUtil';
import { USER_PILOT_FLOW_TIMESHEETS_REMASTER } from '../forecast-app/shared/util/UserPilotConstants';
const featuresAndRedirects = {
    timesheet_remaster: { redirectUri: 'timesheets', triggerUserPilotFlow: USER_PILOT_FLOW_TIMESHEETS_REMASTER },
};
const redirect = (config) => {
    window.location.replace('/' + config.redirectUri);
};
const ActivateFeature = ({ featureFlag }) => {
    if (featuresAndRedirects[featureFlag]) {
        const config = featuresAndRedirects[featureFlag];
        if (!hasFeatureFlag(featureFlag)) {
            const onSuccess = () => {
                scheduleUserPilotTriggerPageLoad(config.triggerUserPilotFlow);
                redirect(config);
            };
            CommitMutation(OptIntoFeatureFlagMutation, { featureFlag: featureFlag }, onSuccess);
        }
        else {
            redirect(config);
        }
    }
    return React.createElement(React.Fragment, null);
};
export default ActivateFeature;
