import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {injectIntl} from 'react-intl';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import DirectApi from '../../directApi';

class Unit4ActivitySelectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUnit4Activity:
				this.props.timeReg && this.props.timeReg.unit4ActivityId ? {value: this.props.timeReg.unit4ActivityId} : null,
			unit4Activities: [],
			loading: false,
		};
	}
	componentDidMount() {
		if (this.modal_title) {
			this.modal_title.focus();
		}
	}

	fetchUnit4Activities(project) {
		if (project) {
			if (project.unit4Project) {
				this.setState({loading: true});
				DirectApi.Fetch('unit4_activities/' + project.id).then(activities => {
					this.setState({unit4Activities: activities, loading: false});
				});
			} else {
				this.setState({unit4Activities: [], loading: false, selectedUnit4Activity: null});
			}
		}
	}

	onUnit4ActivitySelect(val) {
		this.setState({selectedUnit4Activity: val});
	}

	selectActivity() {
		this.props.callback(this.state.selectedUnit4Activity);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const unit4ActivityOptions = this.state.unit4Activities.map(opt => {
			return {value: opt.id, label: opt.name};
		});
		const content = (
			<div ref={div => (this.modal_title = div)} className="integration-entity-select-modal">
				<div className="integration-entity-description">
					{formatMessage(
						{id: 'integration.register_time_for'},
						{input: this.props.value, target: this.props.timeRegName}
					)}
				</div>
				<DropdownV2
					value={this.state.selectedUnit4Activity ? this.state.selectedUnit4Activity.value : null}
					options={unit4ActivityOptions}
					onChange={this.onUnit4ActivitySelect.bind(this)}
					hideLabel={true}
					placeholder={formatMessage({id: 'integrations.unit4_activity'})}
					customHeight={30}
					onClick={this.fetchUnit4Activities.bind(this, this.props.project)}
					integrationDropdown={true}
					integrationLoading={this.state.loading}
					integrationRetryToFetch={this.fetchUnit4Activities.bind(this, this.props.project)}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				headerText={formatMessage({id: 'integrations.unit4_activity_placeholder'})}
				defaultCallback={this.props.defaultCallback}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						callback: this.props.cancelCallback,
					},
					{
						text: formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.selectActivity.bind(this),
					},
				]}
				content={content}
			/>
		);
	}
}
Unit4ActivitySelectModal.propTypes = {
	timeReg: PropTypes.object,
};
export default injectIntl(Unit4ActivitySelectModal);
