import React, { useEffect, useState } from 'react';
import { Table, useTableFunctions, ExportType } from '@forecast-it/design-system';
import { createFragmentContainer, graphql } from 'react-relay';
import { getTableData } from './ProjectBreakdownData';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { profilePicRectSrc } from '../../../../directApi';
import ProjectBreakdownHeader from './ProjectBreakdownHeader';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import { PERMISSION_TYPE } from '../../../../Permissions';
import { useIntl } from 'react-intl';
import { useRemappingFormatMessage } from '../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
export const TABS = {
    baseline: 'project_budget.baseline',
    planned: 'project_budget.plan',
    actualToDate: 'project_budget.actual_to_date',
    forecastToComplete: 'project_budget.forecast_to_complete',
    totalAtCompletion: 'project_budget.total_at_completion',
};
const COLUMNS = {
    'grouping-column': 'common.name',
    hours: 'settings.estimation-hours',
    revenue: 'project_budget.revenue',
    cost: 'project_budget.cost',
    profit: 'common.profit',
    margin: 'common.margin',
    valueOfService: 'common.value_of_service',
};
const SORT = {
    asc: 'common.sort_ascend',
    desc: 'common.sort_descend',
};
export const TABLE_CATEGORY = {
    TIME: 'Time',
    EXPENSES: 'Expenses',
    TIME_REGISTRATIONS: 'Time registrations',
    TASK_ESTIMATES: 'Task Estimates',
    ALLOCATIONS: 'Allocations',
};
const ProjectBreakdownTable = ({ viewer }) => {
    var _a;
    const { formatMessage } = useIntl();
    const formatMessageRemapped = useRemappingFormatMessage();
    const [currentTab, setCurrentTab] = useState(TABS.totalAtCompletion);
    const [tableRef, table] = useTableFunctions();
    const { project, company, permissions } = viewer;
    const revenueOnly = (permissions === null || permissions === void 0 ? void 0 : permissions.includes(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE)) &&
        !(permissions === null || permissions === void 0 ? void 0 : permissions.includes(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION));
    const currency = ((_a = project === null || project === void 0 ? void 0 : project.rateCard) === null || _a === void 0 ? void 0 : _a.currency) || (company === null || company === void 0 ? void 0 : company.currency) || '';
    const useBaseline = (project === null || project === void 0 ? void 0 : project.useBaseline) || false;
    const isBaselineTab = currentTab === TABS.baseline;
    const handleSortTracking = (sort, column) => {
        trackEvent('Project Financial Breakdown Sort', 'Changed', {
            tab: formatMessageRemapped({ id: currentTab }),
            sort: formatMessage({ id: SORT[sort] }),
            column: formatMessage({ id: COLUMNS[column] }),
        });
    };
    const handleTabChange = (tab) => {
        trackEvent('Project Financial Breakdown Tab', 'Changed', {
            tab: formatMessageRemapped({ id: tab }),
        });
        setCurrentTab(tab);
    };
    const [tableData, setTableData] = useState({
        baseline: [],
        planned: [],
        actualToDate: [],
        forecastToComplete: [],
        totalAtCompletion: [],
    });
    useEffect(() => {
        const data = getTableData(viewer, formatMessage);
        setTableData(data);
    }, []);
    const onExportCsv = () => {
        const tabLabel = formatMessageRemapped({ id: currentTab });
        trackEvent('Project Financial Breakdown CSV Export', 'Clicked', {
            tab: tabLabel,
        });
        table.exportData((project === null || project === void 0 ? void 0 : project.name) + '-financials-breakdown-' + tabLabel, ExportType.CSV, isBaselineTab ? ['category', 'role', 'expense_category'] : ['category', 'person', 'expense', 'task', 'allocation']);
    };
    let tabData = [];
    switch (currentTab) {
        case TABS.baseline:
            tabData = tableData.baseline;
            break;
        case TABS.actualToDate:
            tabData = tableData.actualToDate;
            break;
        case TABS.forecastToComplete:
            tabData = tableData.forecastToComplete;
            break;
        case TABS.planned:
            tabData = tableData.planned;
            break;
        case TABS.totalAtCompletion:
            tabData = tableData.totalAtCompletion;
            break;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ProjectBreakdownHeader, { tab: currentTab, onTabChange: handleTabChange, onExportCsv: onExportCsv, useBaseline: useBaseline }),
        React.createElement(CustomScrollDiv, null,
            React.createElement(Table, { data: tabData, showFooter: true, grouping: isBaselineTab ? ['category'] : ['category', 'person'], tableRef: tableRef, width: '100%' },
                React.createElement(Table.GroupingColumn, { header: formatMessage({ id: 'common.name' }), defaultColumnKey: row => isBaselineTab
                        ? row.expenseCategory
                            ? 'expense_category'
                            : 'role'
                        : row.expense
                            ? 'expense'
                            : row.allocation
                                ? 'allocation'
                                : 'task', allowSorting: true, footer: formatMessage({ id: 'common.total' }), onSortChange: handleSortTracking },
                    React.createElement(Table.TextColumn, { accessorKey: 'category', header: 'Category', id: 'category' }),
                    isBaselineTab ? (React.createElement(React.Fragment, null,
                        React.createElement(Table.TextColumn, { accessorKey: 'role.name', header: 'Role', id: 'role', groupingValue: row => row.roleId }),
                        React.createElement(Table.TextColumn, { accessorKey: 'expenseCategory.name', header: 'Expense Category', id: 'expense_category', groupingValue: row => row.expenseCategoryId }))) : (React.createElement(React.Fragment, null,
                        React.createElement(Table.AvatarColumn, { accessorKey: 'person.fullName', header: 'Person', id: 'person', image: row => row.person.profilePictureId ? profilePicRectSrc(row.person.profilePictureId) : undefined, groupingValue: row => { var _a; return (_a = row.person) === null || _a === void 0 ? void 0 : _a.id; }, defaultGroupingValue: formatMessage({ id: 'common.no_person' }) }),
                        React.createElement(Table.TextColumn, { accessorKey: 'task.name', header: 'Task', id: 'task', groupingValue: row => row.taskId }),
                        React.createElement(Table.TextColumn, { accessorKey: 'allocation.name', header: 'Allocation', id: 'allocation', groupingValue: row => row.allocationId }),
                        React.createElement(Table.TextColumn, { accessorKey: 'expense.name', header: 'Expense', id: 'expense', groupingValue: row => row.expenseId })))),
                React.createElement(Table.TimeColumn, { accessorKey: 'hours', header: formatMessage({ id: 'common.hours' }), exportHeader: formatMessage({ id: 'common.minutes' }), allowSorting: true, autoFooter: true, onSortChange: handleSortTracking }),
                React.createElement(Table.CurrencyColumn, { accessorKey: 'revenue', header: formatMessage({ id: 'project_budget.revenue' }), allowSorting: true, currency: currency, autoFooter: true, onSortChange: handleSortTracking }),
                !revenueOnly ? (React.createElement(Table.CurrencyColumn, { accessorKey: 'cost', header: formatMessage({ id: 'project_budget.cost' }), currency: currency, allowSorting: true, autoFooter: true, onSortChange: handleSortTracking })) : undefined,
                !revenueOnly ? (React.createElement(Table.CurrencyColumn, { accessorKey: 'profit', header: formatMessage({ id: 'project_budget.profit' }), currency: currency, allowSorting: true, autoFooter: true, onSortChange: handleSortTracking })) : undefined,
                !revenueOnly ? (React.createElement(Table.PercentageColumn, { accessorKey: 'margin', header: formatMessage({ id: 'project_budget.margin' }), allowSorting: true, aggregationFn: row => row.getValue('profit') / row.getValue('revenue'), autoFooter: true, footer: undefined, onSortChange: handleSortTracking })) : undefined,
                React.createElement(Table.CurrencyColumn, { accessorKey: 'valueOfService', header: formatMessage({ id: 'common.value_of_service' }), currency: currency, allowSorting: true, autoFooter: true, onSortChange: handleSortTracking }),
                React.createElement(Table.ExportOnlyColumn, { header: 'Currency', value: currency })))));
};
const projectBreakdownTableQuery = graphql `
	query ProjectBreakdownTable_Query($projectId: ID) {
		viewer {
			id
			actualPersonId
			component(name: "project_breakdown_table")
			...ProjectBreakdownTable_viewer @arguments(projectId: $projectId)
		}
	}
`;
export { projectBreakdownTableQuery };
export default createFragmentContainer(ProjectBreakdownTable, {
    viewer: graphql `
		fragment ProjectBreakdownTable_viewer on Viewer @argumentDefinitions(projectId: {type: ID}) {
			permissions
			project(internalId: $projectId) {
				id
				name
				rateCard {
					currency
				}
				tasks(first: 1000000) {
					edges {
						node {
							id
							name
						}
					}
				}
				expenseItems(first: 1000000) {
					edges {
						node {
							id
							name
						}
					}
				}
				allocations(first: 1000000) {
					edges {
						node {
							id
							startYear
							startMonth
							startDay
							endYear
							endMonth
							endDay
						}
					}
				}
				groupedFinancialNumbers(convertToProjectCurrency: true, groupBy: ["PERSON", "TASK", "EXPENSE", "ALLOCATION"]) {
					personId
					taskId
					expenseId
					allocationId
					scopeTotalMinutes
					plannedRevenue
					plannedCost
					plannedRevenueProfit
					plannedRevenueMargin
					allPlannedTimeAndExpenses
					registeredMinutes
					totalActualRevenueRecognition
					actualCost
					actualRevenueProfit
					actualRevenueMargin
					allActualTimeAndExpenses
					forecastTimeToComplete
					totalForecastRevenueToComplete
					forecastCostToComplete
					forecastRevenueProfitToComplete
					forecastRevenueMarginToComplete
					allForecastTimeAndExpensesToComplete
				}
				baselineGroupedFinancialNumbers: groupedFinancialNumbers(
					convertToProjectCurrency: true
					groupBy: ["ROLE", "EXPENSE_CATEGORY"]
				) {
					roleId
					expenseCategoryId
					baselineMinutes
					baselineTimeAndExpenses
					baselineCost
					baselineProfit
					baselineMargin
					baselineRevenue
				}
				useBaseline
			}
			company {
				id
				currency
				roles(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
				expenseCategories(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
				allPersons(first: 100000) {
					edges {
						node {
							id
							firstName
							lastName
							profilePictureId
						}
					}
				}
			}
		}
	`,
});
