import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {Dropdown} from 'web-components';
import UpdateImpersonatePersonMutation from '../../mutations/mission_control/update_impersonate_person_mutation';
import MissionControlHeader from './mission_control_header';
import {cloneDeep} from 'lodash';
import Util from '../../forecast-app/shared/util/util';
import {hideLoader, showLoader} from '../global_loader';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import GenericModal from '../modal/generic_modal';
import {Button} from '@forecast-it/design-system';

class impersonate extends Component {
	constructor(props) {
		super(props);

		const sortedOtherPersons = cloneDeep(this.props.viewer.missionControlData.otherPersons).sort((a, b) => {
			if (a.companyName.toUpperCase() < b.companyName.toUpperCase()) return -1;
			if (a.companyName.toUpperCase() > b.companyName.toUpperCase()) return 1;
			return 0;
		});

		let longest_company_name = sortedOtherPersons.reduce(
			(total, el) => (total < el.companyName.length ? el.companyName.length : total),
			0
		);
		if (longest_company_name > 40) longest_company_name = 40;
		let longest_name = sortedOtherPersons.reduce(
			(total, el) => (total < `${el.firstName} ${el.lastName}`.length ? `${el.firstName} ${el.lastName}`.length : total),
			0
		);
		if (longest_name > 50) longest_name = 50;
		let longest_email = sortedOtherPersons.reduce(
			(total, el) => (total < (el.email ? el.email.length : 0) ? el.email.length : total),
			0
		);
		if (longest_email > 40) longest_email = 40;

		const padString = '                                                                               ';

		const permission_length = 40;

		let allOptions = sortedOtherPersons.map(op => {
			const value = op.id;
			const name = op.firstName + ' ' + op.lastName; //.replace(/ /g, '@42@');
			const profile = op.profileName;
			const label = `${(op.companyName + padString).substring(0, longest_company_name).replace(/ /g, '\u00a0')} ${(
				name + padString
			)
				.substring(0, longest_name)
				.replace(/ /g, '\u00a0')} ${(profile + padString).substring(0, permission_length).replace(/ /g, '\u00a0')} ${(
				op.email + padString
			)
				.substring(0, longest_email)
				.replace(/ /g, '\u00a0')} ${op.datacenter}`.replace(/@42@/g, ' ');
			return {value: value, label: label, searchString: label.replace(/\u00a0/g, ' ')};
		});
		allOptions.unshift({
			value: 'none',
			label: `${('Company' + padString).substring(0, longest_company_name).replace(/ /g, '\u00a0')} ${('Name' + padString)
				.substring(0, longest_name)
				.replace(/ /g, '\u00a0')} ${('Permission' + padString)
				.substring(0, permission_length)
				.replace(/ /g, '\u00a0')} ${('Email' + padString)
				.substring(0, longest_email)
				.replace(/ /g, '\u00a0')} Datacenter`,
		});

		const visibleOptions = allOptions.slice(0, 1000);
		this.props.viewer.missionControlData.forecastPersons.forEach(p => {
			if (
				p.impersonatedPersonId !== null &&
				p.impersonatedPersonId !== 'UGVyc29uOg==' &&
				!visibleOptions.some(v => v.value === p.impersonatedPersonId)
			) {
				const option = allOptions.find(a => a.value === p.impersonatedPersonId);
				visibleOptions.push(option);
			}
		});

		this.state = {
			showAllPersons: false,
			searchString: '',
			maxResults: 1000,
			sortedOtherPersons,
			longest_company_name,
			longest_name,
			allOptions,
			visibleOptions,
			selectedCategory: null,
			showModal: false,
			reason: '',
		};
	}

	handleReasonChange(e) {
		const reason = e.target ? e.target.value : '';
		this.setState({reason: reason, categoryError: false, reasonError: false});
	}

	selectCategory(e) {
		this.setState({selectedCategory: e[0], categoryError: false, reasonError: false});
	}

	closeModal() {
		this.setState({showModal: false, categoryError: false, reasonError: false, selectedCategory: null, reason: ''});
	}

	onPersonSelected(personId, value) {
		if (value === null || value.value.includes('none')) {
			return;
		}
		const impersonatingPerson = this.state.sortedOtherPersons.find(p => p.id === value.value);
		this.setState({
			impersonatingPerson,
			showModal: true,
			personIdOfImpersonatingUser: personId,
			idOfPersonToImpersonate: value.value,
		});
	}

	confirmImpersonation() {
		// Validate that a category is selected and a reason given
		if (this.state.selectedCategory === null) {
			this.setState({categoryError: true});
			return;
		}
		if (this.state.reason === '' || this.state.reason === null) {
			this.setState({reasonError: true});
			return;
		}

		this.setState({showModal: false});

		this.impersonate(this.state.personIdOfImpersonatingUser, {value: this.state.idOfPersonToImpersonate});
	}

	impersonate(personId, value) {
		showLoader();
		Util.CommitMutation(
			UpdateImpersonatePersonMutation,
			{
				id: personId,
				impersonatePerson: value === null || value.value.includes('none') ? null : value.value,
				category: value === null || value.value.includes('none') ? null : this.state.selectedCategory,
				reason: value === null || value.value.includes('none') ? null : this.state.reason,
			},
			() => {
				this.props.retry();
			}
		);

		// Hubspot remove cookie
		document.cookie = 'hubspotutk=; Max-Age=-99999999;domain=.forecast.it;';
		document.cookie = '__hstc=; Max-Age=-99999999;domain=.forecast.it;';

		setTimeout(() => {
			const visibleOptions = cloneDeep(this.state.visibleOptions);
			this.props.viewer.missionControlData.forecastPersons.forEach(p => {
				if (
					p.impersonatedPersonId !== null &&
					p.impersonatedPersonId !== 'UGVyc29uOg==' &&
					!visibleOptions.some(v => v?.value === p.impersonatedPersonId)
				) {
					const option = this.state.allOptions.find(a => a.value === p.impersonatedPersonId);
					visibleOptions.push(option);
				}
			});

			this.setState({visibleOptions});
			this.closeModal();

			hideLoader();
		}, 4000);
	}

	searchChanged(searchString) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => this.actualSearch(searchString), 500);
	}

	actualSearch(searchString) {
		let options = this.state.allOptions;

		if (searchString !== '') {
			options = options.filter(option => {
				return searchString.trim() === '' || this.normalizedIncludes(option.searchString, searchString.trim());
			});
		}

		options = options.slice(0, this.state.maxResults);
		if (searchString === '') {
			this.props.viewer.missionControlData.forecastPersons.forEach(p => {
				if (
					p.impersonatedPersonId !== null &&
					p.impersonatedPersonId !== 'UGVyc29uOg==' &&
					!options.some(v => v.value === p.impersonatedPersonId)
				) {
					const option = this.state.allOptions.find(a => a.value === p.impersonatedPersonId);
					options.push(option);
				}
			});
		}
		this.setState({visibleOptions: options});
	}

	normalizedIncludes(origin, searched) {
		if (origin == null || searched == null) return false;

		return origin
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.includes(
				searched
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			);
	}

	render() {
		if (!this.props.viewer.missionControlData) {
			this.props.history.push('/not-found');
			return null;
		}

		if (!this.props.viewer.missionControlData.forecastPersons) {
			return null;
		}

		return (
			<div className="mission-control-list mission-control-impersonate">
				<MissionControlHeader viewer={this.props.viewer} />
				<h1>Impersonate another person</h1>
				<button onClick={() => this.setState({showAllPersons: !this.state.showAllPersons})}>Toggle all persons</button>
				<table>
					<tbody>
						{this.props.viewer.missionControlData.forecastPersons
							.filter(
								p =>
									this.state.showAllPersons ||
									this.props.viewer.actualPersonId === p.id ||
									p.impersonatedPersonId === this.props.viewer.actualPersonId
							)
							.map(p => {
								return (
									<tr key={p.id} className="people-list">
										<td className="name-row">
											<div style={{fontSize: '18px', fontWeight: 'bold'}}>
												{p.firstName} {p.lastName}
												<br />({p.email})
											</div>
										</td>
										<td>
											<div>Select person to impersonate</div>
											<Dropdown
												selectedItems={p.impersonatedPersonId ? [p.impersonatedPersonId] : []}
												name="Select"
												maxHeight={300}
												searchStringChangedCallback={searchString => this.searchChanged(searchString)}
												disabled={
													this.props.viewer.actualPersonId !== p.id //&&
													//p.impersonatedPersonId !== this.props.viewer.actualPersonId
												}
												onSelect={v => this.onPersonSelected(p.id, {value: v[0]})}
											>
												{this.state.visibleOptions.map((o, i) => {
													return (
														<Dropdown.SingleText
															key={i}
															value={o != null ? o.value : 'NA'}
															searchString={o != null ? o.searchString : 'NA'}
														>
															<div style={{fontFamily: 'monospace'}}>
																{o != null ? o.label : 'NA'}
															</div>
														</Dropdown.SingleText>
													);
												})}
											</Dropdown>
										</td>
										<td className="remove-row">
											{this.props.viewer.actualPersonId === p.id ||
											p.impersonatedPersonId === this.props.viewer.actualPersonId ? (
												<Button emphasis={'medium'} onPress={this.impersonate.bind(this, p.id, null)}>
													Clear Impersonation
												</Button>
											) : null}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
				{this.state.showModal && (
					<GenericModal
						closeModal={() => this.closeModal()}
						buttons={[
							{
								text: 'Cancel',
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
							{
								text: 'Confirm',
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.GREEN,
								callback: () => this.confirmImpersonation(),
								preventDefaultClose: true,
							},
						]}
						content={
							<div className="default-warning-modal">
								<h2>
									Impersonating {this.state.impersonatingPerson.firstName} from{' '}
									{this.state.impersonatingPerson.companyName}
								</h2>
								<div>Category</div>
								<div
									style={{
										margin: '4px 0',
										width: '200px',
										border: this.state.categoryError ? '1px solid red' : '',
									}}
								>
									<Dropdown
										width={200}
										name={'select something'}
										selectedItems={this.state.selectedCategory ? [this.state.selectedCategory] : []}
										onSelect={this.selectCategory.bind(this)}
									>
										<Dropdown.SingleText
											key={'Support case'}
											value={'Support case'}
											searchString={'Support case'}
										>
											Support case
										</Dropdown.SingleText>
										<Dropdown.SingleText
											key={'Consultancy'}
											value={'Consultancy'}
											searchString={'Consultancy'}
										>
											Consultancy
										</Dropdown.SingleText>
										<Dropdown.SingleText
											key={'(Bug) Troubleshooting'}
											value={'(Bug) Troubleshooting'}
											searchString={'(Bug) Troubleshooting'}
										>
											(Bug) Troubleshooting
										</Dropdown.SingleText>
										<Dropdown.SingleText
											key={'Performance'}
											value={'Performance'}
											searchString={'Performance'}
										>
											Performance
										</Dropdown.SingleText>
										<Dropdown.SingleText key={'Other'} value={'Other'} searchString={'Other'}>
											Other
										</Dropdown.SingleText>
									</Dropdown>
								</div>
								<br />
								<div>Reason:</div>
								<textarea
									style={{
										margin: '4px 0',
										padding: '4px',
										borderRadius: '4px',
										border: this.state.reasonError ? '1px solid red' : '1px solid rgb(229, 231, 235)',
									}}
									rows={5}
									cols={60}
									placeholder="Specify the reason for impersonating here"
									onChange={this.handleReasonChange.bind(this)}
								/>
							</div>
						}
						//headerText={'Impersonate person'}
					/>
				)}
			</div>
		);
	}
}

const impersonateQuery = graphql`
	query impersonate_Query {
		viewer {
			actualPersonId
			component(name: "control_impersonate")
			...impersonate_viewer
		}
	}
`;

export {impersonateQuery};

export default createFragmentContainer(withRouter(impersonate), {
	viewer: graphql`
		fragment impersonate_viewer on Viewer {
			id
			...missionControlHeader_viewer
			actualPersonId
			missionControlData {
				id
				forecastPersons {
					id
					firstName
					lastName
					impersonatedPersonId
					email
				}
				otherPersons(includeForecastPersons: true) {
					id
					firstName
					lastName
					email
					companyName
					profileName
					datacenter
				}
			}
		}
	`,
});
