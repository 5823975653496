import React from 'react';
import moment from 'moment';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import FocusModal, {FocusModalQuery} from './FocusModal';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../../constants';

const FocusModalRelayWrapper = props => {
	const projectId = props.projectId;
	const today = moment();
	const yesterday = today.clone().subtract(1, 'days');
	const thirtyDaysAgo = today.clone().subtract(30, 'days');

	const resourceSearchQuery = {
		filters: [{field: GLOBAL_FILTER_FIELD.PROJECT, operator: GLOBAL_FILTER_OPERATOR.IN, value: [projectId]}],
	};
	const timeRegSearchQuery = {
		filters: [
			{
				field: GLOBAL_FILTER_FIELD.START_DATE_TIME_REG,
				operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
				value: [thirtyDaysAgo.format('YYYY-MM-DD')],
			},
			{
				field: GLOBAL_FILTER_FIELD.END_DATE_TIME_REG,
				operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
				value: [yesterday.format('YYYY-MM-DD')],
			},
			{field: GLOBAL_FILTER_FIELD.PROJECT, operator: GLOBAL_FILTER_OPERATOR.IS, value: [projectId]},
		],
	};
	return (
		<ForecastQueryRenderer
			key="query-render-predicted-end-date-modal"
			query={FocusModalQuery}
			modal={true}
			variables={{
				projectId: projectId,
				resourceSearchQuery: resourceSearchQuery,
				timeRegSearchQuery: timeRegSearchQuery,
				startYear: thirtyDaysAgo.year(),
				startMonth: thirtyDaysAgo.month() + 1,
				startDay: thirtyDaysAgo.date(),
				endYear: yesterday.year(),
				endMonth: yesterday.month() + 1,
				endDay: yesterday.date(),
			}}
			render={relayProps => {
				return <FocusModal {...relayProps} {...props} />;
			}}
		/>
	);
};

export default FocusModalRelayWrapper;
