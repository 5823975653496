/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SkillRow_skill$ref: FragmentReference;
declare export opaque type SkillRow_skill$fragmentType: SkillRow_skill$ref;
export type SkillRow_skill = {|
  +id: string,
  +name: ?string,
  +skillPersons: ?$ReadOnlyArray<?{|
    +person: ?{|
      +id: string,
      +fullName: ?string,
      +active: ?boolean,
      +permissions: ?$ReadOnlyArray<?string>,
      +profilePictureId: ?string,
    |},
    +level: ?{|
      +id: string
    |},
  |}>,
  +$refType: SkillRow_skill$ref,
|};
export type SkillRow_skill$data = SkillRow_skill;
export type SkillRow_skill$key = {
  +$data?: SkillRow_skill$data,
  +$fragmentRefs: SkillRow_skill$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillRow_skill",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillPerson",
      "kind": "LinkedField",
      "name": "skillPersons",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "permissions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillLevel",
          "kind": "LinkedField",
          "name": "level",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Skill"
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b18e980e4484d326a58beb7e51ea8a7';

module.exports = node;
