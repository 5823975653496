/**
 * Login container.
 */
import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import ResetPasswordMutation from '../mutations/reset_password_mutation';
import VerifyResetKeyMutation from '../mutations/verify_reset_key_mutation';
import * as tracking from '../tracking';
import {FormattedMessage, injectIntl} from 'react-intl';
import Util from '../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE} from '../constants';
import Button from '../forecast-app/shared/components/buttons/button/button';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';
import {isSageSpecificUrl} from '../forecast-app/shared/util/UrlUtil';
import {SageResetPassword} from './sage-signup-flow/SageResetPassword';

class resetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			loggedIn: this.props.viewer.actualPersonId,
			showContent: false,
			isKeyValid: false,
			newPassword: '',
			confirmPassword: '',
			showPasswordHelp: false,
			loading: false,
		};

		if (this.props.viewer.actualPersonId) {
			this.props.history.push('/');
		}
		this.props.setLocaleFromBrowser();

		this.superPropertyChecksum = trackPage('Reset Password');
	}

	componentDidMount() {
		tracking.trackPage('reset');

		if (this.state.loggedIn) return;
		const onSuccess = e => {
			this.setState({showContent: true, isKeyValid: e.verifyResetKey.isKeyValid});
		};
		Util.CommitMutation(
			VerifyResetKeyMutation,
			{
				key: this.props.match.params.key,
			},
			onSuccess
		);
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSubmit(event) {
		if (this.state.loading) {
			return;
		}

		const {formatMessage} = this.props.intl;
		if (event) {
			event.preventDefault();
		}

		const password = this.state.newPassword,
			confirmPassword = this.state.confirmPassword;

		if (!password.length || !confirmPassword.length) {
			this.setState({errorMsg: 'login.all-fields-must-be-filled'});
			return;
		}
		if (password !== confirmPassword) {
			this.setState({errorMsg: 'login.passwords-do-not-match'});
			return;
		}

		let passwordCheck = Util.validatePassword(this.state.newPassword);

		if (!passwordCheck.valid) {
			this.setState({
				errorMsg: formatMessage({id: 'user-settings.security.password_validation_error.' + passwordCheck.error}),
			});
			return;
		}

		this.setState({
			loading: true,
		});

		var onSuccess = response => {
			if (response.resetPassword.success) {
				window.location.href = '/';
			} else {
				this.setState({
					loading: false,
				});
				if (!response.resetPassword.errors) return;
				const error = response.resetPassword.errors[0];
				if (error === 'RESET_LINK_EXPIRED') {
					this.setState({errorMsg: 'reset_password.RESET_LINK_EXPIRED'});
				} else {
					this.setState({
						errorMsg: 'user-settings.security.password_validation_error.' + response.resetPassword.errors[0],
					});
				}
			}
		};

		Util.CommitMutation(
			ResetPasswordMutation,
			{
				password: password,
				key: this.props.match.params.key,
			},
			onSuccess
		);
	}

	onPasswordFocus() {
		this.setState({showPasswordHelp: true});
	}

	onPasswordBlur() {
		if (Util.validatePassword(this.state.newPassword).valid) this.setState({showPasswordHelp: false});
	}

	setNewPassword(password) {
		this.setState({newPassword: password});
	}

	handlePasswordChange(e) {
		this.setNewPassword(e.target.value);
	}

	setConfirmPassword(password) {
		this.setState({confirmPassword: password});
	}

	handleConfirmPasswordChange(e) {
		this.setConfirmPassword(e.target.value);
	}

	render() {
		const {formatMessage} = this.props.intl;
		//Do not display anything if user will be redirected or is still waiting for key verification
		if (this.state.loggedIn || !this.state.showContent) return null;

		if (isSageSpecificUrl()) {
			return (
				<SageResetPassword
					password={this.state.newPassword}
					confirmPassword={this.state.confirmPassword}
					onPasswordChange={this.setNewPassword.bind(this)}
					onPasswordConfirmChange={this.setConfirmPassword.bind(this)}
					onSetPassword={this.handleSubmit.bind(this)}
					isKeyValid={this.state.isKeyValid}
					errorMsg={this.state.errorMsg}
				/>
			);
		} else {
			return (
				<div className="content">
					{this.state.isKeyValid ? (
						<form onSubmit={this.handleSubmit.bind(this)}>
							<h1>{formatMessage({id: 'login.set-new-password-title'})}</h1>

							<label htmlFor="password">{formatMessage({id: 'login.new-password'})}</label>
							<div className="input-bubble-wrapper">
								<input
									type="password"
									id="password"
									ref={input => (this.password = input)}
									defaultValue=""
									placeholder={formatMessage({id: 'login.new-password'})}
									onFocus={this.onPasswordFocus.bind(this)}
									onBlur={this.onPasswordBlur.bind(this)}
									onChange={this.handlePasswordChange.bind(this)}
								/>
								<div
									ref={div => (this.passwordBubble = div)}
									className={
										'bubble left' +
										(this.state.showPasswordHelp ? ' show' : '') +
										(Util.validatePassword(this.state.newPassword).valid ? ' valid' : '')
									}
									style={{top: '-60px', left: '400px'}}
								>
									{Util.getPasswordHelpText(this.state.newPassword, this.state.confirmPassword)}
								</div>
							</div>

							<label htmlFor="confirm-password">{formatMessage({id: 'login.confirm-password'})}</label>
							<input
								type="password"
								id="confirm-password"
								ref={input => (this.confirmPassword = input)}
								defaultValue=""
								placeholder={formatMessage({id: 'login.confirm-password'})}
								onFocus={this.onPasswordFocus.bind(this)}
								onBlur={this.onPasswordBlur.bind(this)}
								onChange={this.handleConfirmPasswordChange.bind(this)}
							/>

							{this.state.errorMsg ? (
								<div className="error">
									<FormattedMessage id={this.state.errorMsg} />
								</div>
							) : (
								''
							)}

							<div className="buttons single-button">
								<Button
									id="reset-password-submit-button"
									text={formatMessage({id: 'login.set-password'})}
									cy="button-reset-password-submit"
									buttonStyle={BUTTON_STYLE.FILLED}
									colorTheme={BUTTON_COLOR.PINK}
									onClick={this.handleSubmit.bind(this)}
									uppercase={false}
									roundedBorders={true}
								/>
							</div>
						</form>
					) : (
						<form>
							<h1>{formatMessage({id: 'login.reset-password'})}</h1>
							<div className="fieldset">
								<div className="error">
									<FormattedMessage id={'reset_password.invalid-reset-link-1'} />{' '}
									<a href="/forgot-password">
										<FormattedMessage id={'reset_password.invalid-reset-link-2'} />
									</a>
								</div>
							</div>
						</form>
					)}
				</div>
			);
		}
	}
}

const resetPasswordQuery = graphql`
	query resetPassword_Query {
		viewer {
			component(name: "reset_password")
			...resetPassword_viewer
		}
	}
`;

export {resetPasswordQuery};

export default createFragmentContainer(injectIntl(withRouter(resetPassword)), {
	viewer: graphql`
		fragment resetPassword_viewer on Viewer {
			id
			actualPersonId
			lastName
		}
	`,
});
