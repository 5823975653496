import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import qs from 'query-string';
import * as tracking from '../tracking';
import {injectIntl} from 'react-intl';
import Util from '../forecast-app/shared/util/util';
import CreateOktaVerificationKeyMutation from '../mutations/create_okta_verification_key_mutation';
import oktaLogo from '../images/integrations/okta-logo.png';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class loginOkta extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			verifying: false,
		};
		this.props.setLocaleFromBrowser();

		this.superPropertyChecksum = trackPage('Login - Okta');
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.actualPersonId !== null) {
			this.props.history.push({
				pathname: '/',
			});
			return;
		}

		if (qs.parse(this.props.location.search).iss) {
			this.setState({verifying: true});
			const onSuccess = ({createOktaVerificationKey}) => {
				if (createOktaVerificationKey.key === null) {
					this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
					return;
				}
				const redirectUri = process.env.OKTA_REDIRECT_URI;
				window.location.href = `https://${createOktaVerificationKey.oktaUrl}.okta.com/oauth2/v1/authorize?client_id=${createOktaVerificationKey.oktaClientId}&response_type=code&scope=openid email profile&redirect_uri=${redirectUri}&state=${createOktaVerificationKey.key}`;
			};

			Util.CommitMutation(
				CreateOktaVerificationKeyMutation,
				{
					issuer: qs.parse(this.props.location.search).iss,
				},
				onSuccess
			);
		}
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('login-okta');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	oktalogin(event) {
		const onSuccess = ({createOktaVerificationKey}) => {
			if (createOktaVerificationKey.key === null) {
				this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
				return;
			}
			const redirectUri = process.env.OKTA_REDIRECT_URI;
			window.location.href = `https://${createOktaVerificationKey.oktaUrl}.okta.com/oauth2/v1/authorize?client_id=${createOktaVerificationKey.oktaClientId}&response_type=code&scope=openid email profile&redirect_uri=${redirectUri}&state=${createOktaVerificationKey.key}`;
		};

		if (event) {
			event.preventDefault();
		}

		const email = this.refs.email.value.trim();

		if (!email.length) {
			this.setState({errorMsg: 'All fields must be filled'});
			return;
		}

		this.setState({verifying: true});

		Util.CommitMutation(
			CreateOktaVerificationKeyMutation,
			{
				email: email,
			},
			onSuccess
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="fullscreen fullscreen-centered-outer login login-bg">
				{this.state.verifying ? <UploadingOverlay /> : null}
				<div className="logo" />
				<div className="fullscreen-centered-inner">
					<div className="fullscreen-centered-content form-vertical">
						<form>
							<h1>{formatMessage({id: 'login.title'})}</h1>
							<div className="fieldset">
								<input
									type="email"
									name="username"
									ref="email"
									placeholder={formatMessage({id: 'login.enter-email-address'})}
								/>
								{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
							</div>
							<div className="external-login">
								<button className="okta-login-button" onClick={this.oktalogin.bind(this)}>
									{formatMessage({id: 'login.login_with'})} <img src={oktaLogo} width="50" alt="Okta logo" />
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const loginOktaQuery = graphql`
	query loginOkta_Query {
		viewer {
			component(name: "login_okta")
			...loginOkta_viewer
		}
	}
`;

export {loginOktaQuery};

export default createFragmentContainer(injectIntl(withRouter(loginOkta)), {
	viewer: graphql`
		fragment loginOkta_viewer on Viewer {
			id
			actualPersonId
		}
	`,
});
