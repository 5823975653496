import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import GridLayout from '../../../components/insights/grid_layout';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import CreateInsightMutation from '../../../mutations/create_insight_mutation';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';

class NewInsightStep3 extends Component {
	constructor(props) {
		super(props);
		// If we are trying to load step 3 without a category, jump to step 1
		if (props.category === null) this.props.history.push('/reports/new/step1');

		this.state = {
			components: props.chosenComponents,
		};
	}

	onLayoutChange(layout) {
		// Update state components with values from the new layout
		const components = this.state.components;
		for (let i = 0; i < components.length; i++) {
			for (let j = 0; j < layout.length; j++) {
				if (components[i].i === layout[j].i) {
					components[i].x = layout[j].x;
					components[i].y = layout[j].y;
					components[i].w = layout[j].w;
					components[i].h = layout[j].h;
					break;
				}
			}
		}
		// Save state
		this.setState({components});
	}

	removeComponent(id) {
		// Filter the removed component out of state components
		const components = this.state.components.filter(component => {
			return component.i !== id;
		});
		// Save state
		this.setState({components});
	}

	cancel() {
		this.props.history.go(-3);
	}

	updateComponentConfiguration(id, configuration) {
		// Find the component in state with the given ID and update it's configuration.
		const components = this.state.components;
		for (let i = 0; i < components.length; i++) {
			if (components[i].id === id || components[i].i === id) {
				components[i].config = JSON.stringify(configuration);
				this.setState({components});
				break;
			}
		}
	}

	createInsight() {
		const components = [];

		for (let i = 0; i < this.state.components.length; i++) {
			const component = this.state.components[i];
			components.push({
				id: component.id,
				componentName: component.componentName,
				x: component.x,
				y: component.y,
				w: component.w,
				h: component.h,
				config: component.config,
			});
		}

		const onSuccess = result => {
			let message = '';
			switch (this.props.category) {
				case 'Projects':
					message = this.props.intl.formatMessage({id: 'new_report.report-created-project'});
					break;
				case 'People':
					message = this.props.intl.formatMessage({id: 'new_report.report-created-person'});
					break;
				case 'Business':
					message = this.props.intl.formatMessage({id: 'new_report.report-created-client'});
					break;
				default:
					message = this.props.intl.formatMessage({id: 'new_report.report-created'});
					break;
			}
			this.props.history.push('/reports/' + result.createInsight.insight.node.id);
			createToast({duration: 5000, message: message});
		};

		Util.CommitMutation(
			CreateInsightMutation,
			{
				companyId: this.props.companyId,
				name: this.props.name,
				category: this.props.category,
				components: components,
			},
			onSuccess,
			true
		);
	}

	setScrollElement(element) {
		this.setState({scrollElement: element});
	}

	render() {
		return (
			<div className="new-insight">
				<CustomScrollDiv setScrollbarsRef={this.setScrollElement.bind(this)}>
					<div className="container">
						<div className="new-insight-step3">
							<div className="main-container">
								<div className="new-insight-header">
									<button className="back-button" onClick={this.props.history.goBack} />
									<h1>
										<FormattedMessage id="new_insight" />
									</h1>
									<button className="cancel-button" onClick={this.cancel.bind(this)} />
								</div>
								<h4 className="new-insight-step">
									<FormattedMessage id="new_insight.step3" />
								</h4>
								<h2 className="new-insight-description">
									<FormattedMessage
										id="new_insight.step3.description"
										values={{insightName: this.props.name}}
									/>
								</h2>
								{this.state.scrollElement ? (
									<div className="insight-container" data-cy="insight-container">
										<GridLayout
											canViewFinancial={this.props.hasFinancials}
											labelsOptions={this.props.labelsOptions}
											skillsOptions={this.props.skillsOptions}
											clientsOptions={this.props.clientsOptions}
											components={this.state.components}
											editMode={true}
											onLayoutChange={this.onLayoutChange.bind(this)}
											removeComponent={this.removeComponent.bind(this)}
											updateComponentConfiguration={this.updateComponentConfiguration.bind(this)}
											scrollElement={this.state.scrollElement}
											availableFeatureFlags={this.props.availableFeatureFlags}
										/>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</CustomScrollDiv>
				<div className="footer">
					<button
						className="create-insight-button"
						onClick={this.createInsight.bind(this)}
						data-cy="create-insight-button"
					>
						<FormattedMessage id="new_insight.button.create" />
					</button>
				</div>
			</div>
		);
	}
}

export default injectIntl(withRouter(NewInsightStep3));
