import DirectApi from '../../../directApi';

export const getHolidayCalendar = (year, selectedCountry, mutation) => {
	DirectApi.Fetch('import-holiday-calendar?year=' + year + '&country=' + selectedCountry).then(json => {
		const jsonArray = Object.values(json)
			.map(h => h[0])
			.filter(h => h !== undefined);
		const holidayCalendarEntries = [];
		for (const holidayEntry of jsonArray) {
			const {observed, name} = holidayEntry;

			holidayCalendarEntries.push({
				name,
				year: parseInt(observed.substr(0, 4), 10),
				month: parseInt(observed.substr(5, 7), 10),
				day: parseInt(observed.substr(8, 10), 10),
			});
		}
		mutation(holidayCalendarEntries);
	});
};
