import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {PROJECT_STATUS, WorkflowCategories} from '../../../../constants';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../../forecast-app/shared/util/util';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import {TASKS_OPTIONS} from '../../../modal/CloseProjectModal';
import {createToast} from '../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {ButtonDropdownField, FlexRow, DropdownList, Text} from '@forecast-it/design-system';
import {DesignSystemDropdownOldLookAdapter} from '../../../../forecast-app/shared/components/dropdowns/DesignSystemDropdownOldLookAdapter';
import {updateProjectOnSuccess} from '../../project_settings/ProjectSettingsMutations';

const changeStage = (projectId, stage) => {
	const project = {id: projectId};

	Util.CommitSchedulingModalUpdate(
		UpdateProjectMutation,
		{
			project: project,
			status: stage,
		},
		res => {
			updateProjectOnSuccess(res);
			createToast({duration: 3000, message: 'Project stage updated'});
		}
	);
};

const changeTasksColumn = (notDoneIds, statusColumnId, statusColumnCategory) => {
	let chunkArray = Util.chunkArray(notDoneIds, 100);
	chunkArray.forEach(chunkIds => {
		Util.CommitMutation(UpdateTaskMutation, {
			ids: chunkIds,
			statusColumnId: statusColumnId,
			statusColumnCategory: statusColumnCategory,
		});
	});
};

const ProjectStageDropdown = ({viewer}) => {
	const intl = useIntl();

	const project = viewer.project;
	const projectId = project.id;
	const projectTasks = project.tasks.edges;
	const projectColumns = project.statusColumnsV2.edges;

	const hasOpportunityAccess = Util.hasOpportunityAccess(viewer.company.modules);
	const stages = Util.getProjectStageForDropdown(intl, hasOpportunityAccess);

	const handleUnfinishedWork = (option, statusColumnId, notDoneTasks) => {
		switch (option) {
			case TASKS_OPTIONS.DONE:
				const notDoneIds = notDoneTasks.map(task => task.node.id);
				changeTasksColumn(notDoneIds, statusColumnId, 'DONE');
				changeStage(projectId, PROJECT_STATUS.DONE);
				break;
			case TASKS_OPTIONS.STAY:
				changeStage(projectId, PROJECT_STATUS.DONE);
				break;
			default:
				break;
		}
	};

	const handleChangeStage = stage => {
		if (stage) {
			if (stage === PROJECT_STATUS.DONE) {
				const notDoneTasks = projectTasks.filter(task => task.node.statusColumnV2.category !== PROJECT_STATUS.DONE);
				if (notDoneTasks.length > 0) {
					const doneColumns = projectColumns
						.filter(col => col.node.category === WorkflowCategories.DONE)
						.sort((a, b) => a.node.order - b.node.order)
						.map(col => ({
							value: col.node.id,
							label: col.node.name,
							logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
						}));
					showModal({
						type: MODAL_TYPE.CLOSE_PROJECT,
						tasksList: notDoneTasks,
						doneColumns: doneColumns,
						callback: (option, statucColumnId) => handleUnfinishedWork(option, statucColumnId, notDoneTasks),
					});
				} else {
					changeStage(projectId, stage);
				}
			} else {
				changeStage(projectId, stage);
			}
		}
	};

	return (
		<>
			<DesignSystemDropdownOldLookAdapter>
				<DropdownList>
					<DropdownList.Trigger data-cy={'settings-project-stage'}>
						<ButtonDropdownField>
							{!!project.status ? (
								<Text>{stages.find(stage => stage.value === project.status)?.label}</Text>
							) : (
								<Text color={Text.Color.auxiliary}>{intl.formatMessage({id: 'settings.project-stage'})}</Text>
							)}
						</ButtonDropdownField>
					</DropdownList.Trigger>
					<div style={{zIndex: 1000}}>
						<DropdownList.Content align={'start'}>
							{stages.map(stage => (
								<DropdownList.Item
									key={stage.value}
									data-cy={stage.cy}
									onClick={() => handleChangeStage(stage.value)}
								>
									<FlexRow>
										<Text>{stage.label}</Text>
									</FlexRow>
								</DropdownList.Item>
							))}
						</DropdownList.Content>
					</div>
				</DropdownList>
			</DesignSystemDropdownOldLookAdapter>
		</>
	);
};

const ProjectStageDropdownQuery = graphql`
	query ProjectStageDropdown_Query($projectId: ID) {
		viewer {
			component(name: "project_stage__dropdown")
			actualPersonId
			id
			...ProjectStageDropdown_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {ProjectStageDropdownQuery};

export default createFragmentContainer(ProjectStageDropdown, {
	viewer: graphql`
		fragment ProjectStageDropdown_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			company {
				modules {
					moduleType
				}
			}
			project(internalId: $projectId) {
				id
				status
				tasks(first: 100000) {
					edges {
						node {
							id
							name
							companyTaskId
							statusColumnV2 {
								name
								category
							}
						}
					}
				}
				statusColumnsV2(first: 1000000) {
					edges {
						node {
							id
							category
							name
						}
					}
				}
			}
		}
	`,
});
