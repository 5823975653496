import React, {useRef} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import Warning from '../../components/warning';
import {showModal, MODAL_TYPE} from '../../forecast-app/shared/components/modals/generic_modal_conductor';

const confirmPasswordModal = ({
	closeModal,
	mutation,
	schedulingCreatePerson,
	mutationName,
	data,
	onSuccess,
	onFailure,
	defaultCallback,
}) => {
	const inputRef = useRef(null);
	const intl = useIntl();

	const onMutationSuccess = result => {
		if (result[mutationName].errors && result[mutationName].errors.length > 0) {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div className="default-warning-modal">
						<Warning messageId="common.error_colon" />
						<div className="warning-part-2">{result[mutationName].errors[0]}</div>
					</div>
				),
				buttons: [
					{
						text: intl.formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
			if (onFailure) {
				onFailure(result);
			}
		} else if (onSuccess) {
			onSuccess(result);
		}
	};

	const runMutation = () => {
		const modifiedData = {adminPassword: inputRef.current.value, ...data};
		if (mutation) {
			Util.CommitMutation(mutation, modifiedData, onMutationSuccess);
		} else if (schedulingCreatePerson) {
			schedulingCreatePerson(modifiedData, onMutationSuccess);
		}
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			closeModal();
			runMutation();
		}
	};

	const content = (
		<div className="modal-check-password">
			<div className="modal-check-password-description">
				<FormattedMessage id="modal.check_password.description" />
			</div>
			<div className="input-container-v2">
				<div className="input-value-container">
					<input
						data-cy="confirm-password-input"
						className="input-value"
						type="password"
						ref={inputRef}
						autoComplete="new-password"
						autoFocus={true}
						style={{width: '350px'}}
						onKeyPress={handleKeyPress}
					/>
				</div>
			</div>
		</div>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.ok'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: runMutation,
				},
			]}
			headerText={intl.formatMessage({id: 'modal.confirm_password'})}
			content={content}
			defaultCallback={defaultCallback}
		/>
	);
};
PropTypes.confirmPasswordModal = {
	closeModal: PropTypes.func.isRequired,
	mutation: PropTypes.object,
	mutationName: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onFailure: PropTypes.func,
	schedulingCreatePerson: PropTypes.func,
	defaultCallback: PropTypes.func,
};

export default confirmPasswordModal;
