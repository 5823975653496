import {createFragmentContainer, graphql} from 'react-relay';
import React from 'react';

import {useIntl} from 'react-intl';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../constants';
import {addExpensesAmount, createInvoiceProjectObject} from '../../../../components/insights/invoicing/InvoicingUtil';
import styled from 'styled-components';
import {DateRangePicker, deprecatedTheme as theme, FlexColumn, Heading} from '@forecast-it/design-system';
import Util from '../../../../forecast-app/shared/util/util';
import {isoDateToMomentNonUtc} from '../../../../forecast-app/shared/util/DateUtil';

const LightTable = styled.table`
	width: 1000px;
	${theme.font.base.regular}
	color: ${theme.colors.text.primary};
	border: 1px solid ${theme.colors.border.primary.default};
	td,
	th {
		padding: ${theme.spacing.s} ${theme.spacing.m} ${theme.spacing.s} ${theme.spacing.m};
		border: 1px solid ${theme.colors.border.primary.default};
	}
	th {
		${theme.font.base.bold}
		color: ${theme.colors.text.primary};
		font-size: 16px;
		text-align: left;
	}
	tr {
		border: 1px solid ${theme.colors.border.primary.default};
		height: 70px;
		font-size: 16px;
	}
`;

const ProjectInvoicingUninvoicedItemsTableSection = ({project, startDate, endDate, onChangeDateInterval}) => {
	const intl = useIntl();
	const {formatMessage} = useIntl();
	const currency = project.rateCard?.currency;

	const startDateMoment = isoDateToMomentNonUtc(startDate);
	const endDateMoment = isoDateToMomentNonUtc(endDate);

	let invoiceProject = createInvoiceProjectObject(project, currency);

	// Pass start and end dates if needed
	addExpensesAmount(invoiceProject, project, startDateMoment, endDateMoment);

	return (
		<FlexColumn flex={'0 0 auto'} gap={'xl'}>
			<Heading>
				{formatMessage({
					id:
						project.budgetType === BUDGET_TYPE.RETAINER
							? 'invoicing.locked_periods_and_expenses'
							: 'invoicing.uninvoiced_time_registrationsand_expenses',
				})}
			</Heading>
			{project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS && (
				<DateRangePicker
					label={formatMessage({id: 'common.dates'})}
					startDate={startDate}
					endDate={endDate}
					onChange={onChangeDateInterval}
					offset={'l'}
				/>
			)}

			<LightTable>
				<thead>
					<tr>
						{project.defaultPeriodBudgetType !== PERIOD_BUDGET_TYPE.FIXED_PRICE && (
							<th>
								{formatMessage({
									id:
										project.budgetType === BUDGET_TYPE.RETAINER
											? 'common.uninvoiced_locked_periods'
											: 'common.uninvoiced_time_registrations',
								})}{' '}
								({formatMessage({id: 'common.time'})})
							</th>
						)}
						<th>
							{formatMessage({
								id:
									project.budgetType === BUDGET_TYPE.RETAINER
										? 'common.uninvoiced_locked_periods'
										: 'common.uninvoiced_time_registrations',
							})}
						</th>
						<th>{formatMessage({id: 'common.uninvoiced_expenses'})}</th>
						<th>{formatMessage({id: 'common.total_uninvoiced'})}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						{project.defaultPeriodBudgetType !== PERIOD_BUDGET_TYPE.FIXED_PRICE && (
							<td>{Util.convertMinutesToFullHour(project.unInvoicedTotal.unInvoicedMinutes, intl)}</td>
						)}
						<td data-cy={'uninvoiced-time'}>
							{Util.getFormattedNumberWithCurrency(
								Util.GetCurrencySymbol(currency),
								project.unInvoicedTotal.unInvoicedPrice,
								intl
							)}
						</td>
						<td data-cy={'uninvoiced-expenses'}>
							{Util.getFormattedNumberWithCurrency(
								Util.GetCurrencySymbol(currency),
								invoiceProject.uninvoicedExpenses,
								intl
							)}
						</td>
						<td style={{backgroundColor: '#E5F4FF', fontWeight: 'bold'}} data-cy={'uninvoiced-total'}>
							{Util.getFormattedNumberWithCurrency(
								Util.GetCurrencySymbol(currency),
								project.unInvoicedTotal.unInvoicedPrice + invoiceProject.uninvoicedExpenses,
								intl
							)}
						</td>
					</tr>
				</tbody>
			</LightTable>
		</FlexColumn>
	);
};

// Start and end dates are hardocoded at the moment
export default createFragmentContainer(ProjectInvoicingUninvoicedItemsTableSection, {
	project: graphql`
		fragment ProjectInvoicingUninvoicedItemsTableSection_project on ProjectType
		@argumentDefinitions(
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
		) {
			id
			unInvoicedTotal(
				startYear: $startYear
				startMonth: $startMonth
				startDay: $startDay
				endYear: $endYear
				endMonth: $endMonth
				endDay: $endDay
			) {
				unInvoicedMinutes
				unInvoicedPrice
			}
			name
			rateCard {
				currency
			}
			budget
			budgetType
			defaultPeriodBudgetType

			expenseItems(first: 10000) @connection(key: "Project_expenseItems") {
				edges {
					node {
						approved
						billable
						partOfFixedPrice
						expenseYear
						expenseMonth
						expenseDay
						price
						cost
						quantity
						invoiced
					}
				}
			}
		}
	`,
});
