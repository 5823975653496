import Styled from 'styled-components';
import { FlexColumn } from '@forecast-it/design-system';
// These ignore lines are needed to make the typescript compiler happy (try to remove them if we upgrade typescript or bundler)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GradientBackground from '../../images/GradientBackground.svg';
export const Page = Styled(FlexColumn) `
  height: 100vh;
`;
export const Content = Styled(FlexColumn) `
	background: #FFF;
	background-image: url(${GradientBackground});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: bottom;
	background-position-x: center;
  gap: 24px;
  margin-top: 24px;
`;
export const PageContent = Styled(FlexColumn) `
    display: flex;
    width: 528px;
    padding: 48px;
    border-radius: 8px;
	  background: #ffffff;
	  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
	  margin-bottom: 68px;
`;
