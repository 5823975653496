/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectDropdown_projects$ref = any;
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type timerModalV3_viewer$ref: FragmentReference;
declare export opaque type timerModalV3_viewer$fragmentType: timerModalV3_viewer$ref;
export type timerModalV3_viewer = {|
  +id: string,
  +harvestUser: ?boolean,
  +language: ?LANGUAGE,
  +actualPersonId: ?string,
  +timerStartDate: ?string,
  +timerEndDate: ?string,
  +excludeFromCompanyLockedPeriod: ?boolean,
  +submitLockedDateYear: ?number,
  +submitLockedDateMonth: ?number,
  +submitLockedDateDay: ?number,
  +startDate: ?string,
  +endDate: ?string,
  +createdAt: ?string,
  +monday: ?number,
  +tuesday: ?number,
  +wednesday: ?number,
  +thursday: ?number,
  +friday: ?number,
  +saturday: ?number,
  +sunday: ?number,
  +task: ?{|
    +id: string,
    +companyTaskId: ?number,
    +jiraId: ?string,
    +name: ?string,
    +timeLeft: ?number,
    +billable: ?boolean,
    +parentTaskId: ?string,
    +estimateForecastMinutes: ?number,
    +totalMinutesRegistered: ?number,
    +startDay: ?number,
    +startMonth: ?number,
    +startYear: ?number,
    +deadlineDay: ?number,
    +deadlineMonth: ?number,
    +deadlineYear: ?number,
    +project: ?{|
      +id: string,
      +companyProjectId: ?number,
      +status: ?PROJECT_STATUS,
      +name: ?string,
      +billable: ?boolean,
      +projectColor: ?string,
      +projectStartYear: ?number,
      +projectStartMonth: ?number,
      +projectStartDay: ?number,
      +projectEndYear: ?number,
      +projectEndMonth: ?number,
      +projectEndDay: ?number,
      +harvestProject: ?{|
        +id: string
      |},
      +unit4Project: ?{|
        +id: string,
        +activitiesEnabled: ?boolean,
      |},
      +projectPerson: ?{|
        +role: ?{|
          +id: string,
          +name: ?string,
        |}
      |},
    |},
    +role: ?{|
      +id: string,
      +name: ?string,
    |},
  |},
  +timerTask: ?{|
    +id: string,
    +companyTaskId: ?number,
    +startYear: ?number,
    +startMonth: ?number,
    +startDay: ?number,
    +deadlineDay: ?number,
    +deadlineMonth: ?number,
    +deadlineYear: ?number,
    +name: ?string,
    +timeLeft: ?number,
    +billable: ?boolean,
    +estimateForecastMinutes: ?number,
    +totalMinutesRegistered: ?number,
    +role: ?{|
      +id: string
    |},
    +assignedPersons: ?$ReadOnlyArray<?{|
      +id: string
    |}>,
    +statusColumnV2: ?{|
      +category: ?STATUS_CATEGORY
    |},
    +project: ?{|
      +id: string,
      +companyProjectId: ?number,
      +status: ?PROJECT_STATUS,
      +name: ?string,
      +billable: ?boolean,
      +projectColor: ?string,
      +projectStartYear: ?number,
      +projectStartMonth: ?number,
      +projectStartDay: ?number,
      +projectEndYear: ?number,
      +projectEndMonth: ?number,
      +projectEndDay: ?number,
      +harvestProject: ?{|
        +id: string
      |},
      +projectPerson: ?{|
        +role: ?{|
          +id: string,
          +name: ?string,
        |}
      |},
    |},
  |},
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +id: string,
    +bambooHREnabled: ?boolean,
    +harvestEnabled: ?boolean,
    +characterLimit: ?number,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +idleTimes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isInternalTime: ?boolean,
          +disabled: ?boolean,
        |}
      |}>
    |},
    +person: ?{|
      +id: string,
      +role: ?{|
        +id: string
      |},
    |},
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +companyProjectId: ?number,
        +isProgramRevenueLocked: ?boolean,
        +programBudgetType: ?PROGRAM_BUDGET_TYPE,
        +billable: ?boolean,
        +projectStartYear: ?number,
        +projectStartMonth: ?number,
        +projectStartDay: ?number,
        +projectEndYear: ?number,
        +projectEndMonth: ?number,
        +projectEndDay: ?number,
        +manualProgressOnProjectEnabled: ?boolean,
        +manualProgressOnPhasesEnabled: ?boolean,
        +manualProgressOnTasksEnabled: ?boolean,
        +status: ?PROJECT_STATUS,
        +projectColor: ?string,
        +harvestProject: ?{|
          +id: string
        |},
        +projectPerson: ?{|
          +role: ?{|
            +id: string,
            +name: ?string,
          |}
        |},
      |},
      +$fragmentRefs: ProjectDropdown_projects$ref,
    |}>
  |},
  +$refType: timerModalV3_viewer$ref,
|};
export type timerModalV3_viewer$data = timerModalV3_viewer;
export type timerModalV3_viewer$key = {
  +$data?: timerModalV3_viewer$data,
  +$fragmentRefs: timerModalV3_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimateForecastMinutes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalMinutesRegistered",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v22 = [
  (v0/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "HarvestProjectType",
  "kind": "LinkedField",
  "name": "harvestProject",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v24/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "personId",
      "variableName": "personId"
    }
  ],
  "concreteType": "ProjectPerson",
  "kind": "LinkedField",
  "name": "projectPerson",
  "plural": false,
  "selections": [
    (v25/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v28 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "taskId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "timerModalV3_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "harvestUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludeFromCompanyLockedPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tuesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wednesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thursday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saturday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sunday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "taskId"
        }
      ],
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraId",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentTaskId",
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v23/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Unit4ProjectType",
              "kind": "LinkedField",
              "name": "unit4Project",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activitiesEnabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v26/*: any*/)
          ],
          "storageKey": null
        },
        (v25/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "timerTask",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v9/*: any*/),
        (v8/*: any*/),
        (v7/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v27/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "assignedPersons",
          "plural": true,
          "selections": (v22/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusColumnV2",
          "kind": "LinkedField",
          "name": "statusColumnV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v23/*: any*/),
            (v26/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bambooHREnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "characterLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "includeDisabled",
              "value": false
            }
          ],
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v24/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(includeDisabled:false)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v28/*: any*/)
          ],
          "concreteType": "IdleTimeTypeConnection",
          "kind": "LinkedField",
          "name": "idleTimes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTimeTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isInternalTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "disabled",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "idleTimes(first:10000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "personId"
            }
          ],
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v27/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeDoneOrHalted",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "excludeReadOnly",
          "value": true
        },
        (v28/*: any*/)
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v13/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isProgramRevenueLocked",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "programBudgetType",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manualProgressOnProjectEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manualProgressOnPhasesEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manualProgressOnTasksEnabled",
                  "storageKey": null
                },
                (v14/*: any*/),
                (v15/*: any*/),
                (v23/*: any*/),
                (v26/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectDropdown_projects"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(excludeDoneOrHalted:true,excludeReadOnly:true,first:10000)"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '837c30e337be7fdb9e9cf29a71955512';

module.exports = node;
