import React from 'react';
import {Button, BUTTON_COLOR, BUTTON_STYLE} from '../../../../components/new-ui/ButtonV2';

export const ActionsColumnExplain = ({actionText}) => {
	return <Button buttonColor={BUTTON_COLOR.TEXT_PURPLE} buttonStyle={BUTTON_STYLE.TEXT} content={actionText || 'See More'} />;
};

export const ActionsColumnAssist = ({assistText, onClick}) => {
	return (
		<Button
			onClick={e => onClick(e)}
			buttonColor={BUTTON_COLOR.PURPLE}
			buttonStyle={BUTTON_STYLE.FILLED}
			content={assistText}
		/>
	);
};
