import React, {Component} from 'react';
import * as tracking from '../tracking';
import BgPic from '../images/sections/not-found/bg.png';
import {FormattedMessage} from 'react-intl';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class NoAccess extends Component {
	constructor(props) {
		super(props);

		this.superPropertyChecksum = trackPage('Not Authorized');
	}

	componentDidMount() {
		document.title = '401 Forbidden - Forecast';
		tracking.trackPage('not-authorized');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	render() {
		return (
			<div className="not-found">
				<div className="text-container">
					<h1>
						<FormattedMessage id="no_access.header" />
					</h1>
					<p>
						<FormattedMessage id="no_access.paragraph_1" />
					</p>
					<p>
						<FormattedMessage id="no_access.paragraph_2" />
					</p>
				</div>
				<div className="image-container">
					<img src={BgPic} alt="" />
				</div>
			</div>
		);
	}
}

export default NoAccess;
