import React, {Component} from 'react';
import InputField from '../../components/inputs/input_field';
import SelectInput from '../../components/inputs/select_input';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import CreatePersonMutation from '../../mutations/create_person_mutation.modern';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import CreateProjectPersonClientMutation from '../../mutations/create_project_person_client_mutation';
import Util from '../../forecast-app/shared/util/util';
import PropTypes from 'prop-types';
import {createPerson} from '../../components/scheduling/scheduling_mutations';
import UpdateClientPersonMutation from '../../mutations/update_client_person_mutation';

class addClientUserModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.editClientUser ? this.props.clientUser.firstName + ' ' + this.props.clientUser.lastName : '',
			email: '',
			selectedRole: null,
			showEmailValidationError: false,
			emailValidationErrorMessage: '',
			canSendRequest: true,
			phone: this.props.addClientUser ? '' : null,
			addClientUserToSingleProject: true,
			addClientUserToProjects: [],
		};
	}

	CLIENT_PROFILE_ID = btoa('Profile:-2');

	checkEmailValidity(isValid) {
		this.setState({
			showEmailValidationError: !isValid,
			emailValidationErrorMessage: !isValid
				? this.props.intl.formatMessage({id: 'settings_team.invalid-email'})
				: this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'}),
		});
	}

	onCreatePersonSuccess(result) {
		if (result.createPerson.errors && result.createPerson.errors.length > 0) {
			this.setState({
				showEmailValidationError: true,
				emailValidationErrorMessage: this.props.intl.formatMessage({id: 'settings_team.duplicate-email'}),
				canSendRequest: true,
			});
		} else {
			this.setState({
				name: '',
				email: '',
			});
			if (this.props.clientId) {
				const onCreateProjectPersonSuccess = () => {
					const toast = this.props.intl.formatMessage({id: 'add_team_member_modal.client_user_added'});
					createToast({duration: 5000, message: toast});
					this.props.closeModal();
					this.setState({canSendRequest: true});
				};
				if (
					(this.props.project && this.state.addClientUserToSingleProject) ||
					this.state.addClientUserToProjects.length !== 0
				) {
					const mutationObject = {
						personId: result.createPerson.person.node.id,
					};
					if (this.props.project) {
						mutationObject.projectId = this.props.project.id;
					} else {
						mutationObject.projectIds = this.state.addClientUserToProjects.map(el => el.value);
					}
					Util.CommitMutation(CreateProjectPersonClientMutation, mutationObject, onCreateProjectPersonSuccess);
				} else {
					onCreateProjectPersonSuccess();
				}
			} else {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'add_team_member_modal.team-member-added'}),
				});
				this.setState({canSendRequest: true});
				if (this.props.closeModal) {
					this.props.closeModal();
				}
			}
		}
	}

	handleInputChange(property, value) {
		this.setState({[property]: value});
	}

	handleSelectChange(property, option) {
		this.setState({[property]: option ? option.value : null});
	}

	callbackPositive() {
		this.props.callbackPositive();
		this.props.closeModal();
	}

	createPerson() {
		if (this.props.editClientUser) {
			//Check if email valid, update email for person and set to CLIENT type
			if (!this.state.showEmailValidationError) {
				const onSuccess = result => {
					if (result.updateClientPerson.errors?.length > 0) {
						this.setState({
							showEmailValidationError: true,
							emailValidationErrorMessage: 'Email already exists',
						});
					} else {
						this.setState({
							showEmailValidationError: false,
							emailValidationErrorMessage: '',
						});
						Util.CommitMutation(
							UpdateClientPersonMutation,
							{
								id: this.props.clientUser.id,
								clientId: this.props.clientId,
								invited: true,
							},
							this.callbackPositive.bind(this)
						);
					}
				};
				if (this.state.email && this.state.email !== '') {
					Util.CommitMutation(
						UpdateClientPersonMutation,
						{
							id: this.props.clientUser.id,
							clientId: this.props.clientId,
							email: this.state.email,
						},
						onSuccess
					);
				}
			}
		} else {
			const name = this.state.name;

			const emailValid = !this.state.showEmailValidationError;
			this.setState({
				showNameValidationError: name === '' || name === null,
				showEmailValidationError: !emailValid || !this.state.email,
				emailValidationErrorMessage: !emailValid
					? this.state.emailValidationErrorMessage
					: this.props.intl.formatMessage({id: 'common.field-cannot-be-empty'}),
			});

			if (name !== null && name !== '' && this.state.canSendRequest && emailValid && this.state.email) {
				const fullName = name.trim();
				this.setState({canSendRequest: false});
				let first;
				let last;
				if (fullName.indexOf(' ') <= 0) {
					first = fullName;
					last = '';
				} else {
					first = fullName.substr(0, fullName.indexOf(' ')).trim();
					last = fullName.substr(fullName.indexOf(' ') + 1).trim();
				}
				if (this.props.addClientUser) {
					Util.CommitMutation(
						CreatePersonMutation,
						{
							companyId: this.props.companyId,
							email: this.state.email,
							clientId: this.props.clientId,
							firstName: first,
							lastName: last,
							profileIds: [this.CLIENT_PROFILE_ID],
							invited: true,
							phone: this.state.phone !== '' ? this.state.phone : null,
							clientUserAccessToProjectId:
								this.props.project && this.state.addClientUserToSingleProject
									? this.props.project.id
									: undefined,
						},
						this.onCreatePersonSuccess.bind(this)
					);
				} else {
					const mutationObject = {
						companyId: this.props.companyId,
						email: this.state.email,
						firstName: first,
						lastName: last,
						roleId: this.state.selectedRole || undefined,
						profileIds: [this.CLIENT_PROFILE_ID],
						invited: true,
					};
					if (Util.isScheduling()) {
						mutationObject.companyId = undefined;
						createPerson(mutationObject, result => {
							if (result.createPerson.errors && result.createPerson.errors.length > 0) {
								this.setState({
									showEmailValidationError: true,
									emailValidationErrorMessage: this.props.intl.formatMessage({
										id: 'settings_team.duplicate-email',
									}),
									canSendRequest: true,
								});
							} else {
								const toast =
									this.state.selected === 'NO_LOGIN'
										? this.props.intl.formatMessage({id: 'add_team_member_modal.client_user_invited'})
										: this.props.intl.formatMessage({id: 'add_team_member_modal.client_user_added'});
								createToast({duration: 5000, message: toast});
								Util.dispatchScheduleEvent(result);
								this.props.closeModal();
							}
						});
					} else {
						Util.CommitMutation(CreatePersonMutation, mutationObject, this.onCreatePersonSuccess.bind(this));
					}
				}
			}
		}
	}

	handleAddClientUserToProjects(selected) {
		if (selected.length === 0) {
			this.setState({addClientUserToProjects: []});
		} else {
			this.setState({addClientUserToProjects: selected});
		}
	}

	handleAddClientUserToSingleProject() {
		this.setState({
			addClientUserToSingleProject: !this.state.addClientUserToSingleProject,
		});
	}

	render() {
		const {formatMessage} = this.props.intl;

		return (
			<div
				className={this.props.isModal ? 'layer' : ''}
				onMouseDown={this.props.isModal ? this.props.closeModal.bind(this) : null}
			>
				<div
					className={'add-team-member-modal ' + (this.props.isModal ? 'modal' : '')}
					data-cy={'add-team-member-modal'}
				>
					<div
						className="inner-container"
						onMouseDown={e => {
							e.stopPropagation();
						}}
					>
						<div className="header-container">
							<h2 className="header-text">
								{this.props.addClientUser
									? formatMessage({id: 'settings_clients.add-client-user'})
									: this.props.editClientUser
									? formatMessage({id: 'add_team_member_modal.edit_client_user_email_title'})
									: formatMessage({id: 'add_team_member_modal.header'})}
							</h2>
							{this.props.isModal ? <div className="close-button" onClick={this.props.closeModal} /> : null}
						</div>
						<div className="body-container">
							{this.props.editClientUser ? (
								<FormattedMessage id="add_team_member_modal.edit_client_user_email_info" />
							) : null}
							{!this.props.editClientUser ? (
								<InputField
									label={formatMessage({id: 'common.full_name'})}
									value={this.state.name}
									onChange={this.handleInputChange.bind(this, 'name')}
									autoFocus={true}
									type="text"
									placeholder=""
									inputValid={!this.state.showNameValidationError}
									errorMessage={formatMessage({id: 'common.field-cannot-be-empty'})}
									cy={'name-input-field'}
								/>
							) : null}
							<InputField
								label={formatMessage({id: 'common.email'})}
								value={this.state.email}
								onChange={this.handleInputChange.bind(this, 'email')}
								type="email"
								placeholder=""
								checkEmailValidity={this.checkEmailValidity.bind(this)}
								inputValid={!this.state.showEmailValidationError}
								errorMessage={this.state.emailValidationErrorMessage}
								shouldFocus={this.state.showEmailValidationError}
								cy={'email-input-field'}
							/>
							{!this.props.addClientUser && !this.props.editClientUser ? (
								<SelectInput
									options={this.props.viewer.company.roles.edges.map(role => {
										return {value: role.node.id, label: role.node.name};
									})}
									onChange={this.handleSelectChange.bind(this, 'selectedRole')}
									value={this.state.selectedRole}
									multi={false}
									label={formatMessage({id: 'add_team_member_modal.main-role'})}
									clearable={true}
									cy={'role-input-select'}
								/>
							) : null}
							{this.props.addClientUser ? (
								<InputField
									label={formatMessage({id: 'settings.client-contact-phone'})}
									value={this.state.phone}
									onChange={this.handleInputChange.bind(this, 'phone')}
									type="text"
									placeholder=""
									maxLength={45}
								/>
							) : null}
							{this.props.addClientUser ? (
								this.props.project ? (
									<div
										className="project-access-checkbox-wrapper"
										onClick={this.handleAddClientUserToSingleProject.bind(this)}
									>
										<input
											type="checkbox"
											checked={this.state.addClientUserToSingleProject}
											onChange={this.handleAddClientUserToSingleProject.bind(this)}
										/>
										<FormattedMessage
											id="team_modal.access_checkbox-info"
											values={{
												project:
													'P-' + this.props.project.companyProjectId + ' ' + this.props.project.name,
											}}
										/>
									</div>
								) : this.props.project_options.length ? (
									<SelectInput
										label={formatMessage({id: 'common.projects'})}
										placeholder={formatMessage({id: 'client_page.project_access_placeholder'})}
										multi={true}
										options={this.props.project_options}
										onChange={this.handleAddClientUserToProjects.bind(this)}
										value={this.state.addClientUserToProjects}
									/>
								) : null
							) : null}
							<div className="button-container">
								<button className="button-cancel" onClick={this.props.closeModal}>
									{formatMessage({id: 'common.cancel'})}
								</button>
								<button
									disabled={!this.state.canSendRequest}
									className="button-send"
									onClick={this.createPerson.bind(this)}
									data-cy={'add-person-modal-button'}
								>
									{this.props.editClientUser
										? formatMessage({id: 'common.save'})
										: formatMessage({id: 'add_team_member_modal.add-person'})}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

addClientUserModal.propTypes = {
	editClientUser: PropTypes.func,
	addClientUser: PropTypes.bool,
	clientUser: PropTypes.object,
	project: PropTypes.object,
	clientId: PropTypes.string,
	projectGroupId: PropTypes.string,
	closeModal: PropTypes.func,
};

const addClientUserModalQuery = graphql`
	query addClientUserModal_Query {
		viewer {
			actualPersonId
			component(name: "add_client_user_modal")
			...addClientUserModal_viewer
		}
	}
`;

export {addClientUserModalQuery};

export default injectIntl(
	createFragmentContainer(addClientUserModal, {
		viewer: graphql`
			fragment addClientUserModal_viewer on Viewer {
				company {
					id
					userSeats
					virtualSeats
					profiles(first: 100000) {
						edges {
							node {
								id
								name
								canBeEdited
								personsCount
								permissions
								disabled
							}
						}
					}
					allPersons(first: 100000, onlyActive: true) {
						edges {
							node {
								id
								active
							}
						}
					}
					roles(first: 1000) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
				projects(first: 100000) {
					edges {
						node {
							id
							projectStartYear
							projectStartMonth
							projectStartDay
							projectEndYear
							projectEndMonth
							projectEndDay
						}
					}
				}
				projectGroups(first: 100000) {
					edges {
						node {
							id
							projects(first: 100000) {
								edges {
									node {
										id
										projectStartYear
										projectStartMonth
										projectStartDay
										projectEndYear
										projectEndMonth
										projectEndDay
									}
								}
							}
						}
					}
				}
			}
		`,
	})
);
