/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SkillRow_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SkillCategoryRow_company$ref: FragmentReference;
declare export opaque type SkillCategoryRow_company$fragmentType: SkillCategoryRow_company$ref;
export type SkillCategoryRow_company = {|
  +id: string,
  +skillLevelsEnabled: ?boolean,
  +$fragmentRefs: SkillRow_company$ref,
  +$refType: SkillCategoryRow_company$ref,
|};
export type SkillCategoryRow_company$data = SkillCategoryRow_company;
export type SkillCategoryRow_company$key = {
  +$data?: SkillCategoryRow_company$data,
  +$fragmentRefs: SkillCategoryRow_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillCategoryRow_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillLevelsEnabled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillRow_company"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'ffa5bb312392142666072110e8350581';

module.exports = node;
