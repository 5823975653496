import moment from 'moment';
import {getDateArray} from '../predicted_end_date_modal/PredictedEndDateModalLogic';
import {addPerformanceData, addTodayIntersection} from './PerformanceModalChartDataFormat';

export const getChartData = (performance, statsPerDayMap, firstChartDate, lastChartDate) => {
	const today = moment();
	const dateArray = getDateArray(firstChartDate, lastChartDate);

	const data = {
		performance: [],
		todayIntersection: [],
	};

	// fallback in case some days have not been cached
	let previousPerformance = 0;

	dateArray.forEach(date => {
		const momentDate = moment(date);
		const isToday = momentDate.isSame(today, 'day');
		const isPast = momentDate.isBefore(today, 'day');
		const dateInfo = {date, momentDate, isToday, isPast};

		const datePerformance = addPerformanceData(data, performance, statsPerDayMap, previousPerformance, dateInfo);
		if (datePerformance) {
			previousPerformance = datePerformance;
		}

		addTodayIntersection(data, performance, dateInfo);
	});

	return data;
};

export const getChartDataSets = data => {
	const dataSets = [];

	dataSets.push({
		name: 'Performance',
		color: '#279FFF',
		data: data.performance,
	});

	dataSets.push({
		name: 'Today intersection',
		pointRadius: 4,
		pointHoverRadius: 4,
		pointHoverBorderWidth: 2,
		color: '#E6E6E6',
		backgroundColor: '#FFF',
		borderColor: '#E6E6E6',
		data: data.todayIntersection,
		hideLegend: true,
		hideLabel: true,
	});

	return dataSets;
};
