import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import InvoiceDetailsForm from '../components/invoice_details_form';
import {
	GroupingBox,
	GroupingBoxSection,
	GroupingBoxHeadline,
	RadioButtonHeadline,
	GroupingBoxSectionContent,
	RadioButtonWrapper,
	RadioButtonSection,
} from '../../../../styles/v2/invoice_modals_v2_styled';
import Dropdown from '../../../../forecast-app/shared/components/dropdowns/dropdown';
import RadioButton from '../../../../components/new-ui/radio_button';
import {
	TIME_REG_GROUPINGS,
	EXPENSE_GROUPINGS,
} from '../../../../components/insights/invoicing/line-generation/invoicing_generate_lines';

const InvoiceSettingsAndLineGeneration = ({
	setInvoice,
	invoice,
	selectedProjectsIds,
	currenciesDropdownOptions,
	projects,
	company,
	isEditing,
	config,
	setConfig,
	setHasError,
	isBulk,
	isProjectInvoicing,
}) => {
	let billableHourGroupingOptions = [];
	let expenseGroupingOptions = [];
	const intl = useIntl();
	Object.keys(TIME_REG_GROUPINGS)
		.filter(timeRegGrouping => !isProjectInvoicing || timeRegGrouping !== 'PROJECT')
		.forEach(timeRegGrouping => {
			let label = '';
			switch (timeRegGrouping) {
				case 'NO_GROUPING':
					label = intl.formatMessage({id: 'project_sprints.group_by_people_disable'});
					break;
				case 'PROJECT':
					label = intl.formatMessage({id: 'common.project'});
					break;
				case 'TASK':
					label = intl.formatMessage({id: 'common.task'});
					break;
				case 'DONE_TASK':
					label = intl.formatMessage({id: 'invoice.done_task'});
					break;
				case 'PERSON':
					label = intl.formatMessage({id: 'common.person'});
					break;
				case 'ROLE':
					label = intl.formatMessage({id: 'common.role'});
					break;
				case 'PHASE':
					label = intl.formatMessage({id: 'common.phase'});
					break;
				case 'SPRINTS':
					label = intl.formatMessage({id: 'common.sprints'});
					break;
				case 'NO_GROUPING_NOTES':
					label = intl.formatMessage({id: 'invoice.no_grouping_notes'});
					break;
			}
			billableHourGroupingOptions.push({value: timeRegGrouping, label: label});
		});
	Object.keys(EXPENSE_GROUPINGS)
		.filter(expenseGrouping => !isProjectInvoicing || expenseGrouping !== 'PROJECT')
		.forEach(expenseGrouping => {
			let label = '';
			switch (expenseGrouping) {
				case 'NO_GROUPING':
					label = intl.formatMessage({id: 'project_sprints.group_by_people_disable'});
					break;
				case 'PROJECT':
					label = intl.formatMessage({id: 'common.project'});
					break;
				case 'PERSON':
					label = intl.formatMessage({id: 'common.person'});
					break;
				case 'PHASE':
					label = intl.formatMessage({id: 'common.phase'});
					break;
			}
			expenseGroupingOptions.push({value: expenseGrouping, label: label});
		});

	const [timeRegGrouping, setTimeRegGrouping] = useState(config.timeRegGrouping);
	const [expenseGrouping, setExpenseGrouping] = useState(config.expenseGrouping);
	const [includeExpenses, setIncludeExpenses] = useState(config.includeExpenses);
	useEffect(() => {
		setConfig({
			...config,
			timeRegGrouping,
			expenseGrouping,
			includeExpenses,
			selectedProjectsIds,
		});
	}, [timeRegGrouping, expenseGrouping, includeExpenses]);

	const onTimeRegGroupingChange = value => {
		setTimeRegGrouping(value);
		localStorage.setItem('invoicing_time_reg_grouping', value);
	};

	const onExpenseGroupingChange = value => {
		setExpenseGrouping(value);
		localStorage.setItem('invoicing_expense_grouping', value);
	};

	const onIncludeExpensesChange = value => {
		setIncludeExpenses(value);
		localStorage.setItem('invoicing_include_expenses', value);
	};

	return (
		<>
			<InvoiceDetailsForm
				isEditing={isEditing}
				invoice={invoice}
				setInvoice={setInvoice}
				currenciesDropdownOptions={currenciesDropdownOptions}
				isCreatingBlankInvoice={false}
				currency={company.currency}
				selectedProjectsIds={selectedProjectsIds}
				projects={projects}
				setHasError={setHasError}
				company={company}
				isBulk={isBulk}
			/>
			<GroupingBox data-userpilot={'grouping-options'}>
				<GroupingBoxSection>
					<GroupingBoxHeadline>{intl.formatMessage({id: 'project_budget.billable_hours'})}</GroupingBoxHeadline>
					<GroupingBoxSectionContent>
						<Dropdown
							onChange={option => onTimeRegGroupingChange(option.value)}
							options={billableHourGroupingOptions}
							value={
								billableHourGroupingOptions.some(option => option.value === timeRegGrouping)
									? timeRegGrouping
									: TIME_REG_GROUPINGS.NO_GROUPING
							}
							label={intl.formatMessage({id: 'common.group-by'})}
							inputCy={'time-reg-grouping'}
						/>
					</GroupingBoxSectionContent>
				</GroupingBoxSection>
				<GroupingBoxSection>
					<GroupingBoxHeadline>{intl.formatMessage({id: 'invoicing.include_expenses'})}</GroupingBoxHeadline>
					<GroupingBoxSectionContent>
						<RadioButtonSection>
							<RadioButtonHeadline>{intl.formatMessage({id: 'common.all_projects'})}</RadioButtonHeadline>
							<RadioButtonWrapper>
								<RadioButton
									onSelected={onIncludeExpensesChange.bind(this)}
									value={true}
									stateValue={includeExpenses}
									text={intl.formatMessage({id: 'common.yes'})}
									cy={'include-expenses-yes'}
								/>
								<RadioButton
									onSelected={onIncludeExpensesChange.bind(this)}
									value={false}
									stateValue={includeExpenses}
									text={intl.formatMessage({id: 'common.no'})}
									cy={'include-expenses-no'}
								/>
							</RadioButtonWrapper>
						</RadioButtonSection>
						<Dropdown
							onChange={option => onExpenseGroupingChange(option.value)}
							options={expenseGroupingOptions}
							value={
								expenseGroupingOptions.some(option => option.value === expenseGrouping)
									? expenseGrouping
									: EXPENSE_GROUPINGS.NO_GROUPING
							}
							label={intl.formatMessage({id: 'common.group-by'})}
							inputCy={'expense-grouping'}
						/>
					</GroupingBoxSectionContent>
				</GroupingBoxSection>
			</GroupingBox>
		</>
	);
};

export default InvoiceSettingsAndLineGeneration;
