import React, {useState, useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import GenericModal from '../generic_modal';
import InvoiceEntriesList from '../create_invoice_modal_v2/steps/invoice_entries_list';
import {
	InvoiceDropdownContainer,
	InvoiceDataLineTwo,
	SageIntacctExportModalContentWrapper,
} from '../../../styles/v2/invoice_modals_v2_styled';
import CreateSageIntacctInvoiceMutation from '../../../mutations/create_sageIntacct_invoice_mutation';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {showModal, MODAL_TYPE} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import {hideLoader, showLoader} from '../../../containers/global_loader';
import DirectApi from '../../../directApi';
import {
	UneditableMessageBox,
	MessageContainer,
	PadLockContainer,
} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';

const sageIntacctExportModal = ({closeModal, viewer, sageIntacctCustomers}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {invoice, projects} = viewer;
	const {entries} = invoice;
	const mappedProjects = projects.edges.map(p => ({...p.node}));
	const clientProjects = mappedProjects.filter(project =>
		project.client && invoice.client ? project.client.id === invoice.client.id : false
	);
	const sageImportedProjects = mappedProjects.filter(p => p.sageProject.sageProjectId !== null);
	const initialInvoiceLines = entries.edges.map(e => ({
		...e.node,
		projectId: e.node.project ? e.node.project.id : null,
		quantity: e.node.quantityText,
	}));

	const mappedTimeRegistrations = invoice.timeRegistrations.edges.map(timeReg => ({...timeReg.node}));

	const [invoiceLines, setInvoiceLines] = useState(initialInvoiceLines);
	const [sageIntacctLocations, setSageIntacctLocations] = useState([]);
	const [sageIntacctAccounts, setSageIntacctAccounts] = useState([]);
	const [selectedClient, setSelectedClient] = useState(
		invoice.client && invoice.client.sageIntacctCustomerId ? invoice.client.sageIntacctCustomerId : null
	);
	const [selectedSageIntacctLocation, setSelectedSageIntacctLocation] = useState(null);
	const [selectedSageIntacctAccount, setSelectedSageIntacctAccount] = useState(null);

	const [exporting, setExporting] = useState(false);

	const selectSageIntacctLocation = e => {
		setSelectedSageIntacctLocation(e.value);
		invoiceLines.forEach(line => {
			const timeReg = mappedTimeRegistrations.find(
				timeReg => timeReg.invoiceEntryId === Util.getIdFromBase64String(line.id)
			);

			if (timeReg === undefined || timeReg.person.sageIntacctLocationId === null) {
				line.sageIntacctLocation = e.value;
			} else {
				line.sageIntacctLocation = sageIntacctLocations.find(
					location => location.value === timeReg.person.sageIntacctLocationId
				).value;
			}
		});
	};

	const selectSageIntacctAccount = e => {
		setSelectedSageIntacctAccount(e.value);
		invoiceLines.forEach(line => {
			line.sageIntacctAccount = e.value;
		});
	};

	const content = (
		<SageIntacctExportModalContentWrapper minHeight="600px">
			<UneditableMessageBox style={{marginBottom: '30px'}}>
				<PadLockContainer>
					<LockIcon size={LockIcon.SIZE.LARGE} color={CSS_CONSTANTS.v2_text_gray} />
				</PadLockContainer>
				<MessageContainer>{formatMessage({id: 'invoicing.cannot_edit_warning_exporting'})}</MessageContainer>
			</UneditableMessageBox>
			<InvoiceDataLineTwo>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => setSelectedClient(option.value)}
						options={sageIntacctCustomers}
						value={selectedClient}
						label={formatMessage({id: 'common.customer'})}
						placeholder={formatMessage({id: 'common.customer'})}
					/>
				</InvoiceDropdownContainer>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectSageIntacctAccount(option)}
						options={sageIntacctAccounts}
						value={selectedSageIntacctAccount}
						label={formatMessage({id: 'integrations.sageIntacct.account'})}
						placeholder={formatMessage({id: 'integrations.sageIntacct.account'})}
					/>
				</InvoiceDropdownContainer>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectSageIntacctLocation(option)}
						options={sageIntacctLocations}
						value={selectedSageIntacctLocation}
						label={formatMessage({id: 'integrations.sageIntacct.location'})}
						placeholder={formatMessage({id: 'integrations.sageIntacct.location'})}
					/>
				</InvoiceDropdownContainer>
			</InvoiceDataLineTwo>
			<InvoiceEntriesList
				invoiceExporting={true}
				lines={invoiceLines}
				projects={mappedProjects}
				clientProjects={clientProjects}
				currency={invoice.currency}
				setInvoiceLines={setInvoiceLines}
				invoice={invoice}
				integrationData={{
					sageIntacct: {
						enabled: true,
						locations: sageIntacctLocations,
						accounts: sageIntacctAccounts,
						mappedTimeRegistrations: mappedTimeRegistrations,
					},
				}}
			/>
		</SageIntacctExportModalContentWrapper>
	);

	const formatErrorMessage = error => {
		return `Sage Intacct - ${JSON.parse(error).error[0].description2}`;
	};

	const getSageProjectId = line => {
		const project = sageImportedProjects.find(p => p.id === line.projectId);
		if (project === undefined) {
			return null;
		}
		return project.sageProject.sageProjectId;
	};

	const getSageData = (line, type) => {
		const timeReg = mappedTimeRegistrations.find(timeReg => timeReg.invoiceEntryId === Util.getIdFromBase64String(line.id));
		if (timeReg === undefined) {
			return null;
		}

		switch (type) {
			case 'departmentId':
				if (timeReg.person.department === null) {
					return null;
				} else {
					return timeReg.person.department.sageIntacctId;
				}

			case 'taskId':
				if (timeReg.task === null) {
					return null;
				} else {
					return timeReg.task.sageIntacctId;
				}

			case 'employeeId':
				return timeReg.person.sageIntacctId;

			case 'locationId':
				if (timeReg.person.sageIntacctLocationId === null) {
					return line.sageIntacctLocation;
				} else {
					return timeReg.person.sageIntacctLocationId;
				}
		}
	};

	const onInvoiceExport = () => {
		setExporting(true);
		showLoader();
		// Export invoice to Sage Intacct Mutation
		Util.CommitMutation(
			CreateSageIntacctInvoiceMutation,
			{
				invoiceId: invoice.id,
				sageIntacctCustomerId: selectedClient,
				entryMap: invoiceLines.map(line => ({
					entryId: line.id,
					projectId: getSageProjectId(line),
					locationId: getSageData(line, 'locationId') || line.sageIntacctLocation,
					accountId: line.sageIntacctAccount,
					departmentId: getSageData(line, 'departmentId'),
					employeeId: getSageData(line, 'employeeId'),
					taskId: getSageData(line, 'taskId'),
					total: Util.getInvoiceEntryAmount(line.unitPrice, line.quantity, 1, line.discount, line.tax),
				})),
			}, // Toast
			result => {
				setExporting(false);
				closeModal();
				hideLoader();
				if (result?.createSageIntacctInvoice?.success) {
					createToast({
						duration: 5000,
						message: intl.formatMessage({id: 'integrations.sageIntacct.invoice_created'}),
					});
				} else {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'invoicing.not_exported',
						warningInformation: [
							result?.createSageIntacctInvoice?.errorMessage
								? formatErrorMessage(result?.createSageIntacctInvoice?.errorMessage)
								: 'The invoice could not be exported. Try again or contact support.',
						],
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			}
		);
	};

	const getSageIntacctLocations = () => {
		try {
			DirectApi.Fetch(`sageIntacct/locations`).then(json => {
				if (json.locations) {
					setSageIntacctLocations(
						json.locations.map(item => ({
							value: item.LOCATIONID,
							label: item.NAME,
						}))
					);
				} else {
					setSageIntacctLocations([]);
				}
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const getSageIntacctAccounts = () => {
		try {
			DirectApi.Fetch(`sageIntacct/accounts`).then(json => {
				if (json.accounts) {
					setSageIntacctAccounts(
						json.accounts.map(item => ({
							value: item.ACCOUNTNO,
							label: item.TITLE,
						}))
					);
				} else {
					setSageIntacctAccounts([]);
				}
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const modalButtons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			callback: closeModal.bind(this),
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: formatMessage({id: 'common.export'}),
			preventDefaultClose: true,
			callback: onInvoiceExport,
			disabled: exporting || selectedClient === null || invoiceLines.find(line => !line.sageIntacctLocation),
		},
	];

	useEffect(() => {
		getSageIntacctLocations();
		getSageIntacctAccounts();
	}, []);

	return (
		<GenericModal
			buttons={modalButtons}
			headerText={formatMessage({id: 'invoicing.export_to'}, {systemName: 'Sage Intacct'})}
			closeModal={closeModal}
			content={content}
		/>
	);
};

const sageIntacctExportModalQuery = graphql`
	query sageIntacctExportModal_Query($invoiceId: ID) {
		viewer {
			actualPersonId
			component(name: "sageIntacct_export_modal")
			...sageIntacctExportModal_viewer @arguments(invoiceId: $invoiceId)
		}
	}
`;

export {sageIntacctExportModalQuery};

export default createFragmentContainer(sageIntacctExportModal, {
	viewer: graphql`
		fragment sageIntacctExportModal_viewer on Viewer @argumentDefinitions(invoiceId: {type: "ID"}) {
			id
			backendId
			invoice(id: $invoiceId) {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				sageIntacctId
				client {
					id
					sageIntacctCustomerId
				}
				timeRegistrations(first: 100000000) {
					edges {
						node {
							id
							invoiceEntryId
							task {
								sageIntacctId
							}
							person {
								sageIntacctId
								sageIntacctLocationId
								department {
									sageIntacctId
								}
							}
						}
					}
				}
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quantityLockedReason
							project {
								id
								companyProjectId
								name
								projectColor
								isInProjectGroup
								budgetType
								client {
									name
								}
								rateCard {
									currency
								}
							}
						}
					}
				}
			}
			projects(first: 100000000) {
				edges {
					node {
						id
						name
						projectColor
						isInProjectGroup
						companyProjectId
						projectGroupId
						budgetType
						status
						fullAccessToProject
						sageProject {
							sageProjectId
						}
					}
				}
			}
		}
	`,
});
