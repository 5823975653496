import {createToast} from '../../../../forecast-app/shared/components/toasts/toast';
import Util from '../../../../forecast-app/shared/util/util';
import UnlinkInvoiceMutation from '../../../../mutations/UnlinkInvoiceMutation';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {FormattedMessage} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import {hideLoader, showLoader} from '../../../../containers/global_loader';
import * as tracking from '../../../../tracking';
import DirectApi from '../../../../directApi';
import React from 'react';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

export const unlinkInvoice = (invoiceId, refetch, formatMessage) => {
	const onSuccess = () => {
		// update state
		refetch();

		createToast({duration: 5000, message: formatMessage({id: 'invoice.invoice_unlinked'})});
	};

	const callback = () => {
		Util.CommitMutation(UnlinkInvoiceMutation, {id: invoiceId}, onSuccess);
	};

	showModal({
		type: MODAL_TYPE.WARNING,
		warningMessageId: 'invoice.confirm_unlink_invoice',
		warningInformation: [<FormattedMessage id="invoice.unlink_warning" />],
		irreversible: true,
		buttons: [
			{
				text: <FormattedMessage id="common.cancel" />,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: <FormattedMessage id="common.unlink" />,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.RED,
				callback: callback,
			},
		],
	});
};

export const synchronizeInvoices = (newXeroEnabled, quickbooksEnabled, economicEnabled, sageIntacctEnabled) => {
	showLoader();
	tracking.trackEvent('Synchronize invoices');
	trackEvent('Invoices', 'Synchronized');

	if (newXeroEnabled) {
		try {
			DirectApi.Fetch('xero/sync').then(json => {
				window.location.reload();
				hideLoader();
			});
		} catch (error) {
			//TODO: Show error?
			hideLoader();
		}
	} else if (sageIntacctEnabled) {
		try {
			DirectApi.Fetch(`sageIntacct/sync`).then(json => {
				window.location.reload();
				hideLoader();
			});
		} catch (error) {
			//TODO: Show error?
			hideLoader();
		}
	} else if (quickbooksEnabled) {
		try {
			DirectApi.Fetch(`quickbooks/sync`).then(json => {
				window.location.reload();
				hideLoader();
			});
		} catch (error) {
			//TODO: Show error?
			hideLoader();
		}
	} else if (economicEnabled) {
		try {
			DirectApi.Fetch(`economic/sync`).then(json => {
				window.location.reload();
				hideLoader();
			});
		} catch (error) {
			//TODO: Show error?
			hideLoader();
		}
	}
};
export const getActiveIntegrationName = (isXeroUsed, isEconomicUsed, isQuickbooksUsed, isSageIntacctUsed) => {
	if (isXeroUsed) {
		return 'Xero';
	} else if (isEconomicUsed) {
		return 'Economic';
	} else if (isQuickbooksUsed) {
		return 'Quickbooks';
	} else if (isSageIntacctUsed) {
		return 'Sage Intacct';
	}
};
export const beautifyExternalStatusText = exStatus => {
	const statusArray = exStatus.split('_');

	if (statusArray[2]) {
		return statusArray[1].charAt(0) + statusArray[1].slice(1).toLowerCase() + ' ' + statusArray[2]?.toLowerCase();
	} else if (statusArray[1]) {
		return statusArray[1].charAt(0) + statusArray[1].slice(1).toLowerCase();
	} else {
		return exStatus.charAt(0) + exStatus.slice(1).toLowerCase();
	}
};
