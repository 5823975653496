/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type insightsBuilderV2_viewer$ref = any;
export type insightsBuilderV2_QueryVariables = {|
  insightId?: ?string
|};
export type insightsBuilderV2_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +company: ?{|
      +insight: ?{|
        +id: string
      |}
    |},
    +$fragmentRefs: insightsBuilderV2_viewer$ref,
  |}
|};
export type insightsBuilderV2_Query = {|
  variables: insightsBuilderV2_QueryVariables,
  response: insightsBuilderV2_QueryResponse,
|};
*/


/*
query insightsBuilderV2_Query(
  $insightId: ID
) {
  viewer {
    actualPersonId
    component(name: "insightsBuilderV2")
    company {
      insight(id: $insightId) {
        id
      }
      id
    }
    ...insightsBuilderV2_viewer_bkqaL
    id
  }
}

fragment insightsBuilderV2_viewer_bkqaL on Viewer {
  availableFeatureFlags {
    id
    name
    key
  }
  company {
    id
    currency
    xeroEnabled
    modules {
      moduleType
      id
    }
    tier
    insight(id: $insightId) {
      id
      name
      category
      components {
        id
        componentName
        x
        y
        w
        h
        config
      }
      labels {
        id
        name
      }
      skills {
        id
        name
      }
      statusColumns {
        id
        category
        name
      }
      clients {
        id
        name
      }
    }
  }
  projects(first: 100000) {
    edges {
      node {
        id
        name
        status
        companyProjectId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "insightId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insightsBuilderV2"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insightsBuilderV2\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "insightId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insightsBuilderV2_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Insight",
                "kind": "LinkedField",
                "name": "insight",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "insightId",
                "variableName": "insightId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "insightsBuilderV2_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insightsBuilderV2_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Insight",
                "kind": "LinkedField",
                "name": "insight",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InsightComponent",
                    "kind": "LinkedField",
                    "name": "components",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "componentName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "x",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "y",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "w",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "h",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "config",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Label",
                    "kind": "LinkedField",
                    "name": "labels",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatusColumn",
                    "kind": "LinkedField",
                    "name": "statusColumns",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Client",
                    "kind": "LinkedField",
                    "name": "clients",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "xeroEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100000
              }
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(first:100000)"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "insightsBuilderV2_Query",
    "operationKind": "query",
    "text": "query insightsBuilderV2_Query(\n  $insightId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insightsBuilderV2\")\n    company {\n      insight(id: $insightId) {\n        id\n      }\n      id\n    }\n    ...insightsBuilderV2_viewer_bkqaL\n    id\n  }\n}\n\nfragment insightsBuilderV2_viewer_bkqaL on Viewer {\n  availableFeatureFlags {\n    id\n    name\n    key\n  }\n  company {\n    id\n    currency\n    xeroEnabled\n    modules {\n      moduleType\n      id\n    }\n    tier\n    insight(id: $insightId) {\n      id\n      name\n      category\n      components {\n        id\n        componentName\n        x\n        y\n        w\n        h\n        config\n      }\n      labels {\n        id\n        name\n      }\n      skills {\n        id\n        name\n      }\n      statusColumns {\n        id\n        category\n        name\n      }\n      clients {\n        id\n        name\n      }\n    }\n  }\n  projects(first: 100000) {\n    edges {\n      node {\n        id\n        name\n        status\n        companyProjectId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '97c5076a76d0d189c7e546b01c9080ac';

module.exports = node;
