import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import ImportEconomicCustomersMutation from '../../mutations/ImportEconomicCustomersMutation';
import {Checkbox} from 'web-components';
import {hideLoader, showLoader} from '../global_loader';
import DirectApi from '../../directApi';

class EconomicImportCustomersModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			economicCustomers: [],
			fetchingCustomers: true,
			selectedCustomers: [],
		};

		this.getContacts();
	}

	getContacts() {
		DirectApi.Fetch('economic/unimported_customers').then(contacts => {
			this.setState({
				economicCustomers: contacts.list.sort((a, b) =>
					a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : -1
				),
				fetchingCustomers: false,
			});
		});
	}

	toggleSelectAll() {
		if (this.state.selectAll) {
			this.setState({
				selectedCustomers: [],
				selectAll: false,
			});
		} else {
			this.setState({
				selectedCustomers: this.state.economicCustomers.map(customer => customer.id),
				selectAll: true,
			});
		}
	}

	toggleContact(economicCustomerId) {
		if (this.state.selectedCustomers.includes(economicCustomerId)) {
			const selectedCustomers = this.state.selectedCustomers.filter(id => id !== economicCustomerId);
			this.setState({
				selectedCustomers,
				selectAll: false,
			});
		} else {
			const selectedCustomers = this.state.selectedCustomers;
			selectedCustomers.push(economicCustomerId);
			this.setState({
				selectedCustomers,
			});
		}
	}

	syncContacts() {
		this.setState({importing: true});
		showLoader();
		Util.CommitMutation(
			ImportEconomicCustomersMutation,
			{
				economicCustomerIds: this.state.selectedCustomers,
			},
			() => {
				this.setState({importing: false});
				this.props.closeModal();
				hideLoader();
				window.location.reload();
			}
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="integration-sync-clients-modal-content">
				<div className="integration-sync-clients-modal-description">
					<FormattedMessage id="integrations.economic.sync_clients.description" />
				</div>
				{this.state.fetchingCustomers ? (
					<InlineLoader />
				) : (
					<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
						<div className="integration-clients">
							{this.state.economicCustomers && this.state.economicCustomers.length > 0 ? (
								<>
									<div className="integration-client" key={'0'}>
										<Checkbox checked={this.state.selectAll} onClick={this.toggleSelectAll.bind(this)} />
										<FormattedMessage id="common.select_all" />
									</div>
									{this.state.economicCustomers.map(customer => (
										<div className="integration-client" key={customer.id}>
											<Checkbox
												checked={this.state.selectedCustomers.includes(customer.id)}
												onClick={this.toggleContact.bind(this, customer.id)}
											/>
											{customer.name}
										</div>
									))}
								</>
							) : (
								<FormattedMessage id="integrations.economic.all_contacts_synched" />
							)}
						</div>
					</CustomScrollDiv>
				)}
			</div>
		);

		return (
			<GenericModal
				headerText={<FormattedMessage id="integrations.economic.sync_clients" />}
				closeModal={this.props.closeModal}
				className="integration-sync-clients-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage(
							{id: 'common.create_x_clients'},
							{numOfClients: this.state.selectedCustomers.length}
						),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.syncContacts.bind(this),
						disabled: this.state.importing || this.state.selectedCustomers.length === 0,
						defaultCallback: () => null,
						preventDefaultClose: true,
					},
				]}
				content={content}
			/>
		);
	}
}

export default injectIntl(withRouter(EconomicImportCustomersModal));
