import {Table} from 'web-components';
import SkillRow from './SkillRow';
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeleteSkillCategoryMutation from '../../../mutations/delete_skill_category_mutation';
import Util from '../../../forecast-app/shared/util/util';
import * as tracking from '../../../tracking';
import {ActionMenuWrapper} from './TableRow.styled';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const SkillCategoryRow = ({
	company,
	tableColumnsProps,
	nextLevelProps,
	skills,
	skillCategory,
	expanded,
	isSkillSelected,
	toggleSkillSelected,
}) => {
	const {formatMessage} = useIntl();
	const categoryRowIndex = tableColumnsProps.index;

	if (!skillCategory) {
		skillCategory = {
			name: formatMessage({id: 'settings_skills.no_category'}),
		};
	}

	function deleteSkillCategory() {
		Util.CommitMutation(
			DeleteSkillCategoryMutation,
			{
				id: skillCategory.id,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.delete_category_toast'}, {name: skillCategory.name}),
				});
			}
		);
	}

	function getActionsMenuOptions() {
		return [
			{
				text: formatMessage({id: 'settings_skills.edit_category'}),
				cy: 'edit',
				onClick: () => {
					tracking.trackEvent('skills - update category modal opened');
					trackEvent('Update Skill Category Modal', 'Opened');
					showModal({
						type: MODAL_TYPE.UPDATE_SKILL_CATEGORY,
						skillCategoryId: skillCategory.id,
					});
				},
			},
			{
				text: formatMessage({id: 'settings_skills.delete_category'}),
				cy: 'delete',
				onClick: () => {
					if (skills.length) {
						tracking.trackEvent('skills - category delete confirm modal opened');
						trackEvent('Delete Skill Category Modal', 'Opened');
						showModal({
							type: MODAL_TYPE.CONFIRM_SKILL_CATEGORY_DELETION,
							title: formatMessage({id: 'settings_skills.delete_category_title'}, {name: skillCategory.name}),
							warningMessage: formatMessage({id: 'settings_skills.delete_category_warning'}),
							confirmText: formatMessage({id: 'settings.confirm'}),
							onDelete: deleteSkillCategory,
						});
					} else {
						deleteSkillCategory();
					}
				},
			},
		];
	}

	return (
		<>
			<Table.Row
				hoverStyle={{visibility: 'visible'}}
				cy={`skill-category-${categoryRowIndex}`}
				{...tableColumnsProps}
				canExpandRow={!!skills.length}
				isHeader
			>
				<Table.Column>
					{skillCategory.name} ({skills.length})
				</Table.Column>
				<Table.Column />
				<Table.Column />
				<Table.Column />
				<Table.Column>
					{!!skillCategory.id && (
						<ActionMenuWrapper
							cy={'skill-category'}
							className={'row-hover'}
							whiteInner={true}
							options={getActionsMenuOptions()}
						/>
					)}
				</Table.Column>
			</Table.Row>
			{expanded && (
				<Table.Rows
					data={{
						rows: skills,
					}}
					canExpand={company.skillLevelsEnabled}
					{...nextLevelProps}
				>
					{({rowData: skill, tableColumnsProps, nextLevelProps, expanded}) => (
						<SkillRow
							cy={`skill-category-${categoryRowIndex}-skill-${tableColumnsProps.index}`}
							company={company}
							tableColumnsProps={tableColumnsProps}
							checked={isSkillSelected(skill.id)}
							toggleSelect={e => {
								tracking.trackEvent('skills - category selected');
								trackEvent('Skill', 'Selected');
								e.stopPropagation();
								toggleSkillSelected(skill.id);
							}}
							expanded={expanded}
							nextLevelProps={nextLevelProps}
							skill={skill}
						/>
					)}
				</Table.Rows>
			)}
		</>
	);
};

export default createFragmentContainer(SkillCategoryRow, {
	company: graphql`
		fragment SkillCategoryRow_company on Company {
			id
			skillLevelsEnabled
			...SkillRow_company
		}
	`,
	skillCategory: graphql`
		fragment SkillCategoryRow_skillCategory on SkillCategory {
			id
			name
		}
	`,
	skills: graphql`
		fragment SkillCategoryRow_skills on Skill @relay(plural: true) {
			id
			...SkillRow_skill
		}
	`,
});
