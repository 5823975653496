/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type missionControlHeader_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type impersonate_viewer$ref: FragmentReference;
declare export opaque type impersonate_viewer$fragmentType: impersonate_viewer$ref;
export type impersonate_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +missionControlData: ?{|
    +id: string,
    +forecastPersons: ?$ReadOnlyArray<?{|
      +id: string,
      +firstName: ?string,
      +lastName: ?string,
      +impersonatedPersonId: ?string,
      +email: ?string,
    |}>,
    +otherPersons: ?$ReadOnlyArray<?{|
      +id: string,
      +firstName: ?string,
      +lastName: ?string,
      +email: ?string,
      +companyName: ?string,
      +profileName: ?string,
      +datacenter: ?string,
    |}>,
  |},
  +$fragmentRefs: missionControlHeader_viewer$ref,
  +$refType: impersonate_viewer$ref,
|};
export type impersonate_viewer$data = impersonate_viewer;
export type impersonate_viewer$key = {
  +$data?: impersonate_viewer$data,
  +$fragmentRefs: impersonate_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "impersonate_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlImpersonatePersonType",
          "kind": "LinkedField",
          "name": "forecastPersons",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "impersonatedPersonId",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "includeForecastPersons",
              "value": true
            }
          ],
          "concreteType": "MissionControlImpersonatePersonType",
          "kind": "LinkedField",
          "name": "otherPersons",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profileName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "datacenter",
              "storageKey": null
            }
          ],
          "storageKey": "otherPersons(includeForecastPersons:true)"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "missionControlHeader_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '94aea82eccedeee0b30517fc0ec7ec16';

module.exports = node;
