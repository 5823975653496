import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

class FileModal extends Component {
	constructor(props) {
		super(props);

		this.state = {currentFile: props.file};
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidMount() {
		window.addEventListener('keydown', this.onKeyDown);
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.onKeyDown);
	}

	onKeyDown(e) {
		if (e.key === 'ArrowRight') {
			this.next(e);
		} else if (e.key === 'ArrowLeft') {
			this.previous(e);
		}
	}

	previous(e) {
		e.stopPropagation();
		for (let i = 0; i < this.props.files.length; i++) {
			const file = this.props.files[i];
			if (file === this.state.currentFile) {
				this.setState({currentFile: i > 0 ? this.props.files[i - 1] : this.props.files[this.props.files.length - 1]});
				break;
			}
		}
	}
	next(e) {
		e.stopPropagation();
		for (let i = 0; i < this.props.files.length; i++) {
			const file = this.props.files[i];
			if (file === this.state.currentFile) {
				this.setState({currentFile: this.props.files[(i + 1) % this.props.files.length]});
				break;
			}
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="layer top-level" onMouseDown={this.props.closeModal}>
				<div className="file-modal">
					<div className="flex-wrapper">
						{this.props.files && this.props.files.length > 1 ? (
							<button className="preview-left" onMouseDown={this.previous.bind(this)} />
						) : (
							<div className="flex-spacer" />
						)}
						<div
							className="inner"
							onMouseDown={e => {
								e.stopPropagation();
							}}
						>
							{this.props.isFileVideoSet && this.props.isFileVideoSet.has(this.state.currentFile) ? (
								<video crossOrigin="use-credentials" width="800" height="600" controls>
									<source src={this.state.currentFile} type="video/mp4" />
									<source src={this.state.currentFile} type="video/webm" />
									<source src={this.state.currentFile} type="video/ogg" />
									{formatMessage({id: 'file-modal.video-not-supported'})}
								</video>
							) : (
								<img
									crossOrigin="use-credentials"
									className="preview"
									src={this.state.currentFile}
									alt="enlarged file"
								/>
							)}
						</div>
						{this.props.files && this.props.files.length > 1 ? (
							<button className="preview-right" onMouseDown={this.next.bind(this)} />
						) : (
							<div className="flex-spacer" />
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default injectIntl(FileModal);
