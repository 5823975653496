import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
// import Input from '../../components/new-ui/input_field';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import CurrencyInput from '../../components/inputs/currency_input';
import Util from '../../forecast-app/shared/util/util';

class BudgetFixedPriceModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: this.props.initialValue || '',
		};
	}

	handleConfirmButton() {
		if (this.props.handleConfirm) {
			this.props.handleConfirm(this.state.inputValue);
		}
		this.props.closeModal();
	}
	handleCostChange(value) {
		this.setState({inputValue: value});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {currency} = this.props;

		const currencySymbol = Util.GetCurrencySymbol(currency);
		const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);
		const {inputValue} = this.state;

		const content = (
			<div className="content budget-fixed-price-modal">
				<p>
					Adding or editing a Fixed Price Budget adjusts your project Rate Card’s billing rate by either increasing or
					decreasing it. This gives you a more accurate budget progress.{' '}
				</p>
				<CurrencyInput
					value={inputValue}
					showCurrencyOnLeft={isDollarOrEuro}
					onChange={this.handleCostChange.bind(this)}
					placeholder={'0'}
					currency={currency}
					allowDecimals={true}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.cancelText || formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: this.props.confirmText,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.handleConfirmButton.bind(this),
					},
				]}
				headerText={this.props.titleText}
				content={content}
			/>
		);
	}
}

BudgetFixedPriceModal.propTypes = {
	titleText: PropTypes.object.isRequired,
	cancelText: PropTypes.object,
	confirmText: PropTypes.object.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	label: PropTypes.string,
};

export default injectIntl(BudgetFixedPriceModal);
