import React, {useEffect, useState} from 'react';
import {ProgressRegistration} from '../../../../forecast-app/shared/components/popups/progress-registration/ProgressRegistration';
import tracking from '../../../../tracking';
import GenericModal from '../../../modal/generic_modal';
import Util from '../../../../forecast-app/shared/util/util';
import CreateProjectProgressMutation from '../../../../mutations/create_project_progress_mutation';
import {createToast} from '../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {useIntl} from 'react-intl';
import ProgressHistory from '../../../../forecast-app/shared/components/popups/progress-registration/ProgressHistory';
import Styled from 'styled-components';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

const Footer = Styled.div`
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #DDD;
 `;

export const ProgressRegistrationModal = ({project, closeModal}) => {
	useEffect(() => {
		tracking.trackPageAction('Project Progress Modal shown');
		trackEvent('Project Progress Modal', 'Shown');
	}, []);

	const intl = useIntl();

	const [selectedProgress, setSelectedProgress] = useState();

	const handleSave = () => {
		if (!isNaN(selectedProgress)) {
			const onSuccess = () => {
				createToast({message: intl.formatMessage({id: 'project_progress_modal.progress_saved'}), duration: 3000});
				closeModal();
			};
			Util.CommitMutation(
				CreateProjectProgressMutation,
				{
					projectId: project.id,
					progress: Math.round(selectedProgress * 100),
				},
				onSuccess
			);
		}
	};

	const getContent = () => {
		return (
			<>
				<ProgressRegistration
					currentProgressValue={project.progressDetails ? project.progressDetails.progress : 0}
					progressChangedCallback={value => {
						setSelectedProgress(value);
					}}
					close={closeModal}
					isTaskModal={false}
					showButtons={false}
					closeOnValueClicked={false}
				/>
			</>
		);
	};

	const getFooter = () => {
		return (
			<>
				{project.progressDetails && (
					<Footer>
						<div className={'header-container'}>
							<h1 className={'header-text'}>Progress History</h1>
						</div>

						<ProgressHistory project={project} />
					</Footer>
				)}
			</>
		);
	};

	return (
		<GenericModal
			buttons={[
				{
					text: intl.formatMessage({id: 'common.update'}),
					callback: () => handleSave(),
					preventDefaultClose: true,
				},
			]}
			closeModal={closeModal}
			content={getContent()}
			footer={getFooter()}
			headerText={intl.formatMessage({id: 'common.update-progress'})}
			modalWidth={520}
		/>
	);
};
