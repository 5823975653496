import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;
	button {
		margin-left: 8px;
	}
`;

export const StatsContainer = styled.div`
	margin-right: 24px;
`;

export const StatContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 202px;
	padding: 16px;
	margin-bottom: 16px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 8px;
`;

export const StatValue = styled.div`
	font-size: 13px;
	font-weight: 500;
	color: ${props => props.color};
`;

export const StatTitle = styled.div`
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 12px;
`;

export const ChartContainer = styled.div`
	width: 612px;
	height: 300px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	padding: 16px;
`;
