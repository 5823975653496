import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal from '../../../../modal/generic_modal';
import * as tracking from '../../../../../tracking';
import PredictedEndDateModalChartSection from './PredictedEndDateModalChartSection';
import {ChartStatsContainer, PredictionInfo} from './PredictedEndDateModal.styled';
import PredictedEndDateModalStatsSection from './PredictedEndDateModalStatsSection';
import {ForecastTooltipFormulaRenderer} from '../../../../../components/ForecastTooltipFormulaRenderer';
import {convertStatsToMapPerDay, shouldShowReestimationWarning} from './PredictedEndDateModalLogic';
import {PROJECT_STATUS} from '../../../../../constants';
import {trackEvent, trackTooltip} from '../../../../../tracking/amplitude/TrackingV2';

const PredictedEndDateModal = ({viewer, project, endDateInfo, closeModal}) => {
	const projectDone = project.status === PROJECT_STATUS.DONE;

	const statsPerDayMap = convertStatsToMapPerDay(viewer.project.projectStats);

	const totalTaskEstimate = project.projectHealth.totalTaskEstimate;

	const showOutOfResourcesWarning = project.projectHealth.predictedAverageScopePerDay !== null;

	const showReestimationWarning = shouldShowReestimationWarning(totalTaskEstimate, statsPerDayMap, projectDone);

	useEffect(() => {
		tracking.trackPageAction('Predicted End Date Modal shown', {
			outOfResourcesWarningShown: showOutOfResourcesWarning,
			reestimationWarningShown: showReestimationWarning,
			projectId: project.id,
			projectName: project.name,
		});
		trackEvent('Predicted End Date Modal', 'Shown', {
			outOfResourcesWarningShown: showOutOfResourcesWarning,
			reestimationWarningShown: showReestimationWarning,
		});
	}, []);

	const getContent = () => {
		return (
			<>
				<PredictionInfo>{endDateInfo}</PredictionInfo>
				{showOutOfResourcesWarning ? (
					<PredictionInfo>
						According to the prediction, the project does not have enough resources to finish on time. The
						prediction assumes that you will continue to allocate the same amount of hours to the project per day as
						you have done so far.
					</PredictionInfo>
				) : null}
				{showReestimationWarning ? (
					<PredictionInfo>
						Recently, your project has been significantly reestimated. This has a negative impact on the accuracy of
						the prediction. For each day you work with your new estimates, the accuracy will improve.
					</PredictionInfo>
				) : null}
				<ChartStatsContainer>
					<PredictedEndDateModalStatsSection project={project} />
					<PredictedEndDateModalChartSection
						project={project}
						statsPerDayMap={statsPerDayMap}
						projectDone={projectDone}
					/>
				</ChartStatsContainer>
			</>
		);
	};

	const tooltipContent = () => {
		return (
			<ForecastTooltipFormulaRenderer
				items={[
					{
						description: `Predicted project end is based on remaining work, the team's future allocation, the current task performance, and team focus.`,
					},
					{
						description: `The optimistic prediction assumes the team focus and task performance will be on track. The predicted end is the probable outcome. The conservative prediction assumes the team focus and task performance will be off track.`,
					},
				]}
				translatedMessage={true}
			/>
		);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			content={getContent()}
			headerText={'Predicted project end'}
			headerInfo={tooltipContent()}
			onInfoIconShow={() => {
				tracking.trackInfoTooltipShown('Predicted end date modal');
				trackTooltip('Predicted End Date Modal Info');
			}}
			modalWidth={900}
		/>
	);
};

const PredictedEndDateModalQuery = graphql`
	query PredictedEndDateModal_Query($projectId: ID) {
		viewer {
			component(name: "predicted_end_date_modal")
			actualPersonId
			id
			...PredictedEndDateModal_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {PredictedEndDateModalQuery};

export default createFragmentContainer(PredictedEndDateModal, {
	viewer: graphql`
		fragment PredictedEndDateModal_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			project(internalId: $projectId) {
				projectStats {
					id
					date
					totalEstimateMinutes
					totalBaselineEstimateMinutes
					totalDoneEstimateMinutes
					performance
					optimisticPerformance
					conservativePerformance
				}
			}
		}
	`,
	project: graphql`
		fragment PredictedEndDateModal_project on ProjectType {
			id
			name
			status
			projectHealth {
				totalTaskEstimate
				predictedAverageScopePerDay
			}
			...PredictedEndDateModalChartSection_project
			...PredictedEndDateModalStatsSection_project
		}
	`,
});
