import React, {Component} from 'react';
import Util from '../../../forecast-app/shared/util/util';
import {ELEMENT_TYPE, PROJECT_STATUS} from '../../../constants';
import {Button, MSTeamsIcon} from 'web-components';
import GenericEmptyState from '../../../containers/project/empty_states/generic_empty_state';
import CreateProjectPersonMutation from '../../../mutations/create_project_person_mutation';
import ExportTeamToTeamsMutation from '../../../mutations/ExportTeamToTeamsMutation';
import LinkTeamToTeamsMutation from '../../../mutations/LinkTeamToTeamsMutation';
import UnlinkTeamsMutation from '../../../mutations/UnlinkTeamsMutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import PropTypes from 'prop-types';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {usingTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import DirectApi from '../../../directApi';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {FormattedHTMLMessage} from 'react-intl';
import {DeprecatedText as Text, FlexColumn} from '@forecast-it/design-system';
import ProjectSettingsTeamGroup from './ProjectSettingsTeamGroup';
import {getProjectIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

class ProjectSettingsTeam extends Component {
	constructor(props) {
		super(props);
		this.state = this.getFreshStateObject(this.props);
		this.handleShortcutProjectTeam = this.handleShortcutProjectTeam.bind(this);
		this.setComponentData(props);
		this.disabledRoles =
			props.viewer.project && props.viewer.project.rateCard
				? props.viewer.project.rateCard.disabledRoles.map(role => role.id)
				: [];
	}

	componentDidMount() {
		const name = this.props.viewer.project
			? this.props.viewer.project.name !== null && this.props.viewer.project.name !== ''
				? this.props.viewer.project.name
				: getProjectIndicatorString(
						this.props.viewer.project.companyProjectId,
						this.props.viewer.project.customProjectId
				  )
			: this.props.viewer.projectGroup.name;
		document.title = 'Team - ' + name + ' - Forecast';
		document.addEventListener('keydown', this.handleShortcutProjectTeam);

		if (this.props.viewer.company.teamsEnabled && this.props.viewer.project) this.getTeams();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.projectId !== nextProps.projectId) {
			this.setState(this.getFreshStateObject(nextProps));
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setComponentData(nextProps);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.searchFilterValue !== this.state.searchFilterValue) {
			if (this.state.searchFilterValue.length > 0) {
				let projectPeople = [];
				projectPeople = this.props.viewer.project
					? this.props.viewer.project.projectPersons.edges.map(p => p.node)
					: this.props.viewer.projectGroup.projects.edges[0].node.projectPersons.edges.map(p => p.node);

				const searchFilter = projectPerson => {
					const taskValue =
						projectPerson.person.firstName +
						projectPerson.person.lastName +
						projectPerson.person.email +
						(projectPerson.role ? projectPerson.role.name : '') +
						(projectPerson.person.role ? projectPerson.person.role.name : '');
					const searchValue = this.state.searchFilterValue.trim();
					return Util.normalizedIncludes(taskValue, searchValue);
				};
				const matchingProjectPeople = projectPeople.filter(searchFilter).length;
				trackEvent('Search', 'Results', {
					searchString: this.state.searchFilterValue,
					matching: matchingProjectPeople,
				});
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleShortcutProjectTeam);
	}

	setComponentData(props) {
		const projectPeopleIds = props.viewer.project
			? props.viewer.project.projectPersons.edges.map(p => p.node.person.id)
			: props.viewer.projectGroup.projects.edges[0].node.projectPersons.edges.map(p => p.node.person.id);

		this.peopleOptions = props.viewer.company.allPersons.edges
			.filter(person => !projectPeopleIds.includes(person.node.id))
			.map(person => ({
				value: person.node.id,
				label: person.node.firstName + ' ' + person.node.lastName,
				isTeam: false,
				profilePictureId: person.node.profilePictureId,
				profilePictureDefaultId: person.node.profilePictureDefaultId,
				nestedOptions: [],
			}))
			.sort((a, b) => {
				if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
				if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
				return 0;
			});

		this.nonEmptyTeams = props.viewer.company.teams.edges
			.filter(
				team =>
					team.node.teamPersons.edges.length !== 0 &&
					team.node.teamPersons.edges.filter(
						tp => tp.node.person.active && !projectPeopleIds.includes(tp.node.person.id)
					).length !== 0
			)
			.sort((a, b) => {
				if (a.node.name.toUpperCase() < b.node.name.toUpperCase()) return -1;
				if (a.node.name.toUpperCase() > b.node.name.toUpperCase()) return 1;
				return 0;
			});
		this.teamOptions = this.nonEmptyTeams.map(team => ({
			value: team.node.id,
			label: team.node.name,
			isTeam: true,
			nestedOptions: [],
		}));
	}

	getFreshStateObject(props) {
		return {
			searchFilterValue: '',
			showContactInfo: false,
			addPeopleSearchValue: '',
			msTeamsTeams: [{value: null, label: this.props.intl.formatMessage({id: 'common.loading'})}],
			retry: props.retry,
		};
	}

	handleSearchChange(val) {
		this.setState({searchFilterValue: val});
	}

	handleShortcutProjectTeam(e) {
		if (e.altKey && e.shiftKey && e.keyCode === 88) {
			this.clearFilters();
		}
	}

	clearFilters() {
		this.setState({
			searchFilterValue: '',
		});
		if (this.props.viewer.project) {
			localStorage.removeItem('project-team-filter-value-' + this.props.viewer.project.id + '-mainRole');
			localStorage.removeItem('project-team-filter-value-' + this.props.viewer.project.id + '-projectRole');
			localStorage.removeItem('project-team-filter-value-' + this.props.viewer.project.id + '-permission');
			localStorage.removeItem('project-team-filter-value-' + this.props.viewer.project.id + '-skill');
		} else {
			localStorage.removeItem('project-group-team-filter-value-' + this.props.viewer.projectGroup.id + '-mainRole');
			localStorage.removeItem('project-group-team-filter-value-' + this.props.viewer.projectGroup.id + '-projectRole');
			localStorage.removeItem('project-group-team-filter-value-' + this.props.viewer.projectGroup.id + '-permission');
			localStorage.removeItem('project-group-team-filter-value-' + this.props.viewer.projectGroup.id + '-skill');
		}
	}

	handleSelectPerson(option) {
		const showDisabledRoleWarningModal = () => {
			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<FlexColumn gap={'s'}>
						<Text type="heading" variant="xl">
							{this.props.intl.formatMessage({
								id: 'settings_team.role_disabled_warning_title',
							})}
						</Text>
						<Text>
							<FormattedHTMLMessage id="settings_team.role_disabled_warning_text" />
						</Text>
					</FlexColumn>
				),
			});
		};

		if (option.isTeam) {
			const callbackPositive = selectedPersons => {
				const onSuccess = () => {
					const {company} = this.props.viewer;
					const {isUsingProjectAllocation} = company;

					// CreateProjectPersonMutation currently only returns 1 of the added persons, so to ensure all are loaded, retry the entire page query.
					if (selectedPersons?.length > 1 && this.state.retry) {
						this.state.retry();
					}

					if (isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(company)) {
						const persons = this.props.viewer.company.allPersons.edges.filter(person =>
							selectedPersons.includes(person.node.id)
						);
						const personRoles = persons.map(person => person.node.role.id);
						showModal(
							{
								type: MODAL_TYPE.ALLOCATE_TEAM_MEMBERSV2,
								projectId: this.props.viewer.project ? this.props.viewer.project.id : undefined,
								projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : undefined,
								selectedTeamMembersIds: selectedPersons,
								closeModal: this.disabledRoles.some(role => personRoles.includes(role))
									? showDisabledRoleWarningModal
									: undefined,
							},
							true
						);
					}
				};
				const mutationObject = {personIds: selectedPersons};
				if (this.props.viewer.project) {
					mutationObject.projectId = this.props.viewer.project.id;
				} else {
					mutationObject.projectGroupId = this.props.viewer.projectGroup.id;
				}
				Util.CommitMutation(CreateProjectPersonMutation, mutationObject, onSuccess);
			};

			const team = this.props.viewer.company.teams.edges.find(team => team.node.id === option.value);
			if (team) {
				showModal({
					type: MODAL_TYPE.ADD_TEAM,
					companyId: this.props.viewer.company.id,
					initialTeam: option.value,
					creatingTeam: false,
					teams: this.nonEmptyTeams,
					projectId: this.props.viewer.project ? this.props.viewer.project.id : null,
					projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : null,
					callbackPositive: callbackPositive,
				});
			}
		} else {
			const onSuccess = response => {
				const roleId = response.createProjectPerson.roleId;
				const errors = response.createProjectPerson.errors;
				if (Util.checkForSageErrorAndShowModal(errors, false)) {
					return;
				}
				const {company} = this.props.viewer;
				const {isUsingProjectAllocation} = company;

				createToast({
					message: this.props.intl.formatMessage({id: 'settings_team.person-has-been-added'}),
					duration: 3000,
				});

				if (isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(company)) {
					showModal(
						{
							type: MODAL_TYPE.ALLOCATE_TEAM_MEMBERSV2,
							projectId: this.props.viewer.project ? this.props.viewer.project.id : undefined,
							projectGroupId: this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : undefined,
							selectedTeamMembersIds: [option.value],
							closeModal: this.disabledRoles.some(role => role === roleId)
								? showDisabledRoleWarningModal
								: undefined,
						},
						true
					);
				} else {
					if (this.disabledRoles.some(role => role === roleId)) {
						showDisabledRoleWarningModal();
					}
				}
			};
			if (this.props.viewer.project) {
				const person = this.props.viewer.company.allPersons.edges.find(person => person.node.id === option.value);
				if (person) {
					const roleId = person.node.role ? person.node.role.id : null;
					Util.CommitMutation(
						CreateProjectPersonMutation,
						{
							projectId: this.props.viewer.project.id,
							personId: option.value,
							roleId: roleId,
						},
						onSuccess
					);
				}
			} else {
				const person = this.props.viewer.company.allPersons.edges.find(person => person.node.id === option.value);
				if (person) {
					Util.CommitMutation(
						CreateProjectPersonMutation,
						{
							projectGroupId: this.props.viewer.projectGroup.id,
							personId: option.value,
							roleId: person.node.role ? person.node.role.id : null,
						},
						onSuccess
					);
				}
			}
		}
	}

	getPersonsTeam(projectPerson) {
		const personTeams = [];
		this.props.viewer.company.teams.edges.forEach(team => {
			const teamPersonIds = team.node.teamPersons.edges.map(tp => tp.node.person.id);
			if (teamPersonIds.includes(projectPerson.person.id)) {
				personTeams.push(team.node.name);
			}
		});
		return personTeams;
	}

	exportToTeams() {
		this.setState({exportingTeam: true});
		const teamName = `${this.props.viewer.project.companyProjectId} - ${this.props.viewer.project.name}`;

		Util.CommitMutation(ExportTeamToTeamsMutation, {projectId: this.props.viewer.project.id, teamName: teamName}, res => {
			this.setState({exportingTeam: false});

			// Create toast
			createToast({
				message: this.props.intl.formatMessage({
					id: res.exportTeamToTeams.project.teamsId
						? 'project_team.export_to_teams_success'
						: 'project_team.export_to_teams_failed',
				}),
				duration: 3000,
			});

			if (res.exportTeamToTeams.project.teamsId) {
				this.setState({
					msTeamsTeams: [...this.state.msTeamsTeams, {value: res.exportTeamToTeams.project.teamsId, label: teamName}],
				});
			}
		});
	}

	linkToTeams(team) {
		this.setState({exportingTeam: true});
		Util.CommitMutation(LinkTeamToTeamsMutation, {projectId: this.props.viewer.project.id, teamId: team.value}, res => {
			this.setState({exportingTeam: false});

			// Create toast
			createToast({
				message: this.props.intl.formatMessage({
					id: res.linkTeamToTeams.project.teamsId
						? 'project_team.link_to_teams_success'
						: 'project_team.link_to_teams_failed',
				}),
				duration: 3000,
			});
		});
	}

	unlinkTeams() {
		Util.CommitMutation(UnlinkTeamsMutation, {projectId: this.props.viewer.project.id});
	}

	getTeams() {
		DirectApi.Fetch('msteams/teams').then(teams => {
			this.setState({
				msTeamsTeams: teams.map(t => ({value: t.id, label: t.displayName})),
			});
		});
	}

	getTeamLink() {
		DirectApi.Fetch('msteams/link?teamId=' + this.props.viewer.project.teamsId).then(link => window.open(link.message));
	}

	getHeaderTitle() {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.team'})} content={[]} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader(isInGroup, projectLocked, personSelectorPeople, startingDate) {
		const {formatMessage} = this.props.intl;
		const rightContent = [],
			leftContent = [];
		if (
			this.props.viewer.project &&
			(this.props.viewer.project.status === PROJECT_STATUS.DONE ||
				this.props.viewer.project.status === PROJECT_STATUS.HALTED)
		) {
			const indicator = {
				type: ELEMENT_TYPE.INDICATOR,
				status: this.props.viewer.project.status,
			};
			leftContent.push(indicator);
		}
		rightContent.push({
			type: ELEMENT_TYPE.SEARCH_LAZY,
			value: this.state.searchFilterValue,
			onChange: this.handleSearchChange.bind(this),
		});
		if (hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE) && !isInGroup && !projectLocked) {
			rightContent.push({
				type: ELEMENT_TYPE.NESTED_DROPDOWN,
				options: personSelectorPeople,
				value: this.state.selectedPersonName,
				callback: this.handleSelectPerson.bind(this),
				placeholder: formatMessage({id: 'person_selector.placeholder'}),
				cy: 'add-people-to-project',
			});
		}

		if (this.props.viewer.company.teamsEnabled && this.props.viewer.project) {
			if (this.props.viewer.project.teamsId) {
				leftContent.push(
					<Button
						onClick={() => this.unlinkTeams()}
						iconPosition={'last'}
						icon={() => <MSTeamsIcon />}
						variant={Button.VARIANT.PURPLE_FILLED}
						size={Button.SIZE.STANDARD}
						showText={true}
					>
						{formatMessage({id: 'common.unlink'})}
					</Button>
				);

				const connectedTeam = this.state.msTeamsTeams.find(team => team.value === this.props.viewer.project.teamsId);

				leftContent.push(
					<span className="teams-text-label">
						{'Linked to: ' + (connectedTeam ? connectedTeam.label : 'unknown')}
					</span>
				);
				if (connectedTeam) {
					leftContent.push(
						<Button
							onClick={() => this.getTeamLink()}
							iconPosition={'last'}
							icon={() => <MSTeamsIcon />}
							variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
							size={Button.SIZE.STANDARD}
						>
							{formatMessage({id: 'project_team.go_to'})}
						</Button>
					);
				}
			} else {
				leftContent.push(
					<Button
						onClick={() => this.exportToTeams()}
						iconPosition={'last'}
						icon={() => <MSTeamsIcon />}
						variant={Button.VARIANT.PURPLE_FILLED}
						size={Button.SIZE.STANDARD}
						showText={true}
						disabled={!!this.state.exportingTeam}
					>
						{formatMessage({id: 'project_team.export_to_teams'})}
					</Button>
				);
				leftContent.push({
					type: ELEMENT_TYPE.DROPDOWN,
					dropdownOptions: this.state.msTeamsTeams,
					value: null,
					callback: this.linkToTeams.bind(this),
					placeholder: formatMessage({id: 'project_team.link_to_teams'}),
					disabled: !!this.state.exportingTeam,
				});
			}
		}

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={leftContent}
				rightContent={rightContent}
				noPadding={true}
			/>
		);
	}

	/**
	 * Splits projectPeople into two arrays - activePeople and deactivatedPeople
	 *
	 * Loops through projectPeople array and checks if person is active
	 * - If active, pushes to activePeople array
	 * - If inactive, pushes to deactivatedPeople array
	 *
	 * @param {Array} projectPeople - Array of project person objects
	 * @returns {Object} Object containing two arrays - activePeople and deactivatedPeople
	 */
	getPeopleGroups(projectPeople) {
		let activePeople = [];
		let deactivatedPeople = [];
		projectPeople.forEach(p => {
			if (p.person.active) {
				activePeople.push(p);
			} else {
				deactivatedPeople.push(p);
			}
		});
		return {activePeople, deactivatedPeople};
	}

	render() {
		const {formatMessage} = this.props.intl;
		const projectLocked =
			this.props.viewer.project !== undefined &&
			this.props.viewer.project !== null &&
			(this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED');
		const isInGroup = this.props.viewer.project && this.props.viewer.project.isInProjectGroup;
		const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
		const useTimeApproval = usingTimeApproval(this.props.viewer.company.useTimeApproval);
		const hasProjectPersonUpdateAccess = hasPermission(PERMISSION_TYPE.PROJECT_PERSON_UPDATE);
		const roles = this.props.viewer.company.roles.edges.filter(role => !this.disabledRoles.includes(role.node.id));
		const isProjectGroupPage = !!this.props.viewer.projectGroup;

		let projectPeople = [];
		if (this.props.viewer.project) {
			projectPeople = this.props.viewer.project.projectPersons.edges.map(p => p.node);
		} else {
			const projects = this.props.viewer.projectGroup.projects.edges;
			const rolesByPersonId = new Map();
			projects.forEach(project => {
				project.node.projectPersons.edges.forEach(projectPerson => {
					const personRoles = rolesByPersonId.get(projectPerson.node.person.id) || new Set();
					if (projectPerson.node.role) {
						personRoles.add(projectPerson.node.role.id);
					}
					rolesByPersonId.set(projectPerson.node.person.id, personRoles);
				});
			});
			this.props.viewer.projectGroup.projects.edges[0].node.projectPersons.edges.forEach(projectPerson => {
				if (rolesByPersonId.get(projectPerson.node.person.id).size > 1) {
					projectPeople.push({...projectPerson.node, hasMultipleRoles: true});
				} else {
					projectPeople.push(projectPerson.node);
				}
			});
		}

		const projectGroupProjectPersonsMap = new Map(),
			projectPersonProjectMap = new Map();
		if (this.props.viewer.projectGroup) {
			this.props.viewer.projectGroup.projects.edges.forEach(project => {
				project.node.projectPersons.edges.forEach(pp => {
					projectPersonProjectMap.set(pp.node.id, project.node);
					const key = pp.node.person.id;
					const projectPersonsList = projectGroupProjectPersonsMap.has(key)
						? projectGroupProjectPersonsMap.get(key)
						: [];
					projectPersonsList.push(pp);
					projectGroupProjectPersonsMap.set(key, projectPersonsList);
				});
			});
		}

		const personSelectorPeople = [
			{
				cy: 'team-members-option',
				value: 'team-members-option',
				label: formatMessage({id: 'settings.team-members'}),
				emptyText: formatMessage({id: 'people_selector.team_persons_option_empty_msg'}),
				options: this.peopleOptions,
				nestedOptions: this.peopleOptions,
			},
			{
				cy: 'your-teams',
				value: 'your-teams',
				label: formatMessage({id: 'people_selector.team_option_title'}),
				emptyText: formatMessage({id: 'people_selector.team_option_empty_msg'}),
				options: this.teamOptions,
				nestedOptions: this.teamOptions,
			},
		];

		if (this.state.searchFilterValue.length > 0) {
			const searchFilter = projectPerson => {
				const taskValue =
					projectPerson.person.firstName +
					projectPerson.person.lastName +
					projectPerson.person.email +
					(projectPerson.role ? projectPerson.role.name : '') +
					(projectPerson.person.role ? projectPerson.person.role.name : '');
				const searchValue = this.state.searchFilterValue.trim();
				return Util.normalizedIncludes(taskValue, searchValue);
			};

			projectPeople = projectPeople.filter(searchFilter);
		}

		let startingDate = null;
		if (
			this.props.viewer.project &&
			this.props.viewer.project.projectStartYear &&
			this.props.viewer.project.projectStartMonth &&
			this.props.viewer.project.projectStartDay
		) {
			// Is project and has start
			startingDate = Util.CreateNonUtcMomentDate(
				this.props.viewer.project.projectStartYear,
				this.props.viewer.project.projectStartMonth,
				this.props.viewer.project.projectStartDay
			);
		}

		let useManualAllocations;
		if (this.props.viewer.project && this.props.viewer.project.useManualAllocations) {
			useManualAllocations = true;
		} else if (this.props.viewer.projectGroup && this.props.viewer.projectGroup.useManualAllocations) {
			useManualAllocations = true;
		}

		let projectPeopleGroups = this.getPeopleGroups(projectPeople);

		return (
			<div className="section-content project-team">
				<div className="section-body">
					{this.props.children}
					{this.getHeader(isInGroup, projectLocked, personSelectorPeople, startingDate)}
					{projectPeople.length ? (
						<div>
							{projectPeopleGroups.activePeople && projectPeopleGroups.activePeople.length ? (
								<ProjectSettingsTeamGroup
									viewer={this.props.viewer}
									groupName={'Active'}
									initialShowMembers={true}
									projectPeople={projectPeopleGroups.activePeople}
									useTimeApproval={useTimeApproval}
									harvestEnabled={this.props.viewer.company.harvestEnabled}
									roles={roles}
									projectLocked={projectLocked}
									isAdmin={isAdmin}
									isInGroup={isInGroup}
									hasProjectPersonUpdateAccess={hasProjectPersonUpdateAccess}
									getPersonsTeam={this.getPersonsTeam.bind(this)}
									projectGroupProjectPersonsMap={projectGroupProjectPersonsMap}
									projectPersonProjectMap={projectPersonProjectMap}
									useManualAllocations={useManualAllocations}
									isProjectGroupPage={isProjectGroupPage}
									retry={this.props.retry}
								/>
							) : null}
							{projectPeopleGroups.deactivatedPeople && projectPeopleGroups.deactivatedPeople.length ? (
								<ProjectSettingsTeamGroup
									viewer={this.props.viewer}
									groupName={'Deactivated'}
									initialShowMembers={false}
									projectPeople={projectPeopleGroups.deactivatedPeople}
									useTimeApproval={useTimeApproval}
									harvestEnabled={this.props.viewer.company.harvestEnabled}
									roles={roles}
									projectLocked={projectLocked}
									isAdmin={isAdmin}
									isInGroup={isInGroup}
									hasProjectPersonUpdateAccess={hasProjectPersonUpdateAccess}
									getPersonsTeam={this.getPersonsTeam.bind(this)}
									projectGroupProjectPersonsMap={projectGroupProjectPersonsMap}
									projectPersonProjectMap={projectPersonProjectMap}
									useManualAllocations={useManualAllocations}
									isProjectGroupPage={isProjectGroupPage}
									retry={this.props.retry}
								/>
							) : null}
						</div>
					) : (
						<GenericEmptyState
							className="team-empty-state"
							headerText={formatMessage({id: 'empty_state.project_team.header_text'})}
							text={formatMessage({id: 'empty_state.project_team.text'})}
							iconClass="team"
						/>
					)}
				</div>
			</div>
		);
	}
}

ProjectSettingsTeam.propTypes = {
	projectId: PropTypes.string,
	selectedPersons: PropTypes.arrayOf(PropTypes.object),
	allocation: PropTypes.object,
	retry: PropTypes.func,
};

export default ProjectSettingsTeam;
