import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createFragmentContainer, graphql} from 'react-relay';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import Switch from '../../../forecast-app/shared/components/switch/switch';
import Styled from 'styled-components/macro';
import OrderedList from './ordered-list/OrderedList';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {cloneDeep} from 'lodash';
import Util from '../../../forecast-app/shared/util/util';
import UpdateSkillLevelsMutation from '../../../mutations/update_skill_levels_mutation';
import UpdateCompanyMutationModernMutation from '../../../mutations/update_company_mutation.modern';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';

const InputWrapper = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
`;

const SwitchLabel = Styled.div`
	font-weight: 400;
	font-size: 13px;
    margin-left:12px;
`;

const NewLevelWrapper = styled.div`
	margin-left: 8px;
	display: flex;
	align-items: center;
	padding-right: 8px;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_branding_purple};
	cursor: pointer;
	&:hover {
		color: ${CSS_CONSTANTS.color_purple_hover_active};
	}
`;

const ManageSkillLevelsModal = ({company, closeModal}) => {
	const {formatMessage} = useIntl();
	const {placeholders, allPersons} = company;

	const skillPlaceholders = placeholders.edges.map(edge => edge.node.skillPlaceholders).flat();
	const skillPersons = allPersons.edges.map(edge => edge.node.skillPersons).flat();

	const DEFAULT_SKILL_LEVELS = [
		{id: '', level: 1, description: formatMessage({id: 'settings_skills.skill_level_novice'})},
		{id: '', level: 2, description: formatMessage({id: 'settings_skills.skill_level_advanced_beginner'})},
		{id: '', level: 3, description: formatMessage({id: 'settings_skills.skill_level_competent'})},
		{id: '', level: 4, description: formatMessage({id: 'settings_skills.skill_level_proficient'})},
		{id: '', level: 5, description: formatMessage({id: 'settings_skills.skill_level_expert'})},
	];

	const [skillLevels, setSkillLevels] = useState(DEFAULT_SKILL_LEVELS);
	const [skillLevelsEnabled, setSkillLevelsEnabled] = useState(!!company.skillLevelsEnabled);
	const [usedSkillLevels, setUsedSkillLevels] = useState(new Map());

	const reorderSkillLevels = items => items.map((item, index) => (item.level = index + 1));

	useEffect(() => {
		const items = company.skillLevels?.map(skillLevel => ({
			id: skillLevel.id,
			level: skillLevel.level,
			description: skillLevel.description,
		}));

		for (let skillLevel of items) {
			const skillPersonsForLevelToDelete = skillPersons.filter(skillPerson => skillPerson.level?.id === skillLevel.id);
			const skillPlaceholdersForLevelToDelete = skillPlaceholders.filter(
				skillPlaceholder => skillPlaceholder.level?.id === skillLevel.id
			);
			const levelIsUsed = skillPersonsForLevelToDelete.length > 0 || skillPlaceholdersForLevelToDelete.length > 0;
			usedSkillLevels.set(skillLevel.id, levelIsUsed);
		}
		setUsedSkillLevels(usedSkillLevels);
		if (items?.length > 0) {
			setSkillLevels(items);
		}
	}, [company.skillLevels]);

	const saveSkillLevel = () => {
		if (company.skillLevelsEnabled !== skillLevelsEnabled) {
			Util.CommitMutation(UpdateCompanyMutationModernMutation, {
				skillLevelsEnabled: skillLevelsEnabled,
			});
		}

		Util.CommitMutation(
			UpdateSkillLevelsMutation,
			{
				skillLevels: skillLevels,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.changes_saved'}),
				});
			}
		);

		if (skillLevelsEnabled && !company.skillLevelsEnabled) {
			showModal(
				{
					type: MODAL_TYPE.ENABLE_SKILL_LEVELS_INFO_MODAL,
				},
				true
			);
		} else {
			closeModal();
		}
	};

	const onOrderChanged = data => {
		if (data.source && data.destination) {
			const items = cloneDeep(skillLevels);
			const [reorderedItem] = items.splice(data.source.index, 1);
			items.splice(data.destination.index, 0, reorderedItem);
			reorderSkillLevels(items);
			setSkillLevels(items);
		}
	};

	const onDescriptionChanged = (index, value) => {
		const items = cloneDeep(skillLevels);
		items[index] = {...items[index], description: value};
		setSkillLevels(items);
	};

	const addNewSkillLevel = () => {
		const items = cloneDeep(skillLevels);
		items.push({level: items.length + 1, description: '', id: ''});
		setSkillLevels(items);
	};

	const removeSkillLevel = (index, selectedForReplace) => {
		const deletedItem = skillLevels[index];
		const items = cloneDeep(skillLevels);
		items.splice(index, 1);
		reorderSkillLevels(items);

		if (selectedForReplace.skillLevel) {
			usedSkillLevels.set(selectedForReplace.skillLevel.id, true);
			setUsedSkillLevels(usedSkillLevels);

			const replacedWithIndex = selectedForReplace.skillLevel.level - 1;
			items[replacedWithIndex] = {
				...items[replacedWithIndex],
				replacesSkillLevelIds: [
					...(items[replacedWithIndex].replacesSkillLevelIds || []),
					...(deletedItem.replacesSkillLevelIds || []),
					deletedItem.id,
				],
			};
		}
		setSkillLevels(items);
	};

	const showDeleteSkillLevelModal = index => {
		const selectedItem = skillLevels[index];
		const levelIsUsed = usedSkillLevels.get(selectedItem.id);
		const items = cloneDeep(skillLevels);
		items.splice(index, 1);
		reorderSkillLevels(items);

		if (selectedItem.id || levelIsUsed) {
			showModal({
				type: MODAL_TYPE.DELETE_SKILL_LEVEL,
				skillLevels: items,
				levelToDelete: selectedItem,
				levelIsUsed: levelIsUsed,
				saveAndCloseModal: result => removeSkillLevel(index, result),
			});
		} else {
			setSkillLevels(items);
		}
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({
				id: company.skillLevelsEnabled ? 'common.manage_skill_levels' : 'common.enable_skill_levels',
			})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					preventDefaultClose: true,
					callback: saveSkillLevel,
					cy: 'button-save',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<InputWrapper>
							<Switch
								checked={skillLevelsEnabled}
								sliderWidth={28}
								sliderHeight={16}
								onChange={setSkillLevelsEnabled}
							/>
							<SwitchLabel>{formatMessage({id: 'settings_skills.use_levels_on_skills'})}</SwitchLabel>
						</InputWrapper>
					</Field>
					<Field>
						<OrderedList
							disabled={!skillLevelsEnabled}
							items={skillLevels}
							onOrderChanged={onOrderChanged}
							onDescriptionChanged={onDescriptionChanged}
							onRemoveItem={showDeleteSkillLevelModal}
						/>
						{skillLevelsEnabled && (
							<NewLevelWrapper onClick={addNewSkillLevel}>
								+ {formatMessage({id: 'settings_skills.add-level'})}
							</NewLevelWrapper>
						)}
					</Field>
				</ModalBody>
			}
		/>
	);
};

const manageSkillLevelsModalQuery = graphql`
	query ManageSkillLevelsModal_Query {
		viewer {
			actualPersonId
			component(name: "manage_skill_levels_modal")
			company {
				...ManageSkillLevelsModal_company
			}
		}
	}
`;

export {manageSkillLevelsModalQuery};

export default createFragmentContainer(ManageSkillLevelsModal, {
	company: graphql`
		fragment ManageSkillLevelsModal_company on Company {
			id
			skillLevelsEnabled
			skillLevels {
				id
				description
				level
			}
			allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
				edges {
					node {
						skillPersons {
							skill {
								id
							}
							level {
								id
								level
							}
						}
					}
				}
			}
			placeholders {
				edges {
					node {
						skillPlaceholders {
							skill {
								id
							}
							level {
								id
								level
							}
						}
					}
				}
			}
		}
	`,
});
