import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import SendTasksToAdoMutationModern from '../../mutations/send_tasks_to_ado_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import DirectApi from '../../directApi';

class CreateAdoWorkitemModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchingWorkitemTypes: true,
			selectedWorkitemType: null,
			workitemTypes: [],
			syncing: false,
		};
	}

	componentDidMount() {
		this.fetchWorkitemTypes();
	}

	fetchWorkitemTypes() {
		DirectApi.Fetch(`ado_workitem_types/${this.props.projectId}`).then(json => {
			const workitemTypes = json || [];
			const selectedWorkitemType = workitemTypes && workitemTypes.length > 0 ? workitemTypes[0].name : undefined;

			this.setState({fetchingWorkitemTypes: false, workitemTypes, selectedWorkitemType});
		});
	}

	handleWorkitemTypeChange(value) {
		this.setState({selectedWorkitemType: value ? value.value : undefined});
	}

	createWorkitem() {
		const onSuccess = response => {
			this.props.closeModal();
			if (this.props.callback) {
				this.props.callback();
			}
		};

		Util.CommitMutation(
			SendTasksToAdoMutationModern,
			{
				ids: this.props.taskIds,
				workitemType: this.state.selectedWorkitemType,
				projectId: this.props.projectId,
				adoState: this.props.adoState,
			},
			onSuccess
		);
		this.setState({syncing: true});
	}

	render() {
		const {formatMessage} = this.props.intl;

		const workitemTypeOptions = this.state.fetchingWorkitemTypes
			? [{label: formatMessage({id: 'common.loading'}), value: null, disabled: true}]
			: this.state.workitemTypes.map(workitemType => ({
					label: workitemType.name,
					value: workitemType.name,
					description: workitemType.description,
			  }));

		const content = (
			<div className="create-ado-workitem-modal" style={{width: '380px'}}>
				{this.state.syncing ? (
					<FormattedMessage id="common.loading_please_wait" />
				) : (
					<>
						<Dropdown
							value={this.state.selectedWorkitemType}
							label={formatMessage({id: 'modal.ado.ado_workitem_type'})}
							options={workitemTypeOptions}
							onChange={this.handleWorkitemTypeChange.bind(this)}
						/>
					</>
				)}
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						disabled: this.state.syncing,
					},
					{
						text: formatMessage({id: 'common.create'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.createWorkitem.bind(this),
						preventDefaultClose: true,
						disabled: this.state.syncing || !this.state.selectedWorkitemType,
					},
				]}
				headerText={formatMessage({id: 'modal.ado.create_x_in_ado'}, {tasks: this.props.taskIds.length})}
				content={content}
			/>
		);
	}
}
CreateAdoWorkitemModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	taskIds: PropTypes.array.isRequired,
	projectId: PropTypes.number.isRequired,
	adoState: PropTypes.string,
	callback: PropTypes.func,
};

export default injectIntl(CreateAdoWorkitemModal);
