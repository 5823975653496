/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type loginOauth_viewer$ref: FragmentReference;
declare export opaque type loginOauth_viewer$fragmentType: loginOauth_viewer$ref;
export type loginOauth_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +firstName: ?string,
  +lastName: ?string,
  +$refType: loginOauth_viewer$ref,
|};
export type loginOauth_viewer$data = loginOauth_viewer;
export type loginOauth_viewer$key = {
  +$data?: loginOauth_viewer$data,
  +$fragmentRefs: loginOauth_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "loginOauth_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'e37fa190520dbb1d01c2dac353db01d0';

module.exports = node;
