import Styled from 'styled-components/macro';

export const PageWrapper = Styled.div`
	padding: 24px 40px;
`;

export const ForecastLogoWrapper = Styled.div`
	margin: 0 0 0 -20px;
`;

export const PasswordFormWrapper = Styled.div`
	width:400px;
	display:flex;
	flex-direction:column;
	align-items:center;
	text-align: center;
	padding: 276px 0 0 0;
	margin:auto;
`;

export const PasswordTitleWrapper = Styled.h1`
	font-size: 20px;
	color:#4C4C58;
	margin: 16px 0;
`;

export const PasswordDescriptionWrapper = Styled.div`
	font-size: 13px;
	color: #A5A5AE;
	margin:0 0 24px 0;
`;

export const PasswordInputWrapper = Styled.div`
	width:100%;
	margin:8px 0 24px 0;
`;

export const PasswordErrorMessageWrapper = Styled.div`
	color:#d0021b;
    text-align:left;
	font-size: 13px;
	margin: 6px 0 0 0;

`;

export const SubmitPasswordWrapper = Styled.div`
	width:100%;
`;

export const SubmitTaskWrapper = Styled.div`
	display:flex;
	justify-content:flex-end;
	margin: 32px 0 0 0;
`;

export const MainSectionWrapper = Styled.div`
	display: flex;
	flex-grow: 1;
    flex-direction: column;
   	width:684px;
    margin: 8px 0 0 0px;
`;
