import {createFragmentContainer, graphql} from 'react-relay';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_FORMAT_DAY, MODULE_TYPES} from '../../../constants';
import React, {useMemo, useReducer, useState} from 'react';
import {useIntl} from 'react-intl';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import {Text} from '../../../Text.styled';
import RadioButton from '../../../components/new-ui/radio_button';
import Util from '../../../forecast-app/shared/util/util';
import DateRangePicker from '../../../components/new-ui/date-controls/date_range_picker';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import Input from '../../../components/new-ui/input_field';
import moment from 'moment';
import RichTextField from '../../../components/new-ui/rich_text_field';
import styled from 'styled-components';
import CreatePlaceholderAllocationMutation from '../../../mutations/create_placeholder_allocation_mutation';
import UpdatePlaceholderAllocationMutation from '../../../mutations/update_placeholder_allocation_mutation';
import DeletePlaceholderAllocationMutation from '../../../mutations/delete_placeholder_allocation_mutation';
import DuplicatePlaceholderAllocationMutation from '../../../mutations/duplicate_placeholder_allocation_mutation';
import Placeholder from '../../../forecast-app/shared/components/placeholder/placeholder';
import {Collapsible} from 'web-components';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {
	getAllocationTotal,
	getAverageWorkingMinutes,
	getDistributionArray,
	getPercentage,
	getProjectDates,
	getProjectGroupDates,
	getTotal,
	handleAllocationTotalChange,
	handleMutationSuccess,
	sumWeeklyMinutes,
} from './PlaceholderAllocationUtils';
import {generateStaffingId} from '../../../components/canvas-scheduling/placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import CheckboxRow from '../../../components/canvas-scheduling/components/CheckboxRow';
import Person from '../../../forecast-app/shared/components/person/person';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {isDateDisabled} from '../../../forecast-app/shared/util/DateUtil';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import AllocationTypeSelector from '../../../components/canvas-scheduling/components/AllocationTypeSelector';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {profilePicSrc} from '../../../directApi';

const DateOptionSection = styled.div`
	font-size: 14px;
	.radio-button-wrapper {
		margin-top: 10px;
		align-items: center;
	}
`;

const DistributionSection = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.input-container-v2 {
		width: 78px;
	}
	> div:not(:first-child) {
		margin-left: 8px;
	}
`;

export const DateSection = styled.div`
	display: flex;
	flex-direction: row;
	.date-range-picker-wrapper-new {
		padding-right: 8px;
		flex: 2;
	}
	.hours-input {
		padding-right: 8px;
		flex: 1;
	}
	.input-container-v2 {
		padding-right: 8px;
		flex: 1;
	}
`;

const PlaceholderAllocationModal = ({
	closeModal,
	viewer,
	isFromCreatePlaceholder = false,
	dragStartDate,
	dragEndDate,
	staffingModeActive,
	placeholderInput,
	placeholderAllocationInput,
}) => {
	const FULL_DURATION = 'full_duration';
	const CUSTOM_DURATION = 'custom_duration';

	const intl = useIntl();
	const {formatMessage, formatDate} = intl;
	const {project, projectGroup, company} = viewer;
	let {placeholder, placeholderAllocation, person} = company;
	const staffedPersonId = placeholderAllocationInput?.personId;

	const isReadOnly = useMemo(() => !hasPermission(PERMISSION_TYPE.ALLOCATION_UPDATE), []);

	// Override placeholder and placeholderAllocation that have been supplied as modal input
	// if we are in staffing mode
	if (staffingModeActive) {
		placeholder = placeholderInput;
		placeholderAllocation = placeholderAllocationInput;
	}

	const isUpdatingAllocation = !!placeholderAllocation;
	const dates = project ? getProjectDates(project) : getProjectGroupDates(projectGroup);

	const companyWeeklyMinutes = sumWeeklyMinutes(company);
	const initialDistributionArray = getDistributionArray(company);
	const hoursPerDay = Util.getHourEstimate(getAverageWorkingMinutes(initialDistributionArray, companyWeeklyMinutes) / 60);

	const initialState = useMemo(() => getInitialState(placeholderAllocation), []);

	const [startDate, setStartDate] = useState(dragStartDate || initialState.startDate);
	const [endDate, setEndDate] = useState(dragEndDate || initialState.endDate);
	const [forceUpdate, forceUpdateHourInput] = useReducer(x => x + 1, 0);

	const [total, setTotal] = useState(
		getAllocationTotal(
			{
				monday: initialState.distributionArray[0],
				tuesday: initialState.distributionArray[1],
				wednesday: initialState.distributionArray[2],
				thursday: initialState.distributionArray[3],
				friday: initialState.distributionArray[4],
				saturday: initialState.distributionArray[5],
				sunday: initialState.distributionArray[6],
			},
			startDate,
			endDate
		)
	);
	const [isSoft, setIsSoft] = useState(placeholderAllocation ? placeholderAllocation.isSoft : true);
	const [distributionArray, setDistributionArray] = useState(initialState.distributionArray);
	const [distributionPercentage, setDistributionPercentage] = useState(
		getPercentage(initialState.distributionArray, companyWeeklyMinutes)
	);
	const [timeOption, setTimeOption] = useState(dragStartDate && dragEndDate ? CUSTOM_DURATION : initialState.timeOption);
	const [descriptionText, setDescriptionText] = useState(initialState.descriptionText);

	const loopDate = moment().startOf('week').subtract(1, 'day');

	function getInitialState(placeholderAllocation) {
		let initialState;

		if (placeholderAllocation && placeholderAllocation.id) {
			initialState = {
				startDate: Util.CreateNonUtcMomentDateFromString(placeholderAllocation.startDate),
				endDate: Util.CreateNonUtcMomentDateFromString(placeholderAllocation.endDate),
				total: placeholderAllocation.total,
				distributionPercentage: 100,
				timeOption: CUSTOM_DURATION,
				distributionArray: [
					placeholderAllocation.monday,
					placeholderAllocation.tuesday,
					placeholderAllocation.wednesday,
					placeholderAllocation.thursday,
					placeholderAllocation.friday,
					placeholderAllocation.saturday,
					placeholderAllocation.sunday,
				],
				descriptionText: placeholderAllocation.description,
			};
		} else {
			initialState = {
				startDate: dates.projectStartDate,
				endDate: dates.projectEndDate,
				total: 0,
				distributionPercentage: 100,
				timeOption: FULL_DURATION,
				distributionArray: initialDistributionArray,
				descriptionText: null,
			};
		}
		return initialState;
	}

	function onSelectedTime() {
		const newTimeOption = timeOption === FULL_DURATION ? CUSTOM_DURATION : FULL_DURATION;
		if (newTimeOption === FULL_DURATION) {
			handleDateRangeChange(dates.projectStartDate, dates.projectEndDate);
		}
		setTimeOption(newTimeOption);
	}

	function updateDescription(description) {
		setDescriptionText(description);
	}

	function handleDateRangeChange(startDate, endDate) {
		setTotal(getTotal(distributionArray, startDate, endDate));
		setStartDate(startDate);
		setEndDate(endDate);
	}

	function onAllocationTotalChange(value) {
		const newTotal = value * 60;

		const result = handleAllocationTotalChange(
			newTotal,
			total ? distributionArray : initialDistributionArray,
			companyWeeklyMinutes,
			startDate,
			endDate
		);
		setDistributionArray(result.newDistributionArray);
		setDistributionPercentage(result.newDistributionPercentage);
		setTotal(result.newTotal);
		forceUpdateHourInput();
	}

	function handlePercentageChange(percentage) {
		const newDistributionArray = initialDistributionArray.map(minutes => {
			return Math.floor((minutes * percentage) / 100);
		});
		const total = getTotal(newDistributionArray, startDate, endDate);

		setTotal(total);
		setDistributionArray(newDistributionArray);
		setDistributionPercentage(percentage);
	}

	const handleDistributionChange = (index, value) => {
		if (index !== null && index !== undefined) {
			distributionArray[index] = Math.round(value * 60);
		}
		const percentage = getPercentage(distributionArray, companyWeeklyMinutes);
		const total = getTotal(distributionArray, startDate, endDate);

		setTotal(total);
		setDistributionArray(distributionArray);
		setDistributionPercentage(percentage);
	};

	function getDuplicateResponse() {
		return {
			duplicatePlaceholderAllocation: {
				placeholderAllocation: {
					node: {
						id: generateStaffingId(),
						startDate: startDate.format(DATE_FORMAT_DAY),
						endDate: endDate.format(DATE_FORMAT_DAY),
						description: descriptionText,
						monday: distributionArray[0],
						tuesday: distributionArray[1],
						wednesday: distributionArray[2],
						thursday: distributionArray[3],
						friday: distributionArray[4],
						saturday: distributionArray[5],
						sunday: distributionArray[6],
						placeholder: {id: placeholder.id},
					},
				},
			},
		};
	}

	function duplicatePlaceholderAllocation() {
		const input = {
			id: placeholderAllocation.id,
		};
		if (staffingModeActive) {
			handleMutationSuccess(getDuplicateResponse());
		} else {
			Util.CommitMutation(DuplicatePlaceholderAllocationMutation, input, res => {
				handleMutationSuccess(res);
			});
		}
		closeModal();
	}

	function deletePlaceholderAllocation() {
		const input = {
			id: placeholderAllocation.id,
		};
		if (staffingModeActive) {
			handleMutationSuccess(DeletePlaceholderAllocationMutation.getOptimisticResponse(input));
		} else {
			Util.CommitMutation(DeletePlaceholderAllocationMutation, input, res => {
				handleMutationSuccess(res);
			});
		}
		closeModal();
	}

	function createPlaceholderAllocation() {
		const input = {
			startDate: startDate.format(DATE_FORMAT_DAY),
			endDate: endDate.format(DATE_FORMAT_DAY),
			placeholderId: placeholder.id,
			monday: distributionArray[0],
			tuesday: distributionArray[1],
			wednesday: distributionArray[2],
			thursday: distributionArray[3],
			friday: distributionArray[4],
			saturday: distributionArray[5],
			sunday: distributionArray[6],
			description: descriptionText,
		};
		if (staffingModeActive) {
			const response = CreatePlaceholderAllocationMutation.getOptimisticResponse(input);
			response.createPlaceholderAllocation.placeholderAllocation.id = generateStaffingId();
			handleMutationSuccess(response);
		} else {
			const isFullDuration =
				moment(input.startDate).isSame(dates.projectStartDate, 'day') &&
				moment(input.endDate).isSame(dates.projectEndDate, 'day');
			const percentageAllocated = getPercentage(distributionArray, companyWeeklyMinutes);

			input.trackingOptions = {
				percentageAllocated,
				isFullDuration,
				isFromCreatePlaceholder,
			};

			Util.CommitMutation(CreatePlaceholderAllocationMutation, input, res => {
				handleMutationSuccess(res);
				createToast({
					duration: 5000,
					message: formatMessage({id: 'placeholder.allocation_created'}),
				});
			});
		}
	}

	function updatePlaceholderAllocation() {
		const input = {
			id: placeholderAllocation.id,
			startDate: startDate.format(DATE_FORMAT_DAY),
			endDate: endDate.format(DATE_FORMAT_DAY),
			placeholderId: placeholder.id,
			monday: distributionArray[0],
			tuesday: distributionArray[1],
			wednesday: distributionArray[2],
			thursday: distributionArray[3],
			friday: distributionArray[4],
			saturday: distributionArray[5],
			sunday: distributionArray[6],
			description: descriptionText,
		};
		if (staffingModeActive) {
			const mutationResponse = UpdatePlaceholderAllocationMutation.getOptimisticResponse(input);
			if (staffedPersonId) {
				// Set personId, projectId and projectGroupId, which exists if placeholder allocation has been staffed
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.personId = staffedPersonId;
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.projectId =
					placeholderAllocation.projectId;
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.projectGroupId =
					placeholderAllocation.projectGroupId;
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.startYear = startDate.year();
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.startMonth = startDate.month() + 1;
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.startDay = startDate.date();
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.endYear = endDate.year();
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.endMonth = endDate.month() + 1;
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.endDay = endDate.date();
				mutationResponse.updatePlaceholderAllocation.placeholderAllocation.node.isSoft = isSoft;
			}
			handleMutationSuccess(mutationResponse);
		} else {
			const onPlaceholderAllocationUpdate = res => {
				handleMutationSuccess(res);
				createToast({
					duration: 5000,
					message: formatMessage({id: 'placeholder.changes_saved'}),
				});
			};
			Util.CommitMutation(UpdatePlaceholderAllocationMutation, input, onPlaceholderAllocationUpdate);

			if (!placeholderAllocation.description && input.description) {
				tracking.trackEvent('Added note to placeholder allocation');
				trackEvent('Placeholder Allocation Note', 'Added');
			}
		}
	}

	const content = (
		<ModalBody>
			{!isUpdatingAllocation && isFromCreatePlaceholder && (
				<Field>
					<Text title={formatMessage({id: 'placeholder.allocate_description'})}>
						{formatMessage({id: 'placeholder.allocate_description'})}
					</Text>
				</Field>
			)}
			<Field>
				{hasFeatureFlag('placeholders_beta_changes') && staffedPersonId ? (
					<Person
						name={person.fullName}
						role={person.role?.name}
						showName={true}
						showRole={true}
						imageSize={'large'}
						imageSrc={profilePicSrc(person.profilePictureId)}
						cy={`assigned-person`}
					/>
				) : (
					<Placeholder placeholder={placeholder} cy={'placeholder-info'} />
				)}
			</Field>
			{!isUpdatingAllocation && isFromCreatePlaceholder && (
				<Field>
					<DateOptionSection>
						<RadioButton
							text={`Full duration (${formatDate(dates.projectStartDate, {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})} ${dates.projectStartDate && dates.projectEndDate ? '-' : ''} ${formatDate(
								dates.projectEndDate,
								{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								}
							)})`}
							stateValue={timeOption}
							value={FULL_DURATION}
							onSelected={onSelectedTime}
							locked={isReadOnly}
						/>
						<RadioButton
							text={'Custom'}
							stateValue={timeOption}
							value={CUSTOM_DURATION}
							onSelected={onSelectedTime}
							locked={isReadOnly}
						/>
					</DateOptionSection>
				</Field>
			)}
			<Field>
				<DateSection>
					<DateRangePicker
						startDate={startDate}
						endDate={endDate}
						handleDateRangeChange={handleDateRangeChange}
						label={formatMessage({id: 'common.date'})}
						locked={isReadOnly || timeOption === FULL_DURATION}
						clearable
						disabledDates={isDateDisabled}
					/>
					<HoursInput
						id="placeholder-allocation-modal-input"
						customClassName={'hours-input'}
						placeholder="0"
						label={formatMessage({id: 'scheduling.allocation_total'})}
						value={total / 60 || ''}
						forceUpdate={forceUpdate}
						mutation={onAllocationTotalChange}
						mutationValidChange
						modalView={true}
						disabled={isReadOnly}
					/>
					<Input
						id="placeholder-allocation-modal-percentage"
						placeholder="0"
						type="number"
						label={`% ${formatMessage({id: 'scheduling.allocation_modal.hours_pr_day'}, {hours: hoursPerDay})}`}
						suffix={'%'}
						value={distributionPercentage ? Math.round(distributionPercentage * 100) / 100 : ''}
						onChange={handlePercentageChange}
						alignTextRight={true}
						locked={isReadOnly}
					/>
				</DateSection>
			</Field>
			{hasFeatureFlag('capacity_planning_beta_2_improvements') &&
				hasFeatureFlag('placeholders') &&
				hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) &&
				!!staffedPersonId && (
					<Field>
						<AllocationTypeSelector
							onChange={() => {
								setIsSoft(!isSoft);
							}}
							isSoft={isSoft}
							disabled={isReadOnly}
						/>
					</Field>
				)}
			<Field>
				<Collapsible title={formatMessage({id: 'common.distribution'})} initiallyCollapsed={true}>
					<DistributionSection>
						{[null, null, null, null, null, null, null].map((element, index) => {
							loopDate.add(1, 'day');
							const dayIndex = loopDate.isoWeekday() - 1;
							return (
								<HoursInput
									id={`distribution-item-${index}`}
									key={`distribution-item-${index}`}
									placeholder="0"
									label={formatDate(loopDate.toDate(), {
										weekday: 'short',
									})}
									value={distributionArray[dayIndex] / 60 || ''}
									mutation={value => {
										handleDistributionChange(dayIndex, value);
									}}
									modalView={true}
									disabled={isReadOnly}
								/>
							);
						})}
					</DistributionSection>
				</Collapsible>
			</Field>
			<Field>
				<RichTextField
					textFieldLabel={formatMessage({id: 'common.notes'})}
					text={descriptionText}
					placeholder={formatMessage({id: 'common.notes'})}
					handleTextChange={updateDescription}
					readOnly={isReadOnly}
				/>
			</Field>
			{!hasFeatureFlag('capacity_planning_beta_2_improvements') &&
				hasFeatureFlag('placeholders_beta_changes') &&
				staffedPersonId && (
					<Field>
						<CheckboxRow
							cy={'placeholder-allocate-modal-soft'}
							checked={isSoft}
							highlightChecked
							onChange={() => {
								setIsSoft(!isSoft);
							}}
							disabled={isReadOnly}
							label={formatMessage({id: 'allocation.soft_allocation'})}
						/>
					</Field>
				)}
		</ModalBody>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.MEDIUM}
			headerText={formatMessage({id: 'placeholder.allocate'})}
			buttons={[
				{
					text:
						isUpdatingAllocation || !isFromCreatePlaceholder
							? formatMessage({id: 'common.cancel'})
							: formatMessage({id: 'common.skip'}),
					callback: closeModal,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: isUpdatingAllocation
						? formatMessage({id: 'common.save'})
						: formatMessage({id: 'placeholder.allocate'}),
					callback: isUpdatingAllocation ? updatePlaceholderAllocation : createPlaceholderAllocation,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					disabled: isReadOnly,
				},
			]}
			actionsMenuOptions={
				isUpdatingAllocation && !isReadOnly && (!staffingModeActive || !placeholderAllocation.personId)
					? [
							{
								text: formatMessage({id: 'common.delete'}),
								onClick: deletePlaceholderAllocation,
							},
							{
								text: formatMessage({id: 'common.duplicate'}),
								onClick: duplicatePlaceholderAllocation,
							},
					  ]
					: undefined
			}
		/>
	);
};

const placeholderAllocationModalQuery = graphql`
	query PlaceholderAllocationModal_Query(
		$projectId: ID
		$projectGroupId: ID
		$personId: ID
		$placeholderId: ID
		$placeholderAllocationId: ID
		$loadProject: Boolean!
		$loadProjectGroup: Boolean!
		$loadPlaceholder: Boolean!
		$loadAllocation: Boolean!
		$loadPerson: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "placeholder_allocation_modal")
			...PlaceholderAllocationModal_viewer
				@arguments(
					projectId: $projectId
					projectGroupId: $projectGroupId
					personId: $personId
					placeholderId: $placeholderId
					placeholderAllocationId: $placeholderAllocationId
					loadProject: $loadProject
					loadProjectGroup: $loadProjectGroup
					loadPlaceholder: $loadPlaceholder
					loadAllocation: $loadAllocation
					loadPerson: $loadPerson
				)
		}
	}
`;

export {placeholderAllocationModalQuery};

export default createFragmentContainer(PlaceholderAllocationModal, {
	viewer: graphql`
		fragment PlaceholderAllocationModal_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID"}
			projectGroupId: {type: "ID"}
			placeholderId: {type: "ID"}
			personId: {type: "ID"}
			placeholderAllocationId: {type: "ID"}
			loadProject: {type: "Boolean!"}
			loadProjectGroup: {type: "Boolean!"}
			loadPlaceholder: {type: "Boolean!"}
			loadAllocation: {type: "Boolean!"}
			loadPerson: {type: "Boolean!"}
		) {
			id
			email
			company {
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				person(id: $personId) @include(if: $loadPerson) {
					id
					fullName
					role {
						id
						name
					}
					profilePictureId
				}
				placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
					id
					name
					role {
						id
						name
					}
				}
				placeholderAllocation(placeholderAllocationId: $placeholderAllocationId) @include(if: $loadAllocation) {
					id
					startDate
					endDate
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					description
				}
			}
			project(internalId: $projectId) @include(if: $loadProject) {
				id
				name
				projectStartYear
				projectStartMonth
				projectStartDay
				projectEndYear
				projectEndMonth
				projectEndDay
			}
			projectGroup(globalId: $projectGroupId) @include(if: $loadProjectGroup) {
				id
				projects(first: 1000000) {
					edges {
						node {
							id
							projectStartYear
							projectStartMonth
							projectStartDay
							projectEndYear
							projectEndMonth
							projectEndDay
						}
					}
				}
			}
		}
	`,
});
