import React from 'react';
import {useIntl} from 'react-intl';
import WarningModal from './warning_modal';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../constants';

const WarningRoleUsedTimeRegModal = ({closeModal}) => {
	const intl = useIntl();

	const buttons = [
		{
			text: intl.formatMessage({id: 'common.ok'}),
			style: BUTTON_STYLE.OUTLINE_THICK,
			color: BUTTON_COLOR.VERYLIGHTGREY,
		},
	];

	return (
		<WarningModal
			closeModal={closeModal}
			buttons={buttons}
			warningMessageId={intl.formatMessage({id: 'warning.role_in_time_reg_header'})}
			warningInformation={[intl.formatMessage({id: 'warning.role_in_time_reg_message'})]}
		/>
	);
};

export default WarningRoleUsedTimeRegModal;
