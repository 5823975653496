import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Sentry from '@sentry/browser';
import { useHistory } from 'react-router-dom';
import { useSingleton } from '../../../forecast-app/shared/hooks/useSingleton';
function goto(url, history) {
    history.push(url);
}
function getButton(url, text, index, history) {
    const currentUrl = window.location.pathname;
    return (React.createElement("button", { key: index, className: 'tab' + (url === currentUrl ? ' selected' : ''), onClick: () => goto(url, history) }, text));
}
function MissionControlHeader({ viewer }) {
    useSingleton(() => {
        Sentry.configureScope(scope => {
            var _a, _b;
            scope.setUser({
                email: viewer.email || undefined,
                id: atob(viewer.actualPersonId || '').replace('Person:', ''),
                company_id: atob(((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.id) || '').replace('Company:', ''),
                name: viewer.firstName + ' ' + viewer.lastName,
                company_name: (_b = viewer.company) === null || _b === void 0 ? void 0 : _b.name,
            });
        });
    }, []);
    const history = useHistory();
    const urls = [
        '',
        '/company-stats',
        '/trials',
        '/companies-with-modules',
        '/person-list',
        '/features',
        '/ml-stats',
        '/restore-data',
        '/sage-signup',
    ];
    const texts = [
        'Impersonate',
        'Company stats',
        'Trials',
        'Modules',
        'Person list',
        'Feature Flags',
        'ML Stats',
        'Restore Data',
        'Sage Signup',
    ];
    const prefix = window.location.pathname.includes('missioncontrol') ? '/missioncontrol' : '/control';
    return (React.createElement("div", { className: "mission-control-header" }, urls.map((url, index) => getButton(prefix + url, texts[index], index, history))));
}
export default createFragmentContainer(MissionControlHeader, {
    viewer: graphql `
		fragment MissionControlHeader_viewer on Viewer {
			email
			actualPersonId
			firstName
			lastName
			company {
				id
				name
			}
		}
	`,
});
