import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import GenericModal from '../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import RadioButton from '../../components/new-ui/radio_button';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import Warning from '../../components/warning';

class WarningRadioButtonModal extends Component {
	constructor(props) {
		super(props);
		this.state = {selectedOptionKey: this.props.selectedOptionKey};
	}

	checkOption(key) {
		this.setState({
			selectedOptionKey: key,
		});
	}

	render() {
		const content = (
			<div className={'warning-radio-button-modal	'}>
				<Warning
					messageId={this.props.warningMessageId}
					important={this.props.irreversible}
					useInfoIcon={this.props.useInfoIcon}
				/>
				{this.props.warningInformation ? (
					<div className="warning-part-2">
						{this.props.warningInformation.map((info, index) => (
							<div className={'info-text'} key={index}>
								{info}
							</div>
						))}
						{this.props.radioOptions.map((option, index) => {
							return (
								<div className={'radio-row'}>
									<RadioButton
										cy={'lock-period-warning-option'}
										value={option.key}
										stateValue={this.state.selectedOptionKey}
										onSelected={this.checkOption.bind(this, option.key)}
										locked={option.locked}
									/>
									<div className={'radio-option-label' + (option.locked ? ' disabled' : '')}>
										{option.text}
									</div>
								</div>
							);
						})}
						{this.props.selectionText ? <div>{this.props.selectionText(this.state.selectedOptionKey)}</div> : null}
						{this.props.warningText ? (
							<div className={'warning-text-important'}>
								{this.props.warningText(this.state.selectedOptionKey)}
							</div>
						) : null}
					</div>
				) : null}
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.intl.formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						callback: this.props.callbackCancel,
						cy: 'close-warning-modal',
					},
					{
						text: this.props.intl.formatMessage({id: 'common.confirm'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.props.callbackPositive.bind(this, this.state.selectedOptionKey),
						disabled: this.state.selectedOptionKey === null,
						cy: 'confirm-warning-modal',
					},
				]}
				content={content}
			/>
		);
	}
}

WarningRadioButtonModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(WarningRadioButtonModal);
