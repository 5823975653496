import React from 'react';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import AllocationAssistanceModal, {AllocationAssistanceModalQuery} from './AllocationAssistanceModal';

const AllocationAssistanceModalRelayWrapper = props => {
	return (
		<ForecastQueryRenderer
			key="query-render-allocation-assistance-modall"
			query={AllocationAssistanceModalQuery}
			modal={true}
			variables={{projectId: props.project.id, performance: props.performance, focus: props.focus}}
			render={relayProps => {
				return <AllocationAssistanceModal {...relayProps} {...props} />;
			}}
		/>
	);
};

export default AllocationAssistanceModalRelayWrapper;
