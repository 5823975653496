/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingTotals_project$ref: FragmentReference;
declare export opaque type ProjectInvoicingTotals_project$fragmentType: ProjectInvoicingTotals_project$ref;
export type ProjectInvoicingTotals_project = {|
  +id: string,
  +name: ?string,
  +rateCard: ?{|
    +currency: ?string
  |},
  +budget: ?number,
  +budgetType: ?BUDGET_TYPE,
  +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
  +invoices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +status: ?string,
        +invoicedWithoutTaxProjectCurrency: ?number,
        +paidWithoutTaxProjectCurrency: ?number,
        +unpaidWithoutTaxProjectCurrency: ?number,
        +dueDay: ?number,
        +dueMonth: ?number,
        +dueYear: ?number,
      |}
    |}>
  |},
  +timeFinancialNumbers: ?{|
    +billableActualTimeAndExpenses: ?number
  |},
  +financialNumbers: ?{|
    +totalRevenueRecognition: ?number,
    +totalActualRevenueRecognition: ?number,
    +expenseRevenuePercentage: ?number,
  |},
  +expenseItems: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +approved: ?boolean,
        +billable: ?boolean,
        +partOfFixedPrice: ?boolean,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +price: ?number,
        +cost: ?number,
        +quantity: ?number,
      |}
    |}>
  |},
  +$refType: ProjectInvoicingTotals_project$ref,
|};
export type ProjectInvoicingTotals_project$data = ProjectInvoicingTotals_project;
export type ProjectInvoicingTotals_project$key = {
  +$data?: ProjectInvoicingTotals_project$data,
  +$fragmentRefs: ProjectInvoicingTotals_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "expenseItems"
        ]
      }
    ]
  },
  "name": "ProjectInvoicingTotals_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "rateCard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPeriodBudgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceTypeConnection",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoicedWithoutTaxProjectCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paidWithoutTaxProjectCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unpaidWithoutTaxProjectCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueYear",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "timeFinancialNumbers",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "searchQuery",
          "value": {
            "filters": [
              {
                "field": "ROLE",
                "operator": "NOT_IN",
                "value": "Um9sZTotMQ=="
              }
            ]
          }
        }
      ],
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableActualTimeAndExpenses",
          "storageKey": null
        }
      ],
      "storageKey": "financialNumbers(convertToProjectCurrency:true,searchQuery:{\"filters\":[{\"field\":\"ROLE\",\"operator\":\"NOT_IN\",\"value\":\"Um9sZTotMQ==\"}]})"
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalRevenueRecognition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalActualRevenueRecognition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expenseRevenuePercentage",
          "storageKey": null
        }
      ],
      "storageKey": "financialNumbers(convertToProjectCurrency:true)"
    },
    {
      "alias": "expenseItems",
      "args": null,
      "concreteType": "ExpenseItemTypeConnection",
      "kind": "LinkedField",
      "name": "__Project_expenseItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseItemTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partOfFixedPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '49a5cf9fcff2bada2344f9ab5e5a5716';

module.exports = node;
