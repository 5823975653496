/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SkillLevelRow_skillLevel$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SkillRow_company$ref: FragmentReference;
declare export opaque type SkillRow_company$fragmentType: SkillRow_company$ref;
export type SkillRow_company = {|
  +id: string,
  +skillLevelsEnabled: ?boolean,
  +skillLevels: ?$ReadOnlyArray<?{|
    +id: string,
    +level: ?number,
    +$fragmentRefs: SkillLevelRow_skillLevel$ref,
  |}>,
  +$refType: SkillRow_company$ref,
|};
export type SkillRow_company$data = SkillRow_company;
export type SkillRow_company$key = {
  +$data?: SkillRow_company$data,
  +$fragmentRefs: SkillRow_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillRow_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillLevelsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillLevel",
      "kind": "LinkedField",
      "name": "skillLevels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "level",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SkillLevelRow_skillLevel"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b237df05b486d1fd351ffdcde3182e8';

module.exports = node;
