/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectStageDropdown_viewer$ref = any;
export type ProjectStageDropdown_QueryVariables = {|
  projectId?: ?string
|};
export type ProjectStageDropdown_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +actualPersonId: ?string,
    +id: string,
    +$fragmentRefs: ProjectStageDropdown_viewer$ref,
  |}
|};
export type ProjectStageDropdown_Query = {|
  variables: ProjectStageDropdown_QueryVariables,
  response: ProjectStageDropdown_QueryResponse,
|};
*/


/*
query ProjectStageDropdown_Query(
  $projectId: ID
) {
  viewer {
    component(name: "project_stage__dropdown")
    actualPersonId
    id
    ...ProjectStageDropdown_viewer_2CgS8h
  }
}

fragment ProjectStageDropdown_viewer_2CgS8h on Viewer {
  company {
    modules {
      moduleType
      id
    }
    id
  }
  project(internalId: $projectId) {
    id
    status
    tasks(first: 100000) {
      edges {
        node {
          id
          name
          companyTaskId
          statusColumnV2 {
            name
            category
            id
          }
        }
      }
    }
    statusColumnsV2(first: 1000000) {
      edges {
        node {
          id
          category
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "project_stage__dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"project_stage__dropdown\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectStageDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ProjectStageDropdown_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectStageDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100000
                  }
                ],
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyTaskId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StatusColumnV2",
                            "kind": "LinkedField",
                            "name": "statusColumnV2",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tasks(first:100000)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000000
                  }
                ],
                "concreteType": "StatusColumnV2TypeConnection",
                "kind": "LinkedField",
                "name": "statusColumnsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatusColumnV2TypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusColumnV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "statusColumnsV2(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectStageDropdown_Query",
    "operationKind": "query",
    "text": "query ProjectStageDropdown_Query(\n  $projectId: ID\n) {\n  viewer {\n    component(name: \"project_stage__dropdown\")\n    actualPersonId\n    id\n    ...ProjectStageDropdown_viewer_2CgS8h\n  }\n}\n\nfragment ProjectStageDropdown_viewer_2CgS8h on Viewer {\n  company {\n    modules {\n      moduleType\n      id\n    }\n    id\n  }\n  project(internalId: $projectId) {\n    id\n    status\n    tasks(first: 100000) {\n      edges {\n        node {\n          id\n          name\n          companyTaskId\n          statusColumnV2 {\n            name\n            category\n            id\n          }\n        }\n      }\n    }\n    statusColumnsV2(first: 1000000) {\n      edges {\n        node {\n          id\n          category\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f0de4ba0a078db142fd5c6a080b7d7f';

module.exports = node;
