/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ManageSkillLevelsModal_company$ref: FragmentReference;
declare export opaque type ManageSkillLevelsModal_company$fragmentType: ManageSkillLevelsModal_company$ref;
export type ManageSkillLevelsModal_company = {|
  +id: string,
  +skillLevelsEnabled: ?boolean,
  +skillLevels: ?$ReadOnlyArray<?{|
    +id: string,
    +description: ?string,
    +level: ?number,
  |}>,
  +allPersons: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +skillPersons: ?$ReadOnlyArray<?{|
          +skill: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string,
            +level: ?number,
          |},
        |}>
      |}
    |}>
  |},
  +placeholders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +skillPlaceholders: ?$ReadOnlyArray<?{|
          +skill: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string,
            +level: ?number,
          |},
        |}>
      |}
    |}>
  |},
  +$refType: ManageSkillLevelsModal_company$ref,
|};
export type ManageSkillLevelsModal_company$data = ManageSkillLevelsModal_company;
export type ManageSkillLevelsModal_company$key = {
  +$data?: ManageSkillLevelsModal_company$data,
  +$fragmentRefs: ManageSkillLevelsModal_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Skill",
    "kind": "LinkedField",
    "name": "skill",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SkillLevel",
    "kind": "LinkedField",
    "name": "level",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "allPersons"
        ]
      }
    ]
  },
  "name": "ManageSkillLevelsModal_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillLevelsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillLevel",
      "kind": "LinkedField",
      "name": "skillLevels",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "allPersons",
      "args": null,
      "concreteType": "PersonTypeConnection",
      "kind": "LinkedField",
      "name": "__Company_allPersons_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersonTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillPerson",
                  "kind": "LinkedField",
                  "name": "skillPersons",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlaceholderTypeConnection",
      "kind": "LinkedField",
      "name": "placeholders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PlaceholderTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Placeholder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillPlaceholder",
                  "kind": "LinkedField",
                  "name": "skillPlaceholders",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7469051ad654ef456ffa90794b4638d3';

module.exports = node;
