import {createFragmentContainer, graphql} from 'react-relay';
import ValueCalculationsComponent from './ValueCalculationsComponent';
export default createFragmentContainer(ValueCalculationsComponent, {
	project: graphql`
		fragment ValueCalculations_project on ProjectType {
			id
			financialSourceSettings {
				plannedHours
				plannedRevenue
				plannedCost
				forecastHours
				forecastRevenue
				forecastCost
				actualHours
				actualRevenue
				actualCost
			}
		}
	`,
});
