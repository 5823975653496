import React, {useState} from 'react';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {FormattedMessage} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import {hideLoader, showLoader} from '../global_loader';
import Util from '../../forecast-app/shared/util/util';
import ImportHubspotClientsMutation from '../../mutations/ImportHubspotCompaniesMutation';
import {Checkbox} from 'web-components';
import {CheckBoxWrapper} from './HubspotImportClientsModal_style';
import Input from '../../components/new-ui/input_field';
import VisibilitySensor from 'react-visibility-sensor';
import DirectApi from '../../directApi';

const hubspotImportClientsModal = ({intl, closeModal, companyId, retry}) => {
	//state variables
	const [selectedCustomers, setSelectedCustomers] = useState([]);
	const [hubspotCustomers, setHubspotCustomers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [importing, setImporting] = useState(false);
	const [fetchingCustomers, setFetchingCustomers] = useState(false);
	const [searchingCustomers, setSearchingCustomers] = useState(false);
	const [fetchTimeout, setFetchTimeout] = useState(null);
	const [after, setAfter] = useState(false);
	const [searchString, setSearchString] = useState(null);
	const [showSynchedMessage, setShowSynchedMessage] = useState(true);

	// Keep this in case we want re-enable it
	// useEffect(() => {
	// 	DirectApi.Fetch(`hubspot/unimportedCompanies`).then(accounts => {
	// 		setHubspotCustomers(
	// 			accounts.list.sort((a, b) =>
	// 				a.properties.name.toUpperCase().trim() > b.properties.name.toUpperCase().trim() ? 1 : -1
	// 			)
	// 		);
	// 		setAfter(accounts.after);
	// 		setFetchingCustomers(false);
	// 	});
	// }, []);

	const getHubspotCompanies = (searchInput, after) => {
		let separator = '?';
		let searchParam = '';
		let afterParam = '';

		if (!searchInput) {
			setHubspotCustomers([]);
			setShowSynchedMessage(true);
			setFetchingCustomers(false);
			setSearchingCustomers(false);
			return;
		}

		if (searchInput) {
			searchParam = separator + `search=${searchInput}`;
			separator = '&';
		}
		if (after) {
			afterParam = separator + `after=${after}`;
		}

		DirectApi.Fetch(`hubspot/unimportedCompanies${searchParam}${afterParam}`).then(accounts => {
			const sortedList = accounts.list.sort((a, b) =>
				a.properties.name.toUpperCase().trim() > b.properties.name.toUpperCase().trim() ? 1 : -1
			);
			setFetchingCustomers(false);
			setSearchingCustomers(false);
			setAfter(accounts.after);
			setHubspotCustomers(sortedList);
			setShowSynchedMessage(false);
		});
	};

	const getHubspotCompaniesWithTimeout = (newSearchString, after) => {
		//send the request to hubspot service 1 second and a half after the user is done typing
		setSearchingCustomers(true);
		clearTimeout(fetchTimeout);
		setFetchTimeout(
			setTimeout(() => {
				getHubspotCompanies(newSearchString, after);
			}, 1500)
		);
	};

	const toggleSelectAll = () => {
		if (selectAll) {
			setSelectedCustomers([]);
			setSelectAll(false);
		} else {
			setSelectedCustomers(hubspotCustomers.map(customer => customer.id));
			setSelectAll(true);
		}
	};

	const toggleContact = hubspotCustomerId => {
		if (selectedCustomers.some(selectedCustomerId => selectedCustomerId === hubspotCustomerId)) {
			const newSelectedCustomers = selectedCustomers.filter(
				selectedCustomerId => selectedCustomerId !== hubspotCustomerId
			);
			setSelectedCustomers(newSelectedCustomers);
			setSelectAll(false);
		} else {
			const newSelectedCustomers = [...selectedCustomers, hubspotCustomerId];
			setSelectedCustomers(newSelectedCustomers);
		}
	};

	const syncContacts = () => {
		setImporting(true);
		showLoader();
		Util.CommitMutation(
			ImportHubspotClientsMutation,
			{
				companyId,
				clientIds: selectedCustomers,
				selectAll: selectAll,
			},
			() => {
				setImporting(false);
				if (retry) retry();
				closeModal();
				hideLoader();
			}
		);
	};

	const {formatMessage} = intl;

	const content = (
		<div className="integration-sync-clients-modal-content">
			<div className="integration-sync-clients-modal-description">
				<FormattedMessage id="integrations.hubspot.sync_clients.description" />
			</div>
			{fetchingCustomers ? (
				<InlineLoader />
			) : (
				<>
					<Input
						autoFocus={true}
						onChange={searchString => {
							getHubspotCompaniesWithTimeout(searchString, after);
							setSearchString(searchString);
						}}
					/>

					{
						<>
							<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
								<div className="integration-clients">
									{hubspotCustomers && hubspotCustomers.length > 0 ? (
										<>
											<div className="integration-client" key={'0'}>
												<CheckBoxWrapper>
													<Checkbox checked={selectAll} onClick={toggleSelectAll} />
												</CheckBoxWrapper>
												<FormattedMessage id="common.select_all" />
											</div>
											{hubspotCustomers.map(hubspotCustomer => {
												return (
													<div className="integration-client" key={hubspotCustomer.id}>
														<CheckBoxWrapper>
															<Checkbox
																checked={selectedCustomers.includes(hubspotCustomer.id)}
																onClick={() => {
																	toggleContact(hubspotCustomer.id);
																}}
															/>
														</CheckBoxWrapper>
														{hubspotCustomer.properties.name}
													</div>
												);
											})}
											{after && (
												<VisibilitySensor>
													{({isVisible}) => {
														if (isVisible) {
															getHubspotCompanies(searchString, after);
														}
														return <InlineLoader />;
													}}
												</VisibilitySensor>
											)}
										</>
									) : !showSynchedMessage ? (
										<FormattedMessage id="integrations.hubspot.all_contacts_synched" />
									) : null}
								</div>
							</CustomScrollDiv>
							{searchingCustomers && <InlineLoader />}
						</>
					}
				</>
			)}
		</div>
	);

	return (
		<GenericModal
			headerText={<FormattedMessage id="integrations.hubspot.sync_clients" />}
			closeModal={closeModal}
			className="integration-sync-clients-modal"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage(
						{id: 'common.create_x_clients'},
						{numOfClients: selectAll ? 'all' : selectedCustomers.length}
					),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: syncContacts.bind(this),
					disabled: importing || selectedCustomers.length === 0,
					defaultCallback: () => null,
					preventDefaultClose: true,
				},
			]}
			content={content}
		/>
	);
};

export default hubspotImportClientsModal;
