import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import moment from 'moment';

import {DATE_PICKER_STYLE} from '../../../../constants';
import {
	InvoiceDataForm,
	InvoiceDataLineOne,
	InvoiceDataLineThree,
	InvoiceDataLineTwo,
	InvoiceDatesInputContainer,
	InvoiceFinancialInputContainer,
	InvoiceIdInputContainer,
	InvoiceNameInputContainer,
	InvoiceProjects,
	InvoiceProjectsLabel,
	InvoiceProjectsList,
	ProjectIndicatorWrapper,
} from '../../../../styles/v2/invoice_modals_v2_styled';
import Dropdown from '../../../../forecast-app/shared/components/dropdowns/dropdown';
import DatePicker from '../../../../forecast-app/shared/components/date-picker/date_picker_v3';
import RichTextField from '../../../../components/new-ui/rich_text_field';
import {PercentageInput, BasicTextInput, InputLabel} from 'web-components';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FlexRow, DeprecatedText as Text} from '@forecast-it/design-system';
import DeprecatedProjectIndicatorJS from '../../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const InvoiceDetailsForm = ({
	isEditing,
	isCreatingBlankInvoice,
	setInvoice,
	currenciesDropdownOptions,
	projects,
	currency,
	selectedProjectsIds,
	setHasError,
	invoice,
	cantEditInvoice,
	isBulk,
	company,
}) => {
	const [invoiceName, setInvoiceName] = useState(invoice ? invoice.name : '');
	const [invoiceDate, setInvoiceDate] = useState(invoice ? invoice.date : moment());
	const [invoiceDueDate, setInvoiceDueDate] = useState(invoice ? invoice.dueDate : moment().add(30, 'days'));
	const [invoiceStatus, setInvoiceStatus] = useState(invoice ? invoice.status : '');
	const [invoiceRef, setInvoiceRef] = useState(invoice ? invoice.reference : '');
	const [invoiceDiscount, setInvoiceDiscount] = useState(invoice ? invoice.discount : 0);
	const [invoiceTax, setInvoiceTax] = useState(invoice ? invoice.tax : 0);
	const [invoiceCurrency, setInvoiceCurrency] = useState(invoice ? invoice.currency : currency);
	const [notes, setNotes] = useState(invoice ? invoice.notes : '');
	const [error, setError] = useState(null);

	const intl = useIntl();
	const {formatMessage} = intl;

	const selectedProjects = projects?.filter(project => selectedProjectsIds.includes(project.id)) || [];
	const maxNameLength =
		191 -
		(isBulk
			? 3 +
			  Math.max.apply(
					null,
					selectedProjects.map(project => project.name.length)
			  )
			: 0);

	const handleSetInvoiceName = name => {
		setInvoiceName(name);
		if (name.length > maxNameLength) {
			setError(formatMessage({id: 'errors.name-too-long'}));
			setHasError(true);
		} else {
			setError(null);
			setHasError(false);
		}
	};

	const checkValid = () => {
		return invoiceName !== '';
	};

	const [isValid, setIsValid] = useState(checkValid());

	const validateInputs = () => {
		setIsValid(checkValid);
	};

	useEffect(() => {
		setInvoice({
			companyInvoiceId: invoice ? invoice.companyInvoiceId : '',
			client: invoice ? invoice.client : undefined,
			name: invoiceName,
			date: invoiceDate,
			dueDate: invoiceDueDate,
			ProjectsIds: selectedProjectsIds,
			reference: invoiceRef,
			discount: invoiceDiscount,
			tax: invoiceTax,
			currency: invoiceCurrency,
			status: invoiceStatus,
			notes: notes,
			isExported: invoice ? invoice.isExported : false,
		});
		validateInputs();
	}, [
		invoiceName,
		invoiceDate,
		invoiceDueDate,
		invoiceRef,
		invoiceDiscount,
		invoiceTax,
		invoiceCurrency,
		invoiceStatus,
		notes,
	]);

	useEffect(() => {
		setHasError(!isValid);
	}, [isValid]);

	return (
		<InvoiceDataForm data-userpilot={'invoice-details-inputs'}>
			<InvoiceDataLineOne>
				{isEditing && (
					<InvoiceIdInputContainer>
						<InputLabel
							text="invoice id"
							child={
								<BasicTextInput
									id="invoice-id"
									type="number"
									label="invoice id"
									value={invoice.companyInvoiceId}
									disabled={true}
									modalView={true}
								/>
							}
						/>
					</InvoiceIdInputContainer>
				)}
				{isBulk && (
					<InvoiceIdInputContainer>
						<InputLabel
							text="invoice id"
							child={
								<BasicTextInput
									id="invoice-id"
									type="number"
									label="invoice id"
									value={
										company.invoiceLastSequence +
										1 +
										' - ' +
										(company.invoiceLastSequence + selectedProjectsIds.length)
									}
									disabled={true}
									modalView={true}
								/>
							}
						/>
					</InvoiceIdInputContainer>
				)}
				<InvoiceNameInputContainer>
					<InputLabel
						text={
							isBulk
								? formatMessage({id: 'invoicing.bulk_name'})
								: formatMessage({id: 'project_section.invoice_name'})
						}
						required
						child={
							<BasicTextInput
								shouldFocus
								placeholder={
									isBulk
										? formatMessage({id: 'invoicing.bulk_name'})
										: formatMessage({id: 'project_section.invoice_name'})
								}
								value={invoiceName}
								onChange={handleSetInvoiceName}
								disabled={cantEditInvoice}
								hasError={error}
								// autoFocus={true}
								// onEnter={this.onNameFieldEnter.bind(this)}

								userpilot={'invoice-name-input'}
								modalView={true}
								cy={'invoice-name'}
							/>
						}
					/>
					{error && <div className="error-message">{error}</div>}
				</InvoiceNameInputContainer>
				<InvoiceDatesInputContainer>
					<DatePicker
						startDate={invoiceDate}
						label={formatMessage({id: 'invoicing.invoice_date'})}
						handleDateRangeChange={setInvoiceDate}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						isSingleDatePicker={true}
						disabled={cantEditInvoice}
					/>
				</InvoiceDatesInputContainer>
				<InvoiceDatesInputContainer>
					<DatePicker
						startDate={invoiceDueDate}
						label={formatMessage({id: 'common.due_date'})}
						handleDateRangeChange={setInvoiceDueDate}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						isSingleDatePicker={true}
						disabled={cantEditInvoice}
					/>
				</InvoiceDatesInputContainer>
			</InvoiceDataLineOne>
			<InvoiceDataLineTwo>
				{!isCreatingBlankInvoice && (
					<CustomScrollDiv horizontalContent width={'420px'} marginRight={'10px'}>
						<InvoiceProjects>
							<InvoiceProjectsLabel>{formatMessage({id: 'common.projects'})}</InvoiceProjectsLabel>
							<InvoiceProjectsList>
								{selectedProjects.length === 1 && !!selectedProjects[0] ? (
									<FlexRow gap={'s'}>
										<FlexRow flex="0 0 auto">
											<DeprecatedProjectIndicatorJS project={selectedProjects[0]} disableLink={true} />
										</FlexRow>
										<Text variant="bold">{selectedProjects[0].name}</Text>
									</FlexRow>
								) : (
									selectedProjects.map(project => (
										<ProjectIndicatorWrapper>
											<DeprecatedProjectIndicatorJS project={project} disableLink={true} />
										</ProjectIndicatorWrapper>
									))
								)}
							</InvoiceProjectsList>
						</InvoiceProjects>
					</CustomScrollDiv>
				)}
				{isEditing && (
					<InvoiceFinancialInputContainer>
						<Dropdown
							label={formatMessage({id: 'common.client'})}
							value={invoice.client ? invoice.client.id : null}
							options={invoice.client ? [{value: invoice.client.id, label: invoice.client.name}] : []}
							onChange={() => {}}
							disabled={true}
						/>
					</InvoiceFinancialInputContainer>
				)}
				<InvoiceFinancialInputContainer>
					<InputLabel
						text={formatMessage({id: 'project_section.xero_invoice_reference'})}
						child={
							<BasicTextInput
								placeholder={formatMessage({id: 'project_section.xero_invoice_reference'})}
								value={invoiceRef}
								onChange={invoiceRef => setInvoiceRef(invoiceRef)}
								disabled={cantEditInvoice}
								modalView={true}
							/>
						}
					/>
				</InvoiceFinancialInputContainer>
				{!isEditing && (
					<InvoiceFinancialInputContainer>
						<InputLabel
							text={formatMessage({id: 'project_section.xero_modal_table_discount'})}
							child={
								<PercentageInput
									placeholder={formatMessage({id: 'project_section.xero_modal_table_discount'})}
									value={invoiceDiscount || 0}
									callback={invoiceDiscount => setInvoiceDiscount(invoiceDiscount * 100)}
									modalView={true}
									showDecimals={true}
									maxLength={3}
								/>
							}
						/>
					</InvoiceFinancialInputContainer>
				)}
				{!isEditing && (
					<InvoiceFinancialInputContainer>
						<InputLabel
							text={formatMessage({id: 'common.tax'})}
							child={
								<PercentageInput
									maxLength={3}
									placeholder={formatMessage({id: 'common.tax'})}
									type="number"
									value={invoiceTax || 0}
									callback={invoiceTax => setInvoiceTax(invoiceTax * 100)}
									modalView={true}
									showDecimals={true}
								/>
							}
						/>
					</InvoiceFinancialInputContainer>
				)}
				<InvoiceFinancialInputContainer>
					<Dropdown
						onChange={option => setInvoiceCurrency(option.value)}
						options={currenciesDropdownOptions}
						value={invoiceCurrency}
						label={formatMessage({id: 'settings_details.currency_section'})}
						placeholder={formatMessage({id: 'settings_details.currency_section'})}
						disabled={isEditing}
						// disabled={cantUserEditAllocation || isEditedAllocationIdleTimeAndDisabled}
					/>
				</InvoiceFinancialInputContainer>
				{isEditing && (
					<InvoiceFinancialInputContainer>
						<Dropdown
							label={formatMessage({id: 'common.status'})}
							value={invoice.status}
							options={[
								{value: 'DRAFT', label: formatMessage({id: 'common.draft'})},
								{value: 'APPROVED', label: formatMessage({id: 'common.approved'})},
								{value: 'SENT', label: formatMessage({id: 'common.sent'})},
							]}
							onChange={option => setInvoiceStatus(option.value)}
							disabled={invoice.isExported}
							userpilot={'invoice-status-dropdown'}
						/>
					</InvoiceFinancialInputContainer>
				)}
			</InvoiceDataLineTwo>
			<InvoiceDataLineThree>
				<RichTextField
					textFieldLabel={formatMessage({id: 'common.notes'})}
					text={notes}
					placeholder={formatMessage({id: 'common.notes'})}
					handleTextChange={notes => setNotes(notes)}
					hideStylingOptions={true}
					readOnly={cantEditInvoice}
					maxHeight={250}
				/>
			</InvoiceDataLineThree>
		</InvoiceDataForm>
	);
};

export default InvoiceDetailsForm;
