import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import Moment from 'moment';
import {DropdownLabel, TextArea} from '../../styles/components/timesheets/add_time_entry_component_styled';
import {versionManager} from '../../version_manager';
import MissionControlHeader from './mission_control_header';
import InputField from '../../components/new-ui/input_field';
import GenericModal from '../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, TIERS} from '../../constants';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import Checkbox from '../../components/new-ui/check_box';
import UpdateFeatureFlag from '../../mutations/mission_control/feature_flag_mutation';
import Util from '../../forecast-app/shared/util/util';
import Toggle from '../../components/inputs/toggle';
import DirectApi from '../../directApi';
import {createToast} from '../../forecast-app/shared/components/toasts/toast';
import {DeprecatedButton} from '@forecast-it/design-system';
import {BulkUsersModal} from './bulk_users_modal';
import {CompanyFeatureFlagsModal} from './company_feature_flags_modal';
import {Text} from '@forecast-it/design-system';

const missionControlFeatureFlagShowRolledOut = 'mission-control-feature-flag-showRolledOut';

const missionControlFeatureFlagTeam = 'missin-control-featture-flag-team';

class featureFlags extends Component {
	constructor(props) {
		super(props);

		const allPersonsByCompany = {};
		const allCompanies = [];
		const activeCompanyIds = [];

		this.props.viewer.missionControlData.companies.forEach(company => {
			const contactEmail = company.contactEmail || ''; //can be null
			if (!company.accessBlocked && company.tier !== TIERS.TRIAL && !contactEmail.includes('@forecast')) {
				activeCompanyIds.push(btoa(`CompanyType:${company.companyId}`));
			}
		});

		this.props.viewer.missionControlData.otherPersons.forEach(p => {
			if (allPersonsByCompany[p.companyId]) {
				const persons = allPersonsByCompany[p.companyId];
				persons.push(p);
				allPersonsByCompany[p.companyId] = persons;
			} else {
				const isActive = activeCompanyIds.includes(p.companyId);
				const x = {value: p.companyId, label: p.companyName + (isActive ? ' (active)' : ''), active: isActive};
				allCompanies.push(x);
				allPersonsByCompany[p.companyId] = [p];
			}
		});

		const collapsed = this.props.viewer.missionControlData.availableFeatures.map(f => {
			return f.key;
		});

		let team = Util.localStorageGetItem(missionControlFeatureFlagTeam);
		if (!team) {
			team = 'ALL';
		}

		const showRolledOut = Util.localStorageGetItemWithDefault(missionControlFeatureFlagShowRolledOut, 'true') === 'true';

		this.state = {
			collapsed,
			deleteClickedForId: null,
			showAddCustomer: false,
			showEditCustomer: false,
			customerData: null,
			allPersonsByCompany,
			allCompanies,
			showPersons: [],
			showRolledOut,
			selectedPersons: [],
			selectedCustomer: null,
			selectAllPersons: false,
			currentFeature: null,
			currentReleaseTitle: null,
			currentReleaseDescription: '',
			bulkCompanyIdInput: '',
			bulkUserIdInput: '',
			team,
			availableFeatureFlags: this.props.viewer.availableFeatureFlags.map(flag => flag.key),
			loadingFlagChange: false,
			showPublishRelease: false,
			showBulk: false,
			showBulkUsers: false,
			featureFlagsCompanyId: null,
		};
	}

	componentDidMount() {}

	handleCompany(e) {
		this.setState({featureFlagsCompanyId: e.value});
	}
	handleTeam(e) {
		const team = e.value;
		Util.localStorageSetItem(missionControlFeatureFlagTeam, team);
		this.setState({team});
	}
	handleRolledOut(e) {
		const showRolledOut = !!e.currentTarget.checked;
		Util.localStorageSetItem(missionControlFeatureFlagShowRolledOut, showRolledOut);
		this.setState({...this.state, showRolledOut});
	}

	editCustomer(data, key, name) {
		const specificPersons = data.specificUsers.map(p => p.id);
		const personOptions = this.state.allPersonsByCompany[data.id]?.filter(p => !specificPersons.includes(p.id)) || [];
		this.setState({
			showEditCustomer: true,
			customerData: data,
			selectAllPersons: data.allUsers,
			selectedCustomer: data.id,
			selectedPersons: specificPersons,
			showPersons: personOptions.map(p => {
				return {value: p.id, label: this.getName(p)};
			}),
			currentFeature: {key, name},
		});
	}

	showAddCustomer(key, name) {
		this.setState({showAddCustomer: true, currentFeature: {key, name}});
	}

	getUsers(users) {
		return (
			<div>
				{users.map(user => {
					return <div key={user.id}>{this.getNameV2(user)}</div>;
				})}
			</div>
		);
	}

	getCompany(data, key, name) {
		return (
			<tr key={data.id} className="feature-flag-group">
				<td>{data.name}</td>
				<td>{data.allUsers ? 'All users' : this.getUsers(data.specificUsers)}</td>
				<td>{data.optedOutUsers ? this.getUsers(data.optedOutUsers) : 'None'}</td>
				<td>
					<div className="edit-company-container">
						<div className="edit-company" onClick={this.editCustomer.bind(this, data, key, name)} />
					</div>
				</td>
			</tr>
		);
	}

	toggleActivities(key) {
		if (this.state.collapsed.some(c => c === key)) {
			this.setState({collapsed: this.state.collapsed.filter(c => c !== key)});
		} else {
			this.setState({collapsed: [...this.state.collapsed, key]});
		}
	}

	getFeatureFlag(featureToCompaniesMap, featureFlag) {
		const {key, name, color, rolledOut, rolledOutDate, cleanedUp, description, type} = featureFlag;
		const expectedRollOutDate = featureFlag.expectedRollOutDate ? new Date(featureFlag.expectedRollOutDate) : null;
		const rollOutDateExpired = expectedRollOutDate ? expectedRollOutDate < new Date() : false;
		const values = Object.values(featureToCompaniesMap[key]);

		let content;
		if (values && values.length > 0) {
			content = (
				<div>
					{rolledOut && <h3>Everyone has access, but some companies still has it explicitly configured:</h3>}
					<table className="feature-flag-group-table">
						<thead>
							<tr>
								<th className="feature-flag-group-table-width">Company</th>
								<th className="feature-flag-group-table-width">Users</th>
								<th className="feature-flag-group-table-width">Users opted out</th>
							</tr>
						</thead>
						<tbody>{values.map(d => this.getCompany(d, key, name))}</tbody>
					</table>
				</div>
			);
		} else if (rolledOut) {
			content = <h3>Everyone has access to this feature.</h3>;
		} else {
			content = <h3>No one has access to this feature yet.</h3>;
		}
		const collapsed = this.state.collapsed.some(c => c === key);
		return (
			<div key={key} className="feature-flag-group" style={{backgroundColor: color}}>
				<div className="new-company-container">
					{type && <div className={type} />}
					<h2>{name}</h2>
					{expectedRollOutDate && (
						<h3 className={rollOutDateExpired ? 'expired' : 'good'}>{expectedRollOutDate.toLocaleDateString()}</h3>
					)}
					{rolledOut ? (
						<>
							<div
								onClick={this.toggleActivities.bind(this, key)}
								className={'arrow' + (collapsed ? '' : ' expanded')}
							/>
							<div
								style={{
									margin: '0 30px 0 auto',
									backgroundColor: 'white',
									padding: '12px',
									borderRadius: '12px',
								}}
							>
								Rolled Out ({rolledOutDate ? rolledOutDate.substring(0, 10) : 'No Date'}) ✅ <br />
								Cleaned Up {cleanedUp ? '✅' : '❌'}
							</div>
						</>
					) : (
						<>
							<div className="new-company" onClick={this.showAddCustomer.bind(this, key, name)} />
							<div
								onClick={this.toggleActivities.bind(this, key)}
								className={'arrow' + (collapsed ? '' : ' expanded')}
							/>
							<div style={{paddingLeft: '20px'}}>Companies: {values.filter(v => v.allUsers).length}</div>
							<div style={{paddingLeft: '20px'}}>
								Individual users:{' '}
								{values
									.filter(v => !v.allUsers)
									.reduce((prev, current) => current.specificUsers.length + prev, 0)}
							</div>
							<div style={{paddingLeft: '20px'}}>
								Users opted out:{' '}
								{values.reduce(
									(acc, current) => (current.optedOutUsers ? current.optedOutUsers.length : 0) + acc,
									0
								)}
							</div>
							<div style={{margin: '0 30px 0 auto'}}>
								<span>Active for your company: </span>
								<Toggle
									checked={this.state.availableFeatureFlags.includes(key)}
									onChange={
										!this.state.loadingFlagChange ? this.toggleFeatureFlag.bind(this, key) : () => null
									}
								/>
							</div>
						</>
					)}
				</div>
				{!collapsed && (
					<>
						{description && <div style={{fontStyle: 'italic', marginBottom: '10px'}}>{description}</div>}
						{content}
					</>
				)}
			</div>
		);
	}

	setPersonsToShow(value) {
		this.setState({
			showPersons: this.state.allPersonsByCompany[value.value].map(p => {
				return {value: p.id, label: this.getName(p)};
			}),
			selectedCustomer: value.value,
			selectedPersons: [],
		});
	}

	getName(person) {
		return (
			person.firstName + (person.lastName ? ' ' + person.lastName : '') + (person.email ? ' (' + person.email + ')' : '')
		);
	}

	getNameV2(person) {
		return person.name + (person.email ? ' (' + person.email + ')' : '');
	}

	setSelectedPerson(person) {
		const selectedPersons = this.state.selectedPersons;
		selectedPersons.push(person.value);

		const updatedShowPersons = this.state.showPersons.filter(p => !selectedPersons.includes(p.value));

		this.setState({selectedPersons, showPersons: updatedShowPersons});
	}

	closeModal() {
		this.setState({
			currentFeature: null,
			showAddCustomer: false,
			showEditCustomer: false,
			selectAllPersons: false,
			selectedCustomer: null,
			selectedPersons: [],
			showPersons: [],
			featureFlagsCompanyId: null,
		});

		this.hideBulk();
		this.hideBulkUsers();
		this.hidePublishRelease();
	}

	saveNewCustomerAccess() {
		if (this.state.selectAllPersons) {
			Util.CommitMutation(UpdateFeatureFlag, {
				featureFlag: this.state.currentFeature.key,
				companyId: this.state.selectedCustomer,
				allUsers: true,
			});
		} else if (this.state.selectedPersons.length > 0) {
			Util.CommitMutation(UpdateFeatureFlag, {
				featureFlag: this.state.currentFeature.key,
				companyId: this.state.selectedCustomer,
				persons: this.state.selectedPersons,
			});
		} else {
			this.removeCustomerAccess();
		}
	}

	toggleFeatureFlag(key) {
		this.setState({loadingFlagChange: true});
		if (this.state.availableFeatureFlags.includes(key)) {
			const onSuccess = () => {
				const featureFlags = this.state.availableFeatureFlags;
				featureFlags.splice(featureFlags.indexOf(key), 1);
				this.setState({availableFeatureFlags: featureFlags, loadingFlagChange: false});
			};
			Util.CommitMutation(
				UpdateFeatureFlag,
				{
					featureFlag: key,
					companyId: this.props.viewer.company.id,
					removeAccess: true,
				},
				onSuccess
			);
		} else {
			const onSuccess = () => {
				const featureFlags = this.state.availableFeatureFlags;
				featureFlags.push(key);
				this.setState({availableFeatureFlags: featureFlags, loadingFlagChange: false});
			};
			Util.CommitMutation(
				UpdateFeatureFlag,
				{
					featureFlag: key,
					companyId: this.props.viewer.company.id,
					allUsers: true,
				},
				onSuccess
			);
		}
	}

	removeCustomerAccess() {
		Util.CommitMutation(UpdateFeatureFlag, {
			featureFlag: this.state.currentFeature.key,
			companyId: this.state.selectedCustomer,
			removeAccess: true,
		});
		this.setState({
			showAddCustomer: false,
			showEditCustomer: false,
			selectAllPersons: false,
			selectedCustomer: null,
			selectedPersons: [],
			showPersons: [],
		});
	}

	removeCustomer(person) {
		let selectedPersons = this.state.selectedPersons;

		selectedPersons = selectedPersons.filter(p => p !== person.id);

		const showPersons = this.state.showPersons;
		showPersons.push({value: person.id, label: this.getName(person)});
		this.setState({selectedPersons, showPersons});
	}

	hideBulk() {
		this.setState({showBulk: false, bulkCompanyIdInput: ''});
	}

	hideBulkUsers() {
		this.setState({showBulkUsers: false, bulkUserIdInput: ''});
	}

	saveBulkAccess(companyIds) {
		if (companyIds) {
			Util.CommitMutation(UpdateFeatureFlag, {
				companyIds: companyIds,
				featureFlag: this.state.currentFeature.key,
				allUsers: true,
			});
		}
	}

	getBulkModal(data) {
		const values = Object.values(data[this.state.currentFeature.key]);
		const filteredCustomers = this.state.allCompanies.filter(c => {
			return !values.find(d => d.allUsers && d.id === c.value);
		});
		const enteredCompanyIds = this.state.bulkCompanyIdInput.split(',').map(id => {
			return btoa(`CompanyType:${id.trim()}`);
		});

		const validEnteredCompanies = filteredCustomers.filter(c => enteredCompanyIds.some(companyId => c.value === companyId));
		const validIds = validEnteredCompanies.map(company => company.value);

		const content = (
			<div className="feature-flag-modal">
				<DropdownLabel>{'Company IDs'}</DropdownLabel>
				<TextArea
					maxLength={9999}
					value={this.state.bulkCompanyIdInput}
					rows={5}
					onChange={e => this.setState({bulkCompanyIdInput: e.target.value})}
					placeholder={
						'Enter comma-separated list of company ids (e.g. 1,2,3,4,5). Only paying and active trial companies will be included.'
					}
				/>
				<div style={{marginTop: '16px'}}>
					<DropdownLabel>{'Companies that will be added'}</DropdownLabel>
					{validIds.length > 0 ? (
						<div>{`${validIds.length} valid IDs found from ${enteredCompanyIds.length} IDs inputted`}</div>
					) : null}
					<ul style={{maxHeight: '300px', overflowY: 'auto'}}>
						{validEnteredCompanies.map(company => (
							<li key={company.value}>{company.label}</li>
						))}
					</ul>
				</div>
			</div>
		);

		const buttons = [
			{
				text: 'Cancel',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				callback: () => this.hideBulk(),
				preventDefaultClose: true,
			},
			{
				text: 'Save',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				callback: () => this.saveBulkAccess(validIds),
				disabled: !this.state.bulkCompanyIdInput,
			},
		];
		return (
			<GenericModal
				closeModal={() => this.closeModal()}
				buttons={buttons}
				content={content}
				headerText={'Bulk add feature flags'}
			/>
		);
	}

	getBulkUsersModal(data) {
		return (
			<BulkUsersModal
				data={data}
				currentFeatureKey={this.state.currentFeature.key}
				allPersonsByCompany={this.state.allPersonsByCompany}
				hideBulkUsers={this.hideBulkUsers.bind(this)}
				closeModal={this.closeModal.bind(this)}
			/>
		);
	}

	getCompanyFeatureFlagsModal(companyId) {
		const encodedCompanyId = btoa(`CompanyType:${companyId}`);
		const companyFeatures = [];
		this.props.viewer.missionControlData.availableFeatures
			.filter(
				feature =>
					!feature.rolledOut &&
					(feature.companies.some(company => company.companyId === encodedCompanyId) ||
						feature.persons.some(person => person.companyId === encodedCompanyId))
			)
			.forEach(feature => {
				const company = feature.companies.find(company => company.companyId === encodedCompanyId);
				const persons = feature.persons.filter(person => person.companyId === encodedCompanyId);

				companyFeatures.push({
					id: feature.id,
					name: feature.name,
					persons: company
						? [`All Users (${company.timestamp.substring(0, 10)})`]
						: persons.map(person => `${person.name} (${person.timestamp.substring(0, 10)})`),
					personsOptedOut: feature.personsOptedOut
						.filter(person => person.companyId === encodedCompanyId)
						.map(person => person.name),
				});
			});
		return <CompanyFeatureFlagsModal features={companyFeatures} closeModal={this.closeModal.bind(this)} />;
	}

	hidePublishRelease() {
		this.setState({showPublishRelease: false, currentReleaseTitle: null, currentReleaseDescription: ''});
	}

	publishReleaseToAmplitude() {
		const onSuccess = res => {
			if (res.status === 200) {
				createToast({
					duration: 5000,
					message: `Release: "${this.state.currentReleaseTitle}" published to Amplitude`,
				});
				this.hidePublishRelease();
			} else {
				createToast({
					duration: 5000,
					message: `Release failed to publish. Status: ${res.status} - Message: ${res.message}`,
				});
			}
		};
		const versionString = versionManager.getClientMajorVersion() + '.' + versionManager.getClientMinorVersion();

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const body = {
			method: 'POST',
			headers,
			credentials: 'include',
			body: JSON.stringify({
				title: this.state.currentReleaseTitle,
				description: this.state.currentReleaseDescription,
				releaseStart: Moment().format('YYYY-MM-DD HH:mm:ss'),
				publisherName: this.props.viewer.firstName
					? this.props.viewer.firstName + (this.props.viewer.lastName ? ` ${this.props.viewer.lastName}` : '')
					: '',
				version: versionString,
			}),
		};

		return fetch(DirectApi.graphqlServerEndpoint('amplitude/release'), body)
			.then(response => response.json())
			.then(res => onSuccess(res));
	}

	getPublishAmplitudeReleaseModal() {
		const content = (
			<div className="feature-flag-modal">
				<InputField
					id={'release-title'}
					value={this.state.currentReleaseTitle}
					maxLength={191}
					onChange={val => this.setState({currentReleaseTitle: val})}
					placeholder={'Enter Release title'}
					label={'Release Title'}
					type="text"
					required
				/>
				<DropdownLabel>{'Release Description'}</DropdownLabel>
				<TextArea
					maxLength={999}
					value={this.state.currentReleaseDescription}
					rows={5}
					onChange={e => this.setState({currentReleaseDescription: e.target.value})}
					placeholder={'Enter Release description (optional)'}
				/>
			</div>
		);

		const buttons = [
			{
				text: 'Cancel',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				callback: () => this.hidePublishRelease(),
				preventDefaultClose: true,
			},
			{
				text: 'Publish',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				callback: () => this.publishReleaseToAmplitude(),
				preventDefaultClose: true,
				disabled: !this.state.currentReleaseTitle,
			},
		];
		return (
			<GenericModal
				closeModal={() => this.closeModal()}
				buttons={buttons}
				content={content}
				headerText={'Publish Release to Amplitude'}
			/>
		);
	}

	getAddCustomerModal(data) {
		const values = Object.values(data[this.state.currentFeature.key]);
		const filteredCustomers = this.state.allCompanies.filter(c => {
			return !values.find(d => d.id === c.value);
		});

		const content = (
			<div className="feature-flag-modal">
				<Dropdown
					options={this.state.showAddCustomer ? filteredCustomers : this.state.allCompanies}
					onChange={this.setPersonsToShow.bind(this)}
					value={this.state.selectedCustomer}
					multi={false}
					disabled={this.state.showEditCustomer}
					label={'Select customer'}
					clearable={true}
				/>
				<div style={{marginTop: '8px', display: 'flex', justifyContent: 'space-between'}}>
					<div className={'description'}>Active means: not trial and not Forecast and not blocked</div>
					<DeprecatedButton type={'secondary'} onClick={() => this.setState({showBulk: true})}>
						Bulk add companies
					</DeprecatedButton>
				</div>
				<div className="feature-flag-users-to-add-all-group">
					<div className="feature-flag-users-to-add-all-title">Select all users</div>
					<div className="feature-flag-users-to-add-all">
						<Checkbox
							onChange={() => {
								this.setState({selectAllPersons: !this.state.selectAllPersons});
							}}
							isChecked={this.state.selectAllPersons}
							isDisabled={!this.state.selectedCustomer}
						/>
					</div>
				</div>
				<Dropdown
					options={this.state.showPersons}
					onChange={this.setSelectedPerson.bind(this)}
					value={null}
					multi={true}
					label={'Select users'}
					clearable={true}
					disabled={this.state.selectAllPersons || !this.state.selectedCustomer}
				/>

				{!this.state.selectAllPersons && this.state.selectedPersons && this.state.selectedPersons.length > 0 ? (
					<div className="feature-flag-users-to-add-title">Users that will be added:</div>
				) : null}
				{!this.state.selectAllPersons
					? this.state.selectedPersons.map(p => {
							const person = this.state.allPersonsByCompany[this.state.selectedCustomer]?.find(pp => pp.id === p);
							return (
								<div key={p} className="feature-flag-user-container">
									<div className="feature-flag-users-to-add">- {person ? this.getName(person) : p}</div>
									<div
										className="feature-flag-remove-user"
										onClick={this.removeCustomer.bind(this, person)}
									/>
								</div>
							);
					  })
					: null}
				{this.state.selectAllPersons ? (
					<div className="feature-flag-users-to-add-title">All users in company will get access!</div>
				) : null}
				<div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '8px'}}>
					<DeprecatedButton type={'secondary'} onClick={() => this.setState({showBulkUsers: true})}>
						Bulk add users
					</DeprecatedButton>
				</div>
			</div>
		);
		const tertiaryButtons = [
			{
				text: 'Publish Release',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
				callback: () => this.setState({showPublishRelease: true, currentReleaseTitle: this.state.currentFeature.name}),
				preventDefaultClose: true,
				className: 'publish-button',
				symbolClass: 'amplitude-icon',
				symbolSize: '16px',
			},
		];

		const buttons = [
			{
				text: 'Cancel',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: 'Save',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				callback: this.saveNewCustomerAccess.bind(this),
			},
		];
		if (this.state.showEditCustomer) {
			const remove = {
				text: 'Remove feature access',
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.RED,
				callback: this.removeCustomerAccess.bind(this),
			};
			buttons.unshift(remove);
		}
		return (
			<GenericModal
				closeModal={() => this.closeModal()}
				buttons={buttons}
				tertiaryButtons={tertiaryButtons}
				content={content}
				headerText={this.state.currentFeature.name}
			/>
		);
	}

	collapseExpandAll() {
		if (this.state.collapsed.length > 0) {
			this.setState({collapsed: []});
		} else {
			this.setState({collapsed: this.props.viewer.missionControlData.availableFeatures.map(f => f.key)});
		}
	}

	render() {
		const allTeams = [
			{value: 'ALL', label: 'All'},
			{value: 'WORK_IN_PROGRESS', label: 'Team - Work In Progress'},
			{value: 'HOLD_YOUR_RESOURCES', label: 'Hold Your Resources'},
			{value: 'FINANCE', label: 'Finance'},
			{value: 'GEEK_SQUAD', label: 'Geek Squad'},
			{value: 'INTELLIGENCE', label: 'Intelligence'},
			{value: 'ARCHITECTURE', label: 'Architecture'},
		];

		if (!this.props.viewer.missionControlData) {
			this.props.history.push('/not-found');
			return null;
		}

		const featureToCompaniesMap = {};
		this.props.viewer.missionControlData.availableFeatures.forEach(f => {
			const c = {};

			f.persons.forEach(p => {
				if (c[p.companyId]) {
					const tmp = c[p.companyId];
					const tmp2 = tmp.specificUsers;

					tmp2.push({...p, id: btoa(`Person:${atob(p.personId).split(':')[1]}`)});
					tmp.specificUsers = tmp2;
					tmp.allUsers = false;
					c[p.companyId] = tmp;
				} else {
					c[p.companyId] = {
						id: p.companyId,
						name: p.companyName,
						allUsers: false,
						specificUsers: [{...p, id: btoa(`Person:${atob(p.personId).split(':')[1]}`)}],
					};
				}
			});

			f.companies.forEach(cc => {
				c[cc.companyId] = {id: cc.companyId, name: cc.name, allUsers: true, specificUsers: []};
			});

			// FIXME: The behavior of this entire function is extremely confusing, should be refactored. Null-check is a band-aid fix for an issue which is less clear how is happening.
			f.personsOptedOut.reduce((acc, p) => {
				if (acc[p.companyId]) {
					const optedOutUsers = acc[p.companyId].optedOutUsers || [];
					optedOutUsers.push(p);
					acc[p.companyId].optedOutUsers = optedOutUsers;
				}
				return acc;
			}, c);

			featureToCompaniesMap[f.key] = c;
		});

		return (
			<div className="mission-control-features">
				<MissionControlHeader viewer={this.props.viewer} />
				<div className="mission-control-features-header">
					<h1>Feature flags</h1>
					<Button
						style={{margin: 125}}
						text={'Collapse / expand all'}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.WHITE}
						onClick={this.collapseExpandAll.bind(this)}
					/>
					<div className="team-dropdown" spellCheck="false">
						<Dropdown
							focusOnClick={true}
							optionClickEvent={true}
							onChange={this.handleTeam.bind(this)}
							options={allTeams}
							value={this.state.team}
							hideLabel={true}
							disabled={false}
						/>
					</div>
					<div style={{display: 'flex', gap: '12px', alignItems: 'center'}}>
						<Text size={2}>Show Rolled Out:</Text>
						<Checkbox isChecked={this.state.showRolledOut} onChange={this.handleRolledOut.bind(this)}></Checkbox>
					</div>
					<div className="company-dropdown" spellCheck="false">
						<Dropdown
							focusOnClick={true}
							optionClickEvent={true}
							onChange={this.handleCompany.bind(this)}
							options={this.props.viewer.missionControlData.companies.map(company => ({
								value: company.companyId,
								label: company.name,
							}))}
							value={null}
							hideLabel={true}
							placeholder="Check Company"
						/>
					</div>
				</div>
				{this.state.showAddCustomer || this.state.showEditCustomer
					? this.state.showBulk
						? this.getBulkModal(featureToCompaniesMap)
						: this.state.showBulkUsers
						? this.getBulkUsersModal(featureToCompaniesMap)
						: this.state.showPublishRelease
						? this.getPublishAmplitudeReleaseModal()
						: this.getAddCustomerModal(featureToCompaniesMap)
					: null}
				{this.state.featureFlagsCompanyId && this.getCompanyFeatureFlagsModal(this.state.featureFlagsCompanyId)}
				<div className="mission-control-list-features">
					{this.props.viewer.missionControlData.availableFeatures
						.filter(f => {
							if (this.state.team !== '' && this.state.team !== 'ALL') {
								return this.state.team === f.team;
							} else {
								return true;
							}
						})
						.filter(f => this.state.showRolledOut || !f.rolledOut)
						.sort((a, b) => a.rolledOut - b.rolledOut)
						.map(f => {
							return this.getFeatureFlag(featureToCompaniesMap, f);
						})}
				</div>
			</div>
		);
	}
}

const featureFlagsQuery = graphql`
	query featureFlags_Query {
		viewer {
			actualPersonId
			component(name: "control_feature_flags")
			...featureFlags_viewer
		}
	}
`;

export {featureFlagsQuery};

export default createFragmentContainer(withRouter(featureFlags), {
	viewer: graphql`
		fragment featureFlags_viewer on Viewer {
			component(name: "control_feature_flags")
			id
			firstName
			lastName
			company {
				id
			}
			availableFeatureFlags {
				key
			}
			...missionControlHeader_viewer
			missionControlData {
				id
				companies {
					id
					companyId
					name
					tier
					forecastDemo
					contactEmail
					accessBlocked
				}
				availableFeatures {
					id
					name
					key
					color
					team
					description
					rolledOut
					rolledOutDate
					cleanedUp
					expectedRollOutDate
					type
					companies {
						id
						companyId
						name
						timestamp
					}
					persons {
						id
						personId
						companyId
						companyName
						name
						email
						timestamp
					}
					personsOptedOut {
						id
						companyId
						companyName
						name
						email
					}
				}
				otherPersons(includeForecastPersons: true) {
					id
					firstName
					lastName
					email
					companyName
					companyId
				}
			}
		}
	`,
});
