/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type linkUnit4ProjectModal_viewer$ref = any;
export type linkUnit4ProjectModal_QueryVariables = {||};
export type linkUnit4ProjectModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: linkUnit4ProjectModal_viewer$ref,
  |}
|};
export type linkUnit4ProjectModal_Query = {|
  variables: linkUnit4ProjectModal_QueryVariables,
  response: linkUnit4ProjectModal_QueryResponse,
|};
*/


/*
query linkUnit4ProjectModal_Query {
  viewer {
    actualPersonId
    component(name: "link_unit4_project_modal")
    ...linkUnit4ProjectModal_viewer
    id
  }
}

fragment linkUnit4ProjectModal_viewer on Viewer {
  id
  company {
    id
    unit4Enabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "link_unit4_project_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"link_unit4_project_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "linkUnit4ProjectModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "linkUnit4ProjectModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "linkUnit4ProjectModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit4Enabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "linkUnit4ProjectModal_Query",
    "operationKind": "query",
    "text": "query linkUnit4ProjectModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"link_unit4_project_modal\")\n    ...linkUnit4ProjectModal_viewer\n    id\n  }\n}\n\nfragment linkUnit4ProjectModal_viewer on Viewer {\n  id\n  company {\n    id\n    unit4Enabled\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '844a2ef60e77d079a2d799e6a8d8210d';

module.exports = node;
