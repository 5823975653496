/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
type ProjectPersonDropdown_persons$ref = any;
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActivateTimeApprovalModal_viewer$ref: FragmentReference;
declare export opaque type ActivateTimeApprovalModal_viewer$fragmentType: ActivateTimeApprovalModal_viewer$ref;
export type ActivateTimeApprovalModal_viewer = {|
  +id: string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +id: string,
    +allProjects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +companyProjectId: ?number,
          +projectColor: ?string,
          +status: ?PROJECT_STATUS,
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +isContactPerson: ?boolean,
                +person: ?{|
                  +id: string,
                  +permissions: ?$ReadOnlyArray<?string>,
                  +active: ?boolean,
                  +fullName: ?string,
                  +profilePictureId: ?string,
                |},
              |},
              +$fragmentRefs: ProjectPersonDropdown_persons$ref,
            |}>
          |},
          +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
        |}
      |}>
    |},
  |},
  +$refType: ActivateTimeApprovalModal_viewer$ref,
|};
export type ActivateTimeApprovalModal_viewer$data = ActivateTimeApprovalModal_viewer;
export type ActivateTimeApprovalModal_viewer$key = {
  +$data?: ActivateTimeApprovalModal_viewer$data,
  +$fragmentRefs: ActivateTimeApprovalModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivateTimeApprovalModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            },
            {
              "kind": "Literal",
              "name": "searchQuery",
              "value": {
                "filters": [
                  {
                    "field": "NO_CONTACT"
                  }
                ]
              }
            }
          ],
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "allProjects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "excludeClientUsers",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 100000
                        }
                      ],
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isContactPerson",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "permissions",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "active",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "fullName",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "profilePictureId",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProjectPersonDropdown_persons"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectPersons(excludeClientUsers:true,first:100000)"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DeprecatedProjectIndicatorJS_project"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allProjects(first:1000000,searchQuery:{\"filters\":[{\"field\":\"NO_CONTACT\"}]})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c554a05e8a88a0356297b50ee93fbd7c';

module.exports = node;
