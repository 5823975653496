import Util from '../../../../../forecast-app/shared/util/util';
import {getFocusVariant} from '../../ProjectHealthLogic';
import {profilePicSrc} from '../../../../../directApi';

export const getResourceBasedPersonsMap = resourceBasedPersons => {
	if (!resourceBasedPersons) {
		return new Map();
	}

	return new Map(resourceBasedPersons.map(person => [person.node.id, person.node.resourceNumbers.plannedTotalMinutes]));
};

export const formatPersonData = (person, allocation, intl) => {
	const minutesAllocated = allocation || 0;
	const minutesRegistered = person.totalMinutesRegistered || 0;
	const focus = minutesAllocated === 0 ? null : minutesRegistered / minutesAllocated;
	return {
		id: person.id,
		name: person.fullName,
		isHeader: person.isHeader,
		profilePicSrc: person.profilePictureId && profilePicSrc(person.profilePictureId),
		timeAllocated: Util.convertMinutesToFullHour(minutesAllocated, intl),
		timeAllocatedRaw: minutesAllocated,
		timeRegistered: Util.convertMinutesToFullHour(minutesRegistered, intl),
		timeRegisteredRaw: minutesRegistered,
		focus: focus === null ? '-' : intl.formatNumber(focus * 100, {format: 'rounded_zero_decimal'}) + '%',
		focusRaw: focus,
		warningVariant: getFocusVariant(focus),
	};
};

const getSortFunction = sort => {
	const direction = sort.direction === 'ASC' ? 1 : -1;
	switch (sort.id) {
		case 'name':
			return (a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? direction : direction * -1);
		case 'allocated-time':
			return (a, b) => (a.timeAllocatedRaw > b.timeAllocatedRaw ? direction : direction * -1);
		case 'actual-time':
			return (a, b) => (a.timeRegisteredRaw > b.timeRegisteredRaw ? direction : direction * -1);
		case 'focus':
			return (a, b) => (a.focusRaw > b.focusRaw ? direction : direction * -1);
		default:
			return (a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1);
	}
};

export const sortTable = (data, sort) => {
	const sortFunction = getSortFunction(sort);
	return data.sort(sortFunction);
};
