import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import InputFieldV2 from '../../../components/new-ui/input_field';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import CreateSkillCategoryMutation from '../../../mutations/create_skill_category_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';

const CreateSkillCategoryModal = ({company, closeModal}) => {
	const {formatMessage} = useIntl();
	const [name, setName] = useState('');
	const [errorMessage, setErrorMessage] = useState();
	const skillCategories = company.skillCategories.edges.map(skillCategory => skillCategory.node);
	const existingCategoryNames = new Set(skillCategories.map(skillCategory => skillCategory.name));

	const createCategory = () => {
		Util.CommitMutation(
			CreateSkillCategoryMutation,
			{
				name,
				companyId: company.id,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.category_created_toast'}),
				});
			}
		);
	};

	const onNameChange = newName => {
		setName(newName);
		const duplicateName =
			existingCategoryNames.has(newName) || newName === formatMessage({id: 'settings_skills.no_category'});
		if (duplicateName) {
			setErrorMessage(formatMessage({id: 'settings_skills.category_already_exists'}));
		} else {
			setErrorMessage(null);
		}
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'common.create_skill_category'})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.create'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: createCategory,
					disabled: !name || !!errorMessage,
					cy: 'button-save',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<InputFieldV2
							value={name}
							cy={'skill-category-name'}
							id="skillCategoryName"
							label={formatMessage({id: 'common.name'})}
							placeholder={formatMessage({id: 'common.name_placeholder'})}
							type="text"
							onChange={onNameChange}
							errorMessage={errorMessage}
							invalidInput={!!errorMessage}
							required={true}
							maxLength={191}
						/>
					</Field>
				</ModalBody>
			}
		/>
	);
};

const createSkillCategoryModalQuery = graphql`
	query CreateSkillCategoryModal_Query {
		viewer {
			actualPersonId
			component(name: "create_skill_category_modal")
			company {
				...CreateSkillCategoryModal_company
			}
		}
	}
`;

export {createSkillCategoryModalQuery};

export default createFragmentContainer(CreateSkillCategoryModal, {
	company: graphql`
		fragment CreateSkillCategoryModal_company on Company {
			id
			skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`,
});
