import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {CustomScrollDiv} from 'web-components';
import GenericModal from '../generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {ProjectCol, ProjectIndicatorWrapper, ProjectRow, TableHeader} from './ActivateTimeApprovalModal_styled';
import ProjectPersonDropdown from '../../../components/dropdowns/project-person-dropdown/Project_person_dropdown';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../forecast-app/shared/util/util';
import UpdateProjectPersonMutation from '../../../mutations/update_project_person_mutation';
import {ModalText, ModalWrapper} from '../modal_styled';
import {personHasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {profilePicSrc} from '../../../directApi';
import DeprecatedProjectIndicatorJS from '../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const ActivateTimeApprovalModal = ({intl: {formatMessage}, viewer, toggleTimeApproval, closeModal}) => {
	const [missingOwnerStep, setMissingOwnerStep] = useState(false);

	const projects = viewer.company.allProjects.edges;

	const handleChangeOwner = (id, isSetOwner) => {
		Util.CommitMutation(UpdateProjectPersonMutation, {
			id: id,
			isContactPerson: isSetOwner,
		});
	};

	const showOwnerStep = projects.length !== 0 && !missingOwnerStep;

	const modalConfirm = () => {
		if (showOwnerStep) {
			toggleTimeApproval();
			setMissingOwnerStep(true);
		} else {
			toggleTimeApproval();
		}
	};

	const content = (
		<ModalWrapper>
			{!missingOwnerStep ? (
				<>
					<ModalText>{formatMessage({id: 'approval.enable_approval_info'})}</ModalText>
					<ModalText>{formatMessage({id: 'approval.enable_approval_info_2'})}</ModalText>
				</>
			) : (
				<>
					<ModalText>{formatMessage({id: 'approval.missing_project_owner_info'})}</ModalText>
					<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={400}>
						<table style={{width: '100%'}}>
							<thead>
								<tr>
									<TableHeader>{formatMessage({id: 'common.project'})}</TableHeader>
									<TableHeader>{formatMessage({id: 'approval.new_project_owner'})}</TableHeader>
								</tr>
							</thead>
							<tbody>
								{projects.map(project => {
									const projectPersons = project.node.projectPersons.edges.filter(
										projectPerson =>
											projectPerson.node.person.active &&
											!personHasPermission(PERMISSION_TYPE.IS_VIRTUAL_USER, projectPerson.node.person)
									);
									const selected = projectPersons.filter(projectPerson => projectPerson.node.isContactPerson);
									const selectedItemsPersons = selected.map(projectPerson => {
										const person = projectPerson.node.person;
										return {
											fullName: person.fullName,
											imageSource: profilePicSrc(person.profilePictureId),
										};
									});
									return (
										<ProjectRow>
											<ProjectCol>
												<ProjectIndicatorWrapper>
													<DeprecatedProjectIndicatorJS
														project={project.node}
														showProjectName={true}
													/>
												</ProjectIndicatorWrapper>
											</ProjectCol>
											<ProjectCol>
												{projectPersons?.length > 0 ? (
													<ProjectPersonDropdown
														persons={projectPersons}
														name={formatMessage({id: 'common.select_persons_placeholder'})}
														optionsName={formatMessage({id: 'common.persons'})}
														selectedGroupName={formatMessage({id: 'common.applied'})}
														selectedItems={selected.map(projectPerson => projectPerson.node.id)}
														selectedItemsPersons={selectedItemsPersons}
														onRemove={ids => handleChangeOwner(ids[0], false)}
														onSelect={ids => handleChangeOwner(ids[0], true)}
														dropdownAlignment={'right'}
														width={275}
														showRole
														projectPersonValue
														usePortal
														maxHeight={255}
													/>
												) : (
													<div>No assignees</div>
												)}
											</ProjectCol>
										</ProjectRow>
									);
								})}
							</tbody>
						</table>
					</CustomScrollDiv>
				</>
			)}
		</ModalWrapper>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: modalConfirm.bind(this),
					preventDefaultClose: showOwnerStep,
					cy: 'time-approval-confirm-button',
				},
			]}
			headerText={formatMessage({id: missingOwnerStep ? 'approval.missing_project_owner' : 'approval.enable_approval'})}
			content={content}
			preventClickAwayClose
		/>
	);
};

const ActivateTimeApprovalModalQuery = graphql`
	query ActivateTimeApprovalModal_Query {
		viewer {
			actualPersonId
			component(name: "activate-time-approval")
			...ActivateTimeApprovalModal_viewer
		}
	}
`;

export {ActivateTimeApprovalModalQuery};

export default injectIntl(
	createFragmentContainer(ActivateTimeApprovalModal, {
		viewer: graphql`
			fragment ActivateTimeApprovalModal_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				company {
					id
					# Projects without a contact person
					allProjects(first: 1000000, searchQuery: {filters: [{field: NO_CONTACT}]}) {
						edges {
							node {
								id
								name
								companyProjectId
								projectColor
								status
								projectPersons(first: 100000, excludeClientUsers: true) {
									edges {
										...ProjectPersonDropdown_persons
										node {
											id
											isContactPerson
											person {
												id
												permissions
												active
												fullName
												profilePictureId
											}
										}
									}
								}
								...DeprecatedProjectIndicatorJS_project
							}
						}
					}
				}
			}
		`,
	})
);
