import React, {useState} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import Styled from 'styled-components';
import GenericModal from '../generic_modal';
import {CSS_CONSTANTS} from '../../../css_variables';

export const RejectSubmissionModal = ({
	rejectForName,
	startDate,
	endDate,
	isTimeOffOnly,
	onReject = () => false,
	closeModal,
}) => {
	const intl = useIntl();
	const [message, setMessage] = useState('');
	const content = (
		<div className="context" style={{fontSize: '13px', width: '384px'}}>
			<div style={{marginBottom: '24px', color: '#535353'}}>
				<FormattedHTMLMessage
					id={isTimeOffOnly ? 'time_off_approval.reject_for_message' : 'time_approval.reject_for_message'}
					values={{
						startDate: startDate.format('D MMM'),
						endDate: endDate.format('D MMM'),
						name: rejectForName,
					}}
				/>
			</div>
			<div style={{marginBottom: '16px', color: '#535353'}}>
				<FormattedHTMLMessage
					id={
						isTimeOffOnly
							? 'time_off_approval.write_message_description'
							: 'time_approval.write_message_description'
					}
					values={{name: rejectForName}}
				/>
			</div>
			<div>
				<TextArea
					value={message}
					rows={3}
					onChange={e => setMessage(e.target.value)}
					placeholder={`${intl.formatMessage({id: 'time_approval.message_to'})} ${rejectForName}...`}
				/>
			</div>
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'time_approval.reject_and_send'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					cy: 'reject',
					callback: () => onReject(message),
				},
			]}
			headerText={intl.formatMessage({id: 'time_approval.reject_submission'})}
			content={content}
		/>
	);
};

const TextArea = Styled.textarea`
	position: relative;
	width: 100%;
	color: #535353;
	line-height: 16px;
	font-size: 13px;
	background-color: $color-white;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	resize: none;
	border-radius: 3px;
	border: solid 1px #e6e6e6;
	padding: 8px;
	outline: none;
	z-index: 99;
	&::placeholder {
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		opacity: 1;
	}

	&:disabled {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		background-color: #f3f3f3;
		&:hover {
			border-color: ${CSS_CONSTANTS.v2_border_gray};
		}
	}
	&:hover {
		border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
	}
	&:focus {
		border-color: #6e0fea;
		outline: none;
	}
`;
