import React from 'react';
import {useIntl} from 'react-intl';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {getPerformanceVariant} from '../../ProjectHealthLogic';
import {getPerformanceRollingTooltipContent, getPerformanceTotalTooltipContent} from '../../ProjectHealthUtil';
import {StatsContainer, StatContainer, StatValue, StatTitle} from './PerformanceModal.styled';

export const PerformanceModalStatsSection = ({performanceRolling, performanceTotal}) => {
	const intl = useIntl();

	const performanceRollingWarningVariant = getPerformanceVariant(performanceRolling);
	const performanceTotalWarningVariant = getPerformanceVariant(performanceTotal);

	const performanceRollingFormatted = intl.formatNumber(performanceRolling * 100) + '%';
	const performanceTotalFormatted = intl.formatNumber(performanceTotal * 100) + '%';

	return (
		<StatsContainer>
			<ForecastTooltip maxWidth={350} content={getPerformanceRollingTooltipContent(performanceRolling, intl)}>
				<StatContainer>
					<StatValue color={performanceRollingWarningVariant.color}>{performanceRollingFormatted}</StatValue>
					<StatTitle>Last 30 days</StatTitle>
				</StatContainer>
			</ForecastTooltip>
			<ForecastTooltip maxWidth={350} content={getPerformanceTotalTooltipContent(performanceTotal, intl)}>
				<StatContainer>
					<StatValue color={performanceTotalWarningVariant.color}>{performanceTotalFormatted}</StatValue>
					<StatTitle>Project Average</StatTitle>
				</StatContainer>
			</ForecastTooltip>
		</StatsContainer>
	);
};
