/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type missionControlHeader_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type trialsList_viewer$ref: FragmentReference;
declare export opaque type trialsList_viewer$fragmentType: trialsList_viewer$ref;
export type trialsList_viewer = {|
  +id: string,
  +missionControlData: ?{|
    +trialsList: ?$ReadOnlyArray<?{|
      +companyId: ?number,
      +companyName: ?string,
      +jiraEnabled: ?boolean,
      +minimumSeats: ?number,
      +minimumVirtualSeats: ?number,
      +users: ?number,
      +virtualUsers: ?number,
      +trialEndDay: ?number,
      +trialEndMonth: ?number,
      +trialEndYear: ?number,
      +contactName: ?string,
      +contactEmail: ?string,
      +trialExpired: ?boolean,
      +datacenter: ?string,
      +persons: ?$ReadOnlyArray<?{|
        +id: string,
        +active: ?boolean,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +firstName: ?string,
        +lastName: ?string,
        +fullName: ?string,
        +profilePictureId: ?string,
        +email: ?string,
        +permissions: ?$ReadOnlyArray<?string>,
      |}>,
    |}>
  |},
  +$fragmentRefs: missionControlHeader_viewer$ref,
  +$refType: trialsList_viewer$ref,
|};
export type trialsList_viewer$data = trialsList_viewer;
export type trialsList_viewer$key = {
  +$data?: trialsList_viewer$data,
  +$fragmentRefs: trialsList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "trialsList_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlTrialsListType",
          "kind": "LinkedField",
          "name": "trialsList",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jiraEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minimumSeats",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minimumVirtualSeats",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "users",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "virtualUsers",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialEndDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialEndMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialEndYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialExpired",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "datacenter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "persons",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "role",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "permissions",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "missionControlHeader_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '030e0416cf6e945881c2deeff786eaa0';

module.exports = node;
