import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {getQueryRenderer} from '../../../../components/insights/task-report/TaskReportUtil';
import {getCurrency} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';

const ProjectTaskReportTable = ({enabledColumns, groupings, searchQuery, viewer, intl, revenueRecognitionNotEqualToBudget}) => {
	const sortValue = null;
	const taskTotals = viewer.project.taskTotals;
	const currency = getCurrency(viewer.company, viewer.project);
	const customFieldDefinitions = viewer.company.customFieldDefinitions.edges
		.map(e => e.node)
		.filter(n => n.entityType === 'TASK');

	const tableHeader = {
		taskTotals,
		currency,
		revenueRecognitionNotEqualToBudget,
		customFieldDefinitions,
	};
	return getQueryRenderer(tableHeader, groupings, searchQuery, 0, enabledColumns, null, {}, sortValue, true, currency);
};

const ProjectTaskReportTableQuery = graphql`
	query projectTaskReportTable_Query($searchQuery: TaskSearchQueryType, $projectId: ID) {
		viewer {
			actualPersonId
			project(internalId: $projectId) {
				id
			}
			component(name: "project_task_report_table")
			...projectTaskReportTable_viewer @arguments(searchQuery: $searchQuery, projectId: $projectId)
		}
	}
`;
export {ProjectTaskReportTableQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(ProjectTaskReportTable, {
			viewer: graphql`
				fragment projectTaskReportTable_viewer on Viewer
				@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, projectId: {type: "ID"}) {
					id
					company {
						currency
						customFieldDefinitions(first: 1000) @connection(key: "Company_customFieldDefinitions") {
							edges {
								node {
									id
									key
									entityType
									displayName
								}
							}
						}
					}
					project(internalId: $projectId) {
						id
						taskTotals(searchQuery: $searchQuery, convertToProjectCurrency: true) {
							registeredMinutes
							billableActualMinutes
							nonBillableActualMinutes
							estimatePoints
							estimateMinutes
							remainingPoints
							remainingMinutes
							plannedRevenue
							actualRevenue
							remainingRevenue
							forecastRevenue
							recognitionLockedRevenue
							recognitionOpenRevenue
							recognitionForecastRevenue
							totalActualRevenueRecognition
							totalForecastRevenueToComplete
							surplus
							numberOfTasks
						}
						rateCard {
							id
							currency
						}
					}
				}
			`,
		})
	)
);
