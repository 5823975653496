/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query ExportInvoiceModal_Query(
  $invoiceId: ID
  $billToId: ID
) {
  viewer {
    actualPersonId
    component(name: "export_invoice_modal")
    ...ExportInvoiceModal_Viewer_1V72f3
    id
  }
}

fragment ExportInvoiceModal_Viewer_1V72f3 on Viewer {
  id
  backendId
  billTo(id: $billToId) {
    id
    name
    externalId
    billFrom {
      id
      name
      integration
      externalId
    }
  }
  invoice(id: $invoiceId) {
    id
    invoiceReference
    companyInvoiceId
    name
    currency
    invoiceType
    status
    dueDay
    dueMonth
    dueYear
    createdDay
    createdMonth
    createdYear
    notes
    quickbooksId
    xeroId
    economicId
    client {
      id
      name
    }
    entries(first: 100000000) {
      edges {
        node {
          id
          name
          quantity
          quantityText
          unitPrice
          discount
          tax
          description
          quantityLockedReason
          project {
            id
            customProjectId
            companyProjectId
            name
            projectColor
            isInProjectGroup
            budgetType
            client {
              name
              id
            }
            rateCard {
              currency
              id
            }
          }
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "invoiceId",
            "type": "ID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "billToId",
            "type": "ID"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "export_invoice_modal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"export_invoice_modal\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalId",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ExportInvoiceModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "billToId",
                                    "variableName": "billToId"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "invoiceId",
                                    "variableName": "invoiceId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "ExportInvoiceModal_Viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ExportInvoiceModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "backendId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "billToId"
                                }
                            ],
                            "concreteType": "BillTo",
                            "kind": "LinkedField",
                            "name": "billTo",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BillFrom",
                                    "kind": "LinkedField",
                                    "name": "billFrom",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "integration",
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "id",
                                    "variableName": "invoiceId"
                                }
                            ],
                            "concreteType": "InvoiceType",
                            "kind": "LinkedField",
                            "name": "invoice",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceReference",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "companyInvoiceId",
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceType",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dueDay",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dueMonth",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dueYear",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdDay",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdMonth",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdYear",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "notes",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "quickbooksId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "xeroId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "economicId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Client",
                                    "kind": "LinkedField",
                                    "name": "client",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 100000000
                                        }
                                    ],
                                    "concreteType": "InvoiceEntryTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "entries",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "InvoiceEntryTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "InvoiceEntryType",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantity",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantityText",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "unitPrice",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "discount",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "tax",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "description",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "quantityLockedReason",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ProjectType",
                                                            "kind": "LinkedField",
                                                            "name": "project",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "customProjectId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "companyProjectId",
                                                                    "storageKey": null
                                                                },
                                                                (v4 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "projectColor",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "isInProjectGroup",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "budgetType",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Client",
                                                                    "kind": "LinkedField",
                                                                    "name": "client",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v4 /*: any*/),
                                                                        (v3 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "RateCard",
                                                                    "kind": "LinkedField",
                                                                    "name": "rateCard",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v6 /*: any*/),
                                                                        (v3 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "entries(first:100000000)"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "ExportInvoiceModal_Query",
            "operationKind": "query",
            "text": "query ExportInvoiceModal_Query(\n  $invoiceId: ID\n  $billToId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"export_invoice_modal\")\n    ...ExportInvoiceModal_Viewer_1V72f3\n    id\n  }\n}\n\nfragment ExportInvoiceModal_Viewer_1V72f3 on Viewer {\n  id\n  backendId\n  billTo(id: $billToId) {\n    id\n    name\n    externalId\n    billFrom {\n      id\n      name\n      integration\n      externalId\n    }\n  }\n  invoice(id: $invoiceId) {\n    id\n    invoiceReference\n    companyInvoiceId\n    name\n    currency\n    invoiceType\n    status\n    dueDay\n    dueMonth\n    dueYear\n    createdDay\n    createdMonth\n    createdYear\n    notes\n    quickbooksId\n    xeroId\n    economicId\n    client {\n      id\n      name\n    }\n    entries(first: 100000000) {\n      edges {\n        node {\n          id\n          name\n          quantity\n          quantityText\n          unitPrice\n          discount\n          tax\n          description\n          quantityLockedReason\n          project {\n            id\n            customProjectId\n            companyProjectId\n            name\n            projectColor\n            isInProjectGroup\n            budgetType\n            client {\n              name\n              id\n            }\n            rateCard {\n              currency\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '1c253f24b338f5cce74bd3262b53d648';
export default node;
