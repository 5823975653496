import React, {Component} from 'react';
import {FormattedDate, FormattedHTMLMessage, injectIntl} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import SuppressTimeRegOnProjectWarningMutation from '../../mutations/suppress_time_reg_on_project_warning_mutation';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';
import Person from '../../forecast-app/shared/components/person/person';
import {profilePicSrc} from '../../directApi';

class TimeRegsOnProjectModal extends Component {
	constructor(props) {
		super(props);

		this.handleOnOk = this.handleOnOk.bind(this);
	}

	handleOnOk() {
		const {
			project: {id: projectId},
			onSuccess,
		} = this.props;

		Util.CommitMutation(
			SuppressTimeRegOnProjectWarningMutation,
			{
				projectId,
			},
			onSuccess
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {timeRegistrations} = this.props;

		const content = (
			<div className="auto-scheduling-modal time-reg-on-project-modal">
				<div className="warning-description-large">
					<FormattedHTMLMessage id="project_budget.time_entries_on_project_description_1" />
				</div>
				<div className="warning-description-large">
					<FormattedHTMLMessage id="project_budget.time_entries_on_project_description_2" />
					<br />
					<FormattedHTMLMessage id="project_budget.time_entries_on_project_description_3" />
				</div>
				<div className="table-heading-row">
					<div className="scrollable-table">
						<div className="headers">
							<div className="header column-person">{formatMessage({id: 'common.team_member'})}</div>
							<div className="header column-date">{formatMessage({id: 'common.date'})}</div>
							<div className="header column-entry">{formatMessage({id: 'common.time_entry'})}</div>
						</div>

						<div className="rows">
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
								{timeRegistrations.edges.map(timeRegistration => (
									<div key={timeRegistration.node.id} className="row">
										<div className="column-person">
											<div className="wrapper">
												<Person
													name={timeRegistration.node.person.fullName}
													showName={true}
													showRole={false}
													imageSrc={profilePicSrc(timeRegistration.node.person.profilePictureId)}
													imageSize="medium"
												/>
												<div className="name-tile">
													<h3 className="name">{timeRegistration.node.person.fullName}</h3>
												</div>
											</div>
										</div>
										<div className="column-date">
											<FormattedDate
												value={Util.CreateNonUtcMomentDate(
													timeRegistration.node.year,
													timeRegistration.node.month,
													timeRegistration.node.day
												).toDate()}
												year="numeric"
												month="short"
												day="numeric"
											/>
										</div>
										<div className="column-entry">
											{Util.convertMinutesToFullHour(
												timeRegistration.node.minutesRegistered,
												this.props.intl,
												true
											)}
										</div>
									</div>
								))}
							</CustomScrollDiv>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.ok'}),
						callback: this.handleOnOk,
					},
				]}
				content={content}
				headerText={formatMessage({id: 'project_budget.time_entries_on_project'})}
			/>
		);
	}
}

TimeRegsOnProjectModal.propTypes = {};

export default injectIntl(TimeRegsOnProjectModal);
