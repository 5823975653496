import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import qs from 'query-string';
import UploadingOverlay from '../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import azureActiveDirectoryLogo from '../images/integrations/azure-active-directory-textlogo.png';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Util from '../forecast-app/shared/util/util';
import CreateAADOauthKeyMutation from '../mutations/create_AAD_oauth_key_mutation';

class loginAzureActiveDirectory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			verifying: false,
		};
		this.props.setLocaleFromBrowser();
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.actualPersonId !== null) {
			this.props.history.push({
				pathname: '/',
			});
			return;
		}

		if (qs.parse(this.props.location.search).iss) {
			this.setState({verifying: true});
			const onSuccess = ({createAADOauthKey}) => {
				if (createAADOauthKey.key === null) {
					this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
					return;
				}

				window.location.href = `https://login.microsoftonline.com/${createAADOauthKey.AADTenant}.onmicrosoft.com/oauth2/v2.0/authorize?client_id=${createAADOauthKey.AADClientId}&response_type=code&redirect_uri=${process.env.AAD_REDIRECT_URI}&response_mode=form_post&scope=openid email&state=${createAADOauthKey.key}`;
			};

			Util.CommitMutation(
				CreateAADOauthKeyMutation,
				{
					issuer: qs.parse(this.props.location.search).iss,
				},
				onSuccess
			);
		}
	}

	loginAAD(event) {
		const onSuccess = ({createAADOauthKey}) => {
			if (createAADOauthKey.key === null) {
				this.setState({errorMsg: this.props.intl.formatMessage({id: 'login.incorrect_email'}), verifying: false});
				return;
			}

			window.location.href = `https://login.microsoftonline.com/${createAADOauthKey.AADTenant}.onmicrosoft.com/oauth2/v2.0/authorize?client_id=${createAADOauthKey.AADClientId}&response_type=code&redirect_uri=${process.env.AAD_REDIRECT_URI}&response_mode=form_post&scope=openid email&state=${createAADOauthKey.key}`;
		};

		if (event) {
			event.preventDefault();
		}

		const email = this.refs.email.value.trim();

		if (!email.length) {
			this.setState({errorMsg: 'All fields must be filled'});
			return;
		}

		this.setState({verifying: true});

		Util.CommitMutation(
			CreateAADOauthKeyMutation,
			{
				email: email,
			},
			onSuccess
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="fullscreen fullscreen-centered-outer login login-bg">
				{this.state.verifying ? <UploadingOverlay /> : null}
				<div className="logo" />
				<div className="fullscreen-centered-inner">
					<div className="fullscreen-centered-content form-vertical">
						<form>
							<h1>{formatMessage({id: 'login.title'})}</h1>
							<div className="fieldset">
								<input
									type="email"
									name="username"
									ref="email"
									placeholder={formatMessage({id: 'login.enter-email-address'})}
								/>
								{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
							</div>
							<div className="external-login">
								<button className="onelogin-login-button" onClick={this.loginAAD.bind(this)}>
									{formatMessage({id: 'login.login_with'})}{' '}
									<img src={azureActiveDirectoryLogo} height="17" alt="Azure DevOps logo" />
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}
const loginAzureActiveDirectoryQuery = graphql`
	query loginAzureActiveDirectory_Query {
		viewer {
			component(name: "login_azure_active_directory")
			...loginAzureActiveDirectory_viewer
		}
	}
`;

export {loginAzureActiveDirectoryQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(loginAzureActiveDirectory, {
			viewer: graphql`
				fragment loginAzureActiveDirectory_viewer on Viewer {
					id
					actualPersonId
					company {
						AADTenant
					}
				}
			`,
		})
	)
);
