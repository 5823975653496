import Styled from 'styled-components';
import { Button, FlexColumn } from '@forecast-it/design-system';
export const Page = Styled(FlexColumn) `
	background: #000000;
    height: 100vh;
`;
export const Content = Styled(FlexColumn) `
	background: #000000;
    height: 100vh;
    gap: 24px;
`;
export const PageContent = Styled(FlexColumn) `
    display: flex;
    width: 528px;
    padding: 48px;
    border-radius: 8px;
	background: #ffffff;
`;
export const WideButton = Styled(Button) `
	width: 100%;
`;
export const HorizontalLine = Styled.div `
    flex: 0 0 1px;
    border-top: 1px solid #CCD6DB;
`;
