import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexColumn, FlexRow, Label, Table, Text, useTableFunctions } from '@forecast-it/design-system';
import Styled from 'styled-components';
export const ANALYSIS_COLUMNS = {
    ID: 'id',
    FORECAST_COMPANY_ID: 'forecastCompanyId',
    SAGE_ENTITY_RECORD_NUMBER: 'sageEntityRecordNumber',
    FORECAST_ENTITY_ID: 'forecastEntityId',
    ENTITY_TYPE: 'entityType',
    RELEVANCY: 'relevancy',
    COMPANY_ANALYSIS_ID: 'companyAnalysisId',
};
const TableWrapper = Styled.div `
  padding: 64px;
  padding-top: 0;
  margin-top: 64px;
  height: 100%;
`;
function SageSyncAnalysisTable({ viewer, scrollRef }) {
    var _a, _b, _c, _d, _e, _f, _g;
    let entries;
    if (((_b = (_a = viewer.missionControlData) === null || _a === void 0 ? void 0 : _a.companySyncAnalysis) === null || _b === void 0 ? void 0 : _b.entries) !== null &&
        ((_d = (_c = viewer.missionControlData) === null || _c === void 0 ? void 0 : _c.companySyncAnalysis) === null || _d === void 0 ? void 0 : _d.entries) !== undefined) {
        entries = (_e = viewer.missionControlData) === null || _e === void 0 ? void 0 : _e.companySyncAnalysis.entries;
    }
    const syncDateNumber = (_g = (_f = viewer.missionControlData) === null || _f === void 0 ? void 0 : _f.companySyncAnalysis) === null || _g === void 0 ? void 0 : _g.syncDate;
    const syncDate = syncDateNumber ? new Date(syncDateNumber) : null;
    const syncDateString = syncDate ? new Date(syncDate).toLocaleString() : null;
    const [tableRef] = useTableFunctions();
    return (React.createElement(TableWrapper, null,
        React.createElement(FlexColumn, null,
            React.createElement(FlexRow, null,
                React.createElement(Label, null, "Analysis Date"),
                React.createElement(Text, null, syncDateString))),
        React.createElement(Table, { "data-cy": 'sage-sync-analysis-table', data: entries, width: '100%', stickyHeader: true, tableRef: tableRef, getRowId: (row) => row.id, scrollRef: scrollRef },
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.ID, accessorKey: 'id', "data-cy": 'entry-id', header: 'ID', allowSorting: true, width: '10%', visible: false }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.FORECAST_COMPANY_ID, accessorKey: 'forecastCompanyId', "data-cy": 'entry-forecastCompanyId', header: 'Forecast Company ID', allowSorting: true, ellipsis: true, width: '10%', visible: false }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.COMPANY_ANALYSIS_ID, accessorKey: 'companyAnalysisId', "data-cy": 'entry-companyAnalysisId', header: 'Company Analysis ID', allowSorting: true, ellipsis: true, width: '10%', visible: false }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.ENTITY_TYPE, accessorKey: 'entityType', "data-cy": 'entry-entityType', header: 'Entity Type', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.RELEVANCY, accessorKey: 'relevancy', "data-cy": 'entry-relevancy', header: 'Relevancy', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.SAGE_ENTITY_RECORD_NUMBER, accessorKey: 'sageEntityRecordNumber', "data-cy": 'entry-sageEntityRecordNumber', header: 'Sage Entity Record Number', allowSorting: true, ellipsis: true, width: '10%' }),
            React.createElement(Table.TextColumn, { id: ANALYSIS_COLUMNS.FORECAST_ENTITY_ID, accessorKey: 'forecastEntityId', "data-cy": 'entry-forecastEntityId', header: 'Forecast Entity ID', allowSorting: true, ellipsis: true, width: '10%' }))));
}
const sageSyncAnalysisTableQuery = graphql `
	query SageSyncAnalysisTable_Query($companyId: Int, $analysisId: Int) {
		viewer {
			actualPersonId
			component(name: "sage_sync_analysis_table")
			...SageSyncAnalysisTable_viewer @arguments(companyId: $companyId, analysisId: $analysisId)
		}
	}
`;
export { sageSyncAnalysisTableQuery };
export default createFragmentContainer(SageSyncAnalysisTable, {
    viewer: graphql `
		fragment SageSyncAnalysisTable_viewer on Viewer
		@argumentDefinitions(companyId: {type: "Int"}, analysisId: {type: "Int"}) {
			id
			missionControlData {
				companySyncAnalysis(companyId: $companyId, analysisId: $analysisId) {
					id
					syncDate
					entries {
						id
						companyAnalysisId
						forecastCompanyId
						sageEntityRecordNumber
						forecastEntityId
						entityType
						relevancy
					}
				}
			}
		}
	`,
});
