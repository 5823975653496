import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import * as tracking from '../tracking';
import {injectIntl} from 'react-intl';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';

class maintenance extends Component {
	constructor(props) {
		super(props);
		this.props.setLocaleFromBrowser();

		this.state = {
			seconds: 60,
		};

		this.superPropertyChecksum = trackPage('Maintenance');
	}

	UNSAFE_componentWillMount() {
		if (!this.props.viewer.downForMaintenance) {
			this.props.history.push({
				pathname: '/',
			});
		}
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('maintenance');

		// update every second
		this.interval = setInterval(() => {
			if (this.state.seconds === 0) {
				clearInterval(this.interval);
				window.location.reload();
			} else {
				this.setState({seconds: this.state.seconds - 1});
			}
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		unregisterPageInfo(this.superPropertyChecksum);
	}

	render() {
		return (
			<div className="fullscreen fullscreen-centered-outer login first-login-bg">
				<div className="logo" />
				<div className="fullscreen-centered-inner">
					<div className="fullscreen-centered-content form-vertical">
						<div className="fieldset">
							<h1>Forecast is down for temporary maintenance. Check back shortly</h1>
							<div>
								This page will reload in{' '}
								<span id="cnt" style={{color: 'red'}}>
									{this.state.seconds}
								</span>{' '}
								seconds
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const maintenanceQuery = graphql`
	query maintenance_Query {
		viewer {
			component(name: "maintenance")
			...maintenance_viewer
		}
	}
`;

export {maintenanceQuery};

export default createFragmentContainer(injectIntl(withRouter(maintenance)), {
	viewer: graphql`
		fragment maintenance_viewer on Viewer {
			component(name: "maintenance")
			downForMaintenance
		}
	`,
});
