import React from 'react';
import {useIntl} from 'react-intl';
import {InputLabel, BasicTextInput, Dropdown, CurrencyInput} from 'web-components';
import {RateCardSettingsRow, RateCardSettingsInput, RateCardSettingsContainer} from './RateCard.styled';
import {currencies, DATE_PICKER_STYLE} from '../../../constants';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {
	NumberField,
	TextField,
	DatePicker as DesignSystemDatePicker,
	DropdownList,
	ButtonDropdownField,
	FlexColumn,
	Label,
} from '@forecast-it/design-system';
import {RoundingEntities} from '../../../forecast-app/shared/util/RoundingUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const RateCardSettings = ({
	rateCardVersions,
	activeRateCardVersion,
	isRateCardUsed,
	handleDateChange,
	handleDefaultRateChange,
	currency,
	handleCurrencyChange,
	name,
	nameError,
	handleNameChange,
	exchangeRates,
	companyCurrency,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const hasRateRounding = hasFeatureFlag('rate_rounding');

	const currency_options = exchangeRates.edges.map(exchangeRate => {
		return currencies.find(currency => currency.value === exchangeRate.node.currency);
	});
	currency_options.unshift(currencies.find(currency => currency.value === companyCurrency));
	const selectedCurrencyOption = currency_options.find(option => option.value === currency);

	return (
		<RateCardSettingsContainer>
			<RateCardSettingsRow marginBottom={hasRateRounding}>
				<RateCardSettingsInput>
					{hasRateRounding ? (
						<TextField
							data-cy={'rate-card-name-input'}
							label={formatMessage({id: 'settings.rate-card-name'})}
							onValueChange={handleNameChange}
							value={name}
							placeholder={formatMessage({id: 'rate_card_modal.name_placeholder'})}
							maxLength={191}
							hasError={!!nameError}
							errorText={nameError}
						/>
					) : (
						<InputLabel
							text={formatMessage({id: 'settings.rate-card-name'})}
							required
							activeError={!!nameError}
							extension={nameError}
							child={
								<BasicTextInput
									cy={'rate-card-name-input'}
									placeholder={formatMessage({id: 'rate_card_modal.name_placeholder'})}
									value={name}
									hasError={!!nameError}
									shouldFocus={true}
									modalView={true}
									onChange={handleNameChange}
								/>
							}
						/>
					)}
				</RateCardSettingsInput>
				<RateCardSettingsInput>
					{hasRateRounding ? (
						<FlexColumn>
							<Label>{formatMessage({id: 'settings_details.currency_section'})}</Label>
							<DropdownList>
								<DropdownList.Trigger>
									<ButtonDropdownField disabled={isRateCardUsed}>
										{selectedCurrencyOption
											? selectedCurrencyOption.label
											: formatMessage({id: 'common.currency'})}
									</ButtonDropdownField>
								</DropdownList.Trigger>
								<DropdownList.Content offset={5} align={'start'}>
									{currency_options.map(option => (
										<DropdownList.Item
											key={option.value}
											onClick={() => handleCurrencyChange(option.value)}
										>
											{option.label}
										</DropdownList.Item>
									))}
								</DropdownList.Content>
							</DropdownList>
						</FlexColumn>
					) : (
						<InputLabel
							text={formatMessage({id: 'settings_details.currency_section'})}
							required
							activeError={false}
							child={
								<ForecastTooltip
									content={formatMessage({id: 'rate_card_modal.currency_disabled'})}
									disabled={!isRateCardUsed}
								>
									<Dropdown
										selectedItems={currency}
										onSelect={option => handleCurrencyChange(option[0])}
										disabled={isRateCardUsed}
										size={'large'}
										usePortal
										name={formatMessage({id: 'common.currency'})}
									>
										{currency_options.map(option => (
											<Dropdown.SingleText
												key={option.value}
												value={option.value}
												searchString={option.label}
												disabled={isRateCardUsed}
											>
												{option.label}
											</Dropdown.SingleText>
										))}
									</Dropdown>
								</ForecastTooltip>
							}
						/>
					)}
				</RateCardSettingsInput>
			</RateCardSettingsRow>
			<RateCardSettingsRow>
				<RateCardSettingsInput>
					{hasRateRounding ? (
						activeRateCardVersion?.startDate ? (
							<DesignSystemDatePicker
								onChange={date => handleDateChange(moment(date))}
								value={activeRateCardVersion?.startDate ? activeRateCardVersion.startDate : null}
								disabled={!activeRateCardVersion?.startDate}
								label={formatMessage({id: 'rate_card_modal.effective_date'})}
							/>
						) : (
							<TextField
								label={formatMessage({id: 'rate_card_modal.effective_date'})}
								disabled={true}
								value={formatMessage({id: 'rate_card_modal.initial_version'})}
							/>
						)
					) : (
						<InputLabel
							text={formatMessage({id: 'rate_card_modal.effective_date'})}
							required
							activeError={false}
							child={
								<DatePicker
									startDate={
										activeRateCardVersion?.startDate ? moment(activeRateCardVersion.startDate) : null
									}
									startDateEmptyText={formatMessage({id: 'rate_card_modal.initial_version'})}
									datePickerStyle={DATE_PICKER_STYLE.RATE_CARD_MODAL}
									isSingleDatePicker={true}
									buttonCy={'rate-card-date-input'}
									fullWidth
									leftAlign
									handleDateRangeChange={handleDateChange}
									disabled={!activeRateCardVersion?.startDate}
									disabledDates={rateCardVersions.map(rateCardVersion => moment(rateCardVersion.startDate))}
								/>
							}
						/>
					)}
				</RateCardSettingsInput>
				<RateCardSettingsInput>
					{hasRateRounding ? (
						<NumberField
							data-cy={'default-rate-input'}
							label={formatMessage({id: 'rate_card_modal.default_rate'})}
							value={activeRateCardVersion.defaultRate}
							maximumFractionDigits={RoundingEntities.RATE.decimals}
							onChange={handleDefaultRateChange}
							format={NumberField.FORMAT.CURRENCY}
							currencyCode={currency}
							width={NumberField.WIDTH.FLEX}
						/>
					) : (
						<InputLabel
							text={formatMessage({id: 'rate_card_modal.default_rate'})}
							required
							activeError={false}
							child={
								<CurrencyInput
									cy={'default-rate-input'}
									value={activeRateCardVersion.defaultRate}
									modalView={true}
									alignLeft={true}
									currencySymbole={Util.GetCurrencySymbol(currency)}
									callback={handleDefaultRateChange}
									filterNonNumerics={true}
								/>
							}
						/>
					)}
				</RateCardSettingsInput>
			</RateCardSettingsRow>
		</RateCardSettingsContainer>
	);
};

export default RateCardSettings;
