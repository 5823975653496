/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "companyId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "analysisId",
                "type": "Int"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "forceRefresh",
                "type": "Boolean"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "SageSyncAnalysisPage_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "MissionControlDataType",
                "kind": "LinkedField",
                "name": "missionControlData",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Variable",
                                "name": "analysisId",
                                "variableName": "analysisId"
                            },
                            (v1 /*: any*/),
                            {
                                "kind": "Variable",
                                "name": "forceRefresh",
                                "variableName": "forceRefresh"
                            }
                        ],
                        "concreteType": "MissionControlSageSyncAnalysisType",
                        "kind": "LinkedField",
                        "name": "companySyncAnalysis",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyId",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyName",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            (v1 /*: any*/)
                        ],
                        "concreteType": "MissionControlSageSyncAnalysisHistoryType",
                        "kind": "LinkedField",
                        "name": "companySyncAnalysisHistory",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "forecastCompanyId",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "syncDate",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MissionControlHeader_viewer"
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = 'f0507b07743d31b146c845206a295435';
export default node;
