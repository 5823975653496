/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type bambooDuplicates_viewer$ref: FragmentReference;
declare export opaque type bambooDuplicates_viewer$fragmentType: bambooDuplicates_viewer$ref;
export type bambooDuplicates_viewer = {|
  +id: string,
  +missionControlData: ?{|
    +id: string,
    +bambooDuplicates: ?$ReadOnlyArray<?{|
      +id: string,
      +company: ?string,
      +duplicateCount: ?number,
    |}>,
  |},
  +$refType: bambooDuplicates_viewer$ref,
|};
export type bambooDuplicates_viewer$data = bambooDuplicates_viewer;
export type bambooDuplicates_viewer$key = {
  +$data?: bambooDuplicates_viewer$data,
  +$fragmentRefs: bambooDuplicates_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "bambooDuplicates_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MissionControlDataType",
      "kind": "LinkedField",
      "name": "missionControlData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MissionControlBambooDuplicatesType",
          "kind": "LinkedField",
          "name": "bambooDuplicates",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duplicateCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'faa0f0973cf1c0249cb4d18667c88ebf';

module.exports = node;
