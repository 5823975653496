import { Checkbox, ComboBox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, SearchField, SubHeading, Text, } from '@forecast-it/design-system';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components/macro';
import { hideLoader, showLoader } from '../../global_loader';
import DirectApi from '../../../directApi';
import CreateAdoUsersMutation from '../../../mutations/ts/integrations/azure_devops/CreateAdoUsersMutation';
import { CommitMutation } from '../../../mutations/ts/CommitMutation';
import { getSelectableProfiles, personHasPermission } from '../../../forecast-app/shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../Permissions';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { BUTTON_COLOR, BUTTON_STYLE } from '../../../constants';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: 1px solid #ccc;
`;
const ListWrapper = styled.div `
	height: 400px;
`;
const AdoImportUsersModal = ({ closeModal, company }) => {
    const { formatMessage } = useIntl();
    const [adoUsers, setAdoUsers] = useState([]);
    const [filteredAdoUsers, setFilteredAdoUsers] = useState([]);
    const [selectedAdoUsers, setSelectedAdoUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [importing, setImporting] = useState(false);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [projects, setProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const disableImportButton = importing || selectedAdoUsers.length === 0 || selectedProfile === null;
    useEffect(() => {
        var _a;
        // Don't allow import of Admin users
        const filteredProfiles = getSelectableProfiles((_a = company.profiles) === null || _a === void 0 ? void 0 : _a.edges).filter(profile => !personHasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS, profile));
        setProfiles(filteredProfiles);
        // Fetch ADO projects
        showLoader();
        DirectApi.Fetch(`vsts_projects`).then((adoProjects) => {
            hideLoader();
            setProjects(adoProjects);
        });
    }, []);
    useEffect(() => {
        if (selectedProjectId) {
            showLoader();
            const adoProject = projects.find(p => p.id === selectedProjectId);
            // Fetch Ado Users
            DirectApi.Fetch(`vsts_team_members?vsts_project_id=${selectedProjectId}&account=${adoProject === null || adoProject === void 0 ? void 0 : adoProject.account}`).then(response => {
                hideLoader();
                if (response.success) {
                    setAdoUsers(response.userList);
                    setFilteredAdoUsers(response.userList);
                }
                else {
                    // TODO: Show error message
                    console.log(response.error);
                }
            });
        }
    }, [selectedProjectId]);
    const selectAllClick = () => {
        if (selectAll) {
            setSelectedAdoUsers([]);
            setSelectAll(false);
        }
        else {
            const selectedUsers = adoUsers.map(user => user.identity.id);
            setSelectedAdoUsers(selectedUsers);
            setSelectAll(true);
        }
    };
    const userSelect = (inputId) => {
        setSelectAll('indeterminate');
        if (selectedAdoUsers.includes(inputId)) {
            const selectedUsers = selectedAdoUsers.filter(id => id !== inputId);
            if (selectedUsers.length === 0) {
                setSelectAll(false);
            }
            setSelectedAdoUsers(selectedUsers);
        }
        else {
            const selectedUsers = [...selectedAdoUsers];
            selectedUsers.push(inputId);
            if (selectedUsers.length === adoUsers.length) {
                setSelectAll(true);
            }
            setSelectedAdoUsers(selectedUsers);
        }
    };
    const onFilterChange = (input) => {
        setFilteredAdoUsers(adoUsers.filter(user => `${user.identity.displayName} ${user.identity.uniqueName}`.toLowerCase().includes(input.toLowerCase())));
    };
    const onExportClick = () => {
        setImporting(true);
        showLoader();
        CommitMutation(CreateAdoUsersMutation, {
            users: adoUsers
                .filter(user => selectedAdoUsers.includes(user.identity.id))
                .map(adoUser => ({
                firstName: adoUser.identity.displayName,
                lastName: '',
                email: adoUser.identity.uniqueName,
                profileIds: [selectedProfile],
                active: false,
                invited: false,
                externalRefs: [
                    {
                        key: 'vsts_member_id',
                        value: `${adoUser.identity.id}`,
                    },
                ],
            })),
        }, response => {
            var _a, _b, _c;
            setImporting(false);
            closeModal();
            hideLoader();
            if ((_b = (_a = response.createAdoUsers) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) {
                showModal({
                    type: MODAL_TYPE.WARNING,
                    message: 'The following Azure DevOps users could not be created:',
                    warningInformation: (_c = response.createAdoUsers) === null || _c === void 0 ? void 0 : _c.errors.map(error => error),
                    buttons: [
                        {
                            text: formatMessage({ id: 'common.ok' }),
                            style: BUTTON_STYLE.FILLED,
                            color: BUTTON_COLOR.WHITE,
                        },
                    ],
                });
            }
            else {
                showModal({
                    type: MODAL_TYPE.INTEGRATIONS_INFO,
                    title: 'Import Azure DevOps Users',
                    infoMessage: 'The resources have been created in Forecast as Deactivated team members.',
                });
            }
        }, false);
    };
    return (React.createElement(CreativeModal, { title: 'Import Azure DevOps Users', size: 'l', closeModal: closeModal, creativeButtonText: `Import Selected Azure DevOps Users (${selectedAdoUsers.length})`, cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onExportClick, onCancelHandler: closeModal, disabled: disableImportButton, replicateDesignSystem: true },
        React.createElement(Text, null, 'Please choose the Azure DevOps resources that you would like to create in Forecast. The chosen resources will be created in Forecast as Deactivated team members.'),
        React.createElement(ComboBox, { label: "Permission Profile", defaultItems: profiles, value: selectedProfile, onChange: setSelectedProfile, width: ComboBox.WIDTH.FLEX }, profile => (React.createElement(ComboBox.Item, { key: profile.id, textValue: profile.name },
            React.createElement(Text, null, profile.name)))),
        React.createElement(ComboBox, { label: "Azure Devops project", defaultItems: projects, value: selectedProjectId, onChange: setSelectedProjectId, width: ComboBox.WIDTH.FLEX }, (project) => (React.createElement(ComboBox.Item, { key: project.id, textValue: project.name },
            React.createElement(Text, null, project.name)))),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Text, { color: Text.color.medium }, `Search for Azure DevOps users`),
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, width: "600px" })),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: selectAllClick, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null,
            React.createElement(Scrollbar, { maxHeight: '500px' },
                React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredAdoUsers &&
                    filteredAdoUsers.map((user) => {
                        return (React.createElement(ListItem, { key: 'adoUser-' + user.identity.id },
                            React.createElement(FlexRow, { gap: 'xl' },
                                React.createElement(Checkbox, { onChange: () => userSelect(user.identity.id), checked: selectedAdoUsers.includes(user.identity.id) }),
                                React.createElement(Text, { size: '2' }, `${user.identity.displayName} (${user.identity.uniqueName})`))));
                    }))))));
};
const adoImportUsersModalQuery = graphql `
	query AdoImportUsersModal_Query {
		viewer {
			actualPersonId
			component(name: "ado_import_users_modal")
			company {
				...AdoImportUsersModal_company
			}
		}
	}
`;
export { adoImportUsersModalQuery };
export default createFragmentContainer(AdoImportUsersModal, {
    company: graphql `
		fragment AdoImportUsersModal_company on Company {
			profiles {
				edges {
					node {
						id
						name
						disabled
						permissions
					}
				}
			}
		}
	`,
});
