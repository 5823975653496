/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type resetPassword_viewer$ref = any;
export type resetPassword_QueryVariables = {||};
export type resetPassword_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: resetPassword_viewer$ref,
  |}
|};
export type resetPassword_Query = {|
  variables: resetPassword_QueryVariables,
  response: resetPassword_QueryResponse,
|};
*/


/*
query resetPassword_Query {
  viewer {
    component(name: "reset_password")
    ...resetPassword_viewer
    id
  }
}

fragment resetPassword_viewer on Viewer {
  id
  actualPersonId
  lastName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "reset_password"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"reset_password\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "resetPassword_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "resetPassword_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "resetPassword_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "resetPassword_Query",
    "operationKind": "query",
    "text": "query resetPassword_Query {\n  viewer {\n    component(name: \"reset_password\")\n    ...resetPassword_viewer\n    id\n  }\n}\n\nfragment resetPassword_viewer on Viewer {\n  id\n  actualPersonId\n  lastName\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3de3d52aae1ea0610631ef21009bfde0';

module.exports = node;
