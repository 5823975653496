import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import AssignMultipleSkillsToMultiplePersonsMutation from '../../../mutations/assign_multiple_persons_to_multiple_skills_mutation';
import PeoplePreviewSelector from '../../../forecast-app/shared/components/dropdowns/preview-selector/PersonsPreviewSelector';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';

const AssignSkillsToPersonsModal = ({skillIds, company, closeModal}) => {
	const {formatMessage} = useIntl();
	const [selectedPersons, setSelectedPersons] = useState([]);

	const bulkAssignSkills = () => {
		const personIds = selectedPersons.map(person => person.personId);
		Util.CommitMutation(
			AssignMultipleSkillsToMultiplePersonsMutation,
			{
				skillIds: skillIds,
				personIds: personIds,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage(
						{id: 'settings_skills.skills_assigned_to_users'},
						{
							skillCount: skillIds.size,
							personCount: selectedPersons.length,
						}
					),
				});
			}
		);
	};

	const persons = company.allPersons.edges.map(edge => edge.node);

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={
				skillIds.size === 1
					? formatMessage({id: 'settings_skills.assign_skill_to_persons_title'})
					: formatMessage({id: 'settings_skills.assign_skills_to_persons_title'}, {skillCount: skillIds.size})
			}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.assign'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: bulkAssignSkills,
					disabled: !selectedPersons || selectedPersons.length <= 0,
					cy: 'button-assign',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<PeoplePreviewSelector
							persons={persons}
							selectedPersons={selectedPersons}
							onChange={setSelectedPersons}
							hideSelectionHeaderActions
							selectionHeaderLabel={formatMessage({id: 'common.added'})}
							required
						/>
					</Field>
				</ModalBody>
			}
		/>
	);
};

const assignSkillsToPersonsModalQuery = graphql`
	query AssignSkillsToPersonsModal_Query {
		viewer {
			actualPersonId
			component(name: "assign_skills_to_persons_modal")
			company {
				...AssignSkillsToPersonsModal_company
			}
		}
	}
`;

export {assignSkillsToPersonsModalQuery};

export default createFragmentContainer(AssignSkillsToPersonsModal, {
	company: graphql`
		fragment AssignSkillsToPersonsModal_company on Company {
			id
			allPersons(first: 10000, excludeClientUsers: true) {
				edges {
					node {
						...PersonsPreviewSelector_persons
					}
				}
			}
		}
	`,
});
