/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BambooHRImportUsersModal_company$ref: FragmentReference;
declare export opaque type BambooHRImportUsersModal_company$fragmentType: BambooHRImportUsersModal_company$ref;
export type BambooHRImportUsersModal_company = {|
  +profiles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +$refType: BambooHRImportUsersModal_company$ref,
|};
export type BambooHRImportUsersModal_company$data = BambooHRImportUsersModal_company;
export type BambooHRImportUsersModal_company$key = {
  +$data?: BambooHRImportUsersModal_company$data,
  +$fragmentRefs: BambooHRImportUsersModal_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BambooHRImportUsersModal_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeConnection",
      "kind": "LinkedField",
      "name": "profiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Profile",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '69a15c4e75bda84f4ba6724882e11085';

module.exports = node;
