import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import UpdateProjectMutation from '../../mutations/update_project_budget_page_mutation';
import Util from '../../forecast-app/shared/util/util';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE, BUDGET_TYPE} from '../../constants';
import Input from '../../components/new-ui/input_field';
import styled from 'styled-components';

const changeFixPriceModal = ({closeModal, isFixedPrice, project, currency, intl, callback}) => {
	const {budget, forecastBillablePriceScopeBased} = project;
	const [fixedPrice, setFixedPrice] = useState(budget || 0);

	const noScopeBasedRevenue = forecastBillablePriceScopeBased === 0;

	//markup = fixed price/ revenue - 1
	const calculateMarkup = val => {
		if (noScopeBasedRevenue) {
			return null;
		} else {
			return Util.roundToDecimals((val / forecastBillablePriceScopeBased) * 100 - 100, 2);
		}
	};

	const markup = calculateMarkup(budget || 0);
	const [markupPercentage, setMarkupPercentage] = useState(markup);
	const {formatMessage} = intl;
	const isDollarOrEuro = Util.CurrencyIsPrefixed(currency);
	const planRevenueTooltip = [
		{
			title: formatMessage({id: 'project_budget.change_fixed_price_revenue_tooltip'}),
			details: [
				formatMessage({id: 'scope.title'}),
				'x',
				formatMessage({id: 'project_budget.original_rate'}),
				'+',
				formatMessage({id: 'project_budget.price_on_planned_billable_expenses'}),
			],
		},
	];

	//fixed price = revenue * (1+ markup)
	const onMarkupChange = val => {
		if (noScopeBasedRevenue) {
			return;
		}
		const fixedPrice = Util.roundToDecimals((forecastBillablePriceScopeBased * (100 + val)) / 100, 2);
		!isNaN(fixedPrice) && setFixedPrice(fixedPrice);
		!isNaN(val) && setMarkupPercentage(val);
	};

	const onFixedPriceChange = val => {
		setFixedPrice(val);

		if (noScopeBasedRevenue) {
			return;
		}
		const markup = calculateMarkup(val);

		!isNaN(markup) && setMarkupPercentage(markup);
	};

	const handleFixedPriceSave = () => {
		let newBudgetType = project.budgetType;

		if (!isFixedPrice) {
			newBudgetType = BUDGET_TYPE.FIXED_PRICE_V2;
		}

		const mutationObject = {
			budgetType: newBudgetType,
			budget: fixedPrice,
			project,
		};

		const onSuccess = () => {
			closeModal();
			callback();
		};

		// eslint-disable-next-line
		Util.CommitMutation(UpdateProjectMutation, mutationObject, onSuccess);
	};

	const content = (
		<div className="">
			<p>{formatMessage({id: 'project_budget.change_fixed_price_description'})}</p>
			<div className="form">
				<div className="plan-revenue">
					<TooltipContainer infoText={planRevenueTooltip} translatedMessage={true} triangleLocation="bottomMiddle">
						<div className="label">{formatMessage({id: 'project_budget.revenue'})}</div>
						<div className="no-decorations">
							<Input
								value={forecastBillablePriceScopeBased}
								type="formatted"
								showCurrencyOnLeft={isDollarOrEuro}
								placeholder=""
								hideLabel={true}
								currency={currency}
								allowDecimals={true}
								locked={true}
								cy={'plan-revenue'}
							/>
						</div>
					</TooltipContainer>
				</div>
				<div className="markup">
					<div className="label">{formatMessage({id: 'common.markup'})}</div>
					<div className="input-control">
						<Input
							type="formatted"
							suffix="%"
							alignTextRight={true}
							value={markupPercentage || ''}
							locked={noScopeBasedRevenue}
							placeholder={0}
							onChange={onMarkupChange}
							hideLabel={true}
							cy={'markup-percentage'}
						/>
					</div>
				</div>
				<div className="fixed-price">
					<div className="label">{formatMessage({id: 'common.fixed_price'})}</div>
					<div className="input-control">
						<Input
							value={fixedPrice}
							alignTextRight={true}
							type="formatted"
							showCurrencyOnLeft={isDollarOrEuro}
							onChange={onFixedPriceChange}
							placeholder=""
							hideLabel={true}
							currency={currency}
							allowDecimals={true}
							autoFocus={true}
							invalidInput={!fixedPrice}
							cy={'markup-fixed-price'}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<StyledModal
			closeModal={closeModal}
			className="change-cost-rate-modal-budget"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'project_budget.save_changes'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					preventDefaultClose: true,
					callback: handleFixedPriceSave,
					disabled: !fixedPrice,
					cy: 'next-button',
				},
			]}
			headerText={
				isFixedPrice
					? formatMessage({id: 'project_budget.edit_fixed_price'})
					: formatMessage({id: 'project_budget.set_fixed_price'})
			}
			content={content}
		/>
	);
};

const StyledModal = styled(GenericModal)`
	.form {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: auto;
		grid-gap: 1rem;
	}
	.input-control {
		border-radius: 4px;
		border: 1px solid rgb(230, 230, 230);
		min-height: 36px;
		max-width: 180px;
	}
	.label {
		margin-bottom: 0.6rem;
	}
	.header-text {
		text-transform: capitalize;
	}
	.no-decorations .input-container-v2 .input-value-container {
		border: none;
		background-color: #ffff;
	}
	.no-decorations .input-container-v2 .currency-placeholder:before {
		left: 0px;
	}
	.no-decorations .input-container-v2 .search-icon-placeholder input,
	.no-decorations .input-container-v2 .currency-placeholder input {
		margin-left: 15px;
		padding: 0;
		font-size: 13px;
	}
`;

export default injectIntl(changeFixPriceModal);
