import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import React, {useMemo, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {useIntl} from 'react-intl';
import {InputLabel, SIZE} from 'web-components';
import {HelpIcon} from 'web-components/icons';
import SkillDropdown from '../../../forecast-app/shared/components/dropdowns/skill-dropdown/SkillDropdown';
import Styled from 'styled-components';
import InputFieldV2 from '../../../components/new-ui/input_field';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../forecast-app/shared/util/util';
import CreatePlaceholderMutation from '../../../mutations/create_placeholder_mutation';
import UpdatePlaceholderMutation from '../../../mutations/update_placeholder_mutation';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {deletePlaceholderMutation, duplicatePlaceholderMutation, handleMutationSuccess} from './PlaceholderAllocationUtils';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {TeamsSelector} from './TeamsSelector';
import {DepartmentSelector} from './DepartmentSelector';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';

const PlaceholderModalContent = Styled.div`
	.dropdown-container {
	    height: 40px;
	    > div {
	        padding: 0;
	    }
	}
	label.input-title {
		height: auto;
	}
	input {
        padding: 10px 0 10px 8px;
	}
`;

const LabelContainer = Styled.div`
	height: auto;
	display: flex;
	align-items: center;
`;

const PlaceholderModal = ({closeModal, viewer, projectId, projectGroupId}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const {company} = viewer;
	const {placeholder} = company;
	const placeholderId = placeholder?.id;
	const roles = company.roles.edges.map(edge => edge.node);
	const roleOptions = roles.map(role => ({value: role.id, label: role.name}));

	const initialState = useMemo(() => getInitialState(placeholder), []);

	const [name, setName] = useState(initialState.name);
	const [selectedRoleId, setSelectedRoleId] = useState(initialState.roleId);
	const [selectedSkillLevels, setSelectedSkillLevels] = useState(initialState.skillLevels);
	const [teamIds, setTeamIds] = useState(initialState.teamIds);
	const [departmentId, setDepartmentId] = useState(initialState.departmentId);

	const disableCreate = !name || !selectedRoleId;

	function getInitialState(placeholder) {
		let name = '';
		let roleId = null;
		let departmentId = null;
		let teamIds = [];
		let skillLevels = [];

		if (placeholder && placeholder.id) {
			name = placeholder.name;
			roleId = placeholder.role?.id;
			departmentId = placeholder.departmentId;
			skillLevels = placeholder.skillPlaceholders.map(skillPlaceholder => ({
				skillId: skillPlaceholder.skill.id,
				skillLevelId: skillPlaceholder.level?.id,
			}));
			teamIds = placeholder.teamPlaceholders.map(teamPlaceholder => teamPlaceholder.teamId);
		}

		return {
			name,
			roleId,
			departmentId,
			skillLevels,
			teamIds,
		};
	}

	const deletePlaceholder = () => {
		closeModal();
		if (placeholder.placeholderAllocations) {
			showModal({
				type: MODAL_TYPE.DELETE_PLACEHOLDER_CONFIRMATION,
				placeholderId: placeholderId,
				placeholderAllocations: placeholder.placeholderAllocations,
			});
		} else {
			deletePlaceholderMutation(formatMessage, placeholderId, handleMutationSuccess);
		}
	};

	const duplicatePlaceholder = () => {
		closeModal();
		duplicatePlaceholderMutation(formatMessage, placeholderId);
	};

	const updatePlaceholder = () => {
		const updateRequest = {
			id: placeholderId,
			projectId: projectId,
			projectGroupId: projectGroupId,
			name,
			departmentId,
			teamIds,
			roleId: selectedRoleId,
			skillPlaceholders: selectedSkillLevels,
		};

		Util.CommitMutation(UpdatePlaceholderMutation, updateRequest, response => {
			handleMutationSuccess(response);
			createToast({
				duration: 5000,
				message: formatMessage({id: 'placeholder.changes_saved'}),
			});
		});
	};

	const createPlaceholder = () => {
		const createRequest = {
			projectId: projectId,
			projectGroupId: projectGroupId,
			name,
			departmentId,
			teamIds,
			roleId: selectedRoleId,
			skillPlaceholders: selectedSkillLevels,
		};
		Util.CommitMutation(CreatePlaceholderMutation, createRequest, response => {
			handleMutationSuccess(response);
			closeModal();
			createToast({
				duration: 5000,
				message: formatMessage({id: 'scheduling.placeholder_created'}),
			});
			showModal({
				type: MODAL_TYPE.PLACEHOLDER_ALLOCATION,
				projectId: projectId,
				projectGroupId: projectGroupId,
				isFromCreatePlaceholder: true,
				staffingModeActive: false,
				placeholderInput: response.createPlaceholder.placeholder.node,
			});
		});
	};

	const onRole = option => {
		setSelectedRoleId(option.value);
		if (name === '') {
			setName(option.label);
		}
	};

	const onNameChange = newName => {
		setName(newName);
	};

	const onSkillSelectionUpdate = (_, selections) => {
		console.log({selections});
		setSelectedSkillLevels(selections);
	};

	const content = (
		<PlaceholderModalContent>
			<ModalBody>
				<Field>
					<DropdownV2
						usePortal
						options={roleOptions}
						onChange={onRole}
						value={selectedRoleId}
						label={formatMessage({id: 'common.role'})}
						placeholder={formatMessage({id: 'placeholder.select_role'})}
						inputName={'role-input'}
						inputCy={'role'}
						listDataCy={'role'}
					/>
				</Field>
				<Field>
					<InputFieldV2
						value={name}
						cy={'placeholder-name'}
						id="placeholderName"
						label={formatMessage({id: 'common.name'})}
						placeholder={formatMessage({id: 'common.name_placeholder'})}
						type="text"
						onChange={onNameChange}
						maxLength={191}
					/>
				</Field>
				<Field>
					<InputLabel
						text={`${formatMessage({id: 'common.skills'})} ${formatMessage({id: 'common.field_optional_label'})}`}
						child={
							<SkillDropdown
								usePortal
								name={formatMessage({id: 'settings_skills.add_skills'})}
								company={company}
								selectedItems={selectedSkillLevels}
								onSelect={onSkillSelectionUpdate}
								onRemove={onSkillSelectionUpdate}
								showSkillsBelow
								selectLevels={company.skillLevelsEnabled}
								multiSelect={company.skillLevelsEnabled}
							/>
						}
					/>
				</Field>
				{hasFeatureFlag('capacity_planning_beta_2_improvements') && (
					<>
						<Field>
							<InputLabel
								text={
									<LabelContainer>
										<span>
											{`${formatMessage({id: 'common.team'})} ${formatMessage({
												id: 'common.field_optional_label',
											})}`}
										</span>
										<ForecastTooltip content={formatMessage({id: 'placeholder.teams_tooltip'})}>
											<HelpIcon size={SIZE.SMALL} />
										</ForecastTooltip>
									</LabelContainer>
								}
								child={<TeamsSelector selected={teamIds} teams={company.teams.edges} onChange={setTeamIds} />}
							/>
						</Field>
						<Field>
							<InputLabel
								text={
									<LabelContainer>
										<span>
											{`${formatMessage({id: 'common.department'})} ${formatMessage({
												id: 'common.field_optional_label',
											})}`}
										</span>
										<ForecastTooltip content={formatMessage({id: 'placeholder.department_tooltip'})}>
											<HelpIcon size={SIZE.SMALL} />
										</ForecastTooltip>
									</LabelContainer>
								}
								child={
									<DepartmentSelector
										selected={departmentId}
										departments={company.departments.edges}
										onChange={setDepartmentId}
									/>
								}
							/>
						</Field>
					</>
				)}
			</ModalBody>
		</PlaceholderModalContent>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={
				placeholderId
					? formatMessage({id: 'placeholder.placeholder'})
					: formatMessage({id: 'placeholder.create_placeholder'})
			}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					callback: closeModal,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: placeholderId ? formatMessage({id: 'common.save'}) : formatMessage({id: 'common.create'}),
					callback: placeholderId ? updatePlaceholder : createPlaceholder,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					disabled: disableCreate,
				},
			]}
			actionsMenuOptions={
				placeholderId
					? [
							{
								text: formatMessage({id: 'common.delete'}),
								onClick: deletePlaceholder,
							},
							{
								text: formatMessage({id: 'common.duplicate'}),
								onClick: duplicatePlaceholder,
							},
					  ]
					: undefined
			}
		/>
	);
};

const placeholderModalQuery = graphql`
	query PlaceholderModal_Query($placeholderId: ID, $loadPlaceholder: Boolean!) {
		viewer {
			actualPersonId
			component(name: "placeholder_modal")
			...PlaceholderModal_viewer @arguments(placeholderId: $placeholderId, loadPlaceholder: $loadPlaceholder)
		}
	}
`;

export {placeholderModalQuery};

export default createFragmentContainer(PlaceholderModal, {
	viewer: graphql`
		fragment PlaceholderModal_viewer on Viewer
		@argumentDefinitions(placeholderId: {type: "ID"}, loadPlaceholder: {type: "Boolean!"}) {
			id
			email
			company {
				skillLevelsEnabled
				...SkillDropdown_company
				placeholder(placeholderId: $placeholderId) @include(if: $loadPlaceholder) {
					id
					name
					role {
						id
						name
					}
					skills(first: 10000) @connection(key: "Placeholder_skills") {
						edges {
							node {
								id
								name
							}
						}
					}
					skillPlaceholders {
						skill {
							id
						}
						level {
							id
						}
					}
					placeholderAllocations(first: 10000) @connection(key: "Placeholder_placeholderAllocations") {
						edges {
							node {
								id
							}
						}
					}
					departmentId
					teamPlaceholders {
						teamId
					}
				}
				roles {
					edges {
						node {
							id
							name
						}
					}
				}
				teams {
					edges {
						...TeamDropdown_teams
						node {
							id
							name
						}
					}
				}
				departments {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
