import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import Util from '../../../forecast-app/shared/util/util';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';
import InformationIcon from '../../../images/information-icon';
import ProjectTeamLineItem from '../../../components/project-team/project_team_line_item';
import {isClientUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

const ProjectSettingsTeamGroup = ({
	viewer,
	groupName,
	initialShowMembers,
	projectPeople,
	useTimeApproval,
	harvestEnabled,
	roles,
	projectLocked,
	isAdmin,
	isInGroup,
	hasProjectPersonUpdateAccess,
	getPersonsTeam,
	projectGroupProjectPersonsMap,
	projectPersonProjectMap,
	useManualAllocations,
	isProjectGroupPage,
	retry,
}) => {
	const {formatMessage} = useIntl();
	const [showMembers, setShowMembers] = useState(initialShowMembers);

	return (
		<div className="member-table">
			<div className={'top-container'}>
				<div className={'group-header'}>
					<h2>
						{`${groupName}`}
						<span data-cy="number-of-users" className={'seats-info'}>
							{formatMessage({id: 'settings_people.no_team_members'}, {number: projectPeople.length})}
						</span>
					</h2>
				</div>
				<div className="controls controls-new">
					<div
						className={'expand-control' + (showMembers ? ' expand' : ' collapse')}
						onClick={() => setShowMembers(prev => !prev)}
					/>
				</div>
			</div>
			{showMembers && (
				<table>
					<thead>
						<tr>
							<th className="column-person">{formatMessage({id: 'settings_team.person'})}</th>
							<th className="column-role">{formatMessage({id: 'settings_team.main-role'})}</th>
							<th className="column-role">{formatMessage({id: 'settings_team.project-role'})}</th>
							<th className="column-permissions">{formatMessage({id: 'common.permission'})}</th>
							<th className="column-relation-type">
								<div className="header-container">
									<span className="header-text">{formatMessage({id: 'project_person.relation_type'})}</span>
									<ForecastTooltip
										content={Util.upperCaseFirst(
											formatMessage(
												{
													id: 'project_person.relation_type.description',
												},
												{program: ProgramUtil.programText(formatMessage)}
											)
										)}
									>
										<InformationIcon />
									</ForecastTooltip>
								</div>
							</th>
							<th className="column-contact-person">
								<div className="header-container">
									<span className="header-text">{formatMessage({id: 'common.project_owner'})}</span>
									<ForecastTooltip
										content={formatMessage({
											id: useTimeApproval ? 'project_team.owner_tooltip' : 'project_team.tooltip',
										})}
									>
										<InformationIcon />
									</ForecastTooltip>
								</div>
							</th>
							<th className="column-teams">{formatMessage({id: 'common.team'})}</th>
							{harvestEnabled ? (
								<th className="column-controls">{formatMessage({id: 'title.app_harvest'})}</th>
							) : null}
							<th className="column-controls">&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{projectPeople.map(projectPerson => (
							<ProjectTeamLineItem
								key={projectPerson.id}
								roles={roles}
								projectPerson={projectPerson}
								company={viewer.company}
								projectId={viewer.project ? viewer.project.id : null}
								projectGroupId={viewer.projectGroup ? viewer.projectGroup.id : null}
								projectLocked={projectLocked}
								isAdmin={isAdmin}
								isInGroup={isInGroup}
								isClient={isClientUser()}
								hasProjectPersonUpdateAccess={hasProjectPersonUpdateAccess}
								personTeams={getPersonsTeam(projectPerson)}
								isProjectGroup={viewer.projectGroup !== null && viewer.projectGroup !== undefined}
								projectGroupProjectPersonsMap={projectGroupProjectPersonsMap}
								projectPersonProjectMap={projectPersonProjectMap}
								useManualAllocations={useManualAllocations}
								isProjectGroupPage={isProjectGroupPage}
								retry={retry}
							/>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

ProjectSettingsTeamGroup.prototype = {};

export default ProjectSettingsTeamGroup;
