import {BUDGET_TYPE} from '../../../constants';
import {createFragmentContainer, graphql} from 'react-relay';
import React from 'react';
import EditInvoiceModal from './edit_invoice_modal';

const standaloneEditInvoiceModal = props => {
	const {
		closeModal,
		refetch,
		viewer: {invoice, projects, company, language},
		isProjectInvoicing,
		projectId,
	} = props;
	const filteredProjects = projects.edges.filter(project => project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE);
	const project = projectId ? projects.edges.find(project => project.node.id === projectId).node : null;

	return (
		<EditInvoiceModal
			closeModal={closeModal}
			company={company}
			invoice={invoice}
			projects={{edges: filteredProjects}}
			refetch={refetch}
			isProjectInvoicing={isProjectInvoicing}
			project={project}
			language={language}
		/>
	);
};

const standaloneEditInvoiceModalQuery = graphql`
	query standaloneEditInvoiceModal_Query($invoiceId: ID, $companyInvoiceId: String) {
		viewer {
			actualPersonId
			component(name: "edit_invoice_modal_standalone")
			...standaloneEditInvoiceModal_viewer @arguments(invoiceId: $invoiceId, companyInvoiceId: $companyInvoiceId)
		}
	}
`;

export {standaloneEditInvoiceModalQuery};

export default createFragmentContainer(standaloneEditInvoiceModal, {
	viewer: graphql`
		fragment standaloneEditInvoiceModal_viewer on Viewer
		@argumentDefinitions(invoiceId: {type: "ID"}, companyInvoiceId: {type: "String"}) {
			id
			backendId
			company {
				invoiceQuantityDecimals
			}
			invoice(id: $invoiceId, companyInvoiceId: $companyInvoiceId) {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				economicId
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quantityLockedReason
							project {
								id
								companyProjectId
								name
								projectColor
								isInProjectGroup
								budgetType
								client {
									name
								}
								rateCard {
									currency
								}
							}
						}
					}
				}
				payments(first: 100000000) {
					edges {
						node {
							id
							notes
							amount
							day
							month
							year
							createdAt
							createdBy {
								fullName
							}
						}
					}
				}
				timeRegistrations(first: 100000000) {
					edges {
						node {
							id
							year
							month
							day
							project {
								id
								companyProjectId
								projectColor
							}
							task {
								project {
									id
									companyProjectId
									projectColor
								}
							}
							person {
								fullName
							}
							notes
							billableMinutesRegistered
							minutesRegistered
							price
							cost
							rate
						}
					}
				}
				client {
					id
					name
				}
			}
			projects {
				edges {
					...ProjectDropdown_projects
					node {
						id
						name
						projectColor
						isInProjectGroup
						companyProjectId
						projectGroupId
						budgetType
						status
						fullAccessToProject
						client {
							id
						}
						...DeprecatedProjectIndicatorJS_project
					}
				}
			}
			language
		}
	`,
});
