export const addPerformanceData = (data, performance, statsPerDayMap, previousPerformance, dateInfo) => {
	const {date, momentDate, isToday, isPast} = dateInfo;

	let dayPerformance = null;

	if (isToday) {
		data.performance.push({
			date: momentDate,
			value: performance,
		});
	} else if (isPast) {
		dayPerformance = statsPerDayMap.get(date)?.performance;
		data.performance.push({
			date: momentDate,
			value: dayPerformance || previousPerformance,
		});
	}

	return dayPerformance;
};

export const addTodayIntersection = (data, performance, dateInfo) => {
	const {momentDate, isToday, isPast, isFuture} = dateInfo;

	if (isToday) {
		data.todayIntersection.push({
			date: momentDate,
			value: performance,
			customData: {
				hideLabel: true,
			},
		});
	} else if (isFuture) {
		data.todayIntersection.push({
			date: momentDate,
			value: null,
		});
	} else if (isPast) {
		data.todayIntersection.push({
			date: momentDate,
			value: null,
		});
	}
};
