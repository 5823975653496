/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JiraStartDateModal_viewer$ref = any;
export type JiraStartDateModal_QueryVariables = {||};
export type JiraStartDateModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: JiraStartDateModal_viewer$ref,
  |}
|};
export type JiraStartDateModal_Query = {|
  variables: JiraStartDateModal_QueryVariables,
  response: JiraStartDateModal_QueryResponse,
|};
*/


/*
query JiraStartDateModal_Query {
  viewer {
    actualPersonId
    component(name: "jira_start_date_modal")
    ...JiraStartDateModal_viewer
    id
  }
}

fragment JiraStartDateModal_viewer on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    id
    modules {
      moduleType
      id
    }
    integrations {
      jiraCloud {
        dateFields {
          id
          name
        }
        startDateField {
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "jira_start_date_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"jira_start_date_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JiraStartDateModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JiraStartDateModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JiraStartDateModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyIntegrationsType",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyJiraSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraCloud",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JiraFieldType",
                        "kind": "LinkedField",
                        "name": "dateFields",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JiraStartDateFieldType",
                        "kind": "LinkedField",
                        "name": "startDateField",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "JiraStartDateModal_Query",
    "operationKind": "query",
    "text": "query JiraStartDateModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"jira_start_date_modal\")\n    ...JiraStartDateModal_viewer\n    id\n  }\n}\n\nfragment JiraStartDateModal_viewer on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    id\n    modules {\n      moduleType\n      id\n    }\n    integrations {\n      jiraCloud {\n        dateFields {\n          id\n          name\n        }\n        startDateField {\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46bd355a43bb2e71052b22555daadaa0';

module.exports = node;
