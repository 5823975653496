import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, injectIntl, useIntl} from 'react-intl';
import {hideLoader, showLoader} from '../global_loader';
import UpdateProjectMutation from '../../mutations/update_project_budget_page_mutation';
import Util from '../../forecast-app/shared/util/util';
import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE, CAP_SETTING, PERIOD_BUDGET_TYPE, PERIODICITY} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import GenericModal, {MODAL_WIDTH} from './generic_modal';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import InformationIcon from '../../images/information-icon';
import ProjectBudgetTypeControls from '../../components/new-ui/project/project_budget_type_controls';
import {
	DeprecatedContentContainer as ContentContainer,
	FlexColumn,
	DeprecatedLink as Link,
	DeprecatedNoticeBanner as NoticeBanner,
	DeprecatedText as Text,
	deprecatedTheme as theme,
} from '@forecast-it/design-system';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {useHistory} from 'react-router-dom';
import {ProgramBudgetExceededWarning} from '../../components/new-ui/ProgramBudgetExceededWarning';
import ProgramUtil from '../../forecast-app/shared/util/ProgramUtil';

const isFixedPriceType = budgetType => {
	return budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
};

const getDefaultFixedPriceBudget = (budgetType, budget) => {
	return isFixedPriceType(budgetType) && budget !== undefined ? budget : '';
};

const ProjectBudgetChangeModal = ({
	project,
	program,
	isInFixedPriceProgram,
	isInCappedRevenueProgram,
	programBudget,
	programAllocation,
	programRemainingBudget,
	isProgramRevenueLocked,
	canManageProgram,
	revenueSetting,
	useRetainers,
	hasFinance,
	hasInvoice,
	currency,
	closeModal,
	retry,
	titleText,
	cancelText,
	confirmText,
}) => {
	const [budgetType, setBudgetType] = useState(project.budgetType);
	const [defaultPeriodPriceAmount, setDefaultPeriodPriceAmount] = useState(project.defaultPeriodPriceAmount);
	const [defaultPeriodHoursAmount, setDefaultPeriodHoursAmount] = useState(project.defaultPeriodHoursAmount);
	const [defaultPeriodBudgetType, setDefaultPeriodBudgetType] = useState(
		project.defaultPeriodBudgetType || PERIOD_BUDGET_TYPE.FIXED_HOURS
	);
	const [defaultPeriodLength, setDefaultPeriodLength] = useState(project.defaultPeriodLength || 1);
	const [defaultPeriodPeriodicity, setDefaultPeriodPeriodicity] = useState(
		project.defaultPeriodPeriodicity || PERIODICITY.MONTHLY
	);
	const [wrongPeriodLength, setWrongPeriodLength] = useState(false);
	const [wrongPeriodAmount, setWrongPeriodAmount] = useState(false);
	const [invalidFixedPriceFormat, setInvalidFixedPriceFormat] = useState(false);
	const [fixedPriceCappedBudgetOverflow, setFixedPriceCappedBudgetOverflow] = useState(false);
	const [fixedPriceLockedRevenueCap, setFixedPriceLockedRevenueCap] = useState(false);
	const [fixedPriceBudget, setFixedPriceBudget] = useState(getDefaultFixedPriceBudget(project.budgetType, project.budget));
	const [errorMessage, setErrorMessage] = useState();

	const {formatMessage} = useIntl();
	const history = useHistory();
	const isOverProgramCap = isInCappedRevenueProgram && project.isProgramRevenueLocked;
	const showCapBudgetOverflowError =
		isFixedPriceType(budgetType) && isInCappedRevenueProgram && fixedPriceCappedBudgetOverflow;

	if (isOverProgramCap) {
		trackEvent('Change Project Budget Type Button', 'Clicked', {
			warning: 'Program Revenue Locked Limited Budget Types Available',
		});
	}

	const handleConfirmButton = () => {
		if (!wrongPeriodAmount && !wrongPeriodLength) {
			const onSuccess = () => {
				hideLoader();

				closeModal();
				if (retry) {
					retry();
				}
				const oldBudgetType = project.budgetType;
				const newBudgetType = budgetType;
				if (oldBudgetType !== newBudgetType) {
					trackEvent('Project Budget Type', 'Changed', {
						oldBudgetType,
						newBudgetType,
					});
				}
				createToast({
					duration: 5000,
					message: formatMessage({id: 'project_budget_change_modal.budget_type_changed_toast'}),
				});
			};
			const mutationObject = {
				project: project,
				budgetType: budgetType,
				billable: true,
			};
			if (budgetType === BUDGET_TYPE.RETAINER) {
				mutationObject.defaultPeriodPriceAmount = defaultPeriodPriceAmount < 0 ? 0 : +defaultPeriodPriceAmount || 0;
				mutationObject.defaultPeriodHoursAmount = defaultPeriodHoursAmount < 0 ? 0 : +defaultPeriodHoursAmount || 0;
				mutationObject.defaultPeriodBudgetType = defaultPeriodBudgetType;
				mutationObject.defaultPeriodLength = defaultPeriodLength;
				mutationObject.defaultPeriodPeriodicity = defaultPeriodPeriodicity;
			} else if (budgetType === BUDGET_TYPE.NON_BILLABLE) {
				mutationObject.billable = false;
			} else if (
				(budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
				(!fixedPriceBudget || (fixedPriceBudget && !parseFloat(fixedPriceBudget))) &&
				!isInFixedPriceProgram
			) {
				setInvalidFixedPriceFormat(true);
				return;
			}

			if ((budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2) && fixedPriceBudget) {
				mutationObject.budget = parseFloat(fixedPriceBudget);
				const oldBudget = project.budget;
				const newBudget = mutationObject.budget;
				if (oldBudget !== newBudget) {
					trackEvent('Project Budget', 'Changed', {
						isProjectInProgram: !!program,
						increased: oldBudget < newBudget,
					});
				}
			}

			showLoader();
			Util.CommitMutation(UpdateProjectMutation, mutationObject, onSuccess);
		}
	};

	const handlePeriodLengthChange = ({defaultPeriodLength, wrongPeriodLength, errorMessage}) => {
		setDefaultPeriodLength(defaultPeriodLength);
		setWrongPeriodLength(wrongPeriodLength);
		setErrorMessage(errorMessage);
	};

	const handledefaultPeriodChange = (field, value) => {
		setWrongPeriodAmount(!value || isNaN(value));

		if (field === 'defaultPeriodHoursAmount') {
			setDefaultPeriodHoursAmount(value);
		}
		if (field === 'defaultPeriodPriceAmount') {
			setDefaultPeriodPriceAmount(value);
		}
	};

	const handleFixedPriceBudget = fixedPriceBudget => {
		let invalidNewFixedPrice = !parseFloat(fixedPriceBudget);
		const difference = fixedPriceBudget - project.budget;
		// capSettingCondition : To make it work with and without feature flag
		const capSettingCondition = revenueSetting === CAP_SETTING.PERMITTED_UNTIL_CAP;
		if (isInCappedRevenueProgram && revenueSetting === CAP_SETTING.ALWAYS_DISABLED) {
			setFixedPriceLockedRevenueCap(difference > 0);
		} else if (isInCappedRevenueProgram && capSettingCondition) {
			setFixedPriceCappedBudgetOverflow(isProgramRevenueLocked || difference > programRemainingBudget);
		}
		setFixedPriceBudget(fixedPriceBudget);
		setInvalidFixedPriceFormat(invalidNewFixedPrice);
	};

	const onPeriodicityChange = periodicity => {
		if (periodicity !== defaultPeriodPeriodicity) {
			setDefaultPeriodPeriodicity(periodicity.value);
		}
	};

	const onPeriodBudgetTypeChange = periodBudgetType => {
		if (periodBudgetType !== defaultPeriodBudgetType) {
			setDefaultPeriodBudgetType(periodBudgetType[0]);
		}
	};

	const onBudgetTypeChange = ({
		budgetType,
		defaultPeriodPriceAmount,
		defaultPeriodHoursAmount,
		defaultPeriodBudgetType,
		defaultPeriodLength,
		defaultPeriodPeriodicity,
		wrongPeriodLength,
		wrongPeriodAmount,
	}) => {
		const difference = fixedPriceBudget - project.budget;
		// capSettingCondition : To make it work with and without feature flag
		const capSettingCondition = revenueSetting === CAP_SETTING.PERMITTED_UNTIL_CAP;
		if (budgetType === BUDGET_TYPE.FIXED_PRICE_V2 && isInCappedRevenueProgram && capSettingCondition) {
			const difference = fixedPriceBudget - project.budget;
			setFixedPriceCappedBudgetOverflow(project.programRemainingBudget - difference < 0);
		} else if (
			budgetType === BUDGET_TYPE.FIXED_PRICE_V2 &&
			isInCappedRevenueProgram &&
			revenueSetting === CAP_SETTING.ALWAYS_DISABLED
		) {
			setFixedPriceLockedRevenueCap(project.budgetType !== BUDGET_TYPE.FIXED_PRICE_V2 ? true : difference > 0);
		}
		setBudgetType(budgetType);
		setDefaultPeriodPriceAmount(defaultPeriodPriceAmount);
		setDefaultPeriodHoursAmount(defaultPeriodHoursAmount);
		if (defaultPeriodBudgetType) {
			setDefaultPeriodBudgetType(defaultPeriodBudgetType);
		}
		if (defaultPeriodLength) {
			setDefaultPeriodLength(defaultPeriodLength);
		}
		if (defaultPeriodPeriodicity) {
			setDefaultPeriodPeriodicity(defaultPeriodPeriodicity);
		}
		setWrongPeriodLength(wrongPeriodLength);
		setWrongPeriodAmount(wrongPeriodAmount);
	};

	const handleEditProgramBudgetClick = () => {
		trackEvent('Edit Program Budget Link', 'Clicked', {location: 'From Project Budget Change Modal'});
		history.push(`${ProgramUtil.programLink()}/${program.prefix}/budget/edit`);
		closeModal();
	};

	const content = (
		<div className="content">
			{isInFixedPriceProgram && (
				<div style={{marginBottom: theme.spacing.m}}>
					{' '}
					<ContentContainer width={'800px'} height={'auto'}>
						<FlexColumn gap={'s'}>
							<Text type={'heading'} variant={'m'}>
								{formatMessage({id: 'project_settings.unavailable_budget_options_title'})}
							</Text>
							<Text type={'base'}>
								{formatMessage({
									id: 'project_settings.fixed_price_program_unavailable_budget_types_message',
								})}
							</Text>
						</FlexColumn>
					</ContentContainer>{' '}
				</div>
			)}
			{isOverProgramCap && !showCapBudgetOverflowError && (
				<div style={{marginBottom: theme.spacing.m}}>
					<NoticeBanner>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'project_settings.unavailable_budget_options_title'})}
						</Text>
						{canManageProgram ? (
							<FlexColumn gap={'s'}>
								<Text type={'base'}>
									<FormattedHTMLMessage
										id="project_settings.capped_program_unavailable_budget_types_message_owner"
										values={{programName: program.name}}
									/>
								</Text>
								<Link onClick={handleEditProgramBudgetClick}>
									{formatMessage({id: 'common.edit_program_budget'})}
								</Link>{' '}
							</FlexColumn>
						) : (
							<Text type={'base'}>
								{formatMessage({
									id: 'project_settings.capped_program_unavailable_budget_types_message',
								})}
							</Text>
						)}
					</NoticeBanner>
				</div>
			)}
			{showCapBudgetOverflowError && (
				<ProgramBudgetExceededWarning
					canManageProgram={canManageProgram}
					program={program}
					onEditProgramBudgetClick={handleEditProgramBudgetClick}
					valueType={'fixed price'}
				></ProgramBudgetExceededWarning>
			)}
			<div className={'budget-type'}>
				<div className="budget-type-options">
					<label className="label tooltip">
						{formatMessage({id: 'new_project_modal.budget_type'})}

						<TooltipContainer
							infoText={[
								{
									title: ['new_project_modal.budget_type_fixed_price'],
									details: ['new_project_modal.budget_type_fixed_hours_info'],
								},
								{
									title: ['new_project_modal.budget_type_time_materials'],
									details: ['new_project_modal.budget_type_time_materials_info'],
								},
								{
									title: ['common.non-billable'],
									details: ['new_project_modal.budget_type_non_billable_info'],
								},
								{
									title: ['new_project_modal.retainer'],
									details: ['new_project_modal.retainer_info'],
									hide: !useRetainers,
								},
							]}
							tooltipInfinteDuration={true}
							edgeOffset={350}
						>
							<InformationIcon />
						</TooltipContainer>
					</label>
					<ProjectBudgetTypeControls
						budgetType={budgetType}
						useRetainers={useRetainers}
						project={project}
						hasFinance={hasFinance}
						hasInvoice={hasInvoice}
						currency={currency}
						defaultPeriodBudgetType={defaultPeriodBudgetType}
						handledefaultPeriodChange={handledefaultPeriodChange}
						defaultPeriodHoursAmount={defaultPeriodHoursAmount}
						defaultPeriodPriceAmount={defaultPeriodPriceAmount}
						onPeriodBudgetTypeChange={onPeriodBudgetTypeChange}
						defaultPeriodPeriodicity={defaultPeriodPeriodicity}
						wrongPeriodLength={wrongPeriodLength}
						wrongPeriodAmount={wrongPeriodAmount}
						errorMessage={errorMessage}
						defaultPeriodLength={defaultPeriodLength}
						handlePeriodLengthChange={handlePeriodLengthChange}
						onPeriodicityChange={onPeriodicityChange}
						handleFixedPriceBudget={handleFixedPriceBudget}
						fixedPriceBudget={fixedPriceBudget}
						invalidFixedPriceFormat={invalidFixedPriceFormat}
						onBudgetTypeChange={onBudgetTypeChange}
						isInFixedPriceProgram={isInFixedPriceProgram}
						isInCappedRevenueProgram={isInCappedRevenueProgram}
						fixedPriceCappedBudgetOverflow={fixedPriceCappedBudgetOverflow}
						program={program}
						programBudget={programBudget}
						programAllocation={programAllocation}
						programRemainingBudget={programRemainingBudget}
						canManageProgram={canManageProgram}
						closeModal={closeModal}
						isOverProgramCap={isOverProgramCap}
						fixedPriceLockedRevenueCap={fixedPriceLockedRevenueCap}
						cannotChangeToFixedPriceBudget={
							revenueSetting === CAP_SETTING.ALWAYS_DISABLED && project.budgetType !== BUDGET_TYPE.FIXED_PRICE_V2
						}
					/>
				</div>
			</div>
		</div>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: cancelText || formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: confirmText,
					disabled:
						wrongPeriodLength ||
						invalidFixedPriceFormat ||
						wrongPeriodAmount ||
						fixedPriceCappedBudgetOverflow ||
						(fixedPriceLockedRevenueCap && isFixedPriceType(budgetType)),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: handleConfirmButton,
					cy: 'user-input-confirm-btn',
					preventDefaultClose: true,
				},
			]}
			headerText={titleText}
			content={content}
			modalWidth={MODAL_WIDTH.LARGE}
		/>
	);
};

ProjectBudgetChangeModal.propTypes = {
	titleText: PropTypes.string.isRequired,
	cancelText: PropTypes.string,
	confirmText: PropTypes.string.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	inputType: PropTypes.string,
	project: PropTypes.shape({
		budgetType: PropTypes.string.isRequired,
		budget: PropTypes.number,
	}).isRequired,
	isInFixedPriceProgram: PropTypes.bool,
	program: PropTypes.shape({
		name: PropTypes.string,
		prefix: PropTypes.string,
	}),
};

export default injectIntl(ProjectBudgetChangeModal);
