import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import React from 'react';
import GenericModal from './generic_modal';
import Util from '../../forecast-app/shared/util/util';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import UpdateProjectMutation from '../../mutations/update_project_budget_page_mutation';

const confirmUpdateFixPriceModal = ({closeModal, project, callback}) => {
	const {formatMessage} = useIntl();

	const content = (
		<StyledModalContent>{formatMessage({id: 'project_budget.fixed_price_confirm_message'})}</StyledModalContent>
	);

	const handleFixedPriceUpdate = () => {
		const mutationObject = {
			budgetType: BUDGET_TYPE.FIXED_PRICE_V2,
			project,
		};

		const onSuccess = () => {
			closeModal();
			callback();
		};

		// eslint-disable-next-line
		Util.CommitMutation(UpdateProjectMutation, mutationObject, onSuccess);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			className="confirm-update-fixed-price-modal-budget"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					preventDefaultClose: true,
					callback: handleFixedPriceUpdate,
					cy: 'next-button',
				},
			]}
			headerText={formatMessage({id: 'project_budget.fixed_price_confirm_update'})}
			content={content}
		/>
	);
};

const StyledModalContent = styled.div`
	width: 280px;
	font-size: 13px;
`;

export default confirmUpdateFixPriceModal;
