import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Input from '../../components/new-ui/input_field';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';

class UserInputModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: this.props.initialValue ? this.props.initialValue : null,
		};
	}

	handleConfirmButton() {
		this.props.handleConfirm(this.state.inputValue);
		this.props.closeModal();
	}
	handleValueChange(value) {
		if (
			this.props.disableOnInvalidInput &&
			(this.props.hoursInputType || this.props.inputType === 'number') &&
			isNaN(value)
		) {
			this.setState({inputValue: null});
			return;
		}
		this.setState({inputValue: value});
	}

	render() {
		const {formatMessage} = this.props.intl;

		const content = (
			<div className="content">
				{this.props.subHeaderText ? <div>{this.props.subHeaderText}</div> : null}

				{this.props.hoursInputType ? (
					<div className={'input-container-v2'}>
						<label className={'input-title'}></label>
						{/* this div and label are ther to keep the sizing until we update all inputs */}
						<HoursInput
							id="user-input-modal-input"
							cy={'user-input-modal-input'}
							value={this.state.inputValue}
							placeholder={this.props.placeholder ? this.props.placeholder : ''}
							mutation={v => this.handleValueChange(v)}
						></HoursInput>
					</div>
				) : (
					<Input
						id="user-input-modal-input"
						label={this.props.label ? this.props.label : ''}
						value={this.state.inputValue}
						type={this.props.inputType}
						onChange={this.handleValueChange.bind(this)}
						placeholder={this.props.placeholder ? this.props.placeholder : ''}
						autoFocus={this.props.focusOnMount || undefined}
						onEnter={this.handleConfirmButton.bind(this)}
						currency={this.props.currency}
						suffix={this.props.suffix}
						cy={'user-input-modal-input'}
					/>
				)}
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.cancelText || formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: this.props.confirmText,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.handleConfirmButton.bind(this),
						cy: 'user-input-confirm-btn',
						disabled: this.props.disableOnInvalidInput && this.state.inputValue == null,
					},
				]}
				headerText={this.props.titleText}
				content={content}
			/>
		);
	}
}

UserInputModal.propTypes = {
	titleText: PropTypes.string.isRequired,
	cancelText: PropTypes.string,
	confirmText: PropTypes.string.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	inputType: PropTypes.string,
};

export default injectIntl(UserInputModal);
