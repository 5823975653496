/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type myTaskActivities_viewer$ref = any;
export type myTaskActivities_QueryVariables = {||};
export type myTaskActivities_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: myTaskActivities_viewer$ref,
  |}
|};
export type myTaskActivities_Query = {|
  variables: myTaskActivities_QueryVariables,
  response: myTaskActivities_QueryResponse,
|};
*/


/*
query myTaskActivities_Query {
  viewer {
    actualPersonId
    component(name: "my_task_activities")
    ...myTaskActivities_viewer
    id
  }
}

fragment DeprecatedProjectIndicatorJS_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}

fragment SettingsProfileSubNav_viewer on Viewer {
  id
  projectOwner
  disableNotifications
  company {
    id
    forecastDemo
    tier
  }
}

fragment myTaskActivities_viewer on Viewer {
  component(name: "my_task_activities")
  availableFeatureFlags {
    key
    id
  }
  id
  actualPersonId
  taskActivities {
    id
    date
    eventType
    params
    task {
      id
      companyTaskId
      name
      fullAccessToProject
      project {
        companyProjectId
        customProjectId
        ...DeprecatedProjectIndicatorJS_project
        name
        id
      }
    }
  }
  ...SettingsProfileSubNav_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "my_task_activities"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"my_task_activities\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "myTaskActivities_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "myTaskActivities_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "myTaskActivities_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskActivity",
            "kind": "LinkedField",
            "name": "taskActivities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "params",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyTaskId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullAccessToProject",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectOwner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disableNotifications",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forecastDemo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "myTaskActivities_Query",
    "operationKind": "query",
    "text": "query myTaskActivities_Query {\n  viewer {\n    actualPersonId\n    component(name: \"my_task_activities\")\n    ...myTaskActivities_viewer\n    id\n  }\n}\n\nfragment DeprecatedProjectIndicatorJS_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n\nfragment SettingsProfileSubNav_viewer on Viewer {\n  id\n  projectOwner\n  disableNotifications\n  company {\n    id\n    forecastDemo\n    tier\n  }\n}\n\nfragment myTaskActivities_viewer on Viewer {\n  component(name: \"my_task_activities\")\n  availableFeatureFlags {\n    key\n    id\n  }\n  id\n  actualPersonId\n  taskActivities {\n    id\n    date\n    eventType\n    params\n    task {\n      id\n      companyTaskId\n      name\n      fullAccessToProject\n      project {\n        companyProjectId\n        customProjectId\n        ...DeprecatedProjectIndicatorJS_project\n        name\n        id\n      }\n    }\n  }\n  ...SettingsProfileSubNav_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '466db2baeee7a29e3436c70530e63e0a';

module.exports = node;
