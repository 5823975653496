/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SageSyncAnalysisTable_Query(
  $companyId: Int
  $analysisId: Int
) {
  viewer {
    actualPersonId
    component(name: "sage_sync_analysis_table")
    ...SageSyncAnalysisTable_viewer_1OPT0D
    id
  }
}

fragment SageSyncAnalysisTable_viewer_1OPT0D on Viewer {
  id
  missionControlData {
    companySyncAnalysis(companyId: $companyId, analysisId: $analysisId) {
      id
      syncDate
      entries {
        id
        companyAnalysisId
        forecastCompanyId
        sageEntityRecordNumber
        forecastEntityId
        entityType
        relevancy
      }
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyId",
            "type": "Int"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "analysisId",
            "type": "Int"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "sage_sync_analysis_table"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"sage_sync_analysis_table\")"
    }, v3 = [
        {
            "kind": "Variable",
            "name": "analysisId",
            "variableName": "analysisId"
        },
        {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SageSyncAnalysisTable_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": (v3 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "SageSyncAnalysisTable_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SageSyncAnalysisTable_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MissionControlDataType",
                            "kind": "LinkedField",
                            "name": "missionControlData",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "MissionControlSageSyncAnalysisType",
                                    "kind": "LinkedField",
                                    "name": "companySyncAnalysis",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "syncDate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "MissionControlSageSyncAnalysisEntryType",
                                            "kind": "LinkedField",
                                            "name": "entries",
                                            "plural": true,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "companyAnalysisId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "forecastCompanyId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "sageEntityRecordNumber",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "forecastEntityId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "entityType",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "relevancy",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SageSyncAnalysisTable_Query",
            "operationKind": "query",
            "text": "query SageSyncAnalysisTable_Query(\n  $companyId: Int\n  $analysisId: Int\n) {\n  viewer {\n    actualPersonId\n    component(name: \"sage_sync_analysis_table\")\n    ...SageSyncAnalysisTable_viewer_1OPT0D\n    id\n  }\n}\n\nfragment SageSyncAnalysisTable_viewer_1OPT0D on Viewer {\n  id\n  missionControlData {\n    companySyncAnalysis(companyId: $companyId, analysisId: $analysisId) {\n      id\n      syncDate\n      entries {\n        id\n        companyAnalysisId\n        forecastCompanyId\n        sageEntityRecordNumber\n        forecastEntityId\n        entityType\n        relevancy\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '4a581027104645ce1a4fb3b793e9f0a3';
export default node;
