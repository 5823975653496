import React, { useMemo, useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { Button, Checkbox, ComboBox, FlexColumn, FlexRow, Link, SubHeading, Text, TextField } from '@forecast-it/design-system';
import MissionControlHeader from './MissionControlHeader';
import { hideLoader, showLoader } from '../../global_loader';
import CreateSageCompanyMutation from '../../../mutations/mission_control/ts/CreateSageCompanyMutation';
import { createToast } from '../../../forecast-app/shared/components/toasts/toast';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { CommitMutation } from '../../../mutations/ts/CommitMutation';
import MissionControlActivateSageIntacctMutation from '../../../mutations/mission_control/ts/MissionControlActivateSageIntacctMutation';
import MissionControlDeactivateSageIntacctMutation from '../../../mutations/mission_control/ts/MissionControlDeactivateSageIntacctMutation';
import { notNullishAndPredicate } from '../../../forecast-app/shared/util/NotNullPredicate';
const SignupForm = styled.div `
	display: flex;
	margin: 24px;
	width: 500px;
`;
const CompanyList = styled.div `
	margin: 24px;
`;
function getSageCustomerItems(viewer) {
    var _a;
    if (!((_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.unlinkedSageCustomers)) {
        return [];
    }
    const items = [];
    viewer.company.unlinkedSageCustomers.forEach(customer => {
        if (customer) {
            items.push({ id: customer.id, name: customer.name || 'No name' });
        }
    });
    return items;
}
function SageSignupControlPage({ viewer, relay }) {
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [sageCompanyId, setSageCompanyId] = useState(null);
    const [projectFinancialsAccess, setProjectFinancialsAccess] = useState(false);
    const [revenueAccess, setRevenueAccess] = useState(false);
    const [financeMenuAccess, setFinanceMenuAccess] = useState(false);
    const [reportingAccess, setReportingAccess] = useState(false);
    const [timesheetsAccess, setTimesheetsAccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showActivate, setShowActive] = useState(false);
    const sagePSACompanies = useMemo(() => {
        var _a, _b;
        const companies = ((_b = (_a = viewer.missionControlData) === null || _a === void 0 ? void 0 : _a.sagePSACompanies) === null || _b === void 0 ? void 0 : _b.filter(notNullishAndPredicate())) || [];
        companies.sort((a, b) => {
            return a.companyId > b.companyId ? -1 : 1;
        });
        return companies;
    }, [viewer]);
    const companiesWithSageEnabled = useMemo(() => {
        var _a, _b;
        const companies = ((_b = (_a = viewer.missionControlData) === null || _a === void 0 ? void 0 : _a.companiesWithSageEnabled) === null || _b === void 0 ? void 0 : _b.filter(notNullishAndPredicate())) || [];
        companies.sort((a, b) => {
            return a.companyId > b.companyId ? -1 : 1;
        });
        return companies;
    }, [viewer]);
    function validateEmail(email) {
        const valid = email.trim() === '' || /(.+)@(.+)\.(.+)/.test(email.trim());
        setEmailValid(valid);
        setEmail(email.trim());
    }
    function showError(title, messages) {
        showModal({
            type: MODAL_TYPE.WARNING,
            message: title,
            warningInformation: messages,
            buttons: [
                {
                    text: 'Ok',
                },
            ],
        });
    }
    function activateInWorkato(companyId) {
        showLoader();
        setLoading(true);
        CommitMutation(MissionControlActivateSageIntacctMutation, { companyId }, response => {
            var _a, _b;
            hideLoader();
            setLoading(false);
            const outcome = (_a = response.MissionControlActivateSageIntacct) === null || _a === void 0 ? void 0 : _a.outcome;
            if (outcome === 'Success') {
                relay.refetch({});
                createToast({ duration: 5000, message: 'Company Successfully activated!' });
            }
            else {
                showError('Error activating company', [
                    'Could not activate company in Workato. Please retry activation!',
                    ((_b = response.MissionControlActivateSageIntacct) === null || _b === void 0 ? void 0 : _b.errorMessage) || '',
                ]);
            }
        });
    }
    function deactivateInWorkato(companyId) {
        showLoader();
        setLoading(true);
        CommitMutation(MissionControlDeactivateSageIntacctMutation, { companyId }, response => {
            var _a, _b;
            hideLoader();
            setLoading(false);
            const outcome = (_a = response.missionControlDeactivateSageIntacct) === null || _a === void 0 ? void 0 : _a.outcome;
            if (outcome === 'Success') {
                relay.refetch({});
                createToast({ duration: 5000, message: 'Company Successfully deactivated!' });
            }
            else {
                showError('Error deactivating company', [
                    'Could not deactivate company in Workato. Please retry deactivation!',
                    ((_b = response.missionControlDeactivateSageIntacct) === null || _b === void 0 ? void 0 : _b.errorMessage) || '',
                ]);
            }
        });
    }
    function submit() {
        if (loading) {
            return;
        }
        showLoader();
        setLoading(true);
        const domain = email.split('@')[1].split('.')[0];
        const accountName = domain.replace(/^./, domain[0].toUpperCase());
        CommitMutation(CreateSageCompanyMutation, {
            email,
            companyName: companyName || accountName[0].toUpperCase() + accountName.slice(1).toLowerCase(),
            unlinkedSageCustomerID: sageCompanyId,
            projectFinancialsAccess,
            revenueAccess,
            financeMenuAccess,
            reportingAccess,
            timesheetsAccess,
        }, response => {
            var _a, _b;
            // Check response
            let errorMessage;
            const resultCode = (_a = response.CreateSageCompany) === null || _a === void 0 ? void 0 : _a.resultCode;
            switch (resultCode) {
                case '0':
                    // Success
                    createToast({
                        duration: 10000,
                        message: 'Company Successfully created! Activating in Workato...',
                    });
                    activateInWorkato(((_b = response.CreateSageCompany) === null || _b === void 0 ? void 0 : _b.companyId) || '');
                    relay.refetch({});
                    break;
                case '1':
                    // Email is missing...
                    errorMessage = `Create company failed with resultCode: ${resultCode}. Could not find email`;
                    break;
                case '2':
                    // Email in use
                    errorMessage = `Create company failed with resultCode: ${resultCode}. Email is already in use.`;
                    break;
                default:
                    // Other error. Contact a developer...
                    errorMessage = `Create company failed with resultCode: ${resultCode}. Contact a developer to find out what it means...`;
            }
            if (errorMessage) {
                hideLoader();
                setLoading(false);
                showError('Error creating company', [errorMessage]);
            }
        });
    }
    return (React.createElement("div", null,
        React.createElement(MissionControlHeader, { viewer: viewer }),
        React.createElement(SignupForm, null,
            React.createElement(FlexColumn, null,
                React.createElement(SubHeading, null, "Sage PSA Signup"),
                React.createElement(Text, null, "Here you can create a new Forecast company with the Sage module enabled."),
                React.createElement(Text, null, "Sage admin will receive an email with further instructions to complete the signup."),
                React.createElement(ComboBox, { label: "Sage Company", value: sageCompanyId, items: getSageCustomerItems(viewer), onChange: setSageCompanyId }, (sageCompany) => (React.createElement(ComboBox.Item, { textValue: sageCompany.name },
                    React.createElement(Text, null, sageCompany.name)))),
                React.createElement(TextField, { label: "Company Name", value: companyName, onValueChange: val => {
                        setCompanyName(val);
                    }, placeholder: "Write company name here..." }),
                React.createElement(FlexRow, { alignItems: "end" },
                    React.createElement(TextField, { label: "Email", value: email, onChange: e => {
                            validateEmail(e.target.value);
                        }, placeholder: "Write Sage admin email here..." })),
                React.createElement(SubHeading, null, "Financial Configuration Options"),
                React.createElement(Text, { color: Text.Color.auxiliary }, "These can be changed later from the Modules control panel page."),
                React.createElement(Checkbox, { labelText: 'Project Financials on', checked: projectFinancialsAccess, onChange: () => setProjectFinancialsAccess(!projectFinancialsAccess) }),
                React.createElement(Checkbox, { labelText: 'Revenue on', checked: revenueAccess, onChange: () => setRevenueAccess(!revenueAccess), disabled: !projectFinancialsAccess }),
                React.createElement(Checkbox, { labelText: 'Finance menu on', checked: financeMenuAccess, onChange: () => setFinanceMenuAccess(!financeMenuAccess), disabled: !projectFinancialsAccess }),
                React.createElement("br", null),
                React.createElement(SubHeading, null, "Other configuration options"),
                React.createElement(Checkbox, { labelText: 'Reporting in Sage PSA', checked: reportingAccess, onChange: () => setReportingAccess(!reportingAccess) }),
                React.createElement(Text, { color: Text.Color.auxiliary }, "Timesheets in Sage PSA can only be set once so please be careful!"),
                React.createElement(Checkbox, { labelText: 'Timesheets in Sage PSA', checked: timesheetsAccess, onChange: () => setTimesheetsAccess(!timesheetsAccess) }),
                React.createElement("br", null),
                React.createElement(Button, { disabled: !email || !emailValid || loading || !sageCompanyId, onClick: () => {
                        submit();
                    }, noMargin: true }, "Signup"))),
        React.createElement(CompanyList, { className: "mission-control-list" },
            React.createElement(SubHeading, null,
                "Sage PSA Companies ",
                !showActivate && '(missing activation)'),
            React.createElement("div", null,
                React.createElement("button", { onClick: () => setShowActive(!showActivate) }, showActivate ? 'Hide active' : 'Show active')),
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "ID"),
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Created At"),
                        React.createElement("th", null, "Activated"),
                        React.createElement("th", null, "Workato Customer ID"),
                        React.createElement("th", null, "Activate"),
                        React.createElement("th", null, "Deactivate"))),
                React.createElement("tbody", null, sagePSACompanies
                    .filter(company => showActivate || !company.activated)
                    .map(company => (React.createElement("tr", null,
                    React.createElement("td", null, company.companyId),
                    React.createElement("td", null, company.name),
                    React.createElement("td", null, company.createdAt),
                    React.createElement("td", null, company.activated ? '✓' : ''),
                    React.createElement("td", null, company.workatoCustomerId),
                    React.createElement("td", null,
                        React.createElement(Button, { disabled: !!company.activated, onClick: () => {
                                activateInWorkato(company.id);
                            }, noMargin: true }, "Activate")),
                    React.createElement("td", null,
                        React.createElement(Button, { disabled: !company.activated, onClick: () => {
                                deactivateInWorkato(company.id);
                            }, noMargin: true }, "Deactivate")))))))),
        React.createElement(CompanyList, { className: "mission-control-list" },
            React.createElement(SubHeading, null, "Forcast companies with Sage active"),
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "ID"),
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Created At"),
                        React.createElement("th", null, "Activated"),
                        React.createElement("th", null, "Workato Customer ID"),
                        React.createElement("th", null, "Sage Sync Analysis"),
                        React.createElement("th", null, "Deactivate"))),
                React.createElement("tbody", null, companiesWithSageEnabled.map(company => (React.createElement("tr", null,
                    React.createElement("td", null, company.companyId),
                    React.createElement("td", null, company.name),
                    React.createElement("td", null, company.createdAt),
                    React.createElement("td", null, company.activated ? '✓' : ''),
                    React.createElement("td", null, company.workatoCustomerId),
                    React.createElement("td", null,
                        React.createElement(Link, { ellipsis: true, href: '/control/sage-sync-analysis/' + company.companyId }, "View")),
                    React.createElement("td", null,
                        React.createElement(Button, { disabled: !company.activated, onClick: () => {
                                deactivateInWorkato(company.id);
                            }, noMargin: true }, "Deactivate"))))))))));
}
const sageSignupQuery = graphql `
	query SageSignupControlPage_Query {
		viewer {
			actualPersonId
			component(name: "sage_signup")
			...SageSignupControlPage_viewer
		}
	}
`;
export { sageSignupQuery };
export default createRefetchContainer(SageSignupControlPage, {
    viewer: graphql `
			fragment SageSignupControlPage_viewer on Viewer {
				id
				...MissionControlHeader_viewer
				company {
					id
					unlinkedSageCustomers {
						id
						name
					}
				}
				missionControlData {
					sagePSACompanies {
						id
						companyId
						name
						activated
						workatoCustomerId
						createdAt
					}
					companiesWithSageEnabled {
						id
						companyId
						name
						activated
						workatoCustomerId
						createdAt
					}
				}
			}
		`,
}, graphql `
		query SageSignupControlPageRefetchQuery {
			viewer {
				...SageSignupControlPage_viewer
			}
		}
	`);
