import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class DoneProjectsEmptyState extends Component {
	handleAddProject() {
		this.props.addProject();
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="done-projects-empty-state-container">
				<div className="info-container">
					<span className="info">
						{this.props.searchText
							? formatMessage({id: this.props.infoMessageId}, {searchText: this.props.searchText})
							: formatMessage({id: this.props.infoMessageId})}
					</span>
					{this.props.canAddProjects && !this.props.searchText ? (
						<button className="add-project-button" onClick={this.handleAddProject.bind(this)}>
							{formatMessage({id: 'empty_state_projects.create-button'})}
						</button>
					) : (
						''
					)}
				</div>
				<img src={require('../../../images/empty-states/empty-state-background-2.png')} alt="Done projects" />
			</div>
		);
	}
}
DoneProjectsEmptyState.propTypes = {
	infoMessageId: PropTypes.string.isRequired,
	searchText: PropTypes.string,
};
export default injectIntl(DoneProjectsEmptyState);
