import React, { useState, useMemo } from 'react';
import { Modal, FlexRow, Button, DateRangePicker, Text, TextField, Icon, FlexColumn, DropdownList, Frame, DraggableContainer, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { showModal, MODAL_TYPE } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../forecast-app/shared/util/util';
import { dateObjectToIsoDate, dateFragmentPropsFromIntervalInIsoFormat } from '../../forecast-app/shared/util/DateUtil';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { sortByStartDate } from '../../components/new-ui/retainer_period/RetainerPeriodUtil';
import BulkUpdatePhasesMutation from '../../mutations/bulk_update_phases_mutation';
import DeletePhaseMutation from '../../mutations/delete_phase_mutation';
const ORDER_OPTIONS = {
    CUSTOM: 'custom',
    ASCENDING: 'start_date_ascending',
    DESCENDING: 'start_date_descending',
};
const PhaseManagementModal = ({ closeModal, initialPhases, project }) => {
    const { formatMessage } = useIntl();
    const [changesMade, setChangesMade] = useState(false);
    const projectId = project.id;
    const useBaseline = project.useBaseline;
    const phasesSortedBySortOrder = initialPhases
        .filter(phase => phase && phase.node && phase.node.sortOrder !== undefined)
        .sort((a, b) => a.node.sortOrder - b.node.sortOrder);
    const [phases, setPhases] = useState(phasesSortedBySortOrder);
    const [newPhasesCount, setNewPhasesCount] = useState(0);
    const numberOfPhases = phases.length;
    const findOrderSetting = () => {
        const phaseIds = phases.map(phase => phase.node.id);
        const sortedPhaseIds = [...phases].sort((a, b) => sortByStartDate(a.node, b.node)).map(phase => phase.node.id);
        if (phaseIds.every((id, index) => id === sortedPhaseIds[index])) {
            return ORDER_OPTIONS.ASCENDING;
        }
        if (phaseIds.every((id, index) => id === sortedPhaseIds[sortedPhaseIds.length - 1 - index])) {
            return ORDER_OPTIONS.DESCENDING;
        }
        return ORDER_OPTIONS.CUSTOM;
    };
    const [phaseOrder, setPhaseOrder] = useState(findOrderSetting);
    const dragAndDropGroup = 'Phases Group';
    const orderedPhaseIds = useMemo(() => {
        return phases.map(phase => phase.node.id);
    }, [phases]);
    const handlePhaseManagement = () => {
        closeModal();
        if (changesMade) {
            if (phases.length === 0) {
                for (const initialPhase of initialPhases) {
                    Util.CommitMutation(DeletePhaseMutation, {
                        id: initialPhase.node.id,
                        projectId: projectId,
                    });
                }
            }
            else {
                const mutationObject = {
                    phases: phases.map((phase, index) => ({
                        id: phase.node.id.startsWith('new-phase') ? undefined : phase.node.id,
                        projectId: projectId,
                        name: phase.node.name,
                        startYear: phase.node.startYear,
                        startMonth: phase.node.startMonth,
                        startDay: phase.node.startDay,
                        deadlineYear: phase.node.deadlineYear,
                        deadlineMonth: phase.node.deadlineMonth,
                        deadlineDay: phase.node.deadlineDay,
                        sortOrder: index,
                    })),
                };
                Util.CommitMutation(BulkUpdatePhasesMutation, mutationObject);
            }
        }
    };
    const moveToPosition = (sourceIndex, destinationIndex) => {
        const updatedOrderedPhaseIds = Array.from(orderedPhaseIds);
        const [movedPhaseId] = updatedOrderedPhaseIds.splice(sourceIndex, 1);
        updatedOrderedPhaseIds.splice(destinationIndex, 0, movedPhaseId);
        const updatedPhases = updatedOrderedPhaseIds.map(id => {
            const phase = phases.find(phase => phase.node.id === id);
            if (!phase) {
                throw new Error(`Phase with id ${id} not found`);
            }
            return phase;
        });
        setPhases(updatedPhases);
        setChangesMade(true);
        setPhaseOrder('custom');
    };
    const onPhaseOrderChange = (newOrder) => {
        setPhaseOrder(newOrder);
        setChangesMade(true);
        const updatedPhases = [...phases];
        switch (newOrder) {
            case ORDER_OPTIONS.ASCENDING:
                updatedPhases.sort((a, b) => sortByStartDate(a.node, b.node));
                break;
            case ORDER_OPTIONS.DESCENDING:
                updatedPhases.sort((a, b) => sortByStartDate(b.node, a.node));
                break;
            default:
                return;
        }
        setPhases(updatedPhases);
        setChangesMade(true);
    };
    const handleOnDragEnd = (result) => {
        if (!result.destination)
            return;
        const { source, destination } = result;
        moveToPosition(source.index, destination.index);
    };
    const setPhaseName = (name, index) => {
        const updatedPhases = [...phases];
        if (updatedPhases[index]) {
            updatedPhases[index] = Object.assign(Object.assign({}, updatedPhases[index]), { node: Object.assign(Object.assign({}, updatedPhases[index].node), { name: name }) });
        }
        setPhases(updatedPhases);
        setChangesMade(true);
    };
    const handleDateRangeChange = (date, index) => {
        if (!date.startDate || !date.endDate) {
            return;
        }
        const updatedDateRange = dateFragmentPropsFromIntervalInIsoFormat(date.startDate, date.endDate);
        const updatedPhases = [...phases];
        if (updatedPhases[index]) {
            updatedPhases[index] = Object.assign(Object.assign({}, updatedPhases[index]), { node: Object.assign(Object.assign({}, updatedPhases[index].node), { startDay: updatedDateRange.startDay, startMonth: updatedDateRange.startMonth, startYear: updatedDateRange.startYear, deadlineDay: updatedDateRange.endDay, deadlineMonth: updatedDateRange.endMonth, deadlineYear: updatedDateRange.endYear }) });
        }
        setPhases(updatedPhases);
        setChangesMade(true);
    };
    const addNewPhase = () => {
        const newIndex = newPhasesCount + 1;
        setNewPhasesCount(newIndex);
        const newPhase = {
            node: {
                id: 'new-phase' + newIndex,
                projectId: projectId,
                startYear: project.projectStartYear,
                startDay: project.projectStartDay,
                startMonth: project.projectStartMonth,
                deadlineYear: project.projectEndYear,
                deadlineDay: project.projectEndDay,
                deadlineMonth: project.projectEndMonth,
                name: 'New Phase ' + newIndex,
                sortOrder: phases.length,
            },
        };
        setPhases(prevPhases => [...prevPhases, newPhase]);
        setChangesMade(true);
    };
    const onDeleteSuccess = (index) => {
        const updatedPhases = [...phases];
        updatedPhases.splice(index, 1);
        setPhases(updatedPhases);
        setChangesMade(true);
    };
    const onDeletePhase = (index) => {
        showModal({
            type: MODAL_TYPE.DELETE_PHASE_MODAL,
            callback: () => {
                onDeleteSuccess(index);
            },
            useBaseline,
        });
    };
    const getContextMenuOptions = (phase, index) => {
        const disableTop = index === 0;
        const disableBottom = index === phases.length - 1;
        const contextMenuOptions = [
            {
                name: 'Move to the top',
                onClick: () => {
                    moveToPosition(index, 0);
                },
                disabled: disableTop,
            },
            {
                name: 'Move up',
                onClick: () => {
                    moveToPosition(index, index - 1);
                },
                disabled: disableTop,
            },
            {
                name: 'Move down',
                onClick: () => {
                    moveToPosition(index, index + 1);
                },
                disabled: disableBottom,
            },
            {
                name: 'Move to the bottom',
                onClick: () => {
                    moveToPosition(index, phases.length - 1);
                },
                disabled: disableBottom,
            },
            {
                name: 'Delete Phase',
                onClick: () => {
                    onDeletePhase(index);
                },
            },
        ];
        return contextMenuOptions;
    };
    return (React.createElement(Modal, { size: Modal.SIZE.EXTRA_LARGE, onCloseModal: closeModal, setHeight: true },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'phase_management.manage_phases' })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(FlexRow, { gap: 'm' },
                    React.createElement(Button, { emphasis: 'medium', onClick: addNewPhase, "data-cy": 'add-new-phase' }, "Add new Phase"),
                    React.createElement(DropdownList, null,
                        React.createElement(DropdownList.Trigger, null,
                            React.createElement(Button, { emphasis: 'medium', rightIcon: 'chevronDown', disabled: phases.length === 0 },
                                React.createElement(FormattedMessage, { id: 'phase_management.order' }),
                                React.createElement(FormattedMessage, { id: `phase_management.${phaseOrder}` }))),
                        React.createElement(DropdownList.Content, { align: 'start' }, Object.values(ORDER_OPTIONS).map(option => (React.createElement(DropdownList.Item, { onClick: () => onPhaseOrderChange(option) },
                            React.createElement(FormattedMessage, { id: 'phase_management.order' }),
                            React.createElement(FormattedMessage, { id: `phase_management.${option}` }))))))),
                React.createElement(FlexRow, null,
                    React.createElement(Icon, { icon: 'phase' }),
                    React.createElement(Text, { size: '2' }, formatMessage({ id: 'phase_management.phases' }, { numberOfPhases: numberOfPhases }))),
                phases.length !== 0 ? (React.createElement(DragDropContext, { scrollYSpeed: 25, onDragEnd: handleOnDragEnd, outerScrollBar: true, dragAndDropGroup: dragAndDropGroup },
                    React.createElement(Droppable, { dragAndDropGroup: dragAndDropGroup, droppableId: "phasesDroppable", dynamicElemHeight: true, minElemHeight: 40 }, provided => (React.createElement("div", Object.assign({}, provided.droppableProps, { ref: provided.innerRef }),
                        React.createElement(FlexColumn, { gap: 's' },
                            phases.map((phase, index) => (React.createElement(Draggable, { key: phase.node.id, draggableId: phase.node.id, index: index, dragAndDropGroup: dragAndDropGroup, noCancelOnMove: true }, provided => (React.createElement("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps),
                                React.createElement(DraggableContainer, null,
                                    React.createElement(TextField, { "data-cy": 'new-project-name', value: phase.node.name, onValueChange: value => setPhaseName(value, index) }),
                                    React.createElement(DateRangePicker, { startDate: dateObjectToIsoDate(Util.CreateNonUtcMomentDate(phase.node.startYear, phase.node.startMonth, phase.node.startDay)), endDate: dateObjectToIsoDate(Util.CreateNonUtcMomentDate(phase.node.deadlineYear, phase.node.deadlineMonth, phase.node.deadlineDay)), onChange: (date) => handleDateRangeChange(date, index), "aria-label": "Select date range", "data-cy": 'date-range-picker', disabled: false }),
                                    React.createElement(DraggableContainer.ContextMenu, { contextMenuOptions: getContextMenuOptions(phase, index) }))))))),
                            provided.placeholder)))))) : (React.createElement(Frame, { height: 's', centerContent: true },
                    React.createElement(FlexRow, { justifyContent: 'center' },
                        React.createElement(FormattedMessage, { id: 'phase_management.no_phases' })))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: handlePhaseManagement },
                React.createElement(FormattedMessage, { id: "common.save_changes" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: "common.cancel" })))));
};
export default PhaseManagementModal;
