import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import Warning from '../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {injectIntl} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {TaskRow} from 'web-components';

class GenericDeletionWarningModal extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		const {deletedItems, customMessageId} = this.props;
		const multiTasks = deletedItems && deletedItems.length > 1;

		const content = (
			<div className={'default-warning-modal' + (multiTasks ? ' delete-many-tasks-modal' : '')}>
				{multiTasks ? (
					<Warning
						message={formatMessage({id: 'delete_task_modal.description_many_tasks'}, {number: deletedItems.length})}
					/>
				) : (
					<Warning messageId={'common.delete-confirmation-title'} />
				)}
				<div className="warning-part-2">
					{multiTasks ? (
						<>
							<div className={'tasks-title'}>{formatMessage({id: 'common.tasks'})}</div>
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={188} autoHide={false}>
								<div className={'tasks-list'}>
									{deletedItems.map(task => (
										<TaskRow
											columns={{taskId: true}}
											taskId={task.companyTaskId}
											taskName={task.name}
										></TaskRow>
									))}
								</div>
							</CustomScrollDiv>
						</>
					) : null}
					<div className="">
						{deletedItems && deletedItems.find(t => t.hasTimeRegistrations) ? (
							<div>
								{formatMessage({id: 'delete-prompt.card-time-entries'})}
								<br />
							</div>
						) : null}
						{formatMessage({
							id: customMessageId ? customMessageId : 'common.warning.this_action_can_not_be_undone',
						})}
					</div>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.delete'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: this.props.deleteCallback,
						cy: 'modal-delete-task-button',
					},
				]}
				content={content}
			/>
		);
	}
}

GenericDeletionWarningModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	deleteCallback: PropTypes.func.isRequired,
};
export default injectIntl(GenericDeletionWarningModal);
