/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SageSignupFlow_Query {
  viewer {
    actualPersonId
    component(name: "sage_signup_flow")
    ...SageSignupFlow_viewer
    id
  }
}

fragment SageSignupFlow_viewer on Viewer {
  id
  csrfToken
  actualPersonId
  email
  company {
    id
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "sage_signup_flow"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"sage_signup_flow\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SageSignupFlow_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SageSignupFlow_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SageSignupFlow_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "csrfToken",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SageSignupFlow_Query",
            "operationKind": "query",
            "text": "query SageSignupFlow_Query {\n  viewer {\n    actualPersonId\n    component(name: \"sage_signup_flow\")\n    ...SageSignupFlow_viewer\n    id\n  }\n}\n\nfragment SageSignupFlow_viewer on Viewer {\n  id\n  csrfToken\n  actualPersonId\n  email\n  company {\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '37d8499b943b1227fc5538f1087b1d25';
export default node;
