/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type sharedInsight_viewer$ref: FragmentReference;
declare export opaque type sharedInsight_viewer$fragmentType: sharedInsight_viewer$ref;
export type sharedInsight_viewer = {|
  +id: string,
  +downForMaintenance: ?boolean,
  +sharedInsight: ?{|
    +id: string,
    +name: ?string,
    +companyName: ?string,
    +category: ?string,
    +labels: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +category: ?{|
        +allowOnTasks: ?boolean,
        +allowOnProjects: ?boolean,
        +allowOnPeople: ?boolean,
      |},
    |}>,
    +skills: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +clients: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +projects: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +client: ?{|
        +id: string
      |},
    |}>,
    +sprints: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +startYear: ?number,
      +startMonth: ?number,
      +startDay: ?number,
      +endYear: ?number,
      +endMonth: ?number,
      +endDay: ?number,
      +projectGroupSprintId: ?string,
      +isProjectGroupSprint: ?boolean,
    |}>,
    +persons: ?$ReadOnlyArray<?{|
      +id: string,
      +firstName: ?string,
      +lastName: ?string,
    |}>,
    +departments: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +roles: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +teams: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +components: ?$ReadOnlyArray<?{|
      +id: string,
      +componentName: ?string,
      +x: ?number,
      +y: ?number,
      +w: ?number,
      +h: ?number,
      +config: ?string,
    |}>,
  |},
  +$refType: sharedInsight_viewer$ref,
|};
export type sharedInsight_viewer$data = sharedInsight_viewer;
export type sharedInsight_viewer$key = {
  +$data?: sharedInsight_viewer$data,
  +$fragmentRefs: sharedInsight_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sharedInsight_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downForMaintenance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "Insight",
      "kind": "LinkedField",
      "name": "sharedInsight",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Label",
          "kind": "LinkedField",
          "name": "labels",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelCategory",
              "kind": "LinkedField",
              "name": "category",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowOnTasks",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowOnProjects",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowOnPeople",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skills",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "clients",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectType",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Sprint",
          "kind": "LinkedField",
          "name": "sprints",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectGroupSprintId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isProjectGroupSprint",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "persons",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DepartmentType",
          "kind": "LinkedField",
          "name": "departments",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "teams",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InsightComponent",
          "kind": "LinkedField",
          "name": "components",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "componentName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "x",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "y",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "w",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "h",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "config",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '617a6a4353e9ab448e8514017637211f';

module.exports = node;
