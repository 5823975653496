import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../generic_modal';
import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import UpdateSkillPersonMutation from '../../../mutations/update_skill_person_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import SkillsPreviewSelector from '../../../forecast-app/shared/components/dropdowns/preview-selector/SkillsPreviewSelector';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const PersonSkillModal = ({closeModal, company}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const person = company.person;
	const skillLevelsEnabled = !!company.skillLevelsEnabled;
	const [selectedSkillPersons, setSelectedSkillPersons] = useState(
		person.skillPersons.map(sp => ({
			skillId: sp.skill.id,
			skillLevelId: sp.level?.id,
		}))
	);

	const addSkills = () => {
		Util.CommitMutation(
			UpdateSkillPersonMutation,
			{
				personId: person.id,
				skillPersons: selectedSkillPersons,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'settings_skills.changes_saved'}),
				});
			}
		);
	};

	const content = (
		<div>
			<SkillsPreviewSelector
				company={company}
				selectedSkillPersons={selectedSkillPersons}
				onChange={setSelectedSkillPersons}
				selectionHeaderLabel={formatMessage({id: 'settings.skills'})}
				selectLevels={skillLevelsEnabled}
			/>
		</div>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'common.person_skills'}, {name: person.fullName})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					callback: () => {
						tracking.trackEvent('skills - person skill modal was closed');
						trackEvent('Person Skill Modal', 'Closed');
						closeModal();
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.save'}),
					callback: () => {
						addSkills();
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					cy: 'button-save',
				},
			]}
		/>
	);
};

const personSkillsModalQuery = graphql`
	query PersonSkillModalQuery($personId: ID) {
		viewer {
			actualPersonId
			component(name: "person_skills_modal")
			company {
				...PersonSkillModal_company @arguments(personId: $personId)
			}
		}
	}
`;

export {personSkillsModalQuery};

export default createFragmentContainer(PersonSkillModal, {
	company: graphql`
		fragment PersonSkillModal_company on Company @argumentDefinitions(personId: {type: "ID!"}) {
			skillLevelsEnabled
			...SkillsPreviewSelector_company
			person(id: $personId) {
				id
				fullName
				skillPersons {
					skill {
						id
					}
					level {
						id
					}
				}
				skillPersons {
					skill {
						id
					}
					level {
						id
						description
						level
					}
				}
			}
		}
	`,
});
