/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ManageSkillLevelsModal_company$ref = any;
export type ManageSkillLevelsModal_QueryVariables = {||};
export type ManageSkillLevelsModal_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +company: ?{|
      +$fragmentRefs: ManageSkillLevelsModal_company$ref
    |},
  |}
|};
export type ManageSkillLevelsModal_Query = {|
  variables: ManageSkillLevelsModal_QueryVariables,
  response: ManageSkillLevelsModal_QueryResponse,
|};
*/


/*
query ManageSkillLevelsModal_Query {
  viewer {
    actualPersonId
    component(name: "manage_skill_levels_modal")
    company {
      ...ManageSkillLevelsModal_company
      id
    }
    id
  }
}

fragment ManageSkillLevelsModal_company on Company {
  id
  skillLevelsEnabled
  skillLevels {
    id
    description
    level
  }
  allPersons(first: 10000, excludeClientUsers: true) {
    edges {
      node {
        skillPersons {
          skill {
            id
          }
          level {
            id
            level
          }
          id
        }
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  placeholders {
    edges {
      node {
        skillPlaceholders {
          skill {
            id
          }
          level {
            id
            level
          }
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "manage_skill_levels_modal"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"manage_skill_levels_modal\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "excludeClientUsers",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Skill",
    "kind": "LinkedField",
    "name": "skill",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SkillLevel",
    "kind": "LinkedField",
    "name": "level",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageSkillLevelsModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ManageSkillLevelsModal_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ManageSkillLevelsModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "skillLevelsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLevel",
                "kind": "LinkedField",
                "name": "skillLevels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillPerson",
                            "kind": "LinkedField",
                            "name": "skillPersons",
                            "plural": true,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Company_allPersons",
                "kind": "LinkedHandle",
                "name": "allPersons"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceholderTypeConnection",
                "kind": "LinkedField",
                "name": "placeholders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceholderTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Placeholder",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillPlaceholder",
                            "kind": "LinkedField",
                            "name": "skillPlaceholders",
                            "plural": true,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ManageSkillLevelsModal_Query",
    "operationKind": "query",
    "text": "query ManageSkillLevelsModal_Query {\n  viewer {\n    actualPersonId\n    component(name: \"manage_skill_levels_modal\")\n    company {\n      ...ManageSkillLevelsModal_company\n      id\n    }\n    id\n  }\n}\n\nfragment ManageSkillLevelsModal_company on Company {\n  id\n  skillLevelsEnabled\n  skillLevels {\n    id\n    description\n    level\n  }\n  allPersons(first: 10000, excludeClientUsers: true) {\n    edges {\n      node {\n        skillPersons {\n          skill {\n            id\n          }\n          level {\n            id\n            level\n          }\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  placeholders {\n    edges {\n      node {\n        skillPlaceholders {\n          skill {\n            id\n          }\n          level {\n            id\n            level\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '883567beb4ed3be05bdde904671283a2';

module.exports = node;
