/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type loginAzureActiveDirectory_viewer$ref = any;
export type loginAzureActiveDirectory_QueryVariables = {||};
export type loginAzureActiveDirectory_QueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +$fragmentRefs: loginAzureActiveDirectory_viewer$ref,
  |}
|};
export type loginAzureActiveDirectory_Query = {|
  variables: loginAzureActiveDirectory_QueryVariables,
  response: loginAzureActiveDirectory_QueryResponse,
|};
*/


/*
query loginAzureActiveDirectory_Query {
  viewer {
    component(name: "login_azure_active_directory")
    ...loginAzureActiveDirectory_viewer
    id
  }
}

fragment loginAzureActiveDirectory_viewer on Viewer {
  id
  actualPersonId
  company {
    AADTenant
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "login_azure_active_directory"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"login_azure_active_directory\")"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "loginAzureActiveDirectory_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "loginAzureActiveDirectory_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "loginAzureActiveDirectory_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AADTenant",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "loginAzureActiveDirectory_Query",
    "operationKind": "query",
    "text": "query loginAzureActiveDirectory_Query {\n  viewer {\n    component(name: \"login_azure_active_directory\")\n    ...loginAzureActiveDirectory_viewer\n    id\n  }\n}\n\nfragment loginAzureActiveDirectory_viewer on Viewer {\n  id\n  actualPersonId\n  company {\n    AADTenant\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6eefc5d1571be80a52f9cba805a6bc6e';

module.exports = node;
