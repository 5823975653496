import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import Util from '../../forecast-app/shared/util/util';
import UpdateCompanyToBeDeletedMutation from '../../mutations/mission_control/update_company_to_be_deleted_mutation';

class CompaniesToBeDeleted extends Component {
	constructor() {
		super();

		this.state = {
			showAll: false,
		};
	}

	changeToBeDeleted(companyId, currentValue) {
		Util.CommitMutation(UpdateCompanyToBeDeletedMutation, {
			companyId: companyId,
			toBeDeleted: !currentValue,
		});
	}

	exportBetaOptInCSV(companiesToBeDeletedData) {
		const data = [];
		companiesToBeDeletedData.forEach(company => {
			const d = {
				company_id: company.companyId,
				company_name: company.name,
				trial_end_date: company.trialEndDate,
				updated_at: company.updatedAt,
				last_session_date: company.lastSessionDate,
				project_count: company.projectCount,
				user_count: company.userCount,
				flagged_for_deletion: company.toBeDeleted,
			};
			data.push(d);
		});
		const columnNames = [
			'company_id',
			'company_name',
			'trial_end_date',
			'updated_at',
			'last_session_date',
			'project_count',
			'user_count',
			'flagged_for_deletion',
		];
		let exportData = columnNames + '\r\n';
		exportData += Util.JSONToCSV(data);
		const fileName = 'companies_to_be_deleted.csv';
		Util.exportToCSV(exportData, fileName);
	}

	render() {
		if (!this.props.viewer.missionControlData) {
			this.props.history.push('/not-found');
			return null;
		}

		const style = {position: 'sticky', top: 0, background: 'white'};

		const data = this.props.viewer.missionControlData.companiesToBeDeleted;

		const displayedData = this.state.showAll ? data : data.slice(0, 1000);

		return (
			<div className="mission-control-list">
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<h1>Companies To Be Deleted</h1>
					<button onClick={() => this.exportBetaOptInCSV(data)}>Export as CSV</button>
				</div>
				{!this.state.showAll && data.length > 1000 ? (
					<div>
						Showing first 1000 of {data.length} companies{' '}
						<button onClick={() => this.setState({showAll: true})}>Show All</button> (showing all might cause your
						browser to lag - be patient)
					</div>
				) : (
					<div>Showing {data.length} companies</div>
				)}
				<table>
					<tbody>
						<tr>
							<th style={style}>Id</th>
							<th style={style}>Name</th>
							<th style={style}>Created At</th>
							<th style={style}>Updated At</th>
							<th style={style}>Trial End Date</th>
							<th style={style}>Last session Date</th>
							<th style={style}>Project count</th>
							<th style={style}>User count</th>
							<th style={style}>Domains</th>
							<th style={style}>Sisense user count</th>
						</tr>
						{displayedData.map(company => {
							return (
								<tr key={company.companyId}>
									<td>{company.companyId}</td>
									<td>{company.name}</td>
									<td>{company.createdAt}</td>
									<td>{company.updatedAt}</td>
									<td>{company.trialEndDate}</td>
									<td>{company.lastSessionDate}</td>
									<td>{company.projectCount}</td>
									<td>{company.userCount}</td>
									<td>{company.domains}</td>
									<td>{company.sisenseUserCount}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

const companiesToBeDeletedQuery = graphql`
	query companiesToBeDeleted_Query {
		viewer {
			actualPersonId
			component(name: "control_companies_to_be_deleted")
			...companiesToBeDeleted_viewer
		}
	}
`;

export {companiesToBeDeletedQuery};

export default createFragmentContainer(withRouter(CompaniesToBeDeleted), {
	viewer: graphql`
		fragment companiesToBeDeleted_viewer on Viewer {
			id
			actualPersonId
			missionControlData {
				id
				companiesToBeDeleted {
					id
					companyId
					createdAt
					updatedAt
					name
					trialEndDate
					lastSessionDate
					projectCount
					userCount
					flaggedForDeletionDate
					domains
					toBeDeleted
					sisenseUserCount
				}
			}
		}
	`,
});
