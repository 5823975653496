import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const ProjectStatusModalTitle = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	margin-top: 16px;
	margin-bottom: 8px;
`;

export const ProjectStatusModalRow = styled.div`
	display: flex;
`;

export const RichTextWrapper = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	.editor {
		margin: 0;
		.public-DraftEditor-content {
			min-height: ${props => (props.minHeight ? `${props.minHeight}px` : '0px')};
		}
	}
`;
