import { TABLE_CATEGORY } from './ProjectBreakdownTable';
import Util from '../../../../forecast-app/shared/util/util';
export const getTableData = (viewer, formatMessage) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const roleNameById = new Map();
    const expenseCategoryNameById = new Map();
    const personById = new Map();
    const taskNameById = new Map();
    const expenseNameById = new Map();
    const allocationNameById = new Map();
    (_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.forEach(edge => {
        var _a;
        if ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.name) {
            roleNameById.set(Util.getIdFromBase64String(edge.node.id), edge.node.name);
        }
    });
    (_f = (_e = (_d = viewer.company) === null || _d === void 0 ? void 0 : _d.expenseCategories) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.forEach(edge => {
        var _a;
        if ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.name) {
            expenseCategoryNameById.set(Util.getIdFromBase64String(edge.node.id), edge.node.name);
        }
    });
    (_j = (_h = (_g = viewer.company) === null || _g === void 0 ? void 0 : _g.allPersons) === null || _h === void 0 ? void 0 : _h.edges) === null || _j === void 0 ? void 0 : _j.forEach(edge => {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.firstName) || ((_b = edge === null || edge === void 0 ? void 0 : edge.node) === null || _b === void 0 ? void 0 : _b.lastName)) {
            const name = (((_c = edge === null || edge === void 0 ? void 0 : edge.node) === null || _c === void 0 ? void 0 : _c.firstName) || '') + (((_d = edge === null || edge === void 0 ? void 0 : edge.node) === null || _d === void 0 ? void 0 : _d.lastName) !== null ? ' ' + ((_e = edge === null || edge === void 0 ? void 0 : edge.node) === null || _e === void 0 ? void 0 : _e.lastName) : '');
            personById.set(Util.getIdFromBase64String(edge.node.id), {
                name: name,
                profilePictureId: ((_f = edge === null || edge === void 0 ? void 0 : edge.node) === null || _f === void 0 ? void 0 : _f.profilePictureId) || undefined,
            });
        }
    });
    (_m = (_l = (_k = viewer.project) === null || _k === void 0 ? void 0 : _k.tasks) === null || _l === void 0 ? void 0 : _l.edges) === null || _m === void 0 ? void 0 : _m.forEach(edge => {
        var _a;
        if ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.name) {
            taskNameById.set(Util.getIdFromBase64String(edge.node.id), edge.node.name);
        }
    });
    (_q = (_p = (_o = viewer.project) === null || _o === void 0 ? void 0 : _o.expenseItems) === null || _p === void 0 ? void 0 : _p.edges) === null || _q === void 0 ? void 0 : _q.forEach(edge => {
        var _a;
        if ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.name) {
            expenseNameById.set(Util.getIdFromBase64String(edge.node.id), edge.node.name);
        }
    });
    (_t = (_s = (_r = viewer.project) === null || _r === void 0 ? void 0 : _r.allocations) === null || _s === void 0 ? void 0 : _s.edges) === null || _t === void 0 ? void 0 : _t.forEach(edge => {
        const allocation = edge === null || edge === void 0 ? void 0 : edge.node;
        if (allocation &&
            allocation.startDay &&
            allocation.startMonth &&
            allocation.startYear &&
            allocation.endDay &&
            allocation.endMonth &&
            allocation.endYear) {
            const startDate = `${allocation.startDay.toString().padStart(2, '0')}/${allocation.startMonth
                .toString()
                .padStart(2, '0')}/${allocation.startYear}`;
            const endDate = `${allocation.endDay.toString().padStart(2, '0')}/${allocation.endMonth
                .toString()
                .padStart(2, '0')}/${allocation.endYear}`;
            const name = `${startDate} - ${endDate}`;
            allocationNameById.set(Util.getIdFromBase64String(edge.node.id), name);
        }
    });
    const baselineFinancialData = ((_u = viewer.project) === null || _u === void 0 ? void 0 : _u.baselineGroupedFinancialNumbers) || [];
    const financialData = ((_v = viewer.project) === null || _v === void 0 ? void 0 : _v.groupedFinancialNumbers) || [];
    const tableData = {
        baseline: [],
        planned: [],
        actualToDate: [],
        forecastToComplete: [],
        totalAtCompletion: [],
    };
    if (!financialData) {
        return tableData;
    }
    baselineFinancialData.forEach(row => {
        if (row) {
            if (row.baselineMinutes !== 0 ||
                row.baselineTimeAndExpenses !== 0 ||
                row.baselineCost !== 0 ||
                row.baselineProfit !== 0 ||
                row.baselineMargin !== 0 ||
                row.baselineRevenue !== 0) {
                const isRole = row.roleId && row.roleId > 0;
                const isExpenseCategory = row.expenseCategoryId;
                tableData.baseline.push({
                    category: isRole ? TABLE_CATEGORY.TIME : TABLE_CATEGORY.EXPENSES,
                    role: isRole
                        ? {
                            id: row.roleId,
                            name: roleNameById.get(row.roleId) || formatMessage({ id: 'common.no_role' }),
                        }
                        : undefined,
                    expenseCategory: isExpenseCategory
                        ? {
                            id: row.expenseCategoryId,
                            name: expenseCategoryNameById.get(row.expenseCategoryId) ||
                                formatMessage({ id: 'common.no_expense_category' }),
                        }
                        : undefined,
                    hours: isExpenseCategory ? undefined : row.baselineMinutes || 0,
                    revenue: row.baselineRevenue || 0,
                    cost: row.baselineCost || 0,
                    profit: row.baselineProfit || 0,
                    margin: row.baselineMargin || 0,
                    valueOfService: row.baselineTimeAndExpenses || 0,
                });
            }
        }
    });
    financialData.forEach(row => {
        var _a, _b, _c, _d, _e, _f;
        if (row) {
            if (row.scopeTotalMinutes !== 0 ||
                row.plannedRevenue !== 0 ||
                row.plannedCost !== 0 ||
                row.plannedRevenueProfit !== 0 ||
                row.plannedRevenueMargin !== 0 ||
                row.allPlannedTimeAndExpenses) {
                const isTask = row.taskId;
                const isExpense = row.expenseId;
                const isAllocation = row.allocationId;
                tableData.planned.push({
                    category: isTask
                        ? TABLE_CATEGORY.TASK_ESTIMATES
                        : isExpense
                            ? TABLE_CATEGORY.EXPENSES
                            : TABLE_CATEGORY.ALLOCATIONS,
                    person: {
                        id: row.personId || 0,
                        fullName: ((_a = personById.get(row.personId || 0)) === null || _a === void 0 ? void 0 : _a.name) || '',
                        profilePictureId: (_b = personById.get(row.personId || 0)) === null || _b === void 0 ? void 0 : _b.profilePictureId,
                    },
                    task: isTask
                        ? {
                            id: row.taskId,
                            name: taskNameById.get(row.taskId) || '',
                        }
                        : undefined,
                    expense: isExpense
                        ? {
                            id: row.expenseId,
                            name: expenseNameById.get(row.expenseId) || '',
                        }
                        : undefined,
                    allocation: isAllocation
                        ? {
                            id: row.allocationId,
                            name: allocationNameById.get(row.allocationId) || '',
                        }
                        : undefined,
                    hours: isExpense ? undefined : row.scopeTotalMinutes || 0,
                    revenue: row.plannedRevenue || 0,
                    cost: row.plannedCost || 0,
                    profit: row.plannedRevenueProfit || 0,
                    margin: row.plannedRevenueMargin || 0,
                    valueOfService: row.allPlannedTimeAndExpenses || 0,
                });
            }
            if (row.registeredMinutes !== 0 ||
                row.totalActualRevenueRecognition !== 0 ||
                row.actualCost !== 0 ||
                row.actualRevenueProfit !== 0 ||
                row.actualRevenueMargin !== 0 ||
                row.allActualTimeAndExpenses) {
                const isTask = row.taskId;
                const isExpense = row.expenseId;
                const isAllocation = row.allocationId;
                tableData.actualToDate.push({
                    category: isTask
                        ? TABLE_CATEGORY.TIME_REGISTRATIONS
                        : isExpense
                            ? TABLE_CATEGORY.EXPENSES
                            : TABLE_CATEGORY.ALLOCATIONS,
                    person: {
                        id: row.personId || 0,
                        fullName: ((_c = personById.get(row.personId || 0)) === null || _c === void 0 ? void 0 : _c.name) || '',
                        profilePictureId: (_d = personById.get(row.personId || 0)) === null || _d === void 0 ? void 0 : _d.profilePictureId,
                    },
                    task: isTask
                        ? {
                            id: row.taskId || 0,
                            name: row.taskId > 0
                                ? taskNameById.get(row.taskId) || ''
                                : row.taskId === -1
                                    ? formatMessage({ id: 'common.project_time_registration' })
                                    : row.taskId === -2
                                        ? formatMessage({ id: 'common.revenue_recognition_offset' })
                                        : '',
                        }
                        : undefined,
                    expense: isExpense
                        ? {
                            id: row.expenseId,
                            name: expenseNameById.get(row.expenseId) || '',
                        }
                        : undefined,
                    allocation: isAllocation
                        ? {
                            id: row.allocationId,
                            name: allocationNameById.get(row.allocationId) || '',
                        }
                        : undefined,
                    hours: isExpense ? undefined : row.registeredMinutes || 0,
                    revenue: row.totalActualRevenueRecognition || 0,
                    cost: row.actualCost || 0,
                    profit: row.actualRevenueProfit || 0,
                    margin: row.actualRevenueMargin || 0,
                    valueOfService: row.allActualTimeAndExpenses || 0,
                });
                tableData.totalAtCompletion.push({
                    category: isTask
                        ? TABLE_CATEGORY.TIME_REGISTRATIONS
                        : isExpense
                            ? TABLE_CATEGORY.EXPENSES
                            : TABLE_CATEGORY.ALLOCATIONS,
                    person: {
                        id: row.personId || 0,
                        fullName: ((_e = personById.get(row.personId || 0)) === null || _e === void 0 ? void 0 : _e.name) || '',
                        profilePictureId: (_f = personById.get(row.personId || 0)) === null || _f === void 0 ? void 0 : _f.profilePictureId,
                    },
                    task: isTask
                        ? {
                            id: row.taskId || 0,
                            name: row.taskId > 0
                                ? taskNameById.get(row.taskId) || ''
                                : row.taskId === -1
                                    ? formatMessage({ id: 'common.project_time_registration' })
                                    : row.taskId === -2
                                        ? formatMessage({ id: 'common.revenue_recognition_offset' })
                                        : '',
                        }
                        : undefined,
                    expense: isExpense
                        ? {
                            id: row.expenseId,
                            name: expenseNameById.get(row.expenseId) || '',
                        }
                        : undefined,
                    allocation: isAllocation
                        ? {
                            id: row.allocationId,
                            name: allocationNameById.get(row.allocationId) || '',
                        }
                        : undefined,
                    hours: isExpense ? undefined : row.registeredMinutes || 0,
                    revenue: row.totalActualRevenueRecognition || 0,
                    cost: row.actualCost || 0,
                    profit: row.actualRevenueProfit || 0,
                    margin: row.actualRevenueMargin || 0,
                    valueOfService: row.allActualTimeAndExpenses || 0,
                });
            }
        }
    });
    const totalAtCompletionIndexByAllocationId = new Map();
    for (let i = 0; i < tableData.totalAtCompletion.length; i++) {
        const allocation = tableData.totalAtCompletion[i].allocation;
        if (allocation) {
            totalAtCompletionIndexByAllocationId.set(allocation.id, i);
        }
    }
    financialData.forEach(row => {
        var _a, _b, _c, _d;
        if (row) {
            if (row.forecastTimeToComplete !== 0 ||
                row.totalForecastRevenueToComplete !== 0 ||
                row.forecastCostToComplete !== 0 ||
                row.forecastRevenueProfitToComplete !== 0 ||
                row.forecastRevenueMarginToComplete !== 0 ||
                row.allForecastTimeAndExpensesToComplete) {
                const isTask = row.taskId;
                const isExpense = row.expenseId;
                const isAllocation = row.allocationId;
                tableData.forecastToComplete.push({
                    category: isTask
                        ? TABLE_CATEGORY.TASK_ESTIMATES
                        : isExpense
                            ? TABLE_CATEGORY.EXPENSES
                            : TABLE_CATEGORY.ALLOCATIONS,
                    person: {
                        id: row.personId || 0,
                        fullName: ((_a = personById.get(row.personId || 0)) === null || _a === void 0 ? void 0 : _a.name) || '',
                        profilePictureId: (_b = personById.get(row.personId || 0)) === null || _b === void 0 ? void 0 : _b.profilePictureId,
                    },
                    task: isTask
                        ? {
                            id: row.taskId || 0,
                            name: row.taskId > 0
                                ? taskNameById.get(row.taskId) || ''
                                : row.taskId === -1
                                    ? formatMessage({ id: 'common.project_time_registration' })
                                    : row.taskId === -2
                                        ? formatMessage({ id: 'common.revenue_recognition_offset' })
                                        : '',
                        }
                        : undefined,
                    expense: isExpense
                        ? {
                            id: row.expenseId,
                            name: expenseNameById.get(row.expenseId) || '',
                        }
                        : undefined,
                    allocation: isAllocation
                        ? {
                            id: row.allocationId,
                            name: allocationNameById.get(row.allocationId) || '',
                        }
                        : undefined,
                    hours: isExpense ? undefined : row.forecastTimeToComplete || 0,
                    revenue: row.totalForecastRevenueToComplete || 0,
                    cost: row.forecastCostToComplete || 0,
                    profit: row.forecastRevenueProfitToComplete || 0,
                    margin: row.forecastRevenueMarginToComplete || 0,
                    valueOfService: row.allForecastTimeAndExpensesToComplete || 0,
                });
                if (isAllocation && totalAtCompletionIndexByAllocationId.has(row.allocationId)) {
                    const totalAtCompletionIndex = totalAtCompletionIndexByAllocationId.get(row.allocationId);
                    if (totalAtCompletionIndex) {
                        const totalAtCompletionRow = tableData.totalAtCompletion[totalAtCompletionIndex];
                        if (totalAtCompletionRow.hours && row.forecastTimeToComplete) {
                            totalAtCompletionRow.hours += row.forecastTimeToComplete;
                        }
                        else if (row.forecastTimeToComplete) {
                            totalAtCompletionRow.hours = row.forecastTimeToComplete;
                        }
                        else {
                            totalAtCompletionRow.hours = 0;
                        }
                        totalAtCompletionRow.revenue += row.totalForecastRevenueToComplete || 0;
                        totalAtCompletionRow.cost += row.forecastCostToComplete || 0;
                        totalAtCompletionRow.profit += row.forecastRevenueProfitToComplete || 0;
                        totalAtCompletionRow.margin += row.forecastRevenueMarginToComplete || 0;
                        totalAtCompletionRow.valueOfService += row.allForecastTimeAndExpensesToComplete || 0;
                    }
                }
                else {
                    tableData.totalAtCompletion.push({
                        category: isTask
                            ? TABLE_CATEGORY.TASK_ESTIMATES
                            : isExpense
                                ? TABLE_CATEGORY.EXPENSES
                                : TABLE_CATEGORY.ALLOCATIONS,
                        person: {
                            id: row.personId || 0,
                            fullName: ((_c = personById.get(row.personId || 0)) === null || _c === void 0 ? void 0 : _c.name) || '',
                            profilePictureId: (_d = personById.get(row.personId || 0)) === null || _d === void 0 ? void 0 : _d.profilePictureId,
                        },
                        task: isTask
                            ? {
                                id: row.taskId || 0,
                                name: row.taskId > 0
                                    ? taskNameById.get(row.taskId) || ''
                                    : row.taskId === -1
                                        ? formatMessage({ id: 'common.project_time_registration' })
                                        : row.taskId === -2
                                            ? formatMessage({ id: 'common.revenue_recognition_offset' })
                                            : '',
                            }
                            : undefined,
                        expense: isExpense
                            ? {
                                id: row.expenseId,
                                name: expenseNameById.get(row.expenseId) || '',
                            }
                            : undefined,
                        allocation: isAllocation
                            ? {
                                id: row.allocationId,
                                name: allocationNameById.get(row.allocationId) || '',
                            }
                            : undefined,
                        hours: isExpense ? undefined : row.forecastTimeToComplete || 0,
                        revenue: row.totalForecastRevenueToComplete || 0,
                        cost: row.forecastCostToComplete || 0,
                        profit: row.forecastRevenueProfitToComplete || 0,
                        margin: row.forecastRevenueMarginToComplete || 0,
                        valueOfService: row.allForecastTimeAndExpensesToComplete || 0,
                    });
                }
            }
        }
    });
    return tableData;
};
