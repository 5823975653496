import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {hideLoader, showLoader} from '../global_loader';
import UpdateProjectMutation from '../../mutations/update_project_budget_page_mutation';
import Util from '../../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, BUDGET_TYPE, PERIOD_BUDGET_TYPE, PERIODICITY} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import GenericModal from './generic_modal';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import InformationIcon from '../../images/information-icon';
import ProjectBudgetTypeControls from '../../components/new-ui/project/project_budget_type_controls';
import {FlexColumn, DeprecatedText as Text, DeprecatedContentContainer as ContentContainer} from '@forecast-it/design-system';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';

class ProjectBudgetChangeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			budgetType: props.project.budgetType,
			defaultPeriodPriceAmount: props.project.defaultPeriodPriceAmount,
			defaultPeriodHoursAmount: props.project.defaultPeriodHoursAmount,
			defaultPeriodBudgetType: props.project.defaultPeriodBudgetType || PERIOD_BUDGET_TYPE.FIXED_HOURS,
			defaultPeriodLength: props.project.defaultPeriodLength || 1,
			defaultPeriodPeriodicity: props.project.defaultPeriodPeriodicity || PERIODICITY.MONTHLY,
			wrongPeriodLength: false,
			wrongPeriodAmount: false,
			invalidFixedPriceFormat: false,
			fixedPriceBudget: this.getDefaultFixedPriceBudget(),
			fixed_price_v2_enabled: props.availableFeatureFlags.some(flag => flag.key === 'fixed_price_v2'),
			isInFixedPriceProgram: props.isInFixedPriceProgram,
			program: props.program,
			closeModal: this.props.closeModal,
		};
	}

	isFixedPriceType(budgetType) {
		return budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	}

	getDefaultFixedPriceBudget() {
		return this.isFixedPriceType(this.props.project.budgetType) && this.props.project.budget !== undefined
			? this.props.project.budget
			: '';
	}

	handleConfirmButton() {
		if (!this.state.wrongPeriodAmount && !this.state.wrongPeriodLength) {
			const onSuccess = () => {
				hideLoader();

				this.props.closeModal();
				if (this.props.retry) {
					this.props.retry();
				}
				const oldBudgetType = this.props.project.budgetType;
				const newBudgetType = this.state.budgetType;
				if (oldBudgetType !== newBudgetType) {
					trackEvent('Project Budget Type', 'Changed', {
						oldBudgetType,
						newBudgetType,
					});
				}
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'project_budget_change_modal.budget_type_changed_toast'}),
				});
			};
			const mutationObject = {
				project: this.props.project,
				budgetType: this.state.budgetType,
				billable: true,
			};
			if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
				mutationObject.defaultPeriodPriceAmount =
					this.state.defaultPeriodPriceAmount < 0 ? 0 : +this.state.defaultPeriodPriceAmount || 0;
				mutationObject.defaultPeriodHoursAmount =
					this.state.defaultPeriodHoursAmount < 0 ? 0 : +this.state.defaultPeriodHoursAmount || 0;
				mutationObject.defaultPeriodBudgetType = this.state.defaultPeriodBudgetType;
				mutationObject.defaultPeriodLength = this.state.defaultPeriodLength;
				mutationObject.defaultPeriodPeriodicity = this.state.defaultPeriodPeriodicity;
			} else if (this.state.budgetType === BUDGET_TYPE.NON_BILLABLE) {
				mutationObject.billable = false;
			} else if (
				(this.state.budgetType === BUDGET_TYPE.FIXED_PRICE || this.state.budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
				(!this.state.fixedPriceBudget || (this.state.fixedPriceBudget && !parseFloat(this.state.fixedPriceBudget))) &&
				!this.state.isInFixedPriceProgram
			) {
				this.setState({invalidFixedPriceFormat: true});
				return;
			}

			if (
				(this.state.budgetType === BUDGET_TYPE.FIXED_PRICE || this.state.budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
				this.state.fixedPriceBudget
			) {
				mutationObject.budget = parseFloat(this.state.fixedPriceBudget);
				const oldBudget = this.props.project.budget;
				const newBudget = mutationObject.budget;
				if (oldBudget !== newBudget) {
					trackEvent('Project Budget', 'Changed', {
						isProjectInProgram: !!this.props.program,
						increased: oldBudget < newBudget,
					});
				}
			}

			showLoader();
			Util.CommitMutation(UpdateProjectMutation, mutationObject, onSuccess);
		}
	}
	handlePeriodLengthChange(newValues) {
		this.setState(newValues);
	}

	handledefaultPeriodChange(field, value) {
		this.setState({[field]: value, wrongPeriodAmount: !value || isNaN(value)});
	}

	handleFixedPriceBudget(fixedPriceBudget) {
		this.setState({fixedPriceBudget, invalidFixedPriceFormat: !parseFloat(fixedPriceBudget)});
	}

	onPeriodicityChange(periodicity) {
		if (periodicity !== this.state.defaultPeriodPeriodicity) {
			this.setState({defaultPeriodPeriodicity: periodicity.value});
		}
	}

	onPeriodBudgetTypeChange(periodBudgetType) {
		if (periodBudgetType !== this.state.defaultPeriodBudgetType) {
			this.setState({defaultPeriodBudgetType: periodBudgetType[0]});
		}
	}

	onBudgetTypeChange(newValues) {
		this.setState(newValues);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="content">
				{this.state.isInFixedPriceProgram && (
					<div style={{marginBottom: '15px'}}>
						{' '}
						<ContentContainer width={'800px'} height={'auto'}>
							<FlexColumn gap={'s'}>
								<Text type={'heading'} variant={'m'}>
									{formatMessage({id: 'project_settings.unavailable_budget_options_title'})}
								</Text>
								<Text type={'base'}>
									{formatMessage({
										id: 'project_settings.fixed_price_program_unavailable_budget_types_message',
									})}
								</Text>
							</FlexColumn>
						</ContentContainer>{' '}
					</div>
				)}
				<div className={'budget-type'}>
					<div className="budget-type-options">
						<label className="label tooltip">
							{formatMessage({id: 'new_project_modal.budget_type'})}

							<TooltipContainer
								infoText={[
									{
										title: ['new_project_modal.budget_type_fixed_price'],
										details: ['new_project_modal.budget_type_fixed_hours_info'],
									},
									{
										title: ['new_project_modal.budget_type_time_materials'],
										details: ['new_project_modal.budget_type_time_materials_info'],
									},
									{
										title: ['common.non-billable'],
										details: ['new_project_modal.budget_type_non_billable_info'],
									},
									{
										title: ['new_project_modal.retainer'],
										details: ['new_project_modal.retainer_info'],
										hide: !this.props.useRetainers,
									},
								]}
								tooltipInfinteDuration={true}
								edgeOffset={350}
							>
								<InformationIcon />
							</TooltipContainer>
						</label>
						<ProjectBudgetTypeControls
							budgetType={this.state.budgetType}
							useRetainers={this.props.useRetainers}
							project={this.props.project}
							hasFinance={this.props.hasFinance}
							hasInvoice={this.props.hasInvoice}
							currency={this.props.currency}
							defaultPeriodBudgetType={this.state.defaultPeriodBudgetType}
							handledefaultPeriodChange={this.handledefaultPeriodChange.bind(this)}
							defaultPeriodHoursAmount={this.state.defaultPeriodHoursAmount}
							defaultPeriodPriceAmount={this.state.defaultPeriodPriceAmount}
							onPeriodBudgetTypeChange={this.onPeriodBudgetTypeChange.bind(this)}
							defaultPeriodPeriodicity={this.state.defaultPeriodPeriodicity}
							wrongPeriodLength={this.state.wrongPeriodLength}
							wrongPeriodAmount={this.state.wrongPeriodAmount}
							errorMessage={this.state.errorMessage}
							defaultPeriodLength={this.state.defaultPeriodLength}
							handlePeriodLengthChange={this.handlePeriodLengthChange.bind(this)}
							onPeriodicityChange={this.onPeriodicityChange.bind(this)}
							handleFixedPriceBudget={this.handleFixedPriceBudget.bind(this)}
							fixedPriceBudget={this.state.fixedPriceBudget}
							rateCardSelected={this.state.rateCardSelected}
							invalidFixedPriceFormat={this.state.invalidFixedPriceFormat}
							onBudgetTypeChange={this.onBudgetTypeChange.bind(this)}
							fixed_price_v2_enabled={this.state.fixed_price_v2_enabled}
							isInFixedPriceProgram={this.state.isInFixedPriceProgram}
							program={this.state.program}
							closeModal={this.state.closeModal}
						/>
					</div>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: this.props.cancelText || formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: this.props.confirmText,
						disabled:
							this.state.wrongPeriodLength || this.state.invalidFixedPriceFormat || this.state.wrongPeriodAmount,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.handleConfirmButton.bind(this),
						cy: 'user-input-confirm-btn',
						preventDefaultClose: true,
					},
				]}
				headerText={this.props.titleText}
				content={content}
			/>
		);
	}
}

ProjectBudgetChangeModal.propTypes = {
	titleText: PropTypes.string.isRequired,
	cancelText: PropTypes.string,
	confirmText: PropTypes.string.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	inputType: PropTypes.string,
	project: PropTypes.shape({
		budgetType: PropTypes.string.isRequired,
		budget: PropTypes.number,
	}).isRequired,
	isInFixedPriceProgram: PropTypes.bool,
	program: PropTypes.shape({
		name: PropTypes.string,
		prefix: PropTypes.string,
	}),
};

export default injectIntl(ProjectBudgetChangeModal);
