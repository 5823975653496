import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {Checkbox, Dropdown, SingleText} from 'web-components';
import ImportJiraUsersMutation from '../../mutations/ImportJiraUsersMutation';
import DirectApi from '../../directApi';
import {createFragmentContainer, graphql} from 'react-relay';
import {getAssignableProfiles} from '../../forecast-app/shared/util/ImportUserModalUtil';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';

class JiraSyncUsersModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			jiraUsers: [],
			fetchingUsers: true,
			selectedUsers: [],
			selectedProfileId: [],
			profiles: props.company.profiles,
		};

		this.getUsers();
	}

	getUsers() {
		DirectApi.Fetch('jira/' + this.props.jiraType + '/users').then(users => {
			this.setState({
				jiraUsers: users.sort((a, b) => (a.displayName > b.displayName ? 1 : -1)),
				fetchingUsers: false,
			});
		});
	}

	toggleSelectAll() {
		if (this.state.selectAll) {
			this.setState({
				selectedUsers: [],
				selectAll: false,
			});
		} else {
			this.setState({
				selectedUsers: this.state.jiraUsers.map(jiraUser =>
					this.props.jiraType === 'cloud' ? jiraUser.accountId : jiraUser.key
				),
				selectAll: true,
			});
		}
	}

	toggleUser(jiraId) {
		if (this.state.selectedUsers.includes(jiraId)) {
			const selectedUsers = this.state.selectedUsers.filter(userId => userId !== jiraId);
			this.setState({
				selectedUsers,
				selectAll: false,
			});
		} else {
			const selectedUsers = this.state.selectedUsers;
			selectedUsers.push(jiraId);
			this.setState({
				selectedUsers,
			});
		}
	}

	syncUsers() {
		const onSuccess = () => {
			showModal({
				type: MODAL_TYPE.INTEGRATIONS_INFO,
				title: 'Import Jira Users',
				infoMessage: 'The resources have been created in Forecast as Deactivated team members.',
			});
		};

		Util.CommitMutation(
			ImportJiraUsersMutation,
			{
				jiraPersonIds: this.state.selectedUsers,
				jiraType: this.props.jiraType,
				profileId: this.getSelectedProfileId(),
				active: false,
			},
			onSuccess
		);
	}

	onProfileSelect(input) {
		this.setState({selectedProfileId: input});
	}

	getSelectedProfileId() {
		return this.state.selectedProfileId[0];
	}

	getProfileElements() {
		const profilesArray = this.state.profiles.edges.map(option => {
			return {id: option.node.id, name: option.node.name, disable: option.node.disable};
		});
		return getAssignableProfiles(profilesArray).map(option => {
			return (
				<SingleText key={option.id} value={option.id}>
					{option.name}
				</SingleText>
			);
		});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="unit4-sync-users-modal-content">
				<div className="unit4-sync-users-modal-description">
					{
						'Please choose the Jira users that you would like to create in Forecast. The chosen users will be created in Forecast with the selected Permission Profile.'
					}
				</div>
				<Dropdown
					usePortal={true}
					maxHeight={400}
					selectedItems={this.state.selectedProfileId}
					onSelect={this.onProfileSelect.bind(this)}
					name={'Choose Permission Profile'}
				>
					{this.getProfileElements()}
				</Dropdown>
				<div style={{marginTop: '10px'}}>
					{this.state.fetchingUsers ? (
						<InlineLoader />
					) : this.state.jiraUsers.length === 0 ? (
						<FormattedMessage id="modal.jira.sync_users.empty" />
					) : (
						<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
							<div className="unit4-users">
								<div className="unit4-user" key={'0'}>
									<Checkbox checked={this.state.selectAll} onClick={this.toggleSelectAll.bind(this)} />
									<FormattedMessage id="common.select_all" />
								</div>
								{this.state.jiraUsers.map(jiraUser => (
									<div className="unit4-user" key={jiraUser.accountId || jiraUser.key}>
										<Checkbox
											checked={this.state.selectedUsers.includes(jiraUser.accountId || jiraUser.key)}
											onClick={this.toggleUser.bind(this, jiraUser.accountId || jiraUser.key)}
										/>
										{`${jiraUser.displayName}${jiraUser.emailAddress ? ` (${jiraUser.emailAddress})` : ''}`}
									</div>
								))}
							</div>
						</CustomScrollDiv>
					)}
				</div>
			</div>
		);

		return (
			<GenericModal
				headerText={<FormattedMessage id="common.sync_users" />}
				closeModal={this.props.closeModal}
				className="unit4-sync-users-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.create_x_users'}, {numOfUsers: this.state.selectedUsers.length}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.syncUsers.bind(this),
						disabled: this.state.selectedUsers.length === 0 || this.state.selectedProfileId.length === 0,
					},
				]}
				content={content}
			/>
		);
	}
}

const jiraSyncUsersModalQuery = graphql`
	query jiraSyncUsersModal_Query {
		viewer {
			actualPersonId
			component(name: "jira_sync_users_modal")
			company {
				...jiraSyncUsersModal_company
			}
		}
	}
`;

export {jiraSyncUsersModalQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(JiraSyncUsersModal, {
			company: graphql`
				fragment jiraSyncUsersModal_company on Company {
					profiles {
						edges {
							node {
								id
								name
								disabled
							}
						}
					}
				}
			`,
		})
	)
);
