import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {injectIntl} from 'react-intl';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import DirectApi from '../../directApi';

class HarvestTaskSelectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedHarvestTask:
				this.props.timeReg && this.props.timeReg.harvestTask ? this.props.timeReg.harvestTask.id : null,
			harvestTasks: [],
		};
	}
	componentDidMount() {
		if (this.modal_title) {
			this.modal_title.focus();
		}
	}

	updateHarvestTasks(project) {
		if (project) {
			if (project.harvestProject) {
				this.setState({harvestLoading: true});

				DirectApi.Fetch('harvest_tasks/' + project.id).then(tasks => {
					this.setState({harvestTasks: tasks, harvestLoading: false});
				});
			} else {
				this.setState({harvestTasks: [], harvestLoading: false, selectedHarvestTask: null});
			}
		}
	}

	onHarvestTaskSelect(val) {
		this.setState({selectedHarvestTask: val.value});
	}

	selectTask() {
		this.props.callback(this.state.selectedHarvestTask);
	}

	render() {
		const {formatMessage} = this.props.intl;

		const harvestOpts = this.state.harvestTasks.map(opt => {
			return {value: opt.id, label: opt.name};
		});
		const content = (
			<div ref={div => (this.modal_title = div)} className={'integration-entity-select-modal'}>
				<div className={'integration-entity-description'}>
					{formatMessage(
						{id: 'integration.register_time_for'},
						{input: this.props.value, target: this.props.timeRegName}
					)}
				</div>
				<DropdownV2
					value={this.state.selectedHarvestTask}
					options={harvestOpts}
					onChange={this.onHarvestTaskSelect.bind(this)}
					hideLabel={true}
					placeholder={formatMessage({id: 'card_modal.harvest_task'})}
					customHeight={30}
					customClasses="harvest-task-dropdown"
					onClick={this.updateHarvestTasks.bind(this, this.props.project)}
					integrationDropdown={true}
					integrationLoading={this.state.harvestLoading}
					integrationRetryToFetch={this.updateHarvestTasks.bind(this, this.props.project)}
				/>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				headerText={formatMessage({id: 'card_modal.harvest_task_placeholder'})}
				defaultCallback={this.props.defaultCallback}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						callback: this.props.cancelCallback,
					},
					{
						text: formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.selectTask.bind(this),
						disabled: !this.state.selectedHarvestTask,
					},
				]}
				content={content}
			/>
		);
	}
}
HarvestTaskSelectModal.propTypes = {
	harvestTaskOptions: PropTypes.array,
	timeReg: PropTypes.object,
};
export default injectIntl(HarvestTaskSelectModal);
