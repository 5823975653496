/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type jiraSyncUsersModal_company$ref: FragmentReference;
declare export opaque type jiraSyncUsersModal_company$fragmentType: jiraSyncUsersModal_company$ref;
export type jiraSyncUsersModal_company = {|
  +profiles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +disabled: ?boolean,
      |}
    |}>
  |},
  +$refType: jiraSyncUsersModal_company$ref,
|};
export type jiraSyncUsersModal_company$data = jiraSyncUsersModal_company;
export type jiraSyncUsersModal_company$key = {
  +$data?: jiraSyncUsersModal_company$data,
  +$fragmentRefs: jiraSyncUsersModal_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "jiraSyncUsersModal_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeConnection",
      "kind": "LinkedField",
      "name": "profiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Profile",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '13f97eb751622cad9713647b40f0baa5';

module.exports = node;
