import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {Checkbox} from 'web-components';
import Util from '../../forecast-app/shared/util/util';
import {hideLoader, showLoader} from '../global_loader';
import ImportBambooHRTimeOffTypesMutation from '../../mutations/ImportBambooHRTimeOffTypesMutation';
import DirectApi from '../../directApi';

class BambooHRImportTimeOffTypesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bambooTimeOffTypes: [],
			fetchingTimeOffTypes: true,
			selectedTimeOffTypes: [],
			selectAll: false,
			importing: false,
		};

		this.getTimeOffTypes();
	}

	getTimeOffTypes() {
		DirectApi.Fetch('bamboohr/time_off_types').then(timeOffTypes => {
			this.setState({
				bambooTimeOffTypes: timeOffTypes
					.filter(t => !t.forecastId)
					.sort((a, b) => (a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : -1)),
				fetchingTimeOffTypes: false,
			});
		});
	}

	toggleSelectAll() {
		if (this.state.selectAll) {
			this.setState({
				selectedTimeOffTypes: [],
				selectAll: false,
			});
		} else {
			this.setState({
				selectedTimeOffTypes: this.state.bambooTimeOffTypes.map(type => type.id),
				selectAll: true,
			});
		}
	}

	toggleTimeOffType(typeId) {
		if (this.state.selectedTimeOffTypes.includes(typeId)) {
			const selectedTimeOffTypes = this.state.selectedTimeOffTypes.filter(id => id !== typeId);
			this.setState({
				selectedTimeOffTypes,
				selectAll: false,
			});
		} else {
			const selectedTimeOffTypes = this.state.selectedTimeOffTypes;
			selectedTimeOffTypes.push(typeId);
			this.setState({
				selectedTimeOffTypes,
			});
		}
	}

	importTimeOffTypes() {
		this.setState({importing: true});
		showLoader();
		Util.CommitMutation(
			ImportBambooHRTimeOffTypesMutation,
			{
				ids: this.state.selectedTimeOffTypes,
			},
			() => {
				this.setState({importing: false});
				this.props.callbackPositive();
				this.props.closeModal();
				hideLoader();
			}
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="integration-sync-clients-modal-content">
				<div className="integration-sync-clients-modal-description">
					<FormattedMessage id="integrations.bamboohr.import_time_off_types.description" />
				</div>
				{this.state.fetchingTimeOffTypes ? (
					<InlineLoader />
				) : (
					<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
						<div className="integration-clients">
							{this.state.bambooTimeOffTypes && this.state.bambooTimeOffTypes.length > 0 ? (
								<>
									<div className="integration-client" key={'0'}>
										<Checkbox checked={this.state.selectAll} onClick={this.toggleSelectAll.bind(this)} />
										<FormattedMessage id="common.select_all" />
									</div>
									{this.state.bambooTimeOffTypes.map(timeOffType => (
										<div className="integration-client" key={timeOffType.id}>
											<Checkbox
												checked={this.state.selectedTimeOffTypes.includes(timeOffType.id)}
												onClick={this.toggleTimeOffType.bind(this, timeOffType.id)}
											/>
											{timeOffType.name}
										</div>
									))}
								</>
							) : (
								<FormattedMessage id="integrations.bamboohr.all_time_off_types_linked" />
							)}
						</div>
					</CustomScrollDiv>
				)}
			</div>
		);

		return (
			<GenericModal
				headerText={<FormattedMessage id="integrations.bamboohr.import_time_off_types" />}
				closeModal={this.props.closeModal}
				className="integration-sync-clients-modal"
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage(
							{id: 'common.create_x_time_off_types'},
							{numOfTypes: this.state.selectedTimeOffTypes.length}
						),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						callback: this.importTimeOffTypes.bind(this),
						disabled: this.state.importing || this.state.selectedTimeOffTypes.length === 0,
						defaultCallback: () => null,
						preventDefaultClose: true,
					},
				]}
				content={content}
			/>
		);
	}
}

export default injectIntl(withRouter(BambooHRImportTimeOffTypesModal));
