import React from 'react';
import {useIntl, FormattedHTMLMessage} from 'react-intl';
import {DeprecatedText as Text, FlexColumn} from '@forecast-it/design-system';

const RoleDeactivatedWarningModal = ({closeModal}) => {
	const intl = useIntl();

	return (
		<FlexColumn gap={'s'}>
			<Text type="heading" variant="xl">
				{intl.formatMessage({id: 'settings_team.role_disabled_warning_title'})}
			</Text>
			<Text>
				<FormattedHTMLMessage id="settings_team.role_disabled_warning_text" />
			</Text>
		</FlexColumn>
	);
};

export default RoleDeactivatedWarningModal;
