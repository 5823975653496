import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import GenericModal from './generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import {INITIALS_SIZE} from '../../constants';
import CostPeriod from '../../components/cost-periods/cost_period';
import AddCostPeriod from '../../components/cost-periods/add_cost_period';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import {profilePicSrc} from '../../directApi';

class costPeriodsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getPeriods() {
		const person = this.props.viewer.company.person;

		return person.costPeriods.edges
			.map(periodEdge => {
				const period = {id: periodEdge.node.id, cost: periodEdge.node.cost, startDate: null};
				if (
					periodEdge.node.startDay !== null &&
					periodEdge.node.startMonth !== null &&
					periodEdge.node.startYear !== null
				) {
					period.startDate = Util.CreateNonUtcMomentDate(
						periodEdge.node.startYear,
						periodEdge.node.startMonth,
						periodEdge.node.startDay
					);
				}

				return period;
			})
			.sort((a, b) => {
				return a.startDate - b.startDate;
			});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer} = this.props;
		const person = this.props.viewer.company.person;
		const currentCostPeriod = Util.getCurrentCostPeriod(person.costPeriods.edges);
		const periods = this.getPeriods();

		const content = (
			<div className="content cost-periods-modal-container">
				<h1>{formatMessage({id: 'settings_people.internal_cost_periods'})}</h1>
				<div className="person">
					{person.profilePictureId ? (
						<img
							crossOrigin="use-credentials"
							src={profilePicSrc(person.profilePictureId)}
							width="25"
							height="25"
							alt=""
						/>
					) : (
						<PersonInitials initials={person.initials} size={INITIALS_SIZE.SMALL} />
					)}
					<div className="name">{person.fullName}</div>
				</div>

				<div className="cost-periods-container">
					<div className="headers">
						<div className="header start-date">{formatMessage({id: 'common.start_date'})}</div>
						<div className="header line" />
						<div className="header end-date">{formatMessage({id: 'common.end_date'})}</div>
						<div className="header internal-cost">
							{formatMessage({id: 'settings_people.internal_hourly_cost'})}
						</div>
					</div>
					<div className="cost-periods">
						<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
							{periods.map((period, index) => (
								<CostPeriod
									currentCostPeriod={currentCostPeriod}
									key={period.id}
									period={period}
									index={index}
									periods={periods}
									viewer={viewer}
									person={person}
								/>
							))}
						</CustomScrollDiv>
					</div>
					<AddCostPeriod viewer={viewer} person={person} periods={periods} />
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.filter-close'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						cy: 'periods-close',
					},
				]}
				headerText={this.props.title}
				content={content}
			/>
		);
	}
}

costPeriodsModal.propTypes = {};

const costPeriodsModalQuery = graphql`
	query costPeriodsModal_Query($personId: ID) {
		viewer {
			actualPersonId
			component(name: "cost_periods_modal")
			...costPeriodsModal_viewer @arguments(personId: $personId)
		}
	}
`;

export {costPeriodsModalQuery};

export default injectIntl(
	createFragmentContainer(costPeriodsModal, {
		viewer: graphql`
			fragment costPeriodsModal_viewer on Viewer @argumentDefinitions(personId: {type: "ID"}) {
				company {
					id
					isChargebeeCustomer
					currency
					person(id: $personId) {
						id
						fullName
						initials
						email
						profilePictureId
						profilePictureDefaultId
						role {
							id
							name
						}
						active
						isViewer
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
						cost
						costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
							edges {
								node {
									id
									startDay
									startMonth
									startYear
									cost
								}
							}
						}
					}
				}
			}
		`,
	})
);
