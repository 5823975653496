import React from 'react';
import moment from 'moment';
import {ForecastSurplusModalChart} from './ForecastSurplusModalChart';

const getDateArray = (startDate, stopDate) => {
	var dateArray = [];
	var currentDate = moment(startDate);
	var endDate = moment(stopDate);
	while (currentDate <= endDate) {
		dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
		currentDate = moment(currentDate).add(1, 'days');
	}
	return dateArray;
};

export const ForecastSurplusModalChartMock = ({
	fixedPrice,
	startDate,
	endDate,
	revenueMarginLow,
	revenueMarginHigh,
	currencySymbol,
}) => {
	const data = {
		actualRevenueChartData: [],
		forecastRevenueChartData: [],
		fixedPriceChartData: [],
		revenueMarginLowData: [],
		revenueMarginHighData: [],
		todayIntersection: [],
	};

	const today = moment();

	const revenueMarginLowValue = fixedPrice * revenueMarginLow;
	const revenueMarginHighValue = fixedPrice * revenueMarginHigh;

	const startDateMock = startDate || today.clone().subtract(1, 'months');
	const endDateMock = endDate || today.clone().add(1, 'months');

	const dateArray = getDateArray(startDateMock, endDateMock);
	const daysDifference = endDateMock.diff(startDateMock, 'days');

	let rollupActualRevenue = 0;
	let rollupForecastRevenue = 0;

	dateArray.forEach(date => {
		rollupActualRevenue += fixedPrice / (daysDifference + 10);
		rollupForecastRevenue += fixedPrice / (daysDifference + 10);
		const momentDate = moment(date);
		const isToday = momentDate.isSame(today, 'days');
		const isFuture = momentDate.isAfter(today, 'days');

		data.actualRevenueChartData.push({
			date: momentDate,
			value: isFuture ? null : rollupActualRevenue,
		});
		data.forecastRevenueChartData.push({
			date: momentDate,
			value: isToday ? rollupForecastRevenue : isFuture ? rollupForecastRevenue : null,
		});
		data.todayIntersection.push({
			date: momentDate,
			value: isToday ? rollupActualRevenue : null,
			customData: {
				hideLabel: true,
			},
		});
		data.fixedPriceChartData.push({date: momentDate, value: fixedPrice});
		data.revenueMarginLowData.push({date: momentDate, value: revenueMarginLowValue});
		data.revenueMarginHighData.push({date: momentDate, value: revenueMarginHighValue});
	});

	return (
		<ForecastSurplusModalChart
			data={data}
			firstDate={startDateMock}
			lastDate={endDateMock}
			currencySymbol={currencySymbol}
		/>
	);
};
