/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sageIntacctId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "ProjectType",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
            (v0 /*: any*/),
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "SageProjectType",
                "kind": "LinkedField",
                "name": "sageProject",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sageProjectId",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startDateString",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "endDateString",
                "type": "String"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "SageIntacctExportTimesheetsModal_company",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "endDate",
                        "variableName": "endDateString"
                    },
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 10000000
                    },
                    {
                        "kind": "Variable",
                        "name": "startDate",
                        "variableName": "startDateString"
                    }
                ],
                "concreteType": "TimeRegTypeConnection",
                "kind": "LinkedField",
                "name": "timeRegistrations",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeRegTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeRegType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "year",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "month",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "day",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "minutesRegistered",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "billable",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Person",
                                        "kind": "LinkedField",
                                        "name": "person",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "fullName",
                                                "storageKey": null
                                            },
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "sageIntacctLocationId",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "DepartmentType",
                                                "kind": "LinkedField",
                                                "name": "department",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "notes",
                                        "storageKey": null
                                    },
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Task",
                                        "kind": "LinkedField",
                                        "name": "task",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v2 /*: any*/),
                                            (v1 /*: any*/),
                                            (v3 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "sageIntacctTimesheetEntryRecordNumber",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Company"
    };
})();
node.hash = '49bd5c20d735cb4947ce26fa3733b67e';
export default node;
