import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const ProjectHealthSidebarWrapper = styled.div`
	width: 333px;
	height: 100%;
	border-top: 1px solid ${CSS_CONSTANTS.app_border_color};
	border-left: 1px solid ${CSS_CONSTANTS.app_border_color};
`;

export const ProjectHealthSidebarWrapperInner = styled.div`
	height: 100%;
	padding: 24px 32px;
`;

export const ProjectHealthSidebarTile = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 34px;
`;

export const TitleCaretWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`;

export const ProjectHealthSidebarTitle = styled.div`
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;
`;

export const StatusTextClickableTile = styled.div`
	padding: 16px;
	border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	border-radius: 4px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 13px;
	cursor: ${props => !props.disabled && 'pointer'};
	background-color: ${props => props.disabled && CSS_CONSTANTS.v2_input_disabled_grey};
	&:hover {
		background-color: ${props => !props.disabled && '#F7F7FE'};
	}
	&:active {
		background-color: ${props => !props.disabled && '#E6E6ED'};
	}
`;
