import styled from 'styled-components';

export const EmailsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;

export const CopyLink = styled.div`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	//USE COLORS FROM LIBRARY maybe?
	color: #008eff;
`;

export const ButtonsWrapper = styled.div`
	margin-top: 30px;
	display: flex;
	padding-bottom: 24px;
	border-bottom: 1px solid #e6e6ed;
	//How the justify-content was before removing cancel and copy link
	//justify-content: space-between;
	justify-content: end;
`;

export const CancelShareWrapper = styled.div`
	display: flex;
	gap: 16px;
`;

export const AccessHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 26px;
	border-bottom: 1px solid #f7f7fe;
	padding-bottom: 8px;
`;

export const UsersGrantedAccess = styled.div`
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #4c4c58;
`;

export const AlreadySharedContainer = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: 230px;
	border-bottom: 1px solid #f7f7fe;
`;

export const SharedPersonInfoWrapper = styled.div`
	padding: 8px;
	display: flex;
	align-items: center;
	&:hover {
		background-color: #f7f7fe;
	}
`;

export const CrossIconWrapper = styled.div`
	margin-right: 4px;
	visibility: hidden;
	${SharedPersonInfoWrapper}:hover & {
		visibility: visible;
	}
`;

export const EmailWrapper = styled.div`
	margin-left: 8px;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	color: #4c4c58;
	flex: 1;
`;

export const HexagonWrapper = styled.div`
	margin-right: 3px;
`;

export const DeactivatedIconWrapper = styled.div`
	margin-left: 32px;
	margin-right: 124px;
`;

export const SharedWithWrapper = styled.div`
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 8px;
`;

export const InputHolder = styled.div`
	background: #f7f7fe;
	border-radius: 4px;
	padding: 4px;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 96px;
`;

export const ActualInput = styled.input.attrs({type: 'text'})`
	border: 0;
	display: inline-block;
	padding: 5px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	flex-grow: 1;

	&:focus {
		outline-width: 0;
	}
`;

export const InputErrorMessage = styled.div`
	margin-left: 8px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	color: #e63946;
`;
