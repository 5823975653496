import React, {useState, useEffect} from 'react';
import GenericModal from '../generic_modal';
import {BUTTON_COLOR} from '../../../constants';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import TaskList, {taskListQuery} from './task_list';
import {Row, Container, Text, LoaderContainer} from './project_automate_modal.styled';
import Util from '../../../forecast-app/shared/util/util';
import UpdateTasksBulkMutation from '../../../mutations/update_tasks_bulk_mutation.modern';
import AiLoader from '../../../components/loaders/ai_loader';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {startCase} from 'lodash';

export const VARIANT = {
	ASSIGNEES: 'assignees',
	ROLES: 'roles',
	ESTIMATES: 'estimates',
	LABELS: 'labels',
};

export const ProjectAutomateModal = ({closeModal, variant, tasks, intl, roles, persons, labels}) => {
	const [errors, setErrors] = useState(false);
	const [taskListState, setTaskListState] = useState({});

	const TEXTS = {
		assignees: {
			header: intl.formatMessage({id: 'common.assign_people'}),
			info: intl.formatMessage({id: 'automate.info_team_members'}),
		},
		roles: {
			header: intl.formatMessage({id: 'common.assign_roles'}),
			info: intl.formatMessage({id: 'automate.info_roles'}),
		},
		estimates: {
			header: intl.formatMessage({id: 'common.add_estimates_high_low'}),
			info: intl.formatMessage({id: 'automate.info_estimates'}),
		},
		labels: {
			header: intl.formatMessage({id: 'bulk_card_update_modal.add_labels'}),
			info: intl.formatMessage({id: 'automate.info_labels'}),
		},
	};
	useEffect(() => {
		tracking.trackAIEvent('triggered', variant);
		trackEvent(`Automate ${startCase(variant)}`, 'Shown');
	}, []);

	/* useEffect(() => {
		const size = _.size(taskListState.assigneesAI);
		console.log('TRACKING size', size);
	}, [taskListState.assigneesAI]); */
	const getContent = () => {
		return (
			<Container>
				<Row>
					<Text>{TEXTS[variant].info}</Text>
				</Row>
				<Row last={true}>
					<ForecastQueryRenderer
						query={taskListQuery}
						key="automate-modal-query"
						customLoader={() => (
							<LoaderContainer>
								<AiLoader />
							</LoaderContainer>
						)}
						variables={{
							fetchHighLow: variant === VARIANT.ESTIMATES,
							fetchRoles: variant === VARIANT.ROLES,
							fetchAssignee: variant === VARIANT.ASSIGNEES,
							fetchLabels: variant === VARIANT.LABELS,
							taskIds: tasks.map(task => task.id),
						}}
						render={(relayProps, retry) => (
							<TaskList
								passState={setTaskListState}
								onError={setErrors}
								intl={intl}
								{...relayProps}
								retry={retry}
								tasks={tasks}
								labels={labels.edges.map(edge => edge.node)}
								roles={roles.edges.map(edge => edge.node)}
								estimateVisible={variant === VARIANT.ESTIMATES}
								roleVisible={variant === VARIANT.ROLES}
								assigneesVisible={variant === VARIANT.ASSIGNEES}
								labelsVisible={variant === VARIANT.LABELS}
								persons={persons}
							/>
						)}
					/>
				</Row>
			</Container>
		);
	};
	const saveUpdate = () => {
		let tasksToUpdate = tasks.map(task => {
			return {
				taskId: task.id,
				estimateForecast: variant === VARIANT.ESTIMATES ? taskListState.estimates[task.id] * 60 : undefined,
				personIds: variant === VARIANT.ASSIGNEES ? taskListState.assignees[task.id] : undefined,
				roleId: variant === VARIANT.ROLES ? taskListState.roles[task.id] : undefined,
				labelIds: variant === VARIANT.LABELS ? taskListState.labels[task.id] : undefined,
			};
		});

		Util.CommitSchedulingModalUpdatePromise(UpdateTasksBulkMutation, {tasks: tasksToUpdate});
		tracking.trackAIEvent('Applied', variant);
		trackEvent(`Automate ${startCase(variant)}`, 'Applied');
	};

	return (
		<GenericModal
			cy={'project-automate-modal'}
			headerText={TEXTS[variant].header}
			content={getContent()}
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					color: BUTTON_COLOR.WHITE,
				},
				{
					id: 'auto-schedule-continue-button',
					cy: 'auto-schedule-continue-button',
					text: intl.formatMessage({id: 'common.apply'}),
					disabled: errors,
					callback: saveUpdate,
					autoScheduleStyle: true,
				},
			]}
		/>
	);
};
