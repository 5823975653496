import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../css_variables';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import warningRed from '../../images/sections/scheduling-canvas/warning-icon-red.svg';

export const BudgetWrapper = Styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const BudgetContentWrapper = Styled.div`
    margin: 0 32px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const WarningMenu = Styled(ActionsMenu)`
    &.expanded {
        &.actions.warning {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-right: 1px solid transparent !important;
            background: url(${warningRed}) 10px center no-repeat !important;
        }
    }
    &.actions.warning {
        border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
        border-radius: 0;
        background: url(${warningRed}) 10px center no-repeat;
        width: auto !important;
        height: 28px;
        position: relative;
        background-color: ${CSS_CONSTANTS.color_white};
        padding: 7px;
        padding-left: 34px;
        padding-right: 10px;
        color: ${CSS_CONSTANTS.negative_value};
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
    }
`;

export const Tabs = Styled.div`
    display: flex;
    font-size: 13px;
    color: ${CSS_CONSTANTS.v2_text_gray};
    margin: 16px 0 25px 0;
`;

export const Tab = Styled.div`
    padding-top: 5px;
    margin-right: 22px;
    cursor: pointer;
    &:hover {
        color: ${CSS_CONSTANTS.v2_branding_purple};
    }
    &.active {
        color: ${CSS_CONSTANTS.v2_branding_purple};
        border-bottom: 3px solid ${CSS_CONSTANTS.v2_branding_purple};
        padding-bottom: 5px;
        font-weight: 500;
    }
`;
