import React, {useEffect, useState} from 'react';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {FormattedMessage} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import GenericModal from './generic_modal';
import {hideLoader, showLoader} from '../global_loader';
import Util from '../../forecast-app/shared/util/util';
import ImportPipedriveOrganizationsMutation from '../../mutations/ImportPipedriveOrganizationsMutation';
import {Checkbox} from 'web-components';
import {CheckBoxWrapper} from './HubspotImportClientsModal_style';
import Input from '../../components/new-ui/input_field';
import DirectApi from '../../directApi';

const pipedriveImportClientsModal = ({intl, closeModal, companyId, retry}) => {
	//state variables
	const [selectedOrganizations, setSelectedOrganizations] = useState([]);
	const [pipedriveOrganizations, setPipedriveOrganizations] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [importing, setImporting] = useState(false);
	const [fetchingOrganizations, setFetchingOrganizations] = useState(true);
	const [searchingOrganizations, setSearchingOrganizations] = useState(false);
	const [fetchTimeout, setFetchTimeout] = useState(null);

	useEffect(() => {
		DirectApi.Fetch(`pipedrive/unimportedOrganizations?search=${''}`).then(organizations => {
			setPipedriveOrganizations(
				organizations.sort((a, b) => (a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : -1))
			);
			setFetchingOrganizations(false);
		});
	}, []);

	const getPipedriveOrganizations = searchInput => {
		DirectApi.Fetch(`pipedrive/unimportedOrganizations?search=${searchInput ? `${searchInput}` : ''}`).then(accounts => {
			setPipedriveOrganizations(
				accounts.sort((a, b) => (a.name.toUpperCase().trim() > b.name.toUpperCase().trim() ? 1 : -1))
			);
			setFetchingOrganizations(false);
			setSearchingOrganizations(false);
		});
	};

	const getPipedriveOrganizationsWithTimeout = newSearchString => {
		//send the request to Pipedrive service 1 second and a half after the user is done typing
		setSearchingOrganizations(true);
		clearTimeout(fetchTimeout);
		setFetchTimeout(
			setTimeout(() => {
				getPipedriveOrganizations(newSearchString);
			}, 1500)
		);
	};

	const toggleSelectAll = () => {
		if (selectAll) {
			setSelectedOrganizations([]);
			setSelectAll(false);
		} else {
			setSelectedOrganizations(pipedriveOrganizations.map(organization => organization.id.toString()));
			setSelectAll(true);
		}
	};

	const toggleContact = pipedriveOrganizationId => {
		if (selectedOrganizations.some(selectedCustomerId => selectedCustomerId === pipedriveOrganizationId.toString())) {
			const newSelectedOrganizations = selectedOrganizations.filter(
				selectedOrganizationId => selectedOrganizationId !== pipedriveOrganizationId.toString()
			);
			setSelectedOrganizations(newSelectedOrganizations);
			setSelectAll(false);
		} else {
			const newSelectedOrganizations = [...selectedOrganizations, pipedriveOrganizationId.toString()];
			setSelectedOrganizations(newSelectedOrganizations);
		}
	};

	const syncContacts = () => {
		setImporting(true);
		showLoader();
		Util.CommitMutation(
			ImportPipedriveOrganizationsMutation,
			{
				companyId,
				clientIds: selectedOrganizations,
			},
			() => {
				setImporting(false);
				if (retry) retry();
				closeModal();
				hideLoader();
			}
		);
	};

	const {formatMessage} = intl;

	const content = (
		<div className="integration-sync-clients-modal-content">
			<div className="integration-sync-clients-modal-description">
				<FormattedMessage id="integrations.pipedrive.sync_clients.description" />
			</div>
			{fetchingOrganizations ? (
				<InlineLoader />
			) : (
				<>
					<Input
						autoFocus={true}
						onChange={searchString => {
							getPipedriveOrganizationsWithTimeout(searchString);
						}}
					/>

					{searchingOrganizations ? (
						<InlineLoader />
					) : (
						<CustomScrollDiv autoHeight autoHeightMin={10} autoHeightMax={'60vh'}>
							<div className="integration-clients">
								{pipedriveOrganizations && pipedriveOrganizations.length > 0 ? (
									<>
										<div className="integration-client" key={'0'}>
											<CheckBoxWrapper>
												<Checkbox checked={selectAll} onClick={toggleSelectAll} />
											</CheckBoxWrapper>
											<FormattedMessage id="common.select_all" />
										</div>
										{pipedriveOrganizations.map(pipedriveOrganization => {
											return (
												<div className="integration-client" key={pipedriveOrganization.id}>
													<CheckBoxWrapper>
														<Checkbox
															checked={selectedOrganizations.includes(
																pipedriveOrganization.id.toString()
															)}
															onClick={() => {
																toggleContact(pipedriveOrganization.id);
															}}
														/>
													</CheckBoxWrapper>
													{pipedriveOrganization.name}
												</div>
											);
										})}
									</>
								) : (
									<FormattedMessage id="integrations.pipedrive.all_contacts_synched" />
								)}
							</div>
						</CustomScrollDiv>
					)}
				</>
			)}
		</div>
	);

	return (
		<GenericModal
			headerText={<FormattedMessage id="integrations.pipedrive.sync_clients" />}
			closeModal={closeModal}
			className="integration-sync-clients-modal"
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.create_x_clients'}, {numOfClients: selectedOrganizations.length}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: syncContacts.bind(this),
					disabled: importing || selectedOrganizations.length === 0,
					defaultCallback: () => null,
					preventDefaultClose: true,
				},
			]}
			content={content}
		/>
	);
};

export default pipedriveImportClientsModal;
