/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingUninvoicedItemsTableSection_project$ref: FragmentReference;
declare export opaque type ProjectInvoicingUninvoicedItemsTableSection_project$fragmentType: ProjectInvoicingUninvoicedItemsTableSection_project$ref;
export type ProjectInvoicingUninvoicedItemsTableSection_project = {|
  +id: string,
  +unInvoicedTotal: {|
    +unInvoicedMinutes: ?number,
    +unInvoicedPrice: ?number,
  |},
  +name: ?string,
  +rateCard: ?{|
    +currency: ?string
  |},
  +budget: ?number,
  +budgetType: ?BUDGET_TYPE,
  +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
  +expenseItems: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +approved: ?boolean,
        +billable: ?boolean,
        +partOfFixedPrice: ?boolean,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +price: ?number,
        +cost: ?number,
        +quantity: ?number,
        +invoiced: ?boolean,
      |}
    |}>
  |},
  +$refType: ProjectInvoicingUninvoicedItemsTableSection_project$ref,
|};
export type ProjectInvoicingUninvoicedItemsTableSection_project$data = ProjectInvoicingUninvoicedItemsTableSection_project;
export type ProjectInvoicingUninvoicedItemsTableSection_project$key = {
  +$data?: ProjectInvoicingUninvoicedItemsTableSection_project$data,
  +$fragmentRefs: ProjectInvoicingUninvoicedItemsTableSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "expenseItems"
        ]
      }
    ]
  },
  "name": "ProjectInvoicingUninvoicedItemsTableSection_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "endDay",
          "variableName": "endDay"
        },
        {
          "kind": "Variable",
          "name": "endMonth",
          "variableName": "endMonth"
        },
        {
          "kind": "Variable",
          "name": "endYear",
          "variableName": "endYear"
        },
        {
          "kind": "Variable",
          "name": "startDay",
          "variableName": "startDay"
        },
        {
          "kind": "Variable",
          "name": "startMonth",
          "variableName": "startMonth"
        },
        {
          "kind": "Variable",
          "name": "startYear",
          "variableName": "startYear"
        }
      ],
      "concreteType": "UnInvoicedProjectTotal",
      "kind": "LinkedField",
      "name": "unInvoicedTotal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unInvoicedMinutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unInvoicedPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "rateCard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPeriodBudgetType",
      "storageKey": null
    },
    {
      "alias": "expenseItems",
      "args": null,
      "concreteType": "ExpenseItemTypeConnection",
      "kind": "LinkedField",
      "name": "__Project_expenseItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseItemTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partOfFixedPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiced",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'b546a0959b044ff0dc785c0c14745e61';

module.exports = node;
