/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type createJiraMilestoneModal_viewer$ref: FragmentReference;
declare export opaque type createJiraMilestoneModal_viewer$fragmentType: createJiraMilestoneModal_viewer$ref;
export type createJiraMilestoneModal_viewer = {|
  +id: string,
  +project: ?{|
    +id: string,
    +jiraCloudProject: ?{|
      +id: string
    |},
    +jiraServerProject: ?{|
      +id: string
    |},
    +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
  |},
  +$refType: createJiraMilestoneModal_viewer$ref,
|};
export type createJiraMilestoneModal_viewer$data = createJiraMilestoneModal_viewer;
export type createJiraMilestoneModal_viewer$key = {
  +$data?: createJiraMilestoneModal_viewer$data,
  +$fragmentRefs: createJiraMilestoneModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "createJiraMilestoneModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraCloudProjectType",
          "kind": "LinkedField",
          "name": "jiraCloudProject",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraServerProjectType",
          "kind": "LinkedField",
          "name": "jiraServerProject",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jiraCloudEpicIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '79a03f10a6de7747e5f8a7b8c74423fa';

module.exports = node;
