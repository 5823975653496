import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {graphql} from 'react-relay';
import {BasicNumberInput, CurrencyInput, InvoiceRowHeader, PercentageInput} from 'web-components';
import InvoiceRow from '../../../../components/invoice-row/Invoice_row';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {
	AddLineButton,
	DetailLabel,
	DetailLabelColumn,
	DetailValue,
	DetailValueColumn,
	ErrorMessage,
	InputWithErrorWrapper,
	InvoiceDetails,
	InvoiceLinesDetailsSection,
	InvoiceLinesTableContainer,
	LargeTextArea,
	ProjectDropdownCell,
	TextArea,
} from '../../../../styles/sections/insights/invoicing/invoicing_page_styled';
import AddButton from '../../../../forecast-app/shared/components/add_button';
import Dropdown from '../../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../../forecast-app/shared/util/util';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import WarningIcon from '../../../../images/warning_icon';
import {QUANTITY_LOCKED_REASON} from '../../../../constants';
import ProjectDropdownRelay from '../../../../forecast-app/shared/components/dropdowns/project-dropdown-b/Project_dropdown';
import {generateLines} from '../../../../components/insights/invoicing/line-generation/invoicing_generate_lines';

const MAX_DESCRIPTION_LENGTH = 1000;

// NB: Lines is the same as entries, please do not mind the mix in the naming, we kind of decided midway
const InvoiceEntriesList = ({
	canEditInvoice,
	invoiceExported,
	invoiceExporting,
	lines,
	setInvoiceLines,
	projects,
	clientProjects,
	currency,
	tax,
	discount,
	integrationData,
	setHasError,
	viewer,
	config,
	invoice,
	company,
	isProjectInvoicing,
	project,
	isCreatingBlankInvoice,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const columns = {
		project: true,
		description: true,
		xeroAccount: integrationData?.xero?.enabled,
		quickbooksItem: integrationData?.quickbooks?.enabled,
		economicProduct: integrationData?.economic?.enabled,
		sageIntacctLocation: integrationData?.sageIntacct?.enabled,
		sageIntacctAccount: integrationData?.sageIntacct?.enabled,
		quantity: true,
		unitPrice: true,
		tax: true,
		discount: true,
		totalPrice: true,
		hasContextMenu: true,
	};
	let entryScrollDiv = null;

	const [descriptionFocused, setDescriptionFocused] = useState(null);

	const isInvoiceLocked = !canEditInvoice || invoiceExported || invoiceExporting ? true : null;
	const invoiceEntryLineCustomHeight = isInvoiceLocked ? 50 : null;
	const invoiceEntryLineCustomWidth = isInvoiceLocked ? (invoiceExporting ? 290 : 400) : null;

	useEffect(() => {
		if (viewer) {
			let fetchedUnInvoicedTimeRegistrations = [];
			const projectPersonsMap = viewer.company.allProjects.edges.reduce(function (map, obj) {
				map[obj.node.id] = obj.node.projectPersons;
				return map;
			}, {});
			viewer.company.allProjects.edges.forEach(
				project =>
					(fetchedUnInvoicedTimeRegistrations = fetchedUnInvoicedTimeRegistrations.concat(
						project.node.unInvoicedTimeRegistrations
					))
			);
			setInvoiceLines(
				isCreatingBlankInvoice
					? []
					: generateLines(
							config,
							intl,
							invoice,
							fetchedUnInvoicedTimeRegistrations,
							projects,
							projectPersonsMap,
							company
					  )
			);
		}
	}, [viewer]);

	const handleDescriptionBlur = e => {
		setDescriptionFocused(null);
		e.target.scrollTop = 0;
	};

	const updateEntry = (entryIndex, field, value) => {
		let updatedEntry = {...lines[entryIndex]};
		switch (field) {
			case 'project':
				updatedEntry.projectId = value;
				break;
			case 'description':
				updatedEntry.description = value;
				if (updatedEntry.description.length > MAX_DESCRIPTION_LENGTH) {
					setHasError(true);
				} else {
					setHasError(false);
				}
				break;
			case 'xeroAccount':
				updatedEntry.xeroAccount = value.value;
				updatedEntry.tax = value.taxRate;
				updatedEntry.accountCode = value.code;
				break;
			case 'quickbooksItem':
				updatedEntry.quickbooksItem = value.value;
				updatedEntry.quickbooksDescription = value.description;
				updatedEntry.quickbooksTaxable = value.taxable;
				break;
			case 'economicProduct':
				updatedEntry.economicProduct = value.value;
				break;
			case 'sageIntacctLocation':
				const timeReg = integrationData?.sageIntacct?.mappedTimeRegistrations.find(
					timeReg => timeReg.invoiceEntryId === Util.getIdFromBase64String(updatedEntry.id)
				);
				let locationIdPresent = false;
				if (timeReg !== undefined) {
					if (timeReg.person.sageIntacctLocationId !== null) {
						locationIdPresent = true;
					}
				}
				if (locationIdPresent) {
					updatedEntry.sageIntacctLocation = integrationData?.sageIntacct?.locations.find(
						location => location.value === timeReg.person.sageIntacctLocationId
					).value;
				} else {
					updatedEntry.sageIntacctLocation = value.value;
				}
				break;
			case 'sageIntacctAccount':
				updatedEntry.sageIntacctAccount = value.value;
				break;
			case 'quantity':
				updatedEntry.quantity = value;
				break;
			case 'price':
				updatedEntry.unitPrice = +value;
				break;
			case 'tax':
				updatedEntry.tax = +value * 100;
				break;
			case 'discount':
				updatedEntry.discount = +value * 100;
				break;
			default:
				break;
		}

		const updatedLines = [...lines];
		updatedLines.splice(entryIndex, 1, updatedEntry);

		setInvoiceLines(updatedLines);
	};

	const duplicateEntry = index => {
		const newEntry = {...lines[index]};
		newEntry.id = null;
		newEntry.timeRegIds = [];

		const newLines = [...lines];
		newLines.splice(index, 0, newEntry);

		setInvoiceLines(newLines);
	};

	const deleteEntry = index => {
		const newLines = [...lines];
		newLines.splice(index, 1);

		setInvoiceLines(newLines);
	};

	const getWarningTooltipText = (canEditInvoice, invoiceExported, invoiceExporting, warning) => {
		if (canEditInvoice === false)
			return [
				{
					title: 'invoicing.cannot_edit_warning_title',
					description: formatMessage({id: 'invoicing.cannot_edit_warning'}, {invoiceStatus: invoice?.status}),
				},
			];
		if (invoiceExported)
			return [
				{
					title: 'invoicing.cannot_edit_warning_title_exported',
					description: formatMessage({id: 'invoicing.cannot_edit_warning_exported'}),
				},
			];
		if (invoiceExporting)
			return [
				{
					title: 'invoicing.cannot_edit_warning_title_exporting',
					description: formatMessage({id: 'invoicing.cannot_edit_warning_exporting'}),
				},
			];
		if (warning) return [warning];
		return null;
	};

	const getProjectDropdown = (index, showWarning) => {
		const noClientProjects = clientProjects.length === 0;
		return (
			<ProjectDropdownCell maxWidth={invoiceExporting ? 152 : null} customHeight={invoiceEntryLineCustomHeight}>
				{showWarning && (
					<TooltipContainer
						infoText={formatMessage({
							id: noClientProjects ? 'invoicing.no_client_projects' : 'invoicing.no_project_entry_warning',
						})}
					>
						<WarningIcon />
					</TooltipContainer>
				)}
				<ProjectDropdownRelay
					hideEmptyGroups
					projects={clientProjects.map(project => {
						return {node: project};
					})}
					selectedItems={lines[index].projectId ? [lines[index].projectId] : []}
					isMultiSelect={false}
					onSelect={option => updateEntry(index, 'project', option[0])}
					maxHeight={200}
					disabled={noClientProjects}
					isClearable={false}
					usePortal
					dropdownAlignment={'left'}
				/>
			</ProjectDropdownCell>
		);
	};

	const getDescriptionField = (entry, index, hasDescriptionError) => {
		return isInvoiceLocked ? (
			<TooltipContainer infoText={entry.description} triangleLocation={'right'}>
				<LargeTextArea
					disabled={isInvoiceLocked}
					value={entry.description || ''}
					onBlur={handleDescriptionBlur}
					onFocus={() => {
						setDescriptionFocused(index);
					}}
					rows={descriptionFocused === index ? 8 : 1}
					onChange={e => updateEntry(index, 'description', e.target.value)}
					error={hasDescriptionError}
					customWidth={invoiceEntryLineCustomWidth}
					data-cy={'invoice-entry-description'}
				/>
				{hasDescriptionError ? (
					<ErrorMessage>{intl.formatMessage({id: 'invoicing.description_length_error'})}</ErrorMessage>
				) : null}
			</TooltipContainer>
		) : (
			<TooltipContainer infoText={getWarningTooltipText(canEditInvoice, invoiceExported, invoiceExporting, null)}>
				<TextArea
					disabled={isInvoiceLocked}
					value={entry.description || ''}
					onBlur={handleDescriptionBlur}
					onFocus={() => {
						setDescriptionFocused(index);
					}}
					rows={descriptionFocused === index ? 8 : 1}
					onChange={e => updateEntry(index, 'description', e.target.value)}
					error={hasDescriptionError}
					data-cy={'invoice-entry-description'}
				/>
				{hasDescriptionError ? (
					<ErrorMessage>{intl.formatMessage({id: 'invoicing.description_length_error'})}</ErrorMessage>
				) : null}
			</TooltipContainer>
		);
	};

	const getQuantityField = (entry, index) => {
		let warning = null;
		switch (entry.quantityLockedReason) {
			case QUANTITY_LOCKED_REASON.FIXED_PRICE:
				warning = {title: 'common.fixed_price', description: intl.formatMessage({id: 'invoicing.fixed_price_warning'})};
				break;
			case QUANTITY_LOCKED_REASON.FIXED_PRICE_PERIOD:
				warning = {
					title: 'common.fixed_price_period',
					description: intl.formatMessage({id: 'invoicing.fixed_price_period_warning'}),
				};
				break;
			default:
				break;
		}
		return (
			<InputWithErrorWrapper>
				<TooltipContainer
					infoText={
						!isInvoiceLocked
							? getWarningTooltipText(canEditInvoice, invoiceExported, invoiceExporting, warning)
							: null
					}
				>
					<BasicNumberInput
						disabled={warning || isInvoiceLocked}
						shortInput
						hideLabel
						width={'42px'}
						id={`quantity-${entry.id}`}
						type="number"
						value={entry.quantity || ''}
						callback={quantity => updateEntry(index, 'quantity', quantity)}
						alignTextRight={true}
						maxLength={8}
						noBorder={isInvoiceLocked}
						noLocked={isInvoiceLocked}
						preventNumberConversion
						cy={'invoice-entry-quantity'}
					/>
				</TooltipContainer>
			</InputWithErrorWrapper>
		);
	};

	const getUnitPriceField = (entry, index) => {
		let warning = null;
		if (entry.unitPrice === 0.0) {
			const project = projects.find(project => project.id === entry.projectId);
			const isFromExpense = !!entry.expenseItemIds;
			if (project && !project.rateCard && !isFromExpense) {
				warning = {
					title: 'invoicing.no_rate_card_warning_title',
					description: intl.formatMessage({id: 'invoicing.no_rate_card_warning_description'}),
				};
			} else {
				warning = {
					title: 'invoicing.no_unit_price_warning_title',
					description: intl.formatMessage({id: 'invoicing.no_unit_price_warning_description'}),
				};
			}
		}
		const noEditInfoText = getWarningTooltipText(canEditInvoice, invoiceExported, invoiceExporting, null);
		const showWarningIcon = warning && !noEditInfoText;
		return (
			<InputWithErrorWrapper>
				{showWarningIcon && (
					<TooltipContainer infoText={[warning]}>
						<WarningIcon danger />
					</TooltipContainer>
				)}
				<TooltipContainer infoText={!isInvoiceLocked ? noEditInfoText : null}>
					<CurrencyInput
						disabled={isInvoiceLocked}
						shortInput
						hideLabel
						width={showWarningIcon ? '59px' : '82px'}
						id={`unitPrice-${entry.id}`}
						type="number"
						suffix={Util.GetCurrencySymbol(currency)}
						value={entry.unitPrice || ''}
						callback={value => updateEntry(index, 'price', value)}
						currencySymbole={Util.GetCurrencySymbol(currency)}
						intl={intl}
						maxLength={8}
						noBorder={isInvoiceLocked}
						noLocked={isInvoiceLocked}
						cy={'invoice-entry-unit-price'}
					/>
				</TooltipContainer>
			</InputWithErrorWrapper>
		);
	};

	const getTaxField = (entry, index) => {
		return (
			<TooltipContainer
				infoText={
					!isInvoiceLocked ? getWarningTooltipText(canEditInvoice, invoiceExported, invoiceExporting, null) : null
				}
			>
				<PercentageInput
					disabled={isInvoiceLocked}
					shortInput
					hideLabel
					width={'47px'}
					id={`tax-${entry.id}`}
					type="number"
					value={entry.tax || 0}
					callback={value => updateEntry(index, 'tax', value)}
					alignTextRight
					showDecimals={true}
					maxLength={3}
					noBorder={isInvoiceLocked}
					noLocked={isInvoiceLocked}
					cy={'invoice-entry-tax'}
				/>
			</TooltipContainer>
		);
	};

	const getDiscountField = (entry, index) => {
		return (
			<TooltipContainer
				infoText={
					!isInvoiceLocked ? getWarningTooltipText(canEditInvoice, invoiceExported, invoiceExporting, null) : null
				}
			>
				<PercentageInput
					disabled={isInvoiceLocked}
					shortInput
					hideLabel
					width={'47px'}
					id={`discount-${entry.id}`}
					type="number"
					value={entry.discount || 0}
					callback={value => updateEntry(index, 'discount', value)}
					alignTextRight
					showDecimals={true}
					maxLength={3}
					noBorder={isInvoiceLocked}
					noLocked={isInvoiceLocked}
					cy={'invoice-entry-discount'}
				/>
			</TooltipContainer>
		);
	};

	const getMenuActions = index => {
		return [
			{
				text: formatMessage({id: 'common.duplicate'}),
				onClick: () => duplicateEntry(index),
				disabled: isInvoiceLocked,
			},
			{
				text: formatMessage({id: 'common.delete'}),
				onClick: () => deleteEntry(index),
				disabled: isInvoiceLocked,
			},
		];
	};

	const scrollToBottom = () => {
		if (entryScrollDiv) {
			entryScrollDiv.animateScrollTop(entryScrollDiv.scrollbars.getScrollHeight());
		}
	};

	const addLine = () => {
		const newLineList = [
			...lines,
			{
				projectId: isProjectInvoicing ? project.id : null,
				description: '',
				discount: discount || 0,
				name: '',
				quantity: (1).toFixed(company?.invoiceQuantityDecimals || 2),
				tax: tax || 0,
				unitPrice: 0,
				isBeingAdded: true,
			},
		];

		setInvoiceLines(newLineList);
		scrollToBottom();
	};

	const invoiceAmounts = Util.getInvoiceTotals(lines);

	const getXeroAccountDropdown = (entry, index) => {
		const xeroAccounts = integrationData?.xero?.accounts || [];
		return (
			<Dropdown
				customHeight={28}
				customWidth={100}
				hideLabel
				disabled={xeroAccounts.length === 0}
				value={entry.xeroAccount}
				options={xeroAccounts}
				onChange={v => updateEntry(index, 'xeroAccount', v)}
			/>
		);
	};

	const getQuickbooksItemDropdown = (entry, index) => {
		const quickbooksItems = integrationData?.quickbooks?.itemsAndServices || [];
		return (
			<Dropdown
				customHeight={28}
				customWidth={100}
				required={true}
				hideLabel
				value={entry.quickbooksItem}
				options={quickbooksItems}
				onChange={v => updateEntry(index, 'quickbooksItem', v)}
				placeholder={formatMessage({id: 'common.required_field'})}
			/>
		);
	};

	const getEconomicProductDropdown = (entry, index) => {
		const economicProducts = integrationData?.economic?.products || [];
		return (
			<Dropdown
				customHeight={28}
				customWidth={130}
				hideLabel
				value={entry.economicProduct}
				options={economicProducts}
				onChange={v => updateEntry(index, 'economicProduct', v)}
			/>
		);
	};

	const getSageIntacctLocationDropdown = (entry, index) => {
		const sageIntacctLocations = integrationData?.sageIntacct?.locations || [];
		const timeReg = integrationData?.sageIntacct?.mappedTimeRegistrations.find(
			timeReg => timeReg.invoiceEntryId === Util.getIdFromBase64String(entry.id)
		);
		let locationIdPresent = false;

		if (timeReg !== undefined) {
			if (timeReg.person.sageIntacctLocationId !== null) {
				locationIdPresent = true;
			}
		}

		if (sageIntacctLocations.length > 1 && locationIdPresent) {
			const value = sageIntacctLocations.find(location => location.value === timeReg.person.sageIntacctLocationId).value;
			if (value !== entry.sageIntacctLocation) {
				updateEntry(index, 'sageIntacctLocation', value);
			}
		}

		return (
			<Dropdown
				customHeight={28}
				customWidth={130}
				hideLabel
				value={entry.sageIntacctLocation}
				options={sageIntacctLocations}
				disabled={locationIdPresent}
				onChange={v => updateEntry(index, 'sageIntacctLocation', v)}
				placeholder={
					sageIntacctLocations.length > 1 && locationIdPresent
						? sageIntacctLocations.find(location => location.value === timeReg.person.sageIntacctLocationId).label
						: ''
				}
			/>
		);
	};

	const getSageIntacctAccountDropdown = (entry, index) => {
		const sageIntacctAccounts = integrationData?.sageIntacct?.accounts || [];
		return (
			<Dropdown
				customHeight={28}
				customWidth={130}
				hideLabel
				value={entry.sageIntacctAccount}
				options={sageIntacctAccounts}
				onChange={v => updateEntry(index, 'sageIntacctAccount', v)}
			/>
		);
	};

	return (
		<>
			<InvoiceLinesTableContainer data-userpilot={'invoice-entries-list'}>
				<InvoiceRowHeader customWidth={invoiceEntryLineCustomWidth} intl={intl} columns={columns} isEntryRow={true} />
				<CustomScrollDiv
					autoHeightMin={100}
					autoHeightMax={400}
					ref={CustomScrollDiv => (entryScrollDiv = CustomScrollDiv)}
				>
					{lines.map((entry, index) => {
						const hasDescriptionError = entry.description?.length > MAX_DESCRIPTION_LENGTH;
						return (
							<InvoiceRow
								key={index}
								columns={columns}
								project={projects.find(project => project.id === entry.projectId) || project}
								noProjectName={true}
								description={getDescriptionField(entry, index, hasDescriptionError)}
								hasDescriptionError={hasDescriptionError}
								quantity={getQuantityField(entry, index)}
								unitPrice={getUnitPriceField(entry, index)}
								tax={getTaxField(entry, index)}
								discount={getDiscountField(entry, index)}
								totalPrice={Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(currency),
									Util.getInvoiceEntryAmount(entry.unitPrice, entry.quantity, 1, entry.discount, entry.tax),
									intl
								)}
								contextMenuOptions={getMenuActions(index)}
								customProjectField={
									(entry.isBeingAdded || !entry.projectId) && !isProjectInvoicing
										? getProjectDropdown(index, !entry.projectId)
										: null
								}
								isFirstRow={index === 0}
								isEntryRow={true}
								isInModal={true}
								xeroAccount={getXeroAccountDropdown(entry, index)}
								quickbooksItem={getQuickbooksItemDropdown(entry, index)}
								economicProduct={getEconomicProductDropdown(entry, index)}
								sageIntacctLocation={getSageIntacctLocationDropdown(entry, index)}
								sageIntacctAccount={getSageIntacctAccountDropdown(entry, index)}
								customHeight={invoiceEntryLineCustomHeight}
								customWidth={invoiceEntryLineCustomWidth}
								showMeatballMenu={isInvoiceLocked}
								customHoverBackground={isInvoiceLocked}
								projectNameTooltip={isInvoiceLocked}
								cy={'invoice-line-' + index}
							/>
						);
					})}
				</CustomScrollDiv>
			</InvoiceLinesTableContainer>
			<InvoiceLinesDetailsSection>
				{canEditInvoice && !invoiceExported && !invoiceExporting && (
					<AddLineButton data-userpilot={'add-entry-button'}>
						<AddButton onClick={addLine} cy={'add-line-button'} />
					</AddLineButton>
				)}
				<InvoiceDetails>
					<DetailLabelColumn>
						<DetailLabel>{formatMessage({id: 'common.subtotal'}) + ':'}</DetailLabel>
						<DetailLabel>{formatMessage({id: 'project_section.xero_modal_table_discount'}) + ':'}</DetailLabel>
						<DetailLabel>{formatMessage({id: 'common.tax'}) + ':'}</DetailLabel>
						<DetailLabel isTotal>{formatMessage({id: 'common.total_amount'}) + ':'}</DetailLabel>
					</DetailLabelColumn>
					<DetailValueColumn>
						<DetailValue>
							{Util.getFormattedNumberWithCurrency(
								Util.GetCurrencySymbol(currency),
								invoiceAmounts.subtotal,
								intl
							)}
						</DetailValue>
						<DetailValue>
							{Util.getFormattedNumberWithCurrency(
								Util.GetCurrencySymbol(currency),
								invoiceAmounts.discount,
								intl
							)}
						</DetailValue>
						<DetailValue>
							{Util.getFormattedNumberWithCurrency(Util.GetCurrencySymbol(currency), invoiceAmounts.tax, intl)}
						</DetailValue>
						<DetailValue isTotal data-cy={'invoice-entry-total'}>
							{Util.getFormattedNumberWithCurrency(Util.GetCurrencySymbol(currency), invoiceAmounts.total, intl)}
						</DetailValue>
					</DetailValueColumn>
				</InvoiceDetails>
			</InvoiceLinesDetailsSection>
		</>
	);
};

InvoiceEntriesList.propTypes = {
	canEditInvoice: PropTypes.bool,
	invoiceExported: PropTypes.bool,
	invoiceExporting: PropTypes.bool,
	lines: PropTypes.array,
	setInvoiceLines: PropTypes.func,
	projects: PropTypes.array,
	clientProjects: PropTypes.array,
	currency: PropTypes.string,
	integrationData: PropTypes.shape({
		xero: PropTypes.shape({
			enabled: PropTypes.bool,
			accounts: PropTypes.array,
		}),
		quickbooks: PropTypes.shape({
			enabled: PropTypes.bool,
			itemsAndServices: PropTypes.array,
		}),
		economics: PropTypes.shape({
			enabled: PropTypes.bool,
			products: PropTypes.array,
		}),
		sageIntacct: PropTypes.shape({
			enabled: PropTypes.bool,
			locations: PropTypes.array,
			accounts: PropTypes.array,
		}),
	}),
	setHasError: PropTypes.func,
	config: PropTypes.object,
	invoice: PropTypes.object,
	company: PropTypes.object,
};

const invoiceEntriesListQuery = graphql`
	query invoiceEntriesList_Query(
		$searchQuery: TaskSearchQueryType
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			actualPersonId
			component(name: "invoice_entries_list")
			company {
				allProjects(first: 100000, searchQuery: $searchQuery) {
					edges {
						node {
							id
							projectPersons(first: 1000000) {
								edges {
									node {
										person {
											id
										}
										role {
											id
											name
										}
									}
								}
							}
							unInvoicedTimeRegistrations(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
							) {
								id
								person {
									id
								}
								project {
									id
								}
								role {
									id
									name
								}
								task {
									id
									name
									project {
										id
									}
									phase {
										id
										name
									}
									sprint {
										id
										name
									}
									statusColumnV2 {
										category
									}
								}
								minutesRegistered
								billableMinutesRegistered
								year
								month
								day
								price
								priceBaseCurrency
								rate
								notes
							}
							...DeprecatedProjectIndicatorJS_project
						}
					}
				}
			}
		}
	}
`;

export {invoiceEntriesListQuery};

/*
Not using a fragment container, because we use the data for bulkinvoicing without rendering this component.
If we use a fragment container, the data loaded in the fragment container won't be available for the bulk invoice entry generation
*/
export default InvoiceEntriesList;
