import React, {useState} from 'react';
import {Checkbox, FlexContainer, FlexItem, FlexRow, Heading, Text} from '@forecast-it/design-system';
import Logo, {LOGO_SIZE} from '../forecast-app/shared/components/logo/Logo';

import {Content, Page, PageContent} from './sage-signup-flow/Foreast.styled';
import {WideButton} from './sage-signup-flow/Sage.styled';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {useHistory, withRouter} from 'react-router-dom';

import AcceptTermsMutation from '../mutations/AcceptTermsMutation';
import {CommitMutation} from '../mutations/ts/CommitMutation';
import {TERMS_VERSION} from './signup_flow';
import {createFragmentContainer, graphql} from 'react-relay';

const AcceptTerms = ({viewer}) => {
	const [termsAccepted, setTermsAccepted] = useState(viewer.hasAcceptedTerms);
	const {formatMessage} = useIntl();
	const history = useHistory();

	const redirectToNextPath = () => {
		history.push(history.location?.state?.nextPathname || '/');
	};

	if (viewer.hasAcceptedTerms) {
		redirectToNextPath();
	}
	const acceptTerms = () => {
		if (termsAccepted) {
			CommitMutation(
				AcceptTermsMutation,
				{personId: viewer.actualPersonId, termsVersionAccepted: TERMS_VERSION},
				redirectToNextPath
			);
		}
	};
	return (
		<Page>
			<Content alignItems="center" justifyContent="center">
				<Logo size={LOGO_SIZE.MEDIUM} />
				<FlexContainer>
					<PageContent gap="xl">
						<Heading size="3"> Accept Terms</Heading>

						<FlexRow gap="l" alignItems="center">
							<FlexItem>
								<Text>
									<FormattedHTMLMessage id="signup.terms.and.conditions.link" />
								</Text>
							</FlexItem>
						</FlexRow>
						<FlexRow gap={'s'}>
							<Checkbox
								data-cy="signup-flow-terms-chkbox"
								checked={termsAccepted}
								onChange={() => setTermsAccepted(!termsAccepted)}
							/>
							<Text>Accept terms</Text>
						</FlexRow>
						<FlexRow gap={'s'}>
							<WideButton onClick={acceptTerms}>{formatMessage({id: 'common.get_started'})}</WideButton>
						</FlexRow>
					</PageContent>
				</FlexContainer>
			</Content>
		</Page>
	);
};

const AcceptTermsQuery = graphql`
	query AcceptTerms_Query {
		viewer {
			actualPersonId
			component(name: "accept_terms")
			...AcceptTerms_viewer
		}
	}
`;

export {AcceptTermsQuery};

export default withRouter(
	createFragmentContainer(AcceptTerms, {
		viewer: graphql`
			fragment AcceptTerms_viewer on Viewer {
				id
				actualPersonId
				hasAcceptedTerms
			}
		`,
	})
);
