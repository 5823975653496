import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import GenericModal, {MODAL_WIDTH} from '../../modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import AssignMultipleSkillsToSkillCategoryMutation from '../../../mutations/assign_multiple_skills_to_skill_category_mutation';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import {Field, ModalBody} from '../../../forecast-app/shared/components/modals/Modal.styled';

const MoveMultipleSkillsToCategoryModal = ({skillIds, company, closeModal}) => {
	const {formatMessage} = useIntl();
	const [selectedSkillCategory, setSelectedSkillCategory] = useState(null);

	const bulkMoveSkillsToCategory = () => {
		Util.CommitMutation(
			AssignMultipleSkillsToSkillCategoryMutation,
			{
				skillIds: skillIds,
				categoryId: selectedSkillCategory.value,
			},
			() => {
				createToast({
					duration: 5000,
					message: formatMessage(
						{id: 'settings_skills.skills_moved_to_skill_category'},
						{skillCount: skillIds.size, skillCategory: selectedSkillCategory.label}
					),
				});
			}
		);
	};

	const skillCategories = company.skillCategories.edges.map(skillCategory => skillCategory.node);
	const skillCategoryOptions = skillCategories.map(skillCategory => ({value: skillCategory.id, label: skillCategory.name}));
	skillCategoryOptions.push({value: null, label: formatMessage({id: 'settings_skills.no_category'})});

	const onSelectCategory = option => {
		setSelectedSkillCategory(option);
	};

	return (
		<GenericModal
			closeModal={closeModal}
			modalWidth={MODAL_WIDTH.SMALL}
			headerText={formatMessage({id: 'settings_skills.move_skills_to_category_title'}, {skillCount: skillIds.size})}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					cy: 'button-cancel',
				},
				{
					text: formatMessage({id: 'common.move'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: bulkMoveSkillsToCategory,
					disabled: !selectedSkillCategory,
					cy: 'button-move',
				},
			]}
			content={
				<ModalBody>
					<Field>
						<DropdownV2
							options={skillCategoryOptions}
							onChange={onSelectCategory}
							value={selectedSkillCategory?.value}
							label={formatMessage({id: 'settings_skills.category'})}
							placeholder={formatMessage({id: 'settings_skills.skills_category_placeholder'})}
							inputName={'skill-category-input'}
							inputCy={'skill-category'}
							listDataCy={'skill-category'}
						/>
					</Field>
				</ModalBody>
			}
		/>
	);
};

const moveMultipleSkillsToCategoryModalQuery = graphql`
	query MoveMultipleSkillsToCategoryModal_Query {
		viewer {
			actualPersonId
			component(name: "move_multiple_skills_to_category_modal")
			company {
				...MoveMultipleSkillsToCategoryModal_company
			}
		}
	}
`;

export {moveMultipleSkillsToCategoryModalQuery};

export default createFragmentContainer(MoveMultipleSkillsToCategoryModal, {
	company: graphql`
		fragment MoveMultipleSkillsToCategoryModal_company on Company {
			id
			skillCategories(first: 10000) @connection(key: "Company_skillCategories") {
				edges {
					node {
						id
						name
					}
				}
			}
		}
	`,
});
