import React from 'react';
import {
	DeprecatedContentContainer as ContentContainer,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
	DeprecatedLink as Link,
} from '@forecast-it/design-system';
import {useProjectProgramFinancials} from '../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {ExpenseProgramLogic} from './ExpenseProgramLogic';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {useHistory} from 'react-router-dom';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';

const renderWarningMessage = (title, messagePart2, theProgramName, formatMessage) => {
	return (
		<FlexColumn gap={'s'}>
			<Text tyle={'label'}>
				{formatMessage({
					id: title,
				})}
			</Text>
			<Text type={'small'}>
				{theProgramName ? (
					<FormattedHTMLMessage
						id={'program.project_program_rights_warning_part1'}
						values={{programName: theProgramName}}
					/>
				) : (
					<FormattedHTMLMessage id={'program.project_program_rights_warning_part1_no_program_name'} />
				)}
				<FormattedHTMLMessage id={messagePart2} />
			</Text>
		</FlexColumn>
	);
};

const renderWarningMessageAsOneMessage = (
	title,
	fullMessage,
	theProgramName,
	programPrefix,
	formatMessage,
	history,
	closeModal
) => {
	return (
		<FlexColumn gap={'s'}>
			<Text tyle={'label'}>
				{formatMessage({
					id: title,
				})}
			</Text>
			<Text type={'small'}>
				<FormattedHTMLMessage id={fullMessage} values={{programName: theProgramName}} />
			</Text>
			{programPrefix && (
				<Text type={'label'} variant={'m'} color={'primary'}>
					<Link
						target="_blank"
						rel="noopener noreferrer"
						style={{cursor: 'pointer'}}
						onClick={() => {
							trackEvent('Program Budget Settings Link', 'Clicked', {location: 'From Expense Item Modal'});
							history.push(`${ProgramUtil.programLink()}/${programPrefix}/budget/edit`);
							closeModal();
						}}
					>
						{formatMessage({id: 'common.got_to_settings'})}
					</Link>
				</Text>
			)}
		</FlexColumn>
	);
};

const getErrorMessage = (formatMessage, history, programName, programPrefix, canManageProgram, errorCode, closeModal) => {
	switch (errorCode) {
		case ExpenseProgramLogic.ERROR.NON_OWNER_UNAPPROVED_EXPENSE_CANNOT_APPROVE:
			trackEvent('Edit Expense Item Modal', 'Shown', {warning: 'Non Owner Unapproved Expense Cannot Approve'});
			return renderWarningMessage(
				'program.project_program_rights_approved_expense_cannot_approve.title',
				'program.project_program_rights_warning_part2_approve_expenses',
				programName,
				formatMessage
			);

		case ExpenseProgramLogic.ERROR.NON_OWNER_APPROVED_EXPENSE_CANNOT_EDIT:
			trackEvent('Edit Expense Item Modal', 'Shown', {warning: 'Non Owner Approved Expense Cannot Edit'});
			return renderWarningMessage(
				'program.project_program_rights_approved_expense_cannot_edit.title',
				'program.project_program_rights_approved_expense_cannot_edit.action',
				programName,
				formatMessage
			);
		case ExpenseProgramLogic.ERROR.EXPENSE_OVERFLOW:
			return programName
				? renderWarningMessageAsOneMessage(
						'project_settings.capped_revenue_program_cap_exceeded.header',
						canManageProgram
							? 'project_budget.expense.program_cap_exceeded_program_owner'
							: 'project_budget.expense.program_cap_exceeded_non_owner',
						programName,
						programPrefix,
						formatMessage,
						history,
						closeModal
				  )
				: null;
		case ExpenseProgramLogic.ERROR.LOCKED_PROGRAM_CAP:
			return programName
				? renderWarningMessageAsOneMessage(
						'Program Revenue Cap Locked',
						canManageProgram
							? 'project_budget.expense.locked_program_revenue_cap_program_owner'
							: 'project_budget.expense.locked_program_revenue_cap_non_owner',
						programName,
						programPrefix,
						formatMessage,
						history,
						closeModal
				  )
				: null;
	}
	return null;
};

export const ExpenseWarnings = ({
	companyProjectId,
	actualPersonId,
	approved,
	billable,
	isCreate,
	expenseTotalAmount,
	initialExpenseAmount,
	budgetOverflowError,
	showLockedRevenueError,
	partOfFixedPrice,
	closeModal,
}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();
	const {getExpenseProgramLogic, getProgramBudgetInfo} = useProjectProgramFinancials(companyProjectId);

	let errorMessage = null;

	if (companyProjectId) {
		const {programName, programPrefix, canManageProgram} = getProgramBudgetInfo(actualPersonId);
		let expenseAmountDifference = expenseTotalAmount;

		if (!isCreate) {
			expenseAmountDifference = expenseTotalAmount - initialExpenseAmount;
		}
		const expenseLogic = getExpenseProgramLogic(
			actualPersonId,
			approved,
			billable,
			isCreate,
			expenseAmountDifference,
			partOfFixedPrice,
			false
		);
		errorMessage = getErrorMessage(
			formatMessage,
			history,
			programName,
			canManageProgram ? programPrefix : '',
			canManageProgram,
			showLockedRevenueError
				? ExpenseProgramLogic.ERROR.LOCKED_PROGRAM_CAP
				: budgetOverflowError
				? ExpenseProgramLogic.ERROR.EXPENSE_OVERFLOW
				: expenseLogic.getErrorCode(), // this is a workaround for some class / component based state update issues.
			closeModal
		);
	}

	return (
		<div
			style={{
				marginTop: theme.spacing.m,
				overflow: 'hidden',
				transition: 'max-height 2s ease-out',
				height: 'auto',
				maxHeight: errorMessage ? '600px' : '0',
			}}
		>
			{errorMessage && <ContentContainer padding={theme.spacing.s}>{errorMessage}</ContentContainer>}
		</div>
	);
};
