/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type InvoiceFilterButton_project$ref = any;
type ProjectInvoicingTable_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectInvoicingTableSection_project$ref: FragmentReference;
declare export opaque type ProjectInvoicingTableSection_project$fragmentType: ProjectInvoicingTableSection_project$ref;
export type ProjectInvoicingTableSection_project = {|
  +id: string,
  +companyProjectId: ?number,
  +invoices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyInvoiceId: ?number,
      |}
    |}>
  |},
  +$fragmentRefs: ProjectInvoicingTable_project$ref & InvoiceFilterButton_project$ref,
  +$refType: ProjectInvoicingTableSection_project$ref,
|};
export type ProjectInvoicingTableSection_project$data = ProjectInvoicingTableSection_project;
export type ProjectInvoicingTableSection_project$key = {
  +$data?: ProjectInvoicingTableSection_project$data,
  +$fragmentRefs: ProjectInvoicingTableSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectInvoicingTableSection_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyProjectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceTypeConnection",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyInvoiceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectInvoicingTable_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceFilterButton_project"
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '019e78516807ab80d6a8f47784ff4ece';

module.exports = node;
