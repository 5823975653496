import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import GenericModal from './generic_modal';
import styled from 'styled-components';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';

const updateFixPriceModal = ({closeModal, project, callback}) => {
	const {formatMessage} = useIntl();

	const content = (
		<StyledModalContent>
			<FormattedHTMLMessage id={'project_budget.fixed_price_update_html_message'} />
		</StyledModalContent>
	);

	const confirmModal = () => {
		closeModal();

		showModal({
			type: MODAL_TYPE.CONFIRM_UPDATE_FIXED_PRICE,
			project,
			callback,
		});
	};

	return (
		<GenericModal
			closeModal={closeModal}
			className="update-fixed-price-modal-budget"
			buttons={[
				{
					text: formatMessage({id: 'common.later'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.update_now'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					preventDefaultClose: true,
					callback: confirmModal,
					cy: 'next-button',
				},
			]}
			headerText={formatMessage({
				id: 'project_budget.new_fixed_price_logic_with_rev_rec',
			})}
			content={content}
		/>
	);
};

const StyledModalContent = styled.div`
	width: 500px;
	font-size: 13px;
`;

export default updateFixPriceModal;
