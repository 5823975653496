import React from 'react';
import { Button } from '@forecast-it/design-system';
import Util from '../../../../forecast-app/shared/util/util';
import CreateOutlookVerificationKeyMutation from '../../../../mutations/CreateOutlookVerificationKeyMutation';
const OutlookCalendarOauthButton = ({ calendarTokenError, page }) => {
    const handleLinkClick = () => {
        Util.CommitMutation(CreateOutlookVerificationKeyMutation, null, ({ createOutlookVerificationKey }) => {
            const authUrl = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?` +
                `client_id=${'b53a9673-8820-4329-ba9f-543dc6dcf5f6'}` +
                `&response_type=code` +
                `&redirect_uri=${encodeURIComponent(`${process.env.API_URL}/outlook/oauth/${page}`)}` +
                `&response_mode=query` +
                `&scope=${encodeURIComponent('User.Read offline_access Calendars.Read.Shared openid profile')}` +
                `&state=${createOutlookVerificationKey.key}`;
            // Redirect the user to the authorization URL
            window.location.href = authUrl;
        });
    };
    return (React.createElement(Button, { title: 'Outlook Calendar Auth', emphasis: 'medium', onClick: () => handleLinkClick() }, calendarTokenError ? 'Re-authenticate' : 'Link Outlook Calendar'));
};
export default OutlookCalendarOauthButton;
