import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FlexContainer, Heading, TextField, FlexColumn, Icon, Text, FlexRow, Alert, Link as LinkComponent, } from '@forecast-it/design-system';
import { Link as RouterLink } from 'react-router-dom';
import Logo, { LOGO_SIZE } from '../../forecast-app/shared/components/logo/Logo';
import { Content, Page, PageContent, WideButton } from './Sage.styled';
import Util from '../../forecast-app/shared/util/util';
import { AlertType } from '@forecast-it/design-system/dist/forecast-v2/components/data-display/alert/types';
export const SageResetPassword = ({ password, confirmPassword, onPasswordChange, onPasswordConfirmChange, onSetPassword, isKeyValid, errorMsg, }) => {
    const { formatMessage } = useIntl();
    const [validOneLetter, setValidOneLetter] = useState(false);
    const [validOneNumber, setValidOneNumber] = useState(false);
    const [validEightChars, setValidEightChars] = useState(false);
    const [validNoThreeSame, setValidNoThreeSame] = useState(true);
    const [samePasswordError, setSamePasswordError] = useState(false);
    useEffect(() => {
        setValidOneLetter(Util.validate1Letter(password));
        setValidOneNumber(Util.validate1Digit(password));
        setValidEightChars(Util.validateMin8Chars(password));
        setValidNoThreeSame(Util.validate3Identical(password));
        setSamePasswordError(false);
    }, [password]);
    useEffect(() => {
        setSamePasswordError(false);
    }, [confirmPassword]);
    const handleSetNewPassword = () => {
        if (password === confirmPassword) {
            onSetPassword();
        }
        else {
            setSamePasswordError(true);
        }
    };
    return (React.createElement(Page, null,
        React.createElement(Content, { alignItems: "center", justifyContent: "center" },
            React.createElement(Logo, { size: LOGO_SIZE.MEDIUM }),
            React.createElement(FlexContainer, null, isKeyValid ? (React.createElement(PageContent, { gap: "xl" },
                React.createElement(FlexColumn, { gap: "l" },
                    React.createElement(Heading, { size: "3" }, formatMessage({ id: 'login.set-new-password-title' })),
                    errorMsg ? React.createElement(Alert, { text: formatMessage({ id: errorMsg }), type: AlertType.ERROR }) : '',
                    React.createElement(TextField, { isPasswordField: true, label: "New Password", autoComplete: "new-password", maxLength: 64, value: password, onValueChange: onPasswordChange }),
                    React.createElement(FlexColumn, { gap: "s" },
                        React.createElement(Text, null, formatMessage({ id: 'user-settings.security.password_help.password_must_contain' })),
                        React.createElement(FlexRow, null,
                            React.createElement(Icon, { color: validOneLetter ? 'success' : 'error', icon: validOneLetter ? 'checkCircle' : 'error' }),
                            React.createElement(Text, null, formatMessage({ id: 'user-settings.security.password_help.one_letter' }))),
                        React.createElement(FlexRow, null,
                            React.createElement(Icon, { color: validOneNumber ? 'success' : 'error', icon: validOneNumber ? 'checkCircle' : 'error' }),
                            React.createElement(Text, null, formatMessage({ id: 'user-settings.security.password_help.one_number' }))),
                        React.createElement(FlexRow, null,
                            React.createElement(Icon, { color: validEightChars ? 'success' : 'error', icon: validEightChars ? 'checkCircle' : 'error' }),
                            React.createElement(Text, null, formatMessage({ id: 'user-settings.security.password_help.at_least_8' }))),
                        React.createElement(FlexRow, null,
                            React.createElement(Icon, { color: validNoThreeSame ? 'success' : 'error', icon: validNoThreeSame ? 'checkCircle' : 'error' }),
                            React.createElement(Text, null, formatMessage({ id: 'user-settings.security.password_help.max_2_identical' })))),
                    React.createElement(TextField, { isPasswordField: true, label: formatMessage({ id: 'login.confirm-password' }), autoComplete: "new-password", maxLength: 64, value: confirmPassword, onValueChange: onPasswordConfirmChange, errorText: samePasswordError
                            ? formatMessage({
                                id: 'user-settings.security.password_validation_error.PASSWORDS_DONT_MATCH',
                            })
                            : '' })),
                React.createElement(WideButton, { onClick: handleSetNewPassword }, formatMessage({ id: 'login.set-new-password-title' })))) : (React.createElement(PageContent, { gap: "l" },
                React.createElement(Heading, { size: "3" }, formatMessage({ id: 'login.reset-password' })),
                React.createElement(Alert, { text: formatMessage({ id: 'reset_password.invalid-reset-link-1' }), type: AlertType.ERROR }),
                React.createElement(RouterLink, { to: "/forgot-password", component: LinkComponent }, formatMessage({ id: 'reset_password.request_another_link' }))))))));
};
