/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SageSyncAnalysisPage_Query(
  $companyId: Int
  $analysisId: Int
  $forceRefresh: Boolean
) {
  viewer {
    actualPersonId
    component(name: "sage_sync_analysis")
    ...SageSyncAnalysisPage_viewer_2oK7PD
    id
  }
}

fragment MissionControlHeader_viewer on Viewer {
  email
  actualPersonId
  firstName
  lastName
  company {
    id
    name
  }
}

fragment SageSyncAnalysisPage_viewer_2oK7PD on Viewer {
  id
  ...MissionControlHeader_viewer
  missionControlData {
    companySyncAnalysis(companyId: $companyId, analysisId: $analysisId, forceRefresh: $forceRefresh) {
      id
      companyId
      companyName
    }
    companySyncAnalysisHistory(companyId: $companyId) {
      id
      forecastCompanyId
      syncDate
    }
    id
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "companyId",
            "type": "Int"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "analysisId",
            "type": "Int"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "forceRefresh",
            "type": "Boolean"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "sage_sync_analysis"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"sage_sync_analysis\")"
    }, v3 = {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
    }, v4 = [
        {
            "kind": "Variable",
            "name": "analysisId",
            "variableName": "analysisId"
        },
        (v3 /*: any*/),
        {
            "kind": "Variable",
            "name": "forceRefresh",
            "variableName": "forceRefresh"
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SageSyncAnalysisPage_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": (v4 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "SageSyncAnalysisPage_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SageSyncAnalysisPage_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MissionControlDataType",
                            "kind": "LinkedField",
                            "name": "missionControlData",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "MissionControlSageSyncAnalysisType",
                                    "kind": "LinkedField",
                                    "name": "companySyncAnalysis",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "companyId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "companyName",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        (v3 /*: any*/)
                                    ],
                                    "concreteType": "MissionControlSageSyncAnalysisHistoryType",
                                    "kind": "LinkedField",
                                    "name": "companySyncAnalysisHistory",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "forecastCompanyId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "syncDate",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SageSyncAnalysisPage_Query",
            "operationKind": "query",
            "text": "query SageSyncAnalysisPage_Query(\n  $companyId: Int\n  $analysisId: Int\n  $forceRefresh: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"sage_sync_analysis\")\n    ...SageSyncAnalysisPage_viewer_2oK7PD\n    id\n  }\n}\n\nfragment MissionControlHeader_viewer on Viewer {\n  email\n  actualPersonId\n  firstName\n  lastName\n  company {\n    id\n    name\n  }\n}\n\nfragment SageSyncAnalysisPage_viewer_2oK7PD on Viewer {\n  id\n  ...MissionControlHeader_viewer\n  missionControlData {\n    companySyncAnalysis(companyId: $companyId, analysisId: $analysisId, forceRefresh: $forceRefresh) {\n      id\n      companyId\n      companyName\n    }\n    companySyncAnalysisHistory(companyId: $companyId) {\n      id\n      forecastCompanyId\n      syncDate\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = '604a75d2be5e0435cb9240c30180cb6a';
export default node;
