/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SageIntacctExportTimesheetsModal_Query(
  $startDateString: String
  $endDateString: String
) {
  viewer {
    actualPersonId
    component(name: "sage_intacct_export_timesheets_modal")
    company {
      ...SageIntacctExportTimesheetsModal_company_3f0CvU
      id
    }
    id
  }
}

fragment SageIntacctExportTimesheetsModal_company_3f0CvU on Company {
  timeRegistrations(first: 10000000, startDate: $startDateString, endDate: $endDateString) {
    edges {
      node {
        id
        year
        month
        day
        minutesRegistered
        billable
        person {
          id
          fullName
          sageIntacctId
          sageIntacctLocationId
          department {
            id
            sageIntacctId
          }
        }
        notes
        project {
          id
          name
          sageProject {
            sageProjectId
            id
          }
        }
        task {
          id
          name
          sageIntacctId
          project {
            id
            name
            sageProject {
              sageProjectId
              id
            }
          }
        }
        sageIntacctTimesheetEntryRecordNumber
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "startDateString",
            "type": "String"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "endDateString",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "sage_intacct_export_timesheets_modal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"sage_intacct_export_timesheets_modal\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sageIntacctId",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "ProjectType",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v5 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "SageProjectType",
                "kind": "LinkedField",
                "name": "sageProject",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sageProjectId",
                        "storageKey": null
                    },
                    (v3 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SageIntacctExportTimesheetsModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "endDateString",
                                            "variableName": "endDateString"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "startDateString",
                                            "variableName": "startDateString"
                                        }
                                    ],
                                    "kind": "FragmentSpread",
                                    "name": "SageIntacctExportTimesheetsModal_company"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SageIntacctExportTimesheetsModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "endDate",
                                            "variableName": "endDateString"
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 10000000
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "startDate",
                                            "variableName": "startDateString"
                                        }
                                    ],
                                    "concreteType": "TimeRegTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "timeRegistrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeRegTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeRegType",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "year",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "month",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "day",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "minutesRegistered",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "billable",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Person",
                                                            "kind": "LinkedField",
                                                            "name": "person",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "fullName",
                                                                    "storageKey": null
                                                                },
                                                                (v4 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "sageIntacctLocationId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "DepartmentType",
                                                                    "kind": "LinkedField",
                                                                    "name": "department",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v4 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "notes",
                                                            "storageKey": null
                                                        },
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Task",
                                                            "kind": "LinkedField",
                                                            "name": "task",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                (v5 /*: any*/),
                                                                (v4 /*: any*/),
                                                                (v6 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "sageIntacctTimesheetEntryRecordNumber",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SageIntacctExportTimesheetsModal_Query",
            "operationKind": "query",
            "text": "query SageIntacctExportTimesheetsModal_Query(\n  $startDateString: String\n  $endDateString: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"sage_intacct_export_timesheets_modal\")\n    company {\n      ...SageIntacctExportTimesheetsModal_company_3f0CvU\n      id\n    }\n    id\n  }\n}\n\nfragment SageIntacctExportTimesheetsModal_company_3f0CvU on Company {\n  timeRegistrations(first: 10000000, startDate: $startDateString, endDate: $endDateString) {\n    edges {\n      node {\n        id\n        year\n        month\n        day\n        minutesRegistered\n        billable\n        person {\n          id\n          fullName\n          sageIntacctId\n          sageIntacctLocationId\n          department {\n            id\n            sageIntacctId\n          }\n        }\n        notes\n        project {\n          id\n          name\n          sageProject {\n            sageProjectId\n            id\n          }\n        }\n        task {\n          id\n          name\n          sageIntacctId\n          project {\n            id\n            name\n            sageProject {\n              sageProjectId\n              id\n            }\n          }\n        }\n        sageIntacctTimesheetEntryRecordNumber\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'c58d0d404a2976caa2bc803ea060ccaa';
export default node;
