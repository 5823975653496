import { ComboBox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, Label, RadioGroupHandler, SearchField, SubHeading, Text, } from '@forecast-it/design-system';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../../directApi';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import { hideLoader, showLoader } from '../../global_loader';
import Util from '../../../forecast-app/shared/util/util';
import ImportSageIntacctProjectMutation from '../../../mutations/ImportSageIntacctProjectMutation';
import { HorizontalBar } from './SageIntacctModal.styled';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 900 ? '200px' : '500px';
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: 1px solid #ccc;
`;
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const NEW_PROJECT = 'new-project';
const SageIntacctSyncProjectModal = ({ closeModal, forecastProjectId, forecastProjectName, }) => {
    const { formatMessage } = useIntl();
    const [locations, setLocations] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [projects, setProjects] = useState([]);
    const [searchString, setSearchString] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [failed, setFailed] = useState(false);
    const [importing, setImporting] = useState(false);
    const disableSyncButton = (importing || fetching || !selectedLocationId || !selectedProjectId) && selectedProjectId !== NEW_PROJECT;
    useEffect(() => {
        DirectApi.Fetch('sageIntacct/locations').then(res => {
            if (res === null || res === void 0 ? void 0 : res.locations) {
                setLocations(res.locations);
                setFetching(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetching(false);
            }
            else {
                setLocations([]);
                setFetching(false);
            }
        });
    }, []);
    const filterProjects = () => {
        if (searchString) {
            setFilteredProjects(projects.filter(tm => tm.NAME.toLowerCase().includes(searchString.toLowerCase())));
        }
        else {
            setFilteredProjects(projects);
        }
    };
    useEffect(() => {
        filterProjects();
    }, [searchString, projects]);
    const onLocationSelect = (locationId) => {
        setSelectedLocationId(locationId);
        setFetching(true);
        DirectApi.Fetch(`sageIntacct/projects/${locationId}`).then(res => {
            if (res === null || res === void 0 ? void 0 : res.projects) {
                setProjects(res.projects);
                setFetching(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetching(false);
            }
            else {
                setLocations([]);
                setFetching(false);
            }
        });
    };
    const onFilterChange = (input) => {
        setSearchString(input);
    };
    const onSyncClick = () => {
        if (selectedProjectId === NEW_PROJECT) {
            closeModal();
            showModal({
                type: MODAL_TYPE.SAGE_INTACCT_CREATE_NEW_PROJECT,
                forecastProjectId: forecastProjectId,
                forecastProjectName: forecastProjectName,
            });
        }
        else {
            setImporting(true);
            showLoader();
            Util.CommitMutation(ImportSageIntacctProjectMutation, {
                forecastProjectId: forecastProjectId,
                sageIntacctProjectId: selectedProjectId,
            }, () => {
                setImporting(false);
                hideLoader();
                closeModal();
                showModal({
                    type: MODAL_TYPE.SAGE_INTACCT_EXPORT_TASKS,
                    forecastProjectId: forecastProjectId,
                    sageIntacctProjectId: selectedProjectId,
                    isPartOfProjectSyncFlow: true,
                });
            });
        }
    };
    return (React.createElement(CreativeModal, { title: 'Sync Project with Sage Intacct', size: 'l', closeModal: closeModal, creativeButtonText: 'Sync Project with Sage Intacct', cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onSyncClick, onCancelHandler: closeModal, disabled: disableSyncButton, replicateDesignSystem: true },
        React.createElement(ComboBox, { label: "Project Location", "data-cy": "combobox-location", defaultItems: locations, value: selectedLocationId, onChange: onLocationSelect, width: ComboBox.WIDTH.FLEX, disabled: fetching }, (location) => (React.createElement(ComboBox.Item, { key: location.LOCATIONID, textValue: location.NAME },
            React.createElement(Text, null, location.NAME)))),
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Label, null, "Project"),
            React.createElement(Text, { color: Text.color.medium }, "Please select the project you wish to sync with Sage Intacct"),
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, value: searchString, width: "600px", isDisabled: !selectedLocationId })),
        React.createElement(RadioGroupHandler, { onValueChange: setSelectedProjectId, value: selectedProjectId },
            React.createElement(ListItem, null,
                React.createElement(FlexRow, { gap: 'xl' },
                    React.createElement(RadioGroupHandler.Item, { value: NEW_PROJECT }),
                    React.createElement(SubHeading, { size: '2' }, 'New project'))),
            React.createElement(ListWrapper, null, fetching ? (React.createElement(InlineLoader, null)) : (React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
                React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredProjects &&
                    filteredProjects.map(proj => {
                        return (React.createElement(ListItem, { key: 'project-' + proj.PROJECTID },
                            React.createElement(FlexRow, { gap: 'xl' },
                                React.createElement(RadioGroupHandler.Item, { value: proj.PROJECTID }),
                                React.createElement(Text, { size: '2' }, proj.NAME))));
                    })))))),
        React.createElement(HorizontalBar, null)));
};
export default SageIntacctSyncProjectModal;
