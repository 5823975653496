import React from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {Button, Table} from 'web-components';
import {idFromGlobalId} from '../../forecast-app/shared/util/GlobalIdUtil';
import Util from '../../forecast-app/shared/util/util';
import DeleteBambooDuplicates from '../../mutations/mission_control/delete_bamboo_duplicates';

const bambooDuplicates = ({relay, history, viewer}) => {
	const bambooDuplicates = {rows: viewer.missionControlData.bambooDuplicates};

	const buttonClick = (companyId, isDryRun) => {
		Util.CommitMutation(DeleteBambooDuplicates, {
			companyId: companyId,
			isDryRun: isDryRun,
		});
	};

	return (
		<div className="mission-control-bamboo-duplicates">
			<Table>
				<Table.Header>
					<Table.HeaderColumn usePadding align="left" visible width="EXTRA_LARGE">
						<Table.HeaderColumn.Title>{'COMPANY ID'}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="left" visible width="EXTRA_LARGE">
						<Table.HeaderColumn.Title>{'COMPANY NAME'}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="left" visible width="EXTRA_LARGE">
						<Table.HeaderColumn.Title>{'DUPLICATE COUNT'}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="left" visible width="MEDIUM">
						<Table.HeaderColumn.Title />
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="left" visible width="MEDIUM">
						<Table.HeaderColumn.Title />
					</Table.HeaderColumn>
				</Table.Header>
				<Table.Rows data={bambooDuplicates} canExpand={false} hasHeader>
					{({rowData, tableColumnsProps}) => {
						return (
							<Table.Row {...tableColumnsProps}>
								<Table.Column>{idFromGlobalId(rowData.id)}</Table.Column>
								<Table.Column>{rowData.company}</Table.Column>
								<Table.Column>{rowData.duplicateCount}</Table.Column>
								<Table.Column>
									<Button
										size={Button.SIZE.SMALL}
										variant={Button.VARIANT.PURPLE_FILLED}
										onClick={() => buttonClick(idFromGlobalId(rowData.id), false)}
									>
										Delete Duplicates
									</Button>
								</Table.Column>
								<Table.Column>
									<Button
										size={Button.SIZE.SMALL}
										variant={Button.VARIANT.PURPLE_FILLED}
										onClick={() => buttonClick(idFromGlobalId(rowData.id), true)}
									>
										Dry Run
									</Button>
								</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
			</Table>
		</div>
	);
};

const bambooDuplicatesQuery = graphql`
	query bambooDuplicates_Query {
		viewer {
			actualPersonId
			component(name: "control_bamboo_duplicates")
			...bambooDuplicates_viewer
		}
	}
`;

export {bambooDuplicatesQuery};

export default withRouter(
	createRefetchContainer(
		bambooDuplicates,
		{
			viewer: graphql`
				fragment bambooDuplicates_viewer on Viewer {
					id
					missionControlData {
						id
						bambooDuplicates {
							id
							company
							duplicateCount
						}
					}
				}
			`,
		},
		graphql`
			query bambooDuplicatesRefetchQuery {
				viewer {
					...bambooDuplicates_viewer
				}
			}
		`
	)
);
