import React, { useState } from 'react';
import { Label, FlexRow, Modal, Text, FlexColumn, RadioGroupHandler, Frame } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import { PERIOD_BUDGET_TYPE } from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
const RevertRolloverModal = ({ rollovers, periodBudgetType, currency, closeModal, unlockPeriodMutations, isBulkUnlock, }) => {
    const intl = useIntl();
    const { formatMessage, formatNumber } = intl;
    const rolloverIdsToRevert = rollovers.map(rollover => rollover.id);
    const subtractedRollovers = rollovers.filter(rollover => rollover.amount && rollover.amount < 0);
    const addedRollovers = rollovers.filter(rollover => rollover.amount && rollover.amount >= 0);
    const hasSubtractedRollovers = subtractedRollovers.length > 0;
    const hasAddedRollovers = addedRollovers.length > 0;
    const isFixedPrice = periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE;
    const addedRolloverPeriods = [];
    const subtractedRolloverPeriods = [];
    if (isBulkUnlock) {
        addedRollovers.forEach(rollover => {
            const periodFrom = rollover.periodFrom;
            if (periodFrom && !addedRolloverPeriods.some(period => period.id === periodFrom.id)) {
                addedRolloverPeriods.push(periodFrom);
            }
        });
        subtractedRollovers.forEach(rollover => {
            const periodFrom = rollover.periodFrom;
            if (periodFrom && !subtractedRolloverPeriods.some(period => period.id === periodFrom.id)) {
                subtractedRolloverPeriods.push(periodFrom);
            }
        });
    }
    const [revertRollovers, setRevertRollovers] = useState('false');
    const saveChanges = () => {
        closeModal();
        unlockPeriodMutations(revertRollovers === 'true' ? rolloverIdsToRevert : undefined);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.MEDIUM, onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({
            id: hasSubtractedRollovers
                ? hasAddedRollovers
                    ? 'retainer.revert_rollover_modal.add_and_subtract_headline'
                    : 'retainer.revert_rollover_modal.subtract_headline'
                : 'retainer.revert_rollover_modal.add_headline',
        })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'l' },
                hasAddedRollovers && (React.createElement(FlexColumn, { gap: 's' },
                    React.createElement(Text, null, formatMessage({
                        id: isFixedPrice
                            ? isBulkUnlock
                                ? 'retainer.revert_rollover_modal.add_info_price_bulk'
                                : 'retainer.revert_rollover_modal.add_info_price'
                            : isBulkUnlock
                                ? 'retainer.revert_rollover_modal.add_info_hours_bulk'
                                : 'retainer.revert_rollover_modal.add_info_hours',
                    })),
                    React.createElement(Frame, null,
                        React.createElement(FlexColumn, { gap: 'xxs' }, isBulkUnlock
                            ? addedRolloverPeriods.map(period => React.createElement(Text, null, period.name))
                            : addedRollovers.map(rollover => {
                                return rollover.amount && rollover.periodTo ? (React.createElement(Text, null, `${rollover.periodTo.name} (${isFixedPrice
                                    ? Util.getFormattedCurrencyValue(Util.GetCurrencySymbol(currency), formatNumber(rollover.amount, {
                                        format: 'always_two_decimal',
                                    }))
                                    : Util.convertMinutesToFullHour(rollover.amount * 60, intl)})`)) : null;
                            }))))),
                hasSubtractedRollovers && (React.createElement(FlexColumn, { gap: 's' },
                    React.createElement(Text, null, formatMessage({
                        id: isFixedPrice
                            ? isBulkUnlock
                                ? 'retainer.revert_rollover_modal.subtract_info_price_bulk'
                                : 'retainer.revert_rollover_modal.subtract_info_price'
                            : isBulkUnlock
                                ? 'retainer.revert_rollover_modal.subtract_info_hours_bulk'
                                : 'retainer.revert_rollover_modal.subtract_info_hours',
                    })),
                    React.createElement(Frame, null,
                        React.createElement(FlexColumn, { gap: 'xxs' }, isBulkUnlock
                            ? subtractedRolloverPeriods.map(period => React.createElement(Text, null, period.name))
                            : subtractedRollovers.map(rollover => {
                                return rollover.amount && rollover.periodTo ? (React.createElement(Text, null, `${rollover.periodTo.name} (${isFixedPrice
                                    ? Util.getFormattedCurrencyValue(Util.GetCurrencySymbol(currency), formatNumber(Math.abs(rollover.amount), {
                                        format: 'always_two_decimal',
                                    }))
                                    : Util.convertMinutesToFullHour(Math.abs(rollover.amount) * 60, intl)})`)) : null;
                            }))))),
                React.createElement(FlexColumn, { gap: 'xxs' },
                    React.createElement(Label, null, formatMessage({
                        id: hasSubtractedRollovers
                            ? hasAddedRollovers
                                ? isFixedPrice
                                    ? isBulkUnlock
                                        ? 'retainer.revert_rollover_modal.revert_add_and_subtract_headline_price_bulk'
                                        : 'retainer.revert_rollover_modal.revert_add_and_subtract_headline_price'
                                    : isBulkUnlock
                                        ? 'retainer.revert_rollover_modal.revert_add_and_subtract_headline_hours_bulk'
                                        : 'retainer.revert_rollover_modal.revert_add_and_subtract_headline_hours'
                                : isFixedPrice
                                    ? isBulkUnlock
                                        ? 'retainer.revert_rollover_modal.revert_subtract_headline_price_bulk'
                                        : 'retainer.revert_rollover_modal.revert_subtract_headline_price'
                                    : isBulkUnlock
                                        ? 'retainer.revert_rollover_modal.revert_subtract_headline_hours_bulk'
                                        : 'retainer.revert_rollover_modal.revert_subtract_headline_hours'
                            : isFixedPrice
                                ? isBulkUnlock
                                    ? 'retainer.revert_rollover_modal.revert_add_headline_price_bulk'
                                    : 'retainer.revert_rollover_modal.revert_add_headline_price'
                                : isBulkUnlock
                                    ? 'retainer.revert_rollover_modal.revert_add_headline_hours_bulk'
                                    : 'retainer.revert_rollover_modal.revert_add_headline_hours',
                    })),
                    React.createElement(Text, { color: 'medium' }, formatMessage({
                        id: isBulkUnlock
                            ? 'retainer.revert_rollover_modal.revert_info_bulk'
                            : hasSubtractedRollovers
                                ? hasAddedRollovers
                                    ? isFixedPrice
                                        ? 'retainer.revert_rollover_modal.revert_add_and_subtract_info_price'
                                        : 'retainer.revert_rollover_modal.revert_add_and_subtract_info_hours'
                                    : isFixedPrice
                                        ? 'retainer.revert_rollover_modal.revert_subtract_info_price'
                                        : 'retainer.revert_rollover_modal.revert_subtract_info_hours'
                                : isFixedPrice
                                    ? 'retainer.revert_rollover_modal.revert_add_info_price'
                                    : 'retainer.revert_rollover_modal.revert_add_info_hours',
                    }))),
                React.createElement(RadioGroupHandler, { onValueChange: setRevertRollovers, value: revertRollovers },
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: 'true' }),
                        React.createElement(Text, null, formatMessage({ id: 'retainer.revert_rollover_modal.revert_radio' }))),
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: 'false' }),
                        React.createElement(Text, null, formatMessage({ id: 'retainer.revert_rollover_modal.dont_revert_radio' })))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges, disabled: revertRollovers === null }, formatMessage({ id: 'common.confirm' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.cancel' })))));
};
export default RevertRolloverModal;
