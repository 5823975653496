var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DirectApi from '../../../directApi';
import Util from '../../../forecast-app/shared/util/util';
import CreateEconomicInvoiceMutation from '../../../mutations/create_economic_invoice_mutation';
import { BUTTON_STYLE, BUTTON_COLOR } from '../../../constants';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import { showModal, MODAL_TYPE } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
const getEconomicProducts = () => __awaiter(void 0, void 0, void 0, function* () {
    return DirectApi.Fetch(`economic/products`).then(json => json.list.map(item => ({
        value: item.id,
        label: item.name,
        description: item.description,
        unitPrice: item.unitPrice,
        taxable: item.taxable,
    })));
});
export const fetchEconomicProducts = (setProducts) => __awaiter(void 0, void 0, void 0, function* () {
    const products = yield getEconomicProducts();
    setProducts(products);
});
const onExportInvoiceSuccess = (result, intl, onSuccess) => {
    const { formatMessage } = intl;
    onSuccess();
    const { createEconomicInvoice } = result || {};
    if (createEconomicInvoice) {
        const { success, errorMessage } = createEconomicInvoice;
        if (success) {
            createToast({ duration: 5000, message: formatMessage({ id: 'integrations.economic.invoice_created' }) });
        }
        else {
            showModal({
                type: MODAL_TYPE.WARNING,
                warningMessageId: 'invoicing.not_exported',
                warningInformation: [errorMessage || 'The invoice could not be exported.'],
                buttons: [
                    {
                        text: formatMessage({ id: 'common.ok' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.WHITE,
                    },
                ],
            });
        }
    }
};
export const exportInvoiceToEconomics = ({ invoice, billTo, invoiceRows, intl, onSuccess, onError, }) => {
    const entryMap = invoiceRows.map(row => ({ entryId: row.id, productId: row.economicProduct }));
    const input = {
        invoiceId: invoice.id,
        billToId: billTo.id,
        economicClientId: billTo === null || billTo === void 0 ? void 0 : billTo.externalId,
        entryMap,
    };
    Util.CommitMutation(CreateEconomicInvoiceMutation, input, result => onExportInvoiceSuccess(result, intl, onSuccess), true, null, onError);
};
