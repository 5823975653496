/**
 * Login container.
 */
import React, {Component} from 'react';
import RequestResetPasswordMutation from '../mutations/request_reset_password_mutation';
import {BUTTON_COLOR, BUTTON_STYLE, emailRegEx} from '../constants';
import * as tracking from '../tracking';
import {FormattedMessage, injectIntl} from 'react-intl';
import Util from '../forecast-app/shared/util/util';
import Button from '../forecast-app/shared/components/buttons/button/button';
import {Avatar, ImagesWrapper} from '../forecast-app/shared/components/empty-states/empty_state_styled';
import enter_email_animation from '../images/empty-states/empty02_retainer_anim.svg';
import enter_email_background from '../images/empty-states/empty02_retainer_bg.svg';
import confirmation_animation from '../images/empty-states/empty05_scoping2_anim.svg';
import confirmation_background from '../images/empty-states/empty05_scoping2_bg.svg';
import {Link} from 'react-router-dom';
import {DatacenterDropdown} from './DatacenterDropdown';
import DirectApi from '../directApi';
import {trackPage, unregisterPageInfo} from '../tracking/amplitude/TrackingV2';
import {isSageSpecificUrl} from '../forecast-app/shared/util/UrlUtil';
import {SageForgotPassword} from './sage-signup-flow/SageForgotPassword';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMsg: null,
			isSent: false,
			email: '',
			datacenter: DirectApi.getDatacenter(),
		};
		this.props.setLocaleFromBrowser();

		this.superPropertyChecksum = trackPage('Forgot Password');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('forgot-password');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		const email = this.state.email.trim();
		if (!email.length) {
			this.setState({errorMsg: 'Field cannot be empty'});
			return;
		}
		const regEx = new RegExp(emailRegEx);
		if (!regEx.test(email)) {
			this.setState({errorMsg: 'Invalid email format'});
			return;
		}

		var onSuccess = response => {
			if (response.requestResetPassword.success) {
				this.setState({isSent: true});
			} else {
				if (!response.requestResetPassword.errors) return;
				this.setState({
					errorMsg: this.props.intl.formatMessage({id: 'reset_password.' + response.requestResetPassword.errors[0]}),
				});
			}
		};
		Util.CommitMutation(
			RequestResetPasswordMutation,
			{
				email: email,
			},
			onSuccess
		);
	}

	handleEmailChange(e) {
		this.setState({email: e.target ? e.target.value : e});
	}

	setDatacenter(e) {
		this.setState({datacenter: e});
	}

	render() {
		if (isSageSpecificUrl()) {
			return (
				<SageForgotPassword
					email={this.state.email}
					onEmailChange={this.handleEmailChange.bind(this)}
					errorMessage={this.state.errorMsg}
					onReset={this.handleSubmit.bind(this)}
					isSent={this.state.isSent}
				/>
			);
		} else {
			const {formatMessage} = this.props.intl;
			return (
				<>
					<div className="content">
						{this.state.isSent ? (
							<>
								<h1 className="confirmation-text">{formatMessage({id: 'thank_you.header_top'})}</h1>
								<span className="confirmation-text">
									{formatMessage({id: 'login.reset-password-instructions'}, {email: this.state.email})}
								</span>
								<Link to="/login" data-cy="login-page-link">
									<FormattedMessage id="login.sign-in" />
								</Link>
							</>
						) : (
							<form onSubmit={this.handleSubmit.bind(this)}>
								<h1>{formatMessage({id: 'login.reset-password'})}</h1>
								<label htmlFor="email">{formatMessage({id: 'common.email'})}</label>
								<input
									type="text"
									id="email"
									defaultValue=""
									placeholder={formatMessage({id: 'login.enter-email-address'})}
									onChange={this.handleEmailChange.bind(this)}
								/>
								{this.state.errorMsg ? <div className="error">{this.state.errorMsg}</div> : ''}
								<DatacenterDropdown
									datacenter={this.state.datacenter}
									setDatacenter={this.setDatacenter.bind(this)}
								/>
								<div className="buttons">
									<Link to="/login" data-cy="back-to-login-page-link">
										<FormattedMessage id="login.back-to-sign-in" />
									</Link>
									<Button
										id="reset-password-submit-button"
										text={formatMessage({id: 'login.reset-password'})}
										cy="button-reset-password-submit"
										buttonStyle={BUTTON_STYLE.FILLED}
										colorTheme={BUTTON_COLOR.PINK}
										onClick={this.handleSubmit.bind(this)}
										uppercase={false}
										roundedBorders={true}
									/>
								</div>
							</form>
						)}
					</div>

					<div className="animation-container forgot-password-animation">
						<ImagesWrapper
							size="small"
							position="absolute"
							right={this.state.isSent ? 'initial' : '25%'}
							left={this.state.isSent ? '7.5%' : 'initial'}
						>
							<Avatar size="small" image={this.state.isSent ? confirmation_background : enter_email_background} />
							<Avatar size="small" image={this.state.isSent ? confirmation_animation : enter_email_animation} />
						</ImagesWrapper>
					</div>
				</>
			);
		}
	}
}

export default injectIntl(ForgotPassword);
