import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';

export const getBaseLevelTableHeader = (isLockedTable, tableHasAssistCallback) => {
	const intl = useIntl();
	return (
		<Table.Header backgroundColor={'#f5f5f8'}>
			<Table.HeaderColumn align="left" visible={true} width="LARGE">
				<Table.HeaderColumn.Title>{isLockedTable ? 'Locked Insight' : 'Insight'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn flex={1} align="left" visible={true} width="LARGE">
				<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.details'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				flex={tableHasAssistCallback ? 1 : 0}
				align="right"
				visible={true}
				width={tableHasAssistCallback || isLockedTable ? 'LARGE' : 'MEDIUM_SMALL'}
			>
				<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.action'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
		</Table.Header>
	);
};
