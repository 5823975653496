import {takeRight} from 'lodash';
import moment from 'moment';

export const getDateArray = (startDate, stopDate) => {
	var dateArray = [];
	var currentDate = moment(startDate);
	var endDate = moment(stopDate);
	while (currentDate <= endDate) {
		dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
		currentDate = moment(currentDate).add(1, 'days');
	}
	return dateArray;
};

export const convertToMapPerDay = perDayBreakdown => {
	const perDayMap = new Map();
	perDayBreakdown.forEach(predictedScopePerDay => {
		perDayMap.set(predictedScopePerDay.date, predictedScopePerDay.value);
	});

	return perDayMap;
};

export const accumulateMapPerDay = (perDayMap, dateArray, initialValue = 0) => {
	const accPerDayMap = new Map();
	let accumulator = initialValue;
	dateArray.forEach(date => {
		accumulator += perDayMap.get(date) || 0;
		accPerDayMap.set(date, accumulator);
	});

	return accPerDayMap;
};

export const convertStatsToMapPerDay = statsBreakdown => {
	const statsMap = new Map();
	if (statsBreakdown) {
		statsBreakdown.forEach(statPerDay => {
			statsMap.set(statPerDay.date, {
				totalDoneEstimateMinutes: statPerDay.totalDoneEstimateMinutes,
				totalEstimateMinutes: statPerDay.totalEstimateMinutes,
				totalBaselineEstimateMinutes: statPerDay.totalBaselineEstimateMinutes,
			});
		});
	}
	return statsMap;
};

export const getPredictedEndDateWarningVariant = (predictedEndDate, projectDeadline) => {
	const bufferedProjectDeadline = projectDeadline.clone().add(14, 'day');
	if (predictedEndDate.isAfter(bufferedProjectDeadline, 'day')) {
		return 'DANGER';
	} else if (predictedEndDate.isAfter(projectDeadline)) {
		return 'WARNING';
	} else {
		return 'NO_WARNING';
	}
};

export const shouldShowReestimationWarning = (totalTaskEstimate, statsPerDayMap, projectDone) => {
	if (projectDone) {
		return false;
	}
	const last30EstimateArray = takeRight(
		Array.from(statsPerDayMap.values()).map(stat => stat.totalEstimateMinutes),
		30
	);
	const minimumEstimate = Math.min(...last30EstimateArray);
	const absoluteDiff = totalTaskEstimate - minimumEstimate;
	if (absoluteDiff < 600) return false;
	if (minimumEstimate === 0) return true;

	const relativeDiff = (totalTaskEstimate - minimumEstimate) / minimumEstimate;
	return relativeDiff > 0.15;
};
