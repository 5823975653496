import React, {useMemo} from 'react';
import {ShowMore} from '../../../forecast-app/shared/components/dropdowns/skill-dropdown/ShowMore';
import SelectionBadge from '../../../forecast-app/shared/components/dropdowns/selection_badge';
import Styled from 'styled-components';
import _ from 'lodash';
import {useIntl} from 'react-intl';
import {TeamDropdown} from '../../../components/dropdowns/team-dropdown/Team_dropdown';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const SelectionPreview = Styled.div`
    padding-top: 16px;
`;

export const TeamsSelector = ({selected, teams, onChange}) => {
	const {formatMessage} = useIntl();

	const teamById = useMemo(() => {
		return _.keyBy(
			teams.map(edge => edge.node),
			team => team.id
		);
	}, [teams]);

	const removeTeam = teamId => {
		trackEvent('Placeholder Team', 'Deselected');
		onChange(selected.filter(selectedTeam => selectedTeam !== teamId));
	};

	const onTeamAdded = (_, selected) => {
		trackEvent('Placeholder Team', 'Selected');
		return onChange(selected);
	};
	const onTeamRemoved = (_, selected) => {
		trackEvent('Placeholder Team', 'Deselected');
		return onChange(selected);
	};
	return (
		<>
			<TeamDropdown
				usePortal
				selectedItems={selected}
				onSelect={onTeamAdded}
				onRemove={onTeamRemoved}
				cy={'dropdown-teams'}
				dropdownAlignment={'none'}
				name={formatMessage({id: 'placeholder.add_team'})}
				teams={teams}
				optionsName={formatMessage({id: 'settings.teams'})}
			/>
			{!!selected?.length && (
				<SelectionPreview>
					<ShowMore>
						{selected.map(teamId => {
							const team = teamById[teamId];
							return <SelectionBadge key={team.id} name={team.name} onRemove={() => removeTeam(team.id)} />;
						})}
					</ShowMore>
				</SelectionPreview>
			)}
		</>
	);
};
