import React from 'react';
import { Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
import { hasPermission } from '../../../../../forecast-app/shared/util/PermissionsUtil';
import { PERMISSION_TYPE } from '../../../../../Permissions';
import { useHistory } from 'react-router-dom';
const MissingClientBillToWarningModal = ({ closeModal, clientId, fromProjectSettings, }) => {
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    const history = useHistory();
    const goToClientPage = () => {
        closeModal();
        history.push(`/admin/clients/view-client/${clientId}`);
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: fromProjectSettings
                    ? 'invoicing.project.client_bill_tos_missing.title'
                    : 'invoicing.add_billing_details_and_export_invoice' })),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: fromProjectSettings
                        ? 'invoicing.project.client_bill_tos_missing.description'
                        : 'invoicing.client_bill_tos_missing' })),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: isAdmin
                        ? 'invoicing.client_bill_tos_missing_add_from_client_page'
                        : 'invoicing.client_bill_tos_missing_contact_admin' }))),
        React.createElement(Modal.Footer, null,
            isAdmin && (React.createElement(Modal.PrimaryFooterButton, { onPress: goToClientPage },
                React.createElement(FormattedMessage, { id: 'invoicing.go_to_client_page' }))),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default MissingClientBillToWarningModal;
