import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from '../generic_modal';
import {APPROVAL_TYPE, BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import UpdateApprovalStatusMutation from '../../../mutations/UpdateApprovalStatusMutation';
import {ModalText, ModalWrapper} from '../modal_styled';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const SubmitTimeOffApprovalModal = ({selectedPersonId, selectedPersonName, closeModal, intl: {formatMessage}}) => {
	const handleConfirmButton = () => {
		trackEvent('Submit Time Off Modal Save Button', 'Clicked');
		const onSuccess = res => {
			const personRes = res.updateApprovalStatus.persons[0];
			if (personRes) {
				createToast({duration: 2500, message: formatMessage({id: 'time_approval.submit_time_off_toast'})});
			}
			closeModal();
		};

		// Mutation
		Util.CommitMutation(
			UpdateApprovalStatusMutation,
			{
				personIds: [selectedPersonId],
				approvalType: APPROVAL_TYPE.SUBMIT_TIME_OFF,
				includeTimeOff: true,
			},
			onSuccess
		);
	};

	const cancelButton = () => {
		trackEvent('Submit Time Off Modal Cancel Button', 'Clicked');
	};

	const content = (
		<ModalWrapper medium>
			<ModalText margin={'24px'}>
				{selectedPersonName
					? formatMessage({id: 'time_off_approval.submit_names_timesheets_info'}, {name: selectedPersonName})
					: formatMessage({id: 'time_off_approval.submit_timesheets_info'})}
			</ModalText>
		</ModalWrapper>
	);
	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: cancelButton.bind(this),
				},
				{
					text: formatMessage({id: 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: handleConfirmButton.bind(this),
					cy: 'submit-time-off-save-button',
				},
			]}
			headerText={
				selectedPersonName
					? formatMessage({id: 'time_approval.submit_names_time_off'}, {name: selectedPersonName})
					: formatMessage({id: 'time_approval.submit_time_off'})
			}
			content={content}
		/>
	);
};

SubmitTimeOffApprovalModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(SubmitTimeOffApprovalModal);
