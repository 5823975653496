import React, {Component} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import DateRangePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../constants';
import SuppressTaskOutsideProjectPeriodWarningMutation from '../../mutations/suppress_task_outside_project_period_warning_mutation';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import GenericModal from './generic_modal';
import UpdateTaskMutation from '../../mutations/update_task_mutation.modern';

class TaskOutsideProjectPeriodModal extends Component {
	constructor(props) {
		super(props);
		const {project} = this.props;

		this.state = {
			projectStartDate: Util.CreateNonUtcMomentDate(
				project.projectStartYear,
				project.projectStartMonth,
				project.projectStartDay
			),
			projectEndDate: Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay),
			taskDates: {},
		};

		this.handleOnOk = this.handleOnOk.bind(this);
		this.handleProjectDateRangeChange = this.handleProjectDateRangeChange.bind(this);
	}

	handleOnOk() {
		const {projectStartDate, projectEndDate, taskDates} = this.state;
		const startDate = Util.GetYearMonthDateFromMomentDate(projectStartDate);
		const endDate = Util.GetYearMonthDateFromMomentDate(projectEndDate);
		const {onSuccess, project} = this.props;

		Object.keys(taskDates).forEach(taskId => {
			const taskDate = taskDates[taskId];
			const taskStartDate = Util.GetYearMonthDateFromMomentDate(taskDate.taskStartDate);
			const taskDeadlineDate = Util.GetYearMonthDateFromMomentDate(taskDate.taskDeadlineDate);

			Util.CommitMutation(UpdateTaskMutation, {
				ids: [taskId],
				startYear: taskStartDate.year,
				startMonth: taskStartDate.month,
				startDay: taskStartDate.day,
				deadlineYear: taskDeadlineDate.year,
				deadlineMonth: taskDeadlineDate.month,
				deadlineDay: taskDeadlineDate.day,
			});
		});

		Util.CommitMutation(UpdateProjectMutation, {
			project,
			projectStartYear: startDate.year,
			projectStartMonth: startDate.month,
			projectStartDay: startDate.day,
			projectEndYear: endDate.year,
			projectEndMonth: endDate.month,
			projectEndDay: endDate.day,
		});
		Util.CommitMutation(SuppressTaskOutsideProjectPeriodWarningMutation, {projectId: project.id}, onSuccess);
	}

	handleProjectDateRangeChange(projectStartDate, projectEndDate) {
		this.setState({
			projectStartDate,
			projectEndDate,
		});
	}

	handleTaskDateRangeChange(taskId, taskStartDate, taskDeadlineDate) {
		this.setState(prevState => ({
			taskDates: {
				...prevState.taskDates,
				[taskId]: {
					taskStartDate,
					taskDeadlineDate,
				},
			},
		}));
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {tasks} = this.props;
		const {projectStartDate, projectEndDate, taskDates} = this.state;

		const content = (
			<div className="auto-scheduling-modal task-outside-project-period-modal">
				<div className="warning-description-large">
					<FormattedHTMLMessage id="project_budget.task_outside_project_period_description_1" />
				</div>
				<div className="warning-description-large">
					<DateRangePicker
						startDate={projectStartDate}
						endDate={projectEndDate}
						handleDateRangeChange={this.handleProjectDateRangeChange}
						isNewDateRange={true}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						highlighted={true}
					/>
				</div>
				<div className="warning-description-large tasks_description">
					<FormattedHTMLMessage id="project_budget.task_outside_project_period_description_2" />
				</div>
				<div className="table-heading-row">
					<div className="scrollable-table">
						<div className="headers">
							<div className="header column-task">{formatMessage({id: 'common.task'})}</div>
							<div className="header column-date">{formatMessage({id: 'common.date'})}</div>
							<div className="header column-last" />
						</div>

						<div className="rows">
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
								{tasks.edges.map((task, index) => {
									const taskStartDate = taskDates[task.node.id]
										? taskDates[task.node.id].taskStartDate
										: Util.CreateNonUtcMomentDate(
												task.node.startYear,
												task.node.startMonth,
												task.node.startDay
										  );
									const taskDeadlineDate = taskDates[task.node.id]
										? taskDates[task.node.id].taskDeadlineDate
										: Util.CreateNonUtcMomentDate(
												task.node.deadlineYear,
												task.node.deadlineMonth,
												task.node.deadlineDay
										  );

									const focusStart = index === 0 && taskStartDate.isBefore(projectStartDate);
									const focusEnd = index === 0 && !focusStart;

									return (
										<div key={task.node.id} className="row">
											<div className="column-task">{task.node.name}</div>
											<div className="column-date">
												<DateRangePicker
													startDate={taskStartDate}
													endDate={taskDeadlineDate}
													handleDateRangeChange={(startDate, deadlineDate) => {
														this.handleTaskDateRangeChange(task.node.id, startDate, deadlineDate);
													}}
													isNewDateRange={true}
													datePickerStyle={DATE_PICKER_STYLE.STANDARD}
													highlighted={true}
													focusStart={focusStart}
													focusEnd={focusEnd}
												/>
											</div>
											<div className="column-last">
												<div className="chip-container">
													<div className="chip-div" />
													<div className="chip" />
												</div>
											</div>
										</div>
									);
								})}
							</CustomScrollDiv>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.save'}),
						callback: this.handleOnOk,
					},
				]}
				content={content}
				headerText={formatMessage({id: 'project_budget.task_outside_project_period'})}
			/>
		);
	}
}

TaskOutsideProjectPeriodModal.propTypes = {};

export default injectIntl(TaskOutsideProjectPeriodModal);
