/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectsLoader_financialsMultiCurrencies$ref: FragmentReference;
declare export opaque type ProjectsLoader_financialsMultiCurrencies$fragmentType: ProjectsLoader_financialsMultiCurrencies$ref;
export type ProjectsLoader_financialsMultiCurrencies = {|
  +financialNumbers: ?{|
    +billablePlannedTimeAndExpenses: ?number,
    +actualRevenue: ?number,
    +remainingRevenue: ?number,
    +forecastRevenue: ?number,
    +recognitionOpenRevenue: ?number,
    +recognitionForecastRevenue: ?number,
    +recognitionLockedRevenue: ?number,
    +plannedCost: ?number,
    +actualCost: ?number,
    +forecastCost: ?number,
    +remainingCost: ?number,
    +plannedProfit: ?number,
    +forecastProfit: ?number,
    +invoiced: ?number,
    +paid: ?number,
  |},
  +financialNumbersProjectCurrency: ?{|
    +billablePlannedTimeAndExpenses: ?number,
    +actualRevenue: ?number,
    +remainingRevenue: ?number,
    +forecastRevenue: ?number,
    +recognitionOpenRevenue: ?number,
    +recognitionForecastRevenue: ?number,
    +recognitionLockedRevenue: ?number,
    +plannedCost: ?number,
    +actualCost: ?number,
    +forecastCost: ?number,
    +remainingCost: ?number,
    +plannedProfit: ?number,
    +forecastProfit: ?number,
    +invoiced: ?number,
    +paid: ?number,
  |},
  +$refType: ProjectsLoader_financialsMultiCurrencies$ref,
|};
export type ProjectsLoader_financialsMultiCurrencies$data = ProjectsLoader_financialsMultiCurrencies;
export type ProjectsLoader_financialsMultiCurrencies$key = {
  +$data?: ProjectsLoader_financialsMultiCurrencies$data,
  +$fragmentRefs: ProjectsLoader_financialsMultiCurrencies$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "billablePlannedTimeAndExpenses",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actualRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionOpenRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionForecastRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionLockedRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "plannedCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actualCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingCost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "plannedProfit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastProfit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "invoiced",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paid",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsLoader_financialsMultiCurrencies",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbersProjectCurrency",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '5606fabbfdca7f251fa6a4717f24c313';

module.exports = node;
