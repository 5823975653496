// @ts-check
import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {ProjectStatusWrapper, RichTextWrapper, TopRow} from '../project_health_styles/ProjectStatus.styled';
import {StatusIndicator} from './StatusIndicator';
import RichTextItem from '../../../../components/richtext_item';
import Util from '../../../../forecast-app/shared/util/util';
import {Hexagon} from 'web-components';

import {profilePicSrc} from '../../../../directApi';

/**
 * @component
 */
const ProjectStatus = ({projectStatus, showStatusIndicator = true}) => {
	if (!projectStatus) return null;
	const creationDate = Util.CreateNonUtcMomentDate(projectStatus.year, projectStatus.month, projectStatus.day);

	return (
		<ProjectStatusWrapper>
			<TopRow>
				{showStatusIndicator ? <StatusIndicator projectStatusColor={projectStatus.color} /> : null}
				<Hexagon text={projectStatus.person.fullName} imgUrl={profilePicSrc(projectStatus.person.profilePictureId)} />
				<span style={{marginLeft: '4px'}}>
					{projectStatus.person.fullName + ' - ' + creationDate?.format(Util.GetShortYearMomentFormat('ll'))}
				</span>
			</TopRow>
			{projectStatus.description ? (
				<RichTextWrapper>
					<RichTextItem
						cy={'status-description'}
						projectLocked={false}
						alwaysShowControls={false}
						readOnly={true}
						text={projectStatus.description}
						placeholder={''}
					/>
				</RichTextWrapper>
			) : null}
		</ProjectStatusWrapper>
	);
};

export default createFragmentContainer(ProjectStatus, {
	projectStatus: graphql`
		fragment ProjectStatus_projectStatus on ProjectStatus {
			id
			person {
				id
				fullName
				profilePictureId
				profilePictureDefaultId
			}
			description
			color
			day
			month
			year
		}
	`,
});
