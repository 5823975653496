/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type PerformanceModalChartSection_projectStats$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PerformanceModal_viewer$ref: FragmentReference;
declare export opaque type PerformanceModal_viewer$fragmentType: PerformanceModal_viewer$ref;
export type PerformanceModal_viewer = {|
  +project: ?{|
    +id: string,
    +name: ?string,
    +companyProjectId: ?number,
    +estimationUnit: ?string,
    +projectHealth: ?{|
      +id: string,
      +performanceTotal: ?number,
    |},
    +projectStats: ?$ReadOnlyArray<?{|
      +id: string,
      +$fragmentRefs: PerformanceModalChartSection_projectStats$ref,
    |}>,
  |},
  +$refType: PerformanceModal_viewer$ref,
|};
export type PerformanceModal_viewer$data = PerformanceModal_viewer;
export type PerformanceModal_viewer$key = {
  +$data?: PerformanceModal_viewer$data,
  +$fragmentRefs: PerformanceModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PerformanceModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectHealth",
          "kind": "LinkedField",
          "name": "projectHealth",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "performanceTotal",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectStats",
          "kind": "LinkedField",
          "name": "projectStats",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PerformanceModalChartSection_projectStats"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f90455920c2150c50344773fbfd8304e';

module.exports = node;
